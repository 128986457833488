import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { AdbWorkloadType, OciResourceType } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { AzureSubscriptionSummaryExt } from "../../hooks/useSubscriptions";
import { getOciRegion } from "../../utils";
import { OciLink, OciLinkProps } from "./OciLink";

export interface OciAdbLinkProps extends Pick<OciLinkProps, "location" | "analytics"> {
  id: string;
  subscriptions: AzureSubscriptionSummaryExt[];
}

export const OciAdbLink = ({ id, location, subscriptions, analytics }: OciAdbLinkProps): JSX.Element => {
  const idComps = parseId(id);
  const resGroup = decodeURIComponent(idComps?.resourceGroup || "");
  const resourceName = decodeURIComponent(idComps?.resourceName || "");
  const subscription = subscriptions?.find(item => item.id === idComps?.subscriptionId);

  const { response, error } = useQueryCall({
    wait: !id,
    method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.getAdbsDatabase,
    options: {
      args: {
        subscriptionId: idComps?.subscriptionId || "",
        resourceGroupName: resGroup,
        databaseName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });

  const adb = response?.data;

  const ociResourcePath = adb?.dbWorkload === AdbWorkloadType.APEX
    ? `/apex/instances/${adb?.ocid}`
    : `/db/adb/${adb?.ocid}`;

  const ociResourceType = adb?.dbWorkload === AdbWorkloadType.APEX ? OciResourceType.APEX : OciResourceType.ADBS;

  return (
    <OciLink
      ociResourceType={ociResourceType}
      ociResourcePath={!error && adb?.ocid ? ociResourcePath : undefined}
      ociResourceName={resourceName}
      location={location}
      subscription={subscription}
      analytics={analytics}
    />
  );
};
