import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { NetworkingDetails } from "../../components/NetworkingDetails/NetworkingDetails";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { AzureVirtualNetworkCollection, AzureVirtualNetworkSummary } from "../../gen/clients/mchub-azure-api-client";
import { VmdbDbSystem } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { parseId } from "../../helpers/idHelper";
import { responseItemstoArray } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

export interface VmDbSystemNetworkingDetailsProps {
  dbSystem: VmdbDbSystem | undefined;
  location: string;
  subscriptionId: string;
  resourceGroupName: string,
}

export const VmDbSystemNetworkingDetails = (
  { dbSystem, location, subscriptionId, resourceGroupName }: VmDbSystemNetworkingDetailsProps,
): JSX.Element => {
  const { response: resNetwork } = useQueryCall({
    wait: !dbSystem?.networkLinkId,
    method: apiClients.withRegion(getOciRegion(location)).netLinkApi.getNetworkLink,
    options: {
      args: {
        networkLinkName: decodeURIComponent(parseId(dbSystem?.networkLinkId)?.resourceName || ""),
        subscriptionId: subscriptionId || "",
        resourceGroupName,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadNetLink(),
      },
    },
  });

  const networkLink = resNetwork?.data;

  const { response: vnetResponse } = useQueryCall({
    wait: !(subscriptionId && location),
    method: apiClients.withRegion(getOciRegion(location)).azResourceApi.listAzureVirtualNetworksBySubscriptionOnly,
    options: {
      args: { subscriptionId, location },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVNET(),
      },
    },
  });

  const vnets = (vnetResponse
    && responseItemstoArray<AzureVirtualNetworkCollection, AzureVirtualNetworkSummary>(vnetResponse)) || [];

  const selectedVnet = vnets?.find(vnet => vnet.id === networkLink?.properties?.azureAttachedNetworkIds?.[0]);

  return (
    <NetworkingDetails
      hostName={dbSystem?.hostname}
      vnet={selectedVnet}
      networkLink={networkLink}
      location={location}
      mcvcnId={dbSystem?.mcvcnId}
      analytics={{ pageId: PageId.VMDB_SYSTEM_DETAILS, panelId: DetailsPanelId.NETWORKING }}
    />
  );
};
