import { FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Fields as TagDetailsFields, TagsEditPanel } from "../../components/TagsEditPanel/TagsEditPanel";
import { TagsInfoType } from "../../helpers/resourceHelper";
import { useOperation } from "../../hooks/useOperation";
import { OperationProps } from "../OperationTypes";
import { newVmDbSystemUpdateTags, VmDbSystemUpdateTagsProps } from "./VmDbSystemUpdateTags";

export interface VmDbSystemEditTagsProps extends OperationProps {
  dbSystemId: string | undefined;
  defaultTags?: TagsInfoType;
}

const VmDbSystemEditTags = (
  { location, dbSystemId, defaultTags, onExecute, onCancel }: VmDbSystemEditTagsProps,
): JSX.Element => {
  const { trigger: triggerUpdateTagsSystem } = useOperation<VmDbSystemUpdateTagsProps>(newVmDbSystemUpdateTags);

  return (
    <TagsEditPanel
      title={Messages.labels.editTags()}
      resourceId={dbSystemId}
      tagsDefaultValue={defaultTags}
      onSubmit={(formValues: FormValues) => {
        const tags = getValue<TagsInfoType>(formValues, TagDetailsFields.Tags, InputFormGroup) ?? [];
        triggerUpdateTagsSystem({
          dbSystemId,
          tags,
          location,
          onExecute,
        });
      }}
      onClose={onCancel}
    />
  );
};

export const newVmDbSystemEditTags = (
  props: VmDbSystemEditTagsProps,
): JSX.Element => (<VmDbSystemEditTags {...props} />);
