import {
  AnchoredPanelType,
  CheckBox,
  FieldSet,
  FormValues,
  InfoBlockLayout,
  InfoBlockStatus,
  InputFormSidePanel,
  InputFormSidePanelProps,
  Select,
  SubmitButtonMode,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { VmdbDbBackupConfig } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { backupRetentionPeriods, backupSlots, getBackUpScheduleTimeRange } from "../../helpers/resourceHelper";
import { utc } from "../../helpers/timeHelper";

export enum Fields {
  Backups = "backups",
  RetentionPeriod = "retention-period",
  Scheduling = "scheduling",
}

export enum FieldTestIds {
  Backups = "backups-test",
  RetentionPeriod = "retention-period-test",
  Scheduling = "scheduling-test",
}

export enum InfoBlockTestIds {
  BackupInfo = "backup-info"
}

export interface VmDbCdbBackupPanelProps extends
  Pick<InputFormSidePanelProps, "componentRef" | "onClose" | "onSubmit"> {
  cdbBackup: VmdbDbBackupConfig | undefined;
}

export const VmDbCdbBackupEditPanel = ({
  componentRef,
  cdbBackup,
  onSubmit,
  onClose,
}: VmDbCdbBackupPanelProps): JSX.Element => {
  const { locale } = React.useContext<Settings>(SettingsContext);
  const [showBackupOptions, setShowBackupOptions] = React.useState<boolean>();
  const [defaultBackupSchedule, setDefaultBackupSchedule] = React.useState<string>();
  const [defaultRetentionPeriod, setDefaultRetentionPeriod] = React.useState<string>();
  const [key, setKey] = React.useState<string>();

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    const initFieldValues: FormValues = {};
    if (cdbBackup) {
      const isAutoBackupEnabled = !!cdbBackup.autoBackupEnabled;
      initFieldValues[Fields.Backups] = isAutoBackupEnabled;
      setShowBackupOptions(isAutoBackupEnabled);

      if (isAutoBackupEnabled) {
        const retentionPeriod = cdbBackup.recoveryWindowInDays?.toString();

        if (retentionPeriod) {
          initFieldValues[Fields.RetentionPeriod] = [retentionPeriod];
          setDefaultRetentionPeriod(retentionPeriod);
        }

        initFieldValues[Fields.Scheduling] = [cdbBackup.autoBackupWindow];
        setDefaultBackupSchedule(cdbBackup.autoBackupWindow);
      }
    } else {
      initFieldValues[Fields.Backups] = false;
      setShowBackupOptions(false);
    }
    setInitialValues(initFieldValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdbBackup]);

  const info = {
    messageType: InfoBlockStatus.INFO,
    message: Messages.createVmDb.managementTab.info.backupInfo(),
    infoLayout: InfoBlockLayout.Compact,
    testId: InfoBlockTestIds.BackupInfo,
  };

  const onChangeShowBackUpOptions = (value: boolean): void => {
    setShowBackupOptions(value);
    if (value) {
      setKey(uniqueGUID);
    }
  };

  const retentionOptions = Object.values(backupRetentionPeriods).map(period => ({
    id: `${period}`,
    text: `${period} ${Messages.common.days()}`,
  }));

  const scheduleOptions = backupSlots.map(schedule => ({
    id: schedule.id,
    text: getBackUpScheduleTimeRange(schedule.id, locale),
  }));

  return (
    <InputFormSidePanel
      componentRef={componentRef}
      type={AnchoredPanelType.MEDIUM}
      title={Messages.createNewPanels.editBackup.title()}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onClose={onClose}
      testId={SidePanelTestIds.VmDbCdbBackupEditPanel}
      initialValues={initialValues}
    >
      <FieldSet
        header={Messages.labels.backup()}
      >
        <CheckBox
          testId={FieldTestIds.Backups}
          defaultValue={showBackupOptions}
          statusInfo={info}
          fieldName={Fields.Backups}
          label={Messages.labels.enableAutomaticBackups()}
          onChange={value => {
            onChangeShowBackUpOptions(value);
          }}
        />
        {showBackupOptions && (
          <>
            <Select
              testId={FieldTestIds.RetentionPeriod}
              key={`${key}-1`}
              defaultValue={defaultRetentionPeriod ? [defaultRetentionPeriod] : undefined}
              required
              fieldName={Fields.RetentionPeriod}
              label={Messages.labels.backupRetentionPeriod()}
              options={retentionOptions}
              onChange={(id: string) => setDefaultRetentionPeriod(id)}
              hideSearchBox
            />
            <Select
              testId={FieldTestIds.Scheduling}
              key={`${key}-2`}
              defaultValue={defaultBackupSchedule ? [defaultBackupSchedule] : undefined}
              required
              fieldName={Fields.Scheduling}
              label={`${Messages.labels.backupScheduling()} (${utc})`}
              options={scheduleOptions}
              onChange={(id: string) => setDefaultBackupSchedule(id)}
              hideSearchBox
            />
          </>
        )}
      </FieldSet>
    </InputFormSidePanel>
  );
};
