import * as Messages from "../codegen/Messages";

export enum InactivityThresholdId {
  NEVER = "NEVER",
  MINS_15 = "MINS_15",
  MINS_30 = "MINS_30",
  MINS_45 = "MINS_45",
  MINS_60 = "MINS_60",
  MINS_90 = "MINS_90",
  MINS_120 = "MINS_120",
  CUSTOM = "CUSTOM",
}

export enum CustomMinuteId {
  CUSTOM_MINS_0 = "CUSTOM_MINS_0",
  CUSTOM_MINS_15 = "CUSTOM_MINS_15",
  CUSTOM_MINS_30 = "CUSTOM_MINS_30",
  CUSTOM_MINS_45 = "CUSTOM_MINS_45",
}

export const DefaultInactivityThresholdId = InactivityThresholdId.NEVER;

export interface InactivityThreshold {
  id: InactivityThresholdId;
  idleInMins: number | undefined;
}

export const InactivityThresholds: {[key in InactivityThresholdId]: InactivityThreshold} = {
  [InactivityThresholdId.NEVER]: { id: InactivityThresholdId.NEVER, idleInMins: undefined },
  [InactivityThresholdId.MINS_15]: { id: InactivityThresholdId.MINS_15, idleInMins: 15 },
  [InactivityThresholdId.MINS_30]: { id: InactivityThresholdId.MINS_30, idleInMins: 30 },
  [InactivityThresholdId.MINS_45]: { id: InactivityThresholdId.MINS_45, idleInMins: 45 },
  [InactivityThresholdId.MINS_60]: { id: InactivityThresholdId.MINS_60, idleInMins: 60 },
  [InactivityThresholdId.MINS_90]: { id: InactivityThresholdId.MINS_90, idleInMins: 90 },
  [InactivityThresholdId.MINS_120]: { id: InactivityThresholdId.MINS_120, idleInMins: 120 },
  [InactivityThresholdId.CUSTOM]: { id: InactivityThresholdId.CUSTOM, idleInMins: undefined },
};

export const getInactivityThresholdStr = (inactivityThresholdId: InactivityThresholdId): string => {
  switch (inactivityThresholdId) {
    case InactivityThresholdId.NEVER: return Messages.inactivitySignOut.settings.never();
    case InactivityThresholdId.MINS_15: return Messages.inactivitySignOut.settings.min15();
    case InactivityThresholdId.MINS_30: return Messages.inactivitySignOut.settings.min30();
    case InactivityThresholdId.MINS_45: return Messages.inactivitySignOut.settings.min45();
    case InactivityThresholdId.MINS_60: return Messages.inactivitySignOut.settings.min60();
    case InactivityThresholdId.MINS_90: return Messages.inactivitySignOut.settings.min90();
    case InactivityThresholdId.MINS_120: return Messages.inactivitySignOut.settings.min120();
    case InactivityThresholdId.CUSTOM: return Messages.inactivitySignOut.settings.custom();
    default: return inactivityThresholdId;
  }
};

export interface CustomMinute {
  id: CustomMinuteId;
  duration: number;
}

export const CustomMinutes: {[key in CustomMinuteId]: CustomMinute} = {
  [CustomMinuteId.CUSTOM_MINS_0]: { id: CustomMinuteId.CUSTOM_MINS_0, duration: 0 },
  [CustomMinuteId.CUSTOM_MINS_15]: { id: CustomMinuteId.CUSTOM_MINS_15, duration: 15 },
  [CustomMinuteId.CUSTOM_MINS_30]: { id: CustomMinuteId.CUSTOM_MINS_30, duration: 30 },
  [CustomMinuteId.CUSTOM_MINS_45]: { id: CustomMinuteId.CUSTOM_MINS_45, duration: 45 },
};

export const getCustomMinuteStr = (customMinute: CustomMinuteId): string => {
  switch (customMinute) {
    case CustomMinuteId.CUSTOM_MINS_0: return "00";
    case CustomMinuteId.CUSTOM_MINS_15: return "15";
    case CustomMinuteId.CUSTOM_MINS_30: return "30";
    case CustomMinuteId.CUSTOM_MINS_45: return "45";
    default: return customMinute;
  }
};
