import {
  CheckBox,
  FormInputGroupLayout,
  FormValues,
  InfoBlock,
  InfoBlockLayout,
  InfoBlockStatus,
  InputFormCallout,
  InputFormCalloutProps,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";

export enum Fields {
  LocalEnabled = "localEnabled",
  SparseEnabled = "sparseEnabled",
}

export enum FieldsTestIds {
  LocalEnabled = "localEnabled",
  SparseEnabled = "sparseEnabled",
}

export enum InfoBlockTestIds{
  StorageInfoBlock = "storage-info-block"
}
export interface StoragePanelConfigProps extends
  Pick<InputFormCalloutProps, "title" | "targetId" | "onClose" | "onSubmit">
{
  defaultValue?: { localEnabled: boolean, sparseEnabled: boolean },
}

export const StoragePanelConfig = ({
  defaultValue,
  title,
  onClose,
  onSubmit,
  targetId,
}: StoragePanelConfigProps): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (defaultValue) {
      const initFieldValues: FormValues = {
        [Fields.SparseEnabled]: defaultValue.sparseEnabled,
        [Fields.LocalEnabled]: defaultValue.localEnabled,
      };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <InputFormCallout
      layout={FormInputGroupLayout.WIDE}
      testId={CalloutTestIds.StoragePanel}
      title={title || ""}
      targetId={targetId}
      onClose={onClose}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <CheckBox
        fieldName={Fields.SparseEnabled}
        testId={FieldsTestIds.SparseEnabled}
        defaultValue={defaultValue?.sparseEnabled}
        label={Messages.labels.allocateStorageSparse()}
        tooltip={Messages.hints.allocateStorageSparse()}
      />
      <CheckBox
        fieldName={Fields.LocalEnabled}
        testId={FieldsTestIds.LocalEnabled}
        defaultValue={defaultValue?.localEnabled}
        label={Messages.labels.allocateStorageLocal()}
        tooltip={Messages.hints.allocateStorageLocal()}
      />
      <span style={{ marginBottom: "10px" }}>
        <InfoBlock
          testId={InfoBlockTestIds.StorageInfoBlock}
          message={Messages.createCommon.storageNoChangeAfterVMCreation()}
          messageType={InfoBlockStatus.INFO}
          infoLayout={InfoBlockLayout.Compact}
        />
      </span>
    </InputFormCallout>
  );
};
