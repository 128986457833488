import { InputFormCallout, InputFormCalloutProps, TextInput } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";
import { nameCharMaxLength, nameCharMinLength, nameValidationRegex } from "../../helpers/validationHelper";
import { ExaDbVersionSelect } from "../DbVersionSelect/ExaDbVersionSelect";

export interface DbHomeCreateCalloutProps extends Pick<InputFormCalloutProps,
  "targetId" | "onClose" | "onSubmit" | "componentRef"> {
    namePlaceholder: string;
    subscriptionId: string;
    location: string;
  }

export enum Fields {
  DatabaseHomeVersion = "databaseHomeVersion",
  DatabaseHomeName = "databaseHomeName",
}

export enum FieldTestIds {
  DatabaseHomeVersion = "databaseHomeVersion",
  DatabaseHomeName = "databaseHomeName",
}

export const DbHomeCreateCallout = ({
  namePlaceholder,
  subscriptionId,
  location,
  targetId,
  componentRef,
  onClose,
  onSubmit,
}: DbHomeCreateCalloutProps): JSX.Element => (
  <InputFormCallout
    componentRef={componentRef}
    title={Messages.createNewPanels.dbHome.title()}
    targetId={targetId}
    testId={CalloutTestIds.DbHomeCreatePanel}
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <TextInput
      testId={FieldTestIds.DatabaseHomeName}
      fieldName={Fields.DatabaseHomeName}
      placeholder={namePlaceholder}
      label={Messages.labels.name()}
      validator={(value: string | undefined) => {
        const errors: string[] = [];
        if (value && (value.length < nameCharMinLength || value.length > nameCharMaxLength)) {
          errors.push(
            Messages.validation.nameChars(
              nameCharMinLength.toString(),
              nameCharMaxLength.toString(),
            ),
          );
        }
        if (value && !nameValidationRegex.test(value)) {
          errors.push(Messages.validation.nameValidation());
        }
        return errors.length > 0 ? errors : undefined;
      }}
    />
    <ExaDbVersionSelect
      required
      testId={FieldTestIds.DatabaseHomeVersion}
      fieldName={Fields.DatabaseHomeVersion}
      label={Messages.labels.databaseVersion()}
      subscriptionId={subscriptionId}
      location={location}
    />
  </InputFormCallout>
);
