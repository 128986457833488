import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  optimizedRetryOption,
  Select,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { MdsShapeSummary, MdsShapeSummaryCollection } from "../../gen/clients/mchub-azure-api-client-mds";
import { IdResourceType } from "../../helpers/idHelper";
import { MdsShapesEnum, responseItemstoArray } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

export interface HeatwaveShapeSelectProps extends Omit<SelectProps, "options"> {
  subscriptionId: string;
  location: string;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const HeatwaveShapeSelect = (
  {
    location,
    subscriptionId,
    label,
    fieldName,
    defaultValue,
    onError,
    placeholder,
    onMissingDependencies,
    ...rest
  }: HeatwaveShapeSelectProps,
): JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());

  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const form: FormState = React.useContext(FormContext);

  const { loading, response, error } = useQueryCall({
    wait: !(subscriptionId && location),
    method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.listMdsShapes,
    options: {
      args: { subscriptionId, shapeType: MdsShapesEnum.HEATWAVECLUSTER },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadMySQLHeatwaveShape(),
      },
    },
  });

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location]);

  const shapes = (response
    && responseItemstoArray<MdsShapeSummaryCollection, MdsShapeSummary>(response)) || [];

  const options = shapes?.map(shape => ({
    id: shape.name || "",
    text: shape.name || "",
  })).sort(({ text: a }, { text: b }) => (a === b ? 0
    : (a > b ? -1 : 1)));

  const derivedDefaultValue = options.length === 1 ? [options[0].id] : defaultValue;

  React.useEffect(() => {
    if (!loading && options?.length === 1) {
      setKey(uniqueGUID());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) {
      missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    }
    if (!location) {
      missingDependencies.push(IdResourceType.LOCATION);
    }
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const derivedPlaceholder = !loading && !error && !options?.length
    ? Messages.createCommon.loadingNone(label?.toLowerCase() || "")
    : placeholder;

  return (
    <Select
      key={key}
      loading={loading}
      label={label}
      options={options}
      placeholder={derivedPlaceholder}
      defaultValue={derivedDefaultValue}
      onClick={internalOnClick}
      fieldName={fieldName}
      {...rest}
    />
  );
};
