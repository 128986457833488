import { NavigationEntry, NavigationEntryType } from "o4a-react";

export const SettingsVersion = "2.0.0";
export const SettingsVersionStorageProp = "settingsVersion";
export const SessionStorageDataKey = "O4A";
export const LocalStorageDataKey = "O4A-SETTINGS";
export const SubscriptionStorageProp = "subscription";
export const RegionStorageProp = "region";
export const LanguageStorageProp = "language";
export const PreferredLocationPerSubscriptionStorageProp = "preferredLocationPerSubscription";
export const MenuBehaviorStorageProp = "menuBehavior";
export const InactivityThresholdProp = "inactivityThreshold";

export const BASE_PATH = "azure";

export const SUBSCRIPTIONS_PATH = "subscriptions";
export const RESOURCE_GROUP_PATH = "resourceGroups";
export const PROVIDERS_PATH = "providers";

export enum ServiceProviders {
  EXADATA = "Database",
  VMDB = "Vmdb",
  ADBS = "Adbs",
  MDS = "Mds",
  NETWORK = "Network",
  DEPLOYMENT = "Deployment",
}

// IMPORTANT: Any new provider added MUST be added to the SupportedProviders array as well
export const ORACLE_PROVIDER_PATH = "Oracle";
export const ORACLE_DB_PROVIDER_PATH = `${ORACLE_PROVIDER_PATH}.Database`;
export const ORACLE_VMDB_PROVIDER_PATH = `${ORACLE_PROVIDER_PATH}.Vmdb`;
export const ORACLE_ADBS_PROVIDER_PATH = `${ORACLE_PROVIDER_PATH}.Adbs`;
export const ORACLE_MYSQL_PROVIDER_PATH = `${ORACLE_PROVIDER_PATH}.Mds`;
export const ORACLE_NET_PROVIDER_PATH = `${ORACLE_PROVIDER_PATH}.Network`;
export const ORACLE_DEPLOYMENT_PROVIDER_PATH = `${ORACLE_PROVIDER_PATH}.Deployment`;
export const ORACLE_SUPPORT_PROVIDER_PATH = `${ORACLE_PROVIDER_PATH}.Support`;

export const SupportedProviders = [
  ORACLE_DB_PROVIDER_PATH,
  ORACLE_VMDB_PROVIDER_PATH,
  ORACLE_ADBS_PROVIDER_PATH,
  ORACLE_MYSQL_PROVIDER_PATH,
  ORACLE_NET_PROVIDER_PATH,
  ORACLE_DEPLOYMENT_PROVIDER_PATH,
  ORACLE_SUPPORT_PROVIDER_PATH,
];

// IMPORTANT: The following resource type paths MUST have at least 6 characters after the period
// to work around a routing issue
export const EXADATA_HUB_PATH = "Exadata.hub";
export const EXADATA_SYSTEM_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.ExadataSystem`;
export const DATABASE_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.Database`;
export const DATABASES_RESOURCE_TYPE_PATH = `${ORACLE_DB_PROVIDER_PATH}%2FDatabases`;
export const VM_CLUSTERS_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.CloudVmClusters`;
export const EXADATA_INFRAS_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.CloudExadataInfrastructures`;

export const VMDB_HUB_PATH = "Vmdb.hub";
export const VM_DATABASE_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.VmdbDatabase`;

export const ADB_HUB_PATH = "Adb.hub";
export const AUTONOMOUS_DATABASE_SHARED_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.AdbsDatabase`;

export const APEX_DATABASE_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.ApexDatabase`;
export const APEX_DATABASES_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}%2FApexDatabases`;

export const MYSQL_DATABASE_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.MdsDbSystem`;
export const MYSQL_DATABASES_RESOURCE_TYPE_PATH = `${ORACLE_MYSQL_PROVIDER_PATH}%2FDbSystems`;

export const MCVCN_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.VirtualNetwork`;
// End of IMPORTANT note

export const DB_HOMES_RESOURCE_TYPE_PATH = "DbHomes";
export const DB_NODES_RESOURCE_TYPE_PATH = "DbNodes";
export const PDBS_RESOURCE_TYPE_PATH = "PluggableDatabases";
export const DB_SYSTEM_SHAPES_RESOURCE_PATH = "DbSystemShapes";
export const DB_VERSIONS_RESOURCE_PATH = "DbVersions";
export const GI_VERSIONS_RESOURCE_PATH = "GiVersions";

export const MCVCNS_RESOURCE_TYPE_PATH = `${ORACLE_NET_PROVIDER_PATH}%2FVirtualNetworks`;

export const NETLINK_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.NetworkLink`;
export const NETLINKS_RESOURCE_TYPE_PATH = `${ORACLE_NET_PROVIDER_PATH}%2FNetworkLinks`;

export const DEPLOYMENT_RESOURCE_TYPE_PATH = `${ORACLE_PROVIDER_PATH}.Deployment`;
export const DEPLOYMENTS_RESOURCE_TYPE_PATH = `${ORACLE_DEPLOYMENT_PROVIDER_PATH}%2FDeployments`;

export const HOME_PATH = "home";
export const RESOURCE_PATH = "resource";
export const RESOURCE_TYPE_PATH = "resourceType";
export const BROWSE_RESOURCE_PATH = "BrowseResource";
export const RECENT_RESOURCES_PATH = "RecentResources";
export const FAVORITE_RESOURCES_PATH = "FavoriteResources";
export const CREATE_PATH = "create";
export const LIST_PATH = "list";
export const HUB_PATH = "hub";
export const SETTINGS_PATH = "settings";
export const SUPPORT_PATH = "support";
export const CLOUDLINK_LIST_PATH = "subscriptions";
export const BILLING_PATH = "billing";

export const BASE_ROUTE = `/${BASE_PATH}`;
export const HOME_ROUTE = `/${HOME_PATH}`;
export const RECENT_RESOURCES_ROUTE = `/${RECENT_RESOURCES_PATH}`;
export const FAVORITE_RESOURCES_ROUTE = `/${FAVORITE_RESOURCES_PATH}`;
export const CREATE_HUB_ROUTE = `/${CREATE_PATH}/${HUB_PATH}`;
export const RESOURCE_ROUTE = `/${RESOURCE_PATH}`;
export const SETTINGS_ROUTE = `/${SETTINGS_PATH}`;
export const SUPPORT_ROUTE = `/${SUPPORT_PATH}`;
export const SUPPORT_CREATE_ROUTE = `/${SUPPORT_PATH}/${CREATE_PATH}`;
export const CLOUDLINK_LIST_ROUTE = `/${CLOUDLINK_LIST_PATH}`;
export const BILLING_ROUTE = `/${BILLING_PATH}`;

export const EXADB_FULL_CREATE_ROUTE = `/${CREATE_PATH}/${EXADATA_SYSTEM_RESOURCE_TYPE_PATH}`;
export const EXADB_CREATE_ROUTE = `/${CREATE_PATH}/${DATABASE_RESOURCE_TYPE_PATH}`;
export const VMCLUSTER_CREATE_ROUTE = `/${CREATE_PATH}/${VM_CLUSTERS_RESOURCE_TYPE_PATH}`;
export const EXAINFRA_CREATE_ROUTE = `/${CREATE_PATH}/${EXADATA_INFRAS_RESOURCE_TYPE_PATH}`;
export const EXADATA_LIST_HUB_ROUTE = `/${BROWSE_RESOURCE_PATH}/${EXADATA_HUB_PATH}`;

export const VMDB_CREATE_ROUTE = `/${CREATE_PATH}/${VM_DATABASE_RESOURCE_TYPE_PATH}`;
export const VMDB_LIST_HUB_ROUTE = `/${BROWSE_RESOURCE_PATH}/${VMDB_HUB_PATH}`;

export const ADBS_CREATE_ROUTE = `/${CREATE_PATH}/${AUTONOMOUS_DATABASE_SHARED_RESOURCE_TYPE_PATH}`;
export const ADB_LIST_HUB_ROUTE = `/${BROWSE_RESOURCE_PATH}/${ADB_HUB_PATH}`;

export const APEX_CREATE_ROUTE = `/${CREATE_PATH}/${APEX_DATABASE_RESOURCE_TYPE_PATH}`;
export const APEX_LIST_ROUTE = `/${BROWSE_RESOURCE_PATH}/${RESOURCE_TYPE_PATH}/${APEX_DATABASES_RESOURCE_TYPE_PATH}`;

export const MYSQL_CREATE_ROUTE = `/${CREATE_PATH}/${MYSQL_DATABASE_RESOURCE_TYPE_PATH}`;
export const MYSQL_LIST_ROUTE = `/${BROWSE_RESOURCE_PATH}/${RESOURCE_TYPE_PATH}/${MYSQL_DATABASES_RESOURCE_TYPE_PATH}`;

export const MCVCN_CREATE_ROUTE = `/${CREATE_PATH}/${MCVCN_RESOURCE_TYPE_PATH}`;
export const MCVCN_LIST_ROUTE = `/${BROWSE_RESOURCE_PATH}/${RESOURCE_TYPE_PATH}/${MCVCNS_RESOURCE_TYPE_PATH}`;

export const DEPLOYMENT_LIST_ROUTE = `/${BROWSE_RESOURCE_PATH}/${RESOURCE_TYPE_PATH}/${DEPLOYMENTS_RESOURCE_TYPE_PATH}`;

export enum PageId {
  TOP_HEADER = "top-header",
  TOP_MENU = "top-menu",
  ERROR = "error",
  SUPPORT = "support",
  SUPPORT_CREATE = "support-create",
  SUPPORT_DETAILS = "support-details",
  SETTINGS = "settings",
  CLOUDLINK_LIST = "cloudlink-list",
  HOME = "home",
  RECENT_RESOURCES = "recent-resources",
  FAVORITE_RESOURCES = "favorite-resources",
  EXA_LIST_HUB = "exa-list-hub",
  EXADB_CDB_DETAILS = "exadb-cdb-details",
  EXADB_PDB_DETAILS = "exadb-pdb-details",
  EXADB_CREATE = "exadb-create",
  EXAINFRA_CREATE = "exainfra-create",
  EXAINFRA_DETAILS = "exainfra-details",
  MCVCN_CREATE = "mcvcn-create",
  MCVCN_LIST = "mcvcn-list",
  MCVCN_DETAILS = "mcvcn-details",
  NETLNK_LIST = "netlnk-list",
  NETLNK_CREATE = "netlnk-create",
  NETLNK_DETAILS = "netlnk-details",
  VMCLUSTER_CREATE = "vmcluster-create",
  VMCLUSTER_DETAILS = "vmcluster-details",
  VMDB_CREATE = "vmdb-create",
  VMDB_LIST_HUB = "vmdb-list-hub",
  VMDB_CDB_DETAILS = "vmdb-cdb-details",
  VMDB_PDB_DETAILS = "vmdb-pdb-details",
  VMDB_SYSTEM_DETAILS = "vmdb-system-details",
  APEX_LIST = "apex-list",
  ADBS_CREATE = "adbs-create",
  ADBS_DETAILS = "adbs-details",
  ADB_LIST_HUB = "adb-list-hub",
  MYSQL_LIST = "mysql-list",
  MYSQL_CREATE = "mysql-create",
  MYSQL_DETAILS = "mysql-details",
  MYSQL_BACKUP_DETAILS = "mysql-backup-details",
  DEPLOYMENT_LIST = "deployment-list",
  DEPLOYMENT_DETAILS = "deployment-details",
  CREATE_A_RESOURCE = "create-a-resource",
  BILLING = "billing"
}

export enum DetailsPanelId {
  SUBSCRIPTIONS = "subscriptions",
  APPEARANCE = "appearance",
  LANGUAGE = "language",
  SIGNOUT = "signout",
  OVERVIEW = "overview",
  ACTIVITY = "activity",
  TAGS = "tags",
  INFRASTRUCTURE = "infrastructure",
  INFRASTRUCTURES = "infrastructures",
  VMCLUSTER = "vmcluster",
  VMCLUSTERS = "vmclusters",
  DBHOME = "dbhome",
  DATABASE = "database",
  DATABASES = "databases",
  ADBS_DATABASES = "adbsdatabases",
  DBSYSTEM = "dbsystem",
  DBSYSTEMS = "dbsystems",
  PLUGGABLEDATABASES = "pluggabledatabases",
  DATABASENODES = "databasenodes",
  DATABASEHOMES = "databasehomes",
  CONTAINERDATABASES = "containerdatabases",
  BACKUPS = "backups",
  CONFIGURATION = "configuration",
  SECURITY = "security",
  NETWORKING = "networking",
  MANAGEMENT = "management",
  CONNECT = "connect",
  HEATWAVE = "heatwave",
  ATTACHED_DATABASES = "attacheddatabases",
  RECENTLY_CREATED = "recently-created",
}

/**
 * IDs for panels used as read only
 */
export enum InfoPanelId {
  HELP = "help",
  NOTIFICATIONS = "notifications",
  FEEDBACK = "feedback",
  DEPLOYMENT_ERRORS = "deployment-errors",
  DEPLOYMENT_OPERATION_DETAILS = "deployment-operation-details",
  DBHOME = "dbhome",
  DBNODE = "dbnode",
  INSTRUCTIONS_ADMIN_ROLES = "instructions-admin-roles",
  INSTRUCTIONS_SUBSCRIPTION_ROLES = "instructions-subscription-roles",
  CLOUDLINK_LOCATIONS = "cloudlink-locations",
}

/**
 * IDs for forms supporting inline modifications and saving
 */
export enum InlineFormId {
  TAGS = "tags",
  SETTING_APPEARANCE = "setting-appearance",
  SETTING_LANGUAGE = "setting-language",
  SETTING_SUBSCRIPTIONS = "setting-subscriptions",
  SETTING_SIGNOUT = "setting-signout",
}

export const PageRegistrationConfig: { [key: string]: NavigationEntry[] } = {
  [PageId.ERROR]: [
    {
      key: PageId.ERROR,
      type: NavigationEntryType.REGULAR_PAGE,
    },
  ],
  [PageId.SUPPORT_CREATE]: [{ key: PageId.SUPPORT_CREATE, type: NavigationEntryType.REGULAR_PAGE }],
  [PageId.SUPPORT]: [
    {
      key: PageId.SUPPORT,
      type: NavigationEntryType.REGULAR_PAGE,
      path: SUPPORT_PATH,
    },
  ],
  [PageId.SUPPORT_DETAILS]: [
    {
      key: `${PageId.SUPPORT_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
  ],
  [PageId.CLOUDLINK_LIST]: [
    {
      key: PageId.CLOUDLINK_LIST,
      type: NavigationEntryType.REGULAR_PAGE,
      path: CLOUDLINK_LIST_PATH,
    },
  ],
  [PageId.SETTINGS]: [
    {
      key: `${PageId.SETTINGS}-${DetailsPanelId.SUBSCRIPTIONS}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.SUBSCRIPTIONS,
    },
    {
      key: `${PageId.SETTINGS}-${DetailsPanelId.APPEARANCE}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.APPEARANCE,
    },
    {
      key: `${PageId.SETTINGS}-${DetailsPanelId.LANGUAGE}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.LANGUAGE,
    },
    {
      key: `${PageId.SETTINGS}-${DetailsPanelId.SIGNOUT}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.SIGNOUT,
    },
  ],
  [PageId.HOME]: [{ key: PageId.HOME, type: NavigationEntryType.ROOT_PAGE, path: HOME_ROUTE }],
  [PageId.RECENT_RESOURCES]: [
    {
      key: PageId.RECENT_RESOURCES,
      type: NavigationEntryType.REGULAR_PAGE,
      path: RECENT_RESOURCES_PATH,
    },
  ],
  [PageId.FAVORITE_RESOURCES]: [
    {
      key: PageId.FAVORITE_RESOURCES,
      type: NavigationEntryType.REGULAR_PAGE,
      path: FAVORITE_RESOURCES_PATH,
    },
  ],
  [PageId.EXADB_CREATE]: [{ key: PageId.EXADB_CREATE, type: NavigationEntryType.REGULAR_PAGE }],
  [PageId.VMCLUSTER_CREATE]: [{ key: PageId.VMCLUSTER_CREATE, type: NavigationEntryType.REGULAR_PAGE }],
  [PageId.EXAINFRA_CREATE]: [{ key: PageId.EXAINFRA_CREATE, type: NavigationEntryType.REGULAR_PAGE }],
  [PageId.EXA_LIST_HUB]: [
    {
      key: `${PageId.EXA_LIST_HUB}-${DetailsPanelId.DATABASES}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.DATABASES,
    },
    {
      key: `${PageId.EXA_LIST_HUB}-${DetailsPanelId.VMCLUSTERS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.VMCLUSTERS,
    },
    {
      key: `${PageId.EXA_LIST_HUB}-${DetailsPanelId.INFRASTRUCTURES}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.INFRASTRUCTURES,
    },
  ],
  [PageId.EXADB_CDB_DETAILS]: [
    {
      key: `${PageId.EXADB_CDB_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.EXADB_CDB_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
    {
      key: `${PageId.EXADB_CDB_DETAILS}-${DetailsPanelId.CONNECT}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONNECT,
    },
    {
      key: `${PageId.EXADB_CDB_DETAILS}-${DetailsPanelId.CONFIGURATION}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONFIGURATION,
    },
    {
      key: `${PageId.EXADB_CDB_DETAILS}-${DetailsPanelId.MANAGEMENT}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.MANAGEMENT,
    },
    {
      key: `${PageId.EXADB_CDB_DETAILS}-${DetailsPanelId.BACKUPS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.BACKUPS,
    },
    {
      key: `${PageId.EXADB_CDB_DETAILS}-${DetailsPanelId.PLUGGABLEDATABASES}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.PLUGGABLEDATABASES,
    },
  ],
  [PageId.EXADB_PDB_DETAILS]: [
    {
      key: `${PageId.EXADB_PDB_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.EXADB_PDB_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
    {
      key: `${PageId.EXADB_PDB_DETAILS}-${DetailsPanelId.CONNECT}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONNECT,
    },
  ],
  [PageId.VMCLUSTER_DETAILS]: [
    {
      key: `${PageId.VMCLUSTER_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.VMCLUSTER_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
    {
      key: `${PageId.VMCLUSTER_DETAILS}-${DetailsPanelId.CONFIGURATION}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONFIGURATION,
    },
    {
      key: `${PageId.VMCLUSTER_DETAILS}-${DetailsPanelId.NETWORKING}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.NETWORKING,
    },
    {
      key: `${PageId.VMCLUSTER_DETAILS}-${DetailsPanelId.SECURITY}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.SECURITY,
    },
    {
      key: `${PageId.VMCLUSTER_DETAILS}-${DetailsPanelId.DATABASENODES}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.DATABASENODES,
    },
    {
      key: `${PageId.VMCLUSTER_DETAILS}-${DetailsPanelId.DATABASEHOMES}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.DATABASEHOMES,
    },
    {
      key: `${PageId.VMCLUSTER_DETAILS}-${DetailsPanelId.CONTAINERDATABASES}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONTAINERDATABASES,
    },
  ],
  [PageId.EXAINFRA_DETAILS]: [
    {
      key: `${PageId.EXAINFRA_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.EXAINFRA_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
    {
      key: `${PageId.EXAINFRA_DETAILS}-${DetailsPanelId.CONFIGURATION}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONFIGURATION,
    },
    {
      key: `${PageId.EXAINFRA_DETAILS}-${DetailsPanelId.VMCLUSTERS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.VMCLUSTERS,
    },
  ],
  [PageId.MCVCN_CREATE]: [{ key: PageId.MCVCN_CREATE, type: NavigationEntryType.REGULAR_PAGE }],
  [PageId.MCVCN_LIST]: [
    {
      key: PageId.MCVCN_LIST,
      type: NavigationEntryType.REGULAR_PAGE,
      path: MCVCNS_RESOURCE_TYPE_PATH,
    },
  ],
  [PageId.MCVCN_DETAILS]: [
    {
      key: `${PageId.MCVCN_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.MCVCN_DETAILS}-${DetailsPanelId.CONFIGURATION}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONFIGURATION,
    },
    {
      key: `${PageId.MCVCN_DETAILS}-${DetailsPanelId.ATTACHED_DATABASES}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.ATTACHED_DATABASES,
    },
  ],
  [PageId.NETLNK_CREATE]: [{ key: PageId.NETLNK_CREATE, type: NavigationEntryType.REGULAR_PAGE }],
  [PageId.NETLNK_LIST]: [
    {
      key: PageId.NETLNK_LIST,
      type: NavigationEntryType.REGULAR_PAGE,
      path: NETLINKS_RESOURCE_TYPE_PATH,
    },
  ],
  [PageId.NETLNK_DETAILS]: [
    {
      key: `${PageId.NETLNK_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.NETLNK_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
  ],
  [PageId.DEPLOYMENT_LIST]: [
    {
      key: PageId.DEPLOYMENT_LIST,
      type: NavigationEntryType.REGULAR_PAGE,
      path: DEPLOYMENTS_RESOURCE_TYPE_PATH,
    },
  ],
  [PageId.DEPLOYMENT_DETAILS]: [
    {
      key: `${PageId.DEPLOYMENT_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
  ],
  [PageId.CREATE_A_RESOURCE]: [
    // {
    //   key: `${PageId.CREATE_A_RESOURCE}-${DetailsPanelId.RECENTLY_CREATED}`,
    //   type: NavigationEntryType.DETAILS_PANEL,
    //   panelPath: DetailsPanelId.RECENTLY_CREATED,
    // },
    {
      key: `${PageId.CREATE_A_RESOURCE}-${DetailsPanelId.DATABASES}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.DATABASES,
    },
    {
      key: `${PageId.CREATE_A_RESOURCE}-${DetailsPanelId.NETWORKING}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.NETWORKING,
    },
  ],
  [PageId.VMDB_CREATE]: [{ key: PageId.VMDB_CREATE, type: NavigationEntryType.REGULAR_PAGE }],
  [PageId.VMDB_LIST_HUB]: [
    {
      key: `${PageId.VMDB_LIST_HUB}-${DetailsPanelId.DATABASES}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.DATABASES,
    },
    {
      key: `${PageId.VMDB_LIST_HUB}-${DetailsPanelId.DBSYSTEMS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.DBSYSTEMS,
    },
  ],
  [PageId.VMDB_CDB_DETAILS]: [
    {
      key: `${PageId.VMDB_CDB_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.VMDB_CDB_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
    {
      key: `${PageId.VMDB_CDB_DETAILS}-${DetailsPanelId.CONNECT}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONNECT,
    },
    {
      key: `${PageId.VMDB_CDB_DETAILS}-${DetailsPanelId.CONFIGURATION}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONFIGURATION,
    },
    {
      key: `${PageId.VMDB_CDB_DETAILS}-${DetailsPanelId.MANAGEMENT}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.MANAGEMENT,
    },
    {
      key: `${PageId.VMDB_CDB_DETAILS}-${DetailsPanelId.BACKUPS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.BACKUPS,
    },
    {
      key: `${PageId.VMDB_CDB_DETAILS}-${DetailsPanelId.PLUGGABLEDATABASES}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.PLUGGABLEDATABASES,
    },
  ],
  [PageId.VMDB_PDB_DETAILS]: [
    {
      key: `${PageId.VMDB_PDB_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.VMDB_PDB_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
    {
      key: `${PageId.VMDB_PDB_DETAILS}-${DetailsPanelId.CONNECT}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONNECT,
    },
  ],
  [PageId.VMDB_SYSTEM_DETAILS]: [
    {
      key: `${PageId.VMDB_SYSTEM_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.VMDB_SYSTEM_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
    {
      key: `${PageId.VMDB_SYSTEM_DETAILS}-${DetailsPanelId.CONFIGURATION}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONFIGURATION,
    },
    {
      key: `${PageId.VMDB_SYSTEM_DETAILS}-${DetailsPanelId.NETWORKING}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.NETWORKING,
    },
    {
      key: `${PageId.VMDB_SYSTEM_DETAILS}-${DetailsPanelId.SECURITY}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.SECURITY,
    },
    {
      key: `${PageId.VMDB_SYSTEM_DETAILS}-${DetailsPanelId.DATABASENODES}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.DATABASENODES,
    },
    {
      key: `${PageId.VMDB_SYSTEM_DETAILS}-${DetailsPanelId.DATABASEHOMES}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.DATABASEHOMES,
    },
    {
      key: `${PageId.VMDB_SYSTEM_DETAILS}-${DetailsPanelId.CONTAINERDATABASES}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONTAINERDATABASES,
    },
  ],
  [PageId.APEX_LIST]: [{ key: PageId.APEX_LIST, type: NavigationEntryType.REGULAR_PAGE, path: APEX_LIST_ROUTE }],
  [PageId.ADB_LIST_HUB]: [
    {
      key: `${PageId.ADB_LIST_HUB}-${DetailsPanelId.ADBS_DATABASES}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.ADBS_DATABASES,
    },
  ],
  [PageId.ADBS_CREATE]: [{ key: PageId.ADBS_CREATE, type: NavigationEntryType.REGULAR_PAGE }],
  [PageId.ADBS_DETAILS]: [
    {
      key: `${PageId.ADBS_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.ADBS_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
    {
      key: `${PageId.ADBS_DETAILS}-${DetailsPanelId.CONNECT}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONNECT,
    },
    {
      key: `${PageId.ADBS_DETAILS}-${DetailsPanelId.CONFIGURATION}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONFIGURATION,
    },
    {
      key: `${PageId.ADBS_DETAILS}-${DetailsPanelId.NETWORKING}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.NETWORKING,
    },
    {
      key: `${PageId.ADBS_DETAILS}-${DetailsPanelId.BACKUPS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.BACKUPS,
    },
  ],
  [PageId.MYSQL_CREATE]: [{ key: PageId.MYSQL_CREATE, type: NavigationEntryType.REGULAR_PAGE }],
  [PageId.MYSQL_LIST]: [{ key: PageId.MYSQL_LIST, type: NavigationEntryType.REGULAR_PAGE, path: MYSQL_LIST_ROUTE }],
  [PageId.MYSQL_DETAILS]: [
    {
      key: `${PageId.MYSQL_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.MYSQL_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
    {
      key: `${PageId.MYSQL_DETAILS}-${DetailsPanelId.MANAGEMENT}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.MANAGEMENT,
    },
    {
      key: `${PageId.MYSQL_DETAILS}-${DetailsPanelId.HEATWAVE}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.HEATWAVE,
    },
    {
      key: `${PageId.MYSQL_DETAILS}-${DetailsPanelId.NETWORKING}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.NETWORKING,
    },
    {
      key: `${PageId.MYSQL_DETAILS}-${DetailsPanelId.BACKUPS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.BACKUPS,
    },
    {
      key: `${PageId.MYSQL_DETAILS}-${DetailsPanelId.CONFIGURATION}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONFIGURATION,
    },
    {
      key: `${PageId.MYSQL_DETAILS}-${DetailsPanelId.CONNECT}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.CONNECT,
    },
  ],
  [PageId.MYSQL_BACKUP_DETAILS]: [
    {
      key: `${PageId.MYSQL_BACKUP_DETAILS}-${DetailsPanelId.OVERVIEW}`,
      type: NavigationEntryType.DETAILS_ROOT_PANEL,
      panelPath: DetailsPanelId.OVERVIEW,
    },
    {
      key: `${PageId.MYSQL_BACKUP_DETAILS}-${DetailsPanelId.TAGS}`,
      type: NavigationEntryType.DETAILS_PANEL,
      panelPath: DetailsPanelId.TAGS,
    },
  ],
  [PageId.BILLING]: [
    {
      key: PageId.BILLING,
      type: NavigationEntryType.REGULAR_PAGE,
      path: BILLING_PATH,
    },
  ],
};
