import { BookmarkablePage, FullPagePanel, useNavigation } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { APEX_LIST_ROUTE, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { SvgIconIds } from "../../constants/uiConstants";
import { AdbsList } from "../AdbsListPage/AdbsList";

const registrationIds = PageRegistrationConfig[PageId.APEX_LIST].map(config => config.key);

export const ApexListPage = (): JSX.Element => {
  const { back, navigateToSelf } = useNavigation(ConsoleContext);

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(APEX_LIST_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.listApex.titles.short()}
    >
      <FullPagePanel
        title={Messages.listApex.titles.long()}
        icon={SvgIconIds.apexSvg}
        onClose={back}
        isOpen
      >
        <AdbsList isApex />
      </FullPagePanel>
    </BookmarkablePage>
  );
};
