import { BookmarkablePage, FullPagePanel, MessageType, PanelMessage, useNavigation } from "o4a-react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { MYSQL_LIST_ROUTE, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { SvgIconIds } from "../../constants/uiConstants";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { useRoles } from "../../hooks/useRoles";
import { MysqlList } from "./MysqlList";

const registrationIds = PageRegistrationConfig[PageId.MYSQL_LIST].map(config => config.key);

export const MysqlListPage = (): JSX.Element => {
  const { back, navigateToSelf } = useNavigation(ConsoleContext);
  const { pathname } = useLocation();
  const filterState = React.useContext<FilterState>(FilterStateContext);

  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const disableList = !isActionAllowed(RoleBasedAction.LIST_MYSQL_DB);
  const requiredRole = actionRequiredRoles(RoleBasedAction.LIST_MYSQL_DB)[0]?.displayName;

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(MYSQL_LIST_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (filterState.pillFilterStateValue.listId !== pathname) {
      filterState.setFilterStateValue({ filterValues: undefined, listId: undefined, defaultPills: undefined });
      filterState.setFilterTextValue(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.listMysql.titles.short()}
    >
      <FullPagePanel
        title={Messages.listMysql.titles.long()}
        icon={SvgIconIds.mysqlSvg}
        message={disableList
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForList(requiredRole),
          } as PanelMessage
          : undefined}
        onClose={back}
        isOpen
      >
        <MysqlList disabled={disableList} />
      </FullPagePanel>
    </BookmarkablePage>
  );
};
