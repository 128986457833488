import { FormattedString } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { NetworkAddressCollection } from "../../components/NetworkAddressCollection/NetworkAddressCollection";
import { networkCidrSizingUrl } from "../../constants/docConstants";

export const GROUP_CONFIG = "config";

export enum Fields {
  CidrBlocks = "cidrBlocks",
}

export enum FieldTestIds {
  CidrBlocks = "cidrBlocks",
}

const minCidrBlocks = 1;
const maxCidrBlocks = 1; // due to issues, mcvcn only supports 1 cidr for now

export const ConfigTabContent = (): JSX.Element => (
  <NetworkAddressCollection
    required
    testId={FieldTestIds.CidrBlocks}
    label={Messages.labels.ociCidrBlocks()}
    tooltip={FormattedString(
      { inputText: Messages.hints.toolTipCidr(networkCidrSizingUrl) },
    ) as unknown as string}
    fieldName={Fields.CidrBlocks}
    maxRows={maxCidrBlocks}
    minRows={minCidrBlocks}
  />
);
