import {
  AsyncFieldError,
  AsyncGenericError,
  AsyncValidationError,
  FormErrors,
  FormValues,
  getOverlapCidrs,
  isValidIpAddress,
  NetworkAddressValidationConfig,
  SelectOption,
  validateNetworkAddress,
  validateString,
} from "o4a-react";
import { NormalError } from "savant-connector";
import * as Messages from "../codegen/Messages";
import { minCidrPrefix } from "./resourceHelper";

export interface PreflightError extends NormalError {
  body: {
    message: string;
    code?: string;
    messageArguments: { [key: string]: string };
  }
}

export const errorMap2AsyncValidationErrors = (
  errorMap: { [key: string]: string },
): AsyncValidationError[] => Object.entries(errorMap).map(errorEntry => {
  const [key, value] = errorEntry;
  if (key.startsWith("generic.")) {
    return value as AsyncGenericError;
  }
  return {
    field: key,
    error: value,
  } as AsyncFieldError;
});

export const supportSummaryMaxLength = 85;
export const supportDescriptionMaxLength = 1800;

export const adbsDbNameMaxLength = 30;
export const adbsDbHostnamePrefixMaxLength = 63;
export const adbsAdminPwdMinLength = 12;
export const adbsAdminPwdMaxLength = 30;
export const adbsWalletPwdMinLength = 8;
export const adbsWalletPwdMaxLength = 60;
export const exaDbNameMaxLength = 8;
export const nameCharMinLength = 2;
export const nameCharMaxLength = 64;

export const vmClusterHostnameMaxLength = 12;

export const dbNameMaxLength = 8;
export const pdbNameMaxLength = 30;

export const mdsAdminPwdMaxLength = 32;
export const mdsAdminPwdMinLength = 8;
export const mdsAdminUserNameMaxLength = 32;
export const mdsAdminUserNameMinLength = 1;
export const mdsDatabaseDescriptionMaxLength = 400;
export const mdsDbNameMaxLength = 255;
export const mdsDbNameMinLength = 1;
export const mdsHeatwaveMinNode = 1;
export const mdsHeatwaveMaxNode = 64;
export const mdsHostnameMaxLength = 60;
export const mdsHostnameMinLength = 1;
export const mdsMinVersionSupportingPitrPolicy = "8.0.29";
export const baseDbHostnamePrefixMaxLength = 30;
export const mdsDescriptionMaxLengthExclusive = 400;

export const startWithLetterRegex = /^[a-zA-Z]/;
export const startWithAlphaNumericRegex = /^[a-zA-Z0-9]/;
export const alphaNumericRegex = /^[a-zA-Z0-9]*$/;
export const alphaNumericUnderscoreRegex = /^[a-zA-Z0-9_]*$/;
export const alphaNumericHyphenRegex = /^[a-zA-Z0-9-]*$/;
export const WalletPasswordRegex = /(.*[a-zA-z]+.*[\d]+.*)|(.*[\d]+.*[a-zA-z]+.*)/;
export const nameValidationRegex = /^[a-zA-Z0-9]+([a-zA-Z0-9_.-])*(\w+$)/;

export const commonPwdMinLength = 9;
export const commonPwdMaxLength = 30;
export const networkLinkNameMinLength = 1;
export const networkLinkNameMaxLength = 255;

const PasswordRegex = /(?=(.*[A-Z]){2,})(?=(.*[a-z]){2,})(?=(.*[_#-]){2,})(?=(.*[0-9]){2,})^[\w#-]{8,}$/;
const dbNameRegex = /^[A-Za-z][A-Za-z0-9]*$/;
const dbPasswordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*["])^[^ ]+$/;
const dbPasswordReservedWords = ["admin"];
const mdsHostnameRegex = /(?=^(?![0-9]+$)(?=.*[^-]$)(?!-)[a-zA-Z0-9-]*$)/;

// "224.0.0.0" - "239.255.255.255"
export const classDReservedIPAddressRange = "224.0.0.0/4";
// "240.0.0.0" - "255.255.255.255"
export const classEReservedIPAddressRange = "240.0.0.0/4";
// "169.254.0.0" - "169.254.255.255",
export const ociReservedIPAddressRange = "169.254.0.0/16";

export const mdsHostIdStart = 2;
export const mdsHostIdEnd = 254;

export const validateMdsBackupName = (value: string | undefined) : string[] | undefined => {
  const errors: string[] = [];
  if (!value || (value?.length < nameCharMinLength || value?.length > nameCharMaxLength)) {
    errors.push(Messages.validation.nameChars(nameCharMinLength.toString(), nameCharMaxLength.toString()));
  }
  if (!value || (value && !nameValidationRegex.test(value))) {
    errors.push(Messages.validation.nameValidation());
  }
  return errors.length > 0 ? errors : undefined;
};

export const validateAdbDBName = (value: string | undefined) : string[] | undefined => {
  const errors: string[] = [];
  if (!value || (value && !dbNameRegex.test(value))) {
    errors.push(Messages.validation.dbNameValidation());
  }
  if (!value || (value?.length > adbsDbNameMaxLength)) {
    errors.push(Messages.validation.valueMaxLen(adbsDbNameMaxLength.toString()));
  }
  return errors.length > 0 ? errors : undefined;
};

export const validateAdbPassword = (value: string | undefined): string[] | undefined => validateString(
  value,
  {
    lengthRange: {
      min: adbsAdminPwdMinLength,
      max: adbsAdminPwdMaxLength,
    },
    regExpValidation: {
      regExp: dbPasswordRegex,
      errorMessage: Messages.validation.dbPasswordValidation(),
    },
    reservedWordValidation: { words: dbPasswordReservedWords },
  },
);

const validateCommonPassword = (value: string | undefined): string[] | undefined => {
  const errors: string[] = [];
  if (!value || (value && !PasswordRegex.test(value))) {
    errors.push(Messages.validation.passwordValidation());
  }
  if (!value || (value && (value?.length < commonPwdMinLength || value?.length > commonPwdMaxLength))) {
    errors.push(Messages.validation.inputRange(
      commonPwdMinLength.toString(),
      commonPwdMaxLength.toString(),
    ));
  }
  return errors.length > 0 ? errors : undefined;
};

export const validateExaDbPassword = validateCommonPassword;
export const validateVmDbPassword = validateExaDbPassword;
export const validateTdePassword = validateExaDbPassword;
export const validatePdbPassword = validateExaDbPassword;

const validateMdsPasswordRegexHelper = (value: string) : boolean => new RegExp(
  [
    "(?=^[a-zA-Z0-9!@#$%^&*()~_+\\-=\\[\\]{};':\\\"\\\\|,.<>\\/?]{1,}$)",
    "(?=(.*[A-Z]){1,})",
    "(?=(.*[a-z]){1,})",
    "(?=(.*[\\d]){1,})",
    "(?=(.*[!@#$%^&*()~_+\\-=\\[\\]{};':\\\"\\\\|,.<>\\/?]){1,})",
  ].join(""),
).test(value);

export const validateMdsPassword = (value: string | undefined) : string[] | undefined => {
  const errors: string[] = [];
  if (!value || !validateMdsPasswordRegexHelper(value)) {
    errors.push(Messages.validation.passwordValidationMysql());
  }
  if (!value || value.length < mdsAdminPwdMinLength || value.length > mdsAdminPwdMaxLength) {
    errors.push(Messages.validation.inputRange(
      mdsAdminPwdMinLength.toString(),
      mdsAdminPwdMaxLength.toString(),
    ));
  }
  return errors.length > 0 ? errors : undefined;
};

export const validateMdsHostName = (value: string | undefined) : string[] | undefined => {
  const errors: string[] = [];

  if (value) {
    if (!mdsHostnameRegex.test(value)) {
      errors.push(Messages.validation.mdsHostnameValidation());
    }

    if (
      value.length < mdsHostnameMinLength
      || value.length > mdsHostnameMaxLength
    ) {
      errors.push(Messages.validation.inputRange(
        mdsHostnameMinLength.toString(),
        mdsHostnameMaxLength.toString(),
      ));
    }
  }

  return errors.length > 0 ? errors : undefined;
};

export const MDS_RESERVED_USERNAMES = [
  "ocirpl", // OCI_RPL_USERNAME
  "ociadmin", // OCI_ADMIN
  "administrator", // ADMINISTRATOR_USERNAME
  "mysql.sys", // MYSQL_SYS_USERNAME
  "mysql.session", // MYSQL_SESSION_USERNAME
  "mysql.infoschema", // MYSQL_INFOSCHEMA_USERNAME
];

export const MDS_ILLEGAL_USERNAME_CHARS = [
  "'",
  "`",
  "\"",
  "\n", // This must always be the last char in this array, otherwise the string method fails
];

export const buildMdsReservedNamesString = (): string => MDS_RESERVED_USERNAMES.join(", ");

export const buildMdsIllegalCharsString = (): string => {
  const badChars = MDS_ILLEGAL_USERNAME_CHARS.join(", ");
  // It was requested to remove the last ", \n" chars from the string since they don't show properly on UI.
  // Note that this mandates that \n must always be last in the array
  return badChars.substring(0, badChars.length - 3);
};

export const validateMdsUsernameCharacters = (username: string): boolean => {
  for (let i = 0; i < MDS_ILLEGAL_USERNAME_CHARS.length; i++) {
    if (username.indexOf(MDS_ILLEGAL_USERNAME_CHARS[i]) >= 0) {
      return false;
    }
  }
  return true;
};

export const validateMdsReservedNames = (username: string): boolean => {
  for (let i = 0; i < MDS_RESERVED_USERNAMES.length; i++) {
    if (username.indexOf(MDS_RESERVED_USERNAMES[i]) >= 0) {
      return false;
    }
  }
  return true;
};

export const validateMdsUserName = (value: string | undefined): string[] | undefined => {
  const errors: string[] = [];

  if (!value || value?.length > mdsAdminUserNameMaxLength) {
    errors.push(Messages.validation.mdsAdminUserNameLengthValidation(
      mdsAdminUserNameMinLength.toString(),
      mdsAdminUserNameMaxLength.toString(),
    ));
  }
  if (!value || !validateMdsUsernameCharacters(value)) {
    errors.push(Messages.validation.mdsAdminUserNameCharactersNotAllowedValidation(
      buildMdsIllegalCharsString(),
    ));
  }

  if (!value || !validateMdsReservedNames(value)) {
    // eslint-disable-next-line max-len
    errors.push(Messages.validation.mdsAdminUserNameReservedNameValidation(MDS_RESERVED_USERNAMES.join(", ")));
  }

  return errors.length > 0 ? errors : undefined;
};

export const validateTagInvalidChars = (value: string): string[] | undefined => {
  const errors: string[] = [];

  if (value) {
    for (let i = 0; i < value.length; i++) {
      if (value.charAt(i) === " " || value.charAt(i) === "." || value.charCodeAt(i) > 127) {
        errors.push(Messages.validation.tagKeyInvalidChars());
        break;
      }
    }
  }
  return errors.length > 0 ? errors : undefined;
};

export const validateTagRow = (resourceOptions?: SelectOption[]) => (
  builderValues: { name?: string; value?: string; resources?: SelectOption[] }[],
  rowNdx: number,
): FormErrors| undefined => {
  const formErrors: FormErrors = { name: [], resources: [] };

  const rowValue = builderValues.find((_value, ndx) => ndx === rowNdx);

  if (!rowValue?.name) {
    if (rowValue?.value) {
      formErrors.name?.push(Messages.validation.tagKeyRequiredWithValue());
    } else {
      formErrors.name?.push(Messages.validation.tagKeyRequiredNoValue());
    }
  } else {
    formErrors.name?.push(...validateTagInvalidChars(rowValue.name) ?? []);

    const nameArr = builderValues.map(row => row.name?.toLowerCase());
    const findDuplicates: string[] = nameArr.filter((name, ndx) => name && nameArr.indexOf(name) !== ndx) as string[];

    if (findDuplicates.length) {
      const uniqueDuplicates = [...new Set(findDuplicates)];
      const isRowNameDuplicate = uniqueDuplicates.includes(rowValue?.name?.toLowerCase());
      if (isRowNameDuplicate) formErrors.name?.push(Messages.validation.tagKeyUnique(rowValue?.name));
    }
  }

  if (resourceOptions?.length && !rowValue?.resources?.length) {
    formErrors.resources?.push(Messages.validation.tagResourceTypeRequired());
  }

  return formErrors;
};

const mdsNumberConversion = (version: string): number => +version.replace(/./g, "");

export const validateMdsPitrPolicy = (version: string, backupEnabled: boolean): string[] | undefined => {
  const errors: string[] = [];

  const versionSupportsPitr = mdsNumberConversion(version) >= mdsNumberConversion(mdsMinVersionSupportingPitrPolicy);

  if (backupEnabled && !versionSupportsPitr) {
    errors.push(Messages.validation.versionValidationMysql(mdsMinVersionSupportingPitrPolicy));
  }

  return errors.length > 0 ? errors : undefined;
};

/**
 * Checks if the ip address provided is contained within the list of addresses
 * @param ipAddress Expects a valid IP address
 * @param addresses CIDRs or IP addresses
 * @returns
 */
export const validateIpAddressWithinRanges = (ipAddress: string, addresses: string[]): string[] | undefined => {
  const errors: string[] = [];

  if (isValidIpAddress(ipAddress)) {
    const overlaps = getOverlapCidrs([ipAddress, ...addresses]);

    if (overlaps.length === 0) {
      const acceptableAddresses = addresses.join(", ");

      errors.push(
        addresses.length > 1
          ? Messages.validation.addressWithinRanges(acceptableAddresses)
          : Messages.validation.addressWithinRange(acceptableAddresses),
      );
    }
  }

  return errors.length > 0 ? errors : undefined;
};

export const validateMdsDescription = (value: string | undefined): string[] | undefined => {
  if (!value || (value && value.length >= mdsDescriptionMaxLengthExclusive)) {
    return [Messages.validation.mdsDescriptionValidation()];
  }
  return undefined;
};

/**
 * For when SimpleBuilder is used to get ip addresses/cidrs
 */
export const validateOverlappingAddresses = (
  fieldName: string,
) => (values: FormValues[] | undefined): string[] | undefined => {
  const errors: string[] = [];
  if (values?.length) {
    const addresses = values.map(ele => ele?.[fieldName]);
    const overlaps = getOverlapCidrs(addresses);
    overlaps.forEach(overlap => {
      errors.push(Messages.validation.addressOverlap(overlap?.cidr1, overlap?.cidr2));
    });
  }
  return errors.length ? errors : undefined;
};

export const validateNetworkLinkAddressSpace = (
  maxCidrPrefix: number,
  excludeAddresses?: string[],
) => (value: string | undefined): string[] | undefined => {
  const config = {
    containsCidrBlock: true,
    containsIpAddress: false,
    excludeAddresses,
    reservedAddresses: [
      classDReservedIPAddressRange,
      classEReservedIPAddressRange,
      ociReservedIPAddressRange,
    ],
    prefixRange: {
      min: minCidrPrefix,
      max: maxCidrPrefix,
      validationMessage: Messages.validation.invalidCidrPrefixRange(),
    },
  };

  return validateNetworkAddress(value, config);
};

export const validateRowNetworkLinkAddressSpace = (
  maxCidrPrefix: number,
  fieldName: string,
  excludeAddresses?: string[],
) => (builderValues: FormValues[], rowNdx: number): FormErrors => {
  const value = builderValues[rowNdx][fieldName];
  const validator = validateNetworkLinkAddressSpace(maxCidrPrefix, excludeAddresses);
  const errors = validator(value);
  return { [fieldName]: errors };
};

export const validateAdbsNetworkAddressRow = (
  fieldName: string,
  config?: NetworkAddressValidationConfig,
) => (builderValues: FormValues[], rowNdx: number): FormErrors => {
  const value = builderValues[rowNdx][fieldName];
  const errors = validateNetworkAddress(value, config);
  return { [fieldName]: errors };
};

export const validateNetworkLinkName = (value: string | undefined) : string[] | undefined => {
  const errors: string[] = [];
  if (!value || (value?.length < networkLinkNameMinLength || value?.length > networkLinkNameMaxLength)) {
    errors.push(Messages.validation.nameChars(
      networkLinkNameMinLength.toString(),
      networkLinkNameMaxLength.toString(),
    ));
  }
  if (!value || (value && (!startWithAlphaNumericRegex.test(value) || !alphaNumericUnderscoreRegex.test(value)))) {
    errors.push(Messages.validation.networkLinkNameValidation());
  }
  return errors.length > 0 ? errors : undefined;
};

export const validateMcvcnName = (value: string | undefined): string[] | undefined => validateString(
  value,
  {
    lengthRange: { min: nameCharMinLength, max: nameCharMaxLength },
    regExpValidation: {
      regExp: nameValidationRegex,
      errorMessage: Messages.validation.nameValidation(),
    },
  },
);

export const validateSupportSummary = (value: string | undefined) : string[] | undefined => validateString(
  value,
  { lengthRange: { max: supportSummaryMaxLength } },
);

export const validateSupportDescription = (value: string | undefined) : string[] | undefined => validateString(
  value,
  { lengthRange: { max: supportDescriptionMaxLength } },
);
