import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent, RadioGroupOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  Fields as BackupFields,
  MysqlBackupCreatePanel,
} from "../../components/MysqlBackupCreatePanel/MysqlBackupCreatePanel";
import { CreateMdsBackupDetailsBackupTypeEnum } from "../../gen/clients/mchub-azure-api-client-mds";
import { parseId } from "../../helpers/idHelper";
import { getTagsMap, TagsInfoType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_BACKUP_CREATE_POLL_DELAY,
  MDS_BACKUP_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface MysqlCreateBackupProps extends OperationProps {
  dbSystemId: string;
}

const MysqlCreateBackup = (
  { location, dbSystemId, onExecute, onCancel }: MysqlCreateBackupProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup } = parseId(dbSystemId);
  const resourceGroupName = decodeURIComponent(resourceGroup || "");

  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const { invokeCall: invokeCreateBackup } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.createMdsBackup },
  );

  const onCreateBackup = (formValues: FormValues): void => {
    const name = getValue<string>(formValues, BackupFields.Name, InputFormGroup) || "";
    const description = getValue<string>(formValues, BackupFields.Description, InputFormGroup);
    const backupType = getValue<RadioGroupOption>(
      formValues,
      BackupFields.BackupType,
      InputFormGroup,
    )?.id as CreateMdsBackupDetailsBackupTypeEnum | undefined;
    const retention = getValue<string>(formValues, BackupFields.RetentionInDays, InputFormGroup);
    const retentionInDays = retention ? parseInt(retention, 10) : undefined;
    const tagsValue = getValue<TagsInfoType>(formValues, BackupFields.FreeformTags, InputFormGroup);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.createBackup(),
          message: Messages.notifications.inProgress.messages.createBackup(name),
        },
        success: {
          title: Messages.notifications.success.titles.createBackup(),
          message: Messages.notifications.success.messages.createBackup(name),
        },
        failure: {
          title: Messages.notifications.failure.titles.create(),
          message: Messages.notifications.failure.messages.createBackup(name),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_BACKUP_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            backupKey: name,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_BACKUP_CREATED_CHECK,
          delay: MDS_BACKUP_CREATE_POLL_DELAY,
          interval: MDS_BACKUP_CREATE_POLL_INTERVAL,
        },
      },
    };
    invokeCreateBackup({
      subscriptionId,
      resourceGroupName,
      createBackupDetails: {
        name,
        freeformTags: getTagsMap(tagsValue),
        dbSystemId,
        description,
        backupType,
        retentionInDays,
      },
    }, invokeOptions);
  };

  return (
    <MysqlBackupCreatePanel
      componentRef={setSidePanelRef}
      onSubmit={onCreateBackup}
      onClose={onCancel}
    />
  );
};

export const newMysqlCreateBackup = (
  props: MysqlCreateBackupProps,
): JSX.Element => (<MysqlCreateBackup {...props} />);
