import {
  BookmarkablePage,
  DetailView,
  DetailViewsPanel,
  GroupItem,
  MessageType,
  PanelMessage,
  TitleDetails,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useParams } from "react-router-dom";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { DetailsPanelId, PageId, PageRegistrationConfig, VMDB_LIST_HUB_ROUTE } from "../../constants/pluginConstants";
import { SvgIconIds } from "../../constants/uiConstants";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { useRoles } from "../../hooks/useRoles";
import { VmDbList } from "../VmDbListPage/VmDbList";
import { VmDbSystemList } from "../VmDbSystemListPage/VmDbSystemList";

const registrationIds = PageRegistrationConfig[PageId.VMDB_LIST_HUB].map(config => config.key);

const getViewId = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.DBSYSTEMS:
      return registrationIds[1];
    case DetailsPanelId.DATABASE:
    default:
      return registrationIds[0];
  }
};

const getIconName = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.DBSYSTEMS:
      return SvgIconIds.dbSystemSvg;
    case DetailsPanelId.DATABASES:
    default:
      return SvgIconIds.baseDbSvg;
  }
};

export const VmDbListHubPage = (): JSX.Element => {
  const { listId } = useParams();
  const filterState = React.useContext<FilterState>(FilterStateContext);

  const { back, navigateToSelf, navigateToPanel } = useNavigation(ConsoleContext);

  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const disableVmDbList = !isActionAllowed(RoleBasedAction.LIST_VMDB);
  const vmDbRequiredRole = actionRequiredRoles(RoleBasedAction.LIST_VMDB)[0]?.displayName;
  const disableVmDbSystemList = !isActionAllowed(RoleBasedAction.LIST_VMDB_SYSTEM);
  const vmDbSystemRequiredRole = actionRequiredRoles(RoleBasedAction.LIST_VMDB_SYSTEM)[0]?.displayName;

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.VMDB_LIST_HUB].find(
      config => config.panelPath === listId,
    );
    const path = `${VMDB_LIST_HUB_ROUTE}/${listId}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (filterState.pillFilterStateValue.listId !== listId) {
      filterState.setFilterStateValue({ filterValues: undefined, listId: undefined, defaultPills: undefined });
      filterState.setFilterTextValue(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuItemSelect = (id: string): void => {
    filterState.setFilterStateValue({ filterValues: undefined, listId: undefined, defaultPills: undefined });
    filterState.setFilterTextValue(undefined);
    navigateToPanel(id);
  };

  const detailViews: DetailView[] = [
    {
      id: registrationIds[0],
      content: (<VmDbList disabled={disableVmDbList} />),
    },
    {
      id: registrationIds[1],
      content: (<VmDbSystemList disabled={disableVmDbSystemList} />),
    },
  ];

  const groupItems: GroupItem[] = [
    {
      heading: Messages.labels.vmDatabase(),
      items: [
        { icon: getIconName(DetailsPanelId.DATABASES), id: detailViews[0].id, name: Messages.labels.databases() },
        { icon: getIconName(DetailsPanelId.DBSYSTEMS), id: detailViews[1].id, name: Messages.labels.databaseSystems() },
      ],
    },
  ];

  const getTitleSuffix = (panelId: string | undefined): string => {
    switch (panelId) {
      case DetailsPanelId.DBSYSTEMS:
        return Messages.labels.databaseSystems();
      case DetailsPanelId.DATABASES:
      default:
        return Messages.labels.databases();
    }
  };

  const getTitle = (
    panelId: string | undefined,
  ): string | TitleDetails => ({
    primary: Messages.serviceProviders.vmDatabase(),
    secondary: getTitleSuffix(panelId),
  });

  const getRoleMessage = (panelId: string | undefined): PanelMessage | undefined => {
    switch (panelId) {
      case DetailsPanelId.DBSYSTEMS:
        return disableVmDbSystemList
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(vmDbRequiredRole),
          } as PanelMessage
          : undefined;
      case DetailsPanelId.DATABASES:
      default:
        return disableVmDbList
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(vmDbSystemRequiredRole),
          } as PanelMessage
          : undefined;
    }
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.serviceProviders.vmDatabase()}
      titleSuffix={getTitleSuffix(listId)}
    >
      <DetailViewsPanel
        title={getTitle(listId)}
        icon={getIconName(listId)}
        message={getRoleMessage(listId)}
        onClose={back}
        isOpen
        views={detailViews}
        activeViewId={getViewId(listId)}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
      />
    </BookmarkablePage>
  );
};
