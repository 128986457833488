/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Realm, Region, RegionToRealmMap } from "oci-console-regions";
import { SessionStorageDataKey } from "./constants/pluginConstants";
import { RegionInfoSummary } from "./gen/clients/mchub-azure-api-client";

export const getSignupUrl = (tenancyId?: string): string => {
  const queryParams = tenancyId ? `?tenancyId=${tenancyId}` : "";
  const { hostname } = window.location;
  switch (hostname) {
    case "localhost.oracle.com":
    case "multicloud.oraclecorp.com":
      return `https://signup-pintlab.multicloud.oracle.com/azure${queryParams}`;
    case "console-staging.multicloud.oracle.com":
      return `https://signup-staging.multicloud.oracle.com/azure${queryParams}`;
    case "console.multicloud.oracle.com":
    default:
      return `https://signup.multicloud.oracle.com/azure${queryParams}`;
  }
};

export const getAnalyticsUrl = (): string => {
  const { hostname } = window.location;
  switch (hostname) {
    case "localhost.oracle.com":
    case "multicloud.oraclecorp.com":
    case "console-staging.multicloud.oracle.com":
      return "//d.oracleinfinity.io/infy/acs/account/g81cwxmeg3/js/odsa_portal/odc.js?_ora.config=analytics:test";
    case "console.multicloud.oracle.com":
    default:
      return "//d.oracleinfinity.io/infy/acs/account/g81cwxmeg3/js/odsa_portal/odc.js";
  }
};

export const getOCIConsoleUrl = (): string => {
  const { hostname } = window.location;
  switch (hostname) {
    case "localhost.oracle.com":
    case "multicloud.oraclecorp.com":
    case "console-staging.multicloud.oracle.com":
    case "console.multicloud.oracle.com":
    default:
      return "https://cloud.oracle.com";
  }
};

export const getOCIRealm = (): Realm => {
  const { hostname } = window.location;
  switch (hostname) {
    case "localhost.oracle.com":
    case "multicloud.oraclecorp.com":
    case "console-staging.multicloud.oracle.com":
    case "console.multicloud.oracle.com":
    default:
      return Realm.OC1;
  }
};

/**
 * us-ashburn-1 <--> eastus
 * us-sanjose-1 <--> westus
 * us-phoenix-1 <--> westus3
 * ca-toronto-1 <--> canadacentral
 * sa-vinhedo-1 <--> brazilsouth
 * uk-london-1 <--> uksouth
 * eu-frankfurt-1 <--> germanywestcentral
 * eu-amsterdam-1 <--> westeurope
 * ap-tokyo-1 <--> japaneast
 * ap-singapore-1 <--> southeastasia
 * ap-seoul-1 <--> koreacentral
 * af-johannesburg-1 <--> southafricanorth
 */
export const regions: RegionInfoSummary[] = [
  {
    regionName: "eastus",
    regionDisplayName: "East US",
    ociRegionName: "us-ashburn-1",
    ociRegionDisplayName: "US East (Ashburn)",
    endpointUrl: "",
  },
  {
    regionName: "westus",
    regionDisplayName: "West US",
    ociRegionName: "us-sanjose-1",
    ociRegionDisplayName: "US West (San Jose)",
    endpointUrl: "",
  },
  {
    regionName: "westus3",
    regionDisplayName: "West US 3",
    ociRegionName: "us-phoenix-1",
    ociRegionDisplayName: "US West (Phoenix)",
    endpointUrl: "",
  },
  {
    regionName: "canadacentral",
    regionDisplayName: "Canada Central",
    ociRegionName: "ca-toronto-1",
    ociRegionDisplayName: "Canada Southeast (Toronto)",
    endpointUrl: "",
  },
  {
    regionName: "brazilsouth",
    regionDisplayName: "Brazil South",
    ociRegionName: "sa-vinhedo-1",
    ociRegionDisplayName: "Brazil South East (Vinhedo)",
    endpointUrl: "",
  },
  {
    regionName: "uksouth",
    regionDisplayName: "UK South",
    ociRegionName: "uk-london-1",
    ociRegionDisplayName: "UK South (London)",
    endpointUrl: "",
  },
  {
    regionName: "germanywestcentral",
    regionDisplayName: "Germany West Central",
    ociRegionName: "eu-frankfurt-1",
    ociRegionDisplayName: "Germany Central (Frankfurt)",
    endpointUrl: "",
  },
  {
    regionName: "westeurope",
    regionDisplayName: "West Europe",
    ociRegionName: "eu-amsterdam-1",
    ociRegionDisplayName: "Netherlands Northwest (Amsterdam)",
    endpointUrl: "",
  },
  {
    regionName: "japaneast",
    regionDisplayName: "Japan East",
    ociRegionName: "ap-tokyo-1",
    ociRegionDisplayName: "Japan East (Tokyo)",
    endpointUrl: "",
  },
  {
    regionName: "southeastasia",
    regionDisplayName: "Southeast Asia",
    ociRegionName: "ap-singapore-1",
    ociRegionDisplayName: "Singapore (Singapore)",
    endpointUrl: "",
  },
  {
    regionName: "koreacentral",
    regionDisplayName: "Korea Central",
    ociRegionName: "ap-seoul-1",
    ociRegionDisplayName: "South Korea Central (Seoul)",
    endpointUrl: "",
  },
  {
    regionName: "southafricanorth",
    regionDisplayName: "South Africa North",
    ociRegionName: "af-johannesburg-1",
    ociRegionDisplayName: "South Africa Central (Johannesburg)",
    endpointUrl: "",
  },
];

export const getOciRegion = (location: string): string => {
  const ociRegion = regions.find(region => region.regionName === location)?.ociRegionName;
  return ociRegion || "";
};

export const getAzureLocationName = (location: string): string => (
  regions.find(region => region.regionName === location)?.regionDisplayName || location || ""
);

const ENDPOINT_VERSION_1 = "v1";
const ENDPOINT_VERSION_20160918 = "20160918";
const SERVICE_LOGIN = "login";
const SERVICE_CONSOLE = "console";
const SERVICE_IDENTITY = "identity";
const SERVICE_AUTHENTICATION = "auth";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mustNotEmptyNullOrUndefined = (name: string, value: any, errorMessage?: string): void => {
  if (!value) {
    throw new Error(errorMessage || `${name} must not be empty, null or undefined.`);
  }
};

export interface RealmRegions {
  defaultRegion: Region;
  fallbackRegions: Region[];
}

const ConsoleRealmRegionsMapping = new Map<Realm, RealmRegions>([
  [Realm.OC1, { defaultRegion: Region.IAD, fallbackRegions: [Region.IAD, Region.FRA] }],
  [Realm.OC2, { defaultRegion: Region.LFI, fallbackRegions: [] }],
  [Realm.OC3, { defaultRegion: Region.RIC, fallbackRegions: [] }],
  [Realm.OC4, { defaultRegion: Region.LTN, fallbackRegions: [] }],
  [Realm.OC5, { defaultRegion: Region.TIW, fallbackRegions: [] }],
  [Realm.OC6, { defaultRegion: Region.FTW, fallbackRegions: [] }],
  [Realm.OC7, { defaultRegion: Region.BWI, fallbackRegions: [] }],
  [Realm.OC8, { defaultRegion: Region.NJA, fallbackRegions: [] }],
  [Realm.OC9, { defaultRegion: Region.MCT, fallbackRegions: [] }],
  [Realm.OC10, { defaultRegion: Region.WGA, fallbackRegions: [] }],
  [Realm.OC11, { defaultRegion: Region.HWY, fallbackRegions: [] }],
  [Realm.OC12, { defaultRegion: Region.PIT, fallbackRegions: [] }],
]);

export const getRealmRegions = (): RealmRegions => {
  const realm = getOCIRealm();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return ConsoleRealmRegionsMapping.get(realm)!;
};

const realmTopLevels = new Map<Realm, string>([
  [Realm.R1, "oracleiaas.com"],
  [Realm.OC1, "oraclecloud.com"],
  [Realm.OC2, "oraclegovcloud.com"],
  [Realm.OC3, "oraclegovcloud.com"],
  [Realm.OC4, "oraclegovcloud.uk"],
  [Realm.OC5, "oraclecloud5.com"],
  [Realm.OC6, "oraclecloud.ic.gov"],
  [Realm.OC7, "oc.ic.gov"],
  [Realm.OC8, "oraclecloud8.com"],
  [Realm.OC9, "oraclecloud9.com"],
  [Realm.OC10, "oraclecloud10.com"],
  [Realm.OC11, "oraclecloud11.com"],
  [Realm.OC12, "oraclecloud12.com"],
]);

export const getTopLevel = (region: string): string => {
  const regionEnum = region as Region;
  const realm = RegionToRealmMap[regionEnum];
  return realmTopLevels.get(realm) || "";
};

export const getSanitizedRegion = (regionName: string): string => (
  regionName.toLowerCase() === "us-seattle-1" ? "r1" : regionName
);

export const buildServiceUrl = (
  serviceName: string,
  region: string,
  secondLevelDomain: string,
  version?: string,
  pattern?: string,
): string => {
  mustNotEmptyNullOrUndefined("serviceName", serviceName);
  mustNotEmptyNullOrUndefined("secondLevelDomain", secondLevelDomain);

  // take care of r1 special case where it doesn't use region name as sub-domain
  let serviceUrl = pattern || "https://{serviceName}.{region}.{secondLevelDomain}/{version}";

  serviceUrl = serviceUrl
    .replace(/{serviceName}/gi, serviceName)
    .replace(/{secondLevelDomain}/gi, secondLevelDomain)
    .replace(/{version}/gi, version || "")
    .replace(/\/$/, ""); // remove trailing "/"

  return region ? serviceUrl.replace(/{region}/gi, getSanitizedRegion(region)) : serviceUrl.replace(/{region}\./gi, "");
};

/**
 * Returns the Login URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildLoginUrl = (regionName: string): string => buildServiceUrl(
  SERVICE_LOGIN,
  regionName,
  getTopLevel(regionName),
  ENDPOINT_VERSION_1,
);

/**
 * Returns the Console URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildConsoleUrl = (regionName: string): string => buildServiceUrl(
  SERVICE_CONSOLE,
  regionName,
  getTopLevel(regionName),
);

/**
 * Returns the Identity URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildIdentityUrl = (regionName: string): string => buildServiceUrl(
  SERVICE_IDENTITY,
  regionName,
  getTopLevel(regionName),
  ENDPOINT_VERSION_20160918,
);

/**
 * Returns the Authorization URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildAuthorizationUrl = (regionName: string): string => buildServiceUrl(
  SERVICE_AUTHENTICATION,
  regionName,
  getTopLevel(regionName),
  ENDPOINT_VERSION_1,
);

export const getQParam = (name: string): string | undefined => {
  const params = window.location?.search?.substring(1).split("&");

  const param = params?.find(it => it.startsWith(`${name}=`));
  if (param) {
    const nameValue = param.split("=");
    if (nameValue[1]) {
      nameValue[1] = decodeURIComponent(nameValue[1]);
    }
    return nameValue[1];
  }

  return undefined;
};

export const getSessionStorageData = (): {[propertyName: string]: string} | undefined => {
  const storageData = window.sessionStorage.getItem(SessionStorageDataKey);
  if (storageData) {
    try {
      return JSON.parse(storageData);
    } catch {
      // eslint-disable-next-line no-console
      console.error("Failed to parse url query parameters: invalid JSON format");
      return undefined;
    }
  }
  return undefined;
};

export const setSessionStorageProperty = (name: string, value: string): void => {
  if (!name) {
    return;
  }

  const storageData = getSessionStorageData() || {};
  storageData[name] = value;
  try {
    const strStorageData = JSON.stringify(storageData);
    window.sessionStorage.setItem(SessionStorageDataKey, strStorageData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Failed to set session storage data", e);
  }
};

export const getSessionStorageProperty = (name: string): string | undefined => {
  if (!name) {
    return undefined;
  }

  const storageData = getSessionStorageData();

  if (storageData) {
    return storageData[name];
  }

  return undefined;
};

export const getSessionStorageQParam = (name: string): string | undefined => {
  const urlSearchParams = getSessionStorageProperty("urlSearchParams");
  if (urlSearchParams) {
    const urlParams = new URLSearchParams(urlSearchParams);
    return urlParams?.get(name) || undefined;
  }

  return undefined;
};

export const removeSessionStorageData = (): void => window.sessionStorage.removeItem(SessionStorageDataKey);

export const azurePortalUrl = "https://portal.azure.com/";
export const myAccountUrl = "https://myaccount.microsoft.com/";
