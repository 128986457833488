import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Fields as PdbFields, VmDbPdbCreatePanel } from "../../components/PdbCreatePanel/VmDbPdbCreatePanel";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_PDB_CREATE_POLL_DELAY,
  VMDB_PDB_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface VmDbClonePdbProps extends OperationProps {
  databaseId: string | undefined;
}

const VmDbClonePdb = (
  { location, databaseId, onExecute, onCancel }: VmDbClonePdbProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName: databaseName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");

  const { invokeCall: invokeClone } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.localVmdbClonePluggableDatabase },
  );
  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const onClonePdb = (formValues: FormValues): void => {
    const pdbName = getValue<string>(formValues, PdbFields.PdbName, InputFormGroup) || "";
    const pdbAdminPassword = getValue<string>(formValues, PdbFields.Password, InputFormGroup);
    const unlockAccount = getValue<boolean>(formValues, PdbFields.UnlockAdminAccount, InputFormGroup);
    const tdeWalletPassword = getValue<string>(formValues, PdbFields.TdeWalletPassword, InputFormGroup);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.clonePluggableDb(),
          message: Messages.notifications.inProgress.messages.clonePluggableDb(pdbName),
        },
        success: {
          title: Messages.notifications.success.titles.clonePluggableDb(),
          message: Messages.notifications.success.messages.clonePluggableDb(pdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.clone(),
          message: Messages.notifications.failure.messages.clonePluggableDb(pdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_PDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            pluggableDatabaseName: pdbName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_CREATED_CHECK_PDB,
          delay: VMDB_PDB_CREATE_POLL_DELAY,
          interval: VMDB_PDB_CREATE_POLL_INTERVAL,
        },
      },
    };

    invokeClone({
      subscriptionId,
      resourceGroupName: resGroup,
      pluggableDatabaseName: databaseName,
      localClonePluggableDatabaseDetails: {
        clonedPdbName: pdbName,
        ...pdbAdminPassword ? { pdbAdminPassword } : {},
        shouldPdbAdminAccountBeLocked: !unlockAccount,
        targetTdeWalletPassword: tdeWalletPassword,
      },
    }, invokeOptions);
  };

  return (
    <VmDbPdbCreatePanel
      title={Messages.clonePluggableDatabase.title()}
      componentRef={setSidePanelRef}
      onSubmit={onClonePdb}
      onClose={onCancel}
    />
  );
};

export const newVmDbClonePdb = (
  props: VmDbClonePdbProps,
): JSX.Element => (<VmDbClonePdb {...props} />);
