import { Select, SelectOption, SimpleBuilder, SimpleBuilderCell, SimpleBuilderProps, TextInput } from "o4a-react";
import * as React from "react";
import { Label } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { TagInfoType } from "../../helpers/resourceHelper";
import { validateTagRow } from "../../helpers/validationHelper";

export const GROUP_TAGS = "tags";

/**
 * Use this type only when creating a resource in the Create flow as currently
 * this is the only scenario where we allow the user to select a resource to apply the tags
 */

export interface TagsCollectionProps extends Pick<SimpleBuilderProps, "fieldName" | "testId" | "onChange">{
  tagsDefaultValue?: TagInfoType[];
  resourceOptions?: SelectOption[];
}

export enum Fields {
  Name = "name",
  Value = "value",
  Resources = "resources",
}

export enum FieldTestIds {
  Name = "tag-name",
  Value = "tag-value",
  Resources = "tag-resources",
}

export const TagsCollection = ({
  fieldName,
  testId,
  tagsDefaultValue,
  resourceOptions,
  onChange,
}: TagsCollectionProps): JSX.Element => {
  const editResource = React.useMemo(() => (
    <SimpleBuilder
      testId={testId}
      fieldName={fieldName}
      defaultValue={tagsDefaultValue}
      onChange={onChange}
      rowValidator={validateTagRow(resourceOptions)}
    >
      <TextInput
        testId={FieldTestIds.Name}
        label={Messages.common.name()}
        fieldName={Fields.Name}
        ariaLabel={Messages.common.name()}
      />
      <SimpleBuilderCell width={6}>
        <Label>:</Label>
      </SimpleBuilderCell>
      <TextInput
        testId={FieldTestIds.Value}
        label={Messages.common.value()}
        fieldName={Fields.Value}
        ariaLabel={Messages.common.value()}
      />
    </SimpleBuilder>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const createResource = React.useMemo(() => (
    <SimpleBuilder
      testId={testId}
      fieldName={fieldName}
      defaultValue={tagsDefaultValue}
      onChange={onChange}
      rowValidator={validateTagRow(resourceOptions)}
    >
      <TextInput
        testId={FieldTestIds.Name}
        label={Messages.common.name()}
        fieldName={Fields.Name}
        ariaLabel={Messages.common.name()}
      />
      <SimpleBuilderCell width={6}>
        <Label>:</Label>
      </SimpleBuilderCell>
      <TextInput
        testId={FieldTestIds.Value}
        label={Messages.common.value()}
        fieldName={Fields.Value}
        ariaLabel={Messages.common.value()}
      />
      <SimpleBuilderCell width={6}>
        <span>&nbsp;</span>
      </SimpleBuilderCell>
      <Select
        testId={FieldTestIds.Resources}
        label={Messages.labels.resource()}
        multiSelect
        disabled={!resourceOptions?.length || resourceOptions.length === 1}
        selectAllOption={resourceOptions && resourceOptions.length > 1 ? true : undefined}
        showCount={resourceOptions && resourceOptions.length > 1 ? true : undefined}
        fieldName={Fields.Resources}
        defaultValue={resourceOptions?.map(option => option.id)}
        options={resourceOptions ?? []}
        hideSearchBox
      />
    </SimpleBuilder>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  if (!resourceOptions?.length) return editResource;
  return createResource;
};
