import { NotificationProvider } from "o4a-react";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { IButtonStyles, Icon, initializeIcons, IStackTokens, PrimaryButton, Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import * as Messages from "./codegen/Messages";
import { ConsoleContextProvider, ConsoleState } from "./console/ConsoleContext";
import { TopHeader } from "./console/TopHeader";
import { BASE_ROUTE, HOME_ROUTE, PageId } from "./constants/pluginConstants";
import { topHeaderHeight } from "./constants/uiConstants";
import { InitState, InitStatus } from "./session/sessionConstants";

initializeIcons();

export interface AppErrorProps {
  initState: InitState;
}

export const AppError = ({ initState }: AppErrorProps): JSX.Element => {
  const [isOverlayOpen, { setTrue: openOverlay, setFalse: dismissOverlay }] = useBoolean(false);

  const appState = {
    regionName: "",
    basePath: BASE_ROUTE,
    homeKey: PageId.HOME,
    homePath: HOME_ROUTE,
    headerHeight: topHeaderHeight,
  } as ConsoleState;

  return (
    <ConsoleContextProvider value={appState}>
      <NotificationProvider>
        <BrowserRouter basename={BASE_ROUTE}>
          <TopHeader
            isOverlayOpen={isOverlayOpen}
            openOverlay={openOverlay}
            dismissOverlay={dismissOverlay}
            noTopMenu
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100vw",
              height: `calc(100vh - ${topHeaderHeight}px)`,
            }}
          >
            {initState.initStatus === InitStatus.ACCOUNTS_LINKING_IN_PROGRESS && <LinkingInProgress />}
            {initState.initStatus === InitStatus.ACCOUNTS_LINKED_UNKNOWN
              && <LinkingUnknown error={initState.error?.body.message || ""} />}
            {initState.initStatus !== InitStatus.ACCOUNTS_LINKING_IN_PROGRESS
              && initState.initStatus !== InitStatus.ACCOUNTS_LINKED_UNKNOWN
              && <GenericError error={initState.error?.body.message || ""} />}
          </div>
        </BrowserRouter>
      </NotificationProvider>
    </ConsoleContextProvider>
  );
};

const buttonStyles: IButtonStyles = {
  root: {
    fontSize: "13px",
    minWidth: "100px",
  },
};

const stackTokens: IStackTokens = { childrenGap: 20 };

const LinkingInProgress = (): JSX.Element => {
  const onClick = (): void => window.location.reload();
  return (
    <Stack verticalAlign="center" horizontalAlign="center">
      <Icon
        iconName="in-progress-svg"
      />
      <Stack verticalAlign="center" horizontalAlign="center" grow={false} tokens={stackTokens}>
        <div
          style={{
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          {Messages.initErrors.linkingInProgress.title()}
        </div>
        <div>{Messages.initErrors.linkingInProgress.message()}</div>
        <PrimaryButton styles={buttonStyles} onClick={onClick}>{Messages.actions.retry()}</PrimaryButton>
      </Stack>
    </Stack>
  );
};

const LinkingUnknown = ({ error }: { error: string}): JSX.Element => {
  const onClick = (): void => window.location.reload();
  return (
    <Stack verticalAlign="center" horizontalAlign="center">
      <Icon
        iconName="cloud-error-svg"
      />
      <Stack verticalAlign="center" horizontalAlign="center" grow={false} tokens={stackTokens}>
        <div
          style={{
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          {Messages.initErrors.linkingUnknown.title()}
        </div>
        <div>{Messages.initErrors.linkingUnknown.message(error)}</div>
        <PrimaryButton styles={buttonStyles} onClick={onClick}>{Messages.actions.retry()}</PrimaryButton>
      </Stack>
    </Stack>
  );
};

const GenericError = ({ error }: { error: string}): JSX.Element => {
  const onClick = (): void => window.location.reload();
  return (
    <Stack verticalAlign="center" horizontalAlign="center">
      <Icon
        iconName="cloud-error-svg"
      />
      <Stack verticalAlign="center" horizontalAlign="center" grow={false} tokens={stackTokens}>
        <div
          style={{
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          {Messages.initErrors.genericError.title()}
        </div>
        <div>{Messages.initErrors.genericError.message(error)}</div>
        <PrimaryButton styles={buttonStyles} onClick={onClick}>{Messages.actions.retry()}</PrimaryButton>
      </Stack>
    </Stack>
  );
};
