import {
  AnchoredPanelType,
  FormInputGroupLayout,
  FormValues,
  getValue,
  InputFormGroup,
  InputFormSidePanel,
  InputFormSidePanelComponent,
  SelectOption,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { HeatwaveShapeSelect } from "../../components/HeatwaveShapeSelect/HeatwaveShapeSelect";
import { NodeCountEstimator } from "../../components/NodeCountEstimator/NodeCountEstimator";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_HEATWAVE_CREATE_POLL_DELAY,
  MDS_HEATWAVE_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export enum Fields {
  NodeCount = "nodeCount",
  Shape = "shape"
}

export enum FieldTestIds {
  NodeCount = "nodeCount",
  Shape = "shape"
}

export interface MysqlCreateHeatWaveProps extends OperationProps {
  databaseId: string | undefined;
  clusterSize: number | undefined;
}

const MysqlCreateHeatWave = (
  { location, databaseId, clusterSize, onExecute, onCancel }: MysqlCreateHeatWaveProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resourceGroupName = decodeURIComponent(resourceGroup || "");
  const dbSystemName = decodeURIComponent(resourceName || "");

  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (clusterSize != undefined) {
      const initFieldValues: FormValues = { [Fields.NodeCount]: clusterSize.toString() };
      setInitialValues(initFieldValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterSize]);

  const { invokeCall: invokeCreate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.addHeatWaveCluster },
  );

  const onCreate = (formValues: FormValues): void => {
    const shapeName = getValue<SelectOption[]>(
      formValues,
      Fields.Shape,
      InputFormGroup,
    )?.[0]?.id ?? "";
    const nodes = getValue<string>(formValues, Fields.NodeCount, InputFormGroup);
    const nodeCount = nodes ? parseInt(nodes, 10) : 0;

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.createHeatWave(),
          message: Messages.notifications.inProgress.messages.createHeatWave(dbSystemName),
        },
        success: {
          title: Messages.notifications.success.titles.createHeatWave(),
          message: Messages.notifications.success.messages.createHeatWave(dbSystemName),
        },
        failure: {
          title: Messages.notifications.failure.titles.create(),
          message: Messages.notifications.failure.messages.createHeatWave(dbSystemName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_HEATWAVE_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            dbSystemName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_HEATWAVE_CREATED_CHECK,
          delay: MDS_HEATWAVE_CREATE_POLL_DELAY,
          interval: MDS_HEATWAVE_CREATE_POLL_INTERVAL,
        },
      },
    };
    invokeCreate({
      subscriptionId,
      resourceGroupName,
      dbSystemName,
      addHeatWaveClusterDetails: {
        shapeName,
        clusterSize: nodeCount,
      },
    }, invokeOptions);
  };

  return (
    <InputFormSidePanel
      componentRef={setSidePanelRef}
      type={AnchoredPanelType.LARGE}
      title={Messages.labels.createHeatWave()}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onCreate}
      onClose={onCancel}
      layout={FormInputGroupLayout.COMPACT}
      testId={SidePanelTestIds.MysqlCreateHeatWave}
      initialValues={initialValues}
    >
      <div style={{ width: "300px" }}>
        <HeatwaveShapeSelect
          testId={FieldTestIds.Shape}
          fieldName={Fields.Shape}
          label={Messages.labels.mySQLHeatwaveShape()}
          required
          subscriptionId={subscriptionId}
          location={location}
          tooltip={Messages.hints.selectAHeatwaveShape()}
        />
      </div>
      <NodeCountEstimator
        testId={FieldTestIds.NodeCount}
        fieldName={Fields.NodeCount}
        subscriptionId={subscriptionId}
        resourceGroupName={resourceGroup}
        dbSystemName={dbSystemName}
        location={location}
        nodeCountDefaultValue={clusterSize}
      />
    </InputFormSidePanel>
  );
};

export const newMysqlCreateHeatWave = (
  props: MysqlCreateHeatWaveProps,
): JSX.Element => (<MysqlCreateHeatWave {...props} />);
