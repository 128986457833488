import {
  AnchoredPanelType,
  CheckBox,
  FieldSet,
  FormattedString,
  InfoBlockLayout,
  InfoBlockStatus,
  InputFormSidePanel,
  InputFormSidePanelProps,
  MessageType,
  PanelMessage,
  SubmitButtonMode,
  TextInput,
  TextInputTypes,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { vmdbPluggableDatabaseUrl } from "../../constants/docConstants";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { RoleBasedAction } from "../../helpers/roleHelper";
import {
  alphaNumericUnderscoreRegex,
  pdbNameMaxLength,
  startWithLetterRegex,
  validatePdbPassword,
} from "../../helpers/validationHelper";
import { useRoles } from "../../hooks/useRoles";
import { TagsCollection } from "../Tags/TagsCollection";

export enum Fields {
  PdbName = "pdbName",
  Password = "password",
  PasswordConfirm = "passwordConfirm",
  UnlockAdminAccount = "unlockAdminAccount",
  TdeWalletPassword = "tdeWalletPassword",
  Tags = "tags",
}

export enum FieldTestIds {
  PdbName = "pdb-name",
  Password = "password",
  PasswordConfirm = "password-confirm",
  UnlockAdminAccount = "unlock-admin-account",
  TdeWalletPassword = "tde-wallet-password",
  Tags = "tags",
}

export interface VmDbPdbCreatePanelProps extends
  Pick<InputFormSidePanelProps, "componentRef" | "onClose" | "onSubmit" | "title"> {
  showTags?: boolean;
}

export const VmDbPdbCreatePanel = ({
  title,
  showTags,
  componentRef,
  onSubmit,
  onClose,
}: VmDbPdbCreatePanelProps): JSX.Element => {
  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const isCreateRoleMissing = !isActionAllowed(RoleBasedAction.CREATE_VMDB_PDB);
  const requiredRole = actionRequiredRoles(RoleBasedAction.CREATE_VMDB_PDB)[0]?.displayName;

  const [passwordVal, setPasswordVal] = React.useState<string>();
  const [passwordConfirmVal, setPasswordConfirmVal] = React.useState<string>();
  const [unlockAccountVal, setUnlockAccountVal] = React.useState(false);
  const [key, setKey] = React.useState<string>(uniqueGUID());

  const info = {
    messageType: InfoBlockStatus.INFO,
    message: Messages.hints.tdeWalletPasswordInfo(),
    infoLayout: InfoBlockLayout.Compact,

  };

  const onChangeUnlockAccountVal = (value: boolean): void => {
    setUnlockAccountVal(value);
  };

  const onChangePassword = (value: string | undefined): void => {
    setPasswordVal(value);
    if (passwordConfirmVal !== undefined) {
      setPasswordConfirmVal(undefined);
      setKey(uniqueGUID());
    }
  };

  return (
    <InputFormSidePanel
      componentRef={componentRef}
      type={AnchoredPanelType.MEDIUM}
      title={title}
      message={isCreateRoleMissing
        ? {
          type: MessageType.WARNING,
          text: Messages.validation.roleMissingForCreate(requiredRole),
        } as PanelMessage
        : undefined}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onClose={onClose}
      testId={SidePanelTestIds.VmDbPdbCreatePanel}
    >
      <TextInput
        testId={FieldTestIds.PdbName}
        required
        fieldName={Fields.PdbName}
        label={Messages.labels.pluggableDatabaseName()}
        tooltip={
          FormattedString(
            { inputText: Messages.hints.tooltipPluggableDatabase(vmdbPluggableDatabaseUrl) },
          ) as unknown as string
        }
        validator={(value: string | undefined) => {
          const errors: string [] = [];
          if (!value || !startWithLetterRegex.test(value)) {
            errors.push(Messages.validation.nameStartChar());
          }
          if (!value || value.length > pdbNameMaxLength) {
            errors.push(Messages.validation.valueMaxLen(pdbNameMaxLength.toString()));
          }
          if (!value || !alphaNumericUnderscoreRegex.test(value)) {
            errors.push(Messages.validation.valueAlphaNumericUnderscore());
          }
          return errors.length > 0 ? errors : undefined;
        }}
      />
      <CheckBox
        testId={FieldTestIds.UnlockAdminAccount}
        defaultValue={unlockAccountVal}
        fieldName={Fields.UnlockAdminAccount}
        label={Messages.labels.unlockMyPDBAdminAccount()}
        tooltip={Messages.hints.unlockMyPDBAdminAccount()}
        onChange={value => {
          onChangeUnlockAccountVal(value);
        }}
      />
      {unlockAccountVal && (
        <>
          <TextInput
            testId={FieldTestIds.Password}
            required
            defaultValue={passwordVal}
            fieldName={Fields.Password}
            textType={TextInputTypes.PASSWORD}
            label={Messages.labels.pdbAdminPassword()}
            onChange={onChangePassword}
            validator={validatePdbPassword}
          />
          <TextInput
            testId={FieldTestIds.PasswordConfirm}
            required
            key={key}
            defaultValue={passwordConfirmVal}
            fieldName={Fields.PasswordConfirm}
            label={Messages.labels.pdbAdminPasswordConfirm()}
            textType={TextInputTypes.PASSWORD}
            onChange={value => { setPasswordConfirmVal(value); }}
            validator={(value: string | undefined) => {
              if (value && (value !== passwordVal)) {
                return [Messages.validation.passwordConfirmation()];
              }
              return undefined;
            }}
          />
        </>
      )}
      <TextInput
        testId={FieldTestIds.TdeWalletPassword}
        required
        fieldName={Fields.TdeWalletPassword}
        textType={TextInputTypes.PASSWORD}
        label={Messages.labels.tdeWalletPassword()}
        statusInfo={info}
      />
      {showTags && (
        <FieldSet header={Messages.labels.tags()}>
          <TagsCollection fieldName={Fields.Tags} testId={FieldTestIds.Tags} />
        </FieldSet>
      )}
    </InputFormSidePanel>
  );
};
