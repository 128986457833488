import {
  ActionBar,
  ActionType,
  cidrRange,
  cidrValid,
  getResourceLifecycleStatus,
  isValidIpAddress,
  LabelMetaItem,
  Listing,
  ListingColumn,
  MetaItemSection,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import { Stack, Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { AzureSubnetLink } from "../../components/AzureLinks/AzureSubnetLink";
import { AzureVnetLink } from "../../components/AzureLinks/AzureVnetLink";
import { McVcnLink } from "../../components/ResourceLinks/McVcnLink";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds } from "../../constants/uiConstants";
import { AdbsDatabase } from "../../gen/clients/mchub-azure-api-client-adb";
import {
  ACCESS_TYPES,
  AdbWorkloadType,
  getNetworkAccessTypeDisplayName,
  getStatusInfo,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import { useSidePanel } from "../../hooks/useSidePanel";
import { AdbsEditMutualTlsAuthProps, newAdbsEditMutualTlsAuth } from "../../operations/Adbs/AdbsEditMutualTlsAuth";
import { AdbsEditNetworkAccessProps, newAdbsEditNetworkAccess } from "../../operations/Adbs/AdbsEditNetworkAccess";

export enum ActionIds {
  Edit = "edit",
  EditMtls = "edit-mtls",
  Refresh = "refresh",
}

export enum ActionBarTestIds {
  Edit = "edit",
  Refresh = "refresh",
}

export enum MetaItemSectionTestIds {
  STATUS = "networking-status",
  ACCESS_TYPE = "networking-access-type",
  MUTUAL_AUTHENTICATION = "networking-mutual-authentication",
  MCVCN = "networking-mcvcn",
  VIRTUAL_NETWORK = "networking-virtual-network",
  SUBNET = "networking-subnet",
  PRIVATE_ENDPOINT_URL = "networking-private-endpoint-url",
  PRIVATE_ENDPOINT_IP = "networking-private-endpoint-ip",
  HOSTNAME_PREFIX = "networking-hostname-prefix",
}

export enum MetaItemActionTestIds {
  EditMtls = "edit-mtls",
}

export interface AdbsNetworkingDetailsProps {
  adbs: AdbsDatabase | undefined;
  location: string;
  refresh: () => void;
}

export const AdbsNetworkingDetails = (
  { adbs, location, refresh }: AdbsNetworkingDetailsProps,
): JSX.Element => {
  const { enableAdbsOpenPL, enableAdbsRestrictedPL } = useFeatures();

  const networkAccessType = adbs?.networkAccessType
    ? adbs.networkAccessType as ACCESS_TYPES
    : adbs?.whitelistedIps?.length
      ? ACCESS_TYPES.ALLOWED_IPS
      : ACCESS_TYPES.EVERYWHERE;

  const hideVnetFields = (networkAccessType === ACCESS_TYPES.EVERYWHERE && !enableAdbsOpenPL)
    || (networkAccessType === ACCESS_TYPES.ALLOWED_IPS && !enableAdbsRestrictedPL);

  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { trigger: triggerEditNetworkAccess } = useOperation<AdbsEditNetworkAccessProps>(newAdbsEditNetworkAccess);
  const { trigger: triggerEditMutualTlsAuth } = useOperation<AdbsEditMutualTlsAuthProps>(newAdbsEditMutualTlsAuth);

  const { closePanels } = useSidePanel();

  const adbsNetworkActions: ActionType[] = [
    {
      key: ActionIds.Edit,
      testId: ActionBarTestIds.Edit,
      text: Messages.actions.edit(),
      icon: MonochromeIconIds.Edit,
      onClick: () => {
        trackActionClick(ActionIds.Edit, PageId.ADBS_DETAILS, DetailsPanelId.NETWORKING);
        triggerEditNetworkAccess({
          databaseId: adbs?.id,
          workload: adbs?.dbWorkload as AdbWorkloadType,
          adbsEditNetworkingValues: {
            networkAccessType: adbs?.networkAccessType as ACCESS_TYPES,
            isMtlsConnectionRequired: adbs?.isMtlsConnectionRequired,
            whitelistedIps: adbs?.whitelistedIps,
            mcvcnId: adbs?.mcvcnId,
            vnetId: adbs?.vnetId,
            subnetId: adbs?.subnetId,
            hostnamePrefix: adbs?.hostnamePrefix,
          },
          location,
          onExecute: refresh,
          onCancel: () => trackActionDiscard(ActionIds.Edit, PageId.ADBS_DETAILS, DetailsPanelId.NETWORKING),
        });
      },
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.ADBS_DETAILS, DetailsPanelId.NETWORKING);
        refresh();
      },
    },
  ];

  const ociCidrColumns: ListingColumn[] = [
    {
      name: Messages.labels.addresses(),
      itemProp: "address",
      flexGrow: 1,
      isResizable: true,
    },
    {
      name: Messages.labels.addressRange(),
      itemProp: "addressRange",
      flexGrow: 2,
      isResizable: true,
    },
  ];

  const ociCidrs = adbs?.whitelistedIps?.map(address => {
    let item = {};
    if (cidrValid(address)) {
      const range = cidrRange(address);
      item = { addressRange: `${range[0]} - ${range[1]} ${Messages.common.addessesCount(range[2].toString())}` };
    } else if (isValidIpAddress(address)) {
      item = { addressRange: address };
    } else {
      item = { addressRange: `${Messages.common.addessesCount("0")}` };
    }
    return { ...item, address };
  }) || [];

  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
      <ActionBar actions={adbsNetworkActions} onActionClick={closePanels} />
      <MetaItemSection labelWidth={250}>
        <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
          <LabelMetaItem testId={MetaItemSectionTestIds.STATUS} label={Messages.labels.status()}>
            <Status
              iconPosition="right"
              label={stateT(adbs?.status || "")}
              status={getResourceLifecycleStatus(adbs?.status || "")}
              statusInfo={
                getStatusInfo(
                  adbs?.status || "",
                  adbs?.lastOperationStatus || "",
                  adbs?.lastOperationStatusDetails || "",
                )
              }
            />
          </LabelMetaItem>
          <LabelMetaItem testId={MetaItemSectionTestIds.ACCESS_TYPE} label={Messages.labels.accessType()}>
            {getNetworkAccessTypeDisplayName(networkAccessType)}
          </LabelMetaItem>
          {adbs?.dbWorkload !== AdbWorkloadType.APEX && (
            <LabelMetaItem
              testId={MetaItemSectionTestIds.MUTUAL_AUTHENTICATION}
              label={Messages.labels.mutualAuthentication()}
              action={networkAccessType !== ACCESS_TYPES.EVERYWHERE ? {
                id: ActionIds.EditMtls,
                testId: MetaItemActionTestIds.EditMtls,
                label: Messages.actions.edit(),
                onClick: () => {
                  trackActionClick(ActionIds.EditMtls, PageId.ADBS_DETAILS, DetailsPanelId.NETWORKING);
                  triggerEditMutualTlsAuth({
                    databaseId: adbs?.id,
                    isMtlsConnectionRequired: adbs?.isMtlsConnectionRequired,
                    location,
                    targetId: ActionIds.EditMtls,
                    onExecute: refresh,
                    onCancel: () => trackActionDiscard(
                      ActionIds.EditMtls,
                      PageId.ADBS_DETAILS,
                      DetailsPanelId.NETWORKING,
                    ),
                  });
                },
              } : undefined}
            >
              {adbs?.isMtlsConnectionRequired ? Messages.common.required() : Messages.common.notRequired()}
            </LabelMetaItem>
          )}
          {adbs?.mcvcnId && (
            <LabelMetaItem
              testId={MetaItemSectionTestIds.MCVCN}
              label={Messages.labels.virtualCloudNetwork()}
            >
              <McVcnLink
                mcVcnId={adbs.mcvcnId}
                location={location}
                analytics={{ pageId: PageId.ADBS_DETAILS, panelId: DetailsPanelId.NETWORKING }}
              />
            </LabelMetaItem>
          )}
          {adbs?.vnetId && !hideVnetFields && (
            <LabelMetaItem
              testId={MetaItemSectionTestIds.VIRTUAL_NETWORK}
              label={Messages.labels.virtualNetwork()}
            >
              <AzureVnetLink vnetId={adbs.vnetId} />
            </LabelMetaItem>
          )}
          {adbs?.subnetId && !hideVnetFields && (
            <LabelMetaItem testId={MetaItemSectionTestIds.SUBNET} label={Messages.labels.subnet()}>
              <AzureSubnetLink subnetId={adbs.subnetId} />
            </LabelMetaItem>
          )}
          {adbs?.privateEndpoint && (
            <LabelMetaItem
              testId={MetaItemSectionTestIds.PRIVATE_ENDPOINT_URL}
              label={Messages.labels.privateEndpointUrl()}
            >
              {adbs.privateEndpoint}
            </LabelMetaItem>
          )}
          {adbs?.privateEndpointIp && (
            <LabelMetaItem
              testId={MetaItemSectionTestIds.PRIVATE_ENDPOINT_IP}
              label={Messages.labels.privateEndpointIp()}
            >
              {adbs.privateEndpointIp}
            </LabelMetaItem>
          )}
          {(networkAccessType === ACCESS_TYPES.PRIVATE || networkAccessType === ACCESS_TYPES.PRIVATE_PEERING)
            && adbs?.hostnamePrefix && (
            <LabelMetaItem testId={MetaItemSectionTestIds.HOSTNAME_PREFIX} label={Messages.labels.hostName()}>
              {adbs.hostnamePrefix}
            </LabelMetaItem>
          )}
        </Stack>
      </MetaItemSection>
      {networkAccessType === ACCESS_TYPES.ALLOWED_IPS && (
        <Stack style={{ height: "100%" }}>
          <Text variant="mediumPlus">
            {Messages.detailAdbs.networking.sectionTitles.ipsAndOrCidrs()}
          </Text>
          <br />
          <Listing
            items={ociCidrs}
            listColumns={ociCidrColumns}
          />
        </Stack>
      )}
    </Stack>
  );
};
