import {
  AnchoredPanelType,
  FieldSet,
  InputFormSidePanel,
  InputFormSidePanelProps,
  IntegerInput,
  RadioGroup,
  RadioGroupOption,
  SubmitButtonMode,
  TextInput,
  TextInputMultilineConfig,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { mdsBackupDefaultRetentionInDays, MYSQL_BACKUP_TYPES } from "../../helpers/resourceHelper";
import { validateMdsBackupName } from "../../helpers/validationHelper";
import { TagsCollection } from "../Tags/TagsCollection";

export enum Fields {
  Name = "name",
  Description = "description",
  BackupType = "backupType",
  RetentionInDays = "retentionInDays",
  FreeformTags = "freeformTags",
}

export enum FieldTestIds {
  Name = "name",
  Description = "description",
  BackupType = "backupType",
  RetentionInDays = "retentionInDays",
  FreeformTags = "freeformTags",
}

export type MysqlBackupCreatePanelProps = Pick<InputFormSidePanelProps, "componentRef" | "onSubmit" | "onClose">;

export const MysqlBackupCreatePanel = ({
  onSubmit,
  onClose,
  componentRef,
}: MysqlBackupCreatePanelProps): JSX.Element => {
  const backupTypeOptions: RadioGroupOption[] = [
    { id: MYSQL_BACKUP_TYPES.FULL, text: Messages.labels.full() },
    { id: MYSQL_BACKUP_TYPES.INCREMENTAL, text: Messages.labels.incremental() },
  ];

  return (
    <InputFormSidePanel
      type={AnchoredPanelType.MEDIUM}
      title={Messages.createNewPanels.mysqlManualBackup.title()}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onClose={onClose}
      componentRef={componentRef}
      testId={SidePanelTestIds.MysqlBackupCreatePanel}
    >
      <TextInput
        testId={FieldTestIds.Name}
        required
        fieldName={Fields.Name}
        label={Messages.labels.name()}
        validator={validateMdsBackupName}
      />
      <TextInput
        testId={FieldTestIds.Description}
        fieldName={Fields.Description}
        label={Messages.labels.description()}
        multiline={{ rows: 5 } as TextInputMultilineConfig}
      />
      <RadioGroup
        testId={FieldTestIds.BackupType}
        label={Messages.labels.backupType()}
        defaultValue={MYSQL_BACKUP_TYPES.FULL}
        fieldName={Fields.BackupType}
        options={backupTypeOptions}
      />
      <IntegerInput
        testId={FieldTestIds.RetentionInDays}
        required
        label={Messages.labels.retentionPeriodDays()}
        fieldName={Fields.RetentionInDays}
        defaultValue={mdsBackupDefaultRetentionInDays}
      />
      <FieldSet header={Messages.labels.tags()}>
        <TagsCollection fieldName={Fields.FreeformTags} testId={FieldTestIds.FreeformTags} />
      </FieldSet>
    </InputFormSidePanel>
  );
};
