import { FieldSet, InputWizardPanelComponent } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { ExadataDbSystemShapeSelect } from "../../components/DbSystemShapeSelect/ExadataDbSystemShapeSelect";

export const GROUP_CONFIG = "config";

export enum Fields {
  ExadataSystemModel = "cloudExadataInfrastructureDetails.shape",
}

export enum FieldTestIds {
  ExadataSystemModel = "exaDataSystemModel"
}

const defaultShapeName = "Exadata.X8M";

export interface ConfigTabContentProps {
  inputWizardPanelRef: InputWizardPanelComponent;
  subscriptionId: string;
  location: string;
}

export const ConfigTabContent = (
  { inputWizardPanelRef, subscriptionId, location }: ConfigTabContentProps,
): JSX.Element => {
  const onError = (): void => inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.general());
  const onMissingDependencies = (): void => inputWizardPanelRef.showErrorDialog(
    Messages.createCommon.dependencyWarnings.basicsIncomplete.message(),
    Messages.createCommon.dependencyWarnings.basicsIncomplete.title(),
  );

  return (
    <FieldSet
      header={Messages.createInfra.configurationTab.sectionTitles.infrastructureDetail()}
    >
      <ExadataDbSystemShapeSelect
        testId={FieldTestIds.ExadataSystemModel}
        fieldName={Fields.ExadataSystemModel}
        label={Messages.labels.exadataSystemModel()}
        required
        defaultValue={[defaultShapeName]}
        subscriptionId={subscriptionId}
        location={location}
        tooltip={Messages.hints.selectAExadataBase()}
        onError={onError}
        onMissingDependencies={onMissingDependencies}
      />
    </FieldSet>
  );
};
