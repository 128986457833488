import { ActionType, Listing, ListingColumn } from "o4a-react";
import * as React from "react";
import { Stack, Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds } from "../../constants/uiConstants";
import { VmdbDatabase, VmdbPluggableDatabase } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { IdResourceType, parseId } from "../../helpers/idHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useSidePanel } from "../../hooks/useSidePanel";
import { newVmDbChangePassword, VmDbChangePasswordProps } from "../../operations/VmDb/VmDbChangePassword";
import { newVmDbChangeTdePassword, VmDbChangeTdePasswordProps } from "../../operations/VmDb/VmDbChangeTdePassword";

export enum ActionIds {
  ChangePassword = "change-password",
  ChangeTdePassword = "change-tde-password",
  CopyConnectionString = "copy-connection-string",
}

export enum ActionBarTestIds {
  ChangePassword = "change-password",
  ChangeTdePassword = "change-tde-password",
}

export enum ListingActionTestIds {
  CopyConnectionString = "copy-connection-string",
}

enum ColumnIds {
  ConnectionString = "connectionString",
  Id = "id",
}

export interface VmDbConnectDetailsProps {
  database: VmdbDatabase | VmdbPluggableDatabase | undefined;
  location: string;
  refresh: () => void;
}

export const VmDbConnectDetails = ({ database, location, refresh }: VmDbConnectDetailsProps): JSX.Element => {
  const { resourceType } = parseId(database?.id);
  const isCdb = resourceType === IdResourceType.DATABASES;

  const { trackActionClick, trackActionDiscard } = useAnalytics();
  const pageId = isCdb ? PageId.VMDB_CDB_DETAILS : PageId.VMDB_PDB_DETAILS;

  const connectionStrings = Object.keys(database?.connectionStrings?.allConnectionStrings || {}).map(value => ({
    id: value,
    connectionString: database?.connectionStrings?.allConnectionStrings?.[value],
  }));

  const { trigger: triggerChangePassword } = useOperation<VmDbChangePasswordProps>(newVmDbChangePassword);
  const { trigger: triggerChangeTdePassword } = useOperation<VmDbChangeTdePasswordProps>(newVmDbChangeTdePassword);

  const { closePanels } = useSidePanel();

  const connectStringsActionItems: ActionType[] = [
    {
      key: ActionIds.ChangePassword,
      testId: ActionBarTestIds.ChangePassword,
      text: Messages.actions.changePassword(),
      icon: MonochromeIconIds.Permissions,
      onClick: () => {
        trackActionClick(ActionIds.ChangePassword, pageId, DetailsPanelId.CONNECT);
        triggerChangePassword({
          databaseId: database?.id,
          targetId: ActionIds.ChangePassword,
          location,
          onExecute: () => refresh(),
          onCancel: () => trackActionDiscard(ActionIds.ChangePassword, pageId, DetailsPanelId.CONNECT),
        });
      },
    },
    {
      key: ActionIds.ChangeTdePassword,
      testId: ActionBarTestIds.ChangeTdePassword,
      text: Messages.actions.changeTdePassword(),
      icon: MonochromeIconIds.Permissions,
      onClick: () => {
        trackActionClick(ActionIds.ChangeTdePassword, pageId, DetailsPanelId.CONNECT);
        triggerChangeTdePassword({
          databaseId: database?.id,
          targetId: ActionIds.ChangeTdePassword,
          location,
          onExecute: () => refresh(),
          onCancel: () => trackActionDiscard(ActionIds.ChangeTdePassword, pageId, DetailsPanelId.CONNECT),
        });
      },
    },
  ];

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Id,
      name: Messages.labels.tnsName(),
      flexGrow: 1,
    },
    {
      itemProp: ColumnIds.ConnectionString,
      name: Messages.labels.connectionString(),
      flexGrow: 3,
    },
  ];

  return (
    <Stack style={{ height: "100%" }}>
      <Text variant="mediumPlus">
        {Messages.detailVmDb.connect.sectionTitles.connectionStrings()}
      </Text>
      <br />
      <Listing
        testId={ListingTestIds.VmDbConnect}
        actionBarItems={isCdb ? connectStringsActionItems : undefined}
        onActionClick={closePanels}
        items={connectionStrings}
        emptyList={{ title: Messages.common.noResults() }}
        listColumns={columns}
        actions={[
          {
            key: ActionIds.CopyConnectionString,
            testId: ListingActionTestIds.CopyConnectionString,
            text: Messages.actions.copyConnectionString(),
            icon: MonochromeIconIds.Copy,
            onClick: value => {
              trackActionClick(ActionIds.CopyConnectionString, pageId, DetailsPanelId.CONNECT);
              navigator.clipboard.writeText(value?.connectionString || "");
            },
          },
        ]}
      />
    </Stack>
  );
};
