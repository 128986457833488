/* eslint-disable react-hooks/exhaustive-deps */
import { InfoBlock, InfoBlockStatus, Listing, ListingColumn, optimizedRetryOption, SelectionMode } from "o4a-react";
import * as React from "react";
import { DetailsRow, FontIcon, GroupedList, IColumn, Icon, IGroup, IGroupHeaderProps, Stack } from "@fluentui/react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { SvgIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  CloudVmClusterSummary,
  CloudVmClusterSummaryCollection,
  DatabaseSummary,
  DatabaseSummaryCollection,
  PluggableDatabaseSummary,
  PluggableDatabaseSummaryCollection,
} from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import { responseItemstoArray } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

interface GroupedResource {
  displayName: string;
}

export enum ColumnIds {
  ResourceName = "resourceName",
  ResourceType = "resourceType",
}

export enum InfoBlockTestIds {
  ErrorInfo = "error-info",
}

export interface DeleteExaInfraResourcesListProps {
  exaInfraId: string;
  location: string;
  setIsLoading: (isLoading: boolean) => void;
}

/**
 *
 *
 * NEED TO SUPPORT DELETE ADBD VM CLUSTER WHEN THE FEATURE IS ENABLED
 *
 *
 */

export const DeleteExaInfraResourcesList = ({
  exaInfraId,
  location,
  setIsLoading,
}: DeleteExaInfraResourcesListProps): JSX.Element => {
  const [groups, setGroups] = React.useState<IGroup[]>([]);
  const [hasError, setHasError] = React.useState(false);
  const { resourceGroup, subscriptionId } = parseId(exaInfraId);

  const {
    response: listVmClustersResponse,
    loading: listVmClustersLoading,
    error: listVmClustersLoadError,
  } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listCloudVmClusters,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resourceGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVMClusters(),
      },
    },
  });

  const vmClusterItems = listVmClustersResponse
    && responseItemstoArray<CloudVmClusterSummaryCollection, CloudVmClusterSummary>(listVmClustersResponse);

  React.useEffect(() => {
    if (!listVmClustersLoading && listVmClustersLoadError) {
      setHasError(true);
    }
  }, [listVmClustersLoading, listVmClustersLoadError]);

  const { response: cdbResponse, loading: cdbLoading, error: cdbError } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDatabases,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resourceGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDatabases(),
      },
    },
  });

  const cdbItems = cdbResponse
    && responseItemstoArray<DatabaseSummaryCollection, DatabaseSummary>(cdbResponse);

  const { response: pdbResponse, loading: pdbLoading, error: pdbError } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listPluggableDatabases,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resourceGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadExaDbPdbs(),
      },
    },
  });

  const pdbItems = pdbResponse
    && responseItemstoArray<PluggableDatabaseSummaryCollection, PluggableDatabaseSummary>(pdbResponse);

  React.useEffect(() => {
    if (!cdbLoading && cdbError && !listVmClustersLoading && !listVmClustersLoadError) {
      setHasError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdbLoading, cdbError, listVmClustersLoading, listVmClustersLoadError]);

  React.useEffect(() => {
    if (!pdbLoading && pdbError && !listVmClustersLoading && !listVmClustersLoadError) {
      setHasError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdbLoading, pdbError, listVmClustersLoading, listVmClustersLoadError]);

  const items = React.useMemo(() => {
    let groupItems: GroupedResource[] = [];
    vmClusterItems?.forEach(vmClusterItem => {
      const vmClusters: GroupedResource[] = [];
      const cdbs: GroupedResource[] = [];
      const pdbs: GroupedResource[] = [];
      if (vmClusterItem.cloudExadataInfrastructureId === exaInfraId) {
        const vmClusterId = vmClusterItem.id;
        vmClusters.push({ displayName: vmClusterItem.displayName || "" });
        const cdbMap: { [key: string]: DatabaseSummary } = {};
        cdbItems?.forEach(cdbItem => {
          if (cdbItem.vmClusterId === vmClusterId) {
            cdbMap[cdbItem.id] = cdbItem;
            cdbs.push({ displayName: cdbItem.name || "" });
          }
        });
        pdbItems?.forEach(pdbItem => {
          if (cdbMap[pdbItem.containerDatabaseId]) {
            pdbs.push({ displayName: pdbItem.name });
          }
        });
        groupItems = groupItems.concat(vmClusters, cdbs, pdbs);
        setGroups([{ key: "vmCluster", name: vmClusterItem.name, startIndex: 1, count: cdbs.length + pdbs.length }]);
      }
    });
    return groupItems;
  }, [JSON.stringify(vmClusterItems), JSON.stringify(cdbItems), JSON.stringify(pdbItems)]);

  React.useEffect(() => {
    setIsLoading(listVmClustersLoading || cdbLoading || pdbLoading);
  }, [listVmClustersLoading, cdbLoading, pdbLoading]);

  const getErrorMessage = (): string => {
    let errorMessage = "";
    if (listVmClustersLoadError) {
      errorMessage = Messages.createNewPanels.deleteExaInfra.vmClusterNotLoaded();
    } else if (!listVmClustersLoadError && (cdbError || pdbError)) {
      errorMessage = Messages.createNewPanels.deleteExaInfra.databaseNotLoaded();
    }
    return errorMessage;
  };

  const groupColumns: IColumn[] = [{
    key: "displayName",
    name: "displayName",
    fieldName: "displayName",
    minWidth: 300,
  }];

  const listingColumns: ListingColumn[] = [
    {
      itemProp: ColumnIds.ResourceName,
      name: Messages.labels.resourceToBeDeleted(),
      flexGrow: 2,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <>
          <Icon
            iconName={SvgIconIds.infraSvg}
            styles={{
              root: {
                width: "24px",
                height: "18px",
                top: "-2px",
                position: "absolute",
                display: "flex",
              },
            }}
          />
          <span style={{ marginLeft: "30px" }}>
            {value.resourceName}
          </span>
        </>
      ),
    },
    {
      itemProp: ColumnIds.ResourceType,
      name: Messages.labels.resourceType(),
      flexGrow: 1,
    },
  ];

  const onRenderCell = (
    nestingDepth?: number,
    item?: GroupedResource,
    itemIndex?: number,
    group?: IGroup,
  ): React.ReactNode => (
    item && typeof itemIndex === "number" && itemIndex > -1 ? (
      <DetailsRow
        columns={groupColumns}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex}
        selectionMode={SelectionMode.none}
        compact
        group={group}
      />
    ) : null);

  const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element | null => {
    if (props) {
      const toggleCollapse = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        props.onToggleCollapse!(props.group!);
      };
      return (
        <Stack horizontal style={{ marginLeft: "10px" }}>
          <FontIcon
            iconName={props.group?.isCollapsed ? "ChevronDownSmall" : "ChevronUpSmall"}
            onClick={toggleCollapse}
            style={{ marginTop: "5px" }}
          />
          <div style={{ marginLeft: "10px", fontSize: "16px" }}>
            {`${props.group?.name} (${props.group?.count} ${Messages.labels.databases()})`}
          </div>
        </Stack>
      );
    }
    return null;
  };

  return (
    <Stack>
      <Stack style={{ height: "90px" }}>
        <Listing
          items={[{ resourceName: parseId(exaInfraId).resourceName, resourceType: Messages.labels.exadataInfra() }]}
          listColumns={listingColumns}
        />
      </Stack>
      <Stack tokens={{ childrenGap: 20 }}>
        <span style={{ fontWeight: 400, fontSize: "19px" }}>
          {items.length > 1
            ? Messages.labels.containedVmCluster()
            : Messages.labels.containedVmClusters()}
        </span>
        {hasError && (
          <InfoBlock
            testId={InfoBlockTestIds.ErrorInfo}
            messageType={InfoBlockStatus.ERROR}
            message={getErrorMessage()}
          />
        )}
        {items.length > 0 && (
          <Stack style={{ height: `calc(${window.innerHeight}px - 531px)`, overflow: "auto" }}>
            <GroupedList
              items={items}
              onRenderCell={onRenderCell}
              selectionMode={SelectionMode.none}
              groups={groups}
              groupProps={{ onRenderHeader }}
              compact
              styles={{ root: { height: "302px" } }}
            />
          </Stack>
        )}
        {items.length === 0 && (
          <span>
            {Messages.createNewPanels.deleteExaInfra.noVmCluster()}
          </span>
        )}
      </Stack>
    </Stack>
  );
};
