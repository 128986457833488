import { NotificationRequest, NotificationType, useNotificationRequest } from "o4a-react";
import * as React from "react";
import { Query, QueryResult, useQuery } from "savant-connector";

export interface QueryCall<Args, Resp> extends Query<Args, Resp> {
  notification?: {
    failure: {
      title: string;
      message: string;
    },
    excludeErrorStatus?: number[];
  }
}

export function useQueryCall<Args, Resp>({
  method,
  options,
  wait,
  notification,
}: QueryCall<Args, Resp>): QueryResult<Resp> {
  const { submit: submitNotificationRequest } = useNotificationRequest();

  const { response, loading, refresh, error } = useQuery({
    wait,
    method,
    options,
  });

  // ****************************************************************
  // Skip returning any existing error from previously cached result
  // until the call is being made which will happen after the hook
  // is being mounted.
  // ****************************************************************

  const [isMounted, setIsMounted] = React.useState<boolean>(!error);

  const retError = isMounted ? error : undefined;
  const retResp = isMounted ? response : undefined;

  React.useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, [isMounted]);

  React.useEffect(() => {
    if (notification
      && isMounted
      && !loading
      && retError
      && !notification.excludeErrorStatus?.includes(retError.status)
    ) {
      submitNotificationRequest({
        type: NotificationType.FAILURE,
        title: notification.failure.title,
        message: notification.failure.message,
        apiError: retError.body.message,
      } as NotificationRequest);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, loading, retError]);

  return { response: retResp, loading, error: retError, refresh };
}
