import { ReviewInfo, ReviewSection } from "o4a-react";
import * as React from "react";

interface ReviewInfoProps {
  reviewSections: ReviewSection[];
}

export const ReviewTabContent = ({ reviewSections }: ReviewInfoProps): JSX.Element => {
  const reviewInfo = (
    <ReviewInfo reviewSections={reviewSections} />
  );
  return (reviewInfo);
};
