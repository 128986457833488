import { CalloutComponent, FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Fields as UpdatePasswordFields, UpdatePassword } from "../../components/UpdatePassword/UpdatePassword";
import { parseId } from "../../helpers/idHelper";
import { validateTdePassword } from "../../helpers/validationHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_CDB_UPDATE_POLL_DELAY,
  VMDB_CDB_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface VmDbChangeTdePasswordProps extends OperationActionProps {
  databaseId: string | undefined;
}

const VmDbChangeTdePassword = (
  { targetId, location, databaseId, onExecute, onCancel }: VmDbChangeTdePasswordProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName: databaseName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");

  const { invokeCall: invokePasswordUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.updateVmdbDatabase },
  );
  const [passwordTdeCalloutRef, setPasswordTdeCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const onUpdateTdePassword = (formValues: FormValues): void => {
    const oldTdePassword = getValue<string>(formValues, UpdatePasswordFields.OldPassword, InputFormGroup);
    const newTdePassword = getValue<string>(formValues, UpdatePasswordFields.Password, InputFormGroup);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: passwordTdeCalloutRef.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateVmTdePassword(),
          message: Messages.notifications.inProgress.messages.updateVmTdePassword(databaseName),
        },
        success: {
          title: Messages.notifications.success.titles.updateVmTdePassword(),
          message: Messages.notifications.success.messages.updateVmTdePassword(databaseName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateVmTdePassword(),
          message: Messages.notifications.failure.messages.updateVmTdePassword(databaseName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_CDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_UPDATED_CHECK_CDB,
          delay: VMDB_CDB_UPDATE_POLL_DELAY,
          interval: VMDB_CDB_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokePasswordUpdate({
      databaseName,
      resourceGroupName: resGroup,
      subscriptionId,
      updateDatabaseDetails: { oldTdeWalletPassword: oldTdePassword, newTdeWalletPassword: newTdePassword },
    }, invokeOptions);
  };

  return (
    <UpdatePassword
      componentRef={setPasswordTdeCalloutRef}
      targetId={targetId}
      onClose={onCancel}
      onSubmit={onUpdateTdePassword}
      validator={validateTdePassword}
      title={Messages.createNewPanels.changeTdePassword.title()}
      oldPassword={{ label: Messages.labels.oldTdeWalletPassword() }}
      passwordLabel={Messages.labels.newTdeWalletPassword()}
      confirmPasswordLabel={Messages.labels.newTdeWalletConfirmPassword()}
      tooltip={Messages.hints.tooltipTdePassword()}
    />
  );
};

export const newVmDbChangeTdePassword = (
  props: VmDbChangeTdePasswordProps,
): JSX.Element => (<VmDbChangeTdePassword {...props} />);
