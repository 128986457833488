import {
  ActionType,
  ConfirmAction,
  CustomAction,
  EmptyListingButtonTypes,
  ExtraFilter,
  FavoriteAccessItem,
  FilterAll,
  FilterComponent,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingDisplayNameLink,
  ListingSelectOption,
  Operator,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
  useFavoriteAccess,
  useNavigation,
  ViewSelectOption,
} from "o4a-react";
import * as React from "react";
import { useParams } from "react-router-dom";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  DetailsPanelId,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
  VMCLUSTER_CREATE_ROUTE,
} from "../../constants/pluginConstants";
import {
  emptyChar,
  ListingTestIds,
  MonochromeIconIds,
  SvgIconIds,
  ttlOneMinCaching,
  ViewKeys,
} from "../../constants/uiConstants";
import { AzureSubscriptionSummary } from "../../gen/clients/mchub-azure-api-client";
import {
  CloudVmClusterSummary,
  CloudVmClusterSummaryCollection,
  CloudVmClusterSummaryStatusEnum,
} from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import {
  getFieldCustomOptions,
  getStatusInfo,
  responseItemstoArray,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFilterStatePills } from "../../hooks/useFilterStatePills";
import { useLocationFilters } from "../../hooks/useLocationFilters";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { ExaVmClusterDeleteProps, newExaVmClusterDelete } from "../../operations/ExaVmCluster/ExaVmClusterDelete";
import { getAzureLocationName, getOciRegion } from "../../utils";

const detailsPageRegistrationIds = PageRegistrationConfig[PageId.VMCLUSTER_DETAILS].map(config => config.key);
const infraDetailsPageRegistrationIds = PageRegistrationConfig[PageId.EXAINFRA_DETAILS].map(config => config.key);

const getInfrastructureLink = (value: VmClusterItem): JSX.Element => {
  const { resourceName } = parseId(value.infrastructureId);
  return (
    <ListingDisplayNameLink
      displayName={resourceName}
      navigation={{
        // eslint-disable-next-line max-len
        to: `${RESOURCE_ROUTE}/${value.infrastructureId}/${PageRegistrationConfig[PageId.EXAINFRA_DETAILS][0].panelPath}?location=${value.location}`,
        pageKey: infraDetailsPageRegistrationIds[0],
      }}
    />
  );
};

interface VmClusterItem {
  id: string;
  displayName: string;
  subscriptionName: string;
  subscriptionId: string;
  resourceGroupName: string;
  location: string;
  status?: CloudVmClusterSummaryStatusEnum;
  infrastructureId?: string;
  infrastructureName?: string;
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
  lifecycleDetails?: string;
  freeformTags?: { [key: string]: string; };
  isFavorite: boolean;
}

interface AzureSubscriptionSummaryMap {
  [key: string]: AzureSubscriptionSummary;
}

export enum ColumnIds {
  DisplayName = "displayName",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  ResourceStatus = "status",
  Infrastructure = "infrastructureName",
  FreeformTags = "freeformTags",
}

export enum ColumnTestIds {
  DisplayName = "displayName",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  ResourceStatus = "status",
  Infrastructure = "infrastructureName",
  FreeformTags = "freeformTags",
}

export enum ActionBarIds {
  Create = "action-bar-create",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
}

export enum ActionMenuIds {
  Delete = "action-menu-delete",
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum ActionBarTestIds {
  Create = "action-bar-create",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
}

export enum ActionMenuTestIds {
  Delete = "action-menu-delete",
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum FilterTestIds {
  Subscription = "Subscription",
  Location = "Location",
}

export interface ExaVmClusterListProps {
  disabled?: boolean;
}

export const ExaVmClusterList = ({ disabled }: ExaVmClusterListProps): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { favoriteItems, isFavorite, setAsFavorite, removeFromFavorites } = useFavoriteAccess();

  const { navigateTo } = useNavigation(ConsoleContext);
  // eslint-disable-next-line max-len
  const { preferredSubscription, locale } = React.useContext<Settings>(SettingsContext);
  const filterState = React.useContext<FilterState>(FilterStateContext);
  const { listId } = useParams();

  const [selection, setSelection] = React.useState<VmClusterItem[]>([]);
  const [selectedSubscription, setSelectedSubscription] = React.useState<string>("");
  const [subscriptionOptions, setSubscriptionOptions] = React.useState<{ key: string, text: string }[]>([]);

  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();

  const { locationOptions, selectedLocation, setSelectedLocation } = useLocationFilters(
    filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
  );

  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<VmClusterItem>>();

  const [filterComponentRef, setFilterComponentRef] = React.useState<FilterComponent>();
  const pillFilterValues = filterComponentRef?.getFilters();
  const filterTextValue = filterComponentRef?.getFilterText();
  const [filterText, setFilterText] = React.useState(filterState.filterTextValue || filterTextValue);

  const { perPageItemsCount } = useRowCount();

  const groupingOptions: ListingSelectOption<VmClusterItem>[] = [];

  const viewOptions: ViewSelectOption<VmClusterItem>[] = [
    {
      key: ViewKeys.ListView,
      text: Messages.listVmCluster.filters.listView(),
      icon: MonochromeIconIds.List,
    },
  ];

  const getLocation = (value: VmClusterItem): string => getAzureLocationName(value.location);

  const getStatus = (value: VmClusterItem): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
          value?.lifecycleDetails,
        )
      }
      hideClipboardCopy
    />
  );

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.DisplayName,
      testId: ColumnTestIds.DisplayName,
      name: Messages.common.name(),
      flexGrow: 3,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.displayName}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.VMCLUSTER_DETAILS][0].panelPath}?location=${value.location}`,
            pageKey: detailsPageRegistrationIds[0],
          }}
          iconName={SvgIconIds.vmClusterSvg}
        />
      ),
    },
    {
      itemProp: ColumnIds.SubscriptionName,
      testId: ColumnTestIds.SubscriptionName,
      name: Messages.labels.subscription(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, max-len
      onRenderItems: value => <AzureSubscriptionLink resourceId={value.id} subscriptionName={value.subscriptionName} hideClipboard />,
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: VmClusterItem,
        b: VmClusterItem,
      ) => statusSortComparator<VmClusterItem>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.Infrastructure,
      testId: ColumnTestIds.Infrastructure,
      name: Messages.labels.infra(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getInfrastructureLink(value),
    },
  ];

  const {
    refresh: vmClusterRefresh,
    loading: vmClusterLoading,
    response: vmClusterResponse,
  } = useQueryCall({
    wait: (!selectedSubscription || !selectedLocation) || disabled,
    method: apiClients.withRegion(
      getOciRegion(selectedLocation),
    ).exaDatabaseApi.listCloudVmClustersBySubscription,
    options: {
      args: {
        subscriptionId: selectedSubscription,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVMCluster(),
      },
    },
  });

  const vmClusters = vmClusterResponse
    && responseItemstoArray<CloudVmClusterSummaryCollection, CloudVmClusterSummary>(vmClusterResponse);

  const items = React.useMemo(() => {
    const newVmClusters: VmClusterItem[] = [];

    if (vmClusters && subscriptions && subscriptions.length > 0) {
      const subscriptionsMap = subscriptions.reduce((
        map: AzureSubscriptionSummaryMap,
        subscription: AzureSubscriptionSummary,
      ) => {
        map[subscription.id] = subscription;
        return map;
      }, {});
      vmClusters.forEach(vmCluster => {
        const { subscriptionId, resourceGroup } = parseId(vmCluster.id);
        const vmClusterSubscriptionName = subscriptionsMap && subscriptionsMap[subscriptionId]?.name;
        if (vmCluster.lifecycleState !== "TERMINATED") {
          const { resourceName: infraName } = parseId(vmCluster.cloudExadataInfrastructureId);
          newVmClusters.push({
            id: vmCluster.id,
            displayName: vmCluster.name,
            subscriptionName: vmClusterSubscriptionName,
            subscriptionId,
            resourceGroupName: resourceGroup,
            location: vmCluster.location,
            status: vmCluster.status,
            infrastructureId: vmCluster.cloudExadataInfrastructureId,
            infrastructureName: infraName,
            lifecycleDetails: vmCluster.lifecycleDetails,
            freeformTags: vmCluster.freeformTags,
            isFavorite: isFavorite(vmCluster.id),
          });
        }
      });
    }
    return newVmClusters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(vmClusters), JSON.stringify(subscriptions), JSON.stringify(favoriteItems)]);

  const { trigger: triggerDeleteExaVmCluster } = useOperation<ExaVmClusterDeleteProps>(newExaVmClusterDelete);
  const { closePanels } = useSidePanel();

  const setSelectedItems = (selectedItems: VmClusterItem[]): void => {
    setSelection(selectedItems);
  };

  const confirmDeleteExaVmClusterAction = (selectedVmCluster: VmClusterItem) : void => {
    triggerDeleteExaVmCluster({
      vmClusterId: selectedVmCluster.id,
      location: selectedLocation,
      onExecute: () => {
        vmClusterRefresh();
        listingComponentRef?.resetTableSelection();
      },
    });
  };

  const buildDeleteAction = (key:string, testId: string, selectedItem: VmClusterItem)
  : ConfirmAction<VmClusterItem | undefined> => {
    const deleteAction = {
      key,
      testId,
      disabled: !selectedItem,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.labels.deleteVMCluster(),
      description: Messages.hints.deleteVMClusterConfirmation(selectedItem?.displayName),
      onClick: () => trackActionClick(key, PageId.EXA_LIST_HUB, DetailsPanelId.VMCLUSTERS),
      onConfirm: () => confirmDeleteExaVmClusterAction(selectedItem),
      onCancel: () => trackActionDiscard(key, PageId.EXA_LIST_HUB, DetailsPanelId.VMCLUSTERS),
    };
    return deleteAction;
  };

  const vmClusterRowActionMenus = (item?: VmClusterItem | undefined): ActionType<VmClusterItem>[] => {
    const vmClusterActionItems: ActionType<VmClusterItem | undefined>[] = [];
    if (item) {
      if (item.isFavorite) {
        vmClusterActionItems.push({
          key: ActionMenuIds.RemoveFavorite,
          testId: ActionMenuTestIds.RemoveFavorite,
          text: Messages.actions.removeFromFavorites(),
          icon: MonochromeIconIds.FavoriteSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.RemoveFavorite, PageId.EXA_LIST_HUB, DetailsPanelId.VMCLUSTERS);
            removeFromFavorites(item.id);
          },
        } as CustomAction<VmClusterItem | undefined>);
      } else {
        vmClusterActionItems.push({
          key: ActionMenuIds.AddFavorite,
          testId: ActionMenuTestIds.AddFavorite,
          text: Messages.actions.addToFavorites(),
          icon: MonochromeIconIds.FavoriteNotSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.AddFavorite, PageId.EXA_LIST_HUB, DetailsPanelId.VMCLUSTERS);
            setAsFavorite({ id: item.id, data: { location: item.location } } as FavoriteAccessItem);
          },
        } as CustomAction<VmClusterItem | undefined>);
      }

      vmClusterActionItems.push(
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return vmClusterActionItems;
  };

  const vmClusterBarActions: ActionType<VmClusterItem>[] = [
    {
      key: ActionBarIds.Create,
      testId: ActionBarTestIds.Create,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        trackActionClick(ActionBarIds.Create, PageId.EXA_LIST_HUB, DetailsPanelId.VMCLUSTERS);
        navigateTo(VMCLUSTER_CREATE_ROUTE, PageId.VMCLUSTER_CREATE, {
          analytics: {
            pageId: PageId.EXA_LIST_HUB,
            panelId: DetailsPanelId.VMCLUSTERS,
            actionName: ActionBarIds.Create,
          } as NavigationAnalyticsData,
        });
      },
    },
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      disabled,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, PageId.EXA_LIST_HUB, DetailsPanelId.VMCLUSTERS);
        vmClusterRefresh();
        listingComponentRef?.resetTableSelection();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  React.useEffect(() => {
    const newSubscriptionOptions: { key: string, text: string }[] = [];
    // eslint-disable-next-line no-unused-expressions
    subscriptions && subscriptions.forEach(subscription => {
      newSubscriptionOptions.push({ key: subscription.id, text: subscription.name });
    });

    setSubscriptionOptions(newSubscriptionOptions);
    if (filterState.defaultSubscription) {
      setSelectedSubscription(filterState.defaultSubscription);
      if (defaultExtraFilters) defaultExtraFilters[0].value = filterState.defaultSubscription;
    } else if (preferredSubscription) {
      setSelectedSubscription(preferredSubscription);
    } else {
      setSelectedSubscription(newSubscriptionOptions.length ? newSubscriptionOptions[0].key : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions)]);

  const subscriptionFilter: ExtraFilter = {
    testId: FilterTestIds.Subscription,
    name: columns[1].name,
    operator: Operator.Equal,
    value: filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
    fieldKey: columns[1].itemProp,
    customOptions: subscriptionOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedSubscription(value);
        filterState.setDefaultSubscription(value);
      }
    },
    hideDelete: true,
  };

  const resourceGroupFilter: ExtraFilter = {
    name: columns[2].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[2].itemProp)?.value || [FilterAll],
    fieldKey: columns[2].itemProp,
    customOptions: getFieldCustomOptions(items?.map(item => item.resourceGroupName), (fieldVal: string) => fieldVal),
  };

  React.useEffect(() => {
    if (filterState.defaultLocation) {
      setSelectedLocation(filterState.defaultLocation);
      if (defaultExtraFilters) defaultExtraFilters[1].value = filterState.defaultLocation;
    } else {
      setSelectedLocation(filterState.pillFilterStateValue.filterValues?.[1]?.value || selectedLocation);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locationFilter: ExtraFilter = {
    testId: FilterTestIds.Location,
    name: columns[3].name,
    operator: Operator.Equal,
    value: filterState.defaultLocation ? filterState.defaultLocation : selectedLocation,
    fieldKey: columns[3].itemProp,
    customOptions: locationOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedLocation(value);
        filterState.setDefaultLocation(value);
      }
    },
    hideDelete: true,
  };

  const lifecycleStateFilter: ExtraFilter = {
    name: columns[4].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[4].itemProp)?.value || [FilterAll],
    fieldKey: columns[4].itemProp,
    messageCallBack: stateT,
    customOptions: getFieldCustomOptions(
      items?.map(item => item.status || ""),
      (fieldVal: string) => {
        if (fieldVal) {
          return stateT(fieldVal);
        }
        return emptyChar;
      },
    ),
  };

  const infraFilter: ExtraFilter = {
    name: columns[5].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[5].itemProp)?.value || [FilterAll],
    fieldKey: columns[5].itemProp,
    customOptions: getFieldCustomOptions(
      items?.map(item => item.infrastructureName || ""),
      (fieldVal: string) => {
        if (fieldVal) {
          return fieldVal;
        }
        return emptyChar;
      },
    ),
  };

  const allFilterPills = [
    subscriptionFilter,
    locationFilter,
    resourceGroupFilter,
    lifecycleStateFilter,
    infraFilter,
  ];

  const filterPills: ExtraFilter[] = [
    subscriptionFilter,
    locationFilter,
  ];

  const textFilterCallBack = (rowItem: VmClusterItem): VmClusterItem => {
    const displayRowItem = { ...rowItem };
    displayRowItem.status = stateT(
      displayRowItem.status || "",
    ) as CloudVmClusterSummaryStatusEnum;
    displayRowItem.location = getAzureLocationName(displayRowItem.location);
    return displayRowItem;
  };

  React.useEffect(() => {
    setFilterText(filterTextValue);
    filterState.setFilterTextValue(filterTextValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTextValue]);

  const defaultExtraFilters = useFilterStatePills(pillFilterValues, allFilterPills, listId, filterPills);

  const resetFilterValues = (): void => {
    const defaultPillValues = filterPills.map(filter => ({ fieldKey: filter.fieldKey, value: filter.value as string }));
    filterState.setFilterStateValue({ filterValues: defaultPillValues, listId, defaultPills: filterPills });
    filterState.setFilterTextValue("");
    setFilterText("");
  };

  return (
    <Listing
      testId={ListingTestIds.ExaVmCluster}
      listingComponentRef={setListingComponentRef}
      items={items}
      listColumns={columns}
      itemsPerPage={perPageItemsCount}
      actionBarItems={vmClusterBarActions as ActionType<undefined>[]}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      actions={vmClusterRowActionMenus}
      emptyList={{
        title: Messages.listVmCluster.emptyList.title(),
        emptyButtons: [
          {
            type: EmptyListingButtonTypes.PRIMARY,
            text: Messages.actions.createVMCluster(),
            action: () => navigateTo(VMCLUSTER_CREATE_ROUTE, PageId.VMCLUSTER_CREATE),
          },
        ],
        watermark: SvgIconIds.vmClusterWaterMarkSvg,
      }}
      isLoading={(vmClusterLoading || subscriptionLoading) && !disabled}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.DisplayName,
      }}
      groupingSelect={{ groupingOptions }}
      viewSelect={{
        viewOptions,
        defaultSelectedKey: ViewKeys.ListView,
      }}
      filtering={{
        allExtraFilters: allFilterPills,
        defaultExtraFilters,
        defaultFilterText: filterText,
        textFilterCallBack,
        componentRef: setFilterComponentRef,
        onClearFilters: resetFilterValues,
      }}
    />
  );
};
