import {
  AsyncNotificationRequest,
  AsyncValidationError,
  InputWizardPanelComponent,
  NotificationRequest,
  NotificationType,
  uniqueGUID,
  useNavigation,
  useNotificationRequest,
} from "o4a-react";
import * as React from "react";
import { useMutation } from "savant-connector";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { emptyDivTestId } from "../../constants/uiConstants";
import { errorMap2AsyncValidationErrors, PreflightError } from "../../helpers/validationHelper";
import { getOciRegion } from "../../utils";

export enum DeployedService {
  APEX = "APEX",
  AUTONOMOUS_SHARED_DATABASE = "AUTONOMOUS_SHARED_DATABASE",
  EXADATA_DATABASE = "EXADATA_DATABASE",
  EXADATA_INFRA = "EXADATA_INFRA",
  EXADATA_VM_CLUSTER = "EXADATA_VM_CLUSTER",
  EXADATA_SYSTEM = "EXADATA_SYSTEM",
  MYSQL_DATABASE = "MYSQL_DATABASE",
  VM_DATABASE = "VM_DATABASE",
}

export const getDeployedServiceName = (resourceType: DeployedService): string => {
  switch (resourceType) {
    case DeployedService.APEX:
      return Messages.notifications.deployedServices.apex();
    case DeployedService.AUTONOMOUS_SHARED_DATABASE:
      return Messages.notifications.deployedServices.autonomousSharedDatabase();
    case DeployedService.EXADATA_DATABASE:
      return Messages.notifications.deployedServices.exadataDatabase();
    case DeployedService.EXADATA_VM_CLUSTER:
      return Messages.notifications.deployedServices.exadataVmCluster();
    case DeployedService.EXADATA_INFRA:
      return Messages.notifications.deployedServices.exadataInfra();
    case DeployedService.EXADATA_SYSTEM:
      return Messages.notifications.deployedServices.exadataSystem();
    case DeployedService.MYSQL_DATABASE:
      return Messages.notifications.deployedServices.mysqlDatabase();
    case DeployedService.VM_DATABASE:
      return Messages.notifications.deployedServices.vmDatabase();
    default:
      return resourceType;
  }
};

export interface DeploymentAsyncNotification {
  methodKey: string;
  delay: number;
  pollingInterval: number;
  polledResponseKey: string;
}

export interface DeploymentCreateProps {
  panelRef: InputWizardPanelComponent;
  location: string;
  deployedService: DeployedService;
  deploymentName: string;
  subscriptionId: string;
  resourceGroupName: string;
  resourceName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitPayload: any;
  submitReject: (errorMessage: string, validationErrors?: AsyncValidationError[]) => void;
  onProcessAsyncValidationErrors?: (asyncErrors: AsyncValidationError[]) => AsyncValidationError[];
  asyncNotification: DeploymentAsyncNotification;
  onPostSubmit: () => void;
}

export const DeploymentCreatePhase = ({
  panelRef,
  location,
  deployedService,
  deploymentName,
  subscriptionId,
  resourceGroupName,
  resourceName,
  submitPayload,
  submitReject,
  onProcessAsyncValidationErrors,
  asyncNotification,
  onPostSubmit,
}: DeploymentCreateProps): JSX.Element => {
  const resourceType = getDeployedServiceName(deployedService);

  const unitId = React.useRef<string>();

  const { submit: submitNotificationRequest, submitAsync } = useNotificationRequest();
  const { navigateTo } = useNavigation(ConsoleContext);

  const { invoke, result } = useMutation(
    { method: apiClients.withRegion(getOciRegion(location)).deploymentApi.createDeployment },
  );

  React.useEffect(() => {
    unitId.current = uniqueGUID();
    invoke(submitPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (result && !result.loading) {
      if (result.error) {
        let asyncErrors;
        // eslint-disable-next-line max-len
        const errorMessage = `${Messages.notifications.failure.messages.createResourceDeployment(resourceType, deploymentName, resourceName)} ${result.error.body.message}`;
        const { body: { messageArguments } } = result.error as PreflightError;
        if (messageArguments) {
          asyncErrors = errorMap2AsyncValidationErrors(messageArguments);
          if (onProcessAsyncValidationErrors) {
            asyncErrors = onProcessAsyncValidationErrors(asyncErrors);
          }
        }
        submitReject(errorMessage, asyncErrors);

        submitNotificationRequest({
          unitId: unitId.current,
          type: NotificationType.FAILURE,
          title: Messages.notifications.failure.titles.createResourceDeployment(resourceType),
          message: Messages.notifications.failure.messages.createResourceDeployment(
            resourceType,
            deploymentName,
            resourceName,
          ),
          apiError: result.error.body.message,
        } as NotificationRequest);

        // Allow re-clicking on Create button to allow the user to resubmit
        // after fixing issues reported by the failed API.
        panelRef?.allowResubmit();
      } else {
        submitNotificationRequest({
          unitId: unitId.current,
          type: NotificationType.IN_PROGRESS,
          title: Messages.notifications.inProgress.titles.deployResource(resourceType),
          message: Messages.notifications.inProgress.messages.deployResource(
            deploymentName,
            resourceType,
            resourceName,
          ),
        } as NotificationRequest);
        const depName = result.response?.data?.name;
        submitAsync({
          unitId: unitId.current,
          methodKey: asyncNotification.methodKey,
          args: {
            subscriptionId,
            resourceGroupName,
            apiVersion: MultiCloudDatabaseApiVersion,
            deploymentName: depName,
          },
          ociRegion: getOciRegion(location),
          delay: asyncNotification.delay,
          pollingInterval: asyncNotification.pollingInterval,
          polledResponseKey: asyncNotification.polledResponseKey,
          onProgress: {
            title: Messages.notifications.inProgress.titles.deployResource(resourceType),
            message: Messages.notifications.inProgress.messages.deployResource(
              deploymentName,
              resourceType,
              resourceName,
            ),
          },
          onSuccess: {
            title: Messages.notifications.success.titles.deployResource(resourceType),
            message: Messages.notifications.success.messages.deployResource(resourceType, resourceName),
          },
          onFailure: {
            title: Messages.notifications.failure.titles.deployResource(resourceType),
            message: Messages.notifications.failure.messages.deployResource(resourceType, resourceName),
          },
        } as AsyncNotificationRequest);

        const deploymentId = result.response?.data?.id;
        if (deploymentId) {
          const deploymentLocation = result.response?.data?.location;
          // eslint-disable-next-line max-len
          const path = `${RESOURCE_ROUTE}/${deploymentId}/${PageRegistrationConfig[PageId.DEPLOYMENT_DETAILS][0].panelPath}?location=${deploymentLocation}`;
          const { key } = PageRegistrationConfig[PageId.DEPLOYMENT_DETAILS][0];
          navigateTo(path, key, undefined, true);
        } else {
          panelRef?.close();
        }
      }
      onPostSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return <div data-test-id={emptyDivTestId} />;
};
