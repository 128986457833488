import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { OciResourceType } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { AzureSubscriptionSummaryExt } from "../../hooks/useSubscriptions";
import { getOciRegion } from "../../utils";
import { OciLink, OciLinkProps } from "./OciLink";

export interface OciExaDbHomeLinkProps extends Pick<OciLinkProps, "location" | "analytics"> {
  id: string;
  subscriptions: AzureSubscriptionSummaryExt[];
}

export const OciExaDbHomeLink = ({ id, location, subscriptions, analytics }: OciExaDbHomeLinkProps): JSX.Element => {
  const idComps = parseId(id);
  const resGroup = decodeURIComponent(idComps?.resourceGroup || "");
  const resourceName = decodeURIComponent(idComps?.resourceName || "");
  const subscription = subscriptions?.find(item => item.id === idComps?.subscriptionId);

  const { response: responseDbHome, error: errorDbHome } = useQueryCall({
    wait: !id,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.getDbHome,
    options: {
      args: {
        subscriptionId: idComps?.subscriptionId || "",
        resourceGroupName: resGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        dbHomeName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });

  const dbHome = responseDbHome?.data;

  const vmClusterIdComps = parseId(dbHome?.vmClusterId);
  const vmClusterResGroup = decodeURIComponent(vmClusterIdComps?.resourceGroup || "");
  const vmClusterResourceName = decodeURIComponent(vmClusterIdComps?.resourceName || "");

  const { response: responseVmCluster, error: errorVmCluster } = useQueryCall({
    wait: !dbHome?.vmClusterId,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.getCloudVmCluster,
    options: {
      args: {
        subscriptionId: vmClusterIdComps?.subscriptionId || "",
        resourceGroupName: vmClusterResGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        cloudVmClusterName: vmClusterResourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });

  const vmCluster = responseVmCluster?.data;

  const ociResourcePath = `/dbaas/cloudVmClusters/${vmCluster?.ocid}/db-homes/${dbHome?.ocid}`;

  return (
    <OciLink
      ociResourceType={OciResourceType.EXA_DBHOME}
      ociResourcePath={!errorDbHome && !errorVmCluster && vmCluster?.ocid && dbHome?.ocid ? ociResourcePath : undefined}
      ociResourceName={resourceName}
      location={location}
      subscription={subscription}
      analytics={analytics}
    />
  );
};
