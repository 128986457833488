import { CustomLink } from "o4a-react";
import * as React from "react";
import { Link } from "@fluentui/react";
import { parseId } from "../../helpers/idHelper";
import { useAppAuthContext } from "../../hooks/useAppAuthContext";
import { azurePortalUrl } from "../../utils";

export interface AzureResourceGroupLinkProps {
  resourceId: string;
  hideClipboard?: boolean;
}

export const AzureResourceGroupLink = (
  { resourceId, hideClipboard = false }: AzureResourceGroupLinkProps,
): JSX.Element => {
  const { domainName } = useAppAuthContext();
  const idComps = parseId(resourceId);
  const subscriptionId = decodeURIComponent(idComps?.subscriptionId || "");
  const resGroup = decodeURIComponent(idComps?.resourceGroup || "");
  // eslint-disable-next-line max-len
  const url = `${azurePortalUrl}/#@${domainName}/resource/subscriptions/${subscriptionId}/resourceGroups/${resGroup}/overview`;

  if (hideClipboard) {
    return (
      <Link
        title={resGroup}
        href={url}
        target="_blank"
        underline
      >
        {resGroup}
      </Link>
    );
  }

  return (
    <CustomLink
      href={url}
      target="_blank"
      underline
      linkText={resGroup}
    />
  );
};
