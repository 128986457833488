import {
  FormValues,
  getValue,
  InputFormGroup,
  NotificationRequest,
  NotificationType,
  useNotificationRequest,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  AdbsWalletDownload,
  Fields as WalletDownloadFields,
} from "../../components/AdbsWalletDownload/AdbsWalletDownload";
import { parseId } from "../../helpers/idHelper";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface AdbsDownloadWalletProps extends OperationActionProps {
  databaseId: string | undefined;
}

const AdbsDownloadWallet = (
  { targetId, location, databaseId, onExecute, onCancel }: AdbsDownloadWalletProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");
  const { submit: submitNotificationRequest } = useNotificationRequest();

  const doWalletDownload = async (response: Response): Promise<void> => {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = "Wallet.zip"; // fileName;
    a.href = url;
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const onWalletDownload = async (formValues: FormValues): Promise<void> => {
    const password = getValue<string>(formValues, WalletDownloadFields.Password, InputFormGroup) ?? "";
    try {
      const response = await apiClients.withRegion(getOciRegion(location))
        .adbsDatabaseApi.generateAdbsDatabaseWallet({
          databaseName: resName,
          resourceGroupName: resGroup,
          subscriptionId,
          generateAdbsDatabaseWalletDetails: { password },
        });

      await doWalletDownload(response);
      onExecute?.();
      submitNotificationRequest({
        type: NotificationType.SUCCESS,
        title: Messages.notifications.success.titles.downloadWallet(),
        message: Messages.notifications.success.messages.downloadWallet(resName),
      } as NotificationRequest);
    } catch {
      submitNotificationRequest({
        type: NotificationType.FAILURE,
        title: Messages.notifications.failure.titles.downloadWallet(),
        message: Messages.notifications.failure.messages.downloadWallet(resName),
      } as NotificationRequest);
    }
  };

  return (
    <AdbsWalletDownload
      targetId={targetId}
      onClose={onCancel}
      onSubmit={onWalletDownload}
    />
  );
};

export const newAdbsDownloadWallet = (
  props: AdbsDownloadWalletProps,
): JSX.Element => (<AdbsDownloadWallet {...props} />);
