import {
  CalloutComponent,
  FormValues,
  getValue,
  InputFormCallout,
  InputFormGroup,
  SelectOption,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { MySQLVersionSelect } from "../../components/MySQLVersionSelect/MySQLVersionSelect";
import { CalloutTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_UPDATE_POLL_DELAY,
  MDS_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export enum Fields {
  MySqlVersion = "mySqlVersion",
}

export enum FieldTestIds {
  MySqlVersion = "mySqlVersion",
}

export interface MysqlEditVersionProps extends OperationActionProps {
  databaseId: string | undefined;
  version: string | undefined;
}

const MysqlEditVersion = (
  { targetId, location, databaseId, version, onExecute, onCancel }: MysqlEditVersionProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resourceGroupName = decodeURIComponent(resourceGroup || "");
  const dbSystemName = decodeURIComponent(resourceName || "");

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (version) {
      const initFieldValues: FormValues = { [Fields.MySqlVersion]: [version] };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  const [calloutRef, setCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);
  const { invokeCall: invokeMdsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.updateMdsDbSystem },
  );

  const onUpdate = (formValues: FormValues): void => {
    const mysqlVersion = getValue<SelectOption[]>(formValues, Fields.MySqlVersion, InputFormGroup)?.[0]?.id;
    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: calloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateMysqlVersion(),
          message: Messages.notifications.inProgress.messages.updateMysqlVersion(dbSystemName),
        },
        success: {
          title: Messages.notifications.success.titles.updateMysqlVersion(),
          message: Messages.notifications.success.messages.updateMysqlVersion(dbSystemName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateMysqlVersion(),
          message: Messages.notifications.failure.messages.updateMysqlVersion(dbSystemName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            dbSystemName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_UPDATED_CHECK,
          delay: MDS_UPDATE_POLL_DELAY,
          interval: MDS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeMdsUpdate({
      subscriptionId,
      resourceGroupName,
      dbSystemName,
      updateMdsDbSystemDetails: { mysqlVersion },
    }, invokeOptions);
  };

  return (
    <InputFormCallout
      targetId={targetId}
      title={Messages.createNewPanels.editMySqlVersion.title()}
      componentRef={setCalloutRef}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onUpdate}
      onClose={onCancel}
      initialValues={initialValues}
      testId={CalloutTestIds.MysqlEditVersion}
    >
      <MySQLVersionSelect
        required
        testId={FieldTestIds.MySqlVersion}
        fieldName={Fields.MySqlVersion}
        label={Messages.labels.mySQLVersion()}
        subscriptionId={subscriptionId}
        location={location}
        tooltip={Messages.hints.selectAMySQLVersion()}
        defaultValue={version}
      />
    </InputFormCallout>
  );
};

export const newMysqlEditVersion = (
  props: MysqlEditVersionProps,
): JSX.Element => (<MysqlEditVersion {...props} />);
