import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { getTagsMap, TagsInfoType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_PDB_UPDATE_POLL_DELAY,
  VMDB_PDB_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface VmDbUpdateTagsPdbProps extends OperationConfirmationProps {
  databaseId: string | undefined;
  tags: TagsInfoType;
}

const VmDbUpdateTagsPdb = (
  { location, databaseId, tags, onExecute }: VmDbUpdateTagsPdbProps,
): JSX.Element => {
  const { invokeCall: invokePdbTagUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.updateVmdbPluggableDatabase },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName: pdbName } = parseId(databaseId);
    const resGroup = decodeURIComponent(resourceGroup || "");

    const invokePdbOptions = {
      onSuccess: onExecute,
      onFailure: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.applyTags(),
          message: Messages.notifications.inProgress.messages.applyTags(pdbName),
        },
        success: {
          title: Messages.notifications.success.titles.applyTags(),
          message: Messages.notifications.success.messages.applyTags(pdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.applyTags(),
          message: Messages.notifications.failure.messages.applyTags(pdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_PDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            pluggableDatabaseName: pdbName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_UPDATED_CHECK_PDB,
          delay: VMDB_PDB_UPDATE_POLL_DELAY,
          interval: VMDB_PDB_UPDATE_POLL_INTERVAL,
        },
      },
    };

    invokePdbTagUpdate({
      subscriptionId,
      resourceGroupName: resGroup,
      pluggableDatabaseName: pdbName,
      updateVmdbPluggableDatabaseDetails: { freeformTags: getTagsMap(tags) },
    }, invokePdbOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newVmDbUpdateTagsPdb = (
  props: VmDbUpdateTagsPdbProps,
): JSX.Element => (<VmDbUpdateTagsPdb {...props} />);
