import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXADB_PDB_START_POLL_DELAY,
  EXADB_PDB_START_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface ExaDbStartPdbProps extends OperationConfirmationProps {
  databaseId: string;
}

const ExaDbStartPdb = (
  { location, databaseId, onExecute }: ExaDbStartPdbProps,
): JSX.Element => {
  const { invokeCall: invokeStartPDB } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.startPluggableDatabase },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
    const resGroup = decodeURIComponent(resourceGroup || "");
    const pdbName = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.startExaDb(),
          message: Messages.notifications.inProgress.messages.startExaDb(pdbName),
        },
        success: {
          title: Messages.notifications.success.titles.startExaDb(),
          message: Messages.notifications.success.messages.startExaDb(pdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.start(),
          message: Messages.notifications.failure.messages.startExaDb(pdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXADB_PDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            pluggableDatabaseName: pdbName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.EXADB_STARTED_CHECK_PDB,
          delay: EXADB_PDB_START_POLL_DELAY,
          interval: EXADB_PDB_START_POLL_INTERVAL,
        },
      },
    };
    invokeStartPDB({
      subscriptionId,
      resourceGroupName: resGroup,
      pluggableDatabaseName: pdbName,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newExaDbStartPdb = (
  props: ExaDbStartPdbProps,
): JSX.Element => (<ExaDbStartPdb {...props} />);
