import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import { DetailsPanelId, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { ResourceLinkTestIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { AnalyticsDataPage, LinkAnalyticsData } from "../../hooks/useAnalytics";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { Resource, ResourceLink } from "./ResourceLink";

export interface ExaVmClusterLinkProps {
  vmClusterId: string|undefined;
  location: string;
  analytics: AnalyticsDataPage;
}

const registrationIds = PageRegistrationConfig[PageId.VMCLUSTER_DETAILS].map(config => config.key);

export const ExaVmClusterLink = ({
  vmClusterId,
  location,
  analytics,
}: ExaVmClusterLinkProps): JSX.Element => {
  const vmClusterIdComps = parseId(vmClusterId);
  const vmClusterResGroup = decodeURIComponent(vmClusterIdComps?.resourceGroup || "");
  const vmClusterResourceName = decodeURIComponent(vmClusterIdComps?.resourceName || "");
  const { response, error, loading } = useQueryCall({
    wait: !vmClusterId,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.getCloudVmCluster,
    options: {
      args: {
        subscriptionId: vmClusterIdComps?.subscriptionId || "",
        resourceGroupName: vmClusterResGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        cloudVmClusterName: vmClusterResourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });
  const vmCluster = response?.data;

  return (
    <ResourceLink
      resource={vmCluster as Resource}
      navigation={{
        navigationKey: registrationIds[0],
        panelPath: PageRegistrationConfig[PageId.VMCLUSTER_DETAILS][0].panelPath,
      }}
      analytics={{
        target: { pageId: PageId.VMCLUSTER_DETAILS, panelId: DetailsPanelId.OVERVIEW },
        source: { pageId: analytics.pageId, panelId: analytics.panelId },
      } as LinkAnalyticsData}
      error={error}
      loading={loading}
      testId={ResourceLinkTestIds.ExaVmClusterLink}
    />
  );
};
