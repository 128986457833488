import {
  AnchoredPanel,
  AnchoredPanelType,
  NotificationCard,
  NotificationType,
  uniqueGUID,
  useNotification,
} from "o4a-react";
import * as React from "react";
import { CommandButton, Icon, Separator } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { OperationBaseProps } from "../../console/OperationsContext";
import { SvgIconIds } from "../../constants/uiConstants";
import { timeFromNow } from "../../helpers/timeHelper";

const separatorStyles = {
  root: {
    height: "1px",
    padding: "0px",
    "&::before": { backgroundColor: "rgb(204, 204, 204)" },
  },
};

interface TimeAgoProps {
  timestamp: number;
  /**
   * Refresh interval in ms
   */
  refreshInterval: number;
}

export enum ButtonIds {
  DismissAll = "DismissAll",
  DismissInfo = "DismissInfo",
  DismissSuccess = "DissmissSuccess",
}

const TimeAgo = ({ timestamp, refreshInterval }: TimeAgoProps): JSX.Element => {
  const [timeAgo, setTimeAgo] = React.useState<string>(timeFromNow(timestamp));

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(timeFromNow(timestamp));
    }, refreshInterval);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <span style={{ float: "right" }}>{timeAgo}</span>;
};

export type ViewNotificationsProps = OperationBaseProps;

const ViewNotifications = (
  { onCancel }: ViewNotificationsProps,
): JSX.Element => {
  const [notificationCards, setNotificationCards] = React.useState<JSX.Element[]>([]);

  const { notifications, markAllRead, dismiss, dismissByType, dismissAll } = useNotification();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => markAllRead(), []);

  React.useEffect(() => {
    markAllRead();
    const cards = notifications
      .filter(entry => !entry.onlyToast)
      .reverse()
      .map(notification => {
        const notificationCard = (
          <React.Fragment key={uniqueGUID()}>
            <div key={uniqueGUID()} style={{ marginBottom: "30px" }}>
              <NotificationCard
                key={uniqueGUID()}
                type={notification.type}
                title={notification.title}
                message={notification.message}
                apiError={notification.apiError}
                onClose={() => dismiss(notification.id)}
              />
              {notification.timestamp && <TimeAgo timestamp={notification.timestamp || 0} refreshInterval={30000} />}
            </div>
            <Separator key={uniqueGUID()} styles={separatorStyles} />
          </React.Fragment>
        );
        return notificationCard;
      });
    setNotificationCards(cards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(notifications)]);

  const onDismissAll = (): void => {
    dismissAll();
    onCancel?.();
  };

  const onDismissInfo = (): void => { dismissByType(NotificationType.INFO); };

  const onDismissSuccess = (): void => { dismissByType(NotificationType.SUCCESS); };

  return (
    <AnchoredPanel
      type={AnchoredPanelType.CUSTOM_WIDTH}
      customWidth={525}
      title={Messages.labels.notifications()}
      isOpen
      onClose={onCancel}
    >
      {notificationCards.length > 0 && (
        <>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <CommandButton
              data-test-id={ButtonIds.DismissAll}
              text={Messages.actions.dismissAll()}
              splitButtonAriaLabel={Messages.ariaLabel.dismissOptions()}
              menuProps={{
                items: [
                  {
                    "data-test-id": ButtonIds.DismissInfo,
                    key: ButtonIds.DismissInfo,
                    text: Messages.actions.dismissInfo(),
                    onClick: onDismissInfo,
                  },
                  {
                    "data-test-id": ButtonIds.DismissSuccess,
                    key: ButtonIds.DismissSuccess,
                    text: Messages.actions.dismissSuccess(),
                    onClick: onDismissSuccess,
                  },
                ],
              }}
              split
              onClick={onDismissAll}
              styles={{
                textContainer: {
                  color: "rgb(1, 92, 218)",
                  fontSize: "13px",
                  fontWeight: "400",
                  ":hover": { textDecoration: "underline" },
                },
                splitButtonMenuButton: { border: "none", padding: "0px", width: "20px", background: "inherit" },
              }}
            />
          </div>
          <Separator key={uniqueGUID()} styles={separatorStyles} />
          {notificationCards}
        </>
      )}
      {notificationCards.length === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "80px",
          }}
        >
          <Icon
            iconName={SvgIconIds.notificationBellSvg}
            styles={{ root: { width: "86px", height: "100px" } }}
          />
          <div
            style={{
              fontWeight: 700,
              fontSize: "14px",
              fontStyle: "normal",
              marginTop: "30px",
            }}
          >
            {Messages.notifications.watermarkMessage()}
          </div>
        </div>
      )}
    </AnchoredPanel>

  );
};

export const newViewNotifications = (
  props: ViewNotificationsProps,
): JSX.Element => (<ViewNotifications {...props} />);
