import { InfoBlockStatus } from "o4a-react";
import React from "react";
import * as Messages from "../../codegen/Messages";
import {
  DeleteConfirmationPanel,
  DeleteConfirmationPanelProps,
} from "../DeleteConfirmationPanel/DeleteConfirmationPanel";
import { DeleteExaInfraResourcesList } from "../DeleteExaInfraResourcesList/DeleteExaInfraResourcesList";

export enum InfoBlockTestIds {
  WarningDelete = "warning-delete-infoblock"
}

export interface ExaInfraDeletePanelProps extends
Pick<DeleteConfirmationPanelProps, "componentRef" | "onClose" | "onSubmit" | "testId" > {
  location: string;
  exaInfraId: string;
}

export const ExaInfraDeletePanel = ({
  exaInfraId,
  location,
  onSubmit,
  componentRef,
  onClose,
  testId,
}: ExaInfraDeletePanelProps): JSX.Element => {
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(true);

  return (
    <DeleteConfirmationPanel
      componentRef={componentRef}
      title={Messages.labels.deleteExaInfra()}
      description={Messages.createNewPanels.deleteExaInfra.description()}
      onSubmit={onSubmit}
      onClose={onClose}
      infoBlock={{
        message: Messages.createNewPanels.deleteExaInfra.warningInfoBlock(),
        type: InfoBlockStatus.WARNING,
        testId: InfoBlockTestIds.WarningDelete,
      }}
      isLoading={isDeleteLoading}
      testId={testId}
    >
      <DeleteExaInfraResourcesList
        exaInfraId={exaInfraId}
        location={location}
        setIsLoading={setIsDeleteLoading}
      />
    </DeleteConfirmationPanel>
  );
};
