import {
  ActionType,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingDisplayNameLink,
  optimizedRetryOption,
  SortDirections,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { DetailsPanelId, InfoPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { DbNodeSummary, DbNodeSummaryCollection } from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import { getStatusInfo, responseItemstoArray, statusSortComparator } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import {
  ExaVmClusterViewDbNodeProps,
  newExaVmClusterViewDbNode,
} from "../../operations/ExaVmCluster/ExaVmClusterViewDbNode";
import { getOciRegion } from "../../utils";

export interface ExaVmClusterDbNodesListProps {
  vmClusterId: string | undefined;
  location: string;
}

export enum ColumnIds {
  Name = "name",
  ResourceStatus = "status",
  CoreCount = "coreCount",
  Memory = "memory",
  HostName = "hostName",
  HostIP = "hostIp"
}

export enum ColumnTestIds {
  Name = "name",
  ResourceStatus = "status",
  CoreCount = "coreCount",
  Memory = "memory",
  HostName = "hostName",
  HostIP = "hostIp"
}

export enum ActionIds {
  Refresh = "refresh",
}

export enum ActionBarTestIds {
  Refresh = "refresh",
}

export const ExaVmClusterDbNodesList = ({ vmClusterId, location }: ExaVmClusterDbNodesListProps): JSX.Element => {
  const { trackActionClick, trackLinkNavigate } = useAnalytics();

  const { locale } = React.useContext<Settings>(SettingsContext);

  const { subscriptions } = useSubscriptions();

  const idComps = parseId(vmClusterId);
  const { subscriptionId, resourceGroup } = idComps;

  const { response, loading, refresh } = useQueryCall({
    wait: !vmClusterId,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDbNodes,
    options: {
      args: {
        vmClusterId,
        subscriptionId,
        resourceGroupName: resourceGroup,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDatabaseNodes(),
      },
    },
  });

  const dbNodeItems = (response
    && responseItemstoArray<DbNodeSummaryCollection, DbNodeSummary>(response)) || [];

  const getStatus = (value: DbNodeSummary): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
        )
      }
      hideClipboardCopy
    />
  );

  const { trigger: triggerViewDbNode } = useOperation<ExaVmClusterViewDbNodeProps>(newExaVmClusterViewDbNode);

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 2,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.name}
          navigation={{
            onClick: () => {
              trackLinkNavigate(InfoPanelId.DBNODE, PageId.VMCLUSTER_DETAILS, DetailsPanelId.DATABASENODES);
              triggerViewDbNode({
                dbNodeId: value.id,
                subscriptions: subscriptions || [],
                location,
              });
            },
          }}
        />
      ),
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: DbNodeSummary,
        b: DbNodeSummary,
      ) => statusSortComparator<DbNodeSummary>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.CoreCount,
      testId: ColumnTestIds.CoreCount,
      name: Messages.labels.coreCount(),
      flexGrow: 1,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
    },
    {
      itemProp: ColumnIds.Memory,
      testId: ColumnTestIds.Memory,
      name: Messages.labels.memoryInGB(),
      flexGrow: 1,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
    },
    {
      itemProp: ColumnIds.HostName,
      testId: ColumnTestIds.HostName,
      name: Messages.labels.hostNameOnly(),
      flexGrow: 2,
      isResizable: true,
    },
    {
      itemProp: ColumnIds.HostIP,
      testId: ColumnTestIds.HostIP,
      name: Messages.labels.hostIp(),
      flexGrow: 1,
      isResizable: true,
    },
  ];

  const items = React.useMemo(() => dbNodeItems?.map(dbNode => ({
    id: dbNode?.id,
    name: dbNode?.name,
    status: dbNode?.status,
    coreCount: dbNode?.cpuCoreCount,
    memory: dbNode?.memorySizeInGBs,
    hostName: dbNode?.hostname,
    hostIp: dbNode?.hostIp,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })) || [], [JSON.stringify(dbNodeItems)]);

  const dbActions: ActionType[] = [
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.VMCLUSTER_DETAILS, DetailsPanelId.DATABASENODES);
        refresh();
      },
    },
  ];
  const { closePanels } = useSidePanel();

  return (

    <Listing
      items={items}
      testId={ListingTestIds.ExaVmClusterDbNodes}
      emptyList={{ title: Messages.common.noResults() }}
      isLoading={loading}
      listColumns={columns}
      actionBarItems={dbActions}
      onActionClick={closePanels}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Name,
      }}
    />

  );
};
