import {
  ActionBar,
  ActionType,
  AnchoredPanelComponent,
  BookmarkablePage,
  DateTimeDisplay,
  DetailView,
  DetailViewsPanel,
  ErrorViewPanel,
  FormValues,
  getResourceLifecycleStatus,
  getValue,
  GroupItem,
  InfoBlockProps,
  InfoBlockStatus,
  InputFormGroup,
  LabelMetaItem,
  MetaItemSection,
  NoValue,
  stateT,
  Status,
  TitleDetails,
  uniqueGUID,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { OciVmCdbLink } from "../../components/OciLinks/OciVmCdbLink";
import { OciVmPdbLink } from "../../components/OciLinks/OciVmPdbLink";
import { VmDbCdbLink } from "../../components/ResourceLinks/VmDbCdbLink";
import { VmDbHomeLink } from "../../components/ResourceLinks/VmDbHomeLink";
import { VmDbSystemLink } from "../../components/ResourceLinks/VmDbSystemLink";
import { Fields as TagDetailsFields, TagDetails, TagDetailsComponent } from "../../components/TagDetails/TagDetails";
import { ConsoleContext } from "../../console/ConsoleContext";
import { DetailsPanelId, InlineFormId, PageId } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds, SvgIconIds } from "../../constants/uiConstants";
import { VmdbDatabase, VmdbPluggableDatabase } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { buildId, parseId } from "../../helpers/idHelper";
import {
  defaultExaUsername,
  getOpenModeStr,
  getStatusInfo,
  getTitleSuffix,
  ResourceStatus,
  TagsInfoType,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAppAuthContext } from "../../hooks/useAppAuthContext";
import { useOperation } from "../../hooks/useOperation";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { newVmDbChangePassword, VmDbChangePasswordProps } from "../../operations/VmDb/VmDbChangePassword";
import { newVmDbClonePdb, VmDbClonePdbProps } from "../../operations/VmDb/VmDbClonePdb";
import { newVmDbDeleteCdb, VmDbDeleteCdbProps } from "../../operations/VmDb/VmDbDeleteCdb";
import { newVmDbDeletePdb, VmDbDeletePdbProps } from "../../operations/VmDb/VmDbDeletePdb";
import { newVmDbEditTagsCdb, VmDbEditTagsCdbProps } from "../../operations/VmDb/VmDbEditTagsCdb";
import { newVmDbEditTagsPdb, VmDbEditTagsPdbProps } from "../../operations/VmDb/VmDbEditTagsPdb";
import { newVmDbRestoreCdb, VmDbRestoreCdbProps } from "../../operations/VmDb/VmDbRestoreCdb";
import { newVmDbStartPdb, VmDbStartPdbProps } from "../../operations/VmDb/VmDbStartPdb";
import { newVmDbStopPdb, VmDbStopPdbProps } from "../../operations/VmDb/VmDbStopPdb";
import { newVmDbUpdateTagsCdb, VmDbUpdateTagsCdbProps } from "../../operations/VmDb/VmDbUpdateTagsCdb";
import { newVmDbUpdateTagsPdb, VmDbUpdateTagsPdbProps } from "../../operations/VmDb/VmDbUpdateTagsPdb";
import { newVmDbViewDbHome, VmDbViewDbHomeProps } from "../../operations/VmDb/VmDbViewDbHome";
import { azurePortalUrl, getAzureLocationName } from "../../utils";
import { VmDbBackupsList } from "./VmDbBackupsList";
import { VmDbConfigurationDetails } from "./VmDbConfigurationDetails";
import { VmDbConnectDetails } from "./VmDbConnectDetails";
import { VmDbManagementDetails } from "./VmDbManagementDetails";
import { VmDbPdbsList } from "./VmDbPdbsList";

export enum ActionIds {
  Clone = "clone",
  EditPassword = "edit-password",
  Refresh = "refresh",
  Restore = "restore",
  Delete = "delete",
  Start = "start",
  Stop = "stop",
  LaunchMetrics = "launch-metrics",
  LaunchDashboard = "launch-dashboard",
  EditTags = "edit-tags",
}

export enum ActionBarTestIds {
  Clone = "clone",
  Refresh = "refresh",
  Restore = "restore",
  Delete = "delete",
  Start = "start",
  Stop = "stop",
  LaunchMetrics = "launch-metrics",
  LaunchDashboard = "launch-dashboard",
}

export enum MetaItemSectionTestIds {
  ResourceGroup = "mis-resource-group",
  LifeCycleStatus = "mis-status",
  Location = "mis-loaction",
  Subscription = "mis-subscription",
  SubscriptionId = "mis-subscription-id",
  Tags = "mis-tags",
  DbName = "mis-db-name",
  Username = "mis-username",
  OpenMode = "mis-open-mode",
  DbUniqueName = "mis-db-unique-name",
  TimeCreated = "mis-time-created",
  TimeUpdated = "mis-time-updated",
}

export enum MetaItemActionTestIds {
  EditPassword = "edit-password",
  EditTags = "edit-tags",
}

export enum InfoBlockTestIds {
  DisabledActions = "disabled-actions",
}

export enum LinkTestIds {
  AddTags = "add-tags-link",
}

export interface VmDbDetailsPageProps {
  dbInstance: VmdbDatabase|VmdbPluggableDatabase|undefined,
  dbRefresh: () => void,
  dbError: NormalError|undefined,
  isCdb: boolean,
  dbRegistrationIds: string[],
  container?:{
    instance: VmdbDatabase|undefined,
    refresh: () => void,
  },
  panelId: string|undefined,
  location: string,
  resourceId: string,
}

const getViewId = (panelId: string | undefined, dbRegistrationIds: string[]): string => {
  switch (panelId) {
    case DetailsPanelId.PLUGGABLEDATABASES:
      return dbRegistrationIds[6];
    case DetailsPanelId.BACKUPS:
      return dbRegistrationIds[5];
    case DetailsPanelId.MANAGEMENT:
      return dbRegistrationIds[4];
    case DetailsPanelId.CONFIGURATION:
      return dbRegistrationIds[3];
    case DetailsPanelId.CONNECT:
      return dbRegistrationIds[2];
    case DetailsPanelId.TAGS:
      return dbRegistrationIds[1];
    case DetailsPanelId.OVERVIEW:
    default:
      return dbRegistrationIds[0];
  }
};

const getViewTitle = (panelId: string | undefined, instanceName: string): string | TitleDetails => {
  switch (panelId) {
    case DetailsPanelId.PLUGGABLEDATABASES:
      return { primary: instanceName, secondary: Messages.labels.pluggableDatabases() };
    case DetailsPanelId.BACKUPS:
      return { primary: instanceName, secondary: Messages.labels.backups() };
    case DetailsPanelId.MANAGEMENT:
      return { primary: instanceName, secondary: Messages.labels.management() };
    case DetailsPanelId.CONFIGURATION:
      return { primary: instanceName, secondary: Messages.labels.configuration() };
    case DetailsPanelId.CONNECT:
      return { primary: instanceName, secondary: Messages.labels.connect() };
    case DetailsPanelId.TAGS:
      return { primary: instanceName, secondary: Messages.labels.tags() };
    case DetailsPanelId.OVERVIEW:
    default:
      return instanceName;
  }
};

const getViewIcon = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.PLUGGABLEDATABASES:
      return SvgIconIds.pluggableSvg;
    case DetailsPanelId.BACKUPS:
      return SvgIconIds.backupsSvg;
    case DetailsPanelId.MANAGEMENT:
      return SvgIconIds.managementSvg;
    case DetailsPanelId.CONFIGURATION:
      return SvgIconIds.configurationSvg;
    case DetailsPanelId.CONNECT:
      return SvgIconIds.connectSvg;
    case DetailsPanelId.TAGS:
      return SvgIconIds.tagsSvg;
    case DetailsPanelId.OVERVIEW:
    default:
      return SvgIconIds.baseDbSvg;
  }
};

export const VmDbDetailsPage = ({
  dbInstance,
  dbRefresh,
  dbError,
  isCdb,
  dbRegistrationIds,
  container,
  panelId,
  location,
  resourceId,
}: VmDbDetailsPageProps): JSX.Element => {
  const dbIdComps = parseId(resourceId);
  const {
    resourceGroup,
    resourceName,
    subscriptionId,
    provider,
    resourceType,
  } = dbIdComps;

  const { trackActionClick, trackActionDiscard, trackFormSave } = useAnalytics();
  const pageId = isCdb ? PageId.VMDB_CDB_DETAILS : PageId.VMDB_PDB_DETAILS;

  const { back, navigateToPanel } = useNavigation(ConsoleContext);

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  const tagDetailsRef = React.useRef<TagDetailsComponent>();
  const setTagDetailsRef = (panel: TagDetailsComponent): void => {
    tagDetailsRef.current = panel;
  };

  // eslint-disable-next-line max-len
  const subtitle = `${Messages.detailVmDb.titles.long()} | ${isCdb ? Messages.labels.containerDb() : Messages.labels.pluggableDatabase()}`;

  const { domainName } = useAppAuthContext();

  const [tagTabKey, setTagTabKey] = React.useState<string>(uniqueGUID());

  // Subscription
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();
  const subscription = !subscriptionLoading && subscriptions
    ? subscriptions.find(s => s.id === subscriptionId)
    : undefined;

  const vmDbId = buildId({
    subscriptionId: subscriptionId || "",
    resourceGroup: resourceGroup || "",
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName,
  });

  // Tags
  const tagsDefaultValue: TagsInfoType = Object.keys(dbInstance?.freeformTags || {})
    .map((key: string) => ({ name: key, value: dbInstance?.freeformTags?.[key] }));

  const onMenuItemSelect = (id: string): void => navigateToPanel(id);

  const onEditTags = (): void => {
    if (isCdb) {
      triggerEditTagsCdb({
        databaseId: dbInstance?.id,
        defaultTags: tagsDefaultValue,
        location,
        onExecute: () => refresh(),
        onCancel: () => trackActionDiscard(ActionIds.EditTags, pageId, DetailsPanelId.OVERVIEW),
      });
    } else {
      triggerEditTagsPdb({
        databaseId: dbInstance?.id,
        defaultTags: tagsDefaultValue,
        location,
        onExecute: () => refresh(),
        onCancel: () => trackActionDiscard(ActionIds.EditTags, pageId, DetailsPanelId.OVERVIEW),
      });
    }
  };

  const containerDbStatus = container ? container.instance?.status : undefined;

  const cdbStatusThatDisableClonePdb = (): boolean => (
    containerDbStatus !== ResourceStatus.Active
    && containerDbStatus !== ResourceStatus.Updating);

  const cdbFailedState = (): boolean => (containerDbStatus === ResourceStatus.Failed);
  const pdbFailedState = (): boolean => (dbInstance?.status === ResourceStatus.Failed);

  const openModesThatDisablePdbStop = (): boolean => (
    ((dbInstance as VmdbPluggableDatabase)?.openMode !== "READ_ONLY"
    && (dbInstance as VmdbPluggableDatabase)?.openMode !== "READ_WRITE"));
  const openModeThatDisablesPdbStart = (): boolean => (
    (dbInstance as VmdbPluggableDatabase)?.openMode !== "MOUNTED");

  const disabledActionsInfoBlocks = (): InfoBlockProps[] => {
    const disabledActionInfo: InfoBlockProps[] = [];
    const disabledActions: string[] = [];
    if (pdbFailedState()) {
      disabledActions.push(Messages.actions.start());
      disabledActions.push(Messages.actions.stop());
      disabledActions.push(Messages.actions.clone());
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          ? Messages.hints.pdbDisabledActionPdbStatus(disabledActions.toString(), stateT(dbInstance?.status || ""))
          : Messages.hints.pdbDisabledActionsPdbStatus(disabledActions.join(", "), stateT(dbInstance?.status || ""));
        disabledActionInfo.push(
          {
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    } else {
      if (cdbFailedState()) {
        disabledActions.push(Messages.actions.start());
        disabledActions.push(Messages.actions.stop());
      }
      if (cdbStatusThatDisableClonePdb()) {
        disabledActions.push(Messages.actions.clone());
      }
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          ? Messages.hints.pdbDisabledActionCdbStatus(disabledActions.toString(), stateT(containerDbStatus || ""))
          : Messages.hints.pdbDisabledActionsCdbStatus(disabledActions.join(", "), stateT(containerDbStatus || ""));
        disabledActionInfo.push(
          {
            testId: InfoBlockTestIds.DisabledActions,
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    }
    return disabledActionInfo;
  };

  React.useEffect(() => {
    setTagTabKey(uniqueGUID);
  }, [dbInstance]);

  const refresh = (): void => {
    // A Cdb refresh is required since we now display the cdb status on the pdb details page
    if (container) {
      container.refresh();
    }
    dbRefresh();
  };

  const dbHomeId = isCdb ? (dbInstance as VmdbDatabase)?.dbHomeId : container?.instance?.dbHomeId;
  const dbSystemId = isCdb ? (dbInstance as VmdbDatabase)?.dbSystemId : container?.instance?.dbSystemId;

  const essentialsMetaItemGroup: JSX.Element[] = [
    <Stack key="essentials-left" tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem testId={MetaItemSectionTestIds.ResourceGroup} label={Messages.labels.resourceGroup()}>
        <AzureResourceGroupLink resourceId={dbInstance?.id as string} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.LifeCycleStatus} label={Messages.labels.status()}>
        <Status
          iconPosition="right"
          label={stateT(dbInstance?.status || "")}
          status={getResourceLifecycleStatus(dbInstance?.status || "")}
          statusInfo={
            getStatusInfo(
              dbInstance?.status || "",
              dbInstance?.lastOperationStatus || "",
              dbInstance?.lastOperationStatusDetails || "",
            )
          }
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Location} label={Messages.labels.location()}>
        {dbInstance?.location ? getAzureLocationName(dbInstance.location) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Subscription} label={Messages.labels.subscription()}>
        <AzureSubscriptionLink resourceId={dbInstance?.id as string} subscriptionName={subscription?.name} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.SubscriptionId} label={Messages.labels.subscriptionId()}>
        {subscriptionId || <NoValue />}
      </LabelMetaItem>
    </Stack>,
    <Stack key="essentials-right" tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem label={Messages.labels.ociResource()}>
        <>
          {!isCdb && (
            <OciVmPdbLink
              id={dbInstance?.id || ""}
              location={location}
              subscriptions={subscriptions || []}
              analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
            />
          )}
          {isCdb && (
            <OciVmCdbLink
              id={dbInstance?.id || ""}
              location={location}
              subscriptions={subscriptions || []}
              analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
            />
          )}
        </>
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.DbName} label={Messages.labels.databaseName()}>
        {isCdb ? (dbInstance as VmdbDatabase)?.dbName || <NoValue /> : (dbInstance as VmdbPluggableDatabase)?.pdbName}
      </LabelMetaItem>
      {isCdb && (
        <LabelMetaItem testId={MetaItemSectionTestIds.DbUniqueName} label={Messages.labels.databaseUniqueName()}>
          {(dbInstance as VmdbDatabase)?.dbUniqueName || <NoValue />}
        </LabelMetaItem>
      )}
      {isCdb && (
        <LabelMetaItem
          testId={MetaItemSectionTestIds.Username}
          label={Messages.labels.username()}
          action={{
            id: ActionIds.EditPassword,
            testId: MetaItemActionTestIds.EditPassword,
            label: Messages.actions.changePassword(),
            onClick: () => {
              trackActionClick(ActionIds.EditPassword, pageId, DetailsPanelId.OVERVIEW);
              triggerChangePassword({
                databaseId: dbInstance?.id,
                targetId: ActionIds.EditPassword,
                location,
                onExecute: () => refresh(),
                onCancel: () => trackActionDiscard(ActionIds.EditPassword, pageId, DetailsPanelId.OVERVIEW),
              });
            },
          }}
        >
          {defaultExaUsername}
        </LabelMetaItem>
      )}
      {!isCdb && (
        <LabelMetaItem testId={MetaItemSectionTestIds.OpenMode} label={Messages.labels.openMode()}>
          {(dbInstance as VmdbPluggableDatabase)?.openMode
            ? getOpenModeStr((dbInstance as VmdbPluggableDatabase).openMode) : <NoValue />}
        </LabelMetaItem>
      )}
      {!isCdb && (
        <LabelMetaItem label={Messages.labels.containerDb()}>
          <VmDbCdbLink
            databaseId={(dbInstance as VmdbPluggableDatabase)?.containerDatabaseId}
            location={location}
            analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
          />
        </LabelMetaItem>
      )}
      <LabelMetaItem label={Messages.labels.databaseHome()}>
        <VmDbHomeLink
          dbHomeId={dbHomeId}
          location={location}
          analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
          onClick={() => {
            triggerViewDbHome({
              dbHomeId: container?.instance?.dbHomeId || (dbInstance as VmdbDatabase)?.dbHomeId,
              subscriptions: subscriptions || [],
              location,
            });
          }}
        />
      </LabelMetaItem>
      <LabelMetaItem label={Messages.labels.databaseSystem()}>
        <VmDbSystemLink
          dbSystemId={dbSystemId}
          location={location}
          analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
        />
      </LabelMetaItem>
      {dbInstance?.timeCreated && (
        <LabelMetaItem testId={MetaItemSectionTestIds.TimeCreated} label={Messages.labels.created()}>
          <DateTimeDisplay date={dbInstance.timeCreated} />
        </LabelMetaItem>
      )}
      {dbInstance?.timeUpdated && (
        <LabelMetaItem testId={MetaItemSectionTestIds.TimeUpdated} label={Messages.labels.updated()}>
          <DateTimeDisplay date={dbInstance.timeUpdated} />
        </LabelMetaItem>
      )}
    </Stack>,
  ];

  const onMetrics = (): void => {
    const insightsId = (dbInstance as VmdbDatabase)?.observabilityDetails?.azureApplicationInsightsId;
    const dbMetricsUrl = `${azurePortalUrl}#@${domainName}/resource/${insightsId}/metricsV2`;
    window.open(dbMetricsUrl, "_blank");
  };

  const onDashboard = (): void => {
    const dashboardId = (dbInstance as VmdbDatabase)?.observabilityDetails?.azureDashboardId;
    const dbDashboardUrl = `${azurePortalUrl}#@${domainName}/dashboard/arm/${dashboardId}`;
    window.open(dbDashboardUrl, "_blank");
  };

  const { trigger: triggerViewDbHome } = useOperation<VmDbViewDbHomeProps>(newVmDbViewDbHome);
  const { trigger: triggerRestoreCdb } = useOperation<VmDbRestoreCdbProps>(newVmDbRestoreCdb);
  const { trigger: triggerClonePdb } = useOperation<VmDbClonePdbProps>(newVmDbClonePdb);
  const { trigger: triggerChangePassword } = useOperation<VmDbChangePasswordProps>(newVmDbChangePassword);
  const { trigger: triggerStartPdb } = useOperation<VmDbStartPdbProps>(newVmDbStartPdb);
  const { trigger: triggerStopPdb } = useOperation<VmDbStopPdbProps>(newVmDbStopPdb);
  const { trigger: triggerDeleteCdb } = useOperation<VmDbDeleteCdbProps>(newVmDbDeleteCdb);
  const { trigger: triggerDeletePdb } = useOperation<VmDbDeletePdbProps>(newVmDbDeletePdb);
  const { trigger: triggerUpdateTagsCdb } = useOperation<VmDbUpdateTagsCdbProps>(newVmDbUpdateTagsCdb);
  const { trigger: triggerUpdateTagsPdb } = useOperation<VmDbUpdateTagsPdbProps>(newVmDbUpdateTagsPdb);
  const { trigger: triggerEditTagsCdb } = useOperation<VmDbEditTagsCdbProps>(newVmDbEditTagsCdb);
  const { trigger: triggerEditTagsPdb } = useOperation<VmDbEditTagsPdbProps>(newVmDbEditTagsPdb);

  const { closePanels } = useSidePanel();

  const overviewActionItems: ActionType[] = [
    ...(isCdb
      ? [
        {
          key: ActionIds.LaunchMetrics,
          testId: ActionBarTestIds.LaunchMetrics,
          text: Messages.actions.metrics(),
          icon: MonochromeIconIds.OpenInNewWindow,
          disabled: !(dbInstance as VmdbDatabase)?.observabilityDetails?.azureApplicationInsightsId,
          onClick: () => {
            trackActionClick(ActionIds.LaunchMetrics, pageId, DetailsPanelId.OVERVIEW);
            onMetrics();
          },
        },
        {
          key: ActionIds.LaunchDashboard,
          testId: ActionBarTestIds.LaunchDashboard,
          text: Messages.actions.dashboard(),
          icon: MonochromeIconIds.OpenInNewWindow,
          disabled: !(dbInstance as VmdbDatabase)?.observabilityDetails?.azureDashboardId,
          onClick: () => {
            trackActionClick(ActionIds.LaunchDashboard, pageId, DetailsPanelId.OVERVIEW);
            onDashboard();
          },
        },
        {
          key: ActionIds.Restore,
          testId: ActionBarTestIds.Restore,
          text: Messages.actions.restore(),
          icon: MonochromeIconIds.Database,
          onClick: () => {
            trackActionClick(ActionIds.Restore, pageId, DetailsPanelId.OVERVIEW);
            triggerRestoreCdb({
              databaseId: dbInstance?.id,
              timeCreated: dbInstance?.timeCreated,
              targetId: ActionIds.Restore,
              location,
              onExecute: refresh,
              onCancel: () => trackActionDiscard(ActionIds.Restore, pageId, DetailsPanelId.OVERVIEW),

            });
          },
        },
      ]
      : []),
    ...(!isCdb
      ? [
        {
          key: ActionIds.Start,
          testId: ActionBarTestIds.Start,
          text: Messages.actions.start(),
          icon: MonochromeIconIds.Play,
          title: Messages.labels.startVmDB(),
          description: Messages.hints.startVmDBConfirmation(dbInstance?.name || ""),
          disabled: cdbFailedState() || pdbFailedState()
            || openModeThatDisablesPdbStart(),
          onClick: () => {
            trackActionClick(ActionIds.Start, pageId, DetailsPanelId.OVERVIEW);
          },
          onConfirm: () => {
            triggerStartPdb({
              databaseId: dbInstance?.id,
              location,
              onExecute: refresh,
            });
          },
          onCancel: () => trackActionDiscard(ActionIds.Start, pageId, DetailsPanelId.OVERVIEW),
        },
        {
          key: ActionIds.Stop,
          testId: ActionBarTestIds.Stop,
          text: Messages.actions.stop(),
          icon: MonochromeIconIds.Stop,
          title: Messages.labels.stopVmDB(),
          description: Messages.hints.stopVmDBConfirmation(dbInstance?.name || ""),
          disabled: cdbFailedState() || pdbFailedState()
            || openModesThatDisablePdbStop(),
          onClick: () => {
            trackActionClick(ActionIds.Stop, pageId, DetailsPanelId.OVERVIEW);
          },
          onConfirm: () => {
            triggerStopPdb({
              databaseId: dbInstance?.id,
              location,
              onExecute: refresh,
            });
          },
          onCancel: () => trackActionDiscard(ActionIds.Stop, pageId, DetailsPanelId.OVERVIEW),
        },
        {
          key: ActionIds.Clone,
          testId: ActionBarTestIds.Clone,
          text: Messages.actions.clone(),
          icon: MonochromeIconIds.Database,
          disabled: cdbStatusThatDisableClonePdb() || pdbFailedState(),
          onClick: () => {
            trackActionClick(ActionIds.Clone, pageId, DetailsPanelId.OVERVIEW);
            triggerClonePdb({
              databaseId: dbInstance?.id,
              location,
              onExecute: refresh,
              onCancel: () => trackActionDiscard(ActionIds.Clone, pageId, DetailsPanelId.OVERVIEW),
            });
          },
        },
      ]
      : []),
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, pageId, DetailsPanelId.OVERVIEW);
        refresh();
      },
    },
    {
      key: ActionIds.Delete,
      testId: ActionBarTestIds.Delete,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.labels.deleteVMDb(),
      description: Messages.hints.deleteVMDbConfirmation(dbInstance?.name || ""),
      onClick: () => {
        trackActionClick(ActionIds.Delete, pageId, DetailsPanelId.OVERVIEW);
      },
      onConfirm: () => {
        if (isCdb) {
          triggerDeleteCdb({
            dbSystemId: (dbInstance as VmdbDatabase)?.dbSystemId,
            databaseId: dbInstance?.id,
            location,
            onExecute: () => {
              refresh();
              back();
            },
          });
        } else {
          triggerDeletePdb({
            databaseId: dbInstance?.id,
            location,
            onExecute: () => {
              refresh();
              back();
            },
          });
        }
      },
      onCancel: () => trackActionDiscard(ActionIds.Delete, pageId, DetailsPanelId.OVERVIEW),
    },
  ];

  const detailViews: DetailView[] = [
    {
      id: dbRegistrationIds[0],
      content: (
        <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
          <ActionBar
            actions={overviewActionItems}
            onActionClick={closePanels}
            infoBlocks={containerDbStatus ? disabledActionsInfoBlocks() : []}
          />
          <MetaItemSection
            labelWidth={185}
            tags={{
              testId: MetaItemSectionTestIds.Tags,
              items: tagsDefaultValue.length ? tagsDefaultValue : undefined,
              add: {
                testId: LinkTestIds.AddTags,
                onClick: () => {
                  trackActionClick(ActionIds.EditTags, pageId, DetailsPanelId.OVERVIEW);
                  onEditTags();
                },
              },
              edit: {
                id: ActionIds.EditTags,
                testId: MetaItemActionTestIds.EditTags,
                onClick: () => {
                  trackActionClick(ActionIds.EditTags, pageId, DetailsPanelId.OVERVIEW);
                  onEditTags();
                },
              },
            }}
          >
            {essentialsMetaItemGroup}
          </MetaItemSection>
        </Stack>
      ),
    },
    {
      id: dbRegistrationIds[1],
      content: (
        <TagDetails
          key={tagTabKey}
          resourceId={vmDbId}
          tagsDefaultValue={tagsDefaultValue}
          onApply={(formValues: FormValues) => {
            trackFormSave(InlineFormId.TAGS, pageId, DetailsPanelId.TAGS);
            const tags = getValue<TagsInfoType>(
              formValues,
              TagDetailsFields.Tags,
              InputFormGroup,
            ) ?? [];
            // *** Do not call refresh when updating tags on the tags tab to avoid resetting user input ***
            const updateTagsProps = {
              databaseId: dbInstance?.id,
              tags,
              location,
              onExecute: () => tagDetailsRef.current?.toggleInProgress(false, panelRef.current),
            };
            tagDetailsRef.current?.toggleInProgress(true, panelRef.current);
            if (isCdb) {
              triggerUpdateTagsCdb(updateTagsProps);
            } else {
              triggerUpdateTagsPdb(updateTagsProps);
            }
          }}
          componentRef={setTagDetailsRef}
        />
      ),
    },
    {
      id: dbRegistrationIds[2],
      content: (
        <VmDbConnectDetails
          database={dbInstance}
          location={location}
          refresh={refresh}
        />
      ),
    },
    ...(isCdb
      ? [
        {
          id: dbRegistrationIds[3],
          content: (
            <VmDbConfigurationDetails
              cdb={dbInstance as VmdbDatabase}
            />
          ),
        },
        {
          id: dbRegistrationIds[4],
          content: (
            <VmDbManagementDetails
              cdb={dbInstance as VmdbDatabase}
              location={location}
              refresh={refresh}
            />
          ),
        },
        {
          id: dbRegistrationIds[5],
          content: (
            <VmDbBackupsList
              database={dbInstance as VmdbDatabase}
              location={location}
            />
          ),
        },
        {
          id: dbRegistrationIds[6],
          content: (
            <VmDbPdbsList
              cdb={(dbInstance as VmdbDatabase)}
              location={location}
            />
          ),
        },
      ]
      : []),
  ];

  const groupItems: GroupItem[] = [
    {
      items: [
        {
          icon: SvgIconIds.baseDbSvg,
          id: detailViews[0].id,
          name: Messages.labels.overview(),
        },
        {
          icon: SvgIconIds.tagsSvg,
          id: detailViews[1].id,
          name: Messages.labels.tags(),
        },
      ],
    },
    {
      heading: Messages.labels.settings(),
      items: [
        { icon: SvgIconIds.connectSvg, id: detailViews[2].id, name: Messages.labels.connect() },
        ...(isCdb
          ? [{ icon: SvgIconIds.configurationSvg, id: detailViews[3].id, name: Messages.labels.configuration() },
            { icon: SvgIconIds.managementSvg, id: detailViews[4].id, name: Messages.labels.management() }]
          : []),
      ],
    },
  ];

  if (isCdb) {
    groupItems.push({
      heading: Messages.labels.resources(),
      items: [
        { icon: SvgIconIds.backupsSvg, id: detailViews[5].id, name: Messages.labels.backups() },
        { icon: SvgIconIds.pluggableSvg, id: detailViews[6].id, name: Messages.labels.pluggableDatabases() },
      ],
    });
  }

  const renderedContent = (): JSX.Element => {
    if (dbError) {
      const isNotFound = dbError.status === 404;
      const errorTitle = isNotFound
        ? Messages.detailVmDb.loadError.notFound.title()
        : Messages.detailVmDb.loadError.general.title();
      const errorDetail = isNotFound
        ? Messages.detailVmDb.loadError.notFound.details()
        : Messages.detailVmDb.loadError.general.details();

      return (
        <ErrorViewPanel
          icon={SvgIconIds.baseDbSvg}
          title={resourceName}
          errorTitle={errorTitle}
          resourceId={vmDbId}
          errorCode={dbError.status || 0}
          details={[
            errorDetail,
            Messages.notifications.apiErrorMsg(dbError.body?.message || ""),
          ]}
          isOpen
          onClose={back}
          favoriteAccessRegistration={{ id: vmDbId, data: { location } }}
        />
      );
    }
    return (
      <DetailViewsPanel
        componentRef={setPanelRef}
        title={getViewTitle(panelId, dbInstance?.name || "")}
        subTitle={subtitle}
        icon={getViewIcon(panelId)}
        onClose={back}
        isOpen
        views={detailViews}
        activeViewId={getViewId(panelId, dbRegistrationIds)}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
        status={dbInstance?.status !== ResourceStatus.Active ? {
          status: getResourceLifecycleStatus(dbInstance?.status || ""),
          tooltip: stateT(dbInstance?.status || ""),
        } : undefined}
        favoriteAccessRegistration={{ id: dbInstance?.id || "", data: { location } }}
      />
    );
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={dbRegistrationIds}
      title={dbInstance?.name || Messages.detailVmDb.titles.short()}
      titleSuffix={dbInstance?.name ? getTitleSuffix(panelId || "") : undefined}
      recentAccessRegistration={{ id: dbInstance?.id || "", data: { location } }}
    >
      {renderedContent()}
    </BookmarkablePage>
  );
};
