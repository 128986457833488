import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  GroupOption,
  optimizedRetryOption,
  Select,
  SelectOption,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { AzureVirtualNetworkCollection, AzureVirtualNetworkSummary } from "../../gen/clients/mchub-azure-api-client";
import { IdResourceType, parseId } from "../../helpers/idHelper";
import { responseItemstoArray } from "../../helpers/resourceHelper";
import { groupBy } from "../../helpers/utilHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

export interface VNETProps extends Omit<SelectProps, "options" | "onChange"> {
  subscriptionId: string;
  location: string;
  multiSelect?: boolean;
  onChange?: (id: string, vnet: AzureVirtualNetworkSummary | undefined) => void;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const VNETSelect = ({
  defaultValue,
  fieldName,
  label,
  testId,
  inputLink,
  placeholder,
  required,
  subField,
  tooltip,
  subscriptionId,
  location,
  multiSelect,
  validator,
  onChange,
  onError,
  onMissingDependencies,
}: VNETProps): JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());
  const [options, setOptions] = React.useState<GroupOption []>([]);

  const form: FormState = React.useContext(FormContext);

  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const { loading, response, error } = useQueryCall({
    wait: !(subscriptionId && location),
    method: apiClients.withRegion(getOciRegion(location)).azResourceApi.listAzureVirtualNetworksBySubscriptionOnly,
    options: {
      args: { subscriptionId, location },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVNET(),
      },
    },
  });

  const vnets = (response
    && responseItemstoArray<AzureVirtualNetworkCollection, AzureVirtualNetworkSummary>(response)) || [];

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setKey(uniqueGUID());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location]);

  React.useEffect(() => {
    if (vnets.length) {
      const groups = groupBy(vnets, v => parseId(v.id).resourceGroup);
      const newOptions: GroupOption[] = [];
      Object.keys(groups).forEach(groupKey => {
        const list = groups[groupKey]?.map(item => ({
          id: item.id,
          text: `${item.name} (${item.addressSpaces?.join(", ")})`,
          data: item,
        } as SelectOption));
        newOptions.push({
          heading: groupKey,
          options: list,
        });
      });
      setOptions(newOptions);
      setKey(uniqueGUID());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(vnets)]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    if (!location) missingDependencies.push(IdResourceType.LOCATION);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const internalOnChange = (id: string): void => {
    const vnet = vnets?.find(item => item.id === id);
    onChange?.(id, vnet);
  };

  const derivedPlaceholder = !loading && !error && options.length === 0
    ? Messages.createCommon.loadingNone(label?.toLowerCase() || "")
    : placeholder;

  return (
    <Select
      key={key}
      testId={testId}
      loading={!!subscriptionId && loading}
      required={required}
      subField={subField}
      fieldName={fieldName}
      label={label}
      options={options}
      placeholder={derivedPlaceholder}
      inputLink={inputLink}
      defaultValue={defaultValue}
      tooltip={tooltip}
      multiSelect={multiSelect}
      onClick={internalOnClick}
      onChange={internalOnChange}
      validator={validator}
    />
  );
};
