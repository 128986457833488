import {
  ConfigInput,
  ConfigInputProps,
  FormValues,
  getValue,
  InputFormGroup,
  InputLink,
  SelectOption,
} from "o4a-react";
import { buildTestId } from "o4a-react/dist/helpers/testIdHelper";
import * as React from "react";
import { NormalError } from "savant-connector";
import * as Messages from "../../codegen/Messages";
import { VmdbDbSystemShapeSummary } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { IdResourceType } from "../../helpers/idHelper";
import { Fields as ShapeCalloutFields, VmDbShapeCallout } from "./VmDbShapeCallout";

export interface VmShapeConfigValue {
  shape: VmdbDbSystemShapeSummary;
  cpuCoreCount: number;
}

export interface VmShapeConfigInputProps extends Omit<ConfigInputProps, "configInputValue"> {
  subscriptionId: string;
  location: string;
  onChange?: (value: VmShapeConfigValue) => void;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

enum NewSidePanels {
  VM_SHAPE = "VM_SHAPE",
}

export enum Fields {
  Shape = "vmShape",
}

export type VmDbShapeConfirgInputTestId = { link: string; };

export const buildVmDbShapeConfirgInputTestId = (testId: string)
  : VmDbShapeConfirgInputTestId => ({ link: buildTestId(testId, "-link") });

export const VmDbShapeConfigInput = ({
  fieldName,
  label,
  testId,
  placeholder,
  required,
  subField,
  tooltip,
  subscriptionId,
  location,
  validator,
  onChange,
  onError,
}: VmShapeConfigInputProps): JSX.Element => {
  const [sidePanelOpen, setSidePanelOpen] = React.useState<NewSidePanels | undefined>(undefined);
  const [shapeTitle, setShapeTitle] = React.useState<string>(Messages.createNewPanels.vmShape.title());
  const [shapeSummary, setShapeSummary] = React.useState<string |undefined>(undefined);
  const [shapeConfigValue, setShapeConfigValue] = React.useState<VmShapeConfigValue>();
  const shapeConfigInput = {
    title: shapeTitle,
    value: shapeConfigValue,
    summary: shapeSummary,
  };

  function configureVMShapeLink(sidePanelId: NewSidePanels): InputLink {
    return {
      id: "configureVmSystemShape",
      text: Messages.actions.configureDbSystemShape(),
      onLinkClick: () => setSidePanelOpen(sidePanelId),
      testId: buildVmDbShapeConfirgInputTestId(testId || "").link || undefined,
    };
  }

  React.useEffect(() => {
    setShapeTitle(Messages.createNewPanels.vmShape.title());
    setShapeSummary(undefined);
    setShapeConfigValue(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId]);

  const onSubmitNewResource = (formValues: FormValues): void => {
    const selectedShape = getValue<SelectOption[]>(formValues, Fields.Shape, InputFormGroup)?.[0].data;
    const newCoreCount = getValue<string>(formValues, ShapeCalloutFields.CpuCoreCount, InputFormGroup);
    const cpuCoreCount = newCoreCount ? parseInt(newCoreCount, 10) : 0;
    setShapeTitle(selectedShape.name);
    setShapeSummary(Messages.configTitles.ocpuCountPerVM(cpuCoreCount.toString()));
    const configValue = { shape: selectedShape, cpuCoreCount };
    setShapeConfigValue(configValue);
    onChange?.(configValue);
    setSidePanelOpen(undefined);
  };

  const configureVMShape = configureVMShapeLink(NewSidePanels.VM_SHAPE);

  return (
    <>
      <ConfigInput
        configInputValue={shapeConfigInput}
        required={required}
        subField={subField}
        fieldName={fieldName}
        label={label}
        inputLink={configureVMShape}
        placeholder={placeholder}
        tooltip={tooltip}
        validator={() => validator?.(shapeConfigValue)}
      />
      {sidePanelOpen === NewSidePanels.VM_SHAPE
    && (
    <VmDbShapeCallout
      isCreateMode
      onError={onError}
      subscriptionId={subscriptionId}
      location={location}
      defaultValue={
        shapeConfigValue
          ? { shapeId: shapeConfigValue.shape.name, cpuCoreCount: shapeConfigValue.cpuCoreCount }
          : undefined
      }
      targetId={configureVMShape.id}
      onSubmit={onSubmitNewResource}
      onClose={() => setSidePanelOpen(undefined)}
      title={Messages.createNewPanels.vmShape.title()}
      fieldName={Fields.Shape}
    />
    )}
    </>
  );
};
