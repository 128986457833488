import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXADB_PDB_STOP_POLL_DELAY,
  EXADB_PDB_STOP_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface ExaDbStopPdbProps extends OperationConfirmationProps {
  databaseId: string;
}

const ExaDbStopPdb = (
  { location, databaseId, onExecute }: ExaDbStopPdbProps,
): JSX.Element => {
  const { invokeCall: invokeStopPDB } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.stopPluggableDatabase },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
    const resGroup = decodeURIComponent(resourceGroup || "");
    const pdbName = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.stopExaDb(),
          message: Messages.notifications.inProgress.messages.stopExaDb(pdbName),
        },
        success: {
          title: Messages.notifications.success.titles.stopExaDb(),
          message: Messages.notifications.success.messages.stopExaDb(pdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.stop(),
          message: Messages.notifications.failure.messages.stopExaDb(pdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXADB_PDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            pluggableDatabaseName: pdbName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.EXADB_STOPPED_CHECK_PDB,
          delay: EXADB_PDB_STOP_POLL_DELAY,
          interval: EXADB_PDB_STOP_POLL_INTERVAL,
        },
      },
    };
    invokeStopPDB({
      subscriptionId,
      resourceGroupName: resGroup,
      pluggableDatabaseName: pdbName,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newExaDbStopPdb = (
  props: ExaDbStopPdbProps,
): JSX.Element => (<ExaDbStopPdb {...props} />);
