import {
  ConfigInput,
  ConfigInputValues,
  DateTimePicker,
  FieldSet,
  FieldWrapper,
  FormContext,
  FormState,
  InputLink,
  Select,
  SelectOption,
  TextInput,
  TextInputMultilineConfig,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { PageId, ServiceProviders } from "../../constants/pluginConstants";
import {
  CreateIncidentDetailsProblemTypeEnum,
  CreateLimitItemDetails,
} from "../../gen/clients/mchub-azure-api-client-platform";
import { SupportIssueType, SupportSeverity } from "../../helpers/resourceHelper";
import { validateSupportDescription } from "../../helpers/validationHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { newSupportQuotaRequest, SupportQuotaRequestProps } from "../../operations/Support/SupportQuotaRequest";
import { getAzureLocationName } from "../../utils";

export const GROUP_DETAILS = "details";

export enum ActionIds {
  QuotaRequest = "quota-request",
}

export enum Fields {
  StartDate = "startDate",
  Description = "description",
  Quotas = "quotas",
  Severity = "severity",
}

export enum FieldTestIds {
  StartDate = "start-date",
  Quotas = "configuration-input",
  Description = "issue-description",
  Severity = "severity-select",
}

export enum SeverityOptionTestIds {
  SeverityHighest = "severity-highest",
  SeverityHigh = "severity-high",
  SeverityMedium = "severity-medium",
  SeverityLow = "severity-low",
}

export enum LinkTestIds {
  QuotaDetailsLink = "quota-detail-link",
}

export interface QuotaRequestDetails {
  name: string;
  description: string;
  location?: string;
  currentValue: number;
  currentUsed?: number;
  newValue: number;
}

export const getQuotaDetails = (
  quotaRequestDetails: QuotaRequestDetails,
): CreateLimitItemDetails => {
  let locationPart = "";
  if (quotaRequestDetails?.location) {
    const locationDisplayName = getAzureLocationName(quotaRequestDetails?.location);
    locationPart = locationDisplayName ? `${locationDisplayName}, ` : "";
  }

  // eslint-disable-next-line max-len
  const requestSummary = `${locationPart}${quotaRequestDetails?.name} ${Messages.labels.quotaUsageAndLimitAndRequested(quotaRequestDetails?.currentUsed?.toString() || Messages.common.unknown(), quotaRequestDetails?.currentValue.toString(), quotaRequestDetails?.newValue.toString())}`;
  return {
    name: requestSummary,
    limitId: quotaRequestDetails?.name,
    location: quotaRequestDetails?.location,
    currentLimit: quotaRequestDetails?.currentValue,
    currentUsage: quotaRequestDetails?.currentUsed,
    requestedLimit: quotaRequestDetails?.newValue,
  };
};

export interface DetailsTabContentProps {
  subscriptionId: string;
  issueType: CreateIncidentDetailsProblemTypeEnum | undefined;
  provider?: ServiceProviders;
}

export const DetailsTabContent = ({ subscriptionId, issueType, provider }
  : DetailsTabContentProps): JSX.Element => {
  const form = React.useContext<FormState>(FormContext);
  const [remount, setRemount] = React.useState<string>(uniqueGUID());
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const [quotaRequest, setQuotaRequest] = React.useState<QuotaRequestDetails | undefined>();

  const { locale } = React.useContext<Settings>(SettingsContext);

  React.useEffect(() => {
    form.setValue({} as ConfigInputValues, Fields.Quotas, GROUP_DETAILS);
    setQuotaRequest(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, provider]);

  React.useEffect(() => {
    form.setValue(undefined, Fields.StartDate, GROUP_DETAILS);
    form.setValue(undefined, Fields.Description, GROUP_DETAILS);
    form.setValue({} as ConfigInputValues, Fields.Quotas, GROUP_DETAILS);
    form.setValue(undefined, Fields.Severity, GROUP_DETAILS);

    setQuotaRequest(undefined);

    setRemount(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueType]);

  const descriptionLabel = (): string => {
    switch (issueType) {
      case SupportIssueType.BILLING:
        return Messages.labels.provideIssueDetails();
      case SupportIssueType.TECHNICAL:
        return Messages.labels.description();
      default:
        return "";
    }
  };

  const { trigger: triggerSupportQuotaRequest } = useOperation<SupportQuotaRequestProps>(newSupportQuotaRequest);

  const onQuotaDetails = (): void => {
    if (provider) {
      trackActionClick(ActionIds.QuotaRequest, PageId.SUPPORT_CREATE);
      triggerSupportQuotaRequest({
        subscriptionId,
        provider,
        onExecute: setQuotaRequest,
        onCancel: () => trackActionDiscard(ActionIds.QuotaRequest, PageId.SUPPORT_CREATE),
        quotaRequestValues: {
          limitName: quotaRequest?.name,
          location: quotaRequest?.location,
          newLimit: quotaRequest?.newValue,
        },
      });
    }
  };

  const quotaDetailsColumns: IColumn[] = [
    {
      key: "name",
      name: Messages.labels.requestSummary(),
      fieldName: "name",
      data: "string",
      isResizable: true,
      isMultiline: true,
      maxWidth: 300,
      minWidth: 300,
    },
    {
      key: "requested-limit",
      name: Messages.labels.newQuotaLimit(),
      fieldName: "requestedLimit",
      data: "number",
      isResizable: true,
      minWidth: 100,
    },
  ];

  const severityOptions: SelectOption[] = [
    {
      id: SupportSeverity.HIGHEST,
      text: Messages.supportSeverity.highest(),
      testId: SeverityOptionTestIds.SeverityHighest,
    },
    {
      id: SupportSeverity.HIGH,
      text: Messages.supportSeverity.high(),
      testId: SeverityOptionTestIds.SeverityHigh,
    },
    {
      id: SupportSeverity.MEDIUM,
      text: Messages.supportSeverity.medium(),
      testId: SeverityOptionTestIds.SeverityMedium,
    },
    {
      id: SupportSeverity.LOW,
      text: Messages.supportSeverity.low(),
      testId: SeverityOptionTestIds.SeverityLow,
    },
  ];

  return (
    <>
      <FieldSet
        header={Messages.createSupport.detailsTab.sectionTitles.tellUs()}
        description={Messages.createSupport.detailsTab.sectionDescriptions.tellUs()}
      >
        <div />
      </FieldSet>
      <FieldSet
        header={Messages.createSupport.detailsTab.sectionTitles.problemDetails()}
        description={issueType === SupportIssueType.QUOTAS
          ? Messages.createSupport.detailsTab.sectionDescriptions.problemDetails()
          : undefined}
      >
        {issueType === SupportIssueType.QUOTAS && (
          <>
            <ConfigInput
              key={`quotas-${remount}`}
              fieldName={Fields.Quotas}
              testId={FieldTestIds.Quotas}
              label={Messages.labels.requestDetails()}
              required
              configInputValue={{
                title: quotaRequest
                  ? Messages.configTitles.quotaRequests("1")
                  : Messages.configTitles.provideRequestDetails(),
                value: quotaRequest,
              } as ConfigInputValues}
              inputLink={{
                id: "quota-request",
                testId: LinkTestIds.QuotaDetailsLink,
                text: quotaRequest ? Messages.actions.updateDetails() : Messages.actions.enterDetails(),
                onLinkClick: onQuotaDetails,
              } as InputLink}
            />
            {quotaRequest && (
              <FieldWrapper>
                <DetailsList
                  setKey="connection-string-items"
                  ariaLabelForGrid={Messages.ariaLabel.resourceList()}
                  items={quotaRequest ? [getQuotaDetails(quotaRequest)] : []}
                  columns={quotaDetailsColumns}
                  selectionMode={SelectionMode.none}
                />
              </FieldWrapper>
            )}
          </>
        )}
        {(issueType === SupportIssueType.BILLING || issueType === SupportIssueType.TECHNICAL) && (
          <>
            <DateTimePicker
              key={`datepicker-${remount}`}
              fieldName={Fields.StartDate}
              testId={FieldTestIds.StartDate}
              label={Messages.labels.problemStartDate()}
              required
              locale={locale}
            />
            <TextInput
              key={`description-${remount}`}
              fieldName={Fields.Description}
              testId={FieldTestIds.Description}
              label={descriptionLabel()}
              required
              multiline={{ rows: 5 } as TextInputMultilineConfig}
              validator={validateSupportDescription}
            />
          </>
        )}
        <Select
          key={`severity-${remount}`}
          fieldName={Fields.Severity}
          testId={FieldTestIds.Severity}
          label={Messages.labels.severity()}
          required
          options={severityOptions}
          hideSearchBox
          defaultValue={issueType !== SupportIssueType.TECHNICAL ? [SupportSeverity.MEDIUM] : undefined}
          disabled={issueType !== SupportIssueType.TECHNICAL}
        />
      </FieldSet>
    </>
  );
};
