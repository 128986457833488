import {
  AnchoredPanelComponent,
  FormValues,
  getValue,
  InputFormCallout,
  InputFormGroup,
  NotificationRequest,
  NotificationType,
  SubmitButtonMode,
  TextInput,
  useNavigation,
  useNotificationRequest,
} from "o4a-react";
import * as React from "react";
import { Stack, Text } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  ORACLE_SUPPORT_PROVIDER_PATH,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import { CalloutTestIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { IdResourceType } from "../../helpers/idHelper";
import { disableRetry } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface SupportQuickSearchProps extends OperationActionProps {
  panelRef: AnchoredPanelComponent;
  subscriptionId: string | undefined;
}

export const enum Fields {
  IncidentId = "incidentId",
}

export enum FieldTestIds {
  IncidentId = "incidentId",
}

const detailsPageRegistrationIds = PageRegistrationConfig[PageId.SUPPORT_DETAILS].map(config => config.key);

const SupportQuickSearch = (
  { panelRef, subscriptionId, targetId, location, onCancel }: SupportQuickSearchProps,
): JSX.Element => {
  const { submit: submitNotificationRequest } = useNotificationRequest();
  const { navigateTo } = useNavigation(ConsoleContext);
  const [incidentId, setIncidentId] = React.useState<string | undefined>();

  const { loading: loadingSearch, error: errorSearch, response } = useQueryCall({
    wait: !(subscriptionId && location && incidentId),
    method: apiClients.withRegion(getOciRegion(location)).incidentsApi.getIncident,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        incidentKey: incidentId || "",
      },
      caching: ttlOneMinCaching,
      retry: disableRetry,
    },
  });

  // eslint-disable-next-line max-len
  const searchedIncidentId = `/subscriptions/${subscriptionId}/providers/${ORACLE_SUPPORT_PROVIDER_PATH}/${IdResourceType.INCIDENTS}/${incidentId}`;

  React.useEffect(() => {
    if (!loadingSearch) {
      if (errorSearch && errorSearch.status === 404) {
        submitNotificationRequest({
          type: NotificationType.FAILURE,
          title: Messages.notifications.failure.titles.searchSupportRequest(),
          message: Messages.notifications.failure.messages.searchSupportRequest(),
        } as NotificationRequest);
      } else {
        const problemType = response?.data?.problemType;
        navigateTo(
          `${RESOURCE_ROUTE}/${searchedIncidentId}/${PageRegistrationConfig[PageId.SUPPORT_DETAILS][0].panelPath}`,
          detailsPageRegistrationIds[0],
          { problemType },
        );
      }
      setIncidentId(undefined);
      panelRef.toggleInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSearch, loadingSearch]);

  const onSearch = (formValues: FormValues): void => {
    setIncidentId(getValue<string>(formValues, Fields.IncidentId, InputFormGroup));
    panelRef.toggleInProgress(true);
  };

  return (
    <InputFormCallout
      targetId={targetId}
      title=""
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      primaryButtonText={Messages.actions.search()}
      defaultButtonText={Messages.common.cancel()}
      onSubmit={onSearch}
      onClose={onCancel}
      testId={CalloutTestIds.SupportQuickSearch}
    >
      <Stack>
        <Text>{Messages.createNewPanels.supportSearch.description()}</Text>
        <br />
        <TextInput
          required
          fieldName={Fields.IncidentId}
          label={Messages.labels.supportRequestId()}
          testId={FieldTestIds.IncidentId}
        />
      </Stack>
    </InputFormCallout>
  );
};

export const newSupportQuickSearch = (
  props: SupportQuickSearchProps,
): JSX.Element => (<SupportQuickSearch {...props} />);
