import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { ResourceLinkTestIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { MultiCloudVirtualNetwork } from "../../gen/clients/mchub-azure-api-client";
import { parseId } from "../../helpers/idHelper";
import { AnalyticsDataPage, LinkAnalyticsData } from "../../hooks/useAnalytics";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { Resource, ResourceLink } from "./ResourceLink";

export interface McVcnLinkProps {
  mcVcnId: string|undefined;
  location: string;
  analytics: AnalyticsDataPage;
}

const registrationIds = PageRegistrationConfig[PageId.MCVCN_DETAILS].map(config => config.key);

export const McVcnLink = ({
  mcVcnId,
  location,
  analytics,
}: McVcnLinkProps): JSX.Element => {
  const mcVcnIdComps = parseId(mcVcnId);
  const mcVcnIdResGroup = decodeURIComponent(mcVcnIdComps?.resourceGroup || "");
  const mcVcnIdResourceName = decodeURIComponent(mcVcnIdComps?.resourceName || "");

  const { response, loading, error } = useQueryCall({
    wait: !mcVcnId,
    method: apiClients.withRegion(getOciRegion(location)).networkApi.getMultiCloudVirtualNetwork,
    options: {
      args: {
        subscriptionId: mcVcnIdComps?.subscriptionId || "",
        resourceGroupName: mcVcnIdResGroup,
        multiCloudVirtualNetworkName: mcVcnIdResourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadMcvcn(),
      },
      excludeErrorStatus: [404],
    },
  });

  const mcvcn: MultiCloudVirtualNetwork | undefined = response?.data;

  return (
    <ResourceLink
      resource={mcvcn as Resource}
      navigation={{
        navigationKey: registrationIds[0],
        panelPath: PageRegistrationConfig[PageId.MCVCN_DETAILS][0].panelPath,
      }}
      analytics={{
        target: { pageId: PageId.MCVCN_DETAILS, panelId: DetailsPanelId.OVERVIEW },
        source: { pageId: analytics.pageId, panelId: analytics.panelId },
      } as LinkAnalyticsData}
      error={error}
      loading={loading}
      testId={ResourceLinkTestIds.McVcnLink}
    />
  );
};
