/* eslint-disable max-len */
import {
  ActionType,
  AnchoredPanelComponent,
  DateTimeDisplay,
  ExtraFilter,
  FilterAll,
  FilterComponent,
  FilterLayout,
  InfoBlockProps,
  InfoBlockStatus,
  Listing,
  ListingColumn,
  ListingDisplayNameLink,
  NoValue,
  Operator,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { PageId, PageRegistrationConfig, RESOURCE_ROUTE, SUPPORT_CREATE_ROUTE } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  AzureSubscriptionSummary,
  IncidentProblemTypeEnum,
  TicketLifecycleStateValues,
} from "../../gen/clients/mchub-azure-api-client";
import {
  Incident,
  IncidentCollection,
  TicketLifecycleStateEnum,
  TicketSeverityEnum,
} from "../../gen/clients/mchub-azure-api-client-platform";
import {
  getIncidentProblemType,
  getIncidentSeverity,
  getSupportTicketStatus,
  incidentSeveritySortComparator,
  problemTypeSortComparator,
  responseItemstoArray,
  SupportIssueType,
  SupportSeverity,
  supportTicketStatusSortComparator,
} from "../../helpers/resourceHelper";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFilterStatePills } from "../../hooks/useFilterStatePills";
import { useLocationFilters } from "../../hooks/useLocationFilters";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRoles } from "../../hooks/useRoles";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { newSupportQuickSearch, SupportQuickSearchProps } from "../../operations/Support/SupportQuickSearch";
import { getOciRegion } from "../../utils";

export enum ColumnIds {
  Title = "title",
  TicketId = "ticketNumber",
  Severity = "severity",
  TimeCreated = "timeCreated",
  SubscriptionName = "subscriptionName",
  ProblemTypeName = "problemType",
  TimeUpdated = "timeUpdated",
  LifecycleState = "lifecycleState"
}

export enum ColumnTestIds {
  Title = "title",
  TicketId = "ticketNumber",
  Severity = "severity",
  TimeCreated = "timeCreated",
  SubscriptionName = "subscriptionName",
  ProblemTypeName = "problemType",
  TimeUpdated = "timeUpdated",
  LifecycleState = "lifecycleState"
}

export enum ActionIds {
  Create = "create",
  Refresh = "refresh",
  Search = "search",
}

export enum ActionBarTestIds {
  Create = "create",
  Refresh = "refresh",
  Search = "search",
}

export enum FilterTestIds {
  SubscriptionFilter = "subscription-filter",
  LocationFilter = "location-filter",
  TimeCreatedFilter = "time-created-filter",
  LifecycleStateFilter = "lifecycle-state-filter",
  TypeFilter = "type-filter",
  SeverityFilter = "severity-filter",
}

enum FilterDatePeriod {
  past7days = "past7days",
  past14days = "past14days",
  past30days = "past30days",
  past60days = "past60days",
  past90days = "past90days",
  allHistory = "allHistory",
}

interface AzureSubscriptionSummaryMap {
  [key: string]: AzureSubscriptionSummary;
}

export interface IncidentDataItem {
  id: string;
  title: string;
  severity: TicketSeverityEnum,
  timeCreated: Date | undefined;
  subscriptionName: string;
  timeUpdated: Date | undefined;
  lifecycleState: TicketLifecycleStateEnum | string;
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
  ticketNumber?: string;
  problemType?: IncidentProblemTypeEnum;
}

const detailsPageRegistrationIds = PageRegistrationConfig[PageId.SUPPORT_DETAILS].map(config => config.key);

export interface SupportListProps {
  panelRef: AnchoredPanelComponent;
  disabled?: boolean;
}

export const SupportList = ({ panelRef, disabled }: SupportListProps): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { navigateTo } = useNavigation(ConsoleContext);
  const filterState = React.useContext<FilterState>(FilterStateContext);
  const { pathname } = useLocation();

  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const { preferredSubscription, locale } = React.useContext<Settings>(SettingsContext);

  const [selectedSubscription, setSelectedSubscription] = React.useState<string>("");
  const [subscriptionOptions, setSubscriptionOptions] = React.useState<{ key: string, text: string }[]>([]);

  const { selectedLocation: location } = useLocationFilters(
    filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
  );

  const region = getOciRegion(location);
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();

  const { perPageItemsCount } = useRowCount();

  const [filterComponentRef, setFilterComponentRef] = React.useState<FilterComponent>();
  const pillFilterValues = filterComponentRef?.getFilters();
  const filterTextValue = filterComponentRef?.getFilterText();
  const [filterText, setFilterText] = React.useState(filterState.filterTextValue || filterTextValue);

  const getSeverityVal = (severity: TicketSeverityEnum): string => getIncidentSeverity(severity);

  const getStatusVal = (value: IncidentDataItem): string => getSupportTicketStatus(value.lifecycleState);

  const getDateTime = (value: Date | undefined): JSX.Element => <DateTimeDisplay date={value} hideClipboardCopy />;
  const getIncidentId = (value: IncidentDataItem): string => value.ticketNumber ?? "";

  const mapProblemTypeToName = (value: IncidentDataItem): JSX.Element => {
    if (value.problemType) {
      return <span>{getIncidentProblemType(value.problemType, true)}</span>;
    }

    return <NoValue />;
  };

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Title,
      testId: ColumnTestIds.Title,
      name: Messages.labels.title(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.title}
          navigation={{
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.SUPPORT_DETAILS][0].panelPath}`,
            pageKey: detailsPageRegistrationIds[0],
            customData: { problemType: value.problemType },
          }}
        />
      ),
    },
    {
      itemProp: ColumnIds.TicketId,
      testId: ColumnTestIds.TicketId,
      name: Messages.labels.id(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getIncidentId(value),
    },
    {
      itemProp: ColumnIds.Severity,
      testId: ColumnTestIds.Severity,
      name: Messages.labels.severity(),
      flexGrow: 1,
      initialSortDirection: SortDirections.DESC,
      comparator: (
        a: IncidentDataItem,
        b: IncidentDataItem,
      ) => incidentSeveritySortComparator<IncidentDataItem>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getSeverityVal(value.severity),
    },
    {
      itemProp: ColumnIds.TimeCreated,
      testId: ColumnTestIds.TimeCreated,
      name: Messages.labels.created(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getDateTime(value.timeCreated),
    },
    {
      itemProp: ColumnIds.SubscriptionName,
      testId: ColumnTestIds.SubscriptionName,
      name: Messages.labels.subscription(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureSubscriptionLink resourceId={value.id} subscriptionName={value.subscriptionName} hideClipboard />,
    },
    {
      itemProp: ColumnIds.ProblemTypeName,
      testId: ColumnTestIds.ProblemTypeName,
      name: Messages.labels.type(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      comparator: (
        a: IncidentDataItem,
        b: IncidentDataItem,
      ) => problemTypeSortComparator<IncidentDataItem>(a, b, locale),
      onRenderItems: value => mapProblemTypeToName(value),
    },
    {
      itemProp: ColumnIds.TimeUpdated,
      testId: ColumnTestIds.TimeUpdated,
      name: Messages.labels.updated(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getDateTime(value.timeUpdated),
    },
    {
      itemProp: ColumnIds.LifecycleState,
      testId: ColumnTestIds.LifecycleState,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: IncidentDataItem,
        b: IncidentDataItem,
      ) => supportTicketStatusSortComparator<IncidentDataItem>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatusVal(value),
    },
  ];

  const {
    refresh: incidentListRefresh,
    loading: incidentListLoading,
    response: incidentListResponse,
    // error: incidentListError,
  } = useQueryCall({
    wait: !(selectedSubscription && region) || disabled, // Do not fetch if listing is disabled due to missing roles
    method: apiClients.withRegion(region).incidentsApi.listIncidents,
    options: {
      args: { subscriptionId: selectedSubscription },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadSupportList(),
      },
    },
  });

  const incidents = incidentListResponse
    && responseItemstoArray<IncidentCollection, Incident>(incidentListResponse);

  const IncidentDataSummaryToIncidentDataItem = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any[],
    subscriptionsMap: AzureSubscriptionSummaryMap,
  ): IncidentDataItem[] => {
    const incidentDataItem: IncidentDataItem[] = [];
    items.forEach(item => {
      const subscriptionId = item.subscriptionInformation?.subscriptionId;
      const itemSubscriptionName = subscriptionsMap && subscriptionId && subscriptionsMap[subscriptionId]?.name;
      incidentDataItem.push({
        id: item.id,
        title: item.ticket.title,
        severity: item.ticket.severity,
        timeCreated: item.ticket?.timeCreated,
        subscriptionName: itemSubscriptionName || subscriptionId,
        timeUpdated: item.ticket?.timeUpdated,
        lifecycleState: item.ticket?.lifecycleState,
        ticketNumber: item.ticket?.ticketNumber,
        problemType: item.problemType,
      });
    });
    return incidentDataItem;
  };

  const items = React.useMemo(() => {
    let incidentDataItems: IncidentDataItem[] = [];
    if (subscriptions && subscriptions.length > 0) {
      const subscriptionsMap = subscriptions.reduce((
        map: AzureSubscriptionSummaryMap,
        subscription: AzureSubscriptionSummary,
      ) => {
        map[subscription.id] = subscription;
        return map;
      }, {});

      if (incidents) {
        incidentDataItems = incidentDataItems.concat(
          IncidentDataSummaryToIncidentDataItem(incidents, subscriptionsMap),
        );
      }
    }
    return incidentDataItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(incidents), JSON.stringify(subscriptions)]);

  const createAllowed = isActionAllowed(RoleBasedAction.CREATE_SUPPORT);

  const incidentActionsInfoBlocks: InfoBlockProps[] = createAllowed
    ? []
    : [{
      message: Messages.listSupport.messages.createDisabled(actionRequiredRoles(RoleBasedAction.CREATE_SUPPORT)[0]?.displayName),
      messageType: InfoBlockStatus.INFO,
    }];

  const { trigger: triggerQuickSearch } = useOperation<SupportQuickSearchProps>(newSupportQuickSearch);
  const { closePanels } = useSidePanel();

  const incidentActions: ActionType[] = [
    {
      key: ActionIds.Create,
      testId: ActionBarTestIds.Create,
      text: Messages.actions.createASupportRequest(),
      icon: MonochromeIconIds.Create,
      disabled: !createAllowed,
      onClick: () => {
        trackActionClick(ActionIds.Create, PageId.SUPPORT);
        navigateTo(SUPPORT_CREATE_ROUTE, PageId.SUPPORT_CREATE, {
          analytics: {
            pageId: PageId.SUPPORT,
            actionName: ActionIds.Create,
          } as NavigationAnalyticsData,
        });
      },
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      disabled,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.SUPPORT);
        incidentListRefresh();
      },
    },
    {
      key: ActionIds.Search,
      testId: ActionBarTestIds.Search,
      text: Messages.actions.quickSearch(),
      icon: MonochromeIconIds.Search,
      disabled,
      onClick: () => {
        trackActionClick(ActionIds.Search, PageId.SUPPORT);
        triggerQuickSearch({
          targetId: ActionIds.Search,
          location,
          panelRef,
          subscriptionId: selectedSubscription,
          onCancel: () => trackActionDiscard(ActionIds.Search, PageId.SUPPORT),
        });
      },
    },
  ];

  const operatorMessageCallBack = (operator: Operator): string => {
    let returnMessage = operator.toString();
    if (Operator.Equal === operator || Operator.Contains === operator || Operator.Custom === operator) {
      returnMessage = ":";
    }
    return returnMessage;
  };

  React.useEffect(() => {
    const newSubscriptionOptions: { key: string, text: string }[] = [];
    // eslint-disable-next-line no-unused-expressions
    subscriptions && subscriptions.forEach(subscription => {
      newSubscriptionOptions.push({ key: subscription.id, text: subscription.name });
    });

    setSubscriptionOptions(newSubscriptionOptions);
    if (filterState.defaultSubscription) {
      setSelectedSubscription(filterState.defaultSubscription);
      if (defaultExtraFilters) defaultExtraFilters[0].value = filterState.defaultSubscription;
    } else if (preferredSubscription) {
      setSelectedSubscription(preferredSubscription);
    } else {
      setSelectedSubscription(newSubscriptionOptions.length ? newSubscriptionOptions[0].key : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions)]);

  const subscriptionFilter: ExtraFilter = {
    testId: FilterTestIds.SubscriptionFilter,
    name: columns[4].name,
    operator: Operator.Equal,
    value: filterState.defaultSubscription
      ? filterState.defaultSubscription
      : preferredSubscription || selectedSubscription,
    fieldKey: columns[4].itemProp,
    customOptions: subscriptionOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedSubscription(value);
        filterState.setDefaultSubscription(value);
      }
    },
    hideDelete: true,
    operatorMessageCallBack,
  };

  const timeCreatedFilter: ExtraFilter = {
    testId: FilterTestIds.TimeCreatedFilter,
    name: columns[3].name,
    operator: Operator.Custom,
    value: pillFilterValues?.find(item => item.fieldKey === columns[3].itemProp)?.value || FilterDatePeriod.past7days,
    fieldKey: columns[3].itemProp,
    hideAllOption: true,
    customOptions: [
      {
        key: FilterDatePeriod.past7days,
        text: Messages.listSupport.filters.past7days(),
      },
      {
        key: FilterDatePeriod.past14days,
        text: Messages.listSupport.filters.past14days(),
      },
      {
        key: FilterDatePeriod.past30days,
        text: Messages.listSupport.filters.past30days(),
      },
      {
        key: FilterDatePeriod.past60days,
        text: Messages.listSupport.filters.past60days(),
      },
      {
        key: FilterDatePeriod.past90days,
        text: Messages.listSupport.filters.past90days(),
      },
      {
        key: FilterDatePeriod.allHistory,
        text: Messages.listSupport.filters.allHistory(),
      },
    ],
    customFilterOperation: (currentItem: IncidentDataItem, searchKey: string | string[]) => {
      if (currentItem && searchKey && currentItem.timeCreated) {
        let numberOfDays = 1;
        switch (searchKey) {
          case FilterDatePeriod.past7days:
            numberOfDays = 7;
            break;
          case FilterDatePeriod.past14days:
            numberOfDays = 14;
            break;
          case FilterDatePeriod.past30days:
            numberOfDays = 30;
            break;
          case FilterDatePeriod.past60days:
            numberOfDays = 60;
            break;
          case FilterDatePeriod.past90days:
            numberOfDays = 90;
            break;
          default:
            numberOfDays = 1;
            break;
        }
        const createdTime = new Date(currentItem.timeCreated.toString());
        const now = new Date();
        const msBetweenDates = now.getTime() - createdTime.getTime();
        const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
        if (searchKey === FilterDatePeriod.allHistory) {
          return true;
        }
        if (daysBetweenDates < numberOfDays) {
          return true;
        }
        return false;
      }
      return true;
    },
    isRadioGroupStyle: true,
    hideDelete: true,
    operatorMessageCallBack,
  };

  const lifecycleStateCustomOptions: { key: string, text: string }[] = [
    {
      key: TicketLifecycleStateValues.ACTIVE,
      text: Messages.listSupport.status.open(),
    },
    {
      key: TicketLifecycleStateValues.CLOSED,
      text: stateT(TicketLifecycleStateValues.CLOSED),
    },
  ];

  const lifecycleStateFilter: ExtraFilter = {
    testId: FilterTestIds.LifecycleStateFilter,
    name: columns[7].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[7].itemProp)?.value || TicketLifecycleStateValues.ACTIVE,
    fieldKey: columns[7].itemProp,
    customOptions: lifecycleStateCustomOptions,
    isRadioGroupStyle: true,
    hideDelete: true,
    operatorMessageCallBack,
  };

  const SeverityCustomOptions: { key: string, text: string }[] = [
    {
      key: SupportSeverity.HIGHEST,
      text: getIncidentSeverity(SupportSeverity.HIGHEST as TicketSeverityEnum),
    },
    {
      key: SupportSeverity.HIGH,
      text: getIncidentSeverity(SupportSeverity.HIGH as TicketSeverityEnum),
    },
    {
      key: SupportSeverity.MEDIUM,
      text: getIncidentSeverity(SupportSeverity.MEDIUM as TicketSeverityEnum),
    },
    {
      key: SupportSeverity.LOW,
      text: getIncidentSeverity(SupportSeverity.LOW as TicketSeverityEnum),
    },
  ];

  const severityFilter: ExtraFilter = {
    testId: FilterTestIds.SeverityFilter,
    name: columns[2].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[2].itemProp)?.value || FilterAll,
    fieldKey: columns[2].itemProp,
    customOptions: SeverityCustomOptions,
    isRadioGroupStyle: true,
    hideDelete: true,
    operatorMessageCallBack,
  };

  const customTypeOptions = [
    {
      key: SupportIssueType.BILLING,
      text: Messages.supportIssueTypes.billing(),
    },
    {
      key: SupportIssueType.QUOTAS,
      text: Messages.supportIssueTypes.quotasShort(),
    },
    {
      key: SupportIssueType.TECHNICAL,
      text: Messages.supportIssueTypes.technical(),
    },
  ];

  const typeFilter: ExtraFilter = {
    testId: FilterTestIds.TypeFilter,
    name: columns[5].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[5].itemProp)?.value || FilterAll,
    fieldKey: columns[5].itemProp,
    customOptions: customTypeOptions,
    isRadioGroupStyle: true,
    hideDelete: true,
    operatorMessageCallBack,
  };

  const allFilterPills = [
    subscriptionFilter,
    timeCreatedFilter,
    lifecycleStateFilter,
    severityFilter,
    typeFilter,
  ];

  const filterPills = [
    subscriptionFilter,
    timeCreatedFilter,
    lifecycleStateFilter,
    severityFilter,
    typeFilter,
  ];

  const textFilterCallBack = (rowItem: IncidentDataItem): IncidentDataItem => {
    const displayRowItem = { ...rowItem };
    displayRowItem.lifecycleState = stateT(
      displayRowItem.lifecycleState || "",
    ) as TicketLifecycleStateEnum;
    displayRowItem.severity = getIncidentSeverity(displayRowItem.severity as TicketSeverityEnum) as TicketSeverityEnum;
    return displayRowItem;
  };

  React.useEffect(() => {
    setFilterText(filterTextValue);
    filterState.setFilterTextValue(filterTextValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTextValue]);

  const defaultExtraFilters = useFilterStatePills(pillFilterValues, allFilterPills, pathname, filterPills);

  return (
    <Listing
      items={items}
      testId={ListingTestIds.Support}
      listColumns={columns}
      itemsPerPage={perPageItemsCount}
      actionBarItems={incidentActions}
      onActionClick={closePanels}
      infoBlocks={incidentActionsInfoBlocks}
      selectionMode={SelectionMode.none}
      isLoading={(incidentListLoading || subscriptionLoading) && !disabled}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Title,
      }}
      filtering={{
        allExtraFilters: allFilterPills,
        defaultExtraFilters,
        defaultFilterText: filterText,
        hideAddFilterPill: true,
        filterLayout: FilterLayout.Pill,
        textFilterCallBack,
        componentRef: setFilterComponentRef,
        disableCollapse: true,
      }}
      emptyList={{ title: Messages.listSupport.emptyList.title() }}
    />
  );
};
