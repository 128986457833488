import { InputFormSidePanelComponent } from "o4a-react";
import React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ExaInfraDeletePanel } from "../../components/ExaInfraDeletePanel/ExaInfraDeletePanel";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { MutationCallOptions, useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXAINFRA_DELETE_POLL_DELAY,
  EXAINFRA_DELETE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface ExaInfraDeleteProps extends OperationProps {
  exaInfraId: string;
}

const ExaInfraDelete = ({ exaInfraId, location, onExecute, onCancel }: ExaInfraDeleteProps): JSX.Element => {
  const { invokeCall: deleteCloudExadataInfrastructure } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.deleteCloudExadataInfrastructure },
  );

  const [componentRef, setComponentRef] = React.useState<InputFormSidePanelComponent>();

  const onDeleteConfirm = (): void => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(exaInfraId);

    const resourceGroupName = decodeURIComponent(resourceGroup);
    const cloudExadataInfrastructureName = decodeURIComponent(resourceName);

    const invokeExaInfraOptions: MutationCallOptions<Response> = {
      onSuccess: onExecute,
      onFailure: componentRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.deleteExaInfra(),
          message: Messages.notifications.inProgress.messages.deleteExaInfra(cloudExadataInfrastructureName),
        },
        success: {
          title: Messages.notifications.success.titles.deleteExaInfra(),
          message: Messages.notifications.success.messages.deleteExaInfra(cloudExadataInfrastructureName),
        },
        failure: {
          title: Messages.notifications.failure.titles.delete(),
          message: Messages.notifications.failure.messages.deleteExaInfra(cloudExadataInfrastructureName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXAINFRA_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            cloudExadataInfrastructureName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.EXAINFRA_DELETED_CHECK,
          errorStatusOverrides: [404],
          delay: EXAINFRA_DELETE_POLL_DELAY,
          interval: EXAINFRA_DELETE_POLL_INTERVAL,
        },
      },
    };
    deleteCloudExadataInfrastructure({
      subscriptionId,
      resourceGroupName,
      cloudExadataInfrastructureName,
      isDeleteVmClusters: true,
    }, invokeExaInfraOptions);
  };

  return (
    <ExaInfraDeletePanel
      componentRef={setComponentRef}
      exaInfraId={exaInfraId}
      location={location}
      onSubmit={onDeleteConfirm}
      onClose={onCancel}
      testId={SidePanelTestIds.ExaInfraDeletePanel}
    />
  );
};

export const newExaInfraDelete = (
  props: ExaInfraDeleteProps,
): JSX.Element => (<ExaInfraDelete {...props} />);
