import {
  ActionBar,
  ActionType,
  AnchoredPanelComponent,
  BookmarkablePage,
  DateTimeDisplay,
  DetailView,
  DetailViewsPanel,
  ErrorViewPanel,
  FormValues,
  getResourceLifecycleStatus,
  getValue,
  GroupItem,
  InputFormGroup,
  LabelMetaItem,
  MetaItemSection,
  NoValue,
  optimizedRetryOption,
  stateT,
  Status,
  TitleDetails,
  uniqueGUID,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Spinner, Stack } from "@fluentui/react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { OciExaDbVmClusterLink } from "../../components/OciLinks/OciExaDbVmClusterLink";
import { ExaInfraLink } from "../../components/ResourceLinks/ExaInfraLink";
import { Fields as TagDetailsFields, TagDetails, TagDetailsComponent } from "../../components/TagDetails/TagDetails";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  DetailsPanelId,
  InlineFormId,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds, SvgIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { CloudVmCluster } from "../../gen/clients/mchub-azure-api-client-exa";
import { buildId } from "../../helpers/idHelper";
import { getStatusInfo, getTitleSuffix, ResourceStatus, TagsInfoType } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { ExaVmClusterDeleteProps, newExaVmClusterDelete } from "../../operations/ExaVmCluster/ExaVmClusterDelete";
import { ExaVmClusterEditTagsProps, newExaVmClusterEditTags } from "../../operations/ExaVmCluster/ExaVmClusterEditTags";
import {
  ExaVmClusterUpdateTagsProps,
  newExaVmClusterUpdateTags,
} from "../../operations/ExaVmCluster/ExaVmClusterUpdateTags";
import { getAzureLocationName, getOciRegion } from "../../utils";
import { ExaVmClusterConfigurationDetails } from "./ExaVmClusterConfigurationDetails";
import { ExaVmClusterDatabasesList } from "./ExaVmClusterDatabasesList";
import { ExaVmClusterDbHomesList } from "./ExaVmClusterDbHomesList";
import { ExaVmClusterDbNodesList } from "./ExaVmClusterDbNodesList";
import { ExaVmClusterNetworkingDetails } from "./ExaVmClusterNetworkingDetails";
import { ExaVmClusterSecurityDetails } from "./ExaVmClusterSecurityDetails";

export enum ActionIds {
  Refresh = "refresh",
  Delete = "delete",
  EditTags = "edit-tags",
}

export enum ActionBarTestIds {
  Refresh = "refresh",
  Delete = "delete",
}

export enum MetaItemSectionTestIds {
  ResourceGroup = "mis-resource-group",
  LifecycleStatus = "mis-status",
  Location = "mis-location",
  Subscription = "mis-subscription",
  SubscriptionId = "mis-subscription-id",
  Tags = "mis-tags",
  OciResource = "mis-oci-resource",
  ExaInfra = "mis-exa-infra",
  DbName = "mis-db-name",
  Username = "mis-username",
  TimeCreated = "mis-time-created",
}

export enum MetaItemActionTestIds {
  EditTags = "edit-tags",
}

export enum LinkTestIds {
  AddTags = "add-tags-link"
}

const registrationIds = PageRegistrationConfig[PageId.VMCLUSTER_DETAILS].map(config => config.key);

const getViewId = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.CONTAINERDATABASES:
      return registrationIds[7];
    case DetailsPanelId.DATABASEHOMES:
      return registrationIds[6];
    case DetailsPanelId.DATABASENODES:
      return registrationIds[5];
    case DetailsPanelId.SECURITY:
      return registrationIds[4];
    case DetailsPanelId.NETWORKING:
      return registrationIds[3];
    case DetailsPanelId.CONFIGURATION:
      return registrationIds[2];
    case DetailsPanelId.TAGS:
      return registrationIds[1];
    case DetailsPanelId.OVERVIEW:
    default:
      return registrationIds[0];
  }
};

const getViewTitle = (panelId: string | undefined, instanceName: string): string | TitleDetails => {
  switch (panelId) {
    case DetailsPanelId.CONTAINERDATABASES:
      return { primary: instanceName, secondary: Messages.labels.containerDatabases() };
    case DetailsPanelId.DATABASEHOMES:
      return { primary: instanceName, secondary: Messages.labels.databaseHomes() };
    case DetailsPanelId.DATABASENODES:
      return { primary: instanceName, secondary: Messages.labels.databaseNodes() };
    case DetailsPanelId.SECURITY:
      return { primary: instanceName, secondary: Messages.labels.security() };
    case DetailsPanelId.NETWORKING:
      return { primary: instanceName, secondary: Messages.labels.networking() };
    case DetailsPanelId.CONFIGURATION:
      return { primary: instanceName, secondary: Messages.labels.configuration() };
    case DetailsPanelId.TAGS:
      return { primary: instanceName, secondary: Messages.labels.tags() };
    case DetailsPanelId.OVERVIEW:
    default:
      return instanceName;
  }
};

const getViewIcon = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.CONTAINERDATABASES:
      return SvgIconIds.pluggableSvg;
    case DetailsPanelId.DATABASEHOMES:
      return SvgIconIds.databaseSvg;
    case DetailsPanelId.DATABASENODES:
      return SvgIconIds.databaseNodesSvg;
    case DetailsPanelId.SECURITY:
      return SvgIconIds.securitySvg;
    case DetailsPanelId.NETWORKING:
      return SvgIconIds.networkingSvg;
    case DetailsPanelId.CONFIGURATION:
      return SvgIconIds.configurationSvg;
    case DetailsPanelId.TAGS:
      return SvgIconIds.tagsSvg;
    case DetailsPanelId.OVERVIEW:
    default:
      return SvgIconIds.vmClusterSvg;
  }
};

export const ExaVmClusterDetailsPage = (): JSX.Element => {
  const { subscriptionId, resourceGroup, provider, resourceType, resourceName, panelId } = useParams();
  const [searchParams] = useSearchParams();

  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");
  const location = searchParams.get("location") || "";

  const vmClusterId = buildId({
    subscriptionId: subscriptionId || "",
    resourceGroup: resGroup,
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName: resName,
  });

  const { trackActionClick, trackActionDiscard, trackFormSave } = useAnalytics();

  const { back, navigateToPanel, navigateToSelf } = useNavigation(ConsoleContext);

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.VMCLUSTER_DETAILS].find(
      config => config.panelPath === panelId,
    );
    const urlQueryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const path = `${RESOURCE_ROUTE}/${vmClusterId}/${panelId}${urlQueryParams}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tagTabKey, setTagTabKey] = React.useState<string>(uniqueGUID());

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  const tagDetailsRef = React.useRef<TagDetailsComponent>();
  const setTagDetailsRef = (panel: TagDetailsComponent): void => {
    tagDetailsRef.current = panel;
  };

  const onClose = (): void => back();

  const onMenuItemSelect = (id: string): void => navigateToPanel(id);

  const { subscriptions } = useSubscriptions();
  const subscription = subscriptions?.find(item => item.id === subscriptionId);

  const {
    response: responseVmCluster,
    loading: loadingVmCluster,
    refresh: refreshVmCluster,
    error: errorVmCluster,
  } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.getCloudVmCluster,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        cloudVmClusterName: resName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVMCluster(),
      },
      excludeErrorStatus: [404],
    },
  });

  const instance : CloudVmCluster | undefined = responseVmCluster?.data;
  const isLoading = loadingVmCluster && !instance;

  const { trigger: triggerDeleteExaVmCluster } = useOperation<ExaVmClusterDeleteProps>(newExaVmClusterDelete);
  const { trigger: triggerEditExaVmClusterTags } = useOperation<ExaVmClusterEditTagsProps>(newExaVmClusterEditTags);
  // eslint-disable-next-line max-len
  const { trigger: triggerUpdateExaVmClusterTags } = useOperation<ExaVmClusterUpdateTagsProps>(newExaVmClusterUpdateTags);
  const { closePanels } = useSidePanel();
  const overviewActionItems: ActionType[] = [
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.VMCLUSTER_DETAILS, DetailsPanelId.OVERVIEW);
        refreshVmCluster();
      },
    },
    {
      key: ActionIds.Delete,
      testId: ActionBarTestIds.Delete,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.actions.delete(),
      description: Messages.hints.deleteVMClusterConfirmation(instance?.name || ""),
      onClick: () => {
        trackActionClick(ActionIds.Delete, PageId.VMCLUSTER_DETAILS, DetailsPanelId.OVERVIEW);
      },
      onConfirm: () => {
        triggerDeleteExaVmCluster({
          vmClusterId,
          location,
          onExecute: () => {
            refreshVmCluster();
            onClose();
          },
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Delete, PageId.VMCLUSTER_DETAILS, DetailsPanelId.OVERVIEW),
    },
  ];

  const onEditTags = (): void => {
    triggerEditExaVmClusterTags({
      vmClusterId,
      defaultTags: tagsDefaultValue,
      location,
      onExecute: refreshVmCluster,
      onCancel: () => trackActionDiscard(ActionIds.EditTags, PageId.VMCLUSTER_DETAILS, DetailsPanelId.OVERVIEW),
    });
  };

  React.useEffect(() => {
    setTagTabKey(uniqueGUID);
  }, [instance]);

  const tagsDefaultValue: TagsInfoType = Object.keys(instance?.freeformTags || {})
    .map((key: string) => ({ name: key, value: instance?.freeformTags?.[key] }));

  const essentialsMetaItemGroup: JSX.Element[] = [
    <Stack key="essentials-left" tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem testId={MetaItemSectionTestIds.ResourceGroup} label={Messages.labels.resourceGroup()}>
        <AzureResourceGroupLink resourceId={instance?.id as string} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.LifecycleStatus} label={Messages.labels.status()}>
        <Status
          iconPosition="right"
          label={stateT(instance?.status || "")}
          status={getResourceLifecycleStatus(instance?.status || "")}
          statusInfo={
            getStatusInfo(
              instance?.status || "",
              instance?.lastOperationStatus || "",
              instance?.lastOperationStatusDetails || "",
              instance?.lifecycleDetails,
            )
          }
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Location} label={Messages.labels.location()}>
        {instance?.location ? getAzureLocationName(instance.location) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Subscription} label={Messages.labels.subscription()}>
        <AzureSubscriptionLink resourceId={instance?.id as string} subscriptionName={subscription?.name} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.SubscriptionId} label={Messages.labels.subscriptionId()}>
        {subscriptionId || <NoValue />}
      </LabelMetaItem>
    </Stack>,
    <Stack key="essentials-right" tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem testId={MetaItemSectionTestIds.OciResource} label={Messages.labels.ociResource()}>
        <OciExaDbVmClusterLink
          id={vmClusterId}
          location={location}
          subscriptions={subscriptions || []}
          analytics={{ pageId: PageId.VMCLUSTER_DETAILS, panelId: DetailsPanelId.OVERVIEW }}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.ExaInfra} label={Messages.labels.exadataInfra()}>
        <ExaInfraLink
          vmClusterId={vmClusterId}
          location={location}
          analytics={{ pageId: PageId.VMCLUSTER_DETAILS, panelId: DetailsPanelId.OVERVIEW }}
        />
      </LabelMetaItem>
      {instance?.timeCreated && (
        <LabelMetaItem testId={MetaItemSectionTestIds.TimeCreated} label={Messages.labels.created()}>
          <DateTimeDisplay date={instance.timeCreated} />
        </LabelMetaItem>
      )}
    </Stack>,
  ];

  const detailViews: DetailView[] = [
    {
      id: registrationIds[0],
      content: (

        <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
          <ActionBar actions={overviewActionItems} onActionClick={closePanels} />
          <MetaItemSection
            tags={{
              testId: MetaItemSectionTestIds.Tags,
              items: tagsDefaultValue.length ? tagsDefaultValue : undefined,
              add: {
                testId: LinkTestIds.AddTags,
                onClick: () => {
                  trackActionClick(ActionIds.EditTags, PageId.VMCLUSTER_DETAILS, DetailsPanelId.OVERVIEW);
                  onEditTags();
                },
              },
              edit: {
                id: ActionIds.EditTags,
                testId: MetaItemActionTestIds.EditTags,
                onClick: () => {
                  trackActionClick(ActionIds.EditTags, PageId.VMCLUSTER_DETAILS, DetailsPanelId.OVERVIEW);
                  onEditTags();
                },
              },
            }}
          >
            {essentialsMetaItemGroup}
          </MetaItemSection>
        </Stack>

      ),
    },
    {
      id: registrationIds[1],
      content: (
        <TagDetails
          key={tagTabKey}
          resourceId={vmClusterId}
          tagsDefaultValue={tagsDefaultValue}
          onApply={(formValues: FormValues) => {
            trackFormSave(InlineFormId.TAGS, PageId.VMCLUSTER_DETAILS, DetailsPanelId.TAGS);
            const tags = getValue<TagsInfoType>(formValues, TagDetailsFields.Tags, InputFormGroup) ?? [];
            // *** Do not call refresh when updating tags on the tags tab to avoid resetting user input ***
            const updateTagsProps = {
              vmClusterId,
              tags,
              location,
              onExecute: () => tagDetailsRef.current?.toggleInProgress(false, panelRef.current),
            };
            tagDetailsRef.current?.toggleInProgress(true, panelRef.current);
            triggerUpdateExaVmClusterTags(updateTagsProps);
          }}
          componentRef={setTagDetailsRef}
        />
      ),
    },
    {
      id: registrationIds[2],
      content: (
        <ExaVmClusterConfigurationDetails
          vmCluster={instance}
          resourceGroupName={resGroup}
          subscriptionId={subscriptionId || ""}
          location={location}
          refresh={refreshVmCluster}
        />
      ),
    },
    {
      id: registrationIds[3],
      content: (
        <ExaVmClusterNetworkingDetails
          vmCluster={instance}
          location={location}
          subscriptionId={subscriptionId || ""}
          resourceGroupName={resGroup}
        />
      ),
    },
    {
      id: registrationIds[4],
      content: (
        <ExaVmClusterSecurityDetails
          vmCluster={instance}
          location={location}
          refresh={refreshVmCluster}
        />
      ),
    },
    {
      id: registrationIds[5],
      content: (
        <ExaVmClusterDbNodesList
          vmClusterId={vmClusterId}
          location={location}
        />
      ),
    },
    {
      id: registrationIds[6],
      content: (
        <ExaVmClusterDbHomesList
          vmClusterId={vmClusterId}
          location={location}
        />
      ),
    },
    {
      id: registrationIds[7],
      content: (
        <ExaVmClusterDatabasesList
          vmClusterId={vmClusterId}
          location={location}
        />
      ),
    },
  ];

  const groupItems: GroupItem[] = [
    {
      items: [
        {
          icon: SvgIconIds.vmClusterSvg,
          id: detailViews[0].id,
          name: Messages.labels.overview(),
        },
        {
          icon: SvgIconIds.tagsSvg,
          id: detailViews[1].id,
          name: Messages.labels.tags(),
        },
      ],
    },
    {
      heading: Messages.labels.settings(),
      items: [
        { icon: SvgIconIds.configurationSvg, id: detailViews[2].id, name: Messages.labels.configuration() },
        { icon: SvgIconIds.networkingSvg, id: detailViews[3].id, name: Messages.labels.networking() },
        { icon: SvgIconIds.securitySvg, id: detailViews[4].id, name: Messages.labels.security() },
      ],
    },
    {
      heading: Messages.labels.resources(),
      items: [
        { icon: SvgIconIds.databaseNodesSvg, id: detailViews[5].id, name: Messages.labels.databaseNodes() },
        { icon: SvgIconIds.databaseSvg, id: detailViews[6].id, name: Messages.labels.databaseHomes() },
        { icon: SvgIconIds.pluggableSvg, id: detailViews[7].id, name: Messages.labels.containerDatabases() },
      ],
    },
  ];

  const renderedContent = (): JSX.Element => {
    if (isLoading) {
      return <Spinner label={Messages.common.loading()} />;
    } if (errorVmCluster) {
      const isNotFound = errorVmCluster?.status === 404;
      const errorTitle = isNotFound
        ? Messages.detailVmCluster.loadError.notFound.title()
        : Messages.detailVmCluster.loadError.general.title();
      const errorDetail = isNotFound
        ? Messages.detailVmCluster.loadError.notFound.details()
        : Messages.detailVmCluster.loadError.general.details();

      return (
        <ErrorViewPanel
          icon={SvgIconIds.vmClusterSvg}
          title={resName}
          errorTitle={errorTitle}
          resourceId={vmClusterId}
          errorCode={errorVmCluster?.status}
          details={[
            errorDetail,
            Messages.notifications.apiErrorMsg(errorVmCluster?.body?.message),
          ]}
          isOpen
          onClose={onClose}
          favoriteAccessRegistration={{ id: vmClusterId, data: { location } }}
        />
      );
    }
    return (
      <DetailViewsPanel
        componentRef={setPanelRef}
        title={getViewTitle(panelId, instance?.name || "")}
        subTitle={Messages.detailVmCluster.titles.long()}
        icon={getViewIcon(panelId)}
        onClose={onClose}
        isOpen
        views={detailViews}
        activeViewId={getViewId(panelId)}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
        status={(instance?.status !== ResourceStatus.Succeeded && instance?.status !== ResourceStatus.Active) ? {
          status: getResourceLifecycleStatus(instance?.status || ""),
          tooltip: stateT(instance?.status || ""),
        } : undefined}
        favoriteAccessRegistration={{ id: instance?.id || "", data: { location } }}
      />
    );
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={instance?.name || Messages.detailVmCluster.titles.short()}
      titleSuffix={instance?.name ? getTitleSuffix(panelId || "") : undefined}
      recentAccessRegistration={{ id: instance?.id || "", data: { location } }}
    >
      {renderedContent()}
    </BookmarkablePage>
  );
};
