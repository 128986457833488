import {
  FieldSet,
  FormattedString,
  FormValues,
  InputWizardPanelComponent,
  Select,
  SelectComponent,
  TextInput,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Fields as DbHomeCreateFields } from "../../components/DbHomeCreateCallout/DbHomeCreateCallout";
import { DbHomeSelect } from "../../components/DbHomeSelect/DbHomeSelect";
import { ExadataDbSystemShapeSelect } from "../../components/DbSystemShapeSelect/ExadataDbSystemShapeSelect";
import { ExaDbVersionSelect } from "../../components/DbVersionSelect/ExaDbVersionSelect";
import { GridInfraVersionSelect } from "../../components/GridInfraVersionSelect/GridInfraVersionSelect";
import { LicenseTypeSelect } from "../../components/LicenseTypeSelect/LicenseTypeSelect";
import { StorageConfigInput } from "../../components/StorageConfigInput/StorageConfigInput";
import { VmClusterConfigInput } from "../../components/VmClusterConfigInput/VmClusterConfigInput";
import { VmClusterSelect } from "../../components/VmClusterSelect/VmClusterSelect";
import { exaPluggableDatabaseUrl } from "../../constants/docConstants";
import { IdResourceType } from "../../helpers/idHelper";
import {
  CharacterSets,
  defaultCharacterSet,
  defaultNationalCharacterSet,
  ExadataCreateFlowType,
  NationalCharacterSets,
} from "../../helpers/resourceHelper";
import {
  alphaNumericRegex,
  alphaNumericUnderscoreRegex,
  exaDbNameMaxLength,
  nameCharMaxLength,
  nameCharMinLength,
  nameValidationRegex,
  pdbNameMaxLength,
  startWithLetterRegex,
} from "../../helpers/validationHelper";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import {
  ExaDbCreateHomeProps,
  getDefaultDatabaseHomeName,
  newExaDbCreateHome,
} from "../../operations/ExaDb/ExaDbCreateHome";

export const GROUP_CONFIG = "config";

export enum Fields {
  ExaInfraName = "cloudExadataInfrastructureDetails.name",
  ExadataSystemModel = "cloudExadataInfrastructureDetails.shape",
  OracleGridInfrastructureVersion = "cloudVmClusterDetails.giVersion",
  LicenseType = "cloudVmClusterDetails.licenseModel",
  VmClusterConfiguration = "cloudVmClusterDetails.cpuCoreCount",
  StorageConfiguration = "cloudVmClusterDetails.storageConfiguration",
  VMClusterName = "cloudVmClusterDetails.name",
  VMCluster = "cloudVmClusterDetails.id",
  DatabaseVersion = "databaseDetails.dbVersion",
  DatabaseName = "databaseDetails.dbName",
  PdbName = "databaseDetails.pdbName",
  // This field is not used in any submit function currently
  BackupName = "backupName",
  CharacterSet = "databaseDetails.characterSet",
  NationalCharacterSet = "databaseDetails.ncharacterSet",
  DatabaseHome = "databaseDetails.dbHomeId",
  DatabaseHomeName = "databaseDetails.dbHomeName",
}

export enum FieldTestIds {
  ExadataSystemModel = "exaSystemModel",
  OracleGridInfrastructureVersion = "oracleGridInfrastructureVersion",
  LicenseType = "licenseType",
  VmClusterConfiguration = "vmClusterConfiguration",
  StorageConfiguration = "storageConfiguration",
  DatabaseHomeName = "databaseHomeName",
  VMCluster = "vMCluster",
  DatabaseVersion = "databaseVersion",
  DatabaseName = "databaseName",
  DatabaseHomeSelect = "databaseHomeSelect",
  PdbName = "pdbName",
  BackupName = "backupName",
  CharacterSet = "characterSet",
  NationalCharacterSet = "nationalCharacterSet",
  ExaInfraName = "exaInfraName",
  VmClusterName = "vmClusterName",
}

const defaultShapeName = "Exadata.X8M";
const exaInfraNameSuffix = "_infra";
const vmClusterNameSuffix = "_vmcluster";

const createDbHomeLinkId = "create_new_db_home";

interface VmClusterConfigInputValue {
  value: number;
}

export interface BackupInfo {
  id: string,
  name: string,
  startDate: Date | undefined,
}
export interface ConfigTabContentProps {
  createFlowType?: ExadataCreateFlowType;
  inputWizardPanelRef: InputWizardPanelComponent;
  subscriptionId: string;
  resourceGroupName: string;
  location: string;
  vmClusterId?: string;
  backup?: BackupInfo;
  disableUIControls?: Fields[] | undefined;
  exaName: string;
}

export enum LinkTestIds {
  DbHomeCreateNewLink= "dbHomeCreateNewLink",
}

const characterSetOptions = CharacterSets.map(set => ({
  id: set,
  text: set,
}));

const natioanlCharacterSetOptions = NationalCharacterSets.map(set => ({
  id: set,
  text: set,
}));

export const getDefaultExaInfraName = (exaName: string): string => (
  exaName ? `${exaName}${exaInfraNameSuffix}` : ""
);

export const getDefaultVmClusterName = (exaName: string): string => (
  exaName ? `${exaName}${vmClusterNameSuffix}` : ""
);

export const ConfigTabContent = ({
  createFlowType,
  inputWizardPanelRef,
  subscriptionId,
  resourceGroupName,
  location,
  vmClusterId,
  backup,
  disableUIControls,
  exaName,
}: ConfigTabContentProps): JSX.Element => {
  const { enableExaDbHome } = useFeatures();
  const [shapeName, setShapeName] = React.useState<string>(defaultShapeName);
  const [dbServers, setDBServers] = React.useState<number>();
  const [selectRef, setSelectRef] = React.useState<SelectComponent>();
  const [
    , setVmClusterConfigInputValue,
  ] = React.useState<VmClusterConfigInputValue>();
  const [selectedVmClusterId, setSelectedVmClusterId] = React.useState<string>(vmClusterId || "");
  const onVmClusterChange = (clusterId:string):void => setSelectedVmClusterId(clusterId);

  const { trigger: triggerExaDbHomeCreate } = useOperation<ExaDbCreateHomeProps>(newExaDbCreateHome);

  const onError = (): void => inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.general());
  const onMissingDependencies = (): void => {
    if (!subscriptionId || !location || !resourceGroupName) {
      inputWizardPanelRef.showErrorDialog(
        Messages.createCommon.dependencyWarnings.basicsIncomplete.message(),
        Messages.createCommon.dependencyWarnings.basicsIncomplete.title(),
      );
      return;
    }
    if (!vmClusterId && enableExaDbHome && createFlowType !== ExadataCreateFlowType.EXADATA_SYSTEM) {
      inputWizardPanelRef.showErrorDialog(
        Messages.createCommon.dependencyWarnings.vmClusterNotSelected.message(),
        Messages.createCommon.dependencyWarnings.vmClusterNotSelected.title(),
      );
    }
  };

  const onSubmit = (formValues: FormValues): void => {
    const databaseHomeName = formValues.default[DbHomeCreateFields.DatabaseHomeName] || getDefaultDatabaseHomeName();
    const databaseHomeVersion = formValues.default[DbHomeCreateFields.DatabaseHomeVersion][0].id;
    selectRef?.setOptionNew(`${databaseHomeName} (${databaseHomeVersion})`, { databaseHomeName, databaseHomeVersion });
  };

  const onLinkClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    else if (!location) missingDependencies.push(IdResourceType.LOCATION);

    if (missingDependencies.length > 0) {
      onMissingDependencies?.();
      return; // Do not display ExaDbCreateHome if dependencies missing
    }
    triggerExaDbHomeCreate({
      location,
      subscriptionId,
      targetId: createDbHomeLinkId,
      onExecute: onSubmit,
    });
  };

  const createDbHomeLink = {
    id: createDbHomeLinkId,
    testId: LinkTestIds.DbHomeCreateNewLink,
    text: Messages.actions.createNew(),
    onLinkClick,
  };

  return (
    <>
      {createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM && (
        <>
          <FieldSet
            header={Messages.createExaDb.configurationTab.sectionTitles.infrastructureDetail()}
          >
            <TextInput
              testId={FieldTestIds.ExaInfraName}
              fieldName={Fields.ExaInfraName}
              label={Messages.labels.name()}
              placeholder={getDefaultExaInfraName(exaName)}
              validator={(value: string | undefined) => {
                const errors: string[] = [];
                if (value && (value.length < nameCharMinLength || value.length > nameCharMaxLength)) {
                  errors.push(
                    Messages.validation.nameChars(
                      nameCharMinLength.toString(),
                      nameCharMaxLength.toString(),
                    ),
                  );
                }
                if (value && !nameValidationRegex.test(value)) {
                  errors.push(Messages.validation.nameValidation());
                }
                return errors.length > 0 ? errors : undefined;
              }}
            />
            <ExadataDbSystemShapeSelect
              testId={FieldTestIds.ExadataSystemModel}
              fieldName={Fields.ExadataSystemModel}
              label={Messages.labels.exadataSystemModel()}
              required
              defaultValue={[defaultShapeName]}
              subscriptionId={subscriptionId}
              location={location}
              tooltip={Messages.hints.selectAExadataBase()}
              onShapeChange={(sName: string) => {
                setShapeName(sName);
              }}
              onDBServerCountChange={setDBServers}
              onError={onError}
              onMissingDependencies={onMissingDependencies}
            />
          </FieldSet>
          {enableExaDbHome && (
            <FieldSet header={Messages.createExaDb.configurationTab.sectionTitles.databaseHomeDetails()}>
              <TextInput
                testId={FieldTestIds.DatabaseHomeName}
                fieldName={Fields.DatabaseHomeName}
                label={Messages.labels.name()}
                placeholder={getDefaultDatabaseHomeName()}
                validator={(value: string | undefined) => {
                  const errors: string[] = [];
                  if (value && (value.length < nameCharMinLength || value.length > nameCharMaxLength)) {
                    errors.push(
                      Messages.validation.nameChars(
                        nameCharMinLength.toString(),
                        nameCharMaxLength.toString(),
                      ),
                    );
                  }
                  if (value && !nameValidationRegex.test(value)) {
                    errors.push(Messages.validation.nameValidation());
                  }
                  return errors.length > 0 ? errors : undefined;
                }}
              />
              <ExaDbVersionSelect
                testId={FieldTestIds.DatabaseVersion}
                required
                fieldName={Fields.DatabaseVersion}
                label={Messages.labels.databaseVersion()}
                subscriptionId={subscriptionId}
                location={location}
                onError={onError}
                onMissingDependencies={onMissingDependencies}
              />
            </FieldSet>
          )}
          <FieldSet header={Messages.createExaDb.configurationTab.sectionTitles.vmCluster()}>
            <TextInput
              testId={FieldTestIds.VmClusterName}
              fieldName={Fields.VMClusterName}
              label={Messages.labels.name()}
              placeholder={getDefaultVmClusterName(exaName)}
              validator={(value: string | undefined) => {
                const errors: string[] = [];
                if (value && (value.length < nameCharMinLength || value.length > nameCharMaxLength)) {
                  errors.push(
                    Messages.validation.nameChars(
                      nameCharMinLength.toString(),
                      nameCharMaxLength.toString(),
                    ),
                  );
                }
                if (value && !nameValidationRegex.test(value)) {
                  errors.push(Messages.validation.nameValidation());
                }
                return errors.length > 0 ? errors : undefined;
              }}
            />
            <VmClusterConfigInput
              testId={FieldTestIds.VmClusterConfiguration}
              fieldName={Fields.VmClusterConfiguration}
              label={Messages.labels.vmClusterConfiguration()}
              required
              subscriptionId={subscriptionId}
              location={location}
              dbSystemShape={shapeName}
              dbServers={dbServers}
              isSystemCreate={createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM}
              callback={(value: VmClusterConfigInputValue) => { setVmClusterConfigInputValue(value); }}
            />
            <StorageConfigInput
              testId={FieldTestIds.StorageConfiguration}
              label={Messages.labels.storage()}
              fieldName={Fields.StorageConfiguration}
            />
            <GridInfraVersionSelect
              testId={FieldTestIds.OracleGridInfrastructureVersion}
              fieldName={Fields.OracleGridInfrastructureVersion}
              label={Messages.labels.oracleGridInfrastructureVersion()}
              required
              tooltip={Messages.hints.selectOracleGridInfrastructureVersion()}
              subscriptionId={subscriptionId}
              location={location}
              shape={shapeName}
              onError={onError}
              onMissingDependencies={onMissingDependencies}
            />
            <LicenseTypeSelect
              testId={FieldTestIds.LicenseType}
              fieldName={Fields.LicenseType}
            />
          </FieldSet>
        </>
      )}
      {createFlowType !== ExadataCreateFlowType.EXADATA_SYSTEM && (
        <>
          <FieldSet header={Messages.createExaDb.configurationTab.sectionTitles.vmCluster()}>
            <VmClusterSelect
              testId={FieldTestIds.VMCluster}
              required
              fieldName={Fields.VMCluster}
              label={Messages.labels.vmCluster()}
              tooltip={Messages.labels.vmCluster()}
              resourceGroupName={resourceGroupName}
              subscriptionId={subscriptionId}
              location={location}
              onError={onError}
              onChange={onVmClusterChange}
              onMissingDependencies={onMissingDependencies}
              defaultValue={vmClusterId ? [vmClusterId] : undefined}
              disabled={disableUIControls?.find(ele => ele === Fields.VMCluster) !== undefined}
            />
          </FieldSet>
          {enableExaDbHome && (
            <FieldSet header={Messages.createExaDb.configurationTab.sectionTitles.databaseHomeDetails()}>
              <DbHomeSelect
                testId={FieldTestIds.DatabaseHomeSelect}
                required
                componentRef={setSelectRef}
                fieldName={Fields.DatabaseHome}
                label={Messages.labels.databaseHome()}
                resourceGroupName={resourceGroupName}
                subscriptionId={subscriptionId}
                vmClusterId={selectedVmClusterId}
                location={location}
                onError={onError}
                onMissingDependencies={onMissingDependencies}
                inputLink={createDbHomeLink}
              />
            </FieldSet>
          )}
        </>
      )}
      <FieldSet header={Messages.createExaDb.configurationTab.sectionTitles.databaseDetail()}>
        {!enableExaDbHome && createFlowType !== ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP && (
          <ExaDbVersionSelect
            testId={FieldTestIds.DatabaseVersion}
            required
            fieldName={Fields.DatabaseVersion}
            label={Messages.labels.databaseVersion()}
            subscriptionId={subscriptionId}
            location={location}
            onError={onError}
            onMissingDependencies={onMissingDependencies}
          />
        )}
        {createFlowType === ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP && (
          <TextInput
            testId={FieldTestIds.BackupName}
            fieldName={Fields.BackupName}
            label={Messages.labels.databaseBackupName()}
            disabled={disableUIControls?.find(ele => ele === Fields.BackupName) !== undefined}
            defaultValue={`${backup?.name} (${backup?.startDate})`}
          />
        )}
        <TextInput
          testId={FieldTestIds.DatabaseName}
          required
          fieldName={Fields.DatabaseName}
          label={Messages.labels.databaseName()}
          tooltip={Messages.labels.databaseName()}
          validator={(value: string | undefined) => {
            const errors: string[] = [];
            if (!value || !startWithLetterRegex.test(value)) {
              errors.push(Messages.validation.nameStartChar());
            }
            if (!value || !alphaNumericRegex.test(value)) {
              errors.push(Messages.validation.valueAlphaNumeric());
            }
            if (!value || value.length > exaDbNameMaxLength) {
              errors.push(Messages.validation.valueMaxLen(exaDbNameMaxLength.toString()));
            }
            return errors.length > 0 ? errors : undefined;
          }}
        />
        {createFlowType !== ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP && (
          <TextInput
            testId={FieldTestIds.PdbName}
            fieldName={Fields.PdbName}
            label={Messages.labels.pdbName()}
            tooltip={
              FormattedString(
                { inputText: Messages.hints.tooltipPluggableDatabase(exaPluggableDatabaseUrl) },
              ) as unknown as string
            }
            validator={(value: string | undefined) => {
              const errors: string [] = [];
              if (value && !startWithLetterRegex.test(value)) {
                errors.push(Messages.validation.nameStartChar());
              }
              if (value && value.length > pdbNameMaxLength) {
                errors.push(Messages.validation.valueMaxLen(pdbNameMaxLength.toString()));
              }
              if (value && !alphaNumericUnderscoreRegex.test(value)) {
                errors.push(Messages.validation.valueAlphaNumericUnderscore());
              }
              return errors.length > 0 ? errors : undefined;
            }}
          />
        )}
        { createFlowType !== ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP && (
          <>
            <Select
              testId={FieldTestIds.CharacterSet}
              label={Messages.labels.characterSet()}
              required
              fieldName={Fields.CharacterSet}
              options={characterSetOptions}
              defaultValue={[defaultCharacterSet]}
            />
            <Select
              testId={FieldTestIds.NationalCharacterSet}
              label={Messages.labels.nationalCharacterSet()}
              required
              fieldName={Fields.NationalCharacterSet}
              options={natioanlCharacterSetOptions}
              defaultValue={[defaultNationalCharacterSet]}
            />
          </>
        )}
      </FieldSet>
    </>
  );
};
