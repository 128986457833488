import { FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Fields as TagDetailsFields, TagsEditPanel } from "../../components/TagsEditPanel/TagsEditPanel";
import { TagsInfoType } from "../../helpers/resourceHelper";
import { useOperation } from "../../hooks/useOperation";
import { OperationProps } from "../OperationTypes";
import { MysqlBackupUpdateTagsProps, newMysqlBackupUpdateTags } from "./MysqlBackupUpdateTags";

export interface MysqlBackupEditTagsProps extends OperationProps {
  backupId: string | undefined;
  defaultTags?: TagsInfoType;
}

const MysqlBackupEditTags = (
  { location, backupId, defaultTags, onExecute, onCancel }: MysqlBackupEditTagsProps,
): JSX.Element => {
  const { trigger: triggerUpdateBackupTags } = useOperation<MysqlBackupUpdateTagsProps>(newMysqlBackupUpdateTags);

  return (
    <TagsEditPanel
      title={Messages.labels.editTags()}
      resourceId={backupId}
      tagsDefaultValue={defaultTags}
      onSubmit={(formValues: FormValues) => {
        const tags = getValue<TagsInfoType>(formValues, TagDetailsFields.Tags, InputFormGroup) ?? [];
        triggerUpdateBackupTags({
          backupId,
          tags,
          location,
          onExecute,
        });
      }}
      onClose={onCancel}
    />
  );
};

export const newMysqlBackupEditTags = (
  props: MysqlBackupEditTagsProps,
): JSX.Element => (<MysqlBackupEditTags {...props} />);
