import {
  ActionType,
  CustomAction,
  DateTimeDisplay,
  getResourceLifecycleStatus,
  InfoBlockProps,
  InfoBlockStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingDisplayNameLink,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  DetailsPanelId,
  MYSQL_CREATE_ROUTE,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  BackupSummaryTypeEnum,
  MdsBackupSummary,
  MdsBackupSummaryCollection,
  MdsCrashRecoveryStatus,
  MdsCrashRecoveryStatusValues,
} from "../../gen/clients/mchub-azure-api-client-mds";
import { parseId } from "../../helpers/idHelper";
import {
  backupTypeSortComparator,
  getBackupTypeStr,
  getStatusInfo,
  ResourceStatus as BackupStatus,
  responseItemstoArray,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { MysqlCreateBackupProps, newMysqlCreateBackup } from "../../operations/Mysql/MysqlCreateBackup";
import { MysqlDeleteBackupProps, newMysqlDeleteBackup } from "../../operations/MysqlBackup/MysqlDeleteBackup";
import { getOciRegion } from "../../utils";

const detailsPageRegistrationIds = PageRegistrationConfig[PageId.MYSQL_BACKUP_DETAILS].map(config => config.key);
interface MdsBackupSummaryExt extends MdsBackupSummary {
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
}

interface MdsBackupSummaryBackupTypeExt extends MdsBackupSummary {
  backupType: BackupSummaryTypeEnum;
}
export interface MdsBackupsListProps {
  location: string;
  dbSystemId : string;
  crashRecovery: MdsCrashRecoveryStatus | undefined;
}

export enum ActionBarIds {
  CreateBackup = "action-bar-create-backup",
  Delete = "action-bar-delete",
  Refresh = "action-bar-refresh",
  Restore = "action-bar-restore",
}

export enum ActionBarTestIds {
  CreateBackup = "action-bar-create-backup",
  Delete = "action-bar-delete",
  Refresh = "action-bar-refresh",
  Restore = "action-bar-restore",
}

export enum ActionMenuIds {
  Delete = "action-menu-delete",
  Restore = "action-menu-restore",
}

export enum ActionMenuTestIds {
  Delete = "action-menu-delete",
  Restore = "action-menu-restore",
}

export enum ColumnIds {
  Name = "name",
  ResourceStatus = "status",
  Type = "backupType",
  Created = "timeCreated",
}

export enum ColumnTestIds {
  Name = "name",
  ResourceStatus = "status",
  Type = "backupType",
  Created = "timeCreated",
}

export const MysqlBackupsList = (
  { location, dbSystemId, crashRecovery }: MdsBackupsListProps,
): JSX.Element => {
  const { locale } = React.useContext<Settings>(SettingsContext);

  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { navigateTo } = useNavigation(ConsoleContext);

  const [selection, setSelection] = React.useState<MdsBackupSummary[]>([]);
  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<MdsBackupSummary>>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectedItems = (selectedItems: any[]): void => {
    setSelection(selectedItems);
  };

  const { subscriptionId, resourceGroup: resourceGroupName, resourceName: dbSystemName } = parseId(dbSystemId);
  const { subListingPageItemCount } = useRowCount();

  const { response: listBackupsResponse, loading: listBackupsLoading, refresh: listBackupsRefresh } = useQueryCall({
    wait: !(subscriptionId && resourceGroupName && location),
    method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.listMdsDbSystemsBackups,
    options: {
      args: {
        subscriptionId,
        resourceGroupName,
        dbSystemName,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadBackups(),
      },
    },
  });

  const backupItems = listBackupsResponse
    && responseItemstoArray<MdsBackupSummaryCollection, MdsBackupSummary>(listBackupsResponse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = React.useMemo(() => backupItems, [JSON.stringify(backupItems)]);
  const getBackupType = (
    value: MdsBackupSummary,
  ): string => getBackupTypeStr(value?.backupType as BackupSummaryTypeEnum);

  const getDateTime = (value: Date): JSX.Element => <DateTimeDisplay date={value} hideClipboardCopy />;

  const getStatus = (value: MdsBackupSummaryExt): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
        )
      }
      hideClipboardCopy
    />
  );

  const buildRestoreAction = (key:string, testId: string, selectedItem:MdsBackupSummary)
  : CustomAction<MdsBackupSummary | undefined> => {
    const restoreAction = {
      key,
      testId,
      text: Messages.actions.restoreToNewDbSystem(),
      icon: MonochromeIconIds.Database,
      disabled: !selectedItem || selectedItem?.status !== BackupStatus.Active,
      onClick: () => {
        trackActionClick(key, PageId.MYSQL_DETAILS, DetailsPanelId.BACKUPS);
        navigateTo(MYSQL_CREATE_ROUTE, PageId.MYSQL_CREATE, {
          subscriptionId,
          backupId: selectedItem?.id,
          backupName: selectedItem?.name,
          backupDataStorageSizeInGBs: selectedItem?.dataStorageSizeInGBs,
          backupMysqlVersion: selectedItem?.mysqlVersion,
          dbSystemName,
          analytics: {
            pageId: PageId.MYSQL_DETAILS,
            panelId: DetailsPanelId.BACKUPS,
            actionName: key,
          } as NavigationAnalyticsData,
        });
      },
    };
    return restoreAction;
  };

  const buildDeleteAction = (key:string, testId: string, selectedItem:MdsBackupSummary)
  : CustomAction<MdsBackupSummary | undefined> => {
    const deleteAction = {
      key,
      testId,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      disabled: !selectedItem,
      title: Messages.labels.deleteBackup(),
      description: Messages.hints.deleteBackupConfirmation(selectedItem?.name || ""),
      onClick: () => {
        trackActionClick(key, PageId.MYSQL_DETAILS, DetailsPanelId.BACKUPS);
      },
      onConfirm: () => {
        triggerDeleteBackup({
          backupId: selectedItem?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            listBackupsRefresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, PageId.MYSQL_DETAILS, DetailsPanelId.BACKUPS),
    };
    return deleteAction;
  };

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.name}
          navigation={{
            onClick: (): void => {
              navigateTo(
                // eslint-disable-next-line max-len
                `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.MYSQL_BACKUP_DETAILS][0].panelPath}?location=${value.location}`,
                detailsPageRegistrationIds[0],
                { dbSystemId },
              );
            },
          }}
        />
      ),
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: MdsBackupSummaryExt,
        b: MdsBackupSummaryExt,
      ) => statusSortComparator<MdsBackupSummaryExt>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.Type,
      testId: ColumnTestIds.Type,
      name: Messages.labels.type(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: MdsBackupSummaryBackupTypeExt,
        b: MdsBackupSummaryBackupTypeExt,
      ) => backupTypeSortComparator<MdsBackupSummaryBackupTypeExt>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getBackupType(value),
    },
    {
      itemProp: ColumnIds.Created,
      testId: ColumnTestIds.Created,
      name: Messages.labels.created(),
      flexGrow: 1,
      initialSortDirection: SortDirections.DESC,
      isResizable: true,
      onRenderItems: value => getDateTime(value.timeCreated),
    },
  ];

  const { trigger: triggerCreateBackup } = useOperation<MysqlCreateBackupProps>(newMysqlCreateBackup);
  const { trigger: triggerDeleteBackup } = useOperation<MysqlDeleteBackupProps>(newMysqlDeleteBackup);
  const { closePanels } = useSidePanel();

  const backupRowActionMenus = (item?: MdsBackupSummary | undefined): ActionType<MdsBackupSummary>[] => {
    const backupActionItems : ActionType<MdsBackupSummary | undefined>[] = [];
    if (item) {
      backupActionItems.push(
        buildRestoreAction(ActionMenuIds.Restore, ActionMenuTestIds.Restore, item),
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return backupActionItems;
  };

  const backupBarActions: ActionType<MdsBackupSummary>[] = [
    {
      key: ActionBarIds.CreateBackup,
      testId: ActionBarTestIds.CreateBackup,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      disabled: crashRecovery === MdsCrashRecoveryStatusValues.DISABLED,
      onClick: () => {
        trackActionClick(ActionBarIds.CreateBackup, PageId.MYSQL_DETAILS, DetailsPanelId.BACKUPS);
        listingComponentRef?.resetTableSelection();
        triggerCreateBackup({
          dbSystemId,
          location,
          onExecute: listBackupsRefresh,
          onCancel: () => trackActionDiscard(ActionBarIds.CreateBackup, PageId.MYSQL_DETAILS, DetailsPanelId.BACKUPS),
        });
      },
    },
    buildRestoreAction(ActionBarIds.Restore, ActionBarTestIds.Restore, selection[0]),
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, PageId.MYSQL_DETAILS, DetailsPanelId.BACKUPS);
        listBackupsRefresh();
        listingComponentRef?.resetTableSelection();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  const disabledCrashRecoveryInfoBlocks: InfoBlockProps[] = crashRecovery !== MdsCrashRecoveryStatusValues.DISABLED
    ? []
    : [{
      message: Messages.detailMysqlBackup.warning(),
      messageType: InfoBlockStatus.INFO,
    }];

  return (
    <Listing
      testId={ListingTestIds.MysqlBackups}
      listingComponentRef={setListingComponentRef}
      itemsPerPage={subListingPageItemCount}
      items={items || []}
      emptyList={{ title: Messages.common.noResults() }}
      listColumns={columns}
      actionBarItems={backupBarActions as ActionType<undefined>[]}
      actions={backupRowActionMenus}
      infoBlocks={disabledCrashRecoveryInfoBlocks}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      isLoading={listBackupsLoading}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Created,
      }}
    />
  );
};
