import {
  ActionType,
  CustomAction,
  DateTimeDisplay,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  VmdbBackupSummary,
  VmdbBackupSummaryCollection,
  VmdbBackupSummaryTypeEnum,
  VmdbDatabase,
} from "../../gen/clients/mchub-azure-api-client-vmdb";
import { parseId } from "../../helpers/idHelper";
import {
  getBackupTypeStr,
  getStatusInfo,
  ResourceStatus as BackupStatus,
  responseItemstoArray,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { newVmDbCreateBackup, VmDbCreateBackupProps } from "../../operations/VmDb/VmDbCreateBackup";
import { newVmDbDeleteBackup, VmDbDeleteBackupProps } from "../../operations/VmDb/VmDbDeleteBackup";
import { newVmDbRestoreFromBackup, VmDbRestoreFromBackupProps } from "../../operations/VmDb/VmDbRestoreFromBackup";
import { getOciRegion } from "../../utils";

/**
 * TODO
 * This interface is implement to include the last status and details.
 * Api has not expose these two fields for now. When it will, we might remove this interface.
 */
interface VmdbBackupSummaryExt extends VmdbBackupSummary {
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
}

export interface VmDbBackupsListProps {
  database: VmdbDatabase | undefined;
  location: string;
}

export enum ActionBarIds {
  CreateBackup = "action-bar-create-backup",
  CreateDbFromBackup = "action-bar-create-db-from-backup",
  Refresh = "action-bar-refresh",
  Restore = "action-bar-restore",
  Delete = "action-bar-delete",
}

export enum ActionBarTestIds {
  CreateBackup = "action-bar-create-backup",
  CreateDbFromBackup = "action-bar-create-db-from-backup",
  Refresh = "action-bar-refresh",
  Restore = "action-bar-restore",
  Delete = "action-bar-delete",
}

export enum ActionMenuIds {
  Restore = "action-menu-restore",
  Delete = "action-menu-delete",
}

export enum ActionMenuTestIds {
  Restore = "action-menu-restore",
  Delete = "action-menu-delete",
}

export enum ColumnIds {
  Name = "name",
  ResourceStatus = "status",
  Type = "type",
  Started = "timeStarted",
  Ended = "timeEnded",
}

export enum ColumnTestIds {
  Name = "name",
  ResourceStatus = "status",
  Type = "type",
  Started = "timeStarted",
  Ended = "timeEnded",
}

export enum BackupType {
  Incremental = "INCREMENTAL"
}

export const VmDbBackupsList = (
  { location, database }: VmDbBackupsListProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup } = parseId(database?.id || "");
  const resGroup = decodeURIComponent(resourceGroup || "");

  const { trackActionClick, trackActionDiscard } = useAnalytics();
  const pageId = PageId.VMDB_CDB_DETAILS;

  const { locale } = React.useContext<Settings>(SettingsContext);

  const [selection, setSelection] = React.useState<VmdbBackupSummary[]>([]);

  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<VmdbBackupSummary>>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectedItems = (selectedItems: any[]): void => {
    setSelection(selectedItems);
  };

  const { subListingPageItemCount } = useRowCount();

  const { response: listBackupsResponse, loading: listBackupsLoading, refresh } = useQueryCall({
    wait: !(subscriptionId && resGroup && location && database?.id),
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbDatabaseBackups,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        databaseName: database?.name || "",
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadBackups(),
      },
    },
  });

  const backupItems = listBackupsResponse
    && responseItemstoArray<VmdbBackupSummaryCollection, VmdbBackupSummary>(listBackupsResponse);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = React.useMemo(() => backupItems, [JSON.stringify(backupItems)]);

  const getDateTime = (value: Date | undefined): JSX.Element => <DateTimeDisplay date={value} hideClipboardCopy />;
  const getBackupType = (value: VmdbBackupSummary): string => (
    getBackupTypeStr(value?.type as VmdbBackupSummaryTypeEnum)
  );

  const getStatus = (value: VmdbBackupSummaryExt): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
        )
      }
      hideClipboardCopy
    />
  );

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: VmdbBackupSummaryExt,
        b: VmdbBackupSummaryExt,
      ) => statusSortComparator<VmdbBackupSummaryExt>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.Type,
      testId: ColumnTestIds.Type,
      name: Messages.labels.type(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getBackupType(value),
    },
    {
      itemProp: ColumnIds.Started,
      testId: ColumnTestIds.Started,
      name: Messages.labels.started(),
      flexGrow: 1,
      initialSortDirection: SortDirections.DESC,
      isResizable: true,
      onRenderItems: value => getDateTime(value.timeStarted),
    },
    {
      itemProp: ColumnIds.Ended,
      testId: ColumnTestIds.Ended,
      name: Messages.labels.ended(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getDateTime(value.timeEnded),
    },
  ];

  const { trigger: triggerCreateBackup } = useOperation<VmDbCreateBackupProps>(newVmDbCreateBackup);
  const { trigger: triggerRestoreFromBackup } = useOperation<VmDbRestoreFromBackupProps>(newVmDbRestoreFromBackup);
  const { trigger: triggerDeleteBackup } = useOperation<VmDbDeleteBackupProps>(newVmDbDeleteBackup);

  const { closePanels } = useSidePanel();

  const buildRestoreAction = (key:string, testId:string, selectedItem:VmdbBackupSummary)
  : CustomAction<VmdbBackupSummary | undefined> => {
    const restoreAction = {
      key,
      testId,
      text: Messages.actions.restore(),
      icon: MonochromeIconIds.Database,
      disabled: !selectedItem || selectedItem?.status !== BackupStatus.Active,
      title: Messages.labels.restore(),
      description: Messages.hints.restoreBackupConfirmation(selectedItem?.name || ""),
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.BACKUPS);
      },
      onConfirm: () => {
        triggerRestoreFromBackup({
          backup: selectedItem,
          databaseId: database?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, pageId, DetailsPanelId.BACKUPS),
    };
    return restoreAction;
  };

  const buildDeleteAction = (key:string, testId:string, selectedItem:VmdbBackupSummary)
  : CustomAction<VmdbBackupSummary | undefined> => {
    const deleteAction = {
      key,
      testId,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      disabled: !selectedItem || selectedItem.type === BackupType.Incremental,
      title: Messages.labels.deleteBackup(),
      description: Messages.hints.deleteBackupConfirmation(selectedItem?.name || ""),
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.BACKUPS);
      },
      onConfirm: () => {
        triggerDeleteBackup({
          backupId: selectedItem.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, pageId, DetailsPanelId.BACKUPS),
    };
    return deleteAction;
  };

  const backupRowActionMenus = (item?: VmdbBackupSummary | undefined): ActionType<VmdbBackupSummary>[] => {
    const backupActionItems: ActionType<VmdbBackupSummary | undefined>[] = [];
    if (item) {
      backupActionItems.push(
        buildRestoreAction(ActionMenuIds.Restore, ActionMenuTestIds.Restore, item),
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return backupActionItems;
  };
  const backupBarActions: ActionType<VmdbBackupSummary>[] = [
    {
      key: ActionBarIds.CreateBackup,
      testId: ActionBarTestIds.CreateBackup,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        listingComponentRef?.resetTableSelection();
        trackActionClick(ActionBarIds.CreateBackup, pageId, DetailsPanelId.BACKUPS);
        triggerCreateBackup({
          databaseId: database?.id,
          targetId: ActionBarIds.CreateBackup,
          location,
          onExecute: () => refresh(),
          onCancel: () => trackActionDiscard(ActionBarIds.CreateBackup, pageId, DetailsPanelId.BACKUPS),
        });
      },
    },
    buildRestoreAction(ActionBarIds.Restore, ActionBarTestIds.Restore, selection[0]),
    /* Create database from backup has been pushed to post GA
    {
      key: ActionBarKeys.CreateDbFromBackup,
      text: Messages.actions.createDatabase(),
      icon: "Database",
      disabled: !selection.length,
      onClick: () => navigateTo(VMDB_CREATE_ROUTE, PageId.VMDB_CREATE, {
        backup: {
          id: selection[0].id,
          name: selection[0].name,
          startDate: selection[0].timeStarted,
        },
        location,
      }),
    },
    */
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, pageId, DetailsPanelId.BACKUPS);
        refresh();
        listingComponentRef?.resetTableSelection();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  return (
    <Listing
      testId={ListingTestIds.VmDbBackups}
      listingComponentRef={setListingComponentRef}
      itemsPerPage={subListingPageItemCount}
      items={items || []}
      emptyList={{ title: Messages.common.noResults() }}
      listColumns={columns}
      actionBarItems={backupBarActions as ActionType<undefined>[]}
      actions={backupRowActionMenus}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      isLoading={listBackupsLoading}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Started,
      }}
    />
  );
};
