import {
  CheckBox,
  FieldSet,
  FormContext,
  FormState,
  FormValues,
  TextInput,
  TextInputProps,
  TextInputTypes,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { SshKeyPairInput, SshPublicKeySource } from "../../components/SshKeyPair/SshKeyPairInput";
import { defaultVMDBUsername, VmdbCreateFlowType } from "../../helpers/resourceHelper";
import { validateTdePassword, validateVmDbPassword } from "../../helpers/validationHelper";

export const GROUP_SECURITY = "security";

export enum Fields {
  SshKeyPair = "vmdbDbSystemDeploymentDetails.sshPublicKeys",
  Username = "username",
  Password = "vmdbDbSystemDeploymentDetails.dbHome.database.adminPassword",
  PasswordConfirm = "passwordConfirm",
  UseDifferentTDEPassword = "useDifferentTDEPassword",
  TdePassword = "vmdbDbSystemDeploymentDetails.dbHome.database.tdeWalletPassword",
  TdePasswordConfirm = "tdePasswordConfirm"
}

export enum FieldTestIds {
  Username = "username",
  Password = "password-input",
  PasswordConfirm = "confirm-password-input",
  UseDifferentTDEPassword = "use-tde-wallet-password-checkbox",
  TdePassword = "tde-password-input",
  TdePasswordConfirm = "tde-confirm-password-input",
}

type TdePasswordProps = Pick<TextInputProps, "defaultValue" | "onChange">;

const TdePassword = ({ defaultValue, onChange }: TdePasswordProps): JSX.Element => (
  <TextInput
    defaultValue={defaultValue}
    fieldName={Fields.TdePassword}
    label={Messages.labels.tdePassword()}
    testId={FieldTestIds.TdePassword}
    tooltip={Messages.hints.tooltipAdminPassword()}
    onChange={onChange}
    textType={TextInputTypes.PASSWORD}
    required
    validator={validateTdePassword}
  />
);

export interface SecurityTabContentProps {
  createFlowType?: VmdbCreateFlowType;
}

export const SecurityTabContent = ({ createFlowType }: SecurityTabContentProps): JSX.Element => {
  const [passwordVal, setPasswordVal] = React.useState<string>();
  const [tdePasswordVal, setTDEPasswordVal] = React.useState<string>();
  const form: FormState = React.useContext(FormContext);
  const [useDifferentTDEPassword, setUseDifferentTDEPassword] = React.useState(false);
  const [defaultTdePassword, setDefaultTdePassword] = React.useState<string>();
  const [defaultTdePasswordConfirm, setDefaultTdePasswordConfirm] = React.useState<string>();
  const [confirmPassWordKey, setConfirmPasswordKey] = React.useState<string>(uniqueGUID());
  const [tdeConfirmPassWordKey, setTdeConfirmPasswordKey] = React.useState<string>(uniqueGUID());

  const onChangeUseDifferentTDEPasswordVal = (value: boolean): void => {
    setUseDifferentTDEPassword(value);
    if (value) {
      const formValues: FormValues = form.getGroupValues(GROUP_SECURITY);
      setDefaultTdePassword(formValues[Fields.TdePassword] !== undefined
        ? formValues[Fields.TdePassword] : defaultTdePassword);
      setDefaultTdePasswordConfirm(formValues[Fields.TdePasswordConfirm] !== undefined
        ? formValues[Fields.TdePasswordConfirm] : defaultTdePasswordConfirm);
    }
  };

  const onChangePassword = (value: string | undefined): void => {
    setPasswordVal(value);
    const formValues: FormValues = form.getGroupValues(GROUP_SECURITY);
    if (formValues[Fields.PasswordConfirm] !== undefined) {
      form.setValue(undefined, Fields.PasswordConfirm, GROUP_SECURITY);
      setConfirmPasswordKey(uniqueGUID());
    }
  };

  const onChangeTdePassword = (value: string | undefined): void => {
    setTDEPasswordVal(value);
    const formValues: FormValues = form.getGroupValues(GROUP_SECURITY);
    if (formValues[Fields.TdePasswordConfirm] !== undefined) {
      form.setValue(undefined, Fields.TdePasswordConfirm, GROUP_SECURITY);
      setDefaultTdePasswordConfirm(undefined);
      setTdeConfirmPasswordKey(uniqueGUID());
    }
  };
  return (
    <>
      <FieldSet
        header={Messages.createVmDb.securityTab.sectionTitles.databaseSystemAuthentication()}
      >
        <SshKeyPairInput
          defaultValue={SshPublicKeySource.Generate}
          fieldName={Fields.SshKeyPair}
          groupName={GROUP_SECURITY}
        />
      </FieldSet>

      <FieldSet header={Messages.createVmDb.securityTab.sectionTitles.databaseAdministratorCredentials()}>
        <TextInput
          testId={FieldTestIds.Username}
          fieldName={Fields.Username}
          label={Messages.labels.username()}
          defaultValue={defaultVMDBUsername}
          disabled
        />

        <TextInput
          fieldName={Fields.Password}
          label={Messages.labels.password()}
          testId={FieldTestIds.Password}
          tooltip={Messages.hints.tooltipAdminPassword()}
          textType={TextInputTypes.PASSWORD}
          required
          onChange={onChangePassword}
          validator={validateVmDbPassword}
        />

        <TextInput
          key={confirmPassWordKey}
          fieldName={Fields.PasswordConfirm}
          label={Messages.labels.passwordConfirm()}
          testId={FieldTestIds.PasswordConfirm}
          textType={TextInputTypes.PASSWORD}
          required
          validator={(value: string | undefined) => {
            if (value && (value !== passwordVal)) {
              return [Messages.validation.passwordConfirmation()];
            }
            return undefined;
          }}
        />
        {createFlowType === VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP && (
          <TdePassword />
        )}
        {createFlowType !== VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP && (
          <>
            <CheckBox
              defaultValue={useDifferentTDEPassword}
              fieldName={Fields.UseDifferentTDEPassword}
              testId={FieldTestIds.UseDifferentTDEPassword}
              label={Messages.labels.useDifferentTDEPassword()}
              tooltip={Messages.hints.useDifferentTDEPassword()}
              onChange={value => {
                onChangeUseDifferentTDEPasswordVal(value);
              }}
            />
            {useDifferentTDEPassword && (
              <>
                <TdePassword
                  defaultValue={defaultTdePassword}
                  onChange={onChangeTdePassword}
                />
                <TextInput
                  key={tdeConfirmPassWordKey}
                  defaultValue={defaultTdePasswordConfirm}
                  fieldName={Fields.TdePasswordConfirm}
                  testId={FieldTestIds.TdePasswordConfirm}
                  label={Messages.labels.tdePasswordConfirm()}
                  textType={TextInputTypes.PASSWORD}
                  required
                  validator={(value: string | undefined) => {
                    if (value && (value !== tdePasswordVal)) {
                      return [Messages.validation.passwordConfirmation()];
                    }
                    return undefined;
                  }}
                />
              </>
            )}
          </>
        )}
      </FieldSet>
    </>
  );
};
