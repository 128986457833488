import {
  AnchoredPanelType,
  CheckBox,
  FieldSet,
  FormattedString,
  FormValues,
  InfoBlockLayout,
  InfoBlockStatus,
  InputFormGroup,
  InputFormSidePanel,
  InputFormSidePanelComponent,
  RadioGroup,
  RadioGroupOption,
  SubmitButtonMode,
  TextInput,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { vnetTroubleshooting } from "../../constants/docConstants";
import { SidePanelTestIds } from "../../constants/uiConstants";
import {
  ACCESS_TYPES,
  AdbWorkloadType,
  getDatabaseAccessEndpoint,
  getToolsAccessEndpoint,
} from "../../helpers/resourceHelper";
import {
  adbsDbHostnamePrefixMaxLength,
  alphaNumericHyphenRegex,
  startWithLetterRegex,
} from "../../helpers/validationHelper";
import { useFeatures } from "../../hooks/useFeatures";
import {
  AdbsNetworkAddressCollection,
  Fields as NetworkAddressFields,
} from "../AdbsNetworkAddressCollection/AdbsNetworkAddressCollection";
import { McvcnSelect } from "../McvcnSelect/McvcnSelect";
import { SubnetSelect } from "../SubnetSelect/SubnetSelect";
import { VNETSelect } from "../VNETSelect/VNETSelect";

export enum Fields {
  IPsCidrs = "ipsCidrs",
  AccessType = "accessType",
  Mcvcn = "mcvcn",
  VNET = "VNET",
  Subnet = "Subnet",
  RouteVNET = "routeVNET",
  NetworkPeering = "networkPeering",
  HostName = "hostname",
  DatabaseAccessEndpoint = "dbAccessEndpoint",
  ToolsAccessEndpoint = "toolsAccessEndpoint",
}

export enum FieldTestIds {
  IPsCidrs = "ipsCidrs",
  AccessType = "accessType",
  Mcvcn = "mcvcn",
  VNET = "VNET-field",
  Subnet = "Subnet",
  RouteVNET = "routeVNET",
  NetworkPeering = "networkPeering",
  HostName = "hostname",
  DatabaseAccessEndpoint = "dbAccessEndpoint",
  ToolsAccessEndpoint = "toolsAccessEndpoint",
}

export enum InfoBlockTestIds {
  RouteVnet = "route-vnet-infoblock",
}

export interface AdbsEditNetworkingValues {
  networkAccessType: ACCESS_TYPES | undefined,
  isMtlsConnectionRequired: boolean | undefined,
  whitelistedIps: Array<string> | undefined,
  mcvcnId: string | undefined,
  vnetId: string | undefined,
  subnetId: string | undefined,
  hostnamePrefix: string | undefined,
}
export interface AdbsNetworkingProps {
  workload: AdbWorkloadType | undefined;
  adbsEditNetworkingValues: AdbsEditNetworkingValues,
  subscriptionId: string | "";
  location: string | "";
  onClose: (() => void) | undefined;
  onSubmit: (formValues: FormValues) => void;
  componentRef?: (ref: InputFormSidePanelComponent) => void;
}

const accessTypes = (enableAdbsAzurePE: boolean): RadioGroupOption[] => {
  const accessTypeOptions = [
    {
      id: ACCESS_TYPES.EVERYWHERE,
      testId: ACCESS_TYPES.EVERYWHERE,
      text: Messages.labels.accessFromEverywhere(),
    },
    {
      id: ACCESS_TYPES.ALLOWED_IPS,
      testId: ACCESS_TYPES.ALLOWED_IPS,
      text: Messages.labels.accessFromAllowedIPs(),
    },
  ] as RadioGroupOption[];

  if (enableAdbsAzurePE) {
    accessTypeOptions.push({
      id: ACCESS_TYPES.PRIVATE,
      testId: ACCESS_TYPES.PRIVATE,
      text: Messages.labels.accessFromAzurePrivateEndpoint(),
    });
  }
  return accessTypeOptions;
};

const accessTypeHints = (
  accessFromEverywhere: JSX.Element,
  accessFromAllowedIPs: JSX.Element,
  accessFromAzurePE?: JSX.Element,
): JSX.Element => (
  <>
    {accessFromEverywhere}
    <br />
    {accessFromAllowedIPs}
    {!!accessFromAzurePE && (
      <>
        <br />
        {accessFromAzurePE}
      </>
    )}
  </>
);

export const AdbsNetworkingEditPanel = ({
  adbsEditNetworkingValues,
  subscriptionId,
  location,
  componentRef,
  onSubmit,
  onClose,
}: AdbsNetworkingProps): JSX.Element => {
  const {
    enableAdbsOpenPL,
    enableAdbsRestrictedPL,
    enableAdbsAzurePE,
    enableMcvcn,
    enableMcvcnCreate,
    enableAdbsAzurePEPeering,
    enableAdbsAzurePEPeeringUpdate,
  } = useFeatures();
  const defaultIsNetworkPeering = adbsEditNetworkingValues.networkAccessType === ACCESS_TYPES.PRIVATE_PEERING;
  const defaultAccessType = adbsEditNetworkingValues.networkAccessType
    ? defaultIsNetworkPeering ? ACCESS_TYPES.PRIVATE : adbsEditNetworkingValues.networkAccessType
    : adbsEditNetworkingValues.whitelistedIps?.length
      ? ACCESS_TYPES.ALLOWED_IPS
      : ACCESS_TYPES.EVERYWHERE;
  const [selectedAccessType, setSelectedAccessType] = React.useState<string>(defaultAccessType);
  const [addresses, setAddresses] = React.useState<FormValues[]>();
  const [showIPsCidrs, setShowIPsCidrs] = React.useState<boolean>(false);
  const [showNetworkPeering, setShowNetworkPeering] = React.useState<boolean>(false);
  const [showMcvcn, setShowMcvcn] = React.useState<boolean>(false);
  const [showVnetAndSubNet, setShowVnetAndSubNet] = React.useState<boolean>(false);
  const [showRouteVNET, setShowRouteVNET] = React.useState<boolean>(true);
  const [isRouteVNET, setIsRouteVNET] = React.useState<boolean>(true);
  const [isNetworkPeering, setIsNetworkPeering] = React.useState<boolean>(defaultIsNetworkPeering);
  const [selectedVnet, setSelectedVnet] = React.useState<string>();
  const [selectedSubnet, setSelectedSubnet] = React.useState<string>();
  const [selectedMcvcn, setSelectedMcvcn] = React.useState<string>();
  const [disableMcvcn, setDisableMcvcn] = React.useState<boolean>(false);
  const accessOptions = accessTypes(enableAdbsAzurePE);
  const accessFromEverywhere = FormattedString({ inputText: Messages.hints.accessFromEverywhere() });
  const accessFromAllowedIPs = FormattedString(
    { inputText: Messages.hints.accessFromAllowedIPs() },
  );
  const accessFromAzurePE = FormattedString({ inputText: Messages.hints.accessFromAzurePrivateEndpoint() });
  const [hostnameVal, setHostnameVal] = React.useState<string>();
  const vnetTooltip = FormattedString(
    { inputText: Messages.hints.tooltipVNET(vnetTroubleshooting) },
  ) as unknown as string;

  const internalComponentRef = (ref: InputFormSidePanelComponent): void => {
    componentRef?.(ref);
  };

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (adbsEditNetworkingValues) {
      const initFieldValues: FormValues = { [Fields.AccessType]: defaultAccessType };

      let renderIPsCidrs = false;
      let renderVnetAndSubnet = false;
      let renderRouteVnet = true;
      let enableRouteVnet = true;
      let renderMcvcn = false;
      let renderNetworkPeering = false;
      let enableNetworkPeering = false;

      switch (defaultAccessType) {
        case ACCESS_TYPES.EVERYWHERE:
          renderIPsCidrs = false;
          renderNetworkPeering = false;
          enableNetworkPeering = false;
          renderMcvcn = false;
          if (enableAdbsOpenPL) {
            renderRouteVnet = true;
            enableRouteVnet = !!adbsEditNetworkingValues.vnetId || !!adbsEditNetworkingValues.subnetId;
            renderVnetAndSubnet = enableRouteVnet;
          } else {
            renderRouteVnet = false;
            enableRouteVnet = false;
            renderVnetAndSubnet = false;
          }
          break;
        case ACCESS_TYPES.ALLOWED_IPS:
          renderIPsCidrs = true;
          renderNetworkPeering = false;
          enableNetworkPeering = false;
          renderMcvcn = false;
          if (enableAdbsRestrictedPL) {
            renderRouteVnet = true;
            enableRouteVnet = !!adbsEditNetworkingValues.vnetId || !!adbsEditNetworkingValues.subnetId;
            renderVnetAndSubnet = enableRouteVnet;
          } else {
            renderRouteVnet = false;
            enableRouteVnet = false;
            renderVnetAndSubnet = false;
          }
          break;
        case ACCESS_TYPES.PRIVATE:
          renderIPsCidrs = false;
          renderRouteVnet = false;
          enableRouteVnet = false;
          if (enableMcvcn && enableAdbsAzurePEPeering) {
            renderNetworkPeering = true;
            enableNetworkPeering = adbsEditNetworkingValues.networkAccessType === ACCESS_TYPES.PRIVATE_PEERING;
            renderMcvcn = enableNetworkPeering;
            renderVnetAndSubnet = !enableNetworkPeering;
          } else {
            renderNetworkPeering = false;
            enableNetworkPeering = false;
            renderMcvcn = false;
            renderVnetAndSubnet = true;
          }
          break;
        default:
          break;
      }

      if (renderIPsCidrs) {
        const ipCidrs = adbsEditNetworkingValues.whitelistedIps
          ?.map(ip => ({ [NetworkAddressFields.Address]: ip }));

        initFieldValues[Fields.IPsCidrs] = ipCidrs;
        setAddresses(ipCidrs);
      }
      setShowIPsCidrs(renderIPsCidrs);

      if (renderVnetAndSubnet) {
        if (adbsEditNetworkingValues.vnetId) {
          initFieldValues[Fields.VNET] = [adbsEditNetworkingValues.vnetId];
        }
        setSelectedVnet(adbsEditNetworkingValues.vnetId);
        if (adbsEditNetworkingValues.subnetId) {
          initFieldValues[Fields.Subnet] = [adbsEditNetworkingValues.subnetId];
        }
        setSelectedSubnet(adbsEditNetworkingValues.subnetId);
      }
      setShowVnetAndSubNet(renderVnetAndSubnet);

      if (renderRouteVnet) {
        initFieldValues[Fields.RouteVNET] = enableRouteVnet;
        setIsRouteVNET(enableRouteVnet);
      }
      setShowRouteVNET(renderRouteVnet);

      if (renderNetworkPeering) {
        initFieldValues[Fields.NetworkPeering] = enableNetworkPeering;
        setIsNetworkPeering(enableNetworkPeering);
      }
      setShowNetworkPeering(renderNetworkPeering);

      if (renderMcvcn) {
        if (adbsEditNetworkingValues.mcvcnId) {
          initFieldValues[Fields.Mcvcn] = [adbsEditNetworkingValues.mcvcnId];
          if (!enableAdbsAzurePEPeeringUpdate) {
            setDisableMcvcn(true);
          }
        }
        setSelectedMcvcn(adbsEditNetworkingValues.mcvcnId);
      }
      setShowMcvcn(renderMcvcn);

      setHostnameVal(adbsEditNetworkingValues.hostnamePrefix);

      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adbsEditNetworkingValues]);

  return (
    <InputFormSidePanel
      testId={SidePanelTestIds.AdbsEditNetworkAccess}
      componentRef={internalComponentRef}
      title={Messages.createNewPanels.editNetworking.title()}
      type={AnchoredPanelType.CUSTOM_WIDTH}
      customWidth={775}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={initialValues}
    >
      <RadioGroup
        fieldName={Fields.AccessType}
        testId={FieldTestIds.AccessType}
        label={Messages.labels.accessType()}
        options={accessOptions}
        defaultValue={defaultAccessType}
        statusInfo={{
          messageType: InfoBlockStatus.INFO,
          infoLayout: InfoBlockLayout.Compact,
          message: accessTypeHints(
            accessFromEverywhere,
            accessFromAllowedIPs,
            enableAdbsAzurePE ? accessFromAzurePE : undefined,
          ),
        }}
        onChange={(option: string) => {
          setSelectedAccessType(option);
          if (option === ACCESS_TYPES.EVERYWHERE) {
            setShowIPsCidrs(false);
            setShowNetworkPeering(false);
            setShowMcvcn(false);
            if (enableAdbsOpenPL) {
              if (isRouteVNET) {
                setShowVnetAndSubNet(true);
              }
              setShowRouteVNET(true);
            } else {
              setShowVnetAndSubNet(false);
              setShowRouteVNET(false);
            }
          } else if (option === ACCESS_TYPES.ALLOWED_IPS) {
            setShowIPsCidrs(true);
            setShowNetworkPeering(false);
            setShowMcvcn(false);
            if (enableAdbsRestrictedPL) {
              if (isRouteVNET) {
                setShowVnetAndSubNet(true);
              }
              setShowRouteVNET(true);
            } else {
              setShowVnetAndSubNet(false);
              setShowRouteVNET(false);
            }
          } else if (option === ACCESS_TYPES.PRIVATE) {
            setShowIPsCidrs(false);
            setShowRouteVNET(false);
            if (enableMcvcn && enableAdbsAzurePEPeering) {
              setShowNetworkPeering(true);
              if (isNetworkPeering) {
                setShowVnetAndSubNet(false);
                setShowMcvcn(true);
              } else {
                setShowVnetAndSubNet(true);
                setShowMcvcn(false);
              }
            } else {
              setShowVnetAndSubNet(true);
              setShowNetworkPeering(false);
              setShowMcvcn(false);
            }
          }
        }}
      />
      {showIPsCidrs && (
      <AdbsNetworkAddressCollection
        label={Messages.labels.ipsAndOrCidrs()}
        tooltip={Messages.hints.ipsAndOrCidrs()}
        required
        defaultValue={addresses}
        onChange={setAddresses}
        fieldName={Fields.IPsCidrs}
        testId={FieldTestIds.IPsCidrs}
      />
      )}
      {showRouteVNET && (
      <CheckBox
        fieldName={Fields.RouteVNET}
        testId={FieldTestIds.RouteVNET}
        defaultValue={isRouteVNET}
        label={Messages.labels.routeVNET()}
        statusInfo={{
          testId: InfoBlockTestIds.RouteVnet,
          messageType: InfoBlockStatus.INFO,
          infoLayout: InfoBlockLayout.Compact,
          message: Messages.hints.routeVNET(),
        }}
        onChange={(checked: boolean) => {
          setShowVnetAndSubNet(checked);
          setIsRouteVNET(checked);
        }}
      />
      )}
      {showNetworkPeering && (
      <CheckBox
        fieldName={Fields.NetworkPeering}
        testId={FieldTestIds.NetworkPeering}
        defaultValue={isNetworkPeering}
        label={Messages.labels.networkPeering()}
        statusInfo={{
          messageType: InfoBlockStatus.INFO,
          infoLayout: InfoBlockLayout.Compact,
          message: Messages.hints.networkPeering(),
        }}
        onChange={(checked: boolean) => {
          setShowVnetAndSubNet(!checked);
          setShowMcvcn(checked);
          setIsNetworkPeering(checked);
        }}
      />
      )}
      {showMcvcn && (
      <McvcnSelect
        required
        label={Messages.labels.virtualCloudNetwork()}
        disabled={disableMcvcn}
        defaultValue={selectedMcvcn ? [selectedMcvcn] : undefined}
        location={location}
        testId={FieldTestIds.Mcvcn}
        fieldName={Fields.Mcvcn}
        groupName={InputFormGroup}
        subscriptionId={subscriptionId}
        onChange={setSelectedMcvcn}
        statusInfo={enableMcvcnCreate ? {
          messageType: InfoBlockStatus.INFO,
          infoLayout: InfoBlockLayout.Compact,
          message: Messages.hints.editNetworkMcvcnCreate(),
        } : undefined}
      />
      )}
      {showVnetAndSubNet && (
      <>
        <VNETSelect
          required
          defaultValue={selectedVnet ? [selectedVnet] : undefined}
          fieldName={Fields.VNET}
          testId={FieldTestIds.VNET}
          label={Messages.labels.virtualNetwork()}
          tooltip={vnetTooltip}
          subscriptionId={subscriptionId}
          location={location}
          onChange={setSelectedVnet}
        />
        <SubnetSelect
          required
          defaultValue={selectedSubnet ? [selectedSubnet] : undefined}
          fieldName={Fields.Subnet}
          testId={FieldTestIds.Subnet}
          label={Messages.labels.subnets()}
          vnetId={selectedVnet}
          subscriptionId={subscriptionId}
          location={location}
          onChange={setSelectedSubnet}
        />
      </>
      )}
      {(selectedAccessType === ACCESS_TYPES.PRIVATE) && (defaultAccessType !== ACCESS_TYPES.PRIVATE) && (
      <FieldSet header={Messages.createAdbs.networkingTab.sectionTitles.databaseAndToolsAccess()}>
        <TextInput
          fieldName={Fields.HostName}
          testId={FieldTestIds.HostName}
          required
          label={Messages.labels.hostName()}
          defaultValue={hostnameVal}
          onChange={value => { setHostnameVal(value); }}
          validator={(value: string | undefined) => {
            const errors: string[] = [];
            if (!value || !startWithLetterRegex.test(value)) {
              errors.push(Messages.validation.nameStartChar());
            }
            if (!value || !alphaNumericHyphenRegex.test(value)) {
              errors.push(Messages.validation.valueAlphaNumericHyphen());
            }
            if (!value || value.length > adbsDbHostnamePrefixMaxLength) {
              errors.push(Messages.validation.valueMaxLen(adbsDbHostnamePrefixMaxLength.toString()));
            }
            return errors.length ? errors : undefined;
          }}
        />
        <TextInput
          fieldName={Fields.DatabaseAccessEndpoint}
          testId={FieldTestIds.DatabaseAccessEndpoint}
          label={Messages.labels.databaseAccessEndpoint()}
          placeholder={hostnameVal ? getDatabaseAccessEndpoint(hostnameVal, location) : undefined}
          disabled
        />
        <TextInput
          fieldName={Fields.ToolsAccessEndpoint}
          testId={FieldTestIds.ToolsAccessEndpoint}
          label={Messages.labels.toolsAccessEndpoint()}
          placeholder={hostnameVal ? getToolsAccessEndpoint(hostnameVal, location) : undefined}
          disabled
        />
      </FieldSet>
      )}
    </InputFormSidePanel>
  );
};
