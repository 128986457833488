export interface Locale {
  name: string;
  endonym: string;
  languageTag: string;
  deprecatedLanguageTag?: string;
  localeTag: string;
}

const supportedLocales: Locale[] = [
  { name: "Czech", endonym: "Čeština", localeTag: "cs-CZ", languageTag: "cs" },
  { name: "Danish", endonym: "Dansk", localeTag: "da-DK", languageTag: "da" },
  { name: "German", endonym: "Deutsch", localeTag: "de-DE", languageTag: "de" },
  { name: "English", endonym: "English", localeTag: "en-US", languageTag: "en" },
  { name: "Spanish", endonym: "Español", localeTag: "es-ES", languageTag: "es" },
  { name: "French", endonym: "Français", localeTag: "fr-FR", languageTag: "fr" },
  {
    name: "French (Canadian)",
    endonym: "Français (Canada)",
    localeTag: "fr-CA",
    languageTag: "fr-CA",
  },
  { name: "Croatian", endonym: "hrvatski", localeTag: "hr-HR", languageTag: "hr" },
  { name: "Italian", endonym: "Italiano", localeTag: "it-IT", languageTag: "it" },
  { name: "Hungarian", endonym: "magyar", localeTag: "hu-HU", languageTag: "hu" },
  { name: "Dutch", endonym: "Nederlands", localeTag: "nl-NL", languageTag: "nl" },
  { name: "Norwegian", endonym: "Norsk", localeTag: "no-NO", languageTag: "no" },
  { name: "Polish", endonym: "Polski", localeTag: "pl-PL", languageTag: "pl" },
  {
    name: "Portuguese (Brazilian)",
    endonym: "Português (Brasil)",
    localeTag: "pt-BR",
    languageTag: "pt",
  },
  {
    name: "Portuguese",
    endonym: "Português",
    localeTag: "pt-PT",
    languageTag: "pt-PT",
    deprecatedLanguageTag: "pt",
  },
  { name: "Romanian", endonym: "Română", localeTag: "ro-RO", languageTag: "ro" },
  { name: "Slovak", endonym: "slovenčina", localeTag: "sk-SK", languageTag: "sk" },
  { name: "Slovenian", endonym: "slovenščina", localeTag: "sl-SI", languageTag: "sl" },
  {
    name: "Serbian (Latin)",
    endonym: "srpski",
    localeTag: "sh-RS",
    languageTag: "sr-Latn",
    deprecatedLanguageTag: "sh",
  },
  { name: "Finnish", endonym: "suomi", localeTag: "fi-FI", languageTag: "fi" },
  { name: "Swedish", endonym: "Svenska", localeTag: "sv-SE", languageTag: "sv" },
  { name: "Turkish", endonym: "Türkçe", localeTag: "tr-TR", languageTag: "tr" },
  { name: "Greek", endonym: "Ελληνικά", localeTag: "el-GR", languageTag: "el" },
  { name: "Russian", endonym: "Русский", localeTag: "ru-RU", languageTag: "ru" },
  { name: "Serbian", endonym: "српски", localeTag: "sr-RS", languageTag: "sr" },
  { name: "Thai", endonym: "ไทย", localeTag: "th-TH", languageTag: "th" },
  { name: "Korean", endonym: "한국어", localeTag: "ko-KR", languageTag: "ko" },
  {
    name: "Chinese (Simplified)",
    endonym: "中文 (简体)",
    localeTag: "zh-CN",
    languageTag: "zh-Hans",
    deprecatedLanguageTag: "zh",
  },
  {
    name: "Chinese (Traditional)",
    endonym: "中文 (繁體)",
    localeTag: "zh-TW",
    languageTag: "zh-Hant",
  },
  { name: "Japanese", endonym: "日本語", localeTag: "ja-JP", languageTag: "ja" },
];

export function getSupportedLocales(): Locale[] {
  return supportedLocales;
}

export async function languageRequestInterceptor(
  request: Request,
): Promise<Request> {
  const locale = document?.documentElement?.lang;
  if (locale) request.headers.append("accept-language", locale);
  return request;
}

export default supportedLocales;
