import {
  AnchoredPanelType,
  FieldWrapper,
  FormattedString,
  FormErrors,
  FormInputGroupLayout,
  FormValues,
  getValue,
  InputFormGroup,
  InputFormSidePanel,
  InputFormSidePanelComponent,
  InputFormSidePanelProps,
  IpInput,
  RadioGroup,
  RadioGroupOption,
  SelectOption,
  SimpleBuilder,
  TextInput,
  uniqueGUID,
} from "o4a-react";
import React from "react";
import * as Messages from "../../codegen/Messages";
import { networkCidrSizingUrl, vnetTroubleshooting } from "../../constants/docConstants";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { AzureVirtualNetworkSummary } from "../../gen/clients/mchub-azure-api-client";
import { FutureMcvcnExisting, FutureMcvcnNew } from "../../helpers/resourceHelper";
import { validateIpAddressWithinRanges, validateMcvcnName } from "../../helpers/validationHelper";
import {
  Fields as NetworkAddressCollectionFields,
  NetworkAddressCollection,
  NetworkAddressCollectionType,
} from "../NetworkAddressCollection/NetworkAddressCollection";
import { ResetInput, ResetInputComponent } from "../ResetInput/ResetInput";
import { VNETSelect } from "../VNETSelect/VNETSelect";

export enum Fields {
  CidrBlocks = "cidrBlocks",
  FlowType = "flowType",
  Name = "name",
  NetworkVirtualAppliance = "networkVirtualAppliance",
  SelectedVnet = "selectedVnet",
  OciSubnetOcid = "ociSubnetOcid",
  OciSubnetOcids = "ociSubnetOcids",
  OciVcnOcid = "ociVcnOcid",
  Vnet = "vnet",
}

export enum FieldTestIds {
  Name = "mcvcnName",
  VNet = "vnet",
  NetworkVirtualAppliance = "networkVirtualAppliance",
  CidrBlocks = "cidrBlocks",
  OciVcnOcid = "ociVcnOcid",
  OciSubnetOcids = "ociSubnetOcids",
  OciSubnetOcid = "ociSubnetOcid",
}

export enum FlowType {
  CreateNew = "CreateNew",
  RegisterExisting = "RegisterExisting",
}

export interface McvcnCreateSidePanelProps extends
  Pick<InputFormSidePanelProps, "onClose"> {
  onSubmit: (futureMcvcn: FutureMcvcnExisting | FutureMcvcnNew) => void;
  subscriptionId: string;
  location: string;
}

const minCidrBlocks = 1;
const maxCidrBlocks = 1; // due to issues, mcvcn only supports 1 cidr for now
const minSubnets = 1;
const maxSubnets = 2;

export const McvcnCreateSidePanel = ({
  onClose,
  onSubmit,
  location,
  subscriptionId,
}: McvcnCreateSidePanelProps): JSX.Element => {
  const [formRef, setFormRef] = React.useState<InputFormSidePanelComponent>();
  const [selectedVnet, setSelectedVnet] = React.useState<AzureVirtualNetworkSummary | undefined>(undefined);
  const [flowType, setFlowType] = React.useState<FlowType>(FlowType.CreateNew);
  const [renderKey, setRenderKey] = React.useState<string>(uniqueGUID());
  const [ipInputRef, setIpInputRef] = React.useState<ResetInputComponent>();
  const [cidrInputRef, setCidrInputRef] = React.useState<ResetInputComponent>();
  const [vcnOcid, setVcnOcid] = React.useState<string>();
  const [subnetOcids, setSubnetOcids] = React.useState<FormValues[]>();
  const [addresses, setAddresses] = React.useState<FormValues[]>();

  const flowTypeOptions: RadioGroupOption[] = [
    { id: FlowType.CreateNew, text: Messages.labels.createNewVcn() },
    { id: FlowType.RegisterExisting, text: Messages.labels.registerExistingOciVcn() },
  ];

  const onFlowTypeChanged = (value: string): void => setFlowType(value as FlowType);

  const onChangeVnet = (_: string, vnet: AzureVirtualNetworkSummary | undefined): void => {
    setSelectedVnet(vnet);
    setRenderKey(uniqueGUID());
    ipInputRef?.reset();
    cidrInputRef?.reset();
  };

  const internalOnSubmit = (formValues: FormValues): void => {
    // This is short circuited here because we now do not have the flowType as part of the form currently
    const flow = getValue<RadioGroupOption>(formValues, Fields.FlowType, InputFormGroup)?.id || flowType;
    const name = getValue<string>(formValues, Fields.Name, InputFormGroup);
    const azureAttachedNetworkId = getValue<SelectOption[]>(formValues, Fields.Vnet, InputFormGroup)?.[0].id;
    const customerNvaIpAddress = getValue<string>(formValues, Fields.NetworkVirtualAppliance, InputFormGroup);

    if (flow === FlowType.CreateNew) {
      const simpleBuilderCidrs = getValue<NetworkAddressCollectionType>(
        formValues,
        Fields.CidrBlocks,
        InputFormGroup,
      );

      const ociVcnCidrBlocks = simpleBuilderCidrs?.map(
        value => value[NetworkAddressCollectionFields.Address],
      ).filter(value => !!value);

      onSubmit({
        name,
        azureAttachedNetworkId,
        ociVcnCidrBlocks,
        customerNvaIpAddress,
      } as FutureMcvcnNew);
    } else if (flow === FlowType.RegisterExisting) {
      const newSubnetOcids = getValue<FormValues[]>(formValues, Fields.OciSubnetOcids, InputFormGroup) as FormValues[];
      const ociSubnetOcids = newSubnetOcids?.map(value => value[Fields.OciSubnetOcid]);

      const ociVcnOcid = getValue<string>(formValues, Fields.OciVcnOcid, InputFormGroup);

      onSubmit({
        name,
        azureAttachedNetworkId,
        ociVcnOcid,
        ociSubnetOcids,
        customerNvaIpAddress,
      } as FutureMcvcnExisting);
    }

    onClose?.();
  };

  const uniqueValidator = (builderValues: FormValues[], rowNdx: number): FormErrors | undefined => {
    // must always return an object with the keys, otherwise any errors returned will not be unset
    const errors: FormErrors = { [Fields.OciSubnetOcid]: undefined };

    const simpleBuilderValues = getValue<FormValues[]>(
      formRef?.getFormValues() ?? {},
      Fields.OciSubnetOcids,
      InputFormGroup,
    ) ?? [];

    const ocidValues = simpleBuilderValues.map(row => row[Fields.OciSubnetOcid]);

    const ocid = builderValues[rowNdx][Fields.OciSubnetOcid];

    const index = ocidValues.indexOf(ocid);
    const nextIndex = ocidValues.indexOf(ocid, index + 1);

    if (nextIndex > index) {
      errors[Fields.OciSubnetOcid] = [Messages.validation.unique()];
    }

    return errors;
  };

  const nvaWithinVnetValidator = (value: string | undefined): string[] | undefined => {
    const errors: string[] = [];

    if (value) {
      // NVA IP address must be within the Azure Virtual Network address spaces
      errors.push(
        ...(validateIpAddressWithinRanges(
          value,
          selectedVnet?.addressSpaces || [],
        ) || []),
      );
    }

    return errors.length > 0 ? errors : undefined;
  };

  return (
    <InputFormSidePanel
      testId={SidePanelTestIds.McvcnCreatePanel}
      componentRef={setFormRef}
      type={AnchoredPanelType.MEDIUM}
      layout={FormInputGroupLayout.WIDE}
      title={Messages.createNewPanels.mcvcn.title()}
      onSubmit={internalOnSubmit}
      onClose={onClose}
    >
      <TextInput
        required
        testId={FieldTestIds.Name}
        fieldName={Fields.Name}
        label={Messages.labels.name()}
        validator={validateMcvcnName}
      />
      <VNETSelect
        required
        testId={FieldTestIds.VNet}
        fieldName={Fields.Vnet}
        label={Messages.labels.virtualNetwork()}
        tooltip={
          FormattedString(
            { inputText: Messages.hints.tooltipVNET(vnetTroubleshooting) },
          ) as unknown as string
        }
        subscriptionId={subscriptionId}
        location={location}
        onChange={onChangeVnet}
      />
      <ResetInput componentRef={setIpInputRef}>
        <IpInput
          testId={FieldTestIds.NetworkVirtualAppliance}
          key={`${renderKey}-nva`}
          disabled={!selectedVnet}
          validator={nvaWithinVnetValidator}
          fieldName={Fields.NetworkVirtualAppliance}
          label={Messages.labels.networkVirtualAppliance()}
          tooltip={Messages.hints.tooltipNetworkVirtualAppliance()}
          validation={{
            containsIpAddress: true,
            containsCidrBlock: false,
          }}
        />
      </ResetInput>
      {false && (
        // TODO: Register new mcvcn not working on back end - hide this selection for now */}
        <FieldWrapper>
          <RadioGroup
            defaultValue={flowType}
            fieldName={Fields.FlowType}
            options={flowTypeOptions}
            onChange={onFlowTypeChanged}
            required
          />
        </FieldWrapper>
      )}
      {flowType === FlowType.CreateNew && (
        <ResetInput componentRef={setCidrInputRef}>
          <NetworkAddressCollection
            required
            defaultValue={addresses}
            onChange={setAddresses}
            testId={FieldTestIds.CidrBlocks}
            key={`${renderKey}-address-space`}
            addressSpaces={selectedVnet?.addressSpaces}
            label={Messages.labels.ociCidrBlocks()}
            tooltip={FormattedString(
              { inputText: Messages.hints.toolTipCidr(networkCidrSizingUrl) },
            ) as unknown as string}
            fieldName={Fields.CidrBlocks}
            maxRows={maxCidrBlocks}
            minRows={minCidrBlocks}
            disabled={!selectedVnet}
          />
        </ResetInput>
      )}
      {flowType === FlowType.RegisterExisting && (
        <>
          <TextInput
            defaultValue={vcnOcid}
            onChange={setVcnOcid}
            testId={FieldTestIds.OciVcnOcid}
            fieldName={Fields.OciVcnOcid}
            label={Messages.labels.vcnOcid()}
            required
          />
          <SimpleBuilder
            required
            testId={FieldTestIds.OciSubnetOcids}
            label={Messages.labels.subnetOcids()}
            defaultValue={subnetOcids}
            onChange={setSubnetOcids}
            fieldName={Fields.OciSubnetOcids}
            maxRows={maxSubnets}
            minRows={minSubnets}
            rowValidator={uniqueValidator}
          >
            <TextInput
              testId={FieldTestIds.OciSubnetOcid}
              fieldName={Fields.OciSubnetOcid}
              label={Messages.labels.ocid()}
            />
          </SimpleBuilder>
        </>
      )}
    </InputFormSidePanel>
  );
};
