import {
  AsyncNotificationRequest,
  NotificationRequest,
  NotificationType,
  uniqueGUID,
  useNotificationRequest,
} from "o4a-react";
import * as React from "react";
import {
  ApiClientMethod,
  NormalError,
  SerializableResponse,
  SerializableResponseWithData,
  useMutation,
} from "savant-connector";
import { getOciRegion } from "../utils";

export type MutationCallResponse<Resp> = (
  Resp extends Response ? SerializableResponse : SerializableResponseWithData<Resp>
) | undefined;

export interface MutationCallOptions<Resp> {
  onSuccess?: (response: MutationCallResponse<Resp>) => void;
  onFailure?: (error: NormalError) => void;
  notification: {
    inProgress?: {
      title: string;
      message: string;
    },
    success: {
      title: string;
      message: string;
    },
    failure: {
      title: string;
      message: string;
    },
    asyncPolling?: {
      methodKey: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      methodArgs: any;
      location: string;
      responseMethodKey: string;
      errorStatusOverrides?: number[];
      delay: number;
      interval: number;
    },
  }
}

export interface MutationCallArgs<Args, Resp> {
  method: ApiClientMethod<Args, Resp>;
}

export interface MutationCall<Args, Resp> {
  invokeCall: (args: Args, options: MutationCallOptions<Resp>) => void;
}

export function useMutationCall<Args, Resp>({ method }: MutationCallArgs<Args, Resp>): MutationCall<Args, Resp> {
  const { submit: submitNotificationRequest, submitAsync } = useNotificationRequest();

  const optionsRef = React.useRef<MutationCallOptions<Resp>>();
  const unitId = React.useRef<string>();

  const { invoke, result } = useMutation({ method });

  React.useEffect(() => {
    const options = optionsRef.current;
    if (result && !result.loading && options) {
      // to cover the case of badly implemented API spec so we need to consider failure when status code is returned
      // as having an error object might not be enough
      if (result.error?.status) {
        submitNotificationRequest({
          unitId: unitId.current,
          type: NotificationType.FAILURE,
          title: options.notification.failure.title,
          message: options.notification.failure.message,
          apiError: result.error.body.message,
        } as NotificationRequest);

        options.onFailure?.(result.error);
      } else {
        if (options.notification.asyncPolling) {
          submitAsync({
            unitId: unitId.current,
            methodKey: options.notification.asyncPolling.methodKey,
            args: options.notification.asyncPolling.methodArgs,
            ociRegion: getOciRegion(options.notification.asyncPolling.location),
            delay: options.notification.asyncPolling.delay,
            pollingInterval: options.notification.asyncPolling.interval,
            polledResponseKey: options.notification.asyncPolling.responseMethodKey,
            errorStatusOverrides: options.notification.asyncPolling.errorStatusOverrides,
            onProgress: options.notification.inProgress,
            onSuccess: {
              title: options.notification.success.title,
              message: options.notification.success.message,
            },
            onFailure: {
              title: options.notification.failure.title,
              message: options.notification.failure.message,
            },
          } as AsyncNotificationRequest);
        } else {
          submitNotificationRequest({
            unitId: unitId.current,
            type: NotificationType.SUCCESS,
            title: options.notification.success.title,
            message: options.notification.success.message,
          } as NotificationRequest);
        }
        options.onSuccess?.(result.response);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const invokeCall = React.useCallback<(args: Args, options: MutationCallOptions<Resp>) => void>((args, options) => {
    optionsRef.current = options;
    unitId.current = uniqueGUID();
    invoke(args);
    if (options.notification.inProgress) {
      submitNotificationRequest({
        unitId: unitId.current,
        type: NotificationType.IN_PROGRESS,
        title: options.notification.inProgress.title,
        message: options.notification.inProgress.message,
      } as NotificationRequest);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoke]);

  return { invokeCall };
}
