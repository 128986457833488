import { FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Fields as TagDetailsFields, TagsEditPanel } from "../../components/TagsEditPanel/TagsEditPanel";
import { TagsInfoType } from "../../helpers/resourceHelper";
import { useOperation } from "../../hooks/useOperation";
import { OperationProps } from "../OperationTypes";
import { ExaVmClusterUpdateTagsProps, newExaVmClusterUpdateTags } from "./ExaVmClusterUpdateTags";

export interface ExaVmClusterEditTagsProps extends OperationProps {
  vmClusterId: string | undefined;
  defaultTags?: TagsInfoType;
}

const ExaVmClusterEditTags = (
  { location, vmClusterId, defaultTags, onExecute, onCancel }: ExaVmClusterEditTagsProps,
): JSX.Element => {
  const { trigger: triggerUpdateTags } = useOperation<ExaVmClusterUpdateTagsProps>(newExaVmClusterUpdateTags);
  return (
    <TagsEditPanel
      title={Messages.labels.editTags()}
      resourceId={vmClusterId}
      tagsDefaultValue={defaultTags}
      onSubmit={(formValues: FormValues) => {
        const tags = getValue<TagsInfoType>(formValues, TagDetailsFields.Tags, InputFormGroup) ?? [];
        triggerUpdateTags({
          vmClusterId,
          tags,
          location,
          onExecute,
        });
      }}
      onClose={onCancel}
    />
  );
};

export const newExaVmClusterEditTags = (
  props: ExaVmClusterEditTagsProps,
): JSX.Element => (<ExaVmClusterEditTags {...props} />);
