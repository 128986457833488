import {
  ActionType,
  ConfirmAction,
  CustomAction,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingDisplayNameLink,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { DetailsPanelId, PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  VmdbDatabaseSummary,
  VmdbDatabaseSummaryCollection,
  VmdbDbHomeSummary,
  VmdbDbHomeSummaryCollection,
  VmdbDbSystem,
} from "../../gen/clients/mchub-azure-api-client-vmdb";
import { getStatusInfo, responseItemstoArray } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { newVmDbDeleteCdb, VmDbDeleteCdbProps } from "../../operations/VmDb/VmDbDeleteCdb";
import {
  newVmDbViewConnectionStrings,
  VmDbViewConnectionStringsProps,
} from "../../operations/VmDb/VmDbViewConnectionStrings";
import { getAzureLocationName, getOciRegion } from "../../utils";

/**
 * TODO
 * This interface is implement to include the last status and details.
 * Api has not expose these two fields for now. When it will, we might remove this interface.
 */
interface VmdbDatabaseSummaryExt extends VmdbDatabaseSummary {
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
}

export interface VmDbSystemDatabasesListProps {
  dbSystem: VmdbDbSystem | undefined;
  location: string;
  subscriptionId: string;
  resourceGroupName: string,
}

export enum ColumnIds {
  Name = "name",
  ResourceGroupName = "id",
  Location = "location",
  ResourceStatus = "status",
  DbUniqueName = "dbUniqueName",
}

export enum ActionBarIds {
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
  ConnectionStrings = "action-bar-connection-strings",
}

export enum ActionBarTestIds {
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
  ConnectionStrings = "action-bar-connection-strings",
}

export enum ActionMenuIds {
  Delete = "action-menu-delete",
  ConnectionStrings = "action-menu-connection-strings",
}

export enum ActionMenuTestIds {
  Delete = "action-menu-delete",
  ConnectionStrings = "action-menu-connection-strings",
}

const detailsPageRegistrationIds = PageRegistrationConfig[PageId.VMDB_CDB_DETAILS].map(config => config.key);

export const VmDbSystemDatabasesList = (
  { dbSystem, location, subscriptionId, resourceGroupName }: VmDbSystemDatabasesListProps,
): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { locale } = React.useContext<Settings>(SettingsContext);
  const { subDetailsListingItemCount } = useRowCount();
  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<VmdbDatabaseSummary>>();
  const [selection, setSelection] = React.useState<VmdbDatabaseSummary[]>([]);
  const { closePanels } = useSidePanel();

  const { response: responseDbHomes } = useQueryCall({
    wait: !(dbSystem?.id && location),
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbDbHomes,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName,
        apiVersion: MultiCloudDatabaseApiVersion,
        dbSystemId: dbSystem?.id,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDatabaseHomes(),
      },
    },
  });

  const vmDbHomeItems = (responseDbHomes
    && responseItemstoArray<VmdbDbHomeSummaryCollection, VmdbDbHomeSummary>(responseDbHomes)) || [];

  const vmDbHomeId = vmDbHomeItems?.find(item => item.dbSystemId === dbSystem?.id)?.id;

  const setSelectedItems = (selectedItems: VmdbDatabaseSummary[]): void => {
    setSelection(selectedItems);
  };

  const { response: responseDbs, loading: loadingDbs, refresh: refreshDbs } = useQueryCall({
    wait: !vmDbHomeId,
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbDatabases,
    options: {
      args: {
        dbHomeId: vmDbHomeId,
        subscriptionId: subscriptionId || "",
        resourceGroupName,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDatabases(),
      },
    },
  });

  const dbItems = responseDbs
    && responseItemstoArray<VmdbDatabaseSummaryCollection, VmdbDatabaseSummary>(responseDbs);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = React.useMemo(() => dbItems, [JSON.stringify(dbItems)]);

  const getLocation = (value: VmdbDatabaseSummaryExt): string => getAzureLocationName(value?.location || "");

  const getStatus = (value: VmdbDatabaseSummaryExt): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
        )
      }
      hideClipboardCopy
    />
  );

  const columns: ListingColumn[] = [
    {
      name: Messages.common.name(),
      itemProp: ColumnIds.Name,
      flexGrow: 1,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.name}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.VMDB_CDB_DETAILS][0].panelPath}?location=${value.location}`,
            pageKey: detailsPageRegistrationIds[0],
          }}
        />
      ),
    },
    {
      name: Messages.labels.resourceGroup(),
      itemProp: ColumnIds.ResourceGroupName,
      flexGrow: 1,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      name: Messages.labels.location(),
      itemProp: ColumnIds.Location,
      flexGrow: 1,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
      onRenderItems: value => getLocation(value),
    },
    {
      name: Messages.labels.status(),
      itemProp: ColumnIds.ResourceStatus,
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      name: Messages.labels.databaseUniqueName(),
      itemProp: ColumnIds.DbUniqueName,
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
  ];

  const { trigger: triggerDeleteCdb } = useOperation<VmDbDeleteCdbProps>(newVmDbDeleteCdb);
  const { trigger: triggerViewConnectionStrings } = useOperation<VmDbViewConnectionStringsProps>(
    newVmDbViewConnectionStrings,
  );
  
  const buildDeleteAction = (key:string, testId: string, selectedItem: VmdbDatabaseSummary)
  : ConfirmAction<VmdbDatabaseSummary | undefined> => {
    const deleteAction = {
      key,
      testId,
      disabled: !selectedItem,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.labels.deleteVMDb(),
      description: Messages.hints.deleteVMDbSystemConfirmation(selectedItem?.dbName),
      onClick: () => trackActionClick(key, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.CONTAINERDATABASES),
      onConfirm: () => {
        triggerDeleteCdb({
          dbSystemId: selectedItem?.dbSystemId,
          databaseId: selectedItem?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refreshDbs();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.CONTAINERDATABASES),
    };
    return deleteAction;
  };

  const buildViewConnectionStringsAction = (key: string, testId: string, selectedItem: VmdbDatabaseSummary):
  CustomAction<VmdbDatabaseSummary | undefined> => {
    const viewConnectionStringsAction = {
      key,
      testId,
      text: Messages.actions.connectionStrings(),
      icon: MonochromeIconIds.Connect,
      disabled: !selectedItem,
      onClick: () => {
        trackActionClick(key, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.CONTAINERDATABASES);
        triggerViewConnectionStrings({
          databaseId: selectedItem?.id,
          isPdb: false,
          location,
        });
      },
    };
    return viewConnectionStringsAction;
  };

  const dbRowActionMenu = (item?:VmdbDatabaseSummary | undefined)
  : ActionType<VmdbDatabaseSummary> [] => {
    const dbActionItems : ActionType<VmdbDatabaseSummary | undefined>[] = [];
    if (item) {
      dbActionItems.push(
        buildViewConnectionStringsAction(ActionMenuIds.ConnectionStrings, ActionMenuTestIds.ConnectionStrings, item),
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return dbActionItems;
  };

  const dbBarActions: ActionType<VmdbDatabaseSummary>[] = [
    buildViewConnectionStringsAction(ActionBarIds.ConnectionStrings, ActionBarTestIds.ConnectionStrings, selection[0]),
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.CONTAINERDATABASES);
        refreshDbs();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  return (
    <Listing
      testId={ListingTestIds.VmDbSystemDatabases}
      listingComponentRef={setListingComponentRef}
      isLoading={loadingDbs}
      items={items || []}
      emptyList={{ title: Messages.common.noResults() }}
      listColumns={columns}
      itemsPerPage={subDetailsListingItemCount}
      actionBarItems={dbBarActions as ActionType<undefined>[]}
      actions={dbRowActionMenu}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Name,
      }}
    />
  );
};
