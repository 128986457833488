import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { RestoreAdbsDatabaseDetails } from "../../gen/clients/mchub-azure-api-client";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  ADBS_UPDATE_POLL_DELAY,
  ADBS_UPDATE_POLL_INTERVAL,
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface AdbsRestoreFromBackupProps extends OperationConfirmationProps {
  databaseId: string | undefined;
  backupName: string | undefined;
  timeEnded: Date | undefined;
}

const AdbsRestoreFromBackup = (
  { location, databaseId, backupName, timeEnded, onExecute }: AdbsRestoreFromBackupProps,
): JSX.Element => {
  const { invokeCall: invokeRestore } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.restoreAdbsDatabase },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
    const resGroup = decodeURIComponent(resourceGroup || "");
    const resName = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.restore(),
          message: Messages.notifications.inProgress.messages.restoreFromBackup(resName, backupName || ""),
        },
        success: {
          title: Messages.notifications.success.titles.restore(),
          message: Messages.notifications.success.messages.restoreFromBackup(resName, backupName || ""),
        },
        failure: {
          title: Messages.notifications.failure.titles.restore(),
          message: Messages.notifications.failure.messages.restoreFromBackup(resName, backupName || ""),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.ADBS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.ADBS_UPDATED_CHECK,
          delay: ADBS_UPDATE_POLL_DELAY,
          interval: ADBS_UPDATE_POLL_INTERVAL,
        },
      },
    };

    invokeRestore({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName: resName,
      restoreAdbsDatabaseDetails: {
        timestamp:
          timeEnded ? new Date(timeEnded) : undefined,
      } as RestoreAdbsDatabaseDetails,
    }, invokeOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export const newAdbsRestoreFromBackup = (
  props: AdbsRestoreFromBackupProps,
): JSX.Element => (<AdbsRestoreFromBackup {...props} />);
