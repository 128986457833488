import {
  FormValues,
  getValue,
  ReadOnlyText,
  SimpleBuilder,
  SimpleBuilderProps,
  TextInput,
  TextInputProps,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { getAddressRange } from "../../helpers/resourceHelper";
import { validateAdbsNetworkAddressRow, validateOverlappingAddresses } from "../../helpers/validationHelper";

export enum Fields {
  Address = "address",
  Addresses = "addresses",
}

export enum FieldTestIds {
  Address = "address",
  Addresses = "addresses",
}

export type AdbsNetworkAddressCollectionType = { [Fields.Address]: string }[];

type BaseNetworkAddressInputProps = Pick<TextInputProps, "disabled" >;
type BaseSimpleBuilderProps = Pick<
SimpleBuilderProps,
"defaultValue"
| "onChange"
| "fieldName"
| "label"
| "required"
| "testId"
| "tooltip"
>;

export type AdbsNetworkAddressCollectionProps = BaseNetworkAddressInputProps & BaseSimpleBuilderProps;

export const AdbsNetworkAddressCollection = ({
  disabled,
  ...props
}: AdbsNetworkAddressCollectionProps): JSX.Element => (
  <SimpleBuilder
    {...props}
    rowModifier={(rowValues: FormValues) => (
      { [Fields.Addresses]: getAddressRange(getValue<string>(rowValues, Fields.Address)) }
    )}
    validator={validateOverlappingAddresses(Fields.Address)}
    rowValidator={validateAdbsNetworkAddressRow(Fields.Address, {
      containsCidrBlock: true,
      containsIpAddress: true,
    })}
  >
    <TextInput
      disabled={disabled}
      label={Messages.labels.address()}
      fieldName={Fields.Address}
      testId={FieldTestIds.Address}
      ariaLabel={Messages.labels.address()}
    />
    <ReadOnlyText
      defaultValue={getAddressRange()}
      fieldName={Fields.Addresses}
      testId={Fields.Addresses}
      label={Messages.labels.addresses()}
    />
  </SimpleBuilder>
);
