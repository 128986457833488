// ****************************************************************************************
// Typescript does not support "longGeneric" format for timeZoneName.
// Need to use Javascript instead for implementing this function.
// ****************************************************************************************

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function getTimezoneName(locale) {
  const today = new Date();
  const short = Intl.DateTimeFormat(locale).format(today);
  const full = Intl.DateTimeFormat(locale, { timeZoneName: "longGeneric" }).format(today);

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");
  }
  // in some magic case when short representation of date is not present in the long one,
  // just return the long one as a fallback, since it should contain the timezone's name
  return full;
}
