import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  optimizedRetryOption,
  Select,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

export interface StorageScaleProps extends Omit<SelectProps, "options" | "defaultValue" | "loading"> {
  subscriptionId: string;
  location: string;
  resourceGroup?: string;
  dbSystemName?: string;
  defaultValue?: string;
}

export const StorageScaleSelect = ({
  subscriptionId,
  location,
  resourceGroup,
  dbSystemName,
  defaultValue,
  disabled,
  fieldName,
  label,
  onChange,
  placeholder,
  required,
  tooltip,
  validator,
  testId,
}: StorageScaleProps): JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());

  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const form: FormState = React.useContext(FormContext);
  if (!Object.keys(form).length) {
    throw new Error("StorageScaleSelect must be used within the Form");
  }

  const { loading, response } = useQueryCall({
    wait: !(subscriptionId && location && dbSystemName && resourceGroup),
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.getVmScaleStorageDiskOption,
    options: {
      args: { subscriptionId, resourceGroupName: resourceGroup || "", dbSystemName: dbSystemName || "" },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDbSystemStorageScaleSizes(),
      },
    },
  });

  const scaleStorageItems = response?.data.dataDiskStorageOptionsInGBs || [];

  const options = scaleStorageItems.map(item => ({
    id: item.toString(),
    text: item.toString(),
  }));

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location, dbSystemName]);

  React.useEffect(() => {
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(scaleStorageItems)]);

  return (
    <Select
      key={key}
      label={label}
      loading={loading}
      testId={testId}
      tooltip={tooltip}
      required={required}
      disabled={disabled}
      onChange={onChange}
      fieldName={fieldName}
      placeholder={placeholder}
      defaultValue={options.length && defaultValue ? [defaultValue] : undefined}
      options={options}
      validator={validator}
      hideSearchBox
    />
  );
};
