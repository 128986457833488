import { BookmarkablePage, FullPagePanel, optimizedRetryOption, ProgressDotsIndicator, useNavigation } from "o4a-react";
import * as React from "react";
import { Dropdown, IDropdownOption, IDropdownStyles, Spinner, SpinnerSize } from "@fluentui/react";
import { CostManagementWidget, TenancySubscription } from "@oracle-cloud/odsa-billing-widget";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { BILLING_ROUTE, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { SvgIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { useQueryCall } from "../../hooks/useQueryCall";
import { AzureSubscriptionSummaryExt, useSubscriptions } from "../../hooks/useSubscriptions";
import { getOciRegion } from "../../utils";

const registrationIds = PageRegistrationConfig[PageId.BILLING].map(config => config.key);

export const billingSubscriptionTestId = "billing-subscription";

export const BillingPage = (): JSX.Element => {
  const { back, navigateToSelf } = useNavigation(ConsoleContext);
  const { locale } = React.useContext<Settings>(SettingsContext);

  const { loading: subscriptionsLoading, subscriptions = [] } = useSubscriptions();
  const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
  const [subscription, setSubscription] = React.useState<AzureSubscriptionSummaryExt>();

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(BILLING_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // In order to set the first subscription as default
    setSubscription(subscriptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionsLoading]);

  const region = getOciRegion(subscription?.primaryLocation || "");
  const ociTenancy = subscription?.ociTenancyName || "";
  const ociDomain = subscription?.ociDomainName || "";

  const { loading, response } = useQueryCall({
    wait: !(subscription),
    method: apiClients.withRegion(region).billingApi.getTenancySubscription,
    options: {
      args: { subscriptionId: subscription?.id || "" },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadBilling(),
      },
    },
  });
  const tenancyResponseData = response?.data as TenancySubscription;

  const onItemChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined): void => {
    setSelectedItem(option);
    const selectedSubscription = subscriptions?.find(sub => sub.id === option?.id);
    setSubscription(selectedSubscription);
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.topServices.billing()}
    >
      <FullPagePanel
        title={Messages.topServices.billing()}
        icon={SvgIconIds.billingSvg}
        onClose={back}
        isOpen
      >
        <SubscriptionDropdown
          subscriptions={subscriptions}
          selectedItem={selectedItem}
          onItemChange={onItemChange}
          loading={subscriptionsLoading}
        />
        {loading ? <ProgressDotsIndicator /> : (
          <CostManagementWidget
            locale={locale}
            region={region}
            tenant={ociTenancy}
            domain={ociDomain}
            tenancySubscription={tenancyResponseData}
          />
        )}
      </FullPagePanel>
    </BookmarkablePage>
  );
};

interface SubscriptionDropdownProps {
  subscriptions: AzureSubscriptionSummaryExt[];
  selectedItem: IDropdownOption | undefined;
  onItemChange: (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) => void;
  loading: boolean;
}

const SubscriptionDropdown = ({
  subscriptions,
  selectedItem,
  onItemChange,
  loading,
}: SubscriptionDropdownProps): JSX.Element => {
  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
  const dropdownOptions = subscriptions?.map(subscription => ({
    id: subscription.id,
    key: subscription.id,
    text: subscription.name,
  }));
  const onRenderCaretDown = (): JSX.Element => (
    <Spinner
      styles={{ root: { height: "100%" } }}
      size={SpinnerSize.xSmall}
    />
  );
  return (
    <Dropdown
      selectedKey={selectedItem ? selectedItem.key : dropdownOptions[0]?.key}
      onChange={onItemChange}
      options={dropdownOptions}
      styles={dropdownStyles}
      onRenderCaretDown={loading ? onRenderCaretDown : undefined}
      disabled={loading}
      placeholder={loading ? Messages.common.loading() : Messages.common.noSubscriptions()}
      data-test-id={billingSubscriptionTestId}
      ariaLabel={Messages.labels.subscription()}
    />
  );
};
