import {
  ActionType,
  CustomAction,
  FilterComponent,
  Listing,
  ListingColumn,
  ListingDisplayNameLink,
  ListingSelectOption,
  RecentAccessItem,
  SelectionMode,
  SortDirections,
  useFavoriteAccess,
  useRecentAccess,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds } from "../../constants/uiConstants";
import { AzureSubscriptionSummary } from "../../gen/clients/mchub-azure-api-client";
import { IdResourceType, parseId } from "../../helpers/idHelper";
import { getResourceDisplayType, getResourceIconName, getResourcePageId } from "../../helpers/resourceHelper";
import { timeFromNow } from "../../helpers/timeHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { ResourceBaseItem } from "../../models/CommonInterfaces";
import { getAzureLocationName } from "../../utils";

export enum ColumnIds {
  DisplayName = "displayName",
  DisplayType = "displayType",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  LastAccessed = "lastAccessed",
}

export enum ColumnTestIds {
  DisplayName = "displayName",
  DisplayType = "displayType",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  LastAccessed = "lastAccessed",
}

export enum ActionBarIds {
  Clear = "action-bar-clear",
}

export enum ActionBarTestIds {
  Clear = "action-bar-clear",
}

export enum ActionMenuIds {
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum ActionMenuTestIds {
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export interface RecentResourceItem extends ResourceBaseItem {
  subscriptionId: string;
  subscriptionName: string;
  resourceGroupName: string;
  isFavorite: boolean;
}

interface AzureSubscriptionSummaryMap {
  [key: string]: AzureSubscriptionSummary;
}

export const RecentList = (): JSX.Element => {
  const { trackActionClick } = useAnalytics();

  const { locale } = React.useContext<Settings>(SettingsContext);
  const filterState = React.useContext<FilterState>(FilterStateContext);

  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();

  const [filterComponentRef, setFilterComponentRef] = React.useState<FilterComponent>();
  const filterTextValue = filterComponentRef?.getFilterText();
  const [filterText, setFilterText] = React.useState(filterState.filterTextValue || filterTextValue);

  const { recentItems, clearAll } = useRecentAccess();
  const { favoriteItems, isFavorite, setAsFavorite, removeFromFavorites } = useFavoriteAccess();

  const getLocation = (value: RecentResourceItem): string => getAzureLocationName(value.location);

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.DisplayName,
      testId: ColumnTestIds.DisplayName,
      name: Messages.common.name(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.displayName}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[value.detailsPageId][0].panelPath}?location=${value.location}`,
            pageKey: PageRegistrationConfig[value.detailsPageId][0].key,
          }}
          iconName={value.iconName}
        />
      ),
    },
    {
      itemProp: ColumnIds.DisplayType,
      testId: ColumnTestIds.DisplayType,
      name: Messages.labels.type(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.SubscriptionName,
      testId: ColumnTestIds.SubscriptionName,
      name: Messages.labels.subscription(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, max-len
      onRenderItems: value => <AzureSubscriptionLink resourceId={value.id} subscriptionName={value.subscriptionName} hideClipboard />,
    },
    {
      itemProp: ColumnIds.LastAccessed,
      testId: ColumnTestIds.LastAccessed,
      name: Messages.labels.lastAccessed(),
      flexGrow: 1,
      initialSortDirection: SortDirections.DESC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <span>{timeFromNow(value.lastAccessed)}</span>,
    },
  ];

  const groupingOptions: ListingSelectOption<RecentResourceItem>[] = [];

  const recentAccessToRecentResourceItem = (
    items: RecentAccessItem[],
    subscriptionsMap: AzureSubscriptionSummaryMap,
  ): RecentResourceItem[] => {
    const recentResourceItems: RecentResourceItem[] = [];
    items.forEach((item: RecentAccessItem) => {
      const { subscriptionId, resourceGroup, resourceName, resourceType, provider: providerName } = parseId(item.id);
      const itemRgName = resourceGroup;
      const itemSubscriptionName = subscriptionsMap && subscriptionsMap[subscriptionId]?.name;

      recentResourceItems.push({
        id: item.id,
        displayName: resourceName,
        subscriptionId,
        subscriptionName: itemSubscriptionName,
        resourceGroupName: itemRgName,
        location: item.data?.location || "",
        lastAccessed: item.lastAccessed,
        displayType: getResourceDisplayType(providerName, resourceType as IdResourceType),
        iconName: getResourceIconName(providerName, resourceType as IdResourceType),
        detailsPageId: getResourcePageId(providerName, resourceType as IdResourceType),
        isFavorite: isFavorite(item.id),
      });
    });
    return recentResourceItems;
  };

  const items = React.useMemo(() => {
    let convertedItems: RecentResourceItem[] = [];

    if (recentItems.length > 0 && subscriptions && subscriptions.length > 0) {
      const subscriptionsMap = subscriptions.reduce((
        map: AzureSubscriptionSummaryMap,
        subscription: AzureSubscriptionSummary,
      ) => {
        map[subscription.id] = subscription;
        return map;
      }, {});
      convertedItems = recentAccessToRecentResourceItem(recentItems, subscriptionsMap);
    }
    return convertedItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(recentItems), JSON.stringify(subscriptions), JSON.stringify(favoriteItems)]);

  const recentBarActions: ActionType<RecentResourceItem>[] = [
    {
      key: ActionBarIds.Clear,
      testId: ActionBarTestIds.Clear,
      text: Messages.actions.clear(),
      icon: MonochromeIconIds.Delete,
      onClick: () => {
        trackActionClick(ActionBarIds.Clear, PageId.RECENT_RESOURCES);
        clearAll();
      },
    },
  ];

  const favoriteRowActionMenus = (item?: RecentResourceItem): ActionType<RecentResourceItem>[] => {
    const actionItems: ActionType<RecentResourceItem>[] = [];
    if (item) {
      if (item.isFavorite) {
        actionItems.push({
          key: ActionMenuIds.RemoveFavorite,
          testId: ActionMenuTestIds.RemoveFavorite,
          text: Messages.actions.removeFromFavorites(),
          icon: MonochromeIconIds.FavoriteSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.RemoveFavorite, PageId.RECENT_RESOURCES);
            removeFromFavorites(item.id);
          },
        } as CustomAction<RecentResourceItem>);
      } else {
        actionItems.push({
          key: ActionMenuIds.AddFavorite,
          testId: ActionMenuTestIds.AddFavorite,
          text: Messages.actions.addToFavorites(),
          icon: MonochromeIconIds.FavoriteNotSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.AddFavorite, PageId.RECENT_RESOURCES);
            setAsFavorite(item);
          },
        } as CustomAction<RecentResourceItem>);
      }
    }
    return actionItems;
  };

  const textFilterCallBack = (rowItem: RecentResourceItem): RecentResourceItem => {
    const displayRowItem = { ...rowItem };
    displayRowItem.location = getAzureLocationName(displayRowItem.location);
    displayRowItem.lastAccessed = timeFromNow(displayRowItem?.lastAccessed || 0) as unknown as number;
    return displayRowItem;
  };

  React.useEffect(() => {
    setFilterText(filterTextValue);
    filterState.setFilterTextValue(filterTextValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTextValue]);

  const resetFilterValues = (): void => {
    filterState.setFilterTextValue("");
    setFilterText("");
  };

  return (
    <Listing
      testId={ListingTestIds.RecentList}
      items={items}
      listColumns={columns}
      actionBarItems={recentBarActions as ActionType<undefined>[]}
      actions={favoriteRowActionMenus}
      selectionMode={SelectionMode.none}
      isLoading={subscriptionLoading}
      showItemCount
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.LastAccessed,
      }}
      groupingSelect={{ groupingOptions }}
      filtering={{
        hideAddFilterPill: true,
        defaultFilterText: filterText,
        textFilterCallBack,
        componentRef: setFilterComponentRef,
        onClearFilters: resetFilterValues,
      }}
    />
  );
};
