import {
  BookmarkablePage,
  ConfigInputValues,
  FormValues,
  getFormattedDateTime,
  getValue,
  InputWizardPanel,
  InputWizardPanelComponent,
  InputWizardTabNavigation,
  MessageType,
  ReviewItem,
  ReviewSection,
  SelectOption,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
  ServiceProviders,
  SUPPORT_CREATE_ROUTE,
} from "../../constants/pluginConstants";
import { CreateWizardTestIds } from "../../constants/uiConstants";
import {
  CreateBillingItemDetails,
  CreateIncidentDetails,
  CreateIncidentDetailsProblemTypeEnum,
  CreateIncidentDetailsSeverityEnum,
  CreateLimitItemDetails,
  CreateTechSupportItemDetails,
  Incident,
} from "../../gen/clients/mchub-azure-api-client-platform";
import { getServiceCategory, SupportIssueType } from "../../helpers/resourceHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { MutationCallResponse, useMutationCall } from "../../hooks/useMutationCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { getOciRegion } from "../../utils";
import { ReviewTabContent } from "../CreateCommon/ReviewTabContent";
import {
  DetailsTabContent,
  Fields as DetailsFields,
  getQuotaDetails,
  GROUP_DETAILS,
  QuotaRequestDetails,
} from "./DetailsTabContent";
import { Fields as ProblemFields, GROUP_PROBLEM, ProblemTabContent } from "./ProblemTabContent";

const registrationIds = PageRegistrationConfig[PageId.SUPPORT_CREATE].map(config => config.key);

export enum PanelTestIds {
  ProblemDescription = "problem-description",
  AdditionalDetails = "additional-details",
}

interface CreateSupportRequestSubmitProps {
  panelRef: InputWizardPanelComponent;
  location: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitPayload: any;
  onPostSubmit: () => void;
}

const CreateSupportRequestSubmit = ({
  panelRef,
  location,
  submitPayload,
  onPostSubmit,
}: CreateSupportRequestSubmitProps): JSX.Element => {
  const { navigateTo } = useNavigation(ConsoleContext);

  const { invokeCall } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).incidentsApi.createIncident },
  );

  React.useEffect(() => {
    const invokeOptions = {
      onSuccess: (response: MutationCallResponse<Incident>) => {
        const supportRequestId = response?.data?.id;
        const problemType = response?.data?.problemType;
        if (supportRequestId) {
          // eslint-disable-next-line max-len
          const path = `${RESOURCE_ROUTE}/${supportRequestId}/${PageRegistrationConfig[PageId.SUPPORT_DETAILS][0].panelPath}?location=${location}`;
          const { key } = PageRegistrationConfig[PageId.SUPPORT_DETAILS][0];
          navigateTo(path, key, { problemType }, true);
        } else {
          panelRef?.close();
        }
        onPostSubmit();
      },
      onFailure: () => {
        panelRef.allowResubmit();
        onPostSubmit();
      },
      notification: {
        success: {
          title: Messages.notifications.success.titles.createSupportRequest(),
          message: Messages.notifications.success.messages.createSupportRequest(),
        },
        failure: {
          title: Messages.notifications.failure.titles.create(),
          message: Messages.notifications.failure.messages.createSupportRequest(),
        },
      },
    };

    invokeCall(submitPayload, invokeOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export const SupportCreatePage = (): JSX.Element => {
  const { trackActionDiscard } = useAnalytics();

  const { back, navigateToSelf, customData } = useNavigation(ConsoleContext);

  const { closePanels } = useSidePanel();

  const { subscriptions } = useSubscriptions();

  const [subscriptionId, setSubscriptionId] = React.useState<string>("");
  const [issueType, setIssueType] = React.useState<CreateIncidentDetailsProblemTypeEnum>();
  const [provider, setProvider] = React.useState<ServiceProviders>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [submitPayload, setSubmitPayload] = React.useState<any>();

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(SUPPORT_CREATE_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [panelRef, setPanelRef] = React.useState<InputWizardPanelComponent>({} as InputWizardPanelComponent);

  const onClose = (): void => back();

  const onSubmit = (formValues: FormValues): void => {
    const problemType = getValue<SelectOption[]>(
      formValues,
      ProblemFields.IssueType,
      GROUP_PROBLEM,
    )?.[0].id as CreateIncidentDetailsProblemTypeEnum;
    let title = "";
    let description = "";
    let timeEncountered: Date | undefined;
    let itemsList: CreateLimitItemDetails[] | CreateTechSupportItemDetails[] | CreateBillingItemDetails[] | undefined;
    switch (problemType) {
      case SupportIssueType.BILLING: {
        title = getValue<string>(formValues, ProblemFields.BillingSummary, GROUP_PROBLEM) || title;
        description = getValue<string>(formValues, DetailsFields.Description, GROUP_DETAILS) || title;
        const encountered = getValue<number>(formValues, DetailsFields.StartDate, GROUP_DETAILS);
        timeEncountered = encountered ? new Date(encountered) : undefined;
        itemsList = [
          {
            problemType,
            name: title,
          },
        ] as CreateBillingItemDetails[];
        break;
      }
      case SupportIssueType.QUOTAS: {
        title = Messages.createSupport.submitPayload.quotaTitle(
          getValue<SelectOption[]>(formValues, ProblemFields.QuotaType, GROUP_PROBLEM)?.[0]?.text ?? "",
        );
        timeEncountered = undefined;
        const serviceProvider = getValue<SelectOption[]>(
          formValues,
          ProblemFields.QuotaType,
          GROUP_PROBLEM,
        )?.[0]?.id as ServiceProviders;
        itemsList = [
          {
            problemType,
            serviceCategory:
              getServiceCategory(serviceProvider),
            ...getQuotaDetails(getValue<ConfigInputValues>(formValues, DetailsFields.Quotas, GROUP_DETAILS)?.value),
          },
        ] as CreateLimitItemDetails[];
        description = itemsList[0].name ?? "";
        break;
      }
      case SupportIssueType.TECHNICAL: {
        title = getValue<string>(formValues, ProblemFields.TechnicalSummary, GROUP_PROBLEM) || title;
        description = getValue<string>(formValues, DetailsFields.Description, GROUP_DETAILS) || title;
        const encountered = getValue<number>(formValues, DetailsFields.StartDate, GROUP_DETAILS);
        timeEncountered = encountered ? new Date(encountered) : undefined;

        itemsList = [
          {
            problemType,
            serviceCategory: getValue<SelectOption[]>(formValues, ProblemFields.ServiceType, GROUP_PROBLEM)?.[0]?.id,
            name: title,
            location: getValue<SelectOption[]>(formValues, ProblemFields.Region, GROUP_PROBLEM)?.[0]?.id,
          },
        ] as CreateTechSupportItemDetails[];
        break;
      }
      default:
        break;
    }

    const severity = getValue<SelectOption[]>(
      formValues,
      DetailsFields.Severity,
      GROUP_DETAILS,
    )?.[0]?.id as CreateIncidentDetailsSeverityEnum;

    const createIncidentDetails: CreateIncidentDetails = {
      problemType,
      title,
      description,
      timeEncountered,
      severity,
      itemsList,
    };

    setSubmitPayload({
      subscriptionId: getValue<SelectOption[]>(formValues, ProblemFields.Subscription, GROUP_PROBLEM)?.[0]?.id,
      createIncidentDetails,
    });
  };

  const buildProblemInfo = (
    formValues: FormValues,
    problemType: CreateIncidentDetailsProblemTypeEnum,
  ): ReviewItem[] => {
    const reviewProblemInfo = [
      {
        label: Messages.labels.subscription(),
        value: getValue<SelectOption[]>(formValues, ProblemFields.Subscription, GROUP_PROBLEM)?.[0]?.text,
      },
      {
        label: Messages.labels.issueType(),
        value: getValue<SelectOption[]>(formValues, ProblemFields.IssueType, GROUP_PROBLEM)?.[0]?.text,
      },
    ];
    switch (problemType) {
      case SupportIssueType.BILLING:
        reviewProblemInfo.push({
          label: Messages.labels.summary(),
          value: getValue<string>(formValues, ProblemFields.BillingSummary, GROUP_PROBLEM),
        });
        break;
      case SupportIssueType.QUOTAS:
        reviewProblemInfo.push(
          {
            label: Messages.labels.quotaType(),
            value: getValue<SelectOption[]>(formValues, ProblemFields.QuotaType, GROUP_PROBLEM)?.[0]?.text,
          },
        );
        break;
      case SupportIssueType.TECHNICAL:
        reviewProblemInfo.push(
          {
            label: Messages.labels.serviceType(),
            value: getValue<SelectOption[]>(formValues, ProblemFields.ServiceType, GROUP_PROBLEM)?.[0]?.text,
          },
          {
            label: Messages.labels.region(),
            value: getValue<SelectOption[]>(formValues, ProblemFields.Region, GROUP_PROBLEM)?.[0].text,
          },
          {
            label: Messages.labels.summary(),
            value: getValue<string>(formValues, ProblemFields.TechnicalSummary, GROUP_PROBLEM),
          },
        );
        break;
      default:
        break;
    }
    return reviewProblemInfo;
  };

  const buildDetailsInfo = (
    formValues: FormValues,
    problemType: CreateIncidentDetailsProblemTypeEnum,
  ): ReviewItem[] => {
    const reviewDetailsInfo: ReviewItem[] = [];
    switch (problemType) {
      case SupportIssueType.BILLING:
      case SupportIssueType.TECHNICAL:
        {
          const startDate = getValue<number>(formValues, DetailsFields.StartDate, GROUP_DETAILS) as number;
          const problemDate = new Date(startDate);

          reviewDetailsInfo.push(
            {
              label: Messages.labels.problemStartDate(),
              value: getFormattedDateTime(problemDate),
            },
            {
              label: Messages.labels.description(),
              value: getValue<string>(formValues, DetailsFields.Description, GROUP_DETAILS),
            },
          );
        }
        break;
      case SupportIssueType.QUOTAS:
        {
          const quotaRequest: QuotaRequestDetails = getValue<ConfigInputValues>(
            formValues,
            DetailsFields.Quotas,
            GROUP_DETAILS,
          )?.value;
          const quotaDetails = getQuotaDetails(quotaRequest);
          reviewDetailsInfo.push(
            {
              label: Messages.labels.requestSummary(),
              value: quotaDetails.name || "",
            },
            {
              label: Messages.labels.newQuotaLimit(),
              value: quotaDetails.requestedLimit?.toString() || "",
            },
          );
        }
        break;
      default:
        break;
    }
    reviewDetailsInfo.push(
      {
        label: Messages.labels.severity(),
        value: getValue<SelectOption[]>(formValues, DetailsFields.Severity, GROUP_DETAILS)?.[0]?.text,
      },
    );
    return reviewDetailsInfo;
  };

  const onRenderReview = (formValues: FormValues): JSX.Element => {
    const problemType = getValue<SelectOption[]>(
      formValues,
      ProblemFields.IssueType,
      GROUP_PROBLEM,
    )?.[0].id as CreateIncidentDetailsProblemTypeEnum;

    const reviewSections = [
      {
        title: Messages.createSupport.problemTab.title(),
        items: buildProblemInfo(formValues, problemType),
      },
      {
        title: Messages.createSupport.detailsTab.sectionTitles.problemDetails(),
        items: buildDetailsInfo(formValues, problemType),
      },
    ] as ReviewSection[];

    return <ReviewTabContent reviewSections={reviewSections} />;
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.createSupport.titles.short()}
    >
      <InputWizardPanel
        componentRef={setPanelRef}
        testId={CreateWizardTestIds.Support}
        tabNavigation={InputWizardTabNavigation.SEQUENTIAL}
        title={Messages.createSupport.titles.long()}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFormChange={(_formValues: FormValues, fieldValue: any, field: string) => {
          if (field === ProblemFields.Subscription) {
            setSubscriptionId(fieldValue?.[0]?.id);
          } else if (field === ProblemFields.IssueType) {
            setIssueType(fieldValue?.[0]?.id);
          } else if (field === ProblemFields.QuotaType) {
            setProvider(fieldValue?.[0]?.id);
          }
        }}
        items={[
          {
            header: Messages.createSupport.problemTab.title(),
            testId: PanelTestIds.ProblemDescription,
            groupName: GROUP_PROBLEM,
            content: <ProblemTabContent
              inputWizardPanelRef={panelRef}
            />,
          },
          {
            header: Messages.createSupport.detailsTab.title(),
            testId: PanelTestIds.AdditionalDetails,
            groupName: GROUP_DETAILS,
            content: <DetailsTabContent
              subscriptionId={subscriptionId}
              issueType={issueType}
              provider={provider}
            />,
            onUnselecting: closePanels,
          },
        ]}
        itemsMessages={[{
          message: Messages.createSupport.userWarning(),
          type: MessageType.WARNING,
          hideOnFirstSelect: false,
          itemNdx: 0,
        },
        {
          message: Messages.createSupport.userWarning(),
          type: MessageType.WARNING,
          hideOnFirstSelect: false,
          itemNdx: 1,
        }]}
        onRenderReview={onRenderReview}
        onSubmit={onSubmit}
        onClose={() => {
          const analytics = customData?.analytics as NavigationAnalyticsData;
          if (analytics) {
            trackActionDiscard(analytics.actionName, analytics.pageId, analytics.panelId);
          }
          onClose();
        }}
      />
      {submitPayload && (
        <CreateSupportRequestSubmit
          panelRef={panelRef}
          location={subscriptions?.find(sub => sub.id === subscriptionId)?.primaryLocation || ""}
          submitPayload={submitPayload}
          onPostSubmit={() => setSubmitPayload(undefined)}
        />
      )}
    </BookmarkablePage>
  );
};
