import {
  CalloutComponent,
  FormValues,
  getValue,
  InputFormCallout,
  InputFormGroup,
  SubmitButtonMode,
  TextInput,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { validateAdbDBName } from "../../helpers/validationHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  ADBS_UPDATE_POLL_DELAY,
  ADBS_UPDATE_POLL_INTERVAL,
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface AdbsEditDbNameProps extends OperationActionProps {
  databaseId: string | undefined;
  dbNameValue: string | undefined;
}

enum Fields {
  DbName = "dbName",
}

export enum FieldTestIds {
  DbName = "dbName-field",
}

const AdbsEditDbName = (
  { targetId, location, databaseId, dbNameValue, onExecute, onCancel }: AdbsEditDbNameProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (dbNameValue) {
      const initFieldValues: FormValues = { [Fields.DbName]: dbNameValue };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbNameValue]);

  const { invokeCall: invokeAdbsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.updateAdbsDatabase },
  );

  const [dbNameCalloutRef, setDbNameCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const onUpdateAdbsDbName = (formValues: FormValues): void => {
    const dbName = getValue<string>(formValues, Fields.DbName, InputFormGroup);
    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: dbNameCalloutRef.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateAdbsDbName(),
          message: Messages.notifications.inProgress.messages.updateAdbsDbName(resName),
        },
        success: {
          title: Messages.notifications.success.titles.updateAdbsDbName(),
          message: Messages.notifications.success.messages.updateAdbsDbName(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateAdbsDbName(),
          message: Messages.notifications.failure.messages.updateAdbsDbName(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.ADBS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.ADBS_UPDATED_CHECK,
          delay: ADBS_UPDATE_POLL_DELAY,
          interval: ADBS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeAdbsUpdate({
      databaseName: resName,
      resourceGroupName: resGroup,
      subscriptionId,
      updateAdbsDatabaseDetails: { dbName },
    }, invokeOptions);
  };

  return (
    <InputFormCallout
      testId={CalloutTestIds.AdbsEditDbName}
      targetId={targetId}
      title={Messages.createNewPanels.editDatabaseName.title()}
      componentRef={setDbNameCalloutRef}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={(formValues: FormValues) => { onUpdateAdbsDbName(formValues); }}
      onClose={onCancel}
      initialValues={initialValues}
    >
      <TextInput
        required
        fieldName={Fields.DbName}
        testId={FieldTestIds.DbName}
        defaultValue={dbNameValue}
        label={Messages.labels.databaseName()}
        validator={validateAdbDBName}
      />
    </InputFormCallout>
  );
};

export const newAdbsEditDbName = (
  props: AdbsEditDbNameProps,
): JSX.Element => (<AdbsEditDbName {...props} />);
