import * as Messages from "../codegen/Messages";

export const timeFromNow = (timestamp: number, from = Date.now()): string => {
  if (!timestamp) return "";

  const ms = from - timestamp;
  let secondsAgo = Math.floor(ms / 1000);
  let numberPart = 0;

  if (secondsAgo < 60) { return Messages.notifications.when.secondsAgo(); }

  if (secondsAgo < 120) { return Messages.notifications.when.minuteAgo(); }
  if (secondsAgo < 3600) {
    while (secondsAgo >= 60) { secondsAgo -= 60; numberPart += 1; }
    return Messages.notifications.when.minutesAgo(numberPart.toString());
  }

  if (secondsAgo < 7200) { return Messages.notifications.when.hourAgo(); }
  if (secondsAgo < 86400) {
    while (secondsAgo >= 3600) { secondsAgo -= 3600; numberPart += 1; }
    return Messages.notifications.when.hoursAgo(numberPart.toString());
  }

  if (secondsAgo < 172800) { return Messages.notifications.when.dayAgo(); }
  if (secondsAgo < 604800) {
    while (secondsAgo >= 86400) { secondsAgo -= 86400; numberPart += 1; }
    return Messages.notifications.when.daysAgo(numberPart.toString());
  }

  if (secondsAgo < 1209600) { return Messages.notifications.when.weekAgo(); }
  if (secondsAgo < 2592000) {
    while (secondsAgo >= 604800) { secondsAgo -= 604800; numberPart += 1; }
    return Messages.notifications.when.weeksAgo(numberPart.toString());
  }

  if (secondsAgo < 5184000) { return Messages.notifications.when.monthAgo(); }
  if (secondsAgo < 31536000) {
    while (secondsAgo >= 2592000) { secondsAgo -= 2592000; numberPart += 1; }
    return Messages.notifications.when.monthsAgo(numberPart.toString());
  }

  if (secondsAgo < 63072000) {
    return Messages.notifications.when.yearsAgo();
  }

  return "";
};

export const getTimestamp = (): string => {
  const d = new Date();
  const yy = (`0${d.getFullYear()}`).slice(-2);
  const mm = (`0${d.getMonth() + 1}`).slice(-2);
  const dd = (`0${d.getDate()}`).slice(-2);
  const hh = (`0${d.getHours()}`).slice(-2);
  const min = (`0${d.getMinutes()}`).slice(-2);
  const ss = (`0${d.getSeconds()}`).slice(-2);

  return `${yy}${mm}${dd}${hh}${min}${ss}`;
};

export const getDbHomeTimeStamp = (): string => {
  const date = new Date();
  const utcDate = new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
  ));
  const yy = (utcDate.getFullYear());
  const mm = (`0${utcDate.getMonth() + 1}`).slice(-2);
  const dd = (`0${utcDate.getDate()}`).slice(-2);
  const hh = (`0${utcDate.getHours()}`).slice(-2);
  const min = (`0${utcDate.getMinutes()}`).slice(-2);

  return `${yy}${mm}${dd}${hh}${min}`;
};

export const utc = "UTC";
