import {
  CheckBox,
  FieldSet,
  FormContext,
  FormState,
  InfoBlockLayout,
  InfoBlockStatus,
  InputWizardPanelComponent,
  IntegerInput,
  TextInput,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { AdbsDbCharacterSetSelect } from "../../components/AdbsDbCharacterSetSelect/AdbsDbCharacterSetSelect";
import { AdbsDbVersionSelect } from "../../components/AdbsDbVersionSelect/AdbsDbVersionSelect";
import { DatabaseEditionSelect } from "../../components/DatabaseEdition/DatabaseEditionSelect";
import { LicenseTypeSelect } from "../../components/LicenseTypeSelect/LicenseTypeSelect";
import { WorkloadSelect } from "../../components/WorkloadSelect/WorkloadSelect";
import { AdbDbVersionSummaryDbWorkloadEnum } from "../../gen/clients/mchub-azure-api-client-adb";
import { IdResourceType } from "../../helpers/idHelper";
import {
  adbsDbEditionSEOcpuMax,
  adbsMaxCPUCount,
  adbsMaxStorage,
  adbsMinCPUCount,
  adbsMinStorage,
  AdbWorkloadType,
  CharacterSetType,
  DATABASE_EDITION_TYPE,
  defaultCharacterSet,
  defaultNationalCharacterSet,
  LICENSE_TYPE,
} from "../../helpers/resourceHelper";
import { validateAdbDBName } from "../../helpers/validationHelper";
import { useFeatures } from "../../hooks/useFeatures";

export const GROUP_CONFIG = "config";

export enum Fields {
  StorageSize = "adbsDatabaseDetails.dataStorageSizeInTBs",
  DatabaseName = "adbsDatabaseDetails.dbName",
  CoreCount = "adbsDatabaseDetails.cpuCoreCount",
  AutoScaling = "adbsDatabaseDetails.isAutoScalingEnabled",
  WorkloadType = "adbsDatabaseDetails.dbWorkload",
  DbVersion = "adbsDatabaseDetails.dbVersion",
  LicenseType = "adbsDatabaseDetails.licenseModel",
  DatabaseEdition = "adbsDatabaseDetails.databaseEdition",
  CharacterSet = "adbsDatabaseDetails.characterSet",
  NationalCharacterSet = "adbsDatabaseDetails.ncharacterSet",
}
export enum DEFAULT_VALUES {
  OCPU_COUNT = 1,
  STORAGE_SIZE = 1
}

export enum FieldTestIds {
  DbVersion = "db-version",
  WorkloadType = "db-workload",
  LicenseType = "db-license-type",
  DatabaseName = "db-name",
  DatabaseEdition = "db-edition",
  CoreCount = "core-count",
  AutoScaling = "auto-scaling",
  StorageSize = "storage-size",
  CharacterSet = "character-set",
  NationalCharacterSet = "ncharacter-set",
}

export interface ConfigTabContentProps {
  location: string;
  subscription: string;
  inputWizardPanelRef: InputWizardPanelComponent;
  isApex?: boolean;
}

const DEFAULT_WORKLOAD_TYPE = AdbWorkloadType.DataWarehouse;

export const ConfigTabContent = ({
  inputWizardPanelRef,
  subscription,
  location,
  isApex,
}: ConfigTabContentProps): JSX.Element => {
  const form: FormState = React.useContext(FormContext);
  const { enableAdbsCharacterSet } = useFeatures();
  const [workload, setWorkload] = React.useState<string>(DEFAULT_WORKLOAD_TYPE);
  const [licenseTypeKey, setLicenseTypeKey] = React.useState<string>(uniqueGUID());
  const [ocpuCount, setOcpuCount] = React.useState<number | undefined>(DEFAULT_VALUES.OCPU_COUNT);
  const initLicenseType = (
    workload === AdbWorkloadType.APEX || workload === AdbWorkloadType.JSON
  ) ? "LICENSE_INCLUDED" : undefined;
  const [licenseType, setLicenseType] = React.useState<string | undefined>(initLicenseType);
  const [databaseEditionKey, setDatabaseEditionKey] = React.useState<string>(uniqueGUID());

  const onError = (): void => inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.general());
  const onMissingDependencies = (missingDependencies: IdResourceType[]): void => {
    const dependencyType = missingDependencies?.[0];
    if (dependencyType === IdResourceType.SUBSCRIPTIONS || dependencyType === IdResourceType.LOCATION) {
      inputWizardPanelRef.showErrorDialog(
        Messages.createCommon.dependencyWarnings.basicsIncomplete.message(),
        Messages.createCommon.dependencyWarnings.basicsIncomplete.title(),
      );
    }
  };

  React.useEffect(() => {
    if (ocpuCount !== undefined && ocpuCount > adbsDbEditionSEOcpuMax) {
      form.setValue(DATABASE_EDITION_TYPE.ENTERPRISE_EDITION, Fields.DatabaseEdition, GROUP_CONFIG);
    } else {
      form.setValue(undefined, Fields.DatabaseEdition, GROUP_CONFIG);
    }
    setDatabaseEditionKey(uniqueGUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocpuCount, licenseType]);

  return (
    <>
      <FieldSet header="">
        {!isApex && (
          <WorkloadSelect
            required
            fieldName={Fields.WorkloadType}
            label={Messages.labels.workloadType()}
            testId={FieldTestIds.WorkloadType}
            onChange={value => {
              setWorkload(value);
              form.setValue(undefined, Fields.LicenseType, GROUP_CONFIG);
              setLicenseType(initLicenseType);
              setLicenseTypeKey(uniqueGUID());
            }}
            defaultValue={[workload]}
          />
        )}
        <IntegerInput
          min={adbsMinCPUCount}
          max={adbsMaxCPUCount}
          defaultValue={DEFAULT_VALUES.OCPU_COUNT}
          required
          label={Messages.labels.ocpuCount()}
          fieldName={Fields.CoreCount}
          testId={FieldTestIds.CoreCount}
          onChange={setOcpuCount}
        />
        <CheckBox
          fieldName={Fields.AutoScaling}
          testId={FieldTestIds.AutoScaling}
          label={Messages.labels.enableOcpuAutoScaling()}
          defaultValue
          statusInfo={{
            messageType: InfoBlockStatus.INFO,
            infoLayout: InfoBlockLayout.Compact,
            message: Messages.hints.ocpuAutoScaling(),
          }}
        />
        <IntegerInput
          min={adbsMinStorage}
          max={adbsMaxStorage}
          defaultValue={DEFAULT_VALUES.STORAGE_SIZE}
          required
          label={Messages.labels.storageInTB()}
          fieldName={Fields.StorageSize}
          testId={FieldTestIds.StorageSize}
        />
        {!isApex && (
          <>
            <LicenseTypeSelect
              key={licenseTypeKey}
              testId={FieldTestIds.LicenseType}
              fieldName={Fields.LicenseType}
              defaultValue={initLicenseType}
              disabled={workload === AdbWorkloadType.APEX || workload === AdbWorkloadType.JSON}
              onChange={(type: string) => setLicenseType(type)}
            />
            {licenseType === LICENSE_TYPE.BRING_YOUR_OWN_LICENSE
              && (
                <DatabaseEditionSelect
                  key={databaseEditionKey}
                  testId={FieldTestIds.DatabaseEdition}
                  fieldName={Fields.DatabaseEdition}
                  disabled={ocpuCount !== undefined && ocpuCount > adbsDbEditionSEOcpuMax}
                  defaultValue={
                    ocpuCount && ocpuCount > adbsDbEditionSEOcpuMax
                      ? DATABASE_EDITION_TYPE.ENTERPRISE_EDITION
                      : undefined
                  }
                />
              )}
          </>
        )}
      </FieldSet>
      <FieldSet header={Messages.createAdbs.configurationTab.sectionTitles.databaseDetails()}>
        <AdbsDbVersionSelect
          required
          label={Messages.labels.databaseVersion()}
          testId={FieldTestIds.DbVersion}
          fieldName={Fields.DbVersion}
          location={location}
          subscriptionId={subscription}
          workload={workload as AdbDbVersionSummaryDbWorkloadEnum}
          onError={onError}
          onMissingDependencies={onMissingDependencies}
        />
        <TextInput
          required
          testId={FieldTestIds.DatabaseName}
          fieldName={Fields.DatabaseName}
          label={Messages.labels.databaseName()}
          validator={validateAdbDBName}
        />
        {enableAdbsCharacterSet && workload !== AdbWorkloadType.JSON
        && (
        <>
          <AdbsDbCharacterSetSelect
            required
            label={Messages.labels.characterSet()}
            testId={FieldTestIds.CharacterSet}
            fieldName={Fields.CharacterSet}
            location={location}
            subscriptionId={subscription}
            characterSetType={CharacterSetType.DATABASE}
            defaultValue={[defaultCharacterSet]}
          />
          <AdbsDbCharacterSetSelect
            label={Messages.labels.nationalCharacterSet()}
            testId={FieldTestIds.NationalCharacterSet}
            required
            fieldName={Fields.NationalCharacterSet}
            defaultValue={[defaultNationalCharacterSet]}
            location={location}
            subscriptionId={subscription}
            characterSetType={CharacterSetType.NATIONAL}
          />
        </>
        )}
      </FieldSet>
    </>
  );
};
