import {
  CheckBox,
  FormInputGroupLayout,
  FormValues,
  InputFormCallout,
  InputFormCalloutProps,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";
import { MdsDeletionPolicyDetails } from "../../gen/clients/mchub-azure-api-client-mds";
import { MdsAutoBackupRetentionPolicy, MdsFinalBackupPolicy } from "../../helpers/resourceHelper";

export enum Fields {
  DeleteProtected = "DeleteProtected",
  RequireFinalBackup = "RequireFinalBackup",
  RetainAutomaticBackups = "RetainAutomaticBackups",
}

export enum FieldTestIds {
  DeleteProtected = "DeleteProtected",
  RequireFinalBackup = "RequireFinalBackup",
  RetainAutomaticBackups = "RetainAutomaticBackups",
}

export interface DeletionPlanEditCalloutProps extends
  Pick<InputFormCalloutProps, "title" | "targetId" | "onClose" | "onSubmit" | "componentRef">
{
  dbDelete?: MdsDeletionPolicyDetails;
}

export const DeletionPlanEditCallout = ({
  dbDelete,
  title,
  onClose,
  onSubmit,
  targetId,
  componentRef,
}: DeletionPlanEditCalloutProps): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (dbDelete) {
      const initFieldValues: FormValues = {
        [Fields.DeleteProtected]: dbDelete.isDeleteProtected,
        [Fields.RetainAutomaticBackups]: dbDelete.automaticBackupRetention === MdsAutoBackupRetentionPolicy.RETAIN,
        [Fields.RequireFinalBackup]: dbDelete.finalBackup === MdsFinalBackupPolicy.REQUIRE_FINAL_BACKUP,
      };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbDelete]);

  return (
    <InputFormCallout
      layout={FormInputGroupLayout.WIDE}
      title={title || ""}
      targetId={targetId}
      onClose={onClose}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      componentRef={componentRef}
      initialValues={initialValues}
      testId={CalloutTestIds.DeletionPlanEditCallout}
    >
      <CheckBox
        testId={FieldTestIds.DeleteProtected}
        fieldName={Fields.DeleteProtected}
        label={Messages.labels.mySQLEnableDeleteProtected()}
        tooltip={Messages.hints.tooltipMySQLDeleteProtected()}
        defaultValue={dbDelete?.isDeleteProtected ?? false}
      />
      <CheckBox
        testId={FieldTestIds.RetainAutomaticBackups}
        fieldName={Fields.RetainAutomaticBackups}
        label={Messages.labels.mySQLEnableRetainAutomaticBackups()}
        tooltip={Messages.hints.tooltipMySQLRetainAutomaticBackups()}
        defaultValue={dbDelete?.automaticBackupRetention === MdsAutoBackupRetentionPolicy.RETAIN}
      />
      <CheckBox
        testId={FieldTestIds.RequireFinalBackup}
        fieldName={Fields.RequireFinalBackup}
        label={Messages.labels.mySQLEnableRequireFinalBackup()}
        tooltip={Messages.hints.tooltipMySQLRequireFinalBackup()}
        defaultValue={dbDelete?.finalBackup === MdsFinalBackupPolicy.REQUIRE_FINAL_BACKUP}
      />
    </InputFormCallout>
  );
};
