import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMCLUSTER_DELETE_POLL_DELAY,
  VMCLUSTER_DELETE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface ExaVmClusterDeleteProps extends OperationConfirmationProps {
    vmClusterId: string | undefined;
  }

const ExaVmClusterDelete = (
  { location, vmClusterId, onExecute }: ExaVmClusterDeleteProps,
): JSX.Element => {
  const { invokeCall: invokeDelete } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.deleteCloudVmCluster },
  );
  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(vmClusterId);
    const resGroup = decodeURIComponent(resourceGroup || "");
    const resName = decodeURIComponent(resourceName || "");
    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.deleteVMCluster(),
          message: Messages.notifications.inProgress.messages.deleteVMCluster(resName || ""),
        },
        success: {
          title: Messages.notifications.success.titles.deleteVMCluster(),
          message: Messages.notifications.success.messages.deleteVMCluster(resName || ""),
        },
        failure: {
          title: Messages.notifications.failure.titles.delete(),
          message: Messages.notifications.failure.messages.deleteVMCluster(resName || ""),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMCLUSTER_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            cloudVmClusterName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMCLUSTER_DELETED_CHECK,
          errorStatusOverrides: [404],
          delay: VMCLUSTER_DELETE_POLL_DELAY,
          interval: VMCLUSTER_DELETE_POLL_INTERVAL,
        },
      },
    };
    invokeDelete({
      subscriptionId,
      resourceGroupName: resGroup,
      cloudVmClusterName: resName,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div data-test-id={emptyDivTestId} />;
};

export const newExaVmClusterDelete = (
  props: ExaVmClusterDeleteProps,
): JSX.Element => (<ExaVmClusterDelete {...props} />);
