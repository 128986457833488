import { FormattedString, InfoBlock, InfoBlockLayout, InfoBlockStatus } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { parseId } from "../../helpers/idHelper";
import { MdsCrashRecovery } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_UPDATE_POLL_DELAY,
  MDS_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export enum InfoBlockTestIds {
  Confirmation = "confirmation-infoblock"
}

export interface MysqlUpdateCrashRecoveryProps extends OperationConfirmationProps {
  databaseId: string | undefined;
  isEnabled: boolean;
}

const MysqlUpdateCrashRecovery = (
  { location, databaseId, isEnabled, onExecute, onCancel }: MysqlUpdateCrashRecoveryProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resourceGroupName = decodeURIComponent(resourceGroup || "");
  const dbSystemName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeMdsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.updateMdsDbSystem },
  );

  const onUpdate = (): void => {
    const crashRecovery = isEnabled ? MdsCrashRecovery.DISABLED : MdsCrashRecovery.ENABLED;
    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateMysqlCrashRecovery(),
          message: Messages.notifications.inProgress.messages.updateMysqlCrashRecovery(dbSystemName),
        },
        success: {
          title: Messages.notifications.success.titles.updateMysqlCrashRecovery(),
          message: Messages.notifications.success.messages.updateMysqlCrashRecovery(dbSystemName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateMysqlCrashRecovery(),
          message: Messages.notifications.failure.messages.updateMysqlCrashRecovery(dbSystemName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            dbSystemName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_UPDATED_CHECK,
          delay: MDS_UPDATE_POLL_DELAY,
          interval: MDS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeMdsUpdate({
      subscriptionId,
      resourceGroupName,
      dbSystemName,
      updateMdsDbSystemDetails: { crashRecovery },
    }, invokeOptions);
  };

  return (
    <ConfirmationDialog
      // eslint-disable-next-line max-len
      title={isEnabled ? Messages.detailMysql.crashRecoveryConfirmation.disableTitle() : Messages.detailMysql.crashRecoveryConfirmation.enableTitle()}
      cancelButoonText={Messages.common.cancel()}
      submitButtonText={isEnabled ? Messages.actions.disable() : Messages.actions.enable()}
      onSubmit={onUpdate}
      onClose={() => onCancel?.()}
    >
      <div>
        {isEnabled && (
        <InfoBlock
          message={Messages.detailMysql.crashRecoveryConfirmation.disableWarnig()}
          messageType={InfoBlockStatus.WARNING}
          infoLayout={InfoBlockLayout.Compact}
          testId={InfoBlockTestIds.Confirmation}
        />
        )}
        {!isEnabled && <p>{Messages.detailMysql.crashRecoveryConfirmation.enableDescription()}</p>}
        <p>
          <FormattedString
            inputText={
              isEnabled
                ? Messages.detailMysql.crashRecoveryConfirmation.disableConfirmation(dbSystemName)
                : Messages.detailMysql.crashRecoveryConfirmation.enableConfirmation(dbSystemName)
            }
          />
        </p>
      </div>
    </ConfirmationDialog>
  );
};

export const newMysqlUpdateCrashRecovery = (
  props: MysqlUpdateCrashRecoveryProps,
): JSX.Element => (<MysqlUpdateCrashRecovery {...props} />);
