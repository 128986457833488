import { NotificationCard, uniqueGUID, useNotification } from "o4a-react";
import * as React from "react";
import { Callout, DirectionalHint, ICalloutContentStyles } from "@fluentui/react";
import { notificationGap, notificationTimeout, notificationWidth } from "../constants/uiConstants";

export interface NotificationOutletProps {
  anchorId: string;
}

const calloutStyles: Partial<ICalloutContentStyles> = { calloutMain: { padding: "10px" } };

export const NotificationOutlet = ({ anchorId }: NotificationOutletProps): JSX.Element => {
  const [toasts, setToasts] = React.useState<JSX.Element[]>([]);

  const { notifications, markToastShown } = useNotification();

  const markAsShown = (id: string): void => {
    markToastShown(id);
  };

  React.useEffect(() => {
    if (notifications && notifications.length > 0) {
      const notificationToasts = notifications?.filter(
        entry => !entry.toastShown,
      ).map((notification, ndx, allNotifications) => {
        const notificationCard = (
          <NotificationCard
            type={notification.type}
            title={notification.title}
            message={notification.message}
            apiError={notification.apiError}
            onClose={() => markAsShown(notification.id)}
          />
        );

        if (ndx === 0) {
          return (
            <Callout
              key={uniqueGUID()}
              id={notification.id}
              target={`#${anchorId}`}
              directionalHint={DirectionalHint.topRightEdge}
              gapSpace={notificationGap}
              minPagePadding={notificationGap}
              coverTarget
              isBeakVisible={false}
              calloutWidth={notificationWidth}
              styles={calloutStyles}
            >
              {notificationCard}
            </Callout>
          );
        }
        return (
          <Callout
            key={uniqueGUID()}
            id={notification.id}
            target={`#${allNotifications[ndx - 1].id}`}
            directionalHint={DirectionalHint.bottomRightEdge}
            gapSpace={notificationGap}
            minPagePadding={notificationGap}
            isBeakVisible={false}
            calloutWidth={notificationWidth}
            styles={calloutStyles}
          >
            {notificationCard}
          </Callout>
        );
      });
      setToasts(notificationToasts);
      window.setTimeout(() => {
        notifications.forEach(entry => markToastShown(entry.id));
      }, notificationTimeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <div>
      {toasts}
    </div>
  );
};
