import { CustomLink, NoValue } from "o4a-react";
import * as React from "react";
import { Link } from "@fluentui/react";
import { parseId } from "../../helpers/idHelper";
import { useAppAuthContext } from "../../hooks/useAppAuthContext";
import { azurePortalUrl } from "../../utils";

export interface AzureSubnetLinkProps {
  subnetId: string;
  hideClipboard?: boolean;
}

export const AzureSubnetLink = (
  { subnetId, hideClipboard }: AzureSubnetLinkProps,
): JSX.Element => {
  const { domainName } = useAppAuthContext();
  const subnet = decodeURIComponent(parseId(subnetId)?.resourceName || "");
  const url = `${azurePortalUrl}/#@${domainName}/resource${subnetId}/overview`;

  if (!subnetId) return <NoValue />;

  if (hideClipboard) {
    return (
      <Link
        title={subnet}
        href={url}
        target="_blank"
        underline
      >
        {subnet}
      </Link>
    );
  }

  return (
    <CustomLink
      href={url}
      target="_blank"
      underline
      linkText={subnet}
    />
  );
};
