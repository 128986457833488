import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  optimizedRetryOption,
  Select,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { DbVersionSummary, DbVersionSummaryCollection } from "../../gen/clients/mchub-azure-api-client-exa";
import { IdResourceType } from "../../helpers/idHelper";
import { responseItemstoArray } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

export interface ExaDbVersionProps extends Omit<SelectProps, "options"> {
  subscriptionId: string;
  location: string;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const ExaDbVersionSelect = (
  {
    defaultValue,
    fieldName,
    testId,
    label,
    placeholder,
    required,
    subField,
    tooltip,
    validator,
    subscriptionId,
    location,
    onChange,
    onError,
    onMissingDependencies,
  }: ExaDbVersionProps,
): JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());

  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const form: FormState = React.useContext(FormContext);

  const { loading, response, error } = useQueryCall({
    wait: !(subscriptionId && location),
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDbVersionsBySubscription,
    options: {
      args: { subscriptionId },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDbVersion(),
      },
    },
  });
  const items = (response
    && responseItemstoArray<DbVersionSummaryCollection, DbVersionSummary>(response)) || [];
  const options = items?.map(item => ({
    id: item.version,
    text: item.version,
  }));

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setKey(uniqueGUID());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location]);

  React.useEffect(() => {
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    if (!location) missingDependencies.push(IdResourceType.LOCATION);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const derivedPlaceholder = !loading && !error && options.length === 0
    ? Messages.createCommon.loadingNone(label?.toLowerCase() || "")
    : placeholder;

  return (
    <Select
      key={key}
      testId={testId}
      loading={!!subscriptionId && !!location && loading}
      required={required}
      subField={subField}
      fieldName={fieldName}
      label={label}
      options={options}
      placeholder={derivedPlaceholder}
      defaultValue={defaultValue}
      tooltip={tooltip}
      onClick={internalOnClick}
      onChange={onChange}
      validator={validator}
    />
  );
};
