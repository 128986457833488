/* eslint-disable max-classes-per-file */
import { Region } from "oci-console-regions";
import { createAzSavantClients } from "savant-az-connector";
import { FeedbackApi } from "./gen/clients/cfs-api-client";
import {
  AzureResourceApi,
  CloudLinkApi,
  DeploymentApi,
  MultiCloudVirtualNetworkApi,
  NetworkLinkApi,
  RegionsInfoApi,
} from "./gen/clients/mchub-azure-api-client";
import { AdbsAdaptorApi } from "./gen/clients/mchub-azure-api-client-adb";
import { MulticloudDatabaseApi } from "./gen/clients/mchub-azure-api-client-exa";
import { MdsAdaptorApi } from "./gen/clients/mchub-azure-api-client-mds";
import { BillingApi, IncidentApi, LimitsApi } from "./gen/clients/mchub-azure-api-client-platform";
import { VmDatabaseApi } from "./gen/clients/mchub-azure-api-client-vmdb";
import { BaseApiConfig, Fetch } from "./gen/clients/mchub-azure-api-client/lib/base-api";
import { clientId, feedbackApiUrl, mchubEndpointTemplate } from "./session/sessionConstants";
import { getRealmRegions } from "./utils";

interface FallbackRegion {
  region: Region;
  tried: boolean;
}
class FallbackInterceptor {
  private static getRequestFallbackRegions(): FallbackRegion[] {
    const fallbackRegions = getRealmRegions().fallbackRegions || [];
    return fallbackRegions.map(fallbackRegion => ({ region: fallbackRegion, tried: false }));
  }

  private static markTriedRegion(path: string, requestFallbackRegions: FallbackRegion[]): void {
    const pathComps = path.split(".");
    const fallbackRegion = requestFallbackRegions.find(entry => entry.region === pathComps[1]);
    if (fallbackRegion) {
      fallbackRegion.tried = true;
    }
  }

  private static getNextFallbackPath(path: string, requestFallbackRegions: FallbackRegion[]): string | undefined {
    const pathComps = path.split(".");
    const availableFallbackRegion = requestFallbackRegions.find(entry => !entry.tried);
    if (availableFallbackRegion) {
      availableFallbackRegion.tried = true;
      pathComps[1] = availableFallbackRegion.region;
      return pathComps.join(".");
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
  public get(target: any, p: string): any {
    if (p === "request") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return async function request(args: any) {
        const { httpMethod: requestMethod } = args;
        let { path: requestPath } = args;
        const requestFallbackRegions = FallbackInterceptor.getRequestFallbackRegions();
        FallbackInterceptor.markTriedRegion(requestPath, requestFallbackRegions);

        let isRegionDown = false;
        let result;
        do {
          try {
            const requestArgs = { ...args, path: requestPath };
            // eslint-disable-next-line no-await-in-loop
            result = await Reflect.apply(target[p], target, [requestArgs]);
            isRegionDown = false;
          } catch (e) {
            if (e instanceof Response && e.status >= 500 && requestMethod === "GET") {
              isRegionDown = true;
              requestPath = FallbackInterceptor.getNextFallbackPath(requestPath, requestFallbackRegions);
              if (!requestPath) {
                throw e;
              }
            } else {
              throw e;
            }
          }
        } while (isRegionDown);

        return result;
      };
    }
    return target[p];
  }
}

export const MultiCloudDatabaseApiVersion = undefined;
export const NetworkLinkApiVersion = "20220401";

const cfsScopes = [`api://${clientId}/myscopes openid profile`];
const exaDatabaseScopes = [`api://${clientId}/myscopes openid profile`];
const vmDatabaseScopes = [`api://${clientId}/myscopes openid profile`];
const adbsDatabaseScopes = [`api://${clientId}/myscopes openid profile`];
const mdsDatabaseScopes = [`api://${clientId}/myscopes openid profile`];
const netLinkScopes = [`api://${clientId}/myscopes openid profile`];
const azResourceScopes = [`api://${clientId}/myscopes openid profile`];
const cloudLinkScopes = [`api://${clientId}/myscopes openid profile`];
const deploymentScopes = [`api://${clientId}/myscopes openid profile`];
const incidentsScopes = [`api://${clientId}/myscopes openid profile`];
const limitsScopes = [`api://${clientId}/myscopes openid profile`];
const billingScopes = [`api://${clientId}/myscopes openid profile`];
const regionsScopes = [`api://${clientId}/myscopes openid profile`];
const networkScopes = [`api://${clientId}/myscopes openid profile`];

class RegionsInfoApiWFallback extends RegionsInfoApi {
  public static createFromEndpointTemplate(
    fetch: Fetch,
    region: string,
    secondLevelDomain: string,
    config?: BaseApiConfig,
    template?: string,
  ): RegionsInfoApi {
    const regionInfoApi = RegionsInfoApi.createFromEndpointTemplate(fetch, region, secondLevelDomain, config, template);
    return new Proxy(regionInfoApi, new FallbackInterceptor());
  }
}
class CloudLinkApiWFallback extends CloudLinkApi {
  public static createFromEndpointTemplate(
    fetch: Fetch,
    region: string,
    secondLevelDomain: string,
    config?: BaseApiConfig,
    template?: string,
  ): CloudLinkApi {
    const cloudLinkApi = CloudLinkApi.createFromEndpointTemplate(fetch, region, secondLevelDomain, config, template);
    return new Proxy(cloudLinkApi, new FallbackInterceptor());
  }
}

class AzureResourceApiWFallback extends AzureResourceApi {
  public static createFromEndpointTemplate(
    fetch: Fetch,
    region: string,
    secondLevelDomain: string,
    config?: BaseApiConfig,
    template?: string,
  ): AzureResourceApi {
    // eslint-disable-next-line max-len
    const azResourceApi = AzureResourceApi.createFromEndpointTemplate(fetch, region, secondLevelDomain, config, template);
    return new Proxy(azResourceApi, new FallbackInterceptor());
  }
}

const [clients, initialize, setActiveRegion, withRegion] = createAzSavantClients({
  cfsApi: {
    class: FeedbackApi,
    template: feedbackApiUrl,
    scopes: cfsScopes,
  },
  exaDatabaseApi: {
    class: MulticloudDatabaseApi,
    template: mchubEndpointTemplate,
    scopes: exaDatabaseScopes,
  },
  vmDatabaseApi: {
    class: VmDatabaseApi,
    template: mchubEndpointTemplate,
    scopes: vmDatabaseScopes,
  },
  adbsDatabaseApi: {
    class: AdbsAdaptorApi,
    template: mchubEndpointTemplate,
    scopes: adbsDatabaseScopes,
  },
  mdsDatabaseApi: {
    class: MdsAdaptorApi,
    template: mchubEndpointTemplate,
    scopes: mdsDatabaseScopes,
  },
  netLinkApi: {
    class: NetworkLinkApi,
    template: mchubEndpointTemplate,
    scopes: netLinkScopes,
  },
  networkApi: {
    class: MultiCloudVirtualNetworkApi,
    template: mchubEndpointTemplate,
    scopes: networkScopes,
  },
  azResourceApi: {
    class: AzureResourceApiWFallback,
    template: mchubEndpointTemplate,
    scopes: azResourceScopes,
  },
  cloudLinkApi: {
    class: CloudLinkApiWFallback,
    template: mchubEndpointTemplate,
    scopes: cloudLinkScopes,
  },
  deploymentApi: {
    class: DeploymentApi,
    template: mchubEndpointTemplate,
    scopes: deploymentScopes,
  },
  incidentsApi: {
    class: IncidentApi,
    template: mchubEndpointTemplate,
    scopes: incidentsScopes,
  },
  limitsApi: {
    class: LimitsApi,
    template: mchubEndpointTemplate,
    scopes: limitsScopes,
  },
  billingApi: {
    class: BillingApi,
    template: mchubEndpointTemplate,
    scopes: billingScopes,
  },
  regionsApi: {
    class: RegionsInfoApiWFallback,
    template: mchubEndpointTemplate,
    scopes: regionsScopes,
  },
});

const apiClients = {
  ...clients,
  initialize,
  setActiveRegion,
  withRegion,
};

export default apiClients;

/**
 *
 * Response interceptor for handling 204 (No Content)
 * Required because savant-generator does not handle multiple
 * success status for the same API call.
 * Logic is limited to handling POST/PUT requests returning 204
 */
export async function noContentResponseInterceptor(
  request: Request,
  response: Response,
): Promise<Response> {
  if (response.status === 204 && (request.method === "POST" || request.method === "PUT")) {
    const body = { code: "No Content" };
    const noContentResponse = new Response(JSON.stringify(body), {
      status: 203,
      statusText: "Non-Authoritative Information (No Content)",
      headers: response.headers,
    });
    return noContentResponse;
  }
  return response;
}
