import {
  FormValues,
  InputFormCallout,
  InputFormCalloutProps,
  IntegerInput,
  ReadOnlyText,
  SubmitButtonMode,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";

export enum Fields {
  CoreCount = "coreCount",
  RequestedCoreCount = "requestedCoreCount",
  NodeCount = "nodeCount"
}

export enum FieldsTestIds {
  CoreCount = "coreCount",
  RequestedCoreCount = "requestedCoreCount",
  NodeCount = "nodeCount"
}

export interface ScaleConfig {
  defaultValue: number | undefined;
  min?: number | undefined;
  max?: number | undefined;
  isZeroAllowed?: boolean | undefined;
  nodeCount?: number | undefined;
}
type BaseInputFormCalloutProps = Pick<
InputFormCalloutProps,
"title" | "targetId" | "onClose" | "componentRef" | "primaryButtonText" | "testId"
>

export interface ConfigCoreCountPanelProps extends BaseInputFormCalloutProps {
  scaleConfig: ScaleConfig | undefined;
  onSubmit?: (values: FormValues, cpuCoreCount?: number) => void;
}

export const ConfigCoreCountPanel = ({
  title,
  onClose,
  onSubmit,
  targetId,
  primaryButtonText,
  scaleConfig,
  testId,
  componentRef,
}: ConfigCoreCountPanelProps): JSX.Element => {
  const [requestedCoreCount, setRequestedCoreCount] = React.useState<number>(
    (scaleConfig?.defaultValue || scaleConfig?.min || 0) * (scaleConfig?.nodeCount || 1),
  );
  const [mountKey, setMountKey] = React.useState<string>(uniqueGUID());

  const [initialValues, setInitialValues] = React.useState<FormValues>();
  const cpuCoreCount = React.useRef<number>();

  React.useEffect(() => {
    if (scaleConfig) {
      const initFieldValues: FormValues = {
        // eslint-disable-next-line eqeqeq
        [Fields.CoreCount]: scaleConfig.defaultValue != undefined
          ? Number(scaleConfig.defaultValue).toString()
          : undefined,
      };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleConfig]);

  const onChangeCoreCount = (value: number | undefined): void => {
    const coreCount = value ?? 0;
    const newCpuCoreCount = coreCount * (scaleConfig?.nodeCount || 1);
    setRequestedCoreCount(newCpuCoreCount);
    cpuCoreCount.current = newCpuCoreCount;
    setMountKey(uniqueGUID());
  };

  const internalOnSubmit = (values: FormValues): void => {
    onSubmit?.(values, cpuCoreCount.current);
  };

  return (
    <InputFormCallout
      componentRef={componentRef}
      title={title || ""}
      targetId={targetId}
      onClose={onClose}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={internalOnSubmit}
      primaryButtonText={primaryButtonText}
      testId={testId}
      initialValues={initialValues}
    >
      <IntegerInput
        testId={FieldsTestIds.CoreCount}
        required
        defaultValue={scaleConfig?.defaultValue}
        label={Messages.labels.ocpuCountPerVM()}
        fieldName={Fields.CoreCount}
        min={scaleConfig?.isZeroAllowed ? undefined : scaleConfig?.min}
        max={scaleConfig?.isZeroAllowed ? undefined : scaleConfig?.max}
        onChange={value => { onChangeCoreCount(value); }}
        validator={(numValue: number | undefined): string[] | undefined => {
          const errors: string[] = [];
          if (scaleConfig?.isZeroAllowed) {
            if (scaleConfig?.min && Number.isInteger(scaleConfig?.min) && !Number.isInteger(scaleConfig?.max)) {
              if (numValue === undefined
                || !Number.isInteger(numValue) || (numValue !== 0 && numValue < scaleConfig.min)) {
                errors.push(Messages.validation.integerZeroOrMinRange(scaleConfig.min.toString()));
              }
            }
            if (scaleConfig?.max && !Number.isInteger(scaleConfig?.min) && Number.isInteger(scaleConfig?.max)) {
              if (numValue === undefined
                 || !Number.isInteger(numValue) || (numValue !== 0 && numValue > scaleConfig.max)) {
                errors.push(Messages.validation.integerMax(scaleConfig.max.toString()));
              }
            }
            if (scaleConfig?.min && scaleConfig?.max
              && Number.isInteger(scaleConfig?.min) && Number.isInteger(scaleConfig?.max)) {
              if (!Number.isInteger(numValue)
                || numValue === undefined
                || (numValue !== 0 && (numValue < scaleConfig.min || numValue > scaleConfig.max))) {
                errors.push(Messages.validation.integerZeroOrRange(
                  scaleConfig.min.toString(),
                  scaleConfig.max.toString(),
                ));
              }
            }
          }
          return errors.length ? errors : [];
        }}
      />
      <ReadOnlyText
        testId={FieldsTestIds.NodeCount}
        defaultValue={scaleConfig?.nodeCount?.toString()}
        label={Messages.labels.nodeCount()}
        fieldName={Fields.NodeCount}
      />
      <ReadOnlyText
        testId={FieldsTestIds.RequestedCoreCount}
        key={mountKey}
        defaultValue={requestedCoreCount.toString()}
        label={Messages.labels.requestedOCPUCount()}
        fieldName={Fields.RequestedCoreCount}
      />
    </InputFormCallout>
  );
};
