import { cidrRange, cidrValid, LabelMetaItem, Listing, ListingColumn, MetaItemSection, NoValue } from "o4a-react";
import * as React from "react";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { metaItemLabelsGap } from "../../constants/uiConstants";
import { AzureVirtualNetworkSummary, NetworkLink } from "../../gen/clients/mchub-azure-api-client";
import { AnalyticsDataPage } from "../../hooks/useAnalytics";
import { AzureVnetLink } from "../AzureLinks/AzureVnetLink";
import { McVcnLink } from "../ResourceLinks/McVcnLink";

interface CidrItem {
  address: string
  addressRange: string
}

export interface NetworkingDetailsLabels {
  hostName: string
  vnet?: string
  ipAddress?: string
  networkCidr?: string
  vnetAddressSpace?: string
  networkVirtualAppliance?: string;
  routerIpAddresses?: string;
  vcn?: string;
}

interface AddressSpaceItem {
  addressSpaces: string
}
export interface NetworkingDetailsProps {
  labels?: NetworkingDetailsLabels
  hostName?: string
  vnet: AzureVirtualNetworkSummary | undefined
  ipAddress?: string
  networkLink: NetworkLink | undefined;
  mcvcnId: string |undefined;
  location: string
  analytics: AnalyticsDataPage;
}

export enum MetaItemSectionTestIds{
  Hostname = "mis-hostname",
  Mcvcn = "mis-mcvcn",
  Vnet = "mis-vnet",
  IpAddress = "mis-ipaddress",
  NetworkVirtualAppliance = "mis-network-virtual-appliance",
  RouterIpAddresses = "mis-router-ipaddresses",
}

export const NetworkingDetails = (
  {
    labels,
    hostName,
    vnet,
    ipAddress,
    networkLink,
    mcvcnId,
    location,
    analytics,
  }: NetworkingDetailsProps,
): JSX.Element => {
  const ociCidrColumns: ListingColumn[] = [
    {
      name: Messages.labels.cidr(),
      itemProp: "address",
      flexGrow: 1,
      isResizable: true,
    },
    {
      name: Messages.labels.addressRange(),
      itemProp: "addressRange",
      flexGrow: 2,
      isResizable: true,
    },
  ];
  const addressSpacesColumns: ListingColumn[] = [
    {
      name: Messages.labels.addresses(),
      itemProp: "addressSpaces",
      flexGrow: 1,
      isResizable: true,
    },
  ];
  const ociCidrs = (): CidrItem[] => {
    const cidr: CidrItem[] = [];
    const cidrAddress = networkLink?.properties?.ociNetworkCidrs;
    if (cidrAddress && cidrAddress?.length > 0) {
      cidrAddress.forEach(address => {
        let item = null;
        if (cidrValid(address)) {
          const range = cidrRange(address);
          item = `${range[0]} - ${range[1]} ${Messages.common.addessesCount(range[2].toString())}`;
        } else {
          item = `${Messages.common.addessesCount("0")}`;
        }
        cidr.push({
          address,
          addressRange: item,
        });
      });
    }
    return cidr;
  };

  const addressSpaces = (): AddressSpaceItem[] => {
    const addrSpace: AddressSpaceItem[] = [];
    const vnetAddress = vnet?.addressSpaces;
    if (vnetAddress && vnetAddress?.length > 0) {
      vnetAddress.forEach(address => {
        addrSpace.push({ addressSpaces: address });
      });
    }
    return addrSpace;
  };
  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
      <MetaItemSection labelWidth={160}>
        <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.Hostname}
            label={labels?.hostName || Messages.labels.hostName()}
          >
            {hostName || <NoValue />}
          </LabelMetaItem>
          {mcvcnId && (
          <LabelMetaItem
            testId={MetaItemSectionTestIds.Mcvcn}
            label={labels?.vcn || Messages.labels.virtualCloudNetwork()}
          >
            <McVcnLink
              mcVcnId={mcvcnId}
              location={location}
              analytics={analytics}
            />
          </LabelMetaItem>
          ) }
          {!mcvcnId && networkLink && (
          <LabelMetaItem
            testId={MetaItemSectionTestIds.Vnet}
            label={labels?.vnet || Messages.labels.virtualNetwork()}
          >
            <AzureVnetLink vnetId={vnet?.id as string || ""} />
          </LabelMetaItem>
          )}
          {ipAddress && (
            <LabelMetaItem
              testId={MetaItemSectionTestIds.IpAddress}
              label={labels?.ipAddress || Messages.labels.ipAddress()}
            >
              {ipAddress || <NoValue />}
            </LabelMetaItem>
          )}
          {!mcvcnId && networkLink && networkLink?.properties.routingOptions?.customerNvaIpAddress && (
            <LabelMetaItem
              testId={MetaItemSectionTestIds.NetworkVirtualAppliance}
              label={labels?.networkVirtualAppliance || Messages.labels.networkVirtualAppliance()}
            >
              {networkLink.properties.routingOptions.customerNvaIpAddress}
            </LabelMetaItem>
          )}
          {!mcvcnId && networkLink && networkLink?.properties.routerIpAddresses && (
            <LabelMetaItem
              testId={MetaItemSectionTestIds.RouterIpAddresses}
              label={labels?.routerIpAddresses || Messages.labels.routerIpAddresses()}
            >
              {networkLink.properties.routerIpAddresses.join(", ")}
            </LabelMetaItem>
          )}
        </Stack>
      </MetaItemSection>
      {!mcvcnId && networkLink && (
        <Stack style={{ height: "100%" }}>
          <Pivot
            style={{ height: "100%" }}
            styles={{ itemContainer: { height: "calc(100% - 44px)" } }}
            overflowBehavior="menu"
            overflowAriaLabel={Messages.ariaLabel.more()}
          >
            {ociCidrs() && (
              <PivotItem
                headerText={labels?.networkCidr || Messages.labels.ociCidrs()}
                style={{ height: "100%" }}
              >
                <Listing
                  items={ociCidrs()}
                  listColumns={ociCidrColumns}
                />
              </PivotItem>
            )}
            {addressSpaces() && (
              <PivotItem
                headerText={labels?.vnetAddressSpace || Messages.labels.virtualNetworkAddresses()}
                style={{ height: "100%" }}
              >
                <Listing
                  items={addressSpaces()}
                  listColumns={addressSpacesColumns}
                />
              </PivotItem>
            )}
          </Pivot>
        </Stack>
      )}
    </Stack>
  );
};
