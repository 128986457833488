import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import { NormalError, useQuery } from "savant-connector";
import apiClients from "../apiClients";
import { ConsoleContext, ConsoleState } from "../console/ConsoleContext";
import { basicNoRefreshCaching } from "../constants/uiConstants";
import {
  AzureCloudLinkOciTenancyPaymentModelEnum,
  AzureCloudLinkOciTenancyTypeEnum,
  AzureCloudLinkSummary,
  AzureSubscriptionCollection,
  AzureSubscriptionSummary,
} from "../gen/clients/mchub-azure-api-client";
import { responseItemstoArray } from "../helpers/resourceHelper";
import { savantStore as azureSavantStore } from "../session/azureSession";
import { UserType } from "./useAppAuthContext";
import { useCloudLinks } from "./useCloudLinks";

export interface AzureSubscriptionSummaryExt extends AzureSubscriptionSummary {
  cloudLink?: AzureCloudLinkSummary;
  ociTenancyName?: string;
  ociTenancyPaymentModel?: AzureCloudLinkOciTenancyPaymentModelEnum;
  ociDomainName?: string;
  primaryLocation?: string;
  locations?: string[];
}
export interface SubscriptionsResult {
  loading: boolean;
  error?: NormalError;
  subscriptions?: AzureSubscriptionSummaryExt[];
  refresh: () => void;
}
export const useSubscriptions = (): SubscriptionsResult => {
  const {
    loading: cloudLinksLoading,
    error: cloudLinksError,
    refresh: refreshCloudLinks,
    cloudLinks,
  } = useCloudLinks();

  const { regionName: homeRegionName } = React.useContext<ConsoleState>(ConsoleContext);

  // Do not refresh subscriptions since they shouldn't change - user can refresh page to refresh
  const {
    response: subscriptionResponse,
    loading: subscriptionsLoading,
    error: subscriptionsError,
    refresh: refreshSubscriptions,
  } = useQuery({
    method: apiClients.withRegion(homeRegionName).azResourceApi.listAzureSubscriptions,
    options: { args: {}, caching: basicNoRefreshCaching, fetchAllPages: true, retry: optimizedRetryOption },
  });

  const subscriptionItems = subscriptionResponse
    && responseItemstoArray<AzureSubscriptionCollection, AzureSubscriptionSummary>(subscriptionResponse);

  // Convert from deprecated tenancy type attribute to new payment model
  const getPaymentModel = (tenancyType: AzureCloudLinkOciTenancyTypeEnum):
    AzureCloudLinkOciTenancyPaymentModelEnum => {
    switch (tenancyType) {
      case "PROMO":
      case "PROMO_CONSUMED":
        return "FREE";
      case "PAYG":
      case "MONTHLY_COMMITMENT":
      case "ANNUAL_COMMITMENT":
        return "PAID";
      case "UNKNOWN":
      default:
        return "UNKNOWN";
    }
  };

  const subscriptions = React.useMemo(() => {
    const subscriptionsExt: AzureSubscriptionSummaryExt[] = [];
    if (!cloudLinksError && !cloudLinksLoading) {
      const cloudLinkSubscriptionsIds: string[] = [];
      if (cloudLinks?.length) {
        for (let i = 0; i < cloudLinks.length; i++) {
          if (cloudLinks[i].subscriptionIds) {
            cloudLinkSubscriptionsIds.push(...cloudLinks[i].subscriptionIds as string[]);
          }
        }
      }
      // subscriptionIds contains a unique set of all active/updating subscription ids
      const subscriptionIds = Array.from(new Set<string>(cloudLinkSubscriptionsIds));
      for (let i = 0; i < subscriptionIds.length; i++) {
        const cloudLink = cloudLinks?.find(item => item.subscriptionIds?.includes(subscriptionIds[i]));
        const subscription = subscriptionItems?.find(item => item.id === subscriptionIds[i]);
        if (cloudLink && subscription) {
          // user has access to the subscription and it is an active or updating subscription
          subscriptionsExt.push({
            ...subscription,
            ociTenancyName: cloudLink.ociTenancyName,
            ociTenancyPaymentModel: cloudLink.ociTenancyPaymentModel as AzureCloudLinkOciTenancyPaymentModelEnum
              || getPaymentModel(cloudLink.ociTenancyType as AzureCloudLinkOciTenancyTypeEnum),
            ociDomainName: cloudLink.ociDomainName,
            primaryLocation: cloudLink.primaryLocation || (cloudLink.locations && cloudLink.locations[0]),
            locations: cloudLink?.locations,
          });
        }
      }
      azureSavantStore.put("userData", {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...azureSavantStore.get("userData") as any,
        userType: subscriptionIds.length === 0
          ? UserType.FIRST_TIME_USER
          : !subscriptionsLoading && (subscriptionsError?.status === 404 || subscriptionItems?.length === 0)
            ? UserType.NEW_USER
            : UserType.REGULAR_USER,
      });
    }
    return subscriptionsExt;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subscriptionsError,
    cloudLinksError,
    subscriptionsLoading,
    cloudLinksLoading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(cloudLinks),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(subscriptionItems),
  ]);

  const refresh = (): void => {
    refreshCloudLinks();
    refreshSubscriptions();
  };

  const result = React.useMemo(() => ({
    loading: subscriptionsLoading || cloudLinksLoading,
    error: (subscriptionsError?.status === 404 ? undefined : subscriptionsError) || cloudLinksError,
    refresh,
    subscriptions,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    subscriptionsError,
    cloudLinksError,
    subscriptionsLoading,
    cloudLinksLoading,
    refresh,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(cloudLinks),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(subscriptions),
  ]);

  return result;
};
