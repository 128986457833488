import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent, SelectOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Fields, VmDbCdbBackupEditPanel } from "../../components/VmDbCdbBackupEditPanel/VmDbCdbBackupEditPanel";
import {
  VmdbDbBackupConfig,
  VmdbDbBackupConfigAutoBackupWindowEnum,
} from "../../gen/clients/mchub-azure-api-client-vmdb";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_CDB_UPDATE_POLL_DELAY,
  VMDB_CDB_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface VmDbEditBackupConfigProps extends OperationProps {
  databaseId: string | undefined;
  backupConfig: VmdbDbBackupConfig | undefined;
}

const VmDbEditBackupConfig = (
  { location, databaseId, backupConfig, onCancel, onExecute }: VmDbEditBackupConfigProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName: databaseName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");

  const { invokeCall: invokeUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.updateVmdbDatabase },
  );

  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const buildDbBackupConfig = (formValues: FormValues): VmdbDbBackupConfig => {
    const autoBackupEnabled = getValue<boolean>(formValues, Fields.Backups, InputFormGroup);
    const recoveryWindow = autoBackupEnabled
      ? getValue<SelectOption[]>(formValues, Fields.RetentionPeriod, InputFormGroup)?.[0]?.id
      : undefined;
    const backupWindow = getValue<SelectOption[]>(
      formValues,
      Fields.Scheduling,
      InputFormGroup,
    )?.[0]?.id as VmdbDbBackupConfigAutoBackupWindowEnum | undefined;

    const backupDetails: VmdbDbBackupConfig = {
      autoBackupEnabled,
      autoBackupWindow: autoBackupEnabled
        ? backupWindow : undefined,
      recoveryWindowInDays: recoveryWindow ? parseInt(recoveryWindow, 10) : undefined,
    };
    return backupDetails;
  };

  const onEditBackupConfig = (formValues: FormValues): void => {
    const autoBackupEnabled = getValue<boolean>(formValues, Fields.Backups, InputFormGroup);
    if (autoBackupEnabled) {
      const backupWindow = getValue<SelectOption[]>(formValues, Fields.Scheduling, InputFormGroup);
      const recoveryWindow = getValue<SelectOption[]>(formValues, Fields.RetentionPeriod, InputFormGroup);
      const errors: string[] = [];
      if (!backupWindow) {
        errors.push(Messages.validation.required());
      }
      if (!recoveryWindow) {
        errors.push(Messages.validation.required());
      }
      if (errors.length > 0) {
        return;
      }
    }
    const dbBackupConfig = buildDbBackupConfig(formValues);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateVmCdbBackup(),
          message: Messages.notifications.inProgress.messages.updateVmCdbBackup(databaseName),
        },
        success: {
          title: Messages.notifications.success.titles.updateVmCdbBackup(),
          message: Messages.notifications.success.messages.updateVmCdbBackup(databaseName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateVmCdbBackup(),
          message: Messages.notifications.failure.messages.updateVmCdbBackup(databaseName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_CDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_UPDATED_CHECK_CDB,
          delay: VMDB_CDB_UPDATE_POLL_DELAY,
          interval: VMDB_CDB_UPDATE_POLL_INTERVAL,
        },
      },
    };

    invokeUpdate({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName,
      updateDatabaseDetails: { dbBackupConfig },
    }, invokeOptions);
  };

  return (
    <VmDbCdbBackupEditPanel
      componentRef={setSidePanelRef}
      cdbBackup={backupConfig}
      onSubmit={onEditBackupConfig}
      onClose={onCancel}
    />
  );
};

export const newVmDbEditBackupConfig = (
  props: VmDbEditBackupConfigProps,
): JSX.Element => (<VmDbEditBackupConfig {...props} />);
