import { ExtraFilter, PillFilterValue } from "o4a-react";
import * as React from "react";

interface FilterStateValue {
  filterValues: PillFilterValue[]|undefined,
  listId: string|undefined,
  defaultPills: ExtraFilter[]|undefined
}

export interface FilterState {
  setFilterStateValue: ({ filterValues, listId, defaultPills }: FilterStateValue) => void;
  pillFilterStateValue: FilterStateValue;
  setFilterTextValue: (value: string|undefined) => void;
  filterTextValue: string|undefined,
  defaultSubscription: string|undefined;
  setDefaultSubscription: (value: string|undefined) => void;
  defaultLocation: string|undefined;
  setDefaultLocation: (value: string|undefined) => void;
}

export const FilterStateContext = React.createContext<FilterState>({} as FilterState);

export interface FilterStateProviderProps {
  children: React.ReactNode;
}

export const FilterStateProvider = ({ children }: FilterStateProviderProps): JSX.Element => {
  const [pillFilterValues, setPillFilterValues] = React.useState<PillFilterValue[]>();
  const [defaultStatePills, setDefaultStatePills] = React.useState<ExtraFilter[]>();
  const [defaultFilterText, setDefaultFilterText] = React.useState<string>();
  const [defaultSubscription, setDefaultSubscription] = React.useState<string>();
  const [defaultLocation, setDefaultLocation] = React.useState<string>();

  const setFilterStateValue = ({ filterValues, listId, defaultPills }: FilterStateValue): void => {
    setPillFilterValues(filterValues);
    setDefaultStatePills(defaultPills);
    setFilterState(previousState => ({
      ...previousState,
      pillFilterStateValue: {
        listId,
        filterValues,
        defaultPills,
      },
    }));
  };

  const setFilterTextValue = (value: string|undefined): void => {
    setDefaultFilterText(value);
    setFilterState(previousState => ({
      ...previousState,
      filterTextValue: value,
    }));
  };

  const setSubscriptionValue = (value: string|undefined): void => {
    setDefaultSubscription(value);
    setFilterState(previousState => ({
      ...previousState,
      defaultSubscription: value,
    }));
  };
  const setLocationValue = (value: string|undefined): void => {
    setDefaultLocation(value);
    setFilterState(previousState => ({
      ...previousState,
      defaultLocation: value,
    }));
  };

  const [filterState, setFilterState] = React.useState<FilterState>({
    setFilterStateValue,
    pillFilterStateValue: {
      listId: undefined,
      filterValues: pillFilterValues,
      defaultPills: defaultStatePills,
    },
    setFilterTextValue,
    filterTextValue: defaultFilterText,
    defaultSubscription,
    setDefaultSubscription: setSubscriptionValue,
    defaultLocation,
    setDefaultLocation: setLocationValue,
  });

  return (
    <FilterStateContext.Provider value={filterState}>
      {children}
    </FilterStateContext.Provider>
  );
};
