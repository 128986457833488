import {
  ActionType,
  ConfirmAction,
  CustomAction,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingDisplayNameLink,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { ConsoleContext } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  DetailsPanelId,
  EXADB_CREATE_ROUTE,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { DatabaseSummary, DatabaseSummaryCollection } from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import { getStatusInfo, responseItemstoArray } from "../../helpers/resourceHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { ExaDbDeleteCdbProps, newExaDbDeleteCdb } from "../../operations/ExaDb/ExaDbDeleteCdb";
import {
  ExaDbViewConnectionStringsProps,
  newExaDbViewConnectionStrings,
} from "../../operations/ExaDb/ExaDbViewConnectionStrings";
import { getAzureLocationName, getOciRegion } from "../../utils";

const detailsPageRegistrationIds = PageRegistrationConfig[PageId.EXADB_CDB_DETAILS].map(config => config.key);

export interface ExaVmClusterDatabasesListProps {
  vmClusterId: string | undefined;
  location: string;
}

export enum ColumnIds {
  Name = "name",
  ResourceGroupName = "id",
  Location = "location",
  ResourceStatus = "status",
  DbUniqueName = "dbUniqueName"
}

export enum ActionBarIds {
  Create = "action-bar-create",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
  ConnectionStrings = "action-bar-connection-strings",
}

export enum ActionBarTestIds {
  Create = "action-bar-create",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
  ConnectionStrings = "action-bar-connection-strings",
}

export enum ActionMenuIds {
  Delete = "action-menu-delete",
  ConnectionStrings = "action-menu-connection-strings",
}

export enum ActionMenuTestIds {
  Delete = "action-menu-delete",
  ConnectionStrings = "action-menu-connection-strings",
}

export const ExaVmClusterDatabasesList = ({ vmClusterId, location }: ExaVmClusterDatabasesListProps): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();
  const [selection, setSelection] = React.useState<DatabaseSummary[]>([]);

  const { navigateTo } = useNavigation(ConsoleContext);

  const idComps = parseId(vmClusterId);
  const resGroup = decodeURIComponent(idComps?.resourceGroup || "");
  const { closePanels } = useSidePanel();

  const { locale } = React.useContext<Settings>(SettingsContext);
  const [listingComponentRef, setListingComponentRef] = React.useState
    <ListingComponent<DatabaseSummary>>();

  const setSelectedItems = (selectedItems: DatabaseSummary[]): void => {
    setSelection(selectedItems);
  };

  const { response, loading, refresh } = useQueryCall({
    wait: !vmClusterId,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDatabases,
    options: {
      args: {
        vmClusterId,
        subscriptionId: idComps?.subscriptionId || "",
        resourceGroupName: resGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDatabases(),
      },
    },
  });

  const dbItems = response
    && responseItemstoArray<DatabaseSummaryCollection, DatabaseSummary>(response);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = React.useMemo(() => dbItems, [JSON.stringify(dbItems)]);

  const getLocation = (value: DatabaseSummary): string => getAzureLocationName(value?.location || "");

  const getStatus = (value: DatabaseSummary): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
          value?.lifecycleDetails,
        )
      }
      hideClipboardCopy
    />
  );

  const { trigger: triggerDeleteCdb } = useOperation<ExaDbDeleteCdbProps>(newExaDbDeleteCdb);
  const { trigger: triggerViewConnectionStrings } = useOperation<ExaDbViewConnectionStringsProps>(
    newExaDbViewConnectionStrings,
  );
  
  const buildDeleteAction = (key: string, testId: string, selectedItem:DatabaseSummary)
  : ConfirmAction<DatabaseSummary | undefined> => {
    const deleteAction = {
      key,
      testId,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      disabled: !selectedItem,
      title: Messages.labels.deleteExaCSDB(),
      description: Messages.hints.deleteExaCSDBConfirmation(selectedItem?.name || ""),
      onClick: () => {
        trackActionClick(key, PageId.VMCLUSTER_DETAILS, DetailsPanelId.CONTAINERDATABASES);
      },
      onConfirm: () => {
        triggerDeleteCdb({
          databaseId: selectedItem?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, PageId.VMCLUSTER_DETAILS, DetailsPanelId.CONTAINERDATABASES),
    };
    return deleteAction;
  };

  const buildViewConnectionStringsAction = (key: string, testId: string, selectedItem: DatabaseSummary):
  CustomAction<DatabaseSummary | undefined> => {
    const viewConnectionStringsAction = {
      key,
      testId,
      text: Messages.actions.connectionStrings(),
      icon: MonochromeIconIds.Connect,
      disabled: !selectedItem,
      onClick: () => {
        trackActionClick(key, PageId.VMCLUSTER_DETAILS, DetailsPanelId.CONTAINERDATABASES);
        triggerViewConnectionStrings({
          databaseId: selectedItem?.id,
          isPdb: false,
          location,
        });
      },
    };
    return viewConnectionStringsAction;
  };

  const columns: ListingColumn[] = [
    {
      name: Messages.common.name(),
      itemProp: ColumnIds.Name,
      flexGrow: 1,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.name}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.EXADB_CDB_DETAILS][0].panelPath}?location=${value.location}`,
            pageKey: detailsPageRegistrationIds[0],
          }}
        />
      ),
    },
    {
      name: Messages.labels.resourceGroup(),
      itemProp: ColumnIds.ResourceGroupName,
      flexGrow: 1,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      name: Messages.labels.location(),
      itemProp: ColumnIds.Location,
      flexGrow: 1,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
      onRenderItems: value => getLocation(value),
    },
    {
      name: Messages.labels.status(),
      itemProp: ColumnIds.ResourceStatus,
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      name: Messages.labels.databaseUniqueName(),
      itemProp: ColumnIds.DbUniqueName,
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
  ];

  const dbRowActionMenu = (item?:DatabaseSummary | undefined)
  : ActionType<DatabaseSummary> [] => {
    const dbActionItems : ActionType<DatabaseSummary | undefined>[] = [];
    if (item) {
      dbActionItems.push(
        buildViewConnectionStringsAction(ActionMenuIds.ConnectionStrings, ActionMenuTestIds.ConnectionStrings, item),
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return dbActionItems;
  };

  const dbBarActions: ActionType<DatabaseSummary>[] = [
    {
      key: ActionBarIds.Create,
      testId: ActionBarTestIds.Create,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      // eslint-disable-next-line max-len
      onClick: () => {
        trackActionClick(ActionBarIds.Create, PageId.VMCLUSTER_DETAILS, DetailsPanelId.CONTAINERDATABASES);
        navigateTo(EXADB_CREATE_ROUTE, PageId.EXADB_CREATE, {
          vmClusterId,
          location,
          analytics: {
            pageId: PageId.VMCLUSTER_DETAILS,
            panelId: DetailsPanelId.CONTAINERDATABASES,
            actionName: ActionBarIds.Create,
          } as NavigationAnalyticsData,
        });
      },
    },
    
    buildViewConnectionStringsAction(ActionBarIds.ConnectionStrings, ActionBarTestIds.ConnectionStrings, selection[0]),
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, PageId.VMCLUSTER_DETAILS, DetailsPanelId.CONTAINERDATABASES);
        refresh();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  return (
    <Listing
      isLoading={loading}
      testId={ListingTestIds.ExaVmClusterDatabases}
      listingComponentRef={setListingComponentRef}
      items={items || []}
      emptyList={{ title: Messages.common.noResults() }}
      listColumns={columns}
      onActionClick={closePanels}
      actions={dbRowActionMenu}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      actionBarItems={dbBarActions as ActionType<undefined>[]}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Name,
      }}
    />
  );
};
