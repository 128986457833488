import {
  FieldSet,
  FormattedString,
  FormContext,
  FormState,
  InputWizardPanelComponent,
  RadioGroup,
  RadioGroupOption,
  Select,
  SelectOption,
  TextInput,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { LicenseTypeSelect } from "../../components/LicenseTypeSelect/LicenseTypeSelect";
import { StorageSizeSelect } from "../../components/StorageSizeSelect/StorageSizeSelect";
import { VmDbShapeConfigInput, VmShapeConfigValue } from "../../components/VmDbShapeConfigInput/VmDbShapeConfigInput";
import { VMDbVersionSelect } from "../../components/VmDbVersionSelect/VMDbVersionSelect";
import { vmdbPluggableDatabaseUrl } from "../../constants/docConstants";
import { VmdbDbSystemDatabaseEditionEnum } from "../../gen/clients/mchub-azure-api-client-vmdb";
import {
  CharacterSets,
  defaultCharacterSet,
  defaultNationalCharacterSet,
  getSoftwareEditionDisplayName,
  getStoragePerformanceModeDisplayName,
  getStorageTypeDisplayName,
  isFlexVmdbShape,
  NationalCharacterSets,
  SOFTWARE_EDITION_TYPE,
  STORAGE_PERFORMANCE_MODE,
  STORAGE_TYPE,
  storagePerformanceModes,
  VmdbCreateFlowType,
  vmSoftwareEdition,
} from "../../helpers/resourceHelper";
import {
  alphaNumericUnderscoreRegex,
  nameCharMaxLength,
  nameCharMinLength,
  nameValidationRegex,
  pdbNameMaxLength,
  startWithLetterRegex,
} from "../../helpers/validationHelper";
import { useFeatures } from "../../hooks/useFeatures";

export const GROUP_CONFIG = "config";

export enum Fields {
  SystemName = "vmdbDbSystemDeploymentDetails.name",
  DbHomeName = "vmdbDbSystemDeploymentDetails.dbHome.name",
  StorageType = "vmdbDbSystemDeploymentDetails.dbSystemOptions.storageManagement",
  StorageSize = "vmdbDbSystemDeploymentDetails.initialDataStorageSizeInGBs",
  SystemModel = "vmdbDbSystemDeploymentDetails.shape",
  SoftwareEdition = "vmdbDbSystemDeploymentDetails.databaseEdition",
  LicenseType = "vmdbDbSystemDeploymentDetails.licenseModel",
  DbHomeVersion = "vmdbDbSystemDeploymentDetails.dbHome.dbVersion",
  DatabaseName = "vmdbDbSystemDeploymentDetails.dbHome.database.dbName",
  PdbName = "vmdbDbSystemDeploymentDetails.dbHome.database.pdbName",
  NodeCount = "vmdbDbSystemDeploymentDetails.nodeCount",
  BackupName = "vmdbDbSystemDeploymentDetails.dbHome.database.backupId",
  CharacterSet = "vmdbDbSystemDeploymentDetails.dbHome.database.characterSet",
  NationalCharacterSet = "vmdbDbSystemDeploymentDetails.dbHome.database.ncharacterSet",
  StoragePerformanceMode = "vmdbDbSystemDeploymentDetails.storageVolumePerformanceMode",
}
export interface BackupInfo {
  id: string,
  name: string,
  startDate: Date | undefined,
}

export interface ConfigTabContentProps {
  createFlowType?: VmdbCreateFlowType;
  inputWizardPanelRef: InputWizardPanelComponent;
  subscriptionId: string;
  location: string;
  instanceName: string;
  backup?: BackupInfo;
  disableUIControls?: Fields[] | undefined;
}

export enum FieldTestIds {
  SystemModel = "system-model",
  DbHomeVersion = "db-home-version",
  SystemName = "db-system-name",
  DatabaseName = "db-name",
  PdbName = "pdb-name",
  StoragePerformanceMode = "performance-mode",
  NodeCount = "node-count",
  NodeCountOne = "node-count-one",
  NodeCountTwo = "node-count-two",
  SoftwareEdition = "software-edition",
  LicenseType = "license-select",
  StorageSize = "storage-size",
  DbHomeName = "db-home-name",
  BackupName = "db-backup",
  CharacterSet = "character-set",
  NationalCharacterSet = "national-character-set",
}

const dbSystemNameSuffix = "_dbsystem";
const dbHomeNameSuffix = "_dbhome";

export const getDefaultSystemName = (instanceName: string): string => (
  instanceName ? `${instanceName}${dbSystemNameSuffix}` : ""
);
export const getDefaultDbHomeName = (instanceName: string): string => (
  instanceName ? `${instanceName}${dbHomeNameSuffix}` : ""
);

export const defaultNodeCount = "1";

enum NODE_COUNT {
  ONE = "1",
  TWO = "2",
}

const characterSetOptions = CharacterSets.map(set => ({
  id: set,
  text: set,
}));

const natioanlCharacterSetOptions = NationalCharacterSets.map(set => ({
  id: set,
  text: set,
}));

const nodeCountOptions = (): RadioGroupOption[] => ([
  {
    id: NODE_COUNT.ONE,
    text: NODE_COUNT.ONE,
    testId: FieldTestIds.NodeCountOne,
  },
  {
    id: NODE_COUNT.TWO,
    text: NODE_COUNT.TWO,
    testId: FieldTestIds.NodeCountTwo,
  },
] as unknown as RadioGroupOption[]);

const storageTypeOptions = (): SelectOption[] => ([
  {
    id: STORAGE_TYPE.ASM,
    text: getStorageTypeDisplayName(STORAGE_TYPE.ASM),
  },
  {
    id: STORAGE_TYPE.LVM,
    text: getStorageTypeDisplayName(STORAGE_TYPE.LVM),
  },
]);

export const ConfigTabContent = ({
  createFlowType,
  inputWizardPanelRef,
  subscriptionId,
  location,
  instanceName,
  backup,
  disableUIControls,
}: ConfigTabContentProps): JSX.Element => {
  const [configValue, setConfigValue] = React.useState<VmShapeConfigValue | undefined>();
  const [isPdbSupported, setIsPdbSupported] = React.useState<boolean | undefined>(true);
  const [nodeCount, setNodeCount] = React.useState(defaultNodeCount);

  const [softwareEdition, setSoftwareEdition] = React.useState<VmdbDbSystemDatabaseEditionEnum>(
    SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION_HIGH_PERFORMANCE,
  );

  const form: FormState = React.useContext(FormContext);
  const { enableVmdbStoragePerformanceMode, enableVmdbStorageManagementType } = useFeatures();
  const [isFlex, setIsFlex] = React.useState<boolean>(false);
  const [storagePerformanceModeKey, setStoragePerformanceModeKey] = React.useState(uniqueGUID());
  const [
    storagePerformanceMode,
    setStoragePerformanceMode,
  ] = React.useState<STORAGE_PERFORMANCE_MODE>(STORAGE_PERFORMANCE_MODE.BALANCED);
  const storagePerformanceModeOptions = storagePerformanceModes.map(mode => ({
    id: mode.id,
    text: getStoragePerformanceModeDisplayName(mode.id),
  }));

  const [nodeKey, setNodeKey] = React.useState(uniqueGUID());
  const [instanceNameKey, setInstanceNameKey] = React.useState<string>(uniqueGUID());
  const [systemName, setSystemName] = React.useState<string>();
  const [systemNameKey, setSystemNameKey] = React.useState<string>(uniqueGUID());
  const [dbHomeNameKey, setDbHomeNameKey] = React.useState<string>(uniqueGUID());
  const [storageType, setStorageType] = React.useState<STORAGE_TYPE>(STORAGE_TYPE.ASM);
  const [availableStorageKey, setAvailableStorageKey] = React.useState<string>(uniqueGUID());
  const [defaultAvailableStorage, setDefaultAvailableStorage] = React.useState<string>();

  React.useEffect(() => {
    setInstanceNameKey(uniqueGUID());
    setDbHomeNameKey(uniqueGUID());
  }, [instanceName]);

  React.useEffect(() => {
    if (!systemName) {
      setSystemNameKey(uniqueGUID());
    }
  }, [instanceNameKey, systemName]);

  const onError = (): void => inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.general());
  const onMissingDependencies = (): void => inputWizardPanelRef.showErrorDialog(
    Messages.createCommon.dependencyWarnings.basicsIncomplete.message(),
    Messages.createCommon.dependencyWarnings.basicsIncomplete.title(),
  );

  const onMissingVmShapeDependencies = (): void => inputWizardPanelRef.showErrorDialog(
    Messages.createCommon.dependencyWarnings.vmShapeNotSelected.message(),
    Messages.createCommon.dependencyWarnings.vmShapeNotSelected.title(),
  );

  const softwareEditionOptions = vmSoftwareEdition.map(edition => ({
    id: edition.id,
    text: getSoftwareEditionDisplayName(edition.id),
  }));

  const shouldRestrictNodeCount = (minCore: number, maxCore: number): boolean => minCore === 1 && maxCore === 1;

  const onShapeChange = (value: VmShapeConfigValue): void => {
    const shapeModel = value.shape;
    setConfigValue({
      shape: value.shape,
      cpuCoreCount: value.cpuCoreCount,
    });

    if (shouldRestrictNodeCount(shapeModel.minimumCoreCount || 0, shapeModel.availableCoreCount)) {
      setNodeCount(NODE_COUNT.ONE);
      setNodeKey(uniqueGUID());
    }

    if (isFlexVmdbShape(shapeModel)) {
      setIsFlex(true);
      setStoragePerformanceMode(STORAGE_PERFORMANCE_MODE.HIGH_PERFORMANCE);
    } else {
      setIsFlex(false);
      setStoragePerformanceMode(STORAGE_PERFORMANCE_MODE.BALANCED);
    }
    setStoragePerformanceModeKey(uniqueGUID());
  };

  const onNodeCountChange = (nodeCountId: string): void => {
    setNodeCount(nodeCountId);

    if (nodeCountId === NODE_COUNT.TWO) {
      setSoftwareEdition(SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION_EXTREME_PERFORMANCE);
    }
  };

  React.useEffect(() => {
    if (NODE_COUNT.TWO === nodeCount) {
      setStorageType(STORAGE_TYPE.ASM);
    }
  }, [nodeCount]);

  React.useEffect(() => {
    setDefaultAvailableStorage(undefined);
    form.setValue(undefined, Fields.StorageSize, GROUP_CONFIG);
    setAvailableStorageKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageType, configValue]);

  return (
    <>
      <FieldSet
        header={Messages.createVmDb.configurationTab.sectionTitles.databaseSystemDetails()}
      >
        <TextInput
          key={systemNameKey}
          fieldName={Fields.SystemName}
          testId={FieldTestIds.SystemName}
          label={Messages.labels.name()}
          placeholder={getDefaultSystemName(instanceName)}
          onChange={value => setSystemName(value)}
          validator={(value: string | undefined) => {
            const errors: string[] = [];
            if (value && (value.length < nameCharMinLength || value.length > nameCharMaxLength)) {
              errors.push(Messages.validation.nameChars(nameCharMinLength.toString(), nameCharMaxLength.toString()));
            }
            if (value && !nameValidationRegex.test(value)) {
              errors.push(Messages.validation.nameValidation());
            }
            return errors.length > 0 ? errors : undefined;
          }}
        />
        <VmDbShapeConfigInput
          required
          testId={FieldTestIds.SystemModel}
          subscriptionId={subscriptionId}
          location={location}
          fieldName={Fields.SystemModel}
          label={Messages.labels.shapeSelection()}
          tooltip={Messages.hints.selectVMShape()}
          onChange={onShapeChange}
          onError={onError}
          onMissingDependencies={onMissingDependencies}
        />
        <RadioGroup
          required
          key={nodeKey}
          fieldName={Fields.NodeCount}
          testId={FieldTestIds.NodeCount}
          label={Messages.labels.totalNodeCount()}
          options={nodeCountOptions()}
          disabled={shouldRestrictNodeCount(
            configValue?.shape.minimumCoreCount || 0,
            configValue?.shape.availableCoreCount || 0,
          )}
          defaultValue={nodeCount}
          onChange={onNodeCountChange}
        />
        {enableVmdbStorageManagementType && (
          <Select
            required
            key={storageType}
            fieldName={Fields.StorageType}
            label={Messages.labels.StorageManagementSoftware()}
            options={storageTypeOptions()}
            defaultValue={[storageType]}
            hideSearchBox
            onChange={(sType: string) => {
              setStorageType(sType as STORAGE_TYPE);
            }}
            disabled={NODE_COUNT.TWO === nodeCount}
          />
        )}
        {enableVmdbStoragePerformanceMode && (
          <Select
            required
            key={storagePerformanceModeKey}
            testId={FieldTestIds.StoragePerformanceMode}
            fieldName={Fields.StoragePerformanceMode}
            label={Messages.labels.storageVolumePerformance()}
            defaultValue={[storagePerformanceMode]}
            options={storagePerformanceModeOptions}
            disabled={!isFlex}
            hideSearchBox
          />
        )}
        <StorageSizeSelect
          required
          key={availableStorageKey}
          location={location}
          subscriptionId={subscriptionId}
          shapeType={configValue?.shape.shapeType}
          storageType={storageType}
          defaultValue={defaultAvailableStorage}
          fieldName={Fields.StorageSize}
          testId={FieldTestIds.StorageSize}
          label={Messages.labels.availableDataStorageGB()}
          tooltip={Messages.hints.selectAvailableDataStorage()}
          onMissingDependencies={onMissingVmShapeDependencies}
        />
        <Select
          required
          testId={FieldTestIds.SoftwareEdition}
          key={softwareEdition}
          fieldName={Fields.SoftwareEdition}
          label={Messages.labels.softwareEdition()}
          disabled={nodeCount === NODE_COUNT.TWO}
          defaultValue={softwareEdition ? [softwareEdition] : undefined}
          options={softwareEditionOptions}
          onChange={
            (edition: string) => {
              setSoftwareEdition(edition as VmdbDbSystemDatabaseEditionEnum);
            }
          }
          hideSearchBox
        />
        <LicenseTypeSelect
          fieldName={Fields.LicenseType}
          testId={FieldTestIds.LicenseType}
        />
      </FieldSet>
      <FieldSet
        header={Messages.createVmDb.configurationTab.sectionTitles.databaseHomeDetails()}
      >
        <TextInput
          key={dbHomeNameKey}
          disabled
          fieldName={Fields.DbHomeName}
          testId={FieldTestIds.DbHomeName}
          label={Messages.labels.name()}
          defaultValue={getDefaultDbHomeName(instanceName)}
        />
        {createFlowType !== VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP && (
          <VMDbVersionSelect
            required
            shape={configValue?.shape.name}
            testId={FieldTestIds.DbHomeVersion}
            subscriptionId={subscriptionId}
            location={location}
            fieldName={Fields.DbHomeVersion}
            label={Messages.common.version()}
            onError={onError}
            onMissingDependencies={onMissingDependencies}
            isPdbSupported={(supportsPdb: boolean | undefined) => setIsPdbSupported(supportsPdb || undefined)}
          />
        )}
      </FieldSet>
      <FieldSet
        header={Messages.createVmDb.configurationTab.sectionTitles.databaseDetails()}
      >
        {createFlowType === VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP && (
          <TextInput
            fieldName={Fields.BackupName}
            testId={FieldTestIds.BackupName}
            label={Messages.labels.databaseBackupName()}
            disabled={disableUIControls?.find(ele => ele === Fields.BackupName) !== undefined}
            defaultValue={`${backup?.name} (${backup?.startDate})`}
          />
        )}
        <TextInput
          key={instanceNameKey}
          disabled
          fieldName={Fields.DatabaseName}
          label={Messages.labels.databaseName()}
          testId={FieldTestIds.DatabaseName}
          tooltip={Messages.labels.databaseName()}
          defaultValue={instanceName}
        />
        {createFlowType !== VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP && isPdbSupported && (
          <TextInput
            required
            fieldName={Fields.PdbName}
            testId={FieldTestIds.PdbName}
            label={Messages.labels.pdbName()}
            tooltip={
              FormattedString(
                { inputText: Messages.hints.tooltipPluggableDatabase(vmdbPluggableDatabaseUrl) },
              ) as unknown as string
            }
            validator={(value: string | undefined) => {
              const errors: string[] = [];
              if (!value || !startWithLetterRegex.test(value)) {
                errors.push(Messages.validation.nameStartChar());
              }
              if (!value || value?.length > pdbNameMaxLength) {
                errors.push(Messages.validation.valueMaxLen(pdbNameMaxLength.toString()));
              }
              if (!value || !alphaNumericUnderscoreRegex.test(value)) {
                errors.push(Messages.validation.valueAlphaNumericUnderscore());
              }
              return errors.length > 0 ? errors : undefined;
            }}
          />
        )}
        <Select
          label={Messages.labels.characterSet()}
          required
          fieldName={Fields.CharacterSet}
          testId={FieldTestIds.CharacterSet}
          options={characterSetOptions}
          defaultValue={[defaultCharacterSet]}
        />
        <Select
          label={Messages.labels.nationalCharacterSet()}
          required
          fieldName={Fields.NationalCharacterSet}
          testId={FieldTestIds.NationalCharacterSet}
          options={natioanlCharacterSetOptions}
          defaultValue={[defaultNationalCharacterSet]}
        />
      </FieldSet>
    </>
  );
};
