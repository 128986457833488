import { NotificationRequest, NotificationType, useNotificationRequest } from "o4a-react";
import * as React from "react";
import * as Messages from "../codegen/Messages";
import {
  InactivityThresholdProp,
  LanguageStorageProp,
  MenuBehaviorStorageProp,
  PreferredLocationPerSubscriptionStorageProp,
  RegionStorageProp,
  SettingsVersion,
  SettingsVersionStorageProp,
  SubscriptionStorageProp,
} from "../constants/pluginConstants";
import { MenuStyleMode } from "../constants/uiConstants";
import {
  deleteLocalStorageSettingByUser,
  getLocalStorageSettingByUser,
  setLocalStorageSettingByUser,
} from "../helpers/localStorageHelper";
import { DefaultInactivityThresholdId, InactivityThreshold } from "../helpers/settingsHelper";
import { useAppAuthContext } from "../hooks/useAppAuthContext";
import { useSubscriptions } from "../hooks/useSubscriptions";

export enum SettingType {
  PREFERRED_LOCATION_PER_SUBSCRIPTION = "PREFERRED_LOCATION_PER_SUBSCRIPTION",
  PREFERRED_SUBSCRIPTION = "PREFERRED_SUBSCRIPTION",
  LOCALE = "LOCALE",
  MENU_MODE = "MENU_MODE",
  INACTIVITY_THRESHOLD = "INACTIVITY_THRESHOLD",
}

export interface Settings {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSettingValue: (type: SettingType, value: any) => void;
  /**
   * preferred location for each subscription
   * Key is the subscription id
   * Value is the preferred location id for the given subscription id
   * If the value is undefined but the key is set that is an API error
   */
  preferredLocationPerSubscription: { [subscriptionId: string]: string | undefined };
  preferredSubscription: string;
  locale: string;
  menuMode: MenuStyleMode;
  inactivityThreshold: InactivityThreshold;
}

export const SettingsContext = React.createContext<Settings>({} as Settings);

export interface SettingsProviderProps {
  children: React.ReactNode;
}

type InitializedSettings = Pick<Settings, "preferredSubscription" | "preferredLocationPerSubscription">;

// Initialize settings from local storage
const initSettingsForUser = (username: string): InitializedSettings => {
  let preferredLocationPerSubscription: { [subscriptionId: string]: string | undefined } = {};

  try {
    const preferredLocationPerSubscriptionStr = getLocalStorageSettingByUser(
      username,
      PreferredLocationPerSubscriptionStorageProp,
    );

    if (preferredLocationPerSubscriptionStr) {
      preferredLocationPerSubscription = JSON.parse(preferredLocationPerSubscriptionStr);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Failed to retrieve subscription locations from local storage", e);
  }

  const preferredSubscription = getLocalStorageSettingByUser(username, SubscriptionStorageProp);

  return {
    preferredLocationPerSubscription,
    preferredSubscription,
  } as InitializedSettings;
};

export const SettingsProvider = ({ children }: SettingsProviderProps): JSX.Element => {
  const { submit: submitNotificationRequest } = useNotificationRequest();
  const { username } = useAppAuthContext();
  const { loading: subscriptionsLoading, error: subscriptionsError, subscriptions } = useSubscriptions();

  React.useEffect(() => {
    if (!subscriptionsLoading && subscriptionsError) {
      submitNotificationRequest({
        type: NotificationType.FAILURE,
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadSubscription(),
        apiError: subscriptionsError.body.message,
      } as NotificationRequest);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionsLoading, subscriptionsError]);

  // All modifications to local storage happen here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSettingValue = (type: SettingType, value: any): void => {
    const settingsVersion = getLocalStorageSettingByUser(username, SettingsVersionStorageProp);

    // Perform migration(s)
    if (settingsVersion !== SettingsVersion) {
      // If local storage has deprecated preferred location
      if (!settingsVersion && getLocalStorageSettingByUser(username, RegionStorageProp)) {
        deleteLocalStorageSettingByUser(username, RegionStorageProp);
      }
      setLocalStorageSettingByUser(username, SettingsVersionStorageProp, SettingsVersion);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let settingValue: any;
    switch (type) {
      case SettingType.PREFERRED_SUBSCRIPTION:
        settingValue = {
          preferredSubscription: value,
          preferredLocation: settings.preferredLocationPerSubscription[value],
        };
        setLocalStorageSettingByUser(username, SubscriptionStorageProp, value);
        break;
      case SettingType.LOCALE:
        settingValue = { locale: value };
        setLocalStorageSettingByUser(username, LanguageStorageProp, value);
        break;
      case SettingType.MENU_MODE:
        settingValue = { menuMode: value };
        setLocalStorageSettingByUser(username, MenuBehaviorStorageProp, value);
        break;
      case SettingType.PREFERRED_LOCATION_PER_SUBSCRIPTION:
        settingValue = {
          preferredLocationPerSubscription: value,
          preferredLocation: value[settings.preferredSubscription],
        };
        setLocalStorageSettingByUser(username, PreferredLocationPerSubscriptionStorageProp, JSON.stringify(value));
        break;
      case SettingType.INACTIVITY_THRESHOLD:
        settingValue = { inactivityThreshold: value };
        setLocalStorageSettingByUser(username, InactivityThresholdProp, value);
        break;
      default:
        break;
    }

    setSettings(previousSettings => ({
      ...previousSettings,
      ...settingValue,
    }));
  };

  React.useEffect(() => {
    if (!subscriptionsLoading) {
      const preferredLocationPerSubscription = { ...settings?.preferredLocationPerSubscription };

      // add new subscriptions and preferred location for each subscription
      subscriptions?.forEach(subscription => {
        const locationId = preferredLocationPerSubscription[subscription.id];

        // If the preferred location for the subscription does not exist; then set it to the primaryLocation
        if (!locationId || !subscription.locations?.includes(locationId)) {
          // if the primaryLocation is undefined, it is an API error
          preferredLocationPerSubscription[subscription.id] = subscription.primaryLocation;
        }
      });

      // remove any subscriptions that no longer exist
      // May not be necessary as users may not be able to unlink an Azure region from an OCI tenant
      Object.keys(preferredLocationPerSubscription).forEach(subscriptionId => {
        const currentSubscription = subscriptions?.find(sub => sub.id === subscriptionId);

        if (!currentSubscription) {
          delete preferredLocationPerSubscription[subscriptionId];
        }
      });

      // If the preferred subscription is not set then use the first subscription
      const preferredSubscription = !settings.preferredSubscription && subscriptions?.length
        ? subscriptions[0].id
        : settings.preferredSubscription;

      // Update preferredLocations if they have changed
      if (
        JSON.stringify(settings?.preferredLocationPerSubscription) !== JSON.stringify(preferredLocationPerSubscription)
        || settings.preferredSubscription !== preferredSubscription
      ) {
        setSettings(previousSettings => ({
          ...previousSettings,
          preferredSubscription,
          preferredLocationPerSubscription,
          preferredLocation: preferredLocationPerSubscription[preferredSubscription] || "",
        }));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionsLoading, JSON.stringify(subscriptions)]);

  const [settings, setSettings] = React.useState<Settings>({
    setSettingValue,
    ...initSettingsForUser(username),
    locale: getLocalStorageSettingByUser(username, LanguageStorageProp) || "en",
    menuMode: getLocalStorageSettingByUser(username, MenuBehaviorStorageProp) || MenuStyleMode.FlyOut,
    inactivityThreshold: getLocalStorageSettingByUser(username, InactivityThresholdProp)
      || { id: DefaultInactivityThresholdId },
  } as Settings);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const settingsValue = React.useMemo(() => ({ ...settings }), [JSON.stringify(settings)]);

  return (
    <SettingsContext.Provider value={settingsValue}>
      {children}
    </SettingsContext.Provider>
  );
};
