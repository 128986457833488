import { ActionType, Listing, ListingColumn, ListingDisplayNameLink, SortDirections } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  DetailsPanelId,
  ORACLE_ADBS_PROVIDER_PATH,
  ORACLE_DB_PROVIDER_PATH,
  ORACLE_MYSQL_PROVIDER_PATH,
  ORACLE_VMDB_PROVIDER_PATH,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, SvgIconIds } from "../../constants/uiConstants";
import { AzureSubscriptionSummary } from "../../gen/clients/mchub-azure-api-client";
import { IdResourceType, parseId } from "../../helpers/idHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useRowCount } from "../../hooks/useRowCount";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { getAzureLocationName } from "../../utils";

interface AzureSubscriptionSummaryMap {
  [key: string]: AzureSubscriptionSummary;
}

export interface AttachedDatabase {
  id: string;
  displayName: string;
  subscriptionName: string;
  subscriptionId: string;
  resourceGroupName: string;
  location: string;
  provider: string;
  resourceType: string|undefined;
}
export interface AttachedDbListProps {
  attachedDbIds?: string[];
  location: string;
  refresh: () => void;
}

export enum ActionIds {
  Refresh = "refresh",
}

export enum ActionBarTestIds {
  Refresh = "refresh",
}

export enum ColumnIds {
  DisplayName = "displayName",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  DatabaseType = "provider",
}

export enum ColumnTestIds {
  DisplayName = "displayName",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  DatabaseType = "provider",
}

const getNameLink = (value: AttachedDatabase): JSX.Element => {
  let icon;
  let panelPath;
  let pageKey;

  switch (value.provider) {
    case ORACLE_DB_PROVIDER_PATH:
      switch (value.resourceType as IdResourceType) {
        // ExaSystem will need to be added once it's accessible and also add PageId in pluginConstants
        case IdResourceType.DATABASES:
          icon = SvgIconIds.exadataSvg;
          panelPath = PageRegistrationConfig[PageId.EXADB_CDB_DETAILS][0].panelPath;
          pageKey = PageRegistrationConfig[PageId.EXADB_CDB_DETAILS][0].key;
          break;
        case IdResourceType.PLUGGABLE_DATABASES:
          icon = SvgIconIds.exadataSvg;
          panelPath = PageRegistrationConfig[PageId.EXADB_PDB_DETAILS][0].panelPath;
          pageKey = PageRegistrationConfig[PageId.EXADB_PDB_DETAILS][0].key;
          break;
        case IdResourceType.CLOUD_EXADATA_INFRAS:
          icon = SvgIconIds.infraSvg;
          panelPath = PageRegistrationConfig[PageId.EXAINFRA_DETAILS][0].panelPath;
          pageKey = PageRegistrationConfig[PageId.EXAINFRA_DETAILS][0].key;
          break;
        case IdResourceType.CLOUD_VM_CLUSTERS:
          icon = SvgIconIds.vmClusterSvg;
          panelPath = PageRegistrationConfig[PageId.VMCLUSTER_DETAILS][0].panelPath;
          pageKey = PageRegistrationConfig[PageId.VMCLUSTER_DETAILS][0].key;
          break;
        default:
          icon = "";
          panelPath = "";
          pageKey = "";
          break;
      }
      break;
    case ORACLE_VMDB_PROVIDER_PATH:
      switch (value.resourceType as IdResourceType) {
        case IdResourceType.PLUGGABLE_DATABASES:
          icon = SvgIconIds.baseDbSvg;
          panelPath = PageRegistrationConfig[PageId.VMDB_PDB_DETAILS][0].panelPath;
          pageKey = PageRegistrationConfig[PageId.VMDB_PDB_DETAILS][0].key;
          break;
        case IdResourceType.DATABASES:
          icon = SvgIconIds.baseDbSvg;
          panelPath = PageRegistrationConfig[PageId.VMDB_CDB_DETAILS][0].panelPath;
          pageKey = PageRegistrationConfig[PageId.VMDB_CDB_DETAILS][0].key;
          break;
        case IdResourceType.DB_SYSTEMS:
          icon = SvgIconIds.dbSystemSvg;
          panelPath = PageRegistrationConfig[PageId.VMDB_SYSTEM_DETAILS][0].panelPath;
          pageKey = PageRegistrationConfig[PageId.VMDB_SYSTEM_DETAILS][0].key;
          break;
        default:
          icon = "";
          panelPath = "";
          pageKey = "";
          break;
      }
      break;
    case ORACLE_ADBS_PROVIDER_PATH:
      icon = SvgIconIds.adbSvg;
      panelPath = PageRegistrationConfig[PageId.ADBS_DETAILS][0].panelPath;
      pageKey = PageRegistrationConfig[PageId.ADBS_DETAILS][0].key;
      break;
    case ORACLE_MYSQL_PROVIDER_PATH:
      icon = SvgIconIds.mysqlSvg;
      panelPath = PageRegistrationConfig[PageId.MYSQL_DETAILS][0].panelPath;
      pageKey = PageRegistrationConfig[PageId.MYSQL_DETAILS][0].key;
      break;
    default:
      icon = "";
      panelPath = "";
      pageKey = "";
      break;
  }

  if (!panelPath && pageKey) return <span>{value.displayName}</span>;

  return (
    <ListingDisplayNameLink
      displayName={value.displayName}
      navigation={{
        to: `${RESOURCE_ROUTE}/${value.id}/${panelPath}?location=${value.location}`,
        pageKey,
      }}
      iconName={icon}
    />
  );
};

export const AttachedDbList = (
  { attachedDbIds, location: mcvcnLocation, refresh }: AttachedDbListProps,
): JSX.Element => {
  const { trackActionClick } = useAnalytics();

  const { locale } = React.useContext<Settings>(SettingsContext);

  const { subListingPageItemCount } = useRowCount();

  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();

  const items = React.useMemo(() => {
    const attachedDbs: AttachedDatabase[] = [];
    if (attachedDbIds && subscriptions && subscriptions.length > 0) {
      const subscriptionsMap = subscriptions.reduce((
        map: AzureSubscriptionSummaryMap,
        subscription: AzureSubscriptionSummary,
      ) => {
        map[subscription.id] = subscription;
        return map;
      }, {});
      attachedDbIds.forEach(attachedDbId => {
        const { subscriptionId, resourceGroup, resourceName, provider, resourceType } = parseId(attachedDbId);
        const subscriptionName = subscriptionsMap && subscriptionsMap[subscriptionId]?.name;
        attachedDbs.push({
          id: attachedDbId,
          displayName: resourceName,
          subscriptionName,
          subscriptionId,
          resourceGroupName: resourceGroup,
          location: mcvcnLocation,
          provider,
          resourceType,
        });
      });
    }
    return attachedDbs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(attachedDbIds), JSON.stringify(subscriptions)]);

  const getLocation = (location: string): string => getAzureLocationName(location);

  const getDatabaseType = (provider: string): JSX.Element => <span>{getDatabaseTypeString(provider)}</span>;

  const getDatabaseTypeString = (provider: string): string => {
    switch (provider) {
      case ORACLE_DB_PROVIDER_PATH:
        return Messages.serviceProviders.exadataDedicated();
      case ORACLE_VMDB_PROVIDER_PATH:
        return Messages.serviceProviders.vmDatabase();
      case ORACLE_ADBS_PROVIDER_PATH:
        return Messages.serviceProviders.adbShared();
      case ORACLE_MYSQL_PROVIDER_PATH:
        return Messages.serviceProviders.mySqlHeatwave();
      default:
        return provider;
    }
  };

  const sortTypes = (aType: AttachedDatabase, bType: AttachedDatabase): 0 | -1 | 1 => {
    const aTypeStr = getDatabaseTypeString(aType.provider);
    const bTypeStr = getDatabaseTypeString(bType.provider);
    const comparaison = aTypeStr.localeCompare(bTypeStr, locale);
    return comparaison > 0 ? 1 : comparaison < 0 ? -1 : 0;
  };

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.DisplayName,
      testId: ColumnTestIds.DisplayName,
      name: Messages.common.name(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getNameLink(value),
    },
    {
      itemProp: ColumnIds.SubscriptionName,
      testId: ColumnTestIds.SubscriptionName,
      name: Messages.labels.subscription(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, max-len
      onRenderItems: value => <AzureSubscriptionLink resourceId={value.id} subscriptionName={value.subscriptionName} hideClipboard />,
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value.location),
    },
    {
      itemProp: ColumnIds.DatabaseType,
      testId: ColumnTestIds.DatabaseType,
      name: Messages.labels.type(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: sortTypes,
      isResizable: true,
      onRenderItems: value => getDatabaseType(value.provider),
    },
  ];

  const attachedDbActions: ActionType[] = [
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.MCVCN_DETAILS, DetailsPanelId.ATTACHED_DATABASES);
        refresh();
      },
    },
  ];

  return (
    <Listing
      testId={ListingTestIds.McvcnAttachedDbs}
      itemsPerPage={subListingPageItemCount}
      items={items || []}
      emptyList={{ title: Messages.common.noResults() }}
      isLoading={subscriptionLoading}
      listColumns={columns}
      actionBarItems={attachedDbActions}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.DisplayName,
      }}
    />
  );
};
