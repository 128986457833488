import {
  ActionType,
  getResourceLifecycleStatus,
  InfoBlockProps,
  InfoBlockStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  NotificationRequest,
  NotificationType,
  optimizedRetryOption,
  SelectionMode,
  stateT,
  Status,
  useNotificationRequest,
} from "o4a-react";
import * as React from "react";
import { useBulkQuery } from "savant-connector";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureVnetLink } from "../../components/AzureLinks/AzureVnetLink";
import { OciSubnetLink } from "../../components/OciLinks/OciSubnetLink";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { MultiCloudVirtualNetwork, NetworkLink } from "../../gen/clients/mchub-azure-api-client";
import { parseId } from "../../helpers/idHelper";
import { getStatusInfo, NetworkLinkStatus } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { AzureSubscriptionSummaryExt, useSubscriptions } from "../../hooks/useSubscriptions";
import { NetworkLinkAttachProps, newNetworkLinkAttach } from "../../operations/NetworkLink/NetworkLinkAttach";
import { NetworkLinkDetachProps, newNetworkLinkDetach } from "../../operations/NetworkLink/NetworkLinkDetach";
import { getOciRegion } from "../../utils";

export enum PivotItemTestIds {
  OciSubnets = "OciSubnets",
  VirtualNetworks = "VirtualNetworks"
}

export enum InfoBlockTestIds {
  nlAttachInfo = "nl-attach-hint",
  nlDetachInfo = "nl-detach-hint",
}
enum OciSubnetColumnIds {
  Id = "id",
}

enum NetworkColumnIds {
  Name = "internalVnetId",
  Id = "id",
  NLStatus = "status",
  NVAIpAddress = "nvaIpAddress",
  RouterIpAddresses = "routerIpAddresses",
}

interface VnetListItem {
  id: string | undefined,
  nvaIpAddress: string | undefined,
  routerIpAddresses: string[] | undefined,
  status: string | undefined,
  lifecycleDetails: string | undefined;
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
  internalVnetId: string | undefined,
}

export interface McvcnConfigurationDetailsProps {
  mcvcn: MultiCloudVirtualNetwork | undefined;
  location: string;
  disabled?: boolean;
  mcvcnRefresh: () => void;
}

export enum ActionIds {
  Refresh = "refresh",
  Detach = "detach",
  Attach = "attach",
}

export enum ActionBarTestIds {
  Refresh = "refresh",
  Detach = "detach",
  Attach = "attach",
}

export const McvcnConfigurationDetails = (
  { mcvcn, location, mcvcnRefresh }: McvcnConfigurationDetailsProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup } = parseId(mcvcn?.id);
  const { trackActionClick, trackActionDiscard } = useAnalytics();
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();
  const subscription = !subscriptionLoading && subscriptions
    ? subscriptions.find(s => s.id === subscriptionId)
    : undefined;
  const [selection, setSelection] = React.useState<VnetListItem[]>([]);
  const ociSubnets = mcvcn?.ociSubnetOcids?.map(ocid => ({ id: ocid })) || [];
  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<VnetListItem>>();

  const getNVAIpAddress = (value: VnetListItem): string => value?.nvaIpAddress ?? "";
  const getName = (value: VnetListItem): string => parseId(value?.internalVnetId).resourceName;
  const getRouterIpAddress = (value: VnetListItem): string => value?.routerIpAddresses?.join(", ") ?? "";

  const setSelectedItems = (selectedItems: VnetListItem[]): void => {
    setSelection(selectedItems);
  };
  const { trigger: triggerNetworkLinkDetach } = useOperation<NetworkLinkDetachProps>(newNetworkLinkDetach);
  const { trigger: triggerNetworkLinkAttach } = useOperation<NetworkLinkAttachProps>(newNetworkLinkAttach);

  const ociSubnetcolumns: ListingColumn[] = [
    {
      itemProp: OciSubnetColumnIds.Id,
      name: Messages.labels.subnet(),
      flexGrow: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <OciSubnetLink
          id={value.id}
          vcnId={mcvcn?.ociVcnOcid as string}
          subscription={subscription as AzureSubscriptionSummaryExt}
          location={mcvcn?.location as string || location}
          analytics={{ pageId: PageId.MCVCN_DETAILS, panelId: DetailsPanelId.CONFIGURATION }}
          hideClipboard
        />
      ),
    },
  ];
  const networkLinkIds = mcvcn?.networkLinkIds;
  const wait = !(mcvcn?.networkLinkIds);
  const args = wait ? [] : networkLinkIds?.map(network => ({
    networkLinkName: decodeURIComponent(parseId(network)?.resourceName || ""),
    subscriptionId: subscriptionId || "",
    resourceGroupName: resourceGroup,
    apiVersion: MultiCloudDatabaseApiVersion,
  })) || [];

  const { submit: submitNotificationRequest } = useNotificationRequest();
  const { aggregatedResults: { error, loading }, results, refresh: networkLinkRefresh } = useBulkQuery({
    wait,
    method: apiClients.withRegion(getOciRegion(location)).netLinkApi.getNetworkLink,
    options: {
      args,
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });

  const refresh = (): void => {
    mcvcnRefresh();
    networkLinkRefresh();
  };

  React.useEffect(() => {
    if (!loading && error) {
      const apiErrorsIndexes : number[] = [];
      results.forEach((result, index) => {
        if (result?.error) {
          apiErrorsIndexes.push(index);
        }
      });
      apiErrorsIndexes.forEach(apiErrorsIndex => {
        submitNotificationRequest({
          type: NotificationType.FAILURE,
          title: Messages.notifications.failure.titles.load(),
          // eslint-disable-next-line max-len
          message: Messages.notifications.failure.messages.loadNetworkLinkWithName(args?.[apiErrorsIndex].networkLinkName),
          apiError: results[apiErrorsIndex].error?.body.message,
        } as NotificationRequest);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading]);

  // Do not check for error while displaying data since no results will show even if certain calls go through
  const data = results.reduce<NetworkLink[]>((acc, result) => acc.concat(result?.response?.data || []), []);
  const items = React.useMemo(() => {
    const vnets: VnetListItem[] = [];
    if (data && data?.length > 0) {
      data.forEach(item => {
        vnets.push(
          {
            id: item?.properties?.azureAttachedNetworkIds?.[0],
            nvaIpAddress: item?.properties?.routingOptions?.customerNvaIpAddress,
            routerIpAddresses: item?.properties?.routerIpAddresses,
            status: item?.status,
            lifecycleDetails: item?.lifecycleDetails,
            internalVnetId: item?.id,
          },
        );
      });
    }
    return vnets;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);
  const getStatus = (value: VnetListItem): JSX.Element => (
    <Status
      iconPosition="right"
      label={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
          value?.lifecycleDetails,
        )
      }
      hideClipboardCopy
    />
  );

  const networkLinkActions: ActionType[] = [
    {
      key: ActionIds.Attach,
      testId: ActionBarTestIds.Attach,
      text: Messages.actions.attach(),
      icon: MonochromeIconIds.AddLink,
      disabled: items.length > 0,
      onClick: () => {
        triggerNetworkLinkAttach(
          {
            mcvcnId: mcvcn?.id as string,
            location: mcvcn?.location as string,
          },
        );
        trackActionClick(ActionIds.Attach, PageId.MCVCN_DETAILS, DetailsPanelId.CONFIGURATION);
      },
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.MCVCN_DETAILS, DetailsPanelId.CONFIGURATION);
        refresh();
      },
    },
    {
      key: ActionIds.Detach,
      testId: ActionBarTestIds.Detach,
      text: Messages.actions.detach(),
      icon: MonochromeIconIds.RemoveLink,
      disabled: !selection.length || (selection.length > 0
       && (selection[0]?.status !== NetworkLinkStatus.FAILED && selection[0]?.status !== NetworkLinkStatus.CREATED)),
      title: Messages.labels.detachVirtualNetwork(),
      description:
      // eslint-disable-next-line max-len
      Messages.hints.detachVirtualNetworkConfirmation(selection[0] ? parseId(selection[0].id).resourceName as string : ""),
      onClick: () => trackActionClick(ActionIds.Detach, PageId.MCVCN_DETAILS, DetailsPanelId.CONFIGURATION),
      onConfirm: () => {
        triggerNetworkLinkDetach({
          networkLinkId: selection[0].internalVnetId,
          virtualNetworkName: parseId(selection[0].id).resourceName,
          location: mcvcn?.location as string,
          onExecute: () => {
            refresh();
            listingComponentRef?.resetTableSelection();
          },
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Detach, PageId.MCVCN_DETAILS, DetailsPanelId.CONFIGURATION),
    },
  ];

  const vnetInfoBlocks: InfoBlockProps[] = [];
  if (items.length > 0) {
    vnetInfoBlocks.push({
      testId: InfoBlockTestIds.nlAttachInfo,
      message: Messages.hints.createVnet(),
      messageType: InfoBlockStatus.INFO,
    });
  }
  if (selection.length > 0
    && (selection[0]?.status !== NetworkLinkStatus.FAILED && selection[0]?.status !== NetworkLinkStatus.CREATED)) {
    vnetInfoBlocks.push({
      testId: InfoBlockTestIds.nlDetachInfo,
      message: Messages.hints.vnetDeleteInfo(),
      messageType: InfoBlockStatus.INFO,
    });
  }

  const networkcolumns: ListingColumn[] = [
    {
      itemProp: NetworkColumnIds.Name,
      name: Messages.labels.name(),
      flexGrow: 1,
      onRenderItems: value => (getName(value)),
    },
    {
      itemProp: NetworkColumnIds.NLStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      onRenderItems: value => (getStatus(value)),
    },
    {
      itemProp: NetworkColumnIds.Id,
      name: Messages.labels.virtualNetwork(),
      flexGrow: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <AzureVnetLink vnetId={value.id as string || ""} hideClipboard />
      ),
    },
    {
      itemProp: NetworkColumnIds.NVAIpAddress,
      name: Messages.labels.networkVirtualAppliance(),
      flexGrow: 1,
      onRenderItems: value => (
        getNVAIpAddress(value)
      ),

    },
    {
      itemProp: NetworkColumnIds.RouterIpAddresses,
      name: Messages.labels.routerIpAddress(),
      flexGrow: 1,
      onRenderItems: value => (
        getRouterIpAddress(value)
      ),
    },
  ];

  return (
    <Stack style={{ height: "100%" }}>
      <Pivot
        style={{ height: "100%" }}
        styles={{ itemContainer: { height: "calc(100% - 44px)" } }}
        overflowBehavior="menu"
        overflowAriaLabel={Messages.ariaLabel.more()}
      >
        <PivotItem
          headerButtonProps={{ "data-test-id": PivotItemTestIds.VirtualNetworks }}
          headerText={Messages.labels.virtualNetworksAttachments()}
          style={{ height: "100%" }}
        >
          <Listing
            items={items || []}
            testId={ListingTestIds.NetworkLinkList}
            listingComponentRef={setListingComponentRef}
            actionBarItems={networkLinkActions}
            listColumns={networkcolumns}
            emptyList={{ title: Messages.common.noResults() }}
            selectionMode={SelectionMode.single}
            selectedItems={setSelectedItems}
            infoBlocks={vnetInfoBlocks}
            isLoading={loading && networkLinkIds && networkLinkIds.length > 0}
          />

        </PivotItem>

        <PivotItem
          headerButtonProps={{ "data-test-id": PivotItemTestIds.OciSubnets }}
          headerText={Messages.detailMcvcn.configuration.sectionTitles.ociSubnets()}
          style={{ height: "100%" }}
        >
          <Listing
            items={ociSubnets}
            emptyList={{ title: Messages.common.noResults() }}
            listColumns={ociSubnetcolumns}
          />

        </PivotItem>

      </Pivot>
    </Stack>

  );
};
