import { CalloutComponent, FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  AddCapacityVmCluster,
  Fields as AddCapacityFields,
} from "../../components/AddCapacityVmCluster/AddCapacityVmCluster";
import { CloudVmClusterAvailableCapacity } from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMCLUSTER_EDIT_POLL_DELAY,
  VMCLUSTER_EDIT_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface ExaVmClusterAddCapacityProps extends OperationActionProps {
    vmClusterId: string | undefined;
    vmClusterCapacity: CloudVmClusterAvailableCapacity | undefined
}
const ExaVmClusterAddCapacity = (
  { targetId, location, vmClusterId, vmClusterCapacity, onCancel, onExecute }: ExaVmClusterAddCapacityProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(vmClusterId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");
  const { invokeCall: invokeVmClusterCapacityUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.addCloudVmClusterAvailableCapacity },
  );
  const [addCapacityCalloutRef, setAddCapacityCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);
  const onAddCapacity = (formValues: FormValues): void => {
    const newComputeCount = getValue<string>(formValues, AddCapacityFields.NodeCount, InputFormGroup);
    const computeCount = newComputeCount ? parseInt(newComputeCount, 10) : 0;
    const newStorageSize = getValue<string>(formValues, AddCapacityFields.StorageSize, InputFormGroup);
    const storageSize = newStorageSize ? parseInt(newStorageSize, 10) : 0;

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: () => {
        addCapacityCalloutRef?.allowResubmit();
      },
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.addCapacityVMCluster(),
          message: Messages.notifications.inProgress.messages.addCapacityVMCluster(resName),
        },
        success: {
          title: Messages.notifications.success.titles.addCapacityVMCluster(),
          message: Messages.notifications.success.messages.addCapacityVMCluster(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.addCapacityVMCluster(),
          message: Messages.notifications.failure.messages.addCapacityVMCluster(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMCLUSTER_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            cloudVmClusterName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMCLUSTER_EDIT_CHECK,
          delay: VMCLUSTER_EDIT_POLL_DELAY,
          interval: VMCLUSTER_EDIT_POLL_INTERVAL,
        },
      },
    };

    if (computeCount || storageSize) {
      invokeVmClusterCapacityUpdate({
        subscriptionId,
        resourceGroupName: resGroup,
        cloudVmClusterName: resName,
        addCloudVmClusterAvailableCapacityDetails: { computeCount, storageSizeInGBs: storageSize },
      }, invokeOptions);
    }
  };
  return (
    <AddCapacityVmCluster
      componentRef={setAddCapacityCalloutRef}
      targetId={targetId}
      onSubmit={(formValues: FormValues) => {
        onAddCapacity(formValues);
      }}
      availableCapacity={vmClusterCapacity}
      onClose={onCancel}
      title={Messages.createNewPanels.addCapacity.title()}
      primaryButtonText={Messages.actions.addCapacity()}
    />
  );
};

export const newExaVmClusterAddCapacity = (
  props: ExaVmClusterAddCapacityProps,
): JSX.Element => (<ExaVmClusterAddCapacity {...props} />);
