import { FieldSet } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { SshKeyPairInput, SshPublicKeySource } from "../../components/SshKeyPair/SshKeyPairInput";

export const GROUP_SECURITY = "security";

export const enum Fields {
  sshKeyPair = "cloudVmClusterDetails.sshPublicKeys",
}

export const SecurityTabContent = (): JSX.Element => (

  <FieldSet
    header={Messages.createExaDb.securityTab.sectionTitles.vmClusterAuthentication()}
    description={Messages.createExaDb.securityTab.sectionDescriptions.vmClusterAuthentication()}
  >
    <SshKeyPairInput
      defaultValue={SshPublicKeySource.Generate}
      fieldName={Fields.sshKeyPair}
      groupName={GROUP_SECURITY}
    />
  </FieldSet>

);
