import { BookmarkablePage, FullPagePanel, useNavigation } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FAVORITE_RESOURCES_ROUTE, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { FavoriteList } from "./FavoriteList";

const registrationIds = PageRegistrationConfig[PageId.FAVORITE_RESOURCES].map(config => config.key);

export const FavoriteListPage = (): JSX.Element => {
  const { back, navigateToSelf } = useNavigation(ConsoleContext);

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(FAVORITE_RESOURCES_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.listFavorite.titles.short()}
    >
      <FullPagePanel
        title={Messages.listFavorite.titles.long()}
        onClose={back}
        isOpen
      >
        <FavoriteList />
      </FullPagePanel>
    </BookmarkablePage>
  );
};
