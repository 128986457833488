import { CalloutComponent, FormValues, getValue, InputFormGroup } from "o4a-react";
import React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  ExadataInfraScalePanel,
  ExaInfraScaleConfig,
  Fields as InfraScaleFields,
} from "../../components/DbSystemShapeSelect/ExadataInfraScalePanel";
import { CalloutTestIds } from "../../constants/uiConstants";
import { CloudExadataInfrastructure } from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import { MutationCallOptions, useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXAINFRA_EDIT_POLL_DELAY,
  EXAINFRA_EDIT_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface ExaInfraScaleProps extends OperationActionProps {
  exaInfraId: string;
  exaInfraScaleConfig: ExaInfraScaleConfig;
}

const ExaInfraScale = ({
  exaInfraId,
  exaInfraScaleConfig,
  location,
  onCancel,
  onExecute,
  targetId,
}: ExaInfraScaleProps): JSX.Element => {
  const [componentRef, setComponentRef] = React.useState<CalloutComponent>();

  const { invokeCall: invokeInfraUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.updateCloudExadataInfrastructure },
  );

  const onScaleExaInfra = (formValues: FormValues): void => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(exaInfraId);

    const resourceGroupName = decodeURIComponent(resourceGroup);
    const cloudExadataInfrastructureName = decodeURIComponent(resourceName);

    const newComputeCount = getValue<string>(formValues, InfraScaleFields.ComputeCount, InputFormGroup);
    const computeCount = newComputeCount ? parseInt(newComputeCount, 10) : 0;
    const newStorageCount = getValue<string>(formValues, InfraScaleFields.StorageCount, InputFormGroup);
    const storageCount = newStorageCount ? parseInt(newStorageCount, 10) : 0;

    const invokeOptions: MutationCallOptions<CloudExadataInfrastructure> = {
      onSuccess: onExecute,
      onFailure: componentRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.scaleExaInfra(),
          message: Messages.notifications.inProgress.messages.scaleExaInfra(cloudExadataInfrastructureName),
        },
        success: {
          title: Messages.notifications.success.titles.scaleExaInfra(),
          message: Messages.notifications.success.messages.scaleExaInfra(cloudExadataInfrastructureName),
        },
        failure: {
          title: Messages.notifications.failure.titles.scale(),
          message: Messages.notifications.failure.messages.scaleExaInfra(cloudExadataInfrastructureName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXAINFRA_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            cloudExadataInfrastructureName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.EXAINFRA_EDIT_CHECK,
          delay: EXAINFRA_EDIT_POLL_DELAY,
          interval: EXAINFRA_EDIT_POLL_INTERVAL,
        },
      },
    };
    invokeInfraUpdate({
      subscriptionId,
      resourceGroupName,
      cloudExadataInfrastructureName,
      updateCloudExadataInfrastructureDetails: {
        computeCount,
        storageCount,
      },
    }, invokeOptions);
  };
  return (
    <ExadataInfraScalePanel
      componentRef={setComponentRef}
      exaInfraScaleConfig={exaInfraScaleConfig}
      targetId={targetId}
      onSubmit={onScaleExaInfra}
      onClose={onCancel}
      title={Messages.createNewPanels.scaleExaInfra.title()}
      primaryButtonText={Messages.actions.scale()}
      testId={CalloutTestIds.ExadataInfraScalePanel}
    />
  );
};

export const newExaInfraScale = (props: ExaInfraScaleProps): JSX.Element => (
  <ExaInfraScale {...props} />
);
