import { uniqueGUID } from "o4a-react";
import * as React from "react";

export interface OperationBaseProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onExecute?: (args?: any) => void;
  onCancel?: () => void;
}

export interface OperationUnit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  operationFactory: OperationFactory<any>;
  operationProps: OperationBaseProps;
}

export type OperationFactory<T extends OperationBaseProps> = (props: T) => JSX.Element
export type CancelAllOperationsListener = () => void;

export interface OperationManager {
  operationUnits: OperationUnit[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOperationFactory: (factory: OperationFactory<any>) => string;
  unregisterOperationFactory: (registerationKey: string) => void;
  addCancelAllOperationsListener: (listener: CancelAllOperationsListener) => void;
  triggerOperation: (registerationKey: string, props: OperationBaseProps) => void;
  cancelAllOperations: () => void;
}

export const OperationsContext = React.createContext<OperationManager>({} as OperationManager);

export interface OperationsProviderProps {
  children: React.ReactNode;
}

export const OperationsProvider = ({ children }: OperationsProviderProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const registry = React.useRef(new Map<string, OperationFactory<any>>());
  const cancelAllOperationsListener = React.useRef<CancelAllOperationsListener | undefined>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const registerOperationFactory = (factory: OperationFactory<any>): string => {
    const key = uniqueGUID();
    registry.current.set(key, factory);
    return key;
  };

  const unregisterOperationFactory = (registerationKey: string): void => {
    registry.current.delete(registerationKey);
  };

  const addCancelAllOperationsListener = (listener: CancelAllOperationsListener): void => {
    if (listener) {
      cancelAllOperationsListener.current = listener;
    }
  };

  const triggerOperation = (registerationKey: string, props: OperationBaseProps): void => {
    const operationFactory = registry.current.get(registerationKey);
    if (operationFactory) {
      const operationUnits = [{ operationFactory, operationProps: props }];
      setOperationManager(previousOperationManager => ({
        ...previousOperationManager,
        operationUnits,
      }));
    }
  };

  const [operationManager, setOperationManager] = React.useState<OperationManager>({
    operationUnits: [],
    registerOperationFactory,
    unregisterOperationFactory,
    addCancelAllOperationsListener,
    triggerOperation,
    cancelAllOperations: () => cancelAllOperationsListener.current?.(),
  } as OperationManager);

  return (
    <OperationsContext.Provider value={operationManager}>
      {children}
    </OperationsContext.Provider>
  );
};
