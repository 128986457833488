import React from "react";
import { FilterState, FilterStateContext } from "../console/FilterContext";
import { Settings, SettingsContext } from "../console/SettingsContext";
import { getAzureLocationName } from "../utils";
import { useSubscriptions } from "./useSubscriptions";

export interface UseLocationFilterProps {
  selectedSubscription: string;
}

export interface UseLocationFilter {
  /**
   * Select Options
   */
  locationOptions: { key: string; text: string }[];
  /**
   * selected location id
   */
  selectedLocation: string;
  setSelectedLocation: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * obtains the locations for the selected subscription
 * @param selectedSubscription selected subscription id
 * @returns
 */
export const useLocationFilters = (
  selectedSubscription: string,
): UseLocationFilter => {
  const { preferredLocationPerSubscription } = React.useContext<Settings>(SettingsContext);
  const [locationOptions, setLocationOptions] = React.useState<{ key: string; text: string; }[]>([]);
  const [selectedLocation, setSelectedLocation] = React.useState<string>("");
  const { subscriptions } = useSubscriptions();
  const filterState = React.useContext<FilterState>(FilterStateContext);

  React.useEffect(() => {
    const selSub = subscriptions?.find(sub => sub.id === selectedSubscription);

    const newLocationOptions: {key: string, text: string} [] = [];

    if (selSub) {
      selSub.locations?.forEach(loc => {
        newLocationOptions.push({ key: loc, text: getAzureLocationName(loc) });
      });

      setLocationOptions(newLocationOptions);

      if (!selSub.locations?.includes(selectedLocation)) {
        // Preferred location will only be undefined if there is an api error
        // and subscriptions.locations will be empty
        if (filterState.defaultLocation) {
          setSelectedLocation(filterState.defaultLocation);
        } else {
          setSelectedLocation(preferredLocationPerSubscription[selectedSubscription] || "");
          filterState.setDefaultLocation(preferredLocationPerSubscription[selectedSubscription] || "");
        }
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions), selectedSubscription]);

  return {
    locationOptions,
    selectedLocation,
    setSelectedLocation,
  };
};
