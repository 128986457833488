import {
  ActionType,
  CustomAction,
  EmptyListingButtonTypes,
  InfoBlockProps,
  InfoBlockStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingSelectOption,
  NoValue,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  Status,
  StatusType,
  ViewSelectOption,
} from "o4a-react";
import * as React from "react";
import { Link } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { OciLink } from "../../components/OciLinks/OciLink";
import { ConsoleContext, ConsoleState } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { InfoPanelId, PageId } from "../../constants/pluginConstants";
import {
  basicNoRefreshCaching,
  ListingTestIds,
  MonochromeIconIds,
  SvgIconIds,
  ViewKeys,
} from "../../constants/uiConstants";
import {
  AzureCloudLinkCollection,
  AzureCloudLinkSummary,
  AzureLastOperationDetailsStatusEnum,
  AzureSubscriptionCollection,
  AzureSubscriptionSummary,
} from "../../gen/clients/mchub-azure-api-client";
import {
  CloudlinkStatusEnum,
  cloudlinkStatusSortComparator,
  getCloudlinkLinkStatus,
  getCloudlinkStatusStr,
  OciResourceType,
  responseItemstoArray,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { AzureSubscriptionSummaryExt } from "../../hooks/useSubscriptions";
import { CloudLinkAddRegionProps, newCloudLinkAddRegion } from "../../operations/CloudLink/CloudLinkAddRegion";
import {
  CloudLinkListSubscriptionInstructionsProps,
  newCloudLinkListSubscriptionInstructions,
} from "../../operations/CloudLink/CloudLinkListSubscriptionInstructions";
import {
  CloudLinkShowLocationsProps,
  newCloudLinkShowLocations,
} from "../../operations/CloudLink/CloudLinkShowLocations";
import {
  CloudLinkSubscriptionLinkProps,
  newCloudLinkSubscriptionLink,
} from "../../operations/CloudLink/CloudLinkSubscriptionLink";
import { getRealmRegions } from "../../utils";

export interface SubscriptionLinkItem extends AzureSubscriptionSummaryExt {
  status: CloudlinkStatusEnum;
  statusMessage?: string;
  newLocations?: string[];
  newLocationStatus?: AzureLastOperationDetailsStatusEnum;
  newLocationStatusMessage?: string;
}

export enum ColumnIds {
  Name = "name",
  Id = "id",
  LinkStatus = "status",
  OciTenancyName = "ociTenancyName",
  Locations = "locations"
}

export enum ColumnTestIds {
  Name = "name",
  Id = "id",
  LinkStatus = "status",
  OciTenancyName = "ociTenancyName",
  Locations = "locations"
}

export enum ActionBarIds {
  LinkSubscription = "action-bar-link-subscription",
  AddRegion = "action-bar-add-region",
  Refresh = "action-bar-refresh",
  Help = "action-bar-help",
}

export enum ActionBarTestIds {
  LinkSubscription = "action-bar-link-subscription",
  AddRegion = "action-bar-add-region",
  Refresh = "action-bar-refresh",
  Help = "action-bar-help",
}

export enum ActionMenuIds {
  LinkSubscription = "action-menu-link-subscription",
}

export enum ActionMenuTestIds {
  LinkSubscription = "action-menu-link-subscription",
}

export enum NewSidePanels {
  GET_STARTED = "GET_STARTED",
}

export const CloudLinkList = (): JSX.Element => {
  const { trackActionClick, trackLinkNavigate, trackActionDiscard } = useAnalytics();

  const { locale } = React.useContext<Settings>(SettingsContext);

  const { regionName: homeRegionName } = React.useContext<ConsoleState>(ConsoleContext);

  const [selection, setSelection] = React.useState<SubscriptionLinkItem[]>([]);

  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<SubscriptionLinkItem>>();

  const { perPageItemsCount } = useRowCount();

  const {
    response: subscriptionsResponse,
    loading: subscriptionsLoading,
    refresh: subscriptionsRefresh,
  } = useQueryCall({
    method: apiClients.withRegion(homeRegionName).azResourceApi.listAzureSubscriptions,
    options: { args: {}, caching: basicNoRefreshCaching, fetchAllPages: true, retry: optimizedRetryOption },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadSubscription(),
      },
    },
  });
  const subscriptionItems = subscriptionsResponse
    && responseItemstoArray<AzureSubscriptionCollection, AzureSubscriptionSummary>(subscriptionsResponse);

  const {
    response: cloudLinksResponse,
    loading: cloudLinksLoading,
    refresh: cloudLinksRefresh,
  } = useQueryCall({
    method: apiClients.withRegion(getRealmRegions().defaultRegion).cloudLinkApi.listAzureCloudLinks,
    options: { args: {}, caching: basicNoRefreshCaching, fetchAllPages: true, retry: optimizedRetryOption },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadCloudLinks(),
      },
    },
  });
  const activeCloudLinkItemsForLinking = (
    cloudLinksResponse && responseItemstoArray<AzureCloudLinkCollection, AzureCloudLinkSummary>(cloudLinksResponse)
  )?.filter(
    cloudLink => cloudLink.lifecycleState === "ACTIVE"
      || cloudLink.lifecycleState === "UPDATING"
      || cloudLink.lifecycleState === "CREATING",
  );
  const activeCloudLinkItemsForRegionAdd = activeCloudLinkItemsForLinking?.filter(
    cloudLink => (cloudLink.ociTenancyPaymentModel
      ? cloudLink.ociTenancyPaymentModel !== "FREE"
      : cloudLink.ociTenancyType !== "PROMO" && cloudLink.ociTenancyType !== "PROMO_CONSUMED"),
  );

  const refresh = (): void => {
    subscriptionsRefresh();
    cloudLinksRefresh();
  };

  const getLinkStatusType = (value: CloudlinkStatusEnum): StatusType => {
    switch (value) {
      case CloudlinkStatusEnum.CREATING:
      case CloudlinkStatusEnum.UPDATING:
        return StatusType.PENDING;
      case CloudlinkStatusEnum.LINKED:
        return StatusType.SUCCESS;
      case CloudlinkStatusEnum.UNLINKED:
        return StatusType.NONE;
      case CloudlinkStatusEnum.FAILED:
        return StatusType.ERROR;
      default:
        return StatusType.UNKNOWN;
    }
  };

  const getLinkStatus = (value: SubscriptionLinkItem): JSX.Element => (
    <Status
      label={getCloudlinkStatusStr(value.status)}
      tooltip={getCloudlinkStatusStr(value.status)}
      status={getLinkStatusType(value.status)}
      statusInfo={value?.statusMessage ? {
        title: getCloudlinkStatusStr(value.status),
        description: value?.statusMessage || "",
      } : undefined}
      hideClipboardCopy
    />
  );

  const { trigger: triggerShowLocations } = useOperation<CloudLinkShowLocationsProps>(newCloudLinkShowLocations);

  const getLinkLocationSummary = (value: SubscriptionLinkItem): JSX.Element => {
    const locationSummaries = [];
    if (value.locations?.length) {
      locationSummaries.push(Messages.listCloudLink.messages.numActiveLocations(value.locations?.length.toString()));
    }
    if (value.status !== CloudlinkStatusEnum.UNLINKED && value.status !== CloudlinkStatusEnum.FAILED
      && value.newLocations && value.newLocations.length && value.newLocationStatus === "IN_PROGRESS") {
      locationSummaries.push(
        Messages.listCloudLink.messages.numPendingLocations(value.newLocations.length.toString()),
      );
    }
    if (value.status !== CloudlinkStatusEnum.UNLINKED && value.status !== CloudlinkStatusEnum.FAILED
      && value.newLocations && value.newLocations.length && value.newLocationStatus === "FAILED") {
      locationSummaries.push(Messages.listCloudLink.messages.numFailedLocations(value.newLocations.length.toString()));
    }

    if (!locationSummaries.length) return <NoValue />;

    const text = locationSummaries.join(", ");

    return (
      <Link
        title={text}
        onClick={() => {
          trackLinkNavigate(
            InfoPanelId.CLOUDLINK_LOCATIONS,
            PageId.CLOUDLINK_LIST,
          );
          triggerShowLocations({
            subscription: value,
            newLocations: value.newLocations,
            newLocationStatus: value.newLocationStatus,
            newLocationStatusMessage: value.newLocationStatusMessage,
          });
        }}
      >
        {text}
      </Link>
    );
  };

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.labels.subscription(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <AzureSubscriptionLink
          resourceId=""
          subscriptionId={value.id}
          subscriptionName={value.name}
          hideClipboard
        />
      ),
    },
    {
      itemProp: ColumnIds.Id,
      testId: ColumnTestIds.Id,
      name: Messages.labels.subscriptionId(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
    {
      itemProp: ColumnIds.LinkStatus,
      testId: ColumnTestIds.LinkStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: SubscriptionLinkItem,
        b: SubscriptionLinkItem,
      ) => cloudlinkStatusSortComparator<SubscriptionLinkItem>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getLinkStatus(value),
    },
    {
      itemProp: ColumnIds.Locations,
      testId: ColumnTestIds.Locations,
      name: Messages.labels.locations(),
      flexGrow: 1,
      isResizable: true,
      onRenderItems: value => getLinkLocationSummary(value),
    },
    {
      itemProp: ColumnIds.OciTenancyName,
      testId: ColumnTestIds.OciTenancyName,
      name: Messages.labels.ociTenant(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <OciLink
          ociResourceType={OciResourceType.HOME}
          ociResourcePath="/"
          ociResourceName={value.ociTenancyName}
          location={value.primaryLocation}
          subscription={value}
          analytics={{ pageId: PageId.CLOUDLINK_LIST }}
          hideClipboard
        />
      ),
    },
  ];

  const groupingOptions: ListingSelectOption<SubscriptionLinkItem>[] = [];

  const viewOptions: ViewSelectOption<SubscriptionLinkItem>[] = [
    {
      key: ViewKeys.ListView,
      text: Messages.listCloudLink.filters.listView(),
      icon: MonochromeIconIds.List,
    },
  ];

  function timeUpdatedDescSortComparator<T extends AzureCloudLinkSummary>(
    aValue: T,
    bValue: T,
  ): 0 | -1 | 1 {
    const aDate = aValue?.lastOperation?.timeUpdated || aValue?.timeUpdated;
    const bDate = bValue?.lastOperation?.timeUpdated || bValue?.timeUpdated;
    if (aDate && bDate) {
      const comparison = new Date(aDate).getTime() - new Date(bDate).getTime();
      return comparison > 0 ? -1 : comparison < 0 ? 1 : 0;
    }
    if (!aDate && bDate) return 1;
    if (aDate && !bDate) return -1;
    return 0;
  }

  const items = React.useMemo(() => {
    const linkItems: SubscriptionLinkItem[] = [];
    if (subscriptionItems && activeCloudLinkItemsForLinking) {
      subscriptionItems.forEach((item: AzureSubscriptionSummary) => {
        // Find a linked subscription that is included in the main subscription
        // ids array which means it's completed. If not found then find a
        // linked subscription that is included in the last operation subscription
        // ids array which means it's in progress and take the newest one
        const linkedSubscription = activeCloudLinkItemsForLinking?.find(
          cloudLink => cloudLink.subscriptionIds?.includes(item.id),
        ) || activeCloudLinkItemsForLinking?.filter(
          cloudLink => cloudLink.lastOperation?.subscriptionIds?.includes(item.id),
        )?.sort(timeUpdatedDescSortComparator)[0];

        const operationInfo = getCloudlinkLinkStatus(item.id, linkedSubscription);
        const newLocations = linkedSubscription?.lastOperation?.subscribedLocations?.filter(
          location => !linkedSubscription.locations?.includes(location),
        );

        linkItems.push({
          ...item,
          status: operationInfo?.status,
          statusMessage: operationInfo?.message,
          ociTenancyName: operationInfo?.status !== CloudlinkStatusEnum.UNLINKED
          && operationInfo?.status !== CloudlinkStatusEnum.FAILED
            ? linkedSubscription?.ociTenancyName : undefined,
          ociDomainName: operationInfo?.status !== CloudlinkStatusEnum.UNLINKED
          && operationInfo?.status !== CloudlinkStatusEnum.FAILED
            ? linkedSubscription?.ociDomainName : undefined,
          primaryLocation: operationInfo?.status !== CloudlinkStatusEnum.UNLINKED
          && operationInfo?.status !== CloudlinkStatusEnum.FAILED
            ? linkedSubscription?.primaryLocation : undefined,
          locations: operationInfo?.status !== CloudlinkStatusEnum.UNLINKED
          && operationInfo?.status !== CloudlinkStatusEnum.FAILED
            ? linkedSubscription?.locations : undefined,
          newLocations,
          newLocationStatus: newLocations && newLocations.length
            ? linkedSubscription?.lastOperation?.status : undefined,
          newLocationStatusMessage: newLocations && newLocations.length && linkedSubscription?.lastOperation?.message
            ? Messages.listCloudLink.messages.lastOperationMessage(
              linkedSubscription.lastOperation.message,
              new Date(linkedSubscription.lastOperation.timeUpdated).toLocaleString(),
            ) : undefined,
          cloudLink: linkedSubscription,
        });
      });
    }
    return linkItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptionItems), JSON.stringify(activeCloudLinkItemsForLinking)]);

  const disableItemCheckbox = (item: SubscriptionLinkItem): boolean => (
    item.status !== CloudlinkStatusEnum.UNLINKED && item.status !== CloudlinkStatusEnum.FAILED
  );

  // eslint-disable-next-line max-len
  const { trigger: triggerLinkSubscription } = useOperation<CloudLinkSubscriptionLinkProps>(newCloudLinkSubscriptionLink);
  const { trigger: triggerAddRegion } = useOperation<CloudLinkAddRegionProps>(newCloudLinkAddRegion);
  // eslint-disable-next-line max-len
  const { trigger: triggerCloudLinkListSubscriptionInstructions } = useOperation<CloudLinkListSubscriptionInstructionsProps>(newCloudLinkListSubscriptionInstructions);

  const { closePanels } = useSidePanel();
  const buildLinkSubscriptionAction = (key:string, testId:string, selectedItem:SubscriptionLinkItem)
  :CustomAction<SubscriptionLinkItem | undefined> => {
    const linkSubscriptionAction = {
      key,
      testId,
      disabled: !selectedItem || disableItemCheckbox(selectedItem),
      text: Messages.actions.linkSubscription(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        trackActionClick(key, PageId.CLOUDLINK_LIST);
        triggerLinkSubscription({
          targetId: ActionBarIds.LinkSubscription,
          subscription: selectedItem,
          activeCloudLinks: activeCloudLinkItemsForLinking || [],
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
          onCancel: () => trackActionDiscard(key, PageId.CLOUDLINK_LIST),
        });
      },
    };
    return linkSubscriptionAction;
  };

  const cloudLinkRowActionMenuItems = (item?:SubscriptionLinkItem | undefined) :
  ActionType<SubscriptionLinkItem>[] => {
    const linkSubscriptionAction : ActionType<SubscriptionLinkItem | undefined>[] = [];
    if (item) {
      linkSubscriptionAction.push(
        buildLinkSubscriptionAction(ActionMenuIds.LinkSubscription, ActionMenuTestIds.LinkSubscription, item),
      );
    }
    return linkSubscriptionAction;
  };
  
  const cloudLinkBarActions: ActionType<SubscriptionLinkItem>[] = [
    buildLinkSubscriptionAction(ActionBarIds.LinkSubscription, ActionBarTestIds.LinkSubscription, selection[0]),
    {
      key: ActionBarIds.AddRegion,
      testId: ActionBarTestIds.AddRegion,
      text: Messages.actions.addRegion(),
      icon: MonochromeIconIds.Create,
      disabled: !activeCloudLinkItemsForRegionAdd?.length,
      onClick: () => {
        trackActionClick(ActionBarIds.AddRegion, PageId.CLOUDLINK_LIST);
        triggerAddRegion({
          targetId: ActionBarIds.AddRegion,
          showTenantOptions: !!activeCloudLinkItemsForLinking && activeCloudLinkItemsForLinking.length > 1,
          activeCloudLinks: activeCloudLinkItemsForLinking || [],
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
          onCancel: () => trackActionDiscard(ActionBarIds.AddRegion, PageId.CLOUDLINK_LIST),
        });
      },
    },
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, PageId.CLOUDLINK_LIST);
        refresh();
        listingComponentRef?.resetTableSelection();
      },
    },
    {
      key: ActionBarIds.Help,
      testId: ActionBarTestIds.Help,
      text: Messages.actions.addSubscriptionHelp(),
      icon: MonochromeIconIds.Help,
      onClick: () => {
        trackActionClick(ActionBarIds.Help, PageId.CLOUDLINK_LIST);
        triggerCloudLinkListSubscriptionInstructions(
          { onCancel: () => trackActionDiscard(ActionBarIds.Help, PageId.CLOUDLINK_LIST) },
        );
      },
    },
  ];
  const cloudLinkInfoBlocks: InfoBlockProps[] = [];
  if (activeCloudLinkItemsForRegionAdd?.length === 0) {
    cloudLinkInfoBlocks.push({
      message: Messages.hints.regionAddNotAvailable(),
      messageType: InfoBlockStatus.INFO,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectedItems = (selectedItems: any[]): void => {
    setSelection(selectedItems);
  };

  return (
    <Listing
      testId={ListingTestIds.CloudLink}
      listingComponentRef={setListingComponentRef}
      items={items}
      listColumns={columns}
      itemsPerPage={perPageItemsCount}
      actionBarItems={cloudLinkBarActions as ActionType<undefined>[]}
      actions={cloudLinkRowActionMenuItems}
      onActionClick={closePanels}
      infoBlocks={cloudLinkInfoBlocks}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      isRowSelectionDisabled={disableItemCheckbox}
      emptyList={{
        title: Messages.listCloudLink.emptyList.title(),
        emptyButtons: [
          {
            type: EmptyListingButtonTypes.PRIMARY,
            text: Messages.actions.getStarted(),
            action: () => {
              trackActionClick(ActionBarIds.Help, PageId.CLOUDLINK_LIST);
              triggerCloudLinkListSubscriptionInstructions(
                { onCancel: () => trackActionDiscard(ActionBarIds.Help, PageId.CLOUDLINK_LIST) },
              );
            },
          },
        ],
        watermark: SvgIconIds.exaWaterMarkSvg,
      }}
      isLoading={subscriptionsLoading || cloudLinksLoading}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Name,
      }}
      groupingSelect={{ groupingOptions }}
      viewSelect={{
        viewOptions,
        defaultSelectedKey: ViewKeys.ListView,
      }}
    />
  );
};
