import {
  FormattedString,
  InfoBlock,
  InfoBlockLayout,
  InfoBlockStatus,
  InputFormCallout,
  InputFormCalloutProps,
  IntegerInput,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";
import { CloudVmClusterAvailableCapacity } from "../../gen/clients/mchub-azure-api-client-exa";

export enum Fields {
  NodeCount = "nodeCount",
  StorageSize = "storageSize",
}

export enum FieldsTestIds {
  NodeCount = "nodeCount",
  StorageSize = "storageSize",
}

export enum InfoBlockTestIds {
  AvailableStorage = "available-storage-infoblock"
}

export interface AddCapacityVmClusterProps extends
  Omit<InputFormCalloutProps, "children">
{
  availableCapacity: CloudVmClusterAvailableCapacity | undefined;
}

export const AddCapacityVmCluster = ({ availableCapacity, ...rest }: AddCapacityVmClusterProps): JSX.Element => {
  const availableNodeCount = availableCapacity?.availableComputeCount;
  const availableStorageSize = availableCapacity?.availableStorageSizeInGBs;

  const message = (): string => {
    let infoMessage = "";
    if (availableNodeCount && availableStorageSize) {
      infoMessage = Messages.hints.vmClusterAvailableCapacity(`${availableNodeCount}`, `${availableStorageSize}`);
    }
    if (availableNodeCount && !availableStorageSize) {
      infoMessage = Messages.hints.vmClusterAvailableCapacityForNodeCount(`${availableNodeCount}`);
    }
    if (!availableNodeCount && availableStorageSize) {
      infoMessage = Messages.hints.vmClusterAvailableCapacityForStorage(`${availableStorageSize}`);
    }
    return infoMessage;
  };

  return (
    <InputFormCallout
      testId={CalloutTestIds.AddCapacityVmCluster}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      {...rest}
    >
      <IntegerInput
        testId={FieldsTestIds.NodeCount}
        label={Messages.labels.additionalNodeCount()}
        fieldName={Fields.NodeCount}
        min={0}
        max={availableNodeCount}
        disabled={!availableNodeCount}
      />
      <IntegerInput
        testId={FieldsTestIds.StorageSize}
        label={Messages.labels.additionalStorageInGB()}
        fieldName={Fields.StorageSize}
        min={0}
        max={availableStorageSize}
        disabled={!availableStorageSize}
      />
      <InfoBlock
        testId={InfoBlockTestIds.AvailableStorage}
        message={(FormattedString({ inputText: message() }) as unknown as string)}
        messageType={InfoBlockStatus.INFO}
        infoLayout={InfoBlockLayout.Compact}
        customInfoStyle={{ root: { marginBottom: "15px" } }}
      />
    </InputFormCallout>
  );
};
