import { CalloutComponent, FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Fields as UpdatePasswordFields, UpdatePassword } from "../../components/UpdatePassword/UpdatePassword";
import { parseId } from "../../helpers/idHelper";
import { validateAdbPassword } from "../../helpers/validationHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  ADBS_UPDATE_POLL_DELAY,
  ADBS_UPDATE_POLL_INTERVAL,
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface AdbsChangePasswordProps extends OperationActionProps {
  databaseId: string | undefined;
}

const AdbsChangePassword = (
  { targetId, location, databaseId, onExecute, onCancel }: AdbsChangePasswordProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");
  const [passwordCalloutRef, setPasswordCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const { invokeCall: invokeAdbsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.updateAdbsDatabase },
  );

  const onUpdateAdbsPassword = (formValues: FormValues): void => {
    const password = getValue<string>(formValues, UpdatePasswordFields.Password, InputFormGroup);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: passwordCalloutRef.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateAdbsPassword(),
          message: Messages.notifications.inProgress.messages.updateAdbsPassword(resName),
        },
        success: {
          title: Messages.notifications.success.titles.updateAdbsPassword(),
          message: Messages.notifications.success.messages.updateAdbsPassword(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateAdbsPassword(),
          message: Messages.notifications.failure.messages.updateAdbsPassword(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.ADBS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.ADBS_UPDATED_CHECK,
          delay: ADBS_UPDATE_POLL_DELAY,
          interval: ADBS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeAdbsUpdate({
      databaseName: resName,
      resourceGroupName: resGroup,
      subscriptionId,
      updateAdbsDatabaseDetails: { adminPassword: password },
    }, invokeOptions);
  };

  return (
    <UpdatePassword
      componentRef={setPasswordCalloutRef}
      targetId={targetId}
      onClose={onCancel}
      onSubmit={onUpdateAdbsPassword}
      tooltip={Messages.hints.tooltipPasswordAdb()}
      validator={validateAdbPassword}
    />
  );
};

export const newAdbsChangePassword = (
  props: AdbsChangePasswordProps,
): JSX.Element => (<AdbsChangePassword {...props} />);
