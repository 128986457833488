import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { OciResourceType } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { AzureSubscriptionSummaryExt } from "../../hooks/useSubscriptions";
import { getOciRegion } from "../../utils";
import { OciLink, OciLinkProps } from "./OciLink";

export interface OciVmPdbLinkProps extends Pick<OciLinkProps, "location" | "analytics"> {
  id: string;
  subscriptions: AzureSubscriptionSummaryExt[];
}

export const OciVmPdbLink = ({ id, location, subscriptions, analytics }: OciVmPdbLinkProps): JSX.Element => {
  const idComps = parseId(id);
  const resGroup = decodeURIComponent(idComps?.resourceGroup || "");
  const resourceName = decodeURIComponent(idComps?.resourceName || "");
  const subscription = subscriptions?.find(item => item.id === idComps?.subscriptionId);

  const { response, error } = useQueryCall({
    wait: !id,
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.getVmdbPluggableDatabase,
    options: {
      args: {
        subscriptionId: idComps?.subscriptionId || "",
        resourceGroupName: resGroup,
        pluggableDatabaseName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });

  const pdb = response?.data;

  const ociResourcePath = `/dbaas/pluggableDatabases/${pdb?.ocid}`;

  return (
    <OciLink
      ociResourceType={OciResourceType.VMDB_PDB}
      ociResourcePath={!error && pdb?.ocid ? ociResourcePath : undefined}
      ociResourceName={pdb?.pdbName}
      location={location}
      subscription={subscription}
      analytics={analytics}
    />
  );
};
