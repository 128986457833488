import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { getTagsMap, TagsInfoType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXADB_CDB_UPDATE_POLL_DELAY,
  EXADB_CDB_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface ExaDbUpdateTagsCdbProps extends OperationConfirmationProps {
  databaseId: string;
  tags: TagsInfoType;
}

const ExaDbUpdateTagsCdb = (
  { location, databaseId, tags, onExecute }: ExaDbUpdateTagsCdbProps,
): JSX.Element => {
  const { invokeCall: invokeCdbUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.updateDatabase },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
    const resGroup = decodeURIComponent(resourceGroup || "");
    const cdbName = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.applyTags(),
          message: Messages.notifications.inProgress.messages.applyTags(cdbName),
        },
        success: {
          title: Messages.notifications.success.titles.applyTags(),
          message: Messages.notifications.success.messages.applyTags(cdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.applyTags(),
          message: Messages.notifications.failure.messages.applyTags(cdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXADB_CDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: cdbName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.EXADB_UPDATED_CHECK_CDB,
          delay: EXADB_CDB_UPDATE_POLL_DELAY,
          interval: EXADB_CDB_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeCdbUpdate({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName: cdbName,
      updateDatabaseDetails: { freeformTags: getTagsMap(tags) },
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newExaDbUpdateTagsCdb = (
  props: ExaDbUpdateTagsCdbProps,
): JSX.Element => (<ExaDbUpdateTagsCdb {...props} />);
