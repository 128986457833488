import { ActionType, Listing, ListingColumn } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds } from "../../constants/uiConstants";
import { VmdbDbSystem } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useSidePanel } from "../../hooks/useSidePanel";
import { newVmDbSystemAddSshKey, VmDbSystemAddSshKeyProps } from "../../operations/VmDbSystem/VmDbSystemAddSshKey";

export enum ActionIds {
  AddSshKey = "add-ssh-key",
  Refresh = "refresh",
  CopySshPublicKey = "copy-ssh-public-key",
}

export enum ActionBarTestIds {
  AddSshKey = "add-ssh-key",
  Refresh = "refresh",
}

export enum ListingActionTestIds {
  CopySshPublicKey = "copy-ssh-public-key",
}

export interface VmDbSystemSecurityDetailsProps {
  dbSystem: VmdbDbSystem | undefined;
  location: string;
  refresh: () => void;
}

export const VmDbSystemSecurityDetails = (
  { dbSystem, location, refresh }: VmDbSystemSecurityDetailsProps,
): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const disableAddSshKeyAction = !dbSystem;

  const { trigger: triggerAddSsh } = useOperation<VmDbSystemAddSshKeyProps>(newVmDbSystemAddSshKey);

  const { closePanels } = useSidePanel();

  const securityActionItems: ActionType[] = [
    {
      key: ActionIds.AddSshKey,
      testId: ActionBarTestIds.AddSshKey,
      text: Messages.actions.addSshKey(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        trackActionClick(ActionIds.AddSshKey, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.SECURITY);
        triggerAddSsh({
          dbSystemId: dbSystem?.id,
          sshPublicKeys: dbSystem?.sshPublicKeys,
          location,
          onExecute: refresh,
          onCancel: () => trackActionDiscard(ActionIds.AddSshKey, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.SECURITY),
        });
      },
      disabled: disableAddSshKeyAction,
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.SECURITY);
        refresh();
      },
    },
  ];

  const publicSshKeys: { publicKey: string }[] = dbSystem?.sshPublicKeys?.map(entry => ({ publicKey: entry })) || [];

  const columns: ListingColumn[] = [
    {
      itemProp: "publicKey",
      name: Messages.labels.sshPublicKey(),
      flexGrow: 1,
    },
  ];

  return (
    <Listing
      testId={ListingTestIds.VmDbSystemSshKeys}
      actionBarItems={securityActionItems}
      onActionClick={closePanels}
      items={publicSshKeys}
      emptyList={{ title: Messages.common.noResults() }}
      listColumns={columns}
      actions={[
        {
          key: ActionIds.CopySshPublicKey,
          testId: ListingActionTestIds.CopySshPublicKey,
          text: Messages.actions.copySshPublicKey(),
          icon: MonochromeIconIds.Copy,
          onClick: value => {
            trackActionClick(ActionIds.CopySshPublicKey, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.SECURITY);
            navigator.clipboard.writeText(value?.publicKey || "");
          },
        },
      ]}
    />
  );
};
