import {
  AsyncNotificationMonitor,
  initNotificationManager,
  MainAnchorId,
  MenuLayoutType,
  MenuState,
  NotificationProvider,
  registerBreadcrumbLabel,
  registerNavigationEntry,
  ScreenSizeContext,
  ScreenSizeContextProvider,
  ScreenSizeMonitor,
  useResizeObserver,
} from "o4a-react";
import React from "react";
import {
  BrowserRouter,
  Location,
  matchRoutes,
  Navigate,
  Outlet,
  Params,
  Route,
  RouteObject,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { initializeIcons, LayerHost, mergeStyleSets } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import * as Messages from "./codegen/Messages";
import { AsideMenu } from "./console/AsideMenu";
import { ConsoleContextProvider, ConsoleState } from "./console/ConsoleContext";
import { FilterStateProvider } from "./console/FilterContext";
import { Footer } from "./console/Footer";
import { NotificationOutlet } from "./console/NotificationOutlet";
import { OperationOutlet } from "./console/OperationOutlet";
import { OperationsProvider } from "./console/OperationsContext";
import { Settings, SettingsContext, SettingsProvider } from "./console/SettingsContext";
import { ActionIds, TopHeader } from "./console/TopHeader";
import { UserActivityMonitor } from "./console/UserActivityMonitor";
import {
  ADB_HUB_PATH,
  APEX_DATABASE_RESOURCE_TYPE_PATH,
  APEX_DATABASES_RESOURCE_TYPE_PATH,
  AUTONOMOUS_DATABASE_SHARED_RESOURCE_TYPE_PATH,
  BASE_ROUTE,
  BILLING_PATH,
  BROWSE_RESOURCE_PATH,
  CLOUDLINK_LIST_PATH,
  CREATE_PATH,
  DATABASE_RESOURCE_TYPE_PATH,
  DEPLOYMENTS_RESOURCE_TYPE_PATH,
  DetailsPanelId,
  EXADATA_HUB_PATH,
  EXADATA_INFRAS_RESOURCE_TYPE_PATH,
  EXADATA_SYSTEM_RESOURCE_TYPE_PATH,
  FAVORITE_RESOURCES_PATH,
  HOME_PATH,
  HOME_ROUTE,
  HUB_PATH,
  InfoPanelId,
  MCVCN_RESOURCE_TYPE_PATH,
  MCVCNS_RESOURCE_TYPE_PATH,
  MYSQL_DATABASE_RESOURCE_TYPE_PATH,
  MYSQL_DATABASES_RESOURCE_TYPE_PATH,
  ORACLE_ADBS_PROVIDER_PATH,
  ORACLE_DB_PROVIDER_PATH,
  ORACLE_DEPLOYMENT_PROVIDER_PATH,
  ORACLE_MYSQL_PROVIDER_PATH,
  ORACLE_NET_PROVIDER_PATH,
  ORACLE_VMDB_PROVIDER_PATH,
  PageId,
  PageRegistrationConfig,
  RECENT_RESOURCES_PATH,
  RESOURCE_PATH,
  RESOURCE_TYPE_PATH,
  SUPPORT_PATH,
  SupportedProviders,
  VM_CLUSTERS_RESOURCE_TYPE_PATH,
  VM_DATABASE_RESOURCE_TYPE_PATH,
  VMDB_HUB_PATH,
} from "./constants/pluginConstants";
import { footerHeight, MenuStyleMode, topHeaderHeight } from "./constants/uiConstants";
import { IdResourceType } from "./helpers/idHelper";
import { ExadataCreateFlowType } from "./helpers/resourceHelper";
import { useAnalytics } from "./hooks/useAnalytics";
import { useOperation } from "./hooks/useOperation";
import { useSidePanel } from "./hooks/useSidePanel";
import {
  asyncNotificationMethodProvider,
  asyncNotificationPolledResponseProvider,
} from "./models/AsyncNotificationProviders";
import { newOpenSettings, OpenSettingsProps } from "./operations/Console/OpenSettings";
import { newSubmitFeedback, SubmitFeedbackProps } from "./operations/Console/SubmitFeedback";
import { newViewHelp, ViewHelpProps } from "./operations/Console/ViewHelp";
import { newViewNotifications, ViewNotificationsProps } from "./operations/Console/ViewNotifications";
import { AdbListHubPage } from "./pages/AdbListHubPage/AdbListHubPage";
import { AdbsCreatePage } from "./pages/AdbsCreatePage/AdbsCreatePage";
import { AdbsDetailsPage } from "./pages/AdbsDetailsPage/AdbsDetailsPage";
import { ApexListPage } from "./pages/ApexListPage/ApexListPage";
import { BillingPage } from "./pages/BillingPage/BillingPage";
import { CloudLinkListPage } from "./pages/CloudLinkListPage/CloudLinkListPage";
import { CreateAResourcePage } from "./pages/CreateAResourcePage/CreateAResourcePage";
import { DeploymentDetailsPage } from "./pages/DeploymentDetailsPage/DeploymentDetailsPage";
import { DeploymentListPage } from "./pages/DeploymentListPage/DeploymentListPage";
import { ExaDbCreatePage } from "./pages/ExaDbCreatePage/ExaDbCreatePage";
import { ExaDbCdbDetailsPage } from "./pages/ExaDbDetailsPage/ExaDbCdbDetailsPage";
import { ExaDbPdbDetailsPage } from "./pages/ExaDbDetailsPage/ExaDbPdbDetailsPage";
import { ExaInfraCreatePage } from "./pages/ExaInfraCreatePage/ExaInfraCreatePage";
import { ExaInfraDetailsPage } from "./pages/ExaInfraDetailsPage/ExaInfraDetailsPage";
import { ExaListHubPage } from "./pages/ExaListHubPage/ExaListHubPage";
import { ExaVmClusterCreatePage } from "./pages/ExaVmClusterCreatePage/ExaVmClusterCreatePage";
import { ExaVmClusterDetailsPage } from "./pages/ExaVmClusterDetailsPage/ExaVmClusterDetailsPage";
import { FavoriteListPage } from "./pages/FavoriteListPage/FavoriteListPage";
import { HomePage } from "./pages/HomePage/HomePage";
import { McvcnCreatePage } from "./pages/McvcnCreatePage/McvcnCreatePage";
import { McvcnDetailsPage } from "./pages/McvcnDetailsPage/McvcnDetailsPage";
import { McvcnListPage } from "./pages/McvcnListPage/McvcnListPage";
import { MysqlBackupDetailsPage } from "./pages/MysqlBackupDetailsPage/MysqlBackupDetailsPage";
import { MysqlCreatePage } from "./pages/MysqlCreatePage/MysqlCreatePage";
import { MysqlDetailsPage } from "./pages/MysqlDetailsPage/MysqlDetailsPage";
import { MysqlListPage } from "./pages/MysqlListPage/MysqlListPage";
// import { SettingsPage } from "./pages/SettingsPage/SettingsPage";
import { RecentListPage } from "./pages/RecentListPage/RecentListPage";
import { SupportCreatePage } from "./pages/SupportCreatePage/SupportCreatePage";
import { SupportDetailsPage } from "./pages/SupportDetailsPage/SupportDetailsPage";
import { SupportListPage } from "./pages/SupportListPage/SupportListPage";
import { VmDbCreatePage } from "./pages/VmDbCreatePage/VmDbCreatePage";
import { VmDbCdbDetailsPage } from "./pages/VmDbDetailsPage/VmDbCdbDetailsPage";
import { VmDbPdbDetailsPage } from "./pages/VmDbDetailsPage/VmDbPdbDetailsPage";
import { VmDbListHubPage } from "./pages/VmDbListHubPage/VmDbListHubPage";
import { VmDbSystemDetailsPage } from "./pages/VmDbSystemDetailsPage/VmDbSystemDetailsPage";
import { ResourceErrorPage } from "./ResourceErrorPage";

initializeIcons();

const registerPages = (): void => {
  Object.keys(PageRegistrationConfig).forEach(configKey => {
    const configs = PageRegistrationConfig[configKey];
    configs.forEach(config => {
      registerNavigationEntry(config.key, config.type, configKey, config.panelPath, config.path);
    });
  });
};

const classNames = mergeStyleSets({
  consoleContainer: {
    display: "flex",
    height: `calc(100vh - ${topHeaderHeight}px - ${footerHeight}px)`,
  },
  mainContainer: {
    position: "relative",
    overflowY: "auto",
    flex: 8,
  },
});

interface RouteObjectExt extends RouteObject {
  getAnalyticsPath?: (routePath: string, params?: Params, searchParams?: URLSearchParams) => string;
}

/* enum SettingsPageState {
  OPENED = "OPENED",
  CLOSED = "CLOSED",
} */

interface ConsoleProps {
  routes: RouteObject[];
  // settingsPageState: SettingsPageState;
}

const getRouteAnalyticsPath = (
  routes: RouteObject[],
  location: Location,
  searchParams?: URLSearchParams,
): string | undefined => {
  let analyticsPath;
  const matches = matchRoutes(routes, location);
  const noChildrenMatch = matches?.filter(matchedRoute => !matchedRoute.route.children);
  if (noChildrenMatch && noChildrenMatch.length > 0) {
    const match = noChildrenMatch[0];
    analyticsPath = match.pathname;
    const route = match.route as RouteObjectExt;
    if (route.getAnalyticsPath) {
      if (match.params) {
        const pathnameComps = match.pathname.split("/");
        const routePathComps = route.path?.split("/");
        const prefixPathCount = pathnameComps.length - (routePathComps?.length || 0);
        analyticsPath = (pathnameComps.slice(0, prefixPathCount).concat(routePathComps || [])).join("/");
      }
      analyticsPath = route.getAnalyticsPath(analyticsPath, match.params, searchParams);
    }
  }
  return analyticsPath;
};

const getRouteAnaylticsPath = (routePath: string, params?: Params, searchParams?: URLSearchParams): string => {
  let search;
  if (searchParams) {
    searchParams.delete("location");
    search = searchParams.toString();
  }
  const analyticsPath = routePath
    .replace(":provider", params?.provider || ":provider")
    .replace(":resourceType", params?.resourceType || ":resourceType")
    .replace(":panelId", params?.panelId || ":panelId")
    .replace(":listId", params?.listId || ":listId")
    .concat(search ? `?${search}` : "");
  return analyticsPath;
};

interface EffectiveMenuModeResult {
  menuMode: MenuStyleMode;
}

const useEffectiveMenuMode = (
  consoleContainerRef: React.RefObject<HTMLElement | undefined>,
): EffectiveMenuModeResult => {
  const { menuMode } = React.useContext<Settings>(SettingsContext);
  const { height: consoleContainerHeight } = useResizeObserver(consoleContainerRef);
  const screenSzieMonitor = React.useContext<ScreenSizeMonitor | undefined>(ScreenSizeContext);

  // eslint-disable-next-line max-len
  const effectiveMenuMode = (consoleContainerHeight < 520 || screenSzieMonitor?.menuLayoutType === MenuLayoutType.TOP_SIDE_COUPLED) ? MenuStyleMode.FlyOut : menuMode;

  return { menuMode: effectiveMenuMode };
};

// const Console = ({ routes, settingsPageState }: ConsoleProps): JSX.Element => {
const Console = ({ routes }: ConsoleProps): JSX.Element => {
  const consoleContainerRef = React.useRef(null);
  const { menuMode } = useEffectiveMenuMode(consoleContainerRef);

  const { trackActionClick, trackActionDiscard, trackLinkNavigate } = useAnalytics();

  const [isOverlayOpen, { setTrue: openOverlay, setFalse: dismissOverlay }] = useBoolean(false);
  const [activeActionId, setActiveActionId] = React.useState<ActionIds | undefined>(undefined);
  const [menuState, setMenuState] = React.useState<MenuState>();

  registerBreadcrumbLabel(PageId.HOME, Messages.common.home());

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { trackPageLoad } = useAnalytics();

  const { closePanels } = useSidePanel();
  const { trigger: triggerOpenSettings } = useOperation<OpenSettingsProps>(newOpenSettings);
  const { trigger: triggerSubmitFeedback } = useOperation<SubmitFeedbackProps>(newSubmitFeedback);
  const { trigger: triggerViewHelp } = useOperation<ViewHelpProps>(newViewHelp);
  const { trigger: triggerViewNotifications } = useOperation<ViewNotificationsProps>(newViewNotifications);

  React.useEffect(() => {
    closePanels();

    const analyticsPath = getRouteAnalyticsPath(routes, location, searchParams);
    if (analyticsPath) {
      trackPageLoad(analyticsPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /*
  React.useEffect(() => {
    switch (settingsPageState) {
      case SettingsPageState.CLOSED:
        setActiveActionId(undefined);
        break;
      case SettingsPageState.OPENED:
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsPageState]);
  */
  const onActionClick = (actionId: ActionIds): void => {
    setActiveActionId(previousActionId => {
      if (previousActionId === actionId) {
        return undefined;
      }
      return actionId;
    });
  };

  React.useEffect(() => {
    switch (activeActionId) {
      case ActionIds.Settings:
        trackLinkNavigate({ pageId: PageId.SETTINGS, panelId: DetailsPanelId.SUBSCRIPTIONS }, PageId.TOP_HEADER);
        triggerOpenSettings({
          onCancel: () => {
            setActiveActionId(undefined);
          },
        });
        break;
      case ActionIds.Feedback:
        trackActionClick(ActionIds.Feedback, PageId.TOP_HEADER);
        triggerSubmitFeedback({
          onCancel: () => {
            trackActionDiscard(ActionIds.Feedback, PageId.TOP_HEADER);
            setActiveActionId(undefined);
          },
        });
        break;
      case ActionIds.Help:
        trackLinkNavigate(InfoPanelId.HELP, PageId.TOP_HEADER);
        triggerViewHelp({
          onCancel: () => {
            setActiveActionId(undefined);
          },
        });
        break;
      case ActionIds.Notifications:
        trackLinkNavigate(InfoPanelId.NOTIFICATIONS, PageId.TOP_HEADER);
        triggerViewNotifications({
          onCancel: () => {
            setActiveActionId(undefined);
          },
        });
        break;
      default:
        closePanels();
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeActionId]);

  return (
    <>
      <TopHeader
        menuStyleMode={menuMode}
        isOverlayOpen={isOverlayOpen}
        openOverlay={openOverlay}
        dismissOverlay={dismissOverlay}
        onActionClick={onActionClick}
        activeActionId={activeActionId}
      />
      <div
        ref={consoleContainerRef}
        id="console-container"
        role="presentation"
        className={classNames.consoleContainer}
        onClick={() => { dismissOverlay(); }}
      >
        {menuMode === MenuStyleMode.Docking && (
          <AsideMenu
            menuStyleMode={menuMode}
            menuState={menuState}
            onMenuStateChange={setMenuState}
          />
        )}
        <LayerHost id={MainAnchorId} className={classNames.mainContainer}>
          <Outlet />
        </LayerHost>
        <OperationOutlet />
        <NotificationOutlet anchorId={MainAnchorId} />
        <AsyncNotificationMonitor />
      </div>
      <Footer />
    </>
  );
};

const isValidProvider = (provider: string | undefined): boolean => SupportedProviders.includes(provider || "");

const DetailsPageOutlet = (): JSX.Element => {
  const { resourceType, provider } = useParams();

  if (!isValidProvider(provider)) {
    return <ResourceErrorPage errorDetails={[Messages.resourceErrors.invalidProvider(provider || "")]} />;
  }

  switch (provider) {
    case ORACLE_ADBS_PROVIDER_PATH:
      return <AdbsDetailsPage />;
    case ORACLE_DB_PROVIDER_PATH:
      switch (resourceType as IdResourceType) {
        case IdResourceType.DATABASES:
          return <ExaDbCdbDetailsPage />;
        case IdResourceType.PLUGGABLE_DATABASES:
          return <ExaDbPdbDetailsPage />;
        case IdResourceType.CLOUD_EXADATA_INFRAS:
          return <ExaInfraDetailsPage />;
        case IdResourceType.CLOUD_VM_CLUSTERS:
          return <ExaVmClusterDetailsPage />;
        default:
          return (
            <ResourceErrorPage
              errorDetails={[Messages.resourceErrors.invalidResourceType(resourceType || "", provider || "")]}
            />
          );
      }
    case ORACLE_VMDB_PROVIDER_PATH:
      switch (resourceType as IdResourceType) {
        case IdResourceType.PLUGGABLE_DATABASES:
          return <VmDbPdbDetailsPage />;
        case IdResourceType.DATABASES:
          return <VmDbCdbDetailsPage />;
        case IdResourceType.DB_SYSTEMS:
          return <VmDbSystemDetailsPage />;
        default:
          return (
            <ResourceErrorPage
              errorDetails={[Messages.resourceErrors.invalidResourceType(resourceType || "", provider || "")]}
            />
          );
      }
    case ORACLE_MYSQL_PROVIDER_PATH:
      switch (resourceType as IdResourceType) {
        case IdResourceType.DB_SYSTEMS:
          return <MysqlDetailsPage />;
        case IdResourceType.BACKUPS:
          return <MysqlBackupDetailsPage />;
        default:
          return (
            <ResourceErrorPage
              errorDetails={[Messages.resourceErrors.invalidResourceType(resourceType || "", provider || "")]}
            />
          );
      }
    case ORACLE_NET_PROVIDER_PATH:
      return <McvcnDetailsPage />;
    case ORACLE_DEPLOYMENT_PROVIDER_PATH:
      return <DeploymentDetailsPage />;
    default:
      return <ResourceErrorPage errorDetails={[Messages.resourceErrors.invalidProvider(provider || "")]} />;
  }
};

const DetailsPageOutletNoResourceGroup = (): JSX.Element => {
  const { resourceType, provider } = useParams();

  if (!isValidProvider(provider)) {
    return <ResourceErrorPage errorDetails={[Messages.resourceErrors.invalidProvider(provider || "")]} />;
  }

  switch (resourceType as IdResourceType) {
    case IdResourceType.INCIDENTS:
      return <SupportDetailsPage />;
    default:
      return <div />;
  }
};

export interface AppProps {
  regionName: string;
  isCanarySession?: boolean;
}

export const App = ({ regionName, isCanarySession }: AppProps): JSX.Element => {
  // const [settingsPageState, setSettingsPageState] = React.useState<SettingsPageState>(SettingsPageState.CLOSED);

  React.useEffect(() => {
    initNotificationManager(asyncNotificationMethodProvider, asyncNotificationPolledResponseProvider);
  }, []);

  const appState = {
    regionName,
    isCanarySession,
    basePath: BASE_ROUTE,
    homeKey: PageId.HOME,
    homePath: HOME_ROUTE,
    headerHeight: topHeaderHeight,
    footerHeight,
  } as ConsoleState;

  registerPages();

  // const onSettingsPageOpen = (): void => setSettingsPageState(SettingsPageState.OPENED);
  // const onSettingsPageClose = (): void => setSettingsPageState(SettingsPageState.CLOSED);

  const ListRoutes: RouteObject[] = [
    {
      path: APEX_DATABASES_RESOURCE_TYPE_PATH,
      element: <ApexListPage />,
    },
    {
      path: MYSQL_DATABASES_RESOURCE_TYPE_PATH,
      element: <MysqlListPage />,
    },
    {
      path: MCVCNS_RESOURCE_TYPE_PATH,
      element: <McvcnListPage />,
    },
    {
      path: DEPLOYMENTS_RESOURCE_TYPE_PATH,
      element: <DeploymentListPage />,
    },
  ];

  const BrowseRoutes: RouteObjectExt[] = [
    {
      path: `${VMDB_HUB_PATH}/:listId`,
      element: <VmDbListHubPage />,
      getAnalyticsPath: getRouteAnaylticsPath,
    },
    {
      path: `${EXADATA_HUB_PATH}/:listId`,
      element: <ExaListHubPage />,
      getAnalyticsPath: getRouteAnaylticsPath,
    },
    {
      path: `${ADB_HUB_PATH}/:listId`,
      element: <AdbListHubPage />,
      getAnalyticsPath: getRouteAnaylticsPath,
    },
    {
      path: RESOURCE_TYPE_PATH,
      children: ListRoutes,
    },
  ];

  const DetailsRoutes: RouteObjectExt[] = [
    {
      // eslint-disable-next-line max-len
      path: "subscriptions/:subscriptionId/resourceGroups/:resourceGroup/providers/:provider/:resourceType/:resourceName/:panelId",
      element: <DetailsPageOutlet />,
      getAnalyticsPath: getRouteAnaylticsPath,
    },
    {
      path: "subscriptions/:subscriptionId/providers/:provider/:resourceType/:resourceName/:panelId",
      element: <DetailsPageOutletNoResourceGroup />,
      getAnalyticsPath: getRouteAnaylticsPath,
    },
  ];

  const CreateRoutes: RouteObjectExt[] = [
    {
      path: HUB_PATH,
      element: <CreateAResourcePage />,
    },
    {
      path: AUTONOMOUS_DATABASE_SHARED_RESOURCE_TYPE_PATH,
      element: <AdbsCreatePage />,
    },
    {
      path: APEX_DATABASE_RESOURCE_TYPE_PATH,
      element: <AdbsCreatePage />,
    },
    {
      path: DATABASE_RESOURCE_TYPE_PATH,
      element: <ExaDbCreatePage />,
    },
    {
      path: EXADATA_SYSTEM_RESOURCE_TYPE_PATH,
      element: <ExaDbCreatePage createFlowType={ExadataCreateFlowType.EXADATA_SYSTEM} />,
    },
    {
      path: VM_CLUSTERS_RESOURCE_TYPE_PATH,
      element: <ExaVmClusterCreatePage />,
    },
    {
      path: EXADATA_INFRAS_RESOURCE_TYPE_PATH,
      element: <ExaInfraCreatePage />,
    },
    {
      path: VM_DATABASE_RESOURCE_TYPE_PATH,
      element: <VmDbCreatePage />,
    },
    {
      path: MYSQL_DATABASE_RESOURCE_TYPE_PATH,
      element: <MysqlCreatePage />,
    },
    {
      path: MCVCN_RESOURCE_TYPE_PATH,
      element: <McvcnCreatePage />,
    },
  ];

  const SupportRoutes: RouteObject[] = [
    {
      index: true,
      element: <SupportListPage />,
    },
    {
      path: CREATE_PATH,
      element: <SupportCreatePage />,
    },
  ];

  const ConsoleRoutes: RouteObjectExt[] = [
    {
      path: "/",
      element: <Navigate replace to={HOME_ROUTE} />,
    },
    {
      path: HOME_PATH,
      element: <HomePage />,
    },
    {
      path: RECENT_RESOURCES_PATH,
      element: <RecentListPage />,
    },
    {
      path: FAVORITE_RESOURCES_PATH,
      element: <FavoriteListPage />,
    },
    // {
    //   path: `${SETTINGS_PATH}/:panelId`,
    //   element: (
    //     <SettingsPage
    //       onOpen={onSettingsPageOpen}
    //       onClose={onSettingsPageClose}
    //     />
    //   ),
    //   getAnalyticsPath: getRouteAnaylticsPath,
    // },
    {
      path: CLOUDLINK_LIST_PATH,
      element: <CloudLinkListPage />,
    },
    {
      path: SUPPORT_PATH,
      children: SupportRoutes,
    },
    {
      path: BILLING_PATH,
      element: <BillingPage />,
    },
    {
      path: BROWSE_RESOURCE_PATH,
      children: BrowseRoutes,
    },
    {
      path: RESOURCE_PATH,
      children: DetailsRoutes,
    },
    {
      path: CREATE_PATH,
      children: CreateRoutes,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ];

  const AllRoutes: RouteObject[] = [
    {
      // element: <Console settingsPageState={settingsPageState} routes={ConsoleRoutes} />,
      element: <Console routes={ConsoleRoutes} />,
      children: ConsoleRoutes,
    },
  ];

  const getRoutingTree = (route: RouteObject, key: string): JSX.Element => {
    const children = route.children?.map((childRoute, childKey) => getRoutingTree(childRoute, childKey.toString()));

    return (
      <Route key={key.toString()} index={route.index} path={route.path} element={route.element as JSX.Element}>
        {children}
      </Route>
    );
  };

  return (
    <ScreenSizeContextProvider>
      <ConsoleContextProvider value={appState}>
        <NotificationProvider>
          <OperationsProvider>
            <SettingsProvider>
              <FilterStateProvider>
                <UserActivityMonitor />
                <BrowserRouter basename={BASE_ROUTE}>
                  <Routes>
                    {AllRoutes.map((route, key) => getRoutingTree(route, key.toString()))}
                  </Routes>
                </BrowserRouter>
              </FilterStateProvider>
            </SettingsProvider>
          </OperationsProvider>
        </NotificationProvider>
      </ConsoleContextProvider>
    </ScreenSizeContextProvider>
  );
};
