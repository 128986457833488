import {
  ActionType,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingDisplayNameLink,
  optimizedRetryOption,
  SortDirections,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { DetailsPanelId, InfoPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { DbHomeSummary, DbHomeSummaryCollection } from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import { getStatusInfo, responseItemstoArray, statusSortComparator } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import {
  ExaVmClusterViewDbHomeProps,
  newExaVmClusterViewDbHome,
} from "../../operations/ExaVmCluster/ExaVmClusterViewDbHome";
import { getOciRegion } from "../../utils";

export interface ExaVmClusterDbHomesListProps {
  vmClusterId: string | undefined;
  location: string;
}

export enum ColumnIds {
  Name = "name",
  ResourceStatus = "status",
  DbVersion = "dbVersion",
  DbHomeLocation = "dbHomeLocation",
}

export enum ColumnTestIds {
  Name = "name",
  ResourceStatus = "status",
  DbVersion = "dbVersion",
  DbHomeLocation = "dbHomeLocation",
}

export enum ActionIds {
  Refresh = "refresh",
}

export enum ActionBarTestIds {
  Refresh = "refresh"
}

export const ExaVmClusterDbHomesList = ({ vmClusterId, location }: ExaVmClusterDbHomesListProps): JSX.Element => {
  const { trackActionClick, trackLinkNavigate } = useAnalytics();

  const { locale } = React.useContext<Settings>(SettingsContext);

  const { subscriptions } = useSubscriptions();

  const idComps = parseId(vmClusterId);
  const { subscriptionId, resourceGroup } = idComps;

  const { response, loading, refresh } = useQueryCall({
    wait: !vmClusterId,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDbHomes,
    options: {
      args: {
        vmClusterId,
        subscriptionId,
        resourceGroupName: resourceGroup,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDatabaseHomes(),
      },
    },
  });

  const dbHomeItems = (response
    && responseItemstoArray<DbHomeSummaryCollection, DbHomeSummary>(response)) || [];

  const getStatus = (value: DbHomeSummary): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
        )
      }
      hideClipboardCopy
    />
  );

  const { trigger: triggerViewDbHome } = useOperation<ExaVmClusterViewDbHomeProps>(newExaVmClusterViewDbHome);

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 2,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.name}
          navigation={{
            onClick: () => {
              trackLinkNavigate(InfoPanelId.DBHOME, PageId.VMCLUSTER_DETAILS, DetailsPanelId.DATABASEHOMES);
              triggerViewDbHome({
                dbHomeId: value.id,
                subscriptions: subscriptions || [],
                location,
                analytics: { pageId: PageId.VMCLUSTER_DETAILS, panelId: DetailsPanelId.DATABASEHOMES },
              });
            },
          }}
        />
      ),
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: DbHomeSummary,
        b: DbHomeSummary,
      ) => statusSortComparator<DbHomeSummary>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.DbVersion,
      testId: ColumnTestIds.DbVersion,
      name: Messages.labels.databaseVersion(),
      flexGrow: 1,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
    },
    {
      itemProp: ColumnIds.DbHomeLocation,
      testId: ColumnTestIds.DbHomeLocation,
      name: Messages.labels.databaseHomeLocation(),
      flexGrow: 2,
      isResizable: true,
    },
  ];

  const items = React.useMemo(() => dbHomeItems?.map(dbHome => ({
    id: dbHome?.id,
    name: dbHome?.name,
    status: dbHome?.status,
    dbVersion: dbHome?.dbVersion,
    dbHomeLocation: dbHome?.dbHomeLocation,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })) || [], [JSON.stringify(dbHomeItems)]);

  const dbActions: ActionType[] = [
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.VMCLUSTER_DETAILS, DetailsPanelId.DATABASEHOMES);
        refresh();
      },
    },
  ];
  const { closePanels } = useSidePanel();

  return (

    <Listing
      items={items}
      testId={ListingTestIds.ExaVmClusterDbHomes}
      emptyList={{ title: Messages.common.noResults() }}
      isLoading={loading}
      listColumns={columns}
      onActionClick={closePanels}
      actionBarItems={dbActions}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Name,
      }}
    />

  );
};
