/* eslint-disable object-curly-newline */
import {
  FormValues,
  getValue,
  InputFormGroup,
  InputFormSidePanelComponent,
  RadioGroupOption,
  SelectOption,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  Fields as NetworkAddressFields,
} from "../../components/AdbsNetworkAddressCollection/AdbsNetworkAddressCollection";
import {
  AdbsEditNetworkingValues,
  AdbsNetworkingEditPanel,
  Fields,
} from "../../components/AdbsNetworkingEditPanel/AdbsNetworkingEditPanel";
import { UpdateAdbsDatabaseDetails } from "../../gen/clients/mchub-azure-api-client";
import { parseId } from "../../helpers/idHelper";
import { ACCESS_TYPES, AdbWorkloadType } from "../../helpers/resourceHelper";
import { useFeatures } from "../../hooks/useFeatures";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  ADBS_UPDATE_POLL_DELAY,
  ADBS_UPDATE_POLL_INTERVAL,
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface AdbsEditNetworkAccessProps extends OperationProps {
  databaseId: string | undefined;
  workload: AdbWorkloadType | undefined;
  adbsEditNetworkingValues: AdbsEditNetworkingValues;
}

const AdbsEditNetworkAccess = (
  { workload, location, databaseId, adbsEditNetworkingValues, onExecute, onCancel }
    : AdbsEditNetworkAccessProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const {
    enableAdbsOpenPL,
    enableAdbsRestrictedPL,
    enableAdbsAzurePE,
    enableMcvcn,
    enableAdbsAzurePEPeering,
  } = useFeatures();

  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const { invokeCall } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.updateAdbsDatabase },
  );

  const onSubmit = (formValues: FormValues): void => {
    let whitelistedIps: string[] | undefined;
    let vnetId = null;
    let subnetId = null;
    let mcvcnId = null;
    let hostnamePrefix = null;
    const accessType = getValue<RadioGroupOption>(formValues, Fields.AccessType, InputFormGroup)?.id;
    const isRouteVNET = getValue<boolean>(formValues, Fields.RouteVNET, InputFormGroup);
    const isNetworkPeeringChecked = enableMcvcn && enableAdbsAzurePEPeering
      && getValue<boolean>(formValues, Fields.NetworkPeering, InputFormGroup);
    let networkAccessType = accessType;

    if (accessType === ACCESS_TYPES.ALLOWED_IPS) {
      whitelistedIps = getValue<FormValues[]>(formValues, Fields.IPsCidrs, InputFormGroup)
        ?.filter((ele: FormValues) => ele[NetworkAddressFields.Address] !== undefined)
        .map((ele: FormValues) => ele[NetworkAddressFields.Address]);
      if (enableAdbsRestrictedPL) {
        vnetId = isRouteVNET ? getValue<SelectOption[]>(formValues, Fields.VNET, InputFormGroup)?.[0].id : "";
        subnetId = isRouteVNET ? getValue<SelectOption[]>(formValues, Fields.Subnet, InputFormGroup)?.[0].id : "";
      }
    } else if (accessType === ACCESS_TYPES.EVERYWHERE) {
      // Back end expects empty ips array for everywhere case when networkAccessType attribute
      // is not supported. If networkAccessType attribute supported (enableAdbsAzurePE flag) then
      // that enum is used to determine the type and ips should not be passed
      whitelistedIps = enableAdbsAzurePE ? undefined : [];
      if (enableAdbsOpenPL) {
        vnetId = isRouteVNET ? getValue<SelectOption[]>(formValues, Fields.VNET, InputFormGroup)?.[0].id : "";
        subnetId = isRouteVNET ? getValue<SelectOption[]>(formValues, Fields.Subnet, InputFormGroup)?.[0].id : "";
      }
    } else if (accessType === ACCESS_TYPES.PRIVATE && enableAdbsAzurePE) {
      if (isNetworkPeeringChecked) {
        mcvcnId = getValue<SelectOption[]>(formValues, Fields.Mcvcn, InputFormGroup)?.[0].id;
        networkAccessType = ACCESS_TYPES.PRIVATE_PEERING;
      } else {
        vnetId = getValue<SelectOption[]>(formValues, Fields.VNET, InputFormGroup)?.[0].id;
        subnetId = getValue<SelectOption[]>(formValues, Fields.Subnet, InputFormGroup)?.[0].id;
      }
      hostnamePrefix = getValue<string>(formValues, Fields.HostName, InputFormGroup);
    }

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateAdbsNetworking(),
          message: Messages.notifications.inProgress.messages.updateAdbsNetworking(resName),
        },
        success: {
          title: Messages.notifications.success.titles.updateAdbsNetworking(),
          message: Messages.notifications.success.messages.updateAdbsNetworking(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateAdbsNetworking(),
          message: Messages.notifications.failure.messages.updateAdbsNetworking(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.ADBS_GET,
          methodArgs: { subscriptionId, resourceGroupName: resGroup, databaseName: resName },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.ADBS_UPDATED_CHECK,
          delay: ADBS_UPDATE_POLL_DELAY,
          interval: ADBS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeCall({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName: resName,
      updateAdbsDatabaseDetails: {
        whitelistedIps,
        mcvcnId,
        vnetId,
        subnetId,
        hostnamePrefix,
        networkAccessType: enableAdbsAzurePE ? networkAccessType : undefined,
      } as UpdateAdbsDatabaseDetails,
    }, invokeOptions);
  };

  return (
    <AdbsNetworkingEditPanel
      workload={workload}
      adbsEditNetworkingValues={adbsEditNetworkingValues}
      onSubmit={onSubmit}
      componentRef={setSidePanelRef}
      onClose={onCancel}
      subscriptionId={subscriptionId}
      location={location}
    />
  );
};

export const newAdbsEditNetworkAccess = (
  props: AdbsEditNetworkAccessProps,
): JSX.Element => (<AdbsEditNetworkAccess {...props} />);
