import {
  FieldSet,
  FormValues,
  getValue,
  IconGroup,
  IconGroupOption,
  IconType,
  InputFormGroup,
  InputFormInline,
  OptionOrientation,
  SubmitButtonMode,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { mergeStyleSets, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext, SettingType } from "../../console/SettingsContext";
import { DetailsPanelId, InlineFormId, PageId } from "../../constants/pluginConstants";
import { InputFormInlineTestIds, MenuStyleMode, SvgIconIds } from "../../constants/uiConstants";
import { useAnalytics } from "../../hooks/useAnalytics";

const classNames = mergeStyleSets({
  menuBehaviorContainer: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    paddingBottom: "15px",
    marginBottom: "15px",
  },
});

export enum Fields {
  Appearance = "appearance"
}

export enum FieldTestIds {
  Appearance = "appearance"
}

export enum RadioInputTestIds {
  Flyout = "flyout",
  Docked = "docked",
}

export const SettingAppearance = (): JSX.Element => {
  const { trackFormSave } = useAnalytics();
  const { menuMode } = React.useContext<Settings>(SettingsContext);
  const [formKey, setFormKey] = React.useState<string>(uniqueGUID());
  const { setSettingValue } = React.useContext<Settings>(SettingsContext);
  const [initialValues, setInitialValues] = React.useState<FormValues>({ [Fields.Appearance]: menuMode });

  const onSubmit = (formValues: FormValues): void => {
    const selectedMenuMode = getValue<IconGroupOption>(formValues, Fields.Appearance, InputFormGroup)?.id;
    trackFormSave(InlineFormId.SETTING_APPEARANCE, PageId.SETTINGS, DetailsPanelId.APPEARANCE);
    setSettingValue(SettingType.MENU_MODE, selectedMenuMode);
    setInitialValues({ [Fields.Appearance]: selectedMenuMode });
    setFormKey(uniqueGUID());
  };
  const options: IconGroupOption[] = [
    {
      id: MenuStyleMode.FlyOut,
      testId: RadioInputTestIds.Flyout,
      text: Messages.labels.flyout(),
      iconName: SvgIconIds.flyoutMenuSvg,
    },
    {
      id: MenuStyleMode.Docking,
      testId: RadioInputTestIds.Docked,
      text: Messages.labels.docked(),
      iconName: SvgIconIds.dockMenuSvg,
    },
  ];

  return (
    <InputFormInline
      key={formKey}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onDiscard={() => setFormKey(uniqueGUID)}
      testId={InputFormInlineTestIds.SettingAppearance}
      initialValues={initialValues}
      submitButtonText={Messages.actions.apply()}
      discardButtonText={Messages.actions.discardChanges()}
    >
      <FieldSet header={Messages.labels.appearance()}>
        <Stack className={classNames.menuBehaviorContainer}>
          <IconGroup
            defaultValue={menuMode}
            label={Messages.labels.menuBehavior()}
            options={options}
            fieldName={Fields.Appearance}
            groupName={InputFormGroup}
            optionOrientation={OptionOrientation.HORIZONTAL}
            testId={FieldTestIds.Appearance}
            iconType={IconType.SVG}
            iconStyles={{ selectedBackgroundColor: "transparent", borderOnHover: true }}
          />
        </Stack>
      </FieldSet>
    </InputFormInline>
  );
};
