import {
  AsyncFieldError,
  AsyncValidationError,
  BookmarkablePage,
  FormValues,
  getValue,
  InputWizardItemMessage,
  InputWizardPanel,
  InputWizardPanelComponent,
  MessageType,
  PanelMessage,
  ReviewItem,
  SelectOption,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ExadataDbSystemShapeSelectFields } from "../../components/DbSystemShapeSelect/ExadataDbSystemShapeSelect";
import { DeploymentAsyncValidationPhase } from "../../components/DeploymentPhases/DeploymentAsyncValidationPhase";
import { DeployedService, DeploymentCreatePhase } from "../../components/DeploymentPhases/DeploymentCreationPhase";
import { GROUP_TAGS } from "../../components/Tags/TagsCollection";
import { ConsoleContext } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  EXADATA_INFRAS_RESOURCE_TYPE_PATH,
  EXAINFRA_CREATE_ROUTE,
  PageId,
  PageRegistrationConfig,
} from "../../constants/pluginConstants";
import { CreateWizardTestIds } from "../../constants/uiConstants";
import {
  CreateCloudExadataInfrastructureDeploymentDetails,
  ExadataDeploymentParametersDetails,
} from "../../gen/clients/mchub-azure-api-client";
import {
  DeploymentKinds,
  getResourceTypeMessage,
  getTagsPerResourceTypeMap,
  ResourceType,
  TagsInfoType,
} from "../../helpers/resourceHelper";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { getTimestamp } from "../../helpers/timeHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useRoles } from "../../hooks/useRoles";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXAINFRA_DEPLOYMENT_CREATE_POLL_DELAY,
  EXAINFRA_DEPLOYMENT_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { ReviewTabContent } from "../CreateCommon/ReviewTabContent";
import { Fields as TagsFields, TagsTabContent } from "../CreateCommon/TagsTabContent";
import { BasicsTabComponent, BasicsTabContent, Fields as BasicsFields, GROUP_BASICS } from "./BasicsTabContent";
import { ConfigTabContent, Fields as ConfigFields, GROUP_CONFIG } from "./ConfigTabContent";

const registrationIds = PageRegistrationConfig[PageId.EXAINFRA_CREATE].map(config => config.key);

export enum PanelTestIds {
  Basics = "basic",
  Configuration = "configuration",
  Tags = "tags",
}

export const onProcessAsyncValidationErrors = (
  asyncErrors: AsyncValidationError[],
): AsyncValidationError[] => asyncErrors.map(asyncError => {
  if (typeof asyncError === "string") {
    return asyncError;
  }
  const asyncFieldError = asyncError as AsyncFieldError;
  if (asyncFieldError.field === "cloudExadataInfrastructureDetails.computeCount"
   || asyncFieldError.field === "cloudExadataInfrastructureDetails.storageCount") {
    asyncFieldError.field = ConfigFields.ExadataSystemModel;
  }

  return asyncFieldError;
});

enum CreationPhase {
  ASYNC_VALIDATE = "ASYNC_VALIDATE",
  CREATE_SUBMIT = "CREATE_SUBMIT",
}

export const ExaInfraCreatePage = (): JSX.Element => {
  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const isCreateRoleMissing = !isActionAllowed(RoleBasedAction.CREATE_EXA_INFRA);
  const requiredRole = actionRequiredRoles(RoleBasedAction.CREATE_EXA_INFRA)[0]?.displayName;

  const { trackActionDiscard } = useAnalytics();

  const { back, navigateToSelf, customData } = useNavigation(ConsoleContext);

  const { preferredLocationPerSubscription, preferredSubscription } = React.useContext<Settings>(SettingsContext);
  const preferredLocation = preferredLocationPerSubscription[preferredSubscription] || "";

  const [subscriptionId, setSubscriptionId] = React.useState<string>("");
  const [resourceGroupName, setResourceGroupName] = React.useState<string>("");
  const [location, setLocation] = React.useState<string>("");
  const { enableExaInfraPreflight } = useFeatures();

  const resourceNameRef = React.useRef<string>(""); // to hold the name to be used in failure message
  const deploymentNameRef = React.useRef<string>("");

  React.useEffect(() => {
    setLocation(preferredLocation);
    setSubscriptionId(preferredSubscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // In case page was navigated to directly by entering its URL in the browser
    navigateToSelf(EXAINFRA_CREATE_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [panelRef, setPanelRef] = React.useState<InputWizardPanelComponent>({} as InputWizardPanelComponent);

  const basicsTabRef = React.useRef<BasicsTabComponent>();
  const setBasicsTabRef = (basicTab: BasicsTabComponent): void => {
    basicsTabRef.current = basicTab;
  };
  const [creationPhase, setCreationPhase] = React.useState<CreationPhase | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [createExaInfraPayload, setCreateExaInfraPayload] = React.useState<any>();
  const asyncValidationCallbacks = React.useRef<{
    resolve:(errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ resolve: () => {}, reject: () => {} });
  const submitCallbacks = React.useRef<{
        reject:(errorMessage: string, validationErrors?: AsyncValidationError[]) => void
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          }>({ reject: () => {} });

  const onClose = (): void => back();
  const onAsyncValidate = (
    formValues: FormValues,
    resolve: (errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
  ): void => {
    setCreateExaInfraPayload(buildCreateExaInfraPayload(formValues));
    asyncValidationCallbacks.current = { resolve, reject };
    setCreationPhase(CreationPhase.ASYNC_VALIDATE);
  };

  const onSubmit = (formValues: FormValues): void => {
    setCreateExaInfraPayload(buildCreateExaInfraPayload(formValues));
    setCreationPhase(CreationPhase.CREATE_SUBMIT);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildCreateExaInfraPayload = (formValues: FormValues): any => {
    const name = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS) ?? "";
    resourceNameRef.current = name;
    const newComputeCount = getValue<string>(
      formValues,
      ExadataDbSystemShapeSelectFields.DatabaseServers,
      GROUP_CONFIG,
    );
    const computeCount = newComputeCount ? parseInt(newComputeCount, 10) : 0;
    const newStorageCount = getValue<string>(formValues, ExadataDbSystemShapeSelectFields.StorageServers, GROUP_CONFIG);
    const storageCount = newStorageCount ? parseInt(newStorageCount, 10) : 0;
    const tags = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS);
    const tagsMap = getTagsPerResourceTypeMap(tags);
    const shape = getValue<SelectOption[]>(formValues, ConfigFields.ExadataSystemModel, GROUP_CONFIG)?.[0].id ?? "";

    const cloudExadataInfrastructureDetails: CreateCloudExadataInfrastructureDeploymentDetails = {
      kind: DeploymentKinds.Create,
      name,
      computeCount: computeCount > 0 ? computeCount : undefined,
      storageCount: storageCount > 0 ? storageCount : undefined,
      shape,
      maintenanceWindow: { preference: "NO_PREFERENCE" },
      freeformTags: tagsMap[ResourceType.INFRA],
    };
    deploymentNameRef.current = `${EXADATA_INFRAS_RESOURCE_TYPE_PATH}-${getTimestamp()}`;
    const createDeploymentDetails = {
      name: deploymentNameRef.current,
      parameters: {
        kind: DeploymentKinds.Exadata,
        cloudExadataInfrastructureDetails,
      } as ExadataDeploymentParametersDetails,
    };

    const payload = ({
      subscriptionId: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].id,
      resourceGroupName: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      apiVersion: MultiCloudDatabaseApiVersion,
      createDeploymentDetails,
    });
    return payload;
  };

  const buildBasicInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewBasicInfo = [
      {
        label: Messages.labels.subscription(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.resourceGroup(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.name(),
        value: getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS),
      },
      {
        label: Messages.labels.region(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Region, GROUP_BASICS)?.[0].text,

      },
    ];
    return reviewBasicInfo;
  };

  const buildConfigInfo = (formValues: FormValues): ReviewItem[] => {
    const newDatabaseServers = getValue<string>(
      formValues,
      ExadataDbSystemShapeSelectFields.DatabaseServers,
      GROUP_CONFIG,
    );
    const databaseServers = newDatabaseServers ? parseInt(newDatabaseServers, 10) : 0;
    const newStorageServers = getValue<string>(
      formValues,
      ExadataDbSystemShapeSelectFields.StorageServers,
      GROUP_CONFIG,
    );
    const storageServers = newStorageServers ? parseInt(newStorageServers, 10) : 0;

    const reviewConfigInfo = [
      {
        label: Messages.labels.exadataSystemModel(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.ExadataSystemModel, GROUP_CONFIG)?.[0].text,
      },
    ];
    if (databaseServers > 0) {
      reviewConfigInfo.push(
        {
          label: Messages.labels.databaseServers(),
          value: newDatabaseServers,
        },
      );
    }
    if (storageServers > 0) {
      reviewConfigInfo.push({ label: Messages.labels.storageServers(), value: newStorageServers });
    }

    return reviewConfigInfo;
  };

  const buildTagsInfo = (formValues: FormValues): ReviewItem[] => {
    const tagsInfo = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS) ?? [];
    const tags: { label: string; value: string; }[] = [];

    tagsInfo.forEach(element => (
      element.resources?.forEach(resource => (
        element.name !== undefined && tags.push({
          label: element.name,
          value: `${element.value !== undefined ? element.value : ""}(${resource.text})`,
        })
      ))));
    if (tags.length === 0) tags.push({ label: "None", value: "" });
    tags.sort((a, b) => ((a.value.split("(")[1].split(")")[0] > b.value.split("(")[1].split(")")[0]) ? 1 : -1));
    return tags;
  };

  const getTagsResourceTypeOptions = (): SelectOption[] => {
    const resourceOptions: SelectOption[] = [
      { id: ResourceType.INFRA, text: getResourceTypeMessage(ResourceType.INFRA) }];

    return resourceOptions;
  };

  const onRenderReview = (formValues: FormValues): JSX.Element => {
    const reviewSections = [];
    reviewSections.push(
      { title: Messages.createInfra.basicsTab.title(), items: buildBasicInfo(formValues) },
      { title: Messages.createExaDb.configurationTab.title(), items: buildConfigInfo(formValues) },
      { title: Messages.tags.title(), items: buildTagsInfo(formValues) },
    );
    return <ReviewTabContent reviewSections={reviewSections} />;
  };

  const wizardItems = [];
  wizardItems.push({
    header: Messages.createInfra.basicsTab.title(),
    groupName: GROUP_BASICS,
    testId: PanelTestIds.Basics,
    content: (
      <BasicsTabContent
        inputWizardPanelRef={panelRef}
        componentRef={setBasicsTabRef}
        subscription={subscriptionId}
        location={location}
      />
    ),
    onUnselecting: () => basicsTabRef.current?.closeSidePanels(),
  });
  wizardItems.push({
    header: Messages.createInfra.configurationTab.title(),
    groupName: GROUP_CONFIG,
    testId: PanelTestIds.Configuration,
    content: (
      <ConfigTabContent
        inputWizardPanelRef={panelRef}
        subscriptionId={subscriptionId}
        location={location}
      />
    ),
  });
  wizardItems.push({
    header: Messages.tags.title(),
    groupName: GROUP_TAGS,
    testId: PanelTestIds.Tags,
    content: <TagsTabContent resourceOptions={getTagsResourceTypeOptions()} />,
  });

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.createInfra.titles.short()}
    >
      <InputWizardPanel
        componentRef={setPanelRef}
        testId={CreateWizardTestIds.ExaInfra}
        title={Messages.createInfra.titles.long()}
        message={isCreateRoleMissing
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(requiredRole),
          } as PanelMessage
          : undefined}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFormChange={(_formValues: FormValues, fieldValue: any, field: string) => {
          if (field === BasicsFields.Subscription) {
            setSubscriptionId(fieldValue?.[0]?.id);
          } else if (field === BasicsFields.ResourceGroup) {
            setResourceGroupName(fieldValue?.[0]?.text);
          } else if (field === BasicsFields.Region) {
            setLocation(fieldValue?.[0]?.id);
          }
        }}
        items={wizardItems}
        itemsMessages={[{
          itemNdx: 0,
          type: MessageType.WARNING,
          message: Messages.createCommon.dependencyWarnings.basicsChangeWarning(Messages.labels.exadataInfra()),
          hideOnFirstSelect: true,
        }] as InputWizardItemMessage[]}
        onRenderReview={onRenderReview}
        onUnselectingReview={() => {
          // Reset to cover the case of the user navigating away from the review tab before the results come back
          setCreateExaInfraPayload(undefined);
          setCreationPhase(undefined);
        }}
        onAsyncValidate={enableExaInfraPreflight ? onAsyncValidate : undefined}
        onSubmit={(
          formValues: FormValues,
          reject: (errorMessage: string, validationErrors?: AsyncValidationError[]) => void,
        ) => {
          submitCallbacks.current = { reject };
          onSubmit(formValues);
        }}
        onClose={() => {
          const analytics = customData?.analytics as NavigationAnalyticsData;
          if (analytics) {
            trackActionDiscard(analytics.actionName, analytics.pageId, analytics.panelId);
          }
          onClose();
        }}
      />
      {creationPhase === CreationPhase.CREATE_SUBMIT && (
        <DeploymentCreatePhase
          panelRef={panelRef}
          deployedService={DeployedService.EXADATA_INFRA}
          subscriptionId={subscriptionId}
          resourceGroupName={resourceGroupName}
          location={location}
          resourceName={resourceNameRef.current}
          deploymentName={deploymentNameRef.current}
          submitPayload={createExaInfraPayload}
          submitReject={submitCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          asyncNotification={{
            methodKey: AsyncNotificationMethodKey.DEPLOYMENT_GET,
            delay: EXAINFRA_DEPLOYMENT_CREATE_POLL_DELAY,
            pollingInterval: EXAINFRA_DEPLOYMENT_CREATE_POLL_INTERVAL,
            polledResponseKey: AsyncNotificationPolledResponseKey.EXAINFRA_DEPLOYMENT_CREATED_CHECK,
          }}
          onPostSubmit={() => {
            setCreateExaInfraPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
      {creationPhase === CreationPhase.ASYNC_VALIDATE && (
        <DeploymentAsyncValidationPhase
          location={location}
          asyncValidatePayload={createExaInfraPayload}
          asyncValidateResolve={asyncValidationCallbacks.current.resolve}
          asyncValidateReject={asyncValidationCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          onPostAsyncValidate={() => {
            setCreateExaInfraPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
    </BookmarkablePage>
  );
};
