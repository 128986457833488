import { BookmarkablePage, FullPagePanel, useNavigation } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { CLOUDLINK_LIST_ROUTE, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { SvgIconIds } from "../../constants/uiConstants";
import { CloudLinkList } from "./CloudLinkList";

const registrationIds = PageRegistrationConfig[PageId.CLOUDLINK_LIST].map(config => config.key);

export const CloudLinkListPage = (): JSX.Element => {
  const { back, navigateToSelf } = useNavigation(ConsoleContext);

  const onClose = (): void => back();

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(CLOUDLINK_LIST_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.listCloudLink.titles.short()}
    >
      <FullPagePanel
        title={Messages.listCloudLink.titles.long()}
        icon={SvgIconIds.subscriptionSvg}
        onClose={onClose}
        isOpen
      >
        <CloudLinkList />
      </FullPagePanel>
    </BookmarkablePage>
  );
};
