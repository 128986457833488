import React from "react";
import { Link, mergeStyleSets, Stack } from "@fluentui/react";
import * as Messages from "../codegen/Messages";
import { footerHeight } from "../constants/uiConstants";

const classNames = mergeStyleSets({
  footerWrapper: {
    background: "#FFFFFF",
    position: "fixed",
    width: "100%",
    zIndex: "1000",
  },
  footerContainer: {
    bottom: "0",
    padding: "5px 8px",
    borderTop: "1px solid #b5bbc2",
    fontSize: "11px",
    height: footerHeight,
    boxSizing: "border-box",
  },
  privacyLink: {
    marginRight: "18px",
    textDecoration: "none",
    width: "inherit",
    outline: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  trusteLink: {
    width: "inherit",
    textDecoration: "none",
    outline: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    borderBottom: "4px solid transparent",
  },
  copyrightText: {
    color: "#686868",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

const copyrightSymbol = "©";
const termsUrl = "http://www.oracle.com/legal/privacy/index.html";
const trusteScript = "//consent.truste.com/notice?domain=oracle.com&c=teconsent&text=true&gtm=1";

export enum LinkTestIds {
  Privacy = "privacy-link",
  Truste = "truste-link"
}

export const Footer = (): JSX.Element => {
  const date = new Date();
  const year = date.getFullYear().toString();
  const privacy = Messages.footer.termsOfUseAndPrivacy();
  const copyRight = Messages.footer.oracleCopyright(copyrightSymbol, year);

  return (
    <div className={classNames.footerWrapper}>
      <Stack horizontal horizontalAlign="space-between" className={classNames.footerContainer}>
        <Stack horizontal style={{ overflow: "hidden" }}>
          <Link
            className={classNames.privacyLink}
            href={termsUrl}
            target="_blank"
            title={privacy}
            data-test-id={LinkTestIds.Privacy}
          >
            {privacy}
          </Link>
          <Link
            className={classNames.trusteLink}
            id="teconsent"
            data-test-id={LinkTestIds.Truste}
          >
            <script async type="text/javascript" src={trusteScript} crossOrigin="" />
          </Link>
        </Stack>
        <Stack horizontal reversed style={{ overflow: "hidden" }}>
          <span className={classNames.copyrightText} title={copyRight}>
            {copyRight}
          </span>
        </Stack>
      </Stack>
    </div>
  );
};
