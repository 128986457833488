import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../apiClients";
import * as Messages from "../codegen/Messages";
import { ConsoleContext, ConsoleState } from "../console/ConsoleContext";
import { ttlOneMinCaching } from "../constants/uiConstants";
import { AzureResourceGroupCollection, AzureResourceGroupSummary } from "../gen/clients/mchub-azure-api-client";
import { responseItemstoArray } from "../helpers/resourceHelper";
import { useQueryCall } from "./useQueryCall";

export interface ResourceGroupsResult {
  loading: boolean;
  error?: NormalError;
  resourceGroups?: AzureResourceGroupSummary[];
  refresh: () => void;
}

// TODO: make subscriptionId mandatory after OCI session logic is cleaned up
export const useResourceGroups = (subscriptionId: string): ResourceGroupsResult => {
  const { regionName: homeRegionName } = React.useContext<ConsoleState>(ConsoleContext);

  const { response, loading, error, refresh } = useQueryCall({
    wait: !subscriptionId,
    method: apiClients.withRegion(homeRegionName).azResourceApi.listAzureResourceGroups,
    options: {
      args: { subscriptionId },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadResourceGroup(),
      },
    },
  });

  const resourceGroups = response
    && responseItemstoArray<AzureResourceGroupCollection, AzureResourceGroupSummary>(response);

  const result = React.useMemo(() => ({
    loading,
    error,
    refresh,
    resourceGroups,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [error, loading, refresh, JSON.stringify(resourceGroups)]);

  return result;
};
