import { CustomLink, NoValue } from "o4a-react";
import * as React from "react";
import { Link } from "@fluentui/react";
import { OciResourceType } from "../../helpers/resourceHelper";
import { AnalyticsDataPage, useAnalytics } from "../../hooks/useAnalytics";
import { AzureSubscriptionSummaryExt } from "../../hooks/useSubscriptions";
import { getOCIConsoleUrl, getOciRegion } from "../../utils";

export interface OciLinkProps {
  ociResourceType: OciResourceType;
  ociResourcePath: string | undefined;
  ociResourceName: string | undefined;
  location: string;
  subscription: AzureSubscriptionSummaryExt | undefined;
  analytics: AnalyticsDataPage;
  hideClipboard?: boolean;
}

export const OciLink = (
  // eslint-disable-next-line max-len
  { ociResourceType, ociResourcePath, ociResourceName, location, subscription, analytics, hideClipboard = false }: OciLinkProps,
): JSX.Element => {
  const { trackOciLinkNavigate } = useAnalytics();

  const ociConsoleUrl = getOCIConsoleUrl();
  const ociTenancyParam = subscription?.ociTenancyName ? `&tenant=${subscription.ociTenancyName}` : "";
  const ociDomainParam = subscription?.ociDomainName ? `&domain=${subscription.ociDomainName}` : "";
  const region = getOciRegion(location);
  const ociResourceUrl = `${ociConsoleUrl}${ociResourcePath}?region=${region}${ociTenancyParam}${ociDomainParam}`;

  if (!ociResourcePath || !ociResourceName) return <NoValue />;

  if (hideClipboard) {
    return (
      <Link
        title={ociResourceName}
        href={ociResourceUrl}
        target="_blank"
        onClick={() => trackOciLinkNavigate(ociResourceType, analytics.pageId, analytics.panelId)}
        underline
      >
        {ociResourceName}
      </Link>
    );
  }

  return (
    <CustomLink
      href={ociResourceUrl}
      target="_blank"
      onClick={() => trackOciLinkNavigate(ociResourceType, analytics.pageId, analytics.panelId)}
      underline
      linkText={ociResourceName}
    />
  );
};
