import {
  BookmarkablePage,
  DetailView,
  DetailViewsPanel,
  GroupItem,
  MessageType,
  PanelMessage,
  TitleDetails,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useParams } from "react-router-dom";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { ADB_LIST_HUB_ROUTE, DetailsPanelId, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { SvgIconIds } from "../../constants/uiConstants";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { useRoles } from "../../hooks/useRoles";
import { AdbsList } from "../AdbsListPage/AdbsList";

const registrationIds = PageRegistrationConfig[PageId.ADB_LIST_HUB].map(config => config.key);

const getViewId = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.ADBS_DATABASES:
    default:
      return registrationIds[0];
  }
};

const getIconName = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.ADBS_DATABASES:
    default:
      return SvgIconIds.adbSvg;
  }
};

export const AdbListHubPage = (): JSX.Element => {
  const { listId } = useParams();
  const filterState = React.useContext<FilterState>(FilterStateContext);

  const { back, navigateToSelf, navigateToPanel } = useNavigation(ConsoleContext);

  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const disableList = !isActionAllowed(RoleBasedAction.LIST_ADBS);
  const requiredRole = actionRequiredRoles(RoleBasedAction.LIST_ADBS)[0]?.displayName;

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.ADB_LIST_HUB].find(
      config => config.panelPath === listId,
    );
    const path = `${ADB_LIST_HUB_ROUTE}/${listId}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (filterState.pillFilterStateValue.listId !== listId) {
      filterState.setFilterStateValue({ filterValues: undefined, listId: undefined, defaultPills: undefined });
      filterState.setFilterTextValue(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuItemSelect = (id: string): void => navigateToPanel(id);

  const detailViews: DetailView[] = [
    {
      id: registrationIds[0],
      content: (<AdbsList disabled={disableList} />),
    },
  ];

  const groupItems: GroupItem[] = [
    {
      heading: Messages.labels.serverless(),
      items: [
        { icon: getIconName(DetailsPanelId.ADBS_DATABASES), id: detailViews[0].id, name: Messages.labels.databases() },
      ],
    },
  ];

  const getTitlePrefix = (panelId: string | undefined): string => {
    switch (panelId) {
      case DetailsPanelId.ADBS_DATABASES:
      default:
        return Messages.serviceProviders.adbShared();
    }
  };

  const getTitleSuffix = (panelId: string | undefined): string => {
    switch (panelId) {
      case DetailsPanelId.ADBS_DATABASES:
      default:
        return Messages.labels.databases();
    }
  };

  const getTitle = (
    panelId: string | undefined,
  ): string | TitleDetails => ({ primary: getTitlePrefix(panelId), secondary: getTitleSuffix(panelId) });

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={getTitlePrefix(listId)}
      titleSuffix={getTitleSuffix(listId)}
    >
      <DetailViewsPanel
        title={getTitle(listId)}
        icon={getIconName(listId)}
        message={disableList
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForList(requiredRole),
          } as PanelMessage
          : undefined}
        onClose={back}
        isOpen
        views={detailViews}
        activeViewId={getViewId(listId)}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
      />
    </BookmarkablePage>
  );
};
