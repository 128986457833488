import {
  FormattedString,
  InfoBlock,
  InfoBlockLayout,
  InfoBlockStatus,
  InputFormCallout,
  InputFormCalloutProps,
  SubmitButtonMode,
  TextInput,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";

export enum Fields {
  Name = "name",
}

export enum FieldTestIds {
  Name = "name-field",
}

export enum InfoBlockTestIds {
  Warning = "warning-info-block",
}

export interface BackupCreatePanelProps extends
  Pick<InputFormCalloutProps, "targetId" | "onClose" | "onSubmit" | "componentRef">
{
  warningMsg?: string;
}

export const BackupCreatePanel = ({
  targetId,
  componentRef,
  onClose,
  onSubmit,
  warningMsg,
}: BackupCreatePanelProps): JSX.Element => (
  <InputFormCallout
    componentRef={componentRef}
    title={Messages.createNewPanels.backup.title()}
    targetId={targetId}
    onClose={onClose}
    submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
    onSubmit={onSubmit}
    testId={CalloutTestIds.BackupCreatePanel}
  >
    {warningMsg
      && (
      <InfoBlock
        testId={InfoBlockTestIds.Warning}
        message={(FormattedString({ inputText: warningMsg }) as unknown as string)}
        messageType={InfoBlockStatus.WARNING}
        infoLayout={InfoBlockLayout.Compact}
        customInfoStyle={{ root: { marginBottom: "15px" } }}
      />
      )}
    <TextInput
      testId={FieldTestIds.Name}
      required
      fieldName={Fields.Name}
      label={Messages.labels.name()}
    />
  </InputFormCallout>
);
