import {
  ActionBar,
  ActionType,
  AnchoredPanelComponent,
  BookmarkablePage,
  DateTimeDisplay,
  DetailView,
  DetailViewsPanel,
  ErrorViewPanel,
  FormValues,
  getResourceLifecycleStatus,
  getValue,
  GroupItem,
  InputFormGroup,
  LabelMetaItem,
  MetaItemSection,
  NoValue,
  optimizedRetryOption,
  stateT,
  Status,
  uniqueGUID,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Spinner, Stack } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { Fields as TagDetailsFields, TagDetails, TagDetailsComponent } from "../../components/TagDetails/TagDetails";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  DetailsPanelId,
  InlineFormId,
  MYSQL_CREATE_ROUTE,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds, SvgIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { MdsBackup } from "../../gen/clients/mchub-azure-api-client-mds";
import { buildId, parseId } from "../../helpers/idHelper";
import {
  getBackupTypeStr,
  getCreationTypeStr,
  getStatusInfo,
  getTitleSuffix,
  ResourceStatus,
  TagsInfoType,
} from "../../helpers/resourceHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import {
  MysqlBackupEditDescriptionProps,
  newMysqlBackupEditDescription,
} from "../../operations/MysqlBackup/MysqlBackupEditDescription";
import {
  MysqlBackupEditRetentionDaysProps,
  newMysqlBackupEditRetentionDays,
} from "../../operations/MysqlBackup/MysqlBackupEditRetentionDays";
import { MysqlBackupEditTagsProps, newMysqlBackupEditTags } from "../../operations/MysqlBackup/MysqlBackupEditTags";
import {
  MysqlBackupUpdateTagsProps,
  newMysqlBackupUpdateTags,
} from "../../operations/MysqlBackup/MysqlBackupUpdateTags";
import { MysqlDeleteBackupProps, newMysqlDeleteBackup } from "../../operations/MysqlBackup/MysqlDeleteBackup";
import { getAzureLocationName, getOciRegion } from "../../utils";

export enum ActionIds {
  Delete = "delete",
  Refresh = "refresh",
  Restore = "restore",
  EditTags = "edit-tags",
  EditDescription = "edit-description",
  EditRetentionDays = "edit-retention-days"
}

export enum ActionBarTestIds {
  Delete = "delete",
  Refresh = "refresh",
  Restore = "restore",
}

export enum MetaItemSectionTestIds {
  ResourceGroup = "mis-resource-group",
  LifecycleStatus = "mis-status",
  Location = "mis-location",
  Subscription = "mis-subscription",
  SubscriptionId = "mis-subscription-id",
  Tags = "mis-tags",
  BackupType = "mis-backup-type",
  CreationType = "mis-creation-type",
  MysqlVersion = "mis-mysql-version",
  Shape = "mis-shape",
  BackupSize = "mis-backup-size",
  DataStorage = "mis-data-storage",
  RetentionDays = "mis-retention-days",
  CreatedTime = "mis-created-time",
  UpdatedTime = "mis-updated-time",
  Description = "mis-description",
}

export enum MetaItemActionTestIds {
  EditTags = "edit-tags",
  EditDescription = "edit-description",
  EditRetentionDays = "edit-retention-days",
}

export enum LinkTestIds {
  AddTags = "add-tags-link"
}

const registrationIds = PageRegistrationConfig[PageId.MYSQL_BACKUP_DETAILS].map(config => config.key);

const getViewId = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.TAGS:
      return registrationIds[1];
    case DetailsPanelId.OVERVIEW:
    default:
      return registrationIds[0];
  }
};

export const MysqlBackupDetailsPage = (): JSX.Element => {
  const
    {
      subscriptionId,
      resourceGroup: resGroupFromUrl,
      provider,
      resourceType,
      resourceName: resourceNameFromUrl,
      panelId,
    } = useParams();
  const [searchParams] = useSearchParams();

  const resGroup = decodeURIComponent(resGroupFromUrl || "");
  const resourceName = decodeURIComponent(resourceNameFromUrl || "");
  const location = searchParams.get("location") || "";

  const { trackActionClick, trackActionDiscard, trackFormSave } = useAnalytics();

  const { back, navigateToSelf, navigateToPanel, navigateTo, customData } = useNavigation(ConsoleContext);

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  const tagDetailsRef = React.useRef<TagDetailsComponent>();
  const setTagDetailsRef = (panel: TagDetailsComponent): void => {
    tagDetailsRef.current = panel;
  };

  const [tagTabKey, setTagTabKey] = React.useState<string>(uniqueGUID());
  const mdsBackupId = buildId({
    subscriptionId: subscriptionId || "",
    resourceGroup: resGroup,
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName,
  });

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.MYSQL_BACKUP_DETAILS].find(
      config => config.panelPath === panelId,
    );
    const urlQueryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const path = `${RESOURCE_ROUTE}/${mdsBackupId}/${panelId}${urlQueryParams}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Subscription
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();
  const subscription = !subscriptionLoading && subscriptions
    ? subscriptions.find(s => s.id === subscriptionId)
    : undefined;

  const {
    response,
    loading,
    refresh,
    error: errorMdsBackup,
  } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.getMdsBackup,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        backupKey: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadMysqlBackupDetail(resourceName || ""),
      },
      excludeErrorStatus: [404],
    },
  });

  const instance : MdsBackup | undefined = response?.data;
  const isLoading = loading && !instance;

  React.useEffect(() => {
    setTagTabKey(uniqueGUID);
  }, [instance]);

  const onEditTags = (): void => {
    triggerEditBackupTags({
      backupId: mdsBackupId,
      defaultTags: tagsDefaultValue,
      location,
      onExecute: refresh,
      onCancel: () => trackActionDiscard(ActionIds.EditTags, PageId.MYSQL_BACKUP_DETAILS, DetailsPanelId.OVERVIEW),
    });
  };

  // Tags
  const tagsDefaultValue: TagsInfoType = Object.keys(instance?.freeformTags || {})
    .map((key: string) => ({ name: key, value: instance?.freeformTags?.[key] }));

  const onClose = (): void => back();
  const onMenuItemSelect = (id: string): void => navigateToPanel(id);

  const { trigger: triggerDeleteBackup } = useOperation<MysqlDeleteBackupProps>(newMysqlDeleteBackup);
  const { trigger: triggerEditBackupTags } = useOperation<MysqlBackupEditTagsProps>(newMysqlBackupEditTags);
  const { trigger: triggerUpdateBackupTags } = useOperation<MysqlBackupUpdateTagsProps>(newMysqlBackupUpdateTags);
  const { closePanels } = useSidePanel();

  const overviewActionItems: ActionType[] = [
    // Need to use customData for the dbSystemId since the dbSystemId in the backup is incorrect
    {
      key: ActionIds.Restore,
      testId: ActionBarTestIds.Restore,
      text: Messages.actions.restoreToNewDbSystem(),
      icon: MonochromeIconIds.Database,
      disabled: !customData?.dbSystemId || instance?.status !== ResourceStatus.Active,
      onClick: () => {
        trackActionClick(ActionIds.Restore, PageId.MYSQL_BACKUP_DETAILS, DetailsPanelId.OVERVIEW);
        const { resourceName: dbSystemName } = parseId(customData?.dbSystemId);
        navigateTo(MYSQL_CREATE_ROUTE, PageId.MYSQL_CREATE, {
          subscriptionId,
          dbSystemName,
          backupId: mdsBackupId,
          backupName: instance?.displayName,
          backupDataStorageSizeInGBs: instance?.dataStorageSizeInGBs,
          backupMysqlVersion: instance?.mysqlVersion,
          analytics: {
            pageId: PageId.MYSQL_BACKUP_DETAILS,
            panelId: DetailsPanelId.OVERVIEW,
            actionName: ActionIds.Restore,
          } as NavigationAnalyticsData,
        });
      },
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.MYSQL_BACKUP_DETAILS, DetailsPanelId.OVERVIEW);
        refresh();
      },
    },
    {
      key: ActionIds.Delete,
      testId: ActionBarTestIds.Delete,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.labels.deleteBackup(),
      description: Messages.hints.deleteBackupConfirmation(instance?.displayName || ""),
      onClick: () => {
        trackActionClick(ActionIds.Delete, PageId.MYSQL_BACKUP_DETAILS, DetailsPanelId.OVERVIEW);
      },
      onConfirm: () => {
        triggerDeleteBackup({
          backupId: mdsBackupId,
          location,
          onExecute: () => {
            refresh();
            back();
          },
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Delete, PageId.MYSQL_BACKUP_DETAILS, DetailsPanelId.OVERVIEW),
    },
  ];

  // eslint-disable-next-line max-len
  const { trigger: triggerEditBackupDescription } = useOperation<MysqlBackupEditDescriptionProps>(newMysqlBackupEditDescription);
  // eslint-disable-next-line max-len
  const { trigger: triggerEditBackupRetentionDays } = useOperation<MysqlBackupEditRetentionDaysProps>(newMysqlBackupEditRetentionDays);

  const essentialsMetaItemGroup: JSX.Element[] = [
    <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="mysql-backup-details-meta-items-left">
      <LabelMetaItem testId={MetaItemSectionTestIds.ResourceGroup} label={Messages.labels.resourceGroup()}>
        <AzureResourceGroupLink
          resourceId={mdsBackupId}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.LifecycleStatus} label={Messages.labels.status()}>
        <Status
          iconPosition="right"
          label={stateT(instance?.status || "")}
          status={getResourceLifecycleStatus(instance?.status || "")}
          statusInfo={
            getStatusInfo(
              instance?.status || "",
              instance?.lastOperationStatus || "",
              instance?.lastOperationStatusDetails || "",
            )
          }
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Location} label={Messages.labels.location()}>
        {instance?.location ? getAzureLocationName(instance.location) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Subscription} label={Messages.labels.subscription()}>
        <AzureSubscriptionLink
          resourceId={mdsBackupId}
          subscriptionName={subscription?.name as string}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.SubscriptionId} label={Messages.labels.subscriptionId()}>
        {subscriptionId || <NoValue />}
      </LabelMetaItem>
    </Stack>,
    <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="mysql-backup-details-meta-items-right">
      <LabelMetaItem
        testId={MetaItemSectionTestIds.Description}
        label={Messages.labels.description()}
        action={{
          id: ActionIds.EditDescription,
          testId: MetaItemActionTestIds.EditDescription,
          label: Messages.actions.edit(),
          onClick: () => {
            trackActionClick(ActionIds.EditDescription, PageId.MYSQL_BACKUP_DETAILS, DetailsPanelId.OVERVIEW);
            triggerEditBackupDescription({
              backupId: instance?.id,
              description: instance?.description,
              location,
              targetId: ActionIds.EditDescription,
              onExecute: refresh,
              onCancel: () => trackActionDiscard(
                ActionIds.EditDescription,
                PageId.MYSQL_BACKUP_DETAILS,
                DetailsPanelId.OVERVIEW,
              ),
            });
          },
        }}
      >
        {instance?.description || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.BackupType} label={Messages.labels.type()}>
        {instance?.backupType ? getBackupTypeStr(instance.backupType) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.CreationType} label={Messages.labels.creationType()}>
        {instance?.creationType ? getCreationTypeStr(instance.creationType) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.MysqlVersion} label={Messages.labels.mySQLVersion()}>
        {instance?.mysqlVersion || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Shape} label={Messages.labels.shape()}>
        {instance?.shapeName || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.BackupSize} label={Messages.labels.backupSizeInGB()}>
        {instance?.backupSizeInGBs?.toString() || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.DataStorage} label={Messages.labels.totalStorageInGB()}>
        {instance?.dataStorageSizeInGBs?.toString() || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem
        testId={MetaItemSectionTestIds.RetentionDays}
        label={Messages.labels.retentionDays()}
        action={instance?.creationType === "MANUAL"
          ? {
            id: ActionIds.EditRetentionDays,
            testId: MetaItemActionTestIds.EditRetentionDays,
            label: Messages.actions.edit(),
            onClick: () => {
              trackActionClick(ActionIds.EditRetentionDays, PageId.MYSQL_BACKUP_DETAILS, DetailsPanelId.OVERVIEW);
              triggerEditBackupRetentionDays({
                backupId: instance?.id,
                retentionDays: instance?.retentionInDays,
                targetId: ActionIds.EditRetentionDays,
                location,
                onExecute: refresh,
                onCancel: () => trackActionDiscard(
                  ActionIds.EditRetentionDays,
                  PageId.MYSQL_BACKUP_DETAILS,
                  DetailsPanelId.OVERVIEW,
                ),
              });
            },
          }
          : undefined}
      >
        {instance?.retentionInDays?.toString() || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.CreatedTime} label={Messages.labels.created()}>
        <DateTimeDisplay date={instance?.timeCreated} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.UpdatedTime} label={Messages.labels.updated()}>
        <DateTimeDisplay date={instance?.timeUpdated} />
      </LabelMetaItem>
    </Stack>,
  ];

  const detailViews: DetailView[] = [
    {
      id: registrationIds[0],
      content: (
        <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
          <ActionBar actions={overviewActionItems} onActionClick={closePanels} />
          <MetaItemSection
            labelWidth={140}
            tags={{
              testId: MetaItemSectionTestIds.Tags,
              items: tagsDefaultValue.length ? tagsDefaultValue : undefined,
              add: {
                testId: LinkTestIds.AddTags,
                onClick: () => {
                  trackActionClick(ActionIds.EditTags, PageId.MYSQL_BACKUP_DETAILS, DetailsPanelId.OVERVIEW);
                  onEditTags();
                },
              },
              edit: {
                id: ActionIds.EditTags,
                testId: MetaItemActionTestIds.EditTags,
                onClick: () => {
                  trackActionClick(ActionIds.EditTags, PageId.MYSQL_BACKUP_DETAILS, DetailsPanelId.OVERVIEW);
                  onEditTags();
                },
              },
            }}
          >
            {essentialsMetaItemGroup}
          </MetaItemSection>
        </Stack>
      ),
    },
    {
      id: registrationIds[1],
      content: (
        <TagDetails
          key={tagTabKey}
          resourceId={mdsBackupId}
          tagsDefaultValue={tagsDefaultValue}
          onApply={(formValues: FormValues) => {
            trackFormSave(InlineFormId.TAGS, PageId.MYSQL_BACKUP_DETAILS, DetailsPanelId.TAGS);
            const tags = getValue<TagsInfoType>(
              formValues,
              TagDetailsFields.Tags,
              InputFormGroup,
            ) ?? [];
            // *** Do not call refresh when updating tags on the tags tab to avoid resetting user input ***
            const updateTagsProps = {
              backupId: mdsBackupId,
              tags,
              location,
              onExecute: () => tagDetailsRef.current?.toggleInProgress(false, panelRef.current),
            };
            tagDetailsRef.current?.toggleInProgress(true, panelRef.current);
            triggerUpdateBackupTags(updateTagsProps);
          }}
          componentRef={setTagDetailsRef}
        />
      ),
    },
  ];

  const groupItems: GroupItem[] = [
    {
      items: [
        {
          icon: SvgIconIds.backupsSvg,
          id: detailViews[0].id,
          name: Messages.labels.overview(),
        },
        {
          icon: SvgIconIds.tagsSvg,
          id: detailViews[1].id,
          name: Messages.labels.tags(),
        },
      ],
    },
  ];

  const renderedContent = (): JSX.Element => {
    if (isLoading) {
      return <Spinner label={Messages.common.loading()} />;
    } if (errorMdsBackup) {
      const isNotFound = errorMdsBackup?.status === 404;
      const errorTitle = isNotFound
        ? Messages.detailMysqlBackup.loadError.notFound.title()
        : Messages.detailMysqlBackup.loadError.general.title();
      const errorDetail = isNotFound
        ? Messages.detailMysqlBackup.loadError.notFound.details()
        : Messages.detailMysqlBackup.loadError.general.details();

      return (
        <ErrorViewPanel
          icon={SvgIconIds.backupsSvg}
          title={resourceName}
          errorTitle={errorTitle}
          resourceId={mdsBackupId}
          errorCode={errorMdsBackup?.status}
          details={[
            errorDetail,
            Messages.notifications.apiErrorMsg(errorMdsBackup?.body?.message),
          ]}
          isOpen
          onClose={onClose}
          favoriteAccessRegistration={{ id: mdsBackupId, data: { location } }}
        />
      );
    }
    return (
      <DetailViewsPanel
        componentRef={setPanelRef}
        title={instance?.displayName || ""}
        subTitle={Messages.detailMysqlBackup.titles.long()}
        icon={SvgIconIds.backupsSvg}
        onClose={onClose}
        isOpen
        views={detailViews}
        activeViewId={getViewId(panelId)}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
        status={instance?.status !== ResourceStatus.Active ? {
          status: getResourceLifecycleStatus(instance?.status || ""),
          tooltip: stateT(instance?.status || ""),
        } : undefined}
        favoriteAccessRegistration={{ id: instance?.id || "", data: { location } }}
      />
    );
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={instance?.displayName || Messages.detailMysqlBackup.titles.short()}
      titleSuffix={instance?.displayName ? getTitleSuffix(panelId || "") : undefined}
      recentAccessRegistration={{ id: instance?.id || "", data: { location } }}
    >
      {renderedContent()}
    </BookmarkablePage>
  );
};
