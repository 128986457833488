/* tslint:disable */

/**
 * CustomerFeedbackService API
 * A description of the CustomerFeedbackService API
 *
 * OpenAPI spec version: 20210801
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as base from "./lib/base-api";

/**
 * Details of feedback given
 */
export interface CreateFeedbackDataDetails {
    "rating": Rating;
    /**
     * Customer Feedback
     */
    "comment"?: string;
    /**
     * Categorization of usability issue, bug, outdated content, etc.
     */
    "feedbackType": CreateFeedbackDataDetailsFeedbackTypeEnum;
    /**
     * The BCP 47 language tag for the langauge in which the comment is being submitted.
     */
    "language": string;
}

export type CreateFeedbackDataDetailsFeedbackTypeEnum = "USABILITY_ISSUE" | "BUG" | "OUTDATED_CONTENT" | "OTHERS";
/**
 * The information about new Feedback.
 */
export interface CreateFeedbackDetails {
    "product": CreateProductDetails;
    "feedbackData": CreateFeedbackDataDetails;
    "referrer": CreateReferrerDetails;
    "userData"?: CreateUserDataDetails;
    /**
     * The metadata associated with this feedback (configured by service teams).
     */
    "metadata"?: { [key: string]: any; };
    /**
     * The time the Feedback was submitted. An RFC3339 formatted datetime string
     */
    "timeSubmitted": Date;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Defined tags for this resource. Each key is predefined and scoped to a namespace. Example: `{\"foo-namespace\": {\"bar-key\": \"value\"}}` 
     */
    "definedTags"?: { [key: string]: { [key: string]: any; }; };
}

/**
 * Details related to product team
 */
export interface CreateProductDetails {
    /**
     * Unique identifier assigned for each product team
     */
    "source": string;
    /**
     * Name of the component from which feedback was triggered
     */
    "component"?: string;
    /**
     * Category details within product
     */
    "category"?: string;
    /**
     * Subcategory details within product
     */
    "subCategory"?: string;
    /**
     * DocId/IncidentId/ReferenceId of the referencing system
     */
    "referenceId"?: string;
}

/**
 * Details of Referrer/URL
 */
export interface CreateReferrerDetails {
    /**
     * url details
     */
    "url": string;
}

/**
 * Details related to user
 */
export interface CreateUserDataDetails {
    /**
     * Persona details for user
     */
    "userPersona"?: string;
    /**
     * Unique identifier for session created for user
     */
    "sessionId"?: string;
    /**
     * Type of customer like ActivePromo, ActiveFree, Paid, PayGo etc.
     */
    "customerType"?: string;
    /**
     * Realm details for user
     */
    "realm"?: string;
    /**
     * Region details for user
     */
    "region"?: string;
    /**
     * User's consent to contact him/her back
     */
    "isUserConsent"?: boolean;
}

/**
 * Description of Feedback.
 */
export interface Feedback {
    /**
     * Unique identifier that is immutable on creation
     */
    "id": string;
    /**
     * Feedback Identifier, can be renamed
     */
    "displayName"?: string;
    /**
     * Compartment Identifier
     */
    "compartmentId"?: string;
    "product": Product;
    "feedbackData": FeedbackData;
    "userAgent": UserAgent;
    "referrer": Referrer;
    "userData"?: UserData;
    /**
     * The metadata associated with this feedback (configured by service teams).
     */
    "metadata"?: { [key: string]: any; };
    /**
     * The time the Feedback was created. An RFC3339 formatted datetime string
     */
    "timeCreated"?: Date;
    /**
     * The time the Feedback was submitted. An RFC3339 formatted datetime string
     */
    "timeSubmitted"?: Date;
    /**
     * The time the Feedback was updated. An RFC3339 formatted datetime string
     */
    "timeUpdated"?: Date;
    /**
     * The current state of the Feedback.
     */
    "lifecycleState"?: FeedbackLifecycleStateEnum;
    /**
     * A message describing the current state in more detail. For example, can be used to provide actionable information for a resource in Failed state.
     */
    "lifecyleDetails"?: string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Defined tags for this resource. Each key is predefined and scoped to a namespace. Example: `{\"foo-namespace\": {\"bar-key\": \"value\"}}` 
     */
    "definedTags"?: { [key: string]: { [key: string]: any; }; };
    /**
     * Usage of system tag keys. These predefined keys are scoped to namespaces. Example: `{\"orcl-cloud\": {\"free-tier-retained\": \"true\"}}` 
     */
    "systemTags"?: { [key: string]: { [key: string]: any; }; };
}

export type FeedbackLifecycleStateEnum = "CREATING" | "UPDATING" | "ACTIVE" | "DELETING" | "DELETED" | "FAILED";
/**
 * Results of a feedback search. Contains both FeedbackSummary items and other information, such as metadata.
 */
export interface FeedbackCollection {
    /**
     * List of feedbacks.
     */
    "items": Array<FeedbackSummary>;
}

/**
 * Response object for create feedback operation.
 */
export interface FeedbackCreateResult {
    /**
     * Unique identifier that is immutable on creation
     */
    "id": string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Defined tags for this resource. Each key is predefined and scoped to a namespace. Example: `{\"foo-namespace\": {\"bar-key\": \"value\"}}` 
     */
    "definedTags"?: { [key: string]: { [key: string]: any; }; };
    /**
     * Usage of system tag keys. These predefined keys are scoped to namespaces. Example: `{\"orcl-cloud\": {\"free-tier-retained\": \"true\"}}` 
     */
    "systemTags"?: { [key: string]: { [key: string]: any; }; };
}

/**
 * Details of feedback given
 */
export interface FeedbackData {
    "rating": Rating;
    /**
     * Customer Feedback
     */
    "comment"?: string;
    /**
     * Categorization of usability issue, bug, outdated content, etc.
     */
    "feedbackType": FeedbackDataFeedbackTypeEnum;
    /**
     * The BCP 47 language tag for the langauge in which the comment is being submitted.
     */
    "language": string;
}

export type FeedbackDataFeedbackTypeEnum = "USABILITY_ISSUE" | "BUG" | "OUTDATED_CONTENT" | "OTHERS";
/**
 * Summary of the Feedback.
 */
export interface FeedbackSummary {
    /**
     * Unique identifier that is immutable on creation
     */
    "id": string;
    /**
     * Feedback Identifier, can be renamed
     */
    "displayName"?: string;
    /**
     * Compartment Identifier
     */
    "compartmentId"?: string;
    "product": Product;
    "feedbackData": FeedbackData;
    "userAgent": UserAgent;
    "referrer": Referrer;
    "userData"?: UserData;
    /**
     * The metadata associated with this feedback (configured by service teams).
     */
    "metadata"?: { [key: string]: any; };
    /**
     * The time the Feedback was created. An RFC3339 formatted datetime string
     */
    "timeCreated"?: Date;
    /**
     * The time the Feedback was updated. An RFC3339 formatted datetime string
     */
    "timeUpdated"?: Date;
    /**
     * The current state of the Feedback.
     */
    "lifecycleState"?: string;
    /**
     * A message describing the current state in more detail. For example, can be used to provide actionable information for a resource in Failed state.
     */
    "lifecyleDetails"?: string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Defined tags for this resource. Each key is predefined and scoped to a namespace. Example: `{\"foo-namespace\": {\"bar-key\": \"value\"}}` 
     */
    "definedTags"?: { [key: string]: { [key: string]: any; }; };
    /**
     * Usage of system tag keys. These predefined keys are scoped to namespaces. Example: `{\"orcl-cloud\": {\"free-tier-retained\": \"true\"}}` 
     */
    "systemTags"?: { [key: string]: { [key: string]: any; }; };
}

/**
 * Error Information.
 */
export interface ModelError {
    /**
     * A short error code that defines the error, meant for programmatic parsing.
     */
    "code": string;
    /**
     * A human-readable error string.
     */
    "message": string;
}

/**
 * Details related to product team
 */
export interface Product {
    /**
     * Unique identifier assigned for each product team
     */
    "source": string;
    /**
     * Name of the component from which feedback was triggered
     */
    "component"?: string;
    /**
     * Category details within product
     */
    "category"?: string;
    /**
     * Subcategory details within product
     */
    "subCategory"?: string;
    /**
     * DocId/IncidentId/ReferenceId of the referencing system
     */
    "referenceId"?: string;
}

/**
 * Details of rating
 */
export interface Rating {
    /**
     * Rating type
     */
    "type": RatingTypeEnum;
    /**
     * value given by customer
     */
    "value": string;
}

export type RatingTypeEnum = "SCALE_2" | "SCALE_3" | "SCALE_5" | "SCALE_10";
/**
 * Details of Referrer/URL
 */
export interface Referrer {
    /**
     * url details
     */
    "url": string;
}

/**
 * Sort orders.
 */
export type SortOrder = "ASC" | "DESC";
export enum SortOrderValues {
    ASC = "ASC",
    DESC = "DESC"
}

/**
 * The information to be updated.
 */
export interface UpdateFeedbackDetails {
    "feedbackData"?: CreateFeedbackDataDetails;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Defined tags for this resource. Each key is predefined and scoped to a namespace. Example: `{\"foo-namespace\": {\"bar-key\": \"value\"}}` 
     */
    "definedTags"?: { [key: string]: { [key: string]: any; }; };
}

/**
 * Details related to browser
 */
export interface UserAgent {
    /**
     * Name of the Browser
     */
    "browser": string;
    /**
     * Version of the browser
     */
    "version": string;
}

/**
 * Details related to user
 */
export interface UserData {
    /**
     * Persona details for user
     */
    "userPersona"?: string;
    /**
     * Unique identifier for session created for user
     */
    "sessionId"?: string;
    /**
     * Type of customer like ActivePromo, ActiveFree, Paid, PayGo etc.
     */
    "customerType"?: string;
    /**
     * Realm details for user
     */
    "realm"?: string;
    /**
     * Region details for user
     */
    "region"?: string;
    /**
     * Unique user identifier
     */
    "userId"?: string;
    /**
     * Tenancy ID of the user
     */
    "tenancyId"?: string;
    /**
     * User's consent to contact him/her back
     */
    "isUserConsent"?: boolean;
}


export interface CreateFeedbackArgs {
     "createFeedbackDetails": CreateFeedbackDetails;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type CreateFeedbackReturnType = { response: Response, data: FeedbackCreateResult };

export interface DeleteFeedbackArgs {
     "feedbackId": string;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type DeleteFeedbackReturnType = Response;

export interface GetFeedbackArgs {
     "feedbackId": string;
     "opcRequestId"?: string
}
export type GetFeedbackReturnType = { response: Response, data: Feedback };

export interface ListFeedbacksArgs {
     "compartmentId"?: string;
     "lifecycleState"?: string;
     "displayName"?: string;
     "id"?: string;
     "limit"?: number;
     "page"?: string;
     "sortOrder"?: string;
     "sortBy"?: string;
     "source"?: string;
     "component"?: string;
     "timeCreatedGreaterThanOrEqualTo"?: Date;
     "timeCreatedLessThan"?: Date;
     "category"?: string;
     "subCategory"?: string;
     "ratingValue"?: string;
     "ratingType"?: string;
     "opcRequestId"?: string
}
export type ListFeedbacksReturnType = { response: Response, data: FeedbackCollection };

export interface UpdateFeedbackArgs {
     "feedbackId": string;
     "updateFeedbackDetails": UpdateFeedbackDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type UpdateFeedbackReturnType = { response: Response, data: Feedback };


/**
 * FeedbackApi - object-oriented interface
 */
export class FeedbackApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): FeedbackApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://customer-feedback-service-api.{region}.oci.{secondLevelDomain}",
            "/20210801",
            region,
            secondLevelDomain
        );

        return new FeedbackApi(fetch, endpoint, config);
    }

    /** 
     * Creates a new Feedback
     * Creates a new Feedback. 
     * @param createFeedbackDetails Details for the new Feedback.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId The client request ID for tracing.
     */
    public createFeedback(params: {  "createFeedbackDetails": CreateFeedbackDetails; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: FeedbackCreateResult }> {
        base.validateRequiredParameters([
            "createFeedbackDetails",
        ], "createFeedback", params);

        const path = `${this.basePath}/feedback`;

        return this.request<FeedbackCreateResult>({
            options,
            path,


            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createFeedbackDetails"]
                    },

            parseResponseBody: true,

            operationName: "createFeedback",
            httpMethod: "POST"
        });
    }
    /** 
     * Delete a provisioned Feedback
     * Deletes a Feedback resource by identifier
     * @param feedbackId Unique feedback identifier
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId The client request ID for tracing.
     */
    public deleteFeedback(params: {  "feedbackId": string; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "feedbackId",
        ], "deleteFeedback", params);

        const path = `${this.basePath}/feedback/{feedbackId}`
            .replace(`{${"feedbackId"}}`, `${ params["feedbackId"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteFeedback",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Get Feedback
     * Gets a Feedback by identifier
     * @param feedbackId Unique feedback identifier
     * @param opcRequestId The client request ID for tracing.
     */
    public getFeedback(params: {  "feedbackId": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: Feedback }> {
        base.validateRequiredParameters([
            "feedbackId",
        ], "getFeedback", params);

        const path = `${this.basePath}/feedback/{feedbackId}`
            .replace(`{${"feedbackId"}}`, `${ params["feedbackId"] }`);

        return this.request<Feedback>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getFeedback",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all Feedbacks in a compartment
     * Returns a list of Feedbacks. 
     * @param compartmentId The ID of the compartment in which to list resources.
     * @param lifecycleState A filter to return only resources their lifecycleState matches the given lifecycleState.
     * @param displayName A filter to return only resources that match the entire display name given.
     * @param id Unique feedback identifier
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param source source passed in POST API
     * @param component component details
     * @param timeCreatedGreaterThanOrEqualTo Search for feedbacks that were created at/after a specific date.
     * @param timeCreatedLessThan Search for feedbacks that were created before a specific date.
     * @param category Category details
     * @param subCategory Subcategory details
     * @param ratingValue Rating value details
     * @param ratingType Rating type details
     * @param opcRequestId The client request ID for tracing.
     */
    public listFeedbacks(params: {  "compartmentId"?: string; "lifecycleState"?: string; "displayName"?: string; "id"?: string; "limit"?: number; "page"?: string; "sortOrder"?: string; "sortBy"?: string; "source"?: string; "component"?: string; "timeCreatedGreaterThanOrEqualTo"?: Date; "timeCreatedLessThan"?: Date; "category"?: string; "subCategory"?: string; "ratingValue"?: string; "ratingType"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: FeedbackCollection }> {
        base.validateRequiredParameters([
        ], "listFeedbacks", params);

        const path = `${this.basePath}/feedback`;

        return this.request<FeedbackCollection>({
            options,
            path,

            queryParameters: {
                    "compartmentId": { values: params["compartmentId"] },
                    "lifecycleState": { values: params["lifecycleState"] },
                    "displayName": { values: params["displayName"] },
                    "id": { values: params["id"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "sortBy": { values: params["sortBy"] },
                    "source": { values: params["source"] },
                    "component": { values: params["component"] },
                    "timeCreatedGreaterThanOrEqualTo": { values: params["timeCreatedGreaterThanOrEqualTo"] },
                    "timeCreatedLessThan": { values: params["timeCreatedLessThan"] },
                    "category": { values: params["category"] },
                    "subCategory": { values: params["subCategory"] },
                    "ratingValue": { values: params["ratingValue"] },
                    "ratingType": { values: params["ratingType"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listFeedbacks",
            httpMethod: "GET"
        });
    }
    /** 
     * Update the Feedback identified by the id
     * Updates the Feedback
     * @param feedbackId Unique feedback identifier
     * @param updateFeedbackDetails The information to be updated.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId The client request ID for tracing.
     */
    public updateFeedback(params: {  "feedbackId": string; "updateFeedbackDetails": UpdateFeedbackDetails; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: Feedback }> {
        base.validateRequiredParameters([
            "feedbackId",
            "updateFeedbackDetails",
        ], "updateFeedback", params);

        const path = `${this.basePath}/feedback/{feedbackId}`
            .replace(`{${"feedbackId"}}`, `${ params["feedbackId"] }`);

        return this.request<Feedback>({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateFeedbackDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateFeedback",
            httpMethod: "PUT"
        });
    }
};

