import {
  ActionBar,
  ActionType,
  AnchoredPanelComponent,
  BookmarkablePage,
  DetailView,
  DetailViewsPanel,
  ErrorViewPanel,
  getResourceLifecycleStatus,
  GroupItem,
  InfoBlockProps,
  InfoBlockStatus,
  LabelMetaItem,
  MetaItemSection,
  NoValue,
  optimizedRetryOption,
  stateT,
  Status,
  TitleDetails,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Spinner, Stack } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { OciVcnLink } from "../../components/OciLinks/OciVcnLink";
import { ConsoleContext } from "../../console/ConsoleContext";
import { DetailsPanelId, PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds, SvgIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { MultiCloudVirtualNetwork } from "../../gen/clients/mchub-azure-api-client";
import { buildId } from "../../helpers/idHelper";
import { getMcvcnStatus, getStatusInfo, getTitleSuffix, ResourceStatus } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { AzureSubscriptionSummaryExt, useSubscriptions } from "../../hooks/useSubscriptions";
import { McvcnDeleteProps, newMcvcnDelete } from "../../operations/Mcvcn/McvcnDelete";
import { getAzureLocationName, getOciRegion } from "../../utils";
import { AttachedDbList } from "./AttachedDbList";
import { McvcnConfigurationDetails } from "./McvcnConfigurationDetails";

const registrationIds = PageRegistrationConfig[PageId.MCVCN_DETAILS].map(config => config.key);

export enum ActionIds {
  Refresh = "refresh",
  Delete = "delete",
  EditTags = "edit-tags",
}

export enum ActionBarTestIds {
  Refresh = "refresh",
  Delete = "delete",
}

export enum MetaItemTestIds {
  Location = "mis-location",
  OciLink = "mis-oci-link",
  ResourceGroup = "mis-resource-group",
  ResourceLifeCycleStatus = "mis-status",
  Subscription = "mis-subscription",
  SubscriptionId = "mis-subscripion-id",
  Tags = "mis-tags",
}

export enum MetaItemActionTestIds {
  EditTags = "edit-tags",
}

export enum LinkTestIds {
  AddTags = "add-tags-link"
}

export enum InfoBlockTestIds {
  deleteMcvcnInfo = "mcvcn-delete-info"
}

const getViewId = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.ATTACHED_DATABASES:
      return registrationIds[2];
    case DetailsPanelId.CONFIGURATION:
      return registrationIds[1];
    case DetailsPanelId.OVERVIEW:
    default:
      return registrationIds[0];
  }
};

const getViewTitle = (panelId: string | undefined, instanceName: string): string | TitleDetails => {
  switch (panelId) {
    case DetailsPanelId.ATTACHED_DATABASES:
      return { primary: instanceName, secondary: Messages.labels.attachedDatabases() };
    case DetailsPanelId.CONFIGURATION:
      return { primary: instanceName, secondary: Messages.labels.configuration() };
    case DetailsPanelId.OVERVIEW:
    default:
      return instanceName;
  }
};

const getViewIcon = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.ATTACHED_DATABASES:
      return SvgIconIds.attachedDbSvg;
    case DetailsPanelId.CONFIGURATION:
      return SvgIconIds.configurationSvg;
    case DetailsPanelId.OVERVIEW:
    default:
      return SvgIconIds.networkSvg;
  }
};

/**
 * TODO
 * This interface is implement to include the last status and details.
 * Api has not expose these two fields for now. When it will, we might remove this interface.
 */
interface MultiCloudVirtualNetworkExt extends MultiCloudVirtualNetwork {
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
}

export const McvcnDetailsPage = (): JSX.Element => {
  const { subscriptionId, resourceGroup, provider, resourceType, resourceName, panelId } = useParams();
  const [searchParams] = useSearchParams();

  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");
  const location = searchParams.get("location") || "";

  const mcvcnId = buildId({
    subscriptionId: subscriptionId || "",
    resourceGroup: resGroup,
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName: resName,
  });

  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { back, navigateToPanel, navigateToSelf } = useNavigation(ConsoleContext);

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.MCVCN_DETAILS].find(
      config => config.panelPath === panelId,
    );
    const urlQueryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const path = `${RESOURCE_ROUTE}/${mcvcnId}/${panelId}${urlQueryParams}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  const onMenuItemSelect = (id: string): void => navigateToPanel(id);

  // Subscription
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();
  const subscription = !subscriptionLoading && subscriptions
    ? subscriptions.find(s => s.id === subscriptionId)
    : undefined;

  const { response, loading, refresh, error } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).networkApi.getMultiCloudVirtualNetwork,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        multiCloudVirtualNetworkName: resName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadMcvcn(),
      },
      excludeErrorStatus: [404],
    },
  });

  const { trigger: triggerMcvcnDelete } = useOperation<McvcnDeleteProps>(newMcvcnDelete);
  const { closePanels } = useSidePanel();

  const mcvcn: MultiCloudVirtualNetworkExt | undefined = response?.data;
  const isLoading = loading && !mcvcn;

  const overviewActionItems: ActionType[] = [
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.MCVCN_DETAILS, DetailsPanelId.OVERVIEW);
        refresh();
      },
    },
    {
      key: ActionIds.Delete,
      testId: ActionBarTestIds.Delete,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.labels.deleteMcVcn(),
      disabled: mcvcn?.status !== ResourceStatus.Failed
      && mcvcn?.isUsable === true,
      description: Messages.hints.deleteMcVcnConfirmation(mcvcn?.name || ""),
      onClick: () => {
        trackActionClick(ActionIds.Delete, PageId.MCVCN_DETAILS, DetailsPanelId.OVERVIEW);
      },
      onConfirm: () => {
        triggerMcvcnDelete({
          mcvcnId,
          location,
          onExecute: () => {
            refresh();
            back();
          },
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Delete, PageId.MCVCN_DETAILS, DetailsPanelId.OVERVIEW),
    },
  ];

  const getStatus = (): JSX.Element => (
    <Status
      iconPosition="right"
      label={getMcvcnStatus(mcvcn?.status as string, mcvcn?.isUsable as boolean)}
      status={getResourceLifecycleStatus(mcvcn?.status || "")}
      statusInfo={
        getStatusInfo(
          mcvcn?.status || "",
          mcvcn?.lastOperationStatus || "",
          mcvcn?.lastOperationStatusDetails || "",
          mcvcn?.lifecycleDetails,
        )
      }
    />
  );

  const essentialsMetaItemGroup: JSX.Element[] = [
    <Stack key="meta-item-essentials-1" tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem testId={MetaItemTestIds.ResourceGroup} label={Messages.labels.resourceGroup()}>
        <AzureResourceGroupLink resourceId={mcvcn?.id as string} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemTestIds.ResourceLifeCycleStatus} label={Messages.labels.status()}>
        {getStatus()}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemTestIds.Location} label={Messages.labels.location()}>
        {mcvcn?.location ? getAzureLocationName(mcvcn.location) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemTestIds.Subscription} label={Messages.labels.subscription()}>
        <AzureSubscriptionLink resourceId={mcvcn?.id as string} subscriptionName={subscription?.name} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemTestIds.SubscriptionId} label={Messages.labels.subscriptionId()}>
        {subscriptionId || <NoValue />}
      </LabelMetaItem>
    </Stack>,
    <Stack key="meta-item-essentials-2" tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem testId={MetaItemTestIds.OciLink} label={Messages.labels.ociResource()}>
        <OciVcnLink
          id={mcvcn?.id as string}
          location={location}
          subscriptions={subscriptions as AzureSubscriptionSummaryExt[]}
          analytics={{ pageId: PageId.MCVCN_DETAILS, panelId: DetailsPanelId.OVERVIEW }}
        />
      </LabelMetaItem>
    </Stack>,
  ];

  const mcvcnDeleteInfoBlocks: InfoBlockProps[] = [];
  if (mcvcn?.status !== ResourceStatus.Failed
  && mcvcn?.isUsable === true) {
    mcvcnDeleteInfoBlocks.push({
      testId: InfoBlockTestIds.deleteMcvcnInfo,
      message: Messages.hints.mcvcnDeleteInfo(),
      messageType: InfoBlockStatus.INFO,
    });
  }

  const detailViews: DetailView[] = [
    {
      id: registrationIds[0],
      content: (
        <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
          <ActionBar
            actions={overviewActionItems}
            onActionClick={closePanels}
            infoBlocks={mcvcnDeleteInfoBlocks}
          />
          <MetaItemSection>
            {essentialsMetaItemGroup}
          </MetaItemSection>
        </Stack>
      ),
    },
    {
      id: registrationIds[1],
      content: (
        <McvcnConfigurationDetails
          mcvcn={mcvcn}
          location={location}
          mcvcnRefresh={refresh}
        />
      ),
    },
    {
      id: registrationIds[2],
      content: (
        <AttachedDbList
          attachedDbIds={mcvcn?.attachedDatabaseIds}
          location={location}
          refresh={refresh}
        />
      ),
    },
  ];

  const groupItems: GroupItem[] = [
    {
      items: [
        {
          icon: SvgIconIds.networkSvg,
          id: detailViews[0].id,
          name: Messages.labels.overview(),
        },
      ],
    },
    {
      heading: Messages.labels.settings(),
      items: [
        {
          icon: SvgIconIds.configurationSvg,
          id: detailViews[1].id,
          name: Messages.labels.configuration(),
        },
      ],
    },
    {
      heading: Messages.labels.resources(),
      items: [
        {
          icon: SvgIconIds.attachedDbSvg,
          id: detailViews[2].id,
          name: Messages.labels.attachedDatabases(),
        },
      ],
    },
  ];

  const renderedContent = (): JSX.Element => {
    if (isLoading) {
      return <Spinner label={Messages.common.loading()} />;
    } if (error) {
      const isNotFound = error?.status === 404;
      const errorTitle = isNotFound
        ? Messages.detailMcvcn.loadError.notFound.title()
        : Messages.detailMcvcn.loadError.general.title();
      const errorDetail = isNotFound
        ? Messages.detailMcvcn.loadError.notFound.details()
        : Messages.detailMcvcn.loadError.general.details();

      return (
        <ErrorViewPanel
          icon={SvgIconIds.networkSvg}
          title={resName}
          errorTitle={errorTitle}
          resourceId={mcvcnId}
          errorCode={error?.status}
          details={[
            errorDetail,
            Messages.notifications.apiErrorMsg(error?.body?.message),
          ]}
          isOpen
          onClose={back}
          favoriteAccessRegistration={{ id: mcvcnId, data: { location } }}
        />
      );
    }
    return (
      <DetailViewsPanel
        componentRef={setPanelRef}
        title={getViewTitle(panelId, mcvcn?.name || "")}
        subTitle={Messages.detailMcvcn.titles.long()}
        icon={getViewIcon(panelId)}
        onClose={back}
        isOpen
        views={detailViews}
        activeViewId={getViewId(panelId)}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
        status={mcvcn?.status !== ResourceStatus.Active ? {
          status: getResourceLifecycleStatus(mcvcn?.status || ""),
          tooltip: stateT(mcvcn?.status || ""),
        } : undefined}
        favoriteAccessRegistration={{ id: mcvcn?.id || "", data: { location } }}
      />
    );
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={mcvcn?.name || Messages.detailMcvcn.titles.short()}
      titleSuffix={mcvcn?.name ? getTitleSuffix(panelId || "") : undefined}
      recentAccessRegistration={{ id: mcvcn?.id || "", data: { location } }}
    >
      {renderedContent()}
    </BookmarkablePage>
  );
};
