import { GroupItem, Menu, MenuProps, MenuType, useNavigation } from "o4a-react";
import * as React from "react";
import { useBoolean } from "@fluentui/react-hooks";
import * as Messages from "../codegen/Messages";
import { PromoAlertModal } from "../components/PromoAlertModal/PromoAlertModal";
import {
  ADB_LIST_HUB_ROUTE,
  APEX_LIST_ROUTE,
  BILLING_ROUTE,
  CLOUDLINK_LIST_ROUTE,
  CREATE_HUB_ROUTE,
  DEPLOYMENT_LIST_ROUTE,
  EXADATA_LIST_HUB_ROUTE,
  HOME_ROUTE,
  MCVCN_LIST_ROUTE,
  MYSQL_LIST_ROUTE,
  PageId,
  PageRegistrationConfig,
  SUPPORT_ROUTE,
  VMDB_LIST_HUB_ROUTE,
} from "../constants/pluginConstants";
import { MenuStyleMode, SvgIconIds } from "../constants/uiConstants";
import { useAppAuthContext, UserType } from "../hooks/useAppAuthContext";
import { useFeatures } from "../hooks/useFeatures";
import { useSidePanel } from "../hooks/useSidePanel";
import { useSubscriptions } from "../hooks/useSubscriptions";
import { getOCIConsoleUrl, getOciRegion } from "../utils";
import { ConsoleContext } from "./ConsoleContext";
import { Settings, SettingsContext } from "./SettingsContext";

export interface TopMenuProps extends
  Pick<MenuProps, "menuState" | "onMenuStateChange" | "backgroundColor" | "insetShadow"> {
  menuStyleMode?: MenuStyleMode;
}

export enum MenuTestIds {
  TopMenu = "top-menu"
}

export enum MenuItemTestIds {
  CreateResource = "create-resource",
  Home = "home",
  Adbs = "adbs",
  Exadata = "exadata",
  VmDatabase = "vm-database",
  MysqlDatabase = "mysql-database",
  Apex = "apex",
  Deployments = "deployments",
  Mcvcn = "mcvcn",
  Billing = "billing",
  Subscriptions = "subscriptions",
  Support = "support"
}

export const TopMenu = ({
  menuStyleMode,
  backgroundColor,
  insetShadow,
  menuState,
  onMenuStateChange,
}: TopMenuProps): JSX.Element => {
  const { enableMcvcn, enableMds, enableApex } = useFeatures();
  const { userType } = useAppAuthContext();
  const { loading: subscriptionsLoading, subscriptions } = useSubscriptions();

  const { navigateTo } = useNavigation(ConsoleContext);
  const { closePanels } = useSidePanel();

  const { preferredSubscription } = React.useContext<Settings>(SettingsContext);
  const preferredSubscriptionInfo = subscriptions?.find(sub => sub.id === preferredSubscription);
  const [isPromoAlertModalOpen, { setTrue: showPromoAlertModal, setFalse: hidePromoAlertModal }] = useBoolean(false);
  const noLinkedSubscriptions = userType === UserType.REGULAR_USER && subscriptions?.length === 0;

  let isPromoAccount = false;
  if (subscriptions) {
    for (let i = 0; i < subscriptions.length; i++) {
      if (subscriptions[i].ociTenancyPaymentModel === "FREE") {
        isPromoAccount = true;
      } else {
        isPromoAccount = false;
        break;
      }
    }
  }

  const onResourceCreate = (): void => {
    navigateTo(CREATE_HUB_ROUTE, PageRegistrationConfig[PageId.CREATE_A_RESOURCE][0].key, undefined, true);
  };

  const onHome = (): void => {
    closePanels();
    navigateTo(HOME_ROUTE, PageId.HOME, undefined, true);
  };

  const onAutonomousDatabases = (): void => {
    navigateTo(
      `${ADB_LIST_HUB_ROUTE}/${PageRegistrationConfig[PageId.ADB_LIST_HUB][0].panelPath}`,
      PageRegistrationConfig[PageId.ADB_LIST_HUB][0].key,
      undefined,
      true,
    );
  };

  const onApex = (): void => {
    navigateTo(APEX_LIST_ROUTE, PageId.APEX_LIST, undefined, true);
  };

  const onExaDatabase = (): void => {
    if (isPromoAccount) {
      showPromoAlertModal();
    } else {
      navigateTo(
        `${EXADATA_LIST_HUB_ROUTE}/${PageRegistrationConfig[PageId.EXA_LIST_HUB][0].panelPath}`,
        PageRegistrationConfig[PageId.EXA_LIST_HUB][0].key,
        undefined,
        true,
      );
    }
  };

  const onUpgrade = (): void => {
    const region = getOciRegion(preferredSubscriptionInfo?.primaryLocation || "");
    const ociConsoleUrl = getOCIConsoleUrl();
    const ociTenancyParam = preferredSubscriptionInfo?.ociTenancyName
      ? `&tenant=${preferredSubscriptionInfo?.ociTenancyName}` : "";
    const ociDomainParam = preferredSubscriptionInfo?.ociDomainName
      ? `&domain=${preferredSubscriptionInfo?.ociDomainName}` : "";
    const upgradeUrl = `${ociConsoleUrl}/invoices-and-orders/upgrade-and-payment`
      + `?region=${region}${ociTenancyParam}${ociDomainParam}`;
    window.open(upgradeUrl, "_blank");
  };

  const onVmDatabase = (): void => {
    navigateTo(
      `${VMDB_LIST_HUB_ROUTE}/${PageRegistrationConfig[PageId.VMDB_LIST_HUB][0].panelPath}`,
      PageRegistrationConfig[PageId.VMDB_LIST_HUB][0].key,
      undefined,
      true,
    );
  };

  const onMysqlDatabase = (): void => {
    navigateTo(MYSQL_LIST_ROUTE, PageId.MYSQL_LIST, undefined, true);
  };

  const onMcvcn = (): void => {
    navigateTo(MCVCN_LIST_ROUTE, PageId.MCVCN_LIST, undefined, true);
  };

  const onDeployments = (): void => {
    navigateTo(DEPLOYMENT_LIST_ROUTE, PageId.DEPLOYMENT_LIST, undefined, true);
  };

  const onSupport = (): void => {
    navigateTo(SUPPORT_ROUTE, PageId.SUPPORT, undefined, true);
  };

  const onCloudLinks = (): void => {
    navigateTo(CLOUDLINK_LIST_ROUTE, PageId.CLOUDLINK_LIST, undefined, true);
  };

  const onBilling = (): void => {
    navigateTo(BILLING_ROUTE, PageId.BILLING, undefined, true);
  };

  const navMenuDataBasic: GroupItem[] = [
    {
      items: [
        {
          id: "1002",
          name: Messages.common.home(),
          icon: "HomeSolid",
          onClick: onHome,
        },
      ],
    },
  ];

  const navMenuData: GroupItem[] = [
    {
      items: [
        {
          id: "1001",
          name: Messages.actions.createAResource(),
          icon: SvgIconIds.addSvg,
          disabled: noLinkedSubscriptions,
          onClick: onResourceCreate,
          testId: MenuItemTestIds.CreateResource,
        },
        {
          id: "1002",
          name: Messages.common.home(),
          icon: SvgIconIds.homeSvg,
          onClick: onHome,
          testId: MenuItemTestIds.Home,
        },
      ],
    },
    {
      heading: "",
      items: [
        {
          id: "2001",
          name: Messages.topServices.autonomousDatabases(),
          icon: SvgIconIds.adbSvg,
          disabled: noLinkedSubscriptions,
          onClick: onAutonomousDatabases,
          testId: MenuItemTestIds.Adbs,
        },
        {
          id: "2003",
          name: Messages.topServices.exadata(),
          icon: SvgIconIds.exaSvg,
          disabled: noLinkedSubscriptions,
          onClick: onExaDatabase,
          testId: MenuItemTestIds.Exadata,
        },
        {
          id: "2004",
          name: Messages.topServices.vmDatabase(),
          icon: SvgIconIds.baseDbSvg,
          disabled: noLinkedSubscriptions,
          onClick: onVmDatabase,
          testId: MenuItemTestIds.VmDatabase,
        },
        ...(enableMds ? [{
          id: "2005",
          name: Messages.topServices.mysqlDatabase(),
          icon: SvgIconIds.mysqlSvg,
          disabled: noLinkedSubscriptions,
          onClick: onMysqlDatabase,
          testId: MenuItemTestIds.MysqlDatabase,
        }] : []),
        ...(enableApex ? [{
          id: "2006",
          name: Messages.topServices.apex(),
          icon: SvgIconIds.apexSvg,
          disabled: noLinkedSubscriptions,
          onClick: onApex,
          testId: MenuItemTestIds.Apex,
        }] : []),
        {
          id: "2101",
          name: Messages.topServices.deployments(),
          icon: SvgIconIds.deploymentSvg,
          disabled: noLinkedSubscriptions,
          onClick: onDeployments,
          testId: MenuItemTestIds.Deployments,
        },
        ...(enableMcvcn ? [{
          id: "2102",
          name: Messages.topServices.mcvcn(),
          icon: SvgIconIds.networkSvg,
          disabled: noLinkedSubscriptions,
          onClick: onMcvcn,
          testId: MenuItemTestIds.Mcvcn,
        }] : []),
        {
          id: "2103",
          name: Messages.topServices.billing(),
          icon: SvgIconIds.billingSvg,
          disabled: noLinkedSubscriptions,
          onClick: onBilling,
          testId: MenuItemTestIds.Billing,
        },
        {
          id: "2104",
          name: Messages.topServices.subscriptionManagement(),
          icon: SvgIconIds.subscriptionSvg,
          onClick: onCloudLinks,
          testId: MenuItemTestIds.Subscriptions,
        },
        {
          id: "2105",
          name: Messages.topServices.support(),
          icon: SvgIconIds.supportSvg,
          disabled: noLinkedSubscriptions,
          onClick: onSupport,
          testId: MenuItemTestIds.Support,
        },
      ],
    },
  ];

  return (
    <>
      <Menu
        type={MenuType.TOP_MENU}
        groups={subscriptionsLoading || userType !== UserType.REGULAR_USER ? navMenuDataBasic : navMenuData}
        isCollapsible={MenuStyleMode.Docking === menuStyleMode}
        backgroundColor={backgroundColor}
        insetShadow={insetShadow}
        hideSearchBox
        testId={MenuTestIds.TopMenu}
        menuState={menuState}
        onMenuStateChange={onMenuStateChange}
      />
      <PromoAlertModal
        isPromoAlertModalOpen={isPromoAlertModalOpen}
        hidePromoAlertModal={hidePromoAlertModal}
        onUpgrade={onUpgrade}
      />
    </>
  );
};
