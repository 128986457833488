import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXADB_PDB_DELETE_POLL_DELAY,
  EXADB_PDB_DELETE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface ExaDbDeletePdbProps extends OperationConfirmationProps {
  databaseId: string;
}

const ExaDbDeletePdb = (
  { location, databaseId, onExecute }: ExaDbDeletePdbProps,
): JSX.Element => {
  const { invokeCall: invokePdbDelete } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.deletePluggableDatabase },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
    const resGroup = decodeURIComponent(resourceGroup || "");
    const pdbName = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.deletePluggableDb(),
          message: Messages.notifications.inProgress.messages.deletePluggableDb(pdbName),
        },
        success: {
          title: Messages.notifications.success.titles.deletePluggableDb(),
          message: Messages.notifications.success.messages.deletePluggableDb(pdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.delete(),
          message: Messages.notifications.failure.messages.deletePluggableDb(pdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXADB_PDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            pluggableDatabaseName: pdbName,
          },
          location,
          errorStatusOverrides: [404],
          responseMethodKey: AsyncNotificationPolledResponseKey.EXADB_DELETED_CHECK_PDB,
          delay: EXADB_PDB_DELETE_POLL_DELAY,
          interval: EXADB_PDB_DELETE_POLL_INTERVAL,
        },
      },
    };
    invokePdbDelete({
      subscriptionId,
      resourceGroupName: resGroup,
      pluggableDatabaseName: pdbName,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newExaDbDeletePdb = (
  props: ExaDbDeletePdbProps,
): JSX.Element => (<ExaDbDeletePdb {...props} />);
