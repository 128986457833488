import { CustomLink, NoValue } from "o4a-react";
import * as React from "react";
import { Link } from "@fluentui/react";
import { parseId } from "../../helpers/idHelper";
import { useAppAuthContext } from "../../hooks/useAppAuthContext";
import { azurePortalUrl } from "../../utils";

export interface AzureSubscriptionLinkProps {
  resourceId: string;
  subscriptionName: string | undefined;
  subscriptionId?: string | undefined;
  hideClipboard?: boolean;
}

export const AzureSubscriptionLink = (
  { resourceId, subscriptionId, subscriptionName, hideClipboard = false }: AzureSubscriptionLinkProps,
): JSX.Element => {
  const { domainName } = useAppAuthContext();
  const idComps = parseId(resourceId) || "";
  const subId = subscriptionId || decodeURIComponent(idComps?.subscriptionId || "");
  const text = subscriptionName || subId;
  const url = `${azurePortalUrl}/#@${domainName}/resource/subscriptions/${subId}/overview`;

  if (!subId) return <NoValue />;

  if (hideClipboard) {
    return (
      <Link
        title={text}
        href={url}
        target="_blank"
        underline
      >
        {text}
      </Link>
    );
  }

  return (
    <CustomLink
      href={url}
      target="_blank"
      underline
      linkText={text}
    />
  );
};
