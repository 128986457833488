import {
  AnchoredPanelComponent,
  BookmarkablePage,
  FullPagePanel,
  InfoBlock,
  InfoBlockStatus,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { DEPLOYMENT_LIST_ROUTE, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { SvgIconIds } from "../../constants/uiConstants";
import { DeploymentList } from "./DeploymentList";

const registrationIds = PageRegistrationConfig[PageId.DEPLOYMENT_LIST].map(config => config.key);

export const DeploymentListPage = (): JSX.Element => {
  const { back, navigateToSelf } = useNavigation(ConsoleContext);
  const { pathname } = useLocation();
  const filterState = React.useContext<FilterState>(FilterStateContext);

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  React.useEffect(() => {
    // In case page was navigated to directly by entering its URL in the browser
    navigateToSelf(DEPLOYMENT_LIST_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (filterState.pillFilterStateValue.listId !== pathname) {
      filterState.setFilterStateValue({ filterValues: undefined, listId: undefined, defaultPills: undefined });
      filterState.setFilterTextValue(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.deployment.titles.short()}
    >
      <FullPagePanel
        componentRef={setPanelRef}
        title={Messages.deployment.titles.long()}
        icon={SvgIconIds.deploymentSvg}
        onClose={back}
        isOpen
      >
        <Stack tokens={{ childrenGap: 10 }} style={{ height: "100%" }}>
          <Stack>
            <InfoBlock
              message={Messages.deployment.messages.cleanup()}
              messageType={InfoBlockStatus.WARNING}
            />
          </Stack>
          {/* 32px info block height & 10px child gap */}
          <Stack style={{ flex: 1, height: "calc(100% - 32px - 10px)" }}>
            <DeploymentList />
          </Stack>
        </Stack>
      </FullPagePanel>
    </BookmarkablePage>
  );
};
