import { InputFormCallout, InputFormCalloutProps, SubmitButtonMode, TextInput, TextInputTypes } from "o4a-react";
import * as React from "react";
import { Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";
import { adbsWalletPwdMaxLength, adbsWalletPwdMinLength, WalletPasswordRegex } from "../../helpers/validationHelper";

export type AdbsWalletDownloadProps = Pick<InputFormCalloutProps, "targetId" | "onClose" | "onSubmit" | "componentRef">;

export enum Fields {
  Password = "password",
  ConfirmPassword = "confirmPassword"
}

export enum FieldTestIds {
  Password = "password-field",
  ConfirmPassword = "confirm-password-field"
}

export const AdbsWalletDownload = (
  { targetId, onClose, onSubmit, componentRef }: AdbsWalletDownloadProps,
): JSX.Element => {
  const [password, setPassword] = React.useState<string>();
  return (
    <InputFormCallout
      testId={CalloutTestIds.WalletDownload}
      componentRef={componentRef}
      title={Messages.createNewPanels.walletDownload.title()}
      targetId={targetId}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Text>
        {Messages.createNewPanels.walletDownload.description()}
      </Text>
      <TextInput
        required
        testId={FieldTestIds.Password}
        fieldName={Fields.Password}
        label={Messages.labels.password()}
        tooltip={Messages.hints.tooltipWalletPassword()}
        textType={TextInputTypes.PASSWORD}
        onChange={value => { setPassword(value); }}
        validator={(value: string | undefined) => {
          const errors: string[] = [];
          if (!value || value.length < adbsWalletPwdMinLength || value.length > adbsWalletPwdMaxLength) {
            errors.push(Messages.validation.inputRange(
              adbsWalletPwdMinLength.toString(),
              adbsWalletPwdMaxLength.toString(),
            ));
          } else if (!WalletPasswordRegex.test(value)) {
            errors.push(Messages.validation.walletPasswordValidation());
          }

          return errors.length > 0 ? errors : undefined;
        }}
      />
      <TextInput
        required
        key={password}
        testId={FieldTestIds.ConfirmPassword}
        fieldName={Fields.ConfirmPassword}
        label={Messages.labels.passwordConfirm()}
        textType={TextInputTypes.PASSWORD}
        validator={(value: string | undefined) => {
          if (value && (value !== password)) {
            return [Messages.validation.passwordConfirmation()];
          }
          return undefined;
        }}
      />
    </InputFormCallout>
  );
};
