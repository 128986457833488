import { Select, SelectOption, SelectProps, uniqueGUID } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { SettingsContext } from "../../console/SettingsContext";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { getAzureLocationName, regions } from "../../utils";

export interface RegionProps extends Omit<SelectProps, "options"> {
  subscriptionId: string;
  showUnlinkedRegions?: boolean;
}

export const RegionSelect = ({
  subscriptionId,
  showUnlinkedRegions,
  defaultValue,
  fieldName,
  label,
  inputLink,
  required,
  subField,
  tooltip,
  validator,
  disabled,
  onChange,
  testId,
  ariaLabel,
}: RegionProps): JSX.Element => {
  const { preferredLocationPerSubscription } = React.useContext(SettingsContext);
  const [key, setKey] = React.useState<string>(uniqueGUID());
  const { loading, subscriptions } = useSubscriptions();
  const [options, setOptions] = React.useState<{id: string, text: string} []>([]);
  const [selectedOption, setSelectedOption] = React.useState<string[] | undefined>(defaultValue);

  React.useEffect(() => {
    const newLocations = subscriptions?.find(sub => sub.id === subscriptionId)?.locations;
    const newOptions = newLocations?.map(loc => ({
      id: loc,
      text: getAzureLocationName(loc),
    } as SelectOption));
    if (newOptions && regions && showUnlinkedRegions) {
      for (let i = 0; i < regions.length; i++) {
        if (!newLocations?.includes(regions[i].regionName)) {
          newOptions?.push({
            id: regions[i].regionName,
            text: getAzureLocationName(regions[i].regionName),
            disabled: true,
            tooltip: Messages.hints.regionNotAdded(),
          });
        }
      }
    }
    setOptions(newOptions || []);

    let newSelectedOption: string[] | undefined;

    if (newOptions && newOptions.length) {
      if (defaultValue && newLocations?.includes(defaultValue[0])) {
        newSelectedOption = defaultValue;
      } else {
        const preferredLocation = preferredLocationPerSubscription[subscriptionId];

        newSelectedOption = preferredLocation
          ? [preferredLocation]
          : undefined;
      }
    }

    setSelectedOption(newSelectedOption);

    if (!loading) {
      setKey(uniqueGUID());
      if (newSelectedOption?.length && (!defaultValue || !newSelectedOption?.includes(defaultValue[0]))) {
        onChange?.(newSelectedOption[0]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions), subscriptionId, loading]);

  return (
    <Select
      key={key}
      testId={testId}
      loading={loading}
      fieldName={fieldName}
      ariaLabel={ariaLabel}
      onChange={onChange}
      options={options}
      label={label}
      required={required}
      subField={subField}
      inputLink={inputLink}
      defaultValue={options?.length > 0 ? selectedOption : undefined}
      tooltip={tooltip}
      disabled={disabled}
      validator={validator}
    />
  );
};
