import {
  AsyncFieldError,
  AsyncValidationError,
  BookmarkablePage,
  ConfigInputValues,
  FormFieldErrors,
  FormValues,
  getValue,
  InputWizardItemMessage,
  InputWizardPanel,
  InputWizardPanelComponent,
  MessageType,
  PanelMessage,
  ReviewItem,
  SelectOption,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { DeploymentAsyncValidationPhase } from "../../components/DeploymentPhases/DeploymentAsyncValidationPhase";
import { DeployedService, DeploymentCreatePhase } from "../../components/DeploymentPhases/DeploymentCreationPhase";
import { DownloadSshKeyModal } from "../../components/SshKeyPair/DownloadSshKeyModal";
import {
  defaultName,
  DerivedFields as NestedSshInputFields,
  SshPublicKeySource,
} from "../../components/SshKeyPair/SshKeyPairInput";
import { GROUP_TAGS } from "../../components/Tags/TagsCollection";
import { ConsoleContext } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  PageId,
  PageRegistrationConfig,
  VM_CLUSTERS_RESOURCE_TYPE_PATH,
  VMCLUSTER_CREATE_ROUTE,
} from "../../constants/pluginConstants";
import { CreateWizardTestIds } from "../../constants/uiConstants";
import {
  AzureVirtualNetworkSummary,
  CreateCloudVmClusterDeploymentDetails,
  CreateMultiCloudVirtualNetworkDeploymentDetails,
  CreateNetworkLinkDeploymentDetails,
  ExadataDeploymentParametersDetails,
  GetCloudExadataInfrastructureDeploymentDetails,
  GetMultiCloudVirtualNetworkDeploymentDetails,
  MultiCloudVirtualNetworkDeploymentDetails,
  MultiCloudVirtualNetworkSummary,
  RegisterExistingOciNetworkDeploymentDetails,
} from "../../gen/clients/mchub-azure-api-client";
import { CreateCloudVmClusterDeploymentDetailsLicenseModelEnum } from "../../gen/clients/mchub-azure-api-client-exa";
import { saveBlob } from "../../helpers/fileHelper";
import { buildResourceGroupId, parseId } from "../../helpers/idHelper";
import {
  DeploymentKinds,
  FutureMcvcn,
  FutureMcvcnExisting,
  FutureMcvcnNew,
  getAddressRange,
  getResourceTypeMessage,
  getTagsPerResourceTypeMap,
  ResourceType,
  TagsInfoType,
} from "../../helpers/resourceHelper";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { getTimestamp } from "../../helpers/timeHelper";
import { validateIpAddressWithinRanges } from "../../helpers/validationHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useRoles } from "../../hooks/useRoles";
import { useSidePanel } from "../../hooks/useSidePanel";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMCLUSTER_DEPLOYMENT_CREATE_POLL_DELAY,
  VMCLUSTER_DEPLOYMENT_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { ReviewTabContent } from "../CreateCommon/ReviewTabContent";
import { Fields as TagsFields, TagsTabContent } from "../CreateCommon/TagsTabContent";
import { BasicsTabContent, Fields as BasicsFields, GROUP_BASICS } from "./BasicsTabContent";
import { ConfigTabContent, Fields as ConfigFields, GROUP_CONFIG } from "./ConfigTabContent";
import { Fields as NetworkFields, GROUP_NETWORK, NetworkTabContent } from "./NetworkTabContent";
import { Fields as SecurityFields, GROUP_SECURITY, SecurityTabContent } from "./SecurityTabContent";

const registrationIds = PageRegistrationConfig[PageId.VMCLUSTER_CREATE].map(config => config.key);

export const onProcessAsyncValidationErrors = (
  asyncErrors: AsyncValidationError[],
): AsyncValidationError[] => asyncErrors.map(asyncError => {
  if (typeof asyncError === "string") {
    return asyncError;
  }
  const asyncFieldError = asyncError as AsyncFieldError;
  let fieldLabel;
  if (asyncFieldError.field.startsWith("multiCloudVirtualNetworkDetails.")) {
    if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.name") {
      fieldLabel = Messages.labels.name();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.azureAttachedNetworkIds") {
      fieldLabel = Messages.labels.virtualNetwork();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociVcnCidrBlocks") {
      fieldLabel = Messages.labels.cidrBlocks();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociVcnOcid") {
      fieldLabel = Messages.labels.vcnOcid();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociSubnetOcids") {
      fieldLabel = Messages.labels.subnetOcids();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.customerNvaIpAddress") {
      fieldLabel = Messages.labels.networkVirtualAppliance();
    } else {
      return asyncFieldError;
    }

    asyncFieldError.field = NetworkFields.Mcvcn;
    asyncFieldError.error = `<strong>(${Messages.common.new()} | ${fieldLabel})</strong> ${asyncFieldError.error}`;
  } else if (asyncFieldError.field === "cloudVmClusterDetails.isLocalBackupEnabled") {
    fieldLabel = Messages.labels.allocateStorageLocal();
    asyncFieldError.field = ConfigFields.StorageConfiguration;
    asyncFieldError.error = `<strong>(${fieldLabel})</strong> ${asyncFieldError.error}`;
  } else if (asyncFieldError.field === "cloudVmClusterDetails.isSparseDiskgroupEnabled") {
    fieldLabel = Messages.labels.allocateStorageSparse();
    asyncFieldError.field = ConfigFields.StorageConfiguration;
    asyncFieldError.error = `<strong>(${fieldLabel})</strong> ${asyncFieldError.error}`;
  } else if (asyncFieldError.field === ConfigFields.VmClusterConfiguration) {
    fieldLabel = Messages.labels.ocpuCountPerVM();
    asyncFieldError.field = ConfigFields.VmClusterConfiguration;
    asyncFieldError.error = `<strong>(${fieldLabel})</strong> ${asyncFieldError.error}`;
  }

  return asyncFieldError;
});

export enum ActionIds {
  DownloadSshKey = "download-ssh-key",
}

enum CreationPhase {
  ASYNC_VALIDATE = "ASYNC_VALIDATE",
  CREATE_SUBMIT = "CREATE_SUBMIT",
}

export enum PanelTestIds {
  Basics = "basic",
  Configuration = "configuration",
  Network = "network",
  Security = "security",
  Tags = "tags",
}

export const ExaVmClusterCreatePage = (): JSX.Element => {
  const { enableMcvcn, enableExaMcvcnCreate, enableExaVmClusterPreflight } = useFeatures();

  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const isCreateRoleMissing = !isActionAllowed(RoleBasedAction.CREATE_EXA_VMCLUSTER);
  const requiredRole = actionRequiredRoles(RoleBasedAction.CREATE_EXA_VMCLUSTER)[0]?.displayName;

  const { trackActionClick, trackActionDiscard } = useAnalytics();
  const { closePanels } = useSidePanel();

  const { back, navigateToSelf, customData } = useNavigation(ConsoleContext);

  const { preferredLocationPerSubscription, preferredSubscription } = React.useContext<Settings>(SettingsContext);
  const preferredLocation = preferredLocationPerSubscription[preferredSubscription] || "";

  const [infraId, setInfraId] = React.useState<string>("");
  const [location, setLocation] = React.useState<string>("");
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const [subscriptionId, setSubscriptionId] = React.useState<string>("");
  const [resourceGroupName, setResourceGroupName] = React.useState<string>("");

  React.useEffect(() => {
    if (customData?.location || customData?.infraId) {
      setDisabled(true);
      setLocation(customData?.location);
      setInfraId(customData?.infraId);
      setSubscriptionId(parseId(customData?.infraId)?.subscriptionId);
    } else {
      setLocation(preferredLocation);
      setSubscriptionId(preferredSubscription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resourceNameRef = React.useRef<string>(""); // to hold the name to be used in failure message
  const deploymentNameRef = React.useRef<string>("");

  const [
    displayDownloadSshKey,
    { setFalse: hideDownloadSshKey, setTrue: showDownloadSshKey },
  ] = useBoolean(false);

  const [creationPhase, setCreationPhase] = React.useState<CreationPhase | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [createVmClusterPayload, setCreateVmClusterPayload] = React.useState<any>();
  const asyncValidationCallbacks = React.useRef<{
    resolve:(errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ resolve: () => {}, reject: () => {} });

  const submitCallbacks = React.useRef<{
    reject:(errorMessage: string, validationErrors?: AsyncValidationError[]) => void
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ reject: () => {} });

  React.useEffect(() => {
    // In case page was navigated to directly by entering its URL in the browser
    navigateToSelf(VMCLUSTER_CREATE_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [panelRef, setPanelRef] = React.useState<InputWizardPanelComponent>({} as InputWizardPanelComponent);

  const buildVmCluster = (formValues: FormValues): CreateCloudVmClusterDeploymentDetails => {
    const name = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS) ?? "";
    const tags = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS);
    const tagsMap = getTagsPerResourceTypeMap(tags);

    const sshPublicKey = getValue<Record<NestedSshInputFields, string>>(
      formValues,
      SecurityFields.sshKeyPair,
      GROUP_SECURITY,
    )?.[NestedSshInputFields.PublicKey];

    const vmClusterConfig = getValue<ConfigInputValues>(formValues, ConfigFields.VmClusterConfiguration, GROUP_CONFIG);
    const giVersion = getValue<SelectOption[]>(
      formValues,
      ConfigFields.OracleGridInfrastructureVersion,
      GROUP_CONFIG,
    )?.[0].id ?? "";

    const licenseModel = getValue<SelectOption[]>(
      formValues,
      ConfigFields.LicenseType,
      GROUP_CONFIG,
    )?.[0].id as CreateCloudVmClusterDeploymentDetailsLicenseModelEnum | undefined;

    const storageConfig = getValue<ConfigInputValues>(
      formValues,
      ConfigFields.StorageConfiguration,
      GROUP_CONFIG,
    )?.value;

    const vmCluster: CreateCloudVmClusterDeploymentDetails = {
      kind: DeploymentKinds.Create,
      name,
      cpuCoreCount: vmClusterConfig?.value.count,
      hostname: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK) ?? "",
      sshPublicKeys: sshPublicKey ? [sshPublicKey] : [],
      giVersion,
      licenseModel,
      freeformTags: tagsMap[ResourceType.VM],
      isLocalBackupEnabled: storageConfig?.localEnabled,
      isSparseDiskgroupEnabled: storageConfig?.sparseEnabled,
    };
    return vmCluster;
  };

  const buildMcvcn = (formValues: FormValues): MultiCloudVirtualNetworkDeploymentDetails => {
    // eslint-disable-next-line max-len
    const data: FutureMcvcnNew | FutureMcvcnExisting | MultiCloudVirtualNetworkSummary | undefined = getValue<SelectOption[]>(
      formValues,
      NetworkFields.Mcvcn,
      GROUP_NETWORK,
    )?.[0]?.data;

    // Only exists if the user has selected an existing MCVCN
    const { id } = data as MultiCloudVirtualNetworkSummary;
    // Exists if the user is creating a new MCVCN
    const { name, azureAttachedNetworkId, customerNvaIpAddress } = data as FutureMcvcn;
    // Exists if the user is creating a new MCVCN using an existing OCI VCN
    const { ociSubnetOcids, ociVcnOcid } = data as FutureMcvcnExisting;
    // Exists if the user is creating a new MCVCN using a new OCI VCN
    const { ociVcnCidrBlocks } = data as FutureMcvcnNew;

    let mcvcnDetails: MultiCloudVirtualNetworkDeploymentDetails;
    const azureAttachedNetworkIds = [azureAttachedNetworkId];

    if (id) {
      mcvcnDetails = {
        kind: DeploymentKinds.Get,
        id,
      } as GetMultiCloudVirtualNetworkDeploymentDetails;
    } else if (ociVcnCidrBlocks) {
      mcvcnDetails = {
        kind: DeploymentKinds.Create,
        azureAttachedNetworkIds,
        name,
        ociVcnCidrBlocks,
        customerNvaIpAddress,
      } as CreateMultiCloudVirtualNetworkDeploymentDetails;
    } else if (ociVcnOcid && ociSubnetOcids) {
      mcvcnDetails = {
        kind: DeploymentKinds.Register,
        azureAttachedNetworkIds,
        name,
        ociVcnOcid,
        ociSubnetOcids,
        customerNvaIpAddress,
      } as RegisterExistingOciNetworkDeploymentDetails;
    } else throw new Error("Invalid parameters to create MCVCN");

    return mcvcnDetails;
  };

  const buildNetworkLink = (formValues: FormValues): CreateNetworkLinkDeploymentDetails => {
    const name = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS);
    const cidrRange = getValue<string>(formValues, NetworkFields.Cidrs, GROUP_NETWORK);
    const cidr = cidrRange ? [cidrRange] : [];
    const vnet = getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].id;
    const azureAttachedNetworkIds = vnet ? [vnet] : [];

    const networkLinkDetails: CreateNetworkLinkDeploymentDetails = {
      kind: DeploymentKinds.Create,
      name: `${name}-networkLink`,
      ociNetworkCidrs: cidr,
      azureAttachedNetworkIds,
      customerNvaIpAddress: getValue<string>(formValues, NetworkFields.NetworkVirtualAppliance, GROUP_NETWORK),
    };
    return networkLinkDetails;
  };

  const onAsyncValidate = (
    formValues: FormValues,
    resolve: (errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
  ): void => {
    setCreateVmClusterPayload(buildCreateVmClusterPayload(formValues));
    asyncValidationCallbacks.current = { resolve, reject };
    setCreationPhase(CreationPhase.ASYNC_VALIDATE);
  };

  const onSubmit = (formValues: FormValues): void => {
    setCreateVmClusterPayload(buildCreateVmClusterPayload(formValues));
    setCreationPhase(CreationPhase.CREATE_SUBMIT);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildCreateVmClusterPayload = (formValues: FormValues): any => {
    resourceNameRef.current = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS) ?? "";
    deploymentNameRef.current = `${VM_CLUSTERS_RESOURCE_TYPE_PATH}-${getTimestamp()}`;
    const payload = ({
      subscriptionId: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].id,
      resourceGroupName: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      createDeploymentDetails: {
        name: deploymentNameRef.current,
        parameters: {
          kind: DeploymentKinds.Exadata,
          networkLinkDetails: !(enableMcvcn && enableExaMcvcnCreate) ? buildNetworkLink(formValues) : undefined,
          multiCloudVirtualNetworkDetails: enableMcvcn && enableExaMcvcnCreate ? buildMcvcn(formValues) : undefined,
          cloudExadataInfrastructureDetails: {
            kind: DeploymentKinds.Get,
            id: getValue<SelectOption[]>(formValues, ConfigFields.Infrastructure, GROUP_CONFIG)?.[0].id,
          } as GetCloudExadataInfrastructureDeploymentDetails,
          cloudVmClusterDetails: { ...buildVmCluster(formValues) },
        } as ExadataDeploymentParametersDetails,
      },
    });

    return payload;
  };

  const buildBasicInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewBasicInfo = [
      {
        label: Messages.labels.subscription(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.resourceGroup(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.name(),
        value: getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS),
      },
      {
        label: Messages.labels.region(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Region, GROUP_BASICS)?.[0].text,
      },
    ];
    return reviewBasicInfo;
  };

  const buildConfigInfo = (formValues: FormValues): ReviewItem[] => {
    const infraStructure = getValue<SelectOption[]>(formValues, ConfigFields.Infrastructure, GROUP_CONFIG)?.[0].text;
    const storageOptions = getValue<ConfigInputValues>(
      formValues,
      ConfigFields.StorageConfiguration,
      GROUP_CONFIG,
    )?.value;

    const reviewConfigInfo: ReviewItem[] = [];

    if (infraStructure) {
      reviewConfigInfo.push(
        {
          label: Messages.labels.infra(),
          value: infraStructure,
        },
      );
    }

    const vmClusterConfig = getValue<ConfigInputValues>(formValues, ConfigFields.VmClusterConfiguration, GROUP_CONFIG);
    const giVersion = getValue<SelectOption[]>(
      formValues,
      ConfigFields.OracleGridInfrastructureVersion,
      GROUP_CONFIG,
    )?.[0].text;

    reviewConfigInfo.push(
      {
        label: Messages.labels.vmClusterConfiguration(),
        value: vmClusterConfig?.title || vmClusterConfig?.summary,
      },
      {
        label: Messages.labels.allocateStorageLocal(),
        value: storageOptions?.localEnabled ? Messages.common.yes() : Messages.common.no(),
      },
      {
        label: Messages.labels.allocateStorageSparse(),
        value: storageOptions?.sparseEnabled ? Messages.common.yes() : Messages.common.no(),
      },
      {
        label: Messages.labels.oracleGridInfrastructureVersion(),
        value: giVersion,
      },
      {
        label: Messages.labels.licenseType(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.LicenseType, GROUP_CONFIG)?.[0].text,
      },
    );
    return reviewConfigInfo;
  };

  const buildNetworkInfo = (formValues: FormValues): ReviewItem[] => {
    const renderList = (list: string[]): JSX.Element => {
      const elements = list.map(value => (
        <Stack horizontal key={value}>
          <span style={{ paddingBottom: 1 }}>
            {value}
          </span>
        </Stack>
      ));
      return (
        <div>
          {elements}
        </div>
      );
    };
    const reviewNetworkingInfo: ReviewItem[] = [];

    if (!(enableMcvcn && enableExaMcvcnCreate)) {
      const cidrRange = getValue<string>(formValues, NetworkFields.Cidrs, GROUP_NETWORK);
      const cidrAddresses = getAddressRange(cidrRange);
      const cidr = [`${cidrRange} [${cidrAddresses}]`];
      const networkVirtualAppliance = getValue<string>(
        formValues,
        NetworkFields.NetworkVirtualAppliance,
        GROUP_NETWORK,
      );

      reviewNetworkingInfo.push(
        {
          label: Messages.labels.hostName(),
          value: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK),
        },
        {
          label: Messages.labels.virtualNetwork(),
          value: getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].text,
        },
        ...(networkVirtualAppliance
          ? [{ label: Messages.labels.networkVirtualAppliance(), value: networkVirtualAppliance }]
          : []),
        {
          label: Messages.labels.ociCidrs(),
          value: renderList(cidr),
        },
      );
    }

    if (enableMcvcn && enableExaMcvcnCreate) {
      // eslint-disable-next-line max-len
      const mcvcn: MultiCloudVirtualNetworkSummary | FutureMcvcnExisting | FutureMcvcnNew | undefined = getValue<SelectOption[]>(
        formValues,
        NetworkFields.Mcvcn,
        GROUP_NETWORK,
      )?.[0].data;

      if (!mcvcn) return [];

      // Only exists if the user has selected an existing MCVCN
      const createNewMcvcn = !(mcvcn as MultiCloudVirtualNetworkSummary).id;

      // Exists if the user is creating a new MCVCN
      const { name, customerNvaIpAddress, azureAttachedNetworkId } = mcvcn as FutureMcvcn;
      const virtualNetwork = decodeURIComponent(parseId(azureAttachedNetworkId)?.resourceName || "");

      // Exists if the user is creating a new MCVCN using a new OCI VCN
      const { ociVcnCidrBlocks } = mcvcn as FutureMcvcnNew;
      // Exists if the user is creating a new MCVCN using an existing OCI VCN
      const { ociSubnetOcids, ociVcnOcid } = mcvcn as FutureMcvcnExisting;

      reviewNetworkingInfo.push(
        {
          label: Messages.labels.hostName(),
          value: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK),
        },
        {
          label: Messages.labels.virtualCloudNetwork(),
          value: name,
        },
      );

      if (createNewMcvcn) {
        reviewNetworkingInfo.push(
          ...(virtualNetwork
            ? [{ label: Messages.labels.virtualNetwork(), value: virtualNetwork }]
            : []),
          ...(customerNvaIpAddress
            ? [{ label: Messages.labels.networkVirtualAppliance(), value: customerNvaIpAddress }]
            : []),
          ...(ociVcnOcid
            ? [{ label: Messages.labels.vcnOcid(), value: ociVcnOcid }]
            : []),
          ...(ociSubnetOcids
            ? [{ label: Messages.labels.subnetOcids(), value: renderList(ociSubnetOcids) }]
            : []),
          ...(ociVcnCidrBlocks
            ? [{
              label: Messages.labels.cidrBlocks(),
              value: renderList(ociVcnCidrBlocks.map(cidr => `${cidr} [${getAddressRange(cidr)}]`)),
            }]
            : []),
        );
      }
    }
    return reviewNetworkingInfo;
  };

  const buildSecurityInfo = (formValues: FormValues): ReviewItem[] => {
    const sshKeySourceFieldName = SecurityFields.sshKeyPair + NestedSshInputFields.Source;
    const sshKeySource = getValue<SelectOption[]>(formValues, sshKeySourceFieldName, GROUP_SECURITY)?.[0].id;

    const sshKeyValue = sshKeySource !== undefined ? sshKeySource === SshPublicKeySource.Upload
      ? Messages.common.uploaded() : Messages.common.generated() : Messages.common.no();
    const reviewSecurityInfo = [
      {
        label: Messages.labels.sshPublicKey(),
        value: sshKeyValue,
      },

    ];
    return reviewSecurityInfo;
  };

  const buildTagsInfo = (formValues: FormValues): ReviewItem[] => {
    const tagsInfo = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS) ?? [];
    const tags: { label: string; value: string; }[] = [];

    tagsInfo.forEach(element => (
      element.resources?.forEach(resource => (
        element.name !== undefined && tags.push({
          label: element.name,
          value: `${element.value !== undefined ? element.value : ""}(${resource.text})`,
        })
      ))));
    if (tags.length === 0) tags.push({ label: "None", value: "" });
    tags.sort((a, b) => ((a.value.split("(")[1].split(")")[0] > b.value.split("(")[1].split(")")[0]) ? 1 : -1));
    return tags;
  };
  const getTagsResourceTypeOptions = (): SelectOption[] => {
    const resourceOptions: SelectOption[] = [
      { id: ResourceType.VM, text: getResourceTypeMessage(ResourceType.VM) }];

    return resourceOptions;
  };

  const onRenderReview = (formValues: FormValues): JSX.Element => {
    const reviewSections = [];
    reviewSections.push(
      { title: Messages.createVMCluster.basicsTab.title(), items: buildBasicInfo(formValues) },
      { title: Messages.createVMCluster.configurationTab.title(), items: buildConfigInfo(formValues) },
      { title: Messages.createVMCluster.networkingTab.title(), items: buildNetworkInfo(formValues) },
      { title: Messages.createVMCluster.securityTab.title(), items: buildSecurityInfo(formValues) },
      { title: Messages.tags.title(), items: buildTagsInfo(formValues) },
    );
    return <ReviewTabContent reviewSections={reviewSections} />;
  };

  const onConfirmDownloadSshKey = (): void => {
    const privateKey = getValue<Record<NestedSshInputFields, string>>(
      panelRef.getFormValues?.() ?? {},
      SecurityFields.sshKeyPair,
      GROUP_SECURITY,
    )?.[NestedSshInputFields.PrivateKey];

    if (privateKey) {
      saveBlob(privateKey, `${defaultName()}.key`);
    }

    onSubmit(panelRef.getFormValues() ?? {});
  };

  const onFormSubmit = (formValues: FormValues): void => {
    const sshKeySourceFieldName = SecurityFields.sshKeyPair + NestedSshInputFields.Source;
    const sshKeySource = getValue<SelectOption[]>(formValues, sshKeySourceFieldName, GROUP_SECURITY)?.[0].id;

    // if the ssh key is set to generate
    if (SshPublicKeySource.Generate === sshKeySource) {
      /**
       * Normally we should not track action click in onSubmit but this is an exception
       * since the dialog is opened automatically by the wizard and not as a result of
       * a user action.
       */
      trackActionClick(ActionIds.DownloadSshKey, PageId.VMCLUSTER_CREATE);
      // then prompt for download of the SSH key
      showDownloadSshKey();
    } else {
      onSubmit(formValues);
    }
  };

  const wizardItems = [];
  wizardItems.push({
    header: Messages.createVMCluster.basicsTab.title(),
    groupName: GROUP_BASICS,
    testId: PanelTestIds.Basics,
    content: (
      <BasicsTabContent
        inputWizardPanelRef={panelRef}
        location={location}
        disableUIControls={disabled ? [
          BasicsFields.Subscription, BasicsFields.ResourceGroup, BasicsFields.Region,
        ] : undefined}
        subscription={subscriptionId}
        resourceGroupId={buildResourceGroupId(infraId)}
      />
    ),
  });
  wizardItems.push({
    header: Messages.createVMCluster.configurationTab.title(),
    groupName: GROUP_CONFIG,
    testId: PanelTestIds.Configuration,
    content: (
      <ConfigTabContent
        inputWizardPanelRef={panelRef}
        subscriptionId={subscriptionId}
        resourceGroupName={resourceGroupName}
        location={location}
        defaultInfraId={infraId}
        disableUIControls={disabled ? [
          ConfigFields.Infrastructure,
        ] : undefined}
      />
    ),
  });
  wizardItems.push({
    header: Messages.createVMCluster.networkingTab.title(),
    groupName: GROUP_NETWORK,
    testId: PanelTestIds.Network,
    content: (
      <NetworkTabContent
        inputWizardPanelRef={panelRef}
        subscriptionId={subscriptionId}
        location={location}
      />
    ),
    onUnselecting: closePanels,
  });
  wizardItems.push({
    header: Messages.createVMCluster.securityTab.title(),
    groupName: GROUP_SECURITY,
    testId: PanelTestIds.Security,
    content: (
      <SecurityTabContent />
    ),
  });
  wizardItems.push({
    header: Messages.tags.title(),
    groupName: GROUP_TAGS,
    testId: PanelTestIds.Tags,
    content: <TagsTabContent resourceOptions={getTagsResourceTypeOptions()} />,
  });

  const formValidator = (formValues: FormValues): FormFieldErrors[] | undefined => {
    const formFieldErrors: FormFieldErrors[] = [];

    const virtualNetwork: AzureVirtualNetworkSummary | undefined = getValue<SelectOption[]>(
      formValues,
      NetworkFields.Vnet,
      GROUP_NETWORK,
    )?.[0]?.data;

    const networkVirtualAppliance = getValue<string>(
      formValues,
      NetworkFields.NetworkVirtualAppliance,
      GROUP_NETWORK,
    );

    formFieldErrors.push({
      errors:
        networkVirtualAppliance
          ? validateIpAddressWithinRanges(networkVirtualAppliance, virtualNetwork?.addressSpaces ?? [])
          : undefined,
      field: NetworkFields.NetworkVirtualAppliance,
      group: GROUP_NETWORK,
    });

    return formFieldErrors;
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.createVMCluster.titles.short()}
    >
      <InputWizardPanel
        componentRef={setPanelRef}
        title={Messages.createVMCluster.titles.long()}
        testId={CreateWizardTestIds.ExaVmCluster}
        message={isCreateRoleMissing
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(requiredRole),
          } as PanelMessage
          : undefined}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFormChange={(_formValues: FormValues, fieldValue: any, field: string) => {
          if (field === BasicsFields.Subscription) {
            setSubscriptionId(fieldValue?.[0]?.id);
          } else if (field === BasicsFields.ResourceGroup) {
            setResourceGroupName(fieldValue?.[0]?.text);
          } else if (field === BasicsFields.Region) {
            setLocation(fieldValue?.[0]?.id);
          }
        }}
        items={wizardItems}
        itemsMessages={!disabled ? [{
          itemNdx: 0,
          type: MessageType.WARNING,
          message: Messages.createCommon.dependencyWarnings.basicsChangeWarning(Messages.labels.vmCluster()),
          hideOnFirstSelect: true,
        }] as InputWizardItemMessage[] : undefined}
        onRenderReview={onRenderReview}
        onUnselectingReview={() => {
          setCreateVmClusterPayload(undefined);
          setCreationPhase(undefined);
        }}
        onAsyncValidate={enableExaVmClusterPreflight ? onAsyncValidate : undefined}
        onSubmit={(
          formValues: FormValues,
          reject: (errorMessage: string, validationErrors?: AsyncValidationError[]) => void,
        ) => {
          submitCallbacks.current = { reject };
          onFormSubmit(formValues);
        }}
        onClose={() => {
          const analytics = customData?.analytics as NavigationAnalyticsData;
          if (analytics) {
            trackActionDiscard(analytics.actionName, analytics.pageId, analytics.panelId);
          }
          back();
        }}
        validator={formValidator}
      />
      {creationPhase === CreationPhase.CREATE_SUBMIT && (
        <DeploymentCreatePhase
          panelRef={panelRef}
          deployedService={DeployedService.EXADATA_VM_CLUSTER}
          location={location}
          subscriptionId={subscriptionId}
          resourceGroupName={resourceGroupName}
          resourceName={resourceNameRef.current}
          deploymentName={deploymentNameRef.current}
          submitPayload={createVmClusterPayload}
          submitReject={submitCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          asyncNotification={{
            methodKey: AsyncNotificationMethodKey.DEPLOYMENT_GET,
            delay: VMCLUSTER_DEPLOYMENT_CREATE_POLL_DELAY,
            pollingInterval: VMCLUSTER_DEPLOYMENT_CREATE_POLL_INTERVAL,
            polledResponseKey: AsyncNotificationPolledResponseKey.VMCLUSTER_DEPLOYMENT_CREATED_CHECK,
          }}
          onPostSubmit={() => {
            setCreateVmClusterPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
      {creationPhase === CreationPhase.ASYNC_VALIDATE && (
        <DeploymentAsyncValidationPhase
          location={location}
          asyncValidatePayload={createVmClusterPayload}
          asyncValidateResolve={asyncValidationCallbacks.current.resolve}
          asyncValidateReject={asyncValidationCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          onPostAsyncValidate={() => {
            setCreateVmClusterPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
      <DownloadSshKeyModal
        closeModal={hideDownloadSshKey}
        submitBtnText={Messages.actions.downloadKeyCreateResource()}
        cancelBtnText={Messages.actions.returnToCreateResource()}
        isOpen={displayDownloadSshKey}
        onCancel={() => {
          trackActionDiscard(ActionIds.DownloadSshKey, PageId.VMCLUSTER_CREATE);
          panelRef.allowResubmit();
        }}
        onSubmit={() => {
          onConfirmDownloadSshKey();
        }}
      />
    </BookmarkablePage>
  );
};
