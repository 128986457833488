import {
  AnchoredPanel,
  AnchoredPanelType,
  getResourceLifecycleStatus,
  LabelMetaItem,
  MetaItemGroupsLayout,
  MetaItemsGroupsDirection,
  NoValue,
  optimizedRetryOption,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import { Spinner, Stack } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { OciExaDbHomeLink } from "../../components/OciLinks/OciExaDbHomeLink";
import { metaItemLabelsGap, spinnerTestId, ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { getStatusInfo } from "../../helpers/resourceHelper";
import { AnalyticsDataPage } from "../../hooks/useAnalytics";
import { useQueryCall } from "../../hooks/useQueryCall";
import { AzureSubscriptionSummaryExt } from "../../hooks/useSubscriptions";
import { getAzureLocationName, getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export enum MetaItemSectionTestIds {
  ResourceGroup = "mis-resource-group",
  LifecycleStatus = "mis-status",
  Location = "mis-loaction",
  Subscription = "mis-subscription",
  SubscriptionId = "mis-subscription-id",
  OCIResource = "mis-oci-resource",
  DbVersion = "mis-db-version",
  DbHomeLocation = "mis-db-home-location",
  TimeCreated = "mis-time-created",
  TimeUpdated = "mis-time-updated",
}

export interface ExaVmClusterViewDbHomeProps extends OperationProps {
  dbHomeId: string | undefined;
  subscriptions: AzureSubscriptionSummaryExt[];
  analytics: AnalyticsDataPage;
}

export const ExaVmClusterViewDbHome = ({
  dbHomeId,
  subscriptions,
  location,
  onCancel,
  analytics,
}: ExaVmClusterViewDbHomeProps): JSX.Element => {
  const idComps = parseId(dbHomeId);
  const resGroup = decodeURIComponent(idComps?.resourceGroup || "");
  const resourceName = decodeURIComponent(idComps?.resourceName || "");
  const subscription = subscriptions?.find(item => item.id === idComps?.subscriptionId);

  const { response, loading } = useQueryCall({
    wait: !dbHomeId,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.getDbHome,
    options: {
      args: {
        subscriptionId: idComps?.subscriptionId,
        resourceGroupName: resGroup,
        dbHomeName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadExadbHome(),
      },
    },
  });

  const dbHome = response?.data;

  const essentialsMetaItemGroup: JSX.Element[] = [
    <Stack key="emig-01-left" tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem testId={MetaItemSectionTestIds.ResourceGroup} label={Messages.labels.resourceGroup()}>
        <AzureResourceGroupLink resourceId={dbHomeId as string} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.LifecycleStatus} label={Messages.labels.status()}>
        <Status
          iconPosition="right"
          label={stateT(dbHome?.status || "")}
          status={getResourceLifecycleStatus(dbHome?.status || "")}
          statusInfo={getStatusInfo(
            dbHome?.status || "",
            dbHome?.lastOperationStatus || "",
            dbHome?.lastOperationStatusDetails || "",
            dbHome?.lifecycleDetails,
          )}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Location} label={Messages.labels.location()}>
        {dbHome?.location ? getAzureLocationName(dbHome.location) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Subscription} label={Messages.labels.subscription()}>
        <AzureSubscriptionLink resourceId={dbHomeId as string} subscriptionName={subscription?.name} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.SubscriptionId} label={Messages.labels.subscriptionId()}>
        {idComps?.subscriptionId || <NoValue />}
      </LabelMetaItem>
    </Stack>,
    <Stack key="emig-02-right" tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem testId={MetaItemSectionTestIds.OCIResource} label={Messages.labels.ociResource()}>
        <OciExaDbHomeLink
          id={dbHome?.id || ""}
          location={location}
          subscriptions={subscriptions}
          analytics={analytics}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.DbVersion} label={Messages.labels.databaseVersion()}>
        {dbHome?.dbVersion || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.DbHomeLocation} label={Messages.labels.databaseHomeLocation()}>
        {dbHome?.dbHomeLocation || <NoValue />}
      </LabelMetaItem>
    </Stack>,
  ];

  return (
    <AnchoredPanel
      type={AnchoredPanelType.CUSTOM_WIDTH}
      customWidth={700}
      title={dbHome?.name || ""}
      isOpen
      onClose={onCancel}
    >
      {loading ? <Spinner data-test-id={spinnerTestId} label={Messages.common.loading()} />
        : (
          <MetaItemGroupsLayout direction={MetaItemsGroupsDirection.Vertical}>
            { essentialsMetaItemGroup }
          </MetaItemGroupsLayout>
        )}
    </AnchoredPanel>
  );
};

export const newExaVmClusterViewDbHome = (
  props: ExaVmClusterViewDbHomeProps,
): JSX.Element => (<ExaVmClusterViewDbHome {...props} />);
