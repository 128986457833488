import {
  CheckBox,
  FileUpload,
  FormattedString,
  InputFormCallout,
  InputFormCalloutProps,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { gcsSecurityPractices } from "../../constants/docConstants";
import { CalloutTestIds } from "../../constants/uiConstants";

export enum Fields {
  File = "file",
  HasPrivateInfo = "hasPrivateInfo"
}

export enum FieldsTestIds {
  FileUploader = "incident-file-uploader",
  HasPrivateInfoCheckbox = "incident-has-private-info-checkbox",
}

// eslint-disable-next-line max-len
export type SupportUploadFilePanelProps = Pick<InputFormCalloutProps, "title" | "targetId" | "onClose" | "onSubmit" | "componentRef">;

export const SupportUploadFilePanel = ({
  title,
  componentRef,
  targetId,
  onSubmit,
  onClose,
}: SupportUploadFilePanelProps): JSX.Element => (
  <InputFormCallout
    componentRef={componentRef}
    targetId={targetId}
    title={title}
    submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
    onSubmit={onSubmit}
    onClose={onClose}
    primaryButtonText={Messages.actions.upload()}
    testId={CalloutTestIds.SupportUploadFile}
  >
    <FileUpload
      label={Messages.labels.file()}
      fieldName={Fields.File}
      required
      testId={FieldsTestIds.FileUploader}
    />
    <CheckBox
      fieldName={Fields.HasPrivateInfo}
      label={Messages.labels.filePrivateInfo()}
      tooltip={
        FormattedString(
          { inputText: Messages.hints.tooltipFilePrivateInfo(gcsSecurityPractices) },
        ) as unknown as string
      }
      switchCheckBoxSide
      testId={FieldsTestIds.HasPrivateInfoCheckbox}
    />
  </InputFormCallout>
);
