const OPT_OUT_DOMAIN = "oracle.com";

let loadTrusteScriptPromise: Promise<void>;

export async function loadTrusteScript(): Promise<void> {
  // check if the trustarc script already exist
  const trustarcScript = document.getElementsByClassName("truste-script")[0];

  if (trustarcScript) {
    loadTrusteScriptPromise = Promise.resolve();
    return loadTrusteScriptPromise;
  }

  // we need to wait until truste script loaded in order to use window.truste in AnalyticsListener.ts
  loadTrusteScriptPromise = new Promise(resolve => {
    const scriptTag = document.createElement("script");
    scriptTag.async = true;
    scriptTag.type = "text/javascript";
    const scriptSrc = "//consent.truste.com/notice?domain=oracle.com&c=teconsent&text=true&gtm=1";
    scriptTag.src = scriptSrc;
    scriptTag.id = "truste-script";
    scriptTag.className = "truste-script";
    scriptTag.onload = () => {
      const iframe = document.getElementById("trustarcNoticeFrame");
      if (!iframe) {
        return resolve();
      }
      iframe.onload = () => {
        resolve();
      };
      iframe.onerror = () => {
        // eslint-disable-next-line no-console
        console.error("Error loading truste iframe. ");
        // still resolve the promise even when the script fails to load as we want to continue loading console
        resolve();
      };
      return null;
    };
    scriptTag.onerror = () => {
      // eslint-disable-next-line no-console
      console.error("Error loading truste script tag. ");
      // still resolve the promise even when the script fails to load as we want to continue loading console
      resolve();
    };
    document.body.appendChild(scriptTag);
  });

  return loadTrusteScriptPromise;
}

export async function userOptedOutInterval(): Promise<boolean> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(() => {
      // eslint-disable-next-line prefer-destructuring, @typescript-eslint/no-explicit-any
      const truste = (window as any).truste;
      if (!truste) {
        clearInterval(intervalId);
        resolve(false);
      }
      if (truste && Object.keys(truste).includes("cma")) {
        try {
          clearInterval(intervalId);
          resolve(truste.cma.callApi("getConsentDecision", OPT_OUT_DOMAIN).consentDecision === 1);
        } catch (e) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const msg = (e && (e as any).message) || "Could not get cookie consent decision using third party script";
          // eslint-disable-next-line no-console
          console.warn(msg);
        }
      }
    }, 100);
  });
}
