import {
  CheckBox,
  FieldSet,
  FormContext,
  FormState,
  FormValues,
  InfoBlockLayout,
  InfoBlockStatus,
  IntegerInput,
  Select,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import {
  mdsBackupPolicyDefaultRetentionInDays,
  mdsBackupPolicyMaxRetentionInDays,
  mdsBackupPolicyMinRetentionInDays,
  mysqlBackupSlots,
} from "../../helpers/resourceHelper";
import { utc } from "../../helpers/timeHelper";
import { mdsMinVersionSupportingPitrPolicy } from "../../helpers/validationHelper";

export const GROUP_MANAGEMENT = "management";

export enum Fields {
  Backups = "mdsDbSystemDeploymentDetails.backupPolicy.isEnabled",
  CrashRecovery = "mdsDbSystemDeploymentDetails.crashRecovery",
  DeleteProtected = "mdsDbSystemDeploymentDetails.deletionPolicy.isDeleteProtected",
  RequireFinalBackup = "mdsDbSystemDeploymentDetails.deletionPolicy.finalBackup",
  RetainAutomaticBackups = "mdsDbSystemDeploymentDetails.deletionPolicy.automaticBackupRetention",
  RetentionPeriod = "mdsDbSystemDeploymentDetails.backupPolicy.retentionInDays",
  WindowStartTime = "mdsDbSystemDeploymentDetails.backupPolicy.windowStartTime",
  PitrPolicy = "mdsDbSystemDeploymentDetails.backupPolicy.pitrPolicy",
}

export enum FieldTestIds {
  Backups = "backupEnabled",
  CrashRecovery = "crashRecovery",
  RetentionPeriod = "retentionInDays",
  WindowStartTime = "windowStartTime",
  PitrPolicy = "pitrPolicy",
  DeleteProtected = "isDeleteProtected",
  RetainAutomaticBackups = "automaticBackupRetention",
  RequireFinalBackup = "finalBackup",
}

export const ManagementTabContent = (): JSX.Element => {
  const [enableAutomaticBackupsKey, setEnableAutomaticBackupsKey] = React.useState<string>(uniqueGUID());
  const [crashRecoveryInfoKey, setCrashRecoveryInfoKey] = React.useState<string>(uniqueGUID());
  const [showBackupOptions, setShowBackupOptions] = React.useState(true);
  const [enableCrashRecovery, setEnableCrashRecovery] = React.useState(true);
  const form: FormState = React.useContext(FormContext);
  const [defaultWindowStartTime, setWindowStartTime] = React.useState<string>();
  const [defaultRetentionPeriod, setDefaultRetentionPeriod] = React.useState(mdsBackupPolicyDefaultRetentionInDays);
  const [defaultPitrPolicy, setDefaultPitrPolicy] = React.useState<boolean>(true);
  const info = {
    messageType: InfoBlockStatus.INFO,
    message: Messages.createMysql.managementTab.info.backupInfo(),
    infoLayout: InfoBlockLayout.Compact,
  };
  const crashRecoveryInfo = {
    messageType: InfoBlockStatus.INFO,
    message: Messages.createMysql.managementTab.info.crashRecoveryInfo(),
    infoLayout: InfoBlockLayout.Compact,
  };

  const onChangeShowBackUpOptions = (value: boolean): void => {
    setShowBackupOptions(value);
    if (value) {
      const formValues: FormValues = form.getGroupValues(GROUP_MANAGEMENT);
      setDefaultRetentionPeriod(formValues[Fields.RetentionPeriod] || defaultRetentionPeriod);
      setDefaultPitrPolicy(formValues[Fields.PitrPolicy]);
      setWindowStartTime(formValues[Fields.WindowStartTime]?.length > 0
        ? formValues[Fields.WindowStartTime]?.[0].id : undefined);
      // if crash recovery is not enabled
      if (!enableCrashRecovery) {
        setEnableCrashRecovery(value);
        setCrashRecoveryInfoKey(uniqueGUID());
      }
    }
  };

  const windowStartTimeOptions = mysqlBackupSlots.map(slot => ({
    id: slot,
    text: `${slot} ${utc}`,
  }));

  return (
    <>
      <FieldSet
        header={Messages.createMysql.managementTab.sectionTitles.backupHeader()}
      >
        <CheckBox
          testId={FieldTestIds.Backups}
          key={enableAutomaticBackupsKey}
          defaultValue={showBackupOptions}
          statusInfo={info}
          fieldName={Fields.Backups}
          label={Messages.labels.enableAutomaticBackups()}
          tooltip={Messages.hints.tooltipMySQLEnableAutomaticBackups(
            Messages.validation.versionValidationMysql(mdsMinVersionSupportingPitrPolicy),
          )}
          onChange={value => onChangeShowBackUpOptions(value)}
        />
        {showBackupOptions && (
          <>
            <IntegerInput
              testId={FieldTestIds.RetentionPeriod}
              fieldName={Fields.RetentionPeriod}
              label={Messages.labels.backupRetentionPeriod()}
              tooltip={Messages.hints.tooltipMySQLBackupRetentionPeriod()}
              defaultValue={defaultRetentionPeriod}
              min={mdsBackupPolicyMinRetentionInDays}
              max={mdsBackupPolicyMaxRetentionInDays}
              required
            />
            <Select
              required
              testId={FieldTestIds.WindowStartTime}
              defaultValue={defaultWindowStartTime ? [defaultWindowStartTime] : undefined}
              fieldName={Fields.WindowStartTime}
              label={Messages.labels.mySQLWindowStartTime()}
              tooltip={Messages.hints.tooltipMySQLWindowStartTime()}
              options={windowStartTimeOptions}
              hideSearchBox
            />
            <CheckBox
              testId={FieldTestIds.PitrPolicy}
              fieldName={Fields.PitrPolicy}
              label={Messages.labels.pitrPolicy()}
              tooltip={Messages.hints.enablePitrPolicy()}
              defaultValue={defaultPitrPolicy}
            />
          </>
        )}
      </FieldSet>
      <FieldSet
        header={Messages.createMysql.managementTab.sectionTitles.deletionPlanHeader()}
      >
        <CheckBox
          testId={FieldTestIds.DeleteProtected}
          fieldName={Fields.DeleteProtected}
          label={Messages.labels.mySQLEnableDeleteProtected()}
          tooltip={Messages.hints.tooltipMySQLDeleteProtected()}
        />
        <CheckBox
          testId={FieldTestIds.RetainAutomaticBackups}
          fieldName={Fields.RetainAutomaticBackups}
          label={Messages.labels.mySQLEnableRetainAutomaticBackups()}
          tooltip={Messages.hints.tooltipMySQLRetainAutomaticBackups()}
        />
        <CheckBox
          testId={FieldTestIds.RequireFinalBackup}
          fieldName={Fields.RequireFinalBackup}
          label={Messages.labels.mySQLEnableRequireFinalBackup()}
          tooltip={Messages.hints.tooltipMySQLRequireFinalBackup()}
        />
      </FieldSet>
      <FieldSet
        header={Messages.createMysql.managementTab.sectionTitles.crashRecoveryHeader()}
      >
        <CheckBox
          testId={FieldTestIds.CrashRecovery}
          key={crashRecoveryInfoKey}
          statusInfo={crashRecoveryInfo}
          defaultValue={enableCrashRecovery}
          onChange={(value: boolean) => {
            setEnableCrashRecovery(value);
            if (!value && showBackupOptions) {
              setShowBackupOptions(value);
              setEnableAutomaticBackupsKey(uniqueGUID());
            }
          }}
          fieldName={Fields.CrashRecovery}
          label={Messages.labels.mySQLEnableCrashRecovery()}
          tooltip={Messages.hints.tooltipMySQLCrashRecovery()}
        />
      </FieldSet>
    </>
  );
};
