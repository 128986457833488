import {
  AsyncFieldError,
  AsyncValidationError,
  BookmarkablePage,
  ConfigInputValues,
  FormFieldErrors,
  FormValues,
  getValue,
  InputWizardItemMessage,
  InputWizardPanel,
  InputWizardPanelComponent,
  MessageType,
  PanelMessage,
  RadioGroupOption,
  ReviewItem,
  ReviewSection,
  SelectOption,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { DeploymentAsyncValidationPhase } from "../../components/DeploymentPhases/DeploymentAsyncValidationPhase";
import { DeployedService, DeploymentCreatePhase } from "../../components/DeploymentPhases/DeploymentCreationPhase";
import { DownloadSshKeyModal } from "../../components/SshKeyPair/DownloadSshKeyModal";
import {
  defaultName,
  DerivedFields as NestedSshInputFields,
  SshPublicKeySource,
} from "../../components/SshKeyPair/SshKeyPairInput";
import { GROUP_TAGS } from "../../components/Tags/TagsCollection";
import { ConsoleContext } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  PageId,
  PageRegistrationConfig,
  VM_DATABASE_RESOURCE_TYPE_PATH,
  VMDB_CREATE_ROUTE,
} from "../../constants/pluginConstants";
import { CreateWizardTestIds } from "../../constants/uiConstants";
import {
  CreateMultiCloudVirtualNetworkDeploymentDetails,
  CreateNetworkLinkDeploymentDetails,
  GetMultiCloudVirtualNetworkDeploymentDetails,
  MultiCloudVirtualNetworkDeploymentDetails,
  MultiCloudVirtualNetworkSummary,
  RegisterExistingOciNetworkDeploymentDetails,
  VmdbDbSystemDeploymentDetails,
} from "../../gen/clients/mchub-azure-api-client";
import {
  AzureVirtualNetworkSummary,
  CreateVmdbDatabaseDetails,
  CreateVmdbDatabaseFromBackupDetails,
  CreateVmdbDbHomeDetails,
  CreateVmdbDbHomeFromBackupDetails,
  VmdbDbBackupConfig,
  VmdbDbBackupConfigAutoBackupWindowEnum,
} from "../../gen/clients/mchub-azure-api-client-vmdb";
import { saveBlob } from "../../helpers/fileHelper";
import { parseId } from "../../helpers/idHelper";
import {
  DeploymentKinds,
  FreeFormTags,
  FutureMcvcn,
  FutureMcvcnExisting,
  FutureMcvcnNew,
  getAddressRange,
  getResourceTypeMessage,
  getTagsPerResourceTypeMap,
  ResourceType,
  TagsInfoType,
  VmdbCreateFlowType,
} from "../../helpers/resourceHelper";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { getTimestamp, utc } from "../../helpers/timeHelper";
import { validateIpAddressWithinRanges } from "../../helpers/validationHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useRoles } from "../../hooks/useRoles";
import { useSidePanel } from "../../hooks/useSidePanel";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_DEPLOYMENT_CREATE_POLL_DELAY,
  VMDB_DEPLOYMENT_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { ReviewTabContent } from "../CreateCommon/ReviewTabContent";
import { Fields as TagsFields, TagsTabContent } from "../CreateCommon/TagsTabContent";
import { BasicsTabContent, Fields as BasicsFields, GROUP_BASICS } from "./BasicsTabContent";
import {
  BackupInfo,
  ConfigTabContent,
  Fields as ConfigFields,
  getDefaultDbHomeName,
  getDefaultSystemName,
  GROUP_CONFIG,
} from "./ConfigTabContent";
import { Fields as ManagementFields, GROUP_MANAGEMENT, ManagementTabContent } from "./ManagementTabContent";
import { Fields as NetworkFields, GROUP_NETWORK, NetworkTabContent } from "./NetworkTabContent";
import { Fields as SecurityFields, GROUP_SECURITY, SecurityTabContent } from "./SecurityTabContent";

const registrationIds = PageRegistrationConfig[PageId.VMDB_CREATE].map(config => config.key);

export const onProcessAsyncValidationErrors = (
  asyncErrors: AsyncValidationError[],
): AsyncValidationError[] => asyncErrors.map(asyncError => {
  if (typeof asyncError === "string") {
    return asyncError;
  }
  const asyncFieldError = asyncError as AsyncFieldError;
  let fieldLabel;
  if (asyncFieldError.field.startsWith("multiCloudVirtualNetworkDetails.")) {
    if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.name") {
      fieldLabel = Messages.labels.name();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.azureAttachedNetworkIds") {
      fieldLabel = Messages.labels.virtualNetwork();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociVcnCidrBlocks") {
      fieldLabel = Messages.labels.cidrBlocks();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociVcnOcid") {
      fieldLabel = Messages.labels.vcnOcid();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociSubnetOcids") {
      fieldLabel = Messages.labels.subnetOcids();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.customerNvaIpAddress") {
      fieldLabel = Messages.labels.networkVirtualAppliance();
    } else {
      return asyncFieldError;
    }

    asyncFieldError.field = NetworkFields.Mcvcn;
    asyncFieldError.error = `<strong>(${Messages.common.new()} | ${fieldLabel})</strong> ${asyncFieldError.error}`;
  } else if (asyncFieldError.field === "vmdbDbSystemDeploymentDetails.cpuCoreCount") {
    fieldLabel = Messages.labels.ocpuCountPerVM();
    asyncFieldError.field = ConfigFields.SystemModel;
    asyncFieldError.error = `<strong>(${fieldLabel})</strong> ${asyncFieldError.error}`;
  } else if (asyncFieldError.field === ConfigFields.SystemModel) {
    fieldLabel = Messages.labels.vmShape();
    asyncFieldError.field = ConfigFields.SystemModel;
    asyncFieldError.error = `<strong>(${fieldLabel})</strong> ${asyncFieldError.error}`;
  } else if (asyncFieldError.field === "vmdbDbSystemDeploymentDetails.dbHome.database.backupTDEPassword") {
    fieldLabel = Messages.labels.tdePassword();
    asyncFieldError.field = SecurityFields.TdePassword;
  }
  return asyncFieldError;
});

export enum ActionIds {
  DownloadSshKey = "download-ssh-key",
}

enum CreationPhase {
  ASYNC_VALIDATE = "ASYNC_VALIDATE",
  CREATE_SUBMIT = "CREATE_SUBMIT",
}

export enum PanelTestIds {
  Basics = "basic",
  Configuration = "configuration",
  Network = "network",
  Security = "security",
  Management = "management",
  Tags = "tags",
}

export interface VmdbCreatePageProps {
  createFlowType?: VmdbCreateFlowType;
}

export const VmDbCreatePage = (
  { createFlowType: createType = VmdbCreateFlowType.VMDB_DATABASE }: VmdbCreatePageProps,
): JSX.Element => {
  const {
    enableMcvcn, enableVmdbMcvcnCreate, enableVmdbPreflight,
    enableVmdbStorageManagementType, enableVmdbStoragePerformanceMode,
  } = useFeatures();
  const [createFlowType, setCreateFlowType] = React.useState<VmdbCreateFlowType>(createType);

  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const isCreateRoleMissing = !isActionAllowed(RoleBasedAction.CREATE_VMDB_CDB);
  const requiredRole = actionRequiredRoles(RoleBasedAction.CREATE_VMDB_CDB)[0]?.displayName;

  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { back, navigateToSelf, customData } = useNavigation(ConsoleContext);
  const { preferredLocationPerSubscription, preferredSubscription } = React.useContext<Settings>(SettingsContext);

  const preferredLocation = preferredLocationPerSubscription[preferredSubscription] || "";

  const [subscriptionId, setSubscriptionId] = React.useState<string>("");
  const [resourceGroupName, setResourceGroupName] = React.useState<string>("");
  const [instanceName, setInstanceName] = React.useState<string>("");
  const [backup, setBackup] = React.useState<BackupInfo>();
  const [location, setLocation] = React.useState<string>("");

  const [configDisabledFields, setConfigDisabledFields] = React.useState<ConfigFields[] | undefined>(undefined);

  const resourceNameRef = React.useRef<string>("");
  const deploymentNameRef = React.useRef<string>("");// to hold the name to be used in failure message

  const [
    displayDownloadSshKey,
    { setFalse: hideDownloadSshKey, setTrue: showDownloadSshKey },
  ] = useBoolean(false);

  const [creationPhase, setCreationPhase] = React.useState<CreationPhase | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [createVmdbPayload, setCreateVmdbPayload] = React.useState<any>();
  const asyncValidationCallbacks = React.useRef<{
    resolve:(errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ resolve: () => { }, reject: () => { } });

  const submitCallbacks = React.useRef<{
    reject:(errorMessage: string, validationErrors?: AsyncValidationError[]) => void
    // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ reject: () => { } });

  React.useEffect(() => {
    if (customData?.location || customData?.backup) {
      const configFieldsToDisable = [];
      if (customData?.location) {
        setLocation(customData.location);
      }
      if (customData?.backup) {
        configFieldsToDisable.push(ConfigFields.BackupName);
        setBackup(customData.backup);
        setCreateFlowType(VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP);
      }
      if (configFieldsToDisable.length > 0) {
        setConfigDisabledFields(configFieldsToDisable);
      }
    } else {
      setLocation(preferredLocation);
      setSubscriptionId(preferredSubscription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredLocation, preferredSubscription]);

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(VMDB_CREATE_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [panelRef, setPanelRef] = React.useState<InputWizardPanelComponent>({} as InputWizardPanelComponent);

  const onClose = (): void => back();

  const { closePanels } = useSidePanel();

  const onAsyncValidate = (
    formValues: FormValues,
    resolve: (errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
  ): void => {
    setCreateVmdbPayload(buildCreateVmdbPayload(formValues));
    asyncValidationCallbacks.current = { resolve, reject };
    setCreationPhase(CreationPhase.ASYNC_VALIDATE);
  };

  const onSubmit = (formValues: FormValues): void => {
    setCreateVmdbPayload(buildCreateVmdbPayload(formValues));
    setCreationPhase(CreationPhase.CREATE_SUBMIT);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildCreateVmdbPayload = (formValues: FormValues): any => {
    const tags = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS);
    const tagsMap = getTagsPerResourceTypeMap(tags);

    const name = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS) as string;
    resourceNameRef.current = name;

    const numNodes = getValue<RadioGroupOption>(formValues, ConfigFields.NodeCount, GROUP_CONFIG);
    const nodeCount = numNodes ? parseInt(numNodes.text, 10) : 0;

    const systemModelConfig = getValue<ConfigInputValues>(
      formValues,
      ConfigFields.SystemModel,
      GROUP_CONFIG,
    )?.value;
    const cpuCores = systemModelConfig.cpuCoreCount;

    let cpuCoreCount = cpuCores ? parseInt(cpuCores, 10) : 0;
    cpuCoreCount *= nodeCount;

    const sshPublicKey = getValue<Record<NestedSshInputFields, string>>(
      formValues,
      SecurityFields.SshKeyPair,
      GROUP_SECURITY,
    )?.[NestedSshInputFields.PublicKey];

    deploymentNameRef.current = `${VM_DATABASE_RESOURCE_TYPE_PATH}-${getTimestamp()}`;

    const storageVolumePerformanceMode = getValue<SelectOption[]>(
      formValues,
      ConfigFields.StoragePerformanceMode,
      GROUP_CONFIG,
    )?.[0].id;

    const initialDataStorage = getValue<SelectOption[]>(formValues, ConfigFields.StorageSize, GROUP_CONFIG)?.[0]?.text;
    const initialDataStorageSizeInGBs = initialDataStorage ? parseInt(initialDataStorage, 10) : undefined;

    const payload = {
      subscriptionId: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].id,
      resourceGroupName: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      createDeploymentDetails: {
        name: deploymentNameRef.current,
        parameters: {
          kind: DeploymentKinds.Vmdb,
          networkLinkDetails: !(enableMcvcn && enableVmdbMcvcnCreate) ? buildNetworkLink(formValues) : undefined,
          multiCloudVirtualNetworkDetails: enableMcvcn && enableVmdbMcvcnCreate ? buildMcvcn(formValues) : undefined,
          vmdbDbSystemDeploymentDetails: {
            kind: createFlowType === VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP
              ? DeploymentKinds.Restore
              : DeploymentKinds.Create,
            name: getValue<string>(formValues, ConfigFields.SystemName, GROUP_CONFIG) || getDefaultSystemName(name),
            freeformTags: tagsMap[ResourceType.DB_SYSTEM],
            hostname: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK),
            nodeCount,
            shape: systemModelConfig.shape.name,
            cpuCoreCount,
            ...(enableVmdbStorageManagementType ? {
              dbSystemOptions: {
                storageManagement:
                  getValue<SelectOption[]>(formValues, ConfigFields.StorageType, GROUP_CONFIG)?.[0].id,
              },
            } : undefined),
            initialDataStorageSizeInGBs,
            ...(enableVmdbStoragePerformanceMode ? { storageVolumePerformanceMode } : undefined),
            sshPublicKeys: [sshPublicKey],
            licenseModel: getValue<SelectOption[]>(formValues, ConfigFields.LicenseType, GROUP_CONFIG)?.[0].id,
            databaseEdition: getValue<SelectOption[]>(formValues, ConfigFields.SoftwareEdition, GROUP_CONFIG)?.[0].id,
            dbHome: createFlowType === VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP
              ? buildVmDbHomeFromBackupDetails(formValues, tagsMap[ResourceType.DATABASE])
              : buildVmDbHomeDetails(formValues, tagsMap[ResourceType.DATABASE]),
          } as VmdbDbSystemDeploymentDetails,
        },
      },
    };

    return payload;
  };

  const onConfirmDownloadSshKey = (): void => {
    const privateKey = getValue<Record<NestedSshInputFields, string>>(
      panelRef.getFormValues?.() ?? {},
      SecurityFields.SshKeyPair,
      GROUP_SECURITY,
    )?.[NestedSshInputFields.PrivateKey];

    if (privateKey) {
      saveBlob(privateKey, `${defaultName()}.key`);
    }

    onSubmit(panelRef.getFormValues() ?? {});
  };

  const onFormSubmit = (formValues: FormValues): void => {
    const sshKeySourceFieldName = SecurityFields.SshKeyPair + NestedSshInputFields.Source;
    const sshKeySource = getValue<SelectOption[]>(formValues, sshKeySourceFieldName, GROUP_SECURITY)?.[0].id;

    // if the ssh key is set to generate
    if (SshPublicKeySource.Generate === sshKeySource) {
      /**
       * Normally we should not track action click in onSubmit but this is an exception
       * since the dialog is opened automatically by the wizard and not as a result of
       * a user action.
       */
      trackActionClick(ActionIds.DownloadSshKey, PageId.VMDB_CREATE);
      // then prompt for download of the SSH key
      showDownloadSshKey();
    } else {
      onSubmit(formValues);
    }
  };

  const buildMcvcn = (formValues: FormValues): MultiCloudVirtualNetworkDeploymentDetails => {
    // eslint-disable-next-line max-len
    const data: FutureMcvcnNew | FutureMcvcnExisting | MultiCloudVirtualNetworkSummary | undefined = getValue<SelectOption[]>(
      formValues,
      NetworkFields.Mcvcn,
      GROUP_NETWORK,
    )?.[0]?.data;

    // Only exists if the user has selected an existing MCVCN
    const { id } = data as MultiCloudVirtualNetworkSummary;
    // Exists if the user is creating a new MCVCN
    const { name, azureAttachedNetworkId, customerNvaIpAddress } = data as FutureMcvcn;
    // Exists if the user is creating a new MCVCN using an existing OCI VCN
    const { ociSubnetOcids, ociVcnOcid } = data as FutureMcvcnExisting;
    // Exists if the user is creating a new MCVCN using a new OCI VCN
    const { ociVcnCidrBlocks } = data as FutureMcvcnNew;

    let mcvcnDetails: MultiCloudVirtualNetworkDeploymentDetails;
    const azureAttachedNetworkIds = [azureAttachedNetworkId];

    if (id) {
      mcvcnDetails = {
        kind: DeploymentKinds.Get,
        id,
      } as GetMultiCloudVirtualNetworkDeploymentDetails;
    } else if (ociVcnCidrBlocks) {
      mcvcnDetails = {
        kind: DeploymentKinds.Create,
        azureAttachedNetworkIds,
        name,
        ociVcnCidrBlocks,
        customerNvaIpAddress,
      } as CreateMultiCloudVirtualNetworkDeploymentDetails;
    } else if (ociVcnOcid && ociSubnetOcids) {
      mcvcnDetails = {
        kind: DeploymentKinds.Register,
        azureAttachedNetworkIds,
        name,
        ociVcnOcid,
        ociSubnetOcids,
        customerNvaIpAddress,
      } as RegisterExistingOciNetworkDeploymentDetails;
    } else throw new Error("Invalid parameters to create MCVCN");

    return mcvcnDetails;
  };

  const buildNetworkLink = (formValues: FormValues): CreateNetworkLinkDeploymentDetails => {
    const name = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS);
    const cidrRange = getValue<string>(formValues, NetworkFields.Cidrs, GROUP_NETWORK);
    const cidr = cidrRange ? [cidrRange] : [];
    const vnet = getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].id;
    const azureAttachedNetworkIds = vnet ? [vnet] : [];

    const networkLinkDetails: CreateNetworkLinkDeploymentDetails = {
      kind: DeploymentKinds.Create,
      name: `${name}-networkLink`,
      ociNetworkCidrs: cidr,
      azureAttachedNetworkIds,
      customerNvaIpAddress: getValue<string>(formValues, NetworkFields.NetworkVirtualAppliance, GROUP_NETWORK),
    };
    return networkLinkDetails;
  };

  // eslint-disable-next-line max-len
  const buildVmDbHomeFromBackupDetails = (formValues: FormValues, tags: FreeFormTags): CreateVmdbDbHomeFromBackupDetails => {
    const vmDbHomeDetails: CreateVmdbDbHomeFromBackupDetails = {
      name: getValue<string>(formValues, ConfigFields.DbHomeName, GROUP_CONFIG)
        || getDefaultDbHomeName(instanceName),
      database: buildDatabaseFromBackupDetails(formValues, tags),
    };
    return vmDbHomeDetails;
  };

  // eslint-disable-next-line max-len
  const buildDatabaseFromBackupDetails = (formValues: FormValues, tags: FreeFormTags): CreateVmdbDatabaseFromBackupDetails => {
    const databaseDetails: CreateVmdbDatabaseFromBackupDetails = {
      freeformTags: tags,
      backupId: backup?.id || "",
      adminPassword: getValue<string>(formValues, SecurityFields.Password, GROUP_SECURITY) as string,
      dbName: getValue<string>(formValues, ConfigFields.DatabaseName, GROUP_CONFIG),
      backupTDEPassword: getValue<string>(formValues, SecurityFields.TdePassword, GROUP_SECURITY),
    };
    return databaseDetails;
  };

  const buildVmDbHomeDetails = (formValues: FormValues, tags: FreeFormTags): CreateVmdbDbHomeDetails => {
    const vmDbHomeDetails: CreateVmdbDbHomeDetails = {
      name: getValue<string>(formValues, ConfigFields.DbHomeName, GROUP_CONFIG) || getDefaultDbHomeName(instanceName),
      dbVersion: getValue<SelectOption[]>(formValues, ConfigFields.DbHomeVersion, GROUP_CONFIG)?.[0].id as string,
      database: buildDatabaseDetails(formValues, tags),
    };
    return vmDbHomeDetails;
  };

  const buildDatabaseDetails = (formValues: FormValues, tags: FreeFormTags): CreateVmdbDatabaseDetails => {
    const pdbName = getValue<string>(formValues, ConfigFields.PdbName, GROUP_CONFIG);

    const databaseDetails: CreateVmdbDatabaseDetails = {
      freeformTags: tags,
      adminPassword: getValue<string>(formValues, SecurityFields.Password, GROUP_SECURITY) as string,
      dbName: getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS) as string,
      pdbName,
      characterSet: getValue<SelectOption[]>(formValues, ConfigFields.CharacterSet, GROUP_CONFIG)?.[0].id,
      ncharacterSet: getValue<SelectOption[]>(formValues, ConfigFields.NationalCharacterSet, GROUP_CONFIG)?.[0].id,
      tdeWalletPassword: getValue<boolean>(formValues, SecurityFields.UseDifferentTDEPassword, GROUP_SECURITY)
        ? getValue<string>(formValues, SecurityFields.TdePassword, GROUP_SECURITY)
        : undefined,
      dbBackupConfig: buildBackupDetails(formValues),
    };
    return databaseDetails;
  };

  const buildBackupDetails = (formValues: FormValues): VmdbDbBackupConfig => {
    const autoBackupEnabled = getValue<boolean>(formValues, ManagementFields.Backups, GROUP_MANAGEMENT);
    const backupWindow = getValue<SelectOption[]>(
      formValues,
      ManagementFields.Scheduling,
      GROUP_MANAGEMENT,
    )?.[0].id as VmdbDbBackupConfigAutoBackupWindowEnum | undefined;
    const retentionPeriod = getValue<SelectOption[]>(
      formValues,
      ManagementFields.RetentionPeriod,
      GROUP_MANAGEMENT,
    )?.[0].id;
    const recoveryWindow = retentionPeriod ? parseInt(retentionPeriod, 10) : undefined;

    const backupDetails: VmdbDbBackupConfig = {
      autoBackupEnabled,
      autoBackupWindow: autoBackupEnabled ? backupWindow : undefined,
      recoveryWindowInDays: autoBackupEnabled ? recoveryWindow : undefined,
    };
    return backupDetails;
  };

  const buildBasicInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewBasicInfo: ReviewItem[] = [
      {
        label: Messages.labels.subscription(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.resourceGroup(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.vmDatabaseName(),
        value: getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS),
      },
      {
        label: Messages.labels.region(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Region, GROUP_BASICS)?.[0].text,
      },
    ];
    return reviewBasicInfo;
  };
  const buildConfigurationInfo = (formValues: FormValues): ReviewItem[] => {
    const shapeConfig = getValue<ConfigInputValues>(formValues, ConfigFields.SystemModel, GROUP_CONFIG);
    const shapeTitle = shapeConfig?.title;
    const shapeSummary = shapeConfig?.summary;

    const storageVolumePerformanceModeText = getValue<SelectOption[]>(
      formValues,
      ConfigFields.StoragePerformanceMode,
      GROUP_CONFIG,
    )?.[0].text;

    const reviewConfigInfo: ReviewItem[] = [
      {
        label: Messages.labels.databaseSystemName(),
        value: getValue<string>(formValues, ConfigFields.SystemName, GROUP_CONFIG)
          || getDefaultSystemName(instanceName),
      },
      {
        label: Messages.labels.shapeSelection(),
        value: `${shapeTitle} (${shapeSummary})`,
      },
      {
        label: Messages.labels.nodeCount(),
        value: getValue<RadioGroupOption>(formValues, ConfigFields.NodeCount, GROUP_CONFIG)?.text,
      },
      ...(enableVmdbStorageManagementType
        ? [{
          label: Messages.labels.storageType(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.StorageType, GROUP_CONFIG)?.[0].text,
        }] : []),
      ...(enableVmdbStoragePerformanceMode
        ? [{
          label: Messages.labels.storageVolumePerformance(),
          value: storageVolumePerformanceModeText,
        }] : []),
      {
        label: Messages.labels.availableDataStorageGB(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.StorageSize, GROUP_CONFIG)?.[0]?.text,
      },
      {
        label: Messages.labels.softwareEdition(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.SoftwareEdition, GROUP_CONFIG)?.[0].text,
      },
      {
        label: Messages.labels.licenseType(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.LicenseType, GROUP_CONFIG)?.[0].text,
      },
    ];

    if (createFlowType === VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP) {
      reviewConfigInfo.push(
        {
          label: Messages.labels.databaseHomeName(),
          value: getValue<string>(formValues, ConfigFields.DbHomeName, GROUP_CONFIG)
            || getDefaultDbHomeName(instanceName),
        },
        {
          label: Messages.labels.databaseBackupName(),
          value: getValue<string>(formValues, ConfigFields.BackupName, GROUP_CONFIG),
        },
        {
          label: Messages.labels.databaseName(),
          value: getValue<string>(formValues, ConfigFields.DatabaseName, GROUP_CONFIG),
        },
      );
    } else {
      reviewConfigInfo.push(
        {
          label: Messages.labels.databaseHomeName(),
          value: getValue<string>(formValues, ConfigFields.DbHomeName, GROUP_CONFIG)
            || getDefaultDbHomeName(instanceName),
        },
        {
          label: Messages.labels.databaseHomeVersion(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.DbHomeVersion, GROUP_CONFIG)?.[0].text,
        },
        {
          label: Messages.labels.databaseName(),
          value: getValue<string>(formValues, ConfigFields.DatabaseName, GROUP_CONFIG),
        },
        {
          label: Messages.labels.pdbName(),
          value: getValue<string>(formValues, ConfigFields.PdbName, GROUP_CONFIG),
        },
        {
          label: Messages.labels.characterSet(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.CharacterSet, GROUP_CONFIG)?.[0].text,
        },
        {
          label: Messages.labels.nationalCharacterSet(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.NationalCharacterSet, GROUP_CONFIG)?.[0].text,
        },
      );
    }

    return reviewConfigInfo;
  };

  const buildNetworkingInfo = (formValues: FormValues): ReviewItem[] => {
    const renderCidrValue = (values: string[]): JSX.Element => {
      const cidrs = values.map(range => (
        <Stack horizontal key={range}>
          <span style={{ paddingBottom: 1 }}>
            {range}
          </span>
        </Stack>
      ));
      return (
        <div>
          {cidrs}
        </div>
      );
    };

    const renderOcidValue = (values: string[]): JSX.Element => {
      const ocids = values.map(ocid => (
        <Stack horizontal key={ocid}>
          <span style={{ paddingBottom: 1 }}>
            {ocid}
          </span>
        </Stack>
      ));
      return (
        <div>
          {ocids}
        </div>
      );
    };

    const reviewNetworkingInfo = [];

    if (!(enableMcvcn && enableVmdbMcvcnCreate)) {
      const cidrRange = getValue<string>(formValues, NetworkFields.Cidrs, GROUP_NETWORK);
      const cidrAddresses = getAddressRange(cidrRange);
      const cidr = [`${cidrRange} [${cidrAddresses}]`];
      const networkVirtualAppliance = getValue<string>(
        formValues,
        NetworkFields.NetworkVirtualAppliance,
        GROUP_NETWORK,
      );

      reviewNetworkingInfo.push(
        {
          label: Messages.labels.hostName(),
          value: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK),
        },
        {
          label: Messages.labels.virtualNetwork(),
          value: getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].text,
        },
        ...(networkVirtualAppliance
          ? [{ label: Messages.labels.networkVirtualAppliance(), value: networkVirtualAppliance }]
          : []),
        {
          label: Messages.labels.ociCidrs(),
          value: renderCidrValue(cidr),
        },
      );
    }

    if (enableMcvcn && enableVmdbMcvcnCreate) {
      // eslint-disable-next-line max-len
      const mcvcn: MultiCloudVirtualNetworkSummary | FutureMcvcnExisting | FutureMcvcnNew | undefined = getValue<SelectOption[]>(
        formValues,
        NetworkFields.Mcvcn,
        GROUP_NETWORK,
      )?.[0].data;

      if (!mcvcn) return [];

      // ID only exists if the user has selected an existing MCVCN
      const createNewMcvcn = !(mcvcn as MultiCloudVirtualNetworkSummary).id;

      // Exists if the user is creating a new MCVCN
      const { name, customerNvaIpAddress, azureAttachedNetworkId } = mcvcn as FutureMcvcn;
      const virtualNetwork = decodeURIComponent(parseId(azureAttachedNetworkId)?.resourceName || "");

      // Exists if the user is creating a new MCVCN using a new OCI VCN
      const { ociVcnCidrBlocks } = mcvcn as FutureMcvcnNew;
      // Exists if the user is creating a new MCVCN using an existing OCI VCN
      const { ociSubnetOcids, ociVcnOcid } = mcvcn as FutureMcvcnExisting;

      reviewNetworkingInfo.push(
        {
          label: Messages.labels.hostName(),
          value: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK),
        },
        {
          label: Messages.labels.virtualCloudNetwork(),
          value: name,
        },
      );

      if (createNewMcvcn) {
        reviewNetworkingInfo.push(
          ...(virtualNetwork
            ? [{ label: Messages.labels.virtualNetwork(), value: virtualNetwork }]
            : []),
          ...(customerNvaIpAddress
            ? [{ label: Messages.labels.networkVirtualAppliance(), value: customerNvaIpAddress }]
            : []),
          ...(ociVcnOcid
            ? [{ label: Messages.labels.vcnOcid(), value: ociVcnOcid }]
            : []),
          ...(ociSubnetOcids
            ? [{ label: Messages.labels.subnetOcids(), value: renderOcidValue(ociSubnetOcids) }]
            : []),
          ...(ociVcnCidrBlocks
            ? [{
              label: Messages.labels.cidrBlocks(),
              value: renderCidrValue(ociVcnCidrBlocks.map(cidr => `${cidr} [${getAddressRange(cidr)}]`)),
            }]
            : []),
        );
      }
    }

    return reviewNetworkingInfo;
  };

  const buildSecurityInfo = (formValues: FormValues): ReviewItem[] => {
    const sshKeySourceFieldName = SecurityFields.SshKeyPair + NestedSshInputFields.Source;
    const sshKeySource = getValue<SelectOption[]>(formValues, sshKeySourceFieldName, GROUP_SECURITY)?.[0].id;
    const useDifferentTdePassword = getValue<boolean>(
      formValues,
      SecurityFields.UseDifferentTDEPassword,
      GROUP_SECURITY,
    );

    const sshKeyValue = sshKeySource !== undefined ? sshKeySource === SshPublicKeySource.Upload
      ? Messages.common.uploaded() : Messages.common.generated() : Messages.common.no();
    const reviewSecurityInfo: ReviewItem[] = [
      {
        label: Messages.labels.sshPublicKey(),
        value: sshKeyValue,
      },
      {
        label: Messages.labels.username(),
        value: getValue<string>(formValues, SecurityFields.Username, GROUP_SECURITY),
      },
    ];
    if (createFlowType !== VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP) {
      reviewSecurityInfo.push(
        {
          label: Messages.labels.useDifferentTDEPassword(),
          value: useDifferentTdePassword ? Messages.common.yes() : Messages.common.no(),
        },
      );
    }
    return reviewSecurityInfo;
  };
  const buildManagementInfo = (formValues: FormValues): ReviewItem[] => {
    const autoBackupEnabled = getValue<boolean>(formValues, ManagementFields.Backups, GROUP_MANAGEMENT) as boolean;
    const reviewManagementInfo: ReviewItem[] = [
      {
        label: Messages.labels.automaticBackups(),
        value: autoBackupEnabled ? Messages.common.enabled() : Messages.common.disabled(),
      },
    ];
    if (autoBackupEnabled) {
      reviewManagementInfo.push(
        {
          label: Messages.labels.backupRetentionPeriod(),
          value: getValue<SelectOption[]>(formValues, ManagementFields.RetentionPeriod, GROUP_MANAGEMENT)?.[0].text,
        },
        {
          label: `${Messages.labels.backupScheduling()} (${utc})`,
          value: getValue<SelectOption[]>(formValues, ManagementFields.Scheduling, GROUP_MANAGEMENT)?.[0].text,
        },
      );
    }
    return reviewManagementInfo;
  };

  const buildTagsInfo = (formValues: FormValues): ReviewItem[] => {
    const tagsInfo = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS) ?? [];
    const tags: { label: string; value: string; }[] = [];

    tagsInfo.forEach(element => (
      element.resources?.forEach(resource => (
        element.name !== undefined && tags.push({
          label: element.name,
          value: `${element.value !== undefined ? element.value : ""}(${resource.text})`,
        })
      ))));
    if (tags.length === 0) tags.push({ label: "None", value: "" });
    tags.sort((a, b) => ((a.value.split("(")[1].split(")")[0] > b.value.split("(")[1].split(")")[0]) ? 1 : -1));
    return tags;
  };

  const onRenderReview = (formValues: FormValues): JSX.Element => {
    const reviewSections = [
      { title: Messages.createExaDb.basicsTab.title(), items: buildBasicInfo(formValues) },
      { title: Messages.createExaDb.configurationTab.title(), items: buildConfigurationInfo(formValues) },
      { title: Messages.createExaDb.networkingTab.title(), items: buildNetworkingInfo(formValues) },
      { title: Messages.createExaDb.securityTab.title(), items: buildSecurityInfo(formValues) },
    ] as ReviewSection[];
    if (createFlowType !== VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP) {
      reviewSections.push(
        { title: Messages.createExaDb.managementTab.title(), items: buildManagementInfo(formValues) },
      );
    }
    reviewSections.push({ title: Messages.tags.title(), items: buildTagsInfo(formValues) });
    return <ReviewTabContent reviewSections={reviewSections} />;
  };

  const getTagsResourceTypeOptions = (): SelectOption[] => {
    const resourceOptions: SelectOption[] = [
      { id: ResourceType.DATABASE, text: getResourceTypeMessage(ResourceType.DATABASE) },
      { id: ResourceType.DB_SYSTEM, text: getResourceTypeMessage(ResourceType.DB_SYSTEM) },
    ];
    return resourceOptions;
  };

  const wizardItems = [
    {
      header: Messages.createVmDb.basicsTab.title(),
      groupName: GROUP_BASICS,
      testId: PanelTestIds.Basics,
      content: (
        <BasicsTabContent
          inputWizardPanelRef={panelRef}
          location={location}
          subscription={subscriptionId}
          resourceGroupId={undefined}
        />
      ),
    },
    {
      header: Messages.createVmDb.configurationTab.title(),
      groupName: GROUP_CONFIG,
      testId: PanelTestIds.Configuration,
      content: (
        <ConfigTabContent
          createFlowType={createFlowType}
          location={location}
          inputWizardPanelRef={panelRef}
          subscriptionId={subscriptionId}
          instanceName={instanceName}
          backup={backup}
          disableUIControls={configDisabledFields}
        />
      ),
    },
    {
      header: Messages.createVmDb.networkingTab.title(),
      groupName: GROUP_NETWORK,
      testId: PanelTestIds.Network,
      content: (
        <NetworkTabContent
          inputWizardPanelRef={panelRef}
          subscriptionId={subscriptionId}
          location={location}
        />
      ),
      onUnselecting: closePanels,
    },
    {
      header: Messages.createVmDb.securityTab.title(),
      groupName: GROUP_SECURITY,
      testId: PanelTestIds.Security,
      content: <SecurityTabContent createFlowType={createFlowType} />,
    },
  ];
  if (createFlowType !== VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP) {
    wizardItems.push(
      {
        header: Messages.createVmDb.managementTab.title(),
        groupName: GROUP_MANAGEMENT,
        testId: PanelTestIds.Management,
        content: <ManagementTabContent />,
      },
    );
  }
  wizardItems.push(
    {
      header: Messages.tags.title(),
      groupName: GROUP_TAGS,
      testId: PanelTestIds.Tags,
      content: <TagsTabContent resourceOptions={getTagsResourceTypeOptions()} />,
    },
  );

  const formValidator = (formValues: FormValues): FormFieldErrors[] | undefined => {
    const formFieldErrors: FormFieldErrors[] = [];

    const virtualNetwork = getValue<SelectOption[]>(
      formValues,
      NetworkFields.Vnet,
      GROUP_NETWORK,
    )?.[0]?.data as AzureVirtualNetworkSummary | undefined;

    const networkVirtualAppliance = getValue<string>(formValues, NetworkFields.NetworkVirtualAppliance, GROUP_NETWORK);

    formFieldErrors.push({
      errors:
        networkVirtualAppliance
          ? validateIpAddressWithinRanges(networkVirtualAppliance, virtualNetwork?.addressSpaces ?? [])
          : undefined,
      field: NetworkFields.NetworkVirtualAppliance,
      group: GROUP_NETWORK,
    });

    return formFieldErrors;
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={createFlowType === VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP
        ? Messages.createVmDb.titles.shortFromBackup()
        : Messages.createVmDb.titles.short()}
    >
      <InputWizardPanel
        componentRef={setPanelRef}
        testId={CreateWizardTestIds.VmDb}
        title={createFlowType === VmdbCreateFlowType.VMDB_DATABASE_FROM_BACKUP
          ? Messages.createVmDb.titles.longFromBackup()
          : Messages.createVmDb.titles.long()}
        message={isCreateRoleMissing
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(requiredRole),
          } as PanelMessage
          : undefined}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFormChange={(_formValues: FormValues, fieldValue: any, field: string) => {
          if (field === BasicsFields.Subscription) {
            setSubscriptionId(fieldValue?.[0]?.id);
          } else if (field === BasicsFields.ResourceGroup) {
            setResourceGroupName(fieldValue?.[0]?.text);
          } else if (field === BasicsFields.Region) {
            setLocation(fieldValue?.[0]?.id);
          } else if (field === BasicsFields.Name) {
            setInstanceName(fieldValue);
          }
        }}
        items={wizardItems}
        itemsMessages={[{
          itemNdx: 0,
          type: MessageType.WARNING,
          message: Messages.createCommon.dependencyWarnings.basicsChangeWarning(Messages.labels.vmDatabase()),
          hideOnFirstSelect: true,
        }] as InputWizardItemMessage[]}
        validator={formValidator}
        onRenderReview={onRenderReview}
        onUnselectingReview={() => {
          setCreateVmdbPayload(undefined);
          setCreationPhase(undefined);
        }}
        onAsyncValidate={enableVmdbPreflight ? onAsyncValidate : undefined}
        onSubmit={(
          formValues: FormValues,
          reject: (errorMessage: string, validationErrors?: AsyncValidationError[]) => void,
        ) => {
          submitCallbacks.current = { reject };
          onFormSubmit(formValues);
        }}
        onClose={() => {
          const analytics = customData?.analytics as NavigationAnalyticsData;
          if (analytics) {
            trackActionDiscard(analytics.actionName, analytics.pageId, analytics.panelId);
          }
          onClose();
        }}
      />
      {creationPhase === CreationPhase.CREATE_SUBMIT && (
        <DeploymentCreatePhase
          panelRef={panelRef}
          deployedService={DeployedService.VM_DATABASE}
          subscriptionId={subscriptionId}
          resourceGroupName={resourceGroupName}
          location={location}
          resourceName={resourceNameRef.current}
          deploymentName={deploymentNameRef.current}
          submitPayload={createVmdbPayload}
          submitReject={submitCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          asyncNotification={{
            methodKey: AsyncNotificationMethodKey.DEPLOYMENT_GET,
            delay: VMDB_DEPLOYMENT_CREATE_POLL_DELAY,
            pollingInterval: VMDB_DEPLOYMENT_CREATE_POLL_INTERVAL,
            polledResponseKey: AsyncNotificationPolledResponseKey.VMDB_DEPLOYMENT_CREATED_CHECK,
          }}
          onPostSubmit={() => {
            setCreateVmdbPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
      {creationPhase === CreationPhase.ASYNC_VALIDATE && (
        <DeploymentAsyncValidationPhase
          location={location}
          asyncValidatePayload={createVmdbPayload}
          asyncValidateResolve={asyncValidationCallbacks.current.resolve}
          asyncValidateReject={asyncValidationCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          onPostAsyncValidate={() => {
            setCreateVmdbPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
      <DownloadSshKeyModal
        closeModal={hideDownloadSshKey}
        submitBtnText={Messages.actions.downloadKeyCreateResource()}
        cancelBtnText={Messages.actions.returnToCreateResource()}
        isOpen={displayDownloadSshKey}
        onCancel={() => {
          trackActionDiscard(ActionIds.DownloadSshKey, PageId.VMDB_CREATE);
          panelRef.allowResubmit();
        }}
        onSubmit={() => {
          onConfirmDownloadSshKey();
        }}
      />
    </BookmarkablePage>
  );
};
