import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { parseId } from "../../helpers/idHelper";
import { getTagsMap, TagsInfoType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_UPDATE_POLL_DELAY,
  MDS_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface MysqlUpdateTagsProps extends OperationConfirmationProps {
  databaseId: string | undefined;
  tags: TagsInfoType;
}

const MysqlUpdateTags = (
  { location, databaseId, tags, onExecute }: MysqlUpdateTagsProps,
): JSX.Element => {
  const { invokeCall: invokeMdsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.updateMdsDbSystem },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
    const resourceGroupName = decodeURIComponent(resourceGroup || "");
    const dbSystemName = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.applyTags(),
          message: Messages.notifications.inProgress.messages.applyTags(dbSystemName),
        },
        success: {
          title: Messages.notifications.success.titles.applyTags(),
          message: Messages.notifications.success.messages.applyTags(dbSystemName),
        },
        failure: {
          title: Messages.notifications.failure.titles.applyTags(),
          message: Messages.notifications.failure.messages.applyTags(dbSystemName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            dbSystemName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_UPDATED_CHECK,
          delay: MDS_UPDATE_POLL_DELAY,
          interval: MDS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeMdsUpdate({
      subscriptionId,
      resourceGroupName,
      dbSystemName,
      updateMdsDbSystemDetails: { freeformTags: getTagsMap(tags) },
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export const newMysqlUpdateTags = (
  props: MysqlUpdateTagsProps,
): JSX.Element => (<MysqlUpdateTags {...props} />);
