import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { getTagsMap, TagsInfoType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMCLUSTER_EDIT_POLL_DELAY,
  VMCLUSTER_EDIT_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface ExaVmClusterUpdateTagsProps extends OperationConfirmationProps {
    vmClusterId: string| undefined;
    tags: TagsInfoType;
}

const ExaVmClusterUpdateTags = (
  { location, vmClusterId, tags, onExecute }: ExaVmClusterUpdateTagsProps,
): JSX.Element => {
  const { invokeCall: invokeTagUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.updateCloudVmCluster },
  );
  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(vmClusterId);
    const resGroup = decodeURIComponent(resourceGroup || "");
    const resName = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.applyTags(),
          message: Messages.notifications.inProgress.messages.applyTags(resName),
        },
        success: {
          title: Messages.notifications.success.titles.applyTags(),
          message: Messages.notifications.success.messages.applyTags(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.applyTags(),
          message: Messages.notifications.failure.messages.applyTags(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMCLUSTER_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            cloudVmClusterName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMCLUSTER_EDIT_CHECK,
          delay: VMCLUSTER_EDIT_POLL_DELAY,
          interval: VMCLUSTER_EDIT_POLL_INTERVAL,
        },
      },
    };
    invokeTagUpdate({
      subscriptionId,
      resourceGroupName: resGroup,
      cloudVmClusterName: resName,
      updateCloudVmClusterDetails: { freeformTags: getTagsMap(tags) },
    }, invokeOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newExaVmClusterUpdateTags = (
  props: ExaVmClusterUpdateTagsProps,
): JSX.Element => (<ExaVmClusterUpdateTags {...props} />);
