import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  Select,
  SelectOption,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import * as Messages from "../../codegen/Messages";
import { IdResourceType } from "../../helpers/idHelper";
import { useResourceGroups } from "../../hooks/useResourceGroups";

export interface ResourceGroupProps extends Omit<SelectProps, "options"> {
  subscriptionId: string;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const ResourceGroupSelect = ({
  defaultValue,
  fieldName,
  componentRef,
  testId,
  label,
  inputLink,
  required,
  subscriptionId,
  subField,
  tooltip,
  placeholder,
  validator,
  onChange,
  onError,
  onMissingDependencies,
  disabled,
}: ResourceGroupProps): JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());
  const { loading, error, resourceGroups = [] } = useResourceGroups(subscriptionId);
  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const form: FormState = React.useContext(FormContext);
  const options = resourceGroups?.map(resource => ({
    id: resource.id,
    text: resource.name,
  }) as SelectOption).sort(({ text: a }, { text: b }) => (a.toLocaleLowerCase() > b.toLocaleLowerCase() ? 1 : -1));

  React.useEffect(() => {
    if (error) onError?.(error);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setKey(uniqueGUID());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId]);
  React.useEffect(() => {
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(resourceGroups)]);

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const derivedPlaceholder = !loading && !error && options.length === 0
    ? Messages.createCommon.loadingNone(label?.toLowerCase() || "")
    : placeholder;

  return (
    <Select
      key={key}
      loading={!!subscriptionId && loading}
      testId={testId}
      componentRef={componentRef}
      fieldName={fieldName}
      options={options}
      placeholder={derivedPlaceholder}
      label={label}
      required={required}
      subField={subField}
      inputLink={inputLink}
      defaultValue={defaultValue}
      tooltip={tooltip}
      onClick={internalOnClick}
      onChange={onChange}
      disabled={disabled}
      validator={validator}
    />
  );
};
