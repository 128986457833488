import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  ConnectionStringsPanel,
  ConnectionStringsType,
} from "../../components/ConnectionStringsPanel/ConnectionStringsPanel";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface ExaDbViewConnectionStringsProps extends OperationProps {
  databaseId: string | undefined;
  isPdb: boolean;
}

const ExaDbViewConnectionStrings = ({
  databaseId,
  isPdb,
  location,
  onCancel,
}: ExaDbViewConnectionStringsProps): JSX.Element => {
  const idComps = parseId(databaseId);
  const resGroup = decodeURIComponent(idComps?.resourceGroup || "");
  const resourceName = decodeURIComponent(idComps?.resourceName || "");

  const { loading: cdbLoading, response: cdbResponse } = useQueryCall({
    wait: !databaseId || isPdb,
    method: apiClients.withRegion(
      getOciRegion(location),
    ).exaDatabaseApi.getDatabase,
    options: {
      args: {
        subscriptionId: idComps?.subscriptionId || "",
        resourceGroupName: resGroup,
        databaseName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDatabases(),
      },
    },
  });

  const { loading: pdbLoading, response: pdbResponse } = useQueryCall({
    wait: !databaseId || !isPdb,
    method: apiClients.withRegion(
      getOciRegion(location),
    ).exaDatabaseApi.getPluggableDatabase,
    options: {
      args: {
        subscriptionId: idComps?.subscriptionId || "",
        resourceGroupName: resGroup,
        pluggableDatabaseName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadPluggableDatabase(),
      },
    },
  });

  const db = isPdb ? pdbResponse?.data : cdbResponse?.data;

  const connectionStrings: ConnectionStringsType = [];
  const allConnectionStrings = db?.connectionStrings?.allConnectionStrings;
  if (allConnectionStrings) {
    Object.entries(allConnectionStrings).forEach(connection => (
      connectionStrings.push({
        id: connection[0],
        connectionString: connection[1],
      })
    ));
  }

  return (
    <ConnectionStringsPanel
      connectionStrings={connectionStrings}
      analytics={{
        pageId: PageId.EXA_LIST_HUB,
        panelId: DetailsPanelId.DATABASES,
      }}
      loading={isPdb ? pdbLoading : cdbLoading}
      onCancel={onCancel}
    />
  );
};

export const newExaDbViewConnectionStrings = (
  props: ExaDbViewConnectionStringsProps,
): JSX.Element => (<ExaDbViewConnectionStrings {...props} />);
