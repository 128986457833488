import { LabelMetaItem, MetaItemSection, NoValue } from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { metaItemLabelsGap } from "../../constants/uiConstants";
import { MdsDbSystem } from "../../gen/clients/mchub-azure-api-client-mds";

export enum MetaItemSectionTestIds {
  INTERNAL_FQN = "mis-internal-fqn",
  IP_ADDRESS = "mis-ip-address",
  MYSQL_PORT = "mis-mysql-port",
  MYSQL_PORT_X = "mis-mysql-port-x",
}

export interface MysqlConnectDetailsProps {
  mdsDbSystem: MdsDbSystem | undefined;
}

export const MysqlConnectDetails = (
  { mdsDbSystem }: MysqlConnectDetailsProps,
): JSX.Element => (
  <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
    <MetaItemSection labelWidth={200}>
      <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
        <LabelMetaItem testId={MetaItemSectionTestIds.INTERNAL_FQN} label={Messages.labels.internalFQDN()}>
          {mdsDbSystem?.fqdn || <NoValue />}
        </LabelMetaItem>
        <LabelMetaItem testId={MetaItemSectionTestIds.IP_ADDRESS} label={Messages.labels.ipAddress()}>
          {mdsDbSystem?.ipAddress || <NoValue />}
        </LabelMetaItem>
        <LabelMetaItem testId={MetaItemSectionTestIds.MYSQL_PORT} label={Messages.labels.mySQLPort()}>
          {mdsDbSystem?.port?.toString() || <NoValue />}
        </LabelMetaItem>
        <LabelMetaItem testId={MetaItemSectionTestIds.MYSQL_PORT_X} label={Messages.labels.mySQLPortX()}>
          {mdsDbSystem?.portX?.toString() || <NoValue />}
        </LabelMetaItem>
      </Stack>
    </MetaItemSection>
  </Stack>
);
