import { InputWizardPanelComponent, optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { CloudInfrastructureSelect } from "../../components/CloudInfrastructureSelect/CloudInfrastructureSelect";
import { GridInfraVersionSelect } from "../../components/GridInfraVersionSelect/GridInfraVersionSelect";
import { LicenseTypeSelect } from "../../components/LicenseTypeSelect/LicenseTypeSelect";
import { StorageConfigInput } from "../../components/StorageConfigInput/StorageConfigInput";
import { VmClusterConfigInput } from "../../components/VmClusterConfigInput/VmClusterConfigInput";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import {
  CloudExadataInfrastructureSummary,
  CloudExadataInfrastructureSummaryCollection,
} from "../../gen/clients/mchub-azure-api-client-exa";
import { responseItemstoArray } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

export const GROUP_CONFIG = "config";

interface VmClusterConfigInputValue {
  value: number;
}

export enum Fields {
  Infrastructure = "cloudExadataInfrastructureDetails.id",
  OracleGridInfrastructureVersion = "cloudVmClusterDetails.giVersion",
  LicenseType = "cloudVmClusterDetails.licenseModel",
  VmClusterConfiguration = "cloudVmClusterDetails.cpuCoreCount",
  StorageConfiguration = "cloudVmClusterDetails.storageConfiguration",
}

export enum FieldTestIds {
  Infrastructure = "cloudInfrastructure",
  VmClusterConfiguration = "vmClusterConfig",
  StorageConfiguration = "storageConfig",
  OracleGridInfrastructureVersion = "gridInfraVersion",
  LicenseType = "licenseType",
}

export interface ConfigTabContentProps {
  inputWizardPanelRef: InputWizardPanelComponent;
  subscriptionId: string;
  resourceGroupName: string;
  location: string;
  disableUIControls?: Fields[] | undefined;
  defaultInfraId?: string;
}

export const ConfigTabContent = (
  {
    inputWizardPanelRef, subscriptionId, resourceGroupName, location,
    defaultInfraId, disableUIControls,
  }: ConfigTabContentProps,
): JSX.Element => {
  const onError = (): void => inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.general());
  const onMissingInfraStructureDependencies = (): void => (
    inputWizardPanelRef.showErrorDialog(
      Messages.createCommon.dependencyWarnings.basicsIncomplete.message(),
      Messages.createCommon.dependencyWarnings.basicsIncomplete.title(),
    ));

  const onMissingInfraVersionDependencies = (): void => (
    inputWizardPanelRef.showErrorDialog(
      Messages.createCommon.dependencyWarnings.infrastructureNotSelected.message(),
      Messages.createCommon.dependencyWarnings.infrastructureNotSelected.title(),
    ));

  const { loading, response, error } = useQueryCall({
    wait: !(subscriptionId && resourceGroupName && location && defaultInfraId),
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listCloudExadataInfrastructures,
    options: {
      args: { subscriptionId, resourceGroupName, apiVersion: MultiCloudDatabaseApiVersion },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadExadbInfraList(),
      },
    },
  });

  const [defaultShape, setDefaultShape] = React.useState<string>();
  const [defaultDbServers, setDefaultDbServers] = React.useState<number>();
  const [
    , setVmClusterConfigInputValue,
  ] = React.useState<VmClusterConfigInputValue>();

  React.useEffect(() => {
    // eslint-disable-next-line max-len
    const items = (!loading && response && responseItemstoArray<CloudExadataInfrastructureSummaryCollection, CloudExadataInfrastructureSummary>(response)) || [];
    const selectedInfra = items.find(item => item.id === defaultInfraId);
    if (selectedInfra) {
      setDefaultShape(selectedInfra?.shape);
      setDefaultDbServers(selectedInfra?.computeCount);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, loading]);

  React.useEffect(() => {
    if (defaultInfraId === "") {
      setDefaultShape(undefined);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceGroupName]);

  React.useEffect(() => {
    if (error) {
      onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <CloudInfrastructureSelect
        required
        fieldName={Fields.Infrastructure}
        testId={FieldTestIds.Infrastructure}
        label={Messages.labels.infra()}
        subscriptionId={subscriptionId}
        location={location}
        resourceGroupName={resourceGroupName}
        onError={onError}
        onMissingDependencies={onMissingInfraStructureDependencies}
        disabled={disableUIControls?.find(ele => ele === Fields.Infrastructure) !== undefined}
        defaultValue={defaultInfraId ? [defaultInfraId] : undefined}
        onInfraChange={(infra: CloudExadataInfrastructureSummary) => {
          setDefaultShape(infra?.shape || undefined);
          setDefaultDbServers(infra?.computeCount);
        }}

      />
      <VmClusterConfigInput
        fieldName={Fields.VmClusterConfiguration}
        testId={FieldTestIds.VmClusterConfiguration}
        label={Messages.labels.vmClusterConfiguration()}
        required
        subscriptionId={subscriptionId}
        location={location}
        dbSystemShape={defaultShape}
        dbServers={defaultDbServers}
        callback={(value: VmClusterConfigInputValue) => { setVmClusterConfigInputValue(value); }}
        onMissingDependencies={onMissingInfraVersionDependencies}
      />
      <StorageConfigInput
        label={Messages.labels.storage()}
        fieldName={Fields.StorageConfiguration}
        testId={FieldTestIds.StorageConfiguration}
      />
      <GridInfraVersionSelect
        fieldName={Fields.OracleGridInfrastructureVersion}
        testId={FieldTestIds.OracleGridInfrastructureVersion}
        label={Messages.labels.oracleGridInfrastructureVersion()}
        required
        tooltip={Messages.hints.selectOracleGridInfrastructureVersion()}
        subscriptionId={subscriptionId}
        location={location}
        shape={defaultShape}
        onError={onError}
        onMissingDependencies={onMissingInfraVersionDependencies}
      />
      <LicenseTypeSelect fieldName={Fields.LicenseType} testId={FieldTestIds.LicenseType} />
    </>
  );
};
