import {
  ActionType,
  CustomAction,
  DateTimeDisplay,
  DurationDisplay,
  ExtraFilter,
  FavoriteAccessItem,
  FilterAll,
  FilterComponent,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingDisplayNameLink,
  Operator,
  optimizedRetryOption,
  SortDirections,
  stateT,
  Status,
  useFavoriteAccess,
} from "o4a-react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { SelectionMode } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import {
  emptyChar,
  ListingTestIds,
  MonochromeIconIds,
  SvgIconIds,
  ttlOneMinCaching,
} from "../../constants/uiConstants";
import {
  AzureSubscriptionSummary,
  DeploymentCollection,
  DeploymentSummary,
  DeploymentSummaryDeploymentTypeEnum,
} from "../../gen/clients/mchub-azure-api-client";
import { parseId } from "../../helpers/idHelper";
import {
  getDeploymentDuration,
  getDeploymentTypeString,
  getFieldCustomOptions,
  responseItemstoArray,
  sortDeploymentDuration,
  sortDeploymentTypes,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useFilterStatePills } from "../../hooks/useFilterStatePills";
import { useLocationFilters } from "../../hooks/useLocationFilters";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { getAzureLocationName, getOciRegion } from "../../utils";

export enum ColumnIds {
  Name = "name",
  DeploymentStatus = "lifecycleState",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  DeploymentType = "deploymentType",
  Started = "timeCreated",
  Duration = "timeFinished"
}

export enum ColumnTestIds {
  Name = "name",
  DeploymentStatus = "lifecycleState",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  DeploymentType = "deploymentType",
  Started = "timeCreated",
  Duration = "timeFinished",
}

export enum ActionIds {
  Refresh = "refresh",
}

export enum ActionBarTestIds {
  Refresh = "refresh",
}

export enum ActionMenuIds {
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum ActionMenuTestIds {
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum FilterTestIds {
  SubscriptionFilter = "subscription-filter",
  LocationFilter = "location-filter",
}

const detailsPageRegistrationIds = PageRegistrationConfig[PageId.DEPLOYMENT_DETAILS].map(config => config.key);

interface AzureSubscriptionSummaryMap {
  [key: string]: AzureSubscriptionSummary;
}

interface DeploymentSummaryExt extends DeploymentSummary {
  displayName: string;
  subscriptionName: string;
  resourceGroupName: string;
  isFavorite: boolean;
}

export const DeploymentList = (): JSX.Element => {
  const { trackActionClick } = useAnalytics();

  const { favoriteItems, isFavorite, setAsFavorite, removeFromFavorites } = useFavoriteAccess();

  const { preferredSubscription, locale } = React.useContext<Settings>(SettingsContext);
  const filterState = React.useContext<FilterState>(FilterStateContext);
  const { pathname } = useLocation();

  const [selectedSubscription, setSelectedSubscription] = React.useState<string>("");
  const [subscriptionOptions, setSubscriptionOptions] = React.useState<{ key: string, text: string }[]>([]);
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();

  const { locationOptions, selectedLocation, setSelectedLocation } = useLocationFilters(
    filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
  );

  const [filterComponentRef, setFilterComponentRef] = React.useState<FilterComponent>();
  const pillFilterValues = filterComponentRef?.getFilters();
  const filterTextValue = filterComponentRef?.getFilterText();
  const [filterText, setFilterText] = React.useState(filterState.filterTextValue || filterTextValue);

  const { perPageItemsCount } = useRowCount();

  const { refresh, loading: instanceLoading, response: instancesResponse } = useQueryCall({
    wait: !selectedSubscription || !selectedLocation,
    method: apiClients.withRegion(
      getOciRegion(selectedLocation),
    ).deploymentApi.listDeploymentsInSubscription,

    options: {
      args: { subscriptionId: selectedSubscription },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDeployment(),
      },
    },
  });

  const deploymentSummaries = instancesResponse
    && responseItemstoArray<DeploymentCollection, DeploymentSummary>(instancesResponse);

  const items = React.useMemo(() => {
    const newDeploymentSummaries: DeploymentSummaryExt[] = [];
    if (deploymentSummaries && subscriptions && subscriptions.length > 0) {
      const subscriptionsMap = subscriptions.reduce((
        map: AzureSubscriptionSummaryMap,
        subscription: AzureSubscriptionSummary,
      ) => {
        map[subscription.id] = subscription;
        return map;
      }, {});
      deploymentSummaries.forEach(summary => {
        const { subscriptionId, resourceGroup } = parseId(summary.id);
        let summarySubscriptionName = subscriptionId;
        if (subscriptionsMap && subscriptionsMap[subscriptionId]) {
          summarySubscriptionName = subscriptionsMap[subscriptionId].name;
        }
        newDeploymentSummaries.push({
          ...summary,
          location: summary.location,
          displayName: summary.name,
          subscriptionName: summarySubscriptionName,
          resourceGroupName: resourceGroup,
          isFavorite: isFavorite(summary.id),
        });
      });
    }
    return newDeploymentSummaries;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(deploymentSummaries), JSON.stringify(subscriptions), JSON.stringify(favoriteItems)]);

  const onRefresh = (): void => {
    refresh();
  };

  const getStatusVal = (
    value: DeploymentSummaryExt,
  ): JSX.Element => (
    <Status
      label={stateT(value.lifecycleState)}
      status={getResourceLifecycleStatus(value.lifecycleState || "")}
      tooltip={stateT(value.lifecycleState || "")}
      hideClipboardCopy
    />
  );

  const getDateTime = (value: Date): JSX.Element => <DateTimeDisplay date={value} hideClipboardCopy />;
  const getDurationVal = (
    deploymentExt: DeploymentSummaryExt,
  ): JSX.Element => (
    <DurationDisplay
      durationMs={getDeploymentDuration(deploymentExt)}
    />
  );

  const getLocation = (value: DeploymentSummaryExt): string => getAzureLocationName(value.location);

  const getDeploymentType = (
    value: DeploymentSummaryExt,
  ): string => getDeploymentTypeString(value.deploymentType);

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 2,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.displayName}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.DEPLOYMENT_DETAILS][0].panelPath}?location=${value.location}`,
            pageKey: detailsPageRegistrationIds[0],
          }}
          iconName={SvgIconIds.deploymentSvg}
        />
      ),
    },
    {
      itemProp: ColumnIds.DeploymentStatus,
      testId: ColumnTestIds.DeploymentStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      isResizable: true,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: DeploymentSummaryExt,
        b: DeploymentSummaryExt,
      ) => statusSortComparator<DeploymentSummaryExt>(a, b, locale),
      onRenderItems: value => getStatusVal(value),
    },
    {
      itemProp: ColumnIds.SubscriptionName,
      testId: ColumnTestIds.SubscriptionName,
      name: Messages.labels.subscription(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, max-len
      onRenderItems: value => <AzureSubscriptionLink resourceId={value.id} subscriptionName={value.subscriptionName} hideClipboard />,
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.DeploymentType,
      testId: ColumnTestIds.DeploymentType,
      name: Messages.labels.type(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      comparator: (a, b) => sortDeploymentTypes(a, b, locale),
      isResizable: true,
      onRenderItems: value => getDeploymentType(value),
    },
    {
      itemProp: ColumnIds.Started,
      testId: ColumnTestIds.Started,
      name: Messages.labels.started(),
      flexGrow: 2,
      initialSortDirection: SortDirections.DESC,
      isResizable: true,
      onRenderItems: value => getDateTime(value.timeCreated),
    },
    {
      itemProp: ColumnIds.Duration,
      testId: ColumnTestIds.Duration,
      name: Messages.labels.duration(),
      flexGrow: 2,
      initialSortDirection: SortDirections.DESC,
      isResizable: true,
      onRenderItems: value => getDurationVal(value),
      comparator: sortDeploymentDuration,
    },
  ];

  const actions: ActionType[] = [
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: "Refresh",
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.DEPLOYMENT_LIST);
        onRefresh();
      },
    },
  ];

  React.useEffect(() => {
    const newSubscriptionOptions: { key: string, text: string }[] = [];
    // eslint-disable-next-line no-unused-expressions
    subscriptions && subscriptions.forEach(subscription => {
      newSubscriptionOptions.push({ key: subscription.id, text: subscription.name });
    });

    setSubscriptionOptions(newSubscriptionOptions);
    if (filterState.defaultSubscription) {
      setSelectedSubscription(filterState.defaultSubscription);
      if (defaultExtraFilters) defaultExtraFilters[0].value = filterState.defaultSubscription;
    } else if (preferredSubscription) {
      setSelectedSubscription(preferredSubscription);
    } else {
      setSelectedSubscription(newSubscriptionOptions.length ? newSubscriptionOptions[0].key : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions)]);

  const subscriptionFilter: ExtraFilter = {
    testId: FilterTestIds.SubscriptionFilter,
    name: columns[2].name,
    operator: Operator.Equal,
    value: filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
    fieldKey: columns[2].itemProp,
    customOptions: subscriptionOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedSubscription(value);
        filterState.setDefaultSubscription(value);
      }
    },
    hideDelete: true,
  };

  const resourceGroupFilter: ExtraFilter = {
    name: columns[3].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[3].itemProp)?.value || [FilterAll],
    fieldKey: columns[3].itemProp,
    customOptions: getFieldCustomOptions(items?.map(item => item.resourceGroupName), (fieldVal: string) => fieldVal),
  };

  React.useEffect(() => {
    if (filterState.defaultLocation) {
      setSelectedLocation(filterState.defaultLocation);
      if (defaultExtraFilters) defaultExtraFilters[1].value = filterState.defaultLocation;
    } else {
      setSelectedLocation(filterState.pillFilterStateValue.filterValues?.[1]?.value || selectedLocation);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locationFilter: ExtraFilter = {
    testId: FilterTestIds.LocationFilter,
    name: columns[4].name,
    operator: Operator.Equal,
    value: filterState.defaultLocation ? filterState.defaultLocation : selectedLocation,
    fieldKey: columns[4].itemProp,
    customOptions: locationOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedLocation(value);
        filterState.setDefaultLocation(value);
      }
    },
    hideDelete: true,
  };

  const lifecycleStateFilter: ExtraFilter = {
    name: columns[1].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[1].itemProp)?.value || [FilterAll],
    fieldKey: columns[1].itemProp,
    messageCallBack: stateT,
    customOptions: getFieldCustomOptions(
      items?.map(item => item.lifecycleState || ""),
      (fieldVal: string) => {
        if (fieldVal) {
          return stateT(fieldVal);
        }
        return emptyChar;
      },
    ),
  };

  const deploymentTypeCustomOptions: { key: string, text: string }[] = [];
  items?.forEach(item => {
    const type = item.deploymentType;
    if (!(deploymentTypeCustomOptions.find(option => (option.key === type)))) {
      deploymentTypeCustomOptions.push({
        key: type,
        text: getDeploymentTypeString(type),
      });
    }
  });

  const deploymentTypeFilter: ExtraFilter = {
    name: columns[5].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[5].itemProp)?.value || [FilterAll],
    fieldKey: columns[5].itemProp,
    messageCallBack: getDeploymentTypeString,
    customOptions: deploymentTypeCustomOptions,
  };

  const allFilterPills = [
    subscriptionFilter,
    locationFilter,
    resourceGroupFilter,
    lifecycleStateFilter,
    deploymentTypeFilter,
  ];

  const filterPills: ExtraFilter[] = [
    subscriptionFilter,
    locationFilter,
  ];

  const textFilterCallBack = (rowItem: DeploymentSummaryExt): DeploymentSummaryExt => {
    const displayRowItem = { ...rowItem };
    displayRowItem.lifecycleState = stateT(displayRowItem.lifecycleState);
    displayRowItem.location = getAzureLocationName(displayRowItem.location);
    displayRowItem.deploymentType = getDeploymentTypeString(
      displayRowItem.deploymentType,
    ) as DeploymentSummaryDeploymentTypeEnum;
    return displayRowItem;
  };

  React.useEffect(() => {
    setFilterText(filterTextValue);
    filterState.setFilterTextValue(filterTextValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTextValue]);

  const defaultExtraFilters = useFilterStatePills(pillFilterValues, allFilterPills, pathname, filterPills);

  const resetFilterValues = (): void => {
    const defaultPillValues = filterPills.map(filter => ({ fieldKey: filter.fieldKey, value: filter.value as string }));
    filterState.setFilterStateValue({ filterValues: defaultPillValues, listId: pathname, defaultPills: filterPills });
    filterState.setFilterTextValue("");
    setFilterText("");
  };

  const deploymentRowActionMenus = (item?: DeploymentSummaryExt): ActionType<DeploymentSummaryExt>[] => {
    const actionItems: ActionType<DeploymentSummaryExt>[] = [];
    if (item) {
      if (item.isFavorite) {
        actionItems.push({
          key: ActionMenuIds.RemoveFavorite,
          testId: ActionMenuTestIds.RemoveFavorite,
          text: Messages.actions.removeFromFavorites(),
          icon: MonochromeIconIds.FavoriteSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.RemoveFavorite, PageId.DEPLOYMENT_LIST);
            removeFromFavorites(item.id);
          },
        } as CustomAction<DeploymentSummaryExt>);
      } else {
        actionItems.push({
          key: ActionMenuIds.AddFavorite,
          testId: ActionMenuTestIds.AddFavorite,
          text: Messages.actions.addToFavorites(),
          icon: MonochromeIconIds.FavoriteNotSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.AddFavorite, PageId.DEPLOYMENT_LIST);
            setAsFavorite({ id: item.id, data: { location: item.location } } as FavoriteAccessItem);
          },
        } as CustomAction<DeploymentSummaryExt>);
      }
    }
    return actionItems;
  };

  return (
    <Listing
      testId={ListingTestIds.Deployments}
      items={items}
      listColumns={columns}
      itemsPerPage={perPageItemsCount}
      actionBarItems={actions}
      actions={deploymentRowActionMenus}
      selectionMode={SelectionMode.none}
      emptyList={{
        title: Messages.deployment.emptyList.title(),
        watermark: SvgIconIds.deploymentWaterMarkSvg,
        emptyButtons: [],
      }}
      isLoading={instanceLoading || subscriptionLoading}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Started,
      }}
      filtering={{
        allExtraFilters: allFilterPills,
        defaultExtraFilters,
        defaultFilterText: filterText,
        textFilterCallBack,
        componentRef: setFilterComponentRef,
        onClearFilters: resetFilterValues,
      }}
    />
  );
};
