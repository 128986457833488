import {
  clearNavHistoryStorage,
  DetailViewsMenuZoomLayout,
  injectKeyFramesForAnimation,
  MenuLayoutType,
  ScreenSizeContext,
  ScreenSizeMonitor,
  useNavigation,
  useNotification,
  useResizeObserver,
} from "o4a-react";
import React, { CSSProperties } from "react";
import {
  ActionButton,
  BaseButton,
  Callout,
  DirectionalHint,
  FocusTrapZone,
  FocusZone,
  IButtonProps,
  IButtonStyles,
  IconButton,
  IContextualMenuItemStyles,
  IOverflowSetItemProps,
  IPersonaProps,
  IPersonaSharedProps,
  Link,
  mergeStyleSets,
  OverflowSet,
  Overlay,
  Persona,
  PersonaPresence,
  PersonaSize,
  PrimaryButton,
  ProgressIndicator,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as Messages from "../codegen/Messages";
import { HOME_ROUTE, PageId } from "../constants/pluginConstants";
import { footerHeight, MenuStyleMode, MonochromeIconIds, SvgIconIds, topHeaderHeight } from "../constants/uiConstants";
import { useAppAuthContext } from "../hooks/useAppAuthContext";
import { useSidePanel } from "../hooks/useSidePanel";
import { logOut } from "../session/azureSession";
import { myAccountUrl } from "../utils";
import { ConsoleContext } from "./ConsoleContext";
import { TopMenu } from "./TopMenu";

const ProfileActionBtnId = "profileActionBtn";

const classNames = mergeStyleSets({
  headerContainer: {
    backgroundColor: "#0078D4",
    color: "white",
    height: `${topHeaderHeight}px`,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  leftContainer: {
    display: "flex",
    minWidth: "220px",
  },
  rightContainer: { display: "flex" },
  starIcon: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  menuActionIcons: {
    display: "flex",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  headerUserInfo: {
    display: "flex",
    width: "220px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    alignItems: "center",
    fontSize: "12px",
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "right",
    cursor: "pointer",
    ":hover": { backgroundColor: "#106ebe" },
  },
  headerUserInfoCompany: { fontSize: "10px" },
  hamburgerOpenIcon: {
    height: `${topHeaderHeight}px`,
    backgroundColor: "#FFFFFF",
    width: "225px",
    borderRadius: "0px",
    display: "flex",
    alignItems: "start",
    padding: "0px 20px 0px 7px",
    cursor: "pointer",
    ":hover": { backgroundColor: "#F0F7FE" },
  },
  hamburgerOpen: {
    fontSize: "16px",
    color: "#3078CD",
    fontWeight: "bold",
  },
  headerMenuOverlay: { msNav: { background: "white" } },
  notificationCount: {
    fontSize: "10px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: "white",
    color: "#0078D4",
    width: "16px",
    height: "16px",
    display: "inline-block",
    lineHeight: "normal",
    position: "absolute",
    cursor: "pointer",
    top: "5px",
    right: "5px",
  },
  profileCallout: {
    fontSize: "16px",
    marginRight: "-8px",
  },
  personnaCallOutContainer: {
    paddingTop: "15px",
    paddingBottom: "30px",
    gridRow: "2/3",
    gridColum: "1/4",
  },
  profileCalloutContainer: {
    backgroundColor: "#fff",
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
  },
  profileTenantName: {
    fontSize: "13px",
    color: "#333",
    paddingLeft: "14px",
    paddingRight: "14px",
    margin: "auto",
    gridColum: "1/2",
  },
  showOverlay: { visibility: "visible" },
  hideOverlay: { visibility: "hidden" },
});

const switchMenuButtonStyles: IButtonStyles = {
  root: {
    height: "32px",
    color: "#0078d4",
    backgroundColor: "#FFFFFF",
    width: "225px",
    borderRadius: "0px",
    display: "flex",
    alignItems: "start",
    padding: "0px 20px 0px 7px",
    cursor: "pointer",
  },
  labelHovered: {
    color: "#004578",
    textDecoration: "underline",
  },
};

export enum ActionIds {
  CloudShell = "cloud-shell",
  Subscriptions = "subscriptions",
  Notifications = "notifications",
  Settings = "settings",
  Help = "help",
  Feedback = "feedback",
}

export enum ActionTestIds {
  CloudShell = "cloud-shell",
  Subscriptions = "subscriptions",
  Notifications = "notifications",
  Settings = "settings",
  Help = "help",
  Feedback = "feedback",
}

export enum CalloutTestIds {
  PersonaCallout = "persona-callout"
}

export enum OverlayTestIds {
  HeaderMenu = "header-menu-overlay"
}

export enum ButtonTestIds {
  Hamburger = "hamburger",
  MoreTools = "more-tools",
  NavigateToHome = "navigate-to-home",
  Profile = "profile",
  Logout = "logout",
  OverlayHamburger = "overlay-hamburger"
}

export enum LinkTestIds {
  ViewAccount = "view-account"
}

export interface TopHeaderProps {
  menuStyleMode?: MenuStyleMode,
  isOverlayOpen: boolean,
  openOverlay: () => void,
  dismissOverlay: () => void,
  onActionClick?: (actionId: ActionIds) => void;
  activeActionId?: ActionIds;
  noTopMenu?: boolean;
}

enum HeaderLayoutType {
  COMPACT = "COMPACT",
  NORMAL = "NORMAL",
}

const getInitialsFromName = (name: string):
  string => ((name.match(/(\b\S)?/g) || []).join("").match(/(^\S|\S$)?/g) || []).join("").toUpperCase();

const getInitialsFromEmail = (email: string): string => {
  const emailRegex = /^(.*)\.(.*)@.*$/;
  const matches = email?.match(emailRegex);
  if (matches) {
    return `${matches[1].substring(0, 1).toUpperCase()}${matches[2].substring(0, 1).toUpperCase()}`;
  }
  return email?.substring(0, 1).toUpperCase();
};

const brandNameStyles: IButtonStyles = {
  root: {
    height: `${topHeaderHeight}px`,
    border: "none",
    fontSize: "15px",
    padding: "0 16px",
    fontWeight: 600,
    lineHeight: `${topHeaderHeight}px`,
    cursor: "pointer",
    ":focus-visible": {
      border: "white solid 1px",
      "::after": { outline: "none !important" },
    },
  },
  rootHovered: { border: "none" },
  rootPressed: { border: "none" },
  label: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  textContainer: { overflow: "hidden" },
};

const btnStyles: IButtonStyles = {
  root: {
    color: "white",
    width: "40px",
    height: "40px",
    borderRadius: 0,
    ":focus-visible": {
      border: "white solid 1px",
      "::after": { outline: "none !important" },
    },
  },
  rootHovered: {
    backgroundColor: "#106ebe",
    color: "white",
  },
  rootPressed: {
    backgroundColor: "#135995",
    color: "white",
  },
  rootChecked: {
    backgroundColor: "white",
    color: "#0078D4",
  },
  rootCheckedHovered: {
    backgroundColor: "white",
    color: "#0078D4",
  },
  rootExpanded: {
    backgroundColor: "white",
    color: "#0078D4",
  },
  rootDisabled: { backgroundColor: "rgb(0, 120, 212)" },
  menuIcon: { fontSize: "16px", margin: "0px 8px" },
  icon: { width: "22px", height: "22px", lineHeight: "22px" },
};

const btnMenuStyles: IContextualMenuItemStyles = {
  root: { ":hover .ms-ContextualMenu-icon": { color: "#323130" }, fontSize: "13px" },
  linkContent: { flexDirection: "row-reverse" },
  icon: { color: "#323130", fontSize: "13px !important", width: "22px", height: "22px" },
  item: { padding: "2px 0px" },
};

const btnMenuIconStyles: CSSProperties = { width: "32px" };

const signOutBtnStyles: IButtonStyles = {
  root: {
    gridColumn: "3/4",
    gridRow: "1/2",
    textDecoration: "none",
    outline: "none",
    padding: "15px 14px",
    fontSize: "13px",
    color: "#333",
    backgroundColor: "inherit",
    border: "0px",
    ":focus-visible": {
      backgroundColor: "rgba(0,0,0,.12)",
      textDecoration: "underline",
      boxShadow: "rgb(96, 94, 92) 0px 0px 0px 1px inset",
    },
  },
  rootHovered: {
    backgroundColor: "rgba(0,0,0,.12)",
    textDecoration: "underline",
  },
};

interface NotificationActionWrapperProps {
  unreadCount: number;
  inProgressCount: number;
  onClick: () => void;
  children?: React.ReactNode;
}

const NotificationActionWrapper = ({
  children,
  unreadCount,
  inProgressCount,
  onClick,
}: NotificationActionWrapperProps): JSX.Element => (
  <span style={{ position: "relative" }}>
    {children}
    {unreadCount > 0 && (
    <div
      className={classNames.notificationCount}
      onClick={onClick}
      role="button"
      onKeyPress={() => ({})}
      tabIndex={0}
    >
      {unreadCount}
    </div>
    )}
    {inProgressCount > 0 && (
    <ProgressIndicator
      styles={{
        root: { marginTop: "-4px" },
        itemProgress: { padding: "0px" },
        progressBar: {
          minWidth: "60%",
          background: "#00bcf2",
          animation: "IndeterminateProgress 1.5s linear infinite",
        },
        progressTrack: { backgroundColor: "inherit" },
      }}
    />
    )}
  </span>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const passPropsToReactElement = (element: any, props: any):JSX.Element => {
  if (React.isValidElement(element)) {
    return React.cloneElement(element, props);
  }
  return element;
};

enum ShowMenu {
  TOP_MENU = "TOP_MENU",
  DETAILS_MENU = "DETAILS_MENU",
}

export const TopHeader = ({
  menuStyleMode,
  isOverlayOpen,
  openOverlay,
  dismissOverlay,
  onActionClick,
  activeActionId,
  noTopMenu,
}: TopHeaderProps): JSX.Element => {
  injectKeyFramesForAnimation(`@keyframes IndeterminateProgress {
    from {
      left: -100%
    }
    to {
      left: 150%
    }
  }`);

  const [showMenu, setShowMenu] = React.useState<ShowMenu>(ShowMenu.TOP_MENU);

  const screenSizeMonitor = React.useContext<ScreenSizeMonitor | undefined>(ScreenSizeContext);

  React.useEffect(() => {
    switch (screenSizeMonitor?.menuLayoutType) {
      case MenuLayoutType.TOP_SIDE_COUPLED:
        setShowMenu(ShowMenu.DETAILS_MENU);
        break;
      case MenuLayoutType.TOP_SIDE_DECOUPLED:
      default:
        setShowMenu(ShowMenu.TOP_MENU);
        if (menuStyleMode === MenuStyleMode.Docking) {
          // Dismiss any flyout menu that was present when zoomed in:
          // If user zooms out while keeping the flyout menu open
          // it leaves the hamburger icon behind which causes a crash
          // if clicked on.
          dismissOverlay();
        }
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSizeMonitor?.menuLayoutType]);

  const ref = React.useRef(null);
  const { width: headerWidth } = useResizeObserver(ref);
  const { closePanels } = useSidePanel();

  const [headerLayoutType, setHeaderLayoutType] = React.useState<HeaderLayoutType>(
    HeaderLayoutType.NORMAL,
  );

  const [isProfileCalloutVisible, setProfileCalloutVisible] = React.useState<boolean>(false);

  React.useLayoutEffect(() => {
    let layoutType;
    if (headerWidth < 960) {
      layoutType = HeaderLayoutType.COMPACT;
    } else {
      layoutType = HeaderLayoutType.NORMAL;
    }
    setHeaderLayoutType(layoutType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerWidth]);

  const labelId = useId("callout-label");
  const descriptionId = useId("callout-description");
  const buttonId = useId("callout-button");

  const { domainName, name, tenantName, tenant, username } = useAppAuthContext();
  const { navigateTo } = useNavigation(ConsoleContext);
  const { unreadCount, inProgressCount } = useNotification();

  const currentPersona: IPersonaSharedProps = {
    imageUrl: "https://www.oracle.com",
    imageInitials: name ? getInitialsFromName(name) : getInitialsFromEmail(username),
    text: name,
    secondaryText: username,
    tertiaryText: tenantName,
  };

  const navigateToHome = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    closePanels();
    navigateTo(HOME_ROUTE, PageId.HOME, undefined, true);
  };

  const getPersonaTooltip = (): string => {
    const personaName = Messages.labels.personaName(name || "");
    const personaEmail = Messages.labels.personaEmail(username || "");
    const personaDirectory = Messages.labels.personaDirectory(tenantName || "", tenant || "");
    const personaDomain = Messages.labels.personaDomain(domainName || "");
    return `${personaName}\r\n${personaEmail}\r\n${personaDirectory}\r\n${personaDomain}`;
  };

  const menuFocus = (menuId:string): void => {
    setTimeout(() => {
      const hamburgerMenu = document.getElementById(menuId) as HTMLElement;
      hamburgerMenu?.focus();
    }, 100);
  };

  const renderActionButton = (item: IOverflowSetItemProps): JSX.Element => {
    const actionButton = (
      <IconButton
        iconProps={{ iconName: item.iconProps.iconName }}
        styles={btnStyles}
        title={item.name}
        ariaLabel={item.name}
        onClick={item.onClick}
        checked={item.isChecked()}
        data-test-id={item?.testId}
      />
    );

    if (item.wrapper) {
      return passPropsToReactElement(item.wrapper, { children: actionButton });
    }
    return actionButton;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => (
    <IconButton
      title={Messages.ariaLabel.morePortalTools()}
      ariaLabel={Messages.ariaLabel.morePortalTools()}
      styles={btnStyles}
      menuIconProps={{ iconName: MonochromeIconIds.More }}
      menuProps={{ items: overflowItems || [], isBeakVisible: false, directionalHint: DirectionalHint.bottomRightEdge }}
      data-test-id={ButtonTestIds.MoreTools}
    />
  );

  const renderViewAccountLink = (props: IPersonaProps | undefined): JSX.Element => (
    // https://myaccount.microsoft.com/?ref=MeControl&login_hint=<username>
    <Link
      href={`${myAccountUrl}?ref=MeControl&login_hint=${props?.secondaryText}`}
      target="_blank"
      underline
      data-test-id={LinkTestIds.ViewAccount}
    >
      {Messages.actions.viewAccount()}
    </Link>
  );

  const hamburgerCloseButton = "hamburgerCloseButton";
  const hamburgerOpenButton = "hamburgerOpenButton";

  const personnaButtonWidth = headerLayoutType === HeaderLayoutType.NORMAL ? "220px" : "40px";

  const renderBtnMenuIcon = (
    props?: IButtonProps,
    defaultRender?: (props?: IButtonProps) => JSX.Element | null,
  ): JSX.Element | null => {
    const orgIcon = defaultRender?.(props);
    const wrappedIcon = (
      <div style={btnMenuIconStyles}>
        {orgIcon}
      </div>
    );
    return wrappedIcon;
  };

  const renderNotificationBtnMenuIcon = (
    props?: IButtonProps,
    defaultRender?: (props?: IButtonProps) => JSX.Element | null,
  ): JSX.Element | null => {
    const orgIcon = defaultRender?.(props);
    const wrappedIcon = (
      <div style={btnMenuIconStyles}>
        {orgIcon}
        {unreadCount > 0 && (
          <div
            className={classNames.notificationCount}
            role="button"
          >
            {unreadCount}
          </div>
        )}
      </div>
    );
    return wrappedIcon;
  };

  // *** itemProps & onRenderIcon are used by the context menu that replaces the action buttons when in COMPACT mode ***
  const actionItems = [
    // {
    //   key: ActionIds.CloudShell,
    //   name: Messages.topHeader.cloudShell(),
    //   iconProps: { iconName: MonochromeIconIds.CommandPrompt },
    //   itemProps: { styles: btnMenuStyles },
    //   onRenderIcon: renderBtnMenuIcon,
    //   onClick: () => onActionClick?.(ActionIds.CloudShell),
    //   isChecked: (): boolean => activeActionId === ActionIds.CloudShell,
    //   testId: ActionTestIds.CloudShell,
    // },
    // {
    //   key: ActionIds.Subscriptions,
    //   name: Messages.topHeader.directoriesSubscriptions(),
    //   iconProps: { iconName: MonochromeIconIds.PageListFilter },
    //   itemProps: { styles: btnMenuStyles },
    //   onRenderIcon: renderBtnMenuIcon,
    //   onClick: () => onActionClick?.(ActionIds.Subscriptions),
    //   isChecked: (): boolean => activeActionId === ActionIds.Subscriptions,
    //   testId: ActionTestIds.Subscriptions,
    // },
    {
      key: ActionIds.Notifications,
      name: Messages.topHeader.notifications(),
      iconProps: { iconName: MonochromeIconIds.Notifications },
      itemProps: { styles: btnMenuStyles },
      onRenderIcon: renderNotificationBtnMenuIcon,
      onClick: () => onActionClick?.(ActionIds.Notifications),
      isChecked: (): boolean => activeActionId === ActionIds.Notifications,
      wrapper: headerLayoutType === HeaderLayoutType.NORMAL
        ? (
          <NotificationActionWrapper
            unreadCount={unreadCount}
            inProgressCount={inProgressCount}
            onClick={() => onActionClick?.(ActionIds.Notifications)}
          />
        )
        : undefined,
      testId: ActionTestIds.Notifications,
    },
    {
      key: ActionIds.Settings,
      name: Messages.topHeader.settings(),
      iconProps: { iconName: MonochromeIconIds.Settings },
      itemProps: { styles: btnMenuStyles },
      onRenderIcon: renderBtnMenuIcon,
      onClick: () => onActionClick?.(ActionIds.Settings),
      isChecked: (): boolean => activeActionId === ActionIds.Settings,
      testId: ActionTestIds.Settings,
    },
    {
      key: ActionIds.Help,
      name: Messages.topHeader.help(),
      iconProps: {
        iconName: headerLayoutType === HeaderLayoutType.NORMAL
          ? SvgIconIds.helpSvg
          : MonochromeIconIds.HelpInMenu,
        styles: { root: { width: "22px", height: "22px" } },
      },
      itemProps: { styles: btnMenuStyles },
      onRenderIcon: renderBtnMenuIcon,
      onClick: () => onActionClick?.(ActionIds.Help),
      isChecked: (): boolean => activeActionId === ActionIds.Help,
      testId: ActionTestIds.Help,
    },
    {
      key: ActionIds.Feedback,
      name: Messages.topHeader.feedback(),
      iconProps: { iconName: MonochromeIconIds.Feedback },
      itemProps: { styles: btnMenuStyles },
      onRenderIcon: renderBtnMenuIcon,
      onClick: () => onActionClick?.(ActionIds.Feedback),
      isChecked: (): boolean => activeActionId === ActionIds.Feedback,
      testId: ActionTestIds.Feedback,
    },
  ];

  return (
    <header className={classNames.headerContainer} ref={ref}>
      <div className={classNames.leftContainer}>
        {(MenuStyleMode.FlyOut === menuStyleMode) && !noTopMenu
          && (
            <IconButton
              id={hamburgerCloseButton}
              iconProps={{ iconName: MonochromeIconIds.GlobalNavButton }}
              styles={btnStyles}
              title={Messages.topHeader.showPortalMenu()}
              aria-label={Messages.topHeader.showPortalMenu()}
              onClick={() => {
                openOverlay();
                menuFocus(hamburgerOpenButton);
              }}
              data-test-id={ButtonTestIds.Hamburger}
            />
          )}
        <PrimaryButton
          onClick={navigateToHome}
          text={Messages.portalBrand.long()}
          title={Messages.portalBrand.long()}
          styles={brandNameStyles}
          data-test-id={ButtonTestIds.NavigateToHome}
        />
      </div>
      <div className={classNames.rightContainer}>
        <OverflowSet
          items={headerLayoutType === HeaderLayoutType.NORMAL ? actionItems : []}
          overflowItems={headerLayoutType === HeaderLayoutType.COMPACT ? actionItems : []}
          onRenderItem={renderActionButton}
          onRenderOverflowButton={renderOverflowButton}
        />
        <FocusTrapZone disabled={!isProfileCalloutVisible} isClickableOutsideFocusTrap>
          <PrimaryButton
            id={ProfileActionBtnId}
            onClick={() => setProfileCalloutVisible(prevState => !prevState)}
            styles={{
              root: {
                width: `${personnaButtonWidth}`,
                minWidth: "auto",
                height: "40px",
                padding: "0px",
                ":focus-visible": {
                  border: "white solid 1px",
                  "::after": { outline: "none !important" },
                },
              },
            }}
            data-test-id={ButtonTestIds.Profile}
          >
            <Persona
              imageUrl={currentPersona.imageUrl}
              imageInitials={currentPersona.imageInitials}
              text={currentPersona.secondaryText}
              secondaryText={currentPersona.tertiaryText?.toUpperCase()}
              coinSize={28}
              coinProps={{ styles: { coin: { padding: "0px 6px" } } }}
              presence={PersonaPresence.online}
              hidePersonaDetails={headerLayoutType === HeaderLayoutType.COMPACT}
              showSecondaryText
              id={buttonId}
              title={getPersonaTooltip()}
              styles={{
                root: {
                  display: "flex",
                  flexDirection: "row-reverse",
                  ":focus-visible": { outline: "none !important" },
                },
                details: { padding: "0px 0px 0px 20px" },
                primaryText: {
                  textAlign: "right",
                  color: "white",
                  fontSize: "14px",
                  lineHeight: "1",
                  paddingTop: "3px",
                  ":hover": { color: "white" },
                },
                secondaryText: {
                  textAlign: "right",
                  color: "white",
                  fontSize: "10px",
                  fontWeight: "600",
                  marginTop: "-2px",
                },
              }}
            />
          </PrimaryButton>
          {isProfileCalloutVisible && (
            <Callout
              role="dialog"
              className={classNames.profileCallout}
              ariaLabelledBy={labelId}
              ariaDescribedBy={descriptionId}
              target={`#${buttonId}`}
              onDismiss={(ev?: Event | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
                if ((ev as React.PointerEvent<HTMLElement>).pointerType !== undefined
                && (ev?.target as HTMLElement).id !== ProfileActionBtnId) {
                  setProfileCalloutVisible(false);
                } else if ((ev as React.KeyboardEvent).getModifierState !== undefined) {
                  if ((ev as React.KeyboardEvent<HTMLElement>).key === "Escape") {
                    setProfileCalloutVisible(false);
                  }
                }
              }}
              setInitialFocus
              isBeakVisible={false}
              calloutMinWidth={260}
              calloutMaxWidth={320}
              styles={{
                root: {
                  backgroundColor: "#FFFFFF",
                  marginTop: "6px",
                },
              }}
              data-test-id={CalloutTestIds.PersonaCallout}
            >
              <div className={classNames.profileCalloutContainer}>
                <div className={classNames.profileTenantName}>{tenantName}</div>
                <Persona
                  imageUrl={currentPersona.imageUrl}
                  imageInitials={currentPersona.imageInitials}
                  text={currentPersona.text}
                  secondaryText={currentPersona.secondaryText}
                  onRenderTertiaryText={renderViewAccountLink}
                  presence={PersonaPresence.online}
                  hidePersonaDetails={false}
                  id={buttonId}
                  size={PersonaSize.size72}
                  coinSize={88}
                  styles={{
                    root: {
                      minHeight: "132px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      gridColumn: "1/4",
                    },
                    primaryText: {
                      fontSize: "18px",
                      color: "#333",
                      fontWeight: "700",
                    },
                    secondaryText: {
                      fontSize: "13px",
                      color: "#333",
                      marginTop: "3px",
                    },
                    tertiaryText: {
                      fontSize: "13px",
                      color: "#333",
                      marginTop: "3px",
                    },
                  }}
                />
                <BaseButton
                  styles={signOutBtnStyles}
                  title={Messages.topHeader.signOut()}
                  ariaLabel={Messages.topHeader.signOut()}
                  onClick={() => {
                    clearNavHistoryStorage();
                    logOut();
                  }}
                  data-test-id={ButtonTestIds.Logout}
                >
                  {Messages.topHeader.signOut()}
                </BaseButton>
              </div>
            </Callout>
          )}
        </FocusTrapZone>
        {!noTopMenu && (
          <div className={isOverlayOpen ? classNames.showOverlay : classNames.hideOverlay}>
            <Overlay
              styles={{ root: { backgroundColor: "rgba(255,255,255,0)", color: "initial", zIndex: 1 } }}
              className={classNames.headerMenuOverlay}
              onClick={() => {
                dismissOverlay();
                menuFocus(hamburgerCloseButton);
              }}
              data-test-id={OverlayTestIds.HeaderMenu}
            >
              <div
                style={{
                  height: `calc(100% - ${footerHeight}px)`,
                  width: "225px",
                  boxShadow: "0 6.4px 14.4px 0 rgba(0,0,0,.132),0 1.2px 3.6px 0 rgba(0,0,0,.108)",
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <IconButton
                  id={hamburgerOpenButton}
                  iconProps={{ iconName: MonochromeIconIds.GlobalNavButton }}
                  className={classNames.hamburgerOpenIcon}
                  title={Messages.topHeader.hidePortalMenu()}
                  aria-label={Messages.topHeader.hidePortalMenu()}
                  onClick={() => {
                    dismissOverlay();
                    menuFocus(hamburgerCloseButton);
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
                    if (event.shiftKey && event.key === "Tab") dismissOverlay();
                  }}
                  data-test-id={ButtonTestIds.OverlayHamburger}
                />
                {screenSizeMonitor?.menuLayoutType === MenuLayoutType.TOP_SIDE_COUPLED
                  && screenSizeMonitor.getDetailViewsMenuZoomLayout() === DetailViewsMenuZoomLayout.COUPLED_PORTAL
                  && screenSizeMonitor.getDetailViewsMenu()
                  && (
                    <>
                      <ActionButton
                        iconProps={{
                          iconName: showMenu === ShowMenu.TOP_MENU
                            ? MonochromeIconIds.ChromeBackMirrored
                            : MonochromeIconIds.ChromeBack,
                        }}
                        styles={switchMenuButtonStyles}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          e.stopPropagation();
                          setShowMenu(currentState => {
                            switch (currentState) {
                              case ShowMenu.DETAILS_MENU:
                                return ShowMenu.TOP_MENU;
                              case ShowMenu.TOP_MENU:
                              default:
                                return ShowMenu.DETAILS_MENU;
                            }
                          });
                        }}
                      >
                        {showMenu === ShowMenu.TOP_MENU
                          ? Messages.actions.serviceMenu()
                          : Messages.actions.portalMenu()}
                      </ActionButton>
                      <div
                        style={{
                          marginTop: "12px",
                          marginBottom: "8px",
                          borderBottom: "1px solid #edebe9",
                        }}
                      />
                    </>
                  )}
                {isOverlayOpen && (
                  <GlobalMenu
                    showMenu={showMenu}
                    searchText={screenSizeMonitor?.getSearchText()}
                    detailViewsMenu={
                      screenSizeMonitor?.getDetailViewsMenuZoomLayout() === DetailViewsMenuZoomLayout.COUPLED_PORTAL
                      && screenSizeMonitor?.getDetailViewsMenu()
                    }
                    dismissOverlay={dismissOverlay}
                  />
                )}
              </div>
            </Overlay>
          </div>
        )}
      </div>
    </header>
  );
};

interface GlobalMenuProps {
  showMenu: ShowMenu;
  searchText?: string;
  detailViewsMenu?: React.ReactNode;
  dismissOverlay: () => void;
}
const GlobalMenu = ({ showMenu, searchText, detailViewsMenu, dismissOverlay }: GlobalMenuProps): JSX.Element => {
  let menuComponent;
  switch (showMenu) {
    case ShowMenu.DETAILS_MENU:
      if (detailViewsMenu) {
        menuComponent = React.cloneElement(detailViewsMenu as JSX.Element, { searchText });
      } else {
        menuComponent = <TopMenu menuStyleMode={MenuStyleMode.FlyOut} />;
      }
      break;
    case ShowMenu.TOP_MENU:
    default:
      menuComponent = <TopMenu menuStyleMode={MenuStyleMode.FlyOut} />;
      break;
  }
  return (
    <FocusZone
      style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}
      onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
        if (!event.shiftKey && event.key === "Tab") dismissOverlay();
      }}
    >
      {menuComponent}
    </FocusZone>
  );
};
