import { optimizedRetryOption, useNavigation } from "o4a-react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Spinner } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { buildId, parseId } from "../../helpers/idHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { VmDbDetailsPage } from "./VmDbDetailsPage";

const pdbRegistrationIds = PageRegistrationConfig[PageId.VMDB_PDB_DETAILS].map(config => config.key);

export const VmDbPdbDetailsPage = (): JSX.Element => {
  const
    {
      subscriptionId,
      resourceGroup: resGroupFromUrl,
      provider,
      resourceType,
      resourceName: resourceNameFromUrl,
      panelId,
    } = useParams();

  const [searchParams] = useSearchParams();
  const { navigateToSelf } = useNavigation(ConsoleContext);

  const resGroup = decodeURIComponent(resGroupFromUrl || "");
  const resourceName = decodeURIComponent(resourceNameFromUrl || "");
  const location = searchParams.get("location") || "";

  const { response: responsePdb, loading: loadingPdb, refresh: refreshPdb, error: errorPdb } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.getVmdbPluggableDatabase,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        pluggableDatabaseName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadPluggableDatabase(),
      },
      excludeErrorStatus: [404],
    },
  });

  const instance = responsePdb?.data;
  const containerIdComps = parseId(instance?.containerDatabaseId || "");

  const { response: responseCdb, refresh: refreshCdb } = useQueryCall({
    wait: !instance?.containerDatabaseId,
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.getVmdbDatabase,
    options: {
      args: {
        subscriptionId: containerIdComps.subscriptionId,
        resourceGroupName: containerIdComps.resourceGroup,
        databaseName: containerIdComps.resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadContainerDatabase(),
      },
    },
  });

  const vmDbId = buildId({
    subscriptionId: subscriptionId || "",
    resourceGroup: resGroup,
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName,
  });

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.VMDB_PDB_DETAILS].find(
      config => config.panelPath === panelId,
    );
    const urlQueryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const path = `${RESOURCE_ROUTE}/${vmDbId}/${panelId}${urlQueryParams}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerInstance = responseCdb?.data;
  const renderContent = (loadingPdb && !instance)
    ? <Spinner label={Messages.common.loading()} />
    : (
      <VmDbDetailsPage
        dbInstance={instance}
        container={{ instance: containerInstance, refresh: refreshCdb }}
        dbRefresh={refreshPdb}
        dbError={errorPdb}
        isCdb={false}
        dbRegistrationIds={pdbRegistrationIds}
        panelId={panelId}
        location={location}
        resourceId={vmDbId}
      />
    );

  return renderContent;
};
