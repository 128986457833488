import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent, SelectOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  Fields as NetworkLinkFields,
  NetworkLinkCreateSidePanel,
} from "../../components/NetworkLinkCreateSidePanel/NetworkLinkCreateSidePanel";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  NETLNK_CREATE_POLL_DELAY,
  NETLNK_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface NetworkLinkAttachProps extends OperationProps {
  mcvcnId: string;
}

const NetworkLinkAttach = (
  { location, mcvcnId, onExecute, onCancel }: NetworkLinkAttachProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup } = parseId(mcvcnId);
  const resGroup = decodeURIComponent(resourceGroup || "");

  const { invokeCall: invokeCreate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).netLinkApi.createNetworkLink },
  );
  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const onCreateNetworkLink = (formValues: FormValues): void => {
    const netLinkName = getValue<string>(formValues, NetworkLinkFields.Name, InputFormGroup) || "";
    const vnet = getValue<SelectOption[]>(formValues, NetworkLinkFields.Vnet, InputFormGroup)?.[0].id;
    const azureAttachedNetworkIds = vnet ? [vnet] : [];
    const multiCloudVirtualNetworkIds = [mcvcnId];
    const customerNvaIpAddress = getValue<string>(
      formValues,
      NetworkLinkFields.NetworkVirtualAppliance,
      InputFormGroup,
    );

    const virtualNetworkName = parseId(azureAttachedNetworkIds?.[0]).resourceName;

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.attachVirtualNetwork(),
          message: Messages.notifications.inProgress.messages.attachVirtualNetwork(virtualNetworkName),
        },
        success: {
          title: Messages.notifications.success.titles.attachVirtualNetwork(),
          message: Messages.notifications.success.messages.attachVirtualNetwork(virtualNetworkName),
        },
        failure: {
          title: Messages.notifications.failure.titles.attach(),
          message: Messages.notifications.failure.messages.attachVirtualNetwork(virtualNetworkName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.NETLNK_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            networkLinkName: netLinkName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.NETLNK_CREATED_CHECK,
          delay: NETLNK_CREATE_POLL_DELAY,
          interval: NETLNK_CREATE_POLL_INTERVAL,
        },
      },
    };
    invokeCreate({
      subscriptionId,
      resourceGroupName: resGroup,
      createNetworkLinkDetails: {
        name: netLinkName,
        azureAttachedNetworkIds,
        multiCloudVirtualNetworkIds,
        routingOptions: { customerNvaIpAddress },

      },
    }, invokeOptions);
  };

  return (
    <NetworkLinkCreateSidePanel
      componentRef={setSidePanelRef}
      onSubmit={onCreateNetworkLink}
      onClose={onCancel}
      location={location}
      subscriptionId={subscriptionId}
    />
  );
};

export const newNetworkLinkAttach = (
  props: NetworkLinkAttachProps,
): JSX.Element => (<NetworkLinkAttach {...props} />);
