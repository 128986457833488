import React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall, MutationCallOptions } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MCVCN_DELETE_POLL_DELAY, MCVCN_DELETE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface McvcnDeleteProps extends OperationConfirmationProps {
  mcvcnId: string | undefined;
}

const McvcnDelete = ({ mcvcnId, location, onExecute }: McvcnDeleteProps): JSX.Element => {
  const { invokeCall: invokeMcVcnDelete } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).networkApi.deleteMultiCloudVirtualNetwork },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(mcvcnId);
    const multiCloudVirtualNetworkName = decodeURIComponent(resourceName || "");
    const resourceGroupName = decodeURIComponent(resourceGroup || "");

    const invokeOptions: MutationCallOptions<Response> = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.deleteMcVcn(),
          message: Messages.notifications.inProgress.messages.deleteMcVcn(multiCloudVirtualNetworkName),
        },
        success: {
          title: Messages.notifications.success.titles.deleteMcVcn(),
          message: Messages.notifications.success.messages.deleteMcVcn(multiCloudVirtualNetworkName),
        },
        failure: {
          title: Messages.notifications.failure.titles.delete(),
          message: Messages.notifications.failure.messages.deleteMcVcn(multiCloudVirtualNetworkName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MCVCN_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            multiCloudVirtualNetworkName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MCVCN_DELETED_CHECK,
          errorStatusOverrides: [404],
          delay: MCVCN_DELETE_POLL_DELAY,
          interval: MCVCN_DELETE_POLL_INTERVAL,

        },
      },
    };
    invokeMcVcnDelete({
      subscriptionId,
      resourceGroupName,
      multiCloudVirtualNetworkName,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export const newMcvcnDelete = (props: McvcnDeleteProps): JSX.Element => (
  <McvcnDelete {...props} />
);
