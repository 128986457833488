import {
  AnchoredPanelType,
  FormInputGroupLayout,
  FormValues,
  getValue,
  InputFormGroup,
  InputFormSidePanel,
  InputFormSidePanelComponent,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { NodeCountEstimator } from "../../components/NodeCountEstimator/NodeCountEstimator";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_HEATWAVE_UPDATE_POLL_DELAY,
  MDS_HEATWAVE_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export enum Fields {
  NodeCount = "nodeCount",
}

export enum FieldTestIds {
  NodeCount = "nodeCount",
}

export interface NodeConfig {
  clusterSize: number | undefined;
  shapeName: string | undefined;
}

export interface MysqlEditNodeCountProps extends OperationProps {
  databaseId: string | undefined;
  nodeConfig: NodeConfig;
}

const MysqlEditNodeCount = (
  { location, databaseId, nodeConfig, onExecute, onCancel }: MysqlEditNodeCountProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resourceGroupName = decodeURIComponent(resourceGroup || "");
  const dbSystemName = decodeURIComponent(resourceName || "");

  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (nodeConfig) {
      const initFieldValues: FormValues = {
      // eslint-disable-next-line eqeqeq
        [Fields.NodeCount]: nodeConfig.clusterSize != undefined ? Number(nodeConfig.clusterSize).toString() : undefined,
      };
      setInitialValues(initFieldValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeConfig]);

  const { invokeCall: invokeHeatWaveUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.updateHeatWaveCluster },
  );

  const onUpdate = (formValues: FormValues): void => {
    const nodeCountValue = getValue<string>(formValues, Fields.NodeCount, InputFormGroup);
    const clusterSize = nodeCountValue ? parseInt(nodeCountValue, 10) : undefined;

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateHeatWaveNodeCount(),
          message: Messages.notifications.inProgress.messages.updateHeatWaveNodeCount(dbSystemName),
        },
        success: {
          title: Messages.notifications.success.titles.updateHeatWaveNodeCount(),
          message: Messages.notifications.success.messages.updateHeatWaveNodeCount(dbSystemName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateHeatWaveNodeCount(),
          message: Messages.notifications.failure.messages.updateHeatWaveNodeCount(dbSystemName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_HEATWAVE_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            dbSystemName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_HEATWAVE_UPDATED_CHECK,
          delay: MDS_HEATWAVE_UPDATE_POLL_DELAY,
          interval: MDS_HEATWAVE_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeHeatWaveUpdate({
      subscriptionId,
      resourceGroupName,
      dbSystemName,
      updateHeatWaveClusterDetails: {
        shapeName: nodeConfig.shapeName,
        clusterSize,
      },
    }, invokeOptions);
  };

  return (
    <InputFormSidePanel
      componentRef={setSidePanelRef}
      type={AnchoredPanelType.LARGE}
      title={Messages.createNewPanels.nodeCountEstimator.title()}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onUpdate}
      onClose={onCancel}
      layout={FormInputGroupLayout.COMPACT}
      testId={SidePanelTestIds.MysqlEditNodeCount}
      initialValues={initialValues}
    >
      <NodeCountEstimator
        testId={FieldTestIds.NodeCount}
        fieldName={Fields.NodeCount}
        subscriptionId={subscriptionId}
        resourceGroupName={resourceGroup}
        dbSystemName={dbSystemName}
        location={location}
        nodeCountDefaultValue={nodeConfig.clusterSize}
      />
    </InputFormSidePanel>
  );
};

export const newMysqlEditNodeCount = (
  props: MysqlEditNodeCountProps,
): JSX.Element => (<MysqlEditNodeCount {...props} />);
