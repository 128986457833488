import React from "react";
import { OperationBaseProps, OperationManager, OperationsContext } from "../console/OperationsContext";

export interface UseOperationHandles<T extends OperationBaseProps> {
  trigger: (props: T) => void;
}

export function useOperation<T extends OperationBaseProps>(
  operationFactory: (props: T) => JSX.Element,
): UseOperationHandles<T> {
  const {
    registerOperationFactory,
    unregisterOperationFactory,
    triggerOperation,
  } = React.useContext<OperationManager>(OperationsContext);

  const registrationKey = React.useRef<string>();

  React.useEffect(() => {
    registrationKey.current = registerOperationFactory(operationFactory);
    return () => {
      unregisterOperationFactory(registrationKey.current || "");
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    trigger: (props: T) => {
      if (registrationKey.current) {
        triggerOperation(registrationKey.current, props);
      }
    },
  };
}
