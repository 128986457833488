import { savantStore as azureSavantStore } from "../session/azureSession";

export enum UserType {
  FIRST_TIME_USER = "FIRST_TIME_USER",
  NEW_USER = "NEW_USER",
  REGULAR_USER = "REGULAR_USER"
}

export interface AppAuthContext {
  tenant: string;
  name: string;
  username: string;
  tenantName: string;
  domainName?: string;
  roles: string[];
  userType: UserType;
}

export const useAppAuthContext = (): AppAuthContext => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userData: any = azureSavantStore.get("userData");
  return {
    name: userData?.name,
    username: userData?.username,
    tenant: userData?.tenant,
    tenantName: userData?.tenantName,
    roles: userData?.roles,
    domainName: userData?.domainName,
    userType: userData?.userType || UserType.REGULAR_USER,
  };
};
