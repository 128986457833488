/* tslint:disable */

/**
 * MchubAzureProxy API
 * A description of the MchubAzureProxy API
 *
 * OpenAPI spec version: 20220401
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as base from "./lib/base-api";

/**
 * The type of activity occurring on the support ticket.
 */
export type ActivityType = "NOTES" | "PROBLEM_DESCRIPTION" | "UPDATE" | "CLOSE" | "REOPEN";
export enum ActivityTypeValues {
    NOTES = "NOTES",
    PROBLEM_DESCRIPTION = "PROBLEM_DESCRIPTION",
    UPDATE = "UPDATE",
    CLOSE = "CLOSE",
    REOPEN = "REOPEN"
}

/**
 * Oracle APEX Application Development is a low-code development platform that enables you to build scalable, secure enterprise apps, with world-class features. ADB-S Database with the APEX workload type is optimized to support APEX development. 
 */
export interface AdbDatabaseApex {
    /**
     * The Oracle APEX Application Development version.
     */
    "apexVersion"?: string;
    /**
     * The Oracle REST Data Services (ORDS) version.
     */
    "ordsVersion"?: string;
}

/**
 * The connection string profile to allow clients to group, filter and select connection string values based on structured metadata. 
 */
export interface AdbDatabaseConnectionStringProfile {
    /**
     * A user-friendly name for the connection.
     */
    "displayName": string;
    /**
     * Connection string value.
     */
    "value": string;
    /**
     * Consumer group used by the connection.
     */
    "consumerGroup"?: AdbDatabaseConnectionStringProfileConsumerGroupEnum;
    /**
     * Protocol used by the connection.
     */
    "protocol": AdbDatabaseConnectionStringProfileProtocolEnum;
    /**
     * Specifies whether the TLS handshake is using one-way (`SERVER`) or mutual (`MUTUAL`) authentication.
     */
    "tlsAuthentication"?: AdbDatabaseConnectionStringProfileTlsAuthenticationEnum;
    /**
     * Host format used in connection string.
     */
    "hostFormat": AdbDatabaseConnectionStringProfileHostFormatEnum;
    /**
     * Specifies whether the listener performs a direct hand-off of the session, or redirects the session. In RAC deployments where SCAN is used, sessions are redirected to a Node VIP. Use `DIRECT` for direct hand-offs. Use `REDIRECT` to redirect the session.
     */
    "sessionMode": AdbDatabaseConnectionStringProfileSessionModeEnum;
}

export type AdbDatabaseConnectionStringProfileConsumerGroupEnum = "HIGH" | "MEDIUM" | "LOW" | "TP" | "TPURGENT";
export type AdbDatabaseConnectionStringProfileProtocolEnum = "TCP" | "TCPS";
export type AdbDatabaseConnectionStringProfileTlsAuthenticationEnum = "SERVER" | "MUTUAL";
export type AdbDatabaseConnectionStringProfileHostFormatEnum = "FQDN" | "IP";
export type AdbDatabaseConnectionStringProfileSessionModeEnum = "DIRECT" | "REDIRECT";
/**
 * Connection strings to connect to an Oracle ADB-S Database. 
 */
export interface AdbDatabaseConnectionStrings {
    /**
     * A list of connection string profiles to allow clients to group, filter and select connection string values based on structured metadata. 
     */
    "profiles"?: Array<AdbDatabaseConnectionStringProfile>;
}

/**
 * The URLs for accessing Oracle Application Express (APEX) and SQL Developer Web with a browser from a Compute instance within your VCN or that has a direct connection to your VCN. 
 */
export interface AdbDatabaseConnectionUrls {
    /**
     * Oracle SQL Developer Web URL.
     */
    "sqlDevWebUrl"?: string;
    /**
     * Oracle Application Express (APEX) URL.
     */
    "apexUrl"?: string;
    /**
     * Oracle Machine Learning user management URL.
     */
    "machineLearningUserManagementUrl"?: string;
    /**
     * The URL of the Graph Studio for the ADB-S Database.
     */
    "graphStudioUrl"?: string;
}

/**
 * A collection of Autonomous Database Version resources.
 */
export interface AdbDbVersionCollection {
    /**
     * An array of AdbDbVersionSummary resources.
     */
    "items": Array<AdbDbVersionSummary>;
}

/**
 * Summary of the supported Autonomous Database version.
 */
export interface AdbDbVersionSummary {
    /**
     * A URL that points to a detailed description of the Autonomous Database version..
     */
    "details"?: string;
    /**
     * A valid Oracle Database version for Autonomous Database.
     */
    "version": string;
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: AdbDbVersionSummaryDbWorkloadEnum;
}

export type AdbDbVersionSummaryDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
/**
 * Details of scheduled operation. 
 */
export interface AdbScheduledOperationDetails {
    "dayOfWeek": DayOfWeek;
    /**
     * auto start time. value must be of ISO-8601 format \"HH:mm\"
     */
    "scheduledStartTime"?: string;
    /**
     * auto stop time. value must be of ISO-8601 format \"HH:mm\"
     */
    "scheduledStopTime"?: string;
}

/**
 * A collection of AdbdContainerDatabase resources
 */
export interface AdbdContainerDatabaseCollection {
    /**
     * An array of AdbdContainerDatabaseSummary resources.
     */
    "items": Array<AdbdContainerDatabaseSummary>;
}

/**
 * Details for the Autonomous Container Database deployment. 
 */
export interface AdbdContainerDatabaseDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": AdbdContainerDatabaseDeploymentDetailsKindEnum;
}

export type AdbdContainerDatabaseDeploymentDetailsKindEnum = "CREATE" | "GET";
/**
 * An Autonomous Container Database is a container database service that enables the customer to host one or more databases within the container database. A basic container database runs on a single Autonomous Exadata Infrastructure from an availability domain without the Extreme Availability features enabled. 
 */
export interface AdbdContainerDatabaseSummary {
    /**
     * The fully qualified Azure resource id.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid": string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": AdbdContainerDatabaseSummaryStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: AdbdContainerDatabaseSummaryLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The name for the AdbdContainerDatabase. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * DB unique name.
     */
    "dbUniqueName"?: string;
    /**
     * The Azure ID of the cloud Autonomous Exadata VM Cluster.
     */
    "cloudAutonomousVmClusterId"?: string;
    /**
     * The date and time the Autonomous Container Database was created.
     */
    "timeCreated"?: Date;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Oracle Database version of the Autonomous Container Database.
     */
    "dbVersion"?: string;
    /**
     * The amount of memory (in GBs) enabled per each OCPU core in Autonomous VM Cluster.
     */
    "memoryPerOracleComputeUnitInGBs"?: number;
    /**
     * Sum of OCPUs available on the Autonomous VM Cluster + Sum of fractional OCPUs available in the Autonomous Container Database.
     */
    "availableCpus"?: number;
    /**
     * The number of CPU cores allocated to the Autonomous VM cluster.
     */
    "totalCpus"?: number;
    /**
     * CPU cores that continue to be included in the count of OCPUs available to the Autonomous Container Database even after one of its Autonomous Database is terminated or scaled down. You can release them to the available OCPUs at its parent AVMC level by restarting the Autonomous Container Database.
     */
    "reclaimableCpus"?: number;
    /**
     * An array of CPU values that can be used to successfully provision a single Autonomous Database.
     */
    "provisionableCpus"?: Array<number>;
}

export type AdbdContainerDatabaseSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type AdbdContainerDatabaseSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * A collection of ADB-D Database resources
 */
export interface AdbdDatabaseCollection {
    /**
     * An array of AdbdDatabaseSummary resources.
     */
    "items": Array<AdbdDatabaseSummary>;
}

/**
 * Details for the Oracle Autonomous Database deployment. 
 */
export interface AdbdDatabaseDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": AdbdDatabaseDeploymentDetailsKindEnum;
}

export type AdbdDatabaseDeploymentDetailsKindEnum = "CREATE";
/**
 * An Oracle Autonomous Database. 
 */
export interface AdbdDatabaseSummary {
    /**
     * The fully qualified Azure resource id.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid": string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": AdbdDatabaseSummaryStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: AdbdDatabaseSummaryLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The name of the database. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The database name.
     */
    "dbName"?: string;
    /**
     * The character set for the autonomous database.  The default is AL32UTF8. Allowed values are:  AL32UTF8, AR8ADOS710, AR8ADOS720, AR8APTEC715, AR8ARABICMACS, AR8ASMO8X, AR8ISO8859P6, AR8MSWIN1256, AR8MUSSAD768, AR8NAFITHA711, AR8NAFITHA721, AR8SAKHR706, AR8SAKHR707, AZ8ISO8859P9E, BG8MSWIN, BG8PC437S, BLT8CP921, BLT8ISO8859P13, BLT8MSWIN1257, BLT8PC775, BN8BSCII, CDN8PC863, CEL8ISO8859P14, CL8ISO8859P5, CL8ISOIR111, CL8KOI8R, CL8KOI8U, CL8MACCYRILLICS, CL8MSWIN1251, EE8ISO8859P2, EE8MACCES, EE8MACCROATIANS, EE8MSWIN1250, EE8PC852, EL8DEC, EL8ISO8859P7, EL8MACGREEKS, EL8MSWIN1253, EL8PC437S, EL8PC851, EL8PC869, ET8MSWIN923, HU8ABMOD, HU8CWI2, IN8ISCII, IS8PC861, IW8ISO8859P8, IW8MACHEBREWS, IW8MSWIN1255, IW8PC1507, JA16EUC, JA16EUCTILDE, JA16SJIS, JA16SJISTILDE, JA16VMS, KO16KSC5601, KO16KSCCS, KO16MSWIN949, LA8ISO6937, LA8PASSPORT, LT8MSWIN921, LT8PC772, LT8PC774, LV8PC1117, LV8PC8LR, LV8RST104090, N8PC865, NE8ISO8859P10, NEE8ISO8859P4, RU8BESTA, RU8PC855, RU8PC866, SE8ISO8859P3, TH8MACTHAIS, TH8TISASCII, TR8DEC, TR8MACTURKISHS, TR8MSWIN1254, TR8PC857, US7ASCII, US8PC437, UTF8, VN8MSWIN1258, VN8VN3, WE8DEC, WE8DG, WE8ISO8859P1, WE8ISO8859P15, WE8ISO8859P9, WE8MACROMAN8S, WE8MSWIN1252, WE8NCR4970, WE8NEXTSTEP, WE8PC850, WE8PC858, WE8PC860, WE8ROMAN8, ZHS16CGB231280, ZHS16GBK, ZHT16BIG5, ZHT16CCDC, ZHT16DBT, ZHT16HKSCS, ZHT16MSWIN950, ZHT32EUC, ZHT32SOPS, ZHT32TRIS 
     */
    "characterSet"?: string;
    /**
     * The national character set for the autonomous database.  The default is AL16UTF16. Allowed values are: AL16UTF16 or UTF8. 
     */
    "ncharacterSet"?: string;
    /**
     * The number of OCPU cores to be made available to the database. For Autonomous Databases on dedicated Exadata infrastructure, the maximum number of cores is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `ocpuCount` parameter. 
     */
    "cpuCoreCount"?: number;
    /**
     * The number of OCPU cores to be made available to the database.  The following points apply: - For Autonomous Databases on dedicated Exadata infrastructure, to provision less than 1 core, enter a fractional value in an increment of 0.1. For example, you can provision 0.3 or 0.4 cores, but not 0.35 cores. (Note that fractional OCPU values are not supported for Autonomous Databasese on shared Exadata infrastructure.) - To provision 1 or more cores, you must enter an integer between 1 and the maximum number of cores available for the infrastructure shape. For example, you can provision 2 cores or 3 cores, but not 2.5 cores. This applies to Autonomous Databases on both shared and dedicated Exadata infrastructure.  For Autonomous Databases on dedicated Exadata infrastructure, the maximum number of cores is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `cpuCoreCount` parameter. 
     */
    "ocpuCount"?: number;
    /**
     * An array of CPU values that an Autonomous Database can be scaled to.
     */
    "provisionableCpus"?: Array<number>;
    /**
     * The quantity of data in the database, in terabytes.
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * The amount of memory (in GBs) enabled per each OCPU core in Autonomous VM Cluster.
     */
    "memoryPerOracleComputeUnitInGBs"?: number;
    /**
     * The quantity of data in the database, in gigabytes.
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The infrastructure type this resource belongs to.
     */
    "infrastructureType"?: AdbdDatabaseSummaryInfrastructureTypeEnum;
    /**
     * The Autonomous Container Database Azure ID.
     */
    "autonomousContainerDatabaseId"?: string;
    /**
     * The date and time the Autonomous Database was created.
     */
    "timeCreated"?: Date;
    /**
     * The user-friendly name for the Autonomous Database. The name does not have to be unique.
     */
    "displayName"?: string;
    /**
     * The URL of the Service Console for the Autonomous Database.
     */
    "serviceConsoleUrl"?: string;
    "connectionStrings"?: AdbDatabaseConnectionStrings;
    "connectionUrls"?: AdbDatabaseConnectionUrls;
    /**
     * The amount of storage that has been used, in terabytes.
     */
    "usedDataStorageSizeInTBs"?: number;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * A valid Oracle Database version for Autonomous Database.
     */
    "dbVersion"?: string;
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: AdbdDatabaseSummaryDbWorkloadEnum;
    /**
     * The SQL Web Developer URL for the Oracle Autonomous Database. The sqlWebDeveloperUrl is only returned if isSqlWebDeveloperEnabled boolean is set to TRUE.
     */
    "sqlWebDeveloperUrl"?: string;
    "apexDetails"?: AdbDatabaseApex;
    /**
     * Indicates if auto scaling is enabled for the Autonomous Database CPU core count. 
     */
    "isAutoScalingEnabled"?: boolean;
    /**
     * Status of the Data Safe registration for this Autonomous Database.
     */
    "dataSafeStatus"?: AdbdDatabaseSummaryDataSafeStatusEnum;
    /**
     * The `DATABASE OPEN` mode. You can open the database in `READ_ONLY` or `READ_WRITE` mode.
     */
    "openMode"?: AdbdDatabaseSummaryOpenModeEnum;
    /**
     * List of Oracle Database versions available for a database upgrade. If there are no version upgrades available, this list is empty.
     */
    "availableUpgradeVersions"?: Array<string>;
    /**
     * Customer Contacts.
     */
    "customerContacts"?: Array<CustomerContact>;
    /**
     * list of scheduled operations
     */
    "scheduledOperations"?: Array<AdbScheduledOperationDetails>;
    /**
     * The amount of storage currently allocated for the database tables and billed for, rounded up. When auto-scaling is not enabled, this value is equal to the `dataStorageSizeInTBs` value. You can compare this value to the `actualUsedDataStorageSizeInTBs` value to determine if a manual shrink operation is appropriate for your allocated storage.  **Note:** Auto-scaling does not automatically decrease allocated storage when data is deleted from the database. 
     */
    "allocatedStorageSizeInTBs"?: number;
    /**
     * The current amount of storage in use for user and system data, in terabytes (TB). 
     */
    "actualUsedDataStorageSizeInTBs"?: number;
    /**
     * The number of Max OCPU cores to be made available to the autonomous database with auto scaling of cpu enabled. 
     */
    "maxCpuCoreCount"?: number;
    /**
     * The Oracle Database Edition that applies to the Autonomous databases. 
     */
    "databaseEdition"?: AdbdDatabaseSummaryDatabaseEditionEnum;
}

export type AdbdDatabaseSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type AdbdDatabaseSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
export type AdbdDatabaseSummaryInfrastructureTypeEnum = "CLOUD" | "CLOUD_AT_CUSTOMER";
export type AdbdDatabaseSummaryDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
export type AdbdDatabaseSummaryDataSafeStatusEnum = "REGISTERING" | "REGISTERED" | "DEREGISTERING" | "NOT_REGISTERED" | "FAILED";
export type AdbdDatabaseSummaryOpenModeEnum = "READ_ONLY" | "READ_WRITE";
export type AdbdDatabaseSummaryDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION";
/**
 * Wallet details for a specified Autonomous Database. 
 */
export interface AdbdDatabaseWallet {
    /**
     * The current lifecycle state of the Autonomous Database wallet.
     */
    "lifecycleState": AdbdDatabaseWalletLifecycleStateEnum;
    /**
     * The date and time the Autonomous Database wallet was downloaded.
     */
    "timeDownloaded"?: Date;
    /**
     * The date and time the wallet was last rotated.
     */
    "timeRotated"?: Date;
}

export type AdbdDatabaseWalletLifecycleStateEnum = "ACTIVE" | "UPDATING";
/**
 * A collection of AdbdVmCluster resources
 */
export interface AdbdVmClusterCollection {
    /**
     * An array of AdbdVmClusterSummary resources.
     */
    "items": Array<AdbdVmClusterSummary>;
}

/**
 * Details for the cloud Autonomous VM cluster deployment. 
 */
export interface AdbdVmClusterDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": AdbdVmClusterDeploymentDetailsKindEnum;
}

export type AdbdVmClusterDeploymentDetailsKindEnum = "CREATE" | "GET";
/**
 * Details of the cloud Autonomous VM cluster. 
 */
export interface AdbdVmClusterSummary {
    /**
     * The fully qualified Azure resource id.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": AdbdVmClusterSummaryStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: AdbdVmClusterSummaryLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The name of the VmCluster. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * User defined description of the cloud Autonomous VM cluster.
     */
    "description"?: string;
    /**
     * The date and time that the cloud Autonomous VM cluster was created.
     */
    "timeCreated"?: Date;
    /**
     * The last date and time that the cloud Autonomous VM cluster was updated.
     */
    "timeUpdated"?: Date;
    /**
     * The hostname for the cloud Autonomous VM cluster.
     */
    "hostname"?: string;
    /**
     * The domain name for the cloud Autonomous VM cluster.
     */
    "domain"?: string;
    /**
     * Azure Id of the cloud Exadata infrastructure.
     */
    "cloudExadataInfrastructureId": string;
    /**
     * The Azure unique identifier of the NetworkLink.
     */
    "networkLinkId": string;
    /**
     * The number of database servers in the cloud VM cluster. 
     */
    "nodeCount"?: number;
    /**
     * The total data storage allocated, in terabytes (TB).
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * The total data storage allocated, in gigabytes (GB).
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The number of CPU cores enabled on the cloud Autonomous VM cluster.
     */
    "cpuCoreCount"?: number;
    /**
     * The number of CPU cores enabled on the cloud Autonomous VM cluster. Only 1 decimal place is allowed for the fractional part.
     */
    "ocpuCount"?: number;
    /**
     * The number of OCPU cores enabled per VM cluster node.
     */
    "cpuCoreCountPerNode"?: number;
    /**
     * The memory allocated in GBs.
     */
    "memorySizeInGBs"?: number;
    /**
     * The Oracle license model that applies to the Oracle Autonomous Database. Bring your own license (BYOL) allows you to apply your current on-premises Oracle software licenses to equivalent, highly automated Oracle PaaS and IaaS services in the cloud. License Included allows you to subscribe to new Oracle Database software licenses and the Database service. Note that when provisioning an Autonomous Database on [dedicated Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html), this attribute must be null because the attribute is already set at the Autonomous Exadata Infrastructure level. When using [shared Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html), if a value is not specified, the system will supply the value of `BRING_YOUR_OWN_LICENSE`. 
     */
    "licenseModel"?: AdbdVmClusterSummaryLicenseModelEnum;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * CPU cores available for allocation to Autonomous Databases.
     */
    "availableCpus"?: number;
    /**
     * CPU cores that continue to be included in the count of OCPUs available to the Autonomous Container Database even after one of its Autonomous Database is terminated or scaled down. You can release them to the available OCPUs at its parent AVMC level by restarting the Autonomous Container Database.
     */
    "reclaimableCpus"?: number;
    /**
     * The number of Autonomous Container Databases that can be created with the currently available local storage.
     */
    "availableContainerDatabases"?: number;
    /**
     * The total number of Autonomous Container Databases that can be created with the allocated local storage.
     */
    "totalContainerDatabases"?: number;
    /**
     * The data disk group size available for Autonomous Databases, in TBs.
     */
    "availableAutonomousDataStorageSizeInTBs"?: number;
    /**
     * The data disk group size allocated for Autonomous Databases, in TBs.
     */
    "autonomousDataStorageSizeInTBs"?: number;
    /**
     * The local node storage allocated in GBs.
     */
    "dbNodeStorageSizeInGBs"?: number;
    /**
     * The amount of memory (in GBs) enabled per each OCPU core.
     */
    "memoryPerOracleComputeUnitInGBs"?: number;
}

export type AdbdVmClusterSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type AdbdVmClusterSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
export type AdbdVmClusterSummaryLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * A collection of Autonomous Database character set resources.
 */
export interface AdbsCharacterSetCollection {
    /**
     * An array of AdbsCharacterSetSummary resources.
     */
    "items": Array<AdbsCharacterSetSummary>;
}

/**
 * Summary of the supported Autonomous Database character sets.
 */
export interface AdbsCharacterSetSummary {
    /**
     * A valid Oracle Database character set for Autonomous Database.
     */
    "name": string;
}

/**
 * An collection of AutonomousDatabaseBackupSummary resources.
 */
export interface AdbsDatabaseBackupCollection {
    /**
     * An array of AdbDbVersionSummary resources.
     */
    "items": Array<AdbsDatabaseBackupSummary>;
}

/**
 * Gets information about the specified Autonomous Database backup. 
 */
export interface AdbsDatabaseBackupSummary {
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The fully qualified Azure resource ID.
     */
    "id": string;
    /**
     * The Azure Resource Id of the database.
     */
    "databaseId": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: AdbsDatabaseBackupSummaryStatusEnum;
    /**
     * The size of the database in terabytes at the time the backup was taken.
     */
    "databaseSizeInTBs"?: number;
    /**
     * The user-friendly name for the backup. The name does not have to be unique.
     */
    "name": string;
    /**
     * Indicates whether the backup is user-initiated or automatic.
     */
    "isAutomatic": boolean;
    /**
     * Indicates whether the backup can be used to restore the associated Autonomous Database.
     */
    "isRestorable"?: boolean;
    /**
     * The date and time the backup completed.
     */
    "timeEnded"?: Date;
    /**
     * The date and time the backup started.
     */
    "timeStarted"?: Date;
    /**
     * The type of backup.
     */
    "type": AdbsDatabaseBackupSummaryTypeEnum;
}

export type AdbsDatabaseBackupSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type AdbsDatabaseBackupSummaryTypeEnum = "INCREMENTAL" | "FULL";
/**
 * An ADB-S Database.
 */
export interface AdbsDatabaseBase {
    /**
     * The specific cloud provider. 
     */
    "cloudProvider": AdbsDatabaseBaseCloudProviderEnum;
    /**
     * The fully qualified cloud resource id.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid": string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The name for the AdbsDatabase. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": AdbsDatabaseBaseStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: AdbsDatabaseBaseLastOperationStatusEnum;
    /**
     * Detailed message for the status
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The database name.
     */
    "dbName": string;
    /**
     * A valid Oracle Database version for Autonomous Database.
     */
    "dbVersion"?: string;
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: AdbsDatabaseBaseDbWorkloadEnum;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The number of OCPU cores to be made available to the database. 
     */
    "cpuCoreCount"?: number;
    /**
     * The number of OCPU cores to be made available to the database. 
     */
    "ocpuCount"?: number;
    /**
     * The quantity of data in the database, in terabytes.
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * The amount of memory (in GBs) enabled per each OCPU core in the ADB-S VM Cluster.
     */
    "memoryPerOracleComputeUnitInGBs"?: number;
    /**
     * The date and time the ADB-S Database was created.
     */
    "timeCreated"?: Date;
    "connectionStrings"?: AdbDatabaseConnectionStrings;
    "connectionUrls"?: AdbDatabaseConnectionUrls;
    /**
     * The amount of storage that has been used, in terabytes.
     */
    "usedDataStorageSizeInTBs"?: number;
    /**
     * The client IP access control list (ACL).
     */
    "whitelistedIps"?: Array<string>;
    "apexDetails"?: AdbDatabaseApex;
    /**
     * Indicates if auto scaling is enabled for the ADB-S Database CPU core count. 
     */
    "isAutoScalingEnabled"?: boolean;
    /**
     * Indicates whether the ADB-S Database is a refreshable clone.
     */
    "isRefreshableClone"?: boolean;
    /**
     * The date and time when last refresh happened.
     */
    "timeOfLastRefresh"?: Date;
    /**
     * The refresh point timestamp (UTC). The refresh point is the time to which the database was most recently refreshed. Data created after the refresh point is not included in the refresh.
     */
    "timeOfLastRefreshPoint"?: Date;
    /**
     * The date and time of next refresh.
     */
    "timeOfNextRefresh"?: Date;
    /**
     * The DATABASE OPEN mode.
     */
    "openMode"?: AdbsDatabaseBaseOpenModeEnum;
    /**
     * The refresh status of the clone.
     */
    "refreshableStatus"?: AdbsDatabaseBaseRefreshableStatusEnum;
    /**
     * The refresh mode of the clone.
     */
    "refreshableMode"?: AdbsDatabaseBaseRefreshableModeEnum;
    /**
     * The Azure resource id of the source ADB-S Database that was cloned to create the current ADB-S Database.
     */
    "sourceId"?: string;
    /**
     * The ADB-S Database permission level. Restricted mode allows access only to admin users.
     */
    "permissionLevel"?: AdbsDatabaseBasePermissionLevelEnum;
    /**
     * Customer Contacts.
     */
    "customerContacts"?: Array<CustomerContact>;
    /**
     * Indicates whether the ADB-S Database requires mTLS connections.
     */
    "isMtlsConnectionRequired"?: boolean;
    /**
     * Indicates if the refreshable clone can be reconnected to its source database.
     */
    "isReconnectCloneEnabled"?: boolean;
    /**
     * The time and date as an RFC3339 formatted string, e.g., 2022-01-01T12:00:00.000Z, to set the limit for a refreshable clone to be reconnected to its source database.
     */
    "timeUntilReconnectCloneEnabled"?: Date;
    /**
     * list of scheduled operations
     */
    "scheduledOperations"?: Array<AdbScheduledOperationDetails>;
    /**
     * Indicates whether the ADB-S Database is setup with bucket storage.
     */
    "isManualBackupStoreConfigured"?: boolean;
    /**
     * The Oracle license model that applies to the Oracle Autonomous Database.  Bring your own license (BYOL) allows you to apply your current on-premises Oracle software licenses to equivalent, highly automated Oracle PaaS and IaaS services in the cloud.  License Included allows you to subscribe to new Oracle Database software licenses and the Database service. If a value is not specified, the system will supply the value of BRING_YOUR_OWN_LICENSE. 
     */
    "licenseModel"?: AdbsDatabaseBaseLicenseModelEnum;
    /**
     * The Oracle Database Edition that applies to the Autonomous databases. 
     */
    "databaseEdition"?: AdbsDatabaseBaseDatabaseEditionEnum;
    /**
     * The (Virtual Network) VNET Id of Azure client.
     */
    "vnetId"?: string;
    /**
     * The subnet Id of Azure client.
     */
    "subnetId"?: string;
    /**
     * specifies the network access options available when you provisioning an Autonomous Database.
     */
    "networkAccessType"?: AdbsDatabaseBaseNetworkAccessTypeEnum;
    /**
     * Hostname alias for the IP address of the Autonomous Database Service. The hostname prefix can contain only letters and numbers. This property is only applicable when networkAccessType is PRIVATE
     */
    "hostnamePrefix"?: string;
    /**
     * The Azure unique identifier of the MCVCN resources. 
     */
    "mcvcnId"?: string;
    /**
     * The private endpoint for the ADBS resource.
     */
    "privateEndpoint"?: string;
    /**
     * The private endpoint Ip address for the ADBS resource.
     */
    "privateEndpointIp"?: string;
    /**
     * The character set for the database. The default is AL32UTF8.
     */
    "characterSet"?: string;
    /**
     * The national character set for the database. The default value is AL16UTF16. Allowed values are AL16UTF16 or UTF8.
     */
    "ncharacterSet"?: string;
}

export type AdbsDatabaseBaseCloudProviderEnum = "AZURE";
export type AdbsDatabaseBaseStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type AdbsDatabaseBaseLastOperationStatusEnum = "Succeeded" | "Failed";
export type AdbsDatabaseBaseDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
export type AdbsDatabaseBaseOpenModeEnum = "READ_ONLY" | "READ_WRITE";
export type AdbsDatabaseBaseRefreshableStatusEnum = "REFRESHING" | "NOT_REFRESHING";
export type AdbsDatabaseBaseRefreshableModeEnum = "AUTOMATIC" | "MANUAL";
export type AdbsDatabaseBasePermissionLevelEnum = "RESTRICTED" | "UNRESTRICTED";
export type AdbsDatabaseBaseLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
export type AdbsDatabaseBaseDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION";
export type AdbsDatabaseBaseNetworkAccessTypeEnum = "EVERYWHERE" | "ALLOWED_IPS" | "PRIVATE" | "PRIVATE_PEERING";
/**
 * A collection of ADB-S Database resources
 */
export interface AdbsDatabaseCollection {
    /**
     * An array of AdbsDatabaseSummary resources.
     */
    "items": Array<AdbsDatabaseSummary>;
}

/**
 * Details for creating ADB-S database deployment. 
 */
export interface AdbsDatabaseDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": AdbsDatabaseDeploymentDetailsKindEnum;
}

export type AdbsDatabaseDeploymentDetailsKindEnum = "CREATE";
/**
 * Summary of a ADB-S Database resource. 
 */
export interface AdbsDatabaseSummary {
    /**
     * The fully qualified Azure resource id.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid": string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The name for the AdbsDatabase. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": AdbsDatabaseSummaryStatusEnum;
    /**
     * The database name.
     */
    "dbName": string;
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: AdbsDatabaseSummaryDbWorkloadEnum;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The number of OCPU cores to be made available to the database. 
     */
    "cpuCoreCount"?: number;
    /**
     * The number of OCPU cores to be made available to the database. 
     */
    "ocpuCount"?: number;
    /**
     * The quantity of data in the database, in terabytes.
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * The amount of memory (in GBs) enabled per each OCPU core in the ADB-S VM Cluster.
     */
    "memoryPerOracleComputeUnitInGBs"?: number;
    /**
     * The date and time the ADB-S Database was created.
     */
    "timeCreated"?: Date;
    /**
     * Indicates if auto scaling is enabled for the ADB-S Database CPU core count. 
     */
    "isAutoScalingEnabled"?: boolean;
    /**
     * Indicates whether the ADB-S Database is a refreshable clone.
     */
    "isRefreshableClone"?: boolean;
    /**
     * The date and time when last refresh happened.
     */
    "timeOfLastRefresh"?: Date;
    /**
     * The refresh point timestamp (UTC). The refresh point is the time to which the database was most recently refreshed. Data created after the refresh point is not included in the refresh.
     */
    "timeOfLastRefreshPoint"?: Date;
    /**
     * The date and time of next refresh.
     */
    "timeOfNextRefresh"?: Date;
    /**
     * The DATABASE OPEN mode.
     */
    "openMode"?: string;
    /**
     * The refresh status of the clone.
     */
    "refreshableStatus"?: string;
    /**
     * The refresh mode of the clone.
     */
    "refreshableMode"?: string;
    /**
     * The Azure resource id of the source ADB-S Database that was cloned to create the current ADB-S Database.
     */
    "sourceId"?: string;
    /**
     * The ADB-S Database permission level. Restricted mode allows access only to admin users.
     */
    "permissionLevel"?: string;
    /**
     * list of scheduled operations
     */
    "scheduledOperations"?: Array<any>;
    /**
     * Indicates whether the ADB-S Database is setup with bucket storage.
     */
    "isManualBackupStoreConfigured"?: boolean;
    /**
     * The Oracle license model that applies to the Oracle Autonomous Database.  Bring your own license (BYOL) allows you to apply your current on-premises Oracle software licenses to equivalent, highly automated Oracle PaaS and IaaS services in the cloud.  License Included allows you to subscribe to new Oracle Database software licenses and the Database service. If a value is not specified, the system will supply the value of BRING_YOUR_OWN_LICENSE. 
     */
    "licenseModel"?: AdbsDatabaseSummaryLicenseModelEnum;
    /**
     * The Oracle Database Edition that applies to the Autonomous databases. 
     */
    "databaseEdition"?: AdbsDatabaseSummaryDatabaseEditionEnum;
    /**
     * The (Virtual Network) VNET Id of Azure client.
     */
    "vnetId"?: string;
    /**
     * The subnet Id of Azure client.
     */
    "subnetId"?: string;
    /**
     * specifies the network access options available when you provisioning an Autonomous Database.
     */
    "networkAccessType"?: AdbsDatabaseSummaryNetworkAccessTypeEnum;
    /**
     * Hostname alias for the IP address of the Autonomous Database Service. The hostname prefix can contain only letters and numbers. This property is only applicable when networkAccessType is PRIVATE
     */
    "hostnamePrefix"?: string;
}

export type AdbsDatabaseSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type AdbsDatabaseSummaryDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
export type AdbsDatabaseSummaryLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
export type AdbsDatabaseSummaryDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION";
export type AdbsDatabaseSummaryNetworkAccessTypeEnum = "EVERYWHERE" | "ALLOWED_IPS" | "PRIVATE" | "PRIVATE_PEERING";
/**
 * Wallet details for a specified Autonomous Database. 
 */
export interface AdbsDatabaseWallet {
    /**
     * The current lifecycle state of the Autonomous Database wallet.
     */
    "lifecycleState": AdbsDatabaseWalletLifecycleStateEnum;
    /**
     * The date and time the Autonomous Database wallet was downloaded.
     */
    "timeDownloaded"?: Date;
    /**
     * The date and time the wallet was last rotated.
     */
    "timeRotated"?: Date;
}

export type AdbsDatabaseWalletLifecycleStateEnum = "ACTIVE" | "UPDATING";
/**
 * Details of the capacity to be added for cloud VM cluster scale up. Applies to Exadata Cloud Service instances only. 
 */
export interface AddCloudVmClusterAvailableCapacityDetails {
    /**
     * The number of compute servers for the cloud Exadata infrastructure.
     */
    "computeCount"?: number;
    /**
     * The storage allocation for the disk group, in gigabytes (GB).
     */
    "storageSizeInGBs"?: number;
}

/**
 * Details required to add a HeatWave cluster. 
 */
export interface AddHeatWaveClusterDetails {
    /**
     * The shape determines resources to allocate to the HeatWave nodes - CPU cores, memory. 
     */
    "shapeName": string;
    /**
     * The number of analytics-processing nodes provisioned for the HeatWave cluster. 
     */
    "clusterSize": number;
}

/**
 * A collection of Autonomous Exadata Infrastructure shapes
 */
export interface AutonomousExadataInfrastructureShapeCollection {
    /**
     * An array of AutonomousDatabaseSummary resources.
     */
    "items": Array<AutonomousExadataInfrastructureShapeSummary>;
}

/**
 * The shape of the Autonomous Exadata Infrastructure. The shape determines resources to allocate to the Autonomous Exadata Infrastructure (CPU cores, memory and storage). 
 */
export interface AutonomousExadataInfrastructureShapeSummary {
    /**
     * The name of the shape used for the Autonomous Exadata Infrastructure.
     */
    "name": string;
    /**
     * The maximum number of CPU cores that can be enabled on the Autonomous Exadata Infrastructure.
     */
    "availableCoreCount": number;
    /**
     * The minimum number of CPU cores that can be enabled on the Autonomous Exadata Infrastructure.
     */
    "minimumCoreCount"?: number;
    /**
     * The runtime minimum number of CPU cores that can be enabled on the DB system for this shape.
     */
    "runtimeMinimumCoreCount"?: number;
    /**
     * The increment in which core count can be increased or decreased.
     */
    "coreCountIncrement"?: number;
    /**
     * The minimum number of nodes available for the shape.
     */
    "minimumNodeCount"?: number;
    /**
     * The maximum number of nodes available for the shape.
     */
    "maximumNodeCount"?: number;
}

/**
 * Description of the AzureCloudLink.
 */
export interface AzureCloudLink {
    /**
     * Azure unique identifier that is immutable on creation.
     */
    "id": string;
    /**
     * Associated OCI tenancy name.
     */
    "ociTenancyName"?: string;
    /**
     * Associated OCI tenancy type. Field is deprecated, use ociTenancyPaymentModel instead
     */
    "ociTenancyType"?: AzureCloudLinkOciTenancyTypeEnum;
    /**
     * Associated OCI subscription payment model.
     */
    "ociTenancyPaymentModel"?: AzureCloudLinkOciTenancyPaymentModelEnum;
    /**
     * Federated domain name for the OCI tenancy.
     */
    "ociDomainName"?: string;
    /**
     * Azure name of the resource.
     */
    "name": string;
    /**
     * Desciption of the resource.
     */
    "description"?: string;
    /**
     * Azure Active Directory Tenant ID to be bound to the OCI tenancy.
     */
    "tenantId": string;
    /**
     * Azure subscriptions associated with this AzureCloudLink.
     */
    "subscriptionIds"?: Array<string>;
    /**
     * Azure primary location of the CloudLink. All write operations for this CloudLink should occur only in this location.
     */
    "primaryLocation": string;
    /**
     * Azure locations where this CloudLink can be used to instantiate multi-cloud resources.
     */
    "locations"?: Array<string>;
    /**
     * The time the the AzureCloudLink was created. An RFC3339 formatted datetime string
     */
    "timeCreated": Date;
    /**
     * The time the AzureCloudLink was updated. An RFC3339 formatted datetime string
     */
    "timeUpdated"?: Date;
    /**
     * The current state of the AzureCloudLink.
     */
    "lifecycleState": AzureCloudLinkLifecycleStateEnum;
    /**
     * A message describing the current state in more detail. For example, can be used to provide actionable information for a resource in Failed state.
     */
    "lifecycleDetails"?: string;
    "lastOperation"?: AzureLastOperationDetails;
}

export type AzureCloudLinkOciTenancyTypeEnum = "UNKNOWN" | "PROMO" | "PROMO_CONSUMED" | "PAYG" | "MONTHLY_COMMITMENT" | "ANNUAL_COMMITMENT";
export type AzureCloudLinkOciTenancyPaymentModelEnum = "UNKNOWN" | "FREE" | "PAID";
export type AzureCloudLinkLifecycleStateEnum = "CREATING" | "UPDATING" | "ACTIVE" | "DELETING" | "DELETED" | "FAILED";
/**
 * Results of a cloudLink search. Contains both AzureCloudLinkSummary items and other information, such as metadata.
 */
export interface AzureCloudLinkCollection {
    /**
     * List of cloudLinks.
     */
    "items": Array<AzureCloudLinkSummary>;
}

/**
 * Summary of the AzureCloudLink.
 */
export interface AzureCloudLinkSummary {
    /**
     * Azure unique identifier that is immutable on creation.
     */
    "id": string;
    /**
     * Associated OCI tenancy name.
     */
    "ociTenancyName"?: string;
    /**
     * Associated OCI tenancy type.
     */
    "ociTenancyType"?: string;
    /**
     * Associated OCI subscription payment model.
     */
    "ociTenancyPaymentModel"?: string;
    /**
     * Federated domain name for the OCI tenancy.
     */
    "ociDomainName"?: string;
    /**
     * Azure unique identifier of the resource.
     */
    "name": string;
    /**
     * Desciption of the resource.
     */
    "description"?: string;
    /**
     * Azure Active Directory Tenant ID to be bound to the OCI tenancy.
     */
    "tenantId": string;
    /**
     * Azure subscriptions associated with this AzureCloudLink.
     */
    "subscriptionIds"?: Array<string>;
    /**
     * Azure primary location of the CloudLink. All write operations for this CloudLink should occur only in this location.
     */
    "primaryLocation": string;
    /**
     * Azure locations where this CloudLink can be used to instantiate multi-cloud resources.
     */
    "locations"?: Array<string>;
    /**
     * The time the the AzureCloudLink was created. An RFC3339 formatted datetime string
     */
    "timeCreated": Date;
    /**
     * The time the AzureCloudLink was updated. An RFC3339 formatted datetime string
     */
    "timeUpdated"?: Date;
    /**
     * The current state of the AzureCloudLink.
     */
    "lifecycleState": AzureCloudLinkSummaryLifecycleStateEnum;
    /**
     * A message describing the current state in more detail. For example, can be used to provide actionable information for a resource in Failed state.
     */
    "lifecycleDetails"?: string;
    "lastOperation"?: AzureLastOperationDetails;
}

export type AzureCloudLinkSummaryLifecycleStateEnum = "CREATING" | "UPDATING" | "ACTIVE" | "DELETING" | "DELETED" | "FAILED";
/**
 * Details of the latest asynchronous operation on the AzureCloudLink
 */
export interface AzureLastOperationDetails {
    /**
     * Type of the operation.
     */
    "operationType": AzureLastOperationDetailsOperationTypeEnum;
    /**
     * Status of the operation.
     */
    "status": AzureLastOperationDetailsStatusEnum;
    /**
     * Azure locations where this CloudLink can be used to instantiate multi-cloud resources.
     */
    "subscribedLocations"?: Array<string>;
    /**
     * Azure subscriptions associated with this AzureCloudLink.
     */
    "subscriptionIds"?: Array<string>;
    /**
     * Message relating to the operation terminus.
     */
    "message"?: string;
    /**
     * The time when the Operation Object was created
     */
    "timeCreated": Date;
    /**
     * The time when the Operation Object was updated
     */
    "timeUpdated": Date;
}

export type AzureLastOperationDetailsOperationTypeEnum = "CREATE_CLOUD_LINK" | "UPDATE_CLOUD_LINK" | "DELETE_CLOUD_LINK";
export type AzureLastOperationDetailsStatusEnum = "IN_PROGRESS" | "FAILED" | "SUCCEEDED";
/**
 * Collection of AzureResourceGroup resources. 
 */
export interface AzureResourceGroupCollection {
    /**
     * List of AzureResourceGroup resources.
     */
    "items": Array<AzureResourceGroupSummary>;
}

/**
 * Summary of the AzureResourceGroup resource. 
 */
export interface AzureResourceGroupSummary {
    /**
     * ID of the resource group.
     */
    "id": string;
    /**
     * Name of the resource group.
     */
    "name": string;
    /**
     * Region name of the resource group.
     */
    "regionName": string;
    /**
     * Type fo the resource group.
     */
    "type": string;
    /**
     * Tags (key-value pairs) for the resource group. 
     */
    "tags": any;
}

/**
 * Collection of AzureSubnet resources. 
 */
export interface AzureSubnetCollection {
    /**
     * List of AzureSubnet resources.
     */
    "items": Array<AzureSubnetSummary>;
}

/**
 * Summary of the AzureSubnet resource. 
 */
export interface AzureSubnetSummary {
    /**
     * Resource(subnet) ID.
     */
    "id": string;
    /**
     * Name of subnet.
     */
    "name": string;
    /**
     * ResourceGroupName of the subnet.
     */
    "resourceGroupName": string;
    /**
     * Region name of the subnet.
     */
    "regionName": string;
    /**
     * Type of the resource.
     */
    "type": string;
    /**
     * Tags (key-value pairs) for the subnet. 
     */
    "tags": any;
}

/**
 * Collection of AzureSubscription resources. 
 */
export interface AzureSubscriptionCollection {
    /**
     * List of AzureSubscription resources.
     */
    "items": Array<AzureSubscriptionSummary>;
}

/**
 * Summary of the AzureSubscription resource. 
 */
export interface AzureSubscriptionSummary {
    /**
     * ID of the resource group.
     */
    "id": string;
    /**
     * Name of the resource group.
     */
    "name": string;
    /**
     * State of the resource.
     */
    "state": string;
}

/**
 * Collection of AzureVirtualNetwork resources. 
 */
export interface AzureVirtualNetworkCollection {
    /**
     * List of AzureVirtualNetwork resources.
     */
    "items": Array<AzureVirtualNetworkSummary>;
}

/**
 * Summary of the AzureVirtualNetwork resource. 
 */
export interface AzureVirtualNetworkSummary {
    /**
     * ID of the virtual network.
     */
    "id": string;
    /**
     * Name of the virtual network.
     */
    "name": string;
    /**
     * AddressSpaces of the virtual network.
     */
    "addressSpaces"?: Array<string>;
    /**
     * DnsServerIps of the virtual network.
     */
    "dnsServerIps"?: Array<string>;
    /**
     * ResourceGroupName of the virtual network.
     */
    "resourceGroupName": string;
    /**
     * Region name of the virtual network.
     */
    "regionName": string;
    /**
     * Type of the virtual network.
     */
    "type": string;
    /**
     * Tags (key-value pairs) for the virtual network. 
     */
    "tags": any;
}

/**
 * Backup of a database
 */
export interface Backup {
    /**
     * The fully qualified Azure resource ID of the database node.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: BackupStatusEnum;
    /**
     * The Oracle Database edition that applies to all the databases on the DB system.
     */
    "databaseEdition"?: BackupDatabaseEditionEnum;
    /**
     * The Azure resource ID of the database.
     */
    "databaseId"?: string;
    /**
     * The size of the database in gigabytes at the time the backup was taken.
     */
    "databaseSizeInGBs"?: number;
    /**
     * The user-friendly name for the backup.
     */
    "displayName": string;
    /**
     * Additional information about the current lifecycle state
     */
    "lifecycleDetails"?: string;
    /**
     * The current state of the backup.
     */
    "lifecycleState"?: BackupLifecycleStateEnum;
    /**
     * Shape of the backup's source database.
     */
    "shape"?: string;
    /**
     * The date and time the backup was completed.
     */
    "timeEnded"?: Date;
    /**
     * The date and time the backup started.
     */
    "timeStarted"?: Date;
    /**
     * The type of backup
     */
    "type"?: BackupTypeEnum;
    /**
     * Version of the backup's source database
     */
    "version"?: string;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: BackupLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type BackupStatusEnum = "Provisioning" | "Updating" | "Terminating" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type BackupDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION" | "ENTERPRISE_EDITION_HIGH_PERFORMANCE" | "ENTERPRISE_EDITION_EXTREME_PERFORMANCE";
export type BackupLifecycleStateEnum = "CREATING" | "ACTIVE" | "DELETING" | "DELETED" | "FAILED" | "RESTORING";
export type BackupTypeEnum = "INCREMENTAL" | "FULL" | "VIRTUAL_FULL";
export type BackupLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * A collection of database backups
 */
export interface BackupCollection {
    /**
     * A collection of database backups
     */
    "items": Array<BackupSummary>;
}

/**
 * A list of database backup resources. 
 */
export interface BackupSummaryCollection {
    /**
     * A collection of database backups
     */
    "items": Array<BackupSummary>;
}

/**
 * Details for the cloud Exadata infrastructure deployment. 
 */
export interface CloudExadataInfrastructureDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": CloudExadataInfrastructureDeploymentDetailsKindEnum;
}

export type CloudExadataInfrastructureDeploymentDetailsKindEnum = "CREATE" | "GET";
/**
 * Details of the cloud Exadata infrastructure resource. Applies to Exadata Cloud Service instances only. 
 */
export interface CloudExadataInfrastructureSummary {
    /**
     * The fully qualified Azure resource ID of the cloud Exadata infrastructure resource.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: CloudExadataInfrastructureSummaryStatusEnum;
    /**
     * The current lifecycle state of the cloud Exadata infrastructure resource.
     */
    "lifecycleState": CloudExadataInfrastructureSummaryLifecycleStateEnum;
    /**
     * The name for the cloud Exadata infrastructure resource. The name needs to be unique in the resource group.
     */
    "name"?: string;
    /**
     * The user-friendly name for the cloud Exadata infrastructure resource. The name does not need to be unique.
     */
    "displayName": string;
    /**
     * The model name of the cloud Exadata infrastructure resource. 
     */
    "shape": string;
    /**
     * The number of compute servers for the cloud Exadata infrastructure.
     */
    "computeCount"?: number;
    /**
     * The number of storage servers for the cloud Exadata infrastructure.
     */
    "storageCount"?: number;
    /**
     * The total storage allocated to the cloud Exadata infrastructure resource, in gigabytes (GB).
     */
    "totalStorageSizeInGBs"?: number;
    /**
     * The available storage can be allocated to the cloud Exadata infrastructure resource, in gigabytes (GB).
     */
    "availableStorageSizeInGBs"?: number;
    /**
     * The date and time the cloud Exadata infrastructure resource was created.
     */
    "timeCreated"?: Date;
    /**
     * Additional information about the current lifecycle state.
     */
    "lifecycleDetails"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    "maintenanceWindow"?: MaintenanceWindow;
    /**
     * The list of customer email addresses that receive information from Oracle about the specified OCI Database service resource. Oracle uses these email addresses to send notifications about planned and unplanned software maintenance updates, information about system hardware, and other information needed by administrators. Up to 10 email addresses can be added to the customer contacts for a cloud Exadata infrastructure instance. 
     */
    "customerContacts"?: Array<CustomerContact>;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: CloudExadataInfrastructureSummaryLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type CloudExadataInfrastructureSummaryStatusEnum = "Provisioning" | "Updating" | "Terminating" | "MaintenanceInProgress" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type CloudExadataInfrastructureSummaryLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "UPDATING" | "TERMINATING" | "TERMINATED" | "FAILED" | "MAINTENANCE_IN_PROGRESS";
export type CloudExadataInfrastructureSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * A list of cloud Exadata infrastructure resources. 
 */
export interface CloudExadataInfrastructureSummaryCollection {
    /**
     * An array of cloud Exadata infrastructure resources.
     */
    "items": Array<CloudExadataInfrastructureSummary>;
}

/**
 * Details of the available capacity for cloud VM cluster. Applies to Exadata Cloud Service instances only. 
 */
export interface CloudVmClusterAvailableCapacity {
    /**
     * The number of compute servers for the cloud Exadata infrastructure.
     */
    "availableComputeCount": number;
    /**
     * The storage allocation for the disk group, in gigabytes (GB).
     */
    "availableStorageSizeInGBs": number;
}

/**
 * Details for the cloud VM cluster deployment. 
 */
export interface CloudVmClusterDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": CloudVmClusterDeploymentDetailsKindEnum;
}

export type CloudVmClusterDeploymentDetailsKindEnum = "CREATE" | "GET";
/**
 * Details of the cloud VM cluster. Applies to Exadata Cloud Service instances only. 
 */
export interface CloudVmClusterSummary {
    /**
     * The fully qualified Azure resource ID of the cloud VM cluster.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: CloudVmClusterSummaryStatusEnum;
    /**
     * The Azure unique identifier of the NetworkLink. 
     */
    "networkLinkId"?: string;
    /**
     * The Azure unique identifier of the VCN resources. Either mcvnId or networkLinkId is required. 
     */
    "mcvcnId"?: string;
    /**
     * The model name of the Exadata hardware running the cloud VM cluster. 
     */
    "shape": string;
    /**
     * The port number configured for the listener on the cloud VM cluster.
     */
    "listenerPort"?: number;
    /**
     * The current state of the cloud VM cluster.
     */
    "lifecycleState": CloudVmClusterSummaryLifecycleStateEnum;
    /**
     * The number of nodes in the cloud VM cluster. 
     */
    "nodeCount"?: number;
    /**
     * The storage allocation for the disk group, in gigabytes (GB).
     */
    "storageSizeInGBs"?: number;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The user-friendly name for the cloud VM cluster. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The user-friendly name for the cloud VM cluster. The name does not need to be unique.
     */
    "displayName"?: string;
    /**
     * The date and time that the cloud VM cluster was created.
     */
    "timeCreated"?: Date;
    /**
     * Additional information about the current lifecycle state.
     */
    "lifecycleDetails"?: string;
    /**
     * The time zone of the cloud VM cluster. For details, see [Exadata Infrastructure Time Zones](/Content/Database/References/timezones.htm).
     */
    "timeZone"?: string;
    /**
     * The hostname for the cloud VM cluster.
     */
    "hostname": string;
    /**
     * The domain name for the cloud VM cluster.
     */
    "domain": string;
    /**
     * The number of CPU cores enabled on the cloud VM cluster.
     */
    "cpuCoreCount": number;
    /**
     * The number of OCPU cores to enable on the cloud VM cluster. Only 1 decimal place is allowed for the fractional part.
     */
    "ocpuCount"?: number;
    /**
     * The cluster name for cloud VM cluster. The cluster name must begin with an alphabetic character, and may contain hyphens (-). Underscores (_) are not permitted. The cluster name can be no longer than 11 characters and is not case sensitive. 
     */
    "clusterName"?: string;
    /**
     * The percentage assigned to DATA storage (user data and database files). The remaining percentage is assigned to RECO storage (database redo logs, archive logs, and recovery manager backups). Accepted values are 35, 40, 60 and 80. The default is 80 percent assigned to DATA storage. See [Storage Configuration](/Content/Database/Concepts/exaoverview.htm#Exadata) in the Exadata documentation for details on the impact of the configuration settings on storage. 
     */
    "dataStoragePercentage"?: number;
    /**
     * If true, database backup on local Exadata storage is configured for the cloud VM cluster. If false, database backup on local Exadata storage is not available in the cloud VM cluster. 
     */
    "isLocalBackupEnabled"?: boolean;
    /**
     * The fully qualified Azure resource ID of the cloud Exadata infrastructure.
     */
    "cloudExadataInfrastructureId": string;
    /**
     * If true, sparse disk group is configured for the cloud VM cluster. If false, sparse disk group is not created. 
     */
    "isSparseDiskgroupEnabled"?: boolean;
    /**
     * A valid Oracle Grid Infrastructure (GI) software version.
     */
    "giVersion"?: string;
    /**
     * Operating system version of the image.
     */
    "systemVersion"?: string;
    /**
     * The public key portion of one or more key pairs used for SSH access to the cloud VM cluster.
     */
    "sshPublicKeys": Array<string>;
    /**
     * The Oracle license model that applies to the cloud VM cluster. The default is LICENSE_INCLUDED. 
     */
    "licenseModel"?: CloudVmClusterSummaryLicenseModelEnum;
    /**
     * The type of redundancy configured for the cloud Vm cluster. NORMAL is 2-way redundancy. HIGH is 3-way redundancy. 
     */
    "diskRedundancy"?: CloudVmClusterSummaryDiskRedundancyEnum;
    /**
     * The Single Client Access Name (SCAN) IP addresses associated with the cloud VM cluster. SCAN IP addresses are typically used for load balancing and are not assigned to any interface. Oracle Clusterware directs the requests to the appropriate nodes in the cluster.  **Note:** For a single-node DB system, this list is empty. 
     */
    "scanIps"?: Array<string>;
    /**
     * The virtual IP (VIP) addresses associated with the cloud VM cluster. The Cluster Ready Services (CRS) creates and maintains one VIP address for each node in the Exadata Cloud Service instance to enable failover. If one node fails, the VIP is reassigned to another active node in the cluster.  **Note:** For a single-node DB system, this list is empty. 
     */
    "vips"?: Array<string>;
    /**
     * The FQDN of the DNS record for the SCAN IP addresses that are associated with the cloud VM cluster. 
     */
    "scanDnsName"?: string;
    /**
     * The TCP Single Client Access Name (SCAN) port. The default port is 1521.
     */
    "scanListenerPortTcp"?: number;
    /**
     * The TCPS Single Client Access Name (SCAN) port. The default port is 2484.
     */
    "scanListenerPortTcpSsl"?: number;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: CloudVmClusterSummaryLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type CloudVmClusterSummaryStatusEnum = "Provisioning" | "Updating" | "Terminating" | "MaintenanceInProgress" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type CloudVmClusterSummaryLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "UPDATING" | "TERMINATING" | "TERMINATED" | "FAILED" | "MAINTENANCE_IN_PROGRESS";
export type CloudVmClusterSummaryLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
export type CloudVmClusterSummaryDiskRedundancyEnum = "HIGH" | "NORMAL";
export type CloudVmClusterSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * A list of cloud VM cluster resources. 
 */
export interface CloudVmClusterSummaryCollection {
    /**
     * An array of cloud VM cluster resources.
     */
    "items": Array<CloudVmClusterSummary>;
}

/**
 * Contact details for the customer. Avoid entering confidential information.
 */
export interface Contact {
    /**
     * The name of the contact person.
     */
    "contactName"?: string;
    /**
     * The email of the contact person.
     */
    "contactEmail"?: string;
    /**
     * The phone number of the contact person.
     */
    "contactPhone"?: string;
}

/**
 * The list of contacts for the ticket.
 */
export interface ContactList {
    /**
     * The list of contacts.
     */
    "contactList": Array<Contact>;
}

/**
 * Describes the required parameters for the creation of an Autonomous Container Database. 
 */
export interface CreateAdbdContainerDatabaseDetails {
    /**
     * The name for the Autonomous Container Database. Must be unique in the resource group.
     */
    "name": string;
    /**
     * The Azure ID of the cloud Autonomous Exadata VM Cluster.
     */
    "cloudAutonomousVmClusterId"?: string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

/**
 * Details to create an Oracle Autonomous Database. 
 */
export interface CreateAdbdDatabaseBase {
    /**
     * The name for the AdbdDatabase. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The character set for the autonomous database.  The default is AL32UTF8. Allowed values for an Autonomous Database on shared infrastructure as as returned by [List Autonomous Database Character Sets](/autonomousDatabaseCharacterSets)  For an Autonomous Database on dedicated infrastructure, the allowed values are:  AL32UTF8, AR8ADOS710, AR8ADOS720, AR8APTEC715, AR8ARABICMACS, AR8ASMO8X, AR8ISO8859P6, AR8MSWIN1256, AR8MUSSAD768, AR8NAFITHA711, AR8NAFITHA721, AR8SAKHR706, AR8SAKHR707, AZ8ISO8859P9E, BG8MSWIN, BG8PC437S, BLT8CP921, BLT8ISO8859P13, BLT8MSWIN1257, BLT8PC775, BN8BSCII, CDN8PC863, CEL8ISO8859P14, CL8ISO8859P5, CL8ISOIR111, CL8KOI8R, CL8KOI8U, CL8MACCYRILLICS, CL8MSWIN1251, EE8ISO8859P2, EE8MACCES, EE8MACCROATIANS, EE8MSWIN1250, EE8PC852, EL8DEC, EL8ISO8859P7, EL8MACGREEKS, EL8MSWIN1253, EL8PC437S, EL8PC851, EL8PC869, ET8MSWIN923, HU8ABMOD, HU8CWI2, IN8ISCII, IS8PC861, IW8ISO8859P8, IW8MACHEBREWS, IW8MSWIN1255, IW8PC1507, JA16EUC, JA16EUCTILDE, JA16SJIS, JA16SJISTILDE, JA16VMS, KO16KSC5601, KO16KSCCS, KO16MSWIN949, LA8ISO6937, LA8PASSPORT, LT8MSWIN921, LT8PC772, LT8PC774, LV8PC1117, LV8PC8LR, LV8RST104090, N8PC865, NE8ISO8859P10, NEE8ISO8859P4, RU8BESTA, RU8PC855, RU8PC866, SE8ISO8859P3, TH8MACTHAIS, TH8TISASCII, TR8DEC, TR8MACTURKISHS, TR8MSWIN1254, TR8PC857, US7ASCII, US8PC437, UTF8, VN8MSWIN1258, VN8VN3, WE8DEC, WE8DG, WE8ISO8859P1, WE8ISO8859P15, WE8ISO8859P9, WE8MACROMAN8S, WE8MSWIN1252, WE8NCR4970, WE8NEXTSTEP, WE8PC850, WE8PC858, WE8PC860, WE8ROMAN8, ZHS16CGB231280, ZHS16GBK, ZHT16BIG5, ZHT16CCDC, ZHT16DBT, ZHT16HKSCS, ZHT16MSWIN950, ZHT32EUC, ZHT32SOPS, ZHT32TRIS 
     */
    "characterSet"?: string;
    /**
     * The character set for the Autonomous Database.  The default is AL32UTF8. Use [List Autonomous Database Character Sets](/autonomousDatabaseCharacterSets) to list the allowed values for an Autonomous Database on shared Exadata infrastructure. For an Autonomous Database on dedicated Exadata infrastructure, the allowed values are: AL16UTF16 or UTF8. 
     */
    "ncharacterSet"?: string;
    /**
     * The database name. The name must begin with an alphabetic character and can contain a maximum of 14 alphanumeric characters. Special characters are not permitted. The database name must be unique in the tenancy.
     */
    "dbName": string;
    /**
     * The number of OCPU cores to be made available to the database. For Autonomous Databases on dedicated Exadata infrastructure, the maximum number of cores is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `ocpuCount` parameter. 
     */
    "cpuCoreCount"?: number;
    /**
     * The number of OCPU cores to be made available to the database.  The following points apply: - For Autonomous Databases on dedicated Exadata infrastructure, to provision less than 1 core, enter a fractional value in an increment of 0.1. For example, you can provision 0.3 or 0.4 cores, but not 0.35 cores. (Note that fractional OCPU values are not supported for Autonomous Databasese on shared Exadata infrastructure.) - To provision 1 or more cores, you must enter an integer between 1 and the maximum number of cores available for the infrastructure shape. For example, you can provision 2 cores or 3 cores, but not 2.5 cores. This applies to Autonomous Databases on both shared and dedicated Exadata infrastructure.  For Autonomous Databases on dedicated Exadata infrastructure, the maximum number of cores is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `cpuCoreCount` parameter. 
     */
    "ocpuCount"?: number;
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: CreateAdbdDatabaseBaseDbWorkloadEnum;
    /**
     * The size, in terabytes, of the data volume that will be created and attached to the database. This storage can later be scaled up if needed. For Autonomous Databases on dedicated Exadata infrastructure, the maximum storage value is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `dataStorageSizeInGBs` parameter. 
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * The size, in gigabytes, of the data volume that will be created and attached to the database. This storage can later be scaled up if needed. The maximum storage value is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Notes** - This parameter is only supported for dedicated Exadata infrastructure. - This parameter cannot be used with the `dataStorageSizeInTBs` parameter. 
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * **Important** The `adminPassword` must be specified for all Autonomous Databases. The password must be between 12 and 30 characters long, and must contain at least 1 uppercase, 1 lowercase, and 1 numeric character. It cannot contain the double quote symbol (\") or the username \"admin\", regardless of casing. 
     */
    "adminPassword": string;
    /**
     * Indicates if auto scaling is enabled for the Autonomous Database OCPU core count. The default value is `FALSE`. 
     */
    "isAutoScalingEnabled"?: boolean;
    /**
     * The Autonomous Container Database Azure ID.
     */
    "autonomousContainerDatabaseId"?: string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * A valid Oracle Database version for Autonomous Database.
     */
    "dbVersion"?: string;
    /**
     * The source of the database: Use `NONE` for creating a new Autonomous Database. Use `DATABASE` for creating a new Autonomous Database by cloning an existing Autonomous Database. Use `CROSS_REGION_DATAGUARD` to create a standby Data Guard database in another region. 
     */
    "source"?: CreateAdbdDatabaseBaseSourceEnum;
    /**
     * list of scheduled operations
     */
    "scheduledOperations"?: Array<AdbScheduledOperationDetails>;
    /**
     * The number of Max OCPU cores to be made available to the autonomous database with auto scaling of cpu enabled. 
     */
    "maxCpuCoreCount"?: number;
    /**
     * The Oracle Database Edition that applies to the Autonomous databases. 
     */
    "databaseEdition"?: string;
}

export type CreateAdbdDatabaseBaseDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
export type CreateAdbdDatabaseBaseSourceEnum = "NONE";
/**
 * Details for the create cloud Autonomous VM cluster operation. 
 */
export interface CreateAdbdVmClusterDetails {
    /**
     * User defined description of the cloud Autonomous VM cluster.
     */
    "description"?: string;
    /**
     * The name for the cloud Autonomous VM cluster. The name must be unique in resource group.
     */
    "name": string;
    /**
     * The Id of the cloud Exadata infrastructure.
     */
    "cloudExadataInfrastructureId": string;
    /**
     * The Azure unique identifier of the NetworkLink.
     */
    "networkLinkId": string;
    /**
     * The total number of Autonomous Container Databases that can be created.
     */
    "totalContainerDatabases"?: number;
    /**
     * The number of OCPU cores to enable per VM cluster node.
     */
    "cpuCoreCountPerNode"?: number;
    /**
     * The amount of memory (in GBs) to be enabled per each OCPU core.
     */
    "memoryPerOracleComputeUnitInGBs"?: number;
    /**
     * The data disk group size to be allocated for Autonomous Databases, in TBs.
     */
    "autonomousDataStorageSizeInTBs"?: number;
    /**
     * The Oracle license model that applies to the Oracle Autonomous Database. Bring your own license (BYOL) allows you to apply your current on-premises Oracle software licenses to equivalent, highly automated Oracle PaaS and IaaS services in the cloud. License Included allows you to subscribe to new Oracle Database software licenses and the Database service. Note that when provisioning an Autonomous Database on [dedicated Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html), this attribute must be null because the attribute is already set at the Autonomous Exadata Infrastructure level. When using [shared Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html), if a value is not specified, the system will supply the value of `BRING_YOUR_OWN_LICENSE`. 
     */
    "licenseModel"?: CreateAdbdVmClusterDetailsLicenseModelEnum;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

export type CreateAdbdVmClusterDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * Details for creating ADB-S database.
 */
export interface CreateAdbsDatabaseBaseDetails {
    /**
     * The name for the AdbsDatabase. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The database name.
     */
    "dbName": string;
    /**
     * The password must be sent to create ADB-S database.
     */
    "adminPassword": string;
    /**
     * A valid Oracle Database version for Autonomous Database.
     */
    "dbVersion"?: string;
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: CreateAdbsDatabaseBaseDetailsDbWorkloadEnum;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The number of OCPU cores to be made available to the database.Minimum is 1 and Maximum is 128
     */
    "cpuCoreCount": number;
    /**
     * Customer Contacts.
     */
    "customerContactDetails"?: Array<CustomerContact>;
    /**
     * The size, in terabytes, of the data volume that will be created and attached to the database. This storage can later be scaled up if needed.
     */
    "dataStorageSizeInTBs": number;
    /**
     * Indicates if auto scaling is enabled for the ADB-S Database OCPU core count. The default value is FALSE
     */
    "isAutoScalingEnabled"?: boolean;
    /**
     * Indicates whether the ADB-S Database requires mTLS connections.
     */
    "isMtlsConnectionRequired"?: boolean;
    /**
     * The number of OCPU cores to be made available to the database.
     */
    "ocpuCount"?: number;
    /**
     * list of scheduled operations
     */
    "scheduledOperations"?: Array<AdbScheduledOperationDetails>;
    /**
     * Use NONE for creating a new ADB-S Database. Use DATABASE for creating a new ADB-S Database by cloning an existing ADB-S Database.
     */
    "source"?: CreateAdbsDatabaseBaseDetailsSourceEnum;
    /**
     * The client IP access control list (ACL).
     */
    "whitelistedIps"?: Array<string>;
    /**
     * The Oracle license model that applies to the Oracle Autonomous Database.  Bring your own license (BYOL) allows you to apply your current on-premises Oracle software licenses to equivalent, highly automated Oracle PaaS and IaaS services in the cloud.  License Included allows you to subscribe to new Oracle Database software licenses and the Database service. If a value is not specified, the system will supply the value of BRING_YOUR_OWN_LICENSE. 
     */
    "licenseModel"?: CreateAdbsDatabaseBaseDetailsLicenseModelEnum;
    /**
     * The (Virtual Network) VNET Id of Azure client.
     */
    "vnetId"?: string;
    /**
     * The subnet Id of Azure client.
     */
    "subnetId"?: string;
    /**
     * The Oracle Database Edition that applies to the Autonomous databases. 
     */
    "databaseEdition"?: CreateAdbsDatabaseBaseDetailsDatabaseEditionEnum;
    /**
     * specifies the network access options available when you provisioning an Autonomous Database.
     */
    "networkAccessType"?: CreateAdbsDatabaseBaseDetailsNetworkAccessTypeEnum;
    /**
     * Hostname alias for the IP address of the Autonomous Database Service. The hostname prefix can contain only letters and numbers. This property is only applicable when networkAccessType is PRIVATE
     */
    "hostnamePrefix"?: string;
    /**
     * The Azure unique identifier of the MCVCN resources. 
     */
    "mcvcnId"?: string;
    /**
     * The CIDRs that will be used to bring up the VirtualCloudNetwork (VCN) in OCI. These can only be ipv4 CIDRS. The list of one or more IPv4 CIDR blocks for the VCN that meet the following criteria   - The CIDR blocks must be valid.   - They must not overlap with each other or with the on-premises network CIDR block.   - The number of CIDR blocks must not exceed the limit of CIDR blocks allowed per VCN.   - During dry-run only one of ociNetworkCidrs or mcvcnId is required. 
     */
    "ociNetworkCidrs"?: Array<string>;
    /**
     * The character set for the database.  The default is AL32UTF8.
     */
    "characterSet"?: string;
    /**
     * The national character set for the database.  The default is AL16UTF16. Allowed values are AL16UTF16 or UTF8.
     */
    "ncharacterSet"?: string;
}

export type CreateAdbsDatabaseBaseDetailsDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
export type CreateAdbsDatabaseBaseDetailsSourceEnum = "NONE" | "DATABASE";
export type CreateAdbsDatabaseBaseDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
export type CreateAdbsDatabaseBaseDetailsDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION";
export type CreateAdbsDatabaseBaseDetailsNetworkAccessTypeEnum = "EVERYWHERE" | "ALLOWED_IPS" | "PRIVATE" | "PRIVATE_PEERING";
/**
 * Details needed to create a backup of a database
 */
export interface CreateBackupDetails {
    /**
     * Azure resource ID of the database to backup.
     */
    "databaseId": string;
    /**
     * The user-friendly name for the backup.
     */
    "displayName": string;
}

/**
 * Request to create cloud Exadata infrastructure. Applies to Exadata Cloud Service instances only. 
 */
export interface CreateCloudExadataInfrastructureDetails {
    /**
     * The name for the cloud Exadata infrastructure resource. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The user-friendly name for the cloud Exadata infrastructure resource. The name does not need to be unique. 
     */
    "displayName"?: string;
    /**
     * The shape of the cloud Exadata infrastructure resource. 
     */
    "shape": string;
    /**
     * The number of compute servers for the cloud Exadata infrastructure.
     */
    "computeCount"?: number;
    /**
     * The number of storage servers for the cloud Exadata infrastructure.
     */
    "storageCount"?: number;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    "maintenanceWindow"?: MaintenanceWindow;
    /**
     * Customer contacts.
     */
    "customerContacts"?: Array<CustomerContact>;
}

/**
 * Details for the create cloud VM cluster operation. Applies to Exadata Cloud Service instances only. 
 */
export interface CreateCloudVmClusterDetails {
    /**
     * The number of CPU cores to enable for a cloud VM cluster. Valid values depend on the specified shape:  - Exadata.Base.48 - Specify a multiple of 2, from 0 to 48. - Exadata.Quarter1.84 - Specify a multiple of 2, from 22 to 84. - Exadata.Half1.168 - Specify a multiple of 4, from 44 to 168. - Exadata.Full1.336 - Specify a multiple of 8, from 88 to 336. - Exadata.Quarter2.92 - Specify a multiple of 2, from 0 to 92. - Exadata.Half2.184 - Specify a multiple of 4, from 0 to 184. - Exadata.Full2.368 - Specify a multiple of 8, from 0 to 368. 
     */
    "cpuCoreCount": number;
    /**
     * The number of OCPU cores to enable for a cloud VM cluster. Only 1 decimal place is allowed for the fractional part.
     */
    "ocpuCount"?: number;
    /**
     * The cluster name for cloud VM cluster. The cluster name must begin with an alphabetic character, and may contain hyphens (-). Underscores (_) are not permitted. The cluster name can be no longer than 11 characters and is not case sensitive. 
     */
    "clusterName"?: string;
    /**
     * The percentage assigned to DATA storage (user data and database files). The remaining percentage is assigned to RECO storage (database redo logs, archive logs, and recovery manager backups). Accepted values are 35, 40, 60 and 80. The default is 80 percent assigned to DATA storage. See [Storage Configuration](/Content/Database/Concepts/exaoverview.htm#Exadata) in the Exadata documentation for details on the impact of the configuration settings on storage. 
     */
    "dataStoragePercentage"?: number;
    /**
     * The name for the cloud VM cluster. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The user-friendly name for the cloud VM cluster. The name does not need to be unique.
     */
    "displayName"?: string;
    /**
     * The hostname for the cloud VM cluster. The hostname must begin with an alphabetic character, and can contain alphanumeric characters and hyphens (-). The maximum length of the hostname is 16 characters for bare metal and virtual machine DB systems, and 12 characters for Exadata systems.  The maximum length of the combined hostname and domain is 63 characters.  **Note:** The hostname must be unique within the subnet. If it is not unique, the cloud VM Cluster will fail to provision. 
     */
    "hostname": string;
    /**
     * A domain name used for the cloud VM cluster. If the Oracle-provided internet and VCN resolver is enabled for the specified subnet, the domain name for the subnet is used (do not provide one). Otherwise, provide a valid DNS domain name. Hyphens (-) are not permitted. Applies to Exadata Cloud Service instances only. 
     */
    "domain"?: string;
    /**
     * The public key portion of one or more key pairs used for SSH access to the cloud VM cluster.
     */
    "sshPublicKeys": Array<string>;
    /**
     * The Oracle license model that applies to the cloud VM cluster. The default is BRING_YOUR_OWN_LICENSE. 
     */
    "licenseModel"?: CreateCloudVmClusterDetailsLicenseModelEnum;
    /**
     * If true, the sparse disk group is configured for the cloud VM cluster. If false, the sparse disk group is not created. 
     */
    "isSparseDiskgroupEnabled"?: boolean;
    /**
     * If true, database backup on local Exadata storage is configured for the cloud VM cluster. If false, database backup on local Exadata storage is not available in the cloud VM cluster. 
     */
    "isLocalBackupEnabled"?: boolean;
    /**
     * The time zone to use for the cloud VM cluster. For details, see [Time Zones](/Content/Database/References/timezones.htm). 
     */
    "timeZone"?: string;
    /**
     * The TCP Single Client Access Name (SCAN) port. The default port is 1521.
     */
    "scanListenerPortTcp"?: number;
    /**
     * The TCPS Single Client Access Name (SCAN) port. The default port is 2484.
     */
    "scanListenerPortTcpSsl"?: number;
    /**
     * A valid Oracle Grid Infrastructure (GI) software version.
     */
    "giVersion": string;
    /**
     * The Azure unique identifier of the NetworkLink.Either mcvnId or networkLinkId is required. 
     */
    "networkLinkId"?: string;
    /**
     * The Azure unique identifier of the VCN resources. Either mcvnId or networkLinkId is required. 
     */
    "mcvcnId"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The Azure unique Identifier of the cloud Exadata infrastructure resource.
     */
    "cloudExadataInfrastructureId": string;
    /**
     * The CIDRs that will be used to bring up the VirtualCloudNetwork (VCN) in OCI. These can only be ipv4 CIDRS. The list of one or more IPv4 CIDR blocks for the VCN that meet the following criteria   - The CIDR blocks must be valid.   - They must not overlap with each other or with the on-premises network CIDR block.   - The number of CIDR blocks must not exceed the limit of CIDR blocks allowed per VCN.   - During dry-run only one of ociNetworkCidrs, mcvcnId, or networkLinkId is required. 
     */
    "ociNetworkCidrs"?: Array<string>;
    /**
     * The number of compute servers for the cloud Exadata infrastructure.
     */
    "computeCount"?: number;
    /**
     * The model name of the cloud Exadata infrastructure resource. 
     */
    "shape"?: string;
}

export type CreateCloudVmClusterDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * Details for creating a database.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateDatabaseBase {
    /**
     * The fully qualified Azure resource ID of the VM cluster.
     */
    "vmClusterId": string;
    /**
     * A valid Oracle Database version. To get a list of supported versions, use the [ListDbVersions](#/en/database/latest/DbVersionSummary/ListDbVersions) operation.
     */
    "dbVersion"?: string;
    /**
     * The source of the database: Use `NONE` for creating a new database. The default is `NONE`. 
     */
    "source": CreateDatabaseBaseSourceEnum;
    /**
     * The fully qualified Azure resource ID of the Database Home.
     */
    "dbHomeId"?: string;
    /**
     * User shall provide either a dbHomeId (to reuse an existing dbHome) OR both dbVersion and dbHomeName (to create a new dbHome). dbHomeName is a user provided name of the DB Home which can contain a maximum of 255 alphanumeric characters. 
     */
    "dbHomeName"?: string;
}

export type CreateDatabaseBaseSourceEnum = "NONE";
/**
 * Details for creating a database.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateDatabaseDetails {
    /**
     * The name for the database. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The database name. The name must begin with an alphabetic character and can contain a maximum of eight alphanumeric characters. Special characters are not permitted.
     */
    "dbName": string;
    /**
     * The `DB_UNIQUE_NAME` of the Oracle Database being backed up.
     */
    "dbUniqueName"?: string;
    /**
     * The name of the pluggable database. The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. Pluggable database should not be same as database name.
     */
    "pdbName"?: string;
    /**
     * A strong password for SYS, SYSTEM, and PDB Admin. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numbers, and two special characters. The special characters must be _, \\#, or -.
     */
    "adminPassword": string;
    /**
     * The optional password to open the TDE wallet. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numeric, and two special characters. The special characters must be _, \\#, or -.
     */
    "tdeWalletPassword"?: string;
    /**
     * The character set for the database.  The default is AL32UTF8. Allowed values are:  AL32UTF8, AR8ADOS710, AR8ADOS720, AR8APTEC715, AR8ARABICMACS, AR8ASMO8X, AR8ISO8859P6, AR8MSWIN1256, AR8MUSSAD768, AR8NAFITHA711, AR8NAFITHA721, AR8SAKHR706, AR8SAKHR707, AZ8ISO8859P9E, BG8MSWIN, BG8PC437S, BLT8CP921, BLT8ISO8859P13, BLT8MSWIN1257, BLT8PC775, BN8BSCII, CDN8PC863, CEL8ISO8859P14, CL8ISO8859P5, CL8ISOIR111, CL8KOI8R, CL8KOI8U, CL8MACCYRILLICS, CL8MSWIN1251, EE8ISO8859P2, EE8MACCES, EE8MACCROATIANS, EE8MSWIN1250, EE8PC852, EL8DEC, EL8ISO8859P7, EL8MACGREEKS, EL8MSWIN1253, EL8PC437S, EL8PC851, EL8PC869, ET8MSWIN923, HU8ABMOD, HU8CWI2, IN8ISCII, IS8PC861, IW8ISO8859P8, IW8MACHEBREWS, IW8MSWIN1255, IW8PC1507, JA16EUC, JA16EUCTILDE, JA16SJIS, JA16SJISTILDE, JA16VMS, KO16KSC5601, KO16KSCCS, KO16MSWIN949, LA8ISO6937, LA8PASSPORT, LT8MSWIN921, LT8PC772, LT8PC774, LV8PC1117, LV8PC8LR, LV8RST104090, N8PC865, NE8ISO8859P10, NEE8ISO8859P4, RU8BESTA, RU8PC855, RU8PC866, SE8ISO8859P3, TH8MACTHAIS, TH8TISASCII, TR8DEC, TR8MACTURKISHS, TR8MSWIN1254, TR8PC857, US7ASCII, US8PC437, UTF8, VN8MSWIN1258, VN8VN3, WE8DEC, WE8DG, WE8ISO8859P1, WE8ISO8859P15, WE8ISO8859P9, WE8MACROMAN8S, WE8MSWIN1252, WE8NCR4970, WE8NEXTSTEP, WE8PC850, WE8PC858, WE8PC860, WE8ROMAN8, ZHS16CGB231280, ZHS16GBK, ZHT16BIG5, ZHT16CCDC, ZHT16DBT, ZHT16HKSCS, ZHT16MSWIN950, ZHT32EUC, ZHT32SOPS, ZHT32TRIS 
     */
    "characterSet"?: string;
    /**
     * The national character set for the database.  The default is AL16UTF16. Allowed values are: AL16UTF16 or UTF8. 
     */
    "ncharacterSet"?: string;
    /**
     * The database workload type.
     */
    "dbWorkload"?: CreateDatabaseDetailsDbWorkloadEnum;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    "dbBackupConfig"?: DbBackupConfig;
    /**
     * The database domain. In a distributed database system, DB_DOMAIN specifies the logical location of the database within the network structure.
     */
    "dbDomain"?: string;
    /**
     * Specifies a prefix for the `Oracle SID` of the database to be created. 
     */
    "sidPrefix"?: string;
}

export type CreateDatabaseDetailsDbWorkloadEnum = "OLTP" | "DSS";
/**
 * Details for creating a new dbHome.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateDbHomeBase {
    /**
     * The source of the new Db Home: Use `VM_CLUSTER_NEW` for creating a new Db Home with VM Cluster ID. The default is `VM_CLUSTER_NEW`. 
     */
    "source"?: CreateDbHomeBaseSourceEnum;
    /**
     * User provided name of the DB Home which can contain a maximum of 255 alphanumeric characters.
     */
    "dbHomeName"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
}

export type CreateDbHomeBaseSourceEnum = "VM_CLUSTER_NEW";
/**
 * The information about new deployment. 
 */
export interface CreateDeploymentDetails {
    /**
     * Deployment uniquely identified name
     */
    "name": string;
    "parameters": DeploymentParametersDetails;
}

/**
 * Details gathered during the creation of the support ticket. 
 */
export interface CreateIncidentDetails {
    /**
     * The date and time when the issue was encountered.
     */
    "timeEncountered"?: Date;
    /**
     * The severity of the support ticket.
     */
    "severity": CreateIncidentDetailsSeverityEnum;
    /**
     * The list of items details.
     */
    "itemsList"?: Array<CreateItemDetails>;
    /**
     * The title of the support ticket. Avoid entering confidential information.
     */
    "title": string;
    /**
     * The description of the support ticket. Avoid entering confidential information.
     */
    "description": string;
    /**
     * The kind of support ticket, such as a technical support request or a limit increase request.
     */
    "problemType": CreateIncidentDetailsProblemTypeEnum;
    /**
     * The list of contacts.
     */
    "contacts"?: Array<Contact>;
    /**
     * The incident referrer. This value is often the URL that the customer used when creating the support ticket.
     */
    "referrer"?: string;
}

export type CreateIncidentDetailsSeverityEnum = "HIGHEST" | "HIGH" | "MEDIUM" | "LOW";
export type CreateIncidentDetailsProblemTypeEnum = "LIMIT" | "TECHNICAL" | "BILLING";
/**
 * Details about the item that the support ticket relates to. 
 */
export interface CreateItemDetails {
    /**
     * The kind of support ticket, such as a technical support request or a limit increase request.
     */
    "problemType"?: CreateItemDetailsProblemTypeEnum;
    /**
     * The display name of the ticket. Avoid entering confidential information.
     */
    "name"?: string;
    /**
     * The location of the resource.
     */
    "location"?: string;
}

export type CreateItemDetailsProblemTypeEnum = "LIMIT" | "TECHNICAL" | "BILLING";
/**
 * Complete information for a Backup.
 */
export interface CreateMdsBackupDetails {
    /**
     * The user-friendly name for the backup.
     */
    "name": string;
    /**
     * A user-supplied display name for the backup.
     */
    "displayName"?: string;
    /**
     * A user-supplied description for the backup.
     */
    "description"?: string;
    /**
     * The type of backup.
     */
    "backupType"?: CreateMdsBackupDetailsBackupTypeEnum;
    /**
     * The Azure Resource Id of the database.
     */
    "dbSystemId": string;
    /**
     * Number of days to retain this backup.
     */
    "retentionInDays"?: number;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

export type CreateMdsBackupDetailsBackupTypeEnum = "FULL" | "INCREMENTAL";
/**
 * Backup policy as optionally used for DB System Creation. 
 */
export interface CreateMdsBackupPolicyDetails {
    /**
     * Specifies if automatic backups are enabled. 
     */
    "isEnabled"?: boolean;
    /**
     * The start of a 30-minute window of time in which daily, automated backups occur.  This should be in the format of the \"Time\" portion of an RFC3339-formatted timestamp. Any second or sub-second time data will be truncated to zero.  At some point in the window, the system may incur a brief service disruption as the backup is performed. 
     */
    "windowStartTime"?: string;
    /**
     * Number of days to retain an automatic backup.
     */
    "retentionInDays"?: number;
    "pitrPolicy"?: MdsDbSystemPitrPolicy;
}

/**
 * Details required to create a DB System. 
 */
export interface CreateMdsDbSystemDetails {
    /**
     * The user-friendly name for the DB System. It does have to be unique in resource group.
     */
    "name": string;
    /**
     * User-provided data about the DB System.
     */
    "description"?: string;
    /**
     * Specifies if the DB System is highly available.  When creating a DB System with High Availability, three instances are created and placed according to your region- and subnet-type. The secondaries are placed automatically in the other two availability or fault domains.  You can choose the preferred location of your primary instance, only. 
     */
    "isHighlyAvailable"?: boolean;
    /**
     * The Azure unique identifier of the Configuration to be used for this DB System.
     */
    "configurationId"?: string;
    /**
     * The name of the shape. The shape determines the resources allocated - CPU cores and memory for VM shapes; CPU cores, memory and storage for non-VM (or bare metal) shapes. To get a list of shapes, use the [ListShapes](#/en/mysql/20190415/ShapeSummary/ListShapes) operation. 
     */
    "shapeName": string;
    /**
     * The specific MySQL version identifier.
     */
    "mysqlVersion"?: string;
    /**
     * The Azure unique identifier of the NetworkLink. During creation only one of mcvcnId or networkLinkId is required. 
     */
    "networkLinkId"?: string;
    /**
     * The username for the administrative user.
     */
    "adminUsername": string;
    /**
     * The password for the administrative user. The password must be between 8 and 32 characters long, and must contain at least 1 numeric character, 1 lowercase character, 1 uppercase character, and 1 special (nonalphanumeric) character. 
     */
    "adminPassword": string;
    /**
     * Initial size of the data volume in GBs that will be created and attached. Keep in mind that this only specifies the size of the database data volume, the log volume for the database will be scaled appropriately with its shape. 
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The hostname for the primary endpoint of the DB System. Used for DNS.  The value is the hostname portion of the primary private IP's fully qualified domain name (FQDN) (for example, \"dbsystem-1\" in FQDN \"dbsystem-1.subnet123.vcn1.oraclevcn.com\").  Must be unique across all VNICs in the subnet and comply with RFC 952 and RFC 1123. 
     */
    "hostnameLabel"?: string;
    /**
     * The IP address the DB System is configured to listen on. A private IP address of your choice to assign to the primary endpoint of the DB System. Must be an available IP address within the subnet's CIDR. If you don't specify a value, Oracle automatically assigns a private IP address from the subnet. This should be a \"dotted-quad\" style IPv4 address. 
     */
    "ipAddress"?: string;
    /**
     * The port for primary endpoint of the DB System to listen on.
     */
    "port"?: number;
    /**
     * The TCP network port on which X Plugin listens for connections. This is the X Plugin equivalent of port. 
     */
    "portX"?: number;
    "backupPolicy"?: CreateMdsBackupPolicyDetails;
    "source"?: CreateMdsDbSystemSourceDetails;
    "maintenance"?: CreateMdsMaintenanceDetails;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    "deletionPolicy"?: CreateMdsDeletionPolicyDetails;
    /**
     * The CIDRs that will be used to bring up the VirtualCloudNetwork (VCN) in OCI. These can only be ipv4 CIDRS. The list of one or more IPv4 CIDR blocks for the VCN that meet the following criteria   - The CIDR blocks must be valid.   - They must not overlap with each other or with the on-premises network CIDR block.   - The number of CIDR blocks must not exceed the limit of CIDR blocks allowed per VCN.   - During dry-run only one of ociNetworkCidrs, mcvcnId, or networkLinkId is required. 
     */
    "ociNetworkCidrs"?: Array<string>;
    /**
     * Whether to run the DB System with InnoDB Redo Logs and the Double Write Buffer enabled or disabled, and whether to enable or disable syncing of the Binary Logs. 
     */
    "crashRecovery"?: CreateMdsDbSystemDetailsCrashRecoveryEnum;
    /**
     * The Azure unique identifier of the MC VCN resources. During creation only one of mcvcnId or networkLinkId is required. 
     */
    "mcvcnId"?: string;
}

export type CreateMdsDbSystemDetailsCrashRecoveryEnum = "ENABLED" | "DISABLED";
/**
 * Parameters detailing how to provision the initial data of the system. 
 */
export interface CreateMdsDbSystemSourceDetails {
    /**
     * The specific source identifier. 
     */
    "sourceType": CreateMdsDbSystemSourceDetailsSourceTypeEnum;
}

export type CreateMdsDbSystemSourceDetailsSourceTypeEnum = "NONE" | "BACKUP" | "PITR" | "IMPORTURL";
/**
 * Policy for how the DB System and related resources should be handled at the time of its deletion. 
 */
export interface CreateMdsDeletionPolicyDetails {
    /**
     * Specifies if any automatic backups created for a DB System should be retained or deleted when the DB System is deleted. 
     */
    "automaticBackupRetention"?: CreateMdsDeletionPolicyDetailsAutomaticBackupRetentionEnum;
    /**
     * Specifies whether or not a backup is taken when the DB System is deleted.   REQUIRE_FINAL_BACKUP: a backup is taken if the DB System is deleted.   SKIP_FINAL_BACKUP: a backup is not taken if the DB System is deleted. 
     */
    "finalBackup"?: CreateMdsDeletionPolicyDetailsFinalBackupEnum;
    /**
     * Specifies whether the DB System can be deleted. Set to true to prevent deletion, false (default) to allow. 
     */
    "isDeleteProtected"?: boolean;
}

export type CreateMdsDeletionPolicyDetailsAutomaticBackupRetentionEnum = "DELETE" | "RETAIN";
export type CreateMdsDeletionPolicyDetailsFinalBackupEnum = "SKIP_FINAL_BACKUP" | "REQUIRE_FINAL_BACKUP";
/**
 * The Maintenance Policy for the DB System.
 */
export interface CreateMdsMaintenanceDetails {
    /**
     * The start of the 2 hour maintenance window.  This string is of the format: \"{day-of-week} {time-of-day}\".  \"{day-of-week}\" is a case-insensitive string like \"mon\", \"tue\", &c.  \"{time-of-day}\" is the \"Time\" portion of an RFC3339-formatted timestamp. Any second or sub-second time data will be truncated to zero.  If you set the read replica maintenance window to \"\" or if not specified, the read replica is set same as the DB system maintenance window. 
     */
    "windowStartTime": string;
}

/**
 * Information for creating a MC VCN.
 */
export interface CreateMultiCloudVirtualNetworkDetails {
    /**
     * MultiCloud VCN Identifier
     */
    "name"?: string;
    /**
     * Option based on how the MultiCloudVirtualNetwork should be created.
     */
    "createOption": CreateMultiCloudVirtualNetworkDetailsCreateOptionEnum;
}

export type CreateMultiCloudVirtualNetworkDetailsCreateOptionEnum = "CREATE_NEW_NETWORK" | "REGISTER_EXISTING_OCI_NETWORK";
/**
 * Details for the creating NetworkLink details 
 */
export interface CreateNetworkLinkDetails {
    /**
     * NetworkLink Identifier
     */
    "name": string;
    /**
     * The CIDRs that will be used to bring up the VirtualCloudNetwork (VCN) in OCI. These can only be ipv4 CIDRS. The list of one or more IPv4 CIDR blocks for the VCN that meet the following criteria   - The CIDR blocks must be valid.   - They must not overlap with each other or with the on-premises network CIDR block.   - The number of CIDR blocks must not exceed the limit of CIDR blocks allowed per VCN. 
     */
    "ociNetworkCidrs"?: Array<string>;
    /**
     * The (Virtual Network) VNET Id where the Azure client will reside.
     */
    "azureAttachedNetworkIds": Array<string>;
    /**
     * MultiCloudVirtualNetwork Identifiers
     */
    "multiCloudVirtualNetworkIds"?: Array<string>;
    "routingOptions"?: RoutingOptions;
}

/**
 * Parameters for creating a pluggable database in a specified container database (CDB).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreatePluggableDatabaseDetails {
    /**
     * The name for the pluggable database (PDB). The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The name for the pluggable database (PDB). The name is unique in the context of a [container database](#/en/database/latest/Database/). The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. The pluggable database name should not be same as the container database name.
     */
    "pdbName": string;
    /**
     * The unqiue identifier of the CDB
     */
    "containerDatabaseId": string;
    /**
     * A strong password for PDB Admin. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numbers, and two special characters. The special characters must be _, \\#, or -.
     */
    "pdbAdminPassword"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The existing TDE wallet password of the CDB.
     */
    "tdeWalletPassword"?: string;
    /**
     * The locked mode of the pluggable database admin account. If false, the user needs to provide the PDB Admin Password to connect to it. If true, the pluggable database will be locked and user cannot login to it. 
     */
    "shouldPdbAdminAccountBeLocked"?: boolean;
}

/**
 * Details for creating a database.  Warning: Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateVmdbDatabaseDetails {
    /**
     * A strong password for SYS, SYSTEM, and PDB Admin. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numbers, and two special characters. The special characters must be _, \\#, or -.
     */
    "adminPassword": string;
    /**
     * The character set for the database.  The default is AL32UTF8. Allowed values are:  AL32UTF8, AR8ADOS710, AR8ADOS720, AR8APTEC715, AR8ARABICMACS, AR8ASMO8X, AR8ISO8859P6, AR8MSWIN1256, AR8MUSSAD768, AR8NAFITHA711, AR8NAFITHA721, AR8SAKHR706, AR8SAKHR707, AZ8ISO8859P9E, BG8MSWIN, BG8PC437S, BLT8CP921, BLT8ISO8859P13, BLT8MSWIN1257, BLT8PC775, BN8BSCII, CDN8PC863, CEL8ISO8859P14, CL8ISO8859P5, CL8ISOIR111, CL8KOI8R, CL8KOI8U, CL8MACCYRILLICS, CL8MSWIN1251, EE8ISO8859P2, EE8MACCES, EE8MACCROATIANS, EE8MSWIN1250, EE8PC852, EL8DEC, EL8ISO8859P7, EL8MACGREEKS, EL8MSWIN1253, EL8PC437S, EL8PC851, EL8PC869, ET8MSWIN923, HU8ABMOD, HU8CWI2, IN8ISCII, IS8PC861, IW8ISO8859P8, IW8MACHEBREWS, IW8MSWIN1255, IW8PC1507, JA16EUC, JA16EUCTILDE, JA16SJIS, JA16SJISTILDE, JA16VMS, KO16KSC5601, KO16KSCCS, KO16MSWIN949, LA8ISO6937, LA8PASSPORT, LT8MSWIN921, LT8PC772, LT8PC774, LV8PC1117, LV8PC8LR, LV8RST104090, N8PC865, NE8ISO8859P10, NEE8ISO8859P4, RU8BESTA, RU8PC855, RU8PC866, SE8ISO8859P3, TH8MACTHAIS, TH8TISASCII, TR8DEC, TR8MACTURKISHS, TR8MSWIN1254, TR8PC857, US7ASCII, US8PC437, UTF8, VN8MSWIN1258, VN8VN3, WE8DEC, WE8DG, WE8ISO8859P1, WE8ISO8859P15, WE8ISO8859P9, WE8MACROMAN8S, WE8MSWIN1252, WE8NCR4970, WE8NEXTSTEP, WE8PC850, WE8PC858, WE8PC860, WE8ROMAN8, ZHS16CGB231280, ZHS16GBK, ZHT16BIG5, ZHT16CCDC, ZHT16DBT, ZHT16HKSCS, ZHT16MSWIN950, ZHT32EUC, ZHT32SOPS, ZHT32TRIS 
     */
    "characterSet"?: string;
    "dbBackupConfig"?: VmdbDbBackupConfig;
    /**
     * The database name.
     */
    "dbName": string;
    /**
     * The `DB_UNIQUE_NAME` of the Oracle Database being backed up.
     */
    "dbUniqueName"?: string;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The national character set for the database.  The default is AL16UTF16. Allowed values are: AL16UTF16 or UTF8. 
     */
    "ncharacterSet"?: string;
    /**
     * The name of the pluggable database. The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. Pluggable database should not be same as database name.
     */
    "pdbName"?: string;
    /**
     * Specifies a prefix for the `Oracle SID` of the database to be created. 
     */
    "sidPrefix"?: string;
    /**
     * The optional password to open the TDE wallet. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numeric, and two special characters. The special characters must be _, \\#, or -.
     */
    "tdeWalletPassword"?: string;
}

/**
 * CreateVmdbDatabaseFromBackupDetails
 */
export interface CreateVmdbDatabaseFromBackupDetails {
    /**
     * A strong password for SYS, SYSTEM, PDB Admin and TDE Wallet. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numbers, and two special characters. The special characters must be _,
     */
    "adminPassword": string;
    /**
     * The backup OCID.
     */
    "backupId": string;
    /**
     * The password to open the TDE wallet.
     */
    "backupTDEPassword"?: string;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The display name of the database to be created from the backup. It must begin with an alphabetic character and can contain a maximum of eight alphanumeric characters. Special characters are not permitted.
     */
    "dbName"?: string;
    /**
     * The DB_UNIQUE_NAME of the Oracle Database being backed up.
     */
    "dbUniqueName"?: string;
    /**
     * Specifies a prefix for the Oracle SID of the database to be created.
     */
    "sidPrefix"?: string;
}

/**
 * Details for creating a Database Home.  Warning: Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateVmdbDbHomeDetails {
    "database": CreateVmdbDatabaseDetails;
    /**
     * The Oracle Database version.
     */
    "dbVersion": string;
    /**
     * The user-provided name of the Database Home.
     */
    "name"?: string;
}

/**
 * Details for creating a Database Home if you are creating a database by restoring from a database backup.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateVmdbDbHomeFromBackupDetails {
    "database": CreateVmdbDatabaseFromBackupDetails;
    /**
     * The user-friendly name for the DB system. The name does not have to be unique.
     */
    "name"?: string;
}

/**
 * Details for the create Db System operation. Applies to VMDB instances only. 
 */
export interface CreateVmdbDbSystemBase {
    /**
     * The source of the database: Use `NONE` for creating a new database. Use `DB_BACKUP` for creating a new database by restoring from a backup. The default is `NONE`. 
     */
    "source": CreateVmdbDbSystemBaseSourceEnum;
    /**
     * The name for the DbSystem. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The CIDRs that will be used to bring up the VirtualCloudNetwork (VCN) in OCI. These can only be ipv4 CIDRS. The list of one or more IPv4 CIDR blocks for the VCN that meet the following criteria   - The CIDR blocks must be valid.   - They must not overlap with each other or with the on-premises network CIDR block.   - The number of CIDR blocks must not exceed the limit of CIDR blocks allowed per VCN.   - During dry-run only one of ociNetworkCidrs, mcvcnId, or networkLinkId is required. 
     */
    "ociNetworkCidrs"?: Array<string>;
    /**
     * The Azure unique identifier of the NetworkLink. During creation only one of mcvcnId or networkLinkId is required. 
     */
    "networkLinkId"?: string;
    /**
     * The number of CPU cores to enable for a DbSystem. Valid values depend on the specified shape:  - VM.Standard.E4.Flex - Specify any thing from 1 to 64. 
     */
    "cpuCoreCount": number;
    /**
     * The public key portion of one or more key pairs used for SSH access to the cloud VM cluster.
     */
    "sshPublicKeys": Array<string>;
    /**
     * The hostname for the DbSystem. The hostname must begin with an alphabetic character, and can contain alphanumeric characters and hyphens (-). The maximum length of the hostname is 16 characters for virtual machine DB systems.  The maximum length of the combined hostname and domain is 63 characters.  **Note:** The hostname must be unique within the subnet. If it is not unique, the DbSystem will fail to provision. 
     */
    "hostname": string;
    /**
     * The shape of the DB system. The shape determines resources allocated to the DB system.  For virtual machine shapes, the number of CPU cores and memory 
     */
    "shape": string;
    "dbSystemOptions"?: VmdbCreateDbSystemOptions;
    /**
     * Size (in GB) of the initial data volume that will be created and attached to a virtual machine DB system.
     */
    "initialDataStorageSizeInGBs": number;
    /**
     * The number of nodes in the DB system. For RAC DB systems, the value is greater than 1.
     */
    "nodeCount": number;
    /**
     * The Azure unique identifier of the MC VCN resources. During creation only one of mcvcnId or networkLinkId is required. 
     */
    "mcvcnId"?: string;
    /**
     * The block storage volume performance level. Valid values are BALANCED and HIGH_PERFORMANCE. HIGH_PERFORMANCE is for E4.Flex shapes only. See Block Volume Performance for more information.
     */
    "storageVolumePerformanceMode"?: CreateVmdbDbSystemBaseStorageVolumePerformanceModeEnum;
}

export type CreateVmdbDbSystemBaseSourceEnum = "NONE" | "DB_BACKUP";
export type CreateVmdbDbSystemBaseStorageVolumePerformanceModeEnum = "BALANCED" | "HIGH_PERFORMANCE";
/**
 * Parameters for creating a pluggable database in a specified container database (CDB).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateVmdbPluggableDatabaseDetails {
    /**
     * The name for the pluggable database (PDB). The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The name for the pluggable database (PDB). The name is unique in the context of a [container database](#/en/database/latest/Database/). The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. The pluggable database name should not be same as the container database name.
     */
    "pdbName": string;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The unique identifier of the CDB
     */
    "containerDatabaseId": string;
    /**
     * A strong password for PDB Admin. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numbers, and two special characters. The special characters must be _, \\#, or -.
     */
    "pdbAdminPassword"?: string;
    /**
     * The existing TDE wallet password of the CDB.
     */
    "tdeWalletPassword"?: string;
    /**
     * The locked mode of the pluggable database admin account. If false, the user needs to provide the PDB Admin Password to connect to it. If true, the pluggable database will be locked and user cannot login to it. 
     */
    "shouldPdbAdminAccountBeLocked"?: boolean;
}

/**
 * Customer contact information that will be used by Oracle to provide notifications needed by database and infrastructure administrators. 
 */
export interface CustomerContact {
    /**
     * The email address used by Oracle to send notifications regarding databases and infrastructure.
     */
    "email"?: string;
}

/**
 * EXACS Database
 */
export interface DatabaseBase {
    /**
     * The specific cloud provider. 
     */
    "cloudProvider": DatabaseBaseCloudProviderEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: DatabaseBaseLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The fully qualified Azure resource ID of the database.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: DatabaseBaseStatusEnum;
    /**
     * The name for the database. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The character set for the database.
     */
    "characterSet"?: string;
    /**
     * The national character set for the database.
     */
    "ncharacterSet"?: string;
    /**
     * The fully qualified Azure resource ID of the Database Home.
     */
    "dbHomeId"?: string;
    /**
     * The fully qualified Azure resource ID of the VM cluster.
     */
    "vmClusterId"?: string;
    /**
     * The database name.
     */
    "dbName": string;
    /**
     * The name of the pluggable database. The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. Pluggable database should not be same as database name.
     */
    "pdbName"?: string;
    /**
     * The database workload type.
     */
    "dbWorkload"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * A system-generated name for the database to ensure uniqueness within an Oracle Data Guard group (a primary database and its standby databases). The unique name cannot be changed. 
     */
    "dbUniqueName": string;
    /**
     * Additional information about the current lifecycle state.
     */
    "lifecycleDetails"?: string;
    /**
     * The current state of the database.
     */
    "lifecycleState": DatabaseBaseLifecycleStateEnum;
    /**
     * The date and time the database was created.
     */
    "timeCreated"?: Date;
    /**
     * The date and time when the latest database backup was created.
     */
    "lastBackupTimestamp"?: Date;
    "dbBackupConfig"?: DbBackupConfig;
    /**
     * The database domain. In a distributed database system, DB_DOMAIN specifies the logical location of the database within the network structure.
     */
    "dbDomain"?: string;
    "connectionStrings"?: DatabaseConnectionStrings;
    /**
     * The date and time when the latest remote automatic incremental database backup was created.
     */
    "lastRemoteBackupTimestamp"?: Date;
    /**
     * Point in time recovery timeStamp of the source database at which cloned database system is cloned from the source database system, as described in [RFC 3339](https://tools.ietf.org/rfc/rfc3339)
     */
    "sourceDatabasePointInTimeRecoveryTimestamp"?: Date;
    /**
     * True if the database is a container database.
     */
    "isCdb"?: boolean;
    /**
     * Specifies a prefix for the `Oracle SID` of the database to be created. 
     */
    "sidPrefix"?: string;
}

export type DatabaseBaseCloudProviderEnum = "AZURE";
export type DatabaseBaseLastOperationStatusEnum = "Succeeded" | "Failed";
export type DatabaseBaseStatusEnum = "Provisioning" | "Updating" | "BackupInProgress" | "Upgrading" | "Converting" | "Terminating" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type DatabaseBaseLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "UPDATING" | "BACKUP_IN_PROGRESS" | "UPGRADING" | "CONVERTING" | "TERMINATING" | "TERMINATED" | "RESTORE_FAILED" | "FAILED";
/**
 * Connection strings to connect to an Oracle Database. 
 */
export interface DatabaseConnectionStrings {
    /**
     * Host name based CDB Connection String.
     */
    "cdbDefault"?: string;
    /**
     * IP based CDB Connection String.
     */
    "cdbIpDefault"?: string;
    /**
     * All connection strings to use to connect to the Database.
     */
    "allConnectionStrings"?: { [key: string]: string; };
}

/**
 * Details for the database deployment. 
 */
export interface DatabaseDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": DatabaseDeploymentDetailsKindEnum;
}

export type DatabaseDeploymentDetailsKindEnum = "CREATE";
/**
 * An Oracle Database on a bare metal or virtual machine DB system. For more information, see [Bare Metal and Virtual Machine DB Systems](/Content/Database/Concepts/overview.htm).  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface DatabaseSummary {
    /**
     * The fully qualified Azure resource ID of the database.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: DatabaseSummaryStatusEnum;
    /**
     * The name for the database. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The character set for the database.
     */
    "characterSet"?: string;
    /**
     * The national character set for the database.
     */
    "ncharacterSet"?: string;
    /**
     * The fully qualified Azure resource ID of the Database Home.
     */
    "dbHomeId"?: string;
    /**
     * The fully qualified Azure resource ID of the VM cluster.
     */
    "vmClusterId"?: string;
    /**
     * The database name.
     */
    "dbName": string;
    /**
     * The name of the pluggable database. The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. Pluggable database should not be same as database name.
     */
    "pdbName"?: string;
    /**
     * The database workload type.
     */
    "dbWorkload"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * A system-generated name for the database to ensure uniqueness within an Oracle Data Guard group (a primary database and its standby databases). The unique name cannot be changed. 
     */
    "dbUniqueName": string;
    /**
     * Additional information about the current lifecycle state.
     */
    "lifecycleDetails"?: string;
    /**
     * The current state of the database.
     */
    "lifecycleState": DatabaseSummaryLifecycleStateEnum;
    /**
     * The date and time the database was created.
     */
    "timeCreated"?: Date;
    /**
     * The date and time when the latest database backup was created.
     */
    "lastBackupTimestamp"?: Date;
    "dbBackupConfig"?: DbBackupConfig;
    /**
     * The database domain. In a distributed database system, DB_DOMAIN specifies the logical location of the database within the network structure.
     */
    "dbDomain"?: string;
    /**
     * The Connection strings used to connect to the Oracle Database.
     */
    "connectionStrings"?: DatabaseConnectionStrings;
    /**
     * The date and time when the latest remote automatic incremental database backup was created.
     */
    "lastRemoteBackupTimestamp"?: Date;
    /**
     * Point in time recovery timeStamp of the source database at which cloned database system is cloned from the source database system, as described in [RFC 3339](https://tools.ietf.org/rfc/rfc3339)
     */
    "sourceDatabasePointInTimeRecoveryTimestamp"?: Date;
    /**
     * True if the database is a container database.
     */
    "isCdb"?: boolean;
    /**
     * Specifies a prefix for the `Oracle SID` of the database to be created. 
     */
    "sidPrefix"?: string;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: DatabaseSummaryLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type DatabaseSummaryStatusEnum = "Provisioning" | "Updating" | "BackupInProgress" | "Upgrading" | "Converting" | "Terminating" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type DatabaseSummaryLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "UPDATING" | "BACKUP_IN_PROGRESS" | "UPGRADING" | "CONVERTING" | "TERMINATING" | "TERMINATED" | "RESTORE_FAILED" | "FAILED";
export type DatabaseSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * A list of database resources. 
 */
export interface DatabaseSummaryCollection {
    /**
     * An array of database resources.
     */
    "items": Array<DatabaseSummary>;
}

/**
 * Day of the week.
 */
export interface DayOfWeek {
    /**
     * Name of the day of the week.
     */
    "name": DayOfWeekNameEnum;
}

export type DayOfWeekNameEnum = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
/**
 * Backup Options To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm). 
 */
export interface DbBackupConfig {
    /**
     * If set to true, configures automatic backups. If you previously used RMAN or dbcli to configure backups and then you switch to using the Console or the API for backups, a new backup configuration is created and associated with your database. This means that you can no longer rely on your previously configured unmanaged backups to work.
     */
    "autoBackupEnabled"?: boolean;
    /**
     * Number of days between the current and the earliest point of recoverability covered by automatic backups. This value applies to automatic backups only. After a new automatic backup has been created, Oracle removes old automatic backups that are created before the window. When the value is updated, it is applied to all existing automatic backups. 
     */
    "recoveryWindowInDays"?: number;
    /**
     * Time window selected for initiating automatic backup for the database system. There are twelve available two-hour time windows. If no option is selected, a start time between 12:00 AM to 7:00 AM in the region of the database is automatically chosen. For example, if the user selects SLOT_TWO from the enum list, the automatic backup job will start in between 2:00 AM (inclusive) to 4:00 AM (exclusive).  Example: `SLOT_TWO` 
     */
    "autoBackupWindow"?: DbBackupConfigAutoBackupWindowEnum;
}

export type DbBackupConfigAutoBackupWindowEnum = "SLOT_ONE" | "SLOT_TWO" | "SLOT_THREE" | "SLOT_FOUR" | "SLOT_FIVE" | "SLOT_SIX" | "SLOT_SEVEN" | "SLOT_EIGHT" | "SLOT_NINE" | "SLOT_TEN" | "SLOT_ELEVEN" | "SLOT_TWELVE";
/**
 * A directory where Oracle Database software is installed. A bare metal or Exadata DB system can have multiple Database Homes and each Database Home can run a different supported version of Oracle Database. A virtual machine DB system can have only one Database Home. For more information, see [Bare Metal and Virtual Machine DB Systems](/Content/Database/Concepts/overview.htm) and [Exadata DB Systems](/Content/Database/Concepts/exaoverview.htm).  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface DbHomeSummary {
    /**
     * The unique of the Database Home.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: DbHomeSummaryStatusEnum;
    /**
     * The user-provided name for the Database Home. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The user-provided name for the Database Home. The name does not need to be unique.
     */
    "displayName"?: string;
    /**
     * The current state of the Database Home.
     */
    "lifecycleState": DbHomeSummaryLifecycleStateEnum;
    /**
     * The fully qualified Azure resource ID of the VM cluster.
     */
    "vmClusterId"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The Oracle Database version.
     */
    "dbVersion": string;
    /**
     * The location of the Oracle Database Home.
     */
    "dbHomeLocation": string;
    /**
     * Additional information about the current lifecycle state.
     */
    "lifecycleDetails"?: string;
    /**
     * The date and time the Database Home was created.
     */
    "timeCreated"?: Date;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: DbHomeSummaryLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type DbHomeSummaryStatusEnum = "Provisioning" | "Updating" | "Terminating" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type DbHomeSummaryLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "UPDATING" | "TERMINATING" | "TERMINATED" | "FAILED";
export type DbHomeSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * A list of Database Home resources. 
 */
export interface DbHomeSummaryCollection {
    /**
     * An array of Database Home resources.
     */
    "items": Array<DbHomeSummary>;
}

/**
 * A server where Oracle Database software is running.  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface DbNodeSummary {
    /**
     * The fully qualified Azure resource ID of the database node.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The user-friendly name for the database node. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: DbNodeSummaryStatusEnum;
    /**
     * The host IP address associated with the database node.  **Note:** Applies only to Exadata Cloud Service. 
     */
    "hostIp"?: string;
    /**
     * The current state of the database node.
     */
    "lifecycleState": DbNodeSummaryLifecycleStateEnum;
    /**
     * The host name for the database node.
     */
    "hostname"?: string;
    /**
     * The date and time that the database node was created.
     */
    "timeCreated": Date;
    /**
     * The size (in GB) of the block storage volume allocation for the DB system. This attribute applies only for virtual machine DB systems. 
     */
    "softwareStorageSizeInGB"?: number;
    /**
     * The type of database node maintenance.
     */
    "maintenanceType"?: DbNodeSummaryMaintenanceTypeEnum;
    /**
     * Start date and time of maintenance window.
     */
    "timeMaintenanceWindowStart"?: Date;
    /**
     * End date and time of maintenance window.
     */
    "timeMaintenanceWindowEnd"?: Date;
    /**
     * Additional information about the planned maintenance.
     */
    "additionalDetails"?: string;
    /**
     * The number of CPU cores enabled on the Db node.
     */
    "cpuCoreCount"?: number;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The allocated memory in GBs on the Db node.
     */
    "memorySizeInGBs"?: number;
    /**
     * The allocated local node storage in GBs on the Db node.
     */
    "dbNodeStorageSizeInGBs"?: number;
    /**
     * The fully qualified Azure resource ID of the VM cluster.
     */
    "vmClusterId"?: string;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: DbNodeSummaryLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type DbNodeSummaryStatusEnum = "Provisioning" | "Updating" | "Stopping" | "Starting" | "Terminating" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type DbNodeSummaryLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "UPDATING" | "STOPPING" | "STOPPED" | "STARTING" | "TERMINATING" | "TERMINATED" | "FAILED";
export type DbNodeSummaryMaintenanceTypeEnum = "VMDB_REBOOT_MIGRATION";
export type DbNodeSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * A list of database node resources. 
 */
export interface DbNodeSummaryCollection {
    /**
     * An array of database node resources.
     */
    "items": Array<DbNodeSummary>;
}

/**
 * Details of possible initial size in GB for DATA and RECO for shape types(AMD/INTEL) and Storage management(ASM/LVM) 
 */
export interface DbSystemInitialStorageSizeSummary {
    /**
     * The storage option used in DB system. ASM - Automatic storage management LVM - Logical Volume management 
     */
    "storageManagement": DbSystemInitialStorageSizeSummaryStorageManagementEnum;
    /**
     * vm shape platform type
     */
    "shapeType": DbSystemInitialStorageSizeSummaryShapeTypeEnum;
    /**
     * List of storage disk details.
     */
    "storageSizeDetails": Array<StorageSizeDetails>;
    /**
     * List of storage disk details available for launches from backup.
     */
    "launchFromBackupStorageSizeDetails"?: Array<StorageSizeDetails>;
}

export type DbSystemInitialStorageSizeSummaryStorageManagementEnum = "ASM" | "LVM";
export type DbSystemInitialStorageSizeSummaryShapeTypeEnum = "AMD" | "INTEL" | "INTEL_FLEX_X9" | "AMPERE_FLEX_A1";
/**
 * An array of supported initial storage sizes. 
 */
export interface DbSystemInitialStorageSizeSummaryCollection {
    /**
     * An array of supported initial storage sizes..
     */
    "items": Array<DbSystemInitialStorageSizeSummary>;
}

/**
 * The shape of the DB system. The shape determines resources to allocate to the DB system - CPU cores and memory for VM shapes; CPU cores, memory and storage for non-VM (or bare metal) shapes.  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm). 
 */
export interface DbSystemShapeSummary {
    /**
     * The name of the shape used for the DB system.
     */
    "name": string;
    /**
     * The family of the shape used for the DB system.
     */
    "shapeFamily"?: string;
    /**
     * Deprecated. Use `name` instead of `shape`.
     */
    "shape"?: string;
    /**
     * The maximum number of CPU cores that can be enabled on the DB system for this shape.
     */
    "availableCoreCount": number;
    /**
     * The minimum number of CPU cores that can be enabled on the DB system for this shape.
     */
    "minimumCoreCount"?: number;
    /**
     * The runtime minimum number of CPU cores that can be enabled on the DB system for this shape.
     */
    "runtimeMinimumCoreCount"?: number;
    /**
     * The discrete number by which the CPU core count for this shape can be increased or decreased.
     */
    "coreCountIncrement"?: number;
    /**
     * The minimum number of Exadata storage servers available for the Exadata infrastructure.
     */
    "minStorageCount"?: number;
    /**
     * The maximum number of Exadata storage servers available for the Exadata infrastructure.
     */
    "maxStorageCount"?: number;
    /**
     * The maximum data storage available per storage server for this shape. Only applicable to ExaCC Elastic shapes.
     */
    "availableDataStoragePerServerInTBs"?: number;
    /**
     * The maximum memory available per database node for this shape. Only applicable to ExaCC Elastic shapes.
     */
    "availableMemoryPerNodeInGBs"?: number;
    /**
     * The maximum Db Node storage available per database node for this shape. Only applicable to ExaCC Elastic shapes.
     */
    "availableDbNodePerNodeInGBs"?: number;
    /**
     * The minimum number of CPU cores that can be enabled per node for this shape.
     */
    "minCoreCountPerNode"?: number;
    /**
     * The maximum memory that can be enabled for this shape.
     */
    "availableMemoryInGBs"?: number;
    /**
     * The minimum memory that need be allocated per node for this shape.
     */
    "minMemoryPerNodeInGBs"?: number;
    /**
     * The maximum Db Node storage that can be enabled for this shape.
     */
    "availableDbNodeStorageInGBs"?: number;
    /**
     * The minimum Db Node storage that need be allocated per node for this shape.
     */
    "minDbNodeStoragePerNodeInGBs"?: number;
    /**
     * The maximum DATA storage that can be enabled for this shape.
     */
    "availableDataStorageInTBs"?: number;
    /**
     * The minimum data storage that need be allocated for this shape.
     */
    "minDataStorageInTBs"?: number;
    /**
     * The minimum number of database nodes available for this shape.
     */
    "minimumNodeCount"?: number;
    /**
     * The maximum number of database nodes available for this shape.
     */
    "maximumNodeCount"?: number;
    /**
     * The maximum number of CPU cores per database node that can be enabled for this shape. Only applicable to the flex Exadata shape and ExaCC Elastic shapes.
     */
    "availableCoreCountPerNode"?: number;
}

/**
 * A list of DB system shape resources. 
 */
export interface DbSystemShapeSummaryCollection {
    /**
     * An array of DB system shape resources.
     */
    "items": Array<DbSystemShapeSummary>;
}

/**
 * The Oracle Database software version. 
 */
export interface DbVersionSummary {
    /**
     * A valid Oracle Database version.
     */
    "version": string;
    /**
     * True if this version of the Oracle Database software is the latest version for a release.
     */
    "isLatestForMajorVersion"?: boolean;
    /**
     * True if this version of the Oracle Database software supports pluggable databases.
     */
    "supportsPdb"?: boolean;
    /**
     * True if this version of the Oracle Database software is the preview version.
     */
    "isPreviewDbVersion"?: boolean;
    /**
     * True if this version of the Oracle Database software is supported for Upgrade.
     */
    "isUpgradeSupported"?: boolean;
}

/**
 * A list of Oracle Database software version resources. 
 */
export interface DbVersionSummaryCollection {
    /**
     * An array of Oracle Database software version resources.
     */
    "items": Array<DbVersionSummary>;
}

/**
 * Deployment details.
 */
export interface Deployment {
    /**
     * Deployment Id.
     */
    "id": string;
    /**
     * Deployment name.
     */
    "name": string;
    /**
     * The type of deployment. 
     */
    "deploymentType": DeploymentDeploymentTypeEnum;
    "parameters"?: DeploymentParametersDetails;
    /**
     * Array of resources being deployed
     */
    "resources"?: Array<DeploymentResource>;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The time the Deployment was created. An RFC3339 formatted datetime string
     */
    "timeCreated": Date;
    /**
     * The time the Deployment was accepted. An RFC3339 formatted datetime string
     */
    "timeAccepted": Date;
    /**
     * The time the Deployment was finished. An RFC3339 formatted datetime string
     */
    "timeFinished"?: Date;
    /**
     * The current state of the Deployment.
     */
    "lifecycleState": DeploymentLifecycleStateEnum;
}

export type DeploymentDeploymentTypeEnum = "EXADATA" | "VMDB" | "ADBD" | "ADBS" | "HEATWAVE";
export type DeploymentLifecycleStateEnum = "ACCEPTED" | "IN_PROGRESS" | "FAILED" | "SUCCEEDED" | "CANCELING" | "CANCELED";
/**
 * Results of a deployment search.
 */
export interface DeploymentCollection {
    /**
     * List of deployments.
     */
    "items": Array<DeploymentSummary>;
}

/**
 * The information about new deployment parameters. 
 */
export interface DeploymentParametersDetails {
    /**
     * The type descriminator. 
     */
    "kind": DeploymentParametersDetailsKindEnum;
}

export type DeploymentParametersDetailsKindEnum = "EXADATA" | "VMDB" | "ADBD" | "ADBS" | "HEATWAVE";
/**
 * Exadata deployment details.
 */
export interface DeploymentResource {
    /**
     * resource Id.
     */
    "id"?: string;
    /**
     * resource type
     */
    "type"?: string;
    /**
     * Status of the resource being deployed.
     */
    "status"?: DeploymentResourceStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type DeploymentResourceStatusEnum = "QUEUED" | "IN_PROGRESS" | "CREATED" | "FAILED";
/**
 * Deployment summary.
 */
export interface DeploymentSummary {
    /**
     * Deployment Id.
     */
    "id": string;
    /**
     * Deployment name.
     */
    "name": string;
    /**
     * The type of deployment. 
     */
    "deploymentType": DeploymentSummaryDeploymentTypeEnum;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The time the Deployment was created. An RFC3339 formatted datetime string
     */
    "timeCreated": Date;
    /**
     * The time the Deployment was accepted. An RFC3339 formatted datetime string
     */
    "timeAccepted": Date;
    /**
     * The time the Deployment was finished. An RFC3339 formatted datetime string
     */
    "timeFinished"?: Date;
    /**
     * The current state of the Deployment.
     */
    "lifecycleState": string;
}

export type DeploymentSummaryDeploymentTypeEnum = "EXADATA" | "VMDB" | "ADBD" | "ADBS" | "HEATWAVE";
/**
 * Results of a FlexComponent lists. Contains FlexComponentSummary items.
 */
export interface FlexComponentCollection {
    /**
     * An array of FlexComponentSummary resources.
     */
    "items": Array<FlexComponentSummary>;
}

/**
 * The Flex Components for a DB system. The Flex Component determines resources to allocate to the DB system -  CPU cores, memory and storage for Flex shapes.  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm). 
 */
export interface FlexComponentSummary {
    /**
     * The name of the Flex Component used for the DB system.
     */
    "name": string;
    /**
     * The minimum number of CPU cores that can be enabled on the DB Server for this Flex Component.
     */
    "minimumCoreCount"?: number;
    /**
     * The maximum number of CPU cores that can ben enabled on the DB Server for this Flex Component.
     */
    "availableCoreCount"?: number;
    /**
     * The maximum  storage that can be enabled on the Storage Server for this Flex Component.
     */
    "availableDbStorageInGBs"?: number;
}

/**
 * Details to create and download an Oracle Autonomous Database wallet. 
 */
export interface GenerateAdbdDatabaseWalletDetails {
    /**
     * The password to encrypt the keys inside the wallet. The password must be at least 8 characters long and must include at least 1 letter and either 1 numeric character or 1 special character.
     */
    "password": string;
}

/**
 * Details to create and download an Oracle Autonomous Database wallet. 
 */
export interface GenerateAdbsDatabaseWalletDetails {
    /**
     * The password to encrypt the keys inside the wallet. The password must be at least 8 characters long and must include at least 1 letter and either 1 numeric character or 1 special character.
     */
    "password": string;
}

/**
 * The Oracle Grid Infrastructure (GI) version.  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm). 
 */
export interface GiVersionSummary {
    /**
     * A valid Oracle Grid Infrastructure (GI) software version.
     */
    "version": string;
}

/**
 * A list of Oracle Grid Infrastructure (GI) version resources. 
 */
export interface GiVersionSummaryCollection {
    /**
     * An array of Oracle Grid Infrastructure (GI) version resources.
     */
    "items": Array<GiVersionSummary>;
}

/**
 * A HeatWave cluster is a database accelerator for a DB System. 
 */
export interface HeatWaveCluster {
    /**
     * The Azure Id of the parent DB System this HeatWave cluster is attached to.
     */
    "dbSystemId": string;
    /**
     * The shape determines resources to allocate to the HeatWave nodes - CPU cores, memory. 
     */
    "shapeName": string;
    /**
     * The number of analytics-processing compute instances, of the specified shape, in the HeatWave cluster. 
     */
    "clusterSize": number;
    /**
     * A HeatWave node is a compute host that is part of a HeatWave cluster.
     */
    "clusterNodes": Array<HeatWaveNode>;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": HeatWaveClusterStatusEnum;
    /**
     * The date and time the HeatWave cluster was created, as described by [RFC 3339](https://tools.ietf.org/rfc/rfc3339). 
     */
    "timeCreated": Date;
    /**
     * The time the HeatWave cluster was last updated, as described by [RFC 3339](https://tools.ietf.org/rfc/rfc3339). 
     */
    "timeUpdated": Date;
}

export type HeatWaveClusterStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
/**
 * HeatWave cluster memory estimate that can be used to determine a suitable HeatWave cluster size. For each MySQL user table the estimated memory footprint when the table is loaded to the HeatWave cluster memory is returned. 
 */
export interface HeatWaveClusterMemoryEstimate {
    /**
     * The OCID of the DB System the HeatWave cluster memory estimate is associated with. 
     */
    "dbSystemId": string;
    /**
     * Current status of the Work Request generating the HeatWave cluster memory estimate.
     */
    "status": HeatWaveClusterMemoryEstimateStatusEnum;
    /**
     * The date and time that the Work Request to generate the HeatWave cluster memory estimate was issued, as described by [RFC 3339](https://tools.ietf.org/rfc/rfc333).
     */
    "timeCreated": Date;
    /**
     * The date and time that the HeatWave cluster memory estimate was generated, as described by [RFC 3339](https://tools.ietf.org/rfc/rfc333).
     */
    "timeUpdated": Date;
    /**
     * Collection of schemas with estimated memory footprints for MySQL user tables of each schema when loaded to HeatWave cluster memory. 
     */
    "tableSchemas": Array<HeatWaveClusterSchemaMemoryEstimate>;
}

export type HeatWaveClusterMemoryEstimateStatusEnum = "ACCEPTED" | "IN_PROGRESS" | "FAILED" | "SUCCEEDED" | "CANCELING" | "CANCELED";
/**
 * Possible operation status.
 */
export type HeatWaveClusterMemoryEstimateStatus = "ACCEPTED" | "IN_PROGRESS" | "FAILED" | "SUCCEEDED" | "CANCELING" | "CANCELED";
export enum HeatWaveClusterMemoryEstimateStatusValues {
    ACCEPTED = "ACCEPTED",
    IN_PROGRESS = "IN_PROGRESS",
    FAILED = "FAILED",
    SUCCEEDED = "SUCCEEDED",
    CANCELING = "CANCELING",
    CANCELED = "CANCELED"
}

/**
 * Schema with estimated memory footprints for each MySQL user table of the schema when loaded to HeatWave cluster memory. 
 */
export interface HeatWaveClusterSchemaMemoryEstimate {
    /**
     * The name of the schema.
     */
    "schemaName": string;
    /**
     * Estimated memory footprints for MySQL user tables of the schema when loaded to HeatWave cluster memory. 
     */
    "perTableEstimates": Array<HeatWaveClusterTableMemoryEstimate>;
}

/**
 * A summary of a HeatWave cluster. 
 */
export interface HeatWaveClusterSummary {
    /**
     * The shape determines resources to allocate to the HeatWave nodes - CPU cores, memory. 
     */
    "shapeName": string;
    /**
     * The number of analytics-processing compute instances, of the specified shape, in the HeatWave cluster. 
     */
    "clusterSize": number;
    /**
     * Datalake enabled status for the HeatWave cluster.
     */
    "isDatalakeEnabled"?: boolean;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": HeatWaveClusterSummaryStatusEnum;
    /**
     * The date and time the HeatWave cluster was created, as described by [RFC 3339](https://tools.ietf.org/rfc/rfc3339). 
     */
    "timeCreated": Date;
    /**
     * The time the HeatWave cluster was last updated, as described by [RFC 3339](https://tools.ietf.org/rfc/rfc3339). 
     */
    "timeUpdated": Date;
}

export type HeatWaveClusterSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
/**
 * Estimated memory footprint for a MySQL user table when loaded to the HeatWave cluster memory. 
 */
export interface HeatWaveClusterTableMemoryEstimate {
    /**
     * The table name.
     */
    "tableName": string;
    /**
     * The number of columns to be loaded to HeatWave cluster memory. These columns contribute to the analytical memory footprint. 
     */
    "toLoadColumnCount": number;
    /**
     * The number of variable-length columns to be loaded to HeatWave cluster memory. These columns contribute to the analytical memory footprint. 
     */
    "varlenColumnCount": number;
    /**
     * The estimated number of rows in the table. This number was used to derive the analytical memory footprint. 
     */
    "estimatedRowCount": number;
    /**
     * The estimated memory footprint of the table in MBs when loaded to HeatWave cluster memory (null if the table cannot be loaded to the HeatWave cluster). 
     */
    "analyticalFootprintInMbs": number;
    /**
     * Error comment (empty string if no errors occured). 
     */
    "errorComment": string;
}

/**
 * A HeatWave node is a compute host that is part of a HeatWave cluster. 
 */
export interface HeatWaveNode {
    /**
     * The ID of the node within MySQL HeatWave cluster.
     */
    "nodeId": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": HeatWaveNodeStatusEnum;
    /**
     * The date and time the MySQL HeatWave node was created, as described by [RFC 3339](https://tools.ietf.org/rfc/rfc3339). 
     */
    "timeCreated"?: Date;
    /**
     * The date and time the MySQL HeatWave node was updated, as described by [RFC 3339](https://tools.ietf.org/rfc/rfc3339). 
     */
    "timeUpdated"?: Date;
}

export type HeatWaveNodeStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
/**
 * Details for the Heatwave cluster deployment. 
 */
export interface HeatwaveClusterDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": HeatwaveClusterDeploymentDetailsKindEnum;
}

export type HeatwaveClusterDeploymentDetailsKindEnum = "CREATE" | "GET";
/**
 * Details about the support ticket.
 */
export interface Incident {
    /**
     * Unique identifier for the support ticket.
     */
    "id": string;
    "contactList"?: ContactList;
    "subscriptionInformation"?: SubscriptionInformation;
    "ticket"?: Ticket;
    /**
     * The kind of support ticket, such as a technical support request or a limit increase request.
     */
    "problemType"?: IncidentProblemTypeEnum;
    /**
     * The date and time when the issue was encountered.
     */
    "timeEncountered"?: Date;
    /**
     * The incident referrer. This value is often the URL that the customer used when creating the support ticket.
     */
    "referrer"?: string;
}

export type IncidentProblemTypeEnum = "LIMIT" | "TECHNICAL" | "BILLING";
/**
 * List of incident.
 */
export interface IncidentCollection {
    /**
     * Array containing incidents.
     */
    "items": Array<Incident>;
}

/**
 * Details about the item object.
 */
export interface Item {
    /**
     * The kind of support item, such as a technical support item or a limit increase, or an activity related to a ticket.
     */
    "itemType": ItemItemTypeEnum;
    /**
     * The display name of the item. Avoid entering confidential information.
     */
    "name"?: string;
    /**
     * The location of the resource.
     */
    "location"?: string;
}

export type ItemItemTypeEnum = "LIMIT" | "TECHNICAL" | "BILLING" | "ACTIVITY";
/**
 * The item type, such as technical support or activity associated with a ticket
 */
export type ItemType = "LIMIT" | "TECHNICAL" | "BILLING" | "ACTIVITY";
export enum ItemTypeValues {
    LIMIT = "LIMIT",
    TECHNICAL = "TECHNICAL",
    BILLING = "BILLING",
    ACTIVITY = "ACTIVITY"
}

/**
 * List of limit Definition.
 */
export interface LimitDefinitionCollection {
    /**
     * Array containing limit definition items.
     */
    "items": Array<LimitDefinitionSummary>;
}

/**
 * The metadata specific to a resource limit definition.
 */
export interface LimitDefinitionSummary {
    /**
     * The resource limit name. To be used for writing policies (in case of quotas) or other programmatic calls. 
     */
    "name"?: string;
    /**
     * The provider name of the limit.
     */
    "providerName"?: string;
    /**
     * The limit description.
     */
    "description"?: string;
    /**
     * Reflects the scope of the resource limit, whether Global (across all regions), regional, or availability domain-specific. 
     */
    "scopeType"?: LimitDefinitionSummaryScopeTypeEnum;
    /**
     * If true, quota policies can be created on top of this resource limit. 
     */
    "areQuotasSupported"?: boolean;
    /**
     * Reflects whether or not the GetResourceAvailability API is supported for this limit. If not, the API returns an empty JSON response. 
     */
    "isResourceAvailabilitySupported"?: boolean;
    /**
     * Indicates if the limit has been deprecated. 
     */
    "isDeprecated"?: boolean;
    /**
     * Indicates if the customer can request a limit increase for this resource. 
     */
    "isEligibleForLimitIncrease"?: boolean;
    /**
     * The limit for this resource has a dynamic value that is based on consumption across all OCI services. 
     */
    "isDynamic"?: boolean;
}

export type LimitDefinitionSummaryScopeTypeEnum = "GLOBAL" | "LOCATION";
/**
 * Reflects the scope of the resource limit, whether Global (across all regions), regional, or availability domain-specific. 
 */
export type LimitScope = "GLOBAL" | "LOCATION";
export enum LimitScopeValues {
    GLOBAL = "GLOBAL",
    LOCATION = "LOCATION"
}

/**
 * List of limit value.
 */
export interface LimitValueCollection {
    /**
     * Array containing limit value items.
     */
    "items": Array<LimitValueSummary>;
}

/**
 * The value of a specific resource limit.
 */
export interface LimitValueSummary {
    /**
     * The Azure Subscription Id 
     */
    "subscriptionId"?: string;
    /**
     * The resource limit name. To be used for writing policies (in case of quotas) or other programmatic calls. 
     */
    "name"?: string;
    /**
     * The scope type of the limit. 
     */
    "scopeType"?: LimitValueSummaryScopeTypeEnum;
    /**
     * The resource limit value.
     */
    "value"?: number;
}

export type LimitValueSummaryScopeTypeEnum = "GLOBAL" | "LOCATION";
/**
 * Parameters for cloning a pluggable database (PDB) within the same database (CDB).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface LocalClonePluggableDatabaseDetails {
    /**
     * The name for the pluggable database (PDB). The name is unique in the context of a container database. The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. The pluggable database name should not be same as the container database name.
     */
    "clonedPdbName": string;
    /**
     * A strong password for PDB Admin. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numbers, and two special characters. The special characters must be _, \\#, or -.
     */
    "pdbAdminPassword"?: string;
    /**
     * The existing TDE wallet password of the CDB.
     */
    "targetTdeWalletPassword"?: string;
    /**
     * The locked mode of the pluggable database admin account. If false, the user needs to provide the PDB Admin Password to connect to it. If true, the pluggable database will be locked and user cannot login to it. 
     */
    "shouldPdbAdminAccountBeLocked"?: boolean;
}

/**
 * The scheduling details for the quarterly maintenance window. Patching and system updates take place during the maintenance window. 
 */
export interface MaintenanceWindow {
    /**
     * The maintenance window scheduling preference.
     */
    "preference": MaintenanceWindowPreferenceEnum;
    /**
     * Months during the year when maintenance should be performed.
     */
    "months"?: Array<Month>;
    /**
     * Weeks during the month when maintenance should be performed. Weeks start on the 1st, 8th, 15th, and 22nd days of the month, and have a duration of 7 days. Weeks start and end based on calendar dates, not days of the week. For example, to allow maintenance during the 2nd week of the month (from the 8th day to the 14th day of the month), use the value 2. Maintenance cannot be scheduled for the fifth week of months that contain more than 28 days. Note that this parameter works in conjunction with the  daysOfWeek and hoursOfDay parameters to allow you to specify specific days of the week and hours that maintenance will be performed. 
     */
    "weeksOfMonth"?: Array<number>;
    /**
     * Days during the week when maintenance should be performed.
     */
    "daysOfWeek"?: Array<DayOfWeek>;
    /**
     * The window of hours during the day when maintenance should be performed. The window is a 4 hour slot. Valid values are - 0 - represents time slot 0:00 - 3:59 UTC - 4 - represents time slot 4:00 - 7:59 UTC - 8 - represents time slot 8:00 - 11:59 UTC - 12 - represents time slot 12:00 - 15:59 UTC - 16 - represents time slot 16:00 - 19:59 UTC - 20 - represents time slot 20:00 - 23:59 UTC
     */
    "hoursOfDay"?: Array<number>;
    /**
     * Lead time window allows user to set a lead time to prepare for a down time. The lead time is in weeks and valid value is between 1 to 4. 
     */
    "leadTimeInWeeks"?: number;
}

export type MaintenanceWindowPreferenceEnum = "NO_PREFERENCE" | "CUSTOM_PREFERENCE";
/**
 * A full or incremental copy of a DB System which can be used to create a new DB System or recover a DB System.  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm). 
 */
export interface MdsBackup {
    /**
     * The Azure Resource Id of the backup.
     */
    "id": string;
    /**
     * A user-supplied display name for the backup.
     */
    "name"?: string;
    /**
     * A user-supplied display name for the backup.
     */
    "displayName"?: string;
    /**
     * A user-supplied description for the backup.
     */
    "description"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * The time the backup record was created.
     */
    "timeCreated": Date;
    /**
     * The time at which the backup was updated.
     */
    "timeUpdated": Date;
    /**
     * The type of backup.
     */
    "backupType": MdsBackupBackupTypeEnum;
    /**
     * Indicates how the backup was created: manually, automatic, or by an Operator. 
     */
    "creationType": MdsBackupCreationTypeEnum;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": MdsBackupStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus": MdsBackupLastOperationStatusEnum;
    /**
     * Detailed message for the status
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The Azure Resource Id of the database.
     */
    "dbSystemId": string;
    "dbSystemSnapshot"?: MdsDbSystemSnapshot;
    /**
     * The size of the backup in base-2 (IEC) gibibytes. (GiB).
     */
    "backupSizeInGBs"?: number;
    /**
     * Number of days to retain this backup.
     */
    "retentionInDays"?: number;
    /**
     * Initial size of the data volume in GiBs. 
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The MySQL server version of the DB System used for backup.
     */
    "mysqlVersion"?: string;
    /**
     * The shape of the DB System used for backup.
     */
    "shapeName"?: string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

export type MdsBackupBackupTypeEnum = "FULL" | "INCREMENTAL";
export type MdsBackupCreationTypeEnum = "MANUAL" | "AUTOMATIC";
export type MdsBackupStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type MdsBackupLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * The Backup policy for the DB System.
 */
export interface MdsBackupPolicy {
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * If automated backups are enabled or disabled.
     */
    "isEnabled": boolean;
    /**
     * The start of a 30-minute window of time in which daily, automated backups occur.  This should be in the format of the \"Time\" portion of an RFC3339-formatted timestamp. Any second or sub-second time data will be truncated to zero.  At some point in the window, the system may incur a brief service disruption as the backup is performed.  If not defined, a window is selected from the following Region-based time-spans: - eu-frankfurt-1: 20:00 - 04:00 UTC - us-ashburn-1: 03:00 - 11:00 UTC - uk-london-1: 06:00 - 14:00 UTC - ap-tokyo-1: 13:00 - 21:00 - us-phoenix-1: 06:00 - 14:00 
     */
    "windowStartTime": string;
    /**
     * The number of days automated backups are retained. 
     */
    "retentionInDays": number;
    "pitrPolicy"?: MdsDbSystemPitrPolicy;
}

/**
 * Details of Backups such as OCID, description, backupType, and so on.  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm). 
 */
export interface MdsBackupSummary {
    /**
     * The Azure Resource Id of the backup.
     */
    "id": string;
    /**
     * The Azure Resource Id of the database.
     */
    "dbSystemId": string;
    /**
     * The user-friendly name for the backup. The name does not have to be unique.
     */
    "name"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * A user-supplied description of the backup.
     */
    "description"?: string;
    /**
     * The time the backup was created.
     */
    "timeCreated": Date;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": MdsBackupSummaryStatusEnum;
    /**
     * The type of backup.
     */
    "backupType": string;
    /**
     * If the backup was created automatically, or by a manual request.
     */
    "creationType": string;
    /**
     * Size of the data volume in GiBs.
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The size of the backup in GiBs.
     */
    "backupSizeInGBs"?: number;
    /**
     * Number of days to retain this backup.
     */
    "retentionInDays"?: number;
    /**
     * The version of the DB System used for backup.
     */
    "mysqlVersion"?: string;
    /**
     * The shape of the DB System instance used for backup.
     */
    "shapeName"?: string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

export type MdsBackupSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
/**
 * A list of database backup resources. 
 */
export interface MdsBackupSummaryCollection {
    /**
     * A collection of database backups
     */
    "items": Array<MdsBackupSummary>;
}

/**
 * Whether to run the DB System with InnoDB Redo Logs and the Double Write Buffer enabled or disabled, and whether to enable or disable syncing of the Binary Logs. 
 */
export type MdsCrashRecoveryStatus = "ENABLED" | "DISABLED";
export enum MdsCrashRecoveryStatusValues {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED"
}

/**
 * A DB System is the core logical unit of MySQL Database Service. 
 */
export interface MdsDbSystem {
    /**
     * The fully qualified Azure resource id.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": MdsDbSystemStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: MdsDbSystemLastOperationStatusEnum;
    /**
     * Detailed message for the status
     */
    "lastOperationStatusDetails"?: string;
    "observabilityDetails"?: ObservabilityDetails;
    /**
     * The user-friendly name for the DB System. It does have to be unique in resource group.
     */
    "name": string;
    /**
     * User-provided data about the DB System.
     */
    "description"?: string;
    /**
     * The Azure unique identifier of the NetworkLink. 
     */
    "networkLinkId"?: string;
    /**
     * Specifies if the DB System is highly available. 
     */
    "isHighlyAvailable"?: boolean;
    /**
     * If the DB System has a HeatWave Cluster attached. 
     */
    "isHeatWaveClusterAttached"?: boolean;
    "heatWaveCluster"?: HeatWaveClusterSummary;
    /**
     * The shape of the primary instances of the DB System. The shape determines resources allocated to a DB System - CPU cores and memory for VM shapes; CPU cores, memory and storage for non-VM (or bare metal) shapes. To get a list of shapes, use (the [ListShapes](#/en/mysql/20181021/ShapeSummary/ListShapes) operation. 
     */
    "shapeName"?: string;
    /**
     * Name of the MySQL Version in use for the DB System.
     */
    "mysqlVersion": string;
    "backupPolicy"?: MdsBackupPolicy;
    "source"?: MdsDbSystemSource;
    /**
     * The Azure unique identifier of the Configuration to be used for this DB System.
     */
    "configurationId"?: string;
    /**
     * Initial size of the data volume in GiBs that will be created and attached. 
     */
    "dataStorageSizeInGBs": number;
    /**
     * The hostname for the primary endpoint of the DB System. Used for DNS. The value is the hostname portion of the primary private IP's fully qualified domain name (FQDN) (for example, \"dbsystem-1\" in FQDN \"dbsystem-1.subnet123.vcn1.oraclevcn.com\"). Must be unique across all VNICs in the subnet and comply with RFC 952 and RFC 1123. 
     */
    "hostnameLabel"?: string;
    /**
     * Constructed and saved fqdn, for example, hostanamelabel = \"dbsystem-1\" in FQDN \"dbsystem-1.subnet123.vcn1.oraclevcn.com\" 
     */
    "fqdn"?: string;
    /**
     * The IP address the DB System is configured to listen on. A private IP address of the primary endpoint of the DB System. Must be an available IP address within the subnet's CIDR. This will be a \"dotted-quad\" style IPv4 address. 
     */
    "ipAddress"?: string;
    /**
     * The port for primary endpoint of the DB System to listen on.
     */
    "port"?: number;
    /**
     * The network port on which X Plugin listens for TCP/IP connections. This is the X Plugin equivalent of port. 
     */
    "portX"?: number;
    /**
     * The network endpoints available for this DB System. 
     */
    "endpoints"?: Array<MdsDbSystemEndpoint>;
    "maintenance": MdsMaintenanceDetails;
    "deletionPolicy": MdsDeletionPolicyDetails;
    /**
     * The date and time the DB System was created.
     */
    "timeCreated": Date;
    /**
     * The time the DB System was last updated.
     */
    "timeUpdated": Date;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Whether to run the DB System with InnoDB Redo Logs and the Double Write Buffer enabled or disabled, and whether to enable or disable syncing of the Binary Logs. 
     */
    "crashRecovery"?: MdsDbSystemCrashRecoveryEnum;
    /**
     * The Azure unique identifier of the VCN resources. 
     */
    "mcvcnId"?: string;
}

export type MdsDbSystemStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type MdsDbSystemLastOperationStatusEnum = "Succeeded" | "Failed";
export type MdsDbSystemCrashRecoveryEnum = "ENABLED" | "DISABLED";
/**
 * A collection of MDS Db System resources
 */
export interface MdsDbSystemCollection {
    /**
     * An array of MdsDbSystemSummary resources.
     */
    "items": Array<MdsDbSystemSummary>;
}

/**
 * A particular functional endpoint for access to a DB System, and the properties that apply to it. 
 */
export interface MdsDbSystemEndpoint {
    /**
     * The network host name of the DB System.
     */
    "hostname"?: string;
    /**
     * The IP address the DB System is configured to listen on.
     */
    "ipAddress": string;
    /**
     * The port the MySQL instance listens on.
     */
    "port": number;
    /**
     * The network port where to connect to use this endpoint using the X protocol.
     */
    "portX": number;
    /**
     * The access modes from the client that this endpoint supports.
     */
    "modes"?: Array<MdsDbSystemEndpointModesEnum>;
    /**
     * The state of the endpoints, as far as it can seen from the DB System. There may be some inconsistency with the actual state of the MySQL service. 
     */
    "status"?: MdsDbSystemEndpointStatusEnum;
    /**
     * Additional information about the current endpoint status.
     */
    "statusDetails"?: string;
}

export type MdsDbSystemEndpointModesEnum = "READ" | "WRITE";
export type MdsDbSystemEndpointStatusEnum = "ACTIVE" | "INACTIVE" | "UPDATING";
/**
 * Point In Time Restore policy for DbSystem 
 */
export interface MdsDbSystemPitrPolicy {
    /**
     * Specifies if Point In Time Restore backups are enabled. 
     */
    "isEnabled"?: boolean;
}

/**
 * The InnoDB shutdown mode to use 
 */
export type MdsDbSystemShutdownType = "IMMEDIATE" | "FAST" | "SLOW";
export enum MdsDbSystemShutdownTypeValues {
    IMMEDIATE = "IMMEDIATE",
    FAST = "FAST",
    SLOW = "SLOW"
}

/**
 * Snapshot of the DbSystem details at the time of the backup 
 */
export interface MdsDbSystemSnapshot {
    /**
     * The OCID of the DB System.
     */
    "id": string;
    /**
     * The user-friendly name for the DB System. It does not have to be unique.
     */
    "displayName": string;
    /**
     * User-provided data about the DB System.
     */
    "description"?: string;
    /**
     * The OCID of the compartment the DB System belongs in.
     */
    "compartmentId": string;
    /**
     * The Azure unique identifier of the NetworkLink. 
     */
    "networkLinkId": string;
    /**
     * The shape of the primary instances of the DB System. The shape determines resources allocated to a DB System - CPU cores and memory for VM shapes; CPU cores, memory and storage for non-VM (or bare metal) shapes. To get a list of shapes, use (the [ListShapes](#/en/mysql/20181021/ShapeSummary/ListShapes) operation. 
     */
    "shapeName"?: string;
    /**
     * Name of the MySQL Version in use for the DB System.
     */
    "mysqlVersion": string;
    /**
     * The username for the administrative user.
     */
    "adminUsername"?: string;
    "backupPolicy"?: MdsBackupPolicy;
    /**
     * The Azure unique identifier of the Configuration to be used for this DB System.
     */
    "configurationId"?: string;
    /**
     * Initial size of the data volume in GiBs that will be created and attached. 
     */
    "dataStorageSizeInGBs": number;
    /**
     * The hostname for the primary endpoint of the DB System. Used for DNS. The value is the hostname portion of the primary private IP's fully qualified domain name (FQDN) (for example, \"dbsystem-1\" in FQDN \"dbsystem-1.subnet123.vcn1.oraclevcn.com\"). Must be unique across all VNICs in the subnet and comply with RFC 952 and RFC 1123. 
     */
    "hostnameLabel"?: string;
    /**
     * The IP address the DB System is configured to listen on. A private IP address of the primary endpoint of the DB System. Must be an available IP address within the subnet's CIDR. This will be a \"dotted-quad\" style IPv4 address. 
     */
    "ipAddress"?: string;
    /**
     * The port for primary endpoint of the DB System to listen on.
     */
    "port"?: number;
    /**
     * The network port on which X Plugin listens for TCP/IP connections. This is the X Plugin equivalent of port. 
     */
    "portX"?: number;
    /**
     * Specifies if the DB System is highly available. 
     */
    "isHighlyAvailable"?: boolean;
    /**
     * The network endpoints available for this DB System. 
     */
    "endpoints"?: Array<MdsDbSystemEndpoint>;
    "maintenance": MdsMaintenanceDetails;
    "deletionPolicy": MdsDeletionPolicyDetails;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Whether to run the DB System with InnoDB Redo Logs and the Double Write Buffer enabled or disabled, and whether to enable or disable syncing of the Binary Logs. 
     */
    "crashRecovery"?: MdsDbSystemSnapshotCrashRecoveryEnum;
}

export type MdsDbSystemSnapshotCrashRecoveryEnum = "ENABLED" | "DISABLED";
/**
 * Parameters detailing how to provision the initial data of the DB System. 
 */
export interface MdsDbSystemSource {
    /**
     * The specific source identifier. 
     */
    "sourceType": MdsDbSystemSourceSourceTypeEnum;
}

export type MdsDbSystemSourceSourceTypeEnum = "NONE" | "BACKUP" | "PITR" | "IMPORTURL";
/**
 * A summary of a DB System. 
 */
export interface MdsDbSystemSummary {
    /**
     * The fully qualified Azure resource id.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * The name for the AdbsDatabase. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": MdsDbSystemSummaryStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: MdsDbSystemSummaryLastOperationStatusEnum;
    /**
     * Detailed message for the status
     */
    "lastOperationStatusDetails"?: string;
    /**
     * User-provided data about the DB System.
     */
    "description"?: string;
    /**
     * Specifies if the DB System is highly available.
     */
    "isHighlyAvailable"?: boolean;
    /**
     * If the DB System has a HeatWave Cluster attached. 
     */
    "isHeatWaveClusterAttached"?: boolean;
    "heatWaveCluster"?: HeatWaveClusterSummary;
    /**
     * The network endpoints available for this DB System. 
     */
    "endpoints"?: Array<MdsDbSystemEndpoint>;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Name of the MySQL Version in use for the DB System.
     */
    "mysqlVersion": string;
    /**
     * The date and time the DB System was created.
     */
    "timeCreated": Date;
    /**
     * The time the DB System was last updated.
     */
    "timeUpdated": Date;
    "deletionPolicy"?: MdsDeletionPolicyDetails;
    "backupPolicy"?: MdsBackupPolicy;
    /**
     * The shape of the primary instances of the DB System. The shape determines resources allocated to a DB System - CPU cores and memory for VM shapes; CPU cores, memory and storage for non-VM (or bare metal) shapes. To get a list of shapes, use (the [ListShapes](#/en/mysql/20181021/ShapeSummary/ListShapes) operation. 
     */
    "shapeName"?: string;
    /**
     * Whether to run the DB System with InnoDB Redo Logs and the Double Write Buffer enabled or disabled, and whether to enable or disable syncing of the Binary Logs. 
     */
    "crashRecovery"?: MdsDbSystemSummaryCrashRecoveryEnum;
    /**
     * The Azure unique identifier of the VCN resources. 
     */
    "mcvcnId"?: string;
}

export type MdsDbSystemSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type MdsDbSystemSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
export type MdsDbSystemSummaryCrashRecoveryEnum = "ENABLED" | "DISABLED";
/**
 * The Deletion policy for the DB System.
 */
export interface MdsDeletionPolicyDetails {
    /**
     * Specifies if any automatic backups created for a DB System should be retained or deleted when the DB System is deleted. 
     */
    "automaticBackupRetention": MdsDeletionPolicyDetailsAutomaticBackupRetentionEnum;
    /**
     * Specifies whether or not a backup is taken when the DB System is deleted.   REQUIRE_FINAL_BACKUP: a backup is taken if the DB System is deleted.   SKIP_FINAL_BACKUP: a backup is not taken if the DB System is deleted. 
     */
    "finalBackup": MdsDeletionPolicyDetailsFinalBackupEnum;
    /**
     * Specifies whether the DB System can be deleted. Set to true to prevent deletion, false (default) to allow. 
     */
    "isDeleteProtected": boolean;
}

export type MdsDeletionPolicyDetailsAutomaticBackupRetentionEnum = "DELETE" | "RETAIN";
export type MdsDeletionPolicyDetailsFinalBackupEnum = "SKIP_FINAL_BACKUP" | "REQUIRE_FINAL_BACKUP";
/**
 * Common status across all db adaptors 
 */
export type MdsLastOperationStatus = "Succeeded" | "Failed";
export enum MdsLastOperationStatusValues {
    Succeeded = "Succeeded",
    Failed = "Failed"
}

/**
 * The Maintenance Policy for the DB System or Read Replica that this model is included in. 
 */
export interface MdsMaintenanceDetails {
    /**
     * The start time of the maintenance window.  This string is of the format: \"{day-of-week} {time-of-day}\".  \"{day-of-week}\" is a case-insensitive string like \"mon\", \"tue\", &c.  \"{time-of-day}\" is the \"Time\" portion of an RFC3339-formatted timestamp. Any second or sub-second time data will be truncated to zero.  If you set the read replica maintenance window to \"\" or if not specified, the read replica is set same as the DB system maintenance window. 
     */
    "windowStartTime"?: string;
}

/**
 * The shape of the DB System. The shape determines resources to allocate to the DB System - CPU cores and memory for VM shapes; CPU cores, memory and storage for non-VM (or bare metal) shapes.  For a description of shapes, see [DB System Shape Options](/mysql-database/doc/shapes.htm). 
 */
export interface MdsShapeSummary {
    /**
     * The name of the shape used for the DB System.
     */
    "name": string;
    /**
     * The number of CPU Cores the Instance provides. These are \"OCPU\"s.
     */
    "cpuCoreCount": number;
    /**
     * The amount of RAM the Instance provides. This is an IEC base-2 number.
     */
    "memorySizeInGBs": number;
    /**
     * What service features the shape is supported for. 
     */
    "isSupportedFor"?: Array<MdsShapeSummaryIsSupportedForEnum>;
}

export type MdsShapeSummaryIsSupportedForEnum = "DBSYSTEM" | "HEATWAVECLUSTER";
/**
 * A list of supported shapes. 
 */
export interface MdsShapeSummaryCollection {
    /**
     * The list of supported MySQL shapes.
     */
    "items": Array<MdsShapeSummary>;
}

/**
 * Common status across all db adaptors 
 */
export type MdsStatus = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export enum MdsStatusValues {
    Provisioning = "Provisioning",
    Updating = "Updating",
    Active = "Active",
    Failed = "Failed",
    Terminating = "Terminating",
    Stopped = "Stopped"
}

/**
 * Details for the MDS deployment. 
 */
export interface MdsSystemDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": MdsSystemDeploymentDetailsKindEnum;
}

export type MdsSystemDeploymentDetailsKindEnum = "CREATE" | "GET";
/**
 * A supported MySQL Version. 
 */
export interface MdsVersion {
    /**
     * A link to a page describing the version.
     */
    "description"?: string;
    /**
     * The specific version identifier
     */
    "version"?: string;
}

/**
 * A supported MySQL VersionSummary. 
 */
export interface MdsVersionSummary {
    /**
     * A descriptive summary of a group of versions.
     */
    "versionFamily"?: string;
    /**
     * The list of supported MySQL Versions.
     */
    "items"?: Array<MdsVersion>;
}

/**
 * A summary of the supported MySQL Versions families, and a list of their supported minor versions. 
 */
export interface MdsVersionSummaryCollection {
    /**
     * The list of supported MySQL VersionSummaries.
     */
    "items": Array<MdsVersionSummary>;
}

/**
 * Error Information.
 */
export interface ModelError {
    /**
     * A short error code that defines the error, meant for programmatic parsing.
     */
    "code": string;
    /**
     * A human-readable error string.
     */
    "message": string;
}

/**
 * Month of the year.
 */
export interface Month {
    /**
     * Name of the month of the year.
     */
    "name": MonthNameEnum;
}

export type MonthNameEnum = "JANUARY" | "FEBRUARY" | "MARCH" | "APRIL" | "MAY" | "JUNE" | "JULY" | "AUGUST" | "SEPTEMBER" | "OCTOBER" | "NOVEMBER" | "DECEMBER";
/**
 * Description of  MultiCloudVirtualNetwork.
 */
export interface MultiCloudVirtualNetwork {
    /**
     * The azure looking id of the MultiCloudVirtualNetwork
     */
    "id": string;
    /**
     * MultiCloudVirtualNetwork Identifier
     */
    "name": string;
    /**
     * The time the the MultiCloudVirtualNetwork was created. An RFC3339 formatted datetime string
     */
    "timeCreated": Date;
    /**
     * The current state of the Multi-Cloud VirtualNetwork.
     */
    "status": MultiCloudVirtualNetworkStatusEnum;
    /**
     * The detailed description of the Multi-Cloud VirtualNetwork.
     */
    "lifecycleDetails"?: string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the OCI VCN
     */
    "ociVcnOcid": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the OCI subnets where the DB will be created
     */
    "ociSubnetOcids"?: Array<string>;
    /**
     * Azure location that the MultiCloudVirtualNetwork is associated with.
     */
    "location"?: string;
    /**
     * An array list of databases created under the MultiCloudVirtualNetwork. 
     */
    "attachedDatabaseIds"?: Array<string>;
    /**
     * An array list of networkLink ID's associated with the MultiCloudVirtualNetwork 
     */
    "networkLinkIds"?: Array<string>;
    /**
     * The time the MultiCloudVirtualNetwork was updated. An RFC3339 formatted datetime string
     */
    "timeUpdated"?: Date;
    /**
     * When is value is set, it denotes that the McVcn is used by the ACTIVE NetworkLink and this McVcn can be re-used. 
     */
    "isUsable": boolean;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

export type MultiCloudVirtualNetworkStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Terminated";
/**
 * Results of a MultiCloudVirtualNetwork search.
 */
export interface MultiCloudVirtualNetworkCollection {
    /**
     * List of multiCloudVirtualNetworks.
     */
    "items": Array<MultiCloudVirtualNetworkSummary>;
}

/**
 * Details for the MC VCN deployment 
 */
export interface MultiCloudVirtualNetworkDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": MultiCloudVirtualNetworkDeploymentDetailsKindEnum;
}

export type MultiCloudVirtualNetworkDeploymentDetailsKindEnum = "CREATE" | "GET" | "REGISTER";
/**
 * Description of MultiCloudVirtualNetwork.
 */
export interface MultiCloudVirtualNetworkSummary {
    /**
     * MultiCloudVirtualNetwork Id.
     */
    "id": string;
    /**
     * MultiCloudVirtualNetwork Uniquely identified Name
     */
    "name": string;
    /**
     * The time the the MultiCloudVirtualNetwork was created. An RFC3339 formatted datetime string
     */
    "timeCreated": Date;
    /**
     * The current status of the MultiCloudVirtualNetwork.
     */
    "status": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the OCI VCN.
     */
    "ociVcnOcid": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the OCI subnets where the DB will be created.
     */
    "ociSubnetOcids"?: Array<string>;
    /**
     * Azure location that the MultiCloudVirtualNetwork is associated with.
     */
    "location"?: string;
    /**
     * An array list of databases created under the MultiCloudVirtualNetwork. 
     */
    "attachedDatabaseIds": Array<string>;
    /**
     * The time the MultiCloudVirtualNetwork was updated. An RFC3339 formatted datetime string
     */
    "timeUpdated"?: Date;
    /**
     * When is value is set, it denotes that the McVcn is used by the ACTIVE NetworkLink and this McVcn can be re-used. 
     */
    "isUsable": boolean;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

/**
 * The current state of the CloudLink.
 */
export type MulticloudLinkLifecycleState = "CREATING" | "UPDATING" | "ACTIVE" | "DELETING" | "DELETED" | "FAILED";
export enum MulticloudLinkLifecycleStateValues {
    CREATING = "CREATING",
    UPDATING = "UPDATING",
    ACTIVE = "ACTIVE",
    DELETING = "DELETING",
    DELETED = "DELETED",
    FAILED = "FAILED"
}

/**
 * Description of NetworkLink.
 */
export interface NetworkLink {
    /**
     * NetworkLink Id.
     */
    "id": string;
    /**
     * NetworkLink Uniquely identified Name
     */
    "name": string;
    /**
     * The current state of the NetworkLink.
     */
    "status": NetworkLinkStatusEnum;
    /**
     * The detailed description of the NetworkLink lifecycle.
     */
    "lifecycleDetails"?: string;
    "properties": NetworkLinkProperties;
    /**
     * The time the the NetworkLink was created. An RFC3339 formatted datetime string
     */
    "timeCreated": Date;
    /**
     * The time the NetworkLink was updated. An RFC3339 formatted datetime string
     */
    "timeUpdated"?: Date;
}

export type NetworkLinkStatusEnum = "CREATING" | "CREATED" | "SUCCEEDED" | "FAILED" | "DELETING" | "UPDATING" | "DELETED";
/**
 * Results of a networkLink search. Contains boh NetworkLinkSummary items and other information, such as metadata.
 */
export interface NetworkLinkCollection {
    /**
     * List of networkLinks.
     */
    "items": Array<NetworkLinkSummary>;
}

/**
 * Details for the NetworkLink deployment 
 */
export interface NetworkLinkDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": NetworkLinkDeploymentDetailsKindEnum;
}

export type NetworkLinkDeploymentDetailsKindEnum = "CREATE" | "GET";
/**
 * Details about the network Link.
 */
export interface NetworkLinkProperties {
    /**
     * The CIDRs that will be used to bring up the VirtualCloudNetwork (VCN) in OCI. These can only be ipv4 CIDRS. The list of one or more IPv4 CIDR blocks for the VCN that meet the following criteria   - The CIDR blocks must be valid.   - They must not overlap with each other or with the on-premises network CIDR block.   - The number of CIDR blocks must not exceed the limit of CIDR blocks allowed per VCN. 
     */
    "ociNetworkCidrs"?: Array<string>;
    /**
     * The list of ExadataOnAzure objects IDs
     */
    "ociAttachedResourceIds"?: Array<string>;
    /**
     * The (Virtual Network) VNET Id where the Azure client will reside.
     */
    "azureAttachedNetworkIds"?: Array<string>;
    "routingOptions"?: RoutingOptions;
    /**
     * IP addresses for which UDR entries need to be added in the customer VNET when MANUAL_ROUTING is chosen as routingPreference
     */
    "routerIpAddresses"?: Array<string>;
}

/**
 * Summary of the NetworkLink.
 */
export interface NetworkLinkSummary {
    /**
     * Resource ID.
     */
    "id": string;
    /**
     * NetworkLink Identifier
     */
    "name": string;
    /**
     * The time the the NetworkLink was created. An RFC3339 formatted datetime string
     */
    "timeCreated": Date;
    /**
     * The current state of the NetworkLink.
     */
    "status": string;
    /**
     * The time the NetworkLink was updated. An RFC3339 formatted datetime string
     */
    "timeUpdated"?: Date;
}

/**
 * Details regarding the Azure observability instance 
 */
export interface ObservabilityDetails {
    /**
     * The Azure resource ID of the Azure dashboard resource associated with the observability
     */
    "azureDashboardId"?: string;
    /**
     * The Azure resource ID of the Azure analytics workspace resource associated with the observability
     */
    "azureAnalyticsWorkspaceId"?: string;
    /**
     * The Azure resource ID of the Azure applications insights resource associated with observability
     */
    "azureApplicationInsightsId"?: string;
    /**
     * The Azure region associated with the observability
     */
    "azureRegion"?: string;
    /**
     * The Azure resource ID of the Azure event grid topic resource associated with observability
     */
    "azureEventGridTopicId"?: string;
}

/**
 * Orginization type of the Tenancy.
 */
export type OrganizationType = "PARENT" | "STANDALONE" | "CHILD";
export enum OrganizationTypeValues {
    PARENT = "PARENT",
    STANDALONE = "STANDALONE",
    CHILD = "CHILD"
}

/**
 * Connection strings to connect to an Oracle Pluggable Database. 
 */
export interface PluggableDatabaseConnectionStrings {
    /**
     * A host name-based PDB connection string.
     */
    "pdbDefault"?: string;
    /**
     * An IP-based PDB connection string.
     */
    "pdbIpDefault"?: string;
    /**
     * All connection strings to use to connect to the pluggable database.
     */
    "allConnectionStrings"?: { [key: string]: string; };
}

/**
 * A pluggable database (PDB) is portable collection of schemas, schema objects, and non-schema objects that appears to an Oracle client as a non-container database. To use a PDB, it needs to be plugged into a CDB. To use any of the API operations, you must be authorized in an IAM policy. If you are not authorized, talk to a tenancy administrator. If you are an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface PluggableDatabaseSummary {
    /**
     * The fully qualified Azure resource ID of the pluggable database.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: PluggableDatabaseSummaryStatusEnum;
    /**
     * The fully qualified Azure resource ID of the CDB.
     */
    "containerDatabaseId": string;
    /**
     * The name for the pluggable database (PDB). The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The name for the pluggable database (PDB). The name is unique in the context of a [container database](#/en/database/latest/Database/). The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. The pluggable database name should not be same as the container database name.
     */
    "pdbName": string;
    /**
     * The current state of the pluggable database.
     */
    "lifecycleState": PluggableDatabaseSummaryLifecycleStateEnum;
    /**
     * Detailed message for the lifecycle state.
     */
    "lifecycleDetails"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The date and time the pluggable database was created.
     */
    "timeCreated": Date;
    "connectionStrings"?: PluggableDatabaseConnectionStrings;
    /**
     * The mode that pluggable database is in. Open mode can only be changed to READ_ONLY or MIGRATE directly from the backend (within the Oracle Database software). 
     */
    "openMode": PluggableDatabaseSummaryOpenModeEnum;
    /**
     * The restricted mode of the pluggable database. If a pluggable database is opened in restricted mode, the user needs both create a session and have restricted session privileges to connect to it. 
     */
    "isRestricted"?: boolean;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: PluggableDatabaseSummaryLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type PluggableDatabaseSummaryStatusEnum = "Provisioning" | "Updating" | "Terminating" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type PluggableDatabaseSummaryLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "TERMINATING" | "TERMINATED" | "UPDATING" | "FAILED";
export type PluggableDatabaseSummaryOpenModeEnum = "READ_ONLY" | "READ_WRITE" | "MOUNTED" | "MIGRATE";
export type PluggableDatabaseSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * A list of pluggable database resources. 
 */
export interface PluggableDatabaseSummaryCollection {
    /**
     * An array of pluggable database resources.
     */
    "items": Array<PluggableDatabaseSummary>;
}

/**
 * The kind of support ticket, such as a technical support request or a limit increase request.
 */
export type ProblemType = "LIMIT" | "TECHNICAL" | "BILLING";
export enum ProblemTypeValues {
    LIMIT = "LIMIT",
    TECHNICAL = "TECHNICAL",
    BILLING = "BILLING"
}

/**
 * List of RegionInfo.
 */
export interface RegionInfoCollection {
    /**
     * List of RegionInfo.
     */
    "items": Array<RegionInfoSummary>;
}

/**
 * Details of the Azure region.
 */
export interface RegionInfoSummary {
    /**
     * Azure region name.
     */
    "regionName": string;
    /**
     * Azure region display name.
     */
    "regionDisplayName"?: string;
    /**
     * OCI region name.
     */
    "ociRegionName": string;
    /**
     * OCI region display name.
     */
    "ociRegionDisplayName"?: string;
    /**
     * endpoint URL.
     */
    "endpointUrl": string;
}

/**
 * The availability of a given resource limit, based on the usage, tenant service limits, and quotas set for the tenancy. Note: We cannot guarantee this data for all the limits. In such cases, these fields will be empty. 
 */
export interface ResourceAvailability {
    /**
     * The current usage in the given compartment. To support resources with fractional counts, the field rounds up to the nearest integer. 
     */
    "used"?: number;
    /**
     * The count of available resources. To support resources with fractional counts, the field rounds down to the nearest integer. 
     */
    "available"?: number;
    /**
     * The current most accurate usage in the given compartment. 
     */
    "fractionalUsage"?: number;
    /**
     * The most accurate count of available resources. 
     */
    "fractionalAvailability"?: number;
    /**
     * The effective quota value for the given compartment. This field is only present if there is a current quota policy affecting the current resource in the target region or availability domain. 
     */
    "effectiveQuotaValue"?: number;
}

/**
 * Details required to restart DbSystem 
 */
export interface RestartMdsDbSystemDetails {
    /**
     * The InnoDB shutdown mode to use 
     */
    "shutdownType"?: RestartMdsDbSystemDetailsShutdownTypeEnum;
}

export type RestartMdsDbSystemDetailsShutdownTypeEnum = "IMMEDIATE" | "FAST" | "SLOW";
/**
 * Details to restore an Oracle Autonomous Database. 
 */
export interface RestoreAdbsDatabaseDetails {
    /**
     * The time to restore the database to.
     */
    "timestamp": Date;
    /**
     * Restores using the backup with the System Change Number (SCN) specified. 
     */
    "databaseSCN"?: string;
    /**
     * Restores to the last known good state with the least possible data loss. 
     */
    "latest"?: boolean;
}

export interface RestoreDatabaseDetails {
    /**
     * Restores using the backup with the System Change Number (SCN) specified. 
     */
    "databaseSCN"?: string;
    /**
     * Restores to the timestamp specified. 
     */
    "timestamp"?: Date;
    /**
     * Restores to the last known good state with the least possible data loss. 
     */
    "latest"?: boolean;
}

export interface RestoreVmdbDatabaseDetails {
    /**
     * Restores using the backup with the System Change Number (SCN) specified. 
     */
    "databaseSCN"?: string;
    /**
     * Restores to the timestamp specified. 
     */
    "timestamp"?: Date;
    /**
     * Restores to the last known good state with the least possible data loss. 
     */
    "latest"?: boolean;
}

/**
 * Information about the Routing option to be used by the NetworkLink.
 */
export interface RoutingOptions {
    /**
     * NetworkLink can be created with either MANUAL_ROUTING or AUTOMATIC_ROUTING as routing preference - MANUAL_ROUTING will create a latency optimized connection but customers would have to add an entry in their VNET's UDR - AUTOMATIC_ROUTING is a higher latency option compared to MANUAL_ROUTING but the user will not have to manually modify their UDR
     */
    "routingPreference"?: RoutingOptionsRoutingPreferenceEnum;
    /**
     * Optional field to specify the IP address to which return traffic is sent from the Multicloud VCN
     */
    "customerNvaIpAddress"?: string;
}

export type RoutingOptionsRoutingPreferenceEnum = "MANUAL_ROUTING" | "AUTOMATIC_ROUTING";
/**
 * The service category, such as a cloud link or network.
 */
export type ServiceCategory = "NETWORK" | "EXADATADB" | "AUTONOMOUSDB_SHARED" | "AUTONOMOUSDB_SERVERLESS" | "AUTONOMOUSDB_DEDICATED" | "VMDB" | "MDS" | "MULTICLOUDLINK";
export enum ServiceCategoryValues {
    NETWORK = "NETWORK",
    EXADATADB = "EXADATADB",
    AUTONOMOUSDB_SHARED = "AUTONOMOUSDB_SHARED",
    AUTONOMOUSDB_SERVERLESS = "AUTONOMOUSDB_SERVERLESS",
    AUTONOMOUSDB_DEDICATED = "AUTONOMOUSDB_DEDICATED",
    VMDB = "VMDB",
    MDS = "MDS",
    MULTICLOUDLINK = "MULTICLOUDLINK"
}

/**
 * The severity assigned to the ticket.
 */
export type Severity = "HIGHEST" | "HIGH" | "MEDIUM" | "LOW";
export enum SeverityValues {
    HIGHEST = "HIGHEST",
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW"
}

/**
 * Sort orders.
 */
export type SortOrder = "ASC" | "DESC";
export enum SortOrderValues {
    ASC = "ASC",
    DESC = "DESC"
}

/**
 * Subscription plan status
 */
export type Status = "TERMINATED" | "AWAITING_PROVISIONING" | "TERMINATED_PENDING_FINAL_BILLING" | "SUPERSEDED" | "DRAFT" | "ACTIVE" | "UPGRADING" | "EXPIRED" | "AWAITING_PROVISIONING_ERROR" | "SIGNED" | "ONHOLD";
export enum StatusValues {
    TERMINATED = "TERMINATED",
    AWAITING_PROVISIONING = "AWAITING_PROVISIONING",
    TERMINATED_PENDING_FINAL_BILLING = "TERMINATED_PENDING_FINAL_BILLING",
    SUPERSEDED = "SUPERSEDED",
    DRAFT = "DRAFT",
    ACTIVE = "ACTIVE",
    UPGRADING = "UPGRADING",
    EXPIRED = "EXPIRED",
    AWAITING_PROVISIONING_ERROR = "AWAITING_PROVISIONING_ERROR",
    SIGNED = "SIGNED",
    ONHOLD = "ONHOLD"
}

/**
 * Details required to stop DbSystem 
 */
export interface StopMdsDbSystemDetails {
    /**
     * The InnoDB shutdown mode to use 
     */
    "shutdownType"?: StopMdsDbSystemDetailsShutdownTypeEnum;
}

export type StopMdsDbSystemDetailsShutdownTypeEnum = "IMMEDIATE" | "FAST" | "SLOW";
/**
 * Storage Managers.
 */
export type StorageManagement = "ASM" | "LVM";
export enum StorageManagementValues {
    ASM = "ASM",
    LVM = "LVM"
}

/**
 * The initial storage size, in gigabytes, that is applicable for virtual machine DBSystem. 
 */
export interface StorageSizeDetails {
    /**
     * The data storage size, in gigabytes, that is applicable for virtual machine DBSystem. 
     */
    "dataStorageSizeInGBs": number;
    /**
     * The RECO/REDO storage size, in gigabytes, that is applicable for virtual machine DBSystem. 
     */
    "recoStorageSizeInGBs": number;
}

/**
 * Details about the subscription.
 */
export interface SubscriptionInformation {
    /**
     * The Customer Support Identifier (CSI) number associated with the subscription.
     */
    "customerSupportKey"?: string;
    /**
     * The ID of the subscription.
     */
    "subscriptionId": string;
}

/**
 * Get the tenancy subscription details 
 */
export interface TenancySubscription {
    /**
     * Tenancy type. 
     */
    "type": TenancySubscriptionTypeEnum;
    /**
     * Subscripton start date. 
     */
    "timeServiceStart"?: Date;
    /**
     * Subscripton end date. 
     */
    "timeServiceEnd"?: Date;
    /**
     * Consumed amount in subscription level. 
     */
    "serviceUsedAmount"?: string;
    /**
     * Total amount in subscription level. 
     */
    "serviceTotalValue"?: string;
    /**
     * Current billing start date. 
     */
    "timeBillingStart"?: Date;
    /**
     * Current billing end date. 
     */
    "timeBillingEnd"?: Date;
    /**
     * Current billing consumed amount. 
     */
    "billingUsedAmount"?: string;
    /**
     * Total current billing amount 
     */
    "billingTotalValue"?: string;
    /**
     * ISO currency code 
     */
    "currencyCode"?: string;
    /**
     * Status of the subscription plan. 
     */
    "status"?: TenancySubscriptionStatusEnum;
    /**
     * Orginization type of the Tenancy. 
     */
    "organizationType"?: TenancySubscriptionOrganizationTypeEnum;
}

export type TenancySubscriptionTypeEnum = "PROMO" | "PROMO_CONSUMED" | "PROMO_EXPIRED" | "PAYG" | "MONTHLY_COMMITMENT" | "ANNUAL_COMMITMENT";
export type TenancySubscriptionStatusEnum = "TERMINATED" | "AWAITING_PROVISIONING" | "TERMINATED_PENDING_FINAL_BILLING" | "SUPERSEDED" | "DRAFT" | "ACTIVE" | "UPGRADING" | "EXPIRED" | "AWAITING_PROVISIONING_ERROR" | "SIGNED" | "ONHOLD";
export type TenancySubscriptionOrganizationTypeEnum = "PARENT" | "STANDALONE" | "CHILD";
/**
 * Details about the ticket created.
 */
export interface Ticket {
    /**
     * Unique identifier for the ticket.
     */
    "ticketNumber"?: string;
    /**
     * The severity assigned to the ticket.
     */
    "severity": TicketSeverityEnum;
    /**
     * The list of items associated with the ticket.
     */
    "itemsList"?: Array<Item>;
    /**
     * The title of the ticket.
     */
    "title": string;
    /**
     * The description of the issue addressed in the ticket.
     */
    "description": string;
    /**
     * The time when the ticket was created, in milliseconds since epoch time.
     */
    "timeCreated"?: Date;
    /**
     * The time when the ticket was updated, in milliseconds since epoch time.
     */
    "timeUpdated"?: Date;
    /**
     * The current state of the ticket.
     */
    "lifecycleState"?: TicketLifecycleStateEnum;
    /**
     * Additional information about the current `lifecycleState`.
     */
    "lifecycleDetails"?: TicketLifecycleDetailsEnum;
}

export type TicketSeverityEnum = "HIGHEST" | "HIGH" | "MEDIUM" | "LOW";
export type TicketLifecycleStateEnum = "ACTIVE" | "CLOSED";
export type TicketLifecycleDetailsEnum = "PENDING_WITH_ORACLE" | "PENDING_WITH_CUSTOMER" | "CLOSE_REQUESTED" | "CLOSED";
/**
 * Information about the current status of the ticket.
 */
export type TicketLifecycleDetails = "PENDING_WITH_ORACLE" | "PENDING_WITH_CUSTOMER" | "CLOSE_REQUESTED" | "CLOSED";
export enum TicketLifecycleDetailsValues {
    PENDING_WITH_ORACLE = "PENDING_WITH_ORACLE",
    PENDING_WITH_CUSTOMER = "PENDING_WITH_CUSTOMER",
    CLOSE_REQUESTED = "CLOSE_REQUESTED",
    CLOSED = "CLOSED"
}

/**
 * The current state of the ticket.
 */
export type TicketLifecycleState = "ACTIVE" | "CLOSED";
export enum TicketLifecycleStateValues {
    ACTIVE = "ACTIVE",
    CLOSED = "CLOSED"
}

/**
 * The dtoken
 */
export interface Token {
    /**
     * Token content
     */
    "content": string;
}

/**
 * The token details
 */
export interface TokenDetails {
    /**
     * Azure Tenant Id
     */
    "azureTenantId": string;
}

/**
 * Tenancy Type
 */
export type Type = "PROMO" | "PROMO_CONSUMED" | "PROMO_EXPIRED" | "PAYG" | "MONTHLY_COMMITMENT" | "ANNUAL_COMMITMENT";
export enum TypeValues {
    PROMO = "PROMO",
    PROMO_CONSUMED = "PROMO_CONSUMED",
    PROMO_EXPIRED = "PROMO_EXPIRED",
    PAYG = "PAYG",
    MONTHLY_COMMITMENT = "MONTHLY_COMMITMENT",
    ANNUAL_COMMITMENT = "ANNUAL_COMMITMENT"
}

/**
 * Describes the modification parameters for the Autonomous Container Database. 
 */
export interface UpdateAdbdContainerDatabaseDetails {
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

/**
 * Details to update an Oracle Autonomous Database.  **Notes** - To specify OCPU core count, you must use either `ocpuCount` or `cpuCoreCount`. You cannot use both parameters at the same time. - To specify a storage allocation, you must use  either `dataStorageSizeInGBs` or `dataStorageSizeInTBs`. - See the individual parameter discriptions for more information on the OCPU and storage value parameters. **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface UpdateAdbdDatabaseDetails {
    /**
     * The number of OCPU cores to be made available to the Autonomous Database.  **Note:** This parameter cannot be used with the `ocpuCount` parameter. 
     */
    "cpuCoreCount"?: number;
    /**
     * The number of OCPU cores to be made available to the Autonomous Database.  For databases on dedicated Exadata infrastructure, you can specify a fractional value for this parameter. Fractional values are not supported for Autonomous Database on shared Exadata infrastructure.  To provision less than 1 core, enter a fractional value in an increment of 0.1. To provision 1 or more cores, you must enter an integer between 1 and the maximum number of cores available to the infrastructure shape. For example, you can provision 0.3 or 0.4 cores, but not 0.35 cores. Likewise, you can provision 2 cores or 3 cores, but not 2.5 cores. The maximum number of cores is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `cpuCoreCount` parameter. 
     */
    "ocpuCount"?: number;
    /**
     * The size, in terabytes, of the data volume that will be created and attached to the database. For Autonomous Databases on dedicated Exadata infrastructure, the maximum storage value is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `dataStorageSizeInGBs` parameter. 
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * Applies to dedicated Exadata infrastructure only.  The size, in gigabytes, of the data volume that will be created and attached to the database. The maximum storage value depends on the system shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `dataStorageSizeInTBs` parameter. 
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The user-friendly name for the Autonomous Database. The name does not have to be unique. The display name can only be updated for Autonomous Databases using dedicated Exadata infrastructure. 
     */
    "displayName"?: string;
    /**
     * The password must be between 12 and 30 characters long, and must contain at least 1 uppercase, 1 lowercase, and 1 numeric character. It cannot contain the double quote symbol (\") or the username \"admin\", regardless of casing. It must be different from the last four passwords and it must not be a password used within the last 24 hours.
     */
    "adminPassword"?: string;
    /**
     * New name for this Autonomous Database. For databases using dedicated Exadata infrastructure, the name must begin with an alphabetic character, and can contain a maximum of eight alphanumeric characters. Special characters are not permitted. 
     */
    "dbName"?: string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: UpdateAdbdDatabaseDetailsDbWorkloadEnum;
    /**
     * Indicates whether SQL Web Developer is enabled. The default is `FALSE` and no option will be given during provisioning.
     */
    "isSqlWebDeveloperEnabled"?: boolean;
    /**
     * Indicates whether auto scaling is enabled for the Autonomous Database OCPU core count. Setting to `TRUE` enables auto scaling. Setting to `FALSE` disables auto scaling. The default value is true. Auto scaling is available for databases on [shared Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html) only. 
     */
    "isAutoScalingEnabled"?: boolean;
    /**
     * The `DATABASE OPEN` mode. You can open the database in `READ_ONLY` or `READ_WRITE` mode.
     */
    "openMode"?: UpdateAdbdDatabaseDetailsOpenModeEnum;
    /**
     * list of scheduled operations
     */
    "scheduledOperations"?: Array<AdbScheduledOperationDetails>;
    /**
     * The number of Max OCPU cores to be made available to the autonomous database with auto scaling of cpu enabled. 
     */
    "maxCpuCoreCount"?: number;
    /**
     * The Oracle Database Edition that applies to the Autonomous databases. 
     */
    "databaseEdition"?: string;
}

export type UpdateAdbdDatabaseDetailsDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
export type UpdateAdbdDatabaseDetailsOpenModeEnum = "READ_ONLY" | "READ_WRITE";
/**
 * Update Wallet details for a specified Autonomous Database. 
 */
export interface UpdateAdbdDatabaseWalletDetails {
    /**
     * The field accepts either True or False.
     */
    "isRotate"?: boolean;
}

/**
 * Details for updating the cloud Autonomous VM cluster.
 */
export interface UpdateAdbdVmClusterDetails {
    /**
     * User defined description of the cloud Autonomous VM cluster.
     */
    "description"?: string;
    /**
     * The Oracle license model that applies to the Oracle Autonomous Database. Bring your own license (BYOL) allows you to apply your current on-premises Oracle software licenses to equivalent, highly automated Oracle PaaS and IaaS services in the cloud. License Included allows you to subscribe to new Oracle Database software licenses and the Database service. Note that when provisioning an Autonomous Database on [dedicated Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html), this attribute must be null because the attribute is already set at the Autonomous Exadata Infrastructure level. When using [shared Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html), if a value is not specified, the system will supply the value of `BRING_YOUR_OWN_LICENSE`. 
     */
    "licenseModel"?: UpdateAdbdVmClusterDetailsLicenseModelEnum;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

export type UpdateAdbdVmClusterDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * Details for Updating ADB-S database. 
 */
export interface UpdateAdbsDatabaseDetails {
    /**
     * The password must be sent to create ADB-S database.
     */
    "adminPassword"?: string;
    /**
     * The database name.
     */
    "dbName"?: string;
    /**
     * A valid Oracle Database version for Autonomous Database.
     */
    "dbVersion"?: string;
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: UpdateAdbsDatabaseDetailsDbWorkloadEnum;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The number of OCPU cores to be made available to the database. Minimum is 1 and Maximum is 128
     */
    "cpuCoreCount"?: number;
    /**
     * Customer Contacts.
     */
    "customerContactDetails"?: Array<CustomerContact>;
    /**
     * The size, in terabytes, of the data volume that will be created and attached to the database. This storage can later be scaled up if needed.
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * Indicates if auto scaling is enabled for the ADB-S Database OCPU core count. The default value is FALSE
     */
    "isAutoScalingEnabled"?: boolean;
    /**
     * Indicates whether the ADB-S Database requires mTLS connections.
     */
    "isMtlsConnectionRequired"?: boolean;
    /**
     * The number of OCPU cores to be made available to the database.
     */
    "ocpuCount"?: number;
    /**
     * list of scheduled operations
     */
    "scheduledOperations"?: Array<AdbScheduledOperationDetails>;
    /**
     * The client IP access control list (ACL).
     */
    "whitelistedIps"?: Array<string>;
    /**
     * The Oracle license model that applies to the Oracle Autonomous Database.  Bring your own license (BYOL) allows you to apply your current on-premises Oracle software licenses to equivalent, highly automated Oracle PaaS and IaaS services in the cloud.  License Included allows you to subscribe to new Oracle Database software licenses and the Database service. If a value is not specified, the system will supply the value of BRING_YOUR_OWN_LICENSE. 
     */
    "licenseModel"?: UpdateAdbsDatabaseDetailsLicenseModelEnum;
    /**
     * The (Virtual Network) VNET Id of Azure client.
     */
    "vnetId"?: string;
    /**
     * The subnet Id of Azure client.
     */
    "subnetId"?: string;
    /**
     * The Oracle Database Edition that applies to the Autonomous databases. 
     */
    "databaseEdition"?: UpdateAdbsDatabaseDetailsDatabaseEditionEnum;
    /**
     * specifies the network access options available when you provisioning an Autonomous Database.
     */
    "networkAccessType"?: UpdateAdbsDatabaseDetailsNetworkAccessTypeEnum;
    /**
     * Hostname alias for the IP address of the Autonomous Database Service. The hostname prefix can contain only letters and numbers. This property is only applicable when networkAccessType is PRIVATE
     */
    "hostnamePrefix"?: string;
    /**
     * The Azure unique identifier of the MC VCN resources.
     */
    "mcvcnId"?: string;
}

export type UpdateAdbsDatabaseDetailsDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
export type UpdateAdbsDatabaseDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
export type UpdateAdbsDatabaseDetailsDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION";
export type UpdateAdbsDatabaseDetailsNetworkAccessTypeEnum = "EVERYWHERE" | "ALLOWED_IPS" | "PRIVATE" | "PRIVATE_PEERING";
/**
 * Update Wallet details for a specified Autonomous Database.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface UpdateAdbsDatabaseWalletDetails {
    /**
     * The field accepts either True or False.
     */
    "isRotate"?: boolean;
}

/**
 * The information to be updated.
 */
export interface UpdateAzureCloudLinkDetails {
    /**
     * Desciption of the resource.
     */
    "description"?: string;
    /**
     * Azure subscriptions associated with this AzureCloudLink.
     */
    "subscriptionIds"?: Array<string>;
    /**
     * Azure locations where this CloudLink can be used to instantiate multi-cloud resources.
     */
    "locations"?: Array<string>;
}

/**
 * Updates the cloud Exadata infrastructure. Applies to Exadata Cloud Service instances only. 
 */
export interface UpdateCloudExadataInfrastructureDetails {
    /**
     * The user-friendly name for the cloud Exadata infrastructure. The name does not need to be unique. 
     */
    "displayName"?: string;
    "maintenanceWindow"?: MaintenanceWindow;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Customer contacts. Setting this to an empty list removes all customer contact information (email addresses) for the specified OCI Database service resource.
     */
    "customerContacts"?: Array<CustomerContact>;
    /**
     * The number of compute servers for the cloud Exadata infrastructure.
     */
    "computeCount"?: number;
    /**
     * The number of storage servers for the cloud Exadata infrastructure.
     */
    "storageCount"?: number;
}

/**
 * Details for updating the cloud VM cluster. Applies to Exadata Cloud Service instances only. 
 */
export interface UpdateCloudVmClusterDetails {
    /**
     * The user-friendly name for the cloud VM cluster. The name does not need to be unique. 
     */
    "displayName"?: string;
    /**
     * The number of CPU cores to enable for the cloud VM cluster.
     */
    "cpuCoreCount"?: number;
    /**
     * The number of OCPU cores to enable for a cloud VM cluster. Only 1 decimal place is allowed for the fractional part.
     */
    "ocpuCount"?: number;
    /**
     * The Oracle license model that applies to the cloud VM cluster. The default is BRING_YOUR_OWN_LICENSE. Applies to Exadata Cloud Service instances only. 
     */
    "licenseModel"?: UpdateCloudVmClusterDetailsLicenseModelEnum;
    /**
     * The public key portion of one or more key pairs used for SSH access to the cloud VM cluster.
     */
    "sshPublicKeys"?: Array<string>;
    /**
     * The disk group size to be allocated in GBs.
     */
    "storageSizeInGBs"?: number;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
}

export type UpdateCloudVmClusterDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * Details to update a database.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface UpdateDatabaseDetails {
    "dbBackupConfig"?: DbBackupConfig;
    /**
     * A new strong password for SYS, SYSTEM, and the plugbable database ADMIN user. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numeric, and two special characters. The special characters must be _, \\#, or -.
     */
    "newAdminPassword"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
}

/**
 * Details about the HeatWave cluster properties to be updated. 
 */
export interface UpdateHeatWaveClusterDetails {
    /**
     * A change to the shape of the nodes in the HeatWave cluster will result in the entire cluster being torn down and re-created with Compute instances of the new Shape. This may result in significant downtime for the analytics capability while the HeatWave cluster is re-provisioned. 
     */
    "shapeName"?: string;
    /**
     * A change to the number of nodes in the HeatWave cluster will result in the entire cluster being torn down and re-created with the new cluster of nodes. This may result in a significant downtime for the analytics capability while the HeatWave cluster is re-provisioned. 
     */
    "clusterSize"?: number;
}

/**
 * Details about the support ticket being updated.
 */
export interface UpdateIncidentDetails {
    "item": UpdateItemDetails;
    /**
     * The kind of support ticket, such as a technical support request or a limit increase request.
     */
    "problemType"?: UpdateIncidentDetailsProblemTypeEnum;
}

export type UpdateIncidentDetailsProblemTypeEnum = "LIMIT" | "TECHNICAL" | "BILLING";
/**
 * Details for updating an item. 
 */
export interface UpdateItemDetails {
    /**
     * The kind of update.
     */
    "kind"?: UpdateItemDetailsKindEnum;
}

export type UpdateItemDetailsKindEnum = "ACTIVITY";
/**
 * The kind of update, currently updates can only be performed through update activities.
 */
export type UpdateKind = "ACTIVITY";
export enum UpdateKindValues {
    ACTIVITY = "ACTIVITY"
}

/**
 * Details required to update backup for DbSystem 
 */
export interface UpdateMdsBackupDetails {
    /**
     * A user-supplied description for the backup
     */
    "description"?: string;
    /**
     * A user-supplied display name for the backup
     */
    "displayName"?: string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The number of days backups are retained
     */
    "retentionInDays"?: number;
}

/**
 * Details required to update backup policy for DbSystem 
 */
export interface UpdateMdsBackupPolicyDetails {
    /**
     * Specifies if automatic backups are enabled. 
     */
    "isEnabled"?: boolean;
    /**
     * Number of days to retain an automatic backup. 
     */
    "retentionInDays"?: number;
    /**
     * The start of a 30-minute window of time in which daily, automated backups occur. 
     */
    "windowStartTime"?: string;
    "pitrPolicy"?: MdsDbSystemPitrPolicy;
}

/**
 * Details required to update a DB System. 
 */
export interface UpdateMdsDbSystemDetails {
    /**
     * User-provided data about the DB System.
     */
    "description"?: string;
    /**
     * Specifies if the DB System is highly available.  When creating a DB System with High Availability, three instances are created and placed according to your region- and subnet-type. The secondaries are placed automatically in the other two availability or fault domains.  You can choose the preferred location of your primary instance, only. 
     */
    "isHighlyAvailable"?: boolean;
    /**
     * The Azure unique identifier of the Configuration to be used for this DB System.
     */
    "configurationId"?: string;
    /**
     * The name of the shape. The shape determines the resources allocated - CPU cores and memory for VM shapes; CPU cores, memory and storage for non-VM (or bare metal) shapes. To get a list of shapes, use the [ListShapes](#/en/mysql/20190415/ShapeSummary/ListShapes) operation. 
     */
    "shapeName"?: string;
    /**
     * The specific MySQL version identifier.
     */
    "mysqlVersion"?: string;
    /**
     * The username for the administrative user.
     */
    "adminUsername"?: string;
    /**
     * The password for the administrative user. The password must be between 8 and 32 characters long, and must contain at least 1 numeric character, 1 lowercase character, 1 uppercase character, and 1 special (nonalphanumeric) character. 
     */
    "adminPassword"?: string;
    /**
     * Initial size of the data volume in GBs that will be created and attached. Keep in mind that this only specifies the size of the database data volume, the log volume for the database will be scaled appropriately with its shape. 
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The hostname for the primary endpoint of the DB System. Used for DNS.  The value is the hostname portion of the primary private IP's fully qualified domain name (FQDN) (for example, \"dbsystem-1\" in FQDN \"dbsystem-1.subnet123.vcn1.oraclevcn.com\").  Must be unique across all VNICs in the subnet and comply with RFC 952 and RFC 1123. 
     */
    "hostnameLabel"?: string;
    /**
     * The IP address the DB System is configured to listen on. A private IP address of your choice to assign to the primary endpoint of the DB System. Must be an available IP address within the subnet's CIDR. If you don't specify a value, Oracle automatically assigns a private IP address from the subnet. This should be a \"dotted-quad\" style IPv4 address. 
     */
    "ipAddress"?: string;
    /**
     * The port for primary endpoint of the DB System to listen on.
     */
    "port"?: number;
    /**
     * The TCP network port on which X Plugin listens for connections. This is the X Plugin equivalent of port. 
     */
    "portX"?: number;
    "backupPolicy"?: UpdateMdsBackupPolicyDetails;
    "maintenance"?: UpdateMdsMaintenanceDetails;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    "deletionPolicy"?: UpdateMdsDeletionPolicyDetails;
    /**
     * Whether to run the DB System with InnoDB Redo Logs and the Double Write Buffer enabled or disabled, and whether to enable or disable syncing of the Binary Logs. 
     */
    "crashRecovery"?: UpdateMdsDbSystemDetailsCrashRecoveryEnum;
}

export type UpdateMdsDbSystemDetailsCrashRecoveryEnum = "ENABLED" | "DISABLED";
/**
 * Policy for how the DB System and related resources should be handled at the time of its deletion. 
 */
export interface UpdateMdsDeletionPolicyDetails {
    /**
     * Specifies if any automatic backups created for a DB System should be retained or deleted when the DB System is deleted. 
     */
    "automaticBackupRetention"?: string;
    /**
     * Specifies whether or not a backup is taken when the DB System is deleted.  REQUIRE_FINAL_BACKUP: a backup is taken if the DB System is deleted.  SKIP_FINAL_BACKUP: a backup is not taken if the DB System is deleted. 
     */
    "finalBackup"?: string;
    /**
     * Specifies whether the DB System can be deleted. Set to true to prevent deletion, false (default) to allow. 
     */
    "isDeleteProtected"?: boolean;
}

/**
 * Details required to update backup policy for DbSystem 
 */
export interface UpdateMdsMaintenanceDetails {
    /**
     * The start of the 2 hour maintenance window.  This string is of the format: \"{day-of-week} {time-of-day}\". \"{day-of-week}\" is a case-insensitive string like \"mon\", \"tue\", &c. \"{time-of-day}\" is the \"Time\" portion of an RFC3339-formatted timestamp. Any second or sub-second time data will be truncated to zero. 
     */
    "windowStartTime"?: string;
}

/**
 * Details to update a database.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface UpdatePluggableDatabaseDetails {
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
}

/**
 * Details to update a database.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface UpdateVmdbDatabaseDetails {
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    "dbBackupConfig"?: VmdbDbBackupConfig;
    /**
     * A new strong password for SYS, SYSTEM, and the plugbable database ADMIN user. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numeric, and two special characters. The special characters must be _, \\#, or -.
     */
    "newAdminPassword"?: string;
    /**
     * The current TDE Wallet password.
     */
    "oldTdeWalletPassword"?: string;
    /**
     * A new strong password for the TDE Wallet
     */
    "newTdeWalletPassword"?: string;
}

/**
 * Updates the db system. 
 */
export interface UpdateVmdbDbSystemDetails {
    /**
     * The new number of CPU cores to set for the DB system. Not applicable for INTEL based virtual machine DB systems.
     */
    "cpuCoreCount"?: number;
    /**
     * The data storage size, in gigabytes, that is currently available to the DB system. Applies only for virtual machine DB systems.
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The Oracle license model that applies to all the databases on the DB system. The default is LICENSE_INCLUDED.
     */
    "licenseModel"?: UpdateVmdbDbSystemDetailsLicenseModelEnum;
    "maintenanceWindow"?: MaintenanceWindow;
    /**
     * The shape of the DB system. The shape determines resources to allocate to the DB system.
     */
    "shape"?: string;
    /**
     * The public key portion of one or more key pairs used for SSH access to the cloud VM cluster.
     */
    "sshPublicKeys"?: Array<string>;
}

export type UpdateVmdbDbSystemDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * Details to update a pluggable database.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface UpdateVmdbPluggableDatabaseDetails {
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
}

/**
 * The information about networking input to validate.
 */
export interface ValidateNetworkingInputDetails {
    "createMultiCloudVirtualNetworkDetails"?: CreateMultiCloudVirtualNetworkDetails;
    "createNetworkLinkDetails"?: CreateNetworkLinkDetails;
    /**
     * The database type for which networking infra needs to be validated.
     */
    "dbType"?: ValidateNetworkingInputDetailsDbTypeEnum;
}

export type ValidateNetworkingInputDetailsDbTypeEnum = "EXADATA" | "VMDB" | "ADBD" | "ADBS" | "HEATWAVE";
/**
 * The details about input validation and map of validation errors.
 */
export interface ValidatedNetworkingInputDetails {
    /**
     * The status of a validation.
     */
    "validationResult": ValidatedNetworkingInputDetailsValidationResultEnum;
    /**
     * key-value for preCheck validationitems
     */
    "additionalDetails"?: { [key: string]: string; };
}

export type ValidatedNetworkingInputDetailsValidationResultEnum = "VALID" | "INVALID";
/**
 * The validation response returned when checking whether the requested user is valid.
 */
export interface ValidationResponse {
    /**
     * A short code that defines the result of the validation, meant for programmatic parsing. The value OK indicates that the validation was successful.
     */
    "code": string;
    /**
     * A human-readable message that describes the result of the validation.
     */
    "message": string;
    /**
     * A human-readable message that suggests a remedial action to resolve the validation error.
     */
    "action"?: string;
    /**
     * csi for user to register MOS account
     */
    "csi"?: string;
}

/**
 * Storage disk details containing all valid disk configurations for any VM DB system. 
 */
export interface VmScaleStorageDiskOption {
    /**
     * The list of possible RECO disk scale up options corresponding to the dbsystem.
     */
    "recoDiskStorageOptionsInGBs": Array<number>;
    /**
     * The list of possible DATA disk scale up options corresponding to the dbsystem.
     */
    "dataDiskStorageOptionsInGBs": Array<number>;
}

/**
 * VMDB database backup
 */
export interface VmdbBackup {
    /**
     * The Azure Resource Id of the backup.
     */
    "id"?: string;
    /**
     * The Azure Resource Id of the database.
     */
    "databaseId"?: string;
    /**
     * The user-friendly name for the backup. The name does not have to be unique.
     */
    "name"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * The type of backup.
     */
    "type"?: VmdbBackupTypeEnum;
    /**
     * The date and time the backup started.
     */
    "timeStarted"?: Date;
    /**
     * The date and time the backup was completed.
     */
    "timeEnded"?: Date;
    /**
     * The current status of the resource
     */
    "status"?: VmdbBackupStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: VmdbBackupLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The Oracle Database edition of the DB system from which the database backup was taken. 
     */
    "databaseEdition"?: VmdbBackupDatabaseEditionEnum;
    /**
     * The size of the database in gigabytes at the time the backup was taken. 
     */
    "databaseSizeInGBs"?: number;
    /**
     * The name (`dbName`) of the database from which the backup was created. 
     */
    "sourceDbName"?: string;
    /**
     * The DB system name of the source database. 
     */
    "sourceDbSystemName"?: string;
    /**
     * Shape of the backup's source database.
     */
    "shape"?: string;
    /**
     * Version of the backup's source database
     */
    "version"?: string;
}

export type VmdbBackupTypeEnum = "INCREMENTAL" | "FULL" | "VIRTUAL_FULL";
export type VmdbBackupStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating";
export type VmdbBackupLastOperationStatusEnum = "Succeeded" | "Failed";
export type VmdbBackupDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION" | "ENTERPRISE_EDITION_HIGH_PERFORMANCE" | "ENTERPRISE_EDITION_EXTREME_PERFORMANCE";
/**
 * A database backup. To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface VmdbBackupSummary {
    /**
     * The Azure Resource Id of the backup.
     */
    "id"?: string;
    /**
     * The Azure Resource Id of the database.
     */
    "databaseId"?: string;
    /**
     * The user-friendly name for the backup. The name does not have to be unique.
     */
    "name"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * The type of backup.
     */
    "type"?: VmdbBackupSummaryTypeEnum;
    /**
     * The date and time the backup started.
     */
    "timeStarted"?: Date;
    /**
     * The date and time the backup was completed.
     */
    "timeEnded"?: Date;
    /**
     * The current status of the resource
     */
    "status"?: VmdbBackupSummaryStatusEnum;
    /**
     * The Oracle Database edition of the DB system from which the database backup was taken. 
     */
    "databaseEdition"?: VmdbBackupSummaryDatabaseEditionEnum;
    /**
     * The name (`dbName`) of the database from which the backup was created. 
     */
    "sourceDbName"?: string;
    /**
     * The DB system name of the source database. 
     */
    "sourceDbSystemName"?: string;
    /**
     * Version of the backup's source database
     */
    "version"?: string;
}

export type VmdbBackupSummaryTypeEnum = "INCREMENTAL" | "FULL" | "VIRTUAL_FULL";
export type VmdbBackupSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating";
export type VmdbBackupSummaryDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION" | "ENTERPRISE_EDITION_HIGH_PERFORMANCE" | "ENTERPRISE_EDITION_EXTREME_PERFORMANCE";
/**
 * A list of database backup resources. 
 */
export interface VmdbBackupSummaryCollection {
    /**
     * A collection of database backups
     */
    "items": Array<VmdbBackupSummary>;
}

/**
 * Details for creating a database backup.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface VmdbCreateBackupDetails {
    /**
     * The Azure Resource Id of the database.
     */
    "databaseId": string;
    /**
     * The user-friendly name for the backup. The name does not have to be unique.
     */
    "name": string;
}

/**
 * The DB system options.
 */
export interface VmdbCreateDbSystemOptions {
    /**
     * The storage option used in DB system. ASM - Automatic storage management LVM - Logical Volume management
     */
    "storageManagement"?: VmdbCreateDbSystemOptionsStorageManagementEnum;
}

export type VmdbCreateDbSystemOptionsStorageManagementEnum = "ASM" | "LVM";
/**
 * VMDB Database
 */
export interface VmdbDatabaseBase {
    /**
     * The specific cloud provider. 
     */
    "cloudProvider": VmdbDatabaseBaseCloudProviderEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: VmdbDatabaseBaseLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The character set for the database.
     */
    "characterSet"?: string;
    /**
     * The national character set for the database.
     */
    "ncharacterSet"?: string;
    /**
     * The fully qualified Azure resource ID of the database.
     */
    "id"?: string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * The current status of the resource
     */
    "status"?: VmdbDatabaseBaseStatusEnum;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The name for the database. The name needs to be unique in the resource group.
     */
    "name"?: string;
    /**
     * The fully qualified Azure resource ID of the Database Home.
     */
    "dbHomeId"?: string;
    /**
     * The DB System Id of the database.
     */
    "dbSystemId"?: string;
    /**
     * The database name.
     */
    "dbName"?: string;
    /**
     * The name of the pluggable database. The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. Pluggable database should not be same as database name.
     */
    "pdbName"?: string;
    /**
     * The database workload type.
     */
    "dbWorkload"?: string;
    /**
     * A system-generated name for the database to ensure uniqueness within an Oracle Data Guard group (a primary database and its standby databases). The unique name cannot be changed. 
     */
    "dbUniqueName"?: string;
    /**
     * The date and time the database was created.
     */
    "timeCreated"?: Date;
    /**
     * The date and time that the database node was created.
     */
    "timeUpdated"?: Date;
    /**
     * The date and time when the latest database backup was created.
     */
    "lastBackupTimestamp"?: Date;
    "dbBackupConfig"?: VmdbDbBackupConfig;
    /**
     * The database domain. In a distributed database system, DB_DOMAIN specifies the logical location of the database within the network structure.
     */
    "dbDomain"?: string;
    "connectionStrings"?: VmdbDatabaseConnectionStrings;
    /**
     * The date and time when the latest remote automatic incremental database backup was created.
     */
    "lastRemoteBackupTimestamp"?: Date;
    /**
     * Point in time recovery timeStamp of the source database at which cloned database system is cloned from the source database system, as described in [RFC 3339](https://tools.ietf.org/rfc/rfc3339)
     */
    "sourceDatabasePointInTimeRecoveryTimestamp"?: Date;
    /**
     * True if the database is a container database.
     */
    "isCdb"?: boolean;
    /**
     * Specifies a prefix for the `Oracle SID` of the database to be created. 
     */
    "sidPrefix"?: string;
}

export type VmdbDatabaseBaseCloudProviderEnum = "AZURE" | "AWS";
export type VmdbDatabaseBaseLastOperationStatusEnum = "Succeeded" | "Failed";
export type VmdbDatabaseBaseStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating";
/**
 * Connection strings to connect to an Oracle Database. 
 */
export interface VmdbDatabaseConnectionStrings {
    /**
     * Host name based CDB Connection String.
     */
    "cdbDefault"?: string;
    /**
     * IP based CDB Connection String.
     */
    "cdbIpDefault"?: string;
    /**
     * All connection strings to use to connect to the Database.
     */
    "allConnectionStrings"?: { [key: string]: string; };
}

/**
 * An Oracle Database on a virtual machine DB system. For more information, see [Virtual Machine DB Systems](/Content/Database/Concepts/overview.htm).  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface VmdbDatabaseSummary {
    /**
     * The fully qualified Azure resource ID of the database.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the resource
     */
    "status": VmdbDatabaseSummaryStatusEnum;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The name for the database. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The fully qualified Azure resource ID of the Database Home.
     */
    "dbHomeId"?: string;
    /**
     * The DB System Id of the database.
     */
    "dbSystemId"?: string;
    /**
     * The database name.
     */
    "dbName": string;
    /**
     * The name of the pluggable database. The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. Pluggable database should not be same as database name.
     */
    "pdbName"?: string;
    /**
     * The database workload type.
     */
    "dbWorkload"?: string;
    /**
     * A system-generated name for the database to ensure uniqueness within an Oracle Data Guard group (a primary database and its standby databases). The unique name cannot be changed. 
     */
    "dbUniqueName": string;
    /**
     * The date and time the database was created.
     */
    "timeCreated"?: Date;
    /**
     * The date and time that the database node was created.
     */
    "timeUpdated"?: Date;
    /**
     * The date and time when the latest database backup was created.
     */
    "lastBackupTimestamp"?: Date;
    "dbBackupConfig"?: VmdbDbBackupConfig;
    /**
     * The database domain. In a distributed database system, DB_DOMAIN specifies the logical location of the database within the network structure.
     */
    "dbDomain"?: string;
    /**
     * The Connection strings used to connect to the Oracle Database.
     */
    "connectionStrings"?: VmdbDatabaseConnectionStrings;
    /**
     * The date and time when the latest remote automatic incremental database backup was created.
     */
    "lastRemoteBackupTimestamp"?: Date;
    /**
     * Point in time recovery timeStamp of the source database at which cloned database system is cloned from the source database system, as described in [RFC 3339](https://tools.ietf.org/rfc/rfc3339)
     */
    "sourceDatabasePointInTimeRecoveryTimestamp"?: Date;
    /**
     * True if the database is a container database.
     */
    "isCdb"?: boolean;
    /**
     * Specifies a prefix for the `Oracle SID` of the database to be created. 
     */
    "sidPrefix"?: string;
}

export type VmdbDatabaseSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating";
/**
 * A list of database resources. 
 */
export interface VmdbDatabaseSummaryCollection {
    /**
     * An array of database resources.
     */
    "items": Array<VmdbDatabaseSummary>;
}

/**
 * Backup Options To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm). 
 */
export interface VmdbDbBackupConfig {
    /**
     * If set to true, configures automatic backups. If you previously used RMAN or dbcli to configure backups and then you switch to using the Console or the API for backups, a new backup configuration is created and associated with your database. This means that you can no longer rely on your previously configured unmanaged backups to work.
     */
    "autoBackupEnabled"?: boolean;
    /**
     * Number of days between the current and the earliest point of recoverability covered by automatic backups. This value applies to automatic backups only. After a new automatic backup has been created, Oracle removes old automatic backups that are created before the window. When the value is updated, it is applied to all existing automatic backups. 
     */
    "recoveryWindowInDays"?: number;
    /**
     * Time window selected for initiating automatic backup for the database system. There are twelve available two-hour time windows. If no option is selected, a start time between 12:00 AM to 7:00 AM in the region of the database is automatically chosen. For example, if the user selects SLOT_TWO from the enum list, the automatic backup job will start in between 2:00 AM (inclusive) to 4:00 AM (exclusive).  Example: `SLOT_TWO` 
     */
    "autoBackupWindow"?: VmdbDbBackupConfigAutoBackupWindowEnum;
}

export type VmdbDbBackupConfigAutoBackupWindowEnum = "SLOT_ONE" | "SLOT_TWO" | "SLOT_THREE" | "SLOT_FOUR" | "SLOT_FIVE" | "SLOT_SIX" | "SLOT_SEVEN" | "SLOT_EIGHT" | "SLOT_NINE" | "SLOT_TEN" | "SLOT_ELEVEN" | "SLOT_TWELVE";
/**
 * A directory where Oracle Database software is installed. Each Database Home can run a different supported version of Oracle Database. A virtual machine DB system can have only one Database Home.  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface VmdbDbHomeSummary {
    /**
     * The unique of the Database Home.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the resource
     */
    "status": VmdbDbHomeSummaryStatusEnum;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The user-provided name for the Database Home. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The DB System Id of the database home.
     */
    "dbSystemId": string;
    /**
     * The Oracle Database version.
     */
    "dbVersion": string;
    /**
     * The location of the Oracle Database Home.
     */
    "dbHomeLocation"?: string;
    /**
     * The date and time the Database Home was created.
     */
    "timeCreated"?: Date;
    /**
     * The date and time the Database Home was created.
     */
    "timeUpdated"?: Date;
}

export type VmdbDbHomeSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating";
/**
 * A list of Database Home resources. 
 */
export interface VmdbDbHomeSummaryCollection {
    /**
     * An array of Database Home resources.
     */
    "items": Array<VmdbDbHomeSummary>;
}

/**
 * The Oracle Database minor version. 
 */
export interface VmdbDbMinorVersionSummary {
    /**
     * A valid Oracle Database minor version.
     */
    "version": string;
    /**
     * True if this version of the Oracle Database software is the latest version for a release.
     */
    "isLatestForMajorVersion"?: boolean;
    /**
     * True if this version of the Oracle Database software supports pluggable databases.
     */
    "supportsPdb"?: boolean;
    /**
     * True if this version of the Oracle Database software is the preview version.
     */
    "isPreviewDbVersion"?: boolean;
    /**
     * True if this version of the Oracle Database software is supported for Upgrade.
     */
    "isUpgradeSupported"?: boolean;
}

/**
 * An array of supported Oracle Database minor versions for the given major version. 
 */
export interface VmdbDbMinorVersionSummaryCollection {
    /**
     * An array of supported Oracle Database minor versions for the given major version.
     */
    "items": Array<VmdbDbMinorVersionSummary>;
}

/**
 * A server where Oracle Database software is running.  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface VmdbDbNodeSummary {
    /**
     * The fully qualified Azure resource ID of the database node.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the resource
     */
    "status": VmdbDbNodeSummaryStatusEnum;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The DB System Id of the database node.
     */
    "dbSystemId": string;
    /**
     * The user-friendly name for the database node. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The date and time that the database node was created.
     */
    "timeCreated": Date;
    /**
     * The date and time that the database node was created.
     */
    "timeUpdated": Date;
    /**
     * The size (in GB) of the block storage volume allocation for the DB system. This attribute applies only for virtual machine DB systems. 
     */
    "softwareStorageSizeInGB"?: number;
    /**
     * The number of CPU cores enabled on the Db node.
     */
    "cpuCoreCount"?: number;
    /**
     * The allocated memory in GBs on the Db node.
     */
    "memorySizeInGBs"?: number;
    /**
     * The allocated local node storage in GBs on the Db node.
     */
    "dbNodeStorageSizeInGBs"?: number;
}

export type VmdbDbNodeSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
/**
 * A list of database node resources. 
 */
export interface VmdbDbNodeSummaryCollection {
    /**
     * An array of database node resources.
     */
    "items": Array<VmdbDbNodeSummary>;
}

/**
 * Details of the db system. 
 */
export interface VmdbDbSystem {
    /**
     * The fully qualified Azure resource ID DB system.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The name for the DbSystem. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The Azure unique identifier of the NetworkLink. Either mcvnId or networkLinkId is required. 
     */
    "networkLinkId"?: string;
    /**
     * The current status of the resource
     */
    "status": VmdbDbSystemStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: VmdbDbSystemLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The cluster name for 2-node RAC virtual machine DB systems. The cluster name must begin with an alphabetic character, and may contain hyphens (-). Underscores (_) are not permitted. The cluster name can be no longer than 11 characters and is not case sensitive.
     */
    "clusterName"?: string;
    /**
     * The number of CPU cores to enable for a DbSystem. Valid values depend on the specified shape
     */
    "cpuCoreCount": number;
    /**
     * The data storage size, in gigabytes, that is currently available to the DB system. Applies only for virtual machine DB systems.
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The Oracle Database edition that applies to all the databases on the DB system.
     */
    "databaseEdition"?: VmdbDbSystemDatabaseEditionEnum;
    "dbSystemOptions"?: VmdbDbSystemOptions;
    /**
     * The domain name for the DB system.
     */
    "domain": string;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The hostname for the DB system.
     */
    "hostname": string;
    /**
     * The Oracle license model that applies to all the databases on the DB system. The default is LICENSE_INCLUDED.
     */
    "licenseModel"?: VmdbDbSystemLicenseModelEnum;
    /**
     * The port number configured for the listener on the DB system.
     */
    "listenerPort"?: number;
    "maintenanceWindow"?: MaintenanceWindow;
    /**
     * The shape of the DB system. The shape determines resources to allocate to the DB system.
     */
    "shape": string;
    /**
     * The public key portion of one or more key pairs used for SSH access to the cloud VM cluster.
     */
    "sshPublicKeys": Array<string>;
    /**
     * The number of nodes in the DB system. For RAC DB systems, the value is greater than 1.
     */
    "nodeCount"?: number;
    /**
     * The Azure unique identifier of the MC VCN resources. Either mcvnId or networkLinkId is required. 
     */
    "mcvcnId"?: string;
    /**
     * The block storage volume performance level. Valid values are BALANCED and HIGH_PERFORMANCE. HIGH_PERFORMANCE is for E4.Flex shapes only. See Block Volume Performance for more information.
     */
    "storageVolumePerformanceMode"?: VmdbDbSystemStorageVolumePerformanceModeEnum;
}

export type VmdbDbSystemStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating";
export type VmdbDbSystemLastOperationStatusEnum = "Succeeded" | "Failed";
export type VmdbDbSystemDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION" | "ENTERPRISE_EDITION_HIGH_PERFORMANCE" | "ENTERPRISE_EDITION_EXTREME_PERFORMANCE";
export type VmdbDbSystemLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
export type VmdbDbSystemStorageVolumePerformanceModeEnum = "BALANCED" | "HIGH_PERFORMANCE";
/**
 * Lists the DB systems in the specified compartment. You can specify a backupId to list only the DB systems that support creating a database using this backup in this compartment.
 */
export interface VmdbDbSystemCollection {
    /**
     * An array of db systems resources.
     */
    "items": Array<VmdbDbSystemSummary>;
}

/**
 * Details for the Vmdb deployment. 
 */
export interface VmdbDbSystemDeploymentDetails {
    /**
     * The type discriminator. 
     */
    "kind": VmdbDbSystemDeploymentDetailsKindEnum;
}

export type VmdbDbSystemDeploymentDetailsKindEnum = "CREATE" | "RESTORE";
/**
 * The DB system options.
 */
export interface VmdbDbSystemOptions {
    /**
     * The storage option used in DB system. ASM - Automatic storage management LVM - Logical Volume management
     */
    "storageManagement"?: VmdbDbSystemOptionsStorageManagementEnum;
}

export type VmdbDbSystemOptionsStorageManagementEnum = "ASM" | "LVM";
/**
 * The shape of the DB system. The shape determines resources to allocate to the DB system - CPU cores and memory for VM shapes.  To use any of the API operations, you must be authorized in an IAM policy. If you're not authorized, talk to an administrator. If you're an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm). 
 */
export interface VmdbDbSystemShapeSummary {
    /**
     * The name of the shape used for the DB system.
     */
    "name": string;
    /**
     * The family of the shape used for the DB system.
     */
    "shapeFamily"?: string;
    /**
     * The maximum number of CPU cores that can be enabled on the DB system for this shape.
     */
    "availableCoreCount": number;
    /**
     * The minimum number of CPU cores that can be enabled on the DB system for this shape.
     */
    "minimumCoreCount"?: number;
    /**
     * The runtime minimum number of CPU cores that can be enabled on the DB system for this shape.
     */
    "runtimeMinimumCoreCount"?: number;
    /**
     * The discrete number by which the CPU core count for this shape can be increased or decreased.
     */
    "coreCountIncrement"?: number;
    /**
     * The minimum number of CPU cores that can be enabled per node for this shape.
     */
    "minCoreCountPerNode"?: number;
    /**
     * The maximum memory that can be enabled for this shape.
     */
    "availableMemoryInGBs"?: number;
    /**
     * The minimum memory that need be allocated per node for this shape.
     */
    "minMemoryPerNodeInGBs"?: number;
    /**
     * The maximum Db Node storage that can be enabled for this shape.
     */
    "availableDbNodeStorageInGBs"?: number;
    /**
     * The minimum Db Node storage that need be allocated per node for this shape.
     */
    "minDbNodeStoragePerNodeInGBs"?: number;
    /**
     * The maximum DATA storage that can be enabled for this shape.
     */
    "availableDataStorageInTBs"?: number;
    /**
     * The minimum data storage that need be allocated for this shape.
     */
    "minDataStorageInTBs"?: number;
    /**
     * The minimum number of database nodes available for this shape.
     */
    "minimumNodeCount"?: number;
    /**
     * The maximum number of database nodes available for this shape.
     */
    "maximumNodeCount"?: number;
    /**
     * The number of CPU cores for each node in this shape.
     */
    "availableCoreCountPerNode"?: number;
    /**
     * vm shape platform type
     */
    "shapeType"?: VmdbDbSystemShapeSummaryShapeTypeEnum;
}

export type VmdbDbSystemShapeSummaryShapeTypeEnum = "AMD" | "INTEL" | "INTEL_FLEX_X9" | "AMPERE_FLEX_A1";
/**
 * A list of DB system shape resources. 
 */
export interface VmdbDbSystemShapeSummaryCollection {
    /**
     * An array of DB system shape resources.
     */
    "items": Array<VmdbDbSystemShapeSummary>;
}

/**
 * The Database Service supports several types of DB systems, ranging in size, price, and performance. For details see Virtual Machine DB Systems.  To use any of the API operations, you must be authorized in an IAM policy. If you are not authorized, talk to an administrator. If you are an administrator who needs to write policies to give users access, see Getting Started with Policies.  For information about access control and compartments, see Overview of the Identity Service.  For information about availability domains, see Regions and Availability Domains.  To get a list of availability domains, use the ListAvailabilityDomains operation in the Identity Service API.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface VmdbDbSystemSummary {
    /**
     * The fully qualified Azure resource ID DB system.
     */
    "id": string;
    /**
     * The name for the DbSystem. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * Additional information about the current lifecycle state.
     */
    "status": VmdbDbSystemSummaryStatusEnum;
    /**
     * The shape of the DB system. The shape determines resources to allocate to the DB system.
     */
    "shape"?: string;
    /**
     * The number of CPU cores to enable for a DbSystem. Valid values depend on the specified shape
     */
    "cpuCoreCount"?: number;
    /**
     * The data storage size, in gigabytes, that is currently available to the DB system. Applies only for virtual machine DB systems.
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The Oracle Database edition that applies to all the databases on the DB system.
     */
    "databaseEdition"?: VmdbDbSystemSummaryDatabaseEditionEnum;
    /**
     * The date and time that the database node was created.
     */
    "timeCreated": Date;
    /**
     * The date and time that the database node was created.
     */
    "timeUpdated": Date;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The number of nodes in the DB system. For RAC DB systems, the value is greater than 1.
     */
    "nodeCount"?: number;
}

export type VmdbDbSystemSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating";
export type VmdbDbSystemSummaryDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION" | "ENTERPRISE_EDITION_HIGH_PERFORMANCE" | "ENTERPRISE_EDITION_EXTREME_PERFORMANCE";
/**
 * The Oracle Database software version. 
 */
export interface VmdbDbVersionSummary {
    /**
     * A valid Oracle Database version.
     */
    "version": string;
    /**
     * True if this version of the Oracle Database software is the latest version for a release.
     */
    "isLatestForMajorVersion"?: boolean;
    /**
     * True if this version of the Oracle Database software supports pluggable databases.
     */
    "supportsPdb"?: boolean;
    /**
     * True if this version of the Oracle Database software is the preview version.
     */
    "isPreviewDbVersion"?: boolean;
    /**
     * True if this version of the Oracle Database software is supported for Upgrade.
     */
    "isUpgradeSupported"?: boolean;
}

/**
 * A list of Oracle Database software version resources. 
 */
export interface VmdbDbVersionSummaryCollection {
    /**
     * An array of Oracle Database software version resources.
     */
    "items": Array<VmdbDbVersionSummary>;
}

/**
 * Parameters for cloning a pluggable database (PDB) within the same database (CDB).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface VmdbLocalClonePluggableDatabaseDetails {
    /**
     * The name for the pluggable database (PDB). The name is unique in the context of a [container database](#/en/database/latest/Database/). The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. The pluggable database name should not be same as the container database name.
     */
    "clonedPdbName": string;
    /**
     * A strong password for PDB Admin of the newly cloned PDB. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numbers, and two special characters. The special characters must be _, \\#, or -.
     */
    "pdbAdminPassword"?: string;
    /**
     * The existing TDE wallet password of the target CDB.
     */
    "targetTdeWalletPassword"?: string;
    /**
     * The locked mode of the pluggable database admin account. If false, the user needs to provide the PDB Admin Password to connect to it. If true, the pluggable database will be locked and user cannot login to it. 
     */
    "shouldPdbAdminAccountBeLocked"?: boolean;
}

/**
 * Connection strings to connect to an Oracle Pluggable Database. 
 */
export interface VmdbPluggableDatabaseConnectionStrings {
    /**
     * A host name-based PDB connection string.
     */
    "pdbDefault"?: string;
    /**
     * An IP-based PDB connection string.
     */
    "pdbIpDefault"?: string;
    /**
     * All connection strings to use to connect to the pluggable database.
     */
    "allConnectionStrings"?: { [key: string]: string; };
}

/**
 * A pluggable database (PDB) is portable collection of schemas, schema objects, and non-schema objects that appears to an Oracle client as a non-container database. To use a PDB, it needs to be plugged into a CDB. To use any of the API operations, you must be authorized in an IAM policy. If you are not authorized, talk to a tenancy administrator. If you are an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface VmdbPluggableDatabaseSummary {
    /**
     * The fully qualified Azure resource ID of the pluggable database.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": VmdbPluggableDatabaseSummaryStatusEnum;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The fully qualified Azure resource ID of the CDB.
     */
    "containerDatabaseId": string;
    /**
     * The name for the pluggable database (PDB). The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The name for the pluggable database (PDB). The name is unique in the context of a [container database](#/en/database/latest/Database/). The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. The pluggable database name should not be same as the container database name.
     */
    "pdbName": string;
    /**
     * The date and time the pluggable database was created.
     */
    "timeCreated": Date;
    /**
     * The date and time the pluggable database was created.
     */
    "timeUpdated": Date;
    "connectionStrings"?: VmdbPluggableDatabaseConnectionStrings;
    /**
     * The mode that pluggable database is in. Open mode can only be changed to READ_ONLY or MIGRATE directly from the backend (within the Oracle Database software). 
     */
    "openMode": VmdbPluggableDatabaseSummaryOpenModeEnum;
    /**
     * The restricted mode of the pluggable database. If a pluggable database is opened in restricted mode, the user needs both create a session and have restricted session privileges to connect to it. 
     */
    "isRestricted"?: boolean;
}

export type VmdbPluggableDatabaseSummaryStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating";
export type VmdbPluggableDatabaseSummaryOpenModeEnum = "READ_ONLY" | "READ_WRITE" | "MOUNTED" | "MIGRATE";
/**
 * A list of pluggable database resources. 
 */
export interface VmdbPluggableDatabaseSummaryCollection {
    /**
     * An array of pluggable database resources.
     */
    "items": Array<VmdbPluggableDatabaseSummary>;
}

/**
 * Details about the ActivityItem object.
 */
export interface ActivityItem extends Item {
    /**
     * Comments added with the activity on the support ticket.
     */
    "comments": string;
    /**
     * The time when the activity was created, in milliseconds since epoch time.
     */
    "timeCreated": Date;
    /**
     * The time when the activity was updated, in milliseconds since epoch time.
     */
    "timeUpdated": Date;
    /**
     * The type of activity occurring on the support ticket.
     */
    "activityType": ActivityItemActivityTypeEnum;
    /**
     * The kind of activity occuring on the support ticket.
     */
    "activityKind"?: ActivityItemActivityKindEnum;
    /**
     * The status of the activity on the support ticket.
     */
    "itemStatus"?: ActivityItemItemStatusEnum;
}

export type ActivityItemActivityTypeEnum = "NOTES" | "PROBLEM_DESCRIPTION" | "UPDATE" | "CLOSE" | "REOPEN";
export type ActivityItemActivityKindEnum = "ATTACHMENTS" | "COMMENTS";
export type ActivityItemItemStatusEnum = "PROCESSING" | "ATTACHED" | "REMOVED" | "FAILED";
/**
 * An Autonomous Container Database is a container database service that enables the customer to host one or more databases within the container database. A basic container database runs on a single Autonomous Exadata Infrastructure from an availability domain without the Extreme Availability features enabled. 
 */
export interface AdbdContainerDatabase {
    /**
     * The fully qualified Azure resource id.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid": string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": AdbdContainerDatabaseStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: AdbdContainerDatabaseLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The name for the AdbdContainerDatabase. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * DB unique name.
     */
    "dbUniqueName"?: string;
    /**
     * The Azure ID of the cloud Autonomous Exadata VM Cluster.
     */
    "cloudAutonomousVmClusterId"?: string;
    /**
     * The date and time the Autonomous Container Database was created.
     */
    "timeCreated"?: Date;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * Oracle Database version of the Autonomous Container Database.
     */
    "dbVersion"?: string;
    /**
     * The amount of memory (in GBs) enabled per each OCPU core in Autonomous VM Cluster.
     */
    "memoryPerOracleComputeUnitInGBs"?: number;
    /**
     * Sum of OCPUs available on the Autonomous VM Cluster + Sum of fractional OCPUs available in the Autonomous Container Database.
     */
    "availableCpus"?: number;
    /**
     * The number of CPU cores allocated to the Autonomous VM cluster.
     */
    "totalCpus"?: number;
    /**
     * CPU cores that continue to be included in the count of OCPUs available to the Autonomous Container Database even after one of its Autonomous Database is terminated or scaled down. You can release them to the available OCPUs at its parent AVMC level by restarting the Autonomous Container Database.
     */
    "reclaimableCpus"?: number;
    /**
     * An array of CPU values that can be used to successfully provision a single Autonomous Database.
     */
    "provisionableCpus"?: Array<number>;
}

export type AdbdContainerDatabaseStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type AdbdContainerDatabaseLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * An Oracle Autonomous Database. 
 */
export interface AdbdDatabase {
    /**
     * The fully qualified Azure resource id.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid": string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": AdbdDatabaseStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: AdbdDatabaseLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The name of the database. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The database name.
     */
    "dbName"?: string;
    /**
     * The character set for the autonomous database.  The default is AL32UTF8. Allowed values are:  AL32UTF8, AR8ADOS710, AR8ADOS720, AR8APTEC715, AR8ARABICMACS, AR8ASMO8X, AR8ISO8859P6, AR8MSWIN1256, AR8MUSSAD768, AR8NAFITHA711, AR8NAFITHA721, AR8SAKHR706, AR8SAKHR707, AZ8ISO8859P9E, BG8MSWIN, BG8PC437S, BLT8CP921, BLT8ISO8859P13, BLT8MSWIN1257, BLT8PC775, BN8BSCII, CDN8PC863, CEL8ISO8859P14, CL8ISO8859P5, CL8ISOIR111, CL8KOI8R, CL8KOI8U, CL8MACCYRILLICS, CL8MSWIN1251, EE8ISO8859P2, EE8MACCES, EE8MACCROATIANS, EE8MSWIN1250, EE8PC852, EL8DEC, EL8ISO8859P7, EL8MACGREEKS, EL8MSWIN1253, EL8PC437S, EL8PC851, EL8PC869, ET8MSWIN923, HU8ABMOD, HU8CWI2, IN8ISCII, IS8PC861, IW8ISO8859P8, IW8MACHEBREWS, IW8MSWIN1255, IW8PC1507, JA16EUC, JA16EUCTILDE, JA16SJIS, JA16SJISTILDE, JA16VMS, KO16KSC5601, KO16KSCCS, KO16MSWIN949, LA8ISO6937, LA8PASSPORT, LT8MSWIN921, LT8PC772, LT8PC774, LV8PC1117, LV8PC8LR, LV8RST104090, N8PC865, NE8ISO8859P10, NEE8ISO8859P4, RU8BESTA, RU8PC855, RU8PC866, SE8ISO8859P3, TH8MACTHAIS, TH8TISASCII, TR8DEC, TR8MACTURKISHS, TR8MSWIN1254, TR8PC857, US7ASCII, US8PC437, UTF8, VN8MSWIN1258, VN8VN3, WE8DEC, WE8DG, WE8ISO8859P1, WE8ISO8859P15, WE8ISO8859P9, WE8MACROMAN8S, WE8MSWIN1252, WE8NCR4970, WE8NEXTSTEP, WE8PC850, WE8PC858, WE8PC860, WE8ROMAN8, ZHS16CGB231280, ZHS16GBK, ZHT16BIG5, ZHT16CCDC, ZHT16DBT, ZHT16HKSCS, ZHT16MSWIN950, ZHT32EUC, ZHT32SOPS, ZHT32TRIS 
     */
    "characterSet"?: string;
    /**
     * The national character set for the autonomous database.  The default is AL16UTF16. Allowed values are: AL16UTF16 or UTF8. 
     */
    "ncharacterSet"?: string;
    /**
     * The number of OCPU cores to be made available to the database. For Autonomous Databases on dedicated Exadata infrastructure, the maximum number of cores is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `ocpuCount` parameter. 
     */
    "cpuCoreCount"?: number;
    /**
     * The number of OCPU cores to be made available to the database.  The following points apply: - For Autonomous Databases on dedicated Exadata infrastructure, to provision less than 1 core, enter a fractional value in an increment of 0.1. For example, you can provision 0.3 or 0.4 cores, but not 0.35 cores. (Note that fractional OCPU values are not supported for Autonomous Databasese on shared Exadata infrastructure.) - To provision 1 or more cores, you must enter an integer between 1 and the maximum number of cores available for the infrastructure shape. For example, you can provision 2 cores or 3 cores, but not 2.5 cores. This applies to Autonomous Databases on both shared and dedicated Exadata infrastructure.  For Autonomous Databases on dedicated Exadata infrastructure, the maximum number of cores is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `cpuCoreCount` parameter. 
     */
    "ocpuCount"?: number;
    /**
     * An array of CPU values that an Autonomous Database can be scaled to.
     */
    "provisionableCpus"?: Array<number>;
    /**
     * The quantity of data in the database, in terabytes.
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * The amount of memory (in GBs) enabled per each OCPU core in Autonomous VM Cluster.
     */
    "memoryPerOracleComputeUnitInGBs"?: number;
    /**
     * The quantity of data in the database, in gigabytes.
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The infrastructure type this resource belongs to.
     */
    "infrastructureType"?: AdbdDatabaseInfrastructureTypeEnum;
    /**
     * The Autonomous Container Database Azure ID.
     */
    "autonomousContainerDatabaseId"?: string;
    /**
     * The date and time the Autonomous Database was created.
     */
    "timeCreated"?: Date;
    /**
     * The user-friendly name for the Autonomous Database. The name does not have to be unique.
     */
    "displayName"?: string;
    /**
     * The URL of the Service Console for the Autonomous Database.
     */
    "serviceConsoleUrl"?: string;
    "connectionStrings"?: AdbDatabaseConnectionStrings;
    "connectionUrls"?: AdbDatabaseConnectionUrls;
    /**
     * The amount of storage that has been used, in terabytes.
     */
    "usedDataStorageSizeInTBs"?: number;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * A valid Oracle Database version for Autonomous Database.
     */
    "dbVersion"?: string;
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: AdbdDatabaseDbWorkloadEnum;
    /**
     * The SQL Web Developer URL for the Oracle Autonomous Database. The sqlWebDeveloperUrl is only returned if isSqlWebDeveloperEnabled boolean is set to TRUE.
     */
    "sqlWebDeveloperUrl"?: string;
    "apexDetails"?: AdbDatabaseApex;
    /**
     * Indicates if auto scaling is enabled for the Autonomous Database CPU core count. 
     */
    "isAutoScalingEnabled"?: boolean;
    /**
     * Status of the Data Safe registration for this Autonomous Database.
     */
    "dataSafeStatus"?: AdbdDatabaseDataSafeStatusEnum;
    /**
     * The `DATABASE OPEN` mode. You can open the database in `READ_ONLY` or `READ_WRITE` mode.
     */
    "openMode"?: AdbdDatabaseOpenModeEnum;
    /**
     * List of Oracle Database versions available for a database upgrade. If there are no version upgrades available, this list is empty.
     */
    "availableUpgradeVersions"?: Array<string>;
    /**
     * Customer Contacts.
     */
    "customerContacts"?: Array<CustomerContact>;
    /**
     * list of scheduled operations
     */
    "scheduledOperations"?: Array<AdbScheduledOperationDetails>;
    /**
     * The amount of storage currently allocated for the database tables and billed for, rounded up. When auto-scaling is not enabled, this value is equal to the `dataStorageSizeInTBs` value. You can compare this value to the `actualUsedDataStorageSizeInTBs` value to determine if a manual shrink operation is appropriate for your allocated storage.  **Note:** Auto-scaling does not automatically decrease allocated storage when data is deleted from the database. 
     */
    "allocatedStorageSizeInTBs"?: number;
    /**
     * The current amount of storage in use for user and system data, in terabytes (TB). 
     */
    "actualUsedDataStorageSizeInTBs"?: number;
    /**
     * The number of Max OCPU cores to be made available to the autonomous database with auto scaling of cpu enabled. 
     */
    "maxCpuCoreCount"?: number;
    /**
     * The Oracle Database Edition that applies to the Autonomous databases. 
     */
    "databaseEdition"?: AdbdDatabaseDatabaseEditionEnum;
    "observabilityDetails"?: ObservabilityDetails;
}

export type AdbdDatabaseStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type AdbdDatabaseLastOperationStatusEnum = "Succeeded" | "Failed";
export type AdbdDatabaseInfrastructureTypeEnum = "CLOUD" | "CLOUD_AT_CUSTOMER";
export type AdbdDatabaseDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
export type AdbdDatabaseDataSafeStatusEnum = "REGISTERING" | "REGISTERED" | "DEREGISTERING" | "NOT_REGISTERED" | "FAILED";
export type AdbdDatabaseOpenModeEnum = "READ_ONLY" | "READ_WRITE";
export type AdbdDatabaseDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION";
/**
 * New Adb-d deployment parameters.
 */
export interface AdbdDeploymentParametersDetails extends DeploymentParametersDetails {
    "multiCloudVirtualNetworkDetails"?: MultiCloudVirtualNetworkDeploymentDetails;
    "networkLinkDetails"?: NetworkLinkDeploymentDetails;
    "cloudExadataInfrastructureDetails"?: CloudExadataInfrastructureDeploymentDetails;
    "adbdVmClusterDetails"?: AdbdVmClusterDeploymentDetails;
    "adbdContainerDatabaseDetails"?: AdbdContainerDatabaseDeploymentDetails;
    "adbdDatabaseDetails"?: AdbdDatabaseDeploymentDetails;
}

/**
 * Details of the cloud Autonomous VM cluster. 
 */
export interface AdbdVmCluster {
    /**
     * The fully qualified Azure resource id.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": AdbdVmClusterStatusEnum;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: AdbdVmClusterLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
    /**
     * The name of the VmCluster. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * User defined description of the cloud Autonomous VM cluster.
     */
    "description"?: string;
    /**
     * The date and time that the cloud Autonomous VM cluster was created.
     */
    "timeCreated"?: Date;
    /**
     * The last date and time that the cloud Autonomous VM cluster was updated.
     */
    "timeUpdated"?: Date;
    /**
     * The hostname for the cloud Autonomous VM cluster.
     */
    "hostname"?: string;
    /**
     * The domain name for the cloud Autonomous VM cluster.
     */
    "domain"?: string;
    /**
     * Azure Id of the cloud Exadata infrastructure.
     */
    "cloudExadataInfrastructureId": string;
    /**
     * The Azure unique identifier of the NetworkLink.
     */
    "networkLinkId": string;
    /**
     * The number of database servers in the cloud VM cluster. 
     */
    "nodeCount"?: number;
    /**
     * The total data storage allocated, in terabytes (TB).
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * The total data storage allocated, in gigabytes (GB).
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The number of CPU cores enabled on the cloud Autonomous VM cluster.
     */
    "cpuCoreCount"?: number;
    /**
     * The number of CPU cores enabled on the cloud Autonomous VM cluster. Only 1 decimal place is allowed for the fractional part.
     */
    "ocpuCount"?: number;
    /**
     * The number of OCPU cores enabled per VM cluster node.
     */
    "cpuCoreCountPerNode"?: number;
    /**
     * The memory allocated in GBs.
     */
    "memorySizeInGBs"?: number;
    /**
     * The Oracle license model that applies to the Oracle Autonomous Database. Bring your own license (BYOL) allows you to apply your current on-premises Oracle software licenses to equivalent, highly automated Oracle PaaS and IaaS services in the cloud. License Included allows you to subscribe to new Oracle Database software licenses and the Database service. Note that when provisioning an Autonomous Database on [dedicated Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html), this attribute must be null because the attribute is already set at the Autonomous Exadata Infrastructure level. When using [shared Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html), if a value is not specified, the system will supply the value of `BRING_YOUR_OWN_LICENSE`. 
     */
    "licenseModel"?: AdbdVmClusterLicenseModelEnum;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * CPU cores available for allocation to Autonomous Databases.
     */
    "availableCpus"?: number;
    /**
     * CPU cores that continue to be included in the count of OCPUs available to the Autonomous Container Database even after one of its Autonomous Database is terminated or scaled down. You can release them to the available OCPUs at its parent AVMC level by restarting the Autonomous Container Database.
     */
    "reclaimableCpus"?: number;
    /**
     * The number of Autonomous Container Databases that can be created with the currently available local storage.
     */
    "availableContainerDatabases"?: number;
    /**
     * The total number of Autonomous Container Databases that can be created with the allocated local storage.
     */
    "totalContainerDatabases"?: number;
    /**
     * The data disk group size available for Autonomous Databases, in TBs.
     */
    "availableAutonomousDataStorageSizeInTBs"?: number;
    /**
     * The data disk group size allocated for Autonomous Databases, in TBs.
     */
    "autonomousDataStorageSizeInTBs"?: number;
    /**
     * The local node storage allocated in GBs.
     */
    "dbNodeStorageSizeInGBs"?: number;
    /**
     * The amount of memory (in GBs) enabled per each OCPU core.
     */
    "memoryPerOracleComputeUnitInGBs"?: number;
}

export type AdbdVmClusterStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type AdbdVmClusterLastOperationStatusEnum = "Succeeded" | "Failed";
export type AdbdVmClusterLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * An ADB-S Database for Azure.
 */
export interface AdbsDatabase extends AdbsDatabaseBase {
    "observabilityDetails"?: ObservabilityDetails;
}

/**
 * An Autonomous Database backup. 
 */
export interface AdbsDatabaseBackup {
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The fully qualified Azure resource ID.
     */
    "id": string;
    /**
     * The Azure Resource Id of the database.
     */
    "databaseId": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: AdbsDatabaseBackupStatusEnum;
    /**
     * The size of the database in terabytes at the time the backup was taken.
     */
    "databaseSizeInTBs"?: number;
    /**
     * The user-friendly name for the backup. The name does not have to be unique.
     */
    "name": string;
    /**
     * Indicates whether the backup is user-initiated or automatic.
     */
    "isAutomatic": boolean;
    /**
     * Indicates whether the backup can be used to restore the associated Autonomous Database.
     */
    "isRestorable"?: boolean;
    /**
     * The date and time the backup completed.
     */
    "timeEnded"?: Date;
    /**
     * The date and time the backup started.
     */
    "timeStarted"?: Date;
    /**
     * The type of backup.
     */
    "type": AdbsDatabaseBackupTypeEnum;
}

export type AdbsDatabaseBackupStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type AdbsDatabaseBackupTypeEnum = "INCREMENTAL" | "FULL";
/**
 * New Adb-s deployment parameters.
 */
export interface AdbsDeploymentParametersDetails extends DeploymentParametersDetails {
    "multiCloudVirtualNetworkDetails"?: MultiCloudVirtualNetworkDeploymentDetails;
    "adbsDatabaseDetails"?: AdbsDatabaseDeploymentDetails;
}

/**
 * Details of the AzureResourceGroup resource. 
 */
export interface AzureResourceGroup {
    /**
     * ID of the resource group.
     */
    "id": string;
    /**
     * Name of the resource group.
     */
    "name": string;
    /**
     * Region name of the resource group.
     */
    "regionName": string;
    /**
     * Type fo the resource group.
     */
    "type": string;
    /**
     * Tags (key-value pairs) for the resource group. 
     */
    "tags": any;
}

/**
 * Details of the AzureSubnet resource. 
 */
export interface AzureSubnet {
    /**
     * Resource(subnet) ID.
     */
    "id": string;
    /**
     * Name of subnet.
     */
    "name": string;
    /**
     * ResourceGroupName of the subnet.
     */
    "resourceGroupName": string;
    /**
     * Region name of the subnet.
     */
    "regionName": string;
    /**
     * Type of the resource.
     */
    "type": string;
    /**
     * Tags (key-value pairs) for the subnet. 
     */
    "tags": any;
}

/**
 * Details of the AzureSubscription resource. 
 */
export interface AzureSubscription {
    /**
     * ID of the resource group.
     */
    "id": string;
    /**
     * Name of the resource group.
     */
    "name": string;
    /**
     * State of the resource.
     */
    "state": string;
}

/**
 * Details of the AzureVirtualNetwork resource. 
 */
export interface AzureVirtualNetwork {
    /**
     * ID of the virtual network.
     */
    "id": string;
    /**
     * Name of the virtual network.
     */
    "name": string;
    /**
     * AddressSpaces of the virtual network.
     */
    "addressSpaces"?: Array<string>;
    /**
     * DnsServerIps of the virtual network.
     */
    "dnsServerIps"?: Array<string>;
    /**
     * ResourceGroupName of the virtual network.
     */
    "resourceGroupName": string;
    /**
     * Region name of the virtual network.
     */
    "regionName": string;
    /**
     * Type of the virtual network.
     */
    "type": string;
    /**
     * Tags (key-value pairs) for the virtual network. 
     */
    "tags": any;
}

/**
 * Backup of a database
 */
export interface BackupSummary {
    /**
     * The fully qualified Azure resource ID of the database node.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location"?: string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: BackupSummaryStatusEnum;
    /**
     * The Oracle Database edition that applies to all the databases on the DB system.
     */
    "databaseEdition"?: BackupSummaryDatabaseEditionEnum;
    /**
     * The Azure resource ID of the database.
     */
    "databaseId"?: string;
    /**
     * The size of the database in gigabytes at the time the backup was taken.
     */
    "databaseSizeInGBs"?: number;
    /**
     * The user-friendly name for the backup.
     */
    "displayName": string;
    /**
     * Additional information about the current lifecycle state
     */
    "lifecycleDetails"?: string;
    /**
     * The current state of the backup.
     */
    "lifecycleState"?: BackupSummaryLifecycleStateEnum;
    /**
     * Shape of the backup's source database.
     */
    "shape"?: string;
    /**
     * The date and time the backup was completed.
     */
    "timeEnded"?: Date;
    /**
     * The date and time the backup started.
     */
    "timeStarted"?: Date;
    /**
     * The type of backup
     */
    "type"?: BackupSummaryTypeEnum;
    /**
     * Version of the backup's source database
     */
    "version"?: string;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: BackupSummaryLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type BackupSummaryStatusEnum = "Provisioning" | "Updating" | "Terminating" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type BackupSummaryDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION" | "ENTERPRISE_EDITION_HIGH_PERFORMANCE" | "ENTERPRISE_EDITION_EXTREME_PERFORMANCE";
export type BackupSummaryLifecycleStateEnum = "CREATING" | "ACTIVE" | "DELETING" | "DELETED" | "FAILED" | "RESTORING";
export type BackupSummaryTypeEnum = "INCREMENTAL" | "FULL" | "VIRTUAL_FULL";
export type BackupSummaryLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * Details about the billing support object.
 */
export interface BillingSupportItem extends Item {
}

/**
 * Details of the cloud Exadata infrastructure resource. Applies to Exadata Cloud Service instances only. 
 */
export interface CloudExadataInfrastructure {
    /**
     * The fully qualified Azure resource ID of the cloud Exadata infrastructure resource.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: CloudExadataInfrastructureStatusEnum;
    /**
     * The current lifecycle state of the cloud Exadata infrastructure resource.
     */
    "lifecycleState": CloudExadataInfrastructureLifecycleStateEnum;
    /**
     * The name for the cloud Exadata infrastructure resource. The name needs to be unique in the resource group.
     */
    "name"?: string;
    /**
     * The user-friendly name for the cloud Exadata infrastructure resource. The name does not need to be unique.
     */
    "displayName": string;
    /**
     * The model name of the cloud Exadata infrastructure resource. 
     */
    "shape": string;
    /**
     * The number of compute servers for the cloud Exadata infrastructure.
     */
    "computeCount"?: number;
    /**
     * The number of storage servers for the cloud Exadata infrastructure.
     */
    "storageCount"?: number;
    /**
     * The total storage allocated to the cloud Exadata infrastructure resource, in gigabytes (GB).
     */
    "totalStorageSizeInGBs"?: number;
    /**
     * The available storage can be allocated to the cloud Exadata infrastructure resource, in gigabytes (GB).
     */
    "availableStorageSizeInGBs"?: number;
    /**
     * The date and time the cloud Exadata infrastructure resource was created.
     */
    "timeCreated"?: Date;
    /**
     * Additional information about the current lifecycle state.
     */
    "lifecycleDetails"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    "maintenanceWindow"?: MaintenanceWindow;
    /**
     * The list of customer email addresses that receive information from Oracle about the specified OCI Database service resource. Oracle uses these email addresses to send notifications about planned and unplanned software maintenance updates, information about system hardware, and other information needed by administrators. Up to 10 email addresses can be added to the customer contacts for a cloud Exadata infrastructure instance. 
     */
    "customerContacts"?: Array<CustomerContact>;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: CloudExadataInfrastructureLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type CloudExadataInfrastructureStatusEnum = "Provisioning" | "Updating" | "Terminating" | "MaintenanceInProgress" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type CloudExadataInfrastructureLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "UPDATING" | "TERMINATING" | "TERMINATED" | "FAILED" | "MAINTENANCE_IN_PROGRESS";
export type CloudExadataInfrastructureLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * Details of the cloud VM cluster. Applies to Exadata Cloud Service instances only. 
 */
export interface CloudVmCluster {
    /**
     * The fully qualified Azure resource ID of the cloud VM cluster.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: CloudVmClusterStatusEnum;
    /**
     * The Azure unique identifier of the NetworkLink. 
     */
    "networkLinkId"?: string;
    /**
     * The Azure unique identifier of the VCN resources. Either mcvnId or networkLinkId is required. 
     */
    "mcvcnId"?: string;
    /**
     * The model name of the Exadata hardware running the cloud VM cluster. 
     */
    "shape": string;
    /**
     * The port number configured for the listener on the cloud VM cluster.
     */
    "listenerPort"?: number;
    /**
     * The current state of the cloud VM cluster.
     */
    "lifecycleState": CloudVmClusterLifecycleStateEnum;
    /**
     * The number of nodes in the cloud VM cluster. 
     */
    "nodeCount"?: number;
    /**
     * The storage allocation for the disk group, in gigabytes (GB).
     */
    "storageSizeInGBs"?: number;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The user-friendly name for the cloud VM cluster. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The user-friendly name for the cloud VM cluster. The name does not need to be unique.
     */
    "displayName"?: string;
    /**
     * The date and time that the cloud VM cluster was created.
     */
    "timeCreated"?: Date;
    /**
     * Additional information about the current lifecycle state.
     */
    "lifecycleDetails"?: string;
    /**
     * The time zone of the cloud VM cluster. For details, see [Exadata Infrastructure Time Zones](/Content/Database/References/timezones.htm).
     */
    "timeZone"?: string;
    /**
     * The hostname for the cloud VM cluster.
     */
    "hostname": string;
    /**
     * The domain name for the cloud VM cluster.
     */
    "domain": string;
    /**
     * The number of CPU cores enabled on the cloud VM cluster.
     */
    "cpuCoreCount": number;
    /**
     * The number of OCPU cores to enable on the cloud VM cluster. Only 1 decimal place is allowed for the fractional part.
     */
    "ocpuCount"?: number;
    /**
     * The cluster name for cloud VM cluster. The cluster name must begin with an alphabetic character, and may contain hyphens (-). Underscores (_) are not permitted. The cluster name can be no longer than 11 characters and is not case sensitive. 
     */
    "clusterName"?: string;
    /**
     * The percentage assigned to DATA storage (user data and database files). The remaining percentage is assigned to RECO storage (database redo logs, archive logs, and recovery manager backups). Accepted values are 35, 40, 60 and 80. The default is 80 percent assigned to DATA storage. See [Storage Configuration](/Content/Database/Concepts/exaoverview.htm#Exadata) in the Exadata documentation for details on the impact of the configuration settings on storage. 
     */
    "dataStoragePercentage"?: number;
    /**
     * If true, database backup on local Exadata storage is configured for the cloud VM cluster. If false, database backup on local Exadata storage is not available in the cloud VM cluster. 
     */
    "isLocalBackupEnabled"?: boolean;
    /**
     * The fully qualified Azure resource ID of the cloud Exadata infrastructure.
     */
    "cloudExadataInfrastructureId": string;
    /**
     * If true, sparse disk group is configured for the cloud VM cluster. If false, sparse disk group is not created. 
     */
    "isSparseDiskgroupEnabled"?: boolean;
    /**
     * A valid Oracle Grid Infrastructure (GI) software version.
     */
    "giVersion"?: string;
    /**
     * Operating system version of the image.
     */
    "systemVersion"?: string;
    /**
     * The public key portion of one or more key pairs used for SSH access to the cloud VM cluster.
     */
    "sshPublicKeys": Array<string>;
    /**
     * The Oracle license model that applies to the cloud VM cluster. The default is LICENSE_INCLUDED. 
     */
    "licenseModel"?: CloudVmClusterLicenseModelEnum;
    /**
     * The type of redundancy configured for the cloud Vm cluster. NORMAL is 2-way redundancy. HIGH is 3-way redundancy. 
     */
    "diskRedundancy"?: CloudVmClusterDiskRedundancyEnum;
    /**
     * The Single Client Access Name (SCAN) IP addresses associated with the cloud VM cluster. SCAN IP addresses are typically used for load balancing and are not assigned to any interface. Oracle Clusterware directs the requests to the appropriate nodes in the cluster.  **Note:** For a single-node DB system, this list is empty. 
     */
    "scanIps"?: Array<string>;
    /**
     * The virtual IP (VIP) addresses associated with the cloud VM cluster. The Cluster Ready Services (CRS) creates and maintains one VIP address for each node in the Exadata Cloud Service instance to enable failover. If one node fails, the VIP is reassigned to another active node in the cluster.  **Note:** For a single-node DB system, this list is empty. 
     */
    "vips"?: Array<string>;
    /**
     * The FQDN of the DNS record for the SCAN IP addresses that are associated with the cloud VM cluster. 
     */
    "scanDnsName"?: string;
    /**
     * The TCP Single Client Access Name (SCAN) port. The default port is 1521.
     */
    "scanListenerPortTcp"?: number;
    /**
     * The TCPS Single Client Access Name (SCAN) port. The default port is 2484.
     */
    "scanListenerPortTcpSsl"?: number;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: CloudVmClusterLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type CloudVmClusterStatusEnum = "Provisioning" | "Updating" | "Terminating" | "MaintenanceInProgress" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type CloudVmClusterLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "UPDATING" | "TERMINATING" | "TERMINATED" | "FAILED" | "MAINTENANCE_IN_PROGRESS";
export type CloudVmClusterLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
export type CloudVmClusterDiskRedundancyEnum = "HIGH" | "NORMAL";
export type CloudVmClusterLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * Details for creating a new Autonomous Container Database deployment. 
 */
export interface CreateAdbdContainerDatabaseDeploymentDetails extends AdbdContainerDatabaseDeploymentDetails {
    /**
     * The name for the Autonomous Container Database. Must be unique in the resource group.
     */
    "name": string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

/**
 * Details for creating a Oracle Autonomous Database deployment. 
 */
export interface CreateAdbdDatabaseDeploymentDetails extends AdbdDatabaseDeploymentDetails {
    /**
     * The name for the AdbdDatabase. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The character set for the autonomous database.  The default is AL32UTF8. Allowed values for an Autonomous Database on shared infrastructure as as returned by [List Autonomous Database Character Sets](/autonomousDatabaseCharacterSets)  For an Autonomous Database on dedicated infrastructure, the allowed values are:  AL32UTF8, AR8ADOS710, AR8ADOS720, AR8APTEC715, AR8ARABICMACS, AR8ASMO8X, AR8ISO8859P6, AR8MSWIN1256, AR8MUSSAD768, AR8NAFITHA711, AR8NAFITHA721, AR8SAKHR706, AR8SAKHR707, AZ8ISO8859P9E, BG8MSWIN, BG8PC437S, BLT8CP921, BLT8ISO8859P13, BLT8MSWIN1257, BLT8PC775, BN8BSCII, CDN8PC863, CEL8ISO8859P14, CL8ISO8859P5, CL8ISOIR111, CL8KOI8R, CL8KOI8U, CL8MACCYRILLICS, CL8MSWIN1251, EE8ISO8859P2, EE8MACCES, EE8MACCROATIANS, EE8MSWIN1250, EE8PC852, EL8DEC, EL8ISO8859P7, EL8MACGREEKS, EL8MSWIN1253, EL8PC437S, EL8PC851, EL8PC869, ET8MSWIN923, HU8ABMOD, HU8CWI2, IN8ISCII, IS8PC861, IW8ISO8859P8, IW8MACHEBREWS, IW8MSWIN1255, IW8PC1507, JA16EUC, JA16EUCTILDE, JA16SJIS, JA16SJISTILDE, JA16VMS, KO16KSC5601, KO16KSCCS, KO16MSWIN949, LA8ISO6937, LA8PASSPORT, LT8MSWIN921, LT8PC772, LT8PC774, LV8PC1117, LV8PC8LR, LV8RST104090, N8PC865, NE8ISO8859P10, NEE8ISO8859P4, RU8BESTA, RU8PC855, RU8PC866, SE8ISO8859P3, TH8MACTHAIS, TH8TISASCII, TR8DEC, TR8MACTURKISHS, TR8MSWIN1254, TR8PC857, US7ASCII, US8PC437, UTF8, VN8MSWIN1258, VN8VN3, WE8DEC, WE8DG, WE8ISO8859P1, WE8ISO8859P15, WE8ISO8859P9, WE8MACROMAN8S, WE8MSWIN1252, WE8NCR4970, WE8NEXTSTEP, WE8PC850, WE8PC858, WE8PC860, WE8ROMAN8, ZHS16CGB231280, ZHS16GBK, ZHT16BIG5, ZHT16CCDC, ZHT16DBT, ZHT16HKSCS, ZHT16MSWIN950, ZHT32EUC, ZHT32SOPS, ZHT32TRIS 
     */
    "characterSet"?: string;
    /**
     * The character set for the Autonomous Database.  The default is AL32UTF8. Use [List Autonomous Database Character Sets](/autonomousDatabaseCharacterSets) to list the allowed values for an Autonomous Database on shared Exadata infrastructure. For an Autonomous Database on dedicated Exadata infrastructure, the allowed values are: AL16UTF16 or UTF8. 
     */
    "ncharacterSet"?: string;
    /**
     * The database name. The name must begin with an alphabetic character and can contain a maximum of 14 alphanumeric characters. Special characters are not permitted. The database name must be unique in the tenancy.
     */
    "dbName": string;
    /**
     * The number of OCPU cores to be made available to the database. For Autonomous Databases on dedicated Exadata infrastructure, the maximum number of cores is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `ocpuCount` parameter. 
     */
    "cpuCoreCount"?: number;
    /**
     * The number of OCPU cores to be made available to the database.  The following points apply: - For Autonomous Databases on dedicated Exadata infrastructure, to provision less than 1 core, enter a fractional value in an increment of 0.1. For example, you can provision 0.3 or 0.4 cores, but not 0.35 cores. (Note that fractional OCPU values are not supported for Autonomous Databasese on shared Exadata infrastructure.) - To provision 1 or more cores, you must enter an integer between 1 and the maximum number of cores available for the infrastructure shape. For example, you can provision 2 cores or 3 cores, but not 2.5 cores. This applies to Autonomous Databases on both shared and dedicated Exadata infrastructure.  For Autonomous Databases on dedicated Exadata infrastructure, the maximum number of cores is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `cpuCoreCount` parameter. 
     */
    "ocpuCount"?: number;
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: CreateAdbdDatabaseDeploymentDetailsDbWorkloadEnum;
    /**
     * The size, in terabytes, of the data volume that will be created and attached to the database. This storage can later be scaled up if needed. For Autonomous Databases on dedicated Exadata infrastructure, the maximum storage value is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Note:** This parameter cannot be used with the `dataStorageSizeInGBs` parameter. 
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * The size, in gigabytes, of the data volume that will be created and attached to the database. This storage can later be scaled up if needed. The maximum storage value is determined by the infrastructure shape. See [Characteristics of Infrastructure Shapes](https://www.oracle.com/pls/topic/lookup?ctx=en/cloud/paas/autonomous-database&id=ATPFG-GUID-B0F033C1-CC5A-42F0-B2E7-3CECFEDA1FD1) for shape details.  **Notes** - This parameter is only supported for dedicated Exadata infrastructure. - This parameter cannot be used with the `dataStorageSizeInTBs` parameter. 
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * **Important** The `adminPassword` must be specified for all Autonomous Databases. The password must be between 12 and 30 characters long, and must contain at least 1 uppercase, 1 lowercase, and 1 numeric character. It cannot contain the double quote symbol (\") or the username \"admin\", regardless of casing. 
     */
    "adminPassword": string;
    /**
     * Indicates if auto scaling is enabled for the Autonomous Database OCPU core count. The default value is `FALSE`. 
     */
    "isAutoScalingEnabled"?: boolean;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * A valid Oracle Database version for Autonomous Database.
     */
    "dbVersion"?: string;
    /**
     * The source of the database: Use `NONE` for creating a new Autonomous Database. Use `DATABASE` for creating a new Autonomous Database by cloning an existing Autonomous Database. Use `CROSS_REGION_DATAGUARD` to create a standby Data Guard database in another region. 
     */
    "source"?: CreateAdbdDatabaseDeploymentDetailsSourceEnum;
    /**
     * list of scheduled operations
     */
    "scheduledOperations"?: Array<AdbScheduledOperationDetails>;
    /**
     * The number of Max OCPU cores to be made available to the autonomous database with auto scaling of cpu enabled. 
     */
    "maxCpuCoreCount"?: number;
    /**
     * The Oracle Database Edition that applies to the Autonomous databases. 
     */
    "databaseEdition"?: string;
}

export type CreateAdbdDatabaseDeploymentDetailsDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
export type CreateAdbdDatabaseDeploymentDetailsSourceEnum = "NONE";
/**
 * Details to create an Oracle Autonomous Database. 
 */
export interface CreateAdbdDatabaseDetails extends CreateAdbdDatabaseBase {
}

/**
 * Details for the create cloud Autonomous VM cluster operation. 
 */
export interface CreateAdbdVmClusterDeploymentDetails extends AdbdVmClusterDeploymentDetails {
    /**
     * User defined description of the cloud Autonomous VM cluster.
     */
    "description"?: string;
    /**
     * The name for the cloud Autonomous VM cluster. The name must be unique in resource group.
     */
    "name": string;
    /**
     * The total number of Autonomous Container Databases that can be created.
     */
    "totalContainerDatabases"?: number;
    /**
     * The number of OCPU cores to enable per VM cluster node.
     */
    "cpuCoreCountPerNode"?: number;
    /**
     * The amount of memory (in GBs) to be enabled per each OCPU core.
     */
    "memoryPerOracleComputeUnitInGBs"?: number;
    /**
     * The data disk group size to be allocated for Autonomous Databases, in TBs.
     */
    "autonomousDataStorageSizeInTBs"?: number;
    /**
     * The Oracle license model that applies to the Oracle Autonomous Database. Bring your own license (BYOL) allows you to apply your current on-premises Oracle software licenses to equivalent, highly automated Oracle PaaS and IaaS services in the cloud. License Included allows you to subscribe to new Oracle Database software licenses and the Database service. Note that when provisioning an Autonomous Database on [dedicated Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html), this attribute must be null because the attribute is already set at the Autonomous Exadata Infrastructure level. When using [shared Exadata infrastructure](https://docs.oracle.com/en/cloud/paas/autonomous-database/index.html), if a value is not specified, the system will supply the value of `BRING_YOUR_OWN_LICENSE`. 
     */
    "licenseModel"?: CreateAdbdVmClusterDeploymentDetailsLicenseModelEnum;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

export type CreateAdbdVmClusterDeploymentDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * Details for creating ADB-S database deployment. 
 */
export interface CreateAdbsDatabaseDeploymentDetails extends AdbsDatabaseDeploymentDetails {
    /**
     * The name for the AdbsDatabase. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The database name.
     */
    "dbName": string;
    /**
     * The password must be sent to create ADB-S database.
     */
    "adminPassword": string;
    /**
     * A valid Oracle Database version for Autonomous Database.
     */
    "dbVersion"?: string;
    /**
     * The Autonomous Database workload type. The following values are valid:  - OLTP - indicates an Autonomous Transaction Processing database - DW - indicates an Autonomous Data Warehouse database - AJD - indicates an Autonomous JSON Database - APEX - indicates an Autonomous Database with the Oracle APEX Application Development workload type. 
     */
    "dbWorkload"?: CreateAdbsDatabaseDeploymentDetailsDbWorkloadEnum;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The number of OCPU cores to be made available to the database.Minimum is 1 and Maximum is 128
     */
    "cpuCoreCount"?: number;
    /**
     * Customer Contacts.
     */
    "customerContactDetails"?: Array<CustomerContact>;
    /**
     * The size, in terabytes, of the data volume that will be created and attached to the database. This storage can later be scaled up if needed.
     */
    "dataStorageSizeInTBs"?: number;
    /**
     * Indicates if auto scaling is enabled for the ADB-S Database OCPU core count. The default value is FALSE
     */
    "isAutoScalingEnabled"?: boolean;
    /**
     * Indicates whether the ADB-S Database requires mTLS connections.
     */
    "isMtlsConnectionRequired"?: boolean;
    /**
     * The number of OCPU cores to be made available to the database.
     */
    "ocpuCount"?: number;
    /**
     * list of scheduled operations
     */
    "scheduledOperations"?: Array<AdbScheduledOperationDetails>;
    /**
     * Use NONE for creating a new ADB-S Database. Use DATABASE for creating a new ADB-S Database by cloning an existing ADB-S Database.
     */
    "source"?: CreateAdbsDatabaseDeploymentDetailsSourceEnum;
    /**
     * The client IP access control list (ACL).
     */
    "whitelistedIps"?: Array<string>;
    /**
     * The Oracle license model that applies to the Oracle Autonomous Database. Bring your own license (BYOL) allows you to apply your current on-premises Oracle software licenses to equivalent, highly automated Oracle PaaS and IaaS services in the cloud. License Included allows you to subscribe to new Oracle Database software licenses and the Database service. If a value is not specified, the system will supply the value of BRING_YOUR_OWN_LICENSE. 
     */
    "licenseModel"?: CreateAdbsDatabaseDeploymentDetailsLicenseModelEnum;
    /**
     * The (Virtual Network) VNET Id of Azure client.
     */
    "vnetId"?: string;
    /**
     * The subnet Id of Azure client.
     */
    "subnetId"?: string;
    /**
     * The Oracle Database Edition that applies to the Autonomous databases. 
     */
    "databaseEdition"?: CreateAdbsDatabaseDeploymentDetailsDatabaseEditionEnum;
    /**
     * specifies the network access options available when you provisioning an Autonomous Database.
     */
    "networkAccessType"?: CreateAdbsDatabaseDeploymentDetailsNetworkAccessTypeEnum;
    /**
     * Hostname alias for the IP address of the Autonomous Database Service. The hostname prefix can contain only letters and numbers. This property is only applicable when networkAccessType is PRIVATE
     */
    "hostnamePrefix"?: string;
    /**
     * The character set for the Autonomous Database.  The default is AL32UTF8. Allowed values for an Autonomous Database on shared infrastructure as as returned by [List Autonomous Database Character Sets](/autonomousDatabaseCharacterSets)  For an Autonomous Database on dedicated infrastructure, the allowed values are:  AL32UTF8, AR8ADOS710, AR8ADOS720, AR8APTEC715, AR8ARABICMACS, AR8ASMO8X, AR8ISO8859P6, AR8MSWIN1256, AR8MUSSAD768, AR8NAFITHA711, AR8NAFITHA721, AR8SAKHR706, AR8SAKHR707, AZ8ISO8859P9E, BG8MSWIN, BG8PC437S, BLT8CP921, BLT8ISO8859P13, BLT8MSWIN1257, BLT8PC775, BN8BSCII, CDN8PC863, CEL8ISO8859P14, CL8ISO8859P5, CL8ISOIR111, CL8KOI8R, CL8KOI8U, CL8MACCYRILLICS, CL8MSWIN1251, EE8ISO8859P2, EE8MACCES, EE8MACCROATIANS, EE8MSWIN1250, EE8PC852, EL8DEC, EL8ISO8859P7, EL8MACGREEKS, EL8MSWIN1253, EL8PC437S, EL8PC851, EL8PC869, ET8MSWIN923, HU8ABMOD, HU8CWI2, IN8ISCII, IS8PC861, IW8ISO8859P8, IW8MACHEBREWS, IW8MSWIN1255, IW8PC1507, JA16EUC, JA16EUCTILDE, JA16SJIS, JA16SJISTILDE, JA16VMS, KO16KSC5601, KO16KSCCS, KO16MSWIN949, LA8ISO6937, LA8PASSPORT, LT8MSWIN921, LT8PC772, LT8PC774, LV8PC1117, LV8PC8LR, LV8RST104090, N8PC865, NE8ISO8859P10, NEE8ISO8859P4, RU8BESTA, RU8PC855, RU8PC866, SE8ISO8859P3, TH8MACTHAIS, TH8TISASCII, TR8DEC, TR8MACTURKISHS, TR8MSWIN1254, TR8PC857, US7ASCII, US8PC437, UTF8, VN8MSWIN1258, VN8VN3, WE8DEC, WE8DG, WE8ISO8859P1, WE8ISO8859P15, WE8ISO8859P9, WE8MACROMAN8S, WE8MSWIN1252, WE8NCR4970, WE8NEXTSTEP, WE8PC850, WE8PC858, WE8PC860, WE8ROMAN8, ZHS16CGB231280, ZHS16GBK, ZHT16BIG5, ZHT16CCDC, ZHT16DBT, ZHT16HKSCS, ZHT16MSWIN950, ZHT32EUC, ZHT32SOPS, ZHT32TRIS 
     */
    "characterSet"?: string;
    /**
     * The national character set for the Autonomous Database.  The default is AL16UTF16. Use [List Autonomous Database Character Sets](/autonomousDatabaseCharacterSets) to list the allowed values for an Autonomous Database on shared Exadata infrastructure. For an Autonomous Database on dedicated Exadata infrastructure, the allowed values are: AL16UTF16 or UTF8. 
     */
    "ncharacterSet"?: string;
}

export type CreateAdbsDatabaseDeploymentDetailsDbWorkloadEnum = "OLTP" | "DW" | "AJD" | "APEX";
export type CreateAdbsDatabaseDeploymentDetailsSourceEnum = "NONE" | "DATABASE";
export type CreateAdbsDatabaseDeploymentDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
export type CreateAdbsDatabaseDeploymentDetailsDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION";
export type CreateAdbsDatabaseDeploymentDetailsNetworkAccessTypeEnum = "EVERYWHERE" | "ALLOWED_IPS" | "PRIVATE" | "PRIVATE_PEERING";
/**
 * Details for creating ADB-S database.
 */
export interface CreateAdbsDatabaseDetails extends CreateAdbsDatabaseBaseDetails {
}

/**
 * Details about the issue that the billing support ticket relates to. Avoid entering confidential information.
 */
export interface CreateBillingItemDetails extends CreateItemDetails {
}

/**
 * Details for the creating infrastructure deployment details. 
 */
export interface CreateCloudExadataInfrastructureDeploymentDetails extends CloudExadataInfrastructureDeploymentDetails {
    /**
     * The name for the cloud Exadata infrastructure resource. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The user-friendly name for the cloud Exadata infrastructure resource. The name does not need to be unique. 
     */
    "displayName"?: string;
    /**
     * The shape of the cloud Exadata infrastructure resource. 
     */
    "shape": string;
    /**
     * The number of compute servers for the cloud Exadata infrastructure.
     */
    "computeCount"?: number;
    /**
     * The number of storage servers for the cloud Exadata infrastructure.
     */
    "storageCount"?: number;
    "maintenanceWindow"?: MaintenanceWindow;
    /**
     * Customer contacts.
     */
    "customerContacts"?: Array<CustomerContact>;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

/**
 * Details for the create cloud VM cluster operation. 
 */
export interface CreateCloudVmClusterDeploymentDetails extends CloudVmClusterDeploymentDetails {
    /**
     * The number of CPU cores to enable for a cloud VM cluster. Valid values depend on the specified shape:  - Exadata.Base.48 - Specify a multiple of 2, from 0 to 48. - Exadata.Quarter1.84 - Specify a multiple of 2, from 22 to 84. - Exadata.Half1.168 - Specify a multiple of 4, from 44 to 168. - Exadata.Full1.336 - Specify a multiple of 8, from 88 to 336. - Exadata.Quarter2.92 - Specify a multiple of 2, from 0 to 92. - Exadata.Half2.184 - Specify a multiple of 4, from 0 to 184. - Exadata.Full2.368 - Specify a multiple of 8, from 0 to 368. 
     */
    "cpuCoreCount": number;
    /**
     * The number of OCPU cores to enable for a cloud VM cluster. Only 1 decimal place is allowed for the fractional part.
     */
    "ocpuCount"?: number;
    /**
     * The cluster name for cloud VM cluster. The cluster name must begin with an alphabetic character, and may contain hyphens (-). Underscores (_) are not permitted. The cluster name can be no longer than 11 characters and is not case sensitive. 
     */
    "clusterName"?: string;
    /**
     * The percentage assigned to DATA storage (user data and database files). The remaining percentage is assigned to RECO storage (database redo logs, archive logs, and recovery manager backups). Accepted values are 35, 40, 60 and 80. The default is 80 percent assigned to DATA storage. See [Storage Configuration](/Content/Database/Concepts/exaoverview.htm#Exadata) in the Exadata documentation for details on the impact of the configuration settings on storage. 
     */
    "dataStoragePercentage"?: number;
    /**
     * The name for the cloud VM cluster. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The user-friendly name for the cloud VM cluster. The name does not need to be unique.
     */
    "displayName"?: string;
    /**
     * The hostname for the cloud VM cluster. The hostname must begin with an alphabetic character, and can contain alphanumeric characters and hyphens (-). The maximum length of the hostname is 16 characters for bare metal and virtual machine DB systems, and 12 characters for Exadata systems.  The maximum length of the combined hostname and domain is 63 characters.  **Note:** The hostname must be unique within the subnet. If it is not unique, the cloud VM Cluster will fail to provision. 
     */
    "hostname": string;
    /**
     * A domain name used for the cloud VM cluster. If the Oracle-provided internet and VCN resolver is enabled for the specified subnet, the domain name for the subnet is used (do not provide one). Otherwise, provide a valid DNS domain name. Hyphens (-) are not permitted. Applies to Exadata Cloud Service instances only. 
     */
    "domain"?: string;
    /**
     * The public key portion of one or more key pairs used for SSH access to the cloud VM cluster.
     */
    "sshPublicKeys": Array<string>;
    /**
     * The Oracle license model that applies to the cloud VM cluster. The default is BRING_YOUR_OWN_LICENSE. 
     */
    "licenseModel"?: CreateCloudVmClusterDeploymentDetailsLicenseModelEnum;
    /**
     * If true, the sparse disk group is configured for the cloud VM cluster. If false, the sparse disk group is not created. 
     */
    "isSparseDiskgroupEnabled"?: boolean;
    /**
     * If true, database backup on local Exadata storage is configured for the cloud VM cluster. If false, database backup on local Exadata storage is not available in the cloud VM cluster. 
     */
    "isLocalBackupEnabled"?: boolean;
    /**
     * The time zone to use for the cloud VM cluster. For details, see [Time Zones](/Content/Database/References/timezones.htm). 
     */
    "timeZone"?: string;
    /**
     * The TCP Single Client Access Name (SCAN) port. The default port is 1521.
     */
    "scanListenerPortTcp"?: number;
    /**
     * The TCPS Single Client Access Name (SCAN) port. The default port is 2484.
     */
    "scanListenerPortTcpSsl"?: number;
    /**
     * A valid Oracle Grid Infrastructure (GI) software version.
     */
    "giVersion": string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

export type CreateCloudVmClusterDeploymentDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * Details for creating a new database deployment. 
 */
export interface CreateDatabaseDeploymentDetails extends DatabaseDeploymentDetails {
    /**
     * The name for the database. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The database name. The name must begin with an alphabetic character and can contain a maximum of eight alphanumeric characters. Special characters are not permitted.
     */
    "dbName": string;
    /**
     * The `DB_UNIQUE_NAME` of the Oracle Database being backed up.
     */
    "dbUniqueName"?: string;
    /**
     * The name of the pluggable database. The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. Pluggable database should not be same as database name.
     */
    "pdbName"?: string;
    /**
     * A strong password for SYS, SYSTEM, and PDB Admin. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numbers, and two special characters. The special characters must be _, \\#, or -.
     */
    "adminPassword": string;
    /**
     * The optional password to open the TDE wallet. The password must be at least nine characters and contain at least two uppercase, two lowercase, two numeric, and two special characters. The special characters must be _, \\#, or -.
     */
    "tdeWalletPassword"?: string;
    /**
     * The character set for the database.  The default is AL32UTF8. Allowed values are:  AL32UTF8, AR8ADOS710, AR8ADOS720, AR8APTEC715, AR8ARABICMACS, AR8ASMO8X, AR8ISO8859P6, AR8MSWIN1256, AR8MUSSAD768, AR8NAFITHA711, AR8NAFITHA721, AR8SAKHR706, AR8SAKHR707, AZ8ISO8859P9E, BG8MSWIN, BG8PC437S, BLT8CP921, BLT8ISO8859P13, BLT8MSWIN1257, BLT8PC775, BN8BSCII, CDN8PC863, CEL8ISO8859P14, CL8ISO8859P5, CL8ISOIR111, CL8KOI8R, CL8KOI8U, CL8MACCYRILLICS, CL8MSWIN1251, EE8ISO8859P2, EE8MACCES, EE8MACCROATIANS, EE8MSWIN1250, EE8PC852, EL8DEC, EL8ISO8859P7, EL8MACGREEKS, EL8MSWIN1253, EL8PC437S, EL8PC851, EL8PC869, ET8MSWIN923, HU8ABMOD, HU8CWI2, IN8ISCII, IS8PC861, IW8ISO8859P8, IW8MACHEBREWS, IW8MSWIN1255, IW8PC1507, JA16EUC, JA16EUCTILDE, JA16SJIS, JA16SJISTILDE, JA16VMS, KO16KSC5601, KO16KSCCS, KO16MSWIN949, LA8ISO6937, LA8PASSPORT, LT8MSWIN921, LT8PC772, LT8PC774, LV8PC1117, LV8PC8LR, LV8RST104090, N8PC865, NE8ISO8859P10, NEE8ISO8859P4, RU8BESTA, RU8PC855, RU8PC866, SE8ISO8859P3, TH8MACTHAIS, TH8TISASCII, TR8DEC, TR8MACTURKISHS, TR8MSWIN1254, TR8PC857, US7ASCII, US8PC437, UTF8, VN8MSWIN1258, VN8VN3, WE8DEC, WE8DG, WE8ISO8859P1, WE8ISO8859P15, WE8ISO8859P9, WE8MACROMAN8S, WE8MSWIN1252, WE8NCR4970, WE8NEXTSTEP, WE8PC850, WE8PC858, WE8PC860, WE8ROMAN8, ZHS16CGB231280, ZHS16GBK, ZHT16BIG5, ZHT16CCDC, ZHT16DBT, ZHT16HKSCS, ZHT16MSWIN950, ZHT32EUC, ZHT32SOPS, ZHT32TRIS 
     */
    "characterSet"?: string;
    /**
     * The national character set for the database.  The default is AL16UTF16. Allowed values are: AL16UTF16 or UTF8. 
     */
    "ncharacterSet"?: string;
    /**
     * The database workload type.
     */
    "dbWorkload"?: CreateDatabaseDeploymentDetailsDbWorkloadEnum;
    "dbBackupConfig"?: DbBackupConfig;
    /**
     * The database domain. In a distributed database system, DB_DOMAIN specifies the logical location of the database within the network structure.
     */
    "dbDomain"?: string;
    /**
     * Specifies a prefix for the `Oracle SID` of the database to be created. 
     */
    "sidPrefix"?: string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * A valid Oracle Database version. To get a list of supported versions, use the [ListDbVersions](#/en/database/latest/DbVersionSummary/ListDbVersions) operation.
     */
    "dbVersion"?: string;
    /**
     * The fully qualified Azure resource ID of the Database Home.
     */
    "dbHomeId"?: string;
    /**
     * User shall provide either a dbHomeId (to reuse an existing dbHome) OR both dbVersion and dbHomeName (to create a new dbHome). dbHomeName is a user provided name of the DB Home which can contain a maximum of 255 alphanumeric characters. 
     */
    "dbHomeName"?: string;
}

export type CreateDatabaseDeploymentDetailsDbWorkloadEnum = "OLTP" | "DSS";
/**
 * Details for creating a new DbHome with VM Cluster Id.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateDbHomeDetails extends CreateDbHomeBase {
    /**
     * The fully qualified Azure resource ID of the VM cluster.
     */
    "vmClusterId": string;
    /**
     * The Oracle Database version.
     */
    "dbVersion"?: string;
}

/**
 * Details for creating a new Heatwave Cluster deployment. 
 */
export interface CreateHeatwaveClusterDeploymentDetails extends HeatwaveClusterDeploymentDetails {
    /**
     * The shape determines resources to allocate to the HeatWave nodes - CPU cores, memory. 
     */
    "shapeName": string;
    /**
     * The number of analytics-processing nodes provisioned for the HeatWave cluster. 
     */
    "clusterSize": number;
}

/**
 * Details about the service limit increase request. Avoid entering confidential information.
 */
export interface CreateLimitItemDetails extends CreateItemDetails {
    /**
     * The service category, such as a cloud link or network.
     */
    "serviceCategory"?: CreateLimitItemDetailsServiceCategoryEnum;
    /**
     * The limit id, for example ee-ocpu-count
     */
    "limitId"?: string;
    /**
     * The limit of the resource currently available.
     */
    "currentLimit"?: number;
    /**
     * The current usage of the resource.
     */
    "currentUsage"?: number;
    /**
     * The new service limit being requested.
     */
    "requestedLimit"?: number;
}

export type CreateLimitItemDetailsServiceCategoryEnum = "NETWORK" | "EXADATADB" | "AUTONOMOUSDB_SHARED" | "AUTONOMOUSDB_SERVERLESS" | "AUTONOMOUSDB_DEDICATED" | "VMDB" | "MDS" | "MULTICLOUDLINK";
/**
 * Use the backupId to specify from which backup the new DB System will be created. 
 */
export interface CreateMdsDbSystemSourceFromBackupDetails extends CreateMdsDbSystemSourceDetails {
    /**
     * The OCID of the backup to be used as the source for the new DB System. 
     */
    "backupId": string;
}

/**
 * Creation of a DbSystem from no particular source. 
 */
export interface CreateMdsDbSystemSourceFromNoneDetails extends CreateMdsDbSystemSourceDetails {
}

/**
 * DB System OCID to perform a point in time recovery to the current point in time.  DB System OCID and recovery point to perform a point in time recovery to the specified recovery point. 
 */
export interface CreateMdsDbSystemSourceFromPitrDetails extends CreateMdsDbSystemSourceDetails {
    /**
     * The OCID of the DB System from which a backup shall be selected to be restored when creating the new DB System. Use this together with recovery point to perform a point in time recovery operation. 
     */
    "dbSystemId": string;
    /**
     * The date and time, as per RFC 3339, of the change up to which the new DB System shall be restored to, using a backup and logs from the original DB System. In case no point in time is specified, then this new DB System shall be restored up to the latest change recorded for the original DB System. 
     */
    "recoveryPoint"?: Date;
}

/**
 * An Object Storage PAR from which to import the DB System initial data. 
 */
export interface CreateMdsDbSystemSourceImportFromUrlDetails extends CreateMdsDbSystemSourceDetails {
    /**
     * The Pre-Authenticated Request (PAR) of a bucket/prefix or PAR of a @.manifest.json object from the Object Storage. Check [Using Pre-Authenticated Requests](https://docs.oracle.com/en-us/iaas/Content/Object/Tasks/usingpreauthenticatedrequests.htm) for information related to PAR creation. Please create PAR with \"Permit object reads\" access type and \"Enable Object Listing\" permission when using a bucket/prefix PAR. Please create PAR with \"Permit object reads\" access type when using a @.manifest.json object PAR. 
     */
    "sourceUrl": string;
}

/**
 * Details for creating a new MDS deployment. 
 */
export interface CreateMdsSystemDeploymentDetails extends MdsSystemDeploymentDetails {
    /**
     * The user-friendly name for the DB System. It does have to be unique in resource group.
     */
    "name": string;
    /**
     * User-provided data about the DB System.
     */
    "description"?: string;
    /**
     * The availability domain where the primary instance should be located.
     */
    "availabilityDomain"?: string;
    /**
     * The name of the Fault Domain the DB System is located in.
     */
    "faultDomain"?: string;
    /**
     * The name of the shape. The shape determines the resources allocated - CPU cores and memory for VM shapes; CPU cores, memory and storage for non-VM (or bare metal) shapes. To get a list of shapes, use the [ListShapes](#/en/mysql/20190415/ShapeSummary/ListShapes) operation. 
     */
    "shapeName": string;
    /**
     * The specific MySQL version identifier.
     */
    "mysqlVersion"?: string;
    /**
     * The username for the administrative user.
     */
    "adminUsername": string;
    /**
     * The password for the administrative user. The password must be between 8 and 32 characters long, and must contain at least 1 numeric character, 1 lowercase character, 1 uppercase character, and 1 special (nonalphanumeric) character. 
     */
    "adminPassword": string;
    /**
     * Initial size of the data volume in GBs that will be created and attached. Keep in mind that this only specifies the size of the database data volume, the log volume for the database will be scaled appropriately with its shape. 
     */
    "dataStorageSizeInGBs"?: number;
    /**
     * The hostname for the primary endpoint of the DB System. Used for DNS.  The value is the hostname portion of the primary private IP's fully qualified domain name (FQDN) (for example, \"dbsystem-1\" in FQDN \"dbsystem-1.subnet123.vcn1.oraclevcn.com\").  Must be unique across all VNICs in the subnet and comply with RFC 952 and RFC 1123. 
     */
    "hostnameLabel"?: string;
    /**
     * The IP address the DB System is configured to listen on. A private IP address of your choice to assign to the primary endpoint of the DB System. Must be an available IP address within the subnet's CIDR. If you don't specify a value, Oracle automatically assigns a private IP address from the subnet. This should be a \"dotted-quad\" style IPv4 address. 
     */
    "ipAddress"?: string;
    /**
     * The port for primary endpoint of the DB System to listen on.
     */
    "port"?: number;
    /**
     * The TCP network port on which X Plugin listens for connections. This is the X Plugin equivalent of port. 
     */
    "portX"?: number;
    "backupPolicy"?: CreateMdsBackupPolicyDetails;
    "source"?: CreateMdsDbSystemSourceDetails;
    "maintenance"?: CreateMdsMaintenanceDetails;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
    "deletionPolicy"?: CreateMdsDeletionPolicyDetails;
    /**
     * Whether to run the DB System with InnoDB Redo Logs and the Double Write Buffer enabled or disabled, and whether to enable or disable syncing of the Binary Logs. 
     */
    "crashRecovery"?: CreateMdsSystemDeploymentDetailsCrashRecoveryEnum;
}

export type CreateMdsSystemDeploymentDetailsCrashRecoveryEnum = "ENABLED" | "DISABLED";
/**
 * The information for creating a new Multi Cloud Virtual Network.
 */
export interface CreateMultiCloudVirtualNetworkDeploymentDetails extends MultiCloudVirtualNetworkDeploymentDetails {
    /**
     * MultiCloud VCN Identifier
     */
    "name": string;
    /**
     * The list of one or more IPv4 CIDR blocks for the VCN that meet the following criteria:   - The CIDR blocks must be valid.   - They must not overlap with each other or with the on-premises network CIDR block.   - The number of CIDR blocks must not exceed the limit of CIDR blocks allowed per VCN.   - Enforce minimum size to be at least /23 
     */
    "ociVcnCidrBlocks": Array<string>;
    /**
     * The (Virtual Network) VNET Id where the Azure client will reside.
     */
    "azureAttachedNetworkIds": Array<string>;
    /**
     * Optional field to specify the IP address to which return traffic is sent from the Multicloud VCN
     */
    "customerNvaIpAddress"?: string;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

/**
 * The information about new NetworkLink deployment.
 */
export interface CreateNetworkLinkDeploymentDetails extends NetworkLinkDeploymentDetails {
    /**
     * NetworkLink Identifier
     */
    "name": string;
    /**
     * The CIDRs that will be used to bring up the VirtualCloudNetwork (VCN) in OCI. These can only be ipv4 CIDRS. The list of one or more IPv4 CIDR blocks for the VCN that meet the following criteria   - The CIDR blocks must be valid.   - They must not overlap with each other or with the on-premises network CIDR block.   - The number of CIDR blocks must not exceed the limit of CIDR blocks allowed per VCN. 
     */
    "ociNetworkCidrs": Array<string>;
    /**
     * The (Virtual Network) VNET Id where the Azure client will reside.
     */
    "azureAttachedNetworkIds": Array<string>;
    /**
     * Optional field to specify the IP address to which return traffic is sent from the Multicloud VCN
     */
    "customerNvaIpAddress"?: string;
}

/**
 * Details for creating a new database.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateNewDatabaseDetails extends CreateDatabaseBase {
    "database": CreateDatabaseDetails;
}

/**
 * Information for creating a new MultiCloudVirtualNetwork. 
 */
export interface CreateNewNetworkDetails extends CreateMultiCloudVirtualNetworkDetails {
    /**
     * The list of one or more IPv4 CIDR blocks for the VCN that meet the following criteria: - The CIDR blocks must be valid. - They must not overlap with each other or with the on-premises network CIDR block. - The number of CIDR blocks must not exceed the limit of CIDR blocks allowed per VCN. - Enforce minimum size to be at least /23 
     */
    "ociVcnCidrBlocks": Array<string>;
    /**
     * Simple key-value pair that is applied without any predefined name, type or scope. Exists for cross-compatibility only. Example: `{\"bar-key\": \"value\"}` 
     */
    "freeformTags"?: { [key: string]: string; };
}

/**
 * Details about the issue that the technical support ticket relates to. Avoid entering confidential information.
 */
export interface CreateTechSupportItemDetails extends CreateItemDetails {
    /**
     * The service category, such as a cloud link or network.
     */
    "serviceCategory"?: CreateTechSupportItemDetailsServiceCategoryEnum;
}

export type CreateTechSupportItemDetailsServiceCategoryEnum = "NETWORK" | "EXADATADB" | "AUTONOMOUSDB_SHARED" | "AUTONOMOUSDB_SERVERLESS" | "AUTONOMOUSDB_DEDICATED" | "VMDB" | "MDS" | "MULTICLOUDLINK";
/**
 * Details for creating a new Vmdb deployment. 
 */
export interface CreateVmdbDbSystemDeploymentDetails extends VmdbDbSystemDeploymentDetails {
    /**
     * The name for the DbSystem. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The number of CPU cores to enable for a DbSystem. Valid values depend on the specified shape:  - VM.Standard.E4.Flex - Specify any thing from 1 to 64. 
     */
    "cpuCoreCount": number;
    /**
     * The public key portion of one or more key pairs used for SSH access to the cloud VM cluster.
     */
    "sshPublicKeys": Array<string>;
    /**
     * The hostname for the DbSystem. The hostname must begin with an alphabetic character, and can contain alphanumeric characters and hyphens (-). The maximum length of the hostname is 16 characters for virtual machine DB systems.  The maximum length of the combined hostname and domain is 63 characters.  **Note:** The hostname must be unique within the subnet. If it is not unique, the DbSystem will fail to provision. 
     */
    "hostname": string;
    /**
     * The shape of the DB system. The shape determines resources allocated to the DB system.  For virtual machine shapes, the number of CPU cores and memory 
     */
    "shape": string;
    "dbSystemOptions"?: VmdbCreateDbSystemOptions;
    /**
     * Size (in GB) of the initial data volume that will be created and attached to a virtual machine DB system.
     */
    "initialDataStorageSizeInGBs"?: number;
    /**
     * The number of nodes to launch for a 2-node RAC virtual machine DB system.
     */
    "nodeCount"?: number;
    /**
     * The block storage volume performance level. Valid values are BALANCED and HIGH_PERFORMANCE. HIGH_PERFORMANCE is for E4.Flex shapes only. See Block Volume Performance for more information.
     */
    "storageVolumePerformanceMode"?: CreateVmdbDbSystemDeploymentDetailsStorageVolumePerformanceModeEnum;
    /**
     * The cluster name for 2-node RAC virtual machine DB systems. The cluster name must begin with an alphabetic character, and may contain hyphens (-). Underscores (_) are not permitted. The cluster name can be no longer than 11 characters and is not case sensitive.
     */
    "clusterName"?: string;
    /**
     * The domain name for the DB system.
     */
    "domain"?: string;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The time zone to use for the DB system. For details, see DB System Time Zones.
     */
    "timeZone"?: string;
    /**
     * The Oracle Database edition that applies to all the databases on the DB system.
     */
    "databaseEdition": CreateVmdbDbSystemDeploymentDetailsDatabaseEditionEnum;
    "dbHome": CreateVmdbDbHomeDetails;
    /**
     * The Oracle license model that applies to all the databases on the DB system. The default is LICENSE_INCLUDED.
     */
    "licenseModel"?: CreateVmdbDbSystemDeploymentDetailsLicenseModelEnum;
    "maintenanceWindowDetails"?: MaintenanceWindow;
}

export type CreateVmdbDbSystemDeploymentDetailsStorageVolumePerformanceModeEnum = "BALANCED" | "HIGH_PERFORMANCE";
export type CreateVmdbDbSystemDeploymentDetailsDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION" | "ENTERPRISE_EDITION_HIGH_PERFORMANCE" | "ENTERPRISE_EDITION_EXTREME_PERFORMANCE";
export type CreateVmdbDbSystemDeploymentDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * Details for creating a new dbSystem.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateVmdbDbSystemDetails extends CreateVmdbDbSystemBase {
    /**
     * The cluster name for 2-node RAC virtual machine DB systems. The cluster name must begin with an alphabetic character, and may contain hyphens (-). Underscores (_) are not permitted. The cluster name can be no longer than 11 characters and is not case sensitive.
     */
    "clusterName"?: string;
    /**
     * The domain name for the DB system.
     */
    "domain"?: string;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The time zone to use for the DB system. For details, see DB System Time Zones.
     */
    "timeZone"?: string;
    /**
     * The Oracle Database edition that applies to all the databases on the DB system.
     */
    "databaseEdition": CreateVmdbDbSystemDetailsDatabaseEditionEnum;
    "dbHome": CreateVmdbDbHomeDetails;
    /**
     * The Oracle license model that applies to all the databases on the DB system. The default is LICENSE_INCLUDED.
     */
    "licenseModel"?: CreateVmdbDbSystemDetailsLicenseModelEnum;
    "maintenanceWindowDetails"?: MaintenanceWindow;
}

export type CreateVmdbDbSystemDetailsDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION" | "ENTERPRISE_EDITION_HIGH_PERFORMANCE" | "ENTERPRISE_EDITION_EXTREME_PERFORMANCE";
export type CreateVmdbDbSystemDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * Details for creating a new dbSystem from a database backup.  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface CreateVmdbDbSystemFromBackupDetails extends CreateVmdbDbSystemBase {
    /**
     * The cluster name for virtual machine DB systems. The cluster name must begin with an alphabetic character, and may contain hyphens (-). Underscores (_) are not permitted. The cluster name can be no longer than 11 characters and is not case sensitive.
     */
    "clusterName"?: string;
    /**
     * The percentage assigned to DATA storage (user data and database files). The remaining percentage is assigned to RECO storage (database redo logs, archive logs, and recovery manager backups). Accepted values are 40 and 80. The default is 80 percent assigned to DATA storage. Not applicable for virtual machine DB systems.
     */
    "dataStoragePercentage"?: number;
    /**
     * The domain name for the DB system.
     */
    "domain"?: string;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The time zone to use for the DB system. For details, see DB System Time Zones.
     */
    "timeZone"?: string;
    /**
     * The Oracle Database edition that applies to all the databases on the DB system.
     */
    "databaseEdition": CreateVmdbDbSystemFromBackupDetailsDatabaseEditionEnum;
    "dbHome": CreateVmdbDbHomeFromBackupDetails;
    /**
     * The Oracle license model that applies to all the databases on the DB system. The default is LICENSE_INCLUDED.
     */
    "licenseModel"?: CreateVmdbDbSystemFromBackupDetailsLicenseModelEnum;
}

export type CreateVmdbDbSystemFromBackupDetailsDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION" | "ENTERPRISE_EDITION_HIGH_PERFORMANCE" | "ENTERPRISE_EDITION_EXTREME_PERFORMANCE";
export type CreateVmdbDbSystemFromBackupDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * An EXA Database for Azure.
 */
export interface Database extends DatabaseBase {
    "observabilityDetails"?: ObservabilityDetails;
}

export interface DbHome {
    /**
     * The unique of the Database Home.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: DbHomeStatusEnum;
    /**
     * The user-provided name for the Database Home. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The user-provided name for the Database Home. The name does not need to be unique.
     */
    "displayName"?: string;
    /**
     * The current state of the Database Home.
     */
    "lifecycleState": DbHomeLifecycleStateEnum;
    /**
     * The fully qualified Azure resource ID of the VM cluster.
     */
    "vmClusterId"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The Oracle Database version.
     */
    "dbVersion": string;
    /**
     * The location of the Oracle Database Home.
     */
    "dbHomeLocation": string;
    /**
     * Additional information about the current lifecycle state.
     */
    "lifecycleDetails"?: string;
    /**
     * The date and time the Database Home was created.
     */
    "timeCreated"?: Date;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: DbHomeLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type DbHomeStatusEnum = "Provisioning" | "Updating" | "Terminating" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type DbHomeLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "UPDATING" | "TERMINATING" | "TERMINATED" | "FAILED";
export type DbHomeLastOperationStatusEnum = "Succeeded" | "Failed";
export interface DbNode {
    /**
     * The fully qualified Azure resource ID of the database node.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The user-friendly name for the database node. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: DbNodeStatusEnum;
    /**
     * The host IP address associated with the database node.  **Note:** Applies only to Exadata Cloud Service. 
     */
    "hostIp"?: string;
    /**
     * The current state of the database node.
     */
    "lifecycleState": DbNodeLifecycleStateEnum;
    /**
     * The host name for the database node.
     */
    "hostname"?: string;
    /**
     * The date and time that the database node was created.
     */
    "timeCreated": Date;
    /**
     * The size (in GB) of the block storage volume allocation for the DB system. This attribute applies only for virtual machine DB systems. 
     */
    "softwareStorageSizeInGB"?: number;
    /**
     * The type of database node maintenance.
     */
    "maintenanceType"?: DbNodeMaintenanceTypeEnum;
    /**
     * Start date and time of maintenance window.
     */
    "timeMaintenanceWindowStart"?: Date;
    /**
     * End date and time of maintenance window.
     */
    "timeMaintenanceWindowEnd"?: Date;
    /**
     * Additional information about the planned maintenance.
     */
    "additionalDetails"?: string;
    /**
     * The number of CPU cores enabled on the Db node.
     */
    "cpuCoreCount"?: number;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The allocated memory in GBs on the Db node.
     */
    "memorySizeInGBs"?: number;
    /**
     * The allocated local node storage in GBs on the Db node.
     */
    "dbNodeStorageSizeInGBs"?: number;
    /**
     * The fully qualified Azure resource ID of the VM cluster.
     */
    "vmClusterId"?: string;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: DbNodeLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type DbNodeStatusEnum = "Provisioning" | "Updating" | "Stopping" | "Starting" | "Terminating" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type DbNodeLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "UPDATING" | "STOPPING" | "STOPPED" | "STARTING" | "TERMINATING" | "TERMINATED" | "FAILED";
export type DbNodeMaintenanceTypeEnum = "VMDB_REBOOT_MIGRATION";
export type DbNodeLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * New Exadata deployment parameters.
 */
export interface ExadataDeploymentParametersDetails extends DeploymentParametersDetails {
    "multiCloudVirtualNetworkDetails"?: MultiCloudVirtualNetworkDeploymentDetails;
    "networkLinkDetails"?: NetworkLinkDeploymentDetails;
    "cloudExadataInfrastructureDetails"?: CloudExadataInfrastructureDeploymentDetails;
    "cloudVmClusterDetails"?: CloudVmClusterDeploymentDetails;
    "databaseDetails"?: DatabaseDeploymentDetails;
}

/**
 * Details for the get Autonomous Container Database operation.
 */
export interface GetAdbdContainerDatabaseDeploymentDetails extends AdbdContainerDatabaseDeploymentDetails {
    /**
     * The Autonomous Container Database identifier.
     */
    "id": string;
}

/**
 * Details for the get cloud Autonomous VM cluster operation.
 */
export interface GetAdbdVmClusterDeploymentDetails extends AdbdVmClusterDeploymentDetails {
    /**
     * The cloud Autonomous VM cluster identifier.
     */
    "id": string;
}

/**
 * Details for the getting infrastructure deployment details.
 */
export interface GetCloudExadataInfrastructureDeploymentDetails extends CloudExadataInfrastructureDeploymentDetails {
    /**
     * The Azure unique Identifier of the cloud Exadata infrastructure resource.
     */
    "id": string;
}

/**
 * Details for the get cloud VM cluster operation.
 */
export interface GetCloudVmClusterDeploymentDetails extends CloudVmClusterDeploymentDetails {
    /**
     * The VM cluster identifier.
     */
    "id": string;
}

/**
 * The information about existing MDS deployment.
 */
export interface GetHeatwaveClusterDeploymentDetails extends HeatwaveClusterDeploymentDetails {
    /**
     * The Heatwave Cluster identifier.
     */
    "id": string;
}

/**
 * The information about existing MDS deployment.
 */
export interface GetMdsSystemDeploymentDetails extends MdsSystemDeploymentDetails {
    /**
     * The MDS Database identifier.
     */
    "id": string;
}

/**
 * The information about existing Multi Cloud Virtual Network.
 */
export interface GetMultiCloudVirtualNetworkDeploymentDetails extends MultiCloudVirtualNetworkDeploymentDetails {
    /**
     * The azure looking id of the MultiCloudVirtualNetwork.
     */
    "id": string;
}

/**
 * The information about existing NetworkLink deployment.
 */
export interface GetNetworkLinkDeploymentDetails extends NetworkLinkDeploymentDetails {
    /**
     * The Azure unique identifier of the NetworkLink.
     */
    "id": string;
}

/**
 * New My SQL Heatwave deployment parameters.
 */
export interface HeatwaveDeploymentParametersDetails extends DeploymentParametersDetails {
    "multiCloudVirtualNetworkDetails"?: MultiCloudVirtualNetworkDeploymentDetails;
    "networkLinkDetails"?: NetworkLinkDeploymentDetails;
    "mdsDbSystemDeploymentDetails"?: MdsSystemDeploymentDetails;
    "heatwaveClusterDeploymentDetails"?: HeatwaveClusterDeploymentDetails;
}

/**
 * Details about the LimitItem object.
 */
export interface LimitItem extends Item {
    /**
     * The service category, such as a cloud link or network.
     */
    "serviceCategory"?: LimitItemServiceCategoryEnum;
    /**
     * The limit id, for example ee-ocpu-count
     */
    "limitId"?: string;
    /**
     * The current service limit for the resource.
     */
    "currentLimit"?: number;
    /**
     * The current resource usage.
     */
    "currentUsage"?: number;
    /**
     * The new service limit being requested for the resource.
     */
    "requestedLimit"?: number;
    /**
     * The status of the request.
     */
    "limitStatus"?: LimitItemLimitStatusEnum;
}

export type LimitItemServiceCategoryEnum = "NETWORK" | "EXADATADB" | "AUTONOMOUSDB_SHARED" | "AUTONOMOUSDB_SERVERLESS" | "AUTONOMOUSDB_DEDICATED" | "VMDB" | "MDS" | "MULTICLOUDLINK";
export type LimitItemLimitStatusEnum = "APPROVED" | "PARTIALLY_APPROVED" | "NOT_APPROVED";
/**
 * From which backup this DB System was created. 
 */
export interface MdsDbSystemSourceFromBackup extends MdsDbSystemSource {
    /**
     * The OCID of the backup to be used as the source for the new DB System. 
     */
    "backupId": string;
}

/**
 * A DB System created from no particular external source. 
 */
export interface MdsDbSystemSourceFromNone extends MdsDbSystemSource {
}

/**
 * DB System OCID to perform a point in time recovery to the current point in time.  DB System OCID and recovery point to perform a point in time recovery to the specified recovery point. 
 */
export interface MdsDbSystemSourceFromPitr extends MdsDbSystemSource {
    /**
     * The OCID of the DB System from which a backup shall be selected to be restored when creating the new DB System. Use this together with recovery point to perform a point in time recovery operation. 
     */
    "dbSystemId": string;
    /**
     * The date and time, as per RFC 3339, of the change up to which the new DB System shall be restored to, using a backup and logs from the original DB System. In case no point in time is specified, then this new DB System shall be restored up to the latest change recorded for the original DB System. 
     */
    "recoveryPoint"?: Date;
}

/**
 * An Object Storage PAR from which to import the DB System initial data. 
 */
export interface MdsDbSystemSourceImportFromUrl extends MdsDbSystemSource {
}

/**
 * A pluggable database (PDB) is portable collection of schemas, schema objects, and non-schema objects that appears to an Oracle client as a non-container database. To use a PDB, it needs to be plugged into a CDB. To use any of the API operations, you must be authorized in an IAM policy. If you are not authorized, talk to a tenancy administrator. If you are an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface PluggableDatabase {
    /**
     * The fully qualified Azure resource ID of the pluggable database.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status"?: PluggableDatabaseStatusEnum;
    /**
     * The fully qualified Azure resource ID of the CDB.
     */
    "containerDatabaseId": string;
    /**
     * The name for the pluggable database (PDB). The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The name for the pluggable database (PDB). The name is unique in the context of a [container database](#/en/database/latest/Database/). The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. The pluggable database name should not be same as the container database name.
     */
    "pdbName": string;
    /**
     * The current state of the pluggable database.
     */
    "lifecycleState": PluggableDatabaseLifecycleStateEnum;
    /**
     * Detailed message for the lifecycle state.
     */
    "lifecycleDetails"?: string;
    /**
     * Free-form tags for this resource.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The date and time the pluggable database was created.
     */
    "timeCreated": Date;
    "connectionStrings"?: PluggableDatabaseConnectionStrings;
    /**
     * The mode that pluggable database is in. Open mode can only be changed to READ_ONLY or MIGRATE directly from the backend (within the Oracle Database software). 
     */
    "openMode": PluggableDatabaseOpenModeEnum;
    /**
     * The restricted mode of the pluggable database. If a pluggable database is opened in restricted mode, the user needs both create a session and have restricted session privileges to connect to it. 
     */
    "isRestricted"?: boolean;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: PluggableDatabaseLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type PluggableDatabaseStatusEnum = "Provisioning" | "Updating" | "Terminating" | "Succeeded" | "Active" | "Failed" | "Canceled";
export type PluggableDatabaseLifecycleStateEnum = "PROVISIONING" | "AVAILABLE" | "TERMINATING" | "TERMINATED" | "UPDATING" | "FAILED";
export type PluggableDatabaseOpenModeEnum = "READ_ONLY" | "READ_WRITE" | "MOUNTED" | "MIGRATE";
export type PluggableDatabaseLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * Details of the Azure region.
 */
export interface RegionInfo {
    /**
     * Azure region name.
     */
    "regionName": string;
    /**
     * Azure region display name.
     */
    "regionDisplayName"?: string;
    /**
     * OCI region name.
     */
    "ociRegionName": string;
    /**
     * OCI region display name.
     */
    "ociRegionDisplayName"?: string;
    /**
     * endpoint URL.
     */
    "endpointUrl": string;
}

/**
 * The information for registering existing OCI resources like VCN and subnets to create a Multi Cloud Virtual Network.
 */
export interface RegisterExistingOciNetworkDeploymentDetails extends MultiCloudVirtualNetworkDeploymentDetails {
    /**
     * The OCID of the VCN.
     */
    "ociVcnOcid": string;
    /**
     * MultiCloud VCN Identifier
     */
    "name": string;
    /**
     * The subnet ocid where the database must be created: - Must be part of the provided VCN - Must not overlap with secondary subnet If ociSubnetCidrBlocks is provided then this field should not be provided 
     */
    "ociSubnetOcids"?: Array<string>;
    /**
     * The list of IPv4 CIDR blocks that meet the following criteria: - The CIDR blocks must be valid. - They must not overlap with each other If ociSubnetOcids is provided then this field should not be provided 
     */
    "ociSubnetCidrBlocks"?: Array<string>;
    /**
     * The (Virtual Network) VNET Id where the Azure client will reside.
     */
    "azureAttachedNetworkIds": Array<string>;
    /**
     * Optional field to specify the IP address to which return traffic is sent from the Multicloud VCN
     */
    "customerNvaIpAddress"?: string;
}

/**
 * Information for registering existing OCI resources like VCN and subnets to create a MultiCloudVirtualNetwork. 
 */
export interface RegisterExistingOciNetworkDetails extends CreateMultiCloudVirtualNetworkDetails {
    /**
     * The [OCID](/iaas/Content/General/Concepts/identifiers.htm) of the VCN. If not provided, this will be created.
     */
    "ociVcnOcid": string;
    /**
     * The subnet ocid where the database must be created: - Must be part of the provided VCN - Must not overlap with secondary subnet If ociSubnetCidrBlocks is provided then this field should not be provided 
     */
    "ociSubnetOcids"?: Array<string>;
    /**
     * The list of IPv4 CIDR blocks that meet the following criteria: - The CIDR blocks must be valid. - They must not overlap with each other If ociSubnetOcids is provided then this field should not be provided 
     */
    "ociSubnetCidrBlocks"?: Array<string>;
}

/**
 * Details for creating a new VMDB deployment from a database backup. 
 */
export interface RestoreVmdbDbSystemDeploymentDetails extends VmdbDbSystemDeploymentDetails {
    /**
     * The name for the DbSystem. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The number of CPU cores to enable for a DbSystem. Valid values depend on the specified shape:  - VM.Standard.E4.Flex - Specify any thing from 1 to 64. 
     */
    "cpuCoreCount": number;
    /**
     * The public key portion of one or more key pairs used for SSH access to the cloud VM cluster.
     */
    "sshPublicKeys": Array<string>;
    /**
     * The hostname for the DbSystem. The hostname must begin with an alphabetic character, and can contain alphanumeric characters and hyphens (-). The maximum length of the hostname is 16 characters for virtual machine DB systems.  The maximum length of the combined hostname and domain is 63 characters.  **Note:** The hostname must be unique within the subnet. If it is not unique, the DbSystem will fail to provision. 
     */
    "hostname": string;
    /**
     * The shape of the DB system. The shape determines resources allocated to the DB system.  For virtual machine shapes, the number of CPU cores and memory 
     */
    "shape": string;
    "dbSystemOptions"?: VmdbCreateDbSystemOptions;
    /**
     * Size (in GB) of the initial data volume that will be created and attached to a virtual machine DB system.
     */
    "initialDataStorageSizeInGBs"?: number;
    /**
     * The number of nodes to launch for a 2-node RAC virtual machine DB system.
     */
    "nodeCount"?: number;
    /**
     * The cluster name for virtual machine DB systems. The cluster name must begin with an alphabetic character, and may contain hyphens (-). Underscores (_) are not permitted. The cluster name can be no longer than 11 characters and is not case sensitive.
     */
    "clusterName"?: string;
    /**
     * The percentage assigned to DATA storage (user data and database files). The remaining percentage is assigned to RECO storage (database redo logs, archive logs, and recovery manager backups). Accepted values are 40 and 80. The default is 80 percent assigned to DATA storage. Not applicable for virtual machine DB systems.
     */
    "dataStoragePercentage"?: number;
    /**
     * The domain name for the DB system.
     */
    "domain"?: string;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The time zone to use for the DB system. For details, see DB System Time Zones.
     */
    "timeZone"?: string;
    /**
     * The Oracle Database edition that applies to all the databases on the DB system.
     */
    "databaseEdition": RestoreVmdbDbSystemDeploymentDetailsDatabaseEditionEnum;
    "dbHome": CreateVmdbDbHomeFromBackupDetails;
    /**
     * The Oracle license model that applies to all the databases on the DB system. The default is LICENSE_INCLUDED.
     */
    "licenseModel"?: RestoreVmdbDbSystemDeploymentDetailsLicenseModelEnum;
}

export type RestoreVmdbDbSystemDeploymentDetailsDatabaseEditionEnum = "STANDARD_EDITION" | "ENTERPRISE_EDITION" | "ENTERPRISE_EDITION_HIGH_PERFORMANCE" | "ENTERPRISE_EDITION_EXTREME_PERFORMANCE";
export type RestoreVmdbDbSystemDeploymentDetailsLicenseModelEnum = "LICENSE_INCLUDED" | "BRING_YOUR_OWN_LICENSE";
/**
 * Details about the TechSupportItem object.
 */
export interface TechSupportItem extends Item {
    /**
     * The service category, such as a cloud link or network.
     */
    "serviceCategory"?: TechSupportItemServiceCategoryEnum;
}

export type TechSupportItemServiceCategoryEnum = "NETWORK" | "EXADATADB" | "AUTONOMOUSDB_SHARED" | "AUTONOMOUSDB_SERVERLESS" | "AUTONOMOUSDB_DEDICATED" | "VMDB" | "MDS" | "MULTICLOUDLINK";
/**
 * Details for updating the support ticket activity. 
 */
export interface UpdateActivityItemDetails extends UpdateItemDetails {
    /**
     * Comments updated at the time that the activity occurs.
     */
    "comments"?: string;
    /**
     * The type of activity occurring on the support ticket.
     */
    "activityType"?: UpdateActivityItemDetailsActivityTypeEnum;
}

export type UpdateActivityItemDetailsActivityTypeEnum = "NOTES" | "PROBLEM_DESCRIPTION" | "UPDATE" | "CLOSE" | "REOPEN";
/**
 * VMDB Azure Database
 */
export interface VmdbDatabase extends VmdbDatabaseBase {
    "observabilityDetails"?: ObservabilityDetails;
}

/**
 * Vmdb DbHome
 */
export interface VmdbDbHome {
    /**
     * The unique of the Database Home.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the resource
     */
    "status": VmdbDbHomeStatusEnum;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The user-provided name for the Database Home. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The DB System Id of the database home.
     */
    "dbSystemId": string;
    /**
     * The Oracle Database version.
     */
    "dbVersion": string;
    /**
     * The location of the Oracle Database Home.
     */
    "dbHomeLocation"?: string;
    /**
     * The date and time the Database Home was created.
     */
    "timeCreated"?: Date;
    /**
     * The date and time the Database Home was created.
     */
    "timeUpdated"?: Date;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: VmdbDbHomeLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type VmdbDbHomeStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating";
export type VmdbDbHomeLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * The Vmdb node
 */
export interface VmdbDbNode {
    /**
     * The fully qualified Azure resource ID of the database node.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the resource
     */
    "status": VmdbDbNodeStatusEnum;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The DB System Id of the database node.
     */
    "dbSystemId": string;
    /**
     * The user-friendly name for the database node. The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The date and time that the database node was created.
     */
    "timeCreated": Date;
    /**
     * The date and time that the database node was created.
     */
    "timeUpdated": Date;
    /**
     * The size (in GB) of the block storage volume allocation for the DB system. This attribute applies only for virtual machine DB systems. 
     */
    "softwareStorageSizeInGB"?: number;
    /**
     * The number of CPU cores enabled on the Db node.
     */
    "cpuCoreCount"?: number;
    /**
     * The allocated memory in GBs on the Db node.
     */
    "memorySizeInGBs"?: number;
    /**
     * The allocated local node storage in GBs on the Db node.
     */
    "dbNodeStorageSizeInGBs"?: number;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: VmdbDbNodeLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type VmdbDbNodeStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating" | "Stopped";
export type VmdbDbNodeLastOperationStatusEnum = "Succeeded" | "Failed";
/**
 * New Vmdb deployment parameters.
 */
export interface VmdbDeploymentParametersDetails extends DeploymentParametersDetails {
    "multiCloudVirtualNetworkDetails"?: MultiCloudVirtualNetworkDeploymentDetails;
    "networkLinkDetails"?: NetworkLinkDeploymentDetails;
    "vmdbDbSystemDeploymentDetails"?: VmdbDbSystemDeploymentDetails;
}

/**
 * A pluggable database (PDB) is portable collection of schemas, schema objects, and non-schema objects that appears to an Oracle client as a non-container database. To use a PDB, it needs to be plugged into a CDB. To use any of the API operations, you must be authorized in an IAM policy. If you are not authorized, talk to a tenancy administrator. If you are an administrator who needs to write policies to give users access, see [Getting Started with Policies](/Content/Identity/Concepts/policygetstarted.htm).  **Warning:** Oracle recommends that you avoid using any confidential information when you supply string values using the API. 
 */
export interface VmdbPluggableDatabase {
    /**
     * The fully qualified Azure resource ID of the pluggable database.
     */
    "id": string;
    /**
     * The [OCID](/Content/General/Concepts/identifiers.htm) of the resource.
     */
    "ocid"?: string;
    /**
     * Azure region name, e.g. \"East US\"
     */
    "location": string;
    /**
     * The current status of the asynchronous operation being performed.
     */
    "status": VmdbPluggableDatabaseStatusEnum;
    /**
     * Free-form tags for this resource. Each tag is a simple key-value pair with no predefined name, type, or namespace. For more information, see Resource Tags.
     */
    "freeformTags"?: { [key: string]: string; };
    /**
     * The fully qualified Azure resource ID of the CDB.
     */
    "containerDatabaseId": string;
    /**
     * The name for the pluggable database (PDB). The name needs to be unique in the resource group.
     */
    "name": string;
    /**
     * The name for the pluggable database (PDB). The name is unique in the context of a [container database](#/en/database/latest/Database/). The name must begin with an alphabetic character and can contain a maximum of thirty alphanumeric characters. Special characters are not permitted. The pluggable database name should not be same as the container database name.
     */
    "pdbName": string;
    /**
     * The date and time the pluggable database was created.
     */
    "timeCreated": Date;
    /**
     * The date and time the pluggable database was created.
     */
    "timeUpdated": Date;
    "connectionStrings"?: VmdbPluggableDatabaseConnectionStrings;
    /**
     * The mode that pluggable database is in. Open mode can only be changed to READ_ONLY or MIGRATE directly from the backend (within the Oracle Database software). 
     */
    "openMode": VmdbPluggableDatabaseOpenModeEnum;
    /**
     * The restricted mode of the pluggable database. If a pluggable database is opened in restricted mode, the user needs both create a session and have restricted session privileges to connect to it. 
     */
    "isRestricted"?: boolean;
    /**
     * The status of the last asynchronous operation.
     */
    "lastOperationStatus"?: VmdbPluggableDatabaseLastOperationStatusEnum;
    /**
     * The details of the status of the last asynchronous operation.
     */
    "lastOperationStatusDetails"?: string;
}

export type VmdbPluggableDatabaseStatusEnum = "Provisioning" | "Updating" | "Active" | "Failed" | "Terminating";
export type VmdbPluggableDatabaseOpenModeEnum = "READ_ONLY" | "READ_WRITE" | "MOUNTED" | "MIGRATE";
export type VmdbPluggableDatabaseLastOperationStatusEnum = "Succeeded" | "Failed";

export interface CreateAdbdContainerDatabaseArgs {
     "createAdbdContainerDatabaseDetails": CreateAdbdContainerDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type CreateAdbdContainerDatabaseReturnType = { response: Response, data: AdbdContainerDatabase };

export interface CreateAdbdDatabaseArgs {
     "createAdbdDatabaseDetails": CreateAdbdDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type CreateAdbdDatabaseReturnType = { response: Response, data: AdbdDatabase };

export interface CreateAdbdVmClusterArgs {
     "createAdbdVmClusterDetails": CreateAdbdVmClusterDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type CreateAdbdVmClusterReturnType = { response: Response, data: AdbdVmCluster };

export interface DeleteAdbdContainerDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteAdbdContainerDatabaseReturnType = Response;

export interface DeleteAdbdDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteAdbdDatabaseReturnType = Response;

export interface DeleteAdbdVmClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "vmClusterName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteAdbdVmClusterReturnType = Response;

export interface GenerateAdbdDatabaseWalletArgs {
     "generateAdbdDatabaseWalletDetails": GenerateAdbdDatabaseWalletDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "opcRetryToken"?: string
}
export type GenerateAdbdDatabaseWalletReturnType = Response;

export interface GetAdbdContainerDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetAdbdContainerDatabaseReturnType = { response: Response, data: AdbdContainerDatabase };

export interface GetAdbdDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetAdbdDatabaseReturnType = { response: Response, data: AdbdDatabase };

export interface GetAdbdDatabaseWalletArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetAdbdDatabaseWalletReturnType = { response: Response, data: AdbdDatabaseWallet };

export interface GetAdbdVmClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "vmClusterName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetAdbdVmClusterReturnType = { response: Response, data: AdbdVmCluster };

export interface ListAdbdContainerDatabasesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListAdbdContainerDatabasesReturnType = { response: Response, data: AdbdContainerDatabaseCollection };

export interface ListAdbdContainerDatabasesBySubscriptionArgs {
     "subscriptionId": string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListAdbdContainerDatabasesBySubscriptionReturnType = { response: Response, data: AdbdContainerDatabaseCollection };

export interface ListAdbdDatabasesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListAdbdDatabasesReturnType = { response: Response, data: AdbdDatabaseCollection };

export interface ListAdbdDatabasesBySubscriptionArgs {
     "subscriptionId": string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListAdbdDatabasesBySubscriptionReturnType = { response: Response, data: AdbdDatabaseCollection };

export interface ListAdbdDbVersionsArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string
}
export type ListAdbdDbVersionsReturnType = { response: Response, data: AdbDbVersionCollection };

export interface ListAdbdVmClustersArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListAdbdVmClustersReturnType = { response: Response, data: AdbdVmClusterCollection };

export interface ListAdbdVmClustersBySubscriptionArgs {
     "subscriptionId": string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListAdbdVmClustersBySubscriptionReturnType = { response: Response, data: AdbdVmClusterCollection };

export interface ListAutonomousExadataInfrastructureShapesArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string
}
export type ListAutonomousExadataInfrastructureShapesReturnType = { response: Response, data: AutonomousExadataInfrastructureShapeCollection };

export interface UpdateAdbdContainerDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "updateAdbdContainerDatabaseDetails": UpdateAdbdContainerDatabaseDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type UpdateAdbdContainerDatabaseReturnType = { response: Response, data: AdbdContainerDatabase };

export interface UpdateAdbdDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "updateAdbdDatabaseDetails": UpdateAdbdDatabaseDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type UpdateAdbdDatabaseReturnType = { response: Response, data: AdbdDatabase };

export interface UpdateAdbdDatabaseWalletArgs {
     "updateAdbdDatabaseWalletDetails": UpdateAdbdDatabaseWalletDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "ifMatch"?: string
}
export type UpdateAdbdDatabaseWalletReturnType = { response: Response, data: AdbdDatabaseWallet };

export interface UpdateAdbdVmClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "vmClusterName": string;
     "updateAdbdVmClusterDetails": UpdateAdbdVmClusterDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type UpdateAdbdVmClusterReturnType = { response: Response, data: AdbdVmCluster };


/**
 * AdbdAdaptorApi - object-oriented interface
 */
export class AdbdAdaptorApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): AdbdAdaptorApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new AdbdAdaptorApi(fetch, endpoint, config);
    }

    /** 
     * CreateAdbdContainerDatabase
     * Creates an autonomous container database. 
     * @param createAdbdContainerDatabaseDetails Request to create an autonomous container database. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public createAdbdContainerDatabase(params: {  "createAdbdContainerDatabaseDetails": CreateAdbdContainerDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbdContainerDatabase }> {
        base.validateRequiredParameters([
            "createAdbdContainerDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createAdbdContainerDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/containerDatabases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbdContainerDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createAdbdContainerDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "createAdbdContainerDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * CreateAdbdDatabase
     * Creates an autonomous database. 
     * @param createAdbdDatabaseDetails Request to create an autonomous database. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public createAdbdDatabase(params: {  "createAdbdDatabaseDetails": CreateAdbdDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbdDatabase }> {
        base.validateRequiredParameters([
            "createAdbdDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createAdbdDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbdDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createAdbdDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "createAdbdDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * CreateAdbdVmCluster
     * Creates an cloud autonomous VM cluster. 
     * @param createAdbdVmClusterDetails Request to create a cloud autonomous VM cluster. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public createAdbdVmCluster(params: {  "createAdbdVmClusterDetails": CreateAdbdVmClusterDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbdVmCluster }> {
        base.validateRequiredParameters([
            "createAdbdVmClusterDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createAdbdVmCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/vmClusters`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbdVmCluster>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createAdbdVmClusterDetails"]
                    },

            parseResponseBody: true,

            operationName: "createAdbdVmCluster",
            httpMethod: "POST"
        });
    }
    /** 
     * Deletes the specified container database.
     * Deletes the specified container database. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the AutonomousContainerDatabase resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteAdbdContainerDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "deleteAdbdContainerDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/containerDatabases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteAdbdContainerDatabase",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Deletes the specified database.
     * Deletes the specified database. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the AutonomousDatabase resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteAdbdDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "deleteAdbdDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteAdbdDatabase",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Deletes the specified cloud VM cluster.
     * Deletes the specified cloud VM cluster. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param vmClusterName The name of the VmCluster resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteAdbdVmCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "vmClusterName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "vmClusterName",
        ], "deleteAdbdVmCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/vmClusters/{vmClusterName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"vmClusterName"}}`, `${ params["vmClusterName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteAdbdVmCluster",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Creates and downloads a wallet for the specified Autonomous Database.
     * Creates and downloads a wallet for the specified Autonomous Database. 
     * @param generateAdbdDatabaseWalletDetails Request to create a new Autonomous Database wallet.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the AutonomousDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public generateAdbdDatabaseWallet(params: {  "generateAdbdDatabaseWalletDetails": GenerateAdbdDatabaseWalletDetails; "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; "opcRetryToken"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "generateAdbdDatabaseWalletDetails",
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "generateAdbdDatabaseWallet", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/databases/{databaseName}/generateWallet`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["generateAdbdDatabaseWalletDetails"]
                    },


            operationName: "generateAdbdDatabaseWallet",
            httpMethod: "POST"
        });
    }
    /** 
     * GetAdbdContainerDatabase
     * Gets information about the specified container database. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the AutonomousContainerDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getAdbdContainerDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbdContainerDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "getAdbdContainerDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/containerDatabases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbdContainerDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getAdbdContainerDatabase",
            httpMethod: "GET"
        });
    }
    /** 
     * GetAdbdDatabase
     * Gets information about the specified database. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the AutonomousDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getAdbdDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbdDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "getAdbdDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbdDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getAdbdDatabase",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets the wallet details for the specified Autonomous Database.
     * Returns the wallet details for the specified Autonomous Database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the AutonomousDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     */
    public getAdbdDatabaseWallet(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbdDatabaseWallet }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "getAdbdDatabaseWallet", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/databases/{databaseName}/wallet`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbdDatabaseWallet>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getAdbdDatabaseWallet",
            httpMethod: "GET"
        });
    }
    /** 
     * GetAdbdVmCluster
     * Gets information about the specified cloud VM cluster. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param vmClusterName The name of the VmCluster resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getAdbdVmCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "vmClusterName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbdVmCluster }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "vmClusterName",
        ], "getAdbdVmCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/vmClusters/{vmClusterName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"vmClusterName"}}`, `${ params["vmClusterName"] }`);

        return this.request<AdbdVmCluster>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getAdbdVmCluster",
            httpMethod: "GET"
        });
    }
    /** 
     * ListAdbdContainerDatabases
     * Gets a list of the autonomous container databases in the specified resource group. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listAdbdContainerDatabases(params: {  "subscriptionId": string; "resourceGroupName": string; "apiVersion"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: AdbdContainerDatabaseCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listAdbdContainerDatabases", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/containerDatabases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbdContainerDatabaseCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbdContainerDatabases",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of the container databases in the specified subscription.
     * Gets a list of the container databases in the specified subscription. 
     * @param subscriptionId subscription identifier
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listAdbdContainerDatabasesBySubscription(params: {  "subscriptionId": string; "apiVersion"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: AdbdContainerDatabaseCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAdbdContainerDatabasesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Adbd/containerDatabases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AdbdContainerDatabaseCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbdContainerDatabasesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListAdbdDatabases
     * Gets a list of the autonomous databases in the specified resource group. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listAdbdDatabases(params: {  "subscriptionId": string; "resourceGroupName": string; "apiVersion"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: AdbdDatabaseCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listAdbdDatabases", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbdDatabaseCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbdDatabases",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of databases in the specified subscription.
     * Gets a list of databases in the specified subscription. 
     * @param subscriptionId subscription identifier
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listAdbdDatabasesBySubscription(params: {  "subscriptionId": string; "apiVersion"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: AdbdDatabaseCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAdbdDatabasesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Adbd/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AdbdDatabaseCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbdDatabasesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of Autonomous Database versions that are supported.
     * Returns a list of Autonomous Database versions that are supported.
     * @param subscriptionId subscription identifier
     * @param opcRequestId The client request ID for tracing.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listAdbdDbVersions(params: {  "subscriptionId": string; "opcRequestId"?: string; "limit"?: number; "page"?: string; }, options?: any): Promise<{ response: Response, data: AdbDbVersionCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAdbdDbVersions", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Adbd/dbVersions`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AdbDbVersionCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbdDbVersions",
            httpMethod: "GET"
        });
    }
    /** 
     * ListAdbdVmClusters
     * Gets a list of the cloud autonomous VM clusters in the specified resource group. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listAdbdVmClusters(params: {  "subscriptionId": string; "resourceGroupName": string; "apiVersion"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: AdbdVmClusterCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listAdbdVmClusters", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/vmClusters`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbdVmClusterCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbdVmClusters",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of the cloud VM clusters in the specified subscription.
     * Gets a list of the cloud VM clusters in the specified subscription. 
     * @param subscriptionId subscription identifier
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listAdbdVmClustersBySubscription(params: {  "subscriptionId": string; "apiVersion"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: AdbdVmClusterCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAdbdVmClustersBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Adbd/vmClusters`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AdbdVmClusterCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbdVmClustersBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of Autonomous Exadata Shapes that are supported.
     * Returns a list of Autonomous Exadata Shapes that are supported.
     * @param subscriptionId subscription identifier
     * @param opcRequestId The client request ID for tracing.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listAutonomousExadataInfrastructureShapes(params: {  "subscriptionId": string; "opcRequestId"?: string; "limit"?: number; "page"?: string; }, options?: any): Promise<{ response: Response, data: AutonomousExadataInfrastructureShapeCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAutonomousExadataInfrastructureShapes", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Adbd/exadataInfrastructureShapes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AutonomousExadataInfrastructureShapeCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAutonomousExadataInfrastructureShapes",
            httpMethod: "GET"
        });
    }
    /** 
     * Updates the specified container database.
     * Updates the specified container database. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the AutonomousContainerDatabase resource. The name needs to be unique in the resource group.
     * @param updateAdbdContainerDatabaseDetails Request to update the attributes of a container database.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public updateAdbdContainerDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "updateAdbdContainerDatabaseDetails": UpdateAdbdContainerDatabaseDetails; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbdContainerDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
            "updateAdbdContainerDatabaseDetails",
        ], "updateAdbdContainerDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/containerDatabases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbdContainerDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateAdbdContainerDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateAdbdContainerDatabase",
            httpMethod: "PUT"
        });
    }
    /** 
     * Updates the specified database.
     * Updates the specified database. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the AutonomousDatabase resource. The name needs to be unique in the resource group.
     * @param updateAdbdDatabaseDetails Request to update the attributes of a database.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public updateAdbdDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "updateAdbdDatabaseDetails": UpdateAdbdDatabaseDetails; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbdDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
            "updateAdbdDatabaseDetails",
        ], "updateAdbdDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbdDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateAdbdDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateAdbdDatabase",
            httpMethod: "PUT"
        });
    }
    /** 
     * Update the wallet details for the specified Autonomous Database.
     * Returns the wallet details for the specified Autonomous Database.
     * @param updateAdbdDatabaseWalletDetails Request to update wallet configuration
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the AutonomousDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     */
    public updateAdbdDatabaseWallet(params: {  "updateAdbdDatabaseWalletDetails": UpdateAdbdDatabaseWalletDetails; "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; "ifMatch"?: string; }, options?: any): Promise<{ response: Response, data: AdbdDatabaseWallet }> {
        base.validateRequiredParameters([
            "updateAdbdDatabaseWalletDetails",
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "updateAdbdDatabaseWallet", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/databases/{databaseName}/wallet`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbdDatabaseWallet>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"],
                "if-match":  params["ifMatch"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateAdbdDatabaseWalletDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateAdbdDatabaseWallet",
            httpMethod: "PUT"
        });
    }
    /** 
     * Updates the specified cloud VM cluster.
     * Updates the specified cloud VM cluster. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param vmClusterName The name of the VmCluster resource. The name needs to be unique in the resource group.
     * @param updateAdbdVmClusterDetails Request to update the attributes of a cloud VM cluster.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public updateAdbdVmCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "vmClusterName": string; "updateAdbdVmClusterDetails": UpdateAdbdVmClusterDetails; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbdVmCluster }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "vmClusterName",
            "updateAdbdVmClusterDetails",
        ], "updateAdbdVmCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbd/vmClusters/{vmClusterName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"vmClusterName"}}`, `${ params["vmClusterName"] }`);

        return this.request<AdbdVmCluster>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateAdbdVmClusterDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateAdbdVmCluster",
            httpMethod: "PUT"
        });
    }
};

export interface CreateAdbsDatabaseArgs {
     "createAdbsDatabaseDetails": CreateAdbsDatabaseBaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "apiVersion"?: string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type CreateAdbsDatabaseReturnType = { response: Response, data: AdbsDatabase };

export interface DeleteAdbsDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "ifMatch"?: string;
     "apiVersion"?: string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type DeleteAdbsDatabaseReturnType = Response;

export interface GenerateAdbsDatabaseWalletArgs {
     "generateAdbsDatabaseWalletDetails": GenerateAdbsDatabaseWalletDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "opcRetryToken"?: string
}
export type GenerateAdbsDatabaseWalletReturnType = Response;

export interface GetAdbsDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetAdbsDatabaseReturnType = { response: Response, data: AdbsDatabase };

export interface GetAdbsDatabaseBackupArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "backupName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetAdbsDatabaseBackupReturnType = { response: Response, data: AdbsDatabaseBackup };

export interface GetAdbsDatabaseWalletArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetAdbsDatabaseWalletReturnType = { response: Response, data: AdbsDatabaseWallet };

export interface ListAdbsDatabaseBackupsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string
}
export type ListAdbsDatabaseBackupsReturnType = { response: Response, data: AdbsDatabaseBackupCollection };

export interface ListAdbsDatabaseBackupsByDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string
}
export type ListAdbsDatabaseBackupsByDatabaseReturnType = { response: Response, data: AdbsDatabaseBackupCollection };

export interface ListAdbsDatabaseBackupsBySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string
}
export type ListAdbsDatabaseBackupsBySubscriptionReturnType = { response: Response, data: AdbsDatabaseBackupCollection };

export interface ListAdbsDatabasesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "apiVersion"?: string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string
}
export type ListAdbsDatabasesReturnType = { response: Response, data: AdbsDatabaseCollection };

export interface ListAdbsDatabasesBySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string
}
export type ListAdbsDatabasesBySubscriptionReturnType = { response: Response, data: AdbsDatabaseCollection };

export interface ListAdbsDbCharacterSetsArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "characterSetType"?: string
}
export type ListAdbsDbCharacterSetsReturnType = { response: Response, data: AdbsCharacterSetCollection };

export interface ListAdbsDbVersionsArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string
}
export type ListAdbsDbVersionsReturnType = { response: Response, data: AdbDbVersionCollection };

export interface RestartAdbsDatabaseArgs {
     "databaseName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type RestartAdbsDatabaseReturnType = { response: Response, data: AdbsDatabase };

export interface RestoreAdbsDatabaseArgs {
     "restoreAdbsDatabaseDetails": RestoreAdbsDatabaseDetails;
     "databaseName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type RestoreAdbsDatabaseReturnType = { response: Response, data: AdbsDatabase };

export interface StartAdbsDatabaseArgs {
     "databaseName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type StartAdbsDatabaseReturnType = { response: Response, data: AdbsDatabase };

export interface StopAdbsDatabaseArgs {
     "databaseName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type StopAdbsDatabaseReturnType = { response: Response, data: AdbsDatabase };

export interface UpdateAdbsDatabaseArgs {
     "updateAdbsDatabaseDetails": UpdateAdbsDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "ifMatch"?: string
}
export type UpdateAdbsDatabaseReturnType = { response: Response, data: AdbsDatabase };

export interface UpdateAdbsDatabaseWalletArgs {
     "updateAdbsDatabaseWalletDetails": UpdateAdbsDatabaseWalletDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "ifMatch"?: string
}
export type UpdateAdbsDatabaseWalletReturnType = { response: Response, data: AdbsDatabaseWallet };


/**
 * AdbsAdaptorApi - object-oriented interface
 */
export class AdbsAdaptorApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): AdbsAdaptorApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new AdbsAdaptorApi(fetch, endpoint, config);
    }

    /** 
     * Creates a new ADB-S Database
     * Creates a new ADB-S Database
     * @param createAdbsDatabaseDetails Request to create a new ADB-s Database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param apiVersion The API version to use for this operation.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public createAdbsDatabase(params: {  "createAdbsDatabaseDetails": CreateAdbsDatabaseBaseDetails; "subscriptionId": string; "resourceGroupName": string; "apiVersion"?: string; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabase }> {
        base.validateRequiredParameters([
            "createAdbsDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createAdbsDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbsDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createAdbsDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "createAdbsDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * Deletes the specified ADB-S database
     * Deletes the specified ADB-S database 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param apiVersion The API version to use for this operation.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public deleteAdbsDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "ifMatch"?: string; "apiVersion"?: string; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "deleteAdbsDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteAdbsDatabase",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Creates and downloads a wallet for the specified Autonomous Database.
     * Creates and downloads a wallet for the specified Autonomous Database. 
     * @param generateAdbsDatabaseWalletDetails Request to create a new Autonomous Database wallet.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public generateAdbsDatabaseWallet(params: {  "generateAdbsDatabaseWalletDetails": GenerateAdbsDatabaseWalletDetails; "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; "opcRetryToken"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "generateAdbsDatabaseWalletDetails",
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "generateAdbsDatabaseWallet", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}/generateWallet`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["generateAdbsDatabaseWalletDetails"]
                    },


            operationName: "generateAdbsDatabaseWallet",
            httpMethod: "POST"
        });
    }
    /** 
     * Gets an ADB-S Database based on databaseName.
     * Returns a ADB-S Database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     */
    public getAdbsDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "getAdbsDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbsDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getAdbsDatabase",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets information about the specified Autonomous Database backup.
     * Returns information about the specified Autonomous Database backup.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param backupName The name of an AdbsDatabaseBackup backup resource. The name needs to be unique in the resource group.
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     */
    public getAdbsDatabaseBackup(params: {  "subscriptionId": string; "resourceGroupName": string; "backupName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabaseBackup }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "backupName",
        ], "getAdbsDatabaseBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/backups/{backupName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"backupName"}}`, `${ params["backupName"] }`);

        return this.request<AdbsDatabaseBackup>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getAdbsDatabaseBackup",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets the wallet details for the specified Autonomous Database.
     * Returns the wallet details for the specified Autonomous Database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     */
    public getAdbsDatabaseWallet(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabaseWallet }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "getAdbsDatabaseWallet", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}/wallet`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbsDatabaseWallet>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getAdbsDatabaseWallet",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of Autonomous Database backups based on the subscription and resource group or the database resource id specified as a query parameter.
     * Returns a list of Autonomous Database backups based on the subscription and resource group or the database resource id specified as a query parameter.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     */
    public listAdbsDatabaseBackups(params: {  "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabaseBackupCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listAdbsDatabaseBackups", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbsDatabaseBackupCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbsDatabaseBackups",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of Autonomous Database backups for the specified database.
     * Returns a list of Autonomous Database backups based for the database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     */
    public listAdbsDatabaseBackupsByDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabaseBackupCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "listAdbsDatabaseBackupsByDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbsDatabaseBackupCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbsDatabaseBackupsByDatabase",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of Autonomous Database backups based on the subscription or the database resource id specified as a query parameter.
     * Returns a list of Autonomous Database backups based on the subscription and resource group or the database resource id specified as a query parameter.
     * @param subscriptionId subscription identifier
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     */
    public listAdbsDatabaseBackupsBySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabaseBackupCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAdbsDatabaseBackupsBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Adbs/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AdbsDatabaseBackupCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbsDatabaseBackupsBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all ADB-S Databases in the specified resource group.
     * Returns a list of ADB-S Databases in the specified resource group.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param apiVersion The API version to use for this operation.
     * @param opcRequestId The client request ID for tracing.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     */
    public listAdbsDatabases(params: {  "subscriptionId": string; "resourceGroupName": string; "apiVersion"?: string; "opcRequestId"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabaseCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listAdbsDatabases", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbsDatabaseCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbsDatabases",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all ADB-S Databases in the specified subscription.
     * Returns a list of ADB-S Databases in the specified subscription.
     * @param subscriptionId subscription identifier
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     */
    public listAdbsDatabasesBySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabaseCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAdbsDatabasesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Adbs/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AdbsDatabaseCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbsDatabasesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of Autonomous Database character sets that are supported.
     * Returns a list of Autonomous Database character sets that are supported.
     * @param subscriptionId subscription identifier
     * @param opcRequestId The client request ID for tracing.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param characterSetType The Character Set Type used when listing character sets. Can be either DATABASE or NATIONAL.
     */
    public listAdbsDbCharacterSets(params: {  "subscriptionId": string; "opcRequestId"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "characterSetType"?: string; }, options?: any): Promise<{ response: Response, data: AdbsCharacterSetCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAdbsDbCharacterSets", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Adbs/characterSets`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AdbsCharacterSetCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "characterSetType": { values: params["characterSetType"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbsDbCharacterSets",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of Autonomous Database versions that are supported.
     * Returns a list of Autonomous Database versions that are supported.
     * @param subscriptionId subscription identifier
     * @param opcRequestId The client request ID for tracing.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     */
    public listAdbsDbVersions(params: {  "subscriptionId": string; "opcRequestId"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; }, options?: any): Promise<{ response: Response, data: AdbDbVersionCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAdbsDbVersions", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Adbs/dbVersions`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AdbDbVersionCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAdbsDbVersions",
            httpMethod: "GET"
        });
    }
    /** 
     * Restarts the specified Autonomous Database.
     * Restarts the specified Autonomous Database. 
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public restartAdbsDatabase(params: {  "databaseName": string; "subscriptionId": string; "resourceGroupName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabase }> {
        base.validateRequiredParameters([
            "databaseName",
            "subscriptionId",
            "resourceGroupName",
        ], "restartAdbsDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}/restart`
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbsDatabase>({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },


            parseResponseBody: true,

            operationName: "restartAdbsDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * Restores an Autonomous Database based on the provided request parameters.
     * Restores an Autonomous Database based on the provided request parameters. 
     * @param restoreAdbsDatabaseDetails Request to perform an Autonomous Database restore.
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public restoreAdbsDatabase(params: {  "restoreAdbsDatabaseDetails": RestoreAdbsDatabaseDetails; "databaseName": string; "subscriptionId": string; "resourceGroupName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabase }> {
        base.validateRequiredParameters([
            "restoreAdbsDatabaseDetails",
            "databaseName",
            "subscriptionId",
            "resourceGroupName",
        ], "restoreAdbsDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}/restore`
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbsDatabase>({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["restoreAdbsDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "restoreAdbsDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * Starts the specified Autonomous Database.
     * Starts the specified Autonomous Database. 
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public startAdbsDatabase(params: {  "databaseName": string; "subscriptionId": string; "resourceGroupName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabase }> {
        base.validateRequiredParameters([
            "databaseName",
            "subscriptionId",
            "resourceGroupName",
        ], "startAdbsDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}/start`
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbsDatabase>({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },


            parseResponseBody: true,

            operationName: "startAdbsDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * Stops the specified Autonomous Database.
     * Stops the specified Autonomous Database. 
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public stopAdbsDatabase(params: {  "databaseName": string; "subscriptionId": string; "resourceGroupName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabase }> {
        base.validateRequiredParameters([
            "databaseName",
            "subscriptionId",
            "resourceGroupName",
        ], "stopAdbsDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}/stop`
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AdbsDatabase>({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },


            parseResponseBody: true,

            operationName: "stopAdbsDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * Update ADBS details like ocpu count , database name etc.
     * Returns a ADB-S Database Details.
     * @param updateAdbsDatabaseDetails Request to Update Existing ADB-s Database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId The client request ID for tracing.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     */
    public updateAdbsDatabase(params: {  "updateAdbsDatabaseDetails": UpdateAdbsDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "ifMatch"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabase }> {
        base.validateRequiredParameters([
            "updateAdbsDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "updateAdbsDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbsDatabase>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"],
                "if-match":  params["ifMatch"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateAdbsDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateAdbsDatabase",
            httpMethod: "PUT"
        });
    }
    /** 
     * Update the wallet details for the specified Autonomous Database.
     * Returns the wallet details for the specified Autonomous Database.
     * @param updateAdbsDatabaseWalletDetails Request to update wallet configuration
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the adbsDatabase resource. The name needs to be unique in the resource group.
     * @param opcRequestId The client request ID for tracing.
     * @param apiVersion The API version to use for this operation.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     */
    public updateAdbsDatabaseWallet(params: {  "updateAdbsDatabaseWalletDetails": UpdateAdbsDatabaseWalletDetails; "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; "ifMatch"?: string; }, options?: any): Promise<{ response: Response, data: AdbsDatabaseWallet }> {
        base.validateRequiredParameters([
            "updateAdbsDatabaseWalletDetails",
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "updateAdbsDatabaseWallet", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Adbs/databases/{databaseName}/wallet`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<AdbsDatabaseWallet>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"],
                "if-match":  params["ifMatch"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateAdbsDatabaseWalletDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateAdbsDatabaseWallet",
            httpMethod: "PUT"
        });
    }
};

export interface SignResourceContextArgs {
     "token"?: string
}
export type SignResourceContextReturnType = { response: Response, data: string };

export interface SignTokenArgs {
     "token"?: string
}
export type SignTokenReturnType = { response: Response, data: string };

export interface ValidateTokenEmailArgs {
     "token": Token;
     "opcRequestId"?: string
}
export type ValidateTokenEmailReturnType = { response: Response, data: string };

export interface ValidateTokenToBindArgs {
     "token": Token;
     "opcRequestId"?: string
}
export type ValidateTokenToBindReturnType = { response: Response, data: TokenDetails };


/**
 * AuthorityApi - object-oriented interface
 */
export class AuthorityApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): AuthorityApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new AuthorityApi(fetch, endpoint, config);
    }

    /** 
     * Sign resource context.
     * Sign resource context. 
     * @param token JSON token to sign
     */
    public signResourceContext(params: {  "token"?: string; }, options?: any): Promise<{ response: Response, data: string }> {
        base.validateRequiredParameters([
        ], "signResourceContext", params);

        const path = `${this.basePath}/authority/resourceContextSigner`;

        return this.request<string>({
            options,
            path,



                    body: !params["token"] ? undefined : 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["token"]
                    },

            parseResponseBody: true,

            operationName: "signResourceContext",
            httpMethod: "POST"
        });
    }
    /** 
     * Validates the SPLAT supplied customer creds.
     * Validates the SPLAT supplied customer creds. 
     * @param token JSON token to sign
     */
    public signToken(params: {  "token"?: string; }, options?: any): Promise<{ response: Response, data: string }> {
        base.validateRequiredParameters([
        ], "signToken", params);

        const path = `${this.basePath}/authority/tokenSigner`;

        return this.request<string>({
            options,
            path,



                    body: !params["token"] ? undefined : 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["token"]
                    },

            parseResponseBody: true,

            operationName: "signToken",
            httpMethod: "POST"
        });
    }
    /** 
     * Validates token&#39;s email is not empty and verified.
     * Validates token&#39;s email is not empty and verified. 
     * @param token Details for the Validation function
     * @param opcRequestId The client request ID for tracing.
     */
    public validateTokenEmail(params: {  "token": Token; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: string }> {
        base.validateRequiredParameters([
            "token",
        ], "validateTokenEmail", params);

        const path = `${this.basePath}/authority/token/validate/email`;

        return this.request<string>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["token"]
                    },

            parseResponseBody: true,

            operationName: "validateTokenEmail",
            httpMethod: "POST"
        });
    }
    /** 
     * Validates token&#39;s AuthN and AuthZ to Bind.
     * Validates token&#39;s AuthN and AuthZ to Bind. 
     * @param token Details for the Validation function
     * @param opcRequestId The client request ID for tracing.
     */
    public validateTokenToBind(params: {  "token": Token; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: TokenDetails }> {
        base.validateRequiredParameters([
            "token",
        ], "validateTokenToBind", params);

        const path = `${this.basePath}/authority/token/validate/bind`;

        return this.request<TokenDetails>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["token"]
                    },

            parseResponseBody: true,

            operationName: "validateTokenToBind",
            httpMethod: "POST"
        });
    }
};

export interface ListAzureResourceGroupsArgs {
     "subscriptionId": string;
     "page"?: string
}
export type ListAzureResourceGroupsReturnType = { response: Response, data: AzureResourceGroupCollection };

export interface ListAzureSubnetsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "virtualNetworkName": string;
     "page"?: string
}
export type ListAzureSubnetsReturnType = { response: Response, data: AzureSubnetCollection };

export interface ListAzureSubscriptionsArgs {
     "page"?: string
}
export type ListAzureSubscriptionsReturnType = { response: Response, data: AzureSubscriptionCollection };

export interface ListAzureVirtualNetworksArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "page"?: string;
     "location"?: string
}
export type ListAzureVirtualNetworksReturnType = { response: Response, data: AzureVirtualNetworkCollection };

export interface ListAzureVirtualNetworksBySubscriptionOnlyArgs {
     "subscriptionId": string;
     "page"?: string;
     "location"?: string
}
export type ListAzureVirtualNetworksBySubscriptionOnlyReturnType = { response: Response, data: AzureVirtualNetworkCollection };


/**
 * AzureResourceApi - object-oriented interface
 */
export class AzureResourceApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): AzureResourceApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new AzureResourceApi(fetch, endpoint, config);
    }

    /** 
     * ListAzureResourceGroups
     * Gets a list of the Azure resource groups for the specified subscription. 
     * @param subscriptionId Azure subscription ID.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listAzureResourceGroups(params: {  "subscriptionId": string; "page"?: string; }, options?: any): Promise<{ response: Response, data: AzureResourceGroupCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAzureResourceGroups", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AzureResourceGroupCollection>({
            options,
            path,

            queryParameters: {
                    "page": { values: params["page"] }
            },



            parseResponseBody: true,

            operationName: "listAzureResourceGroups",
            httpMethod: "GET"
        });
    }
    /** 
     * ListAzureSubnets
     * Gets a list of all subnet in virtual network 
     * @param subscriptionId Azure subscription ID.
     * @param resourceGroupName Azure resource group name.
     * @param virtualNetworkName The name of the azure virtual network.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listAzureSubnets(params: {  "subscriptionId": string; "resourceGroupName": string; "virtualNetworkName": string; "page"?: string; }, options?: any): Promise<{ response: Response, data: AzureSubnetCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "virtualNetworkName",
        ], "listAzureSubnets", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.Network/virtualNetworks/{virtualNetworkName}/subnets`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"virtualNetworkName"}}`, `${ params["virtualNetworkName"] }`);

        return this.request<AzureSubnetCollection>({
            options,
            path,

            queryParameters: {
                    "page": { values: params["page"] }
            },



            parseResponseBody: true,

            operationName: "listAzureSubnets",
            httpMethod: "GET"
        });
    }
    /** 
     * ListAzureSubscriptions
     * Gets a list of Azure subscriptions. 
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listAzureSubscriptions(params: {  "page"?: string; }, options?: any): Promise<{ response: Response, data: AzureSubscriptionCollection }> {
        base.validateRequiredParameters([
        ], "listAzureSubscriptions", params);

        const path = `${this.basePath}/subscriptions`;

        return this.request<AzureSubscriptionCollection>({
            options,
            path,

            queryParameters: {
                    "page": { values: params["page"] }
            },



            parseResponseBody: true,

            operationName: "listAzureSubscriptions",
            httpMethod: "GET"
        });
    }
    /** 
     * ListAzureVirtualNetworks
     * Gets a list of virtual networks for the specified subscription and resource group. 
     * @param subscriptionId Azure subscription ID.
     * @param resourceGroupName Azure resource group name.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param location Customer location (region).
     */
    public listAzureVirtualNetworks(params: {  "subscriptionId": string; "resourceGroupName": string; "page"?: string; "location"?: string; }, options?: any): Promise<{ response: Response, data: AzureVirtualNetworkCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listAzureVirtualNetworks", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.Network/virtualNetworks`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<AzureVirtualNetworkCollection>({
            options,
            path,

            queryParameters: {
                    "page": { values: params["page"] },
                    "location": { values: params["location"] }
            },



            parseResponseBody: true,

            operationName: "listAzureVirtualNetworks",
            httpMethod: "GET"
        });
    }
    /** 
     * ListAzureVirtualNetworksBySubscriptionOnly
     * Gets a list of virtual networks for the specified subscription and resource group. 
     * @param subscriptionId Azure subscription ID.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param location Customer location (region).
     */
    public listAzureVirtualNetworksBySubscriptionOnly(params: {  "subscriptionId": string; "page"?: string; "location"?: string; }, options?: any): Promise<{ response: Response, data: AzureVirtualNetworkCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listAzureVirtualNetworksBySubscriptionOnly", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Microsoft.Network/virtualNetworks`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<AzureVirtualNetworkCollection>({
            options,
            path,

            queryParameters: {
                    "page": { values: params["page"] },
                    "location": { values: params["location"] }
            },



            parseResponseBody: true,

            operationName: "listAzureVirtualNetworksBySubscriptionOnly",
            httpMethod: "GET"
        });
    }
};

export interface GetTenancySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string
}
export type GetTenancySubscriptionReturnType = { response: Response, data: TenancySubscription };


/**
 * BillingApi - object-oriented interface
 */
export class BillingApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): BillingApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new BillingApi(fetch, endpoint, config);
    }

    /** 
     * Get the tenancy subscription detail for the tenancy. 
     * Retrieves the tenancy subscription of the tennacy. 
     * @param subscriptionId subscription identifier
     * @param opcRequestId The client request ID for tracing.
     */
    public getTenancySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: TenancySubscription }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "getTenancySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Billing/tenancySubscription`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<TenancySubscription>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getTenancySubscription",
            httpMethod: "GET"
        });
    }
};

export interface GetAzureCloudLinkArgs {
     "cloudLinkId": string;
     "opcRequestId"?: string
}
export type GetAzureCloudLinkReturnType = { response: Response, data: AzureCloudLink };

export interface ListAzureCloudLinksArgs {
     "cloudLinkId"?: string;
     "name"?: string;
     "lifecycleState"?: string;
     "sortBy"?: string;
     "limit"?: number;
     "page"?: string;
     "sortOrder"?: string;
     "opcRequestId"?: string
}
export type ListAzureCloudLinksReturnType = { response: Response, data: AzureCloudLinkCollection };

export interface UpdateAzureCloudLinkArgs {
     "cloudLinkId": string;
     "updateAzureCloudLinkDetails": UpdateAzureCloudLinkDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type UpdateAzureCloudLinkReturnType = { response: Response, data: AzureCloudLink };


/**
 * CloudLinkApi - object-oriented interface
 */
export class CloudLinkApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): CloudLinkApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new CloudLinkApi(fetch, endpoint, config);
    }

    /** 
     * Get AzureCloudLink
     * Gets a AzureCloudLink by identifier
     * @param cloudLinkId unique CloudLink identifier
     * @param opcRequestId The client request ID for tracing.
     */
    public getAzureCloudLink(params: {  "cloudLinkId": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: AzureCloudLink }> {
        base.validateRequiredParameters([
            "cloudLinkId",
        ], "getAzureCloudLink", params);

        const path = `${this.basePath}/cloudLinks/{cloudLinkId}`
            .replace(`{${"cloudLinkId"}}`, `${ params["cloudLinkId"] }`);

        return this.request<AzureCloudLink>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getAzureCloudLink",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all AzureCloudLinks.
     * Returns a list of AzureCloudLinks.
     * @param cloudLinkId unique CloudLink identifier
     * @param name The name of the Azure resource to list.
     * @param lifecycleState A filter to return only resources their lifecycleState matches the given lifecycleState.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for name is ascending. 
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param opcRequestId The client request ID for tracing.
     */
    public listAzureCloudLinks(params: {  "cloudLinkId"?: string; "name"?: string; "lifecycleState"?: string; "sortBy"?: string; "limit"?: number; "page"?: string; "sortOrder"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: AzureCloudLinkCollection }> {
        base.validateRequiredParameters([
        ], "listAzureCloudLinks", params);

        const path = `${this.basePath}/cloudLinks`;

        return this.request<AzureCloudLinkCollection>({
            options,
            path,

            queryParameters: {
                    "cloudLinkId": { values: params["cloudLinkId"] },
                    "name": { values: params["name"] },
                    "lifecycleState": { values: params["lifecycleState"] },
                    "sortBy": { values: params["sortBy"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortOrder": { values: params["sortOrder"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listAzureCloudLinks",
            httpMethod: "GET"
        });
    }
    /** 
     * Update the AzureCloudLink identified by the name.
     * Updates the AzureCloudLink.
     * @param cloudLinkId unique CloudLink identifier
     * @param updateAzureCloudLinkDetails The information to be updated.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId The client request ID for tracing.
     */
    public updateAzureCloudLink(params: {  "cloudLinkId": string; "updateAzureCloudLinkDetails": UpdateAzureCloudLinkDetails; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: AzureCloudLink }> {
        base.validateRequiredParameters([
            "cloudLinkId",
            "updateAzureCloudLinkDetails",
        ], "updateAzureCloudLink", params);

        const path = `${this.basePath}/cloudLinks/{cloudLinkId}`
            .replace(`{${"cloudLinkId"}}`, `${ params["cloudLinkId"] }`);

        return this.request<AzureCloudLink>({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateAzureCloudLinkDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateAzureCloudLink",
            httpMethod: "PUT"
        });
    }
};

export interface CreateDeploymentArgs {
     "createDeploymentDetails": CreateDeploymentDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "mcpDryRun"?: string;
     "opcDryRun"?: string
}
export type CreateDeploymentReturnType = { response: Response, data: Deployment };

export interface DeleteDeploymentArgs {
     "deploymentName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteDeploymentReturnType = Response;

export interface GetDeploymentArgs {
     "deploymentName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetDeploymentReturnType = { response: Response, data: Deployment };

export interface ListDeploymentsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListDeploymentsReturnType = { response: Response, data: DeploymentCollection };

export interface ListDeploymentsInSubscriptionArgs {
     "subscriptionId": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListDeploymentsInSubscriptionReturnType = { response: Response, data: DeploymentCollection };


/**
 * DeploymentApi - object-oriented interface
 */
export class DeploymentApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): DeploymentApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new DeploymentApi(fetch, endpoint, config);
    }

    /** 
     * Creates a new Deployment
     * Creates a new Deployment. 
     * @param createDeploymentDetails Details for the new Deployment.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param mcpDryRun DryRun Header for Creation Pre-Flight. Set to &#39;All&#39; for creation pre-flight.
     * @param opcDryRun UI supports opc-dry-run header.
     */
    public createDeployment(params: {  "createDeploymentDetails": CreateDeploymentDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; "mcpDryRun"?: string; "opcDryRun"?: string; }, options?: any): Promise<{ response: Response, data: Deployment }> {
        base.validateRequiredParameters([
            "createDeploymentDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createDeployment", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Deployment/deployments`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<Deployment>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"],
                "mcp-dry-run":  params["mcpDryRun"],
                "opc-dry-run":  params["opcDryRun"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createDeploymentDetails"]
                    },

            parseResponseBody: true,

            operationName: "createDeployment",
            httpMethod: "POST"
        });
    }
    /** 
     * Delete a Deployment
     * Deletes a Deployment resource by identifier without deleting child resources.
     * @param deploymentName unique Deployment name
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteDeployment(params: {  "deploymentName": string; "subscriptionId": string; "resourceGroupName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "deploymentName",
            "subscriptionId",
            "resourceGroupName",
        ], "deleteDeployment", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Deployment/deployments/{deploymentName}`
            .replace(`{${"deploymentName"}}`, `${ params["deploymentName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteDeployment",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Get Deployment
     * Gets a Deployment by name
     * @param deploymentName unique Deployment name
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getDeployment(params: {  "deploymentName": string; "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: Deployment }> {
        base.validateRequiredParameters([
            "deploymentName",
            "subscriptionId",
            "resourceGroupName",
        ], "getDeployment", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Deployment/deployments/{deploymentName}`
            .replace(`{${"deploymentName"}}`, `${ params["deploymentName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<Deployment>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getDeployment",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all Deployments in a resourceGroup.
     * Returns a list of Deployments. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listDeployments(params: {  "subscriptionId": string; "resourceGroupName": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DeploymentCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listDeployments", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Deployment/deployments`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<DeploymentCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDeployments",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all Deployments in a subscription
     * Returns a list of Deployments. 
     * @param subscriptionId subscription identifier
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listDeploymentsInSubscription(params: {  "subscriptionId": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DeploymentCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listDeploymentsInSubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Deployment/deployments`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<DeploymentCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDeploymentsInSubscription",
            httpMethod: "GET"
        });
    }
};

export interface CreateIncidentArgs {
     "createIncidentDetails": CreateIncidentDetails;
     "subscriptionId": string;
     "opcRequestId"?: string
}
export type CreateIncidentReturnType = { response: Response, data: Incident };

export interface GetIncidentArgs {
     "subscriptionId": string;
     "incidentKey": string;
     "opcRequestId"?: string;
     "problemType"?: string
}
export type GetIncidentReturnType = { response: Response, data: Incident };

export interface ListIncidentsArgs {
     "subscriptionId": string;
     "limit"?: number;
     "sortBy"?: string;
     "sortOrder"?: string;
     "lifecycleState"?: string;
     "page"?: string;
     "opcRequestId"?: string;
     "problemType"?: string;
     "numberOfDays"?: number
}
export type ListIncidentsReturnType = { response: Response, data: IncidentCollection };

export interface PutAttachmentArgs {
     "putAttachmentDetails": string;
     "subscriptionId": string;
     "incidentKey": string;
     "attachmentName": string;
     "isRestrictedFlag": boolean;
     "problemType"?: string;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type PutAttachmentReturnType = { response: Response, data: Incident };

export interface UpdateIncidentArgs {
     "subscriptionId": string;
     "incidentKey": string;
     "updateIncidentDetails": UpdateIncidentDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "problemType"?: string
}
export type UpdateIncidentReturnType = { response: Response, data: Incident };

export interface ValidateUserArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "problemType"?: string
}
export type ValidateUserReturnType = { response: Response, data: ValidationResponse };


/**
 * IncidentApi - object-oriented interface
 */
export class IncidentApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): IncidentApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new IncidentApi(fetch, endpoint, config);
    }

    /** 
     * CreateIncident
     * Operation to create a support ticket.
     * @param createIncidentDetails Incident information
     * @param subscriptionId subscription identifier
     * @param opcRequestId The client request ID for tracing.
     */
    public createIncident(params: {  "createIncidentDetails": CreateIncidentDetails; "subscriptionId": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: Incident }> {
        base.validateRequiredParameters([
            "createIncidentDetails",
            "subscriptionId",
        ], "createIncident", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Support/incidents`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<Incident>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createIncidentDetails"]
                    },

            parseResponseBody: true,

            operationName: "createIncident",
            httpMethod: "POST"
        });
    }
    /** 
     * GetIncident
     * Gets details about the specified support ticket.
     * @param subscriptionId subscription identifier
     * @param incidentKey Unique identifier for the support ticket.
     * @param opcRequestId The client request ID for tracing.
     * @param problemType The kind of support request.
     */
    public getIncident(params: {  "subscriptionId": string; "incidentKey": string; "opcRequestId"?: string; "problemType"?: string; }, options?: any): Promise<{ response: Response, data: Incident }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "incidentKey",
        ], "getIncident", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Support/incidents/{incidentKey}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"incidentKey"}}`, `${ params["incidentKey"] }`);

        return this.request<Incident>({
            options,
            path,

            queryParameters: {
                    "problemType": { values: params["problemType"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getIncident",
            httpMethod: "GET"
        });
    }
    /** 
     * ListIncidents
     * Returns the list of support tickets raised by the tenancy.
     * @param subscriptionId subscription identifier
     * @param limit The maximum number of items to return.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param lifecycleState The current state of the ticket.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId The client request ID for tracing.
     * @param problemType The kind of support request.
     * @param numberOfDays How many \&quot;created on\&quot; days prior to today should be included in the returned results
     */
    public listIncidents(params: {  "subscriptionId": string; "limit"?: number; "sortBy"?: string; "sortOrder"?: string; "lifecycleState"?: string; "page"?: string; "opcRequestId"?: string; "problemType"?: string; "numberOfDays"?: number; }, options?: any): Promise<{ response: Response, data: IncidentCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listIncidents", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Support/incidents`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<IncidentCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "lifecycleState": { values: params["lifecycleState"] },
                    "page": { values: params["page"] },
                    "problemType": { values: params["problemType"] },
                    "numberOfDays": { values: params["numberOfDays"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listIncidents",
            httpMethod: "GET"
        });
    }
    /** 
     * PutAttachment
     * Uploads the file and attaches it to the support ticket.
     * @param putAttachmentDetails object/file to be uploaded as attachment to the Service Request.
     * @param subscriptionId subscription identifier
     * @param incidentKey Unique identifier for the support ticket.
     * @param attachmentName The name of the file to attach to the support ticket. Avoid entering confidential information.
     * @param isRestrictedFlag Whether the contents of the attachment are restricted.
     * @param problemType The kind of support request.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId The client request ID for tracing.
     */
    public putAttachment(params: {  "putAttachmentDetails": string; "subscriptionId": string; "incidentKey": string; "attachmentName": string; "isRestrictedFlag": boolean; "problemType"?: string; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: Incident }> {
        base.validateRequiredParameters([
            "putAttachmentDetails",
            "subscriptionId",
            "incidentKey",
            "attachmentName",
            "isRestrictedFlag",
        ], "putAttachment", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Support/incidents/{incidentKey}/attachment`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"incidentKey"}}`, `${ params["incidentKey"] }`);

        return this.request<Incident>({
            options,
            path,

            queryParameters: {
                    "attachmentName": { values: params["attachmentName"] },
                    "problemType": { values: params["problemType"] },
                    "isRestrictedFlag": { values: params["isRestrictedFlag"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["putAttachmentDetails"]
                    },

            parseResponseBody: true,

            operationName: "putAttachment",
            httpMethod: "PUT"
        });
    }
    /** 
     * UpdateIncident
     * Updates the specified support ticket&#39;s information.
     * @param subscriptionId subscription identifier
     * @param incidentKey Unique identifier for the support ticket.
     * @param updateIncidentDetails Details about the support ticket being updated.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId The client request ID for tracing.
     * @param problemType The kind of support request.
     */
    public updateIncident(params: {  "subscriptionId": string; "incidentKey": string; "updateIncidentDetails": UpdateIncidentDetails; "ifMatch"?: string; "opcRequestId"?: string; "problemType"?: string; }, options?: any): Promise<{ response: Response, data: Incident }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "incidentKey",
            "updateIncidentDetails",
        ], "updateIncident", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Support/incidents/{incidentKey}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"incidentKey"}}`, `${ params["incidentKey"] }`);

        return this.request<Incident>({
            options,
            path,

            queryParameters: {
                    "problemType": { values: params["problemType"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateIncidentDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateIncident",
            httpMethod: "PUT"
        });
    }
    /** 
     * ValidateUser
     * Checks whether the user is registered on MOS.
     * @param subscriptionId subscription identifier
     * @param opcRequestId The client request ID for tracing.
     * @param problemType The kind of support request.
     */
    public validateUser(params: {  "subscriptionId": string; "opcRequestId"?: string; "problemType"?: string; }, options?: any): Promise<{ response: Response, data: ValidationResponse }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "validateUser", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Support/user/validate`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<ValidationResponse>({
            options,
            path,

            queryParameters: {
                    "problemType": { values: params["problemType"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "validateUser",
            httpMethod: "GET"
        });
    }
};

export interface GetResourceAvailabilityArgs {
     "subscriptionId": string;
     "providerName": string;
     "limitName": string;
     "opcRequestId"?: string
}
export type GetResourceAvailabilityReturnType = { response: Response, data: ResourceAvailability };

export interface ListLimitDefinitionsArgs {
     "subscriptionId": string;
     "providerName"?: string;
     "name"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListLimitDefinitionsReturnType = { response: Response, data: LimitDefinitionCollection };

export interface ListLimitValuesArgs {
     "subscriptionId": string;
     "providerName": string;
     "scopeType"?: string;
     "name"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListLimitValuesReturnType = { response: Response, data: LimitValueCollection };


/**
 * LimitsApi - object-oriented interface
 */
export class LimitsApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): LimitsApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new LimitsApi(fetch, endpoint, config);
    }

    /** 
     * Get the availability and usage within a compartment for a given resource limit. 
     * For a given compartmentId, resource limit name, and scope, returns the following:   * The number of available resources associated with the given limit.   * The usage in the selected compartment for the given limit.   Note that not all resource limits support this API. If the value is not available, the API returns a 404 response. 
     * @param subscriptionId subscription identifier
     * @param providerName The provider name of the target quota.
     * @param limitName The limit name for which to fetch the data.
     * @param opcRequestId The client request ID for tracing.
     */
    public getResourceAvailability(params: {  "subscriptionId": string; "providerName": string; "limitName": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: ResourceAvailability }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "providerName",
            "limitName",
        ], "getResourceAvailability", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Limit/limits/{limitName}/resourceAvailability`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"limitName"}}`, `${ params["limitName"] }`);

        return this.request<ResourceAvailability>({
            options,
            path,

            queryParameters: {
                    "providerName": { values: params["providerName"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getResourceAvailability",
            httpMethod: "GET"
        });
    }
    /** 
     * Get the list of resource limits for a service.
     * Includes a list of resource limits that are currently supported. If the &#39;areQuotasSupported&#39; property is true, you can create quota policies on top of this limit at the compartment level. 
     * @param subscriptionId subscription identifier
     * @param providerName The target provider name.
     * @param name Optional field, filter for a specific resource limit.
     * @param sortBy The field to sort by. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param limit The maximum number of items to return in a paginated \&quot;List\&quot; call. 
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId The client request ID for tracing.
     */
    public listLimitDefinitions(params: {  "subscriptionId": string; "providerName"?: string; "name"?: string; "sortBy"?: string; "sortOrder"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: LimitDefinitionCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listLimitDefinitions", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Limit/limitDefinitions`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<LimitDefinitionCollection>({
            options,
            path,

            queryParameters: {
                    "providerName": { values: params["providerName"] },
                    "name": { values: params["name"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listLimitDefinitions",
            httpMethod: "GET"
        });
    }
    /** 
     * Get the full list of resource limit values for the given service.
     * Includes a full list of resource limits belonging to a given service. 
     * @param subscriptionId subscription identifier
     * @param providerName The target provider name.
     * @param scopeType Filter entries by scope type.
     * @param name Optional field, can be used to see a specific resource limit value.
     * @param sortBy The field to sort by. The sorting is by availabilityDomain, as a second level field, if available. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param limit The maximum number of items to return in a paginated \&quot;List\&quot; call. 
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId The client request ID for tracing.
     */
    public listLimitValues(params: {  "subscriptionId": string; "providerName": string; "scopeType"?: string; "name"?: string; "sortBy"?: string; "sortOrder"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: LimitValueCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "providerName",
        ], "listLimitValues", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Limit/limitValues`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<LimitValueCollection>({
            options,
            path,

            queryParameters: {
                    "providerName": { values: params["providerName"] },
                    "scopeType": { values: params["scopeType"] },
                    "name": { values: params["name"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listLimitValues",
            httpMethod: "GET"
        });
    }
};

export interface AddHeatWaveClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "addHeatWaveClusterDetails": AddHeatWaveClusterDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type AddHeatWaveClusterReturnType = { response: Response, data: HeatWaveCluster };

export interface CreateMdsBackupArgs {
     "createBackupDetails": CreateMdsBackupDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type CreateMdsBackupReturnType = { response: Response, data: MdsBackup };

export interface CreateMdsDbSystemArgs {
     "createMdsDbSystemDetails": CreateMdsDbSystemDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "apiVersion"?: string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type CreateMdsDbSystemReturnType = { response: Response, data: MdsDbSystem };

export interface DeleteHeatWaveClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type DeleteHeatWaveClusterReturnType = Response;

export interface DeleteMdsBackupArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "backupKey": string;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type DeleteMdsBackupReturnType = Response;

export interface DeleteMdsDbSystemArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "ifMatch"?: string;
     "apiVersion"?: string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type DeleteMdsDbSystemReturnType = Response;

export interface GenerateHeatWaveClusterMemoryEstimateArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type GenerateHeatWaveClusterMemoryEstimateReturnType = Response;

export interface GetHeatWaveClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "opcRequestId"?: string
}
export type GetHeatWaveClusterReturnType = { response: Response, data: HeatWaveCluster };

export interface GetHeatWaveClusterMemoryEstimateArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "opcRequestId"?: string
}
export type GetHeatWaveClusterMemoryEstimateReturnType = { response: Response, data: HeatWaveClusterMemoryEstimate };

export interface GetMdsBackupArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "backupKey": string;
     "opcRequestId"?: string
}
export type GetMdsBackupReturnType = { response: Response, data: MdsBackup };

export interface GetMdsDbSystemArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetMdsDbSystemReturnType = { response: Response, data: MdsDbSystem };

export interface ListMdsBackupsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListMdsBackupsReturnType = { response: Response, data: MdsBackupSummaryCollection };

export interface ListMdsDbSystemsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string;
     "name"?: string
}
export type ListMdsDbSystemsReturnType = { response: Response, data: MdsDbSystemCollection };

export interface ListMdsDbSystemsBackupsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListMdsDbSystemsBackupsReturnType = { response: Response, data: MdsBackupSummaryCollection };

export interface ListMdsDbSystemsBySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string;
     "name"?: string
}
export type ListMdsDbSystemsBySubscriptionReturnType = { response: Response, data: MdsDbSystemCollection };

export interface ListMdsShapesArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "shapeType"?: string
}
export type ListMdsShapesReturnType = { response: Response, data: MdsShapeSummaryCollection };

export interface ListMdsVersionsArgs {
     "subscriptionId": string;
     "opcRequestId"?: string
}
export type ListMdsVersionsReturnType = { response: Response, data: MdsVersionSummaryCollection };

export interface RestartHeatWaveClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type RestartHeatWaveClusterReturnType = Response;

export interface RestartMdsDbSystemArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "restartMdsDbSystemDetails"?: RestartMdsDbSystemDetails
}
export type RestartMdsDbSystemReturnType = Response;

export interface StartHeatWaveClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type StartHeatWaveClusterReturnType = Response;

export interface StartMdsDbSystemArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type StartMdsDbSystemReturnType = Response;

export interface StopHeatWaveClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type StopHeatWaveClusterReturnType = Response;

export interface StopMdsDbSystemArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "stopMdsDbSystemDetails"?: StopMdsDbSystemDetails
}
export type StopMdsDbSystemReturnType = Response;

export interface UpdateHeatWaveClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "updateHeatWaveClusterDetails": UpdateHeatWaveClusterDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type UpdateHeatWaveClusterReturnType = Response;

export interface UpdateMdsBackupArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "backupKey": string;
     "updateMdsBackupDetails": UpdateMdsBackupDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type UpdateMdsBackupReturnType = { response: Response, data: MdsBackup };

export interface UpdateMdsDbSystemArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "updateMdsDbSystemDetails": UpdateMdsDbSystemDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type UpdateMdsDbSystemReturnType = Response;


/**
 * MdsAdaptorApi - object-oriented interface
 */
export class MdsAdaptorApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): MdsAdaptorApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new MdsAdaptorApi(fetch, endpoint, config);
    }

    /** 
     * Adds a HeatWave cluster.
     * Adds a HeatWave cluster to the DB System.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param addHeatWaveClusterDetails Request to add a HeatWave cluster.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public addHeatWaveCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "addHeatWaveClusterDetails": AddHeatWaveClusterDetails; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<{ response: Response, data: HeatWaveCluster }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
            "addHeatWaveClusterDetails",
        ], "addHeatWaveCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/heatWaveCluster/actions/add`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request<HeatWaveCluster>({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["addHeatWaveClusterDetails"]
                    },

            parseResponseBody: true,

            operationName: "addHeatWaveCluster",
            httpMethod: "POST"
        });
    }
    /** 
     * CreateMdsBackup
     * Creates a new backup in the specified database based on the request parameters you provide. If you previously used RMAN or dbcli to configure backups and then you switch to using the Console or the API for backups, a new backup configuration is created and associated with your database. This means that you can no longer rely on your previously configured unmanaged backups to work. 
     * @param createBackupDetails Request to create a new database backup.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public createMdsBackup(params: {  "createBackupDetails": CreateMdsBackupDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: MdsBackup }> {
        base.validateRequiredParameters([
            "createBackupDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createMdsBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<MdsBackup>({
            options,
            path,


            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createBackupDetails"]
                    },

            parseResponseBody: true,

            operationName: "createMdsBackup",
            httpMethod: "POST"
        });
    }
    /** 
     * Creates and launches a DB System for MDS.
     * Creates and launches a DB System for MDS.
     * @param createMdsDbSystemDetails Request to create a new DB System for MDS.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param apiVersion The API version to use for this operation.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public createMdsDbSystem(params: {  "createMdsDbSystemDetails": CreateMdsDbSystemDetails; "subscriptionId": string; "resourceGroupName": string; "apiVersion"?: string; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: MdsDbSystem }> {
        base.validateRequiredParameters([
            "createMdsDbSystemDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createMdsDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<MdsDbSystem>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createMdsDbSystemDetails"]
                    },

            parseResponseBody: true,

            operationName: "createMdsDbSystem",
            httpMethod: "POST"
        });
    }
    /** 
     * DeleteHeatWaveCluster
     * Deletes a HeatWave cluster.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public deleteHeatWaveCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "deleteHeatWaveCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/heatWaveCluster`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteHeatWaveCluster",
            httpMethod: "DELETE"
        });
    }
    /** 
     * DeleteMdsBackup
     * Deletes a full backup. You cannot delete automatic backups using this API.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param backupKey The name of the database node resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public deleteMdsBackup(params: {  "subscriptionId": string; "resourceGroupName": string; "backupKey": string; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "backupKey",
        ], "deleteMdsBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/backups/{backupKey}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"backupKey"}}`, `${ params["backupKey"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteMdsBackup",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Deletes the specified MDS Db System
     * Deletes the specified MDS Db System 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param apiVersion The API version to use for this operation.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public deleteMdsDbSystem(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "ifMatch"?: string; "apiVersion"?: string; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "deleteMdsDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteMdsDbSystem",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Sends a request to estimate the memory footprints of user tables when loaded to HeatWave cluster memory.
     * Sends a request to estimate the memory footprints of user tables when loaded to HeatWave cluster memory.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public generateHeatWaveClusterMemoryEstimate(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "generateHeatWaveClusterMemoryEstimate", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/heatWaveClusterMemoryEstimate/actions/generate`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },



            operationName: "generateHeatWaveClusterMemoryEstimate",
            httpMethod: "POST"
        });
    }
    /** 
     * GetHeatWaveCluster
     * Gets HeatWave cluster based on dbSystemName
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     */
    public getHeatWaveCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: HeatWaveCluster }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "getHeatWaveCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/heatWaveCluster`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request<HeatWaveCluster>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getHeatWaveCluster",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets the most recent HeatWave cluster memory estimate that can be used to determine a suitable HeatWave cluster size.
     * Gets the most recent HeatWave cluster memory estimate that can be used to determine a suitable HeatWave cluster size.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     */
    public getHeatWaveClusterMemoryEstimate(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: HeatWaveClusterMemoryEstimate }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "getHeatWaveClusterMemoryEstimate", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/heatWaveClusterMemoryEstimate`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request<HeatWaveClusterMemoryEstimate>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getHeatWaveClusterMemoryEstimate",
            httpMethod: "GET"
        });
    }
    /** 
     * GetMdsBackup
     * Gets information about the specified backup.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param backupKey The name of the database node resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     */
    public getMdsBackup(params: {  "subscriptionId": string; "resourceGroupName": string; "backupKey": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: MdsBackup }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "backupKey",
        ], "getMdsBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/backups/{backupKey}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"backupKey"}}`, `${ params["backupKey"] }`);

        return this.request<MdsBackup>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getMdsBackup",
            httpMethod: "GET"
        });
    }
    /** 
     * GetMdsDbSystem
     * Gets Mds DbSystem based on dbSystemName
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getMdsDbSystem(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: MdsDbSystem }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "getMdsDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request<MdsDbSystem>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getMdsDbSystem",
            httpMethod: "GET"
        });
    }
    /** 
     * ListMdsBackups
     * Gets a list of backups based on the &#x60;databaseId&#x60; specified. Either one of these query parameters must be provided. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listMdsBackups(params: {  "subscriptionId": string; "resourceGroupName": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: MdsBackupSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listMdsBackups", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<MdsBackupSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listMdsBackups",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all MDS Db Systems in the specified resource group.
     * Returns a list of MDS Db Systems in the specified resource group.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRequestId Unique identifier for the request. 
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param name A filter to return only resources that match the entire display name given. The match is not case sensitive.
     */
    public listMdsDbSystems(params: {  "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "limit"?: number; "page"?: string; "name"?: string; }, options?: any): Promise<{ response: Response, data: MdsDbSystemCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listMdsDbSystems", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<MdsDbSystemCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "name": { values: params["name"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listMdsDbSystems",
            httpMethod: "GET"
        });
    }
    /** 
     * ListMdsDbSystemBackups
     * Gets a list of backups for the specified MDS dbSystem. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listMdsDbSystemsBackups(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: MdsBackupSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "listMdsDbSystemsBackups", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request<MdsBackupSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listMdsDbSystemsBackups",
            httpMethod: "GET"
        });
    }
    /** 
     * Get List of MDS DB Systems for specific subscription
     * Returns a list of DbSystems in the specified subscription.
     * @param subscriptionId subscription identifier
     * @param opcRequestId Unique identifier for the request. 
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param name A filter to return only resources that match the entire display name given. The match is not case sensitive.
     */
    public listMdsDbSystemsBySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; "limit"?: number; "page"?: string; "name"?: string; }, options?: any): Promise<{ response: Response, data: MdsDbSystemCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listMdsDbSystemsBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Mds/dbSystems`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<MdsDbSystemCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "name": { values: params["name"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listMdsDbSystemsBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of MySql shapes that are supported.
     * Returns a list of MySql shapes that are supported.
     * @param subscriptionId subscription identifier
     * @param opcRequestId Unique identifier for the request. 
     * @param shapeType MDS System shape type
     */
    public listMdsShapes(params: {  "subscriptionId": string; "opcRequestId"?: string; "shapeType"?: string; }, options?: any): Promise<{ response: Response, data: MdsShapeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listMdsShapes", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Mds/shapes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<MdsShapeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "shapeType": { values: params["shapeType"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listMdsShapes",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of MySql versions that are supported.
     * Returns a list of MySql versions that are supported.
     * @param subscriptionId subscription identifier
     * @param opcRequestId Unique identifier for the request. 
     */
    public listMdsVersions(params: {  "subscriptionId": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: MdsVersionSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listMdsVersions", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Mds/versions`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<MdsVersionSummaryCollection>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listMdsVersions",
            httpMethod: "GET"
        });
    }
    /** 
     * Restart a HeatWave cluster.
     * Restart a HeatWave cluster.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public restartHeatWaveCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "restartHeatWaveCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/heatWaveCluster/actions/restart`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },



            operationName: "restartHeatWaveCluster",
            httpMethod: "POST"
        });
    }
    /** 
     * Restart the specified DB System.
     * Restart the specified DB System.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param restartMdsDbSystemDetails DB System shutdown parameters.
     */
    public restartMdsDbSystem(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; "restartMdsDbSystemDetails"?: RestartMdsDbSystemDetails; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "restartMdsDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/actions/restart`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },

                    body: !params["restartMdsDbSystemDetails"] ? undefined : 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["restartMdsDbSystemDetails"]
                    },


            operationName: "restartMdsDbSystem",
            httpMethod: "POST"
        });
    }
    /** 
     * Start a HeatWave cluster.
     * Start a HeatWave cluster.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public startHeatWaveCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "startHeatWaveCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/heatWaveCluster/actions/start`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },



            operationName: "startHeatWaveCluster",
            httpMethod: "POST"
        });
    }
    /** 
     * Start a DB System.
     * Start a DB System.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public startMdsDbSystem(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "startMdsDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/actions/start`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },



            operationName: "startMdsDbSystem",
            httpMethod: "POST"
        });
    }
    /** 
     * Stop a HeatWave cluster.
     * Stop a HeatWave cluster.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public stopHeatWaveCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "stopHeatWaveCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/heatWaveCluster/actions/stop`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },



            operationName: "stopHeatWaveCluster",
            httpMethod: "POST"
        });
    }
    /** 
     * Stops the specified DB System.
     * Stops the specified DB System.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param stopMdsDbSystemDetails DB System shutdown parameters.
     */
    public stopMdsDbSystem(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; "stopMdsDbSystemDetails"?: StopMdsDbSystemDetails; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "stopMdsDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/actions/stop`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },

                    body: !params["stopMdsDbSystemDetails"] ? undefined : 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["stopMdsDbSystemDetails"]
                    },


            operationName: "stopMdsDbSystem",
            httpMethod: "POST"
        });
    }
    /** 
     * UpdateHeatWaveCluster
     * Update a HeatWave cluster.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param updateHeatWaveClusterDetails Request to update a HeatWave cluster.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public updateHeatWaveCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "updateHeatWaveClusterDetails": UpdateHeatWaveClusterDetails; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
            "updateHeatWaveClusterDetails",
        ], "updateHeatWaveCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}/heatWaveCluster`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateHeatWaveClusterDetails"]
                    },


            operationName: "updateHeatWaveCluster",
            httpMethod: "PUT"
        });
    }
    /** 
     * UpdateMdsBackup
     * Update a MDS Backup.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param backupKey The name of the database node resource. The name needs to be unique in the resource group.
     * @param updateMdsBackupDetails Request to update a MDS Backup.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public updateMdsBackup(params: {  "subscriptionId": string; "resourceGroupName": string; "backupKey": string; "updateMdsBackupDetails": UpdateMdsBackupDetails; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: MdsBackup }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "backupKey",
            "updateMdsBackupDetails",
        ], "updateMdsBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/backups/{backupKey}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"backupKey"}}`, `${ params["backupKey"] }`);

        return this.request<MdsBackup>({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateMdsBackupDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateMdsBackup",
            httpMethod: "PUT"
        });
    }
    /** 
     * UpdateMdsDbSystem
     * Update a MDS DbSystem.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the MDS Db System resource. The name needs to be unique in the resource group.
     * @param updateMdsDbSystemDetails Request to update a MDS DbSystem.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public updateMdsDbSystem(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "updateMdsDbSystemDetails": UpdateMdsDbSystemDetails; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
            "updateMdsDbSystemDetails",
        ], "updateMdsDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Mds/dbSystems/{dbSystemName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateMdsDbSystemDetails"]
                    },


            operationName: "updateMdsDbSystem",
            httpMethod: "PUT"
        });
    }
};

export interface CreateMultiCloudVirtualNetworkArgs {
     "createMultiCloudVirtualNetworkDetails": CreateMultiCloudVirtualNetworkDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type CreateMultiCloudVirtualNetworkReturnType = { response: Response, data: MultiCloudVirtualNetwork };

export interface DeleteMultiCloudVirtualNetworkArgs {
     "multiCloudVirtualNetworkName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteMultiCloudVirtualNetworkReturnType = Response;

export interface GetMultiCloudVirtualNetworkArgs {
     "multiCloudVirtualNetworkName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetMultiCloudVirtualNetworkReturnType = { response: Response, data: MultiCloudVirtualNetwork };

export interface ListMultiCloudVirtualNetworksArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "status"?: string;
     "name"?: string;
     "limit"?: number;
     "page"?: string;
     "sortOrder"?: string;
     "sortBy"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "vcnId"?: string;
     "location"?: string
}
export type ListMultiCloudVirtualNetworksReturnType = { response: Response, data: MultiCloudVirtualNetworkCollection };

export interface ListMultiCloudVirtualNetworksInSubscriptionArgs {
     "subscriptionId": string;
     "status"?: string;
     "name"?: string;
     "limit"?: number;
     "page"?: string;
     "sortOrder"?: string;
     "sortBy"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "vcnId"?: string;
     "location"?: string
}
export type ListMultiCloudVirtualNetworksInSubscriptionReturnType = { response: Response, data: MultiCloudVirtualNetworkCollection };

export interface ValidateNetworkingInputArgs {
     "validateNetworkingInputDetails": ValidateNetworkingInputDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ValidateNetworkingInputReturnType = { response: Response, data: ValidatedNetworkingInputDetails };


/**
 * MultiCloudVirtualNetworkApi - object-oriented interface
 */
export class MultiCloudVirtualNetworkApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): MultiCloudVirtualNetworkApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new MultiCloudVirtualNetworkApi(fetch, endpoint, config);
    }

    /** 
     * Creates a new MultiCloudVirtualNetwork
     * Creates a new MultiCloudVirtualNetwork. 
     * @param createMultiCloudVirtualNetworkDetails Details for the new MultiCloudVirtualNetwork.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public createMultiCloudVirtualNetwork(params: {  "createMultiCloudVirtualNetworkDetails": CreateMultiCloudVirtualNetworkDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: MultiCloudVirtualNetwork }> {
        base.validateRequiredParameters([
            "createMultiCloudVirtualNetworkDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createMultiCloudVirtualNetwork", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Network/multiCloudVirtualNetworks`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<MultiCloudVirtualNetwork>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createMultiCloudVirtualNetworkDetails"]
                    },

            parseResponseBody: true,

            operationName: "createMultiCloudVirtualNetwork",
            httpMethod: "POST"
        });
    }
    /** 
     * Delete MultiCloudVirtualNetwork
     * Delete a MultiCloudVirtualNetwork by identifier
     * @param multiCloudVirtualNetworkName unique MultiCloudVirtualNetwork identifier Name
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteMultiCloudVirtualNetwork(params: {  "multiCloudVirtualNetworkName": string; "subscriptionId": string; "resourceGroupName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "multiCloudVirtualNetworkName",
            "subscriptionId",
            "resourceGroupName",
        ], "deleteMultiCloudVirtualNetwork", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Network/multiCloudVirtualNetworks/{multiCloudVirtualNetworkName}`
            .replace(`{${"multiCloudVirtualNetworkName"}}`, `${ params["multiCloudVirtualNetworkName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteMultiCloudVirtualNetwork",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Get MultiCloudVirtualNetwork
     * Gets a MultiCloudVirtualNetwork by identifier
     * @param multiCloudVirtualNetworkName unique MultiCloudVirtualNetwork identifier Name
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getMultiCloudVirtualNetwork(params: {  "multiCloudVirtualNetworkName": string; "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: MultiCloudVirtualNetwork }> {
        base.validateRequiredParameters([
            "multiCloudVirtualNetworkName",
            "subscriptionId",
            "resourceGroupName",
        ], "getMultiCloudVirtualNetwork", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Network/multiCloudVirtualNetworks/{multiCloudVirtualNetworkName}`
            .replace(`{${"multiCloudVirtualNetworkName"}}`, `${ params["multiCloudVirtualNetworkName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<MultiCloudVirtualNetwork>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getMultiCloudVirtualNetwork",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all MultiCloudVirtualNetworks in a given subscription and resource group
     * Returns a list of MultiCloudVirtualNetworks. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param status A filter to return resources whose status matches the provided Status.
     * @param name A filter to return resources whose name matches the provided Name.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param vcnId A filter to return resources whose Virtual Network Identifier Id matches the provided vcnId.
     * @param location A filter to return resources whose location matches the provided location.
     */
    public listMultiCloudVirtualNetworks(params: {  "subscriptionId": string; "resourceGroupName": string; "status"?: string; "name"?: string; "limit"?: number; "page"?: string; "sortOrder"?: string; "sortBy"?: string; "opcRequestId"?: string; "apiVersion"?: string; "vcnId"?: string; "location"?: string; }, options?: any): Promise<{ response: Response, data: MultiCloudVirtualNetworkCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listMultiCloudVirtualNetworks", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Network/multiCloudVirtualNetworks`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<MultiCloudVirtualNetworkCollection>({
            options,
            path,

            queryParameters: {
                    "status": { values: params["status"] },
                    "name": { values: params["name"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "sortBy": { values: params["sortBy"] },
                    "api-version": { values: params["apiVersion"] },
                    "vcnId": { values: params["vcnId"] },
                    "location": { values: params["location"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listMultiCloudVirtualNetworks",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all MultiCloudVirtualNetworks in a given subscription
     * Returns a list of MultiCloudVirtualNetworks. 
     * @param subscriptionId subscription identifier
     * @param status A filter to return resources whose status matches the provided Status.
     * @param name A filter to return resources whose name matches the provided Name.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param vcnId A filter to return resources whose Virtual Network Identifier Id matches the provided vcnId.
     * @param location A filter to return resources whose location matches the provided location.
     */
    public listMultiCloudVirtualNetworksInSubscription(params: {  "subscriptionId": string; "status"?: string; "name"?: string; "limit"?: number; "page"?: string; "sortOrder"?: string; "sortBy"?: string; "opcRequestId"?: string; "apiVersion"?: string; "vcnId"?: string; "location"?: string; }, options?: any): Promise<{ response: Response, data: MultiCloudVirtualNetworkCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listMultiCloudVirtualNetworksInSubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Network/multiCloudVirtualNetworks`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<MultiCloudVirtualNetworkCollection>({
            options,
            path,

            queryParameters: {
                    "status": { values: params["status"] },
                    "name": { values: params["name"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "sortBy": { values: params["sortBy"] },
                    "api-version": { values: params["apiVersion"] },
                    "vcnId": { values: params["vcnId"] },
                    "location": { values: params["location"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listMultiCloudVirtualNetworksInSubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * Validates the networking input.
     * Pre flight check to validate the networking inputs to fail fast. 
     * @param validateNetworkingInputDetails Details of the networking inputs to be validated.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public validateNetworkingInput(params: {  "validateNetworkingInputDetails": ValidateNetworkingInputDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: ValidatedNetworkingInputDetails }> {
        base.validateRequiredParameters([
            "validateNetworkingInputDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "validateNetworkingInput", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Network/validateNetworkingDetails`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<ValidatedNetworkingInputDetails>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["validateNetworkingInputDetails"]
                    },

            parseResponseBody: true,

            operationName: "validateNetworkingInput",
            httpMethod: "POST"
        });
    }
};

export interface AddCloudVmClusterAvailableCapacityArgs {
     "addCloudVmClusterAvailableCapacityDetails": AddCloudVmClusterAvailableCapacityDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "cloudVmClusterName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type AddCloudVmClusterAvailableCapacityReturnType = Response;

export interface CreateBackupArgs {
     "createBackupDetails": CreateBackupDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type CreateBackupReturnType = { response: Response, data: Backup };

export interface CreateCloudExadataInfrastructureArgs {
     "createCloudExadataInfrastructureDetails": CreateCloudExadataInfrastructureDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type CreateCloudExadataInfrastructureReturnType = { response: Response, data: CloudExadataInfrastructure };

export interface CreateCloudVmClusterArgs {
     "createCloudVmClusterDetails": CreateCloudVmClusterDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type CreateCloudVmClusterReturnType = { response: Response, data: CloudVmCluster };

export interface CreateDatabaseArgs {
     "createNewDatabaseDetails": CreateDatabaseBase;
     "subscriptionId": string;
     "resourceGroupName": string;
     "apiVersion"?: string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type CreateDatabaseReturnType = { response: Response, data: Database };

export interface CreateDbHomeArgs {
     "createDbHomeDetails": CreateDbHomeBase;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "apiVersion"?: string
}
export type CreateDbHomeReturnType = { response: Response, data: DbHome };

export interface CreatePluggableDatabaseArgs {
     "createPluggableDatabaseDetails": CreatePluggableDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type CreatePluggableDatabaseReturnType = { response: Response, data: PluggableDatabase };

export interface DbNodeActionArgs {
     "action": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbNodeName": string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "ifMatch"?: string;
     "apiVersion"?: string
}
export type DbNodeActionReturnType = { response: Response, data: DbNode };

export interface DeleteBackupArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "backupKey": string;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type DeleteBackupReturnType = Response;

export interface DeleteCloudExadataInfrastructureArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "cloudExadataInfrastructureName": string;
     "ifMatch"?: string;
     "isDeleteVmClusters"?: boolean;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteCloudExadataInfrastructureReturnType = Response;

export interface DeleteCloudVmClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "cloudVmClusterName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteCloudVmClusterReturnType = Response;

export interface DeleteDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "ifMatch"?: string;
     "performFinalBackup"?: boolean;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteDatabaseReturnType = Response;

export interface DeleteDbHomeArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbHomeName": string;
     "ifMatch"?: string;
     "apiVersion"?: string;
     "opcRequestId"?: string
}
export type DeleteDbHomeReturnType = Response;

export interface DeletePluggableDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeletePluggableDatabaseReturnType = Response;

export interface EnableObservabilityArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type EnableObservabilityReturnType = { response: Response, data: Database };

export interface GetBackupArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "backupKey": string;
     "opcRequestId"?: string
}
export type GetBackupReturnType = { response: Response, data: Backup };

export interface GetCloudExadataInfrastructureArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "cloudExadataInfrastructureName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetCloudExadataInfrastructureReturnType = { response: Response, data: CloudExadataInfrastructure };

export interface GetCloudVmClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "cloudVmClusterName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetCloudVmClusterReturnType = { response: Response, data: CloudVmCluster };

export interface GetCloudVmClusterAvailableCapacityArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "cloudVmClusterName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetCloudVmClusterAvailableCapacityReturnType = { response: Response, data: CloudVmClusterAvailableCapacity };

export interface GetDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetDatabaseReturnType = { response: Response, data: Database };

export interface GetDbHomeArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbHomeName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetDbHomeReturnType = { response: Response, data: DbHome };

export interface GetDbNodeArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbNodeName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetDbNodeReturnType = { response: Response, data: DbNode };

export interface GetPluggableDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetPluggableDatabaseReturnType = { response: Response, data: PluggableDatabase };

export interface ListBackupsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListBackupsReturnType = { response: Response, data: BackupCollection };

export interface ListBackupsBySubscriptionArgs {
     "subscriptionId": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListBackupsBySubscriptionReturnType = { response: Response, data: BackupCollection };

export interface ListCloudExadataInfrastructuresArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "lifecycleState"?: string;
     "displayName"?: string
}
export type ListCloudExadataInfrastructuresReturnType = { response: Response, data: CloudExadataInfrastructureSummaryCollection };

export interface ListCloudExadataInfrastructuresBySubscriptionArgs {
     "subscriptionId": string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string;
     "sortBy"?: string;
     "sortOrder"?: string
}
export type ListCloudExadataInfrastructuresBySubscriptionReturnType = { response: Response, data: CloudExadataInfrastructureSummaryCollection };

export interface ListCloudVmClustersArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "lifecycleState"?: string;
     "displayName"?: string;
     "opcRequestId"?: string
}
export type ListCloudVmClustersReturnType = { response: Response, data: CloudVmClusterSummaryCollection };

export interface ListCloudVmClustersBySubscriptionArgs {
     "subscriptionId": string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "opcRequestId"?: string
}
export type ListCloudVmClustersBySubscriptionReturnType = { response: Response, data: CloudVmClusterSummaryCollection };

export interface ListDatabaseBackupsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListDatabaseBackupsReturnType = { response: Response, data: BackupSummaryCollection };

export interface ListDatabasesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "vmClusterId"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "lifecycleState"?: string;
     "dbName"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListDatabasesReturnType = { response: Response, data: DatabaseSummaryCollection };

export interface ListDatabasesBySubscriptionArgs {
     "subscriptionId": string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListDatabasesBySubscriptionReturnType = { response: Response, data: DatabaseSummaryCollection };

export interface ListDbHomesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "vmClusterId"?: string;
     "dbVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "lifecycleState"?: string;
     "displayName"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListDbHomesReturnType = { response: Response, data: DbHomeSummaryCollection };

export interface ListDbHomesBySubscriptionArgs {
     "subscriptionId": string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListDbHomesBySubscriptionReturnType = { response: Response, data: DbHomeSummaryCollection };

export interface ListDbNodesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "vmClusterId"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "lifecycleState"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListDbNodesReturnType = { response: Response, data: DbNodeSummaryCollection };

export interface ListDbNodesBySubscriptionArgs {
     "subscriptionId": string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListDbNodesBySubscriptionReturnType = { response: Response, data: DbNodeSummaryCollection };

export interface ListDbSystemShapesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string
}
export type ListDbSystemShapesReturnType = { response: Response, data: DbSystemShapeSummaryCollection };

export interface ListDbSystemShapesBySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string
}
export type ListDbSystemShapesBySubscriptionReturnType = { response: Response, data: DbSystemShapeSummaryCollection };

export interface ListDbVersionsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "dbSystemShape"?: string;
     "isUpgradeSupported"?: boolean;
     "isDatabaseSoftwareImageSupported"?: boolean
}
export type ListDbVersionsReturnType = { response: Response, data: DbVersionSummaryCollection };

export interface ListDbVersionsBySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "dbSystemShape"?: string;
     "isUpgradeSupported"?: boolean;
     "isDatabaseSoftwareImageSupported"?: boolean
}
export type ListDbVersionsBySubscriptionReturnType = { response: Response, data: DbVersionSummaryCollection };

export interface ListFlexComponentsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "name"?: string;
     "sortOrder"?: string;
     "sortBy"?: string;
     "limit"?: number;
     "page"?: string
}
export type ListFlexComponentsReturnType = { response: Response, data: FlexComponentCollection };

export interface ListFlexComponentsBySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "name"?: string;
     "sortOrder"?: string;
     "sortBy"?: string;
     "limit"?: number;
     "page"?: string
}
export type ListFlexComponentsBySubscriptionReturnType = { response: Response, data: FlexComponentCollection };

export interface ListGiVersionsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "sortOrder"?: string;
     "shape"?: string
}
export type ListGiVersionsReturnType = { response: Response, data: GiVersionSummaryCollection };

export interface ListGiVersionsBySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "sortOrder"?: string;
     "shape"?: string
}
export type ListGiVersionsBySubscriptionReturnType = { response: Response, data: GiVersionSummaryCollection };

export interface ListPluggableDatabasesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "databaseId"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "lifecycleState"?: string;
     "pdbName"?: string;
     "apiVersion"?: string
}
export type ListPluggableDatabasesReturnType = { response: Response, data: PluggableDatabaseSummaryCollection };

export interface ListPluggableDatabasesBySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string;
     "sortBy"?: string;
     "sortOrder"?: string;
     "apiVersion"?: string
}
export type ListPluggableDatabasesBySubscriptionReturnType = { response: Response, data: PluggableDatabaseSummaryCollection };

export interface LocalClonePluggableDatabaseArgs {
     "localClonePluggableDatabaseDetails": LocalClonePluggableDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "apiVersion"?: string
}
export type LocalClonePluggableDatabaseReturnType = { response: Response, data: PluggableDatabase };

export interface RestoreDatabaseArgs {
     "restoreDatabaseDetails": RestoreDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type RestoreDatabaseReturnType = { response: Response, data: Database };

export interface StartPluggableDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "apiVersion"?: string
}
export type StartPluggableDatabaseReturnType = { response: Response, data: PluggableDatabase };

export interface StopPluggableDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "apiVersion"?: string
}
export type StopPluggableDatabaseReturnType = { response: Response, data: PluggableDatabase };

export interface UpdateCloudExadataInfrastructureArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "cloudExadataInfrastructureName": string;
     "updateCloudExadataInfrastructureDetails": UpdateCloudExadataInfrastructureDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type UpdateCloudExadataInfrastructureReturnType = { response: Response, data: CloudExadataInfrastructure };

export interface UpdateCloudVmClusterArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "cloudVmClusterName": string;
     "updateCloudVmClusterDetails": UpdateCloudVmClusterDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type UpdateCloudVmClusterReturnType = { response: Response, data: CloudVmCluster };

export interface UpdateDatabaseArgs {
     "updateDatabaseDetails": UpdateDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type UpdateDatabaseReturnType = { response: Response, data: Database };

export interface UpdatePluggableDatabaseArgs {
     "updatePluggableDatabaseDetails": UpdatePluggableDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type UpdatePluggableDatabaseReturnType = { response: Response, data: PluggableDatabase };


/**
 * MulticloudDatabaseApi - object-oriented interface
 */
export class MulticloudDatabaseApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): MulticloudDatabaseApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new MulticloudDatabaseApi(fetch, endpoint, config);
    }

    /** 
     * AddCloudVmClusterAvailableCapacity
     * Adds available capacity provided in the request parameters to cloud VM cluster. 
     * @param addCloudVmClusterAvailableCapacityDetails Request to perform capacity scale up for cloud VM cluster.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param cloudVmClusterName The name of the cloud VM cluster resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public addCloudVmClusterAvailableCapacity(params: {  "addCloudVmClusterAvailableCapacityDetails": AddCloudVmClusterAvailableCapacityDetails; "subscriptionId": string; "resourceGroupName": string; "cloudVmClusterName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "addCloudVmClusterAvailableCapacityDetails",
            "subscriptionId",
            "resourceGroupName",
            "cloudVmClusterName",
        ], "addCloudVmClusterAvailableCapacity", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudVmClusters/{cloudVmClusterName}/addCapacity`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"cloudVmClusterName"}}`, `${ params["cloudVmClusterName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["addCloudVmClusterAvailableCapacityDetails"]
                    },


            operationName: "addCloudVmClusterAvailableCapacity",
            httpMethod: "POST"
        });
    }
    /** 
     * Create a backup.
     * Creates a backup. 
     * @param createBackupDetails Request to create a backup.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public createBackup(params: {  "createBackupDetails": CreateBackupDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: Backup }> {
        base.validateRequiredParameters([
            "createBackupDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<Backup>({
            options,
            path,


            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createBackupDetails"]
                    },

            parseResponseBody: true,

            operationName: "createBackup",
            httpMethod: "POST"
        });
    }
    /** 
     * CreateCloudExadataInfrastructure
     * Creates a cloud Exadata infrastructure resource. This resource is used to create either an [Exadata Cloud Service](/Content/Database/Concepts/exaoverview.htm) instance or an Autonomous Database on dedicated Exadata infrastructure. 
     * @param createCloudExadataInfrastructureDetails Request to create a cloud Exadata infrastructure resource in an [Exadata Cloud Service](/Content/Database/Concepts/exaoverview.htm) instance. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public createCloudExadataInfrastructure(params: {  "createCloudExadataInfrastructureDetails": CreateCloudExadataInfrastructureDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: CloudExadataInfrastructure }> {
        base.validateRequiredParameters([
            "createCloudExadataInfrastructureDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createCloudExadataInfrastructure", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudExadataInfrastructures`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<CloudExadataInfrastructure>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createCloudExadataInfrastructureDetails"]
                    },

            parseResponseBody: true,

            operationName: "createCloudExadataInfrastructure",
            httpMethod: "POST"
        });
    }
    /** 
     * CreateCloudVmCluster
     * Creates a cloud VM cluster. 
     * @param createCloudVmClusterDetails Request to create a cloud VM cluster. Applies to Exadata Cloud Service instances only. See [The New Exadata Cloud Service Resource Model](/iaas/Content/Database/iaas/Content/Database/Concepts/exaflexsystem.htm#exaflexsystem_topic-resource_model) for information on this resource type. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public createCloudVmCluster(params: {  "createCloudVmClusterDetails": CreateCloudVmClusterDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: CloudVmCluster }> {
        base.validateRequiredParameters([
            "createCloudVmClusterDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createCloudVmCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudVmClusters`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<CloudVmCluster>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createCloudVmClusterDetails"]
                    },

            parseResponseBody: true,

            operationName: "createCloudVmCluster",
            httpMethod: "POST"
        });
    }
    /** 
     * CreateDatabase
     * Creates a new database in the specified Database Home. If the database version is provided, it must match the version of the Database Home. Applies to Exadata and Exadata Cloud@Customer systems. 
     * @param createNewDatabaseDetails Request to create a new database.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param apiVersion The API version to use for this operation.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public createDatabase(params: {  "createNewDatabaseDetails": CreateDatabaseBase; "subscriptionId": string; "resourceGroupName": string; "apiVersion"?: string; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: Database }> {
        base.validateRequiredParameters([
            "createNewDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<Database>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createNewDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "createDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * CreateDbHome
     * Create new Database Home in the specified resource group. A Database Home is a directory where Oracle Database software is installed. 
     * @param createDbHomeDetails Request to create Db Home.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param apiVersion The API version to use for this operation.
     */
    public createDbHome(params: {  "createDbHomeDetails": CreateDbHomeBase; "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "opcRetryToken"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DbHome }> {
        base.validateRequiredParameters([
            "createDbHomeDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createDbHome", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/dbHomes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<DbHome>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createDbHomeDetails"]
                    },

            parseResponseBody: true,

            operationName: "createDbHome",
            httpMethod: "POST"
        });
    }
    /** 
     * CreatePluggableDatabase
     * Creates and starts a pluggable database in the specified container database. Use the [StartPluggableDatabase](#/en/database/latest/PluggableDatabase/StartPluggableDatabase) and [StopPluggableDatabase](#/en/database/latest/PluggableDatabase/StopPluggableDatabase) APIs to start and stop the pluggable database. 
     * @param createPluggableDatabaseDetails Request to create pluggable database.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public createPluggableDatabase(params: {  "createPluggableDatabaseDetails": CreatePluggableDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: PluggableDatabase }> {
        base.validateRequiredParameters([
            "createPluggableDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createPluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/pluggableDatabases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<PluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createPluggableDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "createPluggableDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * DbNodeAction
     * Performs one of the following power actions on the specified DB node: - start - power on - stop - power off - softreset - ACPI shutdown and power on - reset - power off and power on  **Note:** Stopping a node affects billing differently, depending on the type of DB system: *Bare metal and Exadata systems* - The _stop_ state has no effect on the resources you consume. Billing continues for DB nodes that you stop, and related resources continue to apply against any relevant quotas. You must terminate the DB system ([TerminateDbSystem](#/en/database/latest/DbSystem/TerminateDbSystem)) to remove its resources from billing and quotas. *Virtual machine DB systems* - Stopping a node stops billing for all OCPUs associated with that node, and billing resumes when you restart the node. 
     * @param action The action to perform on the DB Node.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param dbNodeName The name of the database node resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param apiVersion The API version to use for this operation.
     */
    public dbNodeAction(params: {  "action": string; "subscriptionId": string; "resourceGroupName": string; "dbNodeName": string; "opcRequestId"?: string; "opcRetryToken"?: string; "ifMatch"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DbNode }> {
        base.validateRequiredParameters([
            "action",
            "subscriptionId",
            "resourceGroupName",
            "dbNodeName",
        ], "dbNodeAction", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/dbNodes/{dbNodeName}/dbNodeAction`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbNodeName"}}`, `${ params["dbNodeName"] }`);

        return this.request<DbNode>({
            options,
            path,

            queryParameters: {
                    "action": { values: params["action"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"],
                "if-match":  params["ifMatch"]
            },


            parseResponseBody: true,

            operationName: "dbNodeAction",
            httpMethod: "POST"
        });
    }
    /** 
     * Deletes the specified backup
     * Deletes the specified backup
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param backupKey The name of the database node resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public deleteBackup(params: {  "subscriptionId": string; "resourceGroupName": string; "backupKey": string; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "backupKey",
        ], "deleteBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/backups/{backupKey}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"backupKey"}}`, `${ params["backupKey"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteBackup",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Deletes the specified cloud Exadata infrastructure.
     * Deletes the cloud Exadata infrastructure resource. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param cloudExadataInfrastructureName The name of the cloud Exadata infrastructure resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param isDeleteVmClusters If &#x60;true&#x60;, forces the deletion the specified cloud Exadata infrastructure resource as well as all associated VM clusters. If &#x60;false&#x60;, the cloud Exadata infrastructure resource can be deleted only if it has no associated VM clusters. Default value is &#x60;false&#x60;. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteCloudExadataInfrastructure(params: {  "subscriptionId": string; "resourceGroupName": string; "cloudExadataInfrastructureName": string; "ifMatch"?: string; "isDeleteVmClusters"?: boolean; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "cloudExadataInfrastructureName",
        ], "deleteCloudExadataInfrastructure", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudExadataInfrastructures/{cloudExadataInfrastructureName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"cloudExadataInfrastructureName"}}`, `${ params["cloudExadataInfrastructureName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "isDeleteVmClusters": { values: params["isDeleteVmClusters"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteCloudExadataInfrastructure",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Deletes the specified cloud VM cluster.
     * Deletes the specified cloud VM cluster. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param cloudVmClusterName The name of the cloud VM cluster resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteCloudVmCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "cloudVmClusterName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "cloudVmClusterName",
        ], "deleteCloudVmCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudVmClusters/{cloudVmClusterName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"cloudVmClusterName"}}`, `${ params["cloudVmClusterName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteCloudVmCluster",
            httpMethod: "DELETE"
        });
    }
    /** 
     * DeleteDatabase
     * Deletes the specified database. Applies only to Exadata systems.  The data in this database is local to the Exadata system and will be lost when the database is deleted. Oracle recommends that you back up any data in the Exadata system prior to deleting it. You can use the &#x60;performFinalBackup&#x60; parameter to have the Exadata system database backed up before it is deleted. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param databaseName The name of the database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param performFinalBackup Whether to perform a final backup of the database or not. Default is false.  If you previously used RMAN or dbcli to configure backups and then you switch to using the Console or the API for backups, a new backup configuration is created and associated with your database. This means that you can no longer rely on your previously configured unmanaged backups to work.  This parameter is used in multiple APIs. Refer to the API description for details on how the operation uses it. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "ifMatch"?: string; "performFinalBackup"?: boolean; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "deleteDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "performFinalBackup": { values: params["performFinalBackup"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteDatabase",
            httpMethod: "DELETE"
        });
    }
    /** 
     * DeleteDbHome
     * Delete Database Home in the specified resource group. A Database Home is a directory where Oracle Database software is installed. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param dbHomeName The name of the database home resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param apiVersion The API version to use for this operation.
     * @param opcRequestId Unique identifier for the request. 
     */
    public deleteDbHome(params: {  "subscriptionId": string; "resourceGroupName": string; "dbHomeName": string; "ifMatch"?: string; "apiVersion"?: string; "opcRequestId"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbHomeName",
        ], "deleteDbHome", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/dbHomes/{dbHomeName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbHomeName"}}`, `${ params["dbHomeName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteDbHome",
            httpMethod: "DELETE"
        });
    }
    /** 
     * DeletePluggableDatabase
     * Deletes the specified pluggable database.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deletePluggableDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "deletePluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/pluggableDatabases/{pluggableDatabaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deletePluggableDatabase",
            httpMethod: "DELETE"
        });
    }
    /** 
     * EnableObservability
     * Enable observability for a given database 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param databaseName The name of the database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public enableObservability(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: Database }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "enableObservability", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/databases/{databaseName}/enableObservability`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<Database>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "enableObservability",
            httpMethod: "POST"
        });
    }
    /** 
     * Gets information about the specified backup
     * Gets information about the specified backup
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param backupKey The name of the database node resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     */
    public getBackup(params: {  "subscriptionId": string; "resourceGroupName": string; "backupKey": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: Backup }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "backupKey",
        ], "getBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/backups/{backupKey}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"backupKey"}}`, `${ params["backupKey"] }`);

        return this.request<Backup>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getBackup",
            httpMethod: "GET"
        });
    }
    /** 
     * GetCloudExadataInfrastructure
     * Gets information about the specified cloud Exadata infrastructure resource. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param cloudExadataInfrastructureName The name of the cloud Exadata infrastructure resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getCloudExadataInfrastructure(params: {  "subscriptionId": string; "resourceGroupName": string; "cloudExadataInfrastructureName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: CloudExadataInfrastructure }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "cloudExadataInfrastructureName",
        ], "getCloudExadataInfrastructure", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudExadataInfrastructures/{cloudExadataInfrastructureName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"cloudExadataInfrastructureName"}}`, `${ params["cloudExadataInfrastructureName"] }`);

        return this.request<CloudExadataInfrastructure>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getCloudExadataInfrastructure",
            httpMethod: "GET"
        });
    }
    /** 
     * GetCloudVmCluster
     * Gets information about the specified cloud VM cluster. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param cloudVmClusterName The name of the cloud VM cluster resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getCloudVmCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "cloudVmClusterName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: CloudVmCluster }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "cloudVmClusterName",
        ], "getCloudVmCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudVmClusters/{cloudVmClusterName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"cloudVmClusterName"}}`, `${ params["cloudVmClusterName"] }`);

        return this.request<CloudVmCluster>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getCloudVmCluster",
            httpMethod: "GET"
        });
    }
    /** 
     * GetCloudVmClusterAvailableCapacity
     * Gets available capacity of the specified cloud VM cluster. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param cloudVmClusterName The name of the cloud VM cluster resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getCloudVmClusterAvailableCapacity(params: {  "subscriptionId": string; "resourceGroupName": string; "cloudVmClusterName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: CloudVmClusterAvailableCapacity }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "cloudVmClusterName",
        ], "getCloudVmClusterAvailableCapacity", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudVmClusters/{cloudVmClusterName}/availableCapacity`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"cloudVmClusterName"}}`, `${ params["cloudVmClusterName"] }`);

        return this.request<CloudVmClusterAvailableCapacity>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getCloudVmClusterAvailableCapacity",
            httpMethod: "GET"
        });
    }
    /** 
     * GetDatabase
     * Gets information about the specified database.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param databaseName The name of the database resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: Database }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "getDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<Database>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getDatabase",
            httpMethod: "GET"
        });
    }
    /** 
     * GetDbHome
     * Gets information about the specified Database Home.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param dbHomeName The name of the database home resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getDbHome(params: {  "subscriptionId": string; "resourceGroupName": string; "dbHomeName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DbHome }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbHomeName",
        ], "getDbHome", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/dbHomes/{dbHomeName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbHomeName"}}`, `${ params["dbHomeName"] }`);

        return this.request<DbHome>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getDbHome",
            httpMethod: "GET"
        });
    }
    /** 
     * GetDbNode
     * Gets information about the specified database node.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param dbNodeName The name of the database node resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getDbNode(params: {  "subscriptionId": string; "resourceGroupName": string; "dbNodeName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DbNode }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbNodeName",
        ], "getDbNode", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/dbNodes/{dbNodeName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbNodeName"}}`, `${ params["dbNodeName"] }`);

        return this.request<DbNode>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getDbNode",
            httpMethod: "GET"
        });
    }
    /** 
     * GetPluggableDatabase
     * Gets information about the specified pluggable database.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getPluggableDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: PluggableDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "getPluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/pluggableDatabases/{pluggableDatabaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request<PluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getPluggableDatabase",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of backups in the specified resource group.
     * Gets a list of backups in the specified resource group.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listBackups(params: {  "subscriptionId": string; "resourceGroupName": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: BackupCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listBackups", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<BackupCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listBackups",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of backups in the specified subscription.
     * Gets a list of backups in the specified subscription.
     * @param subscriptionId The ID of the target subscription.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listBackupsBySubscription(params: {  "subscriptionId": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: BackupCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listBackupsBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<BackupCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listBackupsBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListCloudExadataInfrastructures
     * Gets a list of the cloud Exadata infrastructure resources in the specified resource group. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     * @param sortBy The field to sort by. You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for TIMECREATED is descending.  Default order for DISPLAYNAME is ascending. The DISPLAYNAME sort order is case sensitive. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param lifecycleState A filter to return only resources that match the given lifecycle state exactly.
     * @param displayName A filter to return only resources that match the entire display name given. The match is not case sensitive.
     */
    public listCloudExadataInfrastructures(params: {  "subscriptionId": string; "resourceGroupName": string; "apiVersion"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; "sortBy"?: string; "sortOrder"?: string; "lifecycleState"?: string; "displayName"?: string; }, options?: any): Promise<{ response: Response, data: CloudExadataInfrastructureSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listCloudExadataInfrastructures", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudExadataInfrastructures`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<CloudExadataInfrastructureSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "lifecycleState": { values: params["lifecycleState"] },
                    "displayName": { values: params["displayName"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listCloudExadataInfrastructures",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of the cloud Exadata infrastructure resources in the specified subscription.
     * Gets a list of the cloud Exadata infrastructure resources in the specified subscription. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     * @param sortBy The field to sort by. You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for TIMECREATED is descending. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     */
    public listCloudExadataInfrastructuresBySubscription(params: {  "subscriptionId": string; "apiVersion"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; "sortBy"?: string; "sortOrder"?: string; }, options?: any): Promise<{ response: Response, data: CloudExadataInfrastructureSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listCloudExadataInfrastructuresBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/cloudExadataInfrastructures`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<CloudExadataInfrastructureSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listCloudExadataInfrastructuresBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListCloudVmClusters
     * Gets a list of the cloud VM clusters in the specified resource group. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by.  You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for TIMECREATED is descending.  Default order for DISPLAYNAME is ascending. The DISPLAYNAME sort order is case sensitive. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param lifecycleState A filter to return only cloud VM clusters that match the given lifecycle state exactly.
     * @param displayName A filter to return only resources that match the entire display name given. The match is not case sensitive.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listCloudVmClusters(params: {  "subscriptionId": string; "resourceGroupName": string; "apiVersion"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "lifecycleState"?: string; "displayName"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: CloudVmClusterSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listCloudVmClusters", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudVmClusters`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<CloudVmClusterSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "lifecycleState": { values: params["lifecycleState"] },
                    "displayName": { values: params["displayName"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listCloudVmClusters",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of the cloud VM clusters in the specified subscription.
     * Gets a list of the cloud VM clusters in the specified subscription. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by.  You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for TIMECREATED is descending. 
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listCloudVmClustersBySubscription(params: {  "subscriptionId": string; "apiVersion"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: CloudVmClusterSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listCloudVmClustersBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/cloudVmClusters`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<CloudVmClusterSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listCloudVmClustersBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of backups for the specified database.
     * Gets a list of backups for the specified database.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param databaseName The name of the database resource. The name needs to be unique in the resource group.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listDatabaseBackups(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: BackupSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "listDatabaseBackups", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/databases/{databaseName}/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<BackupSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDatabaseBackups",
            httpMethod: "GET"
        });
    }
    /** 
     * ListDatabases
     * Gets a list of the databases in the specified Database Home. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param vmClusterId The fully qualified Azure resource ID of the VM cluster.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by.  You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for TIMECREATED is descending.  Default order for DBNAME is ascending. The DBNAME sort order is case sensitive.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param lifecycleState A filter to return only resources that match the given lifecycle state exactly.
     * @param dbName A filter to return only resources that match the entire database name given. The match is not case sensitive.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listDatabases(params: {  "subscriptionId": string; "resourceGroupName": string; "vmClusterId"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "lifecycleState"?: string; "dbName"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DatabaseSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listDatabases", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<DatabaseSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "vmClusterId": { values: params["vmClusterId"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "lifecycleState": { values: params["lifecycleState"] },
                    "dbName": { values: params["dbName"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDatabases",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of the databases in the specified subscription.
     * Gets a list of the databases in the specified subscription. 
     * @param subscriptionId The ID of the target subscription.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by.  You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for TIMECREATED is descending.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listDatabasesBySubscription(params: {  "subscriptionId": string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DatabaseSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listDatabasesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<DatabaseSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDatabasesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListDbHomes
     * Lists the Database Homes in the specified resource group. A Database Home is a directory where Oracle Database software is installed. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param vmClusterId The fully qualified Azure resource ID of the VM cluster.
     * @param dbVersion A filter to return only DB Homes that match the specified dbVersion.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by.  You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for TIMECREATED is descending.  Default order for DISPLAYNAME is ascending. The DISPLAYNAME sort order is case sensitive.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param lifecycleState A filter to return only resources that match the given lifecycle state exactly.
     * @param displayName A filter to return only resources that match the entire display name given. The match is not case sensitive.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listDbHomes(params: {  "subscriptionId": string; "resourceGroupName": string; "vmClusterId"?: string; "dbVersion"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "lifecycleState"?: string; "displayName"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DbHomeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listDbHomes", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/dbHomes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<DbHomeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "vmClusterId": { values: params["vmClusterId"] },
                    "dbVersion": { values: params["dbVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "lifecycleState": { values: params["lifecycleState"] },
                    "displayName": { values: params["displayName"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDbHomes",
            httpMethod: "GET"
        });
    }
    /** 
     * Lists the Database Homes in the specified subscription.
     * Lists the Database Homes in the specified subscription. A Database Home is a directory where Oracle Database software is installed. 
     * @param subscriptionId The ID of the target subscription.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by.  You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for TIMECREATED is descending.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listDbHomesBySubscription(params: {  "subscriptionId": string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DbHomeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listDbHomesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/dbHomes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<DbHomeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDbHomesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListDbNodes
     * Lists the database nodes in the specified resource group. A database node is a server running database software. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param vmClusterId The fully qualified Azure resource ID of the VM cluster.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy Sort by TIMECREATED.  Default order for TIMECREATED is descending.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param lifecycleState A filter to return only resources that match the given lifecycle state exactly.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listDbNodes(params: {  "subscriptionId": string; "resourceGroupName": string; "vmClusterId"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "lifecycleState"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DbNodeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listDbNodes", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/dbNodes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<DbNodeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "vmClusterId": { values: params["vmClusterId"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "lifecycleState": { values: params["lifecycleState"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDbNodes",
            httpMethod: "GET"
        });
    }
    /** 
     * Lists the database nodes in the specified subscription.
     * Lists the database nodes in the specified subscription. A database node is a server running database software. 
     * @param subscriptionId The ID of the target subscription.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy Sort by TIMECREATED.  Default order for TIMECREATED is descending.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listDbNodesBySubscription(params: {  "subscriptionId": string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: DbNodeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listDbNodesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/dbNodes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<DbNodeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDbNodesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListDbSystemShapes
     * Gets a list of the shapes that can be used to launch a new DB system. The shape determines resources to allocate to the DB system - CPU cores and memory for VM shapes; CPU cores, memory and storage for non-VM (or bare metal) shapes.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listDbSystemShapes(params: {  "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; }, options?: any): Promise<{ response: Response, data: DbSystemShapeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listDbSystemShapes", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/dbSystemShapes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<DbSystemShapeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDbSystemShapes",
            httpMethod: "GET"
        });
    }
    /** 
     * ListDbSystemShapesBySubscription
     * Gets a list of the shapes that can be used to launch a new DB system in the specified subscription. The shape determines resources to allocate to the DB system - CPU cores and memory for VM shapes; CPU cores, memory and storage for non-VM (or bare metal) shapes.
     * @param subscriptionId The ID of the target subscription.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listDbSystemShapesBySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; }, options?: any): Promise<{ response: Response, data: DbSystemShapeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listDbSystemShapesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/dbSystemShapes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<DbSystemShapeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDbSystemShapesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListDbVersions
     * Gets a list of supported Oracle Database versions.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param dbSystemShape If provided, filters the results to the set of database versions which are supported for the given shape.
     * @param isUpgradeSupported If provided, filters the results to the set of database versions which are supported for Upgrade.
     * @param isDatabaseSoftwareImageSupported If true, filters the results to the set of Oracle Database versions that are supported for OCI database software images.
     */
    public listDbVersions(params: {  "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; "dbSystemShape"?: string; "isUpgradeSupported"?: boolean; "isDatabaseSoftwareImageSupported"?: boolean; }, options?: any): Promise<{ response: Response, data: DbVersionSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listDbVersions", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/dbVersions`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<DbVersionSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "dbSystemShape": { values: params["dbSystemShape"] },
                    "isUpgradeSupported": { values: params["isUpgradeSupported"] },
                    "isDatabaseSoftwareImageSupported": { values: params["isDatabaseSoftwareImageSupported"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDbVersions",
            httpMethod: "GET"
        });
    }
    /** 
     * ListDbVersionsBySubscription
     * Gets a list of supported Oracle Database versions in the specified subscription.
     * @param subscriptionId The ID of the target subscription.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param dbSystemShape If provided, filters the results to the set of database versions which are supported for the given shape.
     * @param isUpgradeSupported If provided, filters the results to the set of database versions which are supported for Upgrade.
     * @param isDatabaseSoftwareImageSupported If true, filters the results to the set of Oracle Database versions that are supported for OCI database software images.
     */
    public listDbVersionsBySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; "dbSystemShape"?: string; "isUpgradeSupported"?: boolean; "isDatabaseSoftwareImageSupported"?: boolean; }, options?: any): Promise<{ response: Response, data: DbVersionSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listDbVersionsBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/dbVersions`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<DbVersionSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "dbSystemShape": { values: params["dbSystemShape"] },
                    "isUpgradeSupported": { values: params["isUpgradeSupported"] },
                    "isDatabaseSoftwareImageSupported": { values: params["isDatabaseSoftwareImageSupported"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDbVersionsBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListFlexComponents
     * Gets a list of the flex components that can be used to launch a new DB system. The flex component determines resources to allocate to the DB system - Database Servers and Storage Servers.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param name A filter to return only resources that match the entire name given. The match is not case sensitive.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param sortBy The field to sort by.  You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for NAME is ascending. The NAME sort order is case sensitive. 
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listFlexComponents(params: {  "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "apiVersion"?: string; "name"?: string; "sortOrder"?: string; "sortBy"?: string; "limit"?: number; "page"?: string; }, options?: any): Promise<{ response: Response, data: FlexComponentCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listFlexComponents", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/dbSystemShapes/flexComponents`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<FlexComponentCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "name": { values: params["name"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "sortBy": { values: params["sortBy"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listFlexComponents",
            httpMethod: "GET"
        });
    }
    /** 
     * ListFlexComponentsBySubscription
     * Gets a list of the flex components that can be used to launch a new DB system in the specified subscription. The flex component determines resources to allocate to the DB system - Database Servers and Storage Servers.
     * @param subscriptionId The ID of the target subscription.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param name A filter to return only resources that match the entire name given. The match is not case sensitive.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param sortBy The field to sort by.  You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for NAME is ascending. The NAME sort order is case sensitive. 
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listFlexComponentsBySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; "apiVersion"?: string; "name"?: string; "sortOrder"?: string; "sortBy"?: string; "limit"?: number; "page"?: string; }, options?: any): Promise<{ response: Response, data: FlexComponentCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listFlexComponentsBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/dbSystemShapes/flexComponents`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<FlexComponentCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "name": { values: params["name"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "sortBy": { values: params["sortBy"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listFlexComponentsBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListGiVersions
     * Gets a list of supported GI versions for the Exadata Cloud VM cluster.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param shape If provided, filters the results for the given shape.
     */
    public listGiVersions(params: {  "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; "sortOrder"?: string; "shape"?: string; }, options?: any): Promise<{ response: Response, data: GiVersionSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listGiVersions", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/giVersions`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<GiVersionSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "shape": { values: params["shape"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listGiVersions",
            httpMethod: "GET"
        });
    }
    /** 
     * ListGiVersionsBySubscription
     * Gets a list of supported GI versions for the Exadata Cloud VM cluster in the specified subscription.
     * @param subscriptionId The ID of the target subscription.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param shape If provided, filters the results for the given shape.
     */
    public listGiVersionsBySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; "sortOrder"?: string; "shape"?: string; }, options?: any): Promise<{ response: Response, data: GiVersionSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listGiVersionsBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/giVersions`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<GiVersionSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "shape": { values: params["shape"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listGiVersionsBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListPluggableDatabases
     * Gets a list of the pluggable databases in a database or resource group. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param opcRequestId Unique identifier for the request. 
     * @param databaseId The fully qualified Azure resource ID of the database.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by.  You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for TIMECREATED is descending.  Default order for PDBNAME is ascending. The PDBNAME sort order is case sensitive.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param lifecycleState A filter to return only resources that match the given lifecycle state exactly.
     * @param pdbName A filter to return only pluggable databases that match the entire name given. The match is not case sensitive.
     * @param apiVersion The API version to use for this operation.
     */
    public listPluggableDatabases(params: {  "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "databaseId"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "lifecycleState"?: string; "pdbName"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: PluggableDatabaseSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listPluggableDatabases", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/pluggableDatabases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<PluggableDatabaseSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "databaseId": { values: params["databaseId"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "lifecycleState": { values: params["lifecycleState"] },
                    "pdbName": { values: params["pdbName"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listPluggableDatabases",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of the pluggable databases in the specified subscription.
     * Gets a list of the pluggable databases in the specified subscription. 
     * @param subscriptionId The ID of the target subscription.
     * @param opcRequestId Unique identifier for the request. 
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param sortBy The field to sort by.  You can provide one sort order (&#x60;sortOrder&#x60;).  Default order for TIMECREATED is descending.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param apiVersion The API version to use for this operation.
     */
    public listPluggableDatabasesBySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; "limit"?: number; "page"?: string; "sortBy"?: string; "sortOrder"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: PluggableDatabaseSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listPluggableDatabasesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Database/pluggableDatabases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<PluggableDatabaseSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "sortBy": { values: params["sortBy"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listPluggableDatabasesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * LocalClonePluggableDatabase
     * Local clone pluggable database.
     * @param localClonePluggableDatabaseDetails Request to create local clone pluggable database.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param apiVersion The API version to use for this operation.
     */
    public localClonePluggableDatabase(params: {  "localClonePluggableDatabaseDetails": LocalClonePluggableDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: PluggableDatabase }> {
        base.validateRequiredParameters([
            "localClonePluggableDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "localClonePluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/pluggableDatabases/{pluggableDatabaseName}/localClone`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request<PluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["localClonePluggableDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "localClonePluggableDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * RestoreDatabase
     * Restore a Database based on the request parameters you provide. 
     * @param restoreDatabaseDetails Request to perform database restore.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param databaseName The name of the database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public restoreDatabase(params: {  "restoreDatabaseDetails": RestoreDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: Database }> {
        base.validateRequiredParameters([
            "restoreDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "restoreDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/databases/{databaseName}/restore`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<Database>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["restoreDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "restoreDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * StartPluggableDatabase
     * Starts a stopped pluggable database. The &#x60;openMode&#x60; value of the pluggable database will be &#x60;READ_WRITE&#x60; upon completion.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param apiVersion The API version to use for this operation.
     */
    public startPluggableDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: PluggableDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "startPluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/pluggableDatabases/{pluggableDatabaseName}/start`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request<PluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },


            parseResponseBody: true,

            operationName: "startPluggableDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * StopPluggableDatabase
     * Stops a pluggable database. The &#x60;openMode&#x60; value of the pluggable database will be &#x60;MOUNTED&#x60; upon completion.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param apiVersion The API version to use for this operation.
     */
    public stopPluggableDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: PluggableDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "stopPluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/pluggableDatabases/{pluggableDatabaseName}/stop`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request<PluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },


            parseResponseBody: true,

            operationName: "stopPluggableDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * Updates the specified cloud Exadata Infrastructure resource.
     * Updates the Cloud Exadata infrastructure resource. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param cloudExadataInfrastructureName The name of the cloud Exadata infrastructure resource. The name needs to be unique in the resource group.
     * @param updateCloudExadataInfrastructureDetails Request to update the properties of an cloud Exadata infrastructure resource.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public updateCloudExadataInfrastructure(params: {  "subscriptionId": string; "resourceGroupName": string; "cloudExadataInfrastructureName": string; "updateCloudExadataInfrastructureDetails": UpdateCloudExadataInfrastructureDetails; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: CloudExadataInfrastructure }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "cloudExadataInfrastructureName",
            "updateCloudExadataInfrastructureDetails",
        ], "updateCloudExadataInfrastructure", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudExadataInfrastructures/{cloudExadataInfrastructureName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"cloudExadataInfrastructureName"}}`, `${ params["cloudExadataInfrastructureName"] }`);

        return this.request<CloudExadataInfrastructure>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateCloudExadataInfrastructureDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateCloudExadataInfrastructure",
            httpMethod: "PUT"
        });
    }
    /** 
     * Updates the specified cloud VM cluster.
     * Updates the specified cloud VM cluster. Applies to Exadata Cloud Service instances and Autonomous Database on dedicated Exadata infrastructure only. 
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param cloudVmClusterName The name of the cloud VM cluster resource. The name needs to be unique in the resource group.
     * @param updateCloudVmClusterDetails Request to update the attributes of a cloud VM cluster.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public updateCloudVmCluster(params: {  "subscriptionId": string; "resourceGroupName": string; "cloudVmClusterName": string; "updateCloudVmClusterDetails": UpdateCloudVmClusterDetails; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: CloudVmCluster }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "cloudVmClusterName",
            "updateCloudVmClusterDetails",
        ], "updateCloudVmCluster", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/cloudVmClusters/{cloudVmClusterName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"cloudVmClusterName"}}`, `${ params["cloudVmClusterName"] }`);

        return this.request<CloudVmCluster>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateCloudVmClusterDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateCloudVmCluster",
            httpMethod: "PUT"
        });
    }
    /** 
     * UpdateDatabase
     * Update the specified database based on the request parameters provided. 
     * @param updateDatabaseDetails Request to perform database update.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param databaseName The name of the database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public updateDatabase(params: {  "updateDatabaseDetails": UpdateDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: Database }> {
        base.validateRequiredParameters([
            "updateDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "updateDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<Database>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateDatabase",
            httpMethod: "PUT"
        });
    }
    /** 
     * UpdatePluggableDatabase
     * Update the specified pluggable database based on the request parameters provided. 
     * @param updatePluggableDatabaseDetails Request to perform database update.
     * @param subscriptionId The ID of the target subscription.
     * @param resourceGroupName The name of the resource group. The name is case insensitive.
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public updatePluggableDatabase(params: {  "updatePluggableDatabaseDetails": UpdatePluggableDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: PluggableDatabase }> {
        base.validateRequiredParameters([
            "updatePluggableDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "updatePluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Database/pluggableDatabases/{pluggableDatabaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request<PluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updatePluggableDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "updatePluggableDatabase",
            httpMethod: "PUT"
        });
    }
};

export interface CreateNetworkLinkArgs {
     "createNetworkLinkDetails": CreateNetworkLinkDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type CreateNetworkLinkReturnType = { response: Response, data: NetworkLink };

export interface DeleteNetworkLinkArgs {
     "networkLinkName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteNetworkLinkReturnType = Response;

export interface GetNetworkLinkArgs {
     "networkLinkName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetNetworkLinkReturnType = { response: Response, data: NetworkLink };

export interface ListNetworkLinksArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "status"?: string;
     "name"?: string;
     "sortOrder"?: string;
     "sortBy"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListNetworkLinksReturnType = { response: Response, data: NetworkLinkCollection };

export interface ListNetworkLinksInSubscriptionArgs {
     "subscriptionId": string;
     "status"?: string;
     "name"?: string;
     "sortOrder"?: string;
     "sortBy"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListNetworkLinksInSubscriptionReturnType = { response: Response, data: NetworkLinkCollection };


/**
 * NetworkLinkApi - object-oriented interface
 */
export class NetworkLinkApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): NetworkLinkApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new NetworkLinkApi(fetch, endpoint, config);
    }

    /** 
     * Creates a new NetworkLink
     * Creates a new NetworkLink. 
     * @param createNetworkLinkDetails Details for the new NetworkLink.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public createNetworkLink(params: {  "createNetworkLinkDetails": CreateNetworkLinkDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: NetworkLink }> {
        base.validateRequiredParameters([
            "createNetworkLinkDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createNetworkLink", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Network/networkLinks`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<NetworkLink>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createNetworkLinkDetails"]
                    },

            parseResponseBody: true,

            operationName: "createNetworkLink",
            httpMethod: "POST"
        });
    }
    /** 
     * Delete a provisioned NetworkLink
     * Deletes a NetworkLink resource by identifier
     * @param networkLinkName unique NetworkLink identifier
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteNetworkLink(params: {  "networkLinkName": string; "subscriptionId": string; "resourceGroupName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "networkLinkName",
            "subscriptionId",
            "resourceGroupName",
        ], "deleteNetworkLink", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Network/networkLinks/{networkLinkName}`
            .replace(`{${"networkLinkName"}}`, `${ params["networkLinkName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteNetworkLink",
            httpMethod: "DELETE"
        });
    }
    /** 
     * Get NetworkLink
     * Gets a NetworkLink by identifier
     * @param networkLinkName unique NetworkLink identifier
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getNetworkLink(params: {  "networkLinkName": string; "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: NetworkLink }> {
        base.validateRequiredParameters([
            "networkLinkName",
            "subscriptionId",
            "resourceGroupName",
        ], "getNetworkLink", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Network/networkLinks/{networkLinkName}`
            .replace(`{${"networkLinkName"}}`, `${ params["networkLinkName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<NetworkLink>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getNetworkLink",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all NetworkLinks in a resourceGroup.
     * Returns a list of NetworkLinks. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param status A filter to return only resources their lifecycleState matches the given lifecycleState.
     * @param name A filter to return only resources that match the entire display name given.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listNetworkLinks(params: {  "subscriptionId": string; "resourceGroupName": string; "status"?: string; "name"?: string; "sortOrder"?: string; "sortBy"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: NetworkLinkCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listNetworkLinks", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Network/networkLinks`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<NetworkLinkCollection>({
            options,
            path,

            queryParameters: {
                    "status": { values: params["status"] },
                    "name": { values: params["name"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "sortBy": { values: params["sortBy"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listNetworkLinks",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of all NetworkLinks in a subscription
     * Returns a list of NetworkLinks. 
     * @param subscriptionId subscription identifier
     * @param status A filter to return only resources their lifecycleState matches the given lifecycleState.
     * @param name A filter to return only resources that match the entire display name given.
     * @param sortOrder The sort order to use, either &#39;ASC&#39; or &#39;DESC&#39;.
     * @param sortBy The field to sort by. Only one sort order may be provided. Default order for timeCreated is descending. Default order for displayName is ascending. 
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listNetworkLinksInSubscription(params: {  "subscriptionId": string; "status"?: string; "name"?: string; "sortOrder"?: string; "sortBy"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: NetworkLinkCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listNetworkLinksInSubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Network/networkLinks`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<NetworkLinkCollection>({
            options,
            path,

            queryParameters: {
                    "status": { values: params["status"] },
                    "name": { values: params["name"] },
                    "sortOrder": { values: params["sortOrder"] },
                    "sortBy": { values: params["sortBy"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listNetworkLinksInSubscription",
            httpMethod: "GET"
        });
    }
};

export interface ListRegionsInfoArgs {
     "regionName"?: string;
     "opcRequestId"?: string;
     "page"?: string;
     "limit"?: number
}
export type ListRegionsInfoReturnType = { response: Response, data: RegionInfoCollection };


/**
 * RegionsInfoApi - object-oriented interface
 */
export class RegionsInfoApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): RegionsInfoApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new RegionsInfoApi(fetch, endpoint, config);
    }

    /** 
     * List all available regions details
     * List all available Azure regions along with their details 
     * @param regionName Azure region name.
     * @param opcRequestId The client request ID for tracing.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param limit The maximum number of items to return.
     */
    public listRegionsInfo(params: {  "regionName"?: string; "opcRequestId"?: string; "page"?: string; "limit"?: number; }, options?: any): Promise<{ response: Response, data: RegionInfoCollection }> {
        base.validateRequiredParameters([
        ], "listRegionsInfo", params);

        const path = `${this.basePath}/regionsInfo`;

        return this.request<RegionInfoCollection>({
            options,
            path,

            queryParameters: {
                    "regionName": { values: params["regionName"] },
                    "page": { values: params["page"] },
                    "limit": { values: params["limit"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listRegionsInfo",
            httpMethod: "GET"
        });
    }
};

export interface CreateVmdbBackupArgs {
     "createBackupDetails": VmdbCreateBackupDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type CreateVmdbBackupReturnType = { response: Response, data: VmdbBackup };

export interface CreateVmdbDbSystemArgs {
     "createDbSystemDetails": CreateVmdbDbSystemBase;
     "subscriptionId": string;
     "resourceGroupName": string;
     "apiVersion"?: string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type CreateVmdbDbSystemReturnType = { response: Response, data: VmdbDbSystem };

export interface CreateVmdbPluggableDatabaseArgs {
     "createVmdbPluggableDatabaseDetails": CreateVmdbPluggableDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type CreateVmdbPluggableDatabaseReturnType = { response: Response, data: VmdbPluggableDatabase };

export interface DeleteVmdbBackupArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "backupKey": string;
     "ifMatch"?: string;
     "opcRequestId"?: string
}
export type DeleteVmdbBackupReturnType = Response;

export interface DeleteVmdbDbSystemArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteVmdbDbSystemReturnType = Response;

export interface DeleteVmdbPluggableDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type DeleteVmdbPluggableDatabaseReturnType = Response;

export interface GetVmScaleStorageDiskOptionArgs {
     "dbSystemName": string;
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetVmScaleStorageDiskOptionReturnType = { response: Response, data: VmScaleStorageDiskOption };

export interface GetVmdbBackupArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "backupKey": string;
     "opcRequestId"?: string
}
export type GetVmdbBackupReturnType = { response: Response, data: VmdbBackup };

export interface GetVmdbDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetVmdbDatabaseReturnType = { response: Response, data: VmdbDatabase };

export interface GetVmdbDbHomeArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbHomeName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetVmdbDbHomeReturnType = { response: Response, data: VmdbDbHome };

export interface GetVmdbDbNodeArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbNodeName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetVmdbDbNodeReturnType = { response: Response, data: VmdbDbNode };

export interface GetVmdbDbSystemArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetVmdbDbSystemReturnType = { response: Response, data: VmdbDbSystem };

export interface GetVmdbPluggableDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type GetVmdbPluggableDatabaseReturnType = { response: Response, data: VmdbPluggableDatabase };

export interface ListDbSystemInitialStorageSizesArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string
}
export type ListDbSystemInitialStorageSizesReturnType = { response: Response, data: DbSystemInitialStorageSizeSummaryCollection };

export interface ListVmdbBackupsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListVmdbBackupsReturnType = { response: Response, data: VmdbBackupSummaryCollection };

export interface ListVmdbBackupsBySubscriptionArgs {
     "subscriptionId": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListVmdbBackupsBySubscriptionReturnType = { response: Response, data: VmdbBackupSummaryCollection };

export interface ListVmdbDatabaseBackupsArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string
}
export type ListVmdbDatabaseBackupsReturnType = { response: Response, data: VmdbBackupSummaryCollection };

export interface ListVmdbDatabasesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbHomeId"?: string;
     "limit"?: number;
     "page"?: string;
     "dbName"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListVmdbDatabasesReturnType = { response: Response, data: VmdbDatabaseSummaryCollection };

export interface ListVmdbDatabasesBySubscriptionArgs {
     "subscriptionId": string;
     "dbHomeId"?: string;
     "limit"?: number;
     "page"?: string;
     "dbName"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListVmdbDatabasesBySubscriptionReturnType = { response: Response, data: VmdbDatabaseSummaryCollection };

export interface ListVmdbDbHomesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemId"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListVmdbDbHomesReturnType = { response: Response, data: VmdbDbHomeSummaryCollection };

export interface ListVmdbDbHomesBySubscriptionArgs {
     "subscriptionId": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListVmdbDbHomesBySubscriptionReturnType = { response: Response, data: VmdbDbHomeSummaryCollection };

export interface ListVmdbDbNodesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemId"?: string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListVmdbDbNodesReturnType = { response: Response, data: VmdbDbNodeSummaryCollection };

export interface ListVmdbDbNodesBySubscriptionArgs {
     "subscriptionId": string;
     "limit"?: number;
     "page"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type ListVmdbDbNodesBySubscriptionReturnType = { response: Response, data: VmdbDbNodeSummaryCollection };

export interface ListVmdbDbSystemShapesArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string
}
export type ListVmdbDbSystemShapesReturnType = { response: Response, data: VmdbDbSystemShapeSummaryCollection };

export interface ListVmdbDbSystemsArgs {
     "resourceGroupName": string;
     "subscriptionId": string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string;
     "name"?: string
}
export type ListVmdbDbSystemsReturnType = { response: Response, data: VmdbDbSystemCollection };

export interface ListVmdbDbSystemsBySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string;
     "name"?: string;
     "apiVersion"?: string
}
export type ListVmdbDbSystemsBySubscriptionReturnType = { response: Response, data: VmdbDbSystemCollection };

export interface ListVmdbDbVersionsArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string;
     "dbSystemShape"?: string;
     "isUpgradeSupported"?: boolean;
     "storageManagement"?: string
}
export type ListVmdbDbVersionsReturnType = { response: Response, data: VmdbDbVersionSummaryCollection };

export interface ListVmdbMinorVersionsArgs {
     "dbVersion": string;
     "opcRequestId"?: string;
     "apiVersion"?: string;
     "limit"?: number;
     "page"?: string
}
export type ListVmdbMinorVersionsReturnType = { response: Response, data: VmdbDbMinorVersionSummaryCollection };

export interface ListVmdbPluggableDatabasesArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "opcRequestId"?: string;
     "databaseId"?: string;
     "limit"?: number;
     "page"?: string;
     "name"?: string;
     "apiVersion"?: string
}
export type ListVmdbPluggableDatabasesReturnType = { response: Response, data: VmdbPluggableDatabaseSummaryCollection };

export interface ListVmdbPluggableDatabasesBySubscriptionArgs {
     "subscriptionId": string;
     "opcRequestId"?: string;
     "limit"?: number;
     "page"?: string;
     "name"?: string;
     "apiVersion"?: string
}
export type ListVmdbPluggableDatabasesBySubscriptionReturnType = { response: Response, data: VmdbPluggableDatabaseSummaryCollection };

export interface LocalVmdbClonePluggableDatabaseArgs {
     "localClonePluggableDatabaseDetails": VmdbLocalClonePluggableDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string
}
export type LocalVmdbClonePluggableDatabaseReturnType = { response: Response, data: VmdbPluggableDatabase };

export interface RestoreVmdbDatabaseArgs {
     "databaseName": string;
     "restoreVmdbDatabaseDetails": RestoreVmdbDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "ifMatch"?: string;
     "apiVersion"?: string;
     "opcRetryToken"?: string;
     "opcRequestId"?: string
}
export type RestoreVmdbDatabaseReturnType = { response: Response, data: VmdbDatabase };

export interface SoftResetVmdbDbNodeArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbNodeName": string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "ifMatch"?: string;
     "apiVersion"?: string
}
export type SoftResetVmdbDbNodeReturnType = { response: Response, data: VmdbDbNode };

export interface StartVmdbDbNodeArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbNodeName": string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "ifMatch"?: string;
     "apiVersion"?: string
}
export type StartVmdbDbNodeReturnType = { response: Response, data: VmdbDbNode };

export interface StartVmdbPluggableDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "apiVersion"?: string
}
export type StartVmdbPluggableDatabaseReturnType = { response: Response, data: VmdbPluggableDatabase };

export interface StopVmdbDbNodeArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbNodeName": string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "ifMatch"?: string;
     "apiVersion"?: string
}
export type StopVmdbDbNodeReturnType = { response: Response, data: VmdbDbNode };

export interface StopVmdbPluggableDatabaseArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "opcRetryToken"?: string;
     "apiVersion"?: string
}
export type StopVmdbPluggableDatabaseReturnType = { response: Response, data: VmdbPluggableDatabase };

export interface UpdateVmdbDatabaseArgs {
     "updateDatabaseDetails": UpdateVmdbDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "databaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type UpdateVmdbDatabaseReturnType = { response: Response, data: VmdbDatabase };

export interface UpdateVmdbDbSystemArgs {
     "subscriptionId": string;
     "resourceGroupName": string;
     "dbSystemName": string;
     "updateDbSystemDetails": UpdateVmdbDbSystemDetails;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type UpdateVmdbDbSystemReturnType = { response: Response, data: VmdbDbSystem };

export interface UpdateVmdbPluggableDatabaseArgs {
     "updateVmdbPluggableDatabaseDetails": UpdateVmdbPluggableDatabaseDetails;
     "subscriptionId": string;
     "resourceGroupName": string;
     "pluggableDatabaseName": string;
     "ifMatch"?: string;
     "opcRequestId"?: string;
     "apiVersion"?: string
}
export type UpdateVmdbPluggableDatabaseReturnType = { response: Response, data: VmdbPluggableDatabase };


/**
 * VmDatabaseApi - object-oriented interface
 */
export class VmDatabaseApi extends base.BaseAPI {

    private static buildEndpointFromTemplate(
        template: string,
        basePath: string,
        region: string,
        secondLevelDomain: string
    ): string {
        // remove any trailing slashes in the basePath or we'll end up
        // with two slashes in a row as paths begin with '/'
        const normalizedBasePath = (basePath || "").replace(/\/$/, "");
        const base = template
            .replace("{region}", region)
            .replace("{secondLevelDomain}", secondLevelDomain)
            .replace("{basePath}", normalizedBasePath);

        return base;
    }

    public static createFromEndpointTemplate(fetch: base.Fetch, region: string, secondLevelDomain: string, config?: base.BaseApiConfig, template?: string): VmDatabaseApi {
        const endpoint = this.buildEndpointFromTemplate(
            template || "https://mchub-azure-proxy.{region}.oci.{secondLevelDomain}",
            "/",
            region,
            secondLevelDomain
        );

        return new VmDatabaseApi(fetch, endpoint, config);
    }

    /** 
     * CreateVmdbBackup
     * Creates a new backup in the specified database based on the request parameters you provide. If you previously used RMAN or dbcli to configure backups and then you switch to using the Console or the API for backups, a new backup configuration is created and associated with your database. This means that you can no longer rely on your previously configured unmanaged backups to work. 
     * @param createBackupDetails Request to create a new database backup.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public createVmdbBackup(params: {  "createBackupDetails": VmdbCreateBackupDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: VmdbBackup }> {
        base.validateRequiredParameters([
            "createBackupDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createVmdbBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<VmdbBackup>({
            options,
            path,


            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createBackupDetails"]
                    },

            parseResponseBody: true,

            operationName: "createVmdbBackup",
            httpMethod: "POST"
        });
    }
    /** 
     * CreateVmdbDbSystem
     * Creates a new DB system in the specified compartment and availability domain. The Oracle Database edition that you specify applies to all the databases on that DB system. The selected edition cannot be changed. 
     * @param createDbSystemDetails Request to create a new DbSystem.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param apiVersion The API version to use for this operation.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public createVmdbDbSystem(params: {  "createDbSystemDetails": CreateVmdbDbSystemBase; "subscriptionId": string; "resourceGroupName": string; "apiVersion"?: string; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbSystem }> {
        base.validateRequiredParameters([
            "createDbSystemDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createVmdbDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbSystems`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<VmdbDbSystem>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createDbSystemDetails"]
                    },

            parseResponseBody: true,

            operationName: "createVmdbDbSystem",
            httpMethod: "POST"
        });
    }
    /** 
     * CreateVmdbPluggableDatabase
     * Creates and starts a pluggable database in the specified container database. Use the [StartPluggableDatabase](#/en/database/latest/PluggableDatabase/StartPluggableDatabase) and [StopPluggableDatabase](#/en/database/latest/PluggableDatabase/StopPluggableDatabase) APIs to start and stop the pluggable database. 
     * @param createVmdbPluggableDatabaseDetails Request to create pluggable database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public createVmdbPluggableDatabase(params: {  "createVmdbPluggableDatabaseDetails": CreateVmdbPluggableDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "opcRetryToken"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbPluggableDatabase }> {
        base.validateRequiredParameters([
            "createVmdbPluggableDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "createVmdbPluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/pluggableDatabases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<VmdbPluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["createVmdbPluggableDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "createVmdbPluggableDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * DeleteVmdbBackup
     * Deletes a full backup. You cannot delete automatic backups using this API.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param backupKey The name of the database node resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public deleteVmdbBackup(params: {  "subscriptionId": string; "resourceGroupName": string; "backupKey": string; "ifMatch"?: string; "opcRequestId"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "backupKey",
        ], "deleteVmdbBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/backups/{backupKey}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"backupKey"}}`, `${ params["backupKey"] }`);

        return this.request({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteVmdbBackup",
            httpMethod: "DELETE"
        });
    }
    /** 
     * DeleteVmdbDbSystem
     * Terminates a DB system and permanently deletes it and any databases running on it, and any storage volumes attached to it. The database data is local to the DB system and will be lost when the system is terminated. Oracle recommends that you back up any data in the DB system prior to terminating it. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the VMDB resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteVmdbDbSystem(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "deleteVmdbDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbSystems/{dbSystemName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteVmdbDbSystem",
            httpMethod: "DELETE"
        });
    }
    /** 
     * DeleteVmdbPluggableDatabase
     * Deletes the specified pluggable database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public deleteVmdbPluggableDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<Response> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "deleteVmdbPluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/pluggableDatabases/{pluggableDatabaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },



            operationName: "deleteVmdbPluggableDatabase",
            httpMethod: "DELETE"
        });
    }
    /** 
     * GetVmScaleStorageDiskOption
     * Lists the possible data and reco storage disk size options for VM DB systems.
     * @param dbSystemName The name of the VMDB resource. The name needs to be unique in the resource group.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getVmScaleStorageDiskOption(params: {  "dbSystemName": string; "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmScaleStorageDiskOption }> {
        base.validateRequiredParameters([
            "dbSystemName",
            "subscriptionId",
            "resourceGroupName",
        ], "getVmScaleStorageDiskOption", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbSystems/{dbSystemName}/vmScaleStorageDiskOptions`
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<VmScaleStorageDiskOption>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getVmScaleStorageDiskOption",
            httpMethod: "GET"
        });
    }
    /** 
     * GetVmdbBackup
     * Gets information about the specified backup.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param backupKey The name of the database node resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     */
    public getVmdbBackup(params: {  "subscriptionId": string; "resourceGroupName": string; "backupKey": string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: VmdbBackup }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "backupKey",
        ], "getVmdbBackup", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/backups/{backupKey}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"backupKey"}}`, `${ params["backupKey"] }`);

        return this.request<VmdbBackup>({
            options,
            path,


            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getVmdbBackup",
            httpMethod: "GET"
        });
    }
    /** 
     * GetVmdbDatabase
     * Gets information about the specified database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the database resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getVmdbDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "getVmdbDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<VmdbDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getVmdbDatabase",
            httpMethod: "GET"
        });
    }
    /** 
     * GetVmdbDbHome
     * Gets information about the specified Database Home.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbHomeName The name of the database home resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getVmdbDbHome(params: {  "subscriptionId": string; "resourceGroupName": string; "dbHomeName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbHome }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbHomeName",
        ], "getVmdbDbHome", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbHomes/{dbHomeName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbHomeName"}}`, `${ params["dbHomeName"] }`);

        return this.request<VmdbDbHome>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getVmdbDbHome",
            httpMethod: "GET"
        });
    }
    /** 
     * GetVmdbDbNode
     * Gets information about the specified database node.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbNodeName The name of the database node resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getVmdbDbNode(params: {  "subscriptionId": string; "resourceGroupName": string; "dbNodeName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbNode }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbNodeName",
        ], "getVmdbDbNode", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbNodes/{dbNodeName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbNodeName"}}`, `${ params["dbNodeName"] }`);

        return this.request<VmdbDbNode>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getVmdbDbNode",
            httpMethod: "GET"
        });
    }
    /** 
     * GetVmdbDbSystem
     * Gets DbSystem based on dbSystemName
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the VMDB resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getVmdbDbSystem(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbSystem }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
        ], "getVmdbDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbSystems/{dbSystemName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request<VmdbDbSystem>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getVmdbDbSystem",
            httpMethod: "GET"
        });
    }
    /** 
     * GetVmdbPluggableDatabase
     * Gets information about the specified pluggable database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public getVmdbPluggableDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbPluggableDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "getVmdbPluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/pluggableDatabases/{pluggableDatabaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request<VmdbPluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "getVmdbPluggableDatabase",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets list of DbSystemInitalStorageSizes
     * Gets a list of initial storage sizes for RECO and DATA volumes in different types of VMDB Systems. 
     * @param subscriptionId subscription identifier
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listDbSystemInitialStorageSizes(params: {  "subscriptionId": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; }, options?: any): Promise<{ response: Response, data: DbSystemInitialStorageSizeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listDbSystemInitialStorageSizes", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Vmdb/dbSystemInitialStorageSizes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<DbSystemInitialStorageSizeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listDbSystemInitialStorageSizes",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbBackups
     * Gets a list of backups based on the &#x60;databaseId&#x60; specified. Either one of these query parameters must be provided. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listVmdbBackups(params: {  "subscriptionId": string; "resourceGroupName": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: VmdbBackupSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listVmdbBackups", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<VmdbBackupSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbBackups",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbBackupsBySubscription
     * Gets a list of backups based on the subscription id specified. Either one of these query parameters must be provided. 
     * @param subscriptionId subscription identifier
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listVmdbBackupsBySubscription(params: {  "subscriptionId": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: VmdbBackupSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listVmdbBackupsBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Vmdb/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<VmdbBackupSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbBackupsBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * Gets a list of backups for the specified database.
     * Gets a list of backups for the specified database.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the database resource. The name needs to be unique in the resource group.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     */
    public listVmdbDatabaseBackups(params: {  "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: VmdbBackupSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "listVmdbDatabaseBackups", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/databases/{databaseName}/backups`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<VmdbBackupSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDatabaseBackups",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbDatabases
     * Gets a list of the databases in the specified Database Home. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbHomeId The fully qualified Azure resource ID of the DB Home.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param dbName A filter to return only resources that match the entire database name given. The match is not case sensitive.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listVmdbDatabases(params: {  "subscriptionId": string; "resourceGroupName": string; "dbHomeId"?: string; "limit"?: number; "page"?: string; "dbName"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDatabaseSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listVmdbDatabases", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<VmdbDatabaseSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "dbHomeId": { values: params["dbHomeId"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "dbName": { values: params["dbName"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDatabases",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbDatabasesBySubscription
     * Gets a list of the databases in the specified Database Home and specified subscription. 
     * @param subscriptionId subscription identifier
     * @param dbHomeId The fully qualified Azure resource ID of the DB Home.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param dbName A filter to return only resources that match the entire database name given. The match is not case sensitive.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listVmdbDatabasesBySubscription(params: {  "subscriptionId": string; "dbHomeId"?: string; "limit"?: number; "page"?: string; "dbName"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDatabaseSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listVmdbDatabasesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Vmdb/databases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<VmdbDatabaseSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "dbHomeId": { values: params["dbHomeId"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "dbName": { values: params["dbName"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDatabasesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbDbHomes
     * Lists the Database Homes in the specified resource group. A Database Home is a directory where Oracle Database software is installed. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemId The fully qualified Azure resource ID of the DB System.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listVmdbDbHomes(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemId"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbHomeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listVmdbDbHomes", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbHomes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<VmdbDbHomeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "dbSystemId": { values: params["dbSystemId"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDbHomes",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbDbHomesBySubscription
     * Lists the Database Homes in the specified subscription. A Database Home is a directory where Oracle Database software is installed. 
     * @param subscriptionId subscription identifier
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listVmdbDbHomesBySubscription(params: {  "subscriptionId": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbHomeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listVmdbDbHomesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Vmdb/dbHomes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<VmdbDbHomeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDbHomesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbDbNodes
     * Lists the database nodes in the specified resource group. A database node is a server running database software. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemId The fully qualified Azure resource ID of the DB System.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listVmdbDbNodes(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemId"?: string; "limit"?: number; "page"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbNodeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listVmdbDbNodes", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbNodes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<VmdbDbNodeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "dbSystemId": { values: params["dbSystemId"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDbNodes",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbDbNodesBySubscription
     * Lists the database nodes in the specified subscription. A database node is a server running database software. 
     * @param subscriptionId subscription identifier
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public listVmdbDbNodesBySubscription(params: {  "subscriptionId": string; "limit"?: number; "page"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbNodeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listVmdbDbNodesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Vmdb/dbNodes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<VmdbDbNodeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDbNodesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbDbSystemShapes
     * Gets a list of the shapes that can be used to launch a new DB system. The shape determines resources to allocate to the DB system - CPU cores and memory for VM shapes.
     * @param subscriptionId subscription identifier
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listVmdbDbSystemShapes(params: {  "subscriptionId": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbSystemShapeSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listVmdbDbSystemShapes", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Vmdb/shapes`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<VmdbDbSystemShapeSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDbSystemShapes",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbDbSystems
     * Returns a list of DbSystems.
     * @param resourceGroupName Azure resource group name.
     * @param subscriptionId Azure subscription ID.
     * @param opcRequestId The client request ID for tracing.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param name A filter to return only resources that match the entire display name given. The match is not case sensitive.
     */
    public listVmdbDbSystems(params: {  "resourceGroupName": string; "subscriptionId": string; "opcRequestId"?: string; "limit"?: number; "page"?: string; "name"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbSystemCollection }> {
        base.validateRequiredParameters([
            "resourceGroupName",
            "subscriptionId",
        ], "listVmdbDbSystems", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbSystems`
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<VmdbDbSystemCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "name": { values: params["name"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDbSystems",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbDbSystemsBySubscription
     * Returns a list of DbSystems in the specified subscription.
     * @param subscriptionId Azure subscription ID.
     * @param opcRequestId The client request ID for tracing.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param name A filter to return only resources that match the entire display name given. The match is not case sensitive.
     * @param apiVersion The API version to use for this operation.
     */
    public listVmdbDbSystemsBySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; "limit"?: number; "page"?: string; "name"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbSystemCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listVmdbDbSystemsBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Vmdb/dbSystems`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<VmdbDbSystemCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "name": { values: params["name"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDbSystemsBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbDbVersions
     * Gets a list of supported Oracle VMDB Database versions.
     * @param subscriptionId subscription identifier
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param dbSystemShape If provided, filters the results to the set of database versions which are supported for the given shape.
     * @param isUpgradeSupported If provided, filters the results to the set of database versions which are supported for Upgrade.
     * @param storageManagement If provided, filters the results to the list of database versions available for the specified storage manager. Valid values are ASM and LVM. ASM specifies Oracle Automatic Storage Management. LVM specifies logical volume manager, sometimes called logical disk manager. 
     */
    public listVmdbDbVersions(params: {  "subscriptionId": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; "dbSystemShape"?: string; "isUpgradeSupported"?: boolean; "storageManagement"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbVersionSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listVmdbDbVersions", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Vmdb/dbVersions`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<VmdbDbVersionSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "dbSystemShape": { values: params["dbSystemShape"] },
                    "isUpgradeSupported": { values: params["isUpgradeSupported"] },
                    "storageManagement": { values: params["storageManagement"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbDbVersions",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbMinorVersions
     * Gets a list of supported Oracle Database minor versions for the given major version.
     * @param dbVersion The database version.
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     */
    public listVmdbMinorVersions(params: {  "dbVersion": string; "opcRequestId"?: string; "apiVersion"?: string; "limit"?: number; "page"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbMinorVersionSummaryCollection }> {
        base.validateRequiredParameters([
            "dbVersion",
        ], "listVmdbMinorVersions", params);

        const path = `${this.basePath}/providers/Oracle.Vmdb/dbVersions/{dbVersion}/minorVersions`
            .replace(`{${"dbVersion"}}`, `${ params["dbVersion"] }`);

        return this.request<VmdbDbMinorVersionSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbMinorVersions",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbPluggableDatabases
     * Gets a list of the pluggable databases in a database or resource group. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param opcRequestId Unique identifier for the request. 
     * @param databaseId The fully qualified Azure resource ID of the database.
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param name A filter to return only pluggable databases that match the entire name given. The match is not case sensitive.
     * @param apiVersion The API version to use for this operation.
     */
    public listVmdbPluggableDatabases(params: {  "subscriptionId": string; "resourceGroupName": string; "opcRequestId"?: string; "databaseId"?: string; "limit"?: number; "page"?: string; "name"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbPluggableDatabaseSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
        ], "listVmdbPluggableDatabases", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/pluggableDatabases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<VmdbPluggableDatabaseSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "databaseId": { values: params["databaseId"] },
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "name": { values: params["name"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbPluggableDatabases",
            httpMethod: "GET"
        });
    }
    /** 
     * ListVmdbPluggableDatabasesBySubscription
     * Gets a list of the pluggable databases in the specified subscription. 
     * @param subscriptionId subscription identifier
     * @param opcRequestId Unique identifier for the request. 
     * @param limit The maximum number of items to return.
     * @param page A token representing the position at which to start retrieving results. This must come from the &#x60;opc-next-page&#x60; header field of a previous response.
     * @param name A filter to return only pluggable databases that match the entire name given. The match is not case sensitive.
     * @param apiVersion The API version to use for this operation.
     */
    public listVmdbPluggableDatabasesBySubscription(params: {  "subscriptionId": string; "opcRequestId"?: string; "limit"?: number; "page"?: string; "name"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbPluggableDatabaseSummaryCollection }> {
        base.validateRequiredParameters([
            "subscriptionId",
        ], "listVmdbPluggableDatabasesBySubscription", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/providers/Oracle.Vmdb/pluggableDatabases`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`);

        return this.request<VmdbPluggableDatabaseSummaryCollection>({
            options,
            path,

            queryParameters: {
                    "limit": { values: params["limit"] },
                    "page": { values: params["page"] },
                    "name": { values: params["name"] },
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"]
            },


            parseResponseBody: true,

            operationName: "listVmdbPluggableDatabasesBySubscription",
            httpMethod: "GET"
        });
    }
    /** 
     * LocalVmdbClonePluggableDatabase
     * Clones and starts a pluggable database (PDB) in the same database (CDB) as the source PDB. The source PDB must be in the &#x60;READ_WRITE&#x60; openMode to perform the clone operation. 
     * @param localClonePluggableDatabaseDetails Request to clone a pluggable database locally.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     */
    public localVmdbClonePluggableDatabase(params: {  "localClonePluggableDatabaseDetails": VmdbLocalClonePluggableDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; }, options?: any): Promise<{ response: Response, data: VmdbPluggableDatabase }> {
        base.validateRequiredParameters([
            "localClonePluggableDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "localVmdbClonePluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/pluggableDatabases/{pluggableDatabaseName}/localClone`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request<VmdbPluggableDatabase>({
            options,
            path,


            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["localClonePluggableDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "localVmdbClonePluggableDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * RestoreVmdbDatabase
     * Restore a Database based on the request parameters you provide. 
     * @param databaseName The name of the database resource. The name needs to be unique in the resource group.
     * @param restoreVmdbDatabaseDetails Request to perform database restore.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param apiVersion The API version to use for this operation.
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param opcRequestId Unique identifier for the request. 
     */
    public restoreVmdbDatabase(params: {  "databaseName": string; "restoreVmdbDatabaseDetails": RestoreVmdbDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "ifMatch"?: string; "apiVersion"?: string; "opcRetryToken"?: string; "opcRequestId"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDatabase }> {
        base.validateRequiredParameters([
            "databaseName",
            "restoreVmdbDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
        ], "restoreVmdbDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/databases/{databaseName}/restore`
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`)
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`);

        return this.request<VmdbDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-retry-token":  params["opcRetryToken"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["restoreVmdbDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "restoreVmdbDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * SoftResetVmdbDbNode
     * ACPI shutdown and power on a specified VmdbDbNode  **Note:** Stopping a node affects billing differently, depending on the type of DB system: Billing continues for DB nodes that you stop, and related resources continue to apply against any relevant quotas. You must terminate the DB system ([TerminateDbSystem](#/en/database/latest/DbSystem/TerminateDbSystem)) to remove its resources from billing and quotas. *Virtual machine DB systems* - Stopping a node stops billing for all OCPUs associated with that node, and billing resumes when you restart the node. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbNodeName The name of the database node resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param apiVersion The API version to use for this operation.
     */
    public softResetVmdbDbNode(params: {  "subscriptionId": string; "resourceGroupName": string; "dbNodeName": string; "opcRequestId"?: string; "opcRetryToken"?: string; "ifMatch"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbNode }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbNodeName",
        ], "softResetVmdbDbNode", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbNodes/{dbNodeName}/softreset`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbNodeName"}}`, `${ params["dbNodeName"] }`);

        return this.request<VmdbDbNode>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"],
                "if-match":  params["ifMatch"]
            },


            parseResponseBody: true,

            operationName: "softResetVmdbDbNode",
            httpMethod: "POST"
        });
    }
    /** 
     * StartVmdbDbNode
     * Power on the specified VmdbDbNode
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbNodeName The name of the database node resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param apiVersion The API version to use for this operation.
     */
    public startVmdbDbNode(params: {  "subscriptionId": string; "resourceGroupName": string; "dbNodeName": string; "opcRequestId"?: string; "opcRetryToken"?: string; "ifMatch"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbNode }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbNodeName",
        ], "startVmdbDbNode", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbNodes/{dbNodeName}/start`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbNodeName"}}`, `${ params["dbNodeName"] }`);

        return this.request<VmdbDbNode>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"],
                "if-match":  params["ifMatch"]
            },


            parseResponseBody: true,

            operationName: "startVmdbDbNode",
            httpMethod: "POST"
        });
    }
    /** 
     * StartVmdbPluggableDatabase
     * Starts a stopped pluggable database. The &#x60;openMode&#x60; value of the pluggable database will be &#x60;READ_WRITE&#x60; upon completion.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param apiVersion The API version to use for this operation.
     */
    public startVmdbPluggableDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbPluggableDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "startVmdbPluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/pluggableDatabases/{pluggableDatabaseName}/start`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request<VmdbPluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },


            parseResponseBody: true,

            operationName: "startVmdbPluggableDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * StopVmdbDbNode
     * Power off a specified VmdbDbNode  **Note:** Stopping a node affects billing differently, depending on the type of DB system: Billing continues for DB nodes that you stop, and related resources continue to apply against any relevant quotas. You must terminate the DB system ([TerminateDbSystem](#/en/database/latest/DbSystem/TerminateDbSystem)) to remove its resources from billing and quotas. *Virtual machine DB systems* - Stopping a node stops billing for all OCPUs associated with that node, and billing resumes when you restart the node. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbNodeName The name of the database node resource. The name needs to be unique in the resource group.
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param apiVersion The API version to use for this operation.
     */
    public stopVmdbDbNode(params: {  "subscriptionId": string; "resourceGroupName": string; "dbNodeName": string; "opcRequestId"?: string; "opcRetryToken"?: string; "ifMatch"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbNode }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbNodeName",
        ], "stopVmdbDbNode", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbNodes/{dbNodeName}/stop`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbNodeName"}}`, `${ params["dbNodeName"] }`);

        return this.request<VmdbDbNode>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"],
                "if-match":  params["ifMatch"]
            },


            parseResponseBody: true,

            operationName: "stopVmdbDbNode",
            httpMethod: "POST"
        });
    }
    /** 
     * StopVmdbPluggableDatabase
     * Stops a pluggable database. The &#x60;openMode&#x60; value of the pluggable database will be &#x60;MOUNTED&#x60; upon completion.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param opcRetryToken A token that uniquely identifies a request so it can be retried in case of a timeout or server error without risk of executing that same action again. Retry tokens expire after 24 hours, but can be invalidated before then due to conflicting operations. For example, if a resource has been deleted and purged from the system, then a retry of the original creation request might be rejected. 
     * @param apiVersion The API version to use for this operation.
     */
    public stopVmdbPluggableDatabase(params: {  "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "opcRetryToken"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbPluggableDatabase }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "stopVmdbPluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/pluggableDatabases/{pluggableDatabaseName}/stop`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request<VmdbPluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"],
                "opc-retry-token":  params["opcRetryToken"]
            },


            parseResponseBody: true,

            operationName: "stopVmdbPluggableDatabase",
            httpMethod: "POST"
        });
    }
    /** 
     * UpdateVmdbDatabase
     * Update the specified VMDB Database based on the request parameters provided. 
     * @param updateDatabaseDetails Request to perform VMDB database update.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param databaseName The name of the database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public updateVmdbDatabase(params: {  "updateDatabaseDetails": UpdateVmdbDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "databaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDatabase }> {
        base.validateRequiredParameters([
            "updateDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
            "databaseName",
        ], "updateVmdbDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/databases/{databaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"databaseName"}}`, `${ params["databaseName"] }`);

        return this.request<VmdbDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateVmdbDatabase",
            httpMethod: "PUT"
        });
    }
    /** 
     * UpdateVmdbDbSystem
     * Updates the properties of the specified DB system. 
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param dbSystemName The name of the VMDB resource. The name needs to be unique in the resource group.
     * @param updateDbSystemDetails Request to update the attributes of a db system.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public updateVmdbDbSystem(params: {  "subscriptionId": string; "resourceGroupName": string; "dbSystemName": string; "updateDbSystemDetails": UpdateVmdbDbSystemDetails; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbDbSystem }> {
        base.validateRequiredParameters([
            "subscriptionId",
            "resourceGroupName",
            "dbSystemName",
            "updateDbSystemDetails",
        ], "updateVmdbDbSystem", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/dbSystems/{dbSystemName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"dbSystemName"}}`, `${ params["dbSystemName"] }`);

        return this.request<VmdbDbSystem>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateDbSystemDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateVmdbDbSystem",
            httpMethod: "PUT"
        });
    }
    /** 
     * UpdateVmdbPluggableDatabase
     * Update the specified VMDB Pluggable Database based on the request parameters provided. 
     * @param updateVmdbPluggableDatabaseDetails Request to perform VMDB pluggable database update.
     * @param subscriptionId subscription identifier
     * @param resourceGroupName Resource Group name
     * @param pluggableDatabaseName The name of the pluggable database resource. The name needs to be unique in the resource group.
     * @param ifMatch For optimistic concurrency control. In the PUT or DELETE call for a resource, set the &#x60;if-match&#x60; parameter to the value of the etag from a previous GET or POST response for that resource. The resource will be updated or deleted only if the etag you provide matches the resource&#39;s current etag value. 
     * @param opcRequestId Unique identifier for the request. 
     * @param apiVersion The API version to use for this operation.
     */
    public updateVmdbPluggableDatabase(params: {  "updateVmdbPluggableDatabaseDetails": UpdateVmdbPluggableDatabaseDetails; "subscriptionId": string; "resourceGroupName": string; "pluggableDatabaseName": string; "ifMatch"?: string; "opcRequestId"?: string; "apiVersion"?: string; }, options?: any): Promise<{ response: Response, data: VmdbPluggableDatabase }> {
        base.validateRequiredParameters([
            "updateVmdbPluggableDatabaseDetails",
            "subscriptionId",
            "resourceGroupName",
            "pluggableDatabaseName",
        ], "updateVmdbPluggableDatabase", params);

        const path = `${this.basePath}/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Oracle.Vmdb/pluggableDatabases/{pluggableDatabaseName}`
            .replace(`{${"subscriptionId"}}`, `${ params["subscriptionId"] }`)
            .replace(`{${"resourceGroupName"}}`, `${ params["resourceGroupName"] }`)
            .replace(`{${"pluggableDatabaseName"}}`, `${ params["pluggableDatabaseName"] }`);

        return this.request<VmdbPluggableDatabase>({
            options,
            path,

            queryParameters: {
                    "api-version": { values: params["apiVersion"] }
            },

            headerParameters: {
                "if-match":  params["ifMatch"],
                "opc-request-id":  params["opcRequestId"]
            },

                    body: 
                    {
                        type: "content",
                        contentType: (params as any)["contentType"] ? (params as any)["contentType"] as string : "application/json",
                        content: params["updateVmdbPluggableDatabaseDetails"]
                    },

            parseResponseBody: true,

            operationName: "updateVmdbPluggableDatabase",
            httpMethod: "PUT"
        });
    }
};

