import {
  ActionType,
  ConfirmAction,
  DateTimeDisplay,
  getResourceLifecycleStatus,
  InfoBlockProps,
  InfoBlockStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  optimizedRetryOption,
  SortDirections,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import { SelectionMode } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  BackupSummary,
  BackupSummaryCollection,
  BackupSummaryTypeEnum,
} from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import {
  getBackupTypeStr,
  getStatusInfo,
  ResourceStatus,
  responseItemstoArray,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { ExaDbCreateBackupProps, newExaDbCreateBackup } from "../../operations/ExaDb/ExaDbCreateBackup";
import { ExaDbDeleteBackupProps, newExaDbDeleteBackup } from "../../operations/ExaDb/ExaDbDeleteBackup";
import { ExaDbRestoreFromBackupProps, newExaDbRestoreFromBackup } from "../../operations/ExaDb/ExaDbRestoreFromBackup";
import { getOciRegion } from "../../utils";

export interface ExaDbBackupsListProps {
  databaseName: string;
  location: string;
  databaseId: string;
}

export enum ActionBarIds {
  CreateBackup = "action-bar-create-backup",
  // CreateDbFromBackup = "create-db-from-backup",
  Refresh = "action-bar-refresh",
  Restore = "action-bar-restore",
  Delete = "action-bar-delete",
}

export enum ActionBarTestIds {
  CreateBackup = "action-bar-create-backup",
  Delete = "action-bar-delete",
  Restore = "action-bar-restore",
  Refresh = "action-bar-refresh",
}

export enum ActionMenuIds {
  Restore = "action-menu-restore",
  Delete = "action-menu-delete",
}

export enum ActionMenuTestIds {
  Restore = "action-menu-restore",
  Delete = "action-menu-delete",
}

export enum InfoBlockTestIds {
  DisabledActions = "disabled-actions-info-block"
}

export enum ColumnIds {
  Name = "displayName",
  ResStatus = "status",
  Type = "type",
  Started = "timeStarted",
  Ended = "timeEnded",
}

export enum ColumnTestIds {
  Name = "displayName",
  ResStatus = "status",
  Type = "type",
  Started = "timeStarted",
  Ended = "timeEnded",
}

export enum BackupType {
  Incremental = "INCREMENTAL"
}

export const ExaDbBackupsList = (
  { location, databaseName, databaseId }: ExaDbBackupsListProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");

  const { locale } = React.useContext<Settings>(SettingsContext);

  const { trackActionClick, trackActionDiscard } = useAnalytics();
  const pageId = PageId.EXADB_CDB_DETAILS;

  const [selection, setSelection] = React.useState<BackupSummary[]>([]);

  const { subListingPageItemCount } = useRowCount();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectedItems = (selectedItems: any[]): void => {
    setSelection(selectedItems);
  };

  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<BackupSummary>>();

  const {
    response: listBackupsResponse, loading: listBackupsLoading,
    refresh: listBackupsRefresh,
  } = useQueryCall({
    wait: !(subscriptionId && resGroup && location && databaseId),
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDatabaseBackups,
    options: {
      args: {
        subscriptionId,
        resourceGroupName: resGroup,
        databaseName,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadBackups(),
      },
    },
  });

  const backupItems = listBackupsResponse
    && responseItemstoArray<BackupSummaryCollection, BackupSummary>(listBackupsResponse);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = React.useMemo(() => backupItems, [JSON.stringify(backupItems)]);

  const getDateTime = (value: Date | undefined): JSX.Element => <DateTimeDisplay date={value} hideClipboardCopy />;
  const getBackupType = (value: BackupSummary): string => getBackupTypeStr(value?.type as BackupSummaryTypeEnum);

  const getStatus = (value: BackupSummary): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
          value?.lifecycleDetails,
        )
      }
      hideClipboardCopy
    />
  );

  const disabledActionsInfoBlock = (selectedItem:BackupSummary): InfoBlockProps[] => {
    const disabledActionInfo: InfoBlockProps[] = [];
    if (selectedItem?.type === BackupType.Incremental) {
      disabledActionInfo.push({
        testId: InfoBlockTestIds.DisabledActions,
        message: Messages.hints.exaDbBackupDisabledDeleteAction(),
        messageType: InfoBlockStatus.INFO,
      });
    }
    return disabledActionInfo;
  };

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
    {
      itemProp: ColumnIds.ResStatus,
      testId: ColumnTestIds.ResStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: BackupSummary,
        b: BackupSummary,
      ) => statusSortComparator<BackupSummary>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.Type,
      testId: ColumnTestIds.Type,
      name: Messages.labels.type(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getBackupType(value),
    },
    {
      itemProp: ColumnIds.Started,
      testId: ColumnTestIds.Started,
      name: Messages.labels.started(),
      flexGrow: 1,
      initialSortDirection: SortDirections.DESC,
      isResizable: true,
      onRenderItems: value => getDateTime(value.timeStarted),
    },
    {
      itemProp: ColumnIds.Ended,
      testId: ColumnTestIds.Ended,
      name: Messages.labels.ended(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getDateTime(value.timeEnded),
    },
  ];

  // eslint-disable-next-line max-len
  const backupsNotAllowedToDelete = (selectedItem: BackupSummary): boolean => !selectedItem || selectedItem.type === BackupType.Incremental || !(selectedItem?.status === ResourceStatus.Failed || selectedItem?.status === ResourceStatus.Succeeded || selectedItem?.status === ResourceStatus.Active);

  const { trigger: triggerCreateBackup } = useOperation<ExaDbCreateBackupProps>(newExaDbCreateBackup);
  const { trigger: triggerRestoreFromBackup } = useOperation<ExaDbRestoreFromBackupProps>(newExaDbRestoreFromBackup);
  const { trigger: triggerDeleteBackup } = useOperation<ExaDbDeleteBackupProps>(newExaDbDeleteBackup);

  const { closePanels } = useSidePanel();

  const buildRestoreAction = (key:string, testId:string, selectedItem:BackupSummary)
  :ConfirmAction<BackupSummary | undefined> => {
    const restoreAction = {
      key,
      testId,
      text: Messages.actions.restore(),
      icon: MonochromeIconIds.Database,
      // eslint-disable-next-line max-len
      disabled: !selectedItem || !(selectedItem?.status === ResourceStatus.Succeeded || selectedItem?.status === ResourceStatus.Active),
      title: Messages.labels.restore(),
      description: Messages.hints.restoreBackupConfirmation(selectedItem ? selectedItem.displayName : ""),
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.BACKUPS);
      },
      onConfirm: () => {
        triggerRestoreFromBackup({
          backup: {
            id: selectedItem.id,
            timeEnded: selectedItem.timeEnded,
          },
          databaseId,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            listBackupsRefresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, pageId, DetailsPanelId.BACKUPS),
    };
    return restoreAction;
  };

  const buildDeleteAction = (key:string, testId:string, selectedItem:BackupSummary)
  :ConfirmAction<BackupSummary | undefined> => {
    const deleteAction = {
      key,
      testId,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      disabled: backupsNotAllowedToDelete(selectedItem),
      title: Messages.labels.deleteBackup(),
      description: Messages.hints.deleteBackupConfirmation(selectedItem ? selectedItem.displayName : ""),
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.BACKUPS);
      },
      onConfirm: () => {
        triggerDeleteBackup({
          backupId: selectedItem.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            listBackupsRefresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, pageId, DetailsPanelId.BACKUPS),
    };
    return deleteAction;
  };

  const backupRowActionMenus = (item?: BackupSummary | undefined): ActionType<BackupSummary>[] => {
    const backupActionItems : ActionType<BackupSummary | undefined>[] = [];
    if (item) {
      backupActionItems.push(
        buildRestoreAction(ActionMenuIds.Restore, ActionMenuTestIds.Restore, item),
      );
      if (item.type !== BackupType.Incremental) {
        backupActionItems.push(buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item));
      }
    }
    return backupActionItems;
  };

  const backupBarActions: ActionType<BackupSummary>[] = [
    {
      key: ActionBarIds.CreateBackup,
      testId: ActionBarTestIds.CreateBackup,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        listingComponentRef?.resetTableSelection();
        trackActionClick(ActionBarIds.CreateBackup, pageId, DetailsPanelId.BACKUPS);
        triggerCreateBackup({
          databaseId,
          targetId: ActionBarIds.CreateBackup,
          location,
          onExecute: listBackupsRefresh,
          onCancel: () => trackActionDiscard(ActionBarIds.CreateBackup, pageId, DetailsPanelId.BACKUPS),
        });
      },
    },
    buildRestoreAction(ActionBarIds.Restore, ActionBarTestIds.Restore, selection[0]),
    //  Create database from backup has been pushed to post GA
    // {
    //   key: ActionIds.CreateDbFromBackup,
    //   text: Messages.actions.createDatabase(),
    //   icon: MonochromeIconIds.Database,
    //   disabled: !selection.length,
    //   onClick: () => {
    //     trackActionClick(ActionIds.CreateDbFromBackup, pageId, DetailsPanelId.BACKUPS);
    //     navigateTo(EXADB_CREATE_ROUTE, PageId.EXADB_CREATE, {
    //       vmClusterId: database.vmClusterId,
    //       backup: {
    //         id: selection[0].id,
    //         name: selection[0].displayName,
    //         startDate: selection[0].timeStarted,
    //       },
    //       location,
    //       analytics: {
    //         pageId,
    //         panelId: DetailsPanelId.BACKUPS,
    //         actionName: ActionIds.CreateDbFromBackup,
    //       } as NavigationAnalyticsData,
    //     });
    //   },
    // },
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, pageId, DetailsPanelId.BACKUPS);
        listBackupsRefresh();
        listingComponentRef?.resetTableSelection();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  return (
    <Listing
      testId={ListingTestIds.ExaDbBackups}
      listingComponentRef={setListingComponentRef}
      itemsPerPage={subListingPageItemCount}
      items={items || []}
      emptyList={{ title: Messages.common.noResults() }}
      infoBlocks={disabledActionsInfoBlock(selection[0])}
      listColumns={columns}
      actionBarItems={backupBarActions as ActionType<undefined>[]}
      actions={backupRowActionMenus}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      isLoading={listBackupsLoading}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Started,
      }}
    />
  );
};
