import {
  AnchoredPanelType,
  FormValues,
  getValue,
  InputFormGroup,
  InputFormSidePanel,
  InputFormSidePanelComponent,
  InputFormSidePanelProps,
  SelectOption,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import { useBoolean } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { saveBlob } from "../../helpers/fileHelper";
import { DownloadSshKeyModal } from "../SshKeyPair/DownloadSshKeyModal";
import {
  defaultName,
  DerivedFields as NestedSshInputFields,
  SshKeyPairInput,
  SshPublicKeySource,
} from "../SshKeyPair/SshKeyPairInput";

export enum Fields {
  sshKeyPair = "sshKeyPair",
}

export type AddSshKeyPanelProps = Pick<InputFormSidePanelProps,
  "componentRef" |
  "onClose" |
  "onSubmit" |
  "title" |
  "testId"
>;

export const AddSshKeyPanel = (
  { title, testId, componentRef, onSubmit, onClose }: AddSshKeyPanelProps,
): JSX.Element => {
  const [isAddKeyModalOpen, { setTrue: showAddKeyModal, setFalse: hideAddKeyModal }] = useBoolean(false);
  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>();

  const internalComponentRef = (ref: InputFormSidePanelComponent): void => {
    setSidePanelRef(ref);
    componentRef?.(ref);
  };

  const onAddSshKey = (values: FormValues): void => {
    const sshKeySourceFieldName = Fields.sshKeyPair + NestedSshInputFields.Source;
    const sshKeySource = getValue<SelectOption[]>(values, sshKeySourceFieldName, InputFormGroup)?.[0].id;

    if (SshPublicKeySource.Generate === sshKeySource) {
      showAddKeyModal();
    } else {
      onSubmit(values);
    }
  };

  const onCreate = async (): Promise<void> => {
    const formValues = sidePanelRef?.getFormValues() ?? {};
    const privateKey = getValue<Record<NestedSshInputFields, string>>(
      formValues,
      Fields.sshKeyPair,
      InputFormGroup,
    )?.[NestedSshInputFields.PrivateKey];
    if (privateKey) {
      saveBlob(privateKey, `${defaultName()}.key`);
    }
    onSubmit(formValues ?? {});
  };

  return (
    <InputFormSidePanel
      testId={testId}
      componentRef={internalComponentRef}
      type={AnchoredPanelType.MEDIUM}
      title={title}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onAddSshKey}
      onClose={onClose}
    >
      <SshKeyPairInput
        defaultValue={SshPublicKeySource.Generate}
        fieldName={Fields.sshKeyPair}
        groupName={InputFormGroup}
      />
      <DownloadSshKeyModal
        cancelBtnText={Messages.actions.returnToAddSshKey()}
        submitBtnText={Messages.actions.downloadKeyUpdateResource()}
        onCancel={sidePanelRef?.allowResubmit}
        onSubmit={onCreate}
        closeModal={hideAddKeyModal}
        isOpen={isAddKeyModalOpen}
      />
    </InputFormSidePanel>
  );
};
