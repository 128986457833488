import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Fields as PdbFields, VmDbPdbCreatePanel } from "../../components/PdbCreatePanel/VmDbPdbCreatePanel";
import { parseId } from "../../helpers/idHelper";
import { getTagsMap, TagsInfoType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_PDB_CREATE_POLL_DELAY,
  VMDB_PDB_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface VmDbCreatePdbProps extends OperationProps {
  databaseId: string | undefined;
}

const VmDbCreatePdb = (
  { location, databaseId, onExecute, onCancel }: VmDbCreatePdbProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");

  const { invokeCall: invokeCreate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.createVmdbPluggableDatabase },
  );
  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const onCreatePdb = (formValues: FormValues): void => {
    const pdbName = getValue<string>(formValues, PdbFields.PdbName, InputFormGroup) || "";
    const pdbAdminPassword = getValue<string>(formValues, PdbFields.Password, InputFormGroup);
    const unlockAccount = getValue<boolean>(formValues, PdbFields.UnlockAdminAccount, InputFormGroup);
    const tdeWalletPassword = getValue<string>(formValues, PdbFields.TdeWalletPassword, InputFormGroup);
    const tagsValue = getValue<TagsInfoType>(formValues, PdbFields.Tags, InputFormGroup);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.createPluggableDb(),
          message: Messages.notifications.inProgress.messages.createPluggableDb(pdbName),
        },
        success: {
          title: Messages.notifications.success.titles.createPluggableDb(),
          message: Messages.notifications.success.messages.createPluggableDb(pdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.create(),
          message: Messages.notifications.failure.messages.createPluggableDb(pdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_PDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            pluggableDatabaseName: pdbName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_CREATED_CHECK_PDB,
          delay: VMDB_PDB_CREATE_POLL_DELAY,
          interval: VMDB_PDB_CREATE_POLL_INTERVAL,

        },
      },
    };

    invokeCreate({
      subscriptionId,
      resourceGroupName: resGroup,
      createVmdbPluggableDatabaseDetails: {
        name: pdbName,
        pdbName,
        containerDatabaseId: databaseId || "",
        ...pdbAdminPassword ? { pdbAdminPassword } : {},
        shouldPdbAdminAccountBeLocked: !unlockAccount,
        tdeWalletPassword,
        freeformTags: getTagsMap(tagsValue),
      },
    }, invokeOptions);
  };

  return (
    <VmDbPdbCreatePanel
      title={Messages.createPluggableDatabase.title()}
      showTags
      componentRef={setSidePanelRef}
      onSubmit={onCreatePdb}
      onClose={onCancel}
    />
  );
};

export const newVmDbCreatePdb = (
  props: VmDbCreatePdbProps,
): JSX.Element => (<VmDbCreatePdb {...props} />);
