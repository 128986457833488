import { FormContext, FormInputGroupLayoutContext, FormState } from "o4a-react";
import React from "react";

interface ResetInputProps {
    componentRef: (ref: ResetInputComponent) => void;
    children: JSX.Element;
  }

export interface ResetInputComponent {
    reset: (value?: unknown) => void;
  }

export const ResetInput = ({
  children,
  componentRef,
}: ResetInputProps): JSX.Element => {
  const form: FormState = React.useContext(FormContext);
  const { groupName: layoutGroupName } = React.useContext(FormInputGroupLayoutContext);
  const {
    fieldName,
    groupName: childGroupName,
  } = children.props;
  
  const groupName = childGroupName || layoutGroupName;
  
  React.useEffect(() => {
    componentRef?.({ reset: (value?: unknown) => form.setValue(value, fieldName, groupName) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef]);
  
  return children;
};
