import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AddSshKeyPanel, Fields } from "../../components/AddSshKeyPanel/AddSshKeyPanel";
import { DerivedFields as NestedSshInputFields } from "../../components/SshKeyPair/SshKeyPairInput";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_DB_SYSTEM_UPDATE_POLL_DELAY,
  VMDB_DB_SYSTEM_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface VmDbSystemAddSshKeyProps extends OperationProps {
  dbSystemId: string | undefined;
  sshPublicKeys: string[] | undefined;
}

const VmDbSystemAddSshKey = (
  { location, dbSystemId, sshPublicKeys, onCancel, onExecute }: VmDbSystemAddSshKeyProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(dbSystemId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeDbSystemUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.updateVmdbDbSystem },
  );

  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const onAddSshKey = (formValues: FormValues): void => {
    const keyArr = sshPublicKeys || [];

    const publicKey = getValue<Record<NestedSshInputFields, string>>(
      formValues,
      Fields.sshKeyPair,
      InputFormGroup,
    )?.[NestedSshInputFields.PublicKey];

    if (publicKey) {
      keyArr.push(publicKey);
    }

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.addSshKey(),
          message: Messages.notifications.inProgress.messages.addSshKey(resName),
        },
        success: {
          title: Messages.notifications.success.titles.addSshKey(),
          message: Messages.notifications.success.messages.addSshKey(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.addSshKey(),
          message: Messages.notifications.failure.messages.addSshKey(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_DB_SYSTEM_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            dbSystemName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_DB_SYSTEM_UPDATED_CHECK,
          delay: VMDB_DB_SYSTEM_UPDATE_POLL_DELAY,
          interval: VMDB_DB_SYSTEM_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeDbSystemUpdate({
      subscriptionId,
      resourceGroupName: resGroup,
      dbSystemName: resName,
      updateDbSystemDetails: { sshPublicKeys: keyArr },
    }, invokeOptions);
  };

  return (
    <AddSshKeyPanel
      testId={SidePanelTestIds.AddSshKey}
      title={Messages.addSshKey.title()}
      componentRef={setSidePanelRef}
      onSubmit={onAddSshKey}
      onClose={onCancel}
    />
  );
};

export const newVmDbSystemAddSshKey = (
  props: VmDbSystemAddSshKeyProps,
): JSX.Element => (<VmDbSystemAddSshKey {...props} />);
