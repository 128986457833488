import {
  CheckBox,
  FieldSet,
  FormContext,
  FormState,
  FormValues,
  InfoBlockLayout,
  InfoBlockStatus,
  Select,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { backupRetentionPeriods, backupSlots, getBackUpScheduleTimeRange } from "../../helpers/resourceHelper";
import { utc } from "../../helpers/timeHelper";

export const GROUP_MANAGEMENT = "management";

export enum Fields {
  Backups = "vmdbDbSystemDeploymentDetails.dbHome.database.dbBackupConfig.autoBackupEnabled",
  RetentionPeriod = "vmdbDbSystemDeploymentDetails.dbHome.database.dbBackupConfig.recoveryWindowInDays",
  Scheduling = "vmdbDbSystemDeploymentDetails.dbHome.database.dbBackupConfig.autoBackupWindow",
}

export enum FieldTestIds {
  Backups = "auto-backup-checkbox",
  RetentionPeriod = "backup-period-select",
  Scheduling = "backup-schedule-select",
}

export const ManagementTabContent = (): JSX.Element => {
  const { locale } = React.useContext<Settings>(SettingsContext);
  const [showBackupOptions, setShowBackupOptions] = React.useState(true);
  const form: FormState = React.useContext(FormContext);
  const [defaultBackupSchedule, setDefaultBackupSchedule] = React.useState<string>();
  const [defaultRetentionPeriod,
    setDefaultRetentionPeriod] = React.useState(backupRetentionPeriods.thirtyDaysPeriod.toString());
  const info = {
    messageType: InfoBlockStatus.INFO,
    message: Messages.createVmDb.managementTab.info.backupInfo(),
    infoLayout: InfoBlockLayout.Compact,
  };

  const onChangeShowBackUpOptions = (value: boolean): void => {
    setShowBackupOptions(value);
    if (value) {
      const formValues: FormValues = form.getGroupValues(GROUP_MANAGEMENT);
      setDefaultRetentionPeriod(formValues[Fields.RetentionPeriod]?.length > 0
        ? formValues[Fields.RetentionPeriod]?.[0].id
        : defaultRetentionPeriod);
      setDefaultBackupSchedule(formValues[Fields.Scheduling]?.length > 0
        ? formValues[Fields.Scheduling]?.[0].id : undefined);
    }
  };

  const retentionOptions = Object.values(backupRetentionPeriods).map(period => ({
    id: `${period}`,
    text: `${period} ${Messages.common.days()}`,
  }));

  const scheduleOptions = backupSlots.map(schedule => ({
    id: schedule.id,
    text: getBackUpScheduleTimeRange(schedule.id, locale),
  }));

  return (
    <FieldSet
      header={Messages.createVmDb.managementTab.sectionTitles.backupHeader()}
    >
      <CheckBox
        defaultValue={showBackupOptions}
        statusInfo={info}
        fieldName={Fields.Backups}
        testId={FieldTestIds.Backups}
        label={Messages.labels.enableAutomaticBackups()}
        onChange={value => onChangeShowBackUpOptions(value)}
      />
      {showBackupOptions && (
        <>
          <Select
            defaultValue={[defaultRetentionPeriod]}
            required
            fieldName={Fields.RetentionPeriod}
            label={Messages.labels.backupRetentionPeriod()}
            testId={FieldTestIds.RetentionPeriod}
            options={retentionOptions}
            hideSearchBox
          />
          <Select
            defaultValue={defaultBackupSchedule ? [defaultBackupSchedule] : undefined}
            required
            fieldName={Fields.Scheduling}
            label={`${Messages.labels.backupScheduling()} (${utc})`}
            testId={FieldTestIds.Scheduling}
            options={scheduleOptions}
            hideSearchBox
          />
        </>
      )}
    </FieldSet>
  );
};
