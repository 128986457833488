import {
  BookmarkablePage,
  DetailView,
  DetailViewsPanel,
  GroupItem,
  MessageType,
  PanelMessage,
  TitleDetails,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useParams } from "react-router-dom";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import {
  DetailsPanelId,
  EXADATA_LIST_HUB_ROUTE,
  PageId,
  PageRegistrationConfig,
} from "../../constants/pluginConstants";
import { SvgIconIds } from "../../constants/uiConstants";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { useRoles } from "../../hooks/useRoles";
import { ExaDbList } from "../ExaDbListPage/ExaDbList";
import { ExaInfraList } from "../ExaInfraListPage/ExaInfraList";
import { ExaVmClusterList } from "../ExaVmClusterListPage/ExaVmClusterList";

const registrationIds = PageRegistrationConfig[PageId.EXA_LIST_HUB].map(config => config.key);

const getViewId = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.INFRASTRUCTURES:
      return registrationIds[2];
    case DetailsPanelId.VMCLUSTERS:
      return registrationIds[1];
    case DetailsPanelId.DATABASES:
    default:
      return registrationIds[0];
  }
};

const getIconName = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.INFRASTRUCTURES:
      return SvgIconIds.infraSvg;
    case DetailsPanelId.VMCLUSTERS:
      return SvgIconIds.vmClusterSvg;
    case DetailsPanelId.DATABASES:
    default:
      return SvgIconIds.exaSvg;
  }
};

export const ExaListHubPage = (): JSX.Element => {
  const { listId } = useParams();
  const filterState = React.useContext<FilterState>(FilterStateContext);

  const { back, navigateToSelf, navigateToPanel } = useNavigation(ConsoleContext);

  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const disableDbList = !isActionAllowed(RoleBasedAction.LIST_EXA_DB);
  const dbRequiredRole = actionRequiredRoles(RoleBasedAction.LIST_EXA_DB)[0]?.displayName;
  const disableClusterList = !isActionAllowed(RoleBasedAction.LIST_EXA_VMCLUSTER);
  const clusterRequiredRole = actionRequiredRoles(RoleBasedAction.LIST_EXA_VMCLUSTER)[0]?.displayName;
  const disableInfraList = !isActionAllowed(RoleBasedAction.LIST_EXA_INFRA);
  const infraRequiredRole = actionRequiredRoles(RoleBasedAction.LIST_EXA_INFRA)[0]?.displayName;

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.EXA_LIST_HUB].find(
      config => config.panelPath === listId,
    );
    const path = `${EXADATA_LIST_HUB_ROUTE}/${listId}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (filterState.pillFilterStateValue.listId !== listId) {
      filterState.setFilterStateValue({ filterValues: undefined, listId: undefined, defaultPills: undefined });
      filterState.setFilterTextValue(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuItemSelect = (id: string): void => {
    filterState.setFilterStateValue({ filterValues: undefined, listId: undefined, defaultPills: undefined });
    filterState.setFilterTextValue(undefined);
    navigateToPanel(id);
  };

  const detailViews: DetailView[] = [
    {
      id: registrationIds[0],
      content: (<ExaDbList disabled={disableDbList} />),
    },
    {
      id: registrationIds[1],
      content: (<ExaVmClusterList disabled={disableClusterList} />),
    },
    {
      id: registrationIds[2],
      content: (<ExaInfraList disabled={disableInfraList} />),
    },
  ];

  const groupItems: GroupItem[] = [
    {
      heading: Messages.labels.dedicated(),
      items: [
        { icon: getIconName(DetailsPanelId.DATABASES), id: detailViews[0].id, name: Messages.labels.databases() },
        { icon: getIconName(DetailsPanelId.VMCLUSTERS), id: detailViews[1].id, name: Messages.labels.vmClusters() },
        { icon: getIconName(DetailsPanelId.INFRASTRUCTURES), id: detailViews[2].id, name: Messages.labels.infras() },
      ],
    },
  ];

  const getTitleSuffix = (panelId: string | undefined): string => {
    switch (panelId) {
      case DetailsPanelId.INFRASTRUCTURES:
        return Messages.labels.infras();
      case DetailsPanelId.VMCLUSTERS:
        return Messages.labels.vmClusters();
      case DetailsPanelId.DATABASES:
      default:
        return Messages.labels.databases();
    }
  };

  const getTitle = (
    panelId: string | undefined,
  ): string | TitleDetails => ({
    primary: Messages.serviceProviders.exadataDedicated(),
    secondary: getTitleSuffix(panelId),
  });

  const getRoleMessage = (panelId: string | undefined): PanelMessage | undefined => {
    switch (panelId) {
      case DetailsPanelId.INFRASTRUCTURES:
        return disableInfraList
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(infraRequiredRole),
          } as PanelMessage
          : undefined;
      case DetailsPanelId.VMCLUSTERS:
        return disableClusterList
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(clusterRequiredRole),
          } as PanelMessage
          : undefined;
      case DetailsPanelId.DATABASES:
      default:
        return disableDbList
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(dbRequiredRole),
          } as PanelMessage
          : undefined;
    }
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.serviceProviders.exadataDedicated()}
      titleSuffix={getTitleSuffix(listId)}
    >
      <DetailViewsPanel
        title={getTitle(listId)}
        icon={getIconName(listId)}
        message={getRoleMessage(listId)}
        onClose={back}
        isOpen
        views={detailViews}
        activeViewId={getViewId(listId)}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
      />
    </BookmarkablePage>
  );
};
