import {
  ActionType,
  CustomAction,
  getResourceLifecycleStatus,
  InfoBlockProps,
  InfoBlockStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingDisplayNameLink,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { DetailsPanelId, PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  VmdbDatabase,
  VmdbPluggableDatabaseSummary,
  VmdbPluggableDatabaseSummaryCollection,
} from "../../gen/clients/mchub-azure-api-client-vmdb";
import { parseId } from "../../helpers/idHelper";
import {
  getStatusInfo,
  PdbOpenMode,
  ResourceStatus as DbStatus,
  responseItemstoArray,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { newVmDbCreatePdb, VmDbCreatePdbProps } from "../../operations/VmDb/VmDbCreatePdb";
import { newVmDbDeletePdb, VmDbDeletePdbProps } from "../../operations/VmDb/VmDbDeletePdb";
import { newVmDbStartPdb, VmDbStartPdbProps } from "../../operations/VmDb/VmDbStartPdb";
import { newVmDbStopPdb, VmDbStopPdbProps } from "../../operations/VmDb/VmDbStopPdb";
import {
  newVmDbViewConnectionStrings,
  VmDbViewConnectionStringsProps,
} from "../../operations/VmDb/VmDbViewConnectionStrings";
import { getAzureLocationName, getOciRegion } from "../../utils";

/**
 * TODO
 * This interface is implement to include the last status and details.
 * Api has not expose these two fields for now. When it will, we might remove this interface.
 */
interface VmdbPluggableDatabaseSummaryExt extends VmdbPluggableDatabaseSummary {
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
}

export interface VmDbPdbsListProps {
  cdb: VmdbDatabase | undefined;
  location: string;
}

export enum ColumnIds {
  Name = "name",
  ResourceGroupName = "id",
  Location = "location",
  ResourceStatus = "status",
}

export enum ColumnTestIds {
  Name = "name",
  ResourceGroupName = "id",
  Location = "location",
  ResourceStatus = "status",
}

export enum ActionBarIds {
  Create = "action-bar-create",
  Refresh = "action-bar-refresh",
  Start = "action-bar-start",
  Stop = "action-bar-stop",
  ConnectionStrings = "action-bar-connection-strings",
  Delete = "action-bar-delete"
}

export enum ActionBarTestIds {
  Create = "action-bar-create",
  Refresh = "action-bar-refresh",
  Start = "action-bar-start",
  Stop = "action-bar-stop",
  ConnectionStrings = "action-bar-connection-strings",
  Delete = "action-bar-delete"
}

export enum ActionMenuIds {
  Start = "action-menu-start",
  Stop = "action-menu-stop",
  ConnectionStrings = "action-menu-connection-strings",
  Delete = "action-menu-delete"
}

export enum ActionMenuTestIds {
  Start = "action-menu-start",
  Stop = "action-menu-stop",
  ConnectionStrings = "action-menu-connection-strings",
  Delete = "action-menu-delete"
}

export enum InfoBlockTestIds {
  DisabledActions = "disabled-actions-info-block"
}

const detailsPageRegistrationIds = PageRegistrationConfig[PageId.VMDB_PDB_DETAILS].map(config => config.key);
const pageId = PageId.VMDB_CDB_DETAILS;

export const VmDbPdbsList = (
  { cdb, location }: VmDbPdbsListProps,
): JSX.Element => {
  const cdbIdComps = parseId(cdb?.id);

  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const [selection, setSelection] = React.useState<VmdbPluggableDatabaseSummary[]>([]);

  const cdbResGroup = decodeURIComponent(cdbIdComps?.resourceGroup || "");

  const [listingComponentRef, setListingComponentRef] = React.useState
    <ListingComponent<VmdbPluggableDatabaseSummary>>();

  const { locale } = React.useContext<Settings>(SettingsContext);
  const { perPageItemsCount } = useRowCount();

  const { response, loading, refresh } = useQueryCall({
    wait: !cdb?.id,
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbPluggableDatabases,
    options: {
      args: {
        subscriptionId: cdbIdComps?.subscriptionId || "",
        resourceGroupName: cdbResGroup,
        databaseId: cdb?.id,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadPluggableDatabase(),
      },
    },
  });

  const vmdbPdbs = response
    && responseItemstoArray<VmdbPluggableDatabaseSummaryCollection, VmdbPluggableDatabaseSummary>(response);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = React.useMemo(() => vmdbPdbs, [JSON.stringify(vmdbPdbs)]);

  const getLocation = (value: VmdbPluggableDatabaseSummary): string => (
    getAzureLocationName(value?.location || "")
  );

  const getStatus = (value: VmdbPluggableDatabaseSummaryExt): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
        )
      }
      hideClipboardCopy
    />
  );

  const cdbFailedState = (): boolean => (cdb?.status === DbStatus.Failed);
  const pdbFailedState = (selectedItem:VmdbPluggableDatabaseSummary)
  : boolean => (selectedItem?.status === DbStatus.Failed);

  const openModesThatDisablePdbStop = (selectedItem:VmdbPluggableDatabaseSummary): boolean => (
    (selectedItem?.openMode !== PdbOpenMode.READ_ONLY
    && selectedItem?.openMode !== PdbOpenMode.READ_WRITE));
  const openModeThatDisablesPdbStart = (selectedItem:VmdbPluggableDatabaseSummary): boolean => (
    selectedItem?.openMode !== PdbOpenMode.MOUNTED);

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.name}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.VMDB_PDB_DETAILS][0].panelPath}?location=${value.location}`,
            pageKey: detailsPageRegistrationIds[0],
          }}
        />
      ),
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: VmdbPluggableDatabaseSummaryExt,
        b: VmdbPluggableDatabaseSummaryExt,
      ) => statusSortComparator<VmdbPluggableDatabaseSummaryExt>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectedItems = (selectedItems: any[]): void => {
    setSelection(selectedItems);
  };

  const { trigger: triggerCreatePdb } = useOperation<VmDbCreatePdbProps>(newVmDbCreatePdb);
  const { trigger: triggerDeletePdb } = useOperation<VmDbDeletePdbProps>(newVmDbDeletePdb);
  const { trigger: triggerStartPdb } = useOperation<VmDbStartPdbProps>(newVmDbStartPdb);
  const { trigger: triggerStopPdb } = useOperation<VmDbStopPdbProps>(newVmDbStopPdb);
  const { trigger: triggerViewConnectionStrings } = useOperation<VmDbViewConnectionStringsProps>(
    newVmDbViewConnectionStrings,
  );

  const { closePanels } = useSidePanel();

  const buildDeleteAction = (key: string, testId: string, selectedItem:VmdbPluggableDatabaseSummary)
  : CustomAction<VmdbPluggableDatabaseSummary | undefined> => {
    const deleteAction = {
      key,
      testId,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      disabled: !selectedItem,
      title: Messages.actions.delete(),
      description: Messages.hints.deletePluggableDBConfirmation(
        selectedItem?.name || "",
      ),
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.PLUGGABLEDATABASES);
      },
      onConfirm: () => {
        triggerDeletePdb({
          databaseId: selectedItem?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, pageId, DetailsPanelId.PLUGGABLEDATABASES),
    };
    return deleteAction;
  };

  const buildStartAction = (key:string, testId: string, selectedItem:VmdbPluggableDatabaseSummary)
  : CustomAction<VmdbPluggableDatabaseSummary | undefined> => {
    const startAction = {
      key,
      testId,
      text: Messages.actions.start(),
      icon: MonochromeIconIds.Play,
      title: Messages.labels.startVmDB(),
      description: Messages.hints.startVmDBConfirmation(selectedItem?.name || ""),
      disabled: cdbFailedState() || pdbFailedState(selectedItem)
      || openModeThatDisablesPdbStart(selectedItem),
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.PLUGGABLEDATABASES);
      },
      onConfirm: () => {
        triggerStartPdb({
          databaseId: selectedItem?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, pageId, DetailsPanelId.PLUGGABLEDATABASES),
    };
    return startAction;
  };

  const buildStopAction = (key:string, testId:string, selectedItem:VmdbPluggableDatabaseSummary)
  : CustomAction<VmdbPluggableDatabaseSummary | undefined> => {
    const stopAction = {
      key,
      testId,
      text: Messages.actions.stop(),
      icon: MonochromeIconIds.Stop,
      title: Messages.labels.stopVmDB(),
      description: Messages.hints.stopVmDBConfirmation(selectedItem?.name || ""),
      disabled: cdbFailedState() || pdbFailedState(selectedItem)
            || openModesThatDisablePdbStop(selectedItem),
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.PLUGGABLEDATABASES);
      },
      onConfirm: () => {
        triggerStopPdb({
          databaseId: selectedItem?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, pageId, DetailsPanelId.PLUGGABLEDATABASES),
    };
    return stopAction;
  };
  const buildViewConnectionStringsAction = (key: string, testId: string, selectedItem: VmdbPluggableDatabaseSummary):
  CustomAction<VmdbPluggableDatabaseSummary | undefined> => {
    const viewConnectionStringsAction = {
      key,
      testId,
      text: Messages.actions.connectionStrings(),
      icon: MonochromeIconIds.Connect,
      disabled: !selectedItem,
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.PLUGGABLEDATABASES);
        triggerViewConnectionStrings({
          databaseId: selectedItem?.id,
          isPdb: true,
          location,
        });
      },
    };
    return viewConnectionStringsAction;
  };

  const dbRowActionMenu = (item?:VmdbPluggableDatabaseSummary | undefined)
  : ActionType<VmdbPluggableDatabaseSummary> [] => {
    const dbActionItems : ActionType<VmdbPluggableDatabaseSummary | undefined>[] = [];
    if (item) {
      dbActionItems.push(
        buildStartAction(ActionMenuIds.Start, ActionMenuTestIds.Start, item),
        buildStopAction(ActionMenuIds.Stop, ActionMenuTestIds.Stop, item),
        buildViewConnectionStringsAction(ActionMenuIds.ConnectionStrings, ActionMenuTestIds.ConnectionStrings, item),
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return dbActionItems;
  };

  const dbBarActions: ActionType<VmdbPluggableDatabaseSummary>[] = [
    {
      key: ActionBarIds.Create,
      testId: ActionBarTestIds.Create,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        listingComponentRef?.resetTableSelection();
        trackActionClick(ActionBarIds.Create, pageId, DetailsPanelId.PLUGGABLEDATABASES);
        triggerCreatePdb({
          databaseId: cdb?.id,
          location,
          onExecute: () => refresh(),
          onCancel: () => trackActionDiscard(ActionBarIds.Create, pageId, DetailsPanelId.PLUGGABLEDATABASES),
        });
      },
    },
    buildStartAction(ActionBarIds.Start, ActionBarTestIds.Start, selection[0]),
    buildStopAction(ActionBarIds.Stop, ActionBarTestIds.Stop, selection[0]),
    buildViewConnectionStringsAction(ActionBarIds.ConnectionStrings, ActionBarTestIds.ConnectionStrings, selection[0]),
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, pageId, DetailsPanelId.PLUGGABLEDATABASES);
        refresh();
        listingComponentRef?.resetTableSelection();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];
  const disabledActionsInfoBlocks = (selectedItem: VmdbPluggableDatabaseSummary): InfoBlockProps[] => {
    const disabledActionInfo: InfoBlockProps[] = [];
    const disabledActions: string[] = [];
    if (pdbFailedState(selectedItem)) {
      disabledActions.push(Messages.actions.start());
      disabledActions.push(Messages.actions.stop());
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          ? Messages.hints.pdbDisabledActionPdbStatus(disabledActions.toString(), stateT(selectedItem?.status || ""))
          : Messages.hints.pdbDisabledActionsPdbStatus(disabledActions.join(", "), stateT(selectedItem?.status || ""));
        disabledActionInfo.push(
          {
            testId: InfoBlockTestIds.DisabledActions,
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    } else {
      if (cdbFailedState() && selectedItem) {
        disabledActions.push(Messages.actions.start());
        disabledActions.push(Messages.actions.stop());
      }
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          // eslint-disable-next-line max-len
          ? Messages.hints.pdbDisabledActionCdbStatus(disabledActions.toString(), stateT(cdb?.status || ""))
          // eslint-disable-next-line max-len
          : Messages.hints.pdbDisabledActionsCdbStatus(disabledActions.join(", "), stateT(cdb?.status || ""));
        disabledActionInfo.push(
          {
            testId: InfoBlockTestIds.DisabledActions,
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    }
    return disabledActionInfo;
  };

  return (
    <Listing
      testId={ListingTestIds.VmDbPdbs}
      listingComponentRef={setListingComponentRef}
      items={items || []}
      infoBlocks={disabledActionsInfoBlocks(selection[0])}
      emptyList={{ title: Messages.common.noResults() }}
      listColumns={columns}
      itemsPerPage={perPageItemsCount}
      actionBarItems={dbBarActions as ActionType<undefined>[]}
      actions={dbRowActionMenu}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      isLoading={loading}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Name,
      }}
    />
  );
};
