import { FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Fields as TagDetailsFields, TagsEditPanel } from "../../components/TagsEditPanel/TagsEditPanel";
import { TagsInfoType } from "../../helpers/resourceHelper";
import { useOperation } from "../../hooks/useOperation";
import { OperationProps } from "../OperationTypes";
import { AdbsUpdateTagsProps, newAdbsUpdateTags } from "./AdbsUpdateTags";

export interface AdbsEditTagsProps extends OperationProps {
  databaseId: string | undefined;
  defaultTags?: TagsInfoType;
}

const AdbsEditTags = (
  { location, databaseId, defaultTags, onExecute, onCancel }: AdbsEditTagsProps,
): JSX.Element => {
  const { trigger: triggerUpdateTags } = useOperation<AdbsUpdateTagsProps>(newAdbsUpdateTags);
  return (
    <TagsEditPanel
      title={Messages.labels.editTags()}
      resourceId={databaseId}
      tagsDefaultValue={defaultTags}
      onSubmit={(formValues: FormValues) => {
        const tags = getValue<TagsInfoType>(formValues, TagDetailsFields.Tags, InputFormGroup) ?? [];
        triggerUpdateTags({
          databaseId,
          tags,
          location,
          onExecute,
        });
      }}
      onClose={onCancel}
    />
  );
};

export const newAdbsEditTags = (
  props: AdbsEditTagsProps,
): JSX.Element => (<AdbsEditTags {...props} />);
