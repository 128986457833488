import * as React from "react";
import { OciResourceType } from "../../helpers/resourceHelper";
import { OciLink, OciLinkProps } from "./OciLink";

export interface OciSubnetLinkProps extends Pick<OciLinkProps, "location" | "subscription" | "analytics"> {
  id: string;
  vcnId: string;
  hideClipboard?: boolean;
}

export const OciSubnetLink = ({
  id,
  vcnId,
  location,
  subscription,
  analytics,
  hideClipboard,
}: OciSubnetLinkProps): JSX.Element => {
  const ociResourcePath = `/networking/vcns/${vcnId}/subnets/${id}`;

  return (
    <OciLink
      ociResourceType={OciResourceType.SUBNET}
      ociResourcePath={id && vcnId ? ociResourcePath : undefined}
      ociResourceName={id}
      location={location}
      subscription={subscription}
      analytics={analytics}
      hideClipboard={hideClipboard}
    />
  );
};
