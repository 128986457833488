import { LocalStorageDataKey } from "../constants/pluginConstants";

export const getLocalStorageData = (): { [propertyName: string]: string } | undefined => {
  const storageData = window.localStorage.getItem(LocalStorageDataKey);
  if (storageData) {
    try {
      return JSON.parse(storageData);
    } catch {
      // eslint-disable-next-line no-console
      console.error("Failed to parse parameters: invalid JSON format");
      return undefined;
    }
  }
  return undefined;
};

export const getLocalStorageSettingsByUser = (username: string): { [propertyName: string]: string } | undefined => {
  if (!username) {
    return undefined;
  }

  const storageData = window.localStorage.getItem(LocalStorageDataKey);
  if (storageData) {
    try {
      const storageObj = JSON.parse(storageData);
      if (storageObj) {
        return storageObj[username];
      }
      return undefined;
    } catch {
      // eslint-disable-next-line no-console
      console.error("Failed to parse parameters: invalid JSON format");
      return undefined;
    }
  }
  return undefined;
};

export const getLocalStorageSettingByUser = (username: string, prop: string): string | undefined => {
  if (!username || !prop) {
    return undefined;
  }

  const storageData = getLocalStorageData();

  try {
    if (storageData) {
      if (storageData[username]) {
        const userData = JSON.parse(storageData[username]);
        if (userData) {
          return userData[prop];
        }
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Failed to get local storage property by user", e);
  }

  return undefined;
};

export const deleteLocalStorageSettingByUser = (username: string, prop: string): void => {
  if (!username || !prop) {
    return;
  }

  const storageData = getLocalStorageData() || {};
  let userData: { [propertyName: string]: string | undefined } = {};

  try {
    if (storageData[username]) {
      userData = JSON.parse(storageData[username]);
      userData[prop] = undefined;
    }
    storageData[username] = JSON.stringify(userData);
    const strStorageData = JSON.stringify(storageData);
    window.localStorage.setItem(LocalStorageDataKey, strStorageData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Failed to unset local storage data", e);
  }
};

export const setLocalStorageSettingByUser = (username: string, prop: string, value: string): void => {
  if (!username || !prop) {
    return;
  }

  const storageData = getLocalStorageData() || {};
  let userData: { [propertyName: string]: string };
  try {
    if (storageData[username]) {
      userData = JSON.parse(storageData[username]);
      userData[prop] = value;
    } else {
      userData = {};
      userData[prop] = value;
    }
    storageData[username] = JSON.stringify(userData);
    const strStorageData = JSON.stringify(storageData);
    window.localStorage.setItem(LocalStorageDataKey, strStorageData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Failed to set local storage data", e);
  }
};

export const removeLocalStorageData = (): void => window.sessionStorage.removeItem(LocalStorageDataKey);
