import { LabelInfoTooltip, TagProps, TagsDisplay } from "o4a-react";
import * as React from "react";
import { ActivityItem, Icon } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { IdResourceType, parseId } from "../../helpers/idHelper";
import { getResourceDisplayType, getResourceIconName } from "../../helpers/resourceHelper";

export interface ResourceTagsProps {
  resourceId: string | undefined;
  initialValues: TagProps[] | undefined;
  newValues: TagProps[] | undefined;
  skipDeleted?: boolean;
}

interface TagsChanges {
  added: string[];
  updated: string[];
  deleted: string[];
}

const styles = {
  root: { padding: "5px", ":hover": { backgroundColor: "#f3f2f1", borderBottom: "1px solid #f3f2f1" } },
  activityContent: { width: "100%", padding: "5px 10px" },
  activityText: { fontSize: "13px", fontWeight: "400", color: "rgb(41, 40, 39)" },
  commentText: { margin: "5px 0px" },
  timeStamp: { fontSize: "13px", fontWeight: "400", color: "rgb(41, 40, 39)" },
};

const getChanges = (
  initialValuesMap: Map<string, string | undefined>,
  newValues: TagProps[] | undefined,
): TagsChanges => {
  const changes = { added: [], updated: [], deleted: [] } as TagsChanges;
  if (newValues) {
    const initialValuesMapCopy = new Map<string, string | undefined>(initialValuesMap);
    newValues.forEach(newValue => {
      const tagKey = newValue.name;
      if (initialValuesMapCopy.has(tagKey)) {
        const initialTagValue = initialValuesMapCopy.get(tagKey);
        // Entry updated
        if (initialTagValue !== newValue.value) {
          changes.updated.push(tagKey);
        }
        // Remove from copy
        initialValuesMapCopy.delete(tagKey);
      } else {
        // New entry added
        changes.added.push(tagKey);
      }
    });

    // Entries left in initial values map copy are the deleted ones
    initialValuesMapCopy.forEach((_, tagKey) => changes.deleted.push(tagKey));
  }
  return changes;
};

const buildFooter = (tagChanges: TagsChanges, skipDeleted: boolean): JSX.Element => {
  const noChanges = (<span>{Messages.hints.tagsNoChanges()}</span>);

  const countStrings = [];
  const tooltipStrings = [];

  if (tagChanges.added.length > 0) {
    countStrings.push(Messages.hints.tagsToAdd(tagChanges.added.length.toString()));
    tooltipStrings.push(Messages.hints.tagsTooltipAdding(tagChanges.added.join(", ")));
  }
  if (!skipDeleted && tagChanges.deleted.length > 0) {
    countStrings.push(Messages.hints.tagsToDelete(tagChanges.deleted.length.toString()));
    tooltipStrings.push(Messages.hints.tagsTooltipDeleting(tagChanges.deleted.join(", ")));
  }
  if (tagChanges.updated.length > 0) {
    countStrings.push(Messages.hints.tagsToUpdate(tagChanges.updated.length.toString()));
    tooltipStrings.push(Messages.hints.tagsTooltipUpdating(tagChanges.updated.join(", ")));
  }

  const changes = (
    <>
      <span>{countStrings.join(", ")}</span>
      <LabelInfoTooltip tooltip={tooltipStrings.join(", ")} />
    </>
  );

  return countStrings.length > 0 ? changes : noChanges;
};

export const ResourceTags = ({
  resourceId,
  initialValues,
  newValues,
  skipDeleted = false,
}: ResourceTagsProps): JSX.Element => {
  const { provider, resourceType, resourceName } = parseId(resourceId);

  const initialValuesMap = React.useMemo(() => {
    const map = new Map<string, string | undefined>();
    initialValues?.forEach(initialValue => map.set(initialValue.name, initialValue.value === ""
      ? undefined
      : initialValue.value));
    return map;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialValues)]);

  const icon = (
    <Icon
      iconName={getResourceIconName(provider, resourceType as IdResourceType)}
      styles={{ root: { width: "24px", height: "24px" } }}
    />
  );

  const tagsDisplay = <TagsDisplay tags={initialValues || []} />;

  // eslint-disable-next-line max-len
  const header = (<span>{`${resourceName} (${getResourceDisplayType(provider, resourceType as IdResourceType)})`}</span>);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const content = React.useMemo(() => tagsDisplay, [JSON.stringify(initialValues)]);
  const changes = getChanges(initialValuesMap, newValues);

  return (
    <ActivityItem
      activityIcon={icon}
      activityDescription={header}
      comments={content}
      timeStamp={buildFooter(changes, skipDeleted)}
      styles={styles}
    />
  );
};
