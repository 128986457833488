import { getConfigOverride, getMchubEnvironment, MchubEnvironment } from "../session/sessionConstants";

export interface FeatureNames {
  enableApex: boolean;
  enableMds: boolean;
  // McVCN
  enableMcvcn: boolean;
  enableMcvcnCreate: boolean;
  enableMdsMcvcnCreate: boolean;
  enableVmdbMcvcnCreate: boolean;
  enableExaMcvcnCreate: boolean;
  // Preflight
  useOpcDryRunHeader: boolean;
  enableMdsPreflight: boolean;
  enableVmdbPreflight: boolean;
  enableAdbsPreflight: boolean;
  enableExaInfraPreflight: boolean;
  enableExaVmClusterPreflight: boolean;
  enableExaDbPreflight: boolean;
  // Features
  enableAdbsOpenPL: boolean;
  enableAdbsRestrictedPL: boolean;
  enableAdbsAzurePE: boolean;
  enableAdbsAzurePEPeering: boolean;
  enableAdbsAzurePEPeeringUpdate: boolean;
  enableAdbsAJDWorkload: boolean;
  enableAdbsAPEXWorkload: boolean;
  enableAdbsPointInTimeRestore: boolean;
  enableAdbsCharacterSet: boolean;
  enableAddVMCapacity: boolean;
  enableExaDbHome: boolean;
  enableVmdbStoragePerformanceMode: boolean;
  enableVmdbStorageManagementType: boolean;
  // Debug
  debugInactivity: boolean;
}

export const useFeatures = (): FeatureNames => {
  const env: MchubEnvironment = getMchubEnvironment();

  const features = {
    enableApex: false,
    enableMds: true,
    // McVCN
    enableMcvcn: false,
    enableMcvcnCreate: false,
    enableMdsMcvcnCreate: false,
    enableVmdbMcvcnCreate: false,
    enableExaMcvcnCreate: false,
    // Preflight
    useOpcDryRunHeader: true,
    enableMdsPreflight: true,
    enableVmdbPreflight: true,
    enableAdbsPreflight: true,
    enableExaInfraPreflight: true,
    enableExaVmClusterPreflight: true,
    enableExaDbPreflight: true,
    // Features
    enableAdbsOpenPL: false,
    enableAdbsRestrictedPL: false,
    enableAdbsAzurePE: (env === MchubEnvironment.DEV || env === MchubEnvironment.PREPROD),
    enableAdbsAzurePEPeering: false,
    enableAdbsAzurePEPeeringUpdate: false,
    enableAdbsAJDWorkload: true,
    enableAdbsAPEXWorkload: true,
    enableAdbsPointInTimeRestore: false,
    enableAdbsCharacterSet: false,
    enableAddVMCapacity: false,
    enableExaDbHome: true,
    enableVmdbStoragePerformanceMode: true,
    enableVmdbStorageManagementType: true,
    // Debug
    debugInactivity: false,
  };

  const { features: featureOverrides } = getConfigOverride();

  return featureOverrides ? { ...features, ...featureOverrides } : features;
};
