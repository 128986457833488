import { clearNavHistoryStorage } from "o4a-react";
import * as React from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, PrimaryButton } from "@fluentui/react";
import * as Messages from "../codegen/Messages";
import { InactivityThresholdId } from "../helpers/settingsHelper";
import { useFeatures } from "../hooks/useFeatures";
import { logOut } from "../session/azureSession";
import { Settings, SettingsContext } from "./SettingsContext";

const USER_ACTIVITY_THROTTLER_TIME = 15 * 1000; // 15 secs
const CONFIRMATION_TIMEOUT = 1 * 60 * 1000; // 1 min

interface SignOutConfirmProps {
  hidden: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const SignOutConfirm = ({ hidden, onConfirm, onCancel }: SignOutConfirmProps): JSX.Element => {
  const dialogContentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: Messages.inactivitySignOut.confirmation.title(),
    subText: Messages.inactivitySignOut.confirmation.message(),
    styles: { title: { color: "black" } },
  };

  const modalProps = { isBlocking: true };

  return (
    <Dialog
      hidden={hidden}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <DialogFooter styles={{ actions: { display: "flex" } }}>
        <PrimaryButton
          text={Messages.inactivitySignOut.confirmation.buttonConfirm()}
          onClick={onConfirm}
        />
        <DefaultButton
          text={Messages.inactivitySignOut.confirmation.buttonCancel()}
          onClick={onCancel}
        />
      </DialogFooter>
    </Dialog>
  );
};

export const UserActivityMonitor = (): JSX.Element => {
  const { debugInactivity } = useFeatures();
  const [hideConfirmation, setHideConfirmation] = React.useState<boolean>(true);

  const consoleDebug = (msg: string): void => {
    if (debugInactivity) {
      // eslint-disable-next-line no-console
      console.debug(msg);
    }
  };

  const onDoNotSignOut = (): void => {
    clearTimeout(confirmationTimeout.current);
    setHideConfirmation(true);
    if (inactivityThreshold.id !== InactivityThresholdId.NEVER) {
      consoleDebug(`>>>> Restarting inactivity monitoring... (${inactivityThreshold.idleInMins})`);
      activateActivityTracker();
    } else {
      consoleDebug(`>>>> Inactivity monitoring disabled (${inactivityThreshold.id})`);
    }
  };

  const onSignOutNow = (): void => {
    clearTimeout(confirmationTimeout.current);
    setHideConfirmation(true);
    signOut();
  };

  const { inactivityThreshold } = React.useContext<Settings>(SettingsContext);

  const userActivityTimeout = React.useRef<number | undefined>(undefined);
  const userActivityThrottlerTimeout = React.useRef<number | undefined>(undefined);
  const isInactive = React.useRef<boolean>(true);
  const confirmationTimeout = React.useRef<number | undefined>(undefined);

  const signOut = ():void => {
    clearNavHistoryStorage();
    logOut();
  };

  const clearTimeouts = (): void => {
    if (userActivityThrottlerTimeout.current) clearTimeout(userActivityThrottlerTimeout.current);
    if (userActivityTimeout.current) clearTimeout(userActivityTimeout.current);
  };

  const activateActivityTracker = (): void => {
    window.addEventListener("mousemove", userActivityThrottler);
    window.addEventListener("scroll", userActivityThrottler);
    window.addEventListener("keydown", userActivityThrottler);
    window.addEventListener("resize", userActivityThrottler);
  };

  const deactivateActivityTracker = (): void => {
    window.removeEventListener("mousemove", userActivityThrottler);
    window.removeEventListener("scroll", userActivityThrottler);
    window.removeEventListener("keydown", userActivityThrottler);
    window.removeEventListener("resize", userActivityThrottler);
  };

  const resetUserActivityTimeout = (): void => {
    if (inactivityThreshold.idleInMins) {
      userActivityTimeout.current = window.setTimeout(() => {
        inactiveUserAction();
      }, inactivityThreshold.idleInMins * 60 * 1000);
    }
  };

  const userActivityThrottler = (): void => {
    if (isInactive.current) {
      isInactive.current = false;
      consoleDebug(`>>>> User is active at ${new Date(Date.now()).toLocaleTimeString()}`);
      clearTimeout(userActivityTimeout.current);
      userActivityTimeout.current = undefined;
    }

    if (!userActivityThrottlerTimeout.current) {
      userActivityThrottlerTimeout.current = window.setTimeout(() => {
        consoleDebug(`>>>> User is inactive at ${new Date(Date.now()).toLocaleTimeString()}`);
        isInactive.current = true;
        resetUserActivityTimeout();
        clearTimeout(userActivityThrottlerTimeout.current);
        userActivityThrottlerTimeout.current = undefined;
      }, USER_ACTIVITY_THROTTLER_TIME);
    }
  };

  const inactiveUserAction = (): void => {
    deactivateActivityTracker();
    // eslint-disable-next-line max-len
    consoleDebug(`>>>> User has been inactive and will be signout after 1 min from ${new Date(Date.now()).toLocaleTimeString()}`);
    confirmationTimeout.current = window.setTimeout(() => {
      setHideConfirmation(true);
      signOut();
    }, CONFIRMATION_TIMEOUT);
    setHideConfirmation(false);
  };

  React.useEffect(() => {
    consoleDebug(">>>> Reset inactivity monitoring...");
    clearTimeouts();
    deactivateActivityTracker();
    isInactive.current = true;

    if (inactivityThreshold.id !== InactivityThresholdId.NEVER) {
      consoleDebug(`>>>> Starting inactivity monitoring... (${inactivityThreshold.idleInMins})`);
      activateActivityTracker();
    } else {
      consoleDebug(`>>>> Inactivity monitoring disabled (${inactivityThreshold.id})`);
    }

    return deactivateActivityTracker;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(inactivityThreshold)]);

  return (
    <SignOutConfirm
      hidden={hideConfirmation}
      onConfirm={onDoNotSignOut}
      onCancel={onSignOutNow}
    />
  );
};
