import {
  ConfigInput,
  ConfigInputProps,
  FormValues,
  getValue,
  InputFormGroup,
  InputLink,
  optimizedRetryOption,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { DbSystemShapeSummary, DbSystemShapeSummaryCollection } from "../../gen/clients/mchub-azure-api-client-exa";
import { IdResourceType } from "../../helpers/idHelper";
import {
  getDefaultCoreCountPerNode,
  getMaxCoreCountPerNode,
  getMinCoreCountPerNode,
  isFlexDatabaseShape,
  isZeroCoreCountPerNodeAllowed,
  responseItemstoArray,
} from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { ConfigCoreCountPanel, Fields as ConfigCoreCountFields, ScaleConfig } from "./ConfigCoreCountPanel";

export enum NewSidePanels {
  CORE_COUNT_CONFIG = "CORE_COUNT_CONFIG",
}

type BasicProps = Omit<ConfigInputProps, "configInputValue" | "inputLink">

export interface VmClusterConfigInputProps extends BasicProps {
  subscriptionId: string;
  location: string;
  dbSystemShape?: string;
  dbServers?: number;
  isSystemCreate?: boolean;
  callback?: (value: VmClusterConfigInputValue) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}
export interface VmClusterConfigInputValue {
  value: number;
}

const getRequestedNodeCount = (shape?: DbSystemShapeSummary, dbServers?: number): number => {
  if (isFlexDatabaseShape(shape)) {
    return (dbServers || 1);
  }
  return (shape?.maximumNodeCount || 1);
};
export enum LinkTestIds {
  VmClusterConfigInputLink = "vm-cluster-config-link"
}

export const VmClusterConfigInput = (
  {
    fieldName,
    label,
    validator,
    subscriptionId,
    location,
    dbSystemShape,
    dbServers,
    isSystemCreate,
    statusInfo,
    required,
    callback,
    onMissingDependencies,
    testId,
  }: VmClusterConfigInputProps,
): JSX.Element => {
  const [sidePanelOpen, setSidePanelOpen] = React.useState<NewSidePanels | undefined>(undefined);

  const [key, setKey] = React.useState<string>(uniqueGUID());
  const [currentShape, setCurrentShape] = React.useState<DbSystemShapeSummary>();
  const [coreCount, setCoreCount] = React.useState<number>(getDefaultCoreCountPerNode(currentShape));

  const { loading: loadingShape, response: responseShape } = useQueryCall({
    wait: !(subscriptionId && location),
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDbSystemShapesBySubscription,
    options: {
      args: { subscriptionId },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVMClusterConfig(),
      },
    },
  });
  const shapeArr = (responseShape
    && responseItemstoArray<DbSystemShapeSummaryCollection, DbSystemShapeSummary>(responseShape)) || [];

  React.useEffect(() => {
    if (!loadingShape && dbSystemShape) {
      const tempShape = shapeArr?.find(shape => (shape.name === dbSystemShape));
      setCurrentShape(tempShape);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingShape, dbSystemShape]);

  React.useEffect(() => {
    setCoreCount(getDefaultCoreCountPerNode(currentShape));
    if (callback) {
      callback({ value: coreCount });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShape]);

  React.useEffect(() => {
    if (!loadingShape) setKey(uniqueGUID());
  }, [loadingShape]);

  function createConfigCoreCountLink(
    configLabel: string,
    sidePanelId: NewSidePanels,
  ): InputLink {
    return {
      id: "config_core_count",
      testId: LinkTestIds.VmClusterConfigInputLink,
      text: configLabel,
      onLinkClick: () => {
        const missingDependencies: IdResourceType[] = [];
        if (!dbSystemShape) missingDependencies.push(IdResourceType.CLOUD_EXADATA_INFRAS);
        if (onMissingDependencies && missingDependencies.length > 0) {
          onMissingDependencies(missingDependencies);
        } else {
          setSidePanelOpen(sidePanelId);
        }
      },
    };
  }

  // eslint-disable-next-line max-len
  const configCoreCountLink = createConfigCoreCountLink(Messages.actions.configureVMCluster(), NewSidePanels.CORE_COUNT_CONFIG);

  const onSubmitConfigCoreCount = (formValues: FormValues): void => {
    const newCoreCount = getValue<string>(formValues, ConfigCoreCountFields.CoreCount, InputFormGroup);
    setCoreCount(newCoreCount ? parseInt(newCoreCount, 10) : 0);
    setSidePanelOpen(undefined);
  };

  const scaleConfig: ScaleConfig = {
    defaultValue: coreCount,
    min: getMinCoreCountPerNode(currentShape),
    max: getMaxCoreCountPerNode(currentShape),
    isZeroAllowed: !isSystemCreate && isZeroCoreCountPerNodeAllowed(currentShape),
    nodeCount: getRequestedNodeCount(currentShape, dbServers),
  };

  return (
    <>
      <ConfigInput
        testId={testId}
        key={key}
        fieldName={fieldName}
        label={label}
        inputLink={configCoreCountLink}
        statusInfo={statusInfo}
        required={required}
        configInputValue={{
          title: Messages.configTitles.ocpuCountPerVM(coreCount?.toString()),
          summary: Messages.configTitles.requestedOCPUCount(
            (coreCount * getRequestedNodeCount(currentShape, dbServers)).toString(),
          ),
          value: { count: (coreCount * getRequestedNodeCount(currentShape, dbServers)) },
        }}
        validator={validator}
      />
      {sidePanelOpen === NewSidePanels.CORE_COUNT_CONFIG && (
        <ConfigCoreCountPanel
          scaleConfig={scaleConfig}
          targetId={configCoreCountLink.id}
          onSubmit={onSubmitConfigCoreCount}
          onClose={() => setSidePanelOpen(undefined)}
          title={Messages.createNewPanels.configCoreCount.title()}
        />
      )}
    </>
  );
};
