import { FormattedString, InfoBlockLayout, InfoBlockStatus, Select, SelectOption } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { licenseTypeUrl } from "../../constants/docConstants";
import { LICENSE_TYPE } from "../../helpers/resourceHelper";

export interface LicenseTypeSelectProps {
  defaultValue?: string;
  fieldName: string;
  label?: string;
  disabled?: boolean;
  testId?: string;
  onChange?: (id: string) => void
}

export enum LicenseTypeSelectOptionTestIds {
  LicenseIncluded = "license-included-option",
  BringYourOwnLicense = "byol-option",
}

function getLicenseTypes(): SelectOption[] {
  return [
    {
      id: LICENSE_TYPE.LICENSE_INCLUDED,
      testId: LicenseTypeSelectOptionTestIds.LicenseIncluded,
      text: Messages.labels.licenseIncluded(),
    },
    {
      id: LICENSE_TYPE.BRING_YOUR_OWN_LICENSE,
      testId: LicenseTypeSelectOptionTestIds.BringYourOwnLicense,
      text: Messages.labels.byol(),
    },
  ] as SelectOption[];
}

function licenseStatusInfo(licenseIncluded: JSX.Element, bringYourOwnLicense: JSX.Element): JSX.Element {
  return (
    <>
      {licenseIncluded}
      <br />
      {bringYourOwnLicense}
    </>
  );
}

export const LicenseTypeSelect = (
  { defaultValue, fieldName, label = Messages.labels.licenseType(), disabled, testId, onChange }
    : LicenseTypeSelectProps,
): JSX.Element => {
  const licenseTypes = getLicenseTypes();
  const licenseIncluded = FormattedString({ inputText: Messages.hints.licenseIncluded() });
  const BYOL = FormattedString(
    { inputText: Messages.hints.bringYourOwnLicense(licenseTypeUrl) },
  );

  return (
    <Select
      required
      testId={testId}
      fieldName={fieldName}
      label={label}
      options={licenseTypes}
      defaultValue={defaultValue ? [defaultValue] : undefined}
      statusInfo={disabled ? undefined : {
        message: licenseStatusInfo(licenseIncluded, BYOL),
        messageType: InfoBlockStatus.INFO,
        infoLayout: InfoBlockLayout.Compact,
      }}
      disabled={disabled}
      hideSearchBox
      onChange={onChange}
    />
  );
};
