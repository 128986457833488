import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  ConnectionStringsPanel,
  ConnectionStringsType,
} from "../../components/ConnectionStringsPanel/ConnectionStringsPanel";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { getConnectionStringType } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface AdbsViewConnectionStringsProps extends OperationProps {
  databaseId: string | undefined;
}

const AdbsViewConnectionStrings = ({
  databaseId,
  location,
  onCancel,
}: AdbsViewConnectionStringsProps): JSX.Element => {
  const idComps = parseId(databaseId);
  const resGroup = decodeURIComponent(idComps?.resourceGroup || "");
  const resourceName = decodeURIComponent(idComps?.resourceName || "");

  const { response, loading } = useQueryCall({
    wait: !databaseId,
    method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.getAdbsDatabase,
    options: {
      args: {
        subscriptionId: idComps?.subscriptionId || "",
        resourceGroupName: resGroup,
        databaseName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadAdbs(),
      },
    },
  });

  const adbs = response?.data;

  const connectionStrings: ConnectionStringsType = [];
  adbs?.connectionStrings?.profiles?.forEach(profile => (
    connectionStrings.push({
      id: profile.displayName,
      type: getConnectionStringType(profile),
      connectionString: profile.value,
    })));

  return (
    <ConnectionStringsPanel
      connectionStrings={connectionStrings}
      analytics={{
        pageId: PageId.ADB_LIST_HUB,
        panelId: DetailsPanelId.ADBS_DATABASES,
      }}
      loading={loading}
      onCancel={onCancel}
    />
  );
};

export const newAdbsViewConnectionStrings = (
  props: AdbsViewConnectionStringsProps,
): JSX.Element => (<AdbsViewConnectionStrings {...props} />);
