import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  Fields as SupportUpdateFields,
  SupportUpdatePanel,
} from "../../components/SupportUpdatePanel/SupportUpdatePanel";
import {
  IncidentProblemTypeEnum,
  UpdateActivityItemDetails,
  UpdateKindValues,
} from "../../gen/clients/mchub-azure-api-client";
import { parseSupportId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface SupportCloseValues {
  title: string | undefined;
  problemType: IncidentProblemTypeEnum | undefined;
}

export interface SupportCloseProps extends OperationProps {
  incidentId: string | undefined;
  supportCloseValues: SupportCloseValues;
}

const SupportClose = (
  { location, incidentId, supportCloseValues, onExecute, onCancel }: SupportCloseProps,
): JSX.Element => {
  const [panelRef, setPanelRef] = React.useState<InputFormSidePanelComponent>({} as InputFormSidePanelComponent);
  const { subscriptionId, resourceName } = parseSupportId(incidentId);
  const resName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).incidentsApi.updateIncident },
  );

  const onCloseTicketSubmit = (formValues: FormValues): void => {
    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: panelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.closeSupportRequest(),
          message: Messages.notifications.inProgress.messages.closeSupportRequest(supportCloseValues.title || ""),
        },
        success: {
          title: Messages.notifications.success.titles.closeSupportRequest(),
          message: Messages.notifications.success.messages.closeSupportRequest(supportCloseValues.title || ""),
        },
        failure: {
          title: Messages.notifications.failure.titles.closeSupportRequest(),
          message: Messages.notifications.failure.messages.closeSupportRequest(supportCloseValues.title || ""),
        },
      },
    };
    invokeUpdate({
      subscriptionId,
      incidentKey: resName,
      problemType: supportCloseValues.problemType,
      updateIncidentDetails: {
        problemType: supportCloseValues.problemType,
        item: {
          kind: UpdateKindValues.ACTIVITY,
          activityType: "CLOSE",
          comments: getValue<string>(formValues, SupportUpdateFields.Comments, InputFormGroup),
        } as UpdateActivityItemDetails,
      },
    }, invokeOptions);
  };

  return (
    <SupportUpdatePanel
      componentRef={setPanelRef}
      onSubmit={onCloseTicketSubmit}
      onClose={onCancel}
      title={Messages.labels.closeTicket()}
      submitText={Messages.actions.close()}
    />
  );
};

export const newSupportClose = (
  props: SupportCloseProps,
): JSX.Element => (<SupportClose {...props} />);
