import {
  ActionBar,
  ActionType,
  AnchoredPanelComponent,
  BookmarkablePage,
  DateTimeDisplay,
  DetailView,
  DetailViewsPanel,
  ErrorViewPanel,
  FormValues,
  getResourceLifecycleStatus,
  getValue,
  GroupItem,
  InfoBlockProps,
  InfoBlockStatus,
  InputFormGroup,
  LabelMetaItem,
  MetaItemSection,
  NoValue,
  stateT,
  Status,
  TitleDetails,
  uniqueGUID,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { OciExaCdbLink } from "../../components/OciLinks/OciExaCdbLink";
import { OciExaPdbLink } from "../../components/OciLinks/OciExaPdbLink";
import { ExaCdbLink } from "../../components/ResourceLinks/ExaCdbLink";
import { ExaDbHomeLink } from "../../components/ResourceLinks/ExaDbHomeLink";
import { ExaInfraLink } from "../../components/ResourceLinks/ExaInfraLink";
import { ExaVmClusterLink } from "../../components/ResourceLinks/ExaVmClusterLink";
import { Fields as TagDetailsFields, TagDetails, TagDetailsComponent } from "../../components/TagDetails/TagDetails";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  DetailsPanelId,
  InlineFormId,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds, SvgIconIds } from "../../constants/uiConstants";
import { Database, PluggableDatabase } from "../../gen/clients/mchub-azure-api-client-exa";
import { buildId, parseId } from "../../helpers/idHelper";
import {
  defaultExaUsername,
  getOpenModeStr,
  getStatusInfo,
  getTitleSuffix,
  ResourceStatus,
  TagsInfoType,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAppAuthContext } from "../../hooks/useAppAuthContext";
import { useOperation } from "../../hooks/useOperation";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { ExaDbClonePdbProps, newExaDbClonePdb } from "../../operations/ExaDb/ExaDbClonePdb";
import { ExaDbDeleteCdbProps, newExaDbDeleteCdb } from "../../operations/ExaDb/ExaDbDeleteCdb";
import { ExaDbDeletePdbProps, newExaDbDeletePdb } from "../../operations/ExaDb/ExaDbDeletePdb";
import { ExaDbEditTagsCdbProps, newExaDbEditTagsCdb } from "../../operations/ExaDb/ExaDbEditTagsCdb";
import { ExaDbEditTagsPdbProps, newExaDbEditTagsPdb } from "../../operations/ExaDb/ExaDbEditTagsPdb";
import { ExaDbRestoreCdbProps, newExaDbRestoreCdb } from "../../operations/ExaDb/ExaDbRestoreCdb";
import { ExaDbStartPdbProps, newExaDbStartPdb } from "../../operations/ExaDb/ExaDbStartPdb";
import { ExaDbStopPdbProps, newExaDbStopPdb } from "../../operations/ExaDb/ExaDbStopPdb";
import { ExaDbUpdateTagsCdbProps, newExaDbUpdateTagsCdb } from "../../operations/ExaDb/ExaDbUpdateTagsCdb";
import { ExaDbUpdateTagsPdbProps, newExaDbUpdateTagsPdb } from "../../operations/ExaDb/ExaDbUpdateTagsPdb";
import { ExaDbViewDbHomeProps, newExaDbViewDbHome } from "../../operations/ExaDb/ExaDbViewDbHome";
import { azurePortalUrl, getAzureLocationName } from "../../utils";
import { ExaDbBackupsList } from "./ExaDbBackupsList";
import { ExaDbConfigurationDetails } from "./ExaDbConfigurationDetails";
import { ExaDbConnectDetails } from "./ExaDbConnectDetails";
import { ExaDbManagementDetails } from "./ExaDbManagementDetails";
import { ExaDbPdbsList } from "./ExaDbPdbsList";

export enum ActionIds {
  Clone = "clone",
  LaunchMetrics = "launch-metrics",
  LaunchDashboard = "launch-dashboard",
  Restore = "restore",
  Refresh = "refresh",
  Start = "start",
  Stop = "stop",
  Delete = "delete",
  EditTags = "edit-tags",
}

export enum ActionBarTestIds {
  Clone = "clone",
  LaunchMetrics = "launch-metrics",
  LaunchDashboard = "launch-dashboard",
  Restore = "restore",
  Refresh = "refresh",
  Start = "start",
  Stop = "stop",
  Delete = "delete",
}

export enum MetaItemSectionTestIds {
  ContainerDatabase = "mis-cdb",
  DatabaseHome = "mis-db-home",
  DatabaseName = "mis-db-name",
  Infra = "mis-infra",
  Location = "mis-location",
  OciLink = "mis-oci-link",
  OpenMode = "mis-open-mode",
  LifecycleStatus = "mis-status",
  ResourceGroup = "mis-resource-group",
  Subscription = "mis-subscription",
  SubscriptionId = "mis-subscripion-id",
  Tags = "mis-tags",
  Username = "mis-username",
  VmCluster = "mis-vm-cluster",
  TimeCreated = "mis-time-created",
  DbUniqueName = "mis-db-unique-name",
}

export enum MetaItemActionTestIds {
  EditTags = "edit-tags",
}

export enum ResourceLinkTestIds {
  DbHome = "res-link-db-home"
}

export enum InfoBlockTestIds {
  DisabledActions = "disabled-actions-info-block"
}

export enum LinkTestIds {
  AddTags = "add-tags"
}

const getViewId = (panelId: string | undefined, dbRegistrationIds: string[]): string => {
  switch (panelId) {
    case DetailsPanelId.PLUGGABLEDATABASES:
      return dbRegistrationIds[6];
    case DetailsPanelId.BACKUPS:
      return dbRegistrationIds[5];
    case DetailsPanelId.MANAGEMENT:
      return dbRegistrationIds[4];
    case DetailsPanelId.CONFIGURATION:
      return dbRegistrationIds[3];
    case DetailsPanelId.CONNECT:
      return dbRegistrationIds[2];
    case DetailsPanelId.TAGS:
      return dbRegistrationIds[1];
    case DetailsPanelId.OVERVIEW:
    default:
      return dbRegistrationIds[0];
  }
};

const getViewTitle = (panelId: string | undefined, instanceName: string): string | TitleDetails => {
  switch (panelId) {
    case DetailsPanelId.PLUGGABLEDATABASES:
      return { primary: instanceName, secondary: Messages.labels.pluggableDatabases() };
    case DetailsPanelId.BACKUPS:
      return { primary: instanceName, secondary: Messages.labels.backups() };
    case DetailsPanelId.MANAGEMENT:
      return { primary: instanceName, secondary: Messages.labels.management() };
    case DetailsPanelId.CONFIGURATION:
      return { primary: instanceName, secondary: Messages.labels.configuration() };
    case DetailsPanelId.CONNECT:
      return { primary: instanceName, secondary: Messages.labels.connect() };
    case DetailsPanelId.TAGS:
      return { primary: instanceName, secondary: Messages.labels.tags() };
    case DetailsPanelId.OVERVIEW:
    default:
      return instanceName;
  }
};

const getViewIcon = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.PLUGGABLEDATABASES:
      return SvgIconIds.pluggableSvg;
    case DetailsPanelId.BACKUPS:
      return SvgIconIds.backupsSvg;
    case DetailsPanelId.MANAGEMENT:
      return SvgIconIds.managementSvg;
    case DetailsPanelId.CONFIGURATION:
      return SvgIconIds.configurationSvg;
    case DetailsPanelId.CONNECT:
      return SvgIconIds.connectSvg;
    case DetailsPanelId.TAGS:
      return SvgIconIds.tagsSvg;
    case DetailsPanelId.OVERVIEW:
    default:
      return SvgIconIds.exaSvg;
  }
};

export interface ExaDbDetailsPageProps {
  dbInstance: Database|PluggableDatabase|undefined,
  dbRefresh: () => void,
  dbError: NormalError|undefined,
  isCdb: boolean,
  dbRegistrationIds: string[],
  container?:{
    instance: Database|undefined,
    refresh: () => void,
  },
  panelId: string|undefined,
  location: string,
  resourceId: string,
}

export const ExaDbDetailsPage = ({
  dbInstance,
  dbRefresh,
  dbError,
  isCdb,
  dbRegistrationIds,
  container,
  panelId,
  location,
  resourceId,
}: ExaDbDetailsPageProps): JSX.Element => {
  const dbIdComps = parseId(resourceId);
  const {
    resourceGroup,
    resourceName,
    subscriptionId,
    provider,
    resourceType,
  } = dbIdComps;

  const { trackActionClick, trackActionDiscard, trackFormSave } = useAnalytics();
  const pageId = isCdb ? PageId.EXADB_CDB_DETAILS : PageId.EXADB_PDB_DETAILS;

  const { back, navigateToPanel, navigateTo } = useNavigation(ConsoleContext);

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  const tagDetailsRef = React.useRef<TagDetailsComponent>();
  const setTagDetailsRef = (panel: TagDetailsComponent): void => {
    tagDetailsRef.current = panel;
  };

  // eslint-disable-next-line max-len
  const subtitle = `${Messages.detailExaDb.titles.long()} | ${isCdb ? Messages.labels.containerDb() : Messages.labels.pluggableDatabase()}`;

  const { domainName } = useAppAuthContext();

  const [tagTabKey, setTagTabKey] = React.useState<string>(uniqueGUID());

  // Subscription
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();
  const subscription = !subscriptionLoading && subscriptions
    ? subscriptions.find(s => s.id === subscriptionId)
    : undefined;

  const exadbId = buildId({
    subscriptionId: subscriptionId || "",
    resourceGroup: resourceGroup || "",
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName,
  });

  const onClose = (): void => back();

  const refresh = (): void => {
    if (container) {
      container.refresh();
    }
    dbRefresh();
  };

  const containerDbStatus = container ? container.instance?.status : undefined;

  const cdbStatusThatDisableClonePdb = (): boolean => (
    containerDbStatus !== ResourceStatus.Active
    && containerDbStatus !== ResourceStatus.Updating
    && containerDbStatus !== ResourceStatus.BackupInProgress
    && containerDbStatus !== ResourceStatus.Succeeded);

  const cdbFailedState = (): boolean => (containerDbStatus === ResourceStatus.Failed);
  const pdbFailedState = (): boolean => (dbInstance?.status === ResourceStatus.Failed);

  const openModesThatDisablePdbStop = (): boolean => (((dbInstance as PluggableDatabase)?.openMode !== "READ_ONLY"
    && (dbInstance as PluggableDatabase)?.openMode !== "READ_WRITE"));
  const openModeThatDisablesPdbStart = (): boolean => ((dbInstance as PluggableDatabase)?.openMode !== "MOUNTED");

  const disabledActionsInfoBlocks = (): InfoBlockProps[] => {
    const disabledActionInfo: InfoBlockProps[] = [];
    const disabledActions: string[] = [];
    if (pdbFailedState()) {
      disabledActions.push(Messages.actions.start());
      disabledActions.push(Messages.actions.stop());
      disabledActions.push(Messages.actions.clone());
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          ? Messages.hints.pdbDisabledActionPdbStatus(disabledActions.toString(), stateT(dbInstance?.status || ""))
          : Messages.hints.pdbDisabledActionsPdbStatus(disabledActions.join(", "), stateT(dbInstance?.status || ""));
        disabledActionInfo.push(
          {
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    } else {
      if (cdbFailedState()) {
        disabledActions.push(Messages.actions.start());
        disabledActions.push(Messages.actions.stop());
      }
      if (cdbStatusThatDisableClonePdb()) {
        disabledActions.push(Messages.actions.clone());
      }
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          ? Messages.hints.pdbDisabledActionCdbStatus(disabledActions.toString(), stateT(containerDbStatus || ""))
          : Messages.hints.pdbDisabledActionsCdbStatus(disabledActions.join(", "), stateT(containerDbStatus || ""));
        disabledActionInfo.push(
          {
            testId: InfoBlockTestIds.DisabledActions,
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    }
    return disabledActionInfo;
  };

  React.useEffect(() => {
    setTagTabKey(uniqueGUID);
  }, [dbInstance]);

  const onEditTags = (): void => {
    if (isCdb) {
      triggerEditTagsCdb({
        databaseId: resourceId,
        defaultTags: tagsDefaultValue,
        location,
        onExecute: refresh,
        onCancel: () => trackActionDiscard(ActionIds.EditTags, pageId, DetailsPanelId.OVERVIEW),
      });
    } else {
      triggerEditTagsPdb({
        databaseId: resourceId,
        defaultTags: tagsDefaultValue,
        location,
        onExecute: refresh,
        onCancel: () => trackActionDiscard(ActionIds.EditTags, pageId, DetailsPanelId.OVERVIEW),
      });
    }
  };

  const tagsDefaultValue: TagsInfoType = Object.keys(dbInstance?.freeformTags || {})
    .map((key: string) => ({ name: key, value: dbInstance?.freeformTags?.[key] }));

  const onMenuItemSelect = (id: string): void => navigateToPanel(id);

  const dbHomeId = isCdb ? (dbInstance as Database)?.dbHomeId : container?.instance?.dbHomeId;

  const essentialsMetaItemGroup: JSX.Element[] = [
    <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="column1">
      <LabelMetaItem testId={MetaItemSectionTestIds.ResourceGroup} label={Messages.labels.resourceGroup()}>
        <AzureResourceGroupLink resourceId={resourceId} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.LifecycleStatus} label={Messages.labels.status()}>
        <Status
          iconPosition="right"
          label={stateT(dbInstance?.status || "")}
          status={getResourceLifecycleStatus(dbInstance?.status || "")}
          statusInfo={getStatusInfo(
            dbInstance?.status || "",
            dbInstance?.lastOperationStatus || "",
            dbInstance?.lastOperationStatusDetails || "",
            dbInstance?.lifecycleDetails,
          )}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Location} label={Messages.labels.location()}>
        {dbInstance?.location ? getAzureLocationName(dbInstance.location) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Subscription} label={Messages.labels.subscription()}>
        <AzureSubscriptionLink resourceId={resourceId} subscriptionName={subscription?.name} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.SubscriptionId} label={Messages.labels.subscriptionId()}>
        {subscriptionId || <NoValue />}
      </LabelMetaItem>
    </Stack>,
    <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="column2">
      <LabelMetaItem testId={MetaItemSectionTestIds.OciLink} label={Messages.labels.ociResource()}>
        <>
          {!isCdb && (
            <OciExaPdbLink
              id={resourceId}
              location={location}
              subscriptions={subscriptions || []}
              analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
            />
          )}
          {isCdb && (
            <OciExaCdbLink
              id={resourceId}
              location={location}
              subscriptions={subscriptions || []}
              analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
            />
          )}
        </>
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.DatabaseName} label={Messages.labels.databaseName()}>
        {isCdb ? (dbInstance as Database)?.dbName : (dbInstance as PluggableDatabase)?.pdbName}
      </LabelMetaItem>
      {isCdb && (
        <LabelMetaItem
          testId={MetaItemSectionTestIds.DbUniqueName}
          label={Messages.labels.databaseUniqueName()}
        >
          {(dbInstance as Database)?.dbUniqueName || <NoValue />}
        </LabelMetaItem>
      )}
      {isCdb && (
        <LabelMetaItem
          testId={MetaItemSectionTestIds.Username}
          label={Messages.labels.username()}
        >
          {defaultExaUsername}
        </LabelMetaItem>
      )}
      {!isCdb && (
        <LabelMetaItem label={Messages.labels.openMode()}>
          {(dbInstance as PluggableDatabase)?.openMode
            ? getOpenModeStr((dbInstance as PluggableDatabase).openMode) : <NoValue />}
        </LabelMetaItem>
      )}
      {!isCdb && (
        <LabelMetaItem testId={MetaItemSectionTestIds.ContainerDatabase} label={Messages.labels.containerDb()}>
          <ExaCdbLink
            databaseId={(dbInstance as PluggableDatabase)?.containerDatabaseId}
            location={location}
            analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
          />
        </LabelMetaItem>
      )}
      <LabelMetaItem testId={MetaItemSectionTestIds.DatabaseHome} label={Messages.labels.databaseHome()}>
        <ExaDbHomeLink
          dbHomeId={dbHomeId}
          location={location}
          analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
          onClick={() => {
            triggerViewDbHome({
              dbHomeId,
              subscriptions: subscriptions || [],
              location,
              analytics: { pageId, panelId: DetailsPanelId.OVERVIEW },
            });
          }}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.VmCluster} label={Messages.labels.vmCluster()}>
        <ExaVmClusterLink
          vmClusterId={(dbInstance && isCdb) ? (dbInstance as Database).vmClusterId : container?.instance?.vmClusterId}
          location={location}
          analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Infra} label={Messages.labels.exadataInfra()}>
        <ExaInfraLink
          vmClusterId={(dbInstance && isCdb) ? (dbInstance as Database).vmClusterId : container?.instance?.vmClusterId}
          location={location}
          analytics={{ pageId, panelId: DetailsPanelId.OVERVIEW }}
        />
      </LabelMetaItem>
      {dbInstance?.timeCreated && (
        <LabelMetaItem testId={MetaItemSectionTestIds.TimeCreated} label={Messages.labels.created()}>
          <DateTimeDisplay date={dbInstance.timeCreated} />
        </LabelMetaItem>
      )}
    </Stack>,
  ];

  const onMetrics = (): void => {
    const insightsId = (dbInstance as Database)?.observabilityDetails?.azureApplicationInsightsId;
    const dbMetricsUrl = `${azurePortalUrl}#@${domainName}/resource/${insightsId}/metricsV2`;
    window.open(dbMetricsUrl, "_blank");
  };

  const onDashboard = (): void => {
    const dashboardId = (dbInstance as Database)?.observabilityDetails?.azureDashboardId;
    const dbDashboardUrl = `${azurePortalUrl}#@${domainName}/dashboard/arm/${dashboardId}`;
    window.open(dbDashboardUrl, "_blank");
  };

  const { trigger: triggerDeleteCdb } = useOperation<ExaDbDeleteCdbProps>(newExaDbDeleteCdb);
  const { trigger: triggerDeletePdb } = useOperation<ExaDbDeletePdbProps>(newExaDbDeletePdb);
  const { trigger: triggerStartPdb } = useOperation<ExaDbStartPdbProps>(newExaDbStartPdb);
  const { trigger: triggerStopPdb } = useOperation<ExaDbStopPdbProps>(newExaDbStopPdb);
  const { trigger: triggerClonePdb } = useOperation<ExaDbClonePdbProps>(newExaDbClonePdb);
  const { trigger: triggerUpdateTagsCdb } = useOperation<ExaDbUpdateTagsCdbProps>(newExaDbUpdateTagsCdb);
  const { trigger: triggerUpdateTagsPdb } = useOperation<ExaDbUpdateTagsPdbProps>(newExaDbUpdateTagsPdb);
  const { trigger: triggerEditTagsCdb } = useOperation<ExaDbEditTagsCdbProps>(newExaDbEditTagsCdb);
  const { trigger: triggerEditTagsPdb } = useOperation<ExaDbEditTagsPdbProps>(newExaDbEditTagsPdb);
  const { trigger: triggerRestoreCdb } = useOperation<ExaDbRestoreCdbProps>(newExaDbRestoreCdb);
  const { trigger: triggerViewDbHome } = useOperation<ExaDbViewDbHomeProps>(newExaDbViewDbHome);

  const { closePanels } = useSidePanel();

  const overviewActionItems: ActionType[] = [
    ...(isCdb
      ? [
        {
          key: ActionIds.LaunchMetrics,
          testId: ActionBarTestIds.LaunchMetrics,
          text: Messages.actions.metrics(),
          icon: MonochromeIconIds.OpenInNewWindow,
          disabled: !(dbInstance as Database)?.observabilityDetails?.azureApplicationInsightsId,
          onClick: () => {
            trackActionClick(ActionIds.LaunchMetrics, pageId, DetailsPanelId.OVERVIEW);
            onMetrics();
          },
        },
        {
          key: ActionIds.LaunchDashboard,
          testId: ActionBarTestIds.LaunchDashboard,
          text: Messages.actions.dashboard(),
          icon: MonochromeIconIds.OpenInNewWindow,
          disabled: !(dbInstance as Database)?.observabilityDetails?.azureDashboardId,
          onClick: () => {
            trackActionClick(ActionIds.LaunchDashboard, pageId, DetailsPanelId.OVERVIEW);
            onDashboard();
          },
        },
        {
          key: ActionIds.Restore,
          testId: ActionBarTestIds.Restore,
          text: Messages.actions.restore(),
          icon: MonochromeIconIds.Database,
          onClick: () => {
            trackActionClick(ActionIds.Restore, pageId, DetailsPanelId.OVERVIEW);
            triggerRestoreCdb({
              databaseId: resourceId,
              timeCreated: (dbInstance as Database)?.timeCreated,
              targetId: ActionIds.Restore,
              location,
              onExecute: refresh,
              onCancel: () => trackActionDiscard(ActionIds.Restore, pageId, DetailsPanelId.OVERVIEW),
            });
          },
        },
      ]
      : []),
    ...(!isCdb
      ? [
        {
          key: ActionIds.Start,
          testId: ActionBarTestIds.Start,
          text: Messages.actions.start(),
          icon: MonochromeIconIds.Play,
          title: Messages.labels.startExaCSDB(),
          description: Messages.hints.startExaCSDBConfirmation(resourceName || ""),
          disabled: cdbFailedState() || pdbFailedState()
          || openModeThatDisablesPdbStart(),
          onClick: () => {
            trackActionClick(ActionIds.Start, pageId, DetailsPanelId.OVERVIEW);
          },
          onConfirm: () => {
            triggerStartPdb({
              databaseId: resourceId,
              location,
              onExecute: refresh,
            });
          },
          onCancel: () => trackActionDiscard(ActionIds.Start, pageId, DetailsPanelId.OVERVIEW),
        },
        {
          key: ActionIds.Stop,
          testId: ActionBarTestIds.Stop,
          text: Messages.actions.stop(),
          icon: MonochromeIconIds.Stop,
          title: Messages.labels.stopExaCSDB(),
          description: Messages.hints.stopExaCSDBConfirmation(resourceName || ""),
          disabled: cdbFailedState() || pdbFailedState()
          || openModesThatDisablePdbStop(),
          onClick: () => {
            trackActionClick(ActionIds.Stop, pageId, DetailsPanelId.OVERVIEW);
          },
          onConfirm: () => {
            triggerStopPdb({
              databaseId: resourceId,
              location,
              onExecute: refresh,
            });
          },
          onCancel: () => trackActionDiscard(ActionIds.Stop, pageId, DetailsPanelId.OVERVIEW),
        },
        {
          key: ActionIds.Clone,
          testId: ActionBarTestIds.Clone,
          text: Messages.actions.clone(),
          icon: MonochromeIconIds.Database,
          disabled: cdbStatusThatDisableClonePdb() || pdbFailedState(),
          onClick: () => {
            trackActionClick(ActionIds.Clone, pageId, DetailsPanelId.OVERVIEW);
            triggerClonePdb({
              databaseId: resourceId,
              location,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onExecute: (pdbName: any) => {
                const cloneId = buildId({
                  subscriptionId,
                  resourceGroup,
                  provider,
                  resourceType,
                  resourceName: pdbName,
                });
                refresh();
                navigateTo(
                  // eslint-disable-next-line max-len
                  `${RESOURCE_ROUTE}/${cloneId}/${PageRegistrationConfig[PageId.EXADB_PDB_DETAILS][0].panelPath}?location=${location}`,
                  dbRegistrationIds[0],
                );
              },
              onCancel: () => trackActionDiscard(ActionIds.Clone, pageId, DetailsPanelId.OVERVIEW),
            });
          },
        },
      ]
      : []),
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, pageId, DetailsPanelId.OVERVIEW);
        refresh();
      },
    },
    {

      key: ActionIds.Delete,
      testId: ActionBarTestIds.Delete,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.labels.deleteExaCSDB(),
      description: Messages.hints.deleteExaCSDBConfirmation(resourceName || ""),
      onClick: () => {
        trackActionClick(ActionIds.Delete, pageId, DetailsPanelId.OVERVIEW);
      },
      onConfirm: () => {
        if (isCdb) {
          triggerDeleteCdb({
            databaseId: resourceId,
            location,
            onExecute: onClose,
          });
        } else {
          triggerDeletePdb({
            databaseId: resourceId,
            location,
            onExecute: onClose,
          });
        }
      },
      onCancel: () => trackActionDiscard(ActionIds.Delete, pageId, DetailsPanelId.OVERVIEW),
    },
  ];

  const detailViews: DetailView[] = [
    {
      id: dbRegistrationIds[0],
      content: (
        <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
          <ActionBar
            actions={overviewActionItems}
            infoBlocks={containerDbStatus ? disabledActionsInfoBlocks() : []}
            onActionClick={closePanels}
          />
          <MetaItemSection
            labelWidth={185}
            tags={{
              testId: MetaItemSectionTestIds.Tags,
              items: tagsDefaultValue.length ? tagsDefaultValue : undefined,
              add: {
                testId: LinkTestIds.AddTags,
                onClick: () => {
                  trackActionClick(ActionIds.EditTags, pageId, DetailsPanelId.OVERVIEW);
                  onEditTags();
                },
              },
              edit: {
                id: ActionIds.EditTags,
                testId: MetaItemActionTestIds.EditTags,
                onClick: () => {
                  trackActionClick(ActionIds.EditTags, pageId, DetailsPanelId.OVERVIEW);
                  onEditTags();
                },
              },
            }}
          >
            {essentialsMetaItemGroup}
          </MetaItemSection>
        </Stack>
      ),
    },
    {
      id: dbRegistrationIds[1],
      content: (
        <TagDetails
          key={tagTabKey}
          resourceId={exadbId}
          tagsDefaultValue={tagsDefaultValue}
          onApply={(formValues: FormValues) => {
            trackFormSave(InlineFormId.TAGS, pageId, DetailsPanelId.TAGS);
            const tags = getValue<TagsInfoType>(
              formValues,
              TagDetailsFields.Tags,
              InputFormGroup,
            ) ?? [];
            // *** Do not call refresh when updating tags on the tags tab to avoid resetting user input ***
            const updateTagsProps = {
              databaseId: resourceId,
              tags,
              location,
              onExecute: () => tagDetailsRef.current?.toggleInProgress(false, panelRef.current),
            };
            tagDetailsRef.current?.toggleInProgress(true, panelRef.current);
            if (isCdb) {
              triggerUpdateTagsCdb(updateTagsProps);
            } else {
              triggerUpdateTagsPdb(updateTagsProps);
            }
          }}
          componentRef={setTagDetailsRef}
        />
      ),
    },
    {
      id: dbRegistrationIds[2],
      content: (<ExaDbConnectDetails
        database={dbInstance}
        isCdb={isCdb}
      />),
    },
    ...(isCdb
      ? [
        {
          id: dbRegistrationIds[3],
          content: (<ExaDbConfigurationDetails
            cdb={dbInstance as Database}
          />),
        },
        {
          id: dbRegistrationIds[4],
          content: (<ExaDbManagementDetails
            location={location}
            cdb={dbInstance as Database}
            refresh={refresh}
          />),
        },
        {
          id: dbRegistrationIds[5],
          content: (<ExaDbBackupsList
            location={location}
            databaseName={(dbInstance as Database)?.name}
            databaseId={resourceId}
          />),
        },
        {
          id: dbRegistrationIds[6],
          content: (<ExaDbPdbsList
            cdb={dbInstance as Database}
            location={location}
          />),
        },
      ]
      : []),
  ];

  const resourcesGroups: GroupItem = {
    heading: Messages.labels.resources(),
    items: [
      { icon: SvgIconIds.backupsSvg, id: detailViews[5]?.id, name: Messages.labels.backups() },
      { icon: SvgIconIds.pluggableSvg, id: detailViews[6]?.id, name: Messages.labels.pluggableDatabases() },
    ],
  };

  const groupItems: GroupItem[] = [
    {
      items: [
        {
          icon: SvgIconIds.exaSvg,
          id: detailViews[0].id,
          name: Messages.labels.overview(),
        },
        {
          icon: SvgIconIds.tagsSvg,
          id: detailViews[1].id,
          name: Messages.labels.tags(),
        },
      ],
    },
    {
      heading: Messages.labels.settings(),
      items: [
        { icon: SvgIconIds.connectSvg, id: detailViews[2].id, name: Messages.labels.connect() },
        ...(isCdb
          ? [{ icon: SvgIconIds.configurationSvg, id: detailViews[3].id, name: Messages.labels.configuration() },
            { icon: SvgIconIds.managementSvg, id: detailViews[4].id, name: Messages.labels.management() }]
          : []),
      ],
    },
  ];

  if (isCdb) {
    groupItems.push(resourcesGroups);
  }

  const renderedContent = (): JSX.Element => {
    if (dbError) {
      const isNotFound = dbError.status === 404;
      const errorTitle = isNotFound
        ? Messages.detailExaDb.loadError.notFound.title()
        : Messages.detailExaDb.loadError.general.title();
      const errorDetail = isNotFound
        ? Messages.detailExaDb.loadError.notFound.details()
        : Messages.detailExaDb.loadError.general.details();

      return (
        <ErrorViewPanel
          icon={SvgIconIds.exaSvg}
          title={resourceName}
          errorTitle={errorTitle}
          resourceId={exadbId}
          errorCode={dbError?.status || 0}
          details={[
            errorDetail,
            Messages.notifications.apiErrorMsg(dbError?.body?.message || ""),
          ]}
          isOpen
          onClose={onClose}
          favoriteAccessRegistration={{ id: exadbId, data: { location } }}
        />
      );
    }
    return (
      <DetailViewsPanel
        componentRef={setPanelRef}
        title={getViewTitle(panelId, resourceName || "")}
        subTitle={subtitle}
        icon={getViewIcon(panelId)}
        onClose={onClose}
        isOpen
        views={detailViews}
        activeViewId={getViewId(panelId, dbRegistrationIds)}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
        status={(dbInstance?.status !== ResourceStatus.Succeeded && dbInstance?.status !== ResourceStatus.Active) ? {
          status: getResourceLifecycleStatus(dbInstance?.status || ""),
          tooltip: stateT(dbInstance?.status || ""),
        } : undefined}
        favoriteAccessRegistration={{ id: resourceId, data: { location } }}
      />
    );
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={dbRegistrationIds}
      title={resourceName || Messages.detailExaDb.titles.short()}
      titleSuffix={resourceName ? getTitleSuffix(panelId || "") : undefined}
      recentAccessRegistration={{ id: resourceId, data: { location } }}
    >
      {renderedContent()}
    </BookmarkablePage>
  );
};
