import {
  CalloutComponent,
  FormValues,
  getValue,
  InputFormCallout,
  InputFormGroup,
  SelectOption,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { LicenseTypeSelect } from "../../components/LicenseTypeSelect/LicenseTypeSelect";
import { CalloutTestIds } from "../../constants/uiConstants";
import { UpdateVmdbDbSystemDetailsLicenseModelEnum } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_DB_SYSTEM_UPDATE_POLL_DELAY,
  VMDB_DB_SYSTEM_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export enum Fields {
  LicenseType = "licenseType",
}

export enum FieldTestIds {
  LicenseType = "license-type-field",
}

export interface VmDbSystemEditLicenseProps extends OperationActionProps {
  dbSystemId: string | undefined;
  systemLicenseModel: string | undefined;
}

const VmDbSystemEditLicense = (
  { targetId, location, dbSystemId, systemLicenseModel, onCancel, onExecute }: VmDbSystemEditLicenseProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(dbSystemId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (systemLicenseModel) {
      const initFieldValues: FormValues = { [Fields.LicenseType]: [systemLicenseModel] };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemLicenseModel]);

  const { invokeCall: invokeUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.updateVmdbDbSystem },
  );

  const [licenseCalloutRef, setLicenseCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const onEditLicense = (formValues: FormValues): void => {
    const licenseModel = getValue<SelectOption[]>(
      formValues,
      Fields.LicenseType,
      InputFormGroup,
    )?.[0].id as UpdateVmdbDbSystemDetailsLicenseModelEnum | undefined;

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: licenseCalloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateVmDbSystemLicense(),
          message: Messages.notifications.inProgress.messages.updateVmDbSystemLicense(resName),
        },
        success: {
          title: Messages.notifications.success.titles.updateVmDbSystemLicense(),
          message: Messages.notifications.success.messages.updateVmDbSystemLicense(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateVmDbSystemLicense(),
          message: Messages.notifications.failure.messages.updateVmDbSystemLicense(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_DB_SYSTEM_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            dbSystemName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_DB_SYSTEM_UPDATED_CHECK,
          delay: VMDB_DB_SYSTEM_UPDATE_POLL_DELAY,
          interval: VMDB_DB_SYSTEM_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeUpdate({
      dbSystemName: resName,
      resourceGroupName: resGroup,
      subscriptionId,
      updateDbSystemDetails: { licenseModel },
    }, invokeOptions);
  };

  return (
    <InputFormCallout
      testId={CalloutTestIds.VmDbSystemEditLicense}
      componentRef={setLicenseCalloutRef}
      targetId={targetId}
      title={Messages.createNewPanels.licenseType.title()}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onEditLicense}
      onClose={onCancel}
      initialValues={initialValues}
    >
      <LicenseTypeSelect
        testId={FieldTestIds.LicenseType}
        fieldName={Fields.LicenseType}
        defaultValue={systemLicenseModel}
        label={Messages.labels.licenseType()}
      />
    </InputFormCallout>
  );
};

export const newVmDbSystemEditLicense = (
  props: VmDbSystemEditLicenseProps,
): JSX.Element => (<VmDbSystemEditLicense {...props} />);
