import {
  CourierTransport,
  CourierTransportConfig,
  MultiCloudTelemetryConfig,
  MultiCloudTelemetryTransport,
  TelemetryClient,
  Transport,
} from "console-telemetry-client";
import { AzAuth, AzAuthConfig } from "loom-az-auth-service";
import { o4aMessagesInit } from "o4a-react";
import { NormalError, normalizeError, Store } from "savant-connector";
import { AccountInfo, LogLevel } from "@azure/msal-browser";
import apiClients, { noContentResponseInterceptor } from "../apiClients";
import * as Messages from "../codegen/Messages";
import { BASE_PATH, LanguageStorageProp } from "../constants/pluginConstants";
import { AzureCloudLinkSummary } from "../gen/clients/mchub-azure-api-client";
import { getLocalStorageSettingByUser } from "../helpers/localStorageHelper";
import { languageRequestInterceptor } from "../helpers/supportedLocales";
import { apiErrorHandler, telemetryRequestInterceptor, telemetryResponseInterceptor } from "../helpers/telemetryHelper";
import { loadTrusteScript } from "../models/truste";
import { getOciRegion, getQParam, getRealmRegions, getTopLevel, regions, removeSessionStorageData } from "../utils";
import {
  clientId,
  courierEndpoint,
  InitState,
  InitStatus,
  TELEMETRY_SERVICE_BATCH_SIZE,
  TELEMETRY_SERVICE_MAX_RETRIES,
  telemetryAdaptorEndpoint,
  telemetryPrefix,
  telemetrySource,
  TelemetryTransport,
  telemetryTransport,
} from "./sessionConstants";

/* eslint-disable import/no-mutable-exports */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const storeObj: any = {};
export const savantStore: Store = {
  get: key => storeObj[key],
  put: (key, value) => { storeObj[key] = value; },
};

const defaultLocale = "en";
const loginScopes = ["https://management.azure.com//.default openid profile"];
const ApiScopes = [`api://${clientId}/myscopes openid profile`];

const configTelemetry = (accountInfo: AccountInfo): void => {
  const fetchApi = AzAuth.instance.getFetchApi({ scopes: ApiScopes });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sid = (accountInfo.idTokenClaims as any)?.sid as string;
  let transport: Transport;
  switch (telemetryTransport) {
    case TelemetryTransport.COURIER:
      {
        const courierConfig: CourierTransportConfig = {
          fetchApi,
          namespace: telemetrySource, // do not change
          courierUrl: courierEndpoint, // for all environment except productions use test url
          prefix: telemetryPrefix, // start with o4a_console then environment
          userSession: sid, // guid something not identifiable
        };
        transport = new CourierTransport(courierConfig);
      }
      break;
    case TelemetryTransport.MULTI_CLOUD:
    default:
      {
        const MULTI_CLOUD_TELEMETRY_CONFIG: MultiCloudTelemetryConfig = {
          serviceLimit: TELEMETRY_SERVICE_BATCH_SIZE,
          maxRetries: TELEMETRY_SERVICE_MAX_RETRIES,
          mcTelemetryAdapterURLs: [telemetryAdaptorEndpoint],
          postToTelemetryService: true,
          source: telemetrySource,
          namespace: telemetryPrefix,
        };
        transport = new MultiCloudTelemetryTransport({
          ...MULTI_CLOUD_TELEMETRY_CONFIG,
          fetchApi,
          userSession: sid,
        });
      }
      break;
  }
  TelemetryClient.addTransport(transport);
};

const init = async (azAuthAccountInfo: AccountInfo): Promise<void> => {
  const accountInfo = await AzAuth.instance.getAccount();

  savantStore.put("userData", {
    name: accountInfo.name,
    username: accountInfo.username,
    tenant: accountInfo.tenantId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    roles: (azAuthAccountInfo.idTokenClaims as any)?.roles,
  });

  const locale = getLocalStorageSettingByUser(accountInfo.username, LanguageStorageProp) || defaultLocale;

  await Messages.init(locale);
  o4aMessagesInit(locale);

  configTelemetry(accountInfo);

  const homeRegionName = getRealmRegions().defaultRegion;

  apiClients.initialize({
    store: savantStore,
    azAuth: AzAuth.instance,
    baseApiConfig: {
      requestInterceptors: [
        languageRequestInterceptor,
        telemetryRequestInterceptor,
      ],
      responseInterceptors: [
        telemetryResponseInterceptor,
        noContentResponseInterceptor,
      ],
      errorHandler: apiErrorHandler,
    },
    initialRegion: homeRegionName,
    secondLevelDomain: getTopLevel(homeRegionName),
  });
};

interface AzureTenant {
  countryCode: string;
  defaultDomain: string;
  displayName: string;
  domains: string[];
  id: string;
  tenantCategory: string;
  tenantId: string;
  tenantType: string;
}
interface AzureTenantsCollection {
  value: AzureTenant[];
}

const tenantInit = async () : Promise<void> => {
  try {
    const { tenantId } = await AzAuth.instance.getAccount();
    const fetchApi = AzAuth.instance.getFetchApi({ scopes: loginScopes });
    const requestOrg = new Request("https://management.azure.com/tenants?api-version=2020-01-01", { method: "GET" });
    const responseOrg = await fetchApi(requestOrg);
    const tenantsData: AzureTenantsCollection = await responseOrg.json();
    const currentTenant = tenantsData?.value?.find(tenant => tenant.tenantId === tenantId);

    savantStore.put("userData", {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...savantStore.get("userData") as any,
      tenantName: currentTenant?.displayName,
      domainName: currentTenant?.defaultDomain,
    });
  } catch {
    // eslint-disable-next-line no-console
    console.log("Unable to retrieve tenant information");
  }
};

const msalConfig: AzAuthConfig = {
  msalConfig: {
    auth: {
      clientId,
      authority: "https://login.microsoftonline.com/common/", // "9e83b600-7c5a-44f4-b1ab-58bcfda44779",
      redirectUri: `${window.location.origin}/${BASE_PATH}`,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Verbose,
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              // eslint-disable-next-line no-console
              console.error(message);
              return;
            case LogLevel.Info:
              // eslint-disable-next-line no-console
              console.info(message);
              return;
            case LogLevel.Verbose:
              // eslint-disable-next-line no-console
              console.debug(message);
              return;
            case LogLevel.Warning:
              // eslint-disable-next-line no-console
              console.warn(message);
              break;
            default:
          }
        },
      },
    },
  },
  scopes: loginScopes,
};

const isCanarySession = (): boolean => {
  try {
    const canaryVal = getQParam("canary");
    return canaryVal ? JSON.parse(canaryVal) : false;
  } catch (e) {
    return false;
  }
};

const checkAccountStatus = async (): Promise<InitState> => {
  // *** Just for testing. To be removed after the logic for detecting the linked state is fully implemented ***
  // *** and there are linked accounts to work with ***
  const linked = Boolean(getQParam("linked"));

  const { tenantId } = await AzAuth.instance.getAccount();

  const activeCloudLinks: AzureCloudLinkSummary[] = [];
  const inProgressCloudLinks: AzureCloudLinkSummary[] = [];
  try {
    // eslint-disable-next-line max-len
    const mcpRegions = await (await apiClients.withRegion(getRealmRegions().defaultRegion).regionsApi.listRegionsInfo({}))?.data?.items;
    if (mcpRegions && mcpRegions.length > 0) {
      // replace contents of regions with regions obtained from MCP
      regions.splice(0, regions.length, ...mcpRegions);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  const cloudLinks = await (
    await apiClients.withRegion(getRealmRegions().defaultRegion).cloudLinkApi.listAzureCloudLinks({})
  ).data;
  cloudLinks?.items.forEach(cloudLink => {
    if (cloudLink.lifecycleState === "ACTIVE" || cloudLink.lifecycleState === "UPDATING") {
      activeCloudLinks.push(cloudLink);
    }
    if (cloudLink.lifecycleState === "CREATING") {
      inProgressCloudLinks.push(cloudLink);
    }
  });

  if (activeCloudLinks.length > 0) {
    const allSubscriptionIds: string[] = [];
    const allPrimaryLocations: string[] = [];
    const allLocations: string[] = [];
    activeCloudLinks?.forEach(cloudLink => {
      if (cloudLink.subscriptionIds) {
        allSubscriptionIds.push(...cloudLink.subscriptionIds as string[]);
      }
      if (cloudLink.primaryLocation) {
        allPrimaryLocations.push(cloudLink.primaryLocation);
      }
      if (cloudLink.locations) {
        allLocations.push(...cloudLink.locations as string[]);
      }
    });

    // Make arrays unique
    const subscriptionIds = new Set<string>(allSubscriptionIds);
    const primaryLocations = new Set<string>(allPrimaryLocations);
    const locations = new Set<string>(allLocations);

    // It is now OK to have 0 subscriptionIds but 1 location MUST be present
    if (locations.size === 0) {
      return {
        initStatus: InitStatus.ACCOUNTS_LINKING_MISCONFIGURED,
        error: {
          status: 500,
          body: { message: Messages.initErrors.errorMessages.noLocation() },
        } as NormalError,
        tenancyId: tenantId,
        homeRegionName: getRealmRegions().defaultRegion,
      };
    }
    if (allSubscriptionIds.length !== subscriptionIds.size) {
      return {
        initStatus: InitStatus.ACCOUNTS_LINKING_MISCONFIGURED,
        error: {
          status: 500,
          body: { message: Messages.initErrors.errorMessages.duplicateSubscriptions() },
        } as NormalError,
        tenancyId: tenantId,
        homeRegionName: getRealmRegions().defaultRegion,
      };
    }

    let homeRegionName;
    const [defaultPrimaryLocation] = primaryLocations;
    if (defaultPrimaryLocation) {
      homeRegionName = getOciRegion(defaultPrimaryLocation);
    }
    if (!homeRegionName) {
      const [defaultLocation] = locations;
      if (defaultLocation) {
        homeRegionName = getOciRegion(defaultLocation);
      }
    }

    if (homeRegionName) {
      apiClients.setActiveRegion(homeRegionName);
    } else {
      homeRegionName = getRealmRegions().defaultRegion;
    }

    return { initStatus: InitStatus.ACCOUNTS_LINKED, tenancyId: tenantId, homeRegionName };
  }
  if (inProgressCloudLinks.length > 0) {
    return {
      initStatus: InitStatus.ACCOUNTS_LINKING_IN_PROGRESS,
      tenancyId: tenantId,
      homeRegionName: getRealmRegions().defaultRegion,
    };
  }

  if (linked) {
    return {
      initStatus: InitStatus.ACCOUNTS_LINKED,
      tenancyId: tenantId,
      homeRegionName: getRealmRegions().defaultRegion,
    };
  }

  return {
    initStatus: InitStatus.ACCOUNTS_NOT_LINKED,
    tenancyId: tenantId,
    homeRegionName: getRealmRegions().defaultRegion,
  };
};

const needRefreshAuthToken = (): boolean => {
  const url = new URL(document.location.href);
  const refreshToken = url.searchParams.get("refreshToken");
  if (refreshToken === "true") {
    url.searchParams.delete("refreshToken");
    window.history.replaceState({}, "", url.toString());
    return true;
  }
  return false;
};

const getTenancyIdFromUrl = (): string | undefined => {
  const url = new URL(document.location.href);
  const tenancyId = url.searchParams.get("tenancyId");
  return (tenancyId || undefined);
};

export const userAuthInit = async (): Promise<InitState> => {
  let initMessages = true;
  try {
    const AzAuthInst = await AzAuth.init(msalConfig);
    const forceAuthenticate = needRefreshAuthToken(); // If refreshToken query param is passed
    await AzAuthInst.authenticate(forceAuthenticate, getTenancyIdFromUrl());
    initMessages = false;
    await init(AzAuthInst.getAccount());
  } catch (e) {
    if (initMessages) await Messages.init(defaultLocale);
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      initStatus: InitStatus.INIT_FAILED,
      error: {
        status: 500,
        body: { message: `Failed to initialize the console: ${e}` },
      } as NormalError,
      isCanarySession: isCanarySession(),
    } as InitState;
  }

  try {
    loadTrusteScript();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  try {
    const accountState = await checkAccountStatus();
    if (accountState?.initStatus === InitStatus.ACCOUNTS_LINKED) {
      await tenantInit();
    }
    accountState.isCanarySession = isCanarySession();
    return accountState;
  } catch (e) {
    const normalError = await normalizeError(e);
    // eslint-disable-next-line no-console
    console.error(JSON.stringify(e));
    return {
      initStatus: InitStatus.ACCOUNTS_LINKED_UNKNOWN,
      error: normalError,
      isCanarySession: isCanarySession(),
    } as InitState;
  }
};
//--------------------------------------------------------
export const logOut = (): Promise<void> => {
  removeSessionStorageData();
  return AzAuth.instance.logOut();
};
