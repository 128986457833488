import { ActionBar, ActionType, LabelMetaItem, MetaItemSection, NoValue } from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds } from "../../constants/uiConstants";
import { MdsDbSystem } from "../../gen/clients/mchub-azure-api-client-mds";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useSidePanel } from "../../hooks/useSidePanel";
import { MysqlEditShapeProps, newMysqlEditShape } from "../../operations/Mysql/MysqlEditShape";
import { MysqlEditStorageProps, newMysqlEditStorage } from "../../operations/Mysql/MysqlEditStorage";
import { MysqlEditVersionProps, newMysqlEditVersion } from "../../operations/Mysql/MysqlEditVersion";

export enum ActionIds {
  Refresh = "refresh",
  EditStorageSize = "edit-storage-size",
  EditVersion = "edit-version",
  EditShape = "edit-shape",
}

export enum ActionBarTestIds {
  Refresh = "refresh",
}

export enum MetaItemSectionTestIds {
  MYSQL_VERSION = "mis-mysql-version",
  SHAPE = "mis-shape",
  DATA_STORAGE = "mis-total-storage",
}

export enum MetaItemActionTestIds {
  EditStorageSize = "edit-storage-size",
  EditVersion = "edit-version",
  EditShape = "edit-shape",
}

export interface MysqlConfigurationDetailsProps {
  mdsDbSystem: MdsDbSystem | undefined;
  mdsDbSystemId: string;
  location: string;
  refresh: () => void;
}

export const MysqlConfigurationDetails = (
  { mdsDbSystem, mdsDbSystemId, location, refresh }: MysqlConfigurationDetailsProps,
): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { trigger: triggerEditStorage } = useOperation<MysqlEditStorageProps>(newMysqlEditStorage);
  const { trigger: triggerEditVersion } = useOperation<MysqlEditVersionProps>(newMysqlEditVersion);
  const { trigger: triggerEditShape } = useOperation<MysqlEditShapeProps>(newMysqlEditShape);
  const { closePanels } = useSidePanel();

  const configurationActionItems: ActionType[] = [
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.MYSQL_DETAILS, DetailsPanelId.CONFIGURATION);
        refresh();
      },
    },
  ];

  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
      <ActionBar actions={configurationActionItems} onActionClick={closePanels} />
      <MetaItemSection labelWidth={200}>
        <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.SHAPE}
            label={Messages.labels.dbSystemShape()}
            action={{
              id: ActionIds.EditShape,
              testId: MetaItemActionTestIds.EditShape,
              label: Messages.actions.edit(),
              onClick: () => {
                trackActionClick(ActionIds.EditShape, PageId.MYSQL_DETAILS, DetailsPanelId.CONFIGURATION);
                triggerEditShape({
                  shape: mdsDbSystem?.shapeName,
                  databaseId: mdsDbSystemId,
                  location,
                  targetId: ActionIds.EditShape,
                  onExecute: refresh,
                  onCancel: () => trackActionDiscard(
                    ActionIds.EditShape,
                    PageId.MYSQL_DETAILS,
                    DetailsPanelId.CONFIGURATION,
                  ),
                });
              },
            }}
          >
            {mdsDbSystem?.shapeName || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.DATA_STORAGE}
            label={Messages.labels.dataStorageSize()}
            action={{
              id: ActionIds.EditStorageSize,
              testId: MetaItemActionTestIds.EditStorageSize,
              label: Messages.actions.edit(),
              onClick: () => {
                trackActionClick(ActionIds.EditStorageSize, PageId.MYSQL_DETAILS, DetailsPanelId.CONFIGURATION);
                triggerEditStorage({
                  databaseId: mdsDbSystemId,
                  dataStorageSizeInGBs: mdsDbSystem?.dataStorageSizeInGBs,
                  location,
                  targetId: ActionIds.EditStorageSize,
                  onExecute: refresh,
                  onCancel: () => trackActionDiscard(
                    ActionIds.EditStorageSize,
                    PageId.MYSQL_DETAILS,
                    DetailsPanelId.CONFIGURATION,
                  ),
                });
              },
            }}
          >
            {mdsDbSystem?.dataStorageSizeInGBs?.toString() || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.MYSQL_VERSION}
            label={Messages.labels.mySQLVersion()}
            action={{
              id: ActionIds.EditVersion,
              testId: MetaItemActionTestIds.EditVersion,
              label: Messages.actions.edit(),
              onClick: () => {
                trackActionClick(ActionIds.EditVersion, PageId.MYSQL_DETAILS, DetailsPanelId.CONFIGURATION);
                triggerEditVersion({
                  databaseId: mdsDbSystemId,
                  version: mdsDbSystem?.mysqlVersion,
                  location,
                  targetId: ActionIds.EditVersion,
                  onExecute: refresh,
                  onCancel: () => trackActionDiscard(
                    ActionIds.EditVersion,
                    PageId.MYSQL_DETAILS,
                    DetailsPanelId.CONFIGURATION,
                  ),
                });
              },
            }}
          >
            {mdsDbSystem?.mysqlVersion || <NoValue />}
          </LabelMetaItem>
        </Stack>
      </MetaItemSection>
    </Stack>
  );
};
