import { ActionBar, ActionType, LabelMetaItem, MetaItemSection, NoValue, optimizedRetryOption } from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  CloudExadataInfrastructure,
  DbSystemShapeSummary,
  DbSystemShapeSummaryCollection,
} from "../../gen/clients/mchub-azure-api-client-exa";
import { getShapeDisplayName, isFlexDatabaseShape, responseItemstoArray } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { ExaInfraScaleProps, newExaInfraScale } from "../../operations/ExaInfra/ExaInfraScale";
import { getOciRegion } from "../../utils";

export enum ActionIds {
  Scale = "scale",
  Refresh = "refresh",
}

export enum ActionBarTestIds {
  Scale = "scale",
  Refresh = "refresh",
}

export interface ExaInfraConfigurationDetailsProps {
  infra: CloudExadataInfrastructure | undefined;
  location: string;
  subscriptionId: string;
  refresh: () => void;
}

export enum MetaItemSectionTestIds {
  SHAPE = "exa-infra-shape",
  DB_SERVERS = "exa-infra-dbServers",
  STORGE_SERVERS = "exa-infra-storageServers"
}

export const ExaInfraConfigurationDetails = (
  { infra, location, subscriptionId, refresh }: ExaInfraConfigurationDetailsProps,
): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { loading: shapeLoading, response: shapeResponse, error: shapeError } = useQueryCall({
    wait: !infra,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDbSystemShapesBySubscription,
    options: {
      args: { subscriptionId: subscriptionId || "" },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
  });

  const shape = (shapeResponse
    && responseItemstoArray<DbSystemShapeSummaryCollection, DbSystemShapeSummary>(shapeResponse))
    ?.find(item => (item.name === infra?.shape));

  const disableScaleAction: boolean = !!shapeLoading || !!shapeError;

  const { trigger: triggerExaInfraScale } = useOperation<ExaInfraScaleProps>(newExaInfraScale);
  const { closePanels } = useSidePanel();

  const configurationActionItems: ActionType[] = [];
  if (infra?.computeCount && infra?.storageCount && isFlexDatabaseShape(shape)) {
    configurationActionItems.push(
      {
        key: ActionIds.Scale,
        testId: ActionBarTestIds.Scale,
        text: Messages.actions.scale(),
        icon: MonochromeIconIds.Scale,
        onClick: () => {
          trackActionClick(ActionIds.Scale, PageId.EXAINFRA_DETAILS, DetailsPanelId.CONFIGURATION);

          triggerExaInfraScale({
            exaInfraId: infra.id,
            exaInfraScaleConfig: {
              computeCount: infra.computeCount,
              storageCount: infra.storageCount,
              maximumNodeCount: shape?.maximumNodeCount,
              minimumNodeCount: shape?.minimumNodeCount,
              maximumStorageCount: shape?.maxStorageCount,
              minimumStorageCount: shape?.minStorageCount,
            },
            location,
            targetId: ActionIds.Scale,
            onExecute: refresh,
            onCancel: () => trackActionDiscard(ActionIds.Scale, PageId.EXAINFRA_DETAILS, DetailsPanelId.CONFIGURATION),
          });
        },
        disabled: disableScaleAction,
      },
    );
  }
  configurationActionItems.push(
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.EXAINFRA_DETAILS, DetailsPanelId.CONFIGURATION);
        refresh();
      },
    },
  );

  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
      <ActionBar actions={configurationActionItems} onActionClick={closePanels} />
      <MetaItemSection>
        <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.SHAPE}
            label={Messages.labels.shape()}
          >
            {infra?.shape ? getShapeDisplayName(infra.shape) : <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.DB_SERVERS}
            label={Messages.labels.databaseServers()}
          >
            {infra?.computeCount?.toString() || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.STORGE_SERVERS}
            label={Messages.labels.storageServers()}
          >
            {infra?.storageCount?.toString() || <NoValue />}
          </LabelMetaItem>
        </Stack>
      </MetaItemSection>
    </Stack>
  );
};
