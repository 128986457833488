import {
  AsyncValidationError,
  BookmarkablePage,
  FormValues,
  getValue,
  InputWizardPanel,
  InputWizardPanelComponent,
  MessageType,
  PanelMessage,
  ReviewItem,
  ReviewSection,
  SelectOption,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  NetworkAddressCollectionEntry,
  NetworkAddressCollectionType,
} from "../../components/NetworkAddressCollection/NetworkAddressCollection";
import { ConsoleContext } from "../../console/ConsoleContext";
import { MCVCN_CREATE_ROUTE, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { CreateWizardTestIds } from "../../constants/uiConstants";
import { getAddressRange } from "../../helpers/resourceHelper";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useMutationCall } from "../../hooks/useMutationCall";
import { useRoles } from "../../hooks/useRoles";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MCVCN_CREATE_POLL_DELAY,
  MCVCN_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { ReviewTabContent } from "../CreateCommon/ReviewTabContent";
import { BasicsTabContent, Fields as BasicsFields, GROUP_BASICS } from "./BasicsTabContent";
import { ConfigTabContent, Fields, GROUP_CONFIG } from "./ConfigTabContent";

const registrationIds = PageRegistrationConfig[PageId.MCVCN_CREATE].map(config => config.key);

const emptyDivTestId = "empty-div-test-id";

interface McvcnCreatePhaseProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitPayload: any;
  onSuccess: () => void;
  onFailure: () => void;
}

const McvcnCreatePhase = ({ submitPayload, onSuccess, onFailure }: McvcnCreatePhaseProps): JSX.Element => {
  const { invokeCall: invokeCreate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(submitPayload.location)).networkApi.createMultiCloudVirtualNetwork },
  );

  React.useEffect(() => {
    const mcvcnName = submitPayload.createMultiCloudVirtualNetworkDetails.name;

    const invokeOptions = {
      onSuccess,
      onFailure,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.createMcvcn(),
          message: Messages.notifications.inProgress.messages.createMcvcn(mcvcnName),
        },
        success: {
          title: Messages.notifications.success.titles.createMcvcn(),
          message: Messages.notifications.success.messages.createMcvcn(mcvcnName),
        },
        failure: {
          title: Messages.notifications.failure.titles.create(),
          message: Messages.notifications.failure.messages.createMcvcn(mcvcnName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MCVCN_GET,
          methodArgs: {
            subscriptionId: submitPayload.subscriptionId,
            resourceGroupName: submitPayload.resourceGroupName,
            multiCloudVirtualNetworkName: mcvcnName,
          },
          location: submitPayload.location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MCVCN_CREATED_CHECK,
          delay: MCVCN_CREATE_POLL_DELAY,
          interval: MCVCN_CREATE_POLL_INTERVAL,
        },
      },
    };

    const createDetails = {
      subscriptionId: submitPayload.subscriptionId,
      resourceGroupName: submitPayload.resourceGroupName,
      createMultiCloudVirtualNetworkDetails: submitPayload.createMultiCloudVirtualNetworkDetails,
    };
    invokeCreate({ ...createDetails }, invokeOptions);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

enum CreationPhase {
  CREATE_SUBMIT = "CREATE_SUBMIT",
}

export enum PanelTestIds {
  Basics = "basic",
  Configuration = "configuration",
}

export const McvcnCreatePage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const isCreateRoleMissing = false; // !isActionAllowed(RoleBasedAction.CREATE_MCVCN);
  const requiredRole = actionRequiredRoles(RoleBasedAction.CREATE_MCVCN)[0]?.displayName;

  const { trackActionDiscard } = useAnalytics();

  const { back, navigateToSelf, customData } = useNavigation(ConsoleContext);

  const [creationPhase, setCreationPhase] = React.useState<CreationPhase>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [createMcvcnPayload, setCreateMcvcnPayload] = React.useState<any>();

  const submitCallbacks = React.useRef<{
    reject:(errorMessage: string, validationErrors?: AsyncValidationError[]) => void
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ reject: () => {} });

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(MCVCN_CREATE_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [panelRef, setPanelRef] = React.useState<InputWizardPanelComponent>({} as InputWizardPanelComponent);

  const onSubmit = (formValues: FormValues): void => {
    const mcvcnCreatePayload = {
      subscriptionId: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].id,
      resourceGroupName: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      location: getValue<SelectOption[]>(formValues, BasicsFields.Region, GROUP_BASICS)?.[0].id,
      createMultiCloudVirtualNetworkDetails: {
        name: getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS),
        createOption: "CREATE_NEW_NETWORK",
        ociVcnCidrBlocks: getValue<NetworkAddressCollectionEntry[]>(formValues, Fields.CidrBlocks, GROUP_CONFIG)?.map(
          (entry: NetworkAddressCollectionEntry) => entry.address,
        ),
      },
    };
    setCreateMcvcnPayload(mcvcnCreatePayload);
    setCreationPhase(CreationPhase.CREATE_SUBMIT);
  };

  const buildBasicInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewBasicInfo: ReviewItem[] = [
      {
        label: Messages.labels.subscription(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.resourceGroup(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.name(),
        value: getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS),
      },
      {
        label: Messages.labels.region(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Region, GROUP_BASICS)?.[0].text,
      },
    ];
    return reviewBasicInfo;
  };
  const buildConfigurationInfo = (formValues: FormValues): ReviewItem[] => {
    const renderCidrValue = (values: string[]): JSX.Element => {
      const cidrs = values.map(range => (
        <Stack horizontal key={range}>
          <span style={{ paddingBottom: 1 }}>
            {range}
          </span>
        </Stack>
      ));
      return (
        <div>
          {cidrs}
        </div>
      );
    };

    const ociVcnCidrBlocks = getValue<NetworkAddressCollectionType>(formValues, Fields.CidrBlocks, GROUP_CONFIG);

    const reviewConfigInfo: ReviewItem[] = [
      {
        label: Messages.labels.ociCidrBlocks(),
        value: ociVcnCidrBlocks?.length
          ? renderCidrValue(ociVcnCidrBlocks?.map(entry => `${entry.address} [${getAddressRange(entry.address)}]`))
          : undefined,
      },
    ];
    return reviewConfigInfo;
  };

  const onRenderReview = (formValues: FormValues): JSX.Element => {
    const reviewSections = [
      { title: Messages.createMcvcn.basicsTab.title(), items: buildBasicInfo(formValues) },
      { title: Messages.createMcvcn.configurationTab.title(), items: buildConfigurationInfo(formValues) },
    ] as ReviewSection[];
    return <ReviewTabContent reviewSections={reviewSections} />;
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.createMcvcn.titles.short()}
    >
      <InputWizardPanel
        componentRef={setPanelRef}
        title={Messages.createMcvcn.titles.long()}
        testId={CreateWizardTestIds.Mcvcn}
        message={isCreateRoleMissing
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(requiredRole),
          } as PanelMessage
          : undefined}
        items={[
          {
            header: Messages.createMcvcn.basicsTab.title(),
            groupName: GROUP_BASICS,
            testId: PanelTestIds.Basics,
            content: (
              <BasicsTabContent
                inputWizardPanelRef={panelRef}
              />
            ),
          },
          {
            header: Messages.createMcvcn.configurationTab.title(),
            groupName: GROUP_CONFIG,
            testId: PanelTestIds.Configuration,
            content: (
              <ConfigTabContent />
            ),
          },
        ]}
        onRenderReview={onRenderReview}
        onSubmit={(
          formValues: FormValues,
          reject: (errorMessage: string, validationErrors?: AsyncValidationError[]) => void,
        ) => {
          submitCallbacks.current = { reject };
          onSubmit(formValues);
        }}
        onClose={() => {
          const analytics = customData?.analytics as NavigationAnalyticsData;
          if (analytics) {
            trackActionDiscard(analytics.actionName, analytics.pageId, analytics.panelId);
          }
          back();
        }}
      />
      {creationPhase === CreationPhase.CREATE_SUBMIT && (
        <McvcnCreatePhase
          submitPayload={createMcvcnPayload}
          onSuccess={() => {
            setCreateMcvcnPayload(undefined);
            setCreationPhase(undefined);
            back();
          }}
          onFailure={() => {
            panelRef.allowResubmit();
            setCreateMcvcnPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
    </BookmarkablePage>
  );
};
