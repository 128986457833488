import { CalloutComponent, FormValues, getValue, InputFormGroup, SelectOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  Fields as ShapeCalloutFields,
  VmDbScaleConfig,
  VmDbShapeCallout,
} from "../../components/VmDbShapeConfigInput/VmDbShapeCallout";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_DB_SYSTEM_UPDATE_POLL_DELAY,
  VMDB_DB_SYSTEM_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export enum Fields {
  SystemModel = "vmSystemModel",
}

export interface VmDbSystemScaleProps extends OperationActionProps {
  dbSystemId: string | undefined;
  scaleConfig: VmDbScaleConfig | undefined;
}

const VmDbSystemScale = (
  { targetId, location, dbSystemId, scaleConfig, onCancel, onExecute }: VmDbSystemScaleProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(dbSystemId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.updateVmdbDbSystem },
  );

  const [shapeCalloutRef, setShapeCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const onScaleSystem = (formValues: FormValues): void => {
    const shape = getValue<SelectOption[]>(formValues, Fields.SystemModel, InputFormGroup)?.[0].id;
    const coreCount = getValue<string>(formValues, ShapeCalloutFields.CpuCoreCount, InputFormGroup);
    const cpuCoreCount = coreCount ? parseInt(coreCount, 10) : 0;

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: shapeCalloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.scaleVmDbSystem(),
          message: Messages.notifications.inProgress.messages.scaleVmDbSystem(resName),
        },
        success: {
          title: Messages.notifications.success.titles.scaleVmDbSystem(),
          message: Messages.notifications.success.messages.scaleVmDbSystem(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.scaleVmDbSystem(),
          message: Messages.notifications.failure.messages.scaleVmDbSystem(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_DB_SYSTEM_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            dbSystemName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_DB_SYSTEM_UPDATED_CHECK,
          delay: VMDB_DB_SYSTEM_UPDATE_POLL_DELAY,
          interval: VMDB_DB_SYSTEM_UPDATE_POLL_INTERVAL,
        },
      },
    };
    // Cannot update both shape and cpu together
    invokeUpdate({
      dbSystemName: resName,
      resourceGroupName: resGroup,
      subscriptionId,
      updateDbSystemDetails: {
        shape: (scaleConfig?.shapeId !== shape)
          ? shape
          : undefined,
        cpuCoreCount: (scaleConfig?.shapeId !== shape)
          ? undefined
          : cpuCoreCount * (scaleConfig?.nodeCount || 1),
      },
    }, invokeOptions);
  };

  return (
    <VmDbShapeCallout
      isCreateMode={false}
      componentRef={setShapeCalloutRef}
      fieldName={Fields.SystemModel}
      defaultValue={scaleConfig}
      location={location}
      onSubmit={onScaleSystem}
      onClose={onCancel}
      subscriptionId={subscriptionId}
      targetId={targetId}
      title={Messages.createNewPanels.scaleVmDbSystem.title()}
    />
  );
};

export const newVmDbSystemScale = (
  props: VmDbSystemScaleProps,
): JSX.Element => (<VmDbSystemScale {...props} />);
