import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import { InfoPanelId } from "../../constants/pluginConstants";
import { ResourceLinkTestIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { AnalyticsDataPage, LinkAnalyticsData } from "../../hooks/useAnalytics";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { Resource, ResourceLink } from "./ResourceLink";

export interface ExaDbHomeLinkProps {
  dbHomeId: string|undefined;
  location: string;
  onClick: () => void;
  analytics: AnalyticsDataPage;
}

export const ExaDbHomeLink = ({
  dbHomeId,
  location,
  onClick,
  analytics,
}: ExaDbHomeLinkProps): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(dbHomeId);
  const { response, error, loading } = useQueryCall({
    wait: !dbHomeId,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.getDbHome,
    options: {
      args: {
        subscriptionId,
        resourceGroupName: resourceGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        dbHomeName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });
  const dbHome = response?.data;

  return (
    <ResourceLink
      resource={dbHome as Resource}
      navigation={{ onClick }}
      analytics={{
        target: InfoPanelId.DBHOME,
        source: { pageId: analytics.pageId, panelId: analytics.panelId },
      } as LinkAnalyticsData}
      error={error}
      loading={loading}
      testId={ResourceLinkTestIds.ExaDbHomeLink}
    />
  );
};
