import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import { DetailsPanelId, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { ResourceLinkTestIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { AnalyticsDataPage, LinkAnalyticsData } from "../../hooks/useAnalytics";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { Resource, ResourceLink } from "./ResourceLink";

export interface VmDbSystemLinkProps {
  dbSystemId: string | undefined;
  location: string;
  analytics: AnalyticsDataPage;
}

const registrationIds = PageRegistrationConfig[PageId.VMDB_SYSTEM_DETAILS].map(config => config.key);

export const VmDbSystemLink = ({
  dbSystemId,
  location,
  analytics,
}: VmDbSystemLinkProps): JSX.Element => {
  const dbSystemIdComps = parseId(dbSystemId);
  const dbSystemResGroup = decodeURIComponent(dbSystemIdComps?.resourceGroup || "");
  const dbSystemResourceName = decodeURIComponent(dbSystemIdComps?.resourceName || "");
  const { response, error, loading } = useQueryCall({
    wait: !dbSystemId,
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.getVmdbDbSystem,
    options: {
      args: {
        subscriptionId: dbSystemIdComps?.subscriptionId || "",
        resourceGroupName: dbSystemResGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        dbSystemName: dbSystemResourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });
  const dbSystem = response?.data;

  return (
    <ResourceLink
      resource={dbSystem as Resource}
      navigation={{
        navigationKey: registrationIds[0],
        panelPath: PageRegistrationConfig[PageId.VMDB_SYSTEM_DETAILS][0].panelPath,
      }}
      analytics={{
        target: { pageId: PageId.VMDB_SYSTEM_DETAILS, panelId: DetailsPanelId.OVERVIEW },
        source: { pageId: analytics.pageId, panelId: analytics.panelId },
      } as LinkAnalyticsData}
      error={error}
      loading={loading}
      testId={ResourceLinkTestIds.VmDbSystemLink}
    />
  );
};
