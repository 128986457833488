import {
  FieldSet,
  FormattedString,
  FormContext,
  FormState,
  InputWizardPanelComponent,
  IpInput,
  NetworkAddressInput,
  SelectComponent,
  TextInput,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { McvcnSelect } from "../../components/McvcnSelect/McvcnSelect";
import { VNETSelect } from "../../components/VNETSelect/VNETSelect";
import { networkCidrSizingUrl, networkOverviewUrl, vnetTroubleshooting } from "../../constants/docConstants";
import { AzureVirtualNetworkSummary } from "../../gen/clients/mchub-azure-api-client";
import { IdResourceType } from "../../helpers/idHelper";
import { FutureMcvcnExisting, FutureMcvcnNew, maxNetworkLinkCidrPrefix } from "../../helpers/resourceHelper";
import { validateMdsHostName, validateNetworkLinkAddressSpace } from "../../helpers/validationHelper";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import { McvcnCreateNewProps, newMcvcnCreateNew } from "../../operations/Mcvcn/McvcnCreateNew";

export const GROUP_NETWORK = "network";

export enum Fields {
  Hostname = "mdsDbSystemDeploymentDetails.hostnameLabel",
  IpAddress = "mdsDbSystemDeploymentDetails.ipAddress",
  Mcvcn = "multiCloudVirtualNetworkDetails.id",
  Vnet = "networkLinkDetails.azureAttachedNetworkIds",
  Cidrs = "networkLinkDetails.ociNetworkCidrs",
  NetworkVirtualAppliance = "networkLinkDetails.customerNvaIpAddress",
}

export enum FieldTestIds {
  Hostname = "hostnameLabel",
  IpAddress = "ipAddress",
  Mcvcn = "mcvcnId",
  Vnet = "azureAttachedNetworkIds",
  Cidrs = "ociNetworkCidrs",
  NetworkVirtualAppliance = "customerNvaIpAddress",
}

export enum LinkTestIds {
  McvcnCreateNewLink = "mcvcnCreateNewLink",
}

export interface NetworkTabContentProps {
  inputWizardPanelRef: InputWizardPanelComponent;
  location: string;
  subscriptionId: string;
}

export const NetworkTabContent = ({
  inputWizardPanelRef,
  location,
  subscriptionId,
}: NetworkTabContentProps): JSX.Element => {
  const { enableMcvcn, enableMdsMcvcnCreate } = useFeatures();
  const [selectRef, setSelectRef] = React.useState<SelectComponent>();
  const [selectedVnet, setSelectedVnet] = React.useState<AzureVirtualNetworkSummary | undefined>(undefined);
  const [renderKey, setRenderKey] = React.useState<string>(uniqueGUID());
  const form: FormState = React.useContext(FormContext);

  const onError = (): void => inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.general());

  const onMissingDependencies = (): void => {
    inputWizardPanelRef.showErrorDialog(
      Messages.createCommon.dependencyWarnings.basicsIncomplete.message(),
      Messages.createCommon.dependencyWarnings.basicsIncomplete.title(),
    );
  };

  const onChangeVnet = (_: string, vnet: AzureVirtualNetworkSummary | undefined): void => {
    form.setValue(undefined, Fields.Cidrs, GROUP_NETWORK);
    setSelectedVnet(vnet);
    setRenderKey(uniqueGUID());
  };

  const { trigger: triggerMcvcnCreateNew } = useOperation<McvcnCreateNewProps>(newMcvcnCreateNew);

  const onLinkClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    else if (!location) missingDependencies.push(IdResourceType.LOCATION);

    if (missingDependencies.length > 0) {
      onMissingDependencies?.();
      return;
    }

    triggerMcvcnCreateNew({
      location,
      subscriptionId,
      onExecute: (futureMcvcn: FutureMcvcnExisting | FutureMcvcnNew): void => {
        if (selectRef) {
          // Here is where the consuming form can format the values returned by the panel
          // Just alter the data passed to the setNewOption
          selectRef.setOptionNew(futureMcvcn.name, futureMcvcn);
        }
      },
    });
  };

  const createVcnLink = {
    id: "create_new_vcn",
    testId: LinkTestIds.McvcnCreateNewLink,
    text: Messages.actions.createNew(),
    onLinkClick,
  };

  return (
    <>
      <FieldSet header={Messages.createMysql.networkingTab.sectionTitles.databaseSystemNetworking()}>
        <TextInput
          testId={FieldTestIds.Hostname}
          fieldName={Fields.Hostname}
          tooltip={Messages.hints.toolTipMySQLHostName()}
          label={Messages.labels.hostNameOnly()}
          validator={validateMdsHostName}
        />
        <IpInput
          testId={FieldTestIds.IpAddress}
          fieldName={Fields.IpAddress}
          tooltip={
            FormattedString(
              { inputText: Messages.hints.toolTipMySQLIpAddress(networkOverviewUrl) },
            ) as unknown as string
          }
          label={Messages.labels.ipAddress()}
          validation={{
            containsIpAddress: true,
            containsCidrBlock: false,
          }}
        />
      </FieldSet>
      <FieldSet header={Messages.createMysql.networkingTab.sectionTitles.networkPeering()}>
        {enableMcvcn && enableMdsMcvcnCreate && (
          <McvcnSelect
            required
            testId={FieldTestIds.Mcvcn}
            label={Messages.labels.virtualCloudNetwork()}
            componentRef={setSelectRef}
            location={location}
            fieldName={Fields.Mcvcn}
            groupName={GROUP_NETWORK}
            subscriptionId={subscriptionId}
            inputLink={createVcnLink}
          />
        )}
        {!(enableMcvcn && enableMdsMcvcnCreate) && (
          <>
            <VNETSelect
              required
              testId={FieldTestIds.Vnet}
              fieldName={Fields.Vnet}
              label={Messages.labels.virtualNetwork()}
              tooltip={
                FormattedString(
                  { inputText: Messages.hints.tooltipVNET(vnetTroubleshooting) },
                ) as unknown as string
              }
              subscriptionId={subscriptionId}
              location={location}
              onChange={onChangeVnet}
              onError={onError}
              onMissingDependencies={onMissingDependencies}
            />
            <IpInput
              testId={FieldTestIds.NetworkVirtualAppliance}
              fieldName={Fields.NetworkVirtualAppliance}
              label={Messages.labels.networkVirtualAppliance()}
              tooltip={Messages.hints.tooltipNetworkVirtualAppliance()}
              validation={{
                containsIpAddress: true,
                containsCidrBlock: false,
              }}
            />
            <NetworkAddressInput
              required
              key={renderKey}
              testId={FieldTestIds.Cidrs}
              fieldName={Fields.Cidrs}
              groupName={GROUP_NETWORK}
              label={Messages.labels.ociCidr()}
              tooltip={FormattedString(
                { inputText: Messages.hints.toolTipCidr(networkCidrSizingUrl) },
              ) as unknown as string}
              validator={validateNetworkLinkAddressSpace(maxNetworkLinkCidrPrefix, selectedVnet?.addressSpaces)}
            />
          </>
        )}
      </FieldSet>
    </>
  );
};
