export interface RowCountResult {
  perPageItemsCount: number;
  subListingPageItemCount: number;
  subDetailsListingItemCount: number;
}

const MainListDefaultPageItemsCount = 100;
const SubListDefaultPageItemsCount = 50;

export const useRowCount = (): RowCountResult => ({
  perPageItemsCount: MainListDefaultPageItemsCount,
  subListingPageItemCount: SubListDefaultPageItemsCount,
  subDetailsListingItemCount: SubListDefaultPageItemsCount,
});
