import { FieldSet, InputWizardPanelComponent, TextInput } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { RegionSelect } from "../../components/RegionSelect/RegionSelect";
import { ResourceGroupSelect } from "../../components/ResourceGroupSelect/ResourceGroupSelect";
import { SubscriptionSelect } from "../../components/SubscriptionSelect/SubscriptionSelect";
import { ExadataCreateFlowType } from "../../helpers/resourceHelper";
import { alphaNumericRegex, exaDbNameMaxLength, startWithLetterRegex } from "../../helpers/validationHelper";

export const GROUP_BASICS = "basics";

export enum Fields {
  Subscription = "subscriptionId",
  ResourceGroup = "resourceGroupId",
  Name = "databaseDetails.name",
  Region = "region",
}

export enum FieldTestIds {
  Subscription = "subscriptionId",
  ResourceGroup = "resourceGroupId",
  Name = "name",
  Region = "region",
}

export interface BasicsTabContentProps {
  location?: string;
  subscription?: string;
  resourceGroupId?: string;
  disableUIControls?: Fields[] | undefined;
  createFlowType?: ExadataCreateFlowType;
  inputWizardPanelRef: InputWizardPanelComponent;
}

export const BasicsTabContent = (
  {
    location,
    subscription,
    createFlowType,
    resourceGroupId,
    disableUIControls,
    inputWizardPanelRef,
  }: BasicsTabContentProps,
): JSX.Element => {
  const [subscriptionId, setSubscriptionId] = React.useState<string>(subscription || "");

  const onSubscriptionChange = (subId:string):void => setSubscriptionId(subId);

  const onResourceGroupError = (): void => {
    inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.resourceGroups());
  };

  const onResourceGroupMissingDependencies = (): void => inputWizardPanelRef.showErrorDialog(
    Messages.createCommon.dependencyWarnings.subscriptionNotSelected.message(),
    Messages.createCommon.dependencyWarnings.subscriptionNotSelected.title(),
  );

  return (
    <>
      <FieldSet header={Messages.createExaDb.basicsTab.sectionTitles.projectDetails()}>
        <SubscriptionSelect
          testId={FieldTestIds.Subscription}
          required
          onChange={onSubscriptionChange}
          fieldName={Fields.Subscription}
          label={Messages.labels.subscription()}
          tooltip={Messages.hints.toolTipSubscription()}
          disabled={disableUIControls?.find(ele => ele === Fields.Subscription) !== undefined}
          defaultValue={subscriptionId ? [subscriptionId] : undefined}
        />
        <ResourceGroupSelect
          testId={FieldTestIds.ResourceGroup}
          required
          subField
          subscriptionId={subscriptionId}
          fieldName={Fields.ResourceGroup}
          label={Messages.labels.resourceGroup()}
          tooltip={Messages.hints.toolTipResourceGroup()}
          onError={onResourceGroupError}
          onMissingDependencies={onResourceGroupMissingDependencies}
          defaultValue={resourceGroupId ? [resourceGroupId] : undefined}
          disabled={disableUIControls?.find(ele => ele === Fields.ResourceGroup) !== undefined}
        />
      </FieldSet>
      <FieldSet header={Messages.createExaDb.basicsTab.sectionTitles.instanceDetails()}>
        <TextInput
          testId={FieldTestIds.Name}
          required
          fieldName={Fields.Name}
          label={
            createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM
              ? Messages.labels.exadataSystemName()
              : Messages.labels.exadataDatabaseName()
          }
          validator={(value: string | undefined) => {
            const errors: string[] = [];
            if (!value || (value && !startWithLetterRegex.test(value))) {
              errors.push(Messages.validation.nameStartChar());
            }
            if (!value || (value && !alphaNumericRegex.test(value))) {
              errors.push(Messages.validation.valueAlphaNumeric());
            }
            if (!value || (value?.length > exaDbNameMaxLength)) {
              errors.push(Messages.validation.valueMaxLen(exaDbNameMaxLength.toString()));
            }
            return errors.length > 0 ? errors : undefined;
          }}
        />
        <RegionSelect
          required
          testId={FieldTestIds.Region}
          subscriptionId={subscriptionId}
          // showUnlinkedRegions
          fieldName={Fields.Region}
          label={Messages.labels.region()}
          tooltip={Messages.hints.toolTipRegion()}
          defaultValue={location ? [location] : undefined}
          disabled={disableUIControls?.find(ele => ele === Fields.Region) !== undefined}
        />
      </FieldSet>
    </>
  );
};
