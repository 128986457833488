import { ExtraFilter, PillFilterValue } from "o4a-react";
import React from "react";
import { FilterState, FilterStateContext } from "../console/FilterContext";

export const useFilterStatePills = (
  pillFilterValues: PillFilterValue[] | undefined,
  allFilterPills: ExtraFilter[],
  pageId: string|undefined,
  filterPills: ExtraFilter[],
): ExtraFilter[]|undefined => {
  const filterState = React.useContext<FilterState>(FilterStateContext);
  React.useEffect(() => {
    const filterArr: ExtraFilter[] = [];
    const stateFieldKeys = Object.values(pillFilterValues || []).map(val => val.fieldKey);
    allFilterPills.forEach(filter => {
      const tempValue = pillFilterValues?.find(pillFilter => pillFilter.fieldKey === filter.fieldKey)?.value || "";
      filter.value = tempValue;
    });
    if (!pageId || !pillFilterValues || !pillFilterValues.every(pill => pill.value)) return;

    allFilterPills.forEach(filterPill => {
      stateFieldKeys.forEach(fieldKey => {
        if (filterPill.fieldKey === fieldKey) {
          filterArr.push(filterPill);
        }
      });
    });

    // eslint-disable-next-line max-len
    const reorderedFilterArr = filterArr.sort((a, b) => stateFieldKeys.indexOf(a.fieldKey) - stateFieldKeys.indexOf(b.fieldKey));

    filterState.setFilterStateValue({
      filterValues: pillFilterValues,
      listId: pageId,
      defaultPills: reorderedFilterArr,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pillFilterValues)]);

  return filterState.pillFilterStateValue.defaultPills || filterPills;
};
