import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXABACKUP_DELETE_POLL_DELAY,
  EXABACKUP_DELETE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface ExaDbDeleteBackupProps extends OperationConfirmationProps {
  backupId: string;
}

const ExaDbDeleteBackup = (
  { location, backupId, onExecute }: ExaDbDeleteBackupProps,
): JSX.Element => {
  const { invokeCall: invokeDelete } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.deleteBackup },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(backupId);
    const resGroup = decodeURIComponent(resourceGroup);
    const backupName = decodeURIComponent(resourceName);

    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.deleteBackup(),
          message: Messages.notifications.inProgress.messages.deleteBackup(backupName),
        },
        success: {
          title: Messages.notifications.success.titles.deleteBackup(),
          message: Messages.notifications.success.messages.deleteBackup(backupName),
        },
        failure: {
          title: Messages.notifications.failure.titles.delete(),
          message: Messages.notifications.failure.messages.deleteBackup(backupName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXABACKUP_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            backupKey: backupName,
          },
          location,
          errorStatusOverrides: [404],
          responseMethodKey: AsyncNotificationPolledResponseKey.EXABACKUP_DELETED_CHECK,
          delay: EXABACKUP_DELETE_POLL_DELAY,
          interval: EXABACKUP_DELETE_POLL_INTERVAL,
        },
      },
    };
    invokeDelete({
      subscriptionId,
      resourceGroupName: resGroup,
      backupKey: backupName,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newExaDbDeleteBackup = (
  props: ExaDbDeleteBackupProps,
): JSX.Element => (<ExaDbDeleteBackup {...props} />);
