import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  optimizedRetryOption,
  Select,
  SelectOption,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { AzureSubnetCollection, AzureSubnetSummary } from "../../gen/clients/mchub-azure-api-client";
import { IdResourceType, parseId } from "../../helpers/idHelper";
import { responseItemstoArray } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

export interface SubnetProps extends Omit<SelectProps, "options" | "onChange"> {
  subscriptionId: string;
  location: string;
  vnetId: string | undefined;
  multiSelect?: boolean;
  onChange?: (id: string, subnet: AzureSubnetSummary | undefined) => void;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const SubnetSelect = ({
  defaultValue,
  fieldName,
  label,
  inputLink,
  testId,
  placeholder,
  required,
  subField,
  tooltip,
  subscriptionId,
  location,
  vnetId,
  multiSelect,
  validator,
  onChange,
  onError,
  onMissingDependencies,
}: SubnetProps): JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());
  const { resourceGroup: vnetResourceGroup, resourceName } = parseId(vnetId) || "";

  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const form: FormState = React.useContext(FormContext);
  const virtualNetworkName = decodeURIComponent(resourceName || "");
  const { loading, response, error } = useQueryCall({
    wait: !(subscriptionId && location && vnetId),
    method: apiClients.withRegion(getOciRegion(location)).azResourceApi.listAzureSubnets,
    options: {
      args: { subscriptionId, resourceGroupName: vnetResourceGroup, virtualNetworkName },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadSubnets(),
      },
    },
  });

  const subnets = (response
    && responseItemstoArray<AzureSubnetCollection, AzureSubnetSummary>(response)) || [];

  const options = subnets.map(subnet => ({
    id: subnet.id,
    text: subnet.name,
  }) as SelectOption);

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setKey(uniqueGUID());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location, virtualNetworkName]);

  React.useEffect(() => {
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subnets)]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    if (!location) missingDependencies.push(IdResourceType.LOCATION);
    if (!vnetId) missingDependencies.push(IdResourceType.VNETS);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const internalOnChange = (id: string): void => {
    const subnet = subnets?.find(item => item.id === id);
    onChange?.(id, subnet);
  };

  const derivedPlaceholder = !loading && !error && options.length === 0
    ? Messages.createCommon.loadingNone(label?.toLowerCase() || "")
    : placeholder;

  return (
    <Select
      key={key}
      loading={!!subscriptionId && !!virtualNetworkName && loading}
      required={required}
      subField={subField}
      fieldName={fieldName}
      testId={testId}
      label={label}
      options={options}
      placeholder={derivedPlaceholder}
      inputLink={inputLink}
      defaultValue={defaultValue}
      tooltip={tooltip}
      multiSelect={multiSelect}
      onClick={internalOnClick}
      onChange={internalOnChange}
      validator={validator}
    />
  );
};
