import {
  InputFormCallout,
  InputFormCalloutProps,
  SubmitButtonMode,
  TextInput,
  TextInputTypes,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";

export interface UpdatePasswordProps extends
  Pick<InputFormCalloutProps, "targetId" | "onClose" | "onSubmit" | "componentRef">
{
  title?: string;
  tooltip?: string;
  validator?: (value: string | undefined) => string[] | undefined;
  passwordLabel?: string;
  confirmPasswordLabel?: string;
  oldPassword?: {
    label: string;
    tooltip?: string;
  }
}

export enum Fields {
  OldPassword = "OldPassword",
  Password = "Password",
  ConfirmPassword = "ConfirmPassword",
}

export enum FieldTestIds {
  OldPassword = "old-password",
  Password = "password",
  ConfirmPassword = "confirm-password",
}

export const UpdatePassword = (
  {
    targetId,
    onClose,
    onSubmit,
    componentRef,
    tooltip = Messages.hints.tooltipAdminPassword(),
    validator,
    passwordLabel = Messages.labels.newAdminPassword(),
    confirmPasswordLabel = Messages.labels.newAdminConfirmPassword(),
    title = Messages.createNewPanels.changePassword.title(),
    oldPassword,
  }: UpdatePasswordProps,
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setOldWalletPassword] = React.useState<string>();
  const [adminPassword, setAdminPassword] = React.useState<string>();
  const [confirmPassword, setConfirmPassword] = React.useState<string>();
  const [confirmPassWordKey, setConfirmPasswordKey] = React.useState<string>(uniqueGUID());
  const onChangePassword = (value: string | undefined): void => {
    setAdminPassword(value);
    if (confirmPassword !== undefined) {
      setConfirmPasswordKey(uniqueGUID());
    }
  };

  return (

    <InputFormCallout
      componentRef={componentRef}
      title={title}
      targetId={targetId}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onClose={onClose}
      testId={CalloutTestIds.UpdatePassword}
    >
      <>
        {oldPassword && (
          <TextInput
            testId={FieldTestIds.OldPassword}
            required
            fieldName={Fields.OldPassword}
            label={oldPassword.label}
            textType={TextInputTypes.PASSWORD}
            onChange={setOldWalletPassword}
          />
        )}
        <TextInput
          testId={FieldTestIds.Password}
          required
          fieldName={Fields.Password}
          label={passwordLabel}
          tooltip={tooltip}
          textType={TextInputTypes.PASSWORD}
          onChange={onChangePassword}
          validator={validator}
        />
        <TextInput
          testId={FieldTestIds.ConfirmPassword}
          key={confirmPassWordKey}
          required
          fieldName={Fields.ConfirmPassword}
          label={confirmPasswordLabel}
          textType={TextInputTypes.PASSWORD}
          onChange={setConfirmPassword}
          validator={(value: string | undefined) => {
            if (value && (value !== adminPassword)) {
              return [Messages.validation.passwordConfirmation()];
            }
            return undefined;
          }}
        />
      </>
    </InputFormCallout>

  );
};
