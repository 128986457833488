import { optimizedRetryOption, useNavigation } from "o4a-react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Spinner } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { buildId } from "../../helpers/idHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { ExaDbDetailsPage } from "./ExaDbDetailsPage";

const cdbRegistrationIds = PageRegistrationConfig[PageId.EXADB_CDB_DETAILS].map(config => config.key);

export const ExaDbCdbDetailsPage = (): JSX.Element => {
  const
    {
      subscriptionId,
      resourceGroup: resGroupFromUrl,
      provider,
      resourceType,
      resourceName: resourceNameFromUrl,
      panelId,
    } = useParams();

  const [searchParams] = useSearchParams();
  const { navigateToSelf } = useNavigation(ConsoleContext);

  const resGroup = decodeURIComponent(resGroupFromUrl || "");
  const resourceName = decodeURIComponent(resourceNameFromUrl || "");
  const location = searchParams.get("location") || "";

  const { response: responseCdb, loading: loadingCdb, refresh: refreshCdb, error: errorCdb } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.getDatabase,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        databaseName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadContainerDatabase(),
      },
      excludeErrorStatus: [404],
    },
  });

  const exadbId = buildId({
    subscriptionId: subscriptionId || "",
    resourceGroup: resGroup,
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName,
  });

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.EXADB_CDB_DETAILS].find(
      config => config.panelPath === panelId,
    );
    const urlQueryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const path = `${RESOURCE_ROUTE}/${exadbId}/${panelId}${urlQueryParams}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const instance = responseCdb?.data;
  const renderContent = (loadingCdb && !instance)
    ? <Spinner label={Messages.common.loading()} />
    : (
      <ExaDbDetailsPage
        dbInstance={instance}
        dbRefresh={refreshCdb}
        dbError={errorCdb}
        isCdb
        dbRegistrationIds={cdbRegistrationIds}
        panelId={panelId}
        location={location}
        resourceId={exadbId}
      />
    );
  return renderContent;
};
