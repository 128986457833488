import { FormValues, getValue, InputFormGroup, SelectOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AddRegionPanel, Fields as AddRegionFields } from "../../components/AddRegionPanel/AddRegionPanel";
import { AzureCloudLinkSummary } from "../../gen/clients/mchub-azure-api-client";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  CLOUDLINK_UPDATE_POLL_DELAY,
  CLOUDLINK_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface CloudLinkAddRegionProps extends Omit<OperationActionProps, "location"> {
  showTenantOptions: boolean;
  activeCloudLinks: AzureCloudLinkSummary[];
}

interface AddRegionSubmitProps {
  cloudLinkId: string;
  location: string;
  resourceName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitPayload: any;
  onPostSubmit: () => void;
}

const AddRegionSubmit = ({
  cloudLinkId,
  location,
  resourceName,
  submitPayload,
  onPostSubmit,
}: AddRegionSubmitProps): JSX.Element => {
  const { invokeCall: invokeAddRegion } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).cloudLinkApi.updateAzureCloudLink },
  );

  React.useEffect(() => {
    const invokeOptions = {
      onSuccess: onPostSubmit,
      onFailure: onPostSubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.addRegion(),
          message: Messages.notifications.inProgress.messages.addRegion(resourceName || ""),
        },
        success: {
          title: Messages.notifications.success.titles.addRegion(),
          message: Messages.notifications.success.messages.addRegion(resourceName || ""),
        },
        failure: {
          title: Messages.notifications.failure.titles.addRegion(),
          message: Messages.notifications.failure.messages.addRegion(resourceName || ""),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.CLOUDLINK_GET,
          methodArgs: { cloudLinkId },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.CLOUDLINK_UPDATED_CHECK,
          delay: CLOUDLINK_UPDATE_POLL_DELAY,
          interval: CLOUDLINK_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeAddRegion(submitPayload, invokeOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

const CloudLinkAddRegion = (
  { targetId, showTenantOptions, activeCloudLinks, onExecute, onCancel }: CloudLinkAddRegionProps,
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [submitAddRegionPayload, setSubmitAddRegionPayload] = React.useState<any>();
  const [addRegionCloudLinkId, setAddRegionCloudLinkId] = React.useState<string>();
  const [addRegionPrimaryLocation, setAddRegionPrimaryLocation] = React.useState<string>();
  const [addRegionName, setAddRegionName] = React.useState<string>();

  const onAddRegion = (formValues: FormValues): void => {
    const cloudLinkId = activeCloudLinks?.length === 1
      ? activeCloudLinks[0].id
      : getValue<SelectOption[]>(formValues, AddRegionFields.CloudLink, InputFormGroup)?.[0].id;
    const region = getValue<SelectOption[]>(formValues, AddRegionFields.Region, InputFormGroup)?.[0];
    setAddRegionName(region?.text);
    const selectedCloudLink = activeCloudLinks?.find(link => link.id === cloudLinkId);
    setAddRegionCloudLinkId(cloudLinkId);

    if (selectedCloudLink?.primaryLocation) {
      setAddRegionPrimaryLocation(selectedCloudLink?.primaryLocation);
    } else if (selectedCloudLink?.locations && selectedCloudLink?.locations[0]) {
      setAddRegionPrimaryLocation(selectedCloudLink?.locations[0]);
    }

    const newLocations: string[] = selectedCloudLink?.locations ? [...selectedCloudLink.locations] : [];
    newLocations.push(...region?.id ? [region.id] : []);

    setSubmitAddRegionPayload({
      cloudLinkId,
      updateAzureCloudLinkDetails: { locations: newLocations },
    });
  };

  const postSubmitAddRegion = (): void => {
    setSubmitAddRegionPayload(undefined);
    setAddRegionName(undefined);
    setAddRegionPrimaryLocation(undefined);
    setAddRegionCloudLinkId(undefined);
    onExecute?.();
  };
  return (
    <>
      <AddRegionPanel
        showTenantOptions={showTenantOptions}
        activeCloudLinks={activeCloudLinks}
        targetId={targetId}
        onSubmit={onAddRegion}
        onClose={onCancel}
      />
      {submitAddRegionPayload && (
        <AddRegionSubmit
          cloudLinkId={addRegionCloudLinkId || ""}
          location={addRegionPrimaryLocation || ""}
          resourceName={addRegionName || ""}
          submitPayload={submitAddRegionPayload}
          onPostSubmit={postSubmitAddRegion}
        />
      )}
    </>
  );
};

export const newCloudLinkAddRegion = (
  props: CloudLinkAddRegionProps,
): JSX.Element => (<CloudLinkAddRegion {...props} />);
