import { FormattedString, InfoBlockStatus } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { parseId } from "../../helpers/idHelper";
import { AdbWorkloadType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  ADBS_UPDATE_POLL_DELAY,
  ADBS_UPDATE_POLL_INTERVAL,
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface AdbsUpgradeProps extends OperationConfirmationProps {
  databaseId: string | undefined;
}

const AdbsUpgrade = (
  { location, databaseId, onExecute, onCancel }: AdbsUpgradeProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeAdbsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.updateAdbsDatabase },
  );

  const onUpgradeToATP = (): void => {
    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.upgradeToATP(),
          message: Messages.notifications.inProgress.messages.upgradeToATP(resName),
        },
        success: {
          title: Messages.notifications.success.titles.upgradeToATP(),
          message: Messages.notifications.success.messages.upgradeToATP(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.upgradeToATP(),
          message: Messages.notifications.failure.messages.upgradeToATP(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.ADBS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.ADBS_UPDATED_CHECK,
          delay: ADBS_UPDATE_POLL_DELAY,
          interval: ADBS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeAdbsUpdate({
      updateAdbsDatabaseDetails: { dbWorkload: AdbWorkloadType.TransactionProcessing },
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName: resName,
    }, invokeOptions);
  };

  return (
    <ConfirmationDialog
      title={Messages.detailAdbs.upgradeToATP.title()}
      infoBlock={{
        messageType: InfoBlockStatus.WARNING,
        message: Messages.detailAdbs.upgradeToATP.warning(),
      }}
      cancelButoonText={Messages.common.cancel()}
      submitButtonText={Messages.actions.convert()}
      onSubmit={onUpgradeToATP}
      onClose={() => onCancel?.()}
    >
      <div>
        <p>{Messages.detailAdbs.upgradeToATP.description()}</p>
        <ul>
          <li>{Messages.detailAdbs.upgradeToATP.description1stPoint()}</li>
          <li>{Messages.detailAdbs.upgradeToATP.description2ndPoint()}</li>
        </ul>
        <p>
          <FormattedString inputText={Messages.detailAdbs.upgradeToATP.descriptionNote()} />
        </p>
        <p>
          <FormattedString
            inputText={Messages.detailAdbs.upgradeToATP.descriptionConfirmation(resName)}
          />
        </p>
      </div>
    </ConfirmationDialog>
  );
};

export const newAdbsUpgrade = (
  props: AdbsUpgradeProps,
): JSX.Element => (<AdbsUpgrade {...props} />);
