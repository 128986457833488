import { AnchoredPanel, AnchoredPanelType, ButtonType, FieldSet, uniqueGUID } from "o4a-react";
import * as React from "react";
import { mergeStyleSets } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { OperationProps } from "../OperationTypes";

export interface DeploymentShowErrorsProps extends OperationProps {
  errorMsgs: string[];
}

export enum ButtonTestIds {
  ClosePanel = "deployment-show-errors-close-button",
}

const classNames = mergeStyleSets({
  errorMsgContainer: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  errorMsgDivider: {
    border: "none",
    borderBottom: "1px solid rgb(214, 214, 214)",
  },
});

const DeploymentShowErrors = (
  { onCancel, errorMsgs }: DeploymentShowErrorsProps,
): JSX.Element => (
  <AnchoredPanel
    title={Messages.labels.errors()}
    type={AnchoredPanelType.MEDIUM}
    onClose={onCancel}
    isOpen
    footer={[
      {
        type: ButtonType.DEFAULT,
        label: Messages.actions.close(),
        onClick: () => onCancel?.(),
        testId: ButtonTestIds.ClosePanel,
        style: { margin: "10px 5px 0px 0px", height: "24px" },
      },
    ]}
  >
    {errorMsgs && errorMsgs.length > 0 && (
      <FieldSet
        header={Messages.labels.errorDetails()}
      >
        <ul className={classNames.errorMsgContainer}>
          {errorMsgs.map(msg => (
            <li key={uniqueGUID()}>
              {msg}
              <hr className={classNames.errorMsgDivider} />
            </li>
          ))}
        </ul>
      </FieldSet>
    )}
  </AnchoredPanel>
);

export const newDeploymentShowErrors = (
  props: DeploymentShowErrorsProps,
): JSX.Element => (<DeploymentShowErrors {...props} />);
