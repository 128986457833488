import {
  AnchoredPanelType,
  CheckBox,
  FormInputGroupLayout,
  FormValues,
  getValue,
  IconGroup,
  IconGroupOption,
  InfoBlock,
  InfoBlockStatus,
  InputFormGroup,
  InputFormSidePanel,
  InputFormSidePanelComponent,
  OptionOrientation,
  SubmitButtonMode,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { ILinkProps, Link, mergeStyleSets, Stack, Text, TextField } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { OperationBaseProps } from "../../console/OperationsContext";
import { SettingsContext } from "../../console/SettingsContext";
import { PageId, SUPPORT_ROUTE } from "../../constants/pluginConstants";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { CreateFeedbackDataDetailsFeedbackTypeEnum, RatingTypeEnum } from "../../gen/clients/cfs-api-client";
import { useMutationCall } from "../../hooks/useMutationCall";

const linkStyles: ILinkProps["styles"] = {
  root: {
    boxSizing: "border-box",
    borderRadius: "2px",
    border: "1px solid transparent",
    textDecoration: "none",
    "&:hover": { textDecoration: "none !important" },
    "&:active": {
      textDecoration: "none !important",
      border: "1px solid #0058ad",
    },
  },
};
const classNames = mergeStyleSets({
  // The blocker and header.marginTop keep the form contents from overlapping the title
  // This is a fluent issue as the title of the Panel will not take up the height of its contents
  blocker: { width: "100%", height: "40px", backgroundColor: "white", position: "absolute", top: 32, flex: 1 },
  header: { borderBottom: "1px solid #d6d6d6", marginTop: "16px !important" },
  commentWrapper: { position: "relative", marginBottom: 50 },
  commentFooter: { position: "absolute", left: 0, bottom: -50 },
  commentFooterText: { fontSize: 12, color: "#717171" },
  body: { margin: "10px 0 !important", flex: 5 },
});
const styles = {
  subtitle: { fontSize: "13px" },
  supportLink: { margin: "19px 0" },
  comment: { root: { marginBottom: "10px" } },
};

enum Fields {
  Satisfied = "satisfied",
  Feedback = "feedback",
  IsUserConsent = "isUserConsent"
}

export enum FieldTestIds {
  Satisfied = "satisfaction-field",
  Feedback = "feedback-field",
  IsUserConsent = "user-consent-field"
}

export enum InfoBlockTestIds {
  Support = "support-info-block"
}

export enum SatisfactionKind {
  ExtremelyNotSatisfied = "ExtremelyNotSatisfied",
  NotSatisfied = "NotSatisfied",
  Neutral = "Neutral",
  Satisfied = "Satisfied",
  ExtremelySatisfied = "ExtremelySatisfied",
}

// The backend uses a rating from 1-5 (1 being the worst, 5 being the best)
export const satisfactionRating: { [key in SatisfactionKind]: number } = {
  ExtremelyNotSatisfied: 1,
  NotSatisfied: 2,
  Neutral: 3,
  Satisfied: 4,
  ExtremelySatisfied: 5,
};

// API constants per https://jira.oci.oraclecorp.com/browse/O4ACONSOLE-126
const realm = "OC1";
const source = "ODSA_CONSOLE";
const feedbackType: CreateFeedbackDataDetailsFeedbackTypeEnum = "OTHERS";
const ratingType: RatingTypeEnum = "SCALE_5";

const commentMaxLength = 1000;

export type SubmitFeedbackProps = OperationBaseProps;

const SubmitFeedback = ({
  onExecute,
  onCancel,
}: SubmitFeedbackProps): JSX.Element => {
  const { navigateTo } = useNavigation(ConsoleContext);
  const { locale: language } = React.useContext(SettingsContext);
  const [panelRef, setPanelRef] = React.useState<InputFormSidePanelComponent>({} as InputFormSidePanelComponent);
  const [commentContent, setCommentContent] = React.useState<string>("");
  const { invokeCall } = useMutationCall({ method: apiClients.cfsApi.createFeedback });

  const options: IconGroupOption[] = [
    {
      id: SatisfactionKind.ExtremelySatisfied,
      testId: SatisfactionKind.ExtremelySatisfied,
      text: Messages.satisfaction.extremelySatisfied(),
      tooltip: Messages.satisfaction.extremelySatisfied(),
      iconName: "emoji",
    },
    {
      id: SatisfactionKind.Satisfied,
      testId: SatisfactionKind.Satisfied,
      text: Messages.satisfaction.satisfied(),
      tooltip: Messages.satisfaction.satisfied(),
      iconName: "emoji2",
    },
    {
      id: SatisfactionKind.Neutral,
      testId: SatisfactionKind.Neutral,
      text: Messages.satisfaction.neutral(),
      tooltip: Messages.satisfaction.neutral(),
      iconName: "emojineutral",
    },
    {
      id: SatisfactionKind.NotSatisfied,
      testId: SatisfactionKind.NotSatisfied,
      text: Messages.satisfaction.notSatisfied(),
      tooltip: Messages.satisfaction.notSatisfied(),
      iconName: "sad",
    },
    {
      id: SatisfactionKind.ExtremelyNotSatisfied,
      testId: SatisfactionKind.ExtremelyNotSatisfied,
      text: Messages.satisfaction.extremelyNotSatisfied(),
      tooltip: Messages.satisfaction.extremelyNotSatisfied(),
      iconName: "emojidisappointed",
    },
  ];

  const onSupportClick = (): void => {
    navigateTo(SUPPORT_ROUTE, PageId.SUPPORT);
  };

  const onSubmit = (formValues: FormValues): void => {
    const isUserConsent = getValue<boolean>(formValues, Fields.IsUserConsent, InputFormGroup);
    const satisfactionLevel = getValue<IconGroupOption>(
      formValues,
      Fields.Satisfied,
      InputFormGroup,
    )?.id;

    const rating = satisfactionLevel ? satisfactionRating[satisfactionLevel as SatisfactionKind] : "";

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: panelRef.allowResubmit,
      notification: {
        success: {
          title: Messages.notifications.success.titles.submitFeedback(),
          message: Messages.notifications.success.messages.submitFeedback(),
        },
        failure: {
          title: Messages.notifications.failure.titles.submitFeedback(),
          message: Messages.notifications.failure.messages.submitFeedback(),
        },
      },
    };

    invokeCall({
      createFeedbackDetails: {
        userData: {
          isUserConsent,
          realm,
          // Optional params for now that are apart of the api spec
          // sessionId,
          // customerType: "Unknown",
          // region,
        },
        referrer: { url: window.location.href },
        timeSubmitted: new Date(),
        product: { source },
        feedbackData: {
          language,
          comment: commentContent || undefined,
          feedbackType,
          rating: {
            type: ratingType,
            value: rating.toString(),
          },
        },
      },
    }, invokeOptions);
  };

  const onCommentChange = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void => {
    if (!newText || newText.length <= commentMaxLength) {
      setCommentContent(newText || "");
    }
  };

  return (
    <InputFormSidePanel
      testId={SidePanelTestIds.Feedback}
      componentRef={setPanelRef}
      title={Messages.feedback.title()}
      submitText={Messages.actions.submitFeedback()}
      type={AnchoredPanelType.CUSTOM_WIDTH}
      customWidth={400}
      layout={FormInputGroupLayout.COMPACT}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onClose={onCancel}
    >
      {/**
       * This keeps the form contents from overlapping the title
       * This is a fluent issue as the title of the Panel will not take up the height of its contents
       */}
      <Stack className={classNames.blocker} />
      <Stack className={classNames.header}>
        <Text style={styles.subtitle}>{Messages.feedback.subtitle()}</Text>
        <Link
          style={styles.supportLink}
          styles={linkStyles}
          onClick={onSupportClick}
        >
          <InfoBlock
            testId={InfoBlockTestIds.Support}
            message={Messages.feedback.infoBlock()}
            messageType={InfoBlockStatus.INFO}
          />
        </Link>
      </Stack>
      <Stack className={classNames.body}>
        <IconGroup
          required
          label={Messages.labels.feedbackSatisfaction()}
          options={options}
          fieldName={Fields.Satisfied}
          groupName={InputFormGroup}
          optionOrientation={OptionOrientation.HORIZONTAL}
          testId={FieldTestIds.Satisfied}
        />
        <Stack className={classNames.commentWrapper}>
          {/*
            Had to use fluent TextField instead of o4a-react TextInput to be able to apply logic for blocking
            user from typing more than the allowed number of chars
          */}
          <TextField
            placeholder={Messages.labels.feedbackPlaceholder()}
            multiline
            rows={7}
            resizable={false}
            value={commentContent}
            onChange={onCommentChange}
            styles={styles.comment}
            data-test-id={FieldTestIds.Feedback}
          />
          <Stack className={classNames.commentFooter} tokens={{ childrenGap: "5px" }}>
            <Text className={classNames.commentFooterText}>
              Remaining characters:
              {" "}
              {commentMaxLength > commentContent.length ? commentMaxLength - commentContent.length : 0}
            </Text>
            <Text className={classNames.commentFooterText}>
              Do not include any personal, private, or company confidential information in your submission.
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <CheckBox
          switchCheckBoxSide
          fieldName={Fields.IsUserConsent}
          groupName={InputFormGroup}
          label={Messages.labels.consentToContact()}
          testId={FieldTestIds.IsUserConsent}
        />
      </Stack>
    </InputFormSidePanel>
  );
};

export const newSubmitFeedback = (
  props: SubmitFeedbackProps,
): JSX.Element => (<SubmitFeedback {...props} />);
