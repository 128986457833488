import { AnchoredPanelType, FieldSet, FormValues, InputFormSidePanel, SubmitButtonMode, TagProps } from "o4a-react";
import * as React from "react";
import { Icon, Link, Stack, Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { tagsLearnMoreUrl } from "../../constants/docConstants";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { TagsInfoType } from "../../helpers/resourceHelper";
import { ResourceTags } from "../Tags/ResourceTags";
import { TagsCollection } from "../Tags/TagsCollection";

export interface TagsEditPanelProps {
  title: string;
  resourceId: string | undefined;
  tagsDefaultValue?: TagsInfoType;
  onSubmit: (formValues: FormValues) => void;
  onClose?: () => void;
}

export enum Fields {
  Tags = "tags",
}

export enum FieldTestIds {
  Tags = "tags",
}

const formValues2TagProps = (
  formValues: FormValues[] | undefined,
): TagProps[] | undefined => formValues?.map(entry => ({ name: entry.name, value: entry.value } as TagProps));

export const TagsEditPanel = (
  { title, resourceId, onSubmit, onClose, tagsDefaultValue }: TagsEditPanelProps,
): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState<FormValues>();
  const [newValues, setNewValues] = React.useState<FormValues[]>();

  const onChange = (values: FormValues[] | undefined): void => {
    setNewValues(values || []);
  };

  React.useEffect(() => {
    if (tagsDefaultValue) {
      const initFieldValues: FormValues = { [Fields.Tags]: tagsDefaultValue };

      setInitialValues(initFieldValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsDefaultValue]);

  return (
    <InputFormSidePanel
      title={title}
      type={AnchoredPanelType.MEDIUM}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      submitText={Messages.actions.save()}
      onSubmit={onSubmit}
      discardText={Messages.common.cancel()}
      onClose={onClose}
      initialValues={initialValues}
      testId={SidePanelTestIds.TagsEditPanel}
    >
      <Stack style={{ margin: "10px 0px" }}>
        <Text>{Messages.tags.description()}</Text>
        <Link href={tagsLearnMoreUrl} target="_blank">
          <>
            <span>{Messages.tags.info.learnMore()}</span>
            <Icon style={{ paddingLeft: "5px" }} iconName="OpenInNewWindow" />
          </>
        </Link>
      </Stack>
      <Stack>
        <FieldSet header={Messages.labels.tags()} headerSize={16}>
          <TagsCollection
            fieldName={Fields.Tags}
            testId={FieldTestIds.Tags}
            tagsDefaultValue={tagsDefaultValue}
            onChange={onChange}
          />
        </FieldSet>
        <FieldSet header={Messages.labels.resource()} headerSize={16}>
          <ResourceTags
            resourceId={resourceId}
            initialValues={tagsDefaultValue}
            newValues={formValues2TagProps(newValues)}
          />
        </FieldSet>
      </Stack>
    </InputFormSidePanel>
  );
};
