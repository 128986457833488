import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_BACKUP_DELETE_POLL_DELAY,
  VMDB_BACKUP_DELETE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface VmDbDeleteBackupProps extends OperationConfirmationProps {
  backupId: string | undefined;
}

const VmDbDeleteBackup = (
  { location, backupId, onExecute }: VmDbDeleteBackupProps,
): JSX.Element => {
  const { invokeCall: invokeDelete } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.deleteVmdbBackup },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName: backupName } = parseId(backupId);
    const resGroup = decodeURIComponent(resourceGroup);

    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.deleteBackup(),
          message: Messages.notifications.inProgress.messages.deleteBackup(backupName),
        },
        success: {
          title: Messages.notifications.success.titles.deleteBackup(),
          message: Messages.notifications.success.messages.deleteBackup(backupName),
        },
        failure: {
          title: Messages.notifications.failure.titles.delete(),
          message: Messages.notifications.failure.messages.deleteBackup(backupName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_BACKUP_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            backupKey: backupName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_BACKUP_DELETED_CHECK,
          errorStatusOverrides: [404],
          delay: VMDB_BACKUP_DELETE_POLL_DELAY,
          interval: VMDB_BACKUP_DELETE_POLL_INTERVAL,

        },
      },
    };

    const deleteDetails = {
      subscriptionId,
      resourceGroupName: resGroup,
      backupKey: backupName,
    };
    invokeDelete({ ...deleteDetails }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newVmDbDeleteBackup = (
  props: VmDbDeleteBackupProps,
): JSX.Element => (<VmDbDeleteBackup {...props} />);
