import {
  CalloutComponent,
  FormValues,
  getValue,
  InputFormCallout,
  InputFormGroup,
  SubmitButtonMode,
  TextInput,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { validateMdsDescription } from "../../helpers/validationHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_UPDATE_POLL_DELAY,
  MDS_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export enum Fields {
  Description = "description",
}

export enum FieldTestIds {
  Description = "description",
}

export interface MysqlBackupEditDescriptionProps extends OperationActionProps {
  backupId: string | undefined;
  description: string | undefined;
}

const MysqlBackupEditDescription = (
  { targetId, location, backupId, description, onExecute, onCancel }: MysqlBackupEditDescriptionProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(backupId);
  const resourceGroupName = decodeURIComponent(resourceGroup || "");
  const backupName = decodeURIComponent(resourceName || "");

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (description) {
      const initFieldValues: FormValues = { [Fields.Description]: description };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  const { invokeCall: invokeMdsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.updateMdsBackup },
  );
  const [calloutRef, setCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const onUpdate = (formValues: FormValues): void => {
    const descText = getValue<string>(formValues, Fields.Description, InputFormGroup);
    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: calloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateMysqlBackupDescription(),
          message: Messages.notifications.inProgress.messages.updateMysqlBackupDescription(backupName),
        },
        success: {
          title: Messages.notifications.success.titles.updateMysqlBackupDescription(),
          message: Messages.notifications.success.messages.updateMysqlBackupDescription(backupName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateMysqlBackupDescription(),
          message: Messages.notifications.failure.messages.updateMysqlBackupDescription(backupName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            backupKey: backupName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_UPDATED_CHECK,
          delay: MDS_UPDATE_POLL_DELAY,
          interval: MDS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeMdsUpdate({
      backupKey: backupName,
      resourceGroupName,
      subscriptionId,
      updateMdsBackupDetails: { description: descText },
    }, invokeOptions);
  };

  return (
    <InputFormCallout
      targetId={targetId}
      title={Messages.createNewPanels.editDescription.title()}
      componentRef={setCalloutRef}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onUpdate}
      onClose={onCancel}
      testId={CalloutTestIds.MysqlBackupEditDescription}
      initialValues={initialValues}
    >
      <TextInput
        testId={FieldTestIds.Description}
        fieldName={Fields.Description}
        label={Messages.labels.description()}
        defaultValue={description}
        validator={validateMdsDescription}
      />
    </InputFormCallout>
  );
};

export const newMysqlBackupEditDescription = (
  props: MysqlBackupEditDescriptionProps,
): JSX.Element => (<MysqlBackupEditDescription {...props} />);
