import { AnchoredPanel, AnchoredPanelType, ButtonType, FocusableArea, FormattedString } from "o4a-react";
import * as React from "react";
import { Icon, Link } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { OperationBaseProps } from "../../console/OperationsContext";
import { SidePanelTestIds } from "../../constants/uiConstants";

export enum ButtonTestIds {
  Close = "close-button"
}

export type CloudLinkListSubscriptionInstructionsProps = Omit<OperationBaseProps, "onExecute">

const CloudLinkListSubscriptionInstructions = (
  { onCancel }: CloudLinkListSubscriptionInstructionsProps,
): JSX.Element => (
  <AnchoredPanel
    testId={SidePanelTestIds.CloudLinkListSubscriptionInstructions}
    isOpen
    type={AnchoredPanelType.MEDIUM}
    title={Messages.instructions.assignRoles.title(Messages.portalBrand.short())}
    onClose={onCancel}
    initialFocusableArea={FocusableArea.FOOTER}
    footer={[
      {
        type: ButtonType.DEFAULT,
        testId: ButtonTestIds.Close,
        label: Messages.actions.close(),
        onClick: () => onCancel?.(),
        style: { margin: "10px 5px 0px 0px", height: "24px" },
      },
    ]}
  >
    <div>
      <ol>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step1()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step2()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step3()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step3b()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step4()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step5()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step6()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step7()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step8()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step9()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step10()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step11()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step12()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step13()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step14()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step15()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step16()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step17()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step18()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step19()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step20()} /></li>
        <li><FormattedString inputText={Messages.instructions.assignRoles.step21()} /></li>
      </ol>
    </div>
    <div>
      <p>
        <FormattedString inputText={Messages.instructions.assignRoles.importantNote(Messages.portalBrand.short())} />
      </p>
    </div>
    <div>
      <Link
        href="https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBlade"
        target="_blank"
      >
        <span>{Messages.instructions.assignRoles.azureLinkLabel()}</span>
        <span style={{ marginLeft: "8px" }}><Icon iconName="OpenInNewWindow" /></span>
      </Link>
    </div>
  </AnchoredPanel>
);

export const newCloudLinkListSubscriptionInstructions = (
  props: CloudLinkListSubscriptionInstructionsProps,
): JSX.Element => (<CloudLinkListSubscriptionInstructions {...props} />);
