import * as React from "react";
import { OperationManager, OperationsContext } from "./OperationsContext";

export const OperationOutlet = (): JSX.Element => {
  const { operationUnits, addCancelAllOperationsListener } = React.useContext<OperationManager>(OperationsContext);
  const [operationComponent, setOperationComponent] = React.useState<JSX.Element | undefined>(undefined);
  const operationCancel = React.useRef<(() => void) | undefined>(undefined);

  const cancelAllOperations = (): void => {
    operationUnits.pop();
    operationCancel.current?.();
    setOperationComponent(undefined);
    operationCancel.current = undefined;
  };

  React.useEffect(() => {
    addCancelAllOperationsListener(cancelAllOperations);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (operationUnits.length > 0) {
      const operationUnit = operationUnits.pop();
      if (operationUnit) {
        const { onCancel, onExecute, ...rest } = operationUnit.operationProps;
        const internalOnCancel = ():void => {
          setOperationComponent(undefined);
          operationCancel.current = undefined;
          onCancel?.();
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const internalOnExecute = (args?: any):void => {
          setOperationComponent(undefined);
          operationCancel.current = undefined;
          onExecute?.(args);
        };

        const operation = operationUnit?.operationFactory({
          ...rest,
          onCancel: internalOnCancel,
          onExecute: internalOnExecute,
        });
        setOperationComponent(operation);
        operationCancel.current = internalOnCancel;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationUnits]);

  return operationComponent || <div />;
};
