import {
  CheckBox,
  FormInputGroupLayout,
  FormInputGroupLayoutContextProvider,
  InfoBlock,
  InfoBlockStatus,
  InputFormGroup,
  InputFormSidePanel,
  InputFormSidePanelProps,
  ProgressDotsIndicator,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";

export interface DeleteInfoBlock {
  message: string;
  type: InfoBlockStatus;
  testId?: string
}

export enum ActionTestIds {
  ConfirmCheckbox = "confirm-cb"
}

export interface DeleteConfirmationPanelProps extends
  Pick<InputFormSidePanelProps, "componentRef" | "onClose" | "onSubmit" | "title" | "testId" > {
  description?: string;
  infoBlock?: DeleteInfoBlock;
  children: JSX.Element | JSX.Element[];
  isLoading?: boolean;
}

export const DeleteConfirmationPanel = ({
  title,
  description,
  onSubmit,
  onClose,
  infoBlock,
  children,
  isLoading,
  testId,
}: DeleteConfirmationPanelProps): JSX.Element => (
  <InputFormSidePanel
    title={title}
    onSubmit={onSubmit}
    onClose={onClose}
    layout={FormInputGroupLayout.COMPACT}
    submitText={Messages.actions.delete()}
    discardText={Messages.common.cancel()}
    submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
    testId={testId}
  >
    <Stack style={{ height: "100%" }}>
      {isLoading ? <ProgressDotsIndicator /> : (
        <Stack style={{ flex: "0 1 auto" }} tokens={{ childrenGap: 20 }}>
          <span style={{ marginTop: "25px" }}>{description}</span>
          {infoBlock && (
            <InfoBlock message={infoBlock.message} messageType={infoBlock.type} testId={infoBlock.testId} />
          )}
        </Stack>
      )}
      <Stack grow style={{ opacity: isLoading ? 0 : 1 }}>{children}</Stack>
      <Stack style={{
        position: "fixed",
        bottom: "100px",
        background: "white",
        height: "60px",
        paddingTop: "15px",
      }}
      >
        <FormInputGroupLayoutContextProvider
          value={{
            layout: FormInputGroupLayout.COMPACT,
            groupName: InputFormGroup,
          }}
        >
          <CheckBox
            testId={ActionTestIds.ConfirmCheckbox}
            fieldName="confirmCb"
            label={Messages.createNewPanels.deleteExaInfra.confirmCb()}
            disabled={isLoading}
            switchCheckBoxSide
            validator={(value: boolean) => {
              if (!value) {
                return [Messages.validation.deleteExaInfraCb()];
              }
              return undefined;
            }}
          />
        </FormInputGroupLayoutContextProvider>
      </Stack>
    </Stack>
  </InputFormSidePanel>
);
