import { CustomLink, NoValue } from "o4a-react";
import * as React from "react";
import { Link } from "@fluentui/react";
import { parseId } from "../../helpers/idHelper";
import { useAppAuthContext } from "../../hooks/useAppAuthContext";
import { azurePortalUrl } from "../../utils";

export interface AzureVnetLinkProps {
  vnetId: string;
  hideClipboard?: boolean;
}

export const AzureVnetLink = (
  { vnetId, hideClipboard }: AzureVnetLinkProps,
): JSX.Element => {
  const { domainName } = useAppAuthContext();
  const vnet = decodeURIComponent(parseId(vnetId)?.resourceName || "");

  if (!vnetId) return <NoValue />;

  if (hideClipboard) {
    return (
      <Link
        title={vnet}
        href={`${azurePortalUrl}/#@${domainName}/resource${vnetId}/overview`}
        target="_blank"
        underline
      >
        {vnet}
      </Link>
    );
  }

  return (
    <CustomLink
      href={`${azurePortalUrl}/#@${domainName}/resource${vnetId}/overview`}
      target="_blank"
      underline
      linkText={vnet}
    />
  );
};
