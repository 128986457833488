import { BasicOptions, TimeInterval, TTLOptions } from "savant-connector";

export const OverlayLayerId = "overlay-layer";

export enum MenuStyleMode {
  FlyOut = "flyout",
  Docking = "docking",
}

export const topHeaderHeight = 40;
export const footerHeight = 30;

export const notificationWidth = 340;
export const notificationGap = 5;
export const notificationTimeout = 6000; // in msecs
export const maxNumberOfRecentItems = 12;
export const maxNumberOfFavoriteItems = 12;

export const ttlFiveSecCaching: TTLOptions = {
  type: "ttl",
  refreshIfOlderThan: TimeInterval.sm,
};
export const ttlTenSecCaching: TTLOptions = {
  type: "ttl",
  refreshIfOlderThan: TimeInterval.md,
};
export const ttlOneMinCaching: TTLOptions = {
  type: "ttl",
  refreshIfOlderThan: TimeInterval.lg,
};
export const ttlFiveMinCaching: TTLOptions = {
  type: "ttl",
  refreshIfOlderThan: TimeInterval.xl,
};
export const ttlTenMinCaching: TTLOptions = {
  type: "ttl",
  refreshIfOlderThan: TimeInterval.xxl,
};
export const basicNoRefreshCaching: BasicOptions = {
  type: "basic",
  refreshOnMount: false,
};

export enum MonochromeIconIds {
  AddLink = "AddLink",
  GlobalNavButton = "GlobalNavButton",
  Settings = "Settings",
  Feedback = "Feedback",
  Notifications = "Ringer",
  PageListFilter = "PageListFilter",
  CommandPrompt = "CommandPrompt",
  CheckMark = "CheckMark",
  ChromeBack = "ChromeBack",
  ChromeBackMirrored = "ChromeBackMirrored",
  Connect = "PlugConnected",
  Copy = "copy",
  Create = "Add",
  Database = "Database",
  Delete = "Delete",
  Disable = "Cancel",
  Download = "Download",
  Edit = "Edit",
  Enable = "CheckMark",
  FavoriteNotSet = "FavoriteStar",
  FavoriteSet = "FavoriteStarFill",
  Help = "Help",
  HelpInMenu = "Unknown",
  List = "List",
  OpenInNewWindow = "OpenInNewWindow",
  PageRemove = "PageRemove",
  Permissions = "Permissions",
  Play = "Play",
  Refresh = "Refresh",
  RemoveLink = "RemoveLink",
  Restart = "PlaybackRate1x",
  Rotate = "Rotate",
  Rotate90CounterClockwise = "Rotate90CounterClockwise",
  Scale = "ScaleVolume",
  Search = "Search",
  StatusCircleIcon = "StatusCircleBlock2",
  Stop = "Stop",
  Up = "Up",
  Upload = "Upload",
  ScaleUp = "ScaleUp",
  More = "More",
}

export enum SvgIconIds {
  helpSvg = "help-svg",
  homeSvg = "HomeSolid",
  addSvg = "add-svg",
  apexSvg = "apex-svg",
  mysqlSvg = "mysql-svg",
  heatWaveSvg = "heatwave-svg",
  tagsSvg = "tags-svg",
  backupsSvg = "backups-svg",
  dbSystemSvg = "dbSystem-svg",
  baseDbSvg = "base-database-svg",
  databaseSvg = "database-svg",
  pluggableSvg = "pluggable-svg",
  adbSvg = "adb-svg",
  infraSvg = "infra-svg",
  vmClusterSvg = "vmcluster-svg",
  attachedDbSvg = "attachedDb-svg",
  networkingSvg = "networking-svg",
  connectSvg = "connect-svg",
  configurationSvg = "configuration-svg",
  securitySvg = "security-svg",
  managementSvg = "management-svg",
  databaseNodesSvg = "databaseNodes-svg",
  exadataSvg = "exadata-svg",
  exaSvg = "exa-svg",
  supportSvg = "support-svg",
  supportWaterMarkSvg = "support-watermark-svg",
  supportSmallSvg = "support-small-svg",
  networkSvg = "network-svg",
  networkWaterMarkSvg = "Network-watermark-svg",
  apexWatermarkSvg = "apex-watermark-svg",
  adbWatermarkSvg = "adb-watermark-svg",
  baseDbWatermarkSvg = "base-database-watermark-svg",
  baseDbSystemWatermarkSvg = "base-database-system-watermark-svg",
  exaWaterMarkSvg = "exa-watermark-svg",
  infraWaterMarkSvg = "infra-watermark-svg",
  vmClusterWaterMarkSvg = "vmcluster-watermark-svg",
  mySqlWaterMarkSvg = "mysql-watermark-svg",
  deploymentSvg = "deployment-detail-svg",
  deploymentMetaIconSvg = "deployment-meta-svg",
  deploymentWaterMarkSvg = "deployment-watermark-svg",
  subscriptionSvg = "subscription-svg",
  billingSvg = "billing-svg",
  deleteSvg = "delete-svg",
  flyoutMenuSvg = "flyout-menu-svg",
  dockMenuSvg = "dock-menu-svg",
  notificationBellSvg = "notification-bell-svg",
  punchOutIconSvg = "punch-out-link-svg",
}

export enum ViewKeys {
  ListView = "listView",
}

export const metaItemLabelsGap = 5;

export const emptyChar = "-";

export const testIdAttribute = "data-test-id";
export const valueLinkTestIdSuffix = "-link";
export const emptyDivTestId = "empty-div-test-id";
export const spinnerTestId = "spinner-test-id";

export enum ListingTestIds {
  Adbs = "adbs-list",
  AdbsBackups = "adbs-backups-list",
  AdbsConnect = "adbs-connect-list",
  CloudLink = "cloud-link-list",
  ConnectionStrings = "connection-strings-list",
  Deployments = "deployments-list",
  ExaDb = "exa-db-list",
  ExaDbBackups = "exa-db-backups-list",
  ExaDbConnect = "exa-db-connect-list",
  ExaDbPdbs = "exa-db-pdbs-list",
  ExaInfra = "exa-infra-list",
  ExaInfraVmClusters = "exa-infra-vm-clusters-list",
  ExaVmCluster = "exa-vm-cluster-list",
  ExaVmClusterDatabases = "exa-vm-cluster-databases-list",
  ExaVmClusterDbHomes = "exa-vm-cluster-db-homes-list",
  ExaVmClusterDbNodes = "exa-vm-cluster-db-nodes-list",
  ExaVmClusterSshKeys = "exa-vm-cluster-ssh-keys-list",
  FavoriteList = "favorite-list",
  Mcvcn = "mcvcn-list",
  McvcnAttachedDbs = "mcvcn-attached-db-list",
  Mysql = "mysql-list",
  MysqlBackups = "mysql-backups-list",
  NetworkLinkList = "network-link-list",
  RecentList = "recent-list",
  Support = "support-list",
  VmDb = "vmdb-list",
  VmDbSystem = "vmdb-system-list",
  VmDbBackups = "vmdb-backups-list",
  VmDbConnect = "vmdb-connect-list",
  VmDbPdbs = "vmdb-pdbs-list",
  VmDbSystemDatabases = "vmdb-system-databases-list",
  VmDbSystemDbHomes = "vmdb-system-db-homes-list",
  VmDbSystemDbNodes = "vmdb-system-db-nodes-list",
  VmDbSystemSshKeys = "vmdb-system-ssh-keys-list",
}

export enum CalloutTestIds {
  AdbsBackupRestore = "adbs-backup-restore-callout",
  AdbsEditDbName = "adbs-edit-db-name-callout",
  AdbsEditMutualTlsAuth = "adbs-edit-mutual-tls-auth-callout",
  AdbsScale = "adbs-scale-callout",
  AddCapacityVmCluster = "add-capacity-vmcluster",
  AddRegion = "add-region-callout",
  BackupCreatePanel = "backup-create-panel-callout",
  BackupRestorePanel = "backup-restore-callout",
  DbHomeCreatePanel = "db-home-create-callout",
  DeletionPlanEditCallout = "deletion-plan-edit-callout",
  ExadataInfraScalePanel = "exa-infra-scale-callout",
  ExaVmClusterEditLicense = "exa-vmcluster-edit-license-callout",
  ExaVmClusterScale = "exa-vmcluster-scale-callout",
  LinkSubscription = "link-subscription-callout",
  MysqlBackupEditDescription = "mysql-backup-edit-description-callout",
  MysqlBackupEditRetentionDays = "mysql-backup-edit-retention-days-callout",
  MysqlEditDescription = "mysql-edit-description-callout",
  MysqlEditShape = "mysql-edit-shape-callout",
  MysqlEditStorage = "mysql-edit-storage-callout",
  MysqlEditVersion = "mysql-edit-version-callout",
  StoragePanel = "exadb-storage-panel-callout",
  ResourceGroupNewPanel = "resource-group-new-panel-callout",
  SupportQuickSearch = "support-quick-search-callout",
  SupportUploadFile = "support-upload-file-callout",
  UpdatePassword = "update-password-callout",
  VmDbSystemEditLicense = "vmdb-system-edit-license-callout",
  VmDbSystemEditStorage = "vmdb-system-edit-storage-callout",
  VmDbSystemEditScale = "vmdb-system-edit-scale-callout",
  WalletDownload = "download-wallet-callout",
}

export enum SidePanelTestIds {
  AddSshKey = "add-ssh-key-panel",
  AdbsEditLicense = "adbs-edit-license-panel",
  AdbsEditNetworkAccess = "adbs-edit-network-access-panel",
  CloudLinkListSubscriptionInstructions = "cloudlink-list-subscription-instructions",
  CloudLinkViewAdminInstructions = "cloudlink-view-admin-instructions",
  CloudLinkViewLocations = "cloudlink-view-locations",
  ExaDbCdbBackupEditPanel = "exadb-cdb-backup-edit-side-panel",
  ExaDbPdbCreatePanel = "exadb-pdb-create-side-panel",
  ExaInfraDeletePanel = "exa-infra-delete-callout",
  ExaVmClusterAddSshKeyPair = "exa-vmcluster-add-ssh-key-side-panel",
  FavoriteAddResources = "favorite-add-resources",
  Feedback = "feedback-panel",
  McvcnCreatePanel = "mcvcn-create-panel",
  MysqlBackupCreatePanel = "mysql-backup-create-side-panel",
  MysqlBackupEditPanel = "mysql-backup-edit-side-panel",
  MysqlCreateHeatWave = "mysql-create-heatwave-side-panel",
  MysqlEditNodeCount = "mysql-edit-node-count-side-panel",
  NetworkLinkCreatePanel = "network-link-create-panel",
  QuotaRequestPanel = "quota-details-panel",
  SupportUpdate = "support-update-panel",
  TagsEditPanel = "tags-edit-side-panel",
  VmDbCdbBackupEditPanel = "vmdb-cdb-backup-edit-side-panel",
  VmDbPdbCreatePanel = "vmdb-pdb-create-side-panel",
}

export enum CreateWizardTestIds {
  ExaDb = "exadata-create",
  ExaInfra = "exaInfra-create",
  ExaVmCluster = "exaVmCluster-create",
  Adbs = "adbs-create",
  Support = "support-create",
  Mysql = "mysql-create",
  VmDb = "vmdb-create",
  Mcvcn = "mcvcn-create",
}

export enum ResourceLinkTestIds {
  ExaCdbLink = "exa-cdb-link",
  ExaDbHomeLink = "exa-db-home-link",
  ExaInfraLink = "exa-infra-link",
  ExaVmClusterLink = "exa-vmcluster-link",
  McVcnLink = "mcvcn-link",
  ResourceLink = "resource-link",
  VmDbCdbLink = "vmdb-cdb-link",
  VmDbHomeLink = "vmdb-home-link",
  VmDbSystemLink = "vmdb-system-link",
}

export enum InputFormInlineTestIds {
  SettingAppearance = "setting-appearance-inline",
  SettingLanguage = "setting-language-inline",
  SettingSignout = "setting-signout-inline",
  SettingSubscriptionsLocations = "setting-subscriptions-inline",
  TagDetails = "tag-details-input-form-inline",
}
