import {
  CheckBox,
  FieldSet,
  FormContext,
  FormState,
  FormValues,
  TextInput,
  TextInputProps,
  TextInputTypes,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { SshKeyPairInput, SshPublicKeySource } from "../../components/SshKeyPair/SshKeyPairInput";
import { defaultExaUsername, ExadataCreateFlowType } from "../../helpers/resourceHelper";
import { validateExaDbPassword, validateTdePassword } from "../../helpers/validationHelper";

export const GROUP_SECURITY = "security";

export enum Fields {
  SshKeyPair = "cloudVmClusterDetails.sshPublicKeys",
  // This field has a static value and is not actually sent in a payload
  Username = "username",
  Password = "databaseDetails.adminPassword",
  PasswordConfirm = "passwordConfirm",
  UseDifferentTDEPassword = "useDifferentTDEPassword",
  TdePassword = "databaseDetails.tdeWalletPassword",
  TdePasswordConfirm = "tdePasswordConfirm"
}

export enum FieldTestIds {
  Username = "username",
  Password = "adminPassword",
  PasswordConfirm = "passwordConfirm",
  UseDifferentTDEPassword = "useDifferentTDEPassword",
  TdePassword = "tdeWalletPassword",
  TdePasswordConfirm = "tdePasswordConfirm"
}

type TdePasswordProps = Pick<TextInputProps, "defaultValue" | "testId" | "onChange">;

const TdePassword = ({ defaultValue, testId, onChange }: TdePasswordProps): JSX.Element => (
  <TextInput
    testId={testId}
    defaultValue={defaultValue}
    fieldName={Fields.TdePassword}
    label={Messages.labels.tdePassword()}
    tooltip={Messages.hints.tooltipAdminPassword()}
    onChange={onChange}
    textType={TextInputTypes.PASSWORD}
    required
    validator={validateTdePassword}
  />
);

export interface SecurityTabContentProps {
  createFlowType?: ExadataCreateFlowType;
}

export const SecurityTabContent = ({ createFlowType }: SecurityTabContentProps): JSX.Element => {
  const [passwordVal, setPasswordVal] = React.useState<string>();
  const [tdePasswordVal, setTDEPasswordVal] = React.useState<string>();
  const form: FormState = React.useContext(FormContext);
  const [useDifferentTDEPassword, setUseDifferentTDEPassword] = React.useState(false);
  const [defaultTdePassword, setDefaultTdePassword] = React.useState<string>();
  const [defaultTdePasswordConfirm, setDefaultTdePasswordConfirm] = React.useState<string>();
  const [confirmPassWordKey, setConfirmPasswordKey] = React.useState<string>(uniqueGUID());
  const [tdeConfirmPassWordKey, setTdeConfirmPasswordKey] = React.useState<string>(uniqueGUID());

  const onChangeUseDifferentTDEPasswordVal = (value: boolean): void => {
    setUseDifferentTDEPassword(value);
    if (value) {
      const formValues: FormValues = form.getGroupValues(GROUP_SECURITY);
      setDefaultTdePassword(formValues[Fields.TdePassword] !== undefined
        ? formValues[Fields.TdePassword] : defaultTdePassword);
      setDefaultTdePasswordConfirm(formValues[Fields.TdePasswordConfirm] !== undefined
        ? formValues[Fields.TdePasswordConfirm] : defaultTdePasswordConfirm);
    }
  };

  const onChangePassword = (value: string | undefined): void => {
    setPasswordVal(value);
    const formValues: FormValues = form.getGroupValues(GROUP_SECURITY);
    if (formValues[Fields.PasswordConfirm] !== undefined) {
      form.setValue(undefined, Fields.PasswordConfirm, GROUP_SECURITY);
      setConfirmPasswordKey(uniqueGUID());
    }
  };

  const onChangeTdePassword = (value: string | undefined): void => {
    setTDEPasswordVal(value);
    const formValues: FormValues = form.getGroupValues(GROUP_SECURITY);
    if (formValues[Fields.TdePasswordConfirm] !== undefined) {
      form.setValue(undefined, Fields.TdePasswordConfirm, GROUP_SECURITY);
      setDefaultTdePasswordConfirm(undefined);
      setTdeConfirmPasswordKey(uniqueGUID());
    }
  };
  return (
    <>
      {createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM && (
        <FieldSet
          header={Messages.createExaDb.securityTab.sectionTitles.vmClusterAuthentication()}
          description={Messages.createExaDb.securityTab.sectionDescriptions.vmClusterAuthentication()}
        >
          <SshKeyPairInput
            defaultValue={SshPublicKeySource.Generate}
            fieldName={Fields.SshKeyPair}
            groupName={GROUP_SECURITY}
          />
        </FieldSet>
      )}
      <FieldSet header={Messages.createExaDb.securityTab.sectionTitles.databaseAdministratorCredentials()}>
        <TextInput
          testId={FieldTestIds.Username}
          fieldName={Fields.Username}
          label={Messages.labels.username()}
          defaultValue={defaultExaUsername}
          disabled
        />

        <TextInput
          testId={FieldTestIds.Password}
          fieldName={Fields.Password}
          label={Messages.labels.password()}
          tooltip={Messages.hints.tooltipAdminPassword()}
          textType={TextInputTypes.PASSWORD}
          required
          onChange={onChangePassword}
          validator={validateExaDbPassword}
        />

        <TextInput
          key={confirmPassWordKey}
          testId={FieldTestIds.PasswordConfirm}
          fieldName={Fields.PasswordConfirm}
          label={Messages.labels.passwordConfirm()}
          textType={TextInputTypes.PASSWORD}
          required
          validator={(value: string | undefined) => {
            if (value && (value !== passwordVal)) {
              return [Messages.validation.passwordConfirmation()];
            }
            return undefined;
          }}
        />
        {createFlowType === ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP && (
          <TdePassword testId={FieldTestIds.TdePassword} />
        )}
        {createFlowType !== ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP && (
          <>
            <CheckBox
              testId={FieldTestIds.UseDifferentTDEPassword}
              defaultValue={useDifferentTDEPassword}
              fieldName={Fields.UseDifferentTDEPassword}
              label={Messages.labels.useDifferentTDEPassword()}
              tooltip={Messages.hints.useDifferentTDEPassword()}
              onChange={value => {
                onChangeUseDifferentTDEPasswordVal(value);
              }}
            />
            {useDifferentTDEPassword && (
              <>
                <TdePassword
                  testId={FieldTestIds.TdePassword}
                  defaultValue={defaultTdePassword}
                  onChange={onChangeTdePassword}
                />
                <TextInput
                  key={tdeConfirmPassWordKey}
                  testId={FieldTestIds.TdePasswordConfirm}
                  defaultValue={defaultTdePasswordConfirm}
                  fieldName={Fields.TdePasswordConfirm}
                  label={Messages.labels.tdePasswordConfirm()}
                  textType={TextInputTypes.PASSWORD}
                  required
                  validator={(value: string | undefined) => {
                    if (value && (value !== tdePasswordVal)) {
                      return [Messages.validation.passwordConfirmation()];
                    }
                    return undefined;
                  }}
                />
              </>
            )}
          </>
        )}
      </FieldSet>
    </>
  );
};
