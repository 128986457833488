import "./index.css";
import { AnalyticsClient, BrowserLogTransport, TelemetryClient } from "console-telemetry-client";
import React from "react";
import ReactDOM from "react-dom";
import { StoreContextProvider } from "savant-connector";
import { App } from "./App";
import { AppError } from "./AppError";
import { trackInitStatus } from "./helpers/telemetryHelper";
import reportWebVitals from "./reportWebVitals";
import { savantStore, userAuthInit } from "./session/azureSession";
import { InitState, InitStatus } from "./session/sessionConstants";
import { getAnalyticsUrl, getSignupUrl } from "./utils";

const consoleTransport = new BrowserLogTransport();
TelemetryClient.initialize([consoleTransport]);

AnalyticsClient.initialize(getAnalyticsUrl(), true);

userAuthInit().then((initState: InitState) => {
  if (!initState.isCanarySession) {
    trackInitStatus(initState.initStatus, initState.tenancyId);
  }
  switch (initState.initStatus) {
    case InitStatus.ACCOUNTS_LINKED:
      ReactDOM.render(
        <React.StrictMode>
          <StoreContextProvider store={savantStore}>
            <App regionName={initState.homeRegionName} isCanarySession={initState.isCanarySession} />
          </StoreContextProvider>
        </React.StrictMode>,
        document.getElementById("root"),
      );
      // If you want to start measuring performance in your app, pass a function
      // to log results (for example: reportWebVitals(console.log))
      // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
      reportWebVitals();
      break;
    case InitStatus.ACCOUNTS_NOT_LINKED:
      // Redirect to signup app
      window.location.replace(getSignupUrl(initState.tenancyId));
      break;
    default:
      // Show general error message for failure to initialize console
      ReactDOM.render(
        <React.StrictMode>
          <AppError initState={initState} />
        </React.StrictMode>,
        document.getElementById("root"),
      );
      break;
  }
});
