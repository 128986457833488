import { NormalError } from "savant-connector";
import { buildServiceUrl, getQParam, getTopLevel } from "../utils";

export const TELEMETRY_SERVICE_MAX_RETRIES = 1;
export const TELEMETRY_SERVICE_BATCH_SIZE = 5;

export enum TelemetryTransport {
  COURIER = "COURIER",
  MULTI_CLOUD = "MULTI_CLOUD",
}

export enum MchubEnvironment {
  DEV = "dev",
  PREPROD = "preprod",
  PROD = "prod",
}

export enum InitStatus {
  ACCOUNTS_LINKED = "ACCOUNTS_LINKED",
  ACCOUNTS_LINKED_UNKNOWN = "ACCOUNTS_LINKED_UNKNOWN",
  ACCOUNTS_LINKING_IN_PROGRESS = "ACCOUNTS_LINKING_IN_PROGRESS",
  ACCOUNTS_LINKING_MISCONFIGURED = "ACCOUNTS_LINKING_MISCONFIGURED",
  ACCOUNTS_NOT_LINKED = "ACCOUNTS_NOT_LINKED",
  AUTH_INIT_FAILED = "AUTH_INIT_FAILED",
  INIT_FAILED = "INIT_FAILED",
}

export interface InitState {
  initStatus: InitStatus;
  error?: NormalError;
  tenancyId?: string;
  homeRegionName: string;
  isCanarySession?: boolean;
}

export interface ConfigOverride {
  mchubEnv?: MchubEnvironment;
  useCourierTransport?: boolean;
  features?: { [key: string]: string | boolean };
}

export const getConfigOverride = (): ConfigOverride => {
  let config: ConfigOverride = {};
  const qparamConfig = getQParam("configoverride");
  try {
    if (qparamConfig) {
      config = JSON.parse(qparamConfig) as ConfigOverride;
      window.sessionStorage.setItem("ociazConfigOverride", qparamConfig);
    } else {
      const configString = window.sessionStorage.getItem("ociazConfigOverride");
      if (configString) {
        config = JSON.parse(configString) as ConfigOverride;
      }
    }
  // eslint-disable-next-line no-console
  } catch (e) { console.error("Invalid configoverride parameter", e); }
  return config;
};

export const getMchubEnvironment = (): MchubEnvironment => {
  const config = getConfigOverride();
  const { hostname } = window.location;
  switch (hostname) {
    case "localhost.oracle.com":
      return config?.mchubEnv || MchubEnvironment.DEV;
    case "multicloud.oraclecorp.com":
      return MchubEnvironment.DEV;
    case "console-staging.multicloud.oracle.com":
      return MchubEnvironment.PREPROD;
    case "console.multicloud.oracle.com":
    default:
      return MchubEnvironment.PROD;
  }
};

const getClientId = (): string => {
  const envToUse = getMchubEnvironment();
  switch (envToUse) {
    case MchubEnvironment.DEV:
      return "daed79ed-832a-435e-97bc-cefeb4c3aa2b";
    case MchubEnvironment.PREPROD:
      return "29a1f0c7-a310-4db1-b0bf-6be17b05f4be";
    case MchubEnvironment.PROD:
    default:
      return "7bd54f49-089a-4967-9549-3c70195cf58c";
  }
};

const getMchubEndpointTemplate = (): string | undefined => {
  const envToUse = getMchubEnvironment();
  switch (envToUse) {
    case MchubEnvironment.DEV:
      return "https://mchub-azure-proxy-dev.{region}.oci.{secondLevelDomain}";
    case MchubEnvironment.PREPROD:
      return "https://mchub-azure-proxy-preprod.{region}.oci.{secondLevelDomain}";
    case MchubEnvironment.PROD:
    default:
      // Use mchub environment from yaml file
      return undefined;
  }
};

const getTelemetryTransportToUse = (): TelemetryTransport => {
  const config = getConfigOverride();
  const useCourierTransport = config?.useCourierTransport;
  return useCourierTransport ? TelemetryTransport.COURIER : TelemetryTransport.MULTI_CLOUD;
};

const getTelemetryAdaptorEndpoint = (): string => {
  const envToUse = getMchubEnvironment();
  switch (envToUse) {
    case MchubEnvironment.DEV:
      return "https://mchub-telemetry-adaptor-dev.us-ashburn-1.oci.oraclecloud.com/20180828"; // Dev
    case MchubEnvironment.PREPROD:
      return "https://mchub-telemetry-adaptor-preprod.us-ashburn-1.oci.oraclecloud.com/20180828"; // PreProd
    case MchubEnvironment.PROD:
    default:
      return "https://mchub-telemetry-adaptor.us-ashburn-1.oci.oraclecloud.com/20180828"; // Prod
  }
};

const getCourierEndpoint = (): string => {
  const envToUse = getMchubEnvironment();
  switch (envToUse) {
    case MchubEnvironment.DEV:
    case MchubEnvironment.PREPROD:
      return "https://courier-service-stage.oraclecloud.com/20200828"; // Test
    case MchubEnvironment.PROD:
    default:
      return "https://courier-service.oraclecloud.com/20200828"; // Prod
  }
};

const getTelemetryPrefix = (): string => {
  let suffix: string;
  const { hostname } = window.location;
  if (hostname === "localhost.oracle.com") {
    suffix = "localhost";
  } else {
    const envToUse = getMchubEnvironment();
    switch (envToUse) {
      case MchubEnvironment.DEV:
        suffix = "dev";
        break;
      case MchubEnvironment.PREPROD:
        suffix = "preprod";
        break;
      case MchubEnvironment.PROD:
      default:
        suffix = "prod";
        break;
    }
  }
  return `o4a_console_${suffix}`;
};

const getFeedbackServiceUrl = (): string => {
  // As CFS is only in one region (us-phoenix-1) so hardcoding it.
  // https://bitbucket.oci.oraclecorp.com/projects/UX/repos/oci-console/browse/apps/shell/src/utils/urls.ts#186
  const region = "us-phoenix-1";
  const secondLevelDomain = getTopLevel(region);
  const serviceName = "feedback";
  const version = "20210801";

  const pattern = "https://{serviceName}.oci.{secondLevelDomain}/{version}";
  return buildServiceUrl(serviceName, region, secondLevelDomain, version, pattern);
};

export const clientId = getClientId();
export const mchubEndpointTemplate = getMchubEndpointTemplate();
export const telemetryTransport = getTelemetryTransportToUse();
export const telemetryAdaptorEndpoint = getTelemetryAdaptorEndpoint();
export const courierEndpoint = getCourierEndpoint();
export const telemetrySource = "o4a_console_client";
export const telemetryPrefix = getTelemetryPrefix();
export const feedbackApiUrl = getFeedbackServiceUrl();
