import {
  AsyncFieldError,
  AsyncValidationError,
  BookmarkablePage,
  FormValues,
  getValue,
  InputWizardItemMessage,
  InputWizardPanel,
  InputWizardPanelComponent,
  MessageType,
  PanelMessage,
  RadioGroupOption,
  ReviewItem,
  ReviewSection,
  SelectOption,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { Stack } from "@fluentui/react";
import { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  AdbsNetworkAddressCollectionType,
  Fields as AdbsNetworkAddressFields,
} from "../../components/AdbsNetworkAddressCollection/AdbsNetworkAddressCollection";
import { DeploymentAsyncValidationPhase } from "../../components/DeploymentPhases/DeploymentAsyncValidationPhase";
import { DeployedService, DeploymentCreatePhase } from "../../components/DeploymentPhases/DeploymentCreationPhase";
import { NetworkAddressCollectionEntry } from "../../components/NetworkAddressCollection/NetworkAddressCollection";
import { GROUP_TAGS } from "../../components/Tags/TagsCollection";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  ADBS_CREATE_ROUTE,
  APEX_CREATE_ROUTE,
  AUTONOMOUS_DATABASE_SHARED_RESOURCE_TYPE_PATH,
  PageId,
  PageRegistrationConfig,
} from "../../constants/pluginConstants";
import { CreateWizardTestIds } from "../../constants/uiConstants";
import {
  AdbsDeploymentParametersDetails,
  CreateAdbsDatabaseDeploymentDetails,
  CreateMultiCloudVirtualNetworkDeploymentDetails,
  GetMultiCloudVirtualNetworkDeploymentDetails,
  MultiCloudVirtualNetworkDeploymentDetails,
  MultiCloudVirtualNetworkSummary,
  RegisterExistingOciNetworkDeploymentDetails,
} from "../../gen/clients/mchub-azure-api-client";
import {
  CreateAdbsDatabaseDeploymentDetailsDatabaseEditionEnum,
  CreateAdbsDatabaseDeploymentDetailsDbWorkloadEnum,
  CreateAdbsDatabaseDeploymentDetailsNetworkAccessTypeEnum,
} from "../../gen/clients/mchub-azure-api-client-adb";
import { parseId } from "../../helpers/idHelper";
import {
  ACCESS_TYPES,
  AdbWorkloadType,
  DeploymentKinds,
  FutureMcvcn,
  FutureMcvcnExisting,
  FutureMcvcnNew,
  getAddressRange,
  getDatabaseAccessEndpoint,
  getResourceTypeMessage,
  getTagsPerResourceTypeMap,
  getToolsAccessEndpoint,
  LICENSE_TYPE,
  ResourceType,
  TagsInfoType,
} from "../../helpers/resourceHelper";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { getTimestamp } from "../../helpers/timeHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useRoles } from "../../hooks/useRoles";
import {
  ADBS_DEPLOYMENT_CREATE_POLL_DELAY,
  ADBS_DEPLOYMENT_CREATE_POLL_INTERVAL,
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
} from "../../models/AsyncNotificationProviders";
import { ReviewTabContent } from "../CreateCommon/ReviewTabContent";
import { Fields as TagsFields, TagsTabContent } from "../CreateCommon/TagsTabContent";
import { BasicsTabContent, Fields as BasicsFields, GROUP_BASICS } from "./BasicsTabContent";
import { ConfigTabContent, Fields as ConfigFields, GROUP_CONFIG } from "./ConfigTabContent";
import { Fields as NetworkFields, GROUP_NETWORK, NetworkTabContent } from "./NetworkTabContent";
import { Fields as SecurityFields, GROUP_SECURITY, SecurityTabContent } from "./SecurityTabContent";

const registrationIds = PageRegistrationConfig[PageId.ADBS_CREATE].map(config => config.key);

export const onProcessAsyncValidationErrors = (
  asyncErrors: AsyncValidationError[],
): AsyncValidationError[] => asyncErrors.map(asyncError => {
  if (typeof asyncError === "string") {
    return asyncError;
  }
  const asyncFieldError = asyncError as AsyncFieldError;
  let fieldLabel;
  if (asyncFieldError.field.startsWith("multiCloudVirtualNetworkDetails.")) {
    if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.name") {
      fieldLabel = Messages.labels.name();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.azureAttachedNetworkIds") {
      fieldLabel = Messages.labels.virtualNetwork();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociVcnCidrBlocks") {
      fieldLabel = Messages.labels.cidrBlocks();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociVcnOcid") {
      fieldLabel = Messages.labels.vcnOcid();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociSubnetOcids") {
      fieldLabel = Messages.labels.subnetOcids();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.customerNvaIpAddress") {
      fieldLabel = Messages.labels.networkVirtualAppliance();
    } else {
      return asyncFieldError;
    }

    asyncFieldError.field = NetworkFields.Mcvcn;
    asyncFieldError.error = `<strong>(${Messages.common.new()} | ${fieldLabel})</strong> ${asyncFieldError.error}`;
  }
  return asyncFieldError;
});

enum CreationPhase {
  ASYNC_VALIDATE = "ASYNC_VALIDATE",
  CREATE_SUBMIT = "CREATE_SUBMIT",
}

export enum PanelTestIds {
  Basics = "basic",
  Configuration = "configuration",
  Network = "network",
  Security = "security",
  Tags = "tags",
}

export const AdbsCreatePage = (): JSX.Element => {
  const { pathname } = useLocation();
  const isApex = pathname.indexOf(APEX_CREATE_ROUTE) >= 0;

  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const isCreateRoleMissing = !isActionAllowed(RoleBasedAction.CREATE_ADBS);
  const requiredRole = actionRequiredRoles(RoleBasedAction.CREATE_ADBS)[0]?.displayName;

  const { trackActionDiscard } = useAnalytics();

  const { back, navigateToSelf, customData } = useNavigation(ConsoleContext);
  const {
    enableAdbsOpenPL,
    enableAdbsRestrictedPL,
    enableAdbsPreflight,
    enableAdbsAzurePE,
    enableMcvcn,
    enableAdbsAzurePEPeering,
    enableAdbsCharacterSet,
  } = useFeatures();
  const [subscriptionId, setSubscriptionId] = React.useState<string>("");
  const [resourceGroupName, setResourceGroupName] = React.useState<string>("");
  const [location, setLocation] = React.useState<string>("");
  const [workload, setWorkload] = React.useState<AdbWorkloadType>();

  const resourceNameRef = React.useRef<string>("");
  const deploymentNameRef = React.useRef<string>(""); // to hold the name to be used in failure message

  const [creationPhase, setCreationPhase] = React.useState<CreationPhase | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [createAdbsPayload, setCreateAdbsPayload] = React.useState<any>();
  const asyncValidationCallbacks = React.useRef<{
    resolve:(errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ resolve: () => {}, reject: () => {} });

  const submitCallbacks = React.useRef<{
    reject:(errorMessage: string, validationErrors?: AsyncValidationError[]) => void
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ reject: () => {} });

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(ADBS_CREATE_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [panelRef, setPanelRef] = React.useState<InputWizardPanelComponent>({} as InputWizardPanelComponent);

  const onAsyncValidate = (
    formValues: FormValues,
    resolve: (errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
  ): void => {
    setCreateAdbsPayload(buildCreateAdbsPayload(formValues));
    asyncValidationCallbacks.current = { resolve, reject };
    setCreationPhase(CreationPhase.ASYNC_VALIDATE);
  };

  const onSubmit = (formValues: FormValues): void => {
    setCreateAdbsPayload(buildCreateAdbsPayload(formValues));
    setCreationPhase(CreationPhase.CREATE_SUBMIT);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildCreateAdbsPayload = (formValues: FormValues): any => {
    const tags = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS);
    const tagsMap = getTagsPerResourceTypeMap(tags);

    const name = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS) ?? "";
    resourceNameRef.current = name;

    let whitelistedIps: string[] | undefined;
    let vnetId: string | undefined;
    let subnetId: string | undefined;
    let hostnamePrefix: string | undefined;
    let multiCloudVirtualNetworkDetails: MultiCloudVirtualNetworkDeploymentDetails | undefined;
    const accessType = getValue<RadioGroupOption>(
      formValues,
      NetworkFields.NetworkAccessType,
      GROUP_NETWORK,
    )?.id as CreateAdbsDatabaseDeploymentDetailsNetworkAccessTypeEnum | undefined;
    const isRouteVNETChecked = getValue<boolean>(formValues, NetworkFields.RouteVNET, GROUP_NETWORK);
    const isNetworkPeeringChecked = enableMcvcn && enableAdbsAzurePEPeering
      && getValue<boolean>(formValues, NetworkFields.NetworkPeering, GROUP_NETWORK);
    let networkAccessType = accessType;

    if (accessType === ACCESS_TYPES.ALLOWED_IPS) {
      whitelistedIps = getValue<NetworkAddressCollectionEntry[]>(formValues, NetworkFields.IPsCidrs, GROUP_NETWORK)
        ?.filter((ele: NetworkAddressCollectionEntry) => ele.address !== undefined)
        .map((ele: NetworkAddressCollectionEntry) => ele.address);
      if (isRouteVNETChecked && enableAdbsRestrictedPL) {
        vnetId = getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].id;
        subnetId = getValue<SelectOption[]>(formValues, NetworkFields.Subnet, GROUP_NETWORK)?.[0].id;
      }
    } else if (accessType === ACCESS_TYPES.EVERYWHERE && isRouteVNETChecked && enableAdbsOpenPL) {
      vnetId = getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].id;
      subnetId = getValue<SelectOption[]>(formValues, NetworkFields.Subnet, GROUP_NETWORK)?.[0].id;
    } else if (accessType === ACCESS_TYPES.PRIVATE && enableAdbsAzurePE) {
      if (isNetworkPeeringChecked) {
        multiCloudVirtualNetworkDetails = buildMcvcn(formValues);
        networkAccessType = ACCESS_TYPES.PRIVATE_PEERING;
      } else {
        vnetId = getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].id;
        subnetId = getValue<SelectOption[]>(formValues, NetworkFields.Subnet, GROUP_NETWORK)?.[0].id;
      }
      hostnamePrefix = getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK);
    }
    const selectedWorkload = getValue<SelectOption[]>(
      formValues,
      ConfigFields.WorkloadType,
      GROUP_CONFIG,
    )?.[0]?.id as CreateAdbsDatabaseDeploymentDetailsDbWorkloadEnum | undefined;
    const licenseTypeId = getValue<SelectOption[]>(
      formValues,
      ConfigFields.LicenseType,
      GROUP_CONFIG,
    )?.[0].id as LICENSE_TYPE | undefined;

    const dbName = getValue<string>(formValues, ConfigFields.DatabaseName, GROUP_CONFIG) ?? "";
    const dataStorageSize = getValue<string>(formValues, ConfigFields.StorageSize, GROUP_CONFIG);
    const dataStorageSizeInTBs = dataStorageSize ? parseInt(dataStorageSize, 10) : undefined;
    const adminPassword = getValue<string>(formValues, SecurityFields.Password, GROUP_SECURITY) ?? "";
    const databaseEdition = getValue<SelectOption[]>(
      formValues,
      ConfigFields.DatabaseEdition,
      GROUP_CONFIG,
    )?.[0].id as CreateAdbsDatabaseDeploymentDetailsDatabaseEditionEnum | undefined;

    const coreCount = getValue<string>(formValues, ConfigFields.CoreCount, GROUP_CONFIG);
    const cpuCoreCount = coreCount ? parseInt(coreCount, 10) : undefined;
    const characterSet = enableAdbsCharacterSet
      ? getValue<SelectOption[]>(formValues, ConfigFields.CharacterSet, GROUP_CONFIG)?.[0].id : undefined;
    const nCharacterSet = enableAdbsCharacterSet
      ? getValue<SelectOption[]>(formValues, ConfigFields.NationalCharacterSet, GROUP_CONFIG)?.[0].id
      : undefined;
    const workloadType = getValue<SelectOption[]>(formValues, ConfigFields.WorkloadType, GROUP_CONFIG)?.[0].id;

    const adbsDatabaseDetails: CreateAdbsDatabaseDeploymentDetails = {
      kind: DeploymentKinds.Create,
      name,
      source: "NONE",
      whitelistedIps,
      vnetId,
      subnetId,
      hostnamePrefix,
      networkAccessType: enableAdbsAzurePE ? networkAccessType : undefined,
      freeformTags: tagsMap[ResourceType.AUTONOMOUS_DATABASE],
      dbName,
      cpuCoreCount,
      characterSet: enableAdbsCharacterSet
        ? workloadType !== AdbWorkloadType.JSON ? characterSet : undefined : undefined,
      ncharacterSet: enableAdbsCharacterSet
        ? workloadType !== AdbWorkloadType.JSON ? nCharacterSet : undefined : undefined,
      dbWorkload: isApex ? AdbWorkloadType.APEX : selectedWorkload,
      dbVersion: getValue<SelectOption[]>(formValues, ConfigFields.DbVersion, GROUP_CONFIG)?.[0].id,
      dataStorageSizeInTBs,
      licenseModel: isApex ? LICENSE_TYPE.LICENSE_INCLUDED : licenseTypeId,
      ...licenseTypeId === LICENSE_TYPE.BRING_YOUR_OWN_LICENSE
        ? { databaseEdition }
        : undefined,
      isAutoScalingEnabled: getValue<boolean>(formValues, ConfigFields.AutoScaling, GROUP_CONFIG),
      adminPassword,
      isMtlsConnectionRequired: !isApex && selectedWorkload !== AdbWorkloadType.APEX
        ? getValue<boolean>(formValues, NetworkFields.MTLSAuthentication, GROUP_NETWORK) : undefined,
    };
    deploymentNameRef.current = `${AUTONOMOUS_DATABASE_SHARED_RESOURCE_TYPE_PATH}-${getTimestamp()}`;
    const createDeploymentDetails = {
      name: deploymentNameRef.current,
      parameters: {
        kind: DeploymentKinds.Adbs,
        multiCloudVirtualNetworkDetails,
        adbsDatabaseDetails,
      } as AdbsDeploymentParametersDetails,
    };

    return {
      subscriptionId: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].id,
      resourceGroupName: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      apiVersion: MultiCloudDatabaseApiVersion,
      createDeploymentDetails,
    };
  };

  const buildMcvcn = (formValues: FormValues): MultiCloudVirtualNetworkDeploymentDetails => {
    // eslint-disable-next-line max-len
    const data: FutureMcvcnNew | FutureMcvcnExisting | MultiCloudVirtualNetworkSummary | undefined = getValue<SelectOption[]>(
      formValues,
      NetworkFields.Mcvcn,
      GROUP_NETWORK,
    )?.[0]?.data;

    // Only exists if the user has selected an existing MCVCN
    const { id } = data as MultiCloudVirtualNetworkSummary;
    // Exists if the user is creating a new MCVCN
    const { name, azureAttachedNetworkId, customerNvaIpAddress } = data as FutureMcvcn;
    // Exists if the user is creating a new MCVCN using an existing OCI VCN
    const { ociSubnetOcids, ociVcnOcid } = data as FutureMcvcnExisting;
    // Exists if the user is creating a new MCVCN using a new OCI VCN
    const { ociVcnCidrBlocks } = data as FutureMcvcnNew;

    let mcvcnDetails: MultiCloudVirtualNetworkDeploymentDetails;
    const azureAttachedNetworkIds = [azureAttachedNetworkId];

    if (id) {
      mcvcnDetails = {
        kind: DeploymentKinds.Get,
        id,
      } as GetMultiCloudVirtualNetworkDeploymentDetails;
    } else if (ociVcnCidrBlocks) {
      mcvcnDetails = {
        kind: DeploymentKinds.Create,
        azureAttachedNetworkIds,
        name,
        ociVcnCidrBlocks,
        customerNvaIpAddress,
      } as CreateMultiCloudVirtualNetworkDeploymentDetails;
    } else if (ociVcnOcid && ociSubnetOcids) {
      mcvcnDetails = {
        kind: DeploymentKinds.Register,
        azureAttachedNetworkIds,
        name,
        ociVcnOcid,
        ociSubnetOcids,
        customerNvaIpAddress,
      } as RegisterExistingOciNetworkDeploymentDetails;
    } else throw new Error("Invalid parameters to create MCVCN");

    return mcvcnDetails;
  };

  const buildBasicInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewBasicInfo: ReviewItem[] = [
      {
        label: Messages.labels.subscription(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.resourceGroup(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.name(),
        value: getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS),
      },
      {
        label: Messages.labels.region(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Region, GROUP_BASICS)?.[0].text,
      },
    ];
    return reviewBasicInfo;
  };
  const buildConfigurationInfo = (formValues: FormValues): ReviewItem[] => {
    const licenseTypeId = getValue<SelectOption[]>(formValues, ConfigFields.LicenseType, GROUP_CONFIG)?.[0].id;
    const autoScaling = getValue<boolean>(formValues, ConfigFields.AutoScaling, GROUP_CONFIG);
    const workloadType = getValue<SelectOption[]>(formValues, ConfigFields.WorkloadType, GROUP_CONFIG)?.[0].id;

    const reviewConfigInfo: ReviewItem[] = [
      ...(!isApex ? [{
        label: Messages.labels.workloadType(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.WorkloadType, GROUP_CONFIG)?.[0].text,
      }] : []
      ),
      {
        label: Messages.labels.ocpuCount(),
        value: getValue<string>(formValues, ConfigFields.CoreCount, GROUP_CONFIG),
      },
      {
        label: Messages.labels.ocpuAutoScaling(),
        value: autoScaling ? Messages.common.enabled() : Messages.common.disabled(),
      },
      {
        label: Messages.labels.storageInTB(),
        value: getValue<string>(formValues, ConfigFields.StorageSize, GROUP_CONFIG),
      },
      ...(!isApex ? [{
        label: Messages.labels.licenseType(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.LicenseType, GROUP_CONFIG)?.[0].text,
      }] : []
      ),
      ...(!isApex && licenseTypeId === LICENSE_TYPE.BRING_YOUR_OWN_LICENSE ? [{
        label: Messages.labels.oracleDatabaseEdition(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.DatabaseEdition, GROUP_CONFIG)?.[0].text,
      }] : []
      ),
      {
        label: Messages.labels.databaseVersion(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.DbVersion, GROUP_CONFIG)?.[0].text,
      },
      {
        label: Messages.labels.databaseName(),
        value: getValue<string>(formValues, ConfigFields.DatabaseName, GROUP_CONFIG),
      },
       
    ];
    if (enableAdbsCharacterSet) {
      if (workloadType !== AdbWorkloadType.JSON) {
        reviewConfigInfo.push(
          {
            label: Messages.labels.characterSet(),
            value: getValue<SelectOption[]>(formValues, ConfigFields.CharacterSet, GROUP_CONFIG)?.[0].text,
          },
          {
            label: Messages.labels.nationalCharacterSet(),
            value: getValue<SelectOption[]>(formValues, ConfigFields.NationalCharacterSet, GROUP_CONFIG)?.[0].text,
          },
        );
      }
    }
    return reviewConfigInfo;
  };
  const buildNetworkingInfo = (formValues: FormValues): ReviewItem[] => {
    const renderCidrValue = (values: string[]): JSX.Element => {
      const cidrs = values.map(range => (
        <Stack horizontal key={range}>
          <span style={{ paddingBottom: 1 }}>
            {range}
          </span>
        </Stack>
      ));
      return (
        <div>
          {cidrs}
        </div>
      );
    };

    const renderOcidValue = (values: string[]): JSX.Element => {
      const ocids = values.map(ocid => (
        <Stack horizontal key={ocid}>
          <span style={{ paddingBottom: 1 }}>
            {ocid}
          </span>
        </Stack>
      ));
      return (
        <div>
          {ocids}
        </div>
      );
    };

    const addressesInfo = getValue<AdbsNetworkAddressCollectionType>(
      formValues,
      NetworkFields.IPsCidrs,
      GROUP_NETWORK,
    );
    const accessType = getValue<RadioGroupOption>(formValues, NetworkFields.NetworkAccessType, GROUP_NETWORK);
    const isRouteVNET = getValue<boolean>(formValues, NetworkFields.RouteVNET, GROUP_NETWORK);
    const isNetworkPeering = enableMcvcn && enableAdbsAzurePEPeering
      && getValue<boolean>(formValues, NetworkFields.NetworkPeering, GROUP_NETWORK);
    const selectedWorkload = getValue<SelectOption[]>(formValues, ConfigFields.WorkloadType, GROUP_CONFIG)?.[0]?.id;
    const renderIPsCidrs = (
      values: AdbsNetworkAddressCollectionType | undefined,
    ): JSX.Element => {
      const cidrs = values?.map(value => {
        if (value && value?.[AdbsNetworkAddressFields.Address]) {
          const address = value[AdbsNetworkAddressFields.Address];
          return (
            <Stack horizontal key={address}>
              <span style={{ paddingBottom: 1 }}>
                {`${address} [${getAddressRange(address)}]`}
              </span>
            </Stack>
          );
        }
        return "";
      });
      return (
        <div>
          {cidrs}
        </div>
      );
    };
    const reviewNetworkingInfo: ReviewItem[] = [
      {
        label: Messages.labels.accessType(),
        value: accessType?.text,
      }];
    if (accessType?.id === ACCESS_TYPES.ALLOWED_IPS) {
      reviewNetworkingInfo.push({
        label: Messages.labels.ipsAndOrCidrs(),
        value: renderIPsCidrs(addressesInfo),
      });
    }
    if (accessType?.id === ACCESS_TYPES.PRIVATE && enableAdbsAzurePE) {
      if (isNetworkPeering) {
        // eslint-disable-next-line max-len
        const mcvcn: MultiCloudVirtualNetworkSummary | FutureMcvcnExisting | FutureMcvcnNew | undefined = getValue<SelectOption[]>(
          formValues,
          NetworkFields.Mcvcn,
          GROUP_NETWORK,
        )?.[0].data;

        if (!mcvcn) return [];

        // ID only exists if the user has selected an existing MCVCN
        const createNewMcvcn = !(mcvcn as MultiCloudVirtualNetworkSummary).id;

        // Exists if the user is creating a new MCVCN
        const { name, customerNvaIpAddress, azureAttachedNetworkId } = mcvcn as FutureMcvcn;
        const virtualNetwork = decodeURIComponent(parseId(azureAttachedNetworkId)?.resourceName || "");

        // Exists if the user is creating a new MCVCN using a new OCI VCN
        const { ociVcnCidrBlocks } = mcvcn as FutureMcvcnNew;
        // Exists if the user is creating a new MCVCN using an existing OCI VCN
        const { ociSubnetOcids, ociVcnOcid } = mcvcn as FutureMcvcnExisting;

        reviewNetworkingInfo.push(
          {
            label: Messages.labels.hostName(),
            value: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK),
          },
          {
            label: Messages.labels.virtualCloudNetwork(),
            value: name,
          },
        );

        if (createNewMcvcn) {
          reviewNetworkingInfo.push(
            ...(virtualNetwork
              ? [{ label: Messages.labels.virtualNetwork(), value: virtualNetwork }]
              : []),
            ...(customerNvaIpAddress
              ? [{ label: Messages.labels.networkVirtualAppliance(), value: customerNvaIpAddress }]
              : []),
            ...(ociVcnOcid
              ? [{ label: Messages.labels.vcnOcid(), value: ociVcnOcid }]
              : []),
            ...(ociSubnetOcids
              ? [{ label: Messages.labels.subnetOcids(), value: renderOcidValue(ociSubnetOcids) }]
              : []),
            ...(ociVcnCidrBlocks
              ? [{
                label: Messages.labels.cidrBlocks(),
                value: renderCidrValue(ociVcnCidrBlocks.map(cidr => `${cidr} [${getAddressRange(cidr)}]`)),
              }]
              : []),
          );
        }
      } else {
        reviewNetworkingInfo.push(
          {
            label: Messages.labels.virtualNetwork(),
            value: getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].text,
          },
          {
            label: Messages.labels.subnet(),
            value: getValue<SelectOption[]>(formValues, NetworkFields.Subnet, GROUP_NETWORK)?.[0].text,
          },
        );
      }
    }
    if ((accessType?.id === ACCESS_TYPES.EVERYWHERE && enableAdbsOpenPL)
      || (accessType?.id === ACCESS_TYPES.ALLOWED_IPS && enableAdbsRestrictedPL)) {
      reviewNetworkingInfo.push(
        {
          label: Messages.labels.routeVNET(),
          value: isRouteVNET ? Messages.common.enabled() : Messages.common.disabled(),
        },
      );
      if (isRouteVNET) {
        reviewNetworkingInfo.push(
          {
            label: Messages.labels.virtualNetwork(),
            value: getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].text,
          },
          {
            label: Messages.labels.subnet(),
            value: getValue<SelectOption[]>(formValues, NetworkFields.Subnet, GROUP_NETWORK)?.[0].text,
          },
        );
      }
    }
    if (!isApex && selectedWorkload !== AdbWorkloadType.APEX) {
      const mTlsAuthentication = getValue<boolean>(formValues, NetworkFields.MTLSAuthentication, GROUP_NETWORK);
      reviewNetworkingInfo.push({
        label: Messages.labels.mutualAuthentication(),
        value: mTlsAuthentication ? Messages.common.required() : Messages.common.notRequired(),
      });
    }
    if (accessType?.id === ACCESS_TYPES.PRIVATE && enableAdbsAzurePE) {
      const hostnameVal = getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK) ?? "";
      reviewNetworkingInfo.push(
        {
          label: Messages.labels.hostName(),
          value: hostnameVal,
        },
        {
          label: Messages.labels.databaseAccessEndpoint(),
          value: getDatabaseAccessEndpoint(hostnameVal, location),
        },
        {
          label: Messages.labels.toolsAccessEndpoint(),
          value: getToolsAccessEndpoint(hostnameVal, location),
        },
      );
    }

    return reviewNetworkingInfo;
  };
  const buildSecurityInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewSecurityInfo: ReviewItem[] = [
      {
        label: Messages.labels.username(),
        value: getValue<string>(formValues, SecurityFields.Username, GROUP_SECURITY),
      },
    ];
    return reviewSecurityInfo;
  };

  const buildTagsInfo = (formValues: FormValues): ReviewItem[] => {
    const tagsInfo = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS) ?? [];
    const tags: { label: string; value: string; }[] = [];

    tagsInfo.forEach(element => (
      element.resources?.forEach(resource => (
        element.name !== undefined && tags.push({
          label: element.name,
          value: `${element.value !== undefined ? element.value : ""}(${resource.text})`,
        })
      ))));
    if (tags.length === 0) tags.push({ label: "None", value: "" });
    tags.sort((a, b) => ((a.value.split("(")[1].split(")")[0] > b.value.split("(")[1].split(")")[0]) ? 1 : -1));
    return tags;
  };

  const onRenderReview = (formValues: FormValues): JSX.Element => {
    const reviewSections = [
      { title: Messages.createAdbs.basicsTab.title(), items: buildBasicInfo(formValues) },
      { title: Messages.createAdbs.configurationTab.title(), items: buildConfigurationInfo(formValues) },
      { title: Messages.createAdbs.networkingTab.title(), items: buildNetworkingInfo(formValues) },
      { title: Messages.createAdbs.securityTab.title(), items: buildSecurityInfo(formValues) },
      { title: Messages.tags.title(), items: buildTagsInfo(formValues) },
    ] as ReviewSection[];
    return <ReviewTabContent reviewSections={reviewSections} />;
  };

  const getTagsResourceTypeOptions = (): SelectOption[] => {
    const resourceOptions: SelectOption[] = [
      isApex
        ? { id: ResourceType.APEX, text: getResourceTypeMessage(ResourceType.APEX) }
        : { id: ResourceType.AUTONOMOUS_DATABASE, text: getResourceTypeMessage(ResourceType.AUTONOMOUS_DATABASE) },
    ];
    return resourceOptions;
  };

  const longTitle = isApex ? Messages.createApex.titles.long() : Messages.createAdbs.titles.long();
  const shortTitle = isApex ? Messages.createApex.titles.short() : Messages.createAdbs.titles.short();
  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={shortTitle}
    >
      <InputWizardPanel
        componentRef={setPanelRef}
        title={longTitle}
        testId={CreateWizardTestIds.Adbs}
        message={isCreateRoleMissing
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(requiredRole),
          } as PanelMessage
          : undefined}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFormChange={(_formValues: FormValues, fieldValue: any, field: string) => {
          if (field === BasicsFields.Subscription) {
            setSubscriptionId(fieldValue?.[0]?.id);
          } else if (field === BasicsFields.ResourceGroup) {
            setResourceGroupName(fieldValue?.[0]?.text);
          } else if (field === BasicsFields.Region) {
            setLocation(fieldValue?.[0]?.id);
          } else if (field === ConfigFields.WorkloadType) {
            setWorkload(fieldValue?.[0]?.id);
          }
        }}
        items={[
          {
            header: Messages.createAdbs.basicsTab.title(),
            groupName: GROUP_BASICS,
            testId: PanelTestIds.Basics,
            content: (
              <BasicsTabContent
                location={location}
                subscription={subscriptionId}
                inputWizardPanelRef={panelRef}
              />
            ),
          },
          {
            header: Messages.createAdbs.configurationTab.title(),
            groupName: GROUP_CONFIG,
            testId: PanelTestIds.Configuration,
            content: (
              <ConfigTabContent
                subscription={subscriptionId}
                location={location}
                inputWizardPanelRef={panelRef}
                isApex={isApex}
              />
            ),
          },
          {
            header: Messages.createAdbs.networkingTab.title(),
            groupName: GROUP_NETWORK,
            testId: PanelTestIds.Network,
            content: (
              <NetworkTabContent
                subscription={subscriptionId}
                location={location}
                inputWizardPanelRef={panelRef}
                workload={workload}
                isApex={isApex}
              />
            ),
          },
          {
            header: Messages.createAdbs.securityTab.title(),
            groupName: GROUP_SECURITY,
            testId: PanelTestIds.Security,
            content: <SecurityTabContent />,
          },
          {
            header: Messages.tags.title(),
            groupName: GROUP_TAGS,
            testId: PanelTestIds.Tags,
            content: <TagsTabContent resourceOptions={getTagsResourceTypeOptions()} />,
          },
        ]}
        itemsMessages={[{
          itemNdx: 0,
          type: MessageType.WARNING,
          message: Messages.createCommon.dependencyWarnings.basicsChangeWarning(Messages.labels.autonomousDatabase()),
          hideOnFirstSelect: true,
        }] as InputWizardItemMessage[]}
        onRenderReview={onRenderReview}
        onUnselectingReview={() => {
          setCreateAdbsPayload(undefined);
          setCreationPhase(undefined);
        }}
        onAsyncValidate={enableAdbsPreflight ? onAsyncValidate : undefined}
        onSubmit={(
          formValues: FormValues,
          reject: (errorMessage: string, validationErrors?: AsyncValidationError[]) => void,
        ) => {
          submitCallbacks.current = { reject };
          onSubmit(formValues);
        }}
        onClose={() => {
          const analytics = customData?.analytics as NavigationAnalyticsData;
          if (analytics) {
            trackActionDiscard(analytics.actionName, analytics.pageId, analytics.panelId);
          }
          back();
        }}
      />
      {creationPhase === CreationPhase.CREATE_SUBMIT && (
        <DeploymentCreatePhase
          panelRef={panelRef}
          location={location}
          deployedService={isApex ? DeployedService.APEX : DeployedService.AUTONOMOUS_SHARED_DATABASE}
          resourceName={resourceNameRef.current}
          deploymentName={deploymentNameRef.current}
          resourceGroupName={resourceGroupName}
          subscriptionId={subscriptionId}
          submitPayload={createAdbsPayload}
          submitReject={submitCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          asyncNotification={{
            methodKey: AsyncNotificationMethodKey.DEPLOYMENT_GET,
            delay: ADBS_DEPLOYMENT_CREATE_POLL_DELAY,
            pollingInterval: ADBS_DEPLOYMENT_CREATE_POLL_INTERVAL,
            polledResponseKey: AsyncNotificationPolledResponseKey.ADBS_DEPLOYMENT_CREATED_CHECK,
          }}
          onPostSubmit={() => {
            setCreateAdbsPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
      {creationPhase === CreationPhase.ASYNC_VALIDATE && (
        <DeploymentAsyncValidationPhase
          location={location}
          asyncValidatePayload={createAdbsPayload}
          asyncValidateResolve={asyncValidationCallbacks.current.resolve}
          asyncValidateReject={asyncValidationCallbacks.current.reject}
          onPostAsyncValidate={() => {
            setCreateAdbsPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
    </BookmarkablePage>
  );
};
