import {
  FormValues,
  getValue,
  ReadOnlyText,
  SimpleBuilder,
  SimpleBuilderProps,
  TextInput,
  TextInputProps,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { getAddressRange, maxMcvcnCidrPrefix } from "../../helpers/resourceHelper";
import { validateOverlappingAddresses, validateRowNetworkLinkAddressSpace } from "../../helpers/validationHelper";

export enum Fields {
  Address = "address",
  Addresses = "addresses",
}

export enum FieldTestIds {
  Address = "address",
  Addresses = "addresses",
}

export type NetworkAddressCollectionEntry = { [Fields.Address]: string };
export type NetworkAddressCollectionType = NetworkAddressCollectionEntry[];

type BaseNetworkAddressInputProps = Pick<TextInputProps, "disabled" >;
type BaseSimpleBuilderProps = Pick<
SimpleBuilderProps,
"defaultValue"
| "onChange"
| "fieldName"
| "label"
| "maxRows"
| "minRows"
| "required"
| "testId"
| "tooltip"
>;

type BaseProps = BaseNetworkAddressInputProps & BaseSimpleBuilderProps;

export interface NetworkAddressCollectionProps extends BaseProps {
  addressSpaces?: string[]
}

export const NetworkAddressCollection = ({
  addressSpaces,
  disabled,
  ...props
}: NetworkAddressCollectionProps): JSX.Element => (
  <SimpleBuilder
    {...props}
    rowModifier={(rowValues: FormValues) => (
      { [Fields.Addresses]: getAddressRange(getValue<string>(rowValues, Fields.Address)) }
    )}
    validator={validateOverlappingAddresses(Fields.Address)}
    rowValidator={validateRowNetworkLinkAddressSpace(maxMcvcnCidrPrefix, Fields.Address, addressSpaces)}
  >
    <TextInput
      label={Messages.labels.cidr()}
      disabled={disabled}
      fieldName={Fields.Address}
      testId={FieldTestIds.Address}
    />
    <ReadOnlyText
      defaultValue={getAddressRange()}
      fieldName={Fields.Addresses}
      testId={Fields.Addresses}
      label={Messages.labels.addresses()}
    />
  </SimpleBuilder>
);
