import { FieldSet, FormContext, FormState, FormValues, TextInput, TextInputTypes, uniqueGUID } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { defaultAdbsUsername } from "../../helpers/resourceHelper";
import { validateAdbPassword } from "../../helpers/validationHelper";

export const GROUP_SECURITY = "security";

export enum Fields {
  Username = "username",
  Password = "adbsDatabaseDetails.adminPassword",
  PasswordConfirm = "passwordConfirm",
}

export enum FieldTestIds {
  Username = "username-input",
  Password = "password-input",
  PasswordConfirm = "password-confirm-input",
}

export const SecurityTabContent = (): JSX.Element => {
  const [passwordVal, setPasswordVal] = React.useState<string>();
  const form: FormState = React.useContext(FormContext);
  const [confirmPassWordKey, setConfirmPasswordKey] = React.useState<string>(uniqueGUID());

  const onChangePassword = (value: string | undefined): void => {
    setPasswordVal(value);
    const formValues: FormValues = form.getGroupValues(GROUP_SECURITY);
    if (formValues[Fields.PasswordConfirm] !== undefined) {
      form.setValue(undefined, Fields.PasswordConfirm, GROUP_SECURITY);
      setConfirmPasswordKey(uniqueGUID());
    }
  };

  return (
    <FieldSet header={Messages.createAdbs.securityTab.sectionTitles.databaseAdministratorCredentials()}>
      <TextInput
        fieldName={Fields.Username}
        testId={FieldTestIds.Username}
        label={Messages.labels.username()}
        defaultValue={defaultAdbsUsername}
        disabled
      />
      <TextInput
        fieldName={Fields.Password}
        label={Messages.labels.password()}
        testId={FieldTestIds.Password}
        tooltip={Messages.hints.tooltipPasswordAdb()}
        textType={TextInputTypes.PASSWORD}
        required
        onChange={onChangePassword}
        validator={validateAdbPassword}
      />

      <TextInput
        key={confirmPassWordKey}
        fieldName={Fields.PasswordConfirm}
        label={Messages.labels.passwordConfirm()}
        testId={FieldTestIds.PasswordConfirm}
        textType={TextInputTypes.PASSWORD}
        required
        validator={(value: string | undefined) => {
          if (value && (value !== passwordVal)) {
            return [Messages.validation.passwordConfirmation()];
          }
          return undefined;
        }}
      />
    </FieldSet>
  );
};
