import { InputFormCallout, InputFormCalloutProps, Select, SubmitButtonMode } from "o4a-react";
import * as React from "react";
import { Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";
import { AzureCloudLinkSummary, AzureSubscriptionSummary } from "../../gen/clients/mchub-azure-api-client";

export enum Fields {
  CloudLink = "cloudLink",
}

export enum FieldTestIds {
  CloudLink = "cloudLink",
}

export interface LinkSubscriptionPanelProps extends
  Pick<InputFormCalloutProps, "targetId" | "onClose" | "onSubmit" | "componentRef">
{
  subscription: AzureSubscriptionSummary;
  activeCloudLinks: AzureCloudLinkSummary[];
}

export const LinkSubscriptionPanel = ({
  subscription,
  activeCloudLinks,
  targetId,
  componentRef,
  onClose,
  onSubmit,
}: LinkSubscriptionPanelProps): JSX.Element => {
  const cloudLinkOptions = activeCloudLinks.map(cloudLink => ({
    id: cloudLink.id,
    text: cloudLink.ociTenancyName || cloudLink.name,
  }));

  return (
    <InputFormCallout
      testId={CalloutTestIds.LinkSubscription}
      componentRef={componentRef}
      title={Messages.createNewPanels.linkSubscription.title()}
      targetId={targetId}
      onClose={onClose}
      submitButtonMode={
        cloudLinkOptions.length > 1
          ? SubmitButtonMode.DISABLE_TILL_VALID
          : SubmitButtonMode.ENABLE_TILL_CLICKED
      }
      onSubmit={onSubmit}
    >
      <Text
        block
        styles={{
          root: {
            marginBottom: 18,
            fontStyle: "normal",
          },
        }}
        variant="medium"
      >
        {cloudLinkOptions.length > 1
          ? Messages.createNewPanels.linkSubscription.descriptionMultipleCloudLinks(subscription.name)
          : Messages.createNewPanels.linkSubscription.descriptionSingleCloudLink(subscription.name)}
      </Text>
      {cloudLinkOptions.length > 1 && (
        <Select
          required
          testId={FieldTestIds.CloudLink}
          defaultValue={activeCloudLinks.length === 1 ? [activeCloudLinks[0].id] : undefined}
          fieldName={Fields.CloudLink}
          label={Messages.labels.ociTenant()}
          options={cloudLinkOptions}
        />
      )}
    </InputFormCallout>
  );
};
