import { ConfigInput, ConfigInputProps, FormValues, getValue, InputFormGroup, InputLink } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Fields as StoragePanelConfigFields, StoragePanelConfig } from "./StorageCallout";

export enum NewSidePanels {
  STORAGE_OPTIONS = "STORAGE_OPTIONS",
}

export enum LinkTestIds{
  StorageConfigInputLink = "storage-config-link"
}

type Props = Omit<ConfigInputProps, "configInputValue" | "inputLink">

export const StorageConfigInput = (
  {
    label,
    fieldName,
    required,
    testId,
  }: Props,
): JSX.Element => {
  const [sidePanelOpen, setSidePanelOpen] = React.useState<NewSidePanels | undefined>(undefined);
  const [configTitle, setConfigTitle] = React.useState<string>(Messages.configTitles.default());
  const [configValue, setConfigValue] = React.useState<{ [key: string]: boolean; }>({
    localEnabled: false,
    sparseEnabled: false,
  });

  function createStorageConfigLink(
    configLabel: string,
    sidePanelId: NewSidePanels,
  ): InputLink {
    return {
      id: "config_storage_allocate",
      testId: LinkTestIds.StorageConfigInputLink,
      text: configLabel,
      onLinkClick: () => setSidePanelOpen(sidePanelId),
    };
  }

  // eslint-disable-next-line max-len
  const storageConfigLink = createStorageConfigLink(Messages.createNewPanels.configStorage.description(), NewSidePanels.STORAGE_OPTIONS);

  const onSubmit = (formValues: FormValues): void => {
    const localEnabled = !!getValue<boolean>(formValues, StoragePanelConfigFields.LocalEnabled, InputFormGroup);
    const sparseEnabled = !!getValue<boolean>(formValues, StoragePanelConfigFields.SparseEnabled, InputFormGroup);
    setConfigValue({ localEnabled, sparseEnabled });
    if (!localEnabled && !sparseEnabled) {
      setConfigTitle(Messages.configTitles.default());
    } if (localEnabled && !sparseEnabled) {
      setConfigTitle(Messages.configTitles.storageForLocal());
    } if (!localEnabled && sparseEnabled) {
      setConfigTitle(Messages.configTitles.storageForSparse());
    } if (localEnabled && sparseEnabled) {
      setConfigTitle(Messages.configTitles.storageForLocalAndSparse());
    }
    setSidePanelOpen(undefined);
  };

  const createSummary = (): string => {
    if (!configValue?.localEnabled && !configValue?.sparseEnabled) {
      return Messages.configTitles.noStorageAllocatedForLocalAndSparseSummary();
    } if (configValue?.localEnabled && !configValue?.sparseEnabled) {
      return Messages.configTitles.storageLocalSummary();
    } if (!configValue?.localEnabled && configValue?.sparseEnabled) {
      return Messages.configTitles.storageSparseSummary();
    }
    return Messages.configTitles.storageForLocalAndSparseSummary();
  };

  return (
    <>
      <ConfigInput
        fieldName={fieldName}
        testId={testId}
        label={label}
        inputLink={storageConfigLink}
        required={required}
        configInputValue={{
          title: configTitle,
          summary: createSummary(),
          value: { localEnabled: configValue?.localEnabled, sparseEnabled: configValue?.sparseEnabled },
        }}
      />
      {sidePanelOpen === NewSidePanels.STORAGE_OPTIONS && (
        <StoragePanelConfig
          defaultValue={{ localEnabled: configValue.localEnabled, sparseEnabled: configValue?.sparseEnabled }}
          targetId={storageConfigLink.id}
          onSubmit={onSubmit}
          onClose={() => setSidePanelOpen(undefined)}
          title={Messages.createNewPanels.configStorage.title()}
        />
      )}
    </>
  );
};
