import { FormValues, getValue, InputFormGroup, SelectOption } from "o4a-react";
import React from "react";
import {
  Fields as QuotaRequestFields,
  QuotaRequestPanel,
  QuotaRequestValues,
} from "../../components/QuotaRequestPanel/QuotaRequestPanel";
import { OperationBaseProps } from "../../console/OperationsContext";
import { ServiceProviders } from "../../constants/pluginConstants";
import { LimitDefinitionSummary } from "../../gen/clients/mchub-azure-api-client";

export interface SupportQuotaRequestProps extends OperationBaseProps {
  provider: ServiceProviders;
  quotaRequestValues: QuotaRequestValues;
  subscriptionId: string;
}

const SupportQuotaRequest = ({
  onCancel,
  onExecute,
  provider,
  quotaRequestValues,
  subscriptionId,
}: SupportQuotaRequestProps): JSX.Element => {
  const onSubmit = (formValues: FormValues, currentLimit?: number, currentUsed?: number): void => {
    const limit: LimitDefinitionSummary = getValue<SelectOption[]>(
      formValues,
      QuotaRequestFields.LimitName,
      InputFormGroup,
    )?.[0].data;
    const location = getValue<SelectOption[]>(formValues, QuotaRequestFields.Region, InputFormGroup)?.[0].id;

    const newLimitVal = getValue<string>(formValues, QuotaRequestFields.NewLimit, InputFormGroup);
    const newLimit = newLimitVal ? parseInt(newLimitVal, 10) : undefined;

    onExecute?.({
      name: limit.name || "",
      description: limit.description || "",
      location,
      currentValue: currentLimit,
      currentUsed,
      newValue: newLimit,
    });
  };

  return (
    <QuotaRequestPanel
      onClose={onCancel}
      subscriptionId={subscriptionId}
      provider={provider}
      quotaRequestValues={quotaRequestValues}
      onSubmit={onSubmit}
    />
  );
};

export const newSupportQuotaRequest = (props: SupportQuotaRequestProps): JSX.Element => (
  <SupportQuotaRequest {...props} />
);
