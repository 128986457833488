import { CalloutComponent, FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  DeletionPlanEditCallout,
  Fields as DeletionFields,
} from "../../components/MysqlManagementEditPanel/DeletionPlanEditCallout";
import { MdsDeletionPolicyDetails, UpdateMdsDeletionPolicyDetails } from "../../gen/clients/mchub-azure-api-client-mds";
import { parseId } from "../../helpers/idHelper";
import { MdsAutoBackupRetentionPolicy, MdsFinalBackupPolicy } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_UPDATE_POLL_DELAY,
  MDS_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface MysqlEditDeletionPlanProps extends OperationActionProps {
  databaseId: string | undefined;
  deletionPolicy: MdsDeletionPolicyDetails | undefined;
}

const MysqlEditDeletionPlan = (
  { targetId, location, databaseId, deletionPolicy, onExecute, onCancel }: MysqlEditDeletionPlanProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resourceGroupName = decodeURIComponent(resourceGroup || "");
  const dbSystemName = decodeURIComponent(resourceName || "");

  const [calloutRef, setCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);
  const { invokeCall: invokeMdsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.updateMdsDbSystem },
  );

  const buildDbDeletionPolicy = (formValues: FormValues): UpdateMdsDeletionPolicyDetails => {
    const finalBackupIsRequired = getValue<boolean>(formValues, DeletionFields.RequireFinalBackup, InputFormGroup);
    const retainAutomaticBackup = getValue<boolean>(formValues, DeletionFields.RetainAutomaticBackups, InputFormGroup);
    const isDeleteProtected = getValue<boolean>(formValues, DeletionFields.DeleteProtected, InputFormGroup) as boolean;

    const policy: MdsDeletionPolicyDetails = {
      // The spec states it takes a string
      // but I am assuming it uses the same enum as what is specified in the Get interface
      automaticBackupRetention:
        retainAutomaticBackup
          ? MdsAutoBackupRetentionPolicy.RETAIN
          : MdsAutoBackupRetentionPolicy.DELETE,
      // The spec states it takes a string
      // but I am assuming it uses the same enum as what is specified in the Get interface
      finalBackup:
        finalBackupIsRequired
          ? MdsFinalBackupPolicy.REQUIRE_FINAL_BACKUP
          : MdsFinalBackupPolicy.SKIP_FINAL_BACKUP,
      isDeleteProtected,
    };

    return policy;
  };

  const onUpdate = (formValues: FormValues): void => {
    const policy = buildDbDeletionPolicy(formValues);
    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: calloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateMysqlDeletionPlan(),
          message: Messages.notifications.inProgress.messages.updateMysqlDeletionPlan(dbSystemName),
        },
        success: {
          title: Messages.notifications.success.titles.updateMysqlDeletionPlan(),
          message: Messages.notifications.success.messages.updateMysqlDeletionPlan(dbSystemName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateMysqlDeletionPlan(),
          message: Messages.notifications.failure.messages.updateMysqlDeletionPlan(dbSystemName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            dbSystemName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_UPDATED_CHECK,
          delay: MDS_UPDATE_POLL_DELAY,
          interval: MDS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeMdsUpdate({
      subscriptionId,
      resourceGroupName,
      dbSystemName,
      updateMdsDbSystemDetails: { deletionPolicy: policy },
    }, invokeOptions);
  };

  return (
    <DeletionPlanEditCallout
      title={Messages.createNewPanels.editMySqlDeletionPlan.title()}
      targetId={targetId}
      onClose={onCancel}
      dbDelete={deletionPolicy}
      onSubmit={onUpdate}
      componentRef={setCalloutRef}
    />
  );
};

export const newMysqlEditDeletionPlan = (
  props: MysqlEditDeletionPlanProps,
): JSX.Element => (<MysqlEditDeletionPlan {...props} />);
