import { InfoBlock, InfoBlockLayout, InfoBlockStatus } from "o4a-react";
import React from "react";
import { DefaultButton, IButtonStyles, mergeStyleSets, Modal, PrimaryButton, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";

export interface DownloadSshKeyModalProps {
  /**
   * The text within the cancel button
   */
  cancelBtnText: string;
  /**
   * Callback for when the modal is closed
   */
  closeModal: () => void;
  /**
   * Whether to display the modal or not
   */
  isOpen: boolean;
  /**
   * Callback for when the operation is cancelled
   */
  onCancel?: () => void;
  /**
   * Callback for when the operation is confirmed
   */
  onSubmit?: () => void;
  /**
   * The text within the submit button
   */
  submitBtnText: string;
}

const classNames = mergeStyleSets({
  modalItemsContainer: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    maxWidth: "390px",
  },
  keyTitle: {
    fontWeight: 600,
    paddingTop: "12px",
    paddingBottom: "12px",
    fontSize: "18px",
    textAlign: "center",
  },
  actionButtons: { paddingTop: "5px" },
});

const buttonStyles: IButtonStyles = { root: { height: 25 } };
const downloadButtonStyles: IButtonStyles = { root: { height: 25, padding: "0px 6px" } };

export enum InfoBlockTestIds {
  DownloadKey = "download-key",
}

export enum ButtonTestIds {
  DownloadSshKeyPrimary = "downloadSshKeyPrimary",
  DownloadSshKeyCancel = "downloadSshKeyCancel",
}

/**
 * When used as a part of the create
 * this should be triggered on final submit
 * If the user cancels then do not continue with submit
 * Otherwise the ssh key will be downloaded
 */
export const DownloadSshKeyModal: React.FC<DownloadSshKeyModalProps> = ({
  cancelBtnText,
  closeModal,
  isOpen,
  onCancel,
  onSubmit,
  submitBtnText,
}): JSX.Element => {
  const internalOnSubmit = async (): Promise<void> => {
    closeModal();
    onSubmit?.();
  };

  const internalOnCancel = (): void => {
    closeModal();
    onCancel?.();
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={closeModal}
      isBlocking
    >
      <Stack className={classNames.modalItemsContainer}>
        <div className={classNames.keyTitle}>
          {Messages.sshKeySources.generateNewKeyPair()}
        </div>
        <InfoBlock
          testId={InfoBlockTestIds.DownloadKey}
          message={Messages.hints.downloadKey()}
          messageType={InfoBlockStatus.INFO}
          infoLayout={InfoBlockLayout.Compact}
          customInfoStyle={{ root: { marginBottom: "15px" } }}
        />
        <Stack tokens={{ childrenGap: 10 }} className={classNames.actionButtons}>
          <PrimaryButton
            data-test-id={ButtonTestIds.DownloadSshKeyPrimary}
            styles={downloadButtonStyles}
            text={submitBtnText}
            onClick={internalOnSubmit}
          />
          <DefaultButton
            data-test-id={ButtonTestIds.DownloadSshKeyCancel}
            styles={buttonStyles}
            text={cancelBtnText}
            onClick={internalOnCancel}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
