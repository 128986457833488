import * as React from "react";
import { IButtonStyles, Icon, IconButton, Link, mergeStyleSets, Modal, PrimaryButton, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";

export interface PromoAlertModalProps {
  isPromoAlertModalOpen: boolean,
  hidePromoAlertModal: () => void,
  onUpgrade: () => void,
}

export enum LinkTestIds {
  Upgrade = "upgrade-link"
}

const classNames = mergeStyleSets({
  modalItemsContainer: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    maxWidth: "600px",
  },
  actionButtons: {
    justifyContent: "flex-start",
    flexFlow: "row-reverse",
    paddingTop: "20px",
  },
  modalHeader: {
    flex: "1 1 auto",
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
    paddingTop: "10px",
    color: "black",
  },
  modalHeaderTitle: {
    fontWeight: "600",
    fontSize: "18px",
  },
  learnMoreContainer: {
    display: "flex",
    alignItems: "center",
    color: "rgb(0, 120, 212)",
  },
  closeIcon: { marginLeft: "5px" },
  modalBody: { display: "inline-block" },
  modalBodyDesc: { paddingRight: "5px" },
});

const buttonStyles: IButtonStyles = { root: { height: 25 } };

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
    color: "black",
  },
};

export const PromoAlertModal = (
  { isPromoAlertModalOpen, hidePromoAlertModal, onUpgrade }: PromoAlertModalProps,
): JSX.Element => (
  <Modal
    isOpen={isPromoAlertModalOpen}
    onDismiss={hidePromoAlertModal}
    isBlocking={false}
  >
    <Stack className={classNames.modalItemsContainer}>
      <div className={classNames.modalHeader}>
        <span className={classNames.modalHeaderTitle}>
          {Messages.promoAlertModal.exadataDatabaseNotAvailable()}
        </span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel={Messages.common.cancel()}
          onClick={hidePromoAlertModal}
        />
      </div>
      <div className={classNames.modalBody}>
        <span className={classNames.modalBodyDesc}>
          {Messages.promoAlertModal.exadataDatabaseCannotCreated()}
        </span>
        <Link onClick={onUpgrade} data-test-id={LinkTestIds.Upgrade}>
          <div className={classNames.learnMoreContainer}>
            {Messages.actions.upgradeAccount()}
            <Icon className={classNames.closeIcon} iconName="OpenInNewWindow" />
          </div>
        </Link>
      </div>
      <Stack tokens={{ childrenGap: 10 }} className={classNames.actionButtons}>
        <PrimaryButton
          styles={buttonStyles}
          text={Messages.actions.close()}
          onClick={hidePromoAlertModal}
        />
      </Stack>
    </Stack>
  </Modal>
);
