import {
  AnchoredPanelType,
  FormattedString,
  FormInputGroupLayout,
  InputFormSidePanel,
  InputFormSidePanelProps,
  IpInput,
  TextInput,
  uniqueGUID,
} from "o4a-react";
import React from "react";
import * as Messages from "../../codegen/Messages";
import { vnetTroubleshooting } from "../../constants/docConstants";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { AzureVirtualNetworkSummary } from "../../gen/clients/mchub-azure-api-client";
import { validateIpAddressWithinRanges, validateNetworkLinkName } from "../../helpers/validationHelper";
import { ResetInput, ResetInputComponent } from "../ResetInput/ResetInput";
import { VNETSelect } from "../VNETSelect/VNETSelect";

export enum Fields {
    Name = "networkLinkName",
    NetworkVirtualAppliance = "networkVirtualAppliance",
    Vnet = "vnet",
  }

export enum FieldTestIds {
    Name = "networkLinkName",
    Vnet = "vnet",
    NetworkVirtualAppliance = "networkVirtualAppliance",
  }

export interface NetworkLinkCreateSidePanelProps extends
    Pick<InputFormSidePanelProps, "componentRef" | "onClose" | "testId"| "onSubmit"> {
    subscriptionId: string;
    location: string;
  }

export const NetworkLinkCreateSidePanel = ({
  onClose,
  onSubmit,
  location,
  subscriptionId,
  componentRef,
}: NetworkLinkCreateSidePanelProps): JSX.Element => {
  const [selectedVnet, setSelectedVnet] = React.useState<AzureVirtualNetworkSummary | undefined>(undefined);
  const [renderKey, setRenderKey] = React.useState<string>(uniqueGUID());
  const [ipInputRef, setIpInputRef] = React.useState<ResetInputComponent>();

  const onChangeVnet = (_: string, vnet: AzureVirtualNetworkSummary | undefined): void => {
    setSelectedVnet(vnet);
    setRenderKey(uniqueGUID());
    ipInputRef?.reset();
  };

  const nvaWithinVnetValidator = (value: string | undefined): string[] | undefined => {
    const errors: string[] = [];

    if (value) {
      // NVA IP address must be within the Azure Virtual Network address spaces
      errors.push(
        ...(validateIpAddressWithinRanges(
          value,
          selectedVnet?.addressSpaces || [],
        ) || []),
      );
    }

    return errors.length > 0 ? errors : undefined;
  };

  return (
    <InputFormSidePanel
      testId={SidePanelTestIds.NetworkLinkCreatePanel}
      componentRef={componentRef}
      type={AnchoredPanelType.MEDIUM}
      layout={FormInputGroupLayout.WIDE}
      title={Messages.createNewPanels.virtualNetwork.title()}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <TextInput
        required
        testId={FieldTestIds.Name}
        fieldName={Fields.Name}
        label={Messages.labels.name()}
        validator={validateNetworkLinkName}
      />
      <VNETSelect
        required
        testId={FieldTestIds.Vnet}
        fieldName={Fields.Vnet}
        label={Messages.labels.virtualNetwork()}
        tooltip={
            FormattedString(
              { inputText: Messages.hints.tooltipVNET(vnetTroubleshooting) },
            ) as unknown as string
          }
        subscriptionId={subscriptionId}
        location={location}
        onChange={onChangeVnet}
      />
      <ResetInput componentRef={setIpInputRef}>
        <IpInput
          testId={FieldTestIds.NetworkVirtualAppliance}
          key={`${renderKey}-nva`}
          disabled={!selectedVnet}
          validator={nvaWithinVnetValidator}
          fieldName={Fields.NetworkVirtualAppliance}
          label={Messages.labels.networkVirtualAppliance()}
          tooltip={Messages.hints.tooltipNetworkVirtualAppliance()}
          validation={{
            containsIpAddress: true,
            containsCidrBlock: false,
          }}
        />
      </ResetInput>
    </InputFormSidePanel>
  );
};
