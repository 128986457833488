export enum Permission {
  READ = "READ",
  READ_WRITE = "READ_WRITE",
}

export enum RoleBasedAction {
  // Create actions
  CREATE_SUPPORT = "CREATE_SUPPORT",
  CREATE_ADBS = "CREATE_ADBS",
  CREATE_EXA_INFRA = "CREATE_EXA_INFRA",
  CREATE_EXA_VMCLUSTER = "CREATE_EXA_VMCLUSTER",
  CREATE_EXA_SYSTEM = "CREATE_EXA_SYSTEM",
  CREATE_EXA_CDB = "CREATE_EXA_CDB",
  CREATE_EXA_PDB = "CREATE_EXA_PDB",
  CREATE_VMDB_CDB = "CREATE_VMDB_CDB",
  CREATE_VMDB_PDB = "CREATE_VMDB_PDB",
  CREATE_MYSQL_DB = "CREATE_MYSQL_DB",
  CREATE_MCVCN = "CREATE_MCVCN",
  // List actions
  LIST_SUPPORT = "LIST_SUPPORT",
  LIST_ADBS = "LIST_ADBS",
  LIST_EXA_INFRA = "LIST_EXA_INFRA",
  LIST_EXA_VMCLUSTER = "LIST_EXA_VMCLUSTER",
  LIST_EXA_DB = "LIST_EXA_DB",
  LIST_VMDB = "LIST_VMDB",
  LIST_VMDB_SYSTEM = "LIST_VMDB_SYSTEM",
  LIST_MYSQL_DB = "LIST_MYSQL_DB",
  LIST_MCVCN = "LIST_MCVCN",
}

export const ActionPermissionMap: {[key in RoleBasedAction]: Permission } = {
  // *** Create actions ***
  [RoleBasedAction.CREATE_SUPPORT]: Permission.READ_WRITE,
  [RoleBasedAction.CREATE_ADBS]: Permission.READ_WRITE,
  [RoleBasedAction.CREATE_EXA_INFRA]: Permission.READ_WRITE,
  [RoleBasedAction.CREATE_EXA_VMCLUSTER]: Permission.READ_WRITE,
  [RoleBasedAction.CREATE_EXA_SYSTEM]: Permission.READ_WRITE,
  [RoleBasedAction.CREATE_EXA_CDB]: Permission.READ_WRITE,
  [RoleBasedAction.CREATE_EXA_PDB]: Permission.READ_WRITE,
  [RoleBasedAction.CREATE_VMDB_CDB]: Permission.READ_WRITE,
  [RoleBasedAction.CREATE_VMDB_PDB]: Permission.READ_WRITE,
  [RoleBasedAction.CREATE_MYSQL_DB]: Permission.READ_WRITE,
  [RoleBasedAction.CREATE_MCVCN]: Permission.READ_WRITE,
  // *** List actions ***
  [RoleBasedAction.LIST_SUPPORT]: Permission.READ,
  [RoleBasedAction.LIST_MCVCN]: Permission.READ,
  [RoleBasedAction.LIST_ADBS]: Permission.READ,
  [RoleBasedAction.LIST_EXA_INFRA]: Permission.READ,
  [RoleBasedAction.LIST_EXA_VMCLUSTER]: Permission.READ,
  [RoleBasedAction.LIST_EXA_DB]: Permission.READ,
  [RoleBasedAction.LIST_VMDB]: Permission.READ,
  [RoleBasedAction.LIST_VMDB_SYSTEM]: Permission.READ,
  [RoleBasedAction.LIST_MYSQL_DB]: Permission.READ,
};

export enum RoleScope {
  SINGLE_RESOURCE_TYPE = "SINGLE_RESOURCE_TYPE",
  MULTI_RESOURCE_TYPES = "MULTI_RESOURCE_TYPES",
  LEGACY = "LEGACY",
}

export interface Role {
  name: string;
  displayName: string;
  scope: RoleScope;
  permission: Permission;
}

// *** Legacy roles ***
const ExaDbAdmin: Role = {
  name: "exadb-administrator",
  displayName: "Exa DB Administrator",
  scope: RoleScope.LEGACY,
  permission: Permission.READ_WRITE,
};
// *** Cloud link roles ***
// const CloudLinkAdmin: Role = {
//   name: "cloudlink-administrator",
//   displayName: "ODSA Multicloudlink Administrator",
//   scope: RoleScope.SINGLE_RESOURCE_TYPE,
//   permission: Permission.READ_WRITE,
// };
// const CloudLinkUser: Role = {
//   name: "cloudlink-user",
//   displayName: "ODSA Multicloudlink User",
//   scope: RoleScope.SINGLE_RESOURCE_TYPE,
//   permission: Permission.READ,
// };
// *** ADB roles ***
const AdbsDbAdmin: Role = {
  name: "odsa-adbs-db-administrator",
  displayName: "ODSA ADB-S DB Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
// *** Exadata roles ***
const ExaInfraAdmin: Role = {
  name: "odsa-exa-infra-administrator",
  displayName: "ODSA Exa Infrastructure Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
const ExaCdbAdmin: Role = {
  name: "odsa-exa-cdb-administrator",
  displayName: "ODSA Exa Database Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
const ExaPdbAdmin: Role = {
  name: "odsa-exa-pdb-administrator",
  displayName: "ODSA Exa PDB Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
// *** Base DB roles ***
const VmdbInfraAdmin: Role = {
  name: "odsa-basedb-infra-administrator",
  displayName: "ODSA BaseDB Infrastructure Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
const VmdbCdbAdmin: Role = {
  name: "odsa-basedb-cdb-administrator",
  displayName: "ODSA BaseDB Database Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
const VmdbPdbAdmin: Role = {
  name: "odsa-basedb-pdb-administrator",
  displayName: "ODSA BaseDB PDB Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
// *** MySQL roles ***
const MysqlInfraAdmin: Role = {
  name: "odsa-mysql-infra-administrator",
  displayName: "ODSA MySQL Instances Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
const MysqlHeatwaveAdmin: Role = {
  name: "odsa-mysql-heatwave-administrator",
  displayName: "ODSA MySQL HeatWave Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
// *** DB family roles ***
const DbFamilyAdmin: Role = {
  name: "odsa-db-family-administrator",
  displayName: "ODSA Database Family Administrator",
  scope: RoleScope.MULTI_RESOURCE_TYPES,
  permission: Permission.READ_WRITE,
};
const DbFamilyReader: Role = {
  name: "odsa-db-family-reader",
  displayName: "ODSA Database Family Reader",
  scope: RoleScope.MULTI_RESOURCE_TYPES,
  permission: Permission.READ,
};
// *** Network roles ***
const NetLinkAdmin: Role = {
  name: "networklink-administrator",
  displayName: "Network Link Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
// const NetLinkUser: Role = {
//   name: "networklink-user",
//   displayName: "Network Link User",
//   scope: RoleScope.SINGLE_RESOURCE_TYPE,
//   permission: Permission.READ,
// };
// const NetworkAdmin: Role = {
//   name: "odsa-network-administrator",
//   displayName: "ODSA Network Link Administrator",
//   scope: RoleScope.SINGLE_RESOURCE_TYPE,
//   permission: Permission.READ_WRITE,
// };
// *** Support roles ***
const SupportAdmin: Role = {
  name: "odsa-support-administrator",
  displayName: "ODSA Support Administrator",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ_WRITE,
};
const SupportReader: Role = {
  name: "odsa-support-reader",
  displayName: "ODSA Support Reader",
  scope: RoleScope.SINGLE_RESOURCE_TYPE,
  permission: Permission.READ,
};
// *** Billing roles ***
// const BillingAdmin: Role = {
//   name: "odsa-costmgmt-administrator",
//   displayName: "ODSA Cost Management Administrator",
//   scope: RoleScope.SINGLE_RESOURCE_TYPE,
//   permission: Permission.READ_WRITE,
// };
// const BillingReader: Role = {
//   name: "odsa-costmgmt-read",
//   displayName: "ODSA Cost Management Reader",
//   scope: RoleScope.SINGLE_RESOURCE_TYPE,
//   permission: Permission.READ,
// };

export type AllowedRoles = Role[]; // Any of the roles is valid
export type ActionRoles = AllowedRoles[]; // All entries should have at least one valid role
export type ActionRolesMapType = { [key in RoleBasedAction]: ActionRoles | keyof typeof RoleBasedAction }

export const ActionRolesMap: ActionRolesMapType = {
  // *** Create actions ***
  [RoleBasedAction.CREATE_SUPPORT]: [
    [SupportAdmin],
  ],
  [RoleBasedAction.CREATE_ADBS]: [
    [ExaDbAdmin, DbFamilyAdmin, AdbsDbAdmin],
  ],
  [RoleBasedAction.CREATE_EXA_INFRA]: [
    [ExaDbAdmin, DbFamilyAdmin, ExaInfraAdmin],
  ],
  [RoleBasedAction.CREATE_EXA_VMCLUSTER]: RoleBasedAction.CREATE_EXA_INFRA,
  [RoleBasedAction.CREATE_EXA_SYSTEM]: RoleBasedAction.CREATE_EXA_INFRA,
  [RoleBasedAction.CREATE_EXA_CDB]: [
    [ExaDbAdmin, DbFamilyAdmin, ExaCdbAdmin, ExaInfraAdmin],
  ],
  [RoleBasedAction.CREATE_EXA_PDB]: [
    [ExaDbAdmin, DbFamilyAdmin, ExaPdbAdmin],
  ],
  [RoleBasedAction.CREATE_VMDB_CDB]: [
    [ExaDbAdmin, DbFamilyAdmin, VmdbInfraAdmin],
  ],
  [RoleBasedAction.CREATE_VMDB_PDB]: [
    [ExaDbAdmin, DbFamilyAdmin, VmdbPdbAdmin],
  ],
  [RoleBasedAction.CREATE_MYSQL_DB]: [
    [DbFamilyAdmin, MysqlInfraAdmin, MysqlHeatwaveAdmin],
  ],
  [RoleBasedAction.CREATE_MCVCN]: [
    [NetLinkAdmin],
  ],
  // *** List actions ***
  [RoleBasedAction.LIST_SUPPORT]: [
    [SupportReader, SupportAdmin],
  ],
  [RoleBasedAction.LIST_MCVCN]: [
    [NetLinkAdmin],
  ],
  [RoleBasedAction.LIST_ADBS]: [
    [DbFamilyReader, ExaDbAdmin, DbFamilyAdmin, AdbsDbAdmin],
  ],
  [RoleBasedAction.LIST_EXA_INFRA]: [
    [DbFamilyReader, ExaDbAdmin, DbFamilyAdmin, ExaInfraAdmin],
  ],
  [RoleBasedAction.LIST_EXA_VMCLUSTER]: RoleBasedAction.LIST_EXA_INFRA,
  [RoleBasedAction.LIST_EXA_DB]: [
    [DbFamilyReader, ExaDbAdmin, DbFamilyAdmin, ExaInfraAdmin, ExaCdbAdmin, ExaPdbAdmin],
  ],
  [RoleBasedAction.LIST_VMDB]: [
    [DbFamilyReader, ExaDbAdmin, DbFamilyAdmin, VmdbInfraAdmin, VmdbCdbAdmin, VmdbPdbAdmin],
  ],
  [RoleBasedAction.LIST_VMDB_SYSTEM]: [
    [DbFamilyReader, ExaDbAdmin, DbFamilyAdmin, VmdbInfraAdmin, VmdbCdbAdmin, VmdbPdbAdmin],
  ],
  [RoleBasedAction.LIST_MYSQL_DB]: [
    [DbFamilyReader, DbFamilyAdmin, MysqlInfraAdmin],
  ],
};
