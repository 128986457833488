import {
  FormContext,
  FormState,
  GroupOption,
  optimizedRetryOption,
  Select,
  SelectOption,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import {
  MultiCloudVirtualNetworkCollection,
  MultiCloudVirtualNetworkSummary,
} from "../../gen/clients/mchub-azure-api-client";
import { IdResourceType, parseId } from "../../helpers/idHelper";
import { ResourceStatus, responseItemstoArray } from "../../helpers/resourceHelper";
import { groupBy } from "../../helpers/utilHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

type BaseSelectProps = Omit<SelectProps, "options" | "label"> & Required<Pick<SelectProps, "label">>;

export interface McvcnSelectProps extends BaseSelectProps {
  location: string;
  subscriptionId: string;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const McvcnSelect = ({
  location,
  subscriptionId,
  onError,
  onMissingDependencies,
  groupName,
  fieldName,
  label,
  placeholder,
  ...selectProps
}: McvcnSelectProps): JSX.Element => {
  const [renderKey, setRenderKey] = React.useState(uniqueGUID());

  const form: FormState = React.useContext(FormContext);

  const { loading, response, error } = useQueryCall({
    wait: !subscriptionId || !location,
    method: apiClients.withRegion(getOciRegion(location)).networkApi.listMultiCloudVirtualNetworksInSubscription,
    options: {
      args: { subscriptionId },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadMcvcns(),
      },
    },
  });

  const mcvcns = (response
    && responseItemstoArray<MultiCloudVirtualNetworkCollection, MultiCloudVirtualNetworkSummary>(response)) || [];

  const validMcvcns = mcvcns.filter(mcvcn => {
    // eslint-disable-next-line eqeqeq
    if (mcvcn.isUsable == undefined) {
      // Support old api that does not have this new isUsable attribute
      return (mcvcn.status !== ResourceStatus.Failed && mcvcn.status !== ResourceStatus.Terminating);
    }
    return mcvcn.isUsable;
  });

  const groups = groupBy(validMcvcns, v => parseId(v.id).resourceGroup);
  const vcnOptions: GroupOption[] = [];
  Object.keys(groups).forEach(groupKey => {
    const list = groups[groupKey]?.map(mcvcn => ({
      id: mcvcn.id,
      text: mcvcn.name,
      data: mcvcn,
    } as SelectOption));
    vcnOptions.push({
      heading: groupKey,
      options: list,
    });
  });

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setRenderKey(uniqueGUID());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location]);

  React.useEffect(() => {
    setRenderKey(uniqueGUID());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(vcnOptions)]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    if (!location) missingDependencies.push(IdResourceType.LOCATION);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const derivedPlaceholder = !loading && !error && vcnOptions.length === 0
    ? Messages.createCommon.loadingNone(label?.toLowerCase() || "")
    : placeholder;

  return (
    <Select
      key={renderKey}
      fieldName={fieldName}
      groupName={groupName}
      loading={!!subscriptionId && loading}
      label={label}
      placeholder={derivedPlaceholder}
      options={vcnOptions}
      onClick={internalOnClick}
      {...selectProps}
    />
  );
};
