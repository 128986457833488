import { FormValues, getValue, InputFormGroup, SelectOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  Fields as LinkSubscriptionFields,
  LinkSubscriptionPanel,
} from "../../components/LinkSubscriptionPanel/LinkSubscriptionPanel";
import { AzureCloudLinkSummary, AzureSubscriptionSummary } from "../../gen/clients/mchub-azure-api-client";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  CLOUDLINK_UPDATE_POLL_DELAY,
  CLOUDLINK_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface CloudLinkSubscriptionLinkProps extends Omit<OperationActionProps, "location"> {
  subscription: AzureSubscriptionSummary;
  activeCloudLinks: AzureCloudLinkSummary[];
}

interface LinkSubscriptionSubmitProps {
  cloudLinkId: string;
  location: string;
  resourceName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitPayload: any;
  onPostSubmit: () => void;
}

const LinkSubscriptionSubmit = ({
  cloudLinkId,
  location,
  resourceName,
  submitPayload,
  onPostSubmit,
}: LinkSubscriptionSubmitProps): JSX.Element => {
  const { invokeCall: invokeLinkSubscription } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).cloudLinkApi.updateAzureCloudLink },
  );

  React.useEffect(() => {
    const invokeOptions = {
      onSuccess: onPostSubmit,
      onFailure: onPostSubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.linkSubscription(),
          message: Messages.notifications.inProgress.messages.linkSubscription(resourceName || ""),
        },
        success: {
          title: Messages.notifications.success.titles.linkSubscription(),
          message: Messages.notifications.success.messages.linkSubscription(resourceName || ""),
        },
        failure: {
          title: Messages.notifications.failure.titles.linkSubscription(),
          message: Messages.notifications.failure.messages.linkSubscription(resourceName || ""),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.CLOUDLINK_GET,
          methodArgs: { cloudLinkId },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.CLOUDLINK_UPDATED_CHECK,
          delay: CLOUDLINK_UPDATE_POLL_DELAY,
          interval: CLOUDLINK_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeLinkSubscription(submitPayload, invokeOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

const CloudLinkSubscriptionLink = (
  { targetId, subscription, activeCloudLinks, onExecute, onCancel }: CloudLinkSubscriptionLinkProps,
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [submitLinkSubscriptionPayload, setSubmitLinkSubscriptionPayload] = React.useState<any>();
  const [linkSubscriptionCloudLinkId, setLinkSubscriptionCloudLinkId] = React.useState<string>();
  const [linkSubscriptionPrimaryLocation, setLinkSubscriptionPrimaryLocation] = React.useState<string>();
  const [linkSubscriptionName, setLinkSubscriptionName] = React.useState<string>();

  const onLinkSubscription = (formValues: FormValues): void => {
    setLinkSubscriptionName(subscription.name);
    const cloudLinkId = activeCloudLinks?.length === 1
      ? activeCloudLinks[0].id
      : getValue<SelectOption[]>(formValues, LinkSubscriptionFields.CloudLink, InputFormGroup)?.[0].id;
    const selectedCloudLink = activeCloudLinks?.find(link => link.id === cloudLinkId);
    setLinkSubscriptionCloudLinkId(cloudLinkId);

    if (selectedCloudLink?.primaryLocation) {
      setLinkSubscriptionPrimaryLocation(selectedCloudLink?.primaryLocation);
    } else if (selectedCloudLink?.locations && selectedCloudLink?.locations[0]) {
      setLinkSubscriptionPrimaryLocation(selectedCloudLink?.locations[0]);
    }
    const newSubscriptionIds: string[] = selectedCloudLink?.subscriptionIds
      ? [...selectedCloudLink?.subscriptionIds as string[]] : [];
    newSubscriptionIds.push(subscription.id);

    setSubmitLinkSubscriptionPayload({
      cloudLinkId,
      updateAzureCloudLinkDetails: { subscriptionIds: newSubscriptionIds },
    });
  };

  const postSubmitLinkSubscription = (): void => {
    setSubmitLinkSubscriptionPayload(undefined);
    setLinkSubscriptionName(undefined);
    setLinkSubscriptionPrimaryLocation(undefined);
    setLinkSubscriptionCloudLinkId(undefined);
    onExecute?.();
  };
  return (
    <>
      <LinkSubscriptionPanel
        subscription={subscription}
        activeCloudLinks={activeCloudLinks}
        targetId={targetId}
        onSubmit={onLinkSubscription}
        onClose={onCancel}
      />
      {submitLinkSubscriptionPayload && (
        <LinkSubscriptionSubmit
          cloudLinkId={linkSubscriptionCloudLinkId || ""}
          location={linkSubscriptionPrimaryLocation || ""}
          resourceName={linkSubscriptionName || ""}
          submitPayload={submitLinkSubscriptionPayload}
          onPostSubmit={postSubmitLinkSubscription}
        />
      )}
    </>

  );
};

export const newCloudLinkSubscriptionLink = (
  props: CloudLinkSubscriptionLinkProps,
): JSX.Element => (<CloudLinkSubscriptionLink {...props} />);
