import { FormValues, getValue, InputFormGroup } from "o4a-react";
import React from "react";
import * as Messages from "../../codegen/Messages";
import { Fields as TagDetailsFields, TagsEditPanel } from "../../components/TagsEditPanel/TagsEditPanel";
import { TagsInfoType } from "../../helpers/resourceHelper";
import { useOperation } from "../../hooks/useOperation";
import { OperationProps } from "../OperationTypes";
import { ExaInfraUpdateTagsProps, newExaInfraUpdateTags } from "./ExaInfraUpdateTags";

export interface ExaInfraEditTagsProps extends OperationProps {
  exaInfraId: string;
  defaultTags?: TagsInfoType;
}

const ExaInfraEditTags = ({
  defaultTags,
  exaInfraId,
  location,
  onCancel,
  onExecute,
}: ExaInfraEditTagsProps): JSX.Element => {
  const { trigger: triggerExaInfraUpdateTags } = useOperation<ExaInfraUpdateTagsProps>(newExaInfraUpdateTags);

  return (
    <TagsEditPanel
      title={Messages.labels.editTags()}
      resourceId={exaInfraId}
      tagsDefaultValue={defaultTags}
      onSubmit={(formValues: FormValues) => {
        const tags = getValue<TagsInfoType>(formValues, TagDetailsFields.Tags, InputFormGroup) ?? [];

        triggerExaInfraUpdateTags({
          exaInfraId,
          location,
          tags,
          onExecute,
        });
      }}
      onClose={onCancel}
    />
  );
};

export const newExaInfraEditTags = (props: ExaInfraEditTagsProps): JSX.Element => (
  <ExaInfraEditTags {...props} />
);
