import { FieldSet, FormContext, FormState, FormValues, TextInput, TextInputTypes, uniqueGUID } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import {
  buildMdsIllegalCharsString,
  buildMdsReservedNamesString,
  mdsAdminUserNameMaxLength,
  mdsAdminUserNameMinLength,
  validateMdsPassword,
  validateMdsUserName,
} from "../../helpers/validationHelper";

export const GROUP_SECURITY = "security";

export enum Fields {
  Username = "mdsDbSystemDeploymentDetails.adminUsername",
  Password = "mdsDbSystemDeploymentDetails.adminPassword",
  PasswordConfirm = "passwordConfirm",
}

export enum FieldTestIds {
  Username = "adminUsername",
  Password = "adminPassword",
  PasswordConfirm = "passwordConfirm",
}

export const SecurityTabContent = (): JSX.Element => {
  const [passwordVal, setPasswordVal] = React.useState<string>();
  const form: FormState = React.useContext(FormContext);
  const [confirmPassWordKey, setConfirmPasswordKey] = React.useState<string>(uniqueGUID());

  const onChangePassword = (value: string | undefined): void => {
    setPasswordVal(value);
    const formValues: FormValues = form.getGroupValues(GROUP_SECURITY);
    if (formValues[Fields.PasswordConfirm] !== undefined) {
      form.setValue(undefined, Fields.PasswordConfirm, GROUP_SECURITY);
      setConfirmPasswordKey(uniqueGUID());
    }
  };

  return (
    <FieldSet header={Messages.createMysql.securityTab.sectionTitles.administratorCredentials()}>
      <TextInput
        required
        fieldName={Fields.Username}
        testId={FieldTestIds.Username}
        label={Messages.labels.username()}
        tooltip={Messages.hints.toolTipMySQLUserName(
          mdsAdminUserNameMinLength.toString(),
          mdsAdminUserNameMaxLength.toString(),
          buildMdsIllegalCharsString(),
          buildMdsReservedNamesString(),
        )}
        validator={validateMdsUserName}
      />

      <TextInput
        required
        fieldName={Fields.Password}
        testId={FieldTestIds.Password}
        label={Messages.labels.password()}
        tooltip={Messages.hints.tooltipMySQLAdminPassword()}
        textType={TextInputTypes.PASSWORD}
        onChange={onChangePassword}
        validator={validateMdsPassword}
      />

      <TextInput
        required
        key={confirmPassWordKey}
        fieldName={Fields.PasswordConfirm}
        testId={FieldTestIds.PasswordConfirm}
        label={Messages.labels.passwordConfirm()}
        textType={TextInputTypes.PASSWORD}
        validator={(value: string | undefined) => {
          if (value && (value !== passwordVal)) {
            return [Messages.validation.passwordConfirmation()];
          }
          return undefined;
        }}
      />
    </FieldSet>
  );
};
