import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import { DetailsPanelId, PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { ResourceLinkTestIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { AnalyticsDataPage, LinkAnalyticsData } from "../../hooks/useAnalytics";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";
import { Resource, ResourceLink } from "./ResourceLink";

export interface ExaCdbLinkProps {
  databaseId: string | undefined;
  location: string;
  analytics: AnalyticsDataPage;
}

const registrationIds = PageRegistrationConfig[PageId.EXADB_CDB_DETAILS].map(config => config.key);

export const ExaCdbLink = ({
  databaseId,
  location,
  analytics,
}: ExaCdbLinkProps): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const { response, error, loading } = useQueryCall({
    wait: !databaseId,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.getDatabase,
    options: {
      args: {
        subscriptionId,
        resourceGroupName: resourceGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        databaseName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });

  const cdb = response?.data;

  return (
    <ResourceLink
      resource={cdb as Resource}
      navigation={{
        navigationKey: registrationIds[0],
        panelPath: PageRegistrationConfig[PageId.EXADB_CDB_DETAILS][0].panelPath,
      }}
      analytics={{
        target: { pageId: PageId.EXADB_CDB_DETAILS, panelId: DetailsPanelId.OVERVIEW },
        source: { pageId: analytics.pageId, panelId: analytics.panelId },
      } as LinkAnalyticsData}
      error={error}
      loading={loading}
      testId={ResourceLinkTestIds.ExaCdbLink}
    />
  );
};
