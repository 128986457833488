import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  optimizedRetryOption,
  Select,
  SelectOption,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import {
  MdsVersion,
  MdsVersionSummary,
  MdsVersionSummaryCollection,
} from "../../gen/clients/mchub-azure-api-client-mds";
import { IdResourceType } from "../../helpers/idHelper";
import { responseItemstoArray } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

export interface MySQLVersionSelectProps extends Omit<SelectProps, "options" | "defaultValue"> {
  defaultValue?: string;
  subscriptionId: string;
  location: string;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const MySQLVersionSelect = (
  {
    defaultValue,
    fieldName,
    label,
    placeholder,
    required,
    subField,
    tooltip,
    validator,
    subscriptionId,
    location,
    testId,
    onChange,
    onError,
    onMissingDependencies,
    ...rest
  }: MySQLVersionSelectProps,
): JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());

  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const form: FormState = React.useContext(FormContext);

  const { loading, response, error } = useQueryCall({
    wait: !(subscriptionId && location),
    method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.listMdsVersions,
    options: {
      args: { subscriptionId },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadMySQLVersion(),
      },
    },
  });

  const isValidVersion = (value: MdsVersion): boolean => {
    if (!defaultValue || (value.version && value.version >= defaultValue)) {
      return true;
    }
    return false;
  };

  const getSummaryOptions = (): SelectOption[] => {
    const items = (response && responseItemstoArray<MdsVersionSummaryCollection, MdsVersionSummary>(response)) || [];
    const options: SelectOption[] = [];

    items.forEach(item => {
      item.items?.filter(isValidVersion).forEach(versionItem => {
        options.push({
          id: versionItem.version || "",
          text: versionItem.description || "",
        });
      });
    });

    return options.sort(({ id: a }, { id: b }) => (a === b ? 0
      : (a < b ? -1 : 1)));
  };

  const options = (response && getSummaryOptions()) || [];

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location]);

  React.useEffect(() => {
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options)]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    if (!location) missingDependencies.push(IdResourceType.LOCATION);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const derivedPlaceholder = !loading && !error && !options.length
    ? Messages.createCommon.loadingNone(label?.toLowerCase() || "")
    : placeholder;

  return (
    <Select
      key={key}
      testId={testId}
      loading={!!subscriptionId && !!location && loading}
      required={required}
      subField={subField}
      fieldName={fieldName}
      label={label}
      options={options}
      placeholder={derivedPlaceholder}
      defaultValue={!loading && defaultValue ? [defaultValue] : undefined}
      tooltip={tooltip}
      onClick={internalOnClick}
      onChange={onChange}
      validator={validator}
      {...rest}
    />
  );
};
