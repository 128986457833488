// import { useNavigation } from "o4a-react";
import * as React from "react";
import { SettingsPanel } from "../../components/SettingsPanel/SettingsPanel";
// import { ConsoleContext } from "../../console/ConsoleContext";
// import { PageId, PageRegistrationConfig, SETTINGS_ROUTE } from "../../constants/pluginConstants";
import { OperationConfirmationProps } from "../OperationTypes";

// const { panelPath, key } = PageRegistrationConfig[PageId.SETTINGS][0];

export type OpenSettingsProps = Omit<OperationConfirmationProps, "location">;

/*
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OpenSettings = (_: OpenSettingsProps): JSX.Element => {
  const { navigateTo } = useNavigation(ConsoleContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => navigateTo(`${SETTINGS_ROUTE}/${panelPath}`, key), []);

  return <div />;
};
*/

const OpenSettings = ({ onCancel }: OpenSettingsProps): JSX.Element => <SettingsPanel onClose={onCancel} />;

export const newOpenSettings = (
  props: OpenSettingsProps,
): JSX.Element => (<OpenSettings {...props} />);
