import {
  ActionType,
  ConfirmAction,
  DateTimeDisplay,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  AdbsDatabase,
  AdbsDatabaseBackupCollection,
  AdbsDatabaseBackupSummary,
} from "../../gen/clients/mchub-azure-api-client-adb";
import {
  getBackupTypeStr,
  getStatusInfo,
  ResourceStatus as BackupStatus,
  responseItemstoArray,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { AdbsRestoreFromBackupProps, newAdbsRestoreFromBackup } from "../../operations/Adbs/AdbsRestoreFromBackup";
import { getOciRegion } from "../../utils";

/**
 * TODO
 * This interface is implement to include the last status and details.
 * Api has not expose these two fields for now. When it will, we might remove this interface.
 */
interface AdbsDatabaseBackupSummaryExt extends AdbsDatabaseBackupSummary {
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
}
export interface AdbsBackupsListProps {
  database: AdbsDatabase | undefined;
  resGroup: string;
  subscriptionId: string;
  location: string;
}

export enum ActionBarIds {
  Refresh = "action-bar-refresh",
  Restore = "action-bar-restore",
}

export enum ActionMenuIds {
  Restore = "action-menu-restore"
}

export enum ActionBarTestIds {
  Refresh = "action-bar-refresh",
  Restore = "action-bar-restore",
}

export enum ActionMenuTestIds {
  Restore = "action-menu-restore"
}

export enum ColumnIds {
  Name = "name",
  ResourceStatus = "status",
  Type = "type",
  Started = "timeStarted",
  Ended = "timeEnded",
}

export enum ColumnTestIds {
  Name = "name",
  ResourceStatus = "status",
  Type = "type",
  Started = "timeStarted",
  Ended = "timeEnded",
}

export const AdbsBackupsList = (
  { database, resGroup, subscriptionId, location }: AdbsBackupsListProps,
): JSX.Element => {
  const { locale } = React.useContext<Settings>(SettingsContext);
  const [selection, setSelection] = React.useState<AdbsDatabaseBackupSummary[]>([]);

  const { trackActionClick, trackActionDiscard } = useAnalytics();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectedItems = (selectedItems: any[]): void => {
    setSelection(selectedItems);
  };
  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<AdbsDatabaseBackupSummary>>();

  const { subListingPageItemCount } = useRowCount();

  const { response: listBackupsResponse, loading: listBackupsLoading, refresh } = useQueryCall({
    wait: !(subscriptionId && resGroup && location && database?.id),
    method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.listAdbsDatabaseBackupsByDatabase,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        databaseName: database?.name || "",
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadBackups(),
      },
    },
  });

  const backupItems = listBackupsResponse
    && responseItemstoArray<AdbsDatabaseBackupCollection, AdbsDatabaseBackupSummary>(listBackupsResponse);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = React.useMemo(() => backupItems, [JSON.stringify(backupItems)]);

  const getDateTime = (value: Date | undefined): JSX.Element => <DateTimeDisplay date={value} hideClipboardCopy />;

  const getBackupType = (value: AdbsDatabaseBackupSummary): string => getBackupTypeStr(value?.type);

  const getStatus = (value: AdbsDatabaseBackupSummaryExt): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
        )
      }
      hideClipboardCopy
    />
  );

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: AdbsDatabaseBackupSummaryExt,
        b: AdbsDatabaseBackupSummaryExt,
      ) => statusSortComparator<AdbsDatabaseBackupSummaryExt>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.Type,
      testId: ColumnTestIds.Type,
      name: Messages.labels.type(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getBackupType(value),
    },
    {
      itemProp: ColumnIds.Started,
      testId: ColumnTestIds.Started,
      name: Messages.labels.started(),
      flexGrow: 2,
      initialSortDirection: SortDirections.DESC,
      isResizable: true,
      onRenderItems: value => getDateTime(value.timeStarted),
    },
    {
      itemProp: ColumnIds.Ended,
      testId: ColumnTestIds.Ended,
      name: Messages.labels.ended(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getDateTime(value.timeEnded),
    },
  ];

  const { trigger: triggerRestoreFromBackup } = useOperation<AdbsRestoreFromBackupProps>(newAdbsRestoreFromBackup);

  const { closePanels } = useSidePanel();

  const buildRestoreAction = (key:string, testId:string, selectedItem: AdbsDatabaseBackupSummary)
  :ConfirmAction<AdbsDatabaseBackupSummary | undefined> => {
    const restoreAction = {
      key,
      testId,
      disabled: !selectedItem || selectedItem?.status !== BackupStatus.Active,
      text: Messages.actions.restore(),
      title: Messages.labels.restore(),
      icon: MonochromeIconIds.Database,
      description: Messages.hints.restoreBackupConfirmation(selectedItem?.name || ""),
      onClick: () => {
        trackActionClick(key, PageId.ADBS_DETAILS, DetailsPanelId.BACKUPS);
      },
      onConfirm: () => {
        triggerRestoreFromBackup({
          databaseId: database?.id,
          backupName: selectedItem?.name,
          timeEnded: selectedItem?.timeEnded,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, PageId.ADBS_DETAILS, DetailsPanelId.BACKUPS),
    };
    return restoreAction;
  };

  const backupRowActionMenus = (item?:AdbsDatabaseBackupSummary | undefined):
  ActionType<AdbsDatabaseBackupSummary>[] => {
    const backupActionItems: ActionType<AdbsDatabaseBackupSummary | undefined>[] = [];
    if (item) {
      backupActionItems.push(buildRestoreAction(ActionMenuIds.Restore, ActionMenuTestIds.Restore, item));
    }
    return backupActionItems;
  };

  const backupBarActions: ActionType<AdbsDatabaseBackupSummary>[] = [
    buildRestoreAction(ActionBarIds.Restore, ActionBarTestIds.Restore, selection[0]),
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, PageId.ADBS_DETAILS, DetailsPanelId.BACKUPS);
        listingComponentRef?.resetTableSelection();
        refresh();
      },
    },
  ];

  return (
    <Listing
      listingComponentRef={setListingComponentRef}
      testId={ListingTestIds.AdbsBackups}
      itemsPerPage={subListingPageItemCount}
      items={items || []}
      emptyList={{ title: Messages.common.noResults() }}
      listColumns={columns}
      onActionClick={closePanels}
      actionBarItems={backupBarActions as ActionType<undefined>[]}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      isLoading={listBackupsLoading}
      actions={backupRowActionMenus}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Started,
      }}
    />
  );
};
