import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_DB_SYSTEM_DELETE_POLL_DELAY,
  VMDB_DB_SYSTEM_DELETE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface VmDbSystemDeleteProps extends OperationConfirmationProps {
  dbSystemId: string | undefined;
}

const VmDbSystemDelete = (
  { location, dbSystemId, onExecute }: VmDbSystemDeleteProps,
): JSX.Element => {
  const { invokeCall: deleteSystem } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.deleteVmdbDbSystem },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(dbSystemId);
    const resGroup = decodeURIComponent(resourceGroup || "");
    const resName = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.deleteVMDbSystem(),
          message: Messages.notifications.inProgress.messages.deleteVMDbSystem(resName),
        },
        success: {
          title: Messages.notifications.success.titles.deleteVMDbSystem(),
          message: Messages.notifications.success.messages.deleteVMDbSystem(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.delete(),
          message: Messages.notifications.failure.messages.deleteVMDbSystem(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_DB_SYSTEM_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            dbSystemName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_DB_SYSTEM_DELETED_CHECK,
          errorStatusOverrides: [404],
          delay: VMDB_DB_SYSTEM_DELETE_POLL_DELAY,
          interval: VMDB_DB_SYSTEM_DELETE_POLL_INTERVAL,

        },
      },
    };

    deleteSystem({
      subscriptionId,
      resourceGroupName: resGroup,
      dbSystemName: resName,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newVmDbSystemDelete = (
  props: VmDbSystemDeleteProps,
): JSX.Element => (<VmDbSystemDelete {...props} />);
