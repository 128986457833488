import {
  ActionBar,
  ActionType,
  AnchoredPanelComponent,
  BookmarkablePage,
  DateTimeDisplay,
  DetailView,
  DetailViewsPanel,
  ErrorViewPanel,
  FormValues,
  getResourceLifecycleStatus,
  getValue,
  GroupItem,
  InfoBlockProps,
  InfoBlockStatus,
  InputFormGroup,
  LabelMetaItem,
  MetaItemSection,
  NoValue,
  optimizedRetryOption,
  stateT,
  Status,
  TitleDetails,
  uniqueGUID,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Spinner, Stack } from "@fluentui/react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { OciMdsLink } from "../../components/OciLinks/OciMdsLink";
import { Fields as TagDetailsFields, TagDetails, TagDetailsComponent } from "../../components/TagDetails/TagDetails";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  DetailsPanelId,
  InlineFormId,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds, SvgIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { MdsCrashRecoveryStatusValues, MdsDbSystem } from "../../gen/clients/mchub-azure-api-client-mds";
import { buildId } from "../../helpers/idHelper";
import { getStatusInfo, getTitleSuffix, ResourceStatus, TagsInfoType } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAppAuthContext } from "../../hooks/useAppAuthContext";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { AzureSubscriptionSummaryExt, useSubscriptions } from "../../hooks/useSubscriptions";
import { MysqlDeleteProps, newMysqlDelete } from "../../operations/Mysql/MysqlDelete";
import { MysqlEditDescriptionProps, newMysqlEditDescription } from "../../operations/Mysql/MysqlEditDescription";
import { MysqlEditTagsProps, newMysqlEditTags } from "../../operations/Mysql/MysqlEditTags";
import { MysqlRestartProps, newMysqlRestart } from "../../operations/Mysql/MysqlRestart";
import { MysqlStartProps, newMysqlStart } from "../../operations/Mysql/MysqlStart";
import { MysqlStopProps, newMysqlStop } from "../../operations/Mysql/MysqlStop";
import { MysqlUpdateTagsProps, newMysqlUpdateTags } from "../../operations/Mysql/MysqlUpdateTags";
import { azurePortalUrl, getAzureLocationName, getOciRegion } from "../../utils";
import { MysqlBackupsList } from "./MysqlBackupsList";
import { MysqlConfigurationDetails } from "./MysqlConfigurationDetails";
import { MysqlConnectDetails } from "./MysqlConnectDetails";
import { MysqlHeatwaveDetails } from "./MysqlHeatwaveDetails";
import { MysqlManagementDetails } from "./MysqlManagementDetails";
import { MysqlNetworkingDetails } from "./MysqlNetworkingDetails";

export enum ActionIds {
  ChangePassword = "change-password",
  Delete = "delete",
  LaunchMetrics = "launch-metrics",
  LaunchDashboard = "launch-dashboard",
  Refresh = "refresh",
  Start = "start",
  Stop = "stop",
  Restart = "restart",
  EditDescription = "edit-description",
  EditTags = "edit-tags",
}

export enum ActionBarTestIds {
  ChangePassword = "change-password",
  Delete = "delete",
  LaunchMetrics = "launch-metrics",
  LaunchDashboard = "launch-dashboard",
  Refresh = "refresh",
  Start = "start",
  Stop = "stop",
  Restart = "restart",
}

export enum MetaItemSectionTestIds {
  RESOURCE_GROUP = "mis-resource-group",
  STATUS = "mis-status",
  LOCATION = "mis-loaction",
  SUBSCRIPTION = "mis-subscription",
  SUBSCRIPTION_ID = "mis-subscription-id",
  TAGS = "mis-tags",
  OCI_RESOURCE = "mis-oci-resource",
  DB_NAME = "mis-db-name",
  DESCRIPTION = "mis-description",
  CREATED_TIME = "mis-created-time",
  UPDATED_TIME = "mis-updated-time",
}

export enum MetaItemActionTestIds {
  EditDescription = "edit-description",
  EditTags = "edit-tags",
}

export enum LinkTestIds {
  AddTags = "add-tags-link",
}

enum MdsDbSystemStatus {
  PROVISIONING= "Provisioning",
  UPDATING = "Updating",
  ACTIVE = "Active",
  FAILED = "Failed",
  TERMINATING = "Terminating",
  STOPPED = "Stopped"
}

const registrationIds = PageRegistrationConfig[PageId.MYSQL_DETAILS].map(config => config.key);

const getViewId = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.CONNECT:
      return registrationIds[7];
    case DetailsPanelId.BACKUPS:
      return registrationIds[5];
    case DetailsPanelId.MANAGEMENT:
      return registrationIds[2];
    case DetailsPanelId.CONFIGURATION:
      return registrationIds[6];
    case DetailsPanelId.NETWORKING:
      return registrationIds[4];
    case DetailsPanelId.HEATWAVE:
      return registrationIds[3];
    case DetailsPanelId.TAGS:
      return registrationIds[1];
    case DetailsPanelId.OVERVIEW:
    default:
      return registrationIds[0];
  }
};

const getViewTitle = (panelId: string | undefined, instanceName: string): string | TitleDetails => {
  switch (panelId) {
    case DetailsPanelId.HEATWAVE:
      return { primary: instanceName, secondary: Messages.labels.heatwave() };
    case DetailsPanelId.MANAGEMENT:
      return { primary: instanceName, secondary: Messages.labels.management() };
    case DetailsPanelId.BACKUPS:
      return { primary: instanceName, secondary: Messages.labels.backups() };
    case DetailsPanelId.NETWORKING:
      return { primary: instanceName, secondary: Messages.labels.networking() };
    case DetailsPanelId.CONFIGURATION:
      return { primary: instanceName, secondary: Messages.labels.configuration() };
    case DetailsPanelId.CONNECT:
      return { primary: instanceName, secondary: Messages.labels.connect() };
    case DetailsPanelId.TAGS:
      return { primary: instanceName, secondary: Messages.labels.tags() };
    case DetailsPanelId.OVERVIEW:
    default:
      return instanceName;
  }
};

const getViewIcon = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.HEATWAVE:
      return SvgIconIds.heatWaveSvg;
    case DetailsPanelId.MANAGEMENT:
      return SvgIconIds.managementSvg;
    case DetailsPanelId.BACKUPS:
      return SvgIconIds.backupsSvg;
    case DetailsPanelId.NETWORKING:
      return SvgIconIds.networkingSvg;
    case DetailsPanelId.CONFIGURATION:
      return SvgIconIds.configurationSvg;
    case DetailsPanelId.CONNECT:
      return SvgIconIds.connectSvg;
    case DetailsPanelId.TAGS:
      return SvgIconIds.tagsSvg;
    case DetailsPanelId.OVERVIEW:
    default:
      return SvgIconIds.mysqlSvg;
  }
};

export const MysqlDetailsPage = (): JSX.Element => {
  const
    {
      subscriptionId,
      resourceGroup: resGroupFromUrl,
      provider,
      resourceType,
      resourceName: resourceNameFromUrl,
      panelId,
    } = useParams();

  const [searchParams] = useSearchParams();

  const resGroup = decodeURIComponent(resGroupFromUrl || "");
  const resourceName = decodeURIComponent(resourceNameFromUrl || "");
  const location = searchParams.get("location") || "";

  const { trackActionClick, trackActionDiscard, trackFormSave } = useAnalytics();

  const { back, navigateToSelf, navigateToPanel } = useNavigation(ConsoleContext);

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  const tagDetailsRef = React.useRef<TagDetailsComponent>();
  const setTagDetailsRef = (panel: TagDetailsComponent): void => {
    tagDetailsRef.current = panel;
  };

  const [tagTabKey, setTagTabKey] = React.useState<string>(uniqueGUID());
  const mysqlId = buildId({
    subscriptionId: subscriptionId || "",
    resourceGroup: resGroup,
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName,
  });

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.MYSQL_DETAILS].find(
      config => config.panelPath === panelId,
    );
    const urlQueryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const path = `${RESOURCE_ROUTE}/${mysqlId}/${panelId}${urlQueryParams}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiVersion = MultiCloudDatabaseApiVersion;
  const { domainName } = useAppAuthContext();

  // const { trigger: triggerChangePassword } = useOperation<MysqlChangePasswordProps>(newMysqlChangePassword);
  const { trigger: triggerEditDescription } = useOperation<MysqlEditDescriptionProps>(newMysqlEditDescription);
  const { trigger: triggerEditTags } = useOperation<MysqlEditTagsProps>(newMysqlEditTags);
  const { trigger: triggerUpdateTags } = useOperation<MysqlUpdateTagsProps>(newMysqlUpdateTags);
  const { trigger: triggerDeleteMysql } = useOperation<MysqlDeleteProps>(newMysqlDelete);
  const { trigger: triggerStartMysql } = useOperation<MysqlStartProps>(newMysqlStart);
  const { trigger: triggerStopMysql } = useOperation<MysqlStopProps>(newMysqlStop);
  const { trigger: triggerRestartMysql } = useOperation<MysqlRestartProps>(newMysqlRestart);

  const { closePanels } = useSidePanel();

  // Subscription
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();
  const subscription = !subscriptionLoading && subscriptions
    ? subscriptions.find(s => s.id === subscriptionId)
    : undefined;

  const { response, loading, refresh, error } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.getMdsDbSystem,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        apiVersion,
        dbSystemName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadMysqlDatabase(),
      },
      excludeErrorStatus: [404],
    },
  });

  const instance : MdsDbSystem | undefined = response?.data;
  const isLoading = loading && !instance;

  React.useEffect(() => {
    setTagTabKey(uniqueGUID);
  }, [instance]);

  const onEditTags = (): void => {
    triggerEditTags({
      databaseId: mysqlId,
      defaultTags: tagsDefaultValue,
      location,
      onExecute: refresh,
      onCancel: () => trackActionDiscard(ActionIds.EditTags, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW),
    });
  };

  // Tags
  const tagsDefaultValue: TagsInfoType = Object.keys(instance?.freeformTags || {})
    .map((key: string) => ({ name: key, value: instance?.freeformTags?.[key] }));

  const onMenuItemSelect = (id: string): void => navigateToPanel(id);

  const onMetrics = (): void => {
    const insightsId = instance?.observabilityDetails?.azureApplicationInsightsId;
    const dbMetricsUrl = `${azurePortalUrl}#@${domainName}/resource/${insightsId}/metricsV2`;
    window.open(dbMetricsUrl, "_blank");
  };

  const onDashboard = (): void => {
    const dashboardId = instance?.observabilityDetails?.azureDashboardId;
    const dbDashboardUrl = `${azurePortalUrl}#@${domainName}/dashboard/arm/${dashboardId}`;
    window.open(dbDashboardUrl, "_blank");
  };

  const overviewActionItems: ActionType[] = [
    {
      key: ActionIds.LaunchMetrics,
      testId: ActionBarTestIds.LaunchMetrics,
      text: Messages.actions.metrics(),
      icon: MonochromeIconIds.OpenInNewWindow,
      disabled: !instance?.observabilityDetails?.azureApplicationInsightsId,
      onClick: () => {
        trackActionClick(ActionIds.LaunchMetrics, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
        onMetrics();
      },
    },
    {
      key: ActionIds.LaunchDashboard,
      testId: ActionBarTestIds.LaunchDashboard,
      text: Messages.actions.dashboard(),
      icon: MonochromeIconIds.OpenInNewWindow,
      disabled: !instance?.observabilityDetails?.azureDashboardId,
      onClick: () => {
        trackActionClick(ActionIds.LaunchDashboard, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
        onDashboard();
      },
    },
    {
      key: ActionIds.Start,
      testId: ActionBarTestIds.Start,
      text: Messages.actions.start(),
      icon: MonochromeIconIds.Play,
      title: Messages.labels.startMysql(),
      description: Messages.hints.startMysqlConfirmation(instance?.name || ""),
      disabled: instance?.status !== MdsDbSystemStatus.STOPPED,
      onClick: () => {
        trackActionClick(ActionIds.Start, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
      },
      onConfirm: () => {
        triggerStartMysql({
          databaseId: mysqlId,
          location,
          onExecute: refresh,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Start, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW),
    },
    {
      key: ActionIds.Stop,
      testId: ActionBarTestIds.Stop,
      text: Messages.actions.stop(),
      icon: MonochromeIconIds.Stop,
      title: Messages.labels.stopMysql(),
      description: Messages.hints.stopMysqlConfirmation(instance?.name || ""),
      disabled: instance?.status !== MdsDbSystemStatus.ACTIVE
      || instance?.crashRecovery === MdsCrashRecoveryStatusValues.DISABLED,
      onClick: () => {
        trackActionClick(ActionIds.Stop, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
      },
      onConfirm: () => {
        triggerStopMysql({
          databaseId: mysqlId,
          location,
          onExecute: refresh,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Stop, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW),
    },
    {
      key: ActionIds.Restart,
      testId: ActionBarTestIds.Restart,
      text: Messages.actions.restart(),
      icon: MonochromeIconIds.Restart,
      title: Messages.labels.restartMysql(),
      description: Messages.hints.restartMysqlConfirmation(instance?.name || ""),
      disabled: (instance?.status !== MdsDbSystemStatus.STOPPED && instance?.status !== MdsDbSystemStatus.ACTIVE)
        || instance?.crashRecovery === MdsCrashRecoveryStatusValues.DISABLED,
      onClick: () => {
        trackActionClick(ActionIds.Restart, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
      },
      onConfirm: () => {
        triggerRestartMysql({
          databaseId: mysqlId,
          location,
          onExecute: refresh,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Restart, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW),
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
        refresh();
      },
    },
    // {
    //   key: ActionIds.ChangePassword,
    //   text: Messages.actions.changePassword(),
    //   icon: MonochromeIconIds.Permissions,
    //   TODO: Has spec support, but Downstream DB Service has not implemented this
    //   disabled: true,
    //   onClick: () => {
    //  trackActionClick(ActionIds.ChangePassword, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
    //     triggerChangePassword({
    //       dbSystemId: mysqlId,
    //       location,
    //       targetId: ActionIds.ChangePassword,
    //       onExecute: refresh,
    //       onCancel: () => trackActionDiscard(
    //         ActionIds.ChangePassword,
    //         PageId.MYSQL_DETAILS,
    //         DetailsPanelId.OVERVIEW,
    //       ),
    //     });
    //   },
    // },
    {
      key: ActionIds.Delete,
      testId: ActionBarTestIds.Delete,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.labels.deleteMysql(),
      description: Messages.hints.deleteMysqlConfirmation(instance?.name || ""),
      onClick: () => {
        trackActionClick(ActionIds.Delete, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
      },
      onConfirm: () => {
        triggerDeleteMysql({
          databaseId: mysqlId,
          location,
          onExecute: () => {
            refresh();
            back();
          },
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Delete, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW),
    },
  ];

  const substitle = Messages.detailMysql.titles.long();

  const essentialsMetaItemGroup: JSX.Element[] = [
    <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="mysql-details-left-columm">
      <LabelMetaItem testId={MetaItemSectionTestIds.RESOURCE_GROUP} label={Messages.labels.resourceGroup()}>
        <AzureResourceGroupLink resourceId={mysqlId} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.STATUS} label={Messages.labels.status()}>
        <Status
          iconPosition="right"
          label={stateT(instance?.status || "")}
          status={getResourceLifecycleStatus(instance?.status || "")}
          statusInfo={
            getStatusInfo(
              instance?.status || "",
              instance?.lastOperationStatus || "",
              instance?.lastOperationStatusDetails || "",
            )
          }
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.LOCATION} label={Messages.labels.location()}>
        {instance?.location ? getAzureLocationName(instance.location) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.SUBSCRIPTION} label={Messages.labels.subscription()}>
        <AzureSubscriptionLink
          resourceId={mysqlId}
          subscriptionName={subscription?.name as string}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.SUBSCRIPTION_ID} label={Messages.labels.subscriptionId()}>
        {subscriptionId || <NoValue />}
      </LabelMetaItem>
    </Stack>,
    <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="mysql-details-right-columm">
      <LabelMetaItem testId={MetaItemSectionTestIds.OCI_RESOURCE} label={Messages.labels.ociResource()}>
        <OciMdsLink
          id={mysqlId}
          location={location}
          subscriptions={subscriptions as AzureSubscriptionSummaryExt[]}
          analytics={{ pageId: PageId.MYSQL_DETAILS, panelId: DetailsPanelId.OVERVIEW }}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.DB_NAME} label={Messages.labels.databaseName()}>
        {instance?.name || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem
        testId={MetaItemSectionTestIds.DESCRIPTION}
        label={Messages.labels.description()}
        action={{
          id: ActionIds.EditDescription,
          testId: MetaItemActionTestIds.EditDescription,
          label: Messages.actions.edit(),
          onClick: () => {
            trackActionClick(ActionIds.EditDescription, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
            triggerEditDescription({
              databaseId: mysqlId,
              description: instance?.description,
              location,
              targetId: ActionIds.EditDescription,
              onExecute: refresh,
              onCancel: () => trackActionDiscard(
                ActionIds.EditDescription,
                PageId.MYSQL_DETAILS,
                DetailsPanelId.OVERVIEW,
              ),
            });
          },
        }}
      >
        {instance?.description || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.CREATED_TIME} label={Messages.labels.created()}>
        <DateTimeDisplay date={instance?.timeCreated} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.UPDATED_TIME} label={Messages.labels.updated()}>
        <DateTimeDisplay date={instance?.timeUpdated} />
      </LabelMetaItem>
    </Stack>,
  ];

  // eslint-disable-next-line max-len
  const disabledCrashRecoveryInfoBlocks: InfoBlockProps[] = instance?.crashRecovery !== MdsCrashRecoveryStatusValues.DISABLED
    ? []
    : [{
      message: Messages.detailMysql.infoBlock.crashRecoveryDisabled(),
      messageType: InfoBlockStatus.INFO,
    }];

  const detailViews: DetailView[] = [
    {
      id: registrationIds[0],
      content: (
        <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
          <ActionBar
            actions={overviewActionItems}
            onActionClick={closePanels}
            infoBlocks={disabledCrashRecoveryInfoBlocks}
          />
          <MetaItemSection
            tags={{
              testId: MetaItemSectionTestIds.TAGS,
              items: tagsDefaultValue.length ? tagsDefaultValue : undefined,
              add: {
                testId: LinkTestIds.AddTags,
                onClick: () => {
                  trackActionClick(ActionIds.EditTags, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
                  onEditTags();
                },
              },
              edit: {
                id: ActionIds.EditTags,
                testId: MetaItemActionTestIds.EditTags,
                onClick: () => {
                  trackActionClick(ActionIds.EditTags, PageId.MYSQL_DETAILS, DetailsPanelId.OVERVIEW);
                  onEditTags();
                },
              },
            }}
          >
            {essentialsMetaItemGroup}
          </MetaItemSection>
        </Stack>
      ),
    },
    {
      id: registrationIds[1],
      content: (
        <TagDetails
          key={tagTabKey}
          resourceId={mysqlId}
          tagsDefaultValue={tagsDefaultValue}
          onApply={(formValues: FormValues) => {
            trackFormSave(InlineFormId.TAGS, PageId.MYSQL_DETAILS, DetailsPanelId.TAGS);
            const tags = getValue<TagsInfoType>(formValues, TagDetailsFields.Tags, InputFormGroup) ?? [];
            // *** Do not call refresh when updating tags on the tags tab to avoid resetting user input ***
            const updateTagsProps = {
              databaseId: mysqlId,
              tags,
              location,
              onExecute: () => tagDetailsRef.current?.toggleInProgress(false, panelRef.current),
            };
            tagDetailsRef.current?.toggleInProgress(true, panelRef.current);
            triggerUpdateTags(updateTagsProps);
          }}
          componentRef={setTagDetailsRef}
        />
      ),
    },
    {
      id: registrationIds[2],
      content: (
        <MysqlManagementDetails
          database={instance}
          mdsDbSystemId={mysqlId}
          location={location}
          refresh={refresh}
        />
      ),
    },
    {
      id: registrationIds[3],
      content: (
        <MysqlHeatwaveDetails
          mdsDbSystem={instance}
          mdsDbSystemId={mysqlId}
          location={location}
          refreshMds={refresh}
        />
      ),
    },
    {
      id: registrationIds[4],
      content: (<MysqlNetworkingDetails
        mdsDbSystem={instance}
        location={location}
        subscriptionId={subscriptionId || ""}
        resourceGroupName={resGroup}
      />),
    },
    {
      id: registrationIds[5],
      content: (<MysqlBackupsList
        location={location}
        dbSystemId={mysqlId}
        crashRecovery={instance?.crashRecovery}
      />),
    },
    {
      id: registrationIds[6],
      content: (<MysqlConfigurationDetails
        mdsDbSystem={instance}
        mdsDbSystemId={mysqlId}
        location={location}
        refresh={refresh}
      />),
    },
    {
      id: registrationIds[7],
      content: (<MysqlConnectDetails mdsDbSystem={instance} />),
    },
  ];

  const groupItems: GroupItem[] = [
    {
      items: [
        {
          icon: SvgIconIds.mysqlSvg,
          id: detailViews[0].id,
          name: Messages.labels.overview(),
        },
        {
          icon: SvgIconIds.tagsSvg,
          id: detailViews[1].id,
          name: Messages.labels.tags(),
        },
      ],
    },
    {
      heading: Messages.labels.settings(),
      items: [
        {
          icon: SvgIconIds.connectSvg,
          id: detailViews[7].id,
          name: Messages.labels.connect(),
        },
        {
          icon: SvgIconIds.configurationSvg,
          id: detailViews[6].id,
          name: Messages.labels.configuration(),
        },
        {
          icon: SvgIconIds.networkingSvg,
          id: detailViews[4].id,
          name: Messages.labels.networking(),
        },
        {
          icon: SvgIconIds.managementSvg,
          id: detailViews[2].id,
          name: Messages.labels.management(),
        },
      ],
    },
    {
      heading: Messages.labels.resources(),
      items: [
        { icon: SvgIconIds.backupsSvg, id: detailViews[5].id, name: Messages.labels.backups() },
        { icon: SvgIconIds.heatWaveSvg, id: detailViews[3].id, name: Messages.labels.heatwave() },
      ],
    },
  ];

  const renderedContent = (): JSX.Element => {
    if (isLoading) {
      return <Spinner label={Messages.common.loading()} />;
    } if (error) {
      const isNotFound = error?.status === 404;
      const errorTitle = isNotFound
        ? Messages.detailMysql.loadError.notFound.title()
        : Messages.detailMysql.loadError.general.title();
      const errorDetail = isNotFound
        ? Messages.detailMysql.loadError.notFound.details()
        : Messages.detailMysql.loadError.general.details();

      return (
        <ErrorViewPanel
          icon={SvgIconIds.mysqlSvg}
          title={resourceName}
          errorTitle={errorTitle}
          resourceId={mysqlId}
          errorCode={error?.status}
          details={[
            errorDetail,
            Messages.notifications.apiErrorMsg(error?.body?.message),
          ]}
          isOpen
          onClose={back}
          favoriteAccessRegistration={{ id: mysqlId, data: { location } }}
        />
      );
    }

    return (
      <DetailViewsPanel
        componentRef={setPanelRef}
        title={getViewTitle(panelId, instance?.name || "")}
        subTitle={substitle}
        icon={getViewIcon(panelId)}
        onClose={back}
        isOpen
        views={detailViews}
        activeViewId={getViewId(panelId)}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
        status={instance?.status !== ResourceStatus.Active ? {
          status: getResourceLifecycleStatus(instance?.status || ""),
          tooltip: stateT(instance?.status || ""),
        } : undefined}
        favoriteAccessRegistration={{ id: instance?.id || "", data: { location } }}
      />
    );
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={instance?.name || Messages.detailMysql.titles.short()}
      titleSuffix={instance?.name ? getTitleSuffix(panelId || "") : undefined}
      recentAccessRegistration={{ id: instance?.id || "", data: { location } }}
    >
      {renderedContent()}
    </BookmarkablePage>
  );
};
