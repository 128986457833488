import {
  ActionType,
  ConfirmAction,
  CustomAction,
  EmptyListingButtonTypes,
  ExtraFilter,
  FavoriteAccessItem,
  FilterAll,
  FilterComponent,
  getResourceLifecycleStatus,
  InfoBlockProps,
  InfoBlockStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingDisplayNameLink,
  ListingSelectOption,
  Operator,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
  useFavoriteAccess,
  useNavigation,
  ViewSelectOption,
} from "o4a-react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  DetailsPanelId,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
  VMDB_CREATE_ROUTE,
} from "../../constants/pluginConstants";
import {
  emptyChar,
  ListingTestIds,
  MonochromeIconIds,
  SvgIconIds,
  ttlOneMinCaching,
  ViewKeys,
} from "../../constants/uiConstants";
import { AzureSubscriptionSummary } from "../../gen/clients/mchub-azure-api-client";
import {
  VmdbDatabaseSummary,
  VmdbDatabaseSummaryCollection,
  VmdbDatabaseSummaryStatusEnum,
  VmdbPluggableDatabaseOpenModeEnum,
  VmdbPluggableDatabaseSummary,
  VmdbPluggableDatabaseSummaryCollection,
  VmdbPluggableDatabaseSummaryStatusEnum,
} from "../../gen/clients/mchub-azure-api-client-vmdb";
import { parseId } from "../../helpers/idHelper";
import {
  getFieldCustomOptions,
  getStatusInfo,
  PdbOpenMode,
  ResourceStatus,
  responseItemstoArray,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFilterStatePills } from "../../hooks/useFilterStatePills";
import { useLocationFilters } from "../../hooks/useLocationFilters";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { newVmDbDeleteCdb, VmDbDeleteCdbProps } from "../../operations/VmDb/VmDbDeleteCdb";
import { newVmDbDeletePdb, VmDbDeletePdbProps } from "../../operations/VmDb/VmDbDeletePdb";
import { newVmDbStartPdb, VmDbStartPdbProps } from "../../operations/VmDb/VmDbStartPdb";
import { newVmDbStopPdb, VmDbStopPdbProps } from "../../operations/VmDb/VmDbStopPdb";
import {
  newVmDbViewConnectionStrings,
  VmDbViewConnectionStringsProps,
} from "../../operations/VmDb/VmDbViewConnectionStrings";
import { getAzureLocationName, getOciRegion } from "../../utils";

const cdbDetailsPageRegistrationIds = PageRegistrationConfig[PageId.VMDB_CDB_DETAILS].map(config => config.key);
const pdbDetailsPageRegistrationIds = PageRegistrationConfig[PageId.VMDB_PDB_DETAILS].map(config => config.key);
const vmdbSystemDetailsPageIds = PageRegistrationConfig[PageId.VMDB_SYSTEM_DETAILS].map(config => config.key);

const getCdbLink = (value: VmDbItem): JSX.Element => (
  <ListingDisplayNameLink
    displayName={value.containerDBName || ""}
    navigation={{
      // eslint-disable-next-line max-len
      to: `${RESOURCE_ROUTE}/${value.containerDBId}/${PageRegistrationConfig[PageId.VMDB_CDB_DETAILS][0].panelPath}?location=${value.location}`,
      pageKey: cdbDetailsPageRegistrationIds[0],
    }}
  />
);

const getDbSystemLink = (value: VmDbItem): JSX.Element => (
  <ListingDisplayNameLink
    displayName={parseId(value.dbSystemId)?.resourceName || ""}
    navigation={{
      // eslint-disable-next-line max-len
      to: `${RESOURCE_ROUTE}/${value.dbSystemId}/${PageRegistrationConfig[PageId.VMDB_SYSTEM_DETAILS][0].panelPath}?location=${value.location}`,
      pageKey: vmdbSystemDetailsPageIds[0],
    }}
  />
);

export enum ColumnIds {
  DisplayName = "displayName",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  LifeCycleStatus = "status",
  ContainerDB = "containerDBName",
  FreeformTags = "freeformTags",
  DatabaseSystem = "dbSystemName",
}

export enum ColumnTestIds {
  DisplayName = "displayName",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  LifeCycleStatus = "status",
  ContainerDB = "containerDBName",
  FreeformTags = "freeformTags",
  DatabaseSystem = "databaseSystem",
}

export enum ActionBarIds {
  Create = "action-bar-create",
  Start = "action-bar-start",
  Stop = "action-bar-stop",
  ConnectionStrings = "action-bar-connection-strings",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
}

export enum ActionMenuIds {
  Start = "action-menu-start",
  Stop = "action-menu-stop",
  ConnectionStrings = "action-menu-connection-strings",
  Delete = "action-menu-delete",
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum ActionBarTestIds {
  Create = "action-bar-create",
  Start = "action-bar-start",
  Stop = "action-bar-stop",
  ConnectionStrings = "action-bar-connection-strings",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
}

export enum ActionMenuTestIds {
  Start = "action-menu-start",
  Stop = "action-menu-stop",
  ConnectionStrings = "action-menu-connection-strings",
  Delete = "action-menu-delete",
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum FilterTestIds {
  Subscription = "subscription-filter",
  Location = "location-filter"
}

export enum InfoBlockTestIds {
  DisabledActions = "disabled-actions-info-block"
}

interface AzureSubscriptionSummaryMap {
  [key: string]: AzureSubscriptionSummary;
}

export interface VmDbItem {
  id: string;
  dbSystemName?: string;
  dbSystemId?: string;
  displayName: string;
  subscriptionId: string;
  subscriptionName: string;
  resourceGroupName: string;
  location: string;
  containerDBName?: string;
  containerDBId?: string;
  isPdb: boolean;
  status: VmdbPluggableDatabaseSummaryStatusEnum | VmdbDatabaseSummaryStatusEnum;
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
  freeformTags?: { [key: string]: string; };
  openMode?: VmdbPluggableDatabaseOpenModeEnum;
  isFavorite: boolean;
}

export interface VmDbListProps {
  disabled?: boolean;
}

export const VmDbList = ({ disabled }: VmDbListProps): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { favoriteItems, isFavorite, setAsFavorite, removeFromFavorites } = useFavoriteAccess();

  const { navigateTo } = useNavigation(ConsoleContext);
  const { preferredSubscription, locale } = React.useContext<Settings>(SettingsContext);
  const filterState = React.useContext<FilterState>(FilterStateContext);
  const { pathname } = useLocation();

  const [selection, setSelection] = React.useState<VmDbItem[]>([]);
  const [selectedSubscription, setSelectedSubscription] = React.useState<string>("");
  const [subscriptionOptions, setSubscriptionOptions] = React.useState<{ key: string, text: string }[]>([]);

  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();

  const { locationOptions, setSelectedLocation, selectedLocation } = useLocationFilters(
    filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
  );

  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<VmDbItem>>();

  const [filterComponentRef, setFilterComponentRef] = React.useState<FilterComponent>();
  const pillFilterValues = filterComponentRef?.getFilters();
  const filterTextValue = filterComponentRef?.getFilterText();
  const [filterText, setFilterText] = React.useState(filterState.filterTextValue || filterTextValue);

  const { perPageItemsCount } = useRowCount();

  const getLocation = (value: VmDbItem): string => getAzureLocationName(value.location);

  const getStatus = (value: VmDbItem): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
        )
      }
      hideClipboardCopy
    />
  );

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.DisplayName,
      testId: ColumnTestIds.DisplayName,
      name: Messages.common.name(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => {
        const isCdb = !value.isPdb;
        // eslint-disable-next-line max-len
        const cdbNavigation = `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.VMDB_CDB_DETAILS][0].panelPath}?location=${value.location}`;
        // eslint-disable-next-line max-len
        const pdbNavigation = `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.VMDB_PDB_DETAILS][0].panelPath}?location=${value.location}`;
        return (
          <ListingDisplayNameLink
            displayName={value.displayName}
            navigation={{
              to: isCdb ? cdbNavigation : pdbNavigation,
              pageKey: isCdb ? cdbDetailsPageRegistrationIds[0] : pdbDetailsPageRegistrationIds[0],
            }}
            iconName={SvgIconIds.baseDbSvg}
          />
        );
      },
    },
    {
      itemProp: ColumnIds.SubscriptionName,
      testId: ColumnTestIds.SubscriptionName,
      name: Messages.labels.subscription(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, max-len
      onRenderItems: value => <AzureSubscriptionLink resourceId={value.id} subscriptionName={value.subscriptionName} hideClipboard />,
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.LifeCycleStatus,
      testId: ColumnTestIds.LifeCycleStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: VmDbItem,
        b: VmDbItem,
      ) => statusSortComparator<VmDbItem>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.ContainerDB,
      testId: ColumnTestIds.ContainerDB,
      name: Messages.labels.containerDb(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getCdbLink(value),
    },
    {
      itemProp: ColumnIds.DatabaseSystem,
      testId: ColumnTestIds.DatabaseSystem,
      name: Messages.labels.databaseSystem(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getDbSystemLink(value),
    },
  ];

  const setSelectedItems = (selectedItems: VmDbItem[]): void => {
    setSelection(selectedItems);
  };

  const { refresh: cdbRefresh, loading: cdbLoading, response: cdbResponse } = useQueryCall({
    wait: (!selectedSubscription || !selectedLocation) || disabled,
    method: apiClients.withRegion(
      getOciRegion(selectedLocation),
    ).vmDatabaseApi.listVmdbDatabasesBySubscription,
    options: {
      args: {
        subscriptionId: selectedSubscription,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVMDbCdbs(),
      },
    },
  });

  const cdbs = cdbResponse
    && responseItemstoArray<VmdbDatabaseSummaryCollection, VmdbDatabaseSummary>(cdbResponse);

  const { refresh: pdbRefresh, loading: pdbLoading, response: pdbResponse } = useQueryCall({
    wait: (!selectedSubscription || !selectedLocation) || disabled,
    method: apiClients.withRegion(
      getOciRegion(selectedLocation),
    ).vmDatabaseApi.listVmdbPluggableDatabasesBySubscription,
    options: {
      args: {
        subscriptionId: selectedSubscription,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVMDbPdbs(),
      },
    },
  });

  const pdbs = pdbResponse
    && responseItemstoArray<VmdbPluggableDatabaseSummaryCollection, VmdbPluggableDatabaseSummary>(pdbResponse);

  const groupingOptions: ListingSelectOption<VmDbItem>[] = [];

  const viewOptions: ViewSelectOption<VmDbItem>[] = [
    {
      key: ViewKeys.ListView,
      text: Messages.listVmDb.filters.listView(),
      icon: "List",
    },
  ];

  const vmDbSummaryToVmDbItem = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any[],
    subscriptionsMap: AzureSubscriptionSummaryMap,
  ): VmDbItem[] => {
    const vmDbItem: VmDbItem[] = [];
    items.forEach(item => {
      const { subscriptionId, resourceGroup } = parseId(item.id);
      const itemRgName = resourceGroup;
      const itemSubscriptionName = subscriptionsMap && subscriptionsMap[subscriptionId]?.name;
      if (item.status !== "Terminated") {
        if (item.containerDatabaseId) /* is pdb */ {
          const { resourceName: cdbName } = parseId(item.containerDatabaseId);
          vmDbItem.push({
            id: item.id,
            dbSystemName: undefined,
            dbSystemId: undefined,
            displayName: item.name,
            subscriptionId,
            subscriptionName: itemSubscriptionName,
            resourceGroupName: itemRgName,
            location: item.location,
            containerDBName: cdbName,
            containerDBId: item.containerDatabaseId,
            isPdb: true,
            status: item.status,
            freeformTags: item.freeformTags,
            openMode: item.openMode,
            isFavorite: isFavorite(item.id),
          });
        } else {
          const { resourceName: dbSystemName } = parseId(item.dbSystemId);
          vmDbItem.push({
            id: item.id,
            dbSystemName,
            dbSystemId: item.dbSystemId,
            displayName: item.name,
            subscriptionId,
            subscriptionName: itemSubscriptionName,
            resourceGroupName: itemRgName,
            location: item.location,
            isPdb: false,
            status: item.status,
            freeformTags: item.freeformTags,
            isFavorite: isFavorite(item.id),
          });
        }
      }
    });
    return vmDbItem;
  };

  const items = React.useMemo(() => {
    let newInstance: VmDbItem[] = [];

    if (subscriptions && subscriptions.length > 0) {
      const subscriptionsMap = subscriptions.reduce((
        map: AzureSubscriptionSummaryMap,
        subscription: AzureSubscriptionSummary,
      ) => {
        map[subscription.id] = subscription;
        return map;
      }, {});
      if (cdbs) newInstance = newInstance.concat(vmDbSummaryToVmDbItem(cdbs, subscriptionsMap));
      if (pdbs) newInstance = newInstance.concat(vmDbSummaryToVmDbItem(pdbs, subscriptionsMap));
    }
    return newInstance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pdbs), JSON.stringify(cdbs), JSON.stringify(subscriptions), JSON.stringify(favoriteItems)]);

  const onRefresh = (): void => {
    cdbRefresh();
    pdbRefresh();
  };

  const containerDb = (selectedItem: VmDbItem):
  VmDbItem | undefined => items.find(item => item.id === selectedItem?.containerDBId);
  const containerDbStatus = (selectedItem: VmDbItem) : string | undefined => {
    const cdb = containerDb(selectedItem);
    return cdb?.status || undefined;
  };

  const cdbFailedState = (selectedItem: VmDbItem):
  boolean => containerDbStatus(selectedItem) === ResourceStatus.Failed;
  const pdbFailedState = (selectedPdb: VmDbItem):
   boolean => (selectedPdb?.status === ResourceStatus.Failed);

  const openModesThatDisablePdbStop = (selectedItem: VmDbItem): boolean => (
    (selectedItem?.openMode !== PdbOpenMode.READ_ONLY && selectedItem?.openMode !== PdbOpenMode.READ_WRITE));
  const openModeThatDisablesPdbStart = (selectedItem: VmDbItem):
   boolean => (selectedItem?.openMode !== PdbOpenMode.MOUNTED);

  const disabledActionsInfoBlocks = (selectedItem: VmDbItem): InfoBlockProps[] => {
    const disabledActionInfo: InfoBlockProps[] = [];
    const disabledActions: string[] = [];
    if (pdbFailedState(selectedItem)) {
      disabledActions.push(Messages.actions.start());
      disabledActions.push(Messages.actions.stop());
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          ? Messages.hints.pdbDisabledActionPdbStatus(disabledActions.toString(), stateT(selectedItem?.status || ""))
          : Messages.hints.pdbDisabledActionsPdbStatus(disabledActions.join(", "), stateT(selectedItem?.status || ""));
        disabledActionInfo.push(
          {
            testId: InfoBlockTestIds.DisabledActions,
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    } else {
      if (cdbFailedState(selectedItem)) {
        disabledActions.push(Messages.actions.start());
        disabledActions.push(Messages.actions.stop());
      }
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          // eslint-disable-next-line max-len
          ? Messages.hints.pdbDisabledActionCdbStatus(disabledActions.toString(), stateT(containerDbStatus(selectedItem) || ""))
          // eslint-disable-next-line max-len
          : Messages.hints.pdbDisabledActionsCdbStatus(disabledActions.join(", "), stateT(containerDbStatus(selectedItem) || ""));
        disabledActionInfo.push(
          {
            testId: InfoBlockTestIds.DisabledActions,
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    }
    return disabledActionInfo;
  };

  const cdbDisabledActionsInfoBlock = (): InfoBlockProps[] => {
    const disabledActionInfo: InfoBlockProps[] = [];
    if (selection[0] && !selection[0].isPdb) {
      disabledActionInfo.push(
        {
          testId: InfoBlockTestIds.DisabledActions,
          message: Messages.hints.cdbDisabledActions(`${Messages.actions.start()}, ${Messages.actions.stop()}`),
          messageType: InfoBlockStatus.INFO,
        },
      );
    }
    return disabledActionInfo;
  };

  const { trigger: triggerDeleteCdb } = useOperation<VmDbDeleteCdbProps>(newVmDbDeleteCdb);
  const { trigger: triggerDeletePdb } = useOperation<VmDbDeletePdbProps>(newVmDbDeletePdb);
  const { trigger: triggerStartPdb } = useOperation<VmDbStartPdbProps>(newVmDbStartPdb);
  const { trigger: triggerStopPdb } = useOperation<VmDbStopPdbProps>(newVmDbStopPdb);
  const { trigger: triggerViewConnectionStrings } = useOperation<VmDbViewConnectionStringsProps>(
    newVmDbViewConnectionStrings,
  );

  const confirmDeleteCdbAction = (selectedItem: VmDbItem): void => {
    triggerDeleteCdb({
      dbSystemId: selectedItem.dbSystemId,
      databaseId: selectedItem.id,
      location: selectedLocation,
      onExecute: () => {
        listingComponentRef?.resetTableSelection();
        cdbRefresh();
      },
    });
  };

  const confirmDeletePdbAction = (selectedItem: VmDbItem) : void => {
    triggerDeletePdb({
      databaseId: selectedItem.id,
      location: selectedLocation,
      onExecute: () => {
        listingComponentRef?.resetTableSelection();
        pdbRefresh();
      },
    });
  };

  const confirmStartAction = (selectedItem: VmDbItem) : void => {
    triggerStartPdb({
      databaseId: selectedItem.id,
      location: selectedLocation,
      onExecute: () => {
        listingComponentRef?.resetTableSelection();
        pdbRefresh();
      },
    });
  };

  const confirmStopAction = (selectedItem: VmDbItem) : void => {
    triggerStopPdb({
      databaseId: selectedItem.id,
      location: selectedLocation,
      onExecute: () => {
        listingComponentRef?.resetTableSelection();
        pdbRefresh();
      },
    });
  };

  const buildStartAction = (key: string, testId: string, selectedItem: VmDbItem):
  ConfirmAction<VmDbItem | undefined> => {
    const startAction = {
      key,
      testId,
      text: Messages.actions.start(),
      icon: MonochromeIconIds.Play,
      title: Messages.labels.startVmDB(),
      description: Messages.hints.startVmDBConfirmation(selectedItem?.displayName),
      disabled: cdbFailedState(selectedItem) || pdbFailedState(selectedItem)
      || openModeThatDisablesPdbStart(selectedItem),
      onClick: () => trackActionClick(key, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES),
      onConfirm: () => confirmStartAction(selectedItem),
      onCancel: () => trackActionDiscard(key, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES),
    };
    return startAction;
  };

  const buildStopAction = (key: string, testId: string, selectedItem: VmDbItem):
  ConfirmAction<VmDbItem | undefined> => {
    const stopAction = {
      key,
      testId,
      text: Messages.actions.stop(),
      icon: MonochromeIconIds.Stop,
      title: Messages.labels.stopVmDB(),
      description: Messages.hints.stopVmDBConfirmation(selectedItem?.displayName),
      disabled: cdbFailedState(selectedItem) || pdbFailedState(selectedItem)
      || openModesThatDisablePdbStop(selectedItem),
      onClick: () => trackActionClick(key, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES),
      onConfirm: () => confirmStopAction(selectedItem),
      onCancel: () => trackActionDiscard(key, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES),
    };
    return stopAction;
  };

  const buildViewConnectionStringsAction = (key: string, testId: string, selectedItem: VmDbItem):
  CustomAction<VmDbItem | undefined> => {
    const viewConnectionStringsAction = {
      key,
      testId,
      text: Messages.actions.connectionStrings(),
      icon: MonochromeIconIds.Connect,
      disabled: !selectedItem,
      onClick: () => {
        trackActionClick(key, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES);
        triggerViewConnectionStrings({
          databaseId: selectedItem?.id,
          isPdb: selectedItem?.isPdb,
          location: selectedLocation,
        });
      },
    };
    return viewConnectionStringsAction;
  };

  const buildDeleteAction = (key: string, testId: string, selectedItem: VmDbItem):
  ConfirmAction<VmDbItem | undefined> => {
    const deleteAction = {
      key,
      testId,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      disabled: !selectedItem || disabled,
      title: Messages.labels.deleteVMDb(),
      description: Messages.hints.deleteVMDbConfirmation(selectedItem?.displayName),
      onClick: () => trackActionClick(key, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES),
      onConfirm: () => {
        if (selectedItem?.isPdb) {
          confirmDeletePdbAction(selectedItem);
        } else {
          confirmDeleteCdbAction(selectedItem);
        }
      },
      onCancel: () => trackActionDiscard(key, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES),
    };
    return deleteAction;
  };

  const { closePanels } = useSidePanel();

  const vmDbRowActionMenuItems = (item?: VmDbItem | undefined) : ActionType<VmDbItem>[] => {
    const vmDbActionMenuItems: ActionType<VmDbItem | undefined>[] = [];
    if (item) {
      if (item.isFavorite) {
        vmDbActionMenuItems.push({
          key: ActionMenuIds.RemoveFavorite,
          testId: ActionMenuTestIds.RemoveFavorite,
          text: Messages.actions.removeFromFavorites(),
          icon: MonochromeIconIds.FavoriteSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.RemoveFavorite, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES);
            removeFromFavorites(item.id);
          },
        } as CustomAction<VmDbItem | undefined>);
      } else {
        vmDbActionMenuItems.push({
          key: ActionMenuIds.AddFavorite,
          testId: ActionMenuTestIds.AddFavorite,
          text: Messages.actions.addToFavorites(),
          icon: MonochromeIconIds.FavoriteNotSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.AddFavorite, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES);
            setAsFavorite({ id: item.id, data: { location: item.location } } as FavoriteAccessItem);
          },
        } as CustomAction<VmDbItem | undefined>);
      }

      if (item?.isPdb) {
        vmDbActionMenuItems.push(
          buildStartAction(ActionMenuIds.Start, ActionMenuTestIds.Start, item),
          buildStopAction(ActionMenuIds.Stop, ActionMenuTestIds.Stop, item),
        );
      }
      vmDbActionMenuItems.push(
        buildViewConnectionStringsAction(ActionMenuIds.ConnectionStrings, ActionMenuTestIds.ConnectionStrings, item),
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return vmDbActionMenuItems;
  };

  const vmDbBarActions: ActionType<VmDbItem>[] = [
    {
      key: ActionBarIds.Create,
      testId: ActionBarTestIds.Create,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        trackActionClick(ActionBarIds.Create, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES);
        navigateTo(VMDB_CREATE_ROUTE, PageId.VMDB_CREATE, {
          analytics: {
            pageId: PageId.VMDB_LIST_HUB,
            panelId: DetailsPanelId.DATABASES,
            actionName: ActionBarIds.Create,
          } as NavigationAnalyticsData,
        });
      },
    },
    buildStartAction(ActionBarIds.Start, ActionBarTestIds.Start, selection[0]),
    buildStopAction(ActionBarIds.Stop, ActionBarTestIds.Stop, selection[0]),
    buildViewConnectionStringsAction(ActionBarIds.ConnectionStrings, ActionBarTestIds.ConnectionStrings, selection[0]),
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      disabled,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES);
        onRefresh();
        listingComponentRef?.resetTableSelection();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  React.useEffect(() => {
    const newSubscriptionOptions: { key: string, text: string }[] = [];
    // eslint-disable-next-line no-unused-expressions
    subscriptions && subscriptions.forEach(subscription => {
      newSubscriptionOptions.push({ key: subscription.id, text: subscription.name });
    });

    setSubscriptionOptions(newSubscriptionOptions);
    if (filterState.defaultSubscription) {
      setSelectedSubscription(filterState.defaultSubscription);
      if (defaultExtraFilters) defaultExtraFilters[0].value = filterState.defaultSubscription;
    } else if (preferredSubscription) {
      setSelectedSubscription(preferredSubscription);
    } else {
      setSelectedSubscription(newSubscriptionOptions.length ? newSubscriptionOptions[0].key : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions)]);

  const subscriptionFilter: ExtraFilter = {
    testId: FilterTestIds.Subscription,
    name: columns[1].name,
    operator: Operator.Equal,
    value: filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
    fieldKey: columns[1].itemProp,
    customOptions: subscriptionOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedSubscription(value);
        filterState.setDefaultSubscription(value);
      }
    },
    hideDelete: true,
  };

  const resourceGroupFilter: ExtraFilter = {
    name: columns[2].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[2].itemProp)?.value || [FilterAll],
    fieldKey: columns[2].itemProp,
    customOptions: getFieldCustomOptions(items?.map(item => item.resourceGroupName), (fieldVal: string) => fieldVal),
  };

  React.useEffect(() => {
    if (filterState.defaultLocation) {
      setSelectedLocation(filterState.defaultLocation);
      if (defaultExtraFilters) defaultExtraFilters[1].value = filterState.defaultLocation;
    } else {
      setSelectedLocation(filterState.pillFilterStateValue.filterValues?.[1]?.value || selectedLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locationFilter: ExtraFilter = {
    testId: FilterTestIds.Location,
    name: columns[3].name,
    operator: Operator.Equal,
    value: filterState.defaultLocation ? filterState.defaultLocation : selectedLocation,

    fieldKey: columns[3].itemProp,
    customOptions: locationOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedLocation(value);
        filterState.setDefaultLocation(value);
      }
    },
    hideDelete: true,
  };

  const lifecycleStateFilter: ExtraFilter = {
    name: columns[4].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[4].itemProp)?.value || [FilterAll],
    fieldKey: columns[4].itemProp,
    messageCallBack: stateT,
    customOptions: getFieldCustomOptions(
      items?.map(item => item.status || ""),
      (fieldVal: string) => {
        if (fieldVal) {
          return stateT(fieldVal);
        }
        return emptyChar;
      },
    ),
  };

  const containerDbFilter: ExtraFilter = {
    name: columns[5].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[5].itemProp)?.value || [FilterAll],
    fieldKey: columns[5].itemProp,
    customOptions: getFieldCustomOptions(
      items?.map(item => item.containerDBName || ""),
      (fieldVal: string) => {
        if (fieldVal) {
          return fieldVal;
        }
        return emptyChar;
      },
    ),
  };

  const allFilterPills = [
    subscriptionFilter,
    locationFilter,
    resourceGroupFilter,
    lifecycleStateFilter,
    containerDbFilter,
  ];

  const filterPills: ExtraFilter[] = [
    subscriptionFilter,
    locationFilter,
  ];

  const textFilterCallBack = (rowItem: VmDbItem): VmDbItem => {
    const displayRowItem = { ...rowItem };
    displayRowItem.status = stateT(
      displayRowItem.status || "",
    ) as VmdbPluggableDatabaseSummaryStatusEnum | VmdbDatabaseSummaryStatusEnum;
    displayRowItem.location = getAzureLocationName(displayRowItem.location);
    return displayRowItem;
  };

  React.useEffect(() => {
    setFilterText(filterTextValue);
    filterState.setFilterTextValue(filterTextValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTextValue]);

  const defaultExtraFilters = useFilterStatePills(pillFilterValues, allFilterPills, pathname, filterPills);

  const resetFilterValues = (): void => {
    const defaultPillValues = filterPills.map(filter => ({ fieldKey: filter.fieldKey, value: filter.value as string }));
    filterState.setFilterStateValue({ filterValues: defaultPillValues, listId: pathname, defaultPills: filterPills });
    filterState.setFilterTextValue("");
    setFilterText("");
  };

  return (
    <Listing
      testId={ListingTestIds.VmDb}
      listingComponentRef={setListingComponentRef}
      items={items}
      listColumns={columns}
      itemsPerPage={perPageItemsCount}
      actionBarItems={vmDbBarActions as ActionType<undefined>[]}
      infoBlocks={selection[0]?.isPdb ? disabledActionsInfoBlocks(selection[0]) : cdbDisabledActionsInfoBlock()}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      actions={vmDbRowActionMenuItems}
      emptyList={{
        title: Messages.listVmDb.emptyList.title(),
        emptyButtons: [
          {
            type: EmptyListingButtonTypes.PRIMARY,
            text: Messages.actions.createVMDb(),
            action: () => {
              trackActionClick(ActionBarIds.Create, PageId.VMDB_LIST_HUB, DetailsPanelId.DATABASES);
              navigateTo(VMDB_CREATE_ROUTE, PageId.VMDB_CREATE, {
                analytics: {
                  pageId: PageId.VMDB_LIST_HUB,
                  panelId: DetailsPanelId.DATABASES,
                  actionName: ActionBarIds.Create,
                } as NavigationAnalyticsData,
              });
            },
          },
        ],
        watermark: SvgIconIds.baseDbWatermarkSvg,
      }}
      isLoading={(pdbLoading || cdbLoading || subscriptionLoading) && !disabled}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.DisplayName,
      }}
      groupingSelect={{ groupingOptions }}
      viewSelect={{
        viewOptions,
        defaultSelectedKey: ViewKeys.ListView,
      }}
      filtering={{
        allExtraFilters: allFilterPills,
        defaultExtraFilters,
        defaultFilterText: filterText,
        textFilterCallBack,
        componentRef: setFilterComponentRef,
        onClearFilters: resetFilterValues,
      }}
    />
  );
};
