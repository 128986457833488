import {
  ActionBar,
  ActionType,
  AnchoredPanelComponent,
  BookmarkablePage,
  DateTimeDisplay,
  DetailView,
  DetailViewsPanel,
  ErrorViewPanel,
  FormValues,
  getResourceLifecycleStatus,
  getValue,
  GroupItem,
  InfoBlockProps,
  InfoBlockStatus,
  InputFormGroup,
  LabelMetaItem,
  MetaItemSection,
  NoValue,
  optimizedRetryOption,
  stateT,
  Status,
  TitleDetails,
  uniqueGUID,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Spinner, Stack } from "@fluentui/react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { OciAdbLink } from "../../components/OciLinks/OciAdbLink";
import { Fields as TagDetailsFields, TagDetails, TagDetailsComponent } from "../../components/TagDetails/TagDetails";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  DetailsPanelId,
  InlineFormId,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds, SvgIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { AdbsDatabase, AdbsDatabaseBaseOpenModeEnum } from "../../gen/clients/mchub-azure-api-client-adb";
import { buildId } from "../../helpers/idHelper";
import {
  AdbWorkloadType,
  defaultAdbsUsername,
  getOpenModeStr,
  getStatusInfo,
  getTitleSuffix,
  getWorkloadTypeDisplayName,
  ResourceStatus,
  TagsInfoType,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAppAuthContext } from "../../hooks/useAppAuthContext";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { AzureSubscriptionSummaryExt, useSubscriptions } from "../../hooks/useSubscriptions";
import { AdbsChangePasswordProps, newAdbsChangePassword } from "../../operations/Adbs/AdbsChangePassword";
import { AdbsDeleteProps, newAdbsDelete } from "../../operations/Adbs/AdbsDelete";
import { AdbsEditDbNameProps, newAdbsEditDbName } from "../../operations/Adbs/AdbsEditDbName";
import { AdbsEditTagsProps, newAdbsEditTags } from "../../operations/Adbs/AdbsEditTags";
import { AdbsRestartProps, newAdbsRestart } from "../../operations/Adbs/AdbsRestart";
import { AdbsRestoreProps, newAdbsRestore } from "../../operations/Adbs/AdbsRestore";
import { AdbsStartProps, newAdbsStart } from "../../operations/Adbs/AdbsStart";
import { AdbsStopProps, newAdbsStop } from "../../operations/Adbs/AdbsStop";
import { AdbsUpdateTagsProps, newAdbsUpdateTags } from "../../operations/Adbs/AdbsUpdateTags";
import { AdbsUpgradeProps, newAdbsUpgrade } from "../../operations/Adbs/AdbsUpgrade";
import { azurePortalUrl, getAzureLocationName, getOciRegion } from "../../utils";
import { AdbsBackupsList } from "./AdbsBackupsList";
import { AdbsConfigurationDetails } from "./AdbsConfigurationDetails";
import { AdbsConnectDetails } from "./AdbsConnectDetails";
import { AdbsNetworkingDetails } from "./AdbsNetworkingDetails";

export enum ActionIds {
  // overview actions
  LaunchApex = "launch-apex",
  LaunchDbActions = "launch-db-actions",
  Start = "start",
  Stop = "stop",
  Refresh = "refresh",
  Restart = "restart",
  Delete = "delete",
  LaunchMetrics = "launch-metrics",
  LaunchDashboard = "launch-dashboard",
  Restore = "restore",
  // meta item actions
  EditDbName = "edit-db-name",
  EditPassword = "edit-password",
  EditTags = "edit-tags",
  Upgrade = "upgrade",
}

export enum ActionBarTestIds {
  LaunchApex = "launch-apex",
  LaunchDbActions = "launch-db-actions",
  Start = "start",
  Stop = "stop",
  Refresh = "refresh",
  Restart = "restart",
  Delete = "delete",
  LaunchMetrics = "launch-metrics",
  LaunchDashboard = "launch-dashboard",
  Restore = "restore",
}

export enum MetaItemActionTestIds {
  EditDbName = "edit-db-name",
  EditPassword = "edit-password",
  EditTags = "edit-tags",
  Upgrade = "upgrade",
}

export enum MetaItemSectionTestIds {
  ResourceGroup = "mis-resource-group",
  LifecycleStatus = "mis-status",
  Location = "mis-loaction",
  Subscription = "mis-subscription",
  SubscriptionId = "mis-subscription-id",
  Tags = "mis-tags",
  OCIResource = "mis-oci-resource",
  DbName = "mis-db-name",
  Username = "mis-username",
  WorkloadType = "mis-workload-type",
  OpenMode = "mis-open-mode",
  Created = "mis-time-created"
}

export enum LinkTestIds {
  AddTags = "add-tags-link"
}

export enum InfoBlockTestIds {
  DisabledActions = "disabled-actions-info-block"
}

const registrationIds = PageRegistrationConfig[PageId.ADBS_DETAILS].map(config => config.key);

const getViewId = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.BACKUPS:
      return registrationIds[5];
    case DetailsPanelId.NETWORKING:
      return registrationIds[4];
    case DetailsPanelId.CONFIGURATION:
      return registrationIds[3];
    case DetailsPanelId.CONNECT:
      return registrationIds[2];
    case DetailsPanelId.TAGS:
      return registrationIds[1];
    case DetailsPanelId.OVERVIEW:
    default:
      return registrationIds[0];
  }
};

const getViewTitle = (panelId: string | undefined, instanceName: string): string | TitleDetails => {
  switch (panelId) {
    case DetailsPanelId.BACKUPS:
      return { primary: instanceName, secondary: Messages.labels.backups() };
    case DetailsPanelId.NETWORKING:
      return { primary: instanceName, secondary: Messages.labels.networking() };
    case DetailsPanelId.CONFIGURATION:
      return { primary: instanceName, secondary: Messages.labels.configuration() };
    case DetailsPanelId.CONNECT:
      return { primary: instanceName, secondary: Messages.labels.connect() };
    case DetailsPanelId.TAGS:
      return { primary: instanceName, secondary: Messages.labels.tags() };
    case DetailsPanelId.OVERVIEW:
    default:
      return instanceName;
  }
};

const getViewIcon = (panelId: string | undefined, isApex: boolean): string => {
  switch (panelId) {
    case DetailsPanelId.BACKUPS:
      return SvgIconIds.backupsSvg;
    case DetailsPanelId.NETWORKING:
      return SvgIconIds.networkingSvg;
    case DetailsPanelId.CONFIGURATION:
      return SvgIconIds.configurationSvg;
    case DetailsPanelId.CONNECT:
      return SvgIconIds.connectSvg;
    case DetailsPanelId.TAGS:
      return SvgIconIds.tagsSvg;
    case DetailsPanelId.OVERVIEW:
    default:
      return isApex ? SvgIconIds.apexSvg : SvgIconIds.adbSvg;
  }
};

export const AdbsDetailsPage = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location") || "";
  const type = searchParams.get("type");
  const { enableApex, enableAdbsPointInTimeRestore } = useFeatures();
  const [isApex, setIsApex] = React.useState<boolean>(enableApex && type?.toUpperCase() === AdbWorkloadType.APEX);

  const { trackActionClick, trackActionDiscard, trackFormSave } = useAnalytics();

  const
    {
      subscriptionId,
      resourceGroup: resGroupFromUrl,
      provider,
      resourceType,
      resourceName: resourceNameFromUrl,
      panelId,
    } = useParams();

  const resGroup = decodeURIComponent(resGroupFromUrl || "");
  const resourceName = decodeURIComponent(resourceNameFromUrl || "");

  const { back, navigateToSelf, navigateToPanel } = useNavigation(ConsoleContext);

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  const tagDetailsRef = React.useRef<TagDetailsComponent>();
  const setTagDetailsRef = (panel: TagDetailsComponent): void => {
    tagDetailsRef.current = panel;
  };

  // eslint-disable-next-line max-len
  const [tagTabKey, setTagTabKey] = React.useState<string>(uniqueGUID());
  const adbsId = buildId({
    subscriptionId: subscriptionId || "",
    resourceGroup: resGroup,
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName,
  });

  React.useEffect(() => {
    // In case page was navigated to directly by entering its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.ADBS_DETAILS].find(
      config => config.panelPath === panelId,
    );
    const urlQueryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const path = `${RESOURCE_ROUTE}/${adbsId}/${panelId}${urlQueryParams}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiVersion = MultiCloudDatabaseApiVersion;
  const { domainName } = useAppAuthContext();

  // Subscription
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();
  const subscription = !subscriptionLoading && subscriptions
    ? subscriptions.find(s => s.id === subscriptionId)
    : undefined;

  const { response: responseAdbs, loading: loadingAdbs, refresh: refreshAdbs, error: errorAdbs } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.getAdbsDatabase,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        apiVersion,
        databaseName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadAdbs(),
      },
      excludeErrorStatus: [404],
    },
  });

  const instance = responseAdbs?.data;
  const isLoading = loadingAdbs && !instance;

  React.useEffect(() => {
    setTagTabKey(uniqueGUID);
    if (enableApex && instance) {
      setIsApex(instance.dbWorkload === AdbWorkloadType.APEX);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  const onEditTags = (): void => {
    triggerEditTags({
      databaseId: instance?.id,
      defaultTags: tagsDefaultValue,
      location,
      onExecute: refreshAdbs,
      onCancel: () => trackActionDiscard(ActionIds.EditTags, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
    });
  };

  // Tags
  const tagsDefaultValue: TagsInfoType = Object.keys(instance?.freeformTags || {})
    .map((key: string) => ({ name: key, value: instance?.freeformTags?.[key] }));

  const onClose = (): void => back();
  const onMenuItemSelect = (id: string): void => navigateToPanel(id);

  const onApexLaunch = (): void => {
    const apexUrl = instance?.connectionUrls?.apexUrl;
    window.open(apexUrl, "_blank");
  };

  const onDatabaseActions = (): void => {
    let dbActionUrl = instance?.connectionUrls?.sqlDevWebUrl;
    if (dbActionUrl) {
      dbActionUrl = dbActionUrl.replace("/sql-developer", "/admin/_sdw");
    }
    window.open(dbActionUrl, "_blank");
  };

  const onMetrics = (): void => {
    const insightsId = (instance as AdbsDatabase)?.observabilityDetails?.azureApplicationInsightsId;
    const dbMetricsUrl = `${azurePortalUrl}#@${domainName}/resource/${insightsId}/metricsV2`;
    window.open(dbMetricsUrl, "_blank");
  };

  const onDashboard = (): void => {
    const dashboardId = (instance as AdbsDatabase)?.observabilityDetails?.azureDashboardId;
    const dbDashboardUrl = `${azurePortalUrl}#@${domainName}/dashboard/arm/${dashboardId}`;
    window.open(dbDashboardUrl, "_blank");
  };

  const { trigger: triggerRestoreAdbs } = useOperation<AdbsRestoreProps>(newAdbsRestore);
  const { trigger: triggerStartAdbs } = useOperation<AdbsStartProps>(newAdbsStart);
  const { trigger: triggerStopAdbs } = useOperation<AdbsStopProps>(newAdbsStop);
  const { trigger: triggerRestartAdbs } = useOperation<AdbsRestartProps>(newAdbsRestart);
  const { trigger: triggerDeleteAdbs } = useOperation<AdbsDeleteProps>(newAdbsDelete);
  const { trigger: triggerUpdateTags } = useOperation<AdbsUpdateTagsProps>(newAdbsUpdateTags);
  const { trigger: triggerEditTags } = useOperation<AdbsEditTagsProps>(newAdbsEditTags);
  const { trigger: triggerEditDbName } = useOperation<AdbsEditDbNameProps>(newAdbsEditDbName);
  const { trigger: triggerChangePassword } = useOperation<AdbsChangePasswordProps>(newAdbsChangePassword);
  const { trigger: triggerUpgrade } = useOperation<AdbsUpgradeProps>(newAdbsUpgrade);

  const { closePanels } = useSidePanel();

  const overviewActionItems: ActionType[] = [];
  overviewActionItems.push(
    {
      key: ActionIds.LaunchApex,
      testId: ActionBarTestIds.LaunchApex,
      text: Messages.actions.apexLaunch(),
      icon: MonochromeIconIds.OpenInNewWindow,
      disabled: !instance?.connectionUrls?.apexUrl,
      onClick: () => {
        trackActionClick(ActionIds.LaunchApex, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
        onApexLaunch();
      },
    },
    {
      key: ActionIds.LaunchDbActions,
      testId: ActionBarTestIds.LaunchDbActions,
      text: Messages.actions.databaseActions(),
      icon: MonochromeIconIds.OpenInNewWindow,
      disabled: !instance?.connectionUrls?.sqlDevWebUrl,
      onClick: () => {
        trackActionClick(ActionIds.LaunchDbActions, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
        onDatabaseActions();
      },
    },
    {
      key: ActionIds.LaunchMetrics,
      testId: ActionBarTestIds.LaunchMetrics,
      text: Messages.actions.metrics(),
      icon: MonochromeIconIds.OpenInNewWindow,
      disabled: !(instance as AdbsDatabase)?.observabilityDetails?.azureApplicationInsightsId,
      onClick: () => {
        trackActionClick(ActionIds.LaunchMetrics, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
        onMetrics();
      },
    },
    {
      key: ActionIds.LaunchDashboard,
      testId: ActionBarTestIds.LaunchDashboard,
      text: Messages.actions.dashboard(),
      icon: MonochromeIconIds.OpenInNewWindow,
      disabled: !(instance as AdbsDatabase)?.observabilityDetails?.azureDashboardId,
      onClick: () => {
        trackActionClick(ActionIds.LaunchDashboard, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
        onDashboard();
      },
    },
  );
  if (enableAdbsPointInTimeRestore) {
    overviewActionItems.push(
      {
        key: ActionIds.Restore,
        testId: ActionBarTestIds.Restore,
        text: Messages.actions.restore(),
        icon: MonochromeIconIds.Database,
        disabled: (instance?.status === "Provisioning" || instance?.status === "Terminating"),
        onClick: () => {
          trackActionClick(ActionIds.Restore, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
          triggerRestoreAdbs({
            databaseId: instance?.id,
            targetId: ActionIds.Restore,
            location,
            timeCreated: instance?.timeCreated,
            onExecute: refreshAdbs,
            onCancel: () => trackActionDiscard(ActionIds.Restore, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
          });
        },
      },
    );
  }
  overviewActionItems.push(
    {
      key: ActionIds.Start,
      testId: ActionBarTestIds.Start,
      text: Messages.actions.start(),
      icon: MonochromeIconIds.Play,
      title: Messages.labels.startAdbs(),
      description: Messages.hints.startAdbsConfirmation(instance?.name || ""),
      disabled: (instance?.status !== ResourceStatus.Stopped),
      onClick: () => trackActionClick(ActionIds.Start, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
      onConfirm: () => {
        triggerStartAdbs({
          databaseId: instance?.id,
          location,
          onExecute: refreshAdbs,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Start, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
    },
    {
      key: ActionIds.Stop,
      testId: ActionBarTestIds.Stop,
      text: Messages.actions.stop(),
      icon: MonochromeIconIds.Stop,
      title: Messages.labels.stopAdbs(),
      description: Messages.hints.stopAdbsConfirmation(instance?.name || ""),
      disabled: (instance?.status !== ResourceStatus.Active),
      onClick: () => trackActionClick(ActionIds.Stop, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
      onConfirm: () => {
        triggerStopAdbs({
          databaseId: instance?.id,
          location,
          onExecute: refreshAdbs,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Stop, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
    },
    {
      key: ActionIds.Restart,
      testId: ActionBarTestIds.Restart,
      text: Messages.actions.restart(),
      icon: MonochromeIconIds.Restart,
      title: Messages.labels.restartAdbs(),
      description: Messages.hints.restartAdbsConfirmation(instance?.name || ""),
      disabled: (instance?.status !== ResourceStatus.Active && instance?.status !== ResourceStatus.Failed),
      onClick: () => trackActionClick(ActionIds.Restart, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
      onConfirm: () => {
        triggerRestartAdbs({
          databaseId: instance?.id,
          location,
          onExecute: refreshAdbs,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Restart, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
        refreshAdbs();
      },
    },
    {
      key: ActionIds.Delete,
      testId: ActionBarTestIds.Delete,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.labels.deleteAdbs(),
      description: Messages.hints.deleteAdbsConfirmation(instance?.name || ""),
      onClick: () => trackActionClick(ActionIds.Delete, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
      onConfirm: () => {
        triggerDeleteAdbs({
          databaseId: instance?.id,
          location,
          onExecute: () => {
            refreshAdbs();
            back();
          },
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Delete, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
    },
  );

  const substitle = isApex ? Messages.detailApex.titles.long() : Messages.detailAdbs.titles.long();

  const essentialsMetaItemGroup: JSX.Element[] = [
    (
      <Stack key="adbs-essentials-left" tokens={{ childrenGap: metaItemLabelsGap }}>
        <LabelMetaItem testId={MetaItemSectionTestIds.ResourceGroup} label={Messages.labels.resourceGroup()}>
          <AzureResourceGroupLink resourceId={instance?.id as string} />
        </LabelMetaItem>
        <LabelMetaItem testId={MetaItemSectionTestIds.LifecycleStatus} label={Messages.labels.status()}>
          <Status
            iconPosition="right"
            label={stateT(instance?.status || "")}
            status={getResourceLifecycleStatus(instance?.status || "")}
            statusInfo={
              getStatusInfo(
                instance?.status || "",
                instance?.lastOperationStatus || "",
                instance?.lastOperationStatusDetails || "",
              )
            }
          />
        </LabelMetaItem>
        <LabelMetaItem testId={MetaItemSectionTestIds.Location} label={Messages.labels.location()}>
          {instance?.location ? getAzureLocationName(instance.location) : <NoValue />}
        </LabelMetaItem>
        <LabelMetaItem testId={MetaItemSectionTestIds.Subscription} label={Messages.labels.subscription()}>
          <AzureSubscriptionLink resourceId={instance?.id as string} subscriptionName={subscription?.name} />
        </LabelMetaItem>
        <LabelMetaItem testId={MetaItemSectionTestIds.SubscriptionId} label={Messages.labels.subscriptionId()}>
          {subscriptionId || <NoValue />}
        </LabelMetaItem>
      </Stack>
    ),
    (
      <Stack key="adbs-essentials-right" tokens={{ childrenGap: metaItemLabelsGap }}>
        <LabelMetaItem testId={MetaItemSectionTestIds.OCIResource} label={Messages.labels.ociResource()}>
          <OciAdbLink
            id={instance?.id as string}
            location={location}
            subscriptions={subscriptions as AzureSubscriptionSummaryExt[]}
            analytics={{ pageId: PageId.ADBS_DETAILS, panelId: DetailsPanelId.OVERVIEW }}
          />
        </LabelMetaItem>
        <LabelMetaItem
          label={Messages.labels.databaseName()}
          testId={MetaItemSectionTestIds.DbName}
          action={{
            id: ActionIds.EditDbName,
            testId: MetaItemActionTestIds.EditDbName,
            label: Messages.actions.edit(),
            onClick: () => {
              trackActionClick(ActionIds.EditDbName, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
              triggerEditDbName({
                databaseId: instance?.id,
                dbNameValue: instance?.dbName,
                targetId: ActionIds.EditDbName,
                location,
                onExecute: refreshAdbs,
                onCancel: () => trackActionDiscard(ActionIds.EditDbName, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
              });
            },
          }}
        >
          {instance?.dbName || <NoValue />}
        </LabelMetaItem>
        <LabelMetaItem
          label={Messages.labels.username()}
          testId={MetaItemSectionTestIds.Username}
          action={{
            id: ActionIds.EditPassword,
            testId: MetaItemActionTestIds.EditPassword,
            label: Messages.actions.changePassword(),
            onClick: () => {
              trackActionClick(ActionIds.EditPassword, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
              triggerChangePassword({
                databaseId: instance?.id,
                targetId: ActionIds.EditPassword,
                location,
                onExecute: refreshAdbs,
                onCancel: () => trackActionDiscard(
                  ActionIds.EditPassword,
                  PageId.ADBS_DETAILS,
                  DetailsPanelId.OVERVIEW,
                ),
              });
            },
          }}
        >
          {defaultAdbsUsername}
        </LabelMetaItem>
        {!isApex && (
          <LabelMetaItem
            testId={MetaItemSectionTestIds.WorkloadType}
            label={Messages.labels.workloadType()}
            action={instance?.dbWorkload === AdbWorkloadType.APEX || instance?.dbWorkload === AdbWorkloadType.JSON
              ? {
                id: ActionIds.Upgrade,
                testId: MetaItemActionTestIds.Upgrade,
                label: Messages.actions.convert(),
                onClick: () => {
                  trackActionClick(ActionIds.Upgrade, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
                  triggerUpgrade({
                    databaseId: instance?.id,
                    location,
                    onExecute: refreshAdbs,
                    onCancel: () => trackActionDiscard(ActionIds.Upgrade, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW),
                  });
                },
                disabled: instance?.status !== ResourceStatus.Active || !!errorAdbs,
              } : undefined}
          >
            {instance?.dbWorkload ? getWorkloadTypeDisplayName(instance.dbWorkload as AdbWorkloadType) : <NoValue />}
          </LabelMetaItem>
        )}
        <LabelMetaItem testId={MetaItemSectionTestIds.OpenMode} label={Messages.labels.openMode()}>
          {instance?.openMode ? getOpenModeStr(instance.openMode as AdbsDatabaseBaseOpenModeEnum) : <NoValue />}
        </LabelMetaItem>
        {instance?.timeCreated && (
          <LabelMetaItem testId={MetaItemSectionTestIds.Created} label={Messages.labels.created()}>
            <DateTimeDisplay date={instance.timeCreated} />
          </LabelMetaItem>
        )}
      </Stack>
    ),
  ];

  const getDetailViews = (): DetailView[] => {
    const detailViews = [
      {
        id: registrationIds[0],
        content: (
          <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
            <ActionBar
              actions={overviewActionItems}
              onActionClick={closePanels}
              infoBlocks={disabledActionsInfoBlocks()}
            />
            <MetaItemSection
              labelWidth={185}
              tags={{
                testId: MetaItemSectionTestIds.Tags,
                items: tagsDefaultValue?.length ? tagsDefaultValue : undefined,
                add: {
                  testId: LinkTestIds.AddTags,
                  onClick: () => {
                    trackActionClick(ActionIds.EditTags, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
                    onEditTags();
                  },
                },
                edit: {
                  id: ActionIds.EditTags,
                  testId: MetaItemActionTestIds.EditTags,
                  onClick: () => {
                    trackActionClick(ActionIds.EditTags, PageId.ADBS_DETAILS, DetailsPanelId.OVERVIEW);
                    onEditTags();
                  },
                },
              }}
            >
              {essentialsMetaItemGroup}
            </MetaItemSection>
          </Stack>
        ),
      },
      {
        id: registrationIds[1],
        content: (
          <TagDetails
            key={tagTabKey}
            resourceId={adbsId}
            tagsDefaultValue={tagsDefaultValue}
            onApply={(formValues: FormValues) => {
              trackFormSave(InlineFormId.TAGS, PageId.ADBS_DETAILS, DetailsPanelId.TAGS);
              const tags = getValue<TagsInfoType>(
                formValues,
                TagDetailsFields.Tags,
                InputFormGroup,
              ) ?? [];
              // *** Do not call refresh when updating tags on the tags tab to avoid resetting user input ***
              const updateTagsProps = {
                databaseId: instance?.id,
                tags,
                location,
                onExecute: () => tagDetailsRef.current?.toggleInProgress(false, panelRef.current),
              };
              tagDetailsRef.current?.toggleInProgress(true, panelRef.current);
              triggerUpdateTags(updateTagsProps);
            }}
            componentRef={setTagDetailsRef}
          />
        ),
      },
    ];
    if (instance?.dbWorkload !== AdbWorkloadType.APEX) {
      detailViews.push(
        {
          id: registrationIds[2],
          content: (
            <AdbsConnectDetails
              adbs={instance}
              refresh={refreshAdbs}
              location={location}
              resourceGroupName={resGroup}
              subscriptionId={subscriptionId || ""}
            />
          ),
        },
      );
    }
    detailViews.push(
      {
        id: registrationIds[3],
        content: (
          <AdbsConfigurationDetails
            adbs={instance}
            isApex={isApex}
            location={location}
            refresh={refreshAdbs}
            disableOperations={!!errorAdbs}
          />
        ),
      },
      {
        id: registrationIds[4],
        content: (
          <AdbsNetworkingDetails
            adbs={instance}
            location={location}
            refresh={refreshAdbs}
          />
        ),
      },
      {
        id: registrationIds[5],
        content: (
          <AdbsBackupsList
            database={instance}
            resGroup={resGroup}
            subscriptionId={subscriptionId || ""}
            location={location}
          />
        ),
      },
    );
    return detailViews;
  };

  const getGroupItems = (): GroupItem[] => {
    const items: GroupItem[] = [
      {
        items: [
          {
            icon: SvgIconIds.adbSvg,
            id: getDetailViews()[0].id,
            name: Messages.labels.overview(),
          },
          {
            icon: SvgIconIds.tagsSvg,
            id: getDetailViews()[1].id,
            name: Messages.labels.tags(),
          },
        ],
      },
    ];
    if (instance?.dbWorkload === AdbWorkloadType.APEX) {
      items.push({
        heading: Messages.labels.settings(),
        items: [
          {
            icon: SvgIconIds.configurationSvg,
            id: getDetailViews()[2].id,
            name: Messages.labels.configuration(),
          },
          {
            icon: SvgIconIds.networkingSvg,
            id: getDetailViews()[3].id,
            name: Messages.labels.networking(),
          },
        ],
      });
    } else {
      items.push({
        heading: Messages.labels.settings(),
        items: [
          {
            icon: SvgIconIds.connectSvg,
            id: getDetailViews()[2].id,
            name: Messages.labels.connect(),
          },
          {
            icon: SvgIconIds.configurationSvg,
            id: getDetailViews()[3].id,
            name: Messages.labels.configuration(),
          },
          {
            icon: SvgIconIds.networkingSvg,
            id: getDetailViews()[4].id,
            name: Messages.labels.networking(),
          },
        ],
      });
    }
    items.push({
      heading: Messages.labels.resources(),
      items: [
        {
          icon: SvgIconIds.backupsSvg,
          id: getDetailViews()[instance?.dbWorkload === AdbWorkloadType.APEX ? 4 : 5].id,
          name: Messages.labels.backups(),
        },
      ],
    });
    return items;
  };
  const disabledActionsInfoBlocks = (): InfoBlockProps[] => {
    const disabledActionInfo: InfoBlockProps[] = [];
    const disabledActions: string[] = [];
    if (instance?.status === ResourceStatus.Terminating
      || instance?.status === ResourceStatus.Updating) {
      disabledActions.push(Messages.actions.start());
      disabledActions.push(Messages.actions.stop());
      disabledActions.push(Messages.actions.restart());
     
      // eslint-disable-next-line max-len
      const disabledMessage = Messages.hints.adbsDisabledActionsAdbsStatus(disabledActions.join(", "), stateT(instance?.status || ""));
      disabledActionInfo.push(
        {
          testId: InfoBlockTestIds.DisabledActions,
          message: disabledMessage,
          messageType: InfoBlockStatus.INFO,
        },
      );
    } else if (instance?.status === ResourceStatus.Failed) {
      disabledActions.push(Messages.actions.start());
      disabledActions.push(Messages.actions.stop());
      // eslint-disable-next-line max-len
      const disabledMessage = Messages.hints.adbsDisabledActionsAdbsStatus(disabledActions.join(", "), stateT(instance?.status || ""));
      disabledActionInfo.push(
        {
          testId: InfoBlockTestIds.DisabledActions,
          message: disabledMessage,
          messageType: InfoBlockStatus.INFO,
        },
      );
    }
    return disabledActionInfo;
  };

  const renderedContent = (): JSX.Element => {
    if (isLoading) {
      return <Spinner label={Messages.common.loading()} />;
    } if (errorAdbs) {
      const isNotFound = errorAdbs?.status === 404;
      const errorTitle = isNotFound
        ? (isApex ? Messages.detailApex.loadError.notFound.title() : Messages.detailAdbs.loadError.notFound.title())
        : (isApex ? Messages.detailApex.loadError.general.title() : Messages.detailAdbs.loadError.general.title());
      const errorDetail = isNotFound
        ? (isApex ? Messages.detailApex.loadError.notFound.details() : Messages.detailAdbs.loadError.notFound.details())
        : (isApex ? Messages.detailApex.loadError.general.details() : Messages.detailAdbs.loadError.general.details());

      return (
        <ErrorViewPanel
          icon={SvgIconIds.adbSvg}
          title={resourceName}
          errorTitle={errorTitle}
          resourceId={adbsId}
          errorCode={errorAdbs?.status}
          details={[
            errorDetail,
            Messages.notifications.apiErrorMsg(errorAdbs?.body?.message),
          ]}
          isOpen
          onClose={onClose}
          favoriteAccessRegistration={{ id: adbsId, data: { location } }}
        />
      );
    }
    return (
      <DetailViewsPanel
        componentRef={setPanelRef}
        title={getViewTitle(panelId, instance?.name || "")}
        subTitle={substitle}
        icon={getViewIcon(panelId, isApex)}
        onClose={onClose}
        isOpen
        views={getDetailViews()}
        activeViewId={getViewId(panelId)}
        onMenuItemSelect={onMenuItemSelect}
        menu={getGroupItems()}
        status={instance?.status !== ResourceStatus.Active ? {
          status: getResourceLifecycleStatus(instance?.status || ""),
          tooltip: stateT(instance?.status || ""),
        } : undefined}
        favoriteAccessRegistration={{ id: instance?.id || "", data: { location } }}
      />
    );
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={instance?.name || (isApex ? Messages.detailApex.titles.short() : Messages.detailAdbs.titles.short())}
      titleSuffix={instance?.name ? getTitleSuffix(panelId || "") : undefined}
      recentAccessRegistration={{ id: instance?.id || "", data: { location } }}
    >
      {renderedContent()}
    </BookmarkablePage>
  );
};
