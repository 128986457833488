import {
  ActionType,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingDisplayNameLink,
  optimizedRetryOption,
  SortDirections,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { DetailsPanelId, InfoPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  VmdbDbHomeSummary,
  VmdbDbHomeSummaryCollection,
  VmdbDbSystem,
} from "../../gen/clients/mchub-azure-api-client-vmdb";
import { getStatusInfo, responseItemstoArray, statusSortComparator } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { newVmDbViewDbHome, VmDbViewDbHomeProps } from "../../operations/VmDb/VmDbViewDbHome";
import { getOciRegion } from "../../utils";

/**
 * TODO
 * This interface is implement to include the last status and details.
 * Api has not expose these two fields for now. When it will, we might remove this interface.
 */
interface VmdbDbHomeSummaryExt extends VmdbDbHomeSummary {
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
}

export interface VmDbSystemDbHomesListProps {
  dbSystem: VmdbDbSystem | undefined;
  resGroup: string;
  subscriptionId: string;
  location: string;
}

export enum ActionIds {
  Refresh = "refresh",
}

export enum ActionBarTestIds {
  Refresh = "refresh",
}

export enum ColumnIds {
  Name = "name",
  ResourceStatus = "status",
  DbVersion = "dbVersion",
  DbHomeLocation = "dbHomeLocation",
}

export enum ColumnTestIds {
  Name = "name",
  ResourceStatus = "status",
  DbVersion = "dbVersion",
  DbHomeLocation = "dbHomeLocation",
}

export const VmDbSystemDbHomesList = (
  { dbSystem, resGroup, subscriptionId, location }: VmDbSystemDbHomesListProps,
): JSX.Element => {
  const { trackActionClick, trackLinkNavigate } = useAnalytics();

  const { locale } = React.useContext<Settings>(SettingsContext);

  const { subscriptions } = useSubscriptions();

  const { response, loading, refresh } = useQueryCall({
    wait: !(dbSystem?.id && location),
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbDbHomes,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        dbSystemId: dbSystem?.id,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDatabaseHomes(),
      },
    },
  });

  const vmDbHomeItems = (response
    && responseItemstoArray<VmdbDbHomeSummaryCollection, VmdbDbHomeSummary>(response)) || [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = React.useMemo(() => vmDbHomeItems, [JSON.stringify(vmDbHomeItems)]);

  const dbHomeActions: ActionType[] = [
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.DATABASEHOMES);
        refresh();
      },
    },
  ];

  const { trigger: triggerViewDbHome } = useOperation<VmDbViewDbHomeProps>(newVmDbViewDbHome);

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.name}
          navigation={{
            onClick: () => {
              trackLinkNavigate(InfoPanelId.DBHOME, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.DATABASEHOMES);
              triggerViewDbHome({
                dbHomeId: value.id,
                subscriptions: subscriptions || [],
                location,
              });
            },
          }}
        />
      ),
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: VmdbDbHomeSummaryExt,
        b: VmdbDbHomeSummaryExt,
      ) => statusSortComparator<VmdbDbHomeSummaryExt>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.DbVersion,
      testId: ColumnTestIds.DbVersion,
      name: Messages.labels.databaseVersion(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
    {
      itemProp: ColumnIds.DbHomeLocation,
      testId: ColumnTestIds.DbHomeLocation,
      name: Messages.labels.databaseHomeLocation(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
  ];

  const getStatus = (value: VmdbDbHomeSummaryExt): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
        )
      }
      hideClipboardCopy
    />
  );

  return (
    <Listing
      testId={ListingTestIds.VmDbSystemDbHomes}
      items={items || []}
      emptyList={{ title: Messages.common.noResults() }}
      listColumns={columns}
      actionBarItems={dbHomeActions}
      isLoading={loading}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Name,
      }}
    />
  );
};
