import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent, SelectOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { Fields, MysqlBackupEditPanel } from "../../components/MysqlManagementEditPanel/MysqlBackupEditPanel";
import {
  MdsBackupPolicy,
  MdsDbSystemPitrPolicy,
  UpdateMdsBackupPolicyDetails,
} from "../../gen/clients/mchub-azure-api-client-mds";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_UPDATE_POLL_DELAY,
  MDS_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface MysqlEditBackupConfigProps extends OperationProps {
  databaseId: string | undefined;
  backupPolicy: MdsBackupPolicy | undefined;
}

const MysqlEditBackupConfig = (
  { location, databaseId, backupPolicy, onExecute, onCancel }: MysqlEditBackupConfigProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resourceGroupName = decodeURIComponent(resourceGroup || "");
  const dbSystemName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeMdsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.updateMdsDbSystem },
  );
  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const buildPitrPolicy = (formValues: FormValues): MdsDbSystemPitrPolicy => {
    const pitr: MdsDbSystemPitrPolicy = { isEnabled: getValue<boolean>(formValues, Fields.PitrPolicy, InputFormGroup) };
    return pitr;
  };

  const buildDbBackupConfig = (formValues: FormValues): UpdateMdsBackupPolicyDetails => {
    const isEnabled = getValue<boolean>(formValues, Fields.AutoBackupEnabled, InputFormGroup);
    const startTime = getValue<SelectOption[]>(formValues, Fields.WindowStartTime, InputFormGroup)?.[0].id;
    const retention = getValue<string>(formValues, Fields.RetentionInDays, InputFormGroup);
    const retentionInDays = retention ? parseInt(retention, 10) : undefined;

    const backupDetails: UpdateMdsBackupPolicyDetails = {
      isEnabled,
      windowStartTime: isEnabled ? startTime : undefined,
      retentionInDays: isEnabled ? retentionInDays : undefined,
      pitrPolicy: isEnabled ? buildPitrPolicy(formValues) : undefined,
    };
    return backupDetails;
  };

  const onUpdate = (formValues: FormValues): void => {
    const autoBackupEnabled = getValue<boolean>(formValues, Fields.AutoBackupEnabled, InputFormGroup);
    // TODO:
    //  - When the form validator is implemented for the InputFormSidePanel
    //  - Use form validator instead and remove this 'validation'
    if (autoBackupEnabled) {
      const startTime = getValue<SelectOption[]>(formValues, Fields.WindowStartTime, InputFormGroup);
      const retention = getValue<string>(formValues, Fields.RetentionInDays, InputFormGroup);
      const errors: string[] = [];
      if (!startTime?.length) {
        errors.push(Messages.validation.required());
      }
      if (!retention) {
        errors.push(Messages.validation.required());
      }
      if (errors.length > 0) {
        return;
      }
    }

    const backupPolicyConfig = buildDbBackupConfig(formValues);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateMysqlBackup(),
          message: Messages.notifications.inProgress.messages.updateMysqlBackup(dbSystemName),
        },
        success: {
          title: Messages.notifications.success.titles.updateMysqlBackup(),
          message: Messages.notifications.success.messages.updateMysqlBackup(dbSystemName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateMysqlBackup(),
          message: Messages.notifications.failure.messages.updateMysqlBackup(dbSystemName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            dbSystemName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_UPDATED_CHECK,
          delay: MDS_UPDATE_POLL_DELAY,
          interval: MDS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeMdsUpdate({
      subscriptionId,
      resourceGroupName,
      dbSystemName,
      updateMdsDbSystemDetails: { backupPolicy: backupPolicyConfig },
    }, invokeOptions);
  };

  return (
    <MysqlBackupEditPanel
      dbBackup={backupPolicy}
      onSubmit={onUpdate}
      onClose={onCancel}
      componentRef={setSidePanelRef}
    />
  );
};

export const newMysqlEditBackupConfig = (
  props: MysqlEditBackupConfigProps,
): JSX.Element => (<MysqlEditBackupConfig {...props} />);
