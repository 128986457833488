import { FavoriteAccessItem, useFavoriteAccess } from "o4a-react";
import * as React from "react";
import {
  FavoriteAddResourcesPanel,
  FavoriteResourceItem,
} from "../../components/FavoriteAddResourcesPanel/FavoriteAddResourcesPanel";
import { OperationBaseProps } from "../../console/OperationsContext";

export type FavoriteAddResourcesProps = OperationBaseProps;

const FavoriteAddResources = ({
  onCancel,
  onExecute,
}: FavoriteAddResourcesProps): JSX.Element => {
  const { setAsFavorite } = useFavoriteAccess();

  const onSubmit = (selectedItems: FavoriteResourceItem[]): void => {
    selectedItems.forEach(item => setAsFavorite({
      id: item.id,
      data: { location: item.location },
    } as FavoriteAccessItem));
    onExecute?.();
  };

  return (
    <FavoriteAddResourcesPanel
      onSubmit={onSubmit}
      onClose={onCancel}
    />
  );
};

export const newFavoriteAddResources = (props: FavoriteAddResourcesProps): JSX.Element => (
  <FavoriteAddResources {...props} />
);
