import {
  AnchoredPanel,
  AnchoredPanelType,
  DateTimeDisplay,
  LabelMetaItem,
  MetaItemGroupsLayout,
  NoValue,
  stateT,
} from "o4a-react";
import * as React from "react";
import { mergeStyleSets, Stack, Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { metaItemLabelsGap } from "../../constants/uiConstants";
import {
  ContactList,
  IncidentProblemTypeEnum,
  SubscriptionInformation,
  Ticket,
} from "../../gen/clients/mchub-azure-api-client";
import { getIncidentProblemType, getIncidentSeverity, incidentStatusDetailsT } from "../../helpers/resourceHelper";
import { useSubscriptions } from "../../hooks/useSubscriptions";

export interface SupportViewAllValues {
  contactList: ContactList | undefined,
  ticket: Ticket | undefined;
  problemType: IncidentProblemTypeEnum | undefined;
  subscriptionInformation: SubscriptionInformation | undefined;
}

export interface SupportViewAllPanelProps {
  title: string;
  supportViewAllValues: SupportViewAllValues;
  onClose: () => void;
}

const classNames = mergeStyleSets({
  contactTextContainer: {
    display: "flex",
    fontSize: "13px",
    fontWeight: 400,
    paddingTop: "5px",
  },
  fieldTextContainer: { paddingTop: "12px" },
  metaSectionContainer: { marginTop: "32px" },
  metaSectionTitle: {
    fontSize: "14px",
    fontWeight: 600,
    color: "rgb(50, 49, 48)",
    paddingBottom: "10px",
  },
});

export enum MetaItemSectionTestIds {
  Severity = "severity",
  Email = "email",
  Name = "name",
  ProblemType = "problemType",
  Subscription = "subscription",
  Summary = "summary",
  Status = "status",
  SupportRequestId = "supportRequestId",
  Description = "description"
}

export const SupportViewAllPanel = (
  { title, supportViewAllValues, onClose }: SupportViewAllPanelProps,
): JSX.Element => {
  const { subscriptions } = useSubscriptions();

  const getContactNames = (): JSX.Element => {
    const contactNames: JSX.Element[] = [];
    supportViewAllValues.contactList?.contactList.forEach(contact => {
      if (contact.contactName) {
        contactNames.push(
          <Text className={classNames.contactTextContainer} key={contact.contactName}>
            {contact.contactName}
          </Text>,
        );
      }
    });
    return (<div>{contactNames}</div>);
  };

  // const getContactPhones = (): JSX.Element => {
  //   const contactPhones: JSX.Element[] = [];
  //   incident?.contactList?.contactList?.forEach(contact => {
  //     if (contact.contactPhone) {
  //       contactPhones.push(
  //         <Text className={classNames.contactTextContainer} key={contact.contactPhone}>
  //           {contact.contactPhone}
  //         </Text>,
  //       );
  //     }
  //   });
  //   return (<div>{contactPhones}</div>);
  // };

  const getContactEmails = (): JSX.Element => {
    const contactEmails: JSX.Element[] = [];
    supportViewAllValues.contactList?.contactList?.forEach(contact => {
      if (contact.contactEmail) {
        contactEmails.push(
          <Text className={classNames.contactTextContainer} key={contact.contactEmail}>
            {contact.contactEmail}
          </Text>,
        );
      }
    });
    return (<div>{contactEmails}</div>);
  };

  // const getContactMeta = (): JSX.Element => {
  //   const contactElements: JSX.Element[] = [];
  //   incident?.contactList?.contactList?.forEach(contact => {
  //     if (contact.contactPhone) {
  //       contactElements.push(
  //         <Text className={classNames.contactTextContainer} key={contact.contactPhone}>
  //           {Messages.labels.personaPhone(contact.contactPhone || "")}
  //         </Text>,
  //       );
  //     }
  //     if (contact.contactEmail) {
  //       contactElements.push(
  //         <Text className={classNames.contactTextContainer} key={contact.contactEmail}>
  //           {Messages.labels.personaEmail(contact.contactEmail || "")}
  //         </Text>,
  //       );
  //     }
  //   });
  //   return (<div>{contactElements}</div>);
  // };
  const statusLabelMetaItem = (supportViewAllValues.ticket?.lifecycleState === "CLOSED"
  || !supportViewAllValues.ticket?.lifecycleDetails)
    ? stateT(supportViewAllValues.ticket?.lifecycleState || "")
    : supportViewAllValues.ticket?.lifecycleDetails
      ? incidentStatusDetailsT(supportViewAllValues.ticket?.lifecycleDetails)
      : <NoValue />;

  const overviewMetaItemGroup: JSX.Element[] = [
    (
      <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="overviewMetaItemGroup">
        <LabelMetaItem
          label={Messages.labels.severity()}
          testId={MetaItemSectionTestIds.Severity}
          hideSeparator
        >
          {supportViewAllValues.ticket?.severity
            ? getIncidentSeverity(supportViewAllValues.ticket.severity) : <NoValue />}
        </LabelMetaItem>
        <LabelMetaItem
          label={Messages.labels.status()}
          testId={MetaItemSectionTestIds.Status}
          hideSeparator
        >
          {statusLabelMetaItem}
        </LabelMetaItem>
        <LabelMetaItem
          label={Messages.labels.supportRequestId()}
          testId={MetaItemSectionTestIds.SupportRequestId}
          hideSeparator
        >
          {supportViewAllValues.ticket?.ticketNumber || <NoValue />}
        </LabelMetaItem>
        <LabelMetaItem label={Messages.labels.createdOn()} hideSeparator>
          <DateTimeDisplay date={supportViewAllValues.ticket?.timeCreated} />
        </LabelMetaItem>
        {/*       <LabelMetaItem label={Messages.labels.contactMethod()} hideSeparator>
          <Text>{getContactMeta()}</Text>
        </LabelMetaItem> */}
        <LabelMetaItem
          label={Messages.labels.summary()}
          testId={MetaItemSectionTestIds.Summary}
          hideSeparator
        >
          {supportViewAllValues.ticket?.title || <NoValue />}
        </LabelMetaItem>
        <LabelMetaItem
          label={Messages.labels.description()}
          testId={MetaItemSectionTestIds.Description}
          hideSeparator
        >
          {supportViewAllValues.ticket?.description || <NoValue />}
        </LabelMetaItem>
      </Stack>
    ),
  ];

  const detailsMetaItemGroup: JSX.Element[] = [
    (
      <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="detailsMetaItemGroup">
        <LabelMetaItem
          label={Messages.labels.subscription()}
          testId={MetaItemSectionTestIds.Subscription}
          hideSeparator
        >
          {subscriptions?.find(
            sub => sub.id === supportViewAllValues.subscriptionInformation?.subscriptionId,
          )?.name || <NoValue />}
        </LabelMetaItem>
        <LabelMetaItem
          label={Messages.labels.problemType()}
          testId={MetaItemSectionTestIds.ProblemType}
          hideSeparator
        >
          {supportViewAllValues.problemType
            ? getIncidentProblemType(supportViewAllValues.problemType) : <NoValue />}
        </LabelMetaItem>
      </Stack>
    ),
  ];
  const contactInfoMetaItemGroup: JSX.Element[] = [
    (
      <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="contactInfoMetaItemGroup">
        <LabelMetaItem
          label={Messages.labels.name()}
          testId={MetaItemSectionTestIds.Name}
          hideSeparator
        >
          {getContactNames()}
        </LabelMetaItem>
        <LabelMetaItem
          label={Messages.labels.email()}
          testId={MetaItemSectionTestIds.Email}
          hideSeparator
        >
          {getContactEmails()}
        </LabelMetaItem>
        {/*         <LabelMetaItem label={Messages.labels.phone()} hideSeparator>
          {getContactPhones()}
        </LabelMetaItem> */}
      </Stack>
    ),
  ];
  return (
    <AnchoredPanel
      title={title}
      onClose={onClose}
      isOpen
      type={AnchoredPanelType.MEDIUM}
    >
      <>
        <div className={classNames.metaSectionContainer}>
          <div className={classNames.metaSectionTitle}>
            {Messages.detailSupport.overview.titles.overview()}
          </div>
          <MetaItemGroupsLayout>
            {overviewMetaItemGroup}
          </MetaItemGroupsLayout>
        </div>
        <div className={classNames.metaSectionContainer}>
          <div className={classNames.metaSectionTitle}>
            {Messages.detailSupport.overview.titles.details()}
          </div>
          <MetaItemGroupsLayout>
            {detailsMetaItemGroup}
          </MetaItemGroupsLayout>
        </div>
        {supportViewAllValues.contactList?.contactList
          && supportViewAllValues.contactList.contactList.length > 0
          && (
            <div className={classNames.metaSectionContainer}>
              <div className={classNames.metaSectionTitle}>
                {Messages.detailSupport.overview.titles.contactInfo()}
              </div>
              <MetaItemGroupsLayout>
                {contactInfoMetaItemGroup}
              </MetaItemGroupsLayout>
            </div>
          )}
      </>
    </AnchoredPanel>
  );
};
