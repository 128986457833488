import {
  AnchoredPanelType,
  CheckBox,
  FormValues,
  InfoBlockLayout,
  InfoBlockStatus,
  InputFormSidePanel,
  InputFormSidePanelProps,
  IntegerInput,
  Select,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { MdsBackupPolicy } from "../../gen/clients/mchub-azure-api-client-mds";
import {
  mdsBackupPolicyMaxRetentionInDays,
  mdsBackupPolicyMinRetentionInDays,
  mysqlBackupSlots,
} from "../../helpers/resourceHelper";
import { utc } from "../../helpers/timeHelper";

export enum Fields {
  AutoBackupEnabled = "mysqlAutoBackupEnabled",
  RetentionInDays = "retentionInDays",
  WindowStartTime = "windowStartTime",
  PitrPolicy = "PitrPolicy",
}

export enum FieldTestIds {
  AutoBackupEnabled = "mysqlAutoBackupEnabled",
  RetentionInDays = "retentionInDays",
  WindowStartTime = "windowStartTime",
  PitrPolicy = "PitrPolicy",
}

// eslint-disable-next-line max-len
export interface MysqlBackupEditPanelProps extends Pick<InputFormSidePanelProps, "componentRef" | "onSubmit" | "onClose"> {
  dbBackup?: MdsBackupPolicy;
}

export const MysqlBackupEditPanel = ({
  dbBackup,
  onSubmit,
  onClose,
  componentRef,
}: MysqlBackupEditPanelProps): JSX.Element => {
  const [showBackupOptions, setShowBackupOptions] = React.useState<boolean>();
  const [defaultBackupSchedule, setDefaultBackupSchedule] = React.useState<string>();
  const [defaultRetentionPeriod, setDefaultRetentionPeriod] = React.useState<number>();
  const [defaultPitrPolicy, setDefaultPitrPolicy] = React.useState<boolean>();

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    const initFieldValues: FormValues = {};
    if (dbBackup) {
      const isAutoBackupEnabled = !!dbBackup.isEnabled;
      initFieldValues[Fields.AutoBackupEnabled] = isAutoBackupEnabled;
      setShowBackupOptions(isAutoBackupEnabled);

      if (isAutoBackupEnabled) {
        initFieldValues[Fields.RetentionInDays] = dbBackup.retentionInDays.toString();
        setDefaultRetentionPeriod(dbBackup.retentionInDays);

        initFieldValues[Fields.WindowStartTime] = [dbBackup.windowStartTime];
        setDefaultBackupSchedule(dbBackup.windowStartTime);

        const isPitrEnabled = !!dbBackup.pitrPolicy?.isEnabled;
        initFieldValues[Fields.PitrPolicy] = isPitrEnabled;
        setDefaultPitrPolicy(isPitrEnabled);
      }
    } else {
      initFieldValues[Fields.AutoBackupEnabled] = false;
      setShowBackupOptions(false);
    }
    setInitialValues(initFieldValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbBackup]);

  const info = {
    messageType: InfoBlockStatus.INFO,
    message: Messages.createMysql.managementTab.info.backupInfo(),
    infoLayout: InfoBlockLayout.Compact,
  };

  const onChangeShowBackUpOptions = (value: boolean): void => {
    setShowBackupOptions(value);
  };

  const windowStartOptions = mysqlBackupSlots.map(windowStartTime => ({
    id: windowStartTime,
    text: `${windowStartTime} ${utc}`,
  }));

  return (
    <InputFormSidePanel
      type={AnchoredPanelType.MEDIUM}
      title={Messages.createNewPanels.editMySqlBackupPlan.title()}
      onSubmit={onSubmit}
      onClose={onClose}
      componentRef={componentRef}
      testId={SidePanelTestIds.MysqlBackupEditPanel}
      initialValues={initialValues}
    >
      <CheckBox
        testId={FieldTestIds.AutoBackupEnabled}
        defaultValue={showBackupOptions}
        statusInfo={info}
        fieldName={Fields.AutoBackupEnabled}
        label={Messages.labels.enableAutomaticBackups()}
        onChange={onChangeShowBackUpOptions}
      />
      {showBackupOptions && (
      <>
        <IntegerInput
          testId={FieldTestIds.RetentionInDays}
          fieldName={Fields.RetentionInDays}
          label={Messages.labels.backupRetentionPeriod()}
          tooltip={Messages.hints.tooltipMySQLBackupRetentionPeriod()}
          defaultValue={defaultRetentionPeriod}
          min={mdsBackupPolicyMinRetentionInDays}
          max={mdsBackupPolicyMaxRetentionInDays}
          onChange={setDefaultRetentionPeriod}
          required
        />
        <Select
          testId={FieldTestIds.WindowStartTime}
          defaultValue={defaultBackupSchedule ? [defaultBackupSchedule] : undefined}
          required
          fieldName={Fields.WindowStartTime}
          label={Messages.labels.mySQLWindowStartTime()}
          options={windowStartOptions}
          onChange={setDefaultBackupSchedule}
          hideSearchBox
        />
        <CheckBox
          testId={FieldTestIds.PitrPolicy}
          defaultValue={defaultPitrPolicy}
          fieldName={Fields.PitrPolicy}
          label={Messages.labels.pitrPolicy()}
          tooltip={Messages.hints.enablePitrPolicy()}
          onChange={setDefaultPitrPolicy}
        />
      </>
      )}
    </InputFormSidePanel>
  );
};
