import { DateTimePicker, InputFormCallout, InputFormCalloutProps, SubmitButtonMode } from "o4a-react";
import * as React from "react";
import { Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { CalloutTestIds } from "../../constants/uiConstants";
import { getTimezoneName } from "../../helpers/jsMiscHelper.js";

export enum Fields {
  Timestamp = "timestamp",
}

export interface AdbsBackupRestorePanelProps extends
  Pick<InputFormCalloutProps, "targetId" | "onClose" | "onSubmit" | "componentRef"> {
  timeCreated?: Date;
}

export const AdbsBackupRestorePanel = ({
  targetId,
  componentRef,
  onClose,
  onSubmit,
  timeCreated,
}: AdbsBackupRestorePanelProps): JSX.Element => {
  const { locale } = React.useContext<Settings>(SettingsContext);
  const minDate = timeCreated ? new Date(timeCreated).getTime() : new Date(Date.now() - 60 * 24 * 3600 * 1000);
  const timezoneName = getTimezoneName(locale);
  return (
    <InputFormCallout
      testId={CalloutTestIds.AdbsBackupRestore}
      componentRef={componentRef}
      title={Messages.createNewPanels.restore.title()}
      targetId={targetId}
      onClose={onClose}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      primaryButtonText={Messages.actions.restore()}
    >
      <Text>
        {Messages.createNewPanels.restore.restoreDbToPoint()}
      </Text>
      <DateTimePicker
        label={Messages.labels.enterTimestamp(timezoneName)}
        locale={locale}
        fieldName={Fields.Timestamp}
        tooltip={Messages.hints.toolTipRestoreAdbsTime()}
        minDate={new Date(minDate)}
        maxDate={new Date(Date.now())}
      />
    </InputFormCallout>
  );
};
