import { CheckBox, FieldSet, InputWizardPanelComponent, IntegerInput, TextInput, useNavigation } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { HeatwaveShapeSelect } from "../../components/HeatwaveShapeSelect/HeatwaveShapeSelect";
import { MySQLVersionSelect } from "../../components/MySQLVersionSelect/MySQLVersionSelect";
import { SystemModelShapeSelect } from "../../components/SystemModelShapeSelect/SystemModelShapeSelect";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  heatwaveNodeMaxValue,
  heatwaveNodeMinValue,
  mdsDataStorageSizeDefaultValue,
  mdsDataStorageSizeMaxValue,
  mdsDataStorageSizeMinValue,
} from "../../helpers/resourceHelper";

export const GROUP_CONFIG = "config";

export enum Fields {
  Backup = "mdsDbSystemDeploymentDetails.source.backupId",
  DatabaseSystem = "databaseSystem",
  DataStorageSizeInGbs = "mdsDbSystemDeploymentDetails.dataStorageSizeInGBs",
  EnableHeatwaveCluster = "enableHeatwaveCluster",
  HeatwaveShape="heatwaveClusterDeploymentDetails.shapeName",
  MysqlVersion = "mdsDbSystemDeploymentDetails.mysqlVersion",
  NodeCount = "heatwaveClusterDeploymentDetails.clusterSize",
  SystemModelShape = "mdsDbSystemDeploymentDetails.shapeName",
}

export enum FieldTestIds {
  Backup = "backupId",
  DatabaseSystem = "databaseSystem",
  DataStorageSizeInGbs = "dataStorageSizeInGBs",
  EnableHeatwaveCluster = "enableHeatwaveCluster",
  HeatwaveShape="heatwaveShapeName",
  MysqlVersion = "mysqlVersion",
  NodeCount = "clusterSize",
  SystemModelShape = "shapeName",
}

export interface ConfigTabContentProps {
  inputWizardPanelRef: InputWizardPanelComponent;
  location: string;
  subscriptionId: string;
}
export const ConfigTabContent = (
  {
    inputWizardPanelRef,
    location,
    subscriptionId,
  }: ConfigTabContentProps,
): JSX.Element => {
  const { customData } = useNavigation(ConsoleContext);
  const [enableHeatwaveCluster, setEnableHeatwaveCluster] = React.useState(false);
  const onError = (): void => inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.general());
  const onMissingDependencies = (): void => inputWizardPanelRef.showErrorDialog(
    Messages.createCommon.dependencyWarnings.basicsIncomplete.message(),
    Messages.createCommon.dependencyWarnings.basicsIncomplete.title(),
  );

  return (
    <>
      {customData?.dbSystemName && (
      <FieldSet header={Messages.createMysql.configurationTab.sectionTitles.restoreDataFromBackup()}>
        <TextInput
          disabled
          testId={FieldTestIds.DatabaseSystem}
          fieldName={Fields.DatabaseSystem}
          label={Messages.labels.mySQLDatabaseSystem()}
          tooltip={Messages.hints.toolTipMySQLDatabaseSystem()}
          defaultValue={customData?.dbSystemName}
        />
        <TextInput
          disabled
          testId={FieldTestIds.Backup}
          fieldName={Fields.Backup}
          label={Messages.labels.mySQLBackup()}
          tooltip={Messages.hints.tooltipMySQLBackup()}
          defaultValue={customData?.backupName}
        />
      </FieldSet>
      )}
      <FieldSet header={Messages.createMysql.configurationTab.sectionTitles.infrastructureDetails()}>
        <SystemModelShapeSelect
          testId={FieldTestIds.SystemModelShape}
          fieldName={Fields.SystemModelShape}
          label={Messages.labels.systemModelShape()}
          required
          subscriptionId={subscriptionId}
          location={location}
          tooltip={Messages.hints.selectASystemModelShape()}
          onError={onError}
          onMissingDependencies={onMissingDependencies}
        />
        <IntegerInput
          testId={FieldTestIds.DataStorageSizeInGbs}
          min={customData?.backupDataStorageSizeInGBs || mdsDataStorageSizeMinValue}
          max={mdsDataStorageSizeMaxValue}
          defaultValue={customData?.backupDataStorageSizeInGBs || mdsDataStorageSizeDefaultValue}
          required
          tooltip={Messages.hints.toolTipMySQLDataStorageSize()}
          label={Messages.labels.dataStorageSize()}
          fieldName={Fields.DataStorageSizeInGbs}
        />
        <MySQLVersionSelect
          testId={FieldTestIds.MysqlVersion}
          fieldName={Fields.MysqlVersion}
          label={Messages.labels.mySQLVersion()}
          subscriptionId={subscriptionId}
          location={location}
          tooltip={Messages.hints.selectAMySQLVersion()}
          onError={onError}
          onMissingDependencies={onMissingDependencies}
          defaultValue={customData?.backupMysqlVersion}
        />
      </FieldSet>
      <FieldSet header={Messages.createMysql.configurationTab.sectionTitles.heatwaveDetails()}>
        <CheckBox
          testId={FieldTestIds.EnableHeatwaveCluster}
          defaultValue={enableHeatwaveCluster}
          fieldName={Fields.EnableHeatwaveCluster}
          label={Messages.labels.mySQLEnableHeatwaveCluster()}
          onChange={setEnableHeatwaveCluster}
        />
        {
        enableHeatwaveCluster && (
        <>
          <IntegerInput
            testId={FieldTestIds.NodeCount}
            defaultValue={heatwaveNodeMinValue}
            min={heatwaveNodeMinValue}
            max={heatwaveNodeMaxValue}
            required
            label={Messages.labels.nodeCount()}
            fieldName={Fields.NodeCount}
          />
          <HeatwaveShapeSelect
            testId={FieldTestIds.HeatwaveShape}
            fieldName={Fields.HeatwaveShape}
            label={Messages.labels.mySQLHeatwaveShape()}
            required
            subscriptionId={subscriptionId}
            location={location}
            tooltip={Messages.hints.selectAHeatwaveShape()}
            onError={onError}
            onMissingDependencies={onMissingDependencies}
          />
        </>
        )
        }
      </FieldSet>
    </>
  );
};
