import { CalloutComponent, FormValues, getValue, InputFormGroup, RadioGroupOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { BackupRestorePanel, Fields as RestoreFields } from "../../components/BackupRestorePanel/BackupRestorePanel";
import { RestoreDatabaseDetails } from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import { BackupRestoreType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXADB_CDB_UPDATE_POLL_DELAY,
  EXADB_CDB_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface ExaDbRestoreCdbProps extends OperationActionProps {
  databaseId: string;
  timeCreated?: Date | undefined;
}

const ExaDbRestoreCdb = (
  { targetId, location, databaseId, onExecute, onCancel, timeCreated }: ExaDbRestoreCdbProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const databaseName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeRestore } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.restoreDatabase },
  );
  const [restoreCalloutRef, setRestoreCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const onRestore = (formValues: FormValues): void => {
    const restoreType = getValue<RadioGroupOption>(formValues, RestoreFields.Type, InputFormGroup)?.id;
    const restoreTimestamp = getValue<number>(formValues, RestoreFields.Timestamp, InputFormGroup) as number;
    const restoreSCN = getValue<string>(formValues, RestoreFields.SCN, InputFormGroup);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: restoreCalloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.restore(),
          message: Messages.notifications.inProgress.messages.restore(databaseName),
        },
        success: {
          title: Messages.notifications.success.titles.restore(),
          message: Messages.notifications.success.messages.restore(databaseName),
        },
        failure: {
          title: Messages.notifications.failure.titles.restore(),
          message: Messages.notifications.failure.messages.restore(databaseName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXADB_CDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.EXADB_UPDATED_CHECK_CDB,
          delay: EXADB_CDB_UPDATE_POLL_DELAY,
          interval: EXADB_CDB_UPDATE_POLL_INTERVAL,
        },
      },
    };

    invokeRestore({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName,
      restoreDatabaseDetails: {
        latest: restoreType === BackupRestoreType.LATEST ? true : undefined,
        timestamp: restoreType === BackupRestoreType.TIMESTAMP ? new Date(restoreTimestamp) : undefined,
        databaseSCN: restoreType === BackupRestoreType.SCN ? restoreSCN : undefined,
      } as RestoreDatabaseDetails,
    }, invokeOptions);
  };

  return (
    <BackupRestorePanel
      componentRef={setRestoreCalloutRef}
      targetId={targetId}
      onSubmit={onRestore}
      onClose={onCancel}
      timeCreated={timeCreated}
    />
  );
};

export const newExaDbRestoreCdb = (
  props: ExaDbRestoreCdbProps,
): JSX.Element => (<ExaDbRestoreCdb {...props} />);
