import React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { CloudExadataInfrastructure } from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import { getTagsMap, TagsInfoType } from "../../helpers/resourceHelper";
import { MutationCallOptions, useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXAINFRA_EDIT_POLL_DELAY,
  EXAINFRA_EDIT_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface ExaInfraUpdateTagsProps
extends OperationConfirmationProps {
  exaInfraId: string;
  tags: TagsInfoType;
}

const ExaInfraUpdateTags = ({
  location,
  exaInfraId,
  tags,
  onExecute,
}: ExaInfraUpdateTagsProps): JSX.Element => {
  const { invokeCall: invokeTagUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.updateCloudExadataInfrastructure },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(exaInfraId);
    const resourceGroupName = decodeURIComponent(resourceGroup);
    const cloudExadataInfrastructureName = decodeURIComponent(resourceName);

    const freeformTags = getTagsMap(tags);

    const invokeOptions: MutationCallOptions<CloudExadataInfrastructure> = {
      onSuccess: onExecute,
      onFailure: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.applyTags(),
          message: Messages.notifications.inProgress.messages.applyTags(cloudExadataInfrastructureName),
        },
        success: {
          title: Messages.notifications.success.titles.applyTags(),
          message: Messages.notifications.success.messages.applyTags(cloudExadataInfrastructureName),
        },
        failure: {
          title: Messages.notifications.failure.titles.applyTags(),
          message: Messages.notifications.failure.messages.applyTags(cloudExadataInfrastructureName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXAINFRA_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            cloudExadataInfrastructureName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.EXAINFRA_EDIT_CHECK,
          delay: EXAINFRA_EDIT_POLL_DELAY,
          interval: EXAINFRA_EDIT_POLL_INTERVAL,
        },
      },
    };
    invokeTagUpdate({
      subscriptionId,
      resourceGroupName,
      cloudExadataInfrastructureName,
      updateCloudExadataInfrastructureDetails: { freeformTags },
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newExaInfraUpdateTags = (props: ExaInfraUpdateTagsProps): JSX.Element => (
  <ExaInfraUpdateTags {...props} />
);
