import {
  BookmarkablePage,
  FavoriteAccessItem,
  FormattedString,
  ItemStyle,
  LandingPagePanel,
  ListingColumn,
  ListingDisplayNameLink,
  OverviewContentSection,
  OverviewGettingStartedSection,
  OverviewInfoBlockButton,
  OverviewInfoBlockSection,
  OverviewInfoBlockStatus,
  OverviewRecentViewSection,
  OverviewTemplate,
  RecentAccessItem,
  SortDirections,
  useFavoriteAccess,
  useNavigation,
  useRecentAccess,
} from "o4a-react";
import * as React from "react";
import { Link, Spinner } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { PromoAlertModal } from "../../components/PromoAlertModal/PromoAlertModal";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  gettingStartedADB,
  gettingStartedExadata,
  gettingStartedMySQL,
  gettingStartedProvisioning,
  gettingStartedVMDB,
} from "../../constants/docConstants";
import {
  ADB_LIST_HUB_ROUTE,
  APEX_LIST_ROUTE,
  BILLING_ROUTE,
  CLOUDLINK_LIST_ROUTE,
  CREATE_HUB_ROUTE,
  DEPLOYMENT_LIST_ROUTE,
  DetailsPanelId,
  EXADATA_LIST_HUB_ROUTE,
  FAVORITE_RESOURCES_PATH,
  HOME_ROUTE,
  InfoPanelId,
  MCVCN_LIST_ROUTE,
  MYSQL_LIST_ROUTE,
  PageId,
  PageRegistrationConfig,
  RECENT_RESOURCES_PATH,
  RESOURCE_ROUTE,
  SUPPORT_ROUTE,
  VMDB_LIST_HUB_ROUTE,
} from "../../constants/pluginConstants";
import { maxNumberOfFavoriteItems, maxNumberOfRecentItems, SvgIconIds } from "../../constants/uiConstants";
import { IdResourceType, parseId } from "../../helpers/idHelper";
import {
  getResourceDisplayType,
  getResourceIconName,
  getResourcePageId,
  getTrialAccountTypeMessage,
} from "../../helpers/resourceHelper";
import { timeFromNow } from "../../helpers/timeHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAppAuthContext, UserType } from "../../hooks/useAppAuthContext";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { ResourceBaseItem } from "../../models/CommonInterfaces";
import {
  CloudLinkListSubscriptionInstructionsProps,
  newCloudLinkListSubscriptionInstructions,
} from "../../operations/CloudLink/CloudLinkListSubscriptionInstructions";
import {
  CloudLinkViewAdminInstructionsProps,
  newCloudLinkViewAdminInstructions,
} from "../../operations/CloudLink/CloudLinkViewAdminInstructions";
import { getOCIConsoleUrl, getOciRegion } from "../../utils";

export enum ActionIds {
  UpgradeAccount = "upgrade-account",
}

export enum ColumnIds {
  DisplayName = "displayName",
  DisplayType = "displayType",
  LastAccessed = "lastAccessed",
}

export enum ColumnTestIds {
  DisplayName = "displayName",
  DisplayType = "displayType",
  LastAccessed = "lastAccessed",
}

export enum SectionsTestIds {
  GettingStarted = "getting-started",
  NewUserGettingStarted = "new-user-getting-started",
  ContentOCIServices = "oci-services",
  ContentNavigate = "navigate",
  Links = "overview-links",
}

export enum ContentOCIServicesItemsTestIds {
  CreateResource = "create-resource-item",
  AutonomousDatabases = "adb-item",
  ExaDatabase = "exadb-item",
  VmDatabaseHub = "vmdb-hub-item",
  MysqlDatabase = "mysql-item",
  Apex = "apex-item",
  Mcvcn = "mcvcn-item",
}

export enum ContentNavigateItemsTestIds {
  Deployments = "deployment-item",
  Billing = "bill-item",
  CloudLinks = "subscription-item",
  Support = "support-item",
}

export enum GettingStartedItemsTestIds {
  AssignRoles = "assign-roles",
  LinkSubscription = "link-subscription",
}

export enum NewUserGettingStartedItemsTestIds {
  AssignSubscriptionRole = "assign-subscription-role",
}

export enum InfoBlockButtonTestIds {
  UpgradeButton = "upgrade-button-info-block"
}

const registrationIds = PageRegistrationConfig[PageId.HOME].map(config => config.key);

export const HomePage = (): JSX.Element => {
  const filterState = React.useContext<FilterState>(FilterStateContext);

  React.useEffect(() => {
    document.title = Messages.common.home();
    filterState.setFilterStateValue({ filterValues: undefined, listId: undefined, defaultPills: undefined });
    filterState.setFilterTextValue(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { enableMcvcn, enableMds, enableApex } = useFeatures();

  const { trackActionClick, trackLinkNavigate } = useAnalytics();
  const { recentItems } = useRecentAccess();
  const { favoriteItems } = useFavoriteAccess();

  const { roles, userType } = useAppAuthContext();
  const { preferredSubscription, locale } = React.useContext<Settings>(SettingsContext);
  const { loading: subscriptionsLoading, subscriptions } = useSubscriptions();
  const preferredSubscriptionInfo = subscriptions?.find(sub => sub.id === preferredSubscription);
  const [isPromoAlertModalOpen, { setTrue: showPromoAlertModal, setFalse: hidePromoAlertModal }] = useBoolean(false);
  const noLinkedSubscriptions = userType === UserType.REGULAR_USER && subscriptions?.length === 0;

  const { navigateTo, navigateToSelf } = useNavigation(ConsoleContext);
  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(HOME_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let isPromoAccount = false;
  if (subscriptions) {
    for (let i = 0; i < subscriptions.length; i++) {
      if (subscriptions[i].ociTenancyPaymentModel === "FREE") {
        isPromoAccount = true;
      } else {
        isPromoAccount = false;
        break;
      }
    }
  }
  const onResourceCreate = (): void => {
    navigateTo(
      CREATE_HUB_ROUTE,
      PageRegistrationConfig[PageId.CREATE_A_RESOURCE][0].key,
    );
  };

  const onAutonomousDatabases = (): void => {
    navigateTo(
      `${ADB_LIST_HUB_ROUTE}/${PageRegistrationConfig[PageId.ADB_LIST_HUB][0].panelPath}`,
      PageRegistrationConfig[PageId.ADB_LIST_HUB][0].key,
    );
  };

  const onApex = (): void => {
    navigateTo(APEX_LIST_ROUTE, PageId.APEX_LIST);
  };

  const onExaDatabase = (): void => {
    if (isPromoAccount) {
      showPromoAlertModal();
    } else {
      navigateTo(
        `${EXADATA_LIST_HUB_ROUTE}/${PageRegistrationConfig[PageId.EXA_LIST_HUB][0].panelPath}`,
        PageRegistrationConfig[PageId.EXA_LIST_HUB][0].key,
      );
    }
  };

  const onVmDatabase = (): void => {
    navigateTo(
      `${VMDB_LIST_HUB_ROUTE}/${PageRegistrationConfig[PageId.VMDB_LIST_HUB][0].panelPath}`,
      PageRegistrationConfig[PageId.VMDB_LIST_HUB][0].key,
    );
  };

  const onMysqlDatabase = (): void => {
    navigateTo(MYSQL_LIST_ROUTE, PageId.MYSQL_LIST);
  };

  const onMcvcn = (): void => {
    navigateTo(MCVCN_LIST_ROUTE, PageId.MCVCN_LIST);
  };

  const onDeployments = (): void => {
    navigateTo(DEPLOYMENT_LIST_ROUTE, PageId.DEPLOYMENT_LIST);
  };

  const onSupport = (): void => {
    navigateTo(SUPPORT_ROUTE, PageId.SUPPORT);
  };

  const onCloudLinks = (): void => {
    navigateTo(CLOUDLINK_LIST_ROUTE, PageId.CLOUDLINK_LIST);
  };

  const onODSARoles = (): void => {
    triggerCloudLinkViewAdminInstructionsPanel({});
  };

  const onSubscriptionRole = (): void => {
    triggerCloudLinkListSubscriptionInstructions({});
  };

  const onBilling = (): void => {
    navigateTo(BILLING_ROUTE, PageId.BILLING);
  };

  // eslint-disable-next-line max-len
  const { trigger: triggerCloudLinkListSubscriptionInstructions } = useOperation<CloudLinkListSubscriptionInstructionsProps>(newCloudLinkListSubscriptionInstructions);
  // eslint-disable-next-line max-len
  const { trigger: triggerCloudLinkViewAdminInstructionsPanel } = useOperation<CloudLinkViewAdminInstructionsProps>(newCloudLinkViewAdminInstructions);

  const gettingStartedSections: OverviewGettingStartedSection[] = [
    {
      testId: SectionsTestIds.GettingStarted,
      title: Messages.home.sections.gettingStarted(Messages.portalBrand.long()),
      items: [
        {
          image: <img src="/azure/odsaroles.png" alt="" />,
          title: Messages.home.gettingStarted.step1Title(Messages.portalBrand.short()),
          description: Messages.home.gettingStarted.step1Description(),
          buttonLabel: roles?.includes("cloudlink-administrator")
            || roles?.includes("odsa-multicloudlink-administrator")
            ? Messages.labels.completed()
            : Messages.actions.getStarted(),
          onClick: () => {
            trackLinkNavigate(InfoPanelId.INSTRUCTIONS_ADMIN_ROLES, PageId.HOME);
            onODSARoles();
          },
          testId: GettingStartedItemsTestIds.AssignRoles,
          disabled: roles?.includes("cloudlink-administrator")
            || roles?.includes("odsa-multicloudlink-administrator"),
        },
        {
          image: <img src="/azure/linksubscriptions.png" alt="" />,
          title: Messages.home.gettingStarted.step2Title(),
          description: Messages.home.gettingStarted.step2Description(),
          buttonLabel: Messages.actions.getStarted(),
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.CLOUDLINK_LIST }, PageId.HOME);
            onCloudLinks();
          },
          testId: GettingStartedItemsTestIds.LinkSubscription,
          disabled: false,
        },
      ],
    },
  ];

  const newUserGettingStarted: OverviewGettingStartedSection[] = [
    {
      testId: SectionsTestIds.NewUserGettingStarted,
      title: Messages.home.sections.gettingStarted(Messages.portalBrand.long()),
      items: [
        {
          image: <img src="/azure/odsaroles.png" alt="" />,
          title: Messages.home.gettingStartedNewUser.title(Messages.portalBrand.short()),
          description: (
            <>
              <FormattedString
                inputText={Messages.home.gettingStartedNewUser.description(Messages.portalBrand.short())}
              />
              <p />
              <FormattedString
                inputText={Messages.home.gettingStartedNewUser.importantNote(Messages.portalBrand.short())}
              />
            </>
          ),
          buttonLabel: Messages.actions.getStarted(),
          onClick: () => {
            trackLinkNavigate(InfoPanelId.INSTRUCTIONS_SUBSCRIPTION_ROLES, PageId.HOME);
            onSubscriptionRole();
          },
          testId: NewUserGettingStartedItemsTestIds.AssignSubscriptionRole,
        },
      ],
    },
  ];

  const recentItemsListColumns: ListingColumn[] = [
    {
      itemProp: ColumnIds.DisplayName,
      testId: ColumnTestIds.DisplayName,
      name: Messages.labels.name(),
      flexGrow: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.displayName}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[value.detailsPageId][0].panelPath}?location=${value.location}`,
            pageKey: PageRegistrationConfig[value.detailsPageId][0].key,
          }}
          iconName={value.iconName}
        />
      ),
    },
    {
      itemProp: ColumnIds.DisplayType,
      testId: ColumnTestIds.DisplayType,
      name: Messages.labels.type(),
      flexGrow: 1,
    },
    {
      itemProp: ColumnIds.LastAccessed,
      testId: ColumnTestIds.LastAccessed,
      name: Messages.labels.lastViewed(),
      flexGrow: 1,
      initialSortDirection: SortDirections.DESC,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <span>{timeFromNow(value.lastAccessed)}</span>,
    },
  ];

  const favoriteItemsListColumns: ListingColumn[] = [
    {
      itemProp: ColumnIds.DisplayName,
      testId: ColumnTestIds.DisplayName,
      name: Messages.labels.name(),
      flexGrow: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.displayName}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[value.detailsPageId][0].panelPath}?location=${value.location}`,
            pageKey: PageRegistrationConfig[value.detailsPageId][0].key,
          }}
          iconName={value.iconName}
        />
      ),
    },
    {
      itemProp: ColumnIds.DisplayType,
      testId: ColumnTestIds.DisplayType,
      name: Messages.labels.type(),
      flexGrow: 1,
    },
  ];

  const recentAccessItems = React.useMemo(() => {
    const recentResourceItems: ResourceBaseItem[] = [];
    recentItems.forEach((item: RecentAccessItem) => {
      const { resourceName, resourceType, provider: providerName } = parseId(item.id);
      if (recentResourceItems.length < maxNumberOfRecentItems) {
        recentResourceItems.push({
          id: item.id,
          displayName: resourceName,
          lastAccessed: item.lastAccessed,
          location: item.data?.location || "",
          displayType: getResourceDisplayType(providerName, resourceType as IdResourceType),
          iconName: getResourceIconName(providerName, resourceType as IdResourceType),
          detailsPageId: getResourcePageId(providerName, resourceType as IdResourceType),
        });
      }
    });
    return recentResourceItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(recentItems)]);

  const favItems = React.useMemo(() => {
    const favResourceItems: ResourceBaseItem[] = [];
    favoriteItems.forEach((item: FavoriteAccessItem) => {
      const { resourceName, resourceType, provider: providerName } = parseId(item.id);
      if (favResourceItems.length < maxNumberOfFavoriteItems) {
        favResourceItems.push({
          id: item.id,
          displayName: resourceName,
          location: item.data?.location || "",
          displayType: getResourceDisplayType(providerName, resourceType as IdResourceType),
          iconName: getResourceIconName(providerName, resourceType as IdResourceType),
          detailsPageId: getResourcePageId(providerName, resourceType as IdResourceType),
        });
      }
    });
    return favResourceItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(favoriteItems)]);

  const contentSections: OverviewContentSection | OverviewRecentViewSection<ResourceBaseItem>[] = [
    {
      testId: SectionsTestIds.ContentOCIServices,
      title: Messages.home.sections.oracleService(),
      itemStyle: ItemStyle.VERTICAL,
      circularNavigation: true,
      items: [
        {
          iconName: SvgIconIds.addSvg,
          title: Messages.actions.createAResource(),
          disabled: noLinkedSubscriptions,
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.CREATE_A_RESOURCE }, PageId.HOME);
            onResourceCreate();
          },
          testId: ContentOCIServicesItemsTestIds.CreateResource,
        },
        {
          iconName: SvgIconIds.adbSvg,
          title: Messages.topServices.autonomousDatabases(),
          disabled: noLinkedSubscriptions,
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.ADB_LIST_HUB, panelId: DetailsPanelId.ADBS_DATABASES }, PageId.HOME);
            onAutonomousDatabases();
          },
          testId: ContentOCIServicesItemsTestIds.AutonomousDatabases,
        },
        {
          iconName: SvgIconIds.exaSvg,
          title: Messages.topServices.exadata(),
          disabled: noLinkedSubscriptions,
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.EXA_LIST_HUB, panelId: DetailsPanelId.DATABASES }, PageId.HOME);
            onExaDatabase();
          },
          testId: ContentOCIServicesItemsTestIds.ExaDatabase,
        },
        {
          iconName: SvgIconIds.baseDbSvg,
          title: Messages.topServices.vmDatabase(),
          disabled: noLinkedSubscriptions,
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.VMDB_LIST_HUB, panelId: DetailsPanelId.DATABASES }, PageId.HOME);
            onVmDatabase();
          },
          testId: ContentOCIServicesItemsTestIds.VmDatabaseHub,
        },
        ...(enableMds ? [{
          iconName: SvgIconIds.mysqlSvg,
          title: Messages.topServices.mysqlDatabase(),
          disabled: noLinkedSubscriptions,
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.MYSQL_LIST }, PageId.HOME);
            onMysqlDatabase();
          },
          testId: ContentOCIServicesItemsTestIds.MysqlDatabase,
        }] : []),
        ...(enableApex ? [{
          iconName: SvgIconIds.apexSvg,
          title: Messages.topServices.apex(),
          disabled: noLinkedSubscriptions,
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.APEX_LIST }, PageId.HOME);
            onApex();
          },
          testId: ContentOCIServicesItemsTestIds.Apex,
        }] : []),
        ...(enableMcvcn ? [{
          iconName: SvgIconIds.networkSvg,
          title: Messages.topServices.mcvcn(),
          disabled: noLinkedSubscriptions,
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.MCVCN_LIST }, PageId.HOME);
            onMcvcn();
          },
          testId: ContentOCIServicesItemsTestIds.Mcvcn,
        }] : []),
      ],
    },
    {
      title: Messages.labels.resources(),
      content: [{
        title: Messages.labels.recent(),
        listContent: {
          items: recentAccessItems,
          listColumns: recentItemsListColumns,
          emptyList: { title: Messages.listRecent.emptyList.title() },
          sorting: { locale, initialSortedColumn: ColumnIds.LastAccessed },
          // eslint-disable-next-line react/no-unstable-nested-components
          onRenderFooter: (): JSX.Element => (recentAccessItems.length
            ? (
              <div style={{ marginTop: "8px" }}>
                <Link onClick={() => navigateTo(`/${RECENT_RESOURCES_PATH}`, PageId.RECENT_RESOURCES)}>
                  {Messages.actions.seeAll()}
                </Link>
              </div>
            )
            : <div />),
        },
      },
      {
        title: Messages.labels.favorite(),
        listContent: {
          items: favItems,
          listColumns: favoriteItemsListColumns,
          emptyList: { title: Messages.listFavorite.emptyList.title() },
          // eslint-disable-next-line react/no-unstable-nested-components
          onRenderFooter: (): JSX.Element => (favItems.length
            ? (
              <div style={{ marginTop: "8px" }}>
                <Link onClick={() => navigateTo(`/${FAVORITE_RESOURCES_PATH}`, PageId.FAVORITE_RESOURCES)}>
                  {Messages.actions.seeAll()}
                </Link>
              </div>
            )
            : <div />),
        },
      }],
    },
    {
      testId: SectionsTestIds.ContentNavigate,
      title: Messages.home.sections.navigate(),
      itemStyle: ItemStyle.HORIZONTAL,
      circularNavigation: true,
      items: [
        {
          iconName: SvgIconIds.deploymentSvg,
          title: Messages.topServices.deployments(),
          disabled: noLinkedSubscriptions,
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.DEPLOYMENT_LIST }, PageId.HOME);
            onDeployments();
          },
          testId: ContentNavigateItemsTestIds.Deployments,
        },
        {
          iconName: SvgIconIds.billingSvg,
          title: Messages.topServices.billing(),
          disabled: noLinkedSubscriptions,
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.BILLING }, PageId.HOME);
            onBilling();
          },
          testId: ContentNavigateItemsTestIds.Billing,
        },
        {
          iconName: SvgIconIds.subscriptionSvg,
          title: Messages.topServices.subscriptionManagement(),
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.CLOUDLINK_LIST }, PageId.HOME);
            onCloudLinks();
          },
          testId: ContentNavigateItemsTestIds.CloudLinks,
        },
        {
          iconName: SvgIconIds.supportSvg,
          title: Messages.topServices.support(),
          disabled: noLinkedSubscriptions,
          onClick: () => {
            trackLinkNavigate({ pageId: PageId.SUPPORT }, PageId.HOME);
            onSupport();
          },
          testId: ContentNavigateItemsTestIds.Support,
        },
      ],
    } as OverviewContentSection,
  ];

  const linkSections = [
    {
      testId: SectionsTestIds.Links,
      title: Messages.home.sections.usefulLinks(),
      items: [
        {
          text: Messages.home.links.gettingStartedADB(),
          url: gettingStartedADB,
        },
        {
          text: Messages.home.links.gettingStartedExadata(),
          url: gettingStartedExadata,
        },
        ...(enableMds ? [{
          text: Messages.home.links.gettingStartedMySQL(),
          url: gettingStartedMySQL,
        }] : []),
        {
          text: Messages.home.links.gettingStartedVMDB(),
          url: gettingStartedVMDB,
        },
        {
          text: Messages.home.links.gettingStartedProvisioning(Messages.portalBrand.short()),
          url: gettingStartedProvisioning,
        },
      ],
    },
  ];

  const onUpgrade = (): void => {
    const region = getOciRegion(preferredSubscriptionInfo?.primaryLocation || "");
    const ociConsoleUrl = getOCIConsoleUrl();
    const ociTenancyParam = preferredSubscriptionInfo?.ociTenancyName
      ? `&tenant=${preferredSubscriptionInfo?.ociTenancyName}` : "";
    const ociDomainParam = preferredSubscriptionInfo?.ociDomainName
      ? `&domain=${preferredSubscriptionInfo?.ociDomainName}` : "";
    const upgradeUrl = `${ociConsoleUrl}/invoices-and-orders/upgrade-and-payment`
      + `?region=${region}${ociTenancyParam}${ociDomainParam}`;
    window.open(upgradeUrl, "_blank");
  };

  const upgradeNowLink: OverviewInfoBlockButton = {
    testId: InfoBlockButtonTestIds.UpgradeButton,
    text: Messages.labels.upgradeNow(),
    onClick: () => {
      trackActionClick(ActionIds.UpgradeAccount, PageId.HOME);
      onUpgrade();
    },
  };

  const promoInfoBlock: OverviewInfoBlockSection = {
    testId: "promo-info-section",
    message: getTrialAccountTypeMessage(preferredSubscriptionInfo?.ociTenancyPaymentModel),
    messageType: OverviewInfoBlockStatus.INFO,
    actionButtons: [upgradeNowLink],
  };

  const noAccessToLinkedSubscriptionsInfoBlock: OverviewInfoBlockSection = {
    testId: "no-access-to-linked-subscriptions-info-section",
    message: <FormattedString
      inputText={Messages.home.banner.noAccessToLinkedSubscriptions(Messages.portalBrand.short())}
    />,
    messageType: OverviewInfoBlockStatus.ERROR,
    isMultiline: true,
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      title={Messages.common.home()}
    >
      <LandingPagePanel testId="overview">
        {subscriptionsLoading && <Spinner />}
        {!subscriptionsLoading && (
          <OverviewTemplate
            gettingStartedSections={userType === UserType.FIRST_TIME_USER
              ? gettingStartedSections
              : userType === UserType.NEW_USER
                ? newUserGettingStarted
                : undefined}
            contentSections={userType !== UserType.REGULAR_USER ? undefined : contentSections}
            linkSections={linkSections}
            initialFocus={{ sectionNdx: 0 }}
            infoBlock={noLinkedSubscriptions
              ? noAccessToLinkedSubscriptionsInfoBlock
              : isPromoAccount
                ? promoInfoBlock
                : undefined}
          />
        )}
        <PromoAlertModal
          isPromoAlertModalOpen={isPromoAlertModalOpen}
          hidePromoAlertModal={hidePromoAlertModal}
          onUpgrade={onUpgrade}
        />
      </LandingPagePanel>
    </BookmarkablePage>
  );
};
