import { FormValues } from "o4a-react";
import * as React from "react";
import { DbHomeCreateCallout } from "../../components/DbHomeCreateCallout/DbHomeCreateCallout";
import { getDbHomeTimeStamp } from "../../helpers/timeHelper";
import { OperationActionProps } from "../OperationTypes";

export interface ExaDbCreateHomeProps extends OperationActionProps {
  subscriptionId: string;
}

export const dbHomePrefix = "dbHome-";

export const getDefaultDatabaseHomeName = (): string => `${dbHomePrefix}${getDbHomeTimeStamp()}`;

const ExaDbCreateHome = ({
  location,
  subscriptionId,
  targetId,
  onCancel,
  onExecute,
}: ExaDbCreateHomeProps): JSX.Element => {
  const onSubmit = (formValues: FormValues): void => {
    onExecute?.(formValues);
  };

  return (
    <DbHomeCreateCallout
      subscriptionId={subscriptionId}
      location={location}
      namePlaceholder={getDefaultDatabaseHomeName()}
      targetId={targetId}
      onClose={onCancel}
      onSubmit={onSubmit}
    />
  );
};

export const newExaDbCreateHome = (props: ExaDbCreateHomeProps): JSX.Element => (
  <ExaDbCreateHome {...props} />
);
