import {
  AnchoredPanelType,
  InputFormSidePanel,
  InputFormSidePanelProps,
  SubmitButtonMode,
  TextInput,
  TextInputMultilineConfig,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { SidePanelTestIds } from "../../constants/uiConstants";

export const enum Fields {
  Comments = "comments",
}

export enum FieldsTestIds {
  Comments = "incident-comments"
}
export type SupportUpdatePanelProps = Pick<InputFormSidePanelProps,
  "componentRef" |
  "onClose" |
  "onSubmit" |
  "title" |
  "submitText"
>;

export const SupportUpdatePanel = ({
  title,
  componentRef,
  onSubmit,
  onClose,
  submitText,
}: SupportUpdatePanelProps): JSX.Element => (
  <InputFormSidePanel
    submitText={submitText}
    componentRef={componentRef}
    type={AnchoredPanelType.MEDIUM}
    title={title}
    submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
    onSubmit={onSubmit}
    onClose={onClose}
    testId={SidePanelTestIds.SupportUpdate}
  >
    <TextInput
      label={Messages.labels.comments()}
      fieldName={Fields.Comments}
      required
      multiline={{ rows: 10 } as TextInputMultilineConfig}
      testId={FieldsTestIds.Comments}
    />
  </InputFormSidePanel>
);
