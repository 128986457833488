import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_CDB_DELETE_POLL_DELAY,
  VMDB_CDB_DELETE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface VmDbDeleteCdbProps extends OperationConfirmationProps {
  dbSystemId: string | undefined;
  databaseId: string | undefined;
}

const VmDbDeleteCdb = (
  { location, dbSystemId, databaseId, onExecute }: VmDbDeleteCdbProps,
): JSX.Element => {
  // To delete the cdb we need to delete the db system as there is only a single
  // cdb in a dbsystem and deleting that is equivalent to deleting the db system.
  const { invokeCall: deleteCdb } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.deleteVmdbDbSystem },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(dbSystemId);
    const resGroup = decodeURIComponent(resourceGroup || "");
    const { resourceName: cdbName } = parseId(databaseId);

    const invokeCdbOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.deleteVMDb(),
          message: Messages.notifications.inProgress.messages.deleteVMDb(cdbName),
        },
        success: {
          title: Messages.notifications.success.titles.deleteVMDb(),
          message: Messages.notifications.success.messages.deleteVMDb(cdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.delete(),
          message: Messages.notifications.failure.messages.deleteVMDb(cdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_CDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: resourceName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_DELETED_CHECK_CDB,
          errorStatusOverrides: [404],
          delay: VMDB_CDB_DELETE_POLL_DELAY,
          interval: VMDB_CDB_DELETE_POLL_INTERVAL,

        },
      },
    };

    deleteCdb({
      subscriptionId,
      resourceGroupName: resGroup,
      dbSystemName: resourceName,
    }, invokeCdbOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newVmDbDeleteCdb = (
  props: VmDbDeleteCdbProps,
): JSX.Element => (<VmDbDeleteCdb {...props} />);
