import { PolledResponseCallback, PolledResponseResult, PollingAction } from "o4a-react";
import { ApiClientMethod, TimeInterval } from "savant-connector";
import apiClients from "../apiClients";
import {
  AzureCloudLink,
  Deployment,
  MdsBackup,
  MultiCloudVirtualNetwork,
  NetworkLink,
} from "../gen/clients/mchub-azure-api-client";
import { AdbsDatabase, AdbsDatabaseBackup, AdbsDatabaseWallet } from "../gen/clients/mchub-azure-api-client-adb";
import {
  Backup,
  CloudExadataInfrastructure,
  CloudVmCluster,
  Database,
  PluggableDatabase,
} from "../gen/clients/mchub-azure-api-client-exa";
import { HeatWaveCluster, MdsDbSystem } from "../gen/clients/mchub-azure-api-client-mds";
import {
  VmdbBackup,
  VmdbDatabase,
  VmdbDbSystem,
  VmdbPluggableDatabase,
} from "../gen/clients/mchub-azure-api-client-vmdb";
import { ResourceStatus } from "../helpers/resourceHelper";

export const EXADB_DEPLOYMENT_CREATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const EXADB_DEPLOYMENT_CREATE_POLL_INTERVAL = TimeInterval.xxl; // 10 mins
export const EXASYS_DEPLOYMENT_CREATE_POLL_DELAY = TimeInterval.lg; // 1 min
export const EXASYS_DEPLOYMENT_CREATE_POLL_INTERVAL = TimeInterval.xxl; // 10 mins
export const EXADB_CDB_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const EXADB_CDB_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const EXADB_PDB_CREATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const EXADB_PDB_CREATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const EXADB_PDB_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const EXADB_PDB_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const EXADB_CDB_UPDATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const EXADB_CDB_UPDATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const EXADB_PDB_UPDATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const EXADB_PDB_UPDATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const EXADB_PDB_START_POLL_DELAY = TimeInterval.md; // 10 secs
export const EXADB_PDB_START_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const EXADB_PDB_STOP_POLL_DELAY = TimeInterval.md; // 10 secs
export const EXADB_PDB_STOP_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMCLUSTER_DEPLOYMENT_CREATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const VMCLUSTER_DEPLOYMENT_CREATE_POLL_INTERVAL = TimeInterval.xxl; // 10 mins
export const VMCLUSTER_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const VMCLUSTER_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMCLUSTER_EDIT_POLL_DELAY = TimeInterval.lg; // 1 min
export const VMCLUSTER_EDIT_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const EXAINFRA_DEPLOYMENT_CREATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const EXAINFRA_DEPLOYMENT_CREATE_POLL_INTERVAL = TimeInterval.xxl; // 10 mins
export const EXAINFRA_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const EXAINFRA_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const EXAINFRA_EDIT_POLL_DELAY = TimeInterval.md; // 10 secs
export const EXAINFRA_EDIT_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const EXABACKUP_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const EXABACKUP_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const EXABACKUP_CREATE_POLL_DELAY = TimeInterval.lg; // 1 min
export const EXABACKUP_CREATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const NETLNK_CREATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const NETLNK_CREATE_POLL_INTERVAL = TimeInterval.md; // 10 secs
export const NETLNK_DELETE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const NETLNK_DELETE_POLL_INTERVAL = TimeInterval.md; // 10 secs
export const VMDB_PDB_START_POLL_DELAY = TimeInterval.md; // 10 secs
export const VMDB_PDB_START_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMDB_PDB_STOP_POLL_DELAY = TimeInterval.md; // 10 secs
export const VMDB_PDB_STOP_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMDB_PDB_CREATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const VMDB_PDB_CREATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMDB_PDB_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const VMDB_PDB_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMDB_CDB_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const VMDB_CDB_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMDB_DB_SYSTEM_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const VMDB_DB_SYSTEM_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMDB_DB_SYSTEM_UPDATE_POLL_DELAY = TimeInterval.md; // 10 secs
export const VMDB_DB_SYSTEM_UPDATE_POLL_INTERVAL = TimeInterval.md; // 10 secs
export const VMDB_PDB_UPDATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const VMDB_PDB_UPDATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMDB_CDB_UPDATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const VMDB_CDB_UPDATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMDB_BACKUP_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const VMDB_BACKUP_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMDB_BACKUP_CREATE_POLL_DELAY = TimeInterval.lg; // 1 min
export const VMDB_BACKUP_CREATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const VMDB_DEPLOYMENT_CREATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const VMDB_DEPLOYMENT_CREATE_POLL_INTERVAL = TimeInterval.xxl; // 10 mins
export const ADBS_DEPLOYMENT_CREATE_POLL_DELAY = TimeInterval.md; // 10 secs
export const ADBS_DEPLOYMENT_CREATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const ADBS_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const ADBS_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const ADBS_UPDATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const ADBS_UPDATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const ADBS_START_POLL_DELAY = TimeInterval.md; // 10 secs
export const ADBS_START_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const ADBS_STOP_POLL_DELAY = TimeInterval.md; // 10 secs
export const ADBS_STOP_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const ADBS_RESTART_POLL_DELAY = TimeInterval.md; // 10 secs
export const ADBS_RESTART_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const ADBS_RESTORE_POLL_DELAY = TimeInterval.md; // 10 secs
export const ADBS_RESTORE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const ADBS_ROTATE_WALLET_POLL_DELAY = TimeInterval.md; // 10 secs
export const ADBS_ROTATE_WALLET_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const ADBSBACKUP_CREATE_POLL_DELAY = TimeInterval.lg; // 1 min
export const ADBSBACKUP_CREATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const CLOUDLINK_UPDATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const CLOUDLINK_UPDATE_POLL_INTERVAL = TimeInterval.md; // 10 secs
export const MDS_UPDATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const MDS_UPDATE_POLL_INTERVAL = TimeInterval.md; // 10 secs
export const MDS_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const MDS_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_HEATWAVE_RESTART_POLL_DELAY = TimeInterval.md; // 10 secs
export const MDS_HEATWAVE_RESTART_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_HEATWAVE_START_POLL_DELAY = TimeInterval.md; // 10 secs
export const MDS_HEATWAVE_START_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_HEATWAVE_STOP_POLL_DELAY = TimeInterval.md; // 10 secs
export const MDS_HEATWAVE_STOP_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_HEATWAVE_UPDATE_POLL_DELAY = TimeInterval.lg; // 1 min
export const MDS_HEATWAVE_UPDATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_BACKUP_CREATE_POLL_DELAY = TimeInterval.lg; // 1 min
export const MDS_BACKUP_CREATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_BACKUP_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_BACKUP_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const MDS_BACKUP_UPDATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const MDS_BACKUP_UPDATE_POLL_INTERVAL = TimeInterval.sm; // 5 secs
export const MDS_DEPLOYMENT_CREATE_POLL_DELAY = TimeInterval.sm; // 5 secs
export const MDS_DEPLOYMENT_CREATE_POLL_INTERVAL = TimeInterval.xl; // 5 mins
export const MDS_RESTART_POLL_DELAY = TimeInterval.md; // 10 secs
export const MDS_RESTART_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_START_POLL_DELAY = TimeInterval.md; // 10 secs
export const MDS_START_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_STOP_POLL_DELAY = TimeInterval.md; // 10 secs
export const MDS_STOP_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_HEATWAVE_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const MDS_HEATWAVE_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MDS_HEATWAVE_CREATE_POLL_DELAY = TimeInterval.lg; // 1 min
export const MDS_HEATWAVE_CREATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MCVCN_CREATE_POLL_DELAY = TimeInterval.md; // 10 secs
export const MCVCN_CREATE_POLL_INTERVAL = TimeInterval.lg; // 1 min
export const MCVCN_DELETE_POLL_DELAY = TimeInterval.lg; // 1 min
export const MCVCN_DELETE_POLL_INTERVAL = TimeInterval.lg; // 1 min

export enum AsyncNotificationMethodKey {
  EXADB_CDB_GET = "EXADB_CDB_GET",
  EXADB_PDB_GET = "EXADB_PDB_GET",
  VMCLUSTER_GET = "VMCLUSTER_GET",
  EXAINFRA_GET = "EXAINFRA_GET",
  EXABACKUP_GET = "EXABACKUP_GET",
  NETLNK_GET = "NETLNK_GET",
  VMDB_CDB_GET = "VMDB_CDB_GET",
  VMDB_PDB_GET = "VMDB_PDB_GET",
  VMDB_DB_SYSTEM_GET = "VMDB_DB_SYSTEM_GET",
  VMDB_BACKUP_GET = "VMDB_BACKUP_GET",
  ADBS_GET = "ADBS_GET",
  ADBSBACKUP_GET = "ADBSBACKUP_GET",
  ADBSWALLET_GET = "ADBSWALLET_GET",
  CLOUDLINK_GET = "CLOUDLINK_GET",
  MDS_HEATWAVE_GET = "MDS_HEATWAVE_GET",
  MDS_GET = "MDS_GET",
  DEPLOYMENT_GET = "DEPLOYMENT_GET",
  MDS_BACKUP_GET = "MDS_BACKUP_GET",
  MCVCN_GET = "MCVCN_GET"
}

export const asyncNotificationMethodProvider = (
  key: string,
  ociRegion: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ApiClientMethod<any, any> | undefined => {
  switch (key) {
    case AsyncNotificationMethodKey.EXADB_CDB_GET:
      return apiClients.withRegion(ociRegion).exaDatabaseApi.getDatabase;
    case AsyncNotificationMethodKey.EXADB_PDB_GET:
      return apiClients.withRegion(ociRegion).exaDatabaseApi.getPluggableDatabase;
    case AsyncNotificationMethodKey.VMCLUSTER_GET:
      return apiClients.withRegion(ociRegion).exaDatabaseApi.getCloudVmCluster;
    case AsyncNotificationMethodKey.NETLNK_GET:
      return apiClients.withRegion(ociRegion).netLinkApi.getNetworkLink;
    case AsyncNotificationMethodKey.EXAINFRA_GET:
      return apiClients.withRegion(ociRegion).exaDatabaseApi.getCloudExadataInfrastructure;
    case AsyncNotificationMethodKey.EXABACKUP_GET:
      return apiClients.withRegion(ociRegion).exaDatabaseApi.getBackup;
    case AsyncNotificationMethodKey.VMDB_CDB_GET:
      return apiClients.withRegion(ociRegion).vmDatabaseApi.getVmdbDatabase;
    case AsyncNotificationMethodKey.VMDB_PDB_GET:
      return apiClients.withRegion(ociRegion).vmDatabaseApi.getVmdbPluggableDatabase;
    case AsyncNotificationMethodKey.VMDB_DB_SYSTEM_GET:
      return apiClients.withRegion(ociRegion).vmDatabaseApi.getVmdbDbSystem;
    case AsyncNotificationMethodKey.VMDB_BACKUP_GET:
      return apiClients.withRegion(ociRegion).vmDatabaseApi.getVmdbBackup;
    case AsyncNotificationMethodKey.ADBS_GET:
      return apiClients.withRegion(ociRegion).adbsDatabaseApi.getAdbsDatabase;
    case AsyncNotificationMethodKey.ADBSBACKUP_GET:
      return apiClients.withRegion(ociRegion).adbsDatabaseApi.getAdbsDatabaseBackup;
    case AsyncNotificationMethodKey.ADBSWALLET_GET:
      return apiClients.withRegion(ociRegion).adbsDatabaseApi.getAdbsDatabaseWallet;
    case AsyncNotificationMethodKey.CLOUDLINK_GET:
      return apiClients.withRegion(ociRegion).cloudLinkApi.getAzureCloudLink;
    case AsyncNotificationMethodKey.MDS_HEATWAVE_GET:
      return apiClients.withRegion(ociRegion).mdsDatabaseApi.getHeatWaveCluster;
    case AsyncNotificationMethodKey.MDS_GET:
      return apiClients.withRegion(ociRegion).mdsDatabaseApi.getMdsDbSystem;
    case AsyncNotificationMethodKey.DEPLOYMENT_GET:
      return apiClients.withRegion(ociRegion).deploymentApi.getDeployment;
    case AsyncNotificationMethodKey.MDS_BACKUP_GET:
      return apiClients.withRegion(ociRegion).mdsDatabaseApi.getMdsBackup;
    case AsyncNotificationMethodKey.MCVCN_GET:
      return apiClients.withRegion(ociRegion).networkApi.getMultiCloudVirtualNetwork;
    default:
      return undefined;
  }
};

const exaDbCreatedCheckCdb: PolledResponseCallback = (instance: Database): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.lifecycleState) {
    case "AVAILABLE":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "FAILED":
    case "RESTORE_FAILED":
    case "TERMINATED":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "PROVISIONING":
    case "UPDATING":
    case "BACKUP_IN_PROGRESS":
    case "UPGRADING":
    case "CONVERTING":
    case "TERMINATING":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const deploymentCreatedCheck: PolledResponseCallback = (instance: Deployment): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.lifecycleState) {
    case "SUCCEEDED":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "FAILED":
    case "CANCELED":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "ACCEPTED":
    case "IN_PROGRESS":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const exadbDeletedCheckCdb: PolledResponseCallback = (
  instance: Database,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.lifecycleState) {
      case "TERMINATED":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;

      case "AVAILABLE":
      case "FAILED":
      case "RESTORE_FAILED":
        if (instance.status === "Terminating") {
          polledResponseResult.pollingAction = PollingAction.CONTINUE;
        } else polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "PROVISIONING":
      case "UPDATING":
      case "BACKUP_IN_PROGRESS":
      case "UPGRADING":
      case "CONVERTING":
      case "TERMINATING":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const exadbCreatedCheckPdb: PolledResponseCallback = (
  instance: PluggableDatabase,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.lifecycleState) {
    case "AVAILABLE":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "FAILED":
    case "TERMINATED":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "PROVISIONING":
    case "UPDATING":
    case "TERMINATING":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const exadbStartedCheckPdb: PolledResponseCallback = (
  instance: PluggableDatabase,
): PolledResponseResult => exadbCreatedCheckPdb(instance);

const exadbStoppedCheckPdb: PolledResponseCallback = (
  instance: PluggableDatabase,
): PolledResponseResult => exadbCreatedCheckPdb(instance);

const exadbDeletedCheckPdb: PolledResponseCallback = (
  instance: PluggableDatabase,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.lifecycleState) {
      case "TERMINATED":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;

      case "AVAILABLE":
      case "FAILED":
        if (instance.status === "Terminating") {
          polledResponseResult.pollingAction = PollingAction.CONTINUE;
        } else polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "PROVISIONING":
      case "UPDATING":
      case "TERMINATING":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const exabackupCreatedCheck: PolledResponseCallback = (instance: Backup): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.lifecycleState) {
    case "ACTIVE":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "DELETED":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "CREATING":
    case "DELETING":
    case "RESTORING":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const exabackupDeletedCheck: PolledResponseCallback = (
  instance: Backup,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.lifecycleState) {
      case "DELETED":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;

      case "ACTIVE":
      case "FAILED":
        if (instance.status === "Terminating") {
          polledResponseResult.pollingAction = PollingAction.CONTINUE;
        } else polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "CREATING":
      case "RESTORING":
      case "DELETING":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const exainfraDeletedCheck: PolledResponseCallback = (
  instance: CloudExadataInfrastructure,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.lifecycleState) {
      case "TERMINATED":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;

      case "AVAILABLE":
      case "FAILED":
        if (instance.status === "Terminating") {
          polledResponseResult.pollingAction = PollingAction.CONTINUE;
        } else polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "PROVISIONING":
      case "UPDATING":
      case "TERMINATING":
      case "MAINTENANCE_IN_PROGRESS":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const exadbCreateCheckVmCluster: PolledResponseCallback = (
  instance: CloudVmCluster,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.lifecycleState) {
    case "AVAILABLE":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "FAILED":
    case "TERMINATED":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "PROVISIONING":
    case "UPDATING":
    case "TERMINATING":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const exadbEditCheckVmCluster = (
  instance: CloudVmCluster,
): PolledResponseResult => exadbCreateCheckVmCluster(instance);

const vmdbCreatedCheckPdb: PolledResponseCallback = (
  instance: VmdbPluggableDatabase,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case "Active":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "Failed":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "Provisioning":
    case "Updating":
    case "Terminating":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const vmdbDeletedCheckPdb: PolledResponseCallback = (
  instance: VmdbPluggableDatabase,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.status) {
      /*
      case "Terminated":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;
      */

      case "Active":
      case "Failed":
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "Provisioning":
      case "Updating":
      case "Terminating":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const netLinkCreatedCheck: PolledResponseCallback = (instance: NetworkLink): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case "SUCCEEDED":
    case "CREATED":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "FAILED":
    case "DELETED":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "CREATING":
    case "UPDATING":
    case "DELETING":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const exadbEditCheckBackup: PolledResponseCallback = (
  instance: Database,
): PolledResponseResult => exaDbCreatedCheckCdb(instance);

const exadbUpdatedCheckCdb: PolledResponseCallback = (
  instance: Database,
): PolledResponseResult => exaDbCreatedCheckCdb(instance);

const exadbUpdatedCheckPdb: PolledResponseCallback = (
  instance: PluggableDatabase,
): PolledResponseResult => exadbCreatedCheckPdb(instance);

const exadbEditCheckPassword: PolledResponseCallback = (
  instance: Database,
): PolledResponseResult => exaDbCreatedCheckCdb(instance);

const netLinkDeletedCheck: PolledResponseCallback = (
  instance: NetworkLink,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.status) {
      case "DELETED":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;

      case "CREATED":
      case "FAILED":
      case "SUCCEEDED":
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "CREATING":
      case "UPDATING":
      case "DELETING":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const exaInfraCreatedCheck: PolledResponseCallback = (
  instance: CloudExadataInfrastructure,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case "Succeeded":
    case "Active":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;
    case "Failed":
    case "Canceled":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;
    case "Provisioning":
    case "Updating":
    case "Terminating":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const exaInfraEditCheck: PolledResponseCallback = (
  instance: CloudExadataInfrastructure,
): PolledResponseResult => exaInfraCreatedCheck(instance);

const vmClusterDeletedCheck: PolledResponseCallback = (
  instance: CloudVmCluster,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.lifecycleState) {
      case "TERMINATED":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;

      case "AVAILABLE":
      case "FAILED":
        if (instance.status === "Terminating") {
          polledResponseResult.pollingAction = PollingAction.CONTINUE;
        } else polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "PROVISIONING":
      case "UPDATING":
      case "TERMINATING":
      case "MAINTENANCE_IN_PROGRESS":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const vmDbSystemDeletedCheck: PolledResponseCallback = (
  instance: VmdbDbSystem,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.status) {
      case "Active":
      case "Failed":
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "Provisioning":
      case "Updating":
      case "Terminating":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const vmDbSystemUpdatedCheck: PolledResponseCallback = (
  instance: VmdbDbSystem,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case "Active":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "Failed":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "Provisioning":
    case "Updating":
    case "Terminating":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const vmdbDeletedCheckCdb: PolledResponseCallback = (
  instance: VmdbDatabase,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.status) {
      /*
      case "Terminated":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;
      */

      case "Active":
      case "Failed":
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "Provisioning":
      case "Updating":
      case "Terminating":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const vmdbStartedCheckPdb: PolledResponseCallback = (
  instance: VmdbPluggableDatabase,
): PolledResponseResult => vmdbCreatedCheckPdb(instance);

const vmdbStoppedCheckPdb: PolledResponseCallback = (
  instance: VmdbPluggableDatabase,
): PolledResponseResult => vmdbCreatedCheckPdb(instance);

const vmdbCreatedCheckCdb: PolledResponseCallback = (
  instance: VmdbDatabase,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case "Active":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "Failed":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "Provisioning":
    case "Updating":
    case "Terminating":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};
const vmdbUpdatedCheckCdb: PolledResponseCallback = (
  instance: VmdbDatabase,
): PolledResponseResult => vmdbCreatedCheckCdb(instance);

const vmdbUpdatedCheckPdb: PolledResponseCallback = (
  instance: VmdbPluggableDatabase,
): PolledResponseResult => vmdbCreatedCheckPdb(instance);

const vmdbbackupCreatedCheck: PolledResponseCallback = (instance: VmdbBackup): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case "Active":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "Failed":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "Provisioning":
    case "Terminating":
    case "Updating":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const vmdbbackupDeletedCheck: PolledResponseCallback = (
  instance: VmdbBackup,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.status) {
      /*
      case "Terminated":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;
      */

      case "Active":
      case "Failed":
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "Provisioning":
      case "Updating":
      case "Terminating":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const adbsDeletedCheck: PolledResponseCallback = (
  instance: AdbsDatabase,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.status) {
      case "Failed":
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "Active":
      case "Provisioning":
      case "Updating":
      case "Terminating":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const adbsCreatedCheck: PolledResponseCallback = (instance: AdbsDatabase): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case "Active":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "Failed":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "Provisioning":
    case "Updating":
    case "Terminating":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const adbsUpdatedCheck: PolledResponseCallback = (
  instance: AdbsDatabase,
): PolledResponseResult => adbsCreatedCheck(instance);

const adbsEditCheckPassword: PolledResponseCallback = (
  instance: AdbsDatabase,
): PolledResponseResult => adbsCreatedCheck(instance);

const adbsStartedCheck: PolledResponseCallback = (
  instance: AdbsDatabase,
): PolledResponseResult => adbsCreatedCheck(instance);

const adbsStoppedCheck: PolledResponseCallback = (
  instance: AdbsDatabase,
): PolledResponseResult => adbsCreatedCheck(instance);

const adbsRestartCheck: PolledResponseCallback = (
  instance: AdbsDatabase,
): PolledResponseResult => adbsCreatedCheck(instance);

const adbsRestoreCheck: PolledResponseCallback = (
  instance: AdbsDatabase,
): PolledResponseResult => adbsCreatedCheck(instance);

const adbsRotateWalletCheck: PolledResponseCallback = (wallet: AdbsDatabaseWallet): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (wallet.lifecycleState) {
    case "ACTIVE":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "UPDATING":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const adbsbackupCreatedCheck: PolledResponseCallback = (instance: AdbsDatabaseBackup): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };

  switch (instance.status) {
    case "Active":
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case "Failed":
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case "Provisioning":
    case "Terminating":
    case "Updating":
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const cloudLinkUpdatedCheck: PolledResponseCallback = (instance: AzureCloudLink): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (instance.lastOperation) {
    switch (instance.lastOperation.status) {
      case "SUCCEEDED":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;

      case "FAILED":
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "IN_PROGRESS":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  } else {
    switch (instance.lifecycleState) {
      case "ACTIVE":
        polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
        break;

      case "FAILED":
      case "DELETED":
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case "CREATING":
      case "UPDATING":
      case "DELETING":
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const mdsHeatwaveUpdatedCheck: PolledResponseCallback = (instance: HeatWaveCluster): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case ResourceStatus.Active:
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case ResourceStatus.Failed:
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case ResourceStatus.Provisioning:
    case ResourceStatus.Updating:
    case ResourceStatus.Terminating:
    case ResourceStatus.Stopped:
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const mdsHeatwaveStoppedCheck: PolledResponseCallback = (instance: HeatWaveCluster): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case ResourceStatus.Stopped:
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case ResourceStatus.Failed:
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case ResourceStatus.Active:
    case ResourceStatus.Updating:
    case ResourceStatus.Provisioning:
    case ResourceStatus.Terminating:
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const mdsHeatwaveCreatedCheck: PolledResponseCallback = (
  instance: MdsDbSystem,
): PolledResponseResult => mdsHeatwaveUpdatedCheck(instance);

const mdsHeatwaveRestartedCheck: PolledResponseCallback = (
  instance: MdsDbSystem,
): PolledResponseResult => mdsHeatwaveUpdatedCheck(instance);

const mdsHeatwaveStartedCheck: PolledResponseCallback = (
  instance: MdsDbSystem,
): PolledResponseResult => mdsHeatwaveUpdatedCheck(instance);

const mdsHeatwaveDeletedCheck: PolledResponseCallback = (
  instance: HeatWaveCluster,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.status) {
      case ResourceStatus.Failed:
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;
      case ResourceStatus.Active:
      case ResourceStatus.Provisioning:
      case ResourceStatus.Stopped:
      case ResourceStatus.Updating:
      case ResourceStatus.Terminating:
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const mdsUpdatedCheck: PolledResponseCallback = (instance: MdsDbSystem): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case ResourceStatus.Active:
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case ResourceStatus.Failed:
    case ResourceStatus.Stopped:
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case ResourceStatus.Provisioning:
    case ResourceStatus.Updating:
    case ResourceStatus.Terminating:
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const mdsStoppedCheck: PolledResponseCallback = (instance: MdsDbSystem): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case ResourceStatus.Stopped:
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case ResourceStatus.Failed:
    case ResourceStatus.Active:
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case ResourceStatus.Updating:
    case ResourceStatus.Terminating:
    case ResourceStatus.Provisioning:
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const mdsStartedCheck: PolledResponseCallback = (
  instance: MdsDbSystem,
): PolledResponseResult => mdsUpdatedCheck(instance);

const mdsRestartedCheck: PolledResponseCallback = (instance: MdsDbSystem): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case ResourceStatus.Active:
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case ResourceStatus.Failed:
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case ResourceStatus.Provisioning:
    case ResourceStatus.Updating:
    case ResourceStatus.Terminating:
    case ResourceStatus.Stopped:
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const mdsDeletedCheck: PolledResponseCallback = (
  instance: MdsDbSystem,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.status) {
      case ResourceStatus.Failed:
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;
      case ResourceStatus.Active:
      case ResourceStatus.Provisioning:
      case ResourceStatus.Updating:
      case ResourceStatus.Terminating:
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};
const mdsBackupCreatedCheck: PolledResponseCallback = (instance: MdsBackup): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case ResourceStatus.Active:
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case ResourceStatus.Failed:
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case ResourceStatus.Provisioning:
    case ResourceStatus.Updating:
    case ResourceStatus.Terminating:
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const mdsBackupDeletedCheck: PolledResponseCallback = (
  instance: MdsBackup,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.status) {
      case ResourceStatus.Failed:
      case ResourceStatus.Active:
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case ResourceStatus.Provisioning:
      case ResourceStatus.Updating:
      case ResourceStatus.Terminating:
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

const mdsBackupUpdatedCheck: PolledResponseCallback = (instance: MdsBackup): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case ResourceStatus.Active:
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case ResourceStatus.Failed:
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case ResourceStatus.Provisioning:
    case ResourceStatus.Updating:
    case ResourceStatus.Terminating:
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const mcVcnCreatedCheck: PolledResponseCallback = (
  instance: MultiCloudVirtualNetwork,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  switch (instance.status) {
    case ResourceStatus.Active:
      polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
      break;

    case ResourceStatus.Failed:
      polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
      break;

    case ResourceStatus.Provisioning:
    case ResourceStatus.Updating:
    case ResourceStatus.Terminating:
    default:
      polledResponseResult.pollingAction = PollingAction.CONTINUE;
      break;
  }
  return polledResponseResult;
};

const mcVcnDeletedCheck: PolledResponseCallback = (
  instance: MultiCloudVirtualNetwork,
  errorStatus?: number,
): PolledResponseResult => {
  const polledResponseResult: PolledResponseResult = { pollingAction: PollingAction.CONTINUE };
  if (errorStatus === 404) {
    polledResponseResult.pollingAction = PollingAction.STOP_SUCCESS;
  } else {
    switch (instance.status) {
      case ResourceStatus.Active:
      case ResourceStatus.Failed:
        polledResponseResult.pollingAction = PollingAction.STOP_FAILURE;
        break;

      case ResourceStatus.Provisioning:
      case ResourceStatus.Updating:
      case ResourceStatus.Terminating:
      default:
        polledResponseResult.pollingAction = PollingAction.CONTINUE;
        break;
    }
  }
  return polledResponseResult;
};

export enum AsyncNotificationPolledResponseKey {
  EXADB_DEPLOYMENT_CREATED_CHECK_CDB = "EXADB_DEPLOYMENT_CREATED_CHECK_CDB",
  EXADB_DELETED_CHECK_CDB = "EXADB_DELETED_CHECK_CDB",
  EXADB_DELETED_CHECK_PDB = "EXADB_DELETED_CHECK_PDB",
  EXADB_CREATED_CHECK_PDB = "EXADB_CREATED_CHECK_PDB",
  EXADB_STARTED_CHECK_PDB = "EXADB_STARTED_CHECK_PDB",
  EXADB_STOPPED_CHECK_PDB = "EXADB_STOPPED_CHECK_PDB",
  EXADB_EDIT_CHECK_BACKUP = "EXADB_EDIT_CHECK_BACKUP",
  EXADB_EDIT_CHECK_PASSWORD = "EXADB_EDIT_CHECK_PASSWORD",
  EXADB_UPDATED_CHECK_PDB = "EXADB_UPDATED_CHECK_PDB",
  EXADB_UPDATED_CHECK_CDB = "EXADB_UPDATED_CHECK_CDB",
  EXAINFRA_DEPLOYMENT_CREATED_CHECK = "EXAINFRA_DEPLOYMENT_CREATED_CHECK",
  EXAINFRA_DELETED_CHECK = "EXAINFRA_DELETED_CHECK",
  EXAINFRA_EDIT_CHECK = "EXAINFRA_EDIT_CHECK",
  EXABACKUP_DELETED_CHECK = "EXABACKUP_DELETED_CHECK",
  EXABACKUP_CREATED_CHECK = "EXABACKUP_CREATED_CHECK",
  NETLNK_DELETED_CHECK = "NETLNK_DELETED_CHECK",
  NETLNK_CREATED_CHECK = "NETLNK_CREATED_CHECK",
  VMCLUSTER_DEPLOYMENT_CREATED_CHECK = "VMCLUSTER_DEPLOYMENT_CREATED_CHECK",
  VMCLUSTER_DELETED_CHECK = "VMCLUSTER_DELETED_CHECK",
  VMCLUSTER_EDIT_CHECK = "VMCLUSTER_EDIT_CHECK",
  VMDB_CREATED_CHECK_PDB = "VMDB_CREATED_CHECK_PDB",
  VMDB_STARTED_CHECK_PDB = "VMDB_STARTED_CHECK_PDB",
  VMDB_STOPPED_CHECK_PDB = "VMDB_STOPPED_CHECK_PDB",
  VMDB_DELETED_CHECK_PDB = "VMDB_DELETED_CHECK_PDB",
  VMDB_DELETED_CHECK_CDB = "VMDB_DELETED_CHECK_CDB",
  VMDB_CREATED_CHECK_CDB = "VMDB_CREATED_CHECK_CDB",
  VMDB_DB_SYSTEM_DELETED_CHECK = "VMDB_DB_SYSTEM_DELETED_CHECK",
  VMDB_DB_SYSTEM_UPDATED_CHECK = "VMDB_DB_SYSTEM_UPDATED_CHECK",
  VMDB_UPDATED_CHECK_PDB = "VMDB_UPDATED_CHECK_PDB",
  VMDB_UPDATED_CHECK_CDB = "VMDB_UPDATED_CHECK_CDB",
  VMDB_BACKUP_DELETED_CHECK = "VMDB_BACKUP_DELETED_CHECK",
  VMDB_BACKUP_CREATED_CHECK = "VMDB_BACKUP_CREATED_CHECK",
  VMDB_DEPLOYMENT_CREATED_CHECK = "VMDB_DEPLOYMENT_CREATED_CHECK",
  ADBS_DEPLOYMENT_CREATED_CHECK = "ADBS_DEPLOYMENT_CREATED_CHECK",
  ADBS_DELETED_CHECK = "ADBS_DELETED_CHECK",
  ADBS_UPDATED_CHECK = "ADBS_UPDATED_CHECK",
  ADBS_EDIT_CHECK_PASSWORD = "ADBS_EDIT_CHECK_PASSWORD",
  ADBS_STARTED_CHECK = "ADBS_STARTED_CHECK",
  ADBS_STOPPED_CHECK = "ADBS_STOPPED_CHECK",
  ADBS_RESTART_CHECK = "ADBS_RESTART_CHECK",
  ADBS_RESTORE_CHECK = "ADBS_RESTORE_CHECK",
  ADBS_ROTATE_WALLET_CHECK = "ADBS_ROTATE_CHECK",
  ADBSBACKUP_CREATED_CHECK = "ADBSBACKUP_CREATED_CHECK",
  CLOUDLINK_UPDATED_CHECK = "CLOUDLINK_UPDATED_CHECK",
  MDS_HEATWAVE_UPDATED_CHECK = "MDS_HEATWAVE_UPDATED_CHECK",
  MDS_HEATWAVE_STARTED_CHECK = "MDS_HEATWAVE_STARTED_CHECK",
  MDS_HEATWAVE_STOPPED_CHECK = "MDS_HEATWAVE_STOPPED_CHECK",
  MDS_HEATWAVE_RESTARTED_CHECK = "MDS_HEATWAVE_RESTARTED_CHECK",
  MDS_HEATWAVE_DELETED_CHECK = "MDS_HEATWAVE_DELETED_CHECK",
  MDS_HEATWAVE_CREATED_CHECK = "MDS_HEATWAVE_CREATED_CHECK",
  MDS_UPDATED_CHECK = "MDS_UPDATED_CHECK",
  MDS_DELETED_CHECK = "MDS_DELETED_CHECK",
  MDS_DEPLOYMENT_CREATED_CHECK = "MDS_DEPLOYMENT_CREATED_CHECK",
  MDS_STARTED_CHECK = "MDS_STARTED_CHECK",
  MDS_STOPPED_CHECK = "MDS_STOPPED_CHECK",
  MDS_RESTARTED_CHECK = "MDS_RESTARTED_CHECK",
  MDS_BACKUP_CREATED_CHECK = "MDS_BACKUP_CREATED_CHECK",
  MDS_BACKUP_DELETED_CHECK = "MDS_BACKUP_DELETED_CHECK",
  MDS_BACKUP_UPDATED_CHECK = "MDS_BACKUP_UPDATED_CHECK",
  MCVCN_CREATED_CHECK = "MCVCN_CREATED_CHECK",
  MCVCN_DELETED_CHECK = "MCVCN_DELETED_CHECK",
}

export const asyncNotificationPolledResponseProvider = (key: string): PolledResponseCallback | undefined => {
  switch (key) {
    case AsyncNotificationPolledResponseKey.EXADB_DEPLOYMENT_CREATED_CHECK_CDB:
      return deploymentCreatedCheck;
    case AsyncNotificationPolledResponseKey.EXADB_DELETED_CHECK_CDB:
      return exadbDeletedCheckCdb;
    case AsyncNotificationPolledResponseKey.EXADB_DELETED_CHECK_PDB:
      return exadbDeletedCheckPdb;
    case AsyncNotificationPolledResponseKey.EXADB_CREATED_CHECK_PDB:
      return exadbCreatedCheckPdb;
    case AsyncNotificationPolledResponseKey.EXADB_STARTED_CHECK_PDB:
      return exadbStartedCheckPdb;
    case AsyncNotificationPolledResponseKey.EXADB_STOPPED_CHECK_PDB:
      return exadbStoppedCheckPdb;
    case AsyncNotificationPolledResponseKey.EXADB_EDIT_CHECK_BACKUP:
      return exadbEditCheckBackup;
    case AsyncNotificationPolledResponseKey.EXADB_EDIT_CHECK_PASSWORD:
      return exadbEditCheckPassword;
    case AsyncNotificationPolledResponseKey.EXABACKUP_DELETED_CHECK:
      return exabackupDeletedCheck;
    case AsyncNotificationPolledResponseKey.EXABACKUP_CREATED_CHECK:
      return exabackupCreatedCheck;
    case AsyncNotificationPolledResponseKey.EXADB_UPDATED_CHECK_CDB:
      return exadbUpdatedCheckCdb;
    case AsyncNotificationPolledResponseKey.EXADB_UPDATED_CHECK_PDB:
      return exadbUpdatedCheckPdb;
    case AsyncNotificationPolledResponseKey.VMCLUSTER_EDIT_CHECK:
      return exadbEditCheckVmCluster;
    case AsyncNotificationPolledResponseKey.EXAINFRA_DELETED_CHECK:
      return exainfraDeletedCheck;
    case AsyncNotificationPolledResponseKey.EXAINFRA_EDIT_CHECK:
      return exaInfraEditCheck;
    case AsyncNotificationPolledResponseKey.NETLNK_DELETED_CHECK:
      return netLinkDeletedCheck;
    case AsyncNotificationPolledResponseKey.NETLNK_CREATED_CHECK:
      return netLinkCreatedCheck;
    case AsyncNotificationPolledResponseKey.EXAINFRA_DEPLOYMENT_CREATED_CHECK:
      return deploymentCreatedCheck;
    case AsyncNotificationPolledResponseKey.VMCLUSTER_DEPLOYMENT_CREATED_CHECK:
      return deploymentCreatedCheck;
    case AsyncNotificationPolledResponseKey.VMCLUSTER_DELETED_CHECK:
      return vmClusterDeletedCheck;
    case AsyncNotificationPolledResponseKey.VMDB_DELETED_CHECK_PDB:
      return vmdbDeletedCheckPdb;
    case AsyncNotificationPolledResponseKey.VMDB_CREATED_CHECK_PDB:
      return vmdbCreatedCheckPdb;
    case AsyncNotificationPolledResponseKey.VMDB_DELETED_CHECK_CDB:
      return vmdbDeletedCheckCdb;
    case AsyncNotificationPolledResponseKey.VMDB_STARTED_CHECK_PDB:
      return vmdbStartedCheckPdb;
    case AsyncNotificationPolledResponseKey.VMDB_STOPPED_CHECK_PDB:
      return vmdbStoppedCheckPdb;
    case AsyncNotificationPolledResponseKey.VMDB_CREATED_CHECK_CDB:
      return vmdbCreatedCheckCdb;
    case AsyncNotificationPolledResponseKey.VMDB_DB_SYSTEM_DELETED_CHECK:
      return vmDbSystemDeletedCheck;
    case AsyncNotificationPolledResponseKey.VMDB_DB_SYSTEM_UPDATED_CHECK:
      return vmDbSystemUpdatedCheck;
    case AsyncNotificationPolledResponseKey.VMDB_UPDATED_CHECK_CDB:
      return vmdbUpdatedCheckCdb;
    case AsyncNotificationPolledResponseKey.VMDB_UPDATED_CHECK_PDB:
      return vmdbUpdatedCheckPdb;
    case AsyncNotificationPolledResponseKey.VMDB_BACKUP_DELETED_CHECK:
      return vmdbbackupDeletedCheck;
    case AsyncNotificationPolledResponseKey.VMDB_BACKUP_CREATED_CHECK:
      return vmdbbackupCreatedCheck;
    case AsyncNotificationPolledResponseKey.ADBS_DEPLOYMENT_CREATED_CHECK:
      return deploymentCreatedCheck;
    case AsyncNotificationPolledResponseKey.ADBS_DELETED_CHECK:
      return adbsDeletedCheck;
    case AsyncNotificationPolledResponseKey.ADBS_UPDATED_CHECK:
      return adbsUpdatedCheck;
    case AsyncNotificationPolledResponseKey.ADBS_EDIT_CHECK_PASSWORD:
      return adbsEditCheckPassword;
    case AsyncNotificationPolledResponseKey.ADBS_STARTED_CHECK:
      return adbsStartedCheck;
    case AsyncNotificationPolledResponseKey.ADBS_STOPPED_CHECK:
      return adbsStoppedCheck;
    case AsyncNotificationPolledResponseKey.ADBS_RESTART_CHECK:
      return adbsRestartCheck;
    case AsyncNotificationPolledResponseKey.ADBS_RESTORE_CHECK:
      return adbsRestoreCheck;
    case AsyncNotificationPolledResponseKey.ADBSBACKUP_CREATED_CHECK:
      return adbsbackupCreatedCheck;
    case AsyncNotificationPolledResponseKey.ADBS_ROTATE_WALLET_CHECK:
      return adbsRotateWalletCheck;
    case AsyncNotificationPolledResponseKey.CLOUDLINK_UPDATED_CHECK:
      return cloudLinkUpdatedCheck;
    case AsyncNotificationPolledResponseKey.MDS_HEATWAVE_STARTED_CHECK:
      return mdsHeatwaveStartedCheck;
    case AsyncNotificationPolledResponseKey.MDS_HEATWAVE_STOPPED_CHECK:
      return mdsHeatwaveStoppedCheck;
    case AsyncNotificationPolledResponseKey.MDS_HEATWAVE_RESTARTED_CHECK:
      return mdsHeatwaveRestartedCheck;
    case AsyncNotificationPolledResponseKey.MDS_HEATWAVE_UPDATED_CHECK:
      return mdsHeatwaveUpdatedCheck;
    case AsyncNotificationPolledResponseKey.MDS_HEATWAVE_DELETED_CHECK:
      return mdsHeatwaveDeletedCheck;
    case AsyncNotificationPolledResponseKey.MDS_HEATWAVE_CREATED_CHECK:
      return mdsHeatwaveCreatedCheck;
    case AsyncNotificationPolledResponseKey.MDS_UPDATED_CHECK:
      return mdsUpdatedCheck;
    case AsyncNotificationPolledResponseKey.MDS_DELETED_CHECK:
      return mdsDeletedCheck;
    case AsyncNotificationPolledResponseKey.MDS_DEPLOYMENT_CREATED_CHECK:
      return deploymentCreatedCheck;
    case AsyncNotificationPolledResponseKey.VMDB_DEPLOYMENT_CREATED_CHECK:
      return deploymentCreatedCheck;
    case AsyncNotificationPolledResponseKey.MDS_STARTED_CHECK:
      return mdsStartedCheck;
    case AsyncNotificationPolledResponseKey.MDS_STOPPED_CHECK:
      return mdsStoppedCheck;
    case AsyncNotificationPolledResponseKey.MDS_RESTARTED_CHECK:
      return mdsRestartedCheck;
    case AsyncNotificationPolledResponseKey.MDS_BACKUP_CREATED_CHECK:
      return mdsBackupCreatedCheck;
    case AsyncNotificationPolledResponseKey.MDS_BACKUP_DELETED_CHECK:
      return mdsBackupDeletedCheck;
    case AsyncNotificationPolledResponseKey.MDS_BACKUP_UPDATED_CHECK:
      return mdsBackupUpdatedCheck;
    case AsyncNotificationPolledResponseKey.MCVCN_CREATED_CHECK:
      return mcVcnCreatedCheck;
    case AsyncNotificationPolledResponseKey.MCVCN_DELETED_CHECK:
      return mcVcnDeletedCheck;
    default:
      return undefined;
  }
};
