import {
  AnchoredPanelType,
  FormInputGroupLayout,
  FormValues,
  InputFormSidePanel,
  InputFormSidePanelProps,
  IntegerInput,
  optimizedRetryOption,
  ReadOnlyText,
  SubmitButtonMode,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ServiceProviders } from "../../constants/pluginConstants";
import { SidePanelTestIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { LimitDefinitionSummary, LimitScopeValues } from "../../gen/clients/mchub-azure-api-client-platform";
import { getProviderName } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { getOciRegion } from "../../utils";
import { QuotaSelect } from "../QuotaSelect/QuotaSelect";
import { RegionSelect } from "../RegionSelect/RegionSelect";

export const enum Fields {
  LimitName = "limitName",
  Region = "region",
  CurrentLimit = "currentLimit",
  CurrentUsed = "currentUsed",
  NewLimit = "newLimit",
}

export enum FieldTestIds {
  LimitName = "limit-name",
  Region = "region",
  CurrentLimit = "current-limit",
  CurrentUsed = "current-used",
  NewLimit = "new-limit",
}

export interface QuotaRequestValues {
  limitName?: string;
  location?: string;
  newLimit?: number;
}
export interface QuotaRequestPanelProps extends
  Pick<InputFormSidePanelProps, "onClose"> {
  onSubmit: (values: FormValues, currentAvailableLimit?: number, currentUsedLimit?: number) => void;
  subscriptionId: string;
  provider: ServiceProviders;
  quotaRequestValues: QuotaRequestValues;
}

export const QuotaRequestPanel = ({
  subscriptionId,
  provider,
  quotaRequestValues,
  onSubmit,
  onClose,
}: QuotaRequestPanelProps): JSX.Element => {
  const { limitName, location, newLimit } = quotaRequestValues;

  const [limitDefinition, setLimitDefinition] = React.useState<LimitDefinitionSummary>();
  const [remount, setRemount] = React.useState<string>(uniqueGUID());

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (quotaRequestValues) {
      const initFieldValues: FormValues = { [Fields.LimitName]: limitName ? [limitName] : undefined };

      if (location) {
        initFieldValues[Fields.Region] = [location];
      }
      if (newLimit) {
        initFieldValues[Fields.NewLimit] = newLimit.toString();
      }

      setInitialValues(initFieldValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotaRequestValues]);

  let quotaTypeLabel;
  switch (provider) {
    case ServiceProviders.EXADATA:
      quotaTypeLabel = Messages.labels.providerQuotaType(Messages.serviceProviders.exadataDedicated());
      break;
    case ServiceProviders.VMDB:
      quotaTypeLabel = Messages.labels.providerQuotaType(Messages.serviceProviders.vmDatabase());
      break;
    case ServiceProviders.ADBS:
      quotaTypeLabel = Messages.labels.providerQuotaType(Messages.serviceProviders.adbShared());
      break;
    case ServiceProviders.MDS:
      quotaTypeLabel = Messages.labels.providerQuotaType(Messages.serviceProviders.mySqlHeatwave());
      break;
    default:
      break;
  }

  const onDefaultQuotaSet = (selectedLimitDefinition: LimitDefinitionSummary): void => {
    setLimitDefinition(selectedLimitDefinition);
  };

  const onQuotaChange = (_: string, selectedLimitDefinition: LimitDefinitionSummary): void => {
    setLimitDefinition(selectedLimitDefinition);
  };

  const { loading: subscriptionsLoading, subscriptions } = useSubscriptions();
  const subscriptionLocation = subscriptions?.find(sub => sub.id === subscriptionId)?.primaryLocation;

  const { response: limitValueResponse, loading: limitValueLoading, error: limitValueError } = useQueryCall({
    wait: subscriptionsLoading || !(subscriptionId && subscriptionLocation && provider && limitDefinition),
    method: apiClients.withRegion(getOciRegion(subscriptionLocation || "")).limitsApi.listLimitValues,
    options: {
      args: {
        subscriptionId,
        providerName: getProviderName(provider),
        name: limitDefinition?.name || "",
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadQuotasLimitValue(),
      },
    },
  });

  const limitValue = limitValueResponse?.data?.items?.[0];

  const {
    response: limitAvailabilityResponse,
    loading: limitAvailabilityLoading,
    error: limitAvailabilityError,
  } = useQueryCall({
    wait: !(subscriptionId && subscriptionLocation && provider && limitDefinition),
    method: apiClients.withRegion(getOciRegion(subscriptionLocation || "")).limitsApi.getResourceAvailability,
    options: {
      args: {
        subscriptionId,
        providerName: getProviderName(provider),
        limitName: limitDefinition?.name || "",
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadQuotasLimitAvailability(),
      },
    },
  });

  const limitAvailability = limitAvailabilityResponse?.data;

  const currentLimit = React.useRef<number>();
  const currentUsed = React.useRef<number>();

  React.useEffect(() => {
    currentUsed.current = limitAvailability?.used;
    currentLimit.current = limitValue?.value;
    setRemount(uniqueGUID());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitAvailability, limitValue]);

  const internalOnSubmit = (values: FormValues): void => {
    onSubmit?.(
      values,
      currentLimit.current,
      currentUsed.current,
    );
  };

  return (
    <InputFormSidePanel
      type={AnchoredPanelType.MEDIUM}
      testId={SidePanelTestIds.QuotaRequestPanel}
      layout={FormInputGroupLayout.COMPACT}
      title={Messages.createNewPanels.quotaDetails.title()}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={internalOnSubmit}
      onClose={onClose}
      initialValues={initialValues}
    >
      <QuotaSelect
        required
        fieldName={Fields.LimitName}
        testId={FieldTestIds.LimitName}
        label={quotaTypeLabel}
        subscriptionId={subscriptionId}
        provider={provider}
        onDefaultValueSet={onDefaultQuotaSet}
        onChange={onQuotaChange}
        defaultValue={limitName ? [limitName] : undefined}
      />
      {limitDefinition?.scopeType === LimitScopeValues.LOCATION && (
        <RegionSelect
          required
          testId={FieldTestIds.Region}
          subscriptionId={subscriptionId}
          fieldName={Fields.Region}
          label={Messages.labels.region()}
          tooltip={Messages.hints.toolTipRegion()}
          defaultValue={location ? [location] : undefined}
        />
      )}
      <ReadOnlyText
        fieldName={Fields.CurrentLimit}
        testId={FieldTestIds.CurrentLimit}
        label={Messages.labels.currentQuotaLimit(limitDefinition?.description || "")}
        disabled={!limitValue}
        defaultValue={currentLimit.current?.toString()}
        loading={limitDefinition && limitValueLoading && !limitValueError}
      />
      <ReadOnlyText
        fieldName={Fields.CurrentUsed}
        testId={FieldTestIds.CurrentUsed}
        label={Messages.labels.currentQuotaUsed(limitDefinition?.description || "")}
        disabled={!limitAvailability}
        defaultValue={currentUsed.current == null ? Messages.common.unknown() : currentUsed.current?.toString()}
        loading={limitDefinition && limitAvailabilityLoading && !limitAvailabilityError}
      />
      <IntegerInput
        key={`new-limit-${remount}`}
        fieldName={Fields.NewLimit}
        testId={FieldTestIds.NewLimit}
        label={Messages.labels.newQuotaLimit()}
        min={limitValue?.value}
        required
        defaultValue={newLimit || limitValue?.value}
        loading={limitDefinition && limitValueLoading && !limitValueError}
        validator={(): string[] | undefined => {
          const errors: string[] = [];
          if (limitValueError) errors.push(Messages.validation.quotaLimitUnavailable());
          return errors.length > 0 ? errors : undefined;
        }}
      />
    </InputFormSidePanel>
  );
};
