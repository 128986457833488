import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXADB_CDB_DELETE_POLL_DELAY,
  EXADB_CDB_DELETE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface ExaDbDeleteCdbProps extends OperationConfirmationProps {
  databaseId: string;
}

const ExaDbDeleteCdb = (
  { location, databaseId, onExecute }: ExaDbDeleteCdbProps,
): JSX.Element => {
  const { invokeCall: invokeCdbDelete } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.deleteDatabase },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
    const resGroup = decodeURIComponent(resourceGroup || "");
    const cdbName = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.deleteExaDb(),
          message: Messages.notifications.inProgress.messages.deleteExaDb(cdbName),
        },
        success: {
          title: Messages.notifications.success.titles.deleteExaDb(),
          message: Messages.notifications.success.messages.deleteExaDb(cdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.delete(),
          message: Messages.notifications.failure.messages.deleteExaDb(cdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXADB_CDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: cdbName,
          },
          location,
          errorStatusOverrides: [404],
          responseMethodKey: AsyncNotificationPolledResponseKey.EXADB_DELETED_CHECK_CDB,
          delay: EXADB_CDB_DELETE_POLL_DELAY,
          interval: EXADB_CDB_DELETE_POLL_INTERVAL,
        },
      },
    };
    invokeCdbDelete({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName: cdbName,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newExaDbDeleteCdb = (
  props: ExaDbDeleteCdbProps,
): JSX.Element => (<ExaDbDeleteCdb {...props} />);
