import {
  ActionBar,
  ActionType,
  AnchoredPanelComponent,
  FormValues,
  InputFormInline,
  SubmitButtonMode,
  TagProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { Icon, LayerHost, Link, Overlay, Stack, Text } from "@fluentui/react";
import { DeleteIconDisabledSvg, DeleteIconSvg } from "../../ApplicationIcons";
import * as Messages from "../../codegen/Messages";
import { tagsLearnMoreUrl } from "../../constants/docConstants";
import { InputFormInlineTestIds, OverlayLayerId, SvgIconIds } from "../../constants/uiConstants";
import { TagsInfoType } from "../../helpers/resourceHelper";
import { ResourceTags } from "../Tags/ResourceTags";
import { TagsCollection } from "../Tags/TagsCollection";

export enum Fields {
  Tags = "tags"
}

export enum FieldTestIds {
  Tags = "tags"
}

export enum ActionBarTestIds {
  DeleteAll = "delete-all"
}

const deleteKey = "delete";

export interface TagDetailsComponent {
  /**
     * Method to show/hide in-progress indicator above the panel's header
     * The in-progress indicator is off (hidden) at mount time
     */
  toggleInProgress: (on: boolean, panelRef: AnchoredPanelComponent | undefined) => void;
}

export interface TagDetailsProps {
  resourceId: string | undefined;
  tagsDefaultValue?: TagsInfoType;
  disabled?: boolean;
  onApply?: (formValues: FormValues) => void;
    /**
   * Component reference to toggle panel's in-progress indicator
   */
  componentRef?: (ref:TagDetailsComponent) => void;
}

const formValues2TagProps = (
  formValues: FormValues[] | undefined,
): TagProps[] | undefined => formValues?.map(entry => ({ name: entry.name, value: entry.value } as TagProps));

export const TagDetails = ({
  resourceId,
  tagsDefaultValue,
  disabled,
  onApply,
  componentRef,
}: TagDetailsProps): JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());
  const [initialValues, setInitialValues] = React.useState<FormValues>({ [Fields.Tags]: tagsDefaultValue });
  const [tagValues, setTagValues] = React.useState<TagsInfoType | undefined>(tagsDefaultValue);
  const [showOverlay, setShowOverlay] = React.useState<boolean>(false);

  const layerHostStyle: React.CSSProperties = {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    bottom: 0,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRenderTagIcon = (iconProps: any): JSX.Element => {
    const wrappedIcon = (
      <div style={{ width: "16px", height: "16px" }}>
        {iconProps.disabled ? DeleteIconDisabledSvg : DeleteIconSvg}
      </div>
    );
    return wrappedIcon;
  };

  const toggleInProgress = (on: boolean, panelRef: AnchoredPanelComponent | undefined): void => {
    setShowOverlay(on);
    if (panelRef) panelRef.toggleInProgress(on);
  };

  React.useEffect(() => {
    if (componentRef) {
      componentRef({ toggleInProgress } as TagDetailsComponent);
    }
    // Only at mount time. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (formValues: FormValues): void => {
    if (onApply) {
      onApply(formValues);
    }
  };

  const onDiscardChanges = (): void => {
    setTagValues(tagsDefaultValue);
    setKey(uniqueGUID());
  };

  const onDeleteAll = (): void => {
    setTagValues([]);
    setKey(uniqueGUID());
  };

  React.useEffect(() => {
    if (tagsDefaultValue) {
      const initFieldValues: FormValues = { [Fields.Tags]: tagsDefaultValue };

      setInitialValues(initFieldValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tagsDefaultValue)]);

  const tagsActionItems: ActionType[] = [
    {
      key: deleteKey,
      testId: ActionBarTestIds.DeleteAll,
      text: Messages.actions.deleteAll(),
      icon: SvgIconIds.deleteSvg,
      disabled: disabled || !tagValues?.length,
      onRenderIcon: onRenderTagIcon,
      onClick: onDeleteAll,
    },
  ];

  const onTagsChanged = (value: FormValues[] | undefined): void => {
    setTagValues(value as TagsInfoType || []);
  };

  return (
    <InputFormInline
      key={key}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onDiscard={onDiscardChanges}
      testId={InputFormInlineTestIds.TagDetails}
      initialValues={initialValues}
      submitButtonText={Messages.actions.apply()}
      discardButtonText={Messages.actions.discardChanges()}
      footerHasBorder
    >
      {showOverlay && (
        <LayerHost id={OverlayLayerId} style={layerHostStyle}>
          <Overlay />
        </LayerHost>
      )}
      <Stack style={{ flexBasis: 38, position: "sticky", top: "0", zIndex: "1" }}>
        <ActionBar actions={tagsActionItems} />
      </Stack>
      <Stack style={{ padding: "10px 0px 28px" }}>
        <Text>{Messages.tags.description()}</Text>
        <Link href={tagsLearnMoreUrl} target="_blank">
          <>
            <span>{Messages.tags.info.learnMore()}</span>
            <Icon style={{ paddingLeft: "5px" }} iconName="OpenInNewWindow" />
          </>
        </Link>
      </Stack>
      <div style={{ paddingBottom: 18 }}>
        <Text>{Messages.tags.note()}</Text>
      </div>
      <Stack
        tokens={{ childrenGap: 40 }}
        style={{ marginBottom: "20px", flexGrow: 1, flexBasis: 90 }}
      >
        <TagsCollection
          onChange={onTagsChanged}
          fieldName={Fields.Tags}
          testId={FieldTestIds.Tags}
          tagsDefaultValue={tagValues}
        />
        <ResourceTags
          resourceId={resourceId}
          initialValues={tagsDefaultValue}
          newValues={formValues2TagProps(tagValues)}
        />
      </Stack>
    </InputFormInline>
  );
};
