import { CalloutComponent, FormValues } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConfigCoreCountPanel, ScaleConfig } from "../../components/VmClusterConfigInput/ConfigCoreCountPanel";
import { CalloutTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMCLUSTER_EDIT_POLL_DELAY,
  VMCLUSTER_EDIT_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface ExaVmClusterScaleProps extends OperationActionProps {
  vmClusterId: string | undefined;
  scaleConfig: ScaleConfig | undefined;
}

const ExaVmClusterScale = (
  { targetId, location, vmClusterId, scaleConfig, onCancel, onExecute }: ExaVmClusterScaleProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(vmClusterId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeVmClusterUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.updateCloudVmCluster },
  );
  const [scaleCalloutRef, setScaleCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const onScale = (_: FormValues, cpuCoreCount?: number): void => {
    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: () => {
        scaleCalloutRef?.allowResubmit();
      },
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.scaleVmCluster(),
          message: Messages.notifications.inProgress.messages.scaleVmCluster(resName),
        },
        success: {
          title: Messages.notifications.success.titles.scaleVmCluster(),
          message: Messages.notifications.success.messages.scaleVmCluster(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.scale(),
          message: Messages.notifications.failure.messages.scaleVmCluster(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMCLUSTER_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            cloudVmClusterName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMCLUSTER_EDIT_CHECK,
          delay: VMCLUSTER_EDIT_POLL_DELAY,
          interval: VMCLUSTER_EDIT_POLL_INTERVAL,
        },
      },
    };

    invokeVmClusterUpdate({
      subscriptionId,
      resourceGroupName: resGroup,
      cloudVmClusterName: resName,
      updateCloudVmClusterDetails: { cpuCoreCount },
    }, invokeOptions);
  };
  return (
    <ConfigCoreCountPanel
      componentRef={setScaleCalloutRef}
      targetId={targetId}
      scaleConfig={scaleConfig}
      onSubmit={onScale}
      onClose={onCancel}
      title={Messages.createNewPanels.scaleCoreCount.title()}
      primaryButtonText={Messages.actions.scale()}
      testId={CalloutTestIds.ExaVmClusterScale}
    />
  );
};

export const newExaVmClusterScale = (
  props: ExaVmClusterScaleProps,
): JSX.Element => (<ExaVmClusterScale {...props} />);
