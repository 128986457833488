import { ActionType, Listing, ListingColumn, optimizedRetryOption } from "o4a-react";
import * as React from "react";
import { Stack, Text } from "@fluentui/react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { AdbsDatabase } from "../../gen/clients/mchub-azure-api-client-adb";
import { getConnectionStringType } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { AdbsChangePasswordProps, newAdbsChangePassword } from "../../operations/Adbs/AdbsChangePassword";
import { AdbsDownloadWalletProps, newAdbsDownloadWallet } from "../../operations/Adbs/AdbsDownloadWallet";
import { AdbsRotateWalletProps, newAdbsRotateWallet } from "../../operations/Adbs/AdbsRotateWallet";
import { getOciRegion } from "../../utils";

export enum ActionIds {
  ChangePassword = "change-password",
  DownloadWallet = "download-wallet",
  RotateWallet = "rotate-wallet",
  CopyConnectionString = "copy-connection-string",
}

export enum ActionBarTestIds {
  ChangePassword = "change-password",
  DownloadWallet = "download-wallet",
  RotateWallet = "rotate-wallet",
}

export enum ListingActionTestIds {
  CopyConnectionString = "copy-connection-string",
}

enum ColumnIds {
  ConnectionString = "connectionString",
  Type = "type",
  Id = "id",
}

export interface AdbsConnectDetailsProps {
  adbs: AdbsDatabase | undefined;
  location: string;
  resourceGroupName: string;
  subscriptionId: string;
  refresh: () => void;
}

export const AdbsConnectDetails = (
  { adbs, location, subscriptionId, resourceGroupName, refresh }: AdbsConnectDetailsProps,
): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { response: responseWallet, loading: loadingWallet } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.getAdbsDatabaseWallet,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName,
        apiVersion: MultiCloudDatabaseApiVersion,
        databaseName: adbs?.name || "",
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });

  const walletMetaData = loadingWallet ? undefined : responseWallet?.data;

  const connectionStrings: { id: string, type: string, connectionString: string }[] = [];
  adbs?.connectionStrings?.profiles?.forEach(profile => (
    connectionStrings.push({
      id: profile.displayName,
      type: getConnectionStringType(profile),
      connectionString: profile.value,
    })));

  const { trigger: triggerChangePassword } = useOperation<AdbsChangePasswordProps>(newAdbsChangePassword);
  const { trigger: triggerRotateWallet } = useOperation<AdbsRotateWalletProps>(newAdbsRotateWallet);
  const { trigger: triggerDownloadWallet } = useOperation<AdbsDownloadWalletProps>(newAdbsDownloadWallet);

  const { closePanels } = useSidePanel();

  const connectionActionItems: ActionType[] = [
    {
      key: ActionIds.DownloadWallet,
      testId: ActionBarTestIds.DownloadWallet,
      text: Messages.actions.downloadWallet(),
      icon: MonochromeIconIds.Download,
      disabled: (adbs?.status !== "Active") || (walletMetaData && walletMetaData.lifecycleState !== "ACTIVE"),
      onClick: () => {
        trackActionClick(ActionIds.DownloadWallet, PageId.ADBS_DETAILS, DetailsPanelId.CONNECT);
        triggerDownloadWallet({
          databaseId: adbs?.id,
          targetId: ActionIds.DownloadWallet,
          location,
          onCancel: () => trackActionDiscard(ActionIds.DownloadWallet, PageId.ADBS_DETAILS, DetailsPanelId.CONNECT),
        });
      },
    },
    {
      key: ActionIds.RotateWallet,
      testId: ActionBarTestIds.RotateWallet,
      text: Messages.actions.rotateWallet(),
      icon: MonochromeIconIds.Rotate,
      title: Messages.labels.rotateWallet(),
      description: Messages.hints.rotateWalletAdbsConfirmation(adbs?.name || ""),
      disabled: (adbs?.status !== "Active") || (walletMetaData && walletMetaData?.lifecycleState !== "ACTIVE"),
      onClick: () => {
        trackActionClick(ActionIds.RotateWallet, PageId.ADBS_DETAILS, DetailsPanelId.CONNECT);
      },
      onConfirm: () => {
        triggerRotateWallet({
          databaseId: adbs?.id,
          location,
          onExecute: refresh,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.RotateWallet, PageId.ADBS_DETAILS, DetailsPanelId.CONNECT),
    },
    {
      key: ActionIds.ChangePassword,
      testId: ActionBarTestIds.ChangePassword,
      text: Messages.actions.changePassword(),
      icon: MonochromeIconIds.Permissions,
      onClick: () => {
        trackActionClick(ActionIds.ChangePassword, PageId.ADBS_DETAILS, DetailsPanelId.CONNECT);
        triggerChangePassword({
          databaseId: adbs?.id,
          targetId: ActionIds.ChangePassword,
          location,
          onExecute: refresh,
          onCancel: () => trackActionDiscard(ActionIds.ChangePassword, PageId.ADBS_DETAILS, DetailsPanelId.CONNECT),
        });
      },
    },
  ];

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Id,
      name: Messages.labels.tnsName(),
      flexGrow: 2,
    },
    {
      itemProp: ColumnIds.Type,
      name: Messages.labels.type(),
      flexGrow: 1,
    },
    {
      itemProp: ColumnIds.ConnectionString,
      name: Messages.labels.connectionString(),
      flexGrow: 7,
    },
  ];

  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
      <Text variant="mediumPlus">
        {Messages.detailAdbs.connect.sectionTitles.connectionStrings()}
      </Text>
      <Listing
        testId={ListingTestIds.AdbsConnect}
        actionBarItems={connectionActionItems}
        onActionClick={closePanels}
        items={connectionStrings}
        emptyList={{ title: Messages.common.noResults() }}
        listColumns={columns}
        actions={[
          {
            key: ActionIds.CopyConnectionString,
            testId: ListingActionTestIds.CopyConnectionString,
            text: Messages.actions.copyConnectionString(),
            icon: MonochromeIconIds.Copy,
            onClick: value => {
              trackActionClick(ActionIds.CopyConnectionString, PageId.ADBS_DETAILS, DetailsPanelId.CONNECT);
              navigator.clipboard.writeText(value?.connectionString || "");
            },
          },
        ]}
      />
    </Stack>
  );
};
