import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  optimizedRetryOption,
  Select,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import {
  VmdbDbSystemShapeSummary,
  VmdbDbSystemShapeSummaryCollection,
} from "../../gen/clients/mchub-azure-api-client-vmdb/mchub-azure-api-client-vmdb";
import { IdResourceType } from "../../helpers/idHelper";
import { getShapeDisplayName, responseItemstoArray } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

export interface VmDbSystemShapeProps extends Omit<SelectProps, "options"> {
  subscriptionId: string;
  nodeCount: number;
  location: string;
  onDefaultSelect?: (shape?: VmdbDbSystemShapeSummary) => void,
  onChange?: (id: string, shape?: VmdbDbSystemShapeSummary) => void,
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const VmDbSystemShapeSelect = ({
  subscriptionId,
  defaultValue,
  fieldName,
  label = Messages.labels.vmShape(),
  placeholder,
  required,
  subField,
  tooltip,
  location,
  nodeCount,
  statusInfo,
  validator,
  onChange,
  onError,
  onDefaultSelect,
  onMissingDependencies,
  testId,
}: VmDbSystemShapeProps) : JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());
  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const form: FormState = React.useContext(FormContext);

  const { loading, response, error } = useQueryCall({
    wait: !(subscriptionId && location),
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbDbSystemShapes,
    options: {
      args: { subscriptionId },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDBSystemShape(),
      },
    },
  });

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location]);

  React.useEffect(() => {
    if (!loading) {
      setKey(uniqueGUID());
      onDefaultSelect?.(options.find(shape => shape.id === defaultValue?.[0])?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const dbSystemShapes = (response
    && responseItemstoArray<VmdbDbSystemShapeSummaryCollection, VmdbDbSystemShapeSummary>(response)) || [];

  const options = dbSystemShapes?.filter(shape => shape.shapeFamily === "VIRTUALMACHINE")?.map(shape => ({
    id: shape.name || "",
    text: getShapeDisplayName(shape.name) || "",
    data: shape,
    disabled: !(nodeCount <= (shape?.maximumNodeCount || 1)),
  }));

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!location) missingDependencies.push(IdResourceType.LOCATION);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const derivedPlaceholder = options.length === 0
    ? Messages.createCommon.loadingNone(label.toLowerCase())
    : placeholder;

  const onShapeChange = (id: string): void => {
    onChange?.(id, options.find(shape => shape.id === id)?.data);
  };

  return (
    <Select
      key={key}
      testId={testId}
      loading={loading}
      required={required}
      subField={subField}
      fieldName={fieldName}
      label={label}
      options={options}
      tooltip={tooltip}
      placeholder={derivedPlaceholder}
      defaultValue={!loading ? defaultValue : undefined}
      onClick={internalOnClick}
      onChange={onShapeChange}
      validator={validator}
      statusInfo={statusInfo}
    />
  );
};
