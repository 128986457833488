import React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { parseId } from "../../helpers/idHelper";
import { MutationCallOptions, useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  NETLNK_DELETE_POLL_DELAY,
  NETLNK_DELETE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface NetworkLinkDetachProps extends OperationConfirmationProps {
  networkLinkId: string | undefined;
  virtualNetworkName: string | undefined;
}

const NetworkLinkDetach = (
  { networkLinkId, virtualNetworkName, location, onExecute }: NetworkLinkDetachProps,
): JSX.Element => {
  const { invokeCall: invokeNetworkLinkDelete } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).netLinkApi.deleteNetworkLink },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(networkLinkId);
    const networkLinkName = decodeURIComponent(resourceName || "");
    const resourceGroupName = decodeURIComponent(resourceGroup || "");

    const invokeOptions: MutationCallOptions<Response> = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.detachVirtualNetwork(),
          message: Messages.notifications.inProgress.messages.detachVirtualNetwork(virtualNetworkName as string),
        },
        success: {
          title: Messages.notifications.success.titles.detachVirtualNetwork(),
          message: Messages.notifications.success.messages.detachVirtualNetwork(virtualNetworkName as string),
        },
        failure: {
          title: Messages.notifications.failure.titles.detach(),
          message: Messages.notifications.failure.messages.detachVirtualNetwork(virtualNetworkName as string),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.NETLNK_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            networkLinkName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.NETLNK_DELETED_CHECK,
          errorStatusOverrides: [404],
          delay: NETLNK_DELETE_POLL_DELAY,
          interval: NETLNK_DELETE_POLL_INTERVAL,

        },
      },
    };
    invokeNetworkLinkDelete({
      subscriptionId,
      resourceGroupName,
      networkLinkName,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export const newNetworkLinkDetach = (props: NetworkLinkDetachProps): JSX.Element => (
  <NetworkLinkDetach {...props} />
);
