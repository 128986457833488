import {
  CodeSnippet,
  FormContext,
  FormState,
  InfoBlock,
  InfoBlockLayout,
  InfoBlockStatus,
  InputFormGroup,
  IntegerInput,
  NoValue,
  optimizedRetryOption,
  ProgressDotsIndicator,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { defaultBackOffConfig } from "savant-connector";
import {
  Checkbox,
  CheckboxVisibility,
  DefaultButton,
  DetailsList,
  GroupHeader,
  IColumn,
  Icon,
  IDetailsGroupDividerProps,
  IDetailsGroupRenderProps,
  IGroup,
  Label,
  Stack,
} from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlFiveSecCaching, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  HeatWaveClusterMemoryEstimate,
  HeatWaveClusterMemoryEstimateStatusValues,
} from "../../gen/clients/mchub-azure-api-client-mds";
import { mdsHeatwaveMaxNode, mdsHeatwaveMinNode } from "../../helpers/validationHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

enum ResponseStatus {
  InProgress = "IN_PROGRESS",
  Accepted = "ACCEPTED",
  Succeeded = "SUCCEEDED",
  Canceling = "CANCELING",
  Canceled = "CANCELED",
  Failed = "FAILED",
}

enum ItemType {
  Schema = "SCHEMA",
  Table = "TABLE",
}

export interface NodeCountEstimatorProps {
  fieldName: string;
  subscriptionId: string;
  resourceGroupName: string;
  dbSystemName: string;
  location: string;
  nodeCountDefaultValue: number|undefined;
  testId?: string;
}

interface TableItem {
  name: string;
  memory: number;
  columns: string;
  rows: string;
  selected?: boolean;
  schemaName: string;
  error?: string;
}

interface SchemaDisabled {
  disabled: boolean
}

const gigabyte = 1024;
const terabyte = 1048576;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNullOrUndefined = (value: any): boolean => value === null || value === undefined;

const isStringNullOrEmpty = (value: string): boolean => isNullOrUndefined(value) || value === "";

const toJsonArray = (jsonArray: string): string => `JSON_ARRAY(${jsonArray})`;

const stringArrayToJsonArray = (stringArray: string[]): string => {
  const jsonArray = stringArray.reduce((prev, s): string => {
    const escapeString = s
      .replace(/\\/g, "\\\\")
      .replace(/'/g, "\\'");
    return `${prev}${isStringNullOrEmpty(prev) ? "" : ", "}'${escapeString}'`;
  }, "");
  return toJsonArray(jsonArray);
};

const toTableLoadCommand = (selectedSchema: string[], unselectedTables: string[]): string => {
  const selectedSchemaJsonArray = stringArrayToJsonArray(selectedSchema);
  const unselectedTablesJsonArray = stringArrayToJsonArray(unselectedTables);
  const areUnselectedTables = unselectedTables.length > 0;
  const excludeListCommandPortion = areUnselectedTables
    ? `JSON_OBJECT('exclude_list', ${unselectedTablesJsonArray})`
    : "NULL";
  return `CALL sys.heatwave_load(${selectedSchemaJsonArray}, ${excludeListCommandPortion});`;
};

export const NodeCountEstimator = (
  {
    fieldName,
    nodeCountDefaultValue,
    location,
    subscriptionId,
    resourceGroupName,
    dbSystemName,
    testId,
  }: NodeCountEstimatorProps,
): JSX.Element => {
  const [mySqlCommand, setMySqlCommand] = React.useState<string>();
  const [nodeCountValue, setNodeCountValue] = React.useState<number | undefined>(nodeCountDefaultValue);
  const [generateEstimateStarted, setGenerateEstimateStarted] = React.useState<boolean>(false);
  const [estimationDataValue, setEstimationDataValue] = React.useState<HeatWaveClusterMemoryEstimate>();
  const [tableItems, setTableItems] = React.useState<TableItem[]>([]);
  const [tableGroups, setTableGroups] = React.useState<IGroup[]>();
  const [generateButtonPressed, setGenerateButtonPressed] = React.useState<boolean>(tableItems.length > 0);
  const [recommendedNodeCount, setRecommendedNodeCount] = React.useState<number>(0);
  const [totalMemorySize, setTotalMemorySize] = React.useState<number>(0);
  const form: FormState = React.useContext(FormContext);
  const [key, setKey] = React.useState<string>(uniqueGUID());

  const {
    response: regenerateEstimateResponse,
    loading: regenerateEstimateLoading,
    refresh: regenerateEstimateRefresh,
    error: regenerateEstimateError,
  } = useQueryCall({
    wait: !generateEstimateStarted,
    method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.generateHeatWaveClusterMemoryEstimate,
    options: {
      args: {
        subscriptionId,
        resourceGroupName,
        dbSystemName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadSchema(),
      },
    },
  });

  const isGenerateEstimateAccepted = !regenerateEstimateError && !regenerateEstimateLoading
    && regenerateEstimateResponse?.status === 202;

  const {
    response: getEstimateResponse,
    loading: getEstimateLoading,
    refresh: getEstimateRefresh,
  } = useQueryCall({
    wait: !isGenerateEstimateAccepted,
    method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.getHeatWaveClusterMemoryEstimate,
    options: {
      args: {
        subscriptionId,
        resourceGroupName,
        dbSystemName,
      },
      caching: ttlFiveSecCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadSchema(),
      },
    },
  });

  const estimationData: HeatWaveClusterMemoryEstimate | undefined = getEstimateResponse?.data;

  const estimateDataLoading = generateEstimateStarted && (regenerateEstimateLoading || isGenerateEstimateAccepted)
    && (getEstimateLoading
        || estimationData?.status === HeatWaveClusterMemoryEstimateStatusValues.IN_PROGRESS
        || estimationData?.status === HeatWaveClusterMemoryEstimateStatusValues.ACCEPTED);

  const estimateDataCanceled = !estimateDataLoading
    && (regenerateEstimateResponse?.statusText === ResponseStatus.Canceled
      || estimationData?.status === HeatWaveClusterMemoryEstimateStatusValues.CANCELED);

  const estimateDataFailed = !estimateDataLoading
  && (regenerateEstimateResponse?.statusText === ResponseStatus.Failed
      || estimationData?.status === HeatWaveClusterMemoryEstimateStatusValues.FAILED);

  const onGenerateClick = (): void => {
    if (!generateButtonPressed) {
      setGenerateButtonPressed(true);
    }
    if (generateEstimateStarted) {
      onRegenerate();
    } else {
      setGenerateEstimateStarted(true);
    }
  };

  const onRegenerate = (): void => {
    if (!getEstimateLoading) {
      setGenerateEstimateStarted(true);
      setTimeout(() => regenerateEstimateRefresh(), 1);
      setTimeout(() => getEstimateRefresh(), defaultBackOffConfig.timeoutInMs);
    }
  };

  React.useEffect(() => {
    if (estimateDataLoading) {
      setTimeout(() => getEstimateRefresh(), defaultBackOffConfig.timeoutInMs);
    } else if (!estimateDataLoading && !estimateDataCanceled && !estimateDataFailed
      && estimationData
      && (!estimationDataValue || estimationData.timeUpdated !== estimationDataValue.timeUpdated)) {
      setGenerateEstimateStarted(false);
      setEstimationDataValue(estimationData);
      const currentTableData: TableItem[] = [];
      const currentTableGroups: IGroup[] = [];
      estimationData.tableSchemas.forEach(schema => {
        let schemaDisabled = true;
        schema.perTableEstimates.forEach(table => {
          const varlenPercent = ((table.varlenColumnCount / table.toLoadColumnCount) * 100).toFixed();
          const tableError = table.errorComment !== "";
          currentTableData.push({
            name: table.tableName,
            memory: table.analyticalFootprintInMbs,
            columns: Messages.createNewPanels.nodeCountEstimator.tableList.row.columnsCell(
              table.toLoadColumnCount.toString(),
              varlenPercent,
            ),
            rows: table.estimatedRowCount.toString(),
            schemaName: schema.schemaName,
            selected: !tableError,
            error: tableError ? table.errorComment : undefined,
          });
          if (!tableError) schemaDisabled = false;
        });
        currentTableGroups.push({
          key: schema.schemaName,
          name: schema.schemaName,
          count: schema.perTableEstimates.length,
          startIndex: currentTableData.length - schema.perTableEstimates.length,
          data: { disabled: schemaDisabled } as SchemaDisabled,
        });
      });
      setTableGroups(currentTableGroups);
      setTableItems(currentTableData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    estimationData,
    estimationDataValue,
    generateEstimateStarted,
  ]);

  const setSelected = (name: string, checked: boolean, type: ItemType): void => {
    const newTableItems = tableItems.map(value => {
      let returnVal;
      const nameToCompare = type === ItemType.Schema ? value.schemaName : value.name;
      if (nameToCompare === name && !value.error) {
        returnVal = { ...value, selected: checked };
      } else {
        returnVal = value;
      }
      return returnVal;
    });
    setTableItems(newTableItems);
  };

  const calculateRecommendedNodeCount = (totalMemory: number): void => {
    setRecommendedNodeCount(Math.ceil(totalMemory / (0.5 * terabyte)));
  };

  const getHeatWaveCommandsArgs = (): void => {
    const selectedSchema: string[] = [];
    const unselectedTables: string[] = [];

    tableItems.forEach(table => {
      if (table.selected && !selectedSchema.includes(table.schemaName)) {
        selectedSchema.push(table.schemaName);
      }
    });

    tableItems.forEach(table => {
      if (!table.selected && selectedSchema.includes(table.schemaName)) {
        unselectedTables.push(table.name);
      }
    });

    if (selectedSchema.length === 0) {
      setMySqlCommand("");
    } else {
      setMySqlCommand(toTableLoadCommand(selectedSchema, unselectedTables));
    }
  };

  const setEstimates = (): void => {
    const newTotalMemory = tableItems.filter(table => table.selected)
      .reduce((sum, current) => sum + current.memory, 0);
    setTotalMemorySize(newTotalMemory);
    calculateRecommendedNodeCount(newTotalMemory);
  };

  React.useEffect(() => {
    getHeatWaveCommandsArgs();
    setEstimates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableItems]);

  const checkBoxLabel = (props: IDetailsGroupDividerProps | undefined): JSX.Element => (
    <Stack horizontal>
      &nbsp;
      <b>{props?.group?.name}</b>
      {props?.group?.data?.disabled && (
        <span style={{ marginTop: "3px", paddingLeft: "10px" }}>
          <Icon iconName="warning-svg" />
        </span>
      )}
    </Stack>
  );

  const checkBox = (props: IDetailsGroupDividerProps | undefined): JSX.Element => (
    <Checkbox
      onRenderLabel={() => (
        checkBoxLabel(props)
      )}
      onChange={(ev, checked) => setSelected(props?.group?.name || "", checked || false, ItemType.Schema)}
      disabled={props?.group?.data?.disabled}
      checked={!props?.group?.data?.disabled}
    />
  );

  const onRenderHeader: IDetailsGroupRenderProps["onRenderHeader"] = props => (
    <GroupHeader
      onRenderTitle={() => ((
        checkBox(props)
      ))}
      {...props}
    />
  );

  const onRenderItems = (item: TableItem, index: number|undefined, column: IColumn|undefined): React.ReactNode => {
    let memoryString;
    if (column?.key === "memory") {
      memoryString = item.memory < gigabyte
        ? Messages.common.memoryMB((item.memory).toString())
        : item.memory < terabyte
          ? Messages.common.memoryGB((item.memory / gigabyte).toFixed(2))
          : Messages.common.memoryTB((item.memory / terabyte).toFixed(2));
    }
    switch (column?.key) {
      case "name":
        return (
          <Stack horizontal style={{ marginLeft: "2.5rem" }}>
            <Checkbox
              label={item.name}
              onChange={(ev, checked) => setSelected(item.name, checked || false, ItemType.Table)}
              checked={item.selected}
              disabled={!!item.error}
            />
            {item.error && (
              <span style={{ marginTop: "3px", paddingLeft: "10px" }}>
                <Icon iconName="warning-svg" />
              </span>
            )}
          </Stack>
        );
      case "memory":
        return <span>{memoryString}</span>;
      case "columns":
        return <span>{item.columns}</span>;
      case "rows":
        return <span>{item.rows}</span>;
      case "error":
        return <span>{item.error ? item.error : <NoValue />}</span>;
      default:
        return <span>No value</span>;
    }
  };

  const tableColumns : IColumn[] = [
    {
      key: "name",
      name: Messages.labels.tableName(),
      minWidth: 300,
    },
    {
      key: "memory",
      name: Messages.createNewPanels.nodeCountEstimator.memory(),
      minWidth: 75,
    },
    {
      key: "columns",
      name: Messages.createNewPanels.nodeCountEstimator.columns(),
      minWidth: 175,
    },
    {
      key: "rows",
      name: Messages.createNewPanels.nodeCountEstimator.rowCount(),
      minWidth: 75,
    },
    {
      key: "error",
      name: Messages.labels.errors(),
      minWidth: 300,
    },
  ];

  React.useEffect(() => {
    form.setValue(nodeCountValue, fieldName, InputFormGroup);
    setKey(uniqueGUID());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeCountValue]);

  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 20 }}>
      <div style={{ maxWidth: "500px" }}>
        <IntegerInput
          testId={testId}
          key={key}
          fieldName={fieldName}
          label={Messages.labels.nodeCount()}
          defaultValue={nodeCountValue}
          min={mdsHeatwaveMinNode}
          max={mdsHeatwaveMaxNode}
          required
        />
      </div>
      <DefaultButton
        text={generateButtonPressed
          ? Messages.createNewPanels.nodeCountEstimator.regenerateEstimate()
          : Messages.createNewPanels.nodeCountEstimator.generateEstimate()}
        onClick={onGenerateClick}
        style={{ width: "max-content" }}
      />
      {generateButtonPressed && (
        (getEstimateLoading || estimateDataLoading) ? (
          <div>
            <ProgressDotsIndicator />
          </div>
        ) : (
          <>
            <InfoBlock
              message={(
                <>
                  <p>
                    {Messages.createNewPanels.nodeCountEstimator.infoBlock.nodeCount(recommendedNodeCount.toString())}
                  </p>
                  <p>
                    { totalMemorySize < gigabyte
                      ? Messages.createNewPanels.nodeCountEstimator.infoBlock.memorySizeMB(totalMemorySize.toString())
                      : totalMemorySize < terabyte
                        ? Messages.createNewPanels.nodeCountEstimator.infoBlock.memorySizeGB(
                          (totalMemorySize / gigabyte).toFixed(2),
                        )
                        : Messages.createNewPanels.nodeCountEstimator.infoBlock.memorySizeTB(
                          (totalMemorySize / terabyte).toFixed(2),
                        ) }
                  </p>
                </>
              )}
              messageType={InfoBlockStatus.INFO}
              infoLayout={InfoBlockLayout.Compact}
              isMultiline={false}
              actions={(
                <DefaultButton
                  text={Messages.actions.apply()}
                  onClick={() => {
                    setNodeCountValue(recommendedNodeCount);
                  }}
                />
              )}
            />
            <Label>{Messages.createNewPanels.nodeCountEstimator.mySqlCommand.title()}</Label>
            <p style={{ marginBottom: -3, marginTop: 3 }}>
              {Messages.createNewPanels.nodeCountEstimator.mySqlCommand.description()}
            </p>
            <CodeSnippet
              text={mySqlCommand || ""}
              numberOfRows={4}
              ariaLabel={Messages.ariaLabel.mySqlCommand()}
            />
            {tableItems.length > 0 ? (
              <DetailsList
                columns={tableColumns}
                items={tableItems}
                groups={tableGroups}
                groupProps={{ onRenderHeader }}
                checkboxVisibility={CheckboxVisibility.hidden}
                onRenderItemColumn={onRenderItems}
                ariaLabelForGrid={Messages.ariaLabel.dbNodesList()}
              />
            ) : (
              <p>{Messages.createNewPanels.nodeCountEstimator.emptyList.title()}</p>
            )}
          </>
        )
      )}
    </Stack>
  );
};
