import {
  FormattedString,
  FormValues,
  InfoBlock,
  InfoBlockLayout,
  InfoBlockStatus,
  InputFormCallout,
  InputFormCalloutProps,
  IntegerInput,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { maxExaInfraStorageServerCount, minExaInfraStorageServerCount } from "../../helpers/resourceHelper";
import { useFeatures } from "../../hooks/useFeatures";

export enum Fields {
  ComputeCount = "computeCount",
  StorageCount = "storageCount"
}

export enum FieldsTestIds {
  ComputeCount = "computeCount",
  StorageCount = "storageCount"
}

export interface ExaInfraScaleConfig {
  computeCount: number | undefined;
  storageCount: number | undefined;
  minimumNodeCount: number | undefined;
  maximumNodeCount: number | undefined;
  minimumStorageCount: number | undefined;
  maximumStorageCount: number | undefined;
}

export enum InfoBlockTestIds {
  ScaleExaInfra = "scale-exa-infra-infoblock"
}

export interface ExadataInfraScalePanelProps extends
  Omit<InputFormCalloutProps, "children" | "layout" | "submitButtonMode" | "defaultButtonText" >
{
  exaInfraScaleConfig: ExaInfraScaleConfig;
}

export const ExadataInfraScalePanel = ({
  exaInfraScaleConfig,
  title,
  onClose,
  onSubmit,
  targetId,
  primaryButtonText,
  componentRef,
  testId,
}: ExadataInfraScalePanelProps): JSX.Element => {
  const { enableAddVMCapacity } = useFeatures();
  const {
    computeCount,
    storageCount,
    maximumNodeCount,
    minimumNodeCount,
    maximumStorageCount,
    minimumStorageCount,
  } = exaInfraScaleConfig;

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (exaInfraScaleConfig) {
      const initFieldValues: FormValues = {
        // eslint-disable-next-line eqeqeq
        [Fields.ComputeCount]: computeCount != undefined ? Number(computeCount).toString() : undefined,
        // eslint-disable-next-line eqeqeq
        [Fields.StorageCount]: storageCount != undefined ? Number(storageCount).toString() : undefined,
      };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exaInfraScaleConfig]);

  return (
    <InputFormCallout
      componentRef={componentRef}
      title={title || ""}
      targetId={targetId}
      onClose={onClose}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      primaryButtonText={primaryButtonText}
      testId={testId}
      initialValues={initialValues}
    >
      <>
        <IntegerInput
          testId={FieldsTestIds.ComputeCount}
          fieldName={Fields.ComputeCount}
          label={Messages.labels.databaseServers()}
          min={computeCount || minimumNodeCount}
          max={maximumNodeCount}
          defaultValue={computeCount}
          required
        />
        <IntegerInput
          testId={FieldsTestIds.StorageCount}
          fieldName={Fields.StorageCount}
          label={Messages.labels.storageServers()}
          min={storageCount || minimumStorageCount || minExaInfraStorageServerCount}
          max={maximumStorageCount || maxExaInfraStorageServerCount}
          defaultValue={storageCount}
          required
        />
      </>
      {enableAddVMCapacity && (
      <InfoBlock
        message={(FormattedString({ inputText: Messages.hints.scaleExaInfra() }) as unknown as string)}
        messageType={InfoBlockStatus.INFO}
        infoLayout={InfoBlockLayout.Compact}
        customInfoStyle={{ root: { marginBottom: "15px" } }}
        testId={InfoBlockTestIds.ScaleExaInfra}
      />
      )}
    </InputFormCallout>
  );
};
