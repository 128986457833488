import { Select, SelectOption, SelectProps } from "o4a-react";
import * as React from "react";
import { AdbWorkloadType, getWorkloadTypeDisplayName } from "../../helpers/resourceHelper";
import { useFeatures } from "../../hooks/useFeatures";

export type WorkloadSelectProps = Omit<SelectProps, "options">

export const WorkloadSelect = ({
  defaultValue,
  fieldName,
  label,
  testId,
  inputLink,
  required,
  subField,
  tooltip,
  validator,
  disabled,
  onChange,
}: WorkloadSelectProps): JSX.Element => {
  // JSON and APEX are not supported for now
  const { enableAdbsAJDWorkload, enableAdbsAPEXWorkload } = useFeatures();

  const options = Object.values(AdbWorkloadType)
    .filter(type => {
      if (enableAdbsAJDWorkload && enableAdbsAPEXWorkload) return true;
      if (enableAdbsAJDWorkload) return type !== AdbWorkloadType.APEX;
      if (enableAdbsAPEXWorkload) return type !== AdbWorkloadType.JSON;
      return type !== AdbWorkloadType.APEX && type !== AdbWorkloadType.JSON;
    }).map(workload => ({
      id: workload,
      text: getWorkloadTypeDisplayName(workload),
    } as SelectOption));

  return (
    <Select
      fieldName={fieldName}
      onChange={onChange}
      options={options}
      label={label}
      testId={testId}
      required={required}
      subField={subField}
      inputLink={inputLink}
      defaultValue={defaultValue}
      tooltip={tooltip}
      disabled={disabled}
      validator={validator}
      hideSearchBox
    />
  );
};
