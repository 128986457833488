import { InfoBlock, InfoBlockProps } from "o4a-react";
import * as React from "react";
import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";

export type ConfirmationDialogInfoBlockProps = Pick <InfoBlockProps, "message" | "messageType">

export interface ConfirmationDialogProps {
  title: string;
  children: JSX.Element | JSX.Element[];
  onSubmit: () => void;
  onClose: () => void;
  infoBlock?: ConfirmationDialogInfoBlockProps;
  submitButtonText?: string;
  cancelButoonText?: string;
}

export const ConfirmationDialog = ({
  title,
  children,
  onSubmit,
  onClose,
  infoBlock,
  submitButtonText = Messages.actions.submit(),
  cancelButoonText = Messages.common.cancel(),
}: ConfirmationDialogProps): JSX.Element => (
  <Dialog
    dialogContentProps={{ title }}
    modalProps={{ isBlocking: false }}
    hidden={false}
    styles={{ main: [{ selectors: { "@media (min-width: 480px)": { minWidth: "550px" } } }] }}
  >
    {infoBlock && <InfoBlock message={infoBlock.message} messageType={infoBlock.messageType} />}
    {children}
    <DialogFooter>
      <PrimaryButton onClick={onSubmit} text={submitButtonText} />
      <DefaultButton onClick={onClose} text={cancelButoonText} />
    </DialogFooter>
  </Dialog>
);
