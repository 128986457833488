import {
  FormInputGroup,
  FormInputGroupLayout,
  FormValues,
  getValue,
  InputFormGroup,
  InputFormInline,
  SelectOption,
  SubmitButtonMode,
  TextInput,
  uniqueGUID,
  useResizeObserver,
} from "o4a-react";
import * as React from "react";
import { mergeStyleSets, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { Settings, SettingsContext, SettingType } from "../../console/SettingsContext";
import { DetailsPanelId, InlineFormId, PageId } from "../../constants/pluginConstants";
import { InputFormInlineTestIds } from "../../constants/uiConstants";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { RegionSelect } from "../RegionSelect/RegionSelect";
import { SubscriptionSelect } from "../SubscriptionSelect/SubscriptionSelect";

export enum Fields {
  DefaultSubscription = "defaultAzureSubscriptionId",
  Subscription = "subscription",
  Region = "region",
}

export enum FieldTestIds {
  DefaultSubscription = "defaultAzureSubscriptionId",
  Subscription = "subscription",
  Region = "region",
}

const subscriptionFieldNameFormatter = (index: number): string => `${Fields.Subscription}-${index}`;
const locationFieldNameFormatter = (index: number): string => `${Fields.Region}-${index}`;

const classNames = mergeStyleSets({
  subscriptionSelect: {
    minWidth: "107px",
    marginBottom: "2rem",
    maxWidth: "26.6rem",
  },
  subscription: {
    width: "calc(100% - 1rem)",
    gridColumn: "1 / 2",
    minWidth: "107px",
    maxWidth: "404px",
  },
  regionSelect: {
    width: "calc(100% - 1rem)",
    gridColumn: "2 / 3",
    minWidth: "107px",
    maxWidth: "404px",
    marginLeft: "0.6rem",
  },
});

export type DefaultFormValuesType = {
  field: string;
  value: ({ id: string, text: string } | string | undefined)[] | undefined
}

export const SettingSubscriptionsLocations = (): JSX.Element => {
  const { trackFormSave } = useAnalytics();
  const {
    preferredSubscription,
    preferredLocationPerSubscription,
    setSettingValue,
  } = React.useContext<Settings>(SettingsContext);
  const filterState = React.useContext<FilterState>(FilterStateContext);
  const [key, setKey] = React.useState(uniqueGUID());
  const { subscriptions } = useSubscriptions();
  const [initialValues, setInitialValues] = React.useState<FormValues>();

  const ref = React.useRef(null);
  const { width: formWidth } = useResizeObserver(ref);
  React.useEffect(() => {
    if (subscriptions?.length) {
      const initValues: FormValues = {
        [Fields.DefaultSubscription]: preferredSubscription
          ? [preferredSubscription]
          : undefined,
      };

      subscriptions.forEach((subscription, index) => {
        initValues[subscriptionFieldNameFormatter(index)] = subscription.name;
        initValues[locationFieldNameFormatter(index)] = preferredLocationPerSubscription[subscription.id]
          ? [preferredLocationPerSubscription[subscription.id]]
          : undefined;
      });

      setInitialValues(initValues);
      setKey(uniqueGUID());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions)]);

  const onSubmit = (formValues: FormValues): void => {
    const defaultSubId = getValue<SelectOption[]>(
      formValues,
      Fields.DefaultSubscription,
      InputFormGroup,
    )?.[0].id as string;

    const newLocationsPerSubscription: Settings["preferredLocationPerSubscription"] = {};
    const initValues: FormValues = { [Fields.DefaultSubscription]: defaultSubId ? [defaultSubId] : undefined };

    for (let i = 0; i < (subscriptions?.length ?? 0); i++) {
      const subscriptionName = getValue<string>(formValues, subscriptionFieldNameFormatter(i), InputFormGroup);
      const subscriptionId = subscriptions?.find(sub => sub.name === subscriptionName)?.id;
      const locationId = getValue<SelectOption[]>(formValues, locationFieldNameFormatter(i), InputFormGroup)?.[0].id;

      if (subscriptionId) {
        newLocationsPerSubscription[subscriptionId] = locationId;
        initValues[subscriptionFieldNameFormatter(i)] = subscriptionName;
        initValues[locationFieldNameFormatter(i)] = [locationId];
      }
    }

    setSettingValue(SettingType.PREFERRED_SUBSCRIPTION, defaultSubId);
    setSettingValue(SettingType.PREFERRED_LOCATION_PER_SUBSCRIPTION, newLocationsPerSubscription);
    filterState.setDefaultSubscription(defaultSubId);
    filterState.setDefaultLocation(newLocationsPerSubscription[defaultSubId]);

    setInitialValues(initValues);
    setKey(uniqueGUID());
  };

  const formInputGroups = subscriptions?.map((subscription, index) => (
    <div
      ref={ref}
      // eslint-disable-next-line react/no-array-index-key
      key={subscription.id + index}
    >
      <FormInputGroup
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: formWidth < 500 ? "13rem 12.5rem" : "15rem 12rem",
          gridTemplateRows: "100%",
          justifyItems: "left",
          alignContent: "center",
        }}
        layout={FormInputGroupLayout.COMPACT}
        groupName={InputFormGroup}
      >
        <Stack className={classNames.subscription}>
          <TextInput
            disabled
            defaultValue={subscription.name}
            // When this is not included and the page is refreshed
            // the subscription.id is shown instead
            key={subscription.name}
            label={index === 0 ? Messages.labels.subscription() : undefined}
            fieldName={subscriptionFieldNameFormatter(index)}
            ariaLabel={Messages.labels.subscription()}
          />
        </Stack>
        <Stack className={classNames.regionSelect}>
          <RegionSelect
            required
            subscriptionId={subscription.id}
            fieldName={locationFieldNameFormatter(index)}
            tooltip={Messages.hints.toolTipRegion()}
            ariaLabel={Messages.labels.defaultLocation()}
            label={index === 0 ? Messages.labels.defaultLocation() : undefined}
            defaultValue={
              preferredLocationPerSubscription[subscription.id]
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ? [preferredLocationPerSubscription[subscription.id]!]
                : undefined
            }
          />
        </Stack>
      </FormInputGroup>
    </div>
  ));

  return (
    <InputFormInline
      key={key}
      initialValues={initialValues}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={(formValues: FormValues) => {
        trackFormSave(InlineFormId.SETTING_SUBSCRIPTIONS, PageId.SETTINGS, DetailsPanelId.SUBSCRIPTIONS);
        onSubmit(formValues);
      }}
      onDiscard={() => setKey(uniqueGUID())}
      submitButtonText={Messages.actions.apply()}
      discardButtonText={Messages.actions.discardChanges()}
      testId={InputFormInlineTestIds.SettingSubscriptionsLocations}
    >
      <Stack className={classNames.subscriptionSelect}>
        <SubscriptionSelect
          required
          testId={FieldTestIds.DefaultSubscription}
          fieldName={Fields.DefaultSubscription}
          label={Messages.labels.defaultSubscription()}
          ariaLabel={Messages.labels.defaultSubscription()}
          defaultValue={preferredSubscription ? [preferredSubscription] : undefined}
        />
      </Stack>
      {formInputGroups}
    </InputFormInline>
  );
};
