import { cidrRange, cidrValid, isValidAddress, SelectOption, stateT } from "o4a-react";
import { createBackOffDelay, SerializableResponseWithData } from "savant-connector";
import * as Messages from "../codegen/Messages";
import {
  DetailsPanelId,
  ORACLE_ADBS_PROVIDER_PATH,
  ORACLE_DB_PROVIDER_PATH,
  ORACLE_DEPLOYMENT_PROVIDER_PATH,
  ORACLE_MYSQL_PROVIDER_PATH,
  ORACLE_NET_PROVIDER_PATH,
  ORACLE_VMDB_PROVIDER_PATH,
  PageId,
  ServiceProviders,
} from "../constants/pluginConstants";
import { SvgIconIds } from "../constants/uiConstants";
import {
  AzureCloudLinkOciTenancyPaymentModelEnum,
  AzureCloudLinkSummary,
  DeploymentLifecycleStateEnum,
  TenancySubscriptionTypeEnum,
  TicketLifecycleStateValues,
} from "../gen/clients/mchub-azure-api-client";
import {
  AdbDatabaseConnectionStringProfile,
  AdbsDatabaseBaseOpenModeEnum,
} from "../gen/clients/mchub-azure-api-client-adb";
import {
  BackupSummaryTypeEnum,
  CreateDatabaseBase,
  DbBackupConfigAutoBackupWindowEnum,
  DbSystemShapeSummary,
  PluggableDatabaseOpenModeEnum,
} from "../gen/clients/mchub-azure-api-client-exa";
import { MdsBackupCreationTypeEnum } from "../gen/clients/mchub-azure-api-client-mds";
import {
  ActivityItemActivityTypeEnum,
  CreateIncidentDetailsProblemTypeEnum,
  CreateIncidentDetailsSeverityEnum,
  CreateLimitItemDetailsServiceCategoryEnum,
  IncidentProblemTypeEnum,
  ServiceCategoryValues,
  TicketLifecycleDetailsEnum,
  TicketSeverityEnum,
} from "../gen/clients/mchub-azure-api-client-platform";
import {
  VmdbBackupSummaryTypeEnum,
  VmdbDbSystemDatabaseEditionEnum,
  VmdbDbSystemShapeSummary,
  VmdbDbSystemStorageVolumePerformanceModeEnum,
  VmdbPluggableDatabaseOpenModeEnum,
} from "../gen/clients/mchub-azure-api-client-vmdb";
import { getOciRegion } from "../utils";
import { IdResourceType } from "./idHelper";
import { utc } from "./timeHelper";

export type TagInfoType = { name: string; value?: string; resources?: SelectOption[]; }
export type TagsInfoType = TagInfoType[]

export enum OciResourceType {
  ADBS = "adbs",
  APEX = "apex",
  EXA_CDB = "exa-cdb",
  EXA_PDB = "exa-pdb",
  EXA_DBHOME = "exa-dbhome",
  EXA_VM_CLUSTER = "exa-vm-cluster",
  EXA_INFRA = "exa-infra",
  HOME = "home",
  VMDB_CDB = "vmdb-cdb",
  VMDB_PDB = "vmdb-pdb",
  VMDB_SYSTEM = "vmdb-system",
  MDS = "mds",
  VCN = "vcn",
  SUBNET = "subnet",
}

export const disableRetry = {
  nextRetryDelay: createBackOffDelay(
    { retries: -1, timeoutInMs: 0 },
    () => false,
  ),
};

export const getSupportTicketStatus = (lifecycleState: string): string => {
  if (lifecycleState === TicketLifecycleStateValues.ACTIVE) {
    return Messages.listSupport.status.open();
  }
  return stateT(lifecycleState);
};

export function supportTicketStatusSortComparator<T extends { lifecycleState?: string }>(
  aValue: T,
  bValue: T,
  locale: string,
): 0 | -1 | 1 {
  const aStatus = getSupportTicketStatus(aValue?.lifecycleState || "");
  const bStatus = getSupportTicketStatus(bValue?.lifecycleState || "");
  const comparison = aStatus.localeCompare(bStatus, locale);
  return comparison as 1 | -1 | 0;
}

export function statusSortComparator<T extends { status?: string; lifecycleState?: string }>(
  aValue: T,
  bValue: T,
  locale: string,
): 0 | -1 | 1 {
  const aStatus = stateT(aValue?.status || aValue?.lifecycleState || "");
  const bStatus = stateT(bValue?.status || bValue?.lifecycleState || "");
  const comparison = aStatus.localeCompare(bStatus, locale);
  return comparison as 1 | -1 | 0;
}

export function mcvcnStatusSortComparator<T extends { status?: string; isUsable?: boolean } >(
  aValue: T,
  bValue: T,
  locale: string,
): 0 | -1 | 1 {
  const aStatus = getMcvcnStatus(aValue.status as string, aValue.isUsable as boolean);
  const bStatus = getMcvcnStatus(bValue.status as string, bValue.isUsable as boolean);
  const comparison = aStatus.localeCompare(bStatus, locale);
  return comparison as 1 | -1 | 0;
}
export function backupTypeSortComparator<T extends { backupType :BackupSummaryTypeEnum}>(
  aValue: T,
  bValue: T,
  locale: string,
): 0 | -1 | 1 {
  const aBackupType = getBackupTypeStr(aValue.backupType);
  const bBackupType = getBackupTypeStr(bValue.backupType);
  const comparison = aBackupType.localeCompare(bBackupType, locale);
  return comparison as 1 | -1 | 0;
}

export function incidentSeveritySortComparator<T extends { severity?: TicketSeverityEnum }>(
  aValue: T,
  bValue: T,
  locale: string,
): 0 | -1 | 1 {
  if (aValue.severity && bValue.severity) {
    const aSeverity = getIncidentSeverity(aValue.severity);
    const bSeverity = getIncidentSeverity(bValue.severity);
    const comparison = aSeverity.localeCompare(bSeverity, locale);
    return comparison as 1 | -1 | 0;
  }
  if (!aValue.severity && !bValue.severity) return 0;
  if (!aValue.severity) return -1;
  return 1; // if !b.severity
}

export function problemTypeSortComparator<T extends { problemType?: IncidentProblemTypeEnum }>(
  aValue: T,
  bValue: T,
  locale: string,
): 0 | -1 | 1 {
  if (aValue.problemType && bValue.problemType) {
    const aIncidentType = getIncidentProblemType(aValue.problemType);
    const bIncidentType = getIncidentProblemType(bValue.problemType);
    const comparison = aIncidentType.localeCompare(bIncidentType, locale);
    return comparison as 1 | -1 | 0;
  }
  if (!aValue.problemType && !bValue.problemType) return 0;
  if (!aValue.problemType) return -1;
  return 1; // if !b.problemType
}

export function workloadTypeSortComparator<T extends { dbWorkload: AdbWorkloadType}>(
  aValue: T,
  bValue: T,
  locale: string,
): 0 | -1 | 1 {
  const aWorkloadType = getWorkloadTypeDisplayName(aValue.dbWorkload || "");
  const bWorkloadType = getWorkloadTypeDisplayName(bValue.dbWorkload || "");
  const comparison = aWorkloadType.localeCompare(bWorkloadType, locale);
  return comparison as 1 | -1 | 0;
}

export const getDeploymentTypeString = (value: string): string => {
  switch (value) {
    case "EXADATA":
      return Messages.serviceProviders.exadataDedicated();
    case "VMDB":
      return Messages.serviceProviders.vmDatabase();
    case "HEATWAVE":
      return Messages.serviceProviders.mySqlHeatwave();
    case "ADBS":
      return Messages.serviceProviders.adbShared();
    default:
      return value;
  }
};

export const sortDeploymentTypes = <T extends {deploymentType: string}>(
  aType: T,
  bType: T,
  locale: string,
): 0 | -1 | 1 => {
  const aTypeStr = getDeploymentTypeString(aType.deploymentType);
  const bTypeStr = getDeploymentTypeString(bType.deploymentType);
  const comparison = aTypeStr.toLowerCase().localeCompare(bTypeStr.toLowerCase(), locale);
  return comparison as 1 | -1 | 0;
};

export const getDeploymentDuration = <T extends {timeCreated: Date, timeFinished?: Date}>(value: T): number => {
  let duration = 0;
  if (value?.timeCreated) {
    const start = (new Date(value.timeCreated)).getTime();
    const end = value.timeFinished ? (new Date(value.timeFinished)).getTime() : Date.now();
    duration = end - start;
  }
  return duration;
};

export const sortDeploymentDuration = <T extends {timeCreated: Date, timeFinished?: Date}>(a: T, b: T): 0 | -1 | 1 => {
  const aDuration = getDeploymentDuration(a);
  const bDuration = getDeploymentDuration(b);
  return aDuration - bDuration > 0 ? 1 : aDuration - bDuration < 0 ? -1 : 0;
};

export enum CloudlinkStatusEnum {
  LINKED = "LINKED",
  UNLINKED = "UNLINKED",
  CREATING = "CREATING",
  UPDATING = "UPDATING",
  FAILED = "FAILED"
}

export interface CloudlinkLinkStatus {
 status: CloudlinkStatusEnum;
 message?: string;
}

export const getCloudlinkLinkStatus = (
  subscriptionId: string,
  cloudLink?: AzureCloudLinkSummary,
): CloudlinkLinkStatus => {
  if (!cloudLink) {
    // if no cloud link exists for the subscription id then it is unlinked
    return { status: CloudlinkStatusEnum.UNLINKED };
  }

  if (cloudLink.lastOperation) {
    // If the id is not in the main array but is in the last operation array then the last operation is
    // acting on this id
    if (
      !cloudLink.subscriptionIds?.includes(subscriptionId)
      && cloudLink?.lastOperation.subscriptionIds?.includes(subscriptionId)
    ) {
      switch (cloudLink.lastOperation.status) {
        case "SUCCEEDED":
          return { status: CloudlinkStatusEnum.LINKED };

        case "IN_PROGRESS":
          return { status: CloudlinkStatusEnum.CREATING };

        case "FAILED":
        default:
          return {
            status: CloudlinkStatusEnum.FAILED,
            message: cloudLink?.lastOperation?.message
              ? Messages.listCloudLink.messages.lastOperationMessage(
                cloudLink.lastOperation.message || "",
                new Date(cloudLink.lastOperation.timeUpdated).toLocaleString(),
              ) : undefined,
          };
      }
    }

    switch (cloudLink.lifecycleState) {
      case "ACTIVE":
      case "UPDATING":
        return { status: CloudlinkStatusEnum.LINKED };

      case "CREATING":
        return { status: CloudlinkStatusEnum.CREATING };

      case "DELETING":
      case "DELETED":
      case "FAILED":
      default:
        return { status: CloudlinkStatusEnum.UNLINKED };
    }
  }

  // Old functionality without a last operation
  switch (cloudLink.lifecycleState) {
    case "ACTIVE":
      return { status: CloudlinkStatusEnum.LINKED };

    case "UPDATING":
      return { status: CloudlinkStatusEnum.UPDATING };

    case "CREATING":
      return { status: CloudlinkStatusEnum.CREATING };

    case "DELETING":
    case "DELETED":
    case "FAILED":
    default:
      return { status: CloudlinkStatusEnum.UNLINKED };
  }
};

export const getCloudlinkStatusStr = (status: string): string => {
  switch (status) {
    case CloudlinkStatusEnum.LINKED:
      return Messages.subscriptionStatus.linked();
    case CloudlinkStatusEnum.UPDATING:
      return Messages.subscriptionStatus.updating();
    case CloudlinkStatusEnum.CREATING:
      return Messages.subscriptionStatus.linking();
    case CloudlinkStatusEnum.UNLINKED:
      return Messages.subscriptionStatus.unlinked();
    case CloudlinkStatusEnum.FAILED:
      return Messages.subscriptionStatus.failed();
    default:
      return status;
  }
};

export function cloudlinkStatusSortComparator<T extends { status?: string; lifecycleState?: string }>(
  aValue: T,
  bValue: T,
  locale: string,
): 0 | -1 | 1 {
  const aStatus = getCloudlinkStatusStr(aValue?.status || aValue?.lifecycleState || "");
  const bStatus = getCloudlinkStatusStr(bValue?.status || bValue?.lifecycleState || "");
  const comparaison = aStatus.localeCompare(bStatus, locale);
  return comparaison > 0 ? 1 : comparaison < 0 ? -1 : 0;
}

export const minCidrPrefix = 16;
export const maxNetworkLinkCidrPrefix = 28;
export const maxMcvcnCidrPrefix = 28;

export const minExaInfraStorageServerCount = 3;
export const maxExaInfraStorageServerCount = 64;

export const adbsMinCPUCount = 1;
export const adbsMaxCPUCount = 128;
export const adbsMinStorage = 1;
export const adbsMaxStorage = 128;
export const adbsDbEditionSEOcpuMax = 8;

export const mdsBackupDefaultRetentionInDays = 365;
export const mdsBackupPolicyMinRetentionInDays = 1;
export const mdsBackupPolicyMaxRetentionInDays = 35;
export const mdsBackupPolicyDefaultRetentionInDays = 7;
export const mdsDataStorageSizeDefaultValue = 1024;
export const mdsDataStorageSizeMaxValue = 65536;
export const mdsDataStorageSizeMinValue = 50;
export const heatwaveNodeMinValue = 1;
export const heatwaveNodeMaxValue = 64;

export const backupRetentionPeriods = {
  sevenDaysPeriod: 7,
  fifteenDaysPeriod: 15,
  thirtyDaysPeriod: 30,
  fortyFiveDaysPeriod: 45,
  sixtyDaysPeriod: 60,
};

export enum BackupRestoreType {
  LATEST = "LATEST",
  TIMESTAMP = "TIMESTAMP",
  SCN = "SCN"
}

export enum ACCESS_TYPES {
  EVERYWHERE = "EVERYWHERE",
  ALLOWED_IPS = "ALLOWED_IPS",
  PRIVATE = "PRIVATE",
  PRIVATE_PEERING = "PRIVATE_PEERING",
}

export enum SOFTWARE_EDITION_TYPE {
  STANDARD_EDITION = "STANDARD_EDITION",
  ENTERPRISE_EDITION = "ENTERPRISE_EDITION",
  ENTERPRISE_EDITION_HIGH_PERFORMANCE = "ENTERPRISE_EDITION_HIGH_PERFORMANCE",
  ENTERPRISE_EDITION_EXTREME_PERFORMANCE = "ENTERPRISE_EDITION_EXTREME_PERFORMANCE"
}

export enum LICENSE_TYPE {
  LICENSE_INCLUDED = "LICENSE_INCLUDED",
  BRING_YOUR_OWN_LICENSE = "BRING_YOUR_OWN_LICENSE",
}

export enum DATABASE_EDITION_TYPE {
  STANDARD_EDITION = "STANDARD_EDITION",
  ENTERPRISE_EDITION = "ENTERPRISE_EDITION",
}

export enum STORAGE_PERFORMANCE_MODE {
  BALANCED = "BALANCED",
  HIGH_PERFORMANCE = "HIGH_PERFORMANCE",
}

interface Schedule {
  id: DbBackupConfigAutoBackupWindowEnum;
}

interface DbSystemEdition {
  id: VmdbDbSystemDatabaseEditionEnum;
}

interface VmdbDbSystemStorageVolumePerformanceMode {
  id: VmdbDbSystemStorageVolumePerformanceModeEnum;
}

export interface FutureMcvcn {
  name: string;
  azureAttachedNetworkId: string;
  customerNvaIpAddress?: string;
}

/**
 * The details required to create a new MCVCN from a new OCI VCN
 */
export interface FutureMcvcnNew extends FutureMcvcn {
  ociVcnCidrBlocks: string[];
}

/**
 * The details required to create a new MCVCN from a pre-existing OCI VCN
 */
export interface FutureMcvcnExisting extends FutureMcvcn {
  ociVcnOcid: string;
  ociSubnetOcids: string[]
}

const getLocaleTimeRange = (startTime: Date, endTime: Date, locale: string): string => {
  const timeFormater = Intl.DateTimeFormat(
    locale,
    { timeZone: utc, hour: "2-digit", minute: "2-digit" },
  );
  const startTimeRange = timeFormater.format(startTime);
  const endTimeRange = timeFormater.format(endTime);
  return (`${startTimeRange} - ${endTimeRange}`);
};

export const getBackUpScheduleTimeRange = (slot: string, locale: string): string => {
  switch (slot) {
    case "SLOT_ONE": return getLocaleTimeRange(new Date(0), new Date(2 * 60 * 60 * 1000), locale);
    case "SLOT_TWO": return getLocaleTimeRange(new Date(2 * 60 * 60 * 1000), new Date(4 * 60 * 60 * 1000), locale);
    case "SLOT_THREE": return getLocaleTimeRange(new Date(4 * 60 * 60 * 1000), new Date(6 * 60 * 60 * 1000), locale);
    case "SLOT_FOUR": return getLocaleTimeRange(new Date(6 * 60 * 60 * 1000), new Date(8 * 60 * 60 * 1000), locale);
    case "SLOT_FIVE": return getLocaleTimeRange(new Date(8 * 60 * 60 * 1000), new Date(10 * 60 * 60 * 1000), locale);
    case "SLOT_SIX": return getLocaleTimeRange(new Date(10 * 60 * 60 * 1000), new Date(12 * 60 * 60 * 1000), locale);
    case "SLOT_SEVEN": return getLocaleTimeRange(new Date(12 * 60 * 60 * 1000), new Date(14 * 60 * 60 * 1000), locale);
    case "SLOT_EIGHT": return getLocaleTimeRange(new Date(14 * 60 * 60 * 1000), new Date(16 * 60 * 60 * 1000), locale);
    case "SLOT_NINE": return getLocaleTimeRange(new Date(16 * 60 * 60 * 1000), new Date(18 * 60 * 60 * 1000), locale);
    case "SLOT_TEN": return getLocaleTimeRange(new Date(18 * 60 * 60 * 1000), new Date(20 * 60 * 60 * 1000), locale);
    case "SLOT_ELEVEN": return getLocaleTimeRange(new Date(20 * 60 * 60 * 1000), new Date(22 * 60 * 60 * 1000), locale);
    case "SLOT_TWELVE": return getLocaleTimeRange(new Date(22 * 60 * 60 * 1000), new Date(24 * 60 * 60 * 1000), locale);
    default: return slot;
  }
};

export const backupSlots = [
  { id: "SLOT_ONE" } as Schedule,
  { id: "SLOT_TWO" } as Schedule,
  { id: "SLOT_THREE" } as Schedule,
  { id: "SLOT_FOUR" } as Schedule,
  { id: "SLOT_FIVE" } as Schedule,
  { id: "SLOT_SIX" } as Schedule,
  { id: "SLOT_SEVEN" } as Schedule,
  { id: "SLOT_EIGHT" } as Schedule,
  { id: "SLOT_NINE" } as Schedule,
  { id: "SLOT_TEN" } as Schedule,
  { id: "SLOT_ELEVEN" } as Schedule,
  { id: "SLOT_TWELVE" } as Schedule];

export const mysqlBackupSlots = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
export enum AdbWorkloadType {
  TransactionProcessing = "OLTP",
  DataWarehouse = "DW",
  JSON = "AJD",
  APEX = "APEX",
}

export const getWorkloadTypeDisplayName = (workloadType: AdbWorkloadType): string => {
  switch (workloadType) {
    case AdbWorkloadType.TransactionProcessing: return Messages.workloadTypes.transactionProcessing();
    case AdbWorkloadType.DataWarehouse: return Messages.workloadTypes.dataWarehouse();
    case AdbWorkloadType.JSON: return Messages.workloadTypes.json();
    case AdbWorkloadType.APEX: return Messages.workloadTypes.apex();
    default: return workloadType;
  }
};

export const getNetworkAccessTypeDisplayName = (type: ACCESS_TYPES): string => {
  switch (type) {
    case ACCESS_TYPES.EVERYWHERE: return Messages.labels.accessFromEverywhere();
    case ACCESS_TYPES.ALLOWED_IPS: return Messages.labels.accessFromAllowedIPs();
    case ACCESS_TYPES.PRIVATE: return Messages.labels.accessFromAzurePrivateEndpoint();
    case ACCESS_TYPES.PRIVATE_PEERING: return Messages.labels.accessFromAzurePrivateEndpointPeering();
    default: return type;
  }
};

export const vmSoftwareEdition = [
  { id: SOFTWARE_EDITION_TYPE.STANDARD_EDITION } as DbSystemEdition,
  { id: SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION } as DbSystemEdition,
  { id: SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION_HIGH_PERFORMANCE } as DbSystemEdition,
  { id: SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION_EXTREME_PERFORMANCE } as DbSystemEdition];

export const getSoftwareEditionDisplayName = (softwareEdition: string): string => {
  switch (softwareEdition) {
    case SOFTWARE_EDITION_TYPE.STANDARD_EDITION: return Messages.softwareEditionOptions.standardEdition();
    case SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION: return Messages.softwareEditionOptions.enterpriseEdition();
    case SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION_HIGH_PERFORMANCE:
      return Messages.softwareEditionOptions.enterpriseEditionHighPerformance();
    case SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION_EXTREME_PERFORMANCE:
      return Messages.softwareEditionOptions.enterpriseEditionExtremePerformance();
    default: return softwareEdition;
  }
};

export const storagePerformanceModes = [
  { id: STORAGE_PERFORMANCE_MODE.BALANCED } as VmdbDbSystemStorageVolumePerformanceMode,
  { id: STORAGE_PERFORMANCE_MODE.HIGH_PERFORMANCE } as VmdbDbSystemStorageVolumePerformanceMode,
];

export const getStoragePerformanceModeDisplayName = (mode: string): string => {
  switch (mode) {
    case STORAGE_PERFORMANCE_MODE.BALANCED: return Messages.storagePerformanceModeOptions.balanced();
    case STORAGE_PERFORMANCE_MODE.HIGH_PERFORMANCE: return Messages.storagePerformanceModeOptions.higherPerformance();
    default: return mode;
  }
};

export const getDbSystemDatabaseEditionDisplayName = (databaseEdition: string): string => {
  switch (databaseEdition) {
    case SOFTWARE_EDITION_TYPE.STANDARD_EDITION: return Messages.dbSystemDatabaseEditionOptions.standardEdition();
    case SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION: return Messages.dbSystemDatabaseEditionOptions.enterpriseEdition();
    case SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION_HIGH_PERFORMANCE:
      return Messages.dbSystemDatabaseEditionOptions.enterpriseEditionHighPerformance();
    case SOFTWARE_EDITION_TYPE.ENTERPRISE_EDITION_EXTREME_PERFORMANCE:
      return Messages.dbSystemDatabaseEditionOptions.enterpriseEditionExtremePerformance();
    default: return databaseEdition;
  }
};

export const getShapeDisplayName = (shape: string): string => {
  switch (shape) {
    case "Exadata.X9M": return "X9M";
    case "Exadata.X8M": return "X8M-2";
    case "Exadata.Quarter3.100": return "X8-2 Quarter Rack";
    case "Exadata.Half3.200": return "X8-2 Half Rack";
    case "Exadata.Full3.400": return "X8-2 Full Rack";
    case "Exadata.Quarter2.92": return "X7-2 Quarter Rack";
    case "Exadata.Half2.184": return "X7-2 Half Rack";
    case "Exadata.Full2.368": return "X7-2 Full Rack";
    case "Exadata.Quarter1.84": return "X6-2 Quarter Rack";
    case "Exadata.Half1.168": return "X6-2 Half Rack";
    case "Exadata.Full1.336": return "X6-2 Full Rack";
    case "Exadata.Base.48": return "Exadata Base";
    default: return shape;
  }
};

const defaultMaxCoreCountPerNode = 2;
const defaultMinCoreCountPerNode = 2;

export const isFlexDatabaseShape = (shape?: DbSystemShapeSummary)
  : boolean => (shape?.coreCountIncrement === undefined || shape?.coreCountIncrement === null);

export const isFlexVmdbShape = (shape?: VmdbDbSystemShapeSummary)
  : boolean => (shape?.minimumCoreCount !== shape?.availableCoreCount);

export const getDefaultCoreCountPerNode = (
  shape?: DbSystemShapeSummary,
): number => {
  if (shape && shape.runtimeMinimumCoreCount) {
    return (shape.runtimeMinimumCoreCount / (shape.minimumNodeCount || 1));
  }
  return defaultMinCoreCountPerNode;
};

export const isZeroCoreCountPerNodeAllowed = (
  shape?: DbSystemShapeSummary,
): boolean => (
  (shape?.minCoreCountPerNode === 0)
);

export const getMinCoreCountPerNode = (
  shape?: DbSystemShapeSummary,
): number => {
  if (shape && (shape.minCoreCountPerNode === null || shape.minCoreCountPerNode === undefined)) {
    return getDefaultCoreCountPerNode(shape);
  }
  if (shape && shape.minCoreCountPerNode && shape.minCoreCountPerNode > 0) {
    return shape.minCoreCountPerNode;
  }
  // shape is null/undefined or minCoreCountPerNode is 0
  // minimum is the default but 0 is also allowed
  return getDefaultCoreCountPerNode(shape);
};

export const getMaxCoreCountPerNode = (shape?: DbSystemShapeSummary): number => (
  shape?.availableCoreCountPerNode
    || ((shape?.availableCoreCount || 0) / (shape?.maximumNodeCount || 1))
    || defaultMaxCoreCountPerNode
);

export enum ResourceType {
  APEX = "APEX",
  AUTONOMOUS_DATABASE = "AUTONOMOUS_DATABASE",
  EXACS = "exadataOnAzureInstance",
  VM = "VM",
  DATABASE = "DATABASE",
  DB_SYSTEM = "DB_SYSTEM",
  INFRA = "INFRA",
  NETWORK_LINK = "NETWORK_LINK"
}

export enum DeploymentResourceType {
  ORACLE_NETWORK_NETWORKLINKS = "Oracle.Network/networkLinks",
  ORACLE_NETWORK_MCVCNS = "Oracle.Network/multiCloudVirtualNetworks",
  ORACLE_DATABASE_CLOUDEXADATAINFRASTRUCTURES = "Oracle.Database/cloudExadataInfrastructures",
  ORACLE_DATABASE_CLOUDVMCLUSTERS = "Oracle.Database/cloudVmClusters",
  ORACLE_DATABASE_DATABASES = "Oracle.Database/databases",
  ORACLE_VMDB_DBSYSTEMS = "Oracle.Vmdb/dbSystems",
  ORACLE_VMDB_DATABASES = "Oracle.Vmdb/databases",
  ORACLE_MDS_DBSYSTEMS = "Oracle.Mds/dbSystems",
  ORACLE_MDS_HEATWAVE = "Oracle.Mds/Heatwave",
  ORACLE_ADBS_DATABASES = "Oracle.Adbs/databases",
}

export const getResourceTypeMessage = (resourceType: ResourceType | string): string => {
  switch (resourceType) {
    case ResourceType.APEX:
      return Messages.labels.apex();
    case ResourceType.AUTONOMOUS_DATABASE:
      return Messages.labels.autonomousDatabase();
    case ResourceType.EXACS:
      return Messages.labels.exaCS();
    case ResourceType.VM:
      return Messages.labels.vmCluster();
    case ResourceType.DATABASE:
      return Messages.labels.database();
    case ResourceType.DB_SYSTEM:
      return Messages.labels.databaseSystem();
    case ResourceType.INFRA:
      return Messages.labels.infra();
    case ResourceType.NETWORK_LINK:
      return Messages.labels.networkLink();
    default:
      return resourceType;
  }
};

export const getBackupTypeStr = (backupType: BackupSummaryTypeEnum | VmdbBackupSummaryTypeEnum): string => {
  switch (backupType) {
    case "INCREMENTAL":
      return Messages.backupTypes.incremental();
    case "FULL":
      return Messages.backupTypes.full();
    case "VIRTUAL_FULL":
      return Messages.backupTypes.virtualFull();
    default:
      return backupType;
  }
};

export const getCreationTypeStr = (creationType: MdsBackupCreationTypeEnum): string => {
  switch (creationType) {
    case "AUTOMATIC":
      return Messages.creationTypes.automatic();
    case "MANUAL":
      return Messages.creationTypes.manual();
    default:
      return creationType;
  }
};

export const getOpenModeStr = (
  openMode: PluggableDatabaseOpenModeEnum |
    VmdbPluggableDatabaseOpenModeEnum |
    AdbsDatabaseBaseOpenModeEnum,
): string => {
  switch (openMode) {
    case "READ_ONLY":
      return Messages.openModes.readOnly();
    case "READ_WRITE":
      return Messages.openModes.readWrite();
    case "MOUNTED":
      return Messages.openModes.mounted();
    case "MIGRATE":
      return Messages.openModes.migrate();
    default:
      return openMode;
  }
};

export const getConnectionStringType = (profile: AdbDatabaseConnectionStringProfile): string => {
  switch (profile.protocol) {
    case "TCP":
      return Messages.labels.connectionTypeTCP();
    case "TCPS":
      if (profile.tlsAuthentication === "MUTUAL") return Messages.labels.connectionTypeMTLS();
      return Messages.labels.connectionTypeTLS();
    default:
      return profile.protocol;
  }
};

export const getDatabaseAccessEndpoint = (hostnameVal: string, location: string): string => (
  `${hostnameVal}.adb.${getOciRegion(location)}.oraclecloud.com:1522`
);

export const getToolsAccessEndpoint = (hostnameVal: string, location: string): string => (
  `https://${hostnameVal}.adb.${getOciRegion(location)}.oraclecloudapps.com`
);

export const getTitleSuffix = (panelId: string): string => {
  switch (panelId) {
    case DetailsPanelId.PLUGGABLEDATABASES:
      return Messages.labels.pluggableDatabases();
    case DetailsPanelId.INFRASTRUCTURE:
      return Messages.labels.infra();
    case DetailsPanelId.VMCLUSTER:
      return Messages.labels.vmCluster();
    case DetailsPanelId.DBHOME:
      return Messages.labels.databaseHome();
    case DetailsPanelId.DBSYSTEM:
      return Messages.labels.databaseSystem();
    case DetailsPanelId.DATABASE:
      return Messages.labels.database();
    case DetailsPanelId.ACTIVITY:
      return Messages.labels.activity();
    case DetailsPanelId.TAGS:
      return Messages.labels.tags();
    case DetailsPanelId.CONNECT:
      return Messages.labels.connect();
    case DetailsPanelId.CONFIGURATION:
      return Messages.labels.configuration();
    case DetailsPanelId.NETWORKING:
      return Messages.labels.networking();
    case DetailsPanelId.SECURITY:
      return Messages.labels.security();
    case DetailsPanelId.MANAGEMENT:
      return Messages.labels.management();
    case DetailsPanelId.DATABASENODES:
      return Messages.labels.databaseNodes();
    case DetailsPanelId.DATABASEHOMES:
      return Messages.labels.databaseHomes();
    case DetailsPanelId.CONTAINERDATABASES:
      return Messages.labels.containerDatabases();
    case DetailsPanelId.BACKUPS:
      return Messages.labels.backups();
    case DetailsPanelId.VMCLUSTERS:
      return Messages.labels.vmClusters();
    case DetailsPanelId.HEATWAVE:
      return Messages.labels.heatwave();
    case DetailsPanelId.OVERVIEW:
    default:
      return Messages.labels.overview();
  }
};

export const storageSizeMap = new Map([
  [256, 712],
  [512, 968],
  [1024, 1480],
  [2048, 2656],
  [4096, 5116],
  [6144, 7572],
  [8192, 10032],
  [10240, 12488],
  [12288, 14944],
  [14336, 17404],
  [16384, 19860],
  [18432, 22320],
  [20480, 24776],
  [22528, 27232],
  [24576, 29692],
  [26624, 32148],
  [28672, 34608],
  [30720, 37064],
  [32768, 39520],
  [34816, 41980],
  [36864, 44436],
  [38912, 46896],
  [40960, 49352],
]);

export const getTotalStorageSize = (availabeStorage: string, nodeCount: number) : number | undefined => {
  const storage = availabeStorage && (storageSizeMap.get(parseInt(availabeStorage, 10)));
  const nodeValueComputed = ((Number(nodeCount) - 1) * 200);
  const totalStorage = storage && storage + nodeValueComputed;
  return totalStorage || undefined;
};

export const getLocalizedLicenseType = (type : string) : string => {
  switch (type) {
    case LICENSE_TYPE.LICENSE_INCLUDED: return Messages.labels.licenseIncluded();
    case LICENSE_TYPE.BRING_YOUR_OWN_LICENSE: return Messages.labels.byol();
    default: return type;
  }
};

export const getAdbsLocalizedLicenseType = (
  type: string,
  dbEdition: string,
): string => {
  let value = type;
  if (type === LICENSE_TYPE.LICENSE_INCLUDED) {
    value = Messages.labels.licenseIncluded();
  } else if (type === LICENSE_TYPE.BRING_YOUR_OWN_LICENSE) {
    value = Messages.labels.byol();
    if (dbEdition === DATABASE_EDITION_TYPE.ENTERPRISE_EDITION) {
      value = Messages.labels.byolEE();
    } else if (dbEdition === DATABASE_EDITION_TYPE.STANDARD_EDITION) {
      value = Messages.labels.byolSE();
    }
  }
  return value;
};

export const getDeploymentStatusTitle = (stateValue: DeploymentLifecycleStateEnum): string => {
  switch (stateValue) {
    case "ACCEPTED":
      return Messages.deployment.overview.sectionTitles.deploymentStatusAccepted();
    case "CANCELED":
      return Messages.deployment.overview.sectionTitles.deploymentStatusCanceled();
    case "CANCELING":
      return Messages.deployment.overview.sectionTitles.deploymentStatusCanceling();
    case "FAILED":
      return Messages.deployment.overview.sectionTitles.deploymentStatusFailed();
    case "IN_PROGRESS":
      return Messages.deployment.overview.sectionTitles.deploymentStatusInProgress();
    case "SUCCEEDED":
      return Messages.deployment.overview.sectionTitles.deploymentStatusSucceeded();
    default:
      return stateT(stateValue);
  }
};

export enum ExadataCreateFlowType {
  EXADATA_DATABASE,
  EXADATA_DATABASE_FROM_BACKUP,
  EXADATA_SYSTEM,
}

export enum VmdbCreateFlowType {
  VMDB_DATABASE,
  VMDB_DATABASE_FROM_BACKUP,
}

export const SupportIssueType: { [key: string]: CreateIncidentDetailsProblemTypeEnum | IncidentProblemTypeEnum } = {
  BILLING: "BILLING",
  QUOTAS: "LIMIT",
  TECHNICAL: "TECHNICAL",
};

type SeverityEnum = TicketSeverityEnum | CreateIncidentDetailsSeverityEnum;

export const SupportSeverity: { [key: string]: SeverityEnum } = {
  HIGHEST: "HIGHEST",
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
};

export const getTrialAccountTypeMessage = (
  type: TenancySubscriptionTypeEnum | AzureCloudLinkOciTenancyPaymentModelEnum | undefined,
): string => {
  switch (type) {
    case "PROMO":
    case "FREE":
      return Messages.trialAccountInfo.promo();
    case "PROMO_CONSUMED":
      return Messages.trialAccountInfo.promoConsumed();
    case "PROMO_EXPIRED":
      return Messages.trialAccountInfo.promoExpired();
    default: return "";
  }
};

export const getIncidentProblemType = (type: IncidentProblemTypeEnum, shortFormat?: boolean): string => {
  switch (type) {
    case "BILLING": return Messages.supportIssueTypes.billing();
    case "LIMIT": return shortFormat ? Messages.supportIssueTypes.quotasShort() : Messages.supportIssueTypes.quotas();
    case "TECHNICAL": return Messages.supportIssueTypes.technical();
    default: return type;
  }
};

export const getMcvcnStatus = (status: string, isUsable: boolean): string => {
  if (status !== ResourceStatus.Failed && isUsable !== true) {
    return `${stateT(status || "")} (${Messages.common.unusable()})`;
  }
  return stateT(status || "");
};

export const getIncidentSeverity = (severity: TicketSeverityEnum): string => {
  switch (severity) {
    case SupportSeverity.HIGHEST:
      return Messages.supportSeverity.highest();
    case SupportSeverity.HIGH:
      return Messages.supportSeverity.high();
    case SupportSeverity.MEDIUM:
      return Messages.supportSeverity.medium();
    case SupportSeverity.LOW:
      return Messages.supportSeverity.low();
    default:
      return severity;
  }
};

export const getProviderName = (provider: ServiceProviders): string => {
  switch (provider) {
    case ServiceProviders.EXADATA:
      return ORACLE_DB_PROVIDER_PATH;
    case ServiceProviders.VMDB:
      return ORACLE_VMDB_PROVIDER_PATH;
    case ServiceProviders.ADBS:
      return ORACLE_ADBS_PROVIDER_PATH;
    case ServiceProviders.MDS:
      return ORACLE_MYSQL_PROVIDER_PATH;
    case ServiceProviders.NETWORK:
      return ORACLE_NET_PROVIDER_PATH;
    default:
      return "";
  }
};

export const getProviderFromName = (providerName: string): ServiceProviders | undefined => {
  switch (providerName) {
    case ORACLE_DB_PROVIDER_PATH:
      return ServiceProviders.EXADATA;
    case ORACLE_VMDB_PROVIDER_PATH:
      return ServiceProviders.VMDB;
    case ORACLE_ADBS_PROVIDER_PATH:
      return ServiceProviders.ADBS;
    case ORACLE_MYSQL_PROVIDER_PATH:
      return ServiceProviders.MDS;
    case ORACLE_NET_PROVIDER_PATH:
      return ServiceProviders.NETWORK;
    case ORACLE_DEPLOYMENT_PROVIDER_PATH:
      return ServiceProviders.DEPLOYMENT;
    default:
      return undefined;
  }
};

export enum TopLevelResourceType {
  ADBS = "ADBS",
  EXA_DATABASE = "EXA_DATABASE",
  EXA_VM_CLUSTER = "EXA_VM_CLUSTER",
  EXA_INFRA = "EXA_INFRA",
  VMDB_DATABASE = "VMDB_DATABASE",
  VMDB_DBSYSTEM = "VMDB_DBSYSTEM",
  MDS_DBSYSTEM = "MDS_DBSYSTEM",
  MCVCN = "MCVCN",
  DEPLOYMENT = "DEPLOYMENT",
}

export const getTopLevelResourceType = (
  providerName: string,
  resourceType: IdResourceType,
): TopLevelResourceType | undefined => {
  const provider = getProviderFromName(providerName);
  let type: TopLevelResourceType | undefined;

  switch (provider) {
    case ServiceProviders.ADBS:
      type = TopLevelResourceType.ADBS;
      break;
    case ServiceProviders.EXADATA:
      switch (resourceType as IdResourceType) {
        case IdResourceType.DATABASES:
        case IdResourceType.PLUGGABLE_DATABASES:
          type = TopLevelResourceType.EXA_DATABASE;
          break;
        case IdResourceType.CLOUD_EXADATA_INFRAS:
          type = TopLevelResourceType.EXA_INFRA;
          break;
        case IdResourceType.CLOUD_VM_CLUSTERS:
          type = TopLevelResourceType.EXA_VM_CLUSTER;
          break;
        default:
          break;
      }
      break;
    case ServiceProviders.VMDB:
      switch (resourceType as IdResourceType) {
        case IdResourceType.PLUGGABLE_DATABASES:
        case IdResourceType.DATABASES:
          type = TopLevelResourceType.VMDB_DATABASE;
          break;
        case IdResourceType.DB_SYSTEMS:
          type = TopLevelResourceType.VMDB_DBSYSTEM;
          break;
        default:
          break;
      }
      break;
    case ServiceProviders.MDS:
      switch (resourceType) {
        case IdResourceType.DB_SYSTEMS:
          type = TopLevelResourceType.MDS_DBSYSTEM;
          break;
        default:
          break;
      }
      break;
    case ServiceProviders.NETWORK:
      type = TopLevelResourceType.MCVCN;
      break;
    case ServiceProviders.DEPLOYMENT:
      type = TopLevelResourceType.DEPLOYMENT;
      break;
    default:
      break;
  }
  return type;
};

export const getResourceDisplayTypeFromEnum = (type: TopLevelResourceType | undefined): string => {
  switch (type) {
    case TopLevelResourceType.ADBS:
      return Messages.resourceTypes.adbs();
    case TopLevelResourceType.EXA_DATABASE:
      return Messages.resourceTypes.exaDb();
    case TopLevelResourceType.EXA_INFRA:
      return Messages.resourceTypes.exaInfra();
    case TopLevelResourceType.EXA_VM_CLUSTER:
      return Messages.resourceTypes.exaVmCluster();
    case TopLevelResourceType.VMDB_DATABASE:
      return Messages.resourceTypes.vmDb();
    case TopLevelResourceType.VMDB_DBSYSTEM:
      return Messages.resourceTypes.vmDbSystem();
    case TopLevelResourceType.MDS_DBSYSTEM:
      return Messages.resourceTypes.mysql();
    case TopLevelResourceType.MCVCN:
      return Messages.resourceTypes.mcvcn();
    case TopLevelResourceType.DEPLOYMENT:
      return Messages.resourceTypes.deployment();
    default:
      return "";
  }
};

export const getResourceDisplayType = (providerName: string, resourceType: IdResourceType): string => {
  const provider = getProviderFromName(providerName);
  let displayType = "";

  if (provider === ServiceProviders.MDS && resourceType === IdResourceType.BACKUPS) {
    displayType = Messages.resourceTypes.mysqlBackup();
  } else {
    const topLevelResourceType = getTopLevelResourceType(providerName, resourceType);
    displayType = getResourceDisplayTypeFromEnum(topLevelResourceType);
  }

  return displayType;
};

export const getResourceIconName = (providerName: string, resourceType: IdResourceType): string => {
  const provider = getProviderFromName(providerName);
  let iconName = "";

  if (provider === ServiceProviders.MDS && resourceType === IdResourceType.BACKUPS) {
    iconName = SvgIconIds.backupsSvg;
  } else {
    const topLevelResourceType = getTopLevelResourceType(providerName, resourceType);
    switch (topLevelResourceType) {
      case TopLevelResourceType.ADBS:
        iconName = SvgIconIds.adbSvg;
        break;
      case TopLevelResourceType.EXA_DATABASE:
        iconName = SvgIconIds.exaSvg;
        break;
      case TopLevelResourceType.EXA_INFRA:
        iconName = SvgIconIds.infraSvg;
        break;
      case TopLevelResourceType.EXA_VM_CLUSTER:
        iconName = SvgIconIds.vmClusterSvg;
        break;
      case TopLevelResourceType.VMDB_DATABASE:
        iconName = SvgIconIds.baseDbSvg;
        break;
      case TopLevelResourceType.VMDB_DBSYSTEM:
        iconName = SvgIconIds.dbSystemSvg;
        break;
      case TopLevelResourceType.MDS_DBSYSTEM:
        iconName = SvgIconIds.mysqlSvg;
        break;
      case TopLevelResourceType.MCVCN:
        iconName = SvgIconIds.networkSvg;
        break;
      case TopLevelResourceType.DEPLOYMENT:
        iconName = SvgIconIds.deploymentSvg;
        break;
      default:
        break;
    }
  }

  return iconName;
};

export const getResourcePageId = (providerName: string, resourceType: IdResourceType): PageId => {
  const provider = getProviderFromName(providerName);
  let detailsPageId: PageId = PageId.ERROR;

  switch (provider) {
    case ServiceProviders.ADBS:
      detailsPageId = PageId.ADBS_DETAILS;
      break;
    case ServiceProviders.EXADATA:
      switch (resourceType as IdResourceType) {
        case IdResourceType.DATABASES:
          detailsPageId = PageId.EXADB_CDB_DETAILS;
          break;
        case IdResourceType.PLUGGABLE_DATABASES:
          detailsPageId = PageId.EXADB_PDB_DETAILS;
          break;
        case IdResourceType.CLOUD_EXADATA_INFRAS:
          detailsPageId = PageId.EXAINFRA_DETAILS;
          break;
        case IdResourceType.CLOUD_VM_CLUSTERS:
          detailsPageId = PageId.VMCLUSTER_DETAILS;
          break;
        default:
          break;
      }
      break;
    case ServiceProviders.VMDB:
      switch (resourceType as IdResourceType) {
        case IdResourceType.PLUGGABLE_DATABASES:
          detailsPageId = PageId.VMDB_PDB_DETAILS;
          break;
        case IdResourceType.DATABASES:
          detailsPageId = PageId.VMDB_CDB_DETAILS;
          break;
        case IdResourceType.DB_SYSTEMS:
          detailsPageId = PageId.VMDB_SYSTEM_DETAILS;
          break;
        default:
          break;
      }
      break;
    case ServiceProviders.MDS:
      switch (resourceType) {
        case IdResourceType.DB_SYSTEMS:
          detailsPageId = PageId.MYSQL_DETAILS;
          break;
        case IdResourceType.BACKUPS:
          detailsPageId = PageId.MYSQL_BACKUP_DETAILS;
          break;
        default:
          break;
      }
      break;
    case ServiceProviders.NETWORK:
      detailsPageId = PageId.MCVCN_DETAILS;
      break;
    case ServiceProviders.DEPLOYMENT:
      detailsPageId = PageId.DEPLOYMENT_DETAILS;
      break;
    default:
      break;
  }

  return detailsPageId;
};

export const getServiceCategory = (
  provider: ServiceProviders,
): CreateLimitItemDetailsServiceCategoryEnum | undefined => {
  switch (provider) {
    case ServiceProviders.EXADATA:
      return ServiceCategoryValues.EXADATADB;
    case ServiceProviders.VMDB:
      return ServiceCategoryValues.VMDB;
    case ServiceProviders.ADBS:
      return ServiceCategoryValues.AUTONOMOUSDB_SERVERLESS;
    case ServiceProviders.MDS:
      return ServiceCategoryValues.MDS;
    case ServiceProviders.NETWORK:
      return ServiceCategoryValues.NETWORK;
    default:
      return undefined;
  }
};

export const activityTypeT = (type: ActivityItemActivityTypeEnum): string => {
  switch (type) {
    case "NOTES": return Messages.activityTypes.notes();
    case "PROBLEM_DESCRIPTION": return Messages.activityTypes.problemDescription();
    case "UPDATE": return Messages.activityTypes.update();
    case "CLOSE": return Messages.activityTypes.close();
    case "REOPEN": return Messages.activityTypes.reopen();
    default: return type;
  }
};

export const incidentStatusDetailsT = (type: TicketLifecycleDetailsEnum): string => {
  switch (type) {
    case "PENDING_WITH_ORACLE": return Messages.supportIssueStateTypes.pendingOracle();
    case "PENDING_WITH_CUSTOMER": return Messages.supportIssueStateTypes.pendingCustomer();
    case "CLOSE_REQUESTED": return Messages.supportIssueStateTypes.closeRequested();
    case "CLOSED": return Messages.supportIssueStateTypes.closed();
    default: return type;
  }
};

export type FreeFormTags = { [key: string]: string; };
type TagsPerResourceTypeMap = { [resType: string]: FreeFormTags };

export const getTagsPerResourceTypeMap = (tags: TagsInfoType | undefined): TagsPerResourceTypeMap => {
  const resTypes = Object.keys(ResourceType);
  const map = resTypes.reduce((acc, cur) => {
    acc[cur] = {};
    return acc;
  }, {} as TagsPerResourceTypeMap);

  tags?.forEach(tag => {
    if (tag.name) {
      tag.resources?.forEach(res => {
        map[res.id][tag.name] = tag.value || "";
      });
    }
  });
  return map;
};

export const getTagsMap = (
  tags: TagsInfoType | undefined,
): FreeFormTags => {
  const tagsValueMap: { [key: string]: string; } = {};
  tags?.forEach(tag => {
    if (tag.name) {
      tagsValueMap[tag.name] = tag.value || "";
    }
  });
  return tagsValueMap;
};

export const areTagsEqual = (rightSide:FreeFormTags[], leftSide: FreeFormTags[]): boolean => {
  if (rightSide.length !== leftSide.length) {
    return false;
  }
  // eslint-disable-next-line max-len
  const isRightEqual = rightSide.every(element => leftSide.some(ele => ele.name === element.name && ele.value === element.value));
  // eslint-disable-next-line max-len
  const isLeftEqual = leftSide.every(element => rightSide.some(ele => ele.name === element.name && ele.value === element.value));

  return isRightEqual && isLeftEqual;
};

export const responseItemstoArray = <Resp, Resource>(
  respWithData: SerializableResponseWithData<Resp>,
): Resource[] => {
  let dataArray: Resource[] = [];
  if (respWithData?.data) {
    if (Array.isArray(respWithData.data)) {
      // ******************************************************************************************
      // For new specs, when using fetchAllPages, data from different pages are not merged
      // and multiple collections are returned
      // For old specs w/o using fetchAllPages, all pages are merged into one single array
      // ******************************************************************************************

      // Merge all pages into a single array if dealing with new specs
      const consolidated = respWithData.data.reduce((acc, entry) => (
        entry.items ? acc.concat(entry.items) : acc
      ), []);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (consolidated.length > 0 || (respWithData.data as any)[0]?.items) {
        // For new specs all data pages are merged into a single array by the previous step
        dataArray = consolidated;
      } else {
        // Old specs returns a single array of all the data merged
        dataArray = respWithData.data as Resource[];
      }
    } else {
      // ******************************************************************************************
      // For new specs and when not using fetchAllPages, only one collection is returned
      // ******************************************************************************************
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataArray = (respWithData.data as any as {[k: string]: any[]}).items;
    }
  }
  return dataArray;
};

export const getStatusInfo = (
  status: string,
  lastStatus: string,
  lastStatusDetails: string,
  lifecycleDetails?: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (status === "Failed") {
    if (lastStatus === "Failed" && lastStatusDetails) {
      return {
        title: stateT(status),
        description: Messages.notifications.apiErrorMsg(lastStatusDetails),
      };
    }

    if (lifecycleDetails) {
      return {
        title: stateT(status),
        description: Messages.notifications.apiErrorMsg(lifecycleDetails),
      };
    }
  }
  return undefined;
};

export enum ResourceStatus {
  Failed = "Failed",
  Succeeded = "Succeeded",
  Active = "Active",
  Updating = "Updating",
  BackupInProgress = "BackupInProgress",
  Provisioning = "Provisioning",
  Terminating = "Terminating",
  Stopped = "Stopped"
}

export const getFieldCustomOptions = (
  fieldValues: string[],
  fieldTextTransform: (fieldVal: string) => string,
): { key: string, text: string }[] => {
  const fieldCustomOptions: { key: string, text: string }[] = [];
  fieldValues?.forEach(val => {
    if (!(fieldCustomOptions.find(option => (option.key === val)))) {
      fieldCustomOptions.push({
        key: val,
        text: fieldTextTransform(val),
      });
    }
  });
  return fieldCustomOptions;
};

export enum STORAGE_TYPE {
  ASM = "ASM",
  LVM = "LVM",
}

export const getStorageTypeDisplayName = (type: string): string => {
  switch (type) {
    case STORAGE_TYPE.ASM: return Messages.storageManagements.asm();
    case STORAGE_TYPE.LVM: return Messages.storageManagements.lvm();
    default: return type;
  }
};

// API Spec types that have been removed but keeping here so that can keep in the code
export interface CreateDatabaseFromBackupDetails {
  "backupId": string;
  "adminPassword": string;
  "tdeWalletPassword"?: string;
  "dbName"?: string;
  "dbUniqueName"?: string;
  "sidPrefix"?: string;
}
export interface CreateDatabaseFromBackup extends CreateDatabaseBase {
  "database": CreateDatabaseFromBackupDetails;
}

export const getAddressRange = (value?: string): string => {
  let addressRange = `${Messages.common.addessesCount("0")}`;

  if (value) {
    if (cidrValid(value)) {
      const [startingIp, endingIp] = cidrRange(value);
      addressRange = `${startingIp} - ${endingIp}`;
    } else if (isValidAddress(value)) {
      addressRange = value;
    }
  }

  return addressRange;
};

export enum MYSQL_BACKUP_TYPES {
  FULL = "FULL",
  INCREMENTAL = "INCREMENTAL"
}

export enum MdsShapesEnum {
  DBSYSTEM = "DBSYSTEM",
  HEATWAVECLUSTER = "HEATWAVECLUSTER"
}

export const enum MdsAutoBackupRetentionPolicy {
  DELETE = "DELETE",
  RETAIN = "RETAIN"
}

export const enum MdsFinalBackupPolicy {
  REQUIRE_FINAL_BACKUP = "REQUIRE_FINAL_BACKUP",
  SKIP_FINAL_BACKUP = "SKIP_FINAL_BACKUP",
}

export enum MdsCrashRecovery {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export enum NetworkLinkStatus {
  CREATED = "CREATED",
  FAILED = "FAILED",
}

export const CharacterSets: string[] = [
  "AL32UTF8",
  "AR8ADOS710",
  "AR8ADOS720",
  "AR8APTEC715",
  "AR8ARABICMACS",
  "AR8ASMO8X",
  "AR8ISO8859P6",
  "AR8MSWIN1256",
  "AR8MUSSAD768",
  "AR8NAFITHA711",
  "AR8NAFITHA721",
  "AR8SAKHR706",
  "AR8SAKHR707",
  "AZ8ISO8859P9E",
  "BG8MSWIN",
  "BG8PC437S",
  "BLT8CP921",
  "BLT8ISO8859P13",
  "BLT8MSWIN1257",
  "BLT8PC775",
  "BN8BSCII",
  "CDN8PC863",
  "CEL8ISO8859P14",
  "CL8ISO8859P5",
  "CL8ISOIR111",
  "CL8KOI8R",
  "CL8KOI8U",
  "CL8MACCYRILLICS",
  "CL8MSWIN1251",
  "EE8ISO8859P2",
  "EE8MACCES",
  "EE8MACCROATIANS",
  "EE8MSWIN1250",
  "EE8PC852",
  "EL8DEC",
  "EL8ISO8859P7",
  "EL8MACGREEKS",
  "EL8MSWIN1253",
  "EL8PC437S",
  "EL8PC851",
  "EL8PC869",
  "ET8MSWIN923",
  "HU8ABMOD",
  "HU8CWI2",
  "IN8ISCII",
  "IS8PC861",
  "IW8ISO8859P8",
  "IW8MACHEBREWS",
  "IW8MSWIN1255",
  "IW8PC1507",
  "JA16EUC",
  "JA16EUCTILDE",
  "JA16SJIS",
  "JA16SJISTILDE",
  "JA16VMS",
  "KO16KSCCS",
  "KO16KSC5601",
  "KO16MSWIN949",
  "LA8ISO6937",
  "LA8PASSPORT",
  "LT8MSWIN921",
  "LT8PC772",
  "LT8PC774",
  "LV8PC1117",
  "LV8PC8LR",
  "LV8RST104090",
  "N8PC865",
  "NE8ISO8859P10",
  "NEE8ISO8859P4",
  "RU8BESTA",
  "RU8PC855",
  "RU8PC866",
  "SE8ISO8859P3",
  "TH8MACTHAIS",
  "TH8TISASCII",
  "TR8DEC",
  "TR8MACTURKISHS",
  "TR8MSWIN1254",
  "TR8PC857",
  "US7ASCII",
  "US8PC437",
  "UTF8",
  "VN8MSWIN1258",
  "VN8VN3",
  "WE8DEC",
  "WE8DG",
  "WE8ISO8859P1",
  "WE8ISO8859P15",
  "WE8ISO8859P9",
  "WE8MACROMAN8S",
  "WE8MSWIN1252",
  "WE8NCR4970",
  "WE8NEXTSTEP",
  "WE8PC850",
  "WE8PC858",
  "WE8PC860",
  "WE8ROMAN8",
  "ZHS16CGB231280",
  "ZHS16GBK",
  "ZHT16BIG5",
  "ZHT16CCDC",
  "ZHT16DBT",
  "ZHT16HKSCS",
  "ZHT16MSWIN950",
  "ZHT32EUC",
  "ZHT32SOPS",
  "ZHT32TRIS",
];

export const NationalCharacterSets: string[] = ["AL16UTF16", "UTF8"];

export const defaultCharacterSet = "AL32UTF8";
export const defaultNationalCharacterSet = "AL16UTF16";

export const defaultAdbsUsername = "ADMIN";
export const defaultExaUsername = "SYS";
export const defaultVMDBUsername = "SYS";

export const enum HeatWaveClusterStatus {
  PROVISIONING= "Provisioning",
  UPDATING = "Updating",
  ACTIVE = "Active",
  FAILED = "Failed",
  TERMINATING = "Terminating",
  STOPPED = "Stopped"
}

export enum DeploymentKinds {
  // Generic operations
  Create = "CREATE",
  Get = "GET",
  Register = "REGISTER",
  Restore = "RESTORE",
  // Resource based opertaions
  Adbs = "ADBS",
  Exadata = "EXADATA",
  Heatwave = "HEATWAVE",
  Vmdb = "VMDB"
}

export enum ValidateUserErrorCode {
  EmailNotFound = "EMAIL_NOT_FOUND",
  EmailNotVerified = "EMAIL_NOT_VERIFIED",
  CsiNotFound = "CSI_NOT_FOUND",
  MosAccountNotFound = "MOS_ACCOUNT_NOT_FOUND",
  CsiRequestNotFound = "CSI_REQUEST_NOT_FOUND",
  CsiRequestPending = "CSI_REQUEST_PENDING",
  Ok = "OK",
}

export enum PdbOpenMode {
  READ_ONLY = "READ_ONLY",
  READ_WRITE = "READ_WRITE",
  MOUNTED = "MOUNTED",
}

export enum CharacterSetType{
  DATABASE = "DATABASE",
  NATIONAL = "NATIONAL",
}
