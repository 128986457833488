import {
  ActionType,
  CustomAction,
  FavoriteAccessItem,
  FilterComponent,
  Listing,
  ListingColumn,
  ListingDisplayNameLink,
  ListingSelectOption,
  SelectionMode,
  SortDirections,
  useFavoriteAccess,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds } from "../../constants/uiConstants";
import { AzureSubscriptionSummary } from "../../gen/clients/mchub-azure-api-client";
import { IdResourceType, parseId } from "../../helpers/idHelper";
import { getResourceDisplayType, getResourceIconName, getResourcePageId } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { FavoriteAddResourcesProps, newFavoriteAddResources } from "../../operations/Favorite/FavoriteAddResources";
import { getAzureLocationName } from "../../utils";
import { FavoriteResourceItem } from "./FavoriteTypes";

export enum ColumnIds {
  DisplayName = "displayName",
  DisplayType = "displayType",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
}

export enum ColumnTestIds {
  DisplayName = "displayName",
  DisplayType = "displayType",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
}

export enum ActionBarIds {
  AddResources = "action-bar-add-resources",
}

export enum ActionBarTestIds {
  AddResources = "action-bar-add-resources",
}

export enum ActionMenuIds {
  Remove = "action-menu-remove",
}

export enum ActionMenuTestIds {
  Remove = "action-menu-remove",
}

interface AzureSubscriptionSummaryMap {
  [key: string]: AzureSubscriptionSummary;
}

export const FavoriteList = (): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const filterState = React.useContext<FilterState>(FilterStateContext);

  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();

  const [filterComponentRef, setFilterComponentRef] = React.useState<FilterComponent>();
  const filterTextValue = filterComponentRef?.getFilterText();
  const [filterText, setFilterText] = React.useState(filterState.filterTextValue || filterTextValue);

  const { trigger: triggerAddResources } = useOperation<FavoriteAddResourcesProps>(newFavoriteAddResources);

  const { favoriteItems, removeFromFavorites } = useFavoriteAccess();

  const getLocation = (value: FavoriteResourceItem): string => getAzureLocationName(value.location);

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.DisplayName,
      testId: ColumnTestIds.DisplayName,
      name: Messages.common.name(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.displayName}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[value.detailsPageId][0].panelPath}?location=${value.location}`,
            pageKey: PageRegistrationConfig[value.detailsPageId][0].key,
          }}
          iconName={value.iconName}
        />
      ),
    },
    {
      itemProp: ColumnIds.DisplayType,
      testId: ColumnTestIds.DisplayType,
      name: Messages.labels.type(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.SubscriptionName,
      testId: ColumnTestIds.SubscriptionName,
      name: Messages.labels.subscription(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, max-len
      onRenderItems: value => <AzureSubscriptionLink resourceId={value.id} subscriptionName={value.subscriptionName} hideClipboard />,
    },
  ];

  const groupingOptions: ListingSelectOption<FavoriteAccessItem>[] = [];

  const favoriteAccessToFavoriteResourceItem = (
    items: FavoriteAccessItem[],
    subscriptionsMap: AzureSubscriptionSummaryMap,
  ): FavoriteResourceItem[] => {
    const favoriteResourceItems: FavoriteResourceItem[] = [];
    items.forEach((item: FavoriteAccessItem) => {
      const { subscriptionId, resourceGroup, resourceName, resourceType, provider: providerName } = parseId(item.id);
      const itemRgName = resourceGroup;
      const itemSubscriptionName = subscriptionsMap && subscriptionsMap[subscriptionId]?.name;

      favoriteResourceItems.push({
        id: item.id,
        displayName: resourceName,
        subscriptionId,
        subscriptionName: itemSubscriptionName,
        resourceGroupName: itemRgName,
        location: item.data?.location || "",
        displayType: getResourceDisplayType(providerName, resourceType as IdResourceType),
        iconName: getResourceIconName(providerName, resourceType as IdResourceType),
        detailsPageId: getResourcePageId(providerName, resourceType as IdResourceType),
      });
    });
    return favoriteResourceItems;
  };

  const items = React.useMemo(() => {
    let convertedItems: FavoriteResourceItem[] = [];

    if (favoriteItems.length > 0 && subscriptions && subscriptions.length > 0) {
      const subscriptionsMap = subscriptions.reduce((
        map: AzureSubscriptionSummaryMap,
        subscription: AzureSubscriptionSummary,
      ) => {
        map[subscription.id] = subscription;
        return map;
      }, {});
      convertedItems = favoriteAccessToFavoriteResourceItem(favoriteItems, subscriptionsMap);
    }
    return convertedItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(favoriteItems), JSON.stringify(subscriptions)]);

  const favoriteBarActions: ActionType<FavoriteResourceItem>[] = [
    {
      key: ActionBarIds.AddResources,
      testId: ActionBarTestIds.AddResources,
      text: Messages.actions.addResources(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        trackActionClick(ActionBarIds.AddResources, PageId.FAVORITE_RESOURCES);
        triggerAddResources(
          { onCancel: () => trackActionDiscard(ActionBarIds.AddResources, PageId.FAVORITE_RESOURCES) },
        );
      },
    },
  ];

  const favoriteRowActionMenus = (item?: FavoriteResourceItem): ActionType<FavoriteResourceItem>[] => {
    const actionItems: ActionType<FavoriteResourceItem>[] = [];
    if (item) {
      actionItems.push({
        key: ActionMenuIds.Remove,
        testId: ActionMenuTestIds.Remove,
        text: Messages.actions.removeFromFavorites(),
        icon: MonochromeIconIds.FavoriteSet,
        onClick: () => {
          trackActionClick(ActionMenuIds.Remove, PageId.FAVORITE_RESOURCES);
          removeFromFavorites(item.id);
        },
      } as CustomAction<FavoriteResourceItem>);
    }
    return actionItems;
  };

  const textFilterCallBack = (rowItem: FavoriteResourceItem): FavoriteResourceItem => {
    const displayRowItem = { ...rowItem };
    displayRowItem.location = getAzureLocationName(displayRowItem.location);
    return displayRowItem;
  };

  React.useEffect(() => {
    setFilterText(filterTextValue);
    filterState.setFilterTextValue(filterTextValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTextValue]);

  const resetFilterValues = (): void => {
    filterState.setFilterTextValue("");
    setFilterText("");
  };

  return (
    <Listing
      testId={ListingTestIds.FavoriteList}
      items={items}
      listColumns={columns}
      actionBarItems={favoriteBarActions as ActionType<undefined>[]}
      actions={favoriteRowActionMenus}
      selectionMode={SelectionMode.none}
      isLoading={subscriptionLoading}
      showItemCount
      groupingSelect={{ groupingOptions }}
      filtering={{
        hideAddFilterPill: true,
        defaultFilterText: filterText,
        textFilterCallBack,
        componentRef: setFilterComponentRef,
        onClearFilters: resetFilterValues,
      }}
    />
  );
};
