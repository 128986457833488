import {
  AsyncFieldError,
  AsyncValidationError,
  BookmarkablePage,
  FormFieldErrors,
  FormValues,
  getValue,
  InputWizardItemMessage,
  InputWizardPanel,
  InputWizardPanelComponent,
  MessageType,
  PanelMessage,
  ReviewItem,
  ReviewSection,
  SelectOption,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { DeploymentAsyncValidationPhase } from "../../components/DeploymentPhases/DeploymentAsyncValidationPhase";
import { DeployedService, DeploymentCreatePhase } from "../../components/DeploymentPhases/DeploymentCreationPhase";
import { GROUP_TAGS } from "../../components/Tags/TagsCollection";
import { ConsoleContext } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  MYSQL_CREATE_ROUTE,
  MYSQL_DATABASE_RESOURCE_TYPE_PATH,
  PageId,
  PageRegistrationConfig,
} from "../../constants/pluginConstants";
import { CreateWizardTestIds } from "../../constants/uiConstants";
import {
  AzureVirtualNetworkSummary,
  CreateHeatwaveClusterDeploymentDetails,
  CreateMdsBackupPolicyDetails,
  CreateMdsDbSystemSourceFromBackupDetails,
  CreateMdsDeletionPolicyDetails,
  CreateMdsSystemDeploymentDetails,
  CreateMultiCloudVirtualNetworkDeploymentDetails,
  CreateNetworkLinkDeploymentDetails,
  GetMultiCloudVirtualNetworkDeploymentDetails,
  HeatwaveDeploymentParametersDetails,
  MdsDbSystemPitrPolicy,
  MultiCloudVirtualNetworkDeploymentDetails,
  MultiCloudVirtualNetworkSummary,
  RegisterExistingOciNetworkDeploymentDetails,
} from "../../gen/clients/mchub-azure-api-client";
import { parseId } from "../../helpers/idHelper";
import {
  DeploymentKinds,
  FutureMcvcn,
  FutureMcvcnExisting,
  FutureMcvcnNew,
  getAddressRange,
  getResourceTypeMessage,
  getTagsPerResourceTypeMap,
  ResourceType,
  TagsInfoType,
} from "../../helpers/resourceHelper";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { getTimestamp } from "../../helpers/timeHelper";
import { trimText } from "../../helpers/utilHelper";
import { validateIpAddressWithinRanges, validateMdsPitrPolicy } from "../../helpers/validationHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useRoles } from "../../hooks/useRoles";
import { useSidePanel } from "../../hooks/useSidePanel";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_DEPLOYMENT_CREATE_POLL_DELAY,
  MDS_DEPLOYMENT_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { ReviewTabContent } from "../CreateCommon/ReviewTabContent";
import { Fields as TagsFields, TagsTabContent } from "../CreateCommon/TagsTabContent";
import { BasicsTabContent, Fields as BasicsFields, GROUP_BASICS } from "./BasicsTabContent";
import { ConfigTabContent, Fields as ConfigFields, GROUP_CONFIG } from "./ConfigTabContent";
import { Fields as ManagementFields, GROUP_MANAGEMENT, ManagementTabContent } from "./ManagementTabContent";
import { Fields as NetworkFields, GROUP_NETWORK, NetworkTabContent } from "./NetworkTabContent";
import { Fields as SecurityFields, GROUP_SECURITY, SecurityTabContent } from "./SecurityTabContent";

const registrationIds = PageRegistrationConfig[PageId.MYSQL_CREATE].map(config => config.key);

export const onProcessAsyncValidationErrors = (
  asyncErrors: AsyncValidationError[],
): AsyncValidationError[] => asyncErrors.map(asyncError => {
  if (typeof asyncError === "string") {
    return asyncError;
  }
  const asyncFieldError = asyncError as AsyncFieldError;
  const field = "multiCloudVirtualNetworkDetails.id";
  let fieldLabel;
  if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.name") {
    fieldLabel = Messages.labels.name();
  } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.azureAttachedNetworkIds") {
    fieldLabel = Messages.labels.virtualNetwork();
  } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociVcnCidrBlocks") {
    fieldLabel = Messages.labels.cidrBlocks();
  } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociVcnOcid") {
    fieldLabel = Messages.labels.vcnOcid();
  } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociSubnetOcids") {
    fieldLabel = Messages.labels.subnetOcids();
  } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.customerNvaIpAddress") {
    fieldLabel = Messages.labels.networkVirtualAppliance();
  } else {
    return asyncFieldError;
  }
  asyncFieldError.field = field;
  asyncFieldError.error = `<strong>(${Messages.common.new()} | ${fieldLabel})</strong> ${asyncFieldError.error}`;
  return asyncFieldError;
});

enum CreationPhase {
  ASYNC_VALIDATE = "ASYNC_VALIDATE",
  CREATE_SUBMIT = "CREATE_SUBMIT",
}

export enum PanelTestIds {
  Basics = "basic",
  Configuration = "configuration",
  Network = "network",
  Security = "security",
  Management = "management",
  Tags = "tags",
}

export const MysqlCreatePage = (): JSX.Element => {
  const { enableMdsPreflight, enableMcvcn, enableMdsMcvcnCreate } = useFeatures();

  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const isCreateRoleMissing = !isActionAllowed(RoleBasedAction.CREATE_MYSQL_DB);
  const requiredRole = actionRequiredRoles(RoleBasedAction.CREATE_MYSQL_DB)[0]?.displayName;

  const { trackActionDiscard } = useAnalytics();

  const { back, navigateToSelf, customData } = useNavigation(ConsoleContext);
  const context = React.useContext<Settings>(SettingsContext);
  const { preferredLocationPerSubscription } = context;
  const preferredSubscription = customData?.subscriptionId || context.preferredSubscription;
  const preferredLocation = preferredLocationPerSubscription[preferredSubscription] || "";
  const [subscriptionId, setSubscriptionId] = React.useState<string>("");
  const [resourceGroupName, setResourceGroupName] = React.useState<string>("");
  const [location, setLocation] = React.useState<string>("");

  const [creationPhase, setCreationPhase] = React.useState<CreationPhase | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [createMySQLPayload, setCreateMySQLPayload] = React.useState<any>();
  const asyncValidationCallbacks = React.useRef<{
    resolve:(errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ resolve: () => {}, reject: () => {} });
  const submitCallbacks = React.useRef<{
    reject:(errorMessage: string, validationErrors?: AsyncValidationError[]) => void
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ reject: () => {} });

  // to hold the name to be used in failure message
  const resourceNameRef = React.useRef<string>("");
  const deploymentNameRef = React.useRef<string>("");

  React.useEffect(() => {
    setLocation(preferredLocation);
    setSubscriptionId(preferredSubscription);
  }, [preferredLocation, preferredSubscription]);

  React.useEffect(() => {
    // In case page was navigated to directly by entering its URL in the browser
    navigateToSelf(MYSQL_CREATE_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [panelRef, setPanelRef] = React.useState<InputWizardPanelComponent>({} as InputWizardPanelComponent);
  const { closePanels } = useSidePanel();

  const buildDbBackupConfig = (formValues: FormValues): CreateMdsBackupPolicyDetails => {
    const isEnabled = getValue<boolean>(formValues, ManagementFields.Backups, GROUP_MANAGEMENT);
    const windowStartTime = getValue<SelectOption[]>(
      formValues,
      ManagementFields.WindowStartTime,
      GROUP_MANAGEMENT,
    )?.[0].id;
    const retention = getValue<string>(formValues, ManagementFields.RetentionPeriod, GROUP_MANAGEMENT);
    const retentionInDays = retention ? parseInt(retention, 10) : undefined;
    const backupDetails: CreateMdsBackupPolicyDetails = {
      isEnabled,
      windowStartTime: isEnabled ? windowStartTime : undefined,
      retentionInDays: isEnabled ? retentionInDays : undefined,
      pitrPolicy: isEnabled ? buildPitrPolicy(formValues) : undefined,
    };
    return backupDetails;
  };

  const buildPitrPolicy = (formValues: FormValues): MdsDbSystemPitrPolicy => {
    // eslint-disable-next-line max-len
    const pitrPolicy: MdsDbSystemPitrPolicy = { isEnabled: getValue<boolean>(formValues, ManagementFields.PitrPolicy, GROUP_MANAGEMENT) };
    return pitrPolicy;
  };

  const getDeletionPolicy = (formValues: FormValues): CreateMdsDeletionPolicyDetails => {
    const deletedProtected = getValue<boolean>(
      formValues,
      ManagementFields.DeleteProtected,
      GROUP_MANAGEMENT,
    );
    const retainAutomaticBackups = getValue<boolean>(
      formValues,
      ManagementFields.RetainAutomaticBackups,
      GROUP_MANAGEMENT,
    );
    const requireFinalBackup = getValue<boolean>(formValues, ManagementFields.RequireFinalBackup, GROUP_MANAGEMENT);
    const deletionPolicy: CreateMdsDeletionPolicyDetails = {
      isDeleteProtected: deletedProtected,
      automaticBackupRetention: retainAutomaticBackups ? "RETAIN" : "DELETE",
      finalBackup: requireFinalBackup ? "REQUIRE_FINAL_BACKUP" : "SKIP_FINAL_BACKUP",
    };
    return deletionPolicy;
  };

  const getNetworkLink = (formValues: FormValues): CreateNetworkLinkDeploymentDetails => {
    const name = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS);
    const cidrRange = getValue<string>(formValues, NetworkFields.Cidrs, GROUP_NETWORK);
    const cidr = cidrRange ? [cidrRange] : [];
    const vnet = getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].id;
    const azureAttachedNetworkIds = vnet ? [vnet] : [];
    const networkLinkDetails: CreateNetworkLinkDeploymentDetails = {
      kind: DeploymentKinds.Create,
      name: `${name}-networkLink`,
      ociNetworkCidrs: cidr,
      azureAttachedNetworkIds,
      customerNvaIpAddress: getValue<string>(formValues, NetworkFields.NetworkVirtualAppliance, GROUP_NETWORK),
    };
    return networkLinkDetails;
  };

  const getMcvcnDetails = (formValues: FormValues): MultiCloudVirtualNetworkDeploymentDetails => {
    // eslint-disable-next-line max-len
    const data: FutureMcvcnNew | FutureMcvcnExisting | MultiCloudVirtualNetworkSummary | undefined = getValue<SelectOption[]>(
      formValues,
      NetworkFields.Mcvcn,
      GROUP_NETWORK,
    )?.[0]?.data;

    // Only exists if the user has selected an existing MCVCN
    const { id } = data as MultiCloudVirtualNetworkSummary;
    // Exists if the user is creating a new MCVCN
    const { name, azureAttachedNetworkId, customerNvaIpAddress } = data as FutureMcvcn;
    // Exists if the user is creating a new MCVCN using an existing OCI VCN
    const { ociSubnetOcids, ociVcnOcid } = data as FutureMcvcnExisting;
    // Exists if the user is creating a new MCVCN using a new OCI VCN
    const { ociVcnCidrBlocks } = data as FutureMcvcnNew;

    let mcvcnDetails: MultiCloudVirtualNetworkDeploymentDetails;
    const azureAttachedNetworkIds = [azureAttachedNetworkId];

    if (id) {
      mcvcnDetails = {
        kind: DeploymentKinds.Get,
        id,
      } as GetMultiCloudVirtualNetworkDeploymentDetails;
    } else if (ociVcnCidrBlocks) {
      mcvcnDetails = {
        kind: DeploymentKinds.Create,
        azureAttachedNetworkIds,
        name,
        ociVcnCidrBlocks,
        customerNvaIpAddress,
      } as CreateMultiCloudVirtualNetworkDeploymentDetails;
    } else if (ociVcnOcid && ociSubnetOcids) {
      mcvcnDetails = {
        kind: DeploymentKinds.Register,
        azureAttachedNetworkIds,
        name,
        ociVcnOcid,
        ociSubnetOcids,
        customerNvaIpAddress,
      } as RegisterExistingOciNetworkDeploymentDetails;
    } else {
      throw new Error("Invalid parameters to create MCVCN");
    }

    return mcvcnDetails;
  };

  const onAsyncValidate = (
    formValues: FormValues,
    resolve: (errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
  ): void => {
    setCreateMySQLPayload(buildCreateMySQLPayload(formValues));
    asyncValidationCallbacks.current = { resolve, reject };
    setCreationPhase(CreationPhase.ASYNC_VALIDATE);
  };

  const onSubmit = (formValues: FormValues): void => {
    setCreateMySQLPayload(buildCreateMySQLPayload(formValues));
    setCreationPhase(CreationPhase.CREATE_SUBMIT);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildCreateMySQLPayload = (formValues: FormValues): any => {
    const name = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS) ?? "";
    const description = getValue<string>(formValues, BasicsFields.Description, GROUP_BASICS);

    const tags = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS);
    const tagsMap = getTagsPerResourceTypeMap(tags);

    const shapeName = getValue<SelectOption[]>(formValues, ConfigFields.SystemModelShape, GROUP_CONFIG)?.[0]?.id ?? "";
    const dataStorageSize = getValue<string>(formValues, ConfigFields.DataStorageSizeInGbs, GROUP_CONFIG);
    const dataStorageSizeInGBs = dataStorageSize ? parseInt(dataStorageSize, 10) : undefined;

    const adminUsername = getValue<string>(formValues, SecurityFields.Username, GROUP_SECURITY) ?? "";
    const adminPassword = getValue<string>(formValues, SecurityFields.Password, GROUP_SECURITY) ?? "";

    const crashRecoveryEnabled = getValue<boolean>(formValues, ManagementFields.CrashRecovery, GROUP_MANAGEMENT);

    const mdsDbSystemDeploymentDetails: CreateMdsSystemDeploymentDetails = {
      kind: DeploymentKinds.Create,
      name,
      description: trimText(description),
      shapeName,
      dataStorageSizeInGBs,
      mysqlVersion: getValue<SelectOption[]>(formValues, ConfigFields.MysqlVersion, GROUP_CONFIG)?.[0]?.id,
      hostnameLabel: trimText(getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK)),
      ipAddress: trimText(getValue<string>(formValues, NetworkFields.IpAddress, GROUP_NETWORK)),
      adminUsername,
      adminPassword,
      backupPolicy: buildDbBackupConfig(formValues),
      freeformTags: tagsMap[ResourceType.DATABASE],
      crashRecovery: crashRecoveryEnabled ? "ENABLED" : "DISABLED",
      deletionPolicy: getDeletionPolicy(formValues),
    };

    if (customData?.backupId) {
      const source: CreateMdsDbSystemSourceFromBackupDetails = {
        backupId: customData?.backupId,
        sourceType: "BACKUP",
      };
      mdsDbSystemDeploymentDetails.source = source;
    }

    const enableHeatwaveCluster = getValue<boolean>(formValues, ConfigFields.EnableHeatwaveCluster, GROUP_CONFIG);

    const cluster = getValue<string>(formValues, ConfigFields.NodeCount, GROUP_CONFIG);
    const clusterSize = cluster ? parseInt(cluster, 10) : 0;

    const heatwaveClusterDeploymentDetails: CreateHeatwaveClusterDeploymentDetails = {
      kind: DeploymentKinds.Create,
      shapeName: getValue<SelectOption[]>(formValues, ConfigFields.HeatwaveShape, GROUP_CONFIG)?.[0]?.id as string,
      clusterSize,
    };

    deploymentNameRef.current = `${MYSQL_DATABASE_RESOURCE_TYPE_PATH}-${getTimestamp()}`;
    resourceNameRef.current = name;
    const createDeploymentDetails = {
      name: deploymentNameRef.current,
      parameters: {
        kind: DeploymentKinds.Heatwave,
        networkLinkDetails: !(enableMcvcn && enableMdsMcvcnCreate) ? getNetworkLink(formValues) : undefined,
        multiCloudVirtualNetworkDetails:
          enableMcvcn && enableMdsMcvcnCreate
            ? getMcvcnDetails(formValues)
            : undefined,
        mdsDbSystemDeploymentDetails,
        heatwaveClusterDeploymentDetails: enableHeatwaveCluster ? heatwaveClusterDeploymentDetails : undefined,
      } as HeatwaveDeploymentParametersDetails,
    };

    const payload = {
      subscriptionId: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].id,
      resourceGroupName: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      createDeploymentDetails,
    };

    return payload;
  };

  const buildBasicInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewBasicInfo: ReviewItem[] = [
      {
        label: Messages.labels.subscription(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.resourceGroup(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.mySQLDatabaseSystemName(),
        value: getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS),
      },
      {
        label: Messages.labels.mySQLDatabaseDescription(),
        value: getValue<string>(formValues, BasicsFields.Description, GROUP_BASICS),
      },
      {
        label: Messages.labels.region(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Region, GROUP_BASICS)?.[0].text,
      },
    ];
    return reviewBasicInfo;
  };

  const buildConfigurationInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewConfigInfo: ReviewItem[] = [];
    const enableHeatwaveCluster = getValue<boolean>(formValues, ConfigFields.EnableHeatwaveCluster, GROUP_CONFIG);

    if (customData) {
      const { dbSystemName, backupName } = customData;

      if (dbSystemName) {
        reviewConfigInfo.push(
          {
            label: Messages.labels.mySQLRestoreFromBackup(),
            value: `${backupName} (${dbSystemName})`,
          },
        );
      }
    }

    reviewConfigInfo.push(
      {
        label: Messages.labels.systemModelShape(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.SystemModelShape, GROUP_CONFIG)?.[0]?.text,
      },
      {
        label: Messages.labels.dataStorageSize(),
        value: getValue<string>(formValues, ConfigFields.DataStorageSizeInGbs, GROUP_CONFIG),
      },
      {
        label: Messages.labels.mySQLVersion(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.MysqlVersion, GROUP_CONFIG)?.[0]?.text,
      },
      {
        label: Messages.labels.heatwaveCluster(),
        value: enableHeatwaveCluster ? Messages.common.enabled() : Messages.common.disabled(),
      },
    );

    if (enableHeatwaveCluster) {
      reviewConfigInfo.push(
        {
          label: Messages.labels.nodeCount(),
          value: getValue<string>(formValues, ConfigFields.NodeCount, GROUP_CONFIG),
        },
        {
          label: Messages.labels.mySQLHeatwaveShape(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.HeatwaveShape, GROUP_CONFIG)?.[0]?.text,
        },
      );
    }

    return reviewConfigInfo;
  };

  const buildNetworkingInfo = (formValues: FormValues): ReviewItem[] => {
    const renderList = (list: string[]): JSX.Element => {
      const elements = list.map(item => (
        <Stack horizontal key={item}>
          <span style={{ paddingBottom: 1 }}>
            {item}
          </span>
        </Stack>
      ));
      return (
        <div>
          {elements}
        </div>
      );
    };

    const reviewNetworkingInfo: ReviewItem[] = [];

    if (!(enableMcvcn && enableMdsMcvcnCreate)) {
      const cidrRange = getValue<string>(formValues, NetworkFields.Cidrs, GROUP_NETWORK);
      const cidrAddresses = getAddressRange(cidrRange);
      const cidr = [`${cidrRange} [${cidrAddresses}]`];
      const networkVirtualAppliance = getValue<string>(
        formValues,
        NetworkFields.NetworkVirtualAppliance,
        GROUP_NETWORK,
      );

      reviewNetworkingInfo.push(
        {
          label: Messages.labels.hostName(),
          value: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK),
        },
        {
          label: Messages.labels.ipAddress(),
          value: getValue<string>(formValues, NetworkFields.IpAddress, GROUP_NETWORK),
        },
        {
          label: Messages.labels.virtualNetwork(),
          value: getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].text,
        },
        ...(networkVirtualAppliance
          ? [{ label: Messages.labels.networkVirtualAppliance(), value: networkVirtualAppliance }]
          : []),
        {
          label: Messages.labels.ociCidrs(),
          value: renderList(cidr),
        },
      );
    }

    if (enableMcvcn && enableMdsMcvcnCreate) {
      // eslint-disable-next-line max-len
      const data: MultiCloudVirtualNetworkSummary | FutureMcvcnExisting | FutureMcvcnNew | undefined = getValue<SelectOption[]>(
        formValues,
        NetworkFields.Mcvcn,
        GROUP_NETWORK,
      )?.[0]?.data;

      if (!data) return [];

      // ID only exists if the user has selected an existing MCVCN
      const createNewMcvcn = !(data as MultiCloudVirtualNetworkSummary).id;
      // Exists if the user is creating a new MCVCN
      const { name, azureAttachedNetworkId, customerNvaIpAddress } = data as FutureMcvcn;
      const virtualNetwork = decodeURIComponent(parseId(azureAttachedNetworkId)?.resourceName || "");

      // Exists if the user is creating a new MCVCN using a new OCI VCN
      const { ociVcnCidrBlocks } = data as FutureMcvcnNew;
      // Exists if the user is creating a new MCVCN using an existing OCI VCN
      const { ociSubnetOcids, ociVcnOcid } = data as FutureMcvcnExisting;

      reviewNetworkingInfo.push(
        {
          label: Messages.labels.hostName(),
          value: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK),
        },
        {
          label: Messages.labels.ipAddress(),
          value: getValue<string>(formValues, NetworkFields.IpAddress, GROUP_NETWORK),
        },
        {
          label: Messages.labels.virtualCloudNetwork(),
          value: name,
        },
      );

      if (createNewMcvcn) {
        reviewNetworkingInfo.push(
          ...(virtualNetwork
            ? [{ label: Messages.labels.virtualNetwork(), value: virtualNetwork }]
            : []),
          ...(customerNvaIpAddress
            ? [{ label: Messages.labels.networkVirtualAppliance(), value: customerNvaIpAddress }]
            : []),
          ...(ociVcnCidrBlocks
            ? [{
              label: Messages.labels.cidrBlocks(),
              value: renderList(ociVcnCidrBlocks.map(cidr => `${cidr} [${getAddressRange(cidr)}]`)),
            }]
            : []),
          ...(ociVcnOcid
            ? [{ label: Messages.labels.vcnOcid(), value: ociVcnOcid }]
            : []),
          ...(ociSubnetOcids
            ? [{ label: Messages.labels.subnetOcids(), value: renderList(ociSubnetOcids) }]
            : []),
        );
      }
    }

    return reviewNetworkingInfo;
  };
  const buildSecurityInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewSecurityInfo: ReviewItem[] = [
      {
        label: Messages.labels.username(),
        value: getValue<string>(formValues, SecurityFields.Username, GROUP_SECURITY),
      },
    ];

    return reviewSecurityInfo;
  };
  const buildManagementInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewManagementInfo: ReviewItem[] = [];
    const enableAutoBackup = getValue<boolean>(formValues, ManagementFields.Backups, GROUP_MANAGEMENT);
    const enablePitrPolicy = getValue<boolean>(formValues, ManagementFields.PitrPolicy, GROUP_MANAGEMENT);
    const enableDeleteProtected = getValue<boolean>(
      formValues,
      ManagementFields.DeleteProtected,
      GROUP_MANAGEMENT,
    );
    const enableRetainAutoBackup = getValue<boolean>(
      formValues,
      ManagementFields.RetainAutomaticBackups,
      GROUP_MANAGEMENT,
    );
    const enableRequireFinalBackup = getValue<boolean>(
      formValues,
      ManagementFields.RequireFinalBackup,
      GROUP_MANAGEMENT,
    );
    const enableCrashRecovery = getValue<boolean>(formValues, ManagementFields.CrashRecovery, GROUP_MANAGEMENT);

    reviewManagementInfo.push({
      label: Messages.labels.automaticBackups(),
      value: enableAutoBackup ? Messages.common.enabled() : Messages.common.disabled(),
    });

    if (enableAutoBackup) {
      reviewManagementInfo.push(
        {
          label: Messages.labels.backupRetentionPeriod(),
          value: getValue<string>(formValues, ManagementFields.RetentionPeriod, GROUP_MANAGEMENT),
        },
        {
          label: `${Messages.labels.mySQLWindowStartTime()}`,
          value: getValue<SelectOption[]>(formValues, ManagementFields.WindowStartTime, GROUP_MANAGEMENT)?.[0].text,
        },

        {
          label: Messages.labels.pointInTimeRestore(),
          value: enablePitrPolicy ? Messages.common.enabled() : Messages.common.disabled(),
        },
      );
    }
    // deletion plan
    reviewManagementInfo.push(
      {
        label: Messages.labels.mySQLDeleteProtected(),
        value: enableDeleteProtected ? Messages.common.enabled() : Messages.common.disabled(),
      },
      {
        label: Messages.labels.mySQLRetainAutomaticBackups(),
        value: enableRetainAutoBackup ? Messages.common.enabled() : Messages.common.disabled(),
      },
      {
        label: Messages.labels.mySQLRequireFinalBackup(),
        value: enableRequireFinalBackup ? Messages.common.enabled() : Messages.common.disabled(),
      },
    );
    // crash recovery
    reviewManagementInfo.push(
      {
        label: Messages.labels.crashRecovery(),
        value: enableCrashRecovery ? Messages.common.enabled() : Messages.common.disabled(),
      },
    );

    return reviewManagementInfo;
  };

  const buildTagsInfo = (formValues: FormValues): ReviewItem[] => {
    const tagsInfo = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS) ?? [];
    const tags: { label: string; value: string; }[] = [];

    tagsInfo.forEach(tag => {
      tag.resources?.forEach(resource => (
        tag.name !== undefined && tags.push({
          label: tag.name,
          value: `${tag.value !== undefined ? tag.value : ""}(${resource.text})`,
        })
      ));
    });
    if (tags.length === 0) tags.push({ label: "None", value: "" });
    tags.sort((a, b) => ((a.value.split("(")[1].split(")")[0] > b.value.split("(")[1].split(")")[0]) ? 1 : -1));
    return tags;
  };

  const onRenderReview = (formValues: FormValues): JSX.Element => {
    const reviewSections: ReviewSection[] = [
      { title: Messages.createMysql.basicsTab.title(), items: buildBasicInfo(formValues) },
      { title: Messages.createMysql.configurationTab.title(), items: buildConfigurationInfo(formValues) },
      { title: Messages.createMysql.networkingTab.title(), items: buildNetworkingInfo(formValues) },
      { title: Messages.createMysql.securityTab.title(), items: buildSecurityInfo(formValues) },
      { title: Messages.createMysql.managementTab.title(), items: buildManagementInfo(formValues) },
      { title: Messages.tags.title(), items: buildTagsInfo(formValues) },
    ];
    return <ReviewTabContent reviewSections={reviewSections} />;
  };

  const getTagsResourceTypeOptions = (): SelectOption[] => {
    const resourceOptions: SelectOption[] = [
      { id: ResourceType.DATABASE, text: getResourceTypeMessage(ResourceType.DATABASE) },
    ];
    return resourceOptions;
  };

  const validator = (formValues: FormValues): FormFieldErrors[] | undefined => {
    const formFieldErrors: FormFieldErrors[] = [];

    // *** Validate point in time restore is supported

    const configValues = formValues[GROUP_CONFIG];
    const mysqlVersion = configValues?.[ConfigFields.MysqlVersion];
    const mysqlVersionValue = mysqlVersion?.[0]?.id;

    const managementValues = formValues[GROUP_MANAGEMENT];
    const backupEnabled = managementValues?.[ManagementFields.Backups];

    formFieldErrors.push(
      {
        errors: mysqlVersionValue ? validateMdsPitrPolicy(mysqlVersionValue, backupEnabled) : undefined,
        field: ConfigFields.MysqlVersion,
        group: GROUP_CONFIG,
      },
    );

    // *** Validate nva within vnet address range

    const virtualNetwork: AzureVirtualNetworkSummary | undefined = getValue<SelectOption[]>(
      formValues,
      NetworkFields.Vnet,
      GROUP_NETWORK,
    )?.[0]?.data;

    const networkVirtualAppliance = getValue<string>(formValues, NetworkFields.NetworkVirtualAppliance, GROUP_NETWORK);

    formFieldErrors.push({
      errors:
        networkVirtualAppliance
          ? validateIpAddressWithinRanges(networkVirtualAppliance, virtualNetwork?.addressSpaces ?? [])
          : undefined,
      field: NetworkFields.NetworkVirtualAppliance,
      group: GROUP_NETWORK,
    });

    return formFieldErrors;
  };

  const wizardItems = [
    {
      testId: PanelTestIds.Basics,
      header: Messages.createMysql.basicsTab.title(),
      groupName: GROUP_BASICS,
      content: (
        <BasicsTabContent
          inputWizardPanelRef={panelRef}
          location={location}
          subscription={subscriptionId}
        />
      ),
    },
    {
      testId: PanelTestIds.Configuration,
      header: Messages.createMysql.configurationTab.title(),
      groupName: GROUP_CONFIG,
      content: (
        <ConfigTabContent
          inputWizardPanelRef={panelRef}
          subscriptionId={subscriptionId}
          location={location}
        />
      ),
    },
    {
      testId: PanelTestIds.Network,
      header: Messages.createMysql.networkingTab.title(),
      groupName: GROUP_NETWORK,
      content: (
        <NetworkTabContent
          inputWizardPanelRef={panelRef}
          location={location}
          subscriptionId={subscriptionId}
        />
      ),
      onUnselecting: closePanels,
    },
    {
      testId: PanelTestIds.Security,
      header: Messages.createMysql.securityTab.title(),
      groupName: GROUP_SECURITY,
      content: <SecurityTabContent />,
    },
    {
      testId: PanelTestIds.Management,
      header: Messages.createMysql.managementTab.title(),
      groupName: GROUP_MANAGEMENT,
      content: <ManagementTabContent />,
    },
    {
      testId: PanelTestIds.Tags,
      header: Messages.tags.title(),
      groupName: GROUP_TAGS,
      content: <TagsTabContent resourceOptions={getTagsResourceTypeOptions()} />,
    },
  ];

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.createMysql.titles.short()}
    >
      <InputWizardPanel
        testId={CreateWizardTestIds.Mysql}
        componentRef={setPanelRef}
        title={Messages.createMysql.titles.long()}
        message={isCreateRoleMissing
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(requiredRole),
          } as PanelMessage
          : undefined}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFormChange={(_formValues: FormValues, fieldValue: any, field: string) => {
          if (field === BasicsFields.Subscription) {
            setSubscriptionId(fieldValue?.[0]?.id);
          } else if (field === BasicsFields.ResourceGroup) {
            setResourceGroupName(fieldValue?.[0]?.text);
          } else if (field === BasicsFields.Region) {
            setLocation(fieldValue?.[0]?.id);
          }
        }}
        items={wizardItems}
        itemsMessages={[{
          itemNdx: 0,
          type: MessageType.WARNING,
          message: Messages.createCommon.dependencyWarnings.basicsChangeWarning(Messages.labels.mySQLHeatwave()),
          hideOnFirstSelect: true,
        }] as InputWizardItemMessage[]}
        onRenderReview={onRenderReview}
        onUnselectingReview={() => {
          // Reset to cover the case of the user navigating away from the review tab before the results come back
          setCreateMySQLPayload(undefined);
          setCreationPhase(undefined);
        }}
        onAsyncValidate={enableMdsPreflight ? onAsyncValidate : undefined}
        onSubmit={(
          formValues: FormValues,
          reject: (errorMessage: string, validationErrors?: AsyncValidationError[]) => void,
        ) => {
          submitCallbacks.current = { reject };
          onSubmit(formValues);
        }}
        onClose={() => {
          const analytics = customData?.analytics as NavigationAnalyticsData;
          if (analytics) {
            trackActionDiscard(analytics.actionName, analytics.pageId, analytics.panelId);
          }
          back();
        }}
        validator={validator}
      />
      {creationPhase === CreationPhase.CREATE_SUBMIT && (
        <DeploymentCreatePhase
          panelRef={panelRef}
          deployedService={DeployedService.MYSQL_DATABASE}
          subscriptionId={subscriptionId}
          resourceGroupName={resourceGroupName}
          location={location}
          resourceName={resourceNameRef.current}
          deploymentName={deploymentNameRef.current}
          submitPayload={createMySQLPayload}
          submitReject={submitCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          asyncNotification={{
            methodKey: AsyncNotificationMethodKey.DEPLOYMENT_GET,
            delay: MDS_DEPLOYMENT_CREATE_POLL_DELAY,
            pollingInterval: MDS_DEPLOYMENT_CREATE_POLL_INTERVAL,
            polledResponseKey: AsyncNotificationPolledResponseKey.MDS_DEPLOYMENT_CREATED_CHECK,
          }}
          onPostSubmit={() => {
            setCreateMySQLPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
      {creationPhase === CreationPhase.ASYNC_VALIDATE && (
        <DeploymentAsyncValidationPhase
          location={location}
          asyncValidatePayload={createMySQLPayload}
          asyncValidateResolve={asyncValidationCallbacks.current.resolve}
          asyncValidateReject={asyncValidationCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          onPostAsyncValidate={() => {
            setCreateMySQLPayload(undefined);
            setCreationPhase(undefined);
          }}
        />
      )}
    </BookmarkablePage>
  );
};
