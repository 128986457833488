import {
  CalloutComponent,
  FormValues,
  getValue,
  InputFormCallout,
  InputFormGroup,
  SelectOption,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { SystemModelShapeSelect } from "../../components/SystemModelShapeSelect/SystemModelShapeSelect";
import { CalloutTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_UPDATE_POLL_DELAY,
  MDS_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export enum Fields {
  Shape = "shape",
}

export enum FieldTestIds {
  Shape = "shape",
}

export interface MysqlEditShapeProps extends OperationActionProps {
  databaseId: string | undefined;
  shape: string | undefined;
}

const MysqlEditShape = ({
  shape,
  targetId,
  location,
  databaseId,
  onExecute,
  onCancel,
}: MysqlEditShapeProps): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resourceGroupName = decodeURIComponent(resourceGroup || "");
  const dbSystemName = decodeURIComponent(resourceName || "");

  const [initialValues, setInitialValues] = React.useState<FormValues>();
  const [calloutRef, setCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);
  const { invokeCall: invokeMdsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.updateMdsDbSystem },
  );

  const onUpdate = (formValues: FormValues): void => {
    const shapeName = getValue<SelectOption[]>(formValues, Fields.Shape, InputFormGroup)?.[0]?.id;
    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: calloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateMysqlShape(),
          message: Messages.notifications.inProgress.messages.updateMysqlShape(dbSystemName),
        },
        success: {
          title: Messages.notifications.success.titles.updateMysqlShape(),
          message: Messages.notifications.success.messages.updateMysqlShape(dbSystemName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateMysqlShape(),
          message: Messages.notifications.failure.messages.updateMysqlShape(dbSystemName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            dbSystemName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_UPDATED_CHECK,
          delay: MDS_UPDATE_POLL_DELAY,
          interval: MDS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeMdsUpdate({
      subscriptionId,
      resourceGroupName,
      dbSystemName,
      updateMdsDbSystemDetails: { shapeName },
    }, invokeOptions);
  };

  React.useEffect(() => {
    setInitialValues({ [Fields.Shape]: shape ? [shape] : undefined });
  }, [shape]);

  return (
    <InputFormCallout
      targetId={targetId}
      title={Messages.createNewPanels.editShape.title()}
      componentRef={setCalloutRef}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onUpdate}
      onClose={onCancel}
      testId={CalloutTestIds.MysqlEditShape}
      initialValues={initialValues}
    >
      <SystemModelShapeSelect
        testId={FieldTestIds.Shape}
        fieldName={Fields.Shape}
        label={Messages.labels.systemModelShape()}
        required
        subscriptionId={subscriptionId}
        defaultValue={shape}
        location={location}
        tooltip={Messages.hints.selectASystemModelShape()}
      />
    </InputFormCallout>
  );
};

export const newMysqlEditShape = (
  props: MysqlEditShapeProps,
): JSX.Element => (<MysqlEditShape {...props} />);
