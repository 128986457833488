import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { getTagsMap, TagsInfoType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_CDB_UPDATE_POLL_DELAY,
  VMDB_CDB_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface VmDbUpdateTagsCdbProps extends OperationConfirmationProps {
  databaseId: string | undefined;
  tags: TagsInfoType;
}

const VmDbUpdateTagsCdb = (
  { location, databaseId, tags, onExecute }: VmDbUpdateTagsCdbProps,
): JSX.Element => {
  const { invokeCall: invokeCdbUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.updateVmdbDatabase },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName: cdbName } = parseId(databaseId);
    const resGroup = decodeURIComponent(resourceGroup || "");

    const invokeCdbOptions = {
      onSuccess: onExecute,
      onFailure: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.applyTags(),
          message: Messages.notifications.inProgress.messages.applyTags(cdbName),
        },
        success: {
          title: Messages.notifications.success.titles.applyTags(),
          message: Messages.notifications.success.messages.applyTags(cdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.applyTags(),
          message: Messages.notifications.failure.messages.applyTags(cdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_CDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: cdbName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_UPDATED_CHECK_CDB,
          delay: VMDB_CDB_UPDATE_POLL_DELAY,
          interval: VMDB_CDB_UPDATE_POLL_INTERVAL,
        },
      },
    };

    invokeCdbUpdate({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName: cdbName,
      updateDatabaseDetails: { freeformTags: getTagsMap(tags) },
    }, invokeCdbOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newVmDbUpdateTagsCdb = (
  props: VmDbUpdateTagsCdbProps,
): JSX.Element => (<VmDbUpdateTagsCdb {...props} />);
