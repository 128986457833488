import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AddSshKeyPanel, Fields } from "../../components/AddSshKeyPanel/AddSshKeyPanel";
import { DerivedFields as NestedSshInputFields } from "../../components/SshKeyPair/SshKeyPairInput";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMCLUSTER_EDIT_POLL_DELAY,
  VMCLUSTER_EDIT_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface ExaVmClusterAddSshKeyPairProps extends OperationProps {
    vmClusterId: string | undefined;
    sshPublicKeys: string[] | undefined;
  }

const ExaVmClusterAddSshKeyPair = (
  { location, vmClusterId, sshPublicKeys, onCancel, onExecute }: ExaVmClusterAddSshKeyPairProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(vmClusterId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeVmClusterUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.updateCloudVmCluster },
  );
  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>();

  const onAddSshKey = (formValues: FormValues): void => {
    const keyArr = JSON.parse(JSON.stringify(sshPublicKeys || []));

    const publicKey = getValue<Record<NestedSshInputFields, string>>(
      formValues,
      Fields.sshKeyPair,
      InputFormGroup,
    )?.[NestedSshInputFields.PublicKey];

    if (publicKey) {
      keyArr.push(publicKey);
    }
    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: () => sidePanelRef?.allowResubmit(),
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.addSshKey(),
          message: Messages.notifications.inProgress.messages.addSshKey(resName),
        },
        success: {
          title: Messages.notifications.success.titles.addSshKey(),
          message: Messages.notifications.success.messages.addSshKey(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.addSshKey(),
          message: Messages.notifications.failure.messages.addSshKey(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMCLUSTER_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            cloudVmClusterName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMCLUSTER_EDIT_CHECK,
          delay: VMCLUSTER_EDIT_POLL_DELAY,
          interval: VMCLUSTER_EDIT_POLL_INTERVAL,
        },
      },
    };
    invokeVmClusterUpdate({
      subscriptionId,
      resourceGroupName: resGroup,
      cloudVmClusterName: resName,
      updateCloudVmClusterDetails: { sshPublicKeys: keyArr },
    }, invokeOptions);
  };
  return (
    <AddSshKeyPanel
      testId={SidePanelTestIds.ExaVmClusterAddSshKeyPair}
      title={Messages.addSshKey.title()}
      componentRef={setSidePanelRef}
      onSubmit={(formValues: FormValues) => onAddSshKey(formValues)}
      onClose={onCancel}
    />
  );
};

export const newExaVmClusterAddSshKeyPair = (
  props: ExaVmClusterAddSshKeyPairProps,
): JSX.Element => (<ExaVmClusterAddSshKeyPair {...props} />);
