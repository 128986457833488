import React from "react";
import { emptyDivTestId, MenuStyleMode } from "../constants/uiConstants";
import { TopMenu, TopMenuProps } from "./TopMenu";

export interface AsideMenuProps extends Pick<TopMenuProps, "menuState" | "onMenuStateChange"> {
  menuStyleMode?: MenuStyleMode;
}

export const AsideMenu = ({ menuStyleMode, menuState, onMenuStateChange }: AsideMenuProps): JSX.Element => {
  if (MenuStyleMode.Docking === menuStyleMode) {
    return (
      <aside style={{ zIndex: "1" }}>
        <TopMenu
          menuStyleMode={menuStyleMode}
          insetShadow
          menuState={menuState}
          onMenuStateChange={onMenuStateChange}
        />
      </aside>
    );
  }
  return <aside><div data-test-id={emptyDivTestId} /></aside>;
};
