import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  Fields as SupportUpdateFields,
  SupportUpdatePanel,
} from "../../components/SupportUpdatePanel/SupportUpdatePanel";
import {
  IncidentProblemTypeEnum,
  UpdateActivityItemDetails,
  UpdateKindValues,
} from "../../gen/clients/mchub-azure-api-client";
import { parseSupportId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface SupportAddCommentValues {
  title: string | undefined;
  problemType: IncidentProblemTypeEnum | undefined;
}

export interface SupportAddCommentProps extends OperationProps {
  incidentId: string | undefined;
  supportAddCommentValues: SupportAddCommentValues;
}

const SupportAddComment = (
  { location, incidentId, supportAddCommentValues, onExecute, onCancel }: SupportAddCommentProps,
): JSX.Element => {
  const [panelRef, setPanelRef] = React.useState<InputFormSidePanelComponent>({} as InputFormSidePanelComponent);
  const { subscriptionId, resourceName } = parseSupportId(incidentId);
  const resName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).incidentsApi.updateIncident },
  );

  const onUpdateSupport = (formValues: FormValues): void => {
    const comments = getValue<string>(formValues, SupportUpdateFields.Comments, InputFormGroup);
    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: panelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateSupportRequest(),
          message: Messages.notifications.inProgress.messages.updateSupportRequest(supportAddCommentValues.title || ""),
        },
        success: {
          title: Messages.notifications.success.titles.updateSupportRequest(),
          message: Messages.notifications.success.messages.updateSupportRequest(supportAddCommentValues.title || ""),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateSupportRequest(),
          message: Messages.notifications.failure.messages.updateSupportRequest(supportAddCommentValues.title || ""),
        },
      },
    };
    invokeUpdate({
      subscriptionId,
      incidentKey: resName,
      problemType: supportAddCommentValues.problemType,
      updateIncidentDetails: {
        problemType: supportAddCommentValues.problemType,
        item: {
          kind: UpdateKindValues.ACTIVITY,
          comments,
          activityType: "UPDATE",
        } as UpdateActivityItemDetails,
      },
    }, invokeOptions);
  };

  return (
    <SupportUpdatePanel
      componentRef={setPanelRef}
      onSubmit={onUpdateSupport}
      onClose={onCancel}
      title={Messages.updateSupportRequest.title()}
    />
  );
};

export const newSupportAddComment = (
  props: SupportAddCommentProps,
): JSX.Element => (<SupportAddComment {...props} />);
