import { Listing, ListingColumn } from "o4a-react";
import * as React from "react";
import { Stack, Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds } from "../../constants/uiConstants";
import { Database, PluggableDatabase } from "../../gen/clients/mchub-azure-api-client-exa";
import { useAnalytics } from "../../hooks/useAnalytics";

export enum ActionIds {
  CopyConnectionString = "copy-connection-string",
}

export enum ListingActionTestIds {
  CopyConnectionString = "copy-connection-string",
}

enum ColumnIds {
  ConnectionString = "connectionString",
  Id = "id",
}

export enum ColumnTestIds {
  ConnectionString = "connectionString",
  Id = "id",
}

export interface ExaDbConnectDetailsProps {
  database: Database | PluggableDatabase | undefined;
  isCdb: boolean,
}

export const ExaDbConnectDetails = ({ database, isCdb }: ExaDbConnectDetailsProps): JSX.Element => {
  const { trackActionClick } = useAnalytics();
  const pageId = isCdb ? PageId.EXADB_CDB_DETAILS : PageId.EXADB_PDB_DETAILS;

  const connectionStrings = Object.keys(database?.connectionStrings?.allConnectionStrings || {}).map(value => ({
    id: value,
    connectionString: database?.connectionStrings?.allConnectionStrings?.[value],
  }));

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Id,
      testId: ColumnTestIds.Id,
      name: Messages.labels.tnsName(),
      flexGrow: 1,
    },
    {
      itemProp: ColumnIds.ConnectionString,
      testId: ColumnTestIds.ConnectionString,
      name: Messages.labels.connectionString(),
      flexGrow: 3,
    },
  ];

  return (
    <Stack style={{ height: "100%" }}>
      <Text variant="mediumPlus">
        {Messages.detailExaDb.connect.sectionTitles.connectionStrings()}
      </Text>
      <br />
      <Listing
        testId={ListingTestIds.ExaDbConnect}
        items={connectionStrings}
        emptyList={{ title: Messages.common.noResults() }}
        listColumns={columns}
        actions={[
          {
            key: ActionIds.CopyConnectionString,
            testId: ListingActionTestIds.CopyConnectionString,
            text: Messages.actions.copyConnectionString(),
            icon: MonochromeIconIds.Copy,
            onClick: value => {
              trackActionClick(ActionIds.CopyConnectionString, pageId, DetailsPanelId.CONNECT);
              navigator.clipboard.writeText(value?.connectionString || "");
            },
          },
        ]}
      />
    </Stack>
  );
};
