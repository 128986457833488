import { ActionBar, ActionType, LabelMetaItem, MetaItemSection, NoValue } from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds } from "../../constants/uiConstants";
import { VmdbDbSystem } from "../../gen/clients/mchub-azure-api-client-vmdb";
import {
  getDbSystemDatabaseEditionDisplayName,
  getLocalizedLicenseType,
  getShapeDisplayName,
  getStoragePerformanceModeDisplayName,
  getStorageTypeDisplayName,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import { useSidePanel } from "../../hooks/useSidePanel";
import {
  newVmDbSystemEditLicense,
  VmDbSystemEditLicenseProps,
} from "../../operations/VmDbSystem/VmDbSystemEditLicense";
import {
  newVmDbSystemEditStorage,
  VmDbSystemEditStorageProps,
} from "../../operations/VmDbSystem/VmDbSystemEditStorage";
import { newVmDbSystemScale, VmDbSystemScaleProps } from "../../operations/VmDbSystem/VmDbSystemScale";

export enum ActionIds {
  Scale = "scale",
  Refresh = "refresh",
  EditLicense = "edit-license",
  EditStorage = "edit-storage",
}

export enum ActionBarTestIds {
  Scale = "scale",
  Refresh = "refresh",
}

export enum MetaItemSectionTestIds {
  DATABASE_EDITION = "mis-database-edition",
  SHAPE = "mis-shape",
  OCPU_COUNT = "mis-ocpu-count",
  NODE_COUNT = "mis-node-count",
  DATA_STORAGE = "mis-total-storage",
  STORAGE_PERFORMANCE_MODE = "mis-storage-performance-mode",
  LICENSE = "mis-license",
  HOSTNAME = "mis-hostname",
  LICENSE_PORT = "mis-license-port",
  STORAGE_TYPE = "mis-storage-type",
}

export enum MetaItemActionTestIds {
  EditLicense = "edit-license",
  EditStorage = "edit-storage",
}

export interface VmDbSystemConfigurationDetailsProps {
  dbSystem: VmdbDbSystem | undefined;
  location: string;
  refresh: () => void;
}

export const VmDbSystemConfigurationDetails = (
  { dbSystem, location, refresh }: VmDbSystemConfigurationDetailsProps,
): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();
  const { enableVmdbStoragePerformanceMode, enableVmdbStorageManagementType } = useFeatures();

  const sanitizeAvailableStorage = (storage: string | undefined): string => {
    if (!storage) return "";

    return Messages.labels.storageValueInGBPlaceholder(storage);
  };

  const { trigger: triggerEditLicense } = useOperation<VmDbSystemEditLicenseProps>(newVmDbSystemEditLicense);
  const { trigger: triggerEditStorage } = useOperation<VmDbSystemEditStorageProps>(newVmDbSystemEditStorage);
  const { trigger: triggerScale } = useOperation<VmDbSystemScaleProps>(newVmDbSystemScale);

  const { closePanels } = useSidePanel();

  const onEditLicense = (): void => {
    triggerEditLicense({
      targetId: ActionIds.EditLicense,
      dbSystemId: dbSystem?.id,
      systemLicenseModel: dbSystem?.licenseModel,
      location,
      onExecute: refresh,
      onCancel: () => trackActionDiscard(
        ActionIds.EditLicense,
        PageId.VMDB_SYSTEM_DETAILS,
        DetailsPanelId.CONFIGURATION,
      ),
    });
  };
  const onEditStorage = (): void => {
    triggerEditStorage({
      targetId: ActionIds.EditStorage,
      dbSystemId: dbSystem?.id,
      storageConfig: { dataStorageSizeInGBs: dbSystem?.dataStorageSizeInGBs?.toString() },
      location,
      onExecute: refresh,
      onCancel: () => trackActionDiscard(
        ActionIds.EditStorage,
        PageId.VMDB_SYSTEM_DETAILS,
        DetailsPanelId.CONFIGURATION,
      ),
    });
  };

  const overviewActionItems: ActionType[] = [
    {
      key: ActionIds.Scale,
      testId: ActionBarTestIds.Scale,
      text: Messages.actions.scale(),
      icon: MonochromeIconIds.Scale,
      onClick: () => {
        trackActionClick(ActionIds.Scale, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.CONFIGURATION);
        triggerScale({
          targetId: ActionIds.Scale,
          dbSystemId: dbSystem?.id,
          scaleConfig: dbSystem
            ? {
              shapeId: dbSystem.shape,
              cpuCoreCount: dbSystem.cpuCoreCount,
              nodeCount: dbSystem.nodeCount,
            }
            : undefined,
          location,
          onExecute: refresh,
          onCancel: () => trackActionDiscard(ActionIds.Scale, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.CONFIGURATION),
        });
      },
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.CONFIGURATION);
        refresh();
      },
    },
  ];

  const essentialsMetaItemGroup: JSX.Element[] = [
    <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="vmdb-system-configuration-left-columm">
      <LabelMetaItem testId={MetaItemSectionTestIds.DATABASE_EDITION} label={Messages.labels.databaseEdition()}>
        {dbSystem?.databaseEdition ? getDbSystemDatabaseEditionDisplayName(dbSystem.databaseEdition) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.SHAPE} label={Messages.labels.shape()}>
        {dbSystem?.shape ? getShapeDisplayName(dbSystem.shape) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.OCPU_COUNT} label={Messages.labels.ocpuCount()}>
        {dbSystem?.cpuCoreCount?.toString() || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.NODE_COUNT} label={Messages.labels.nodeCount()}>
        {dbSystem?.nodeCount?.toString() || <NoValue />}
      </LabelMetaItem>
      {enableVmdbStoragePerformanceMode && (
      <LabelMetaItem
        testId={MetaItemSectionTestIds.STORAGE_PERFORMANCE_MODE}
        label={Messages.labels.storageVolumePerformance()}
      >
        {dbSystem?.storageVolumePerformanceMode
          ? getStoragePerformanceModeDisplayName(dbSystem.storageVolumePerformanceMode?.toString() || "")
          : <NoValue />}
      </LabelMetaItem>
      )}
      {enableVmdbStorageManagementType && (
        <LabelMetaItem
          testId={MetaItemSectionTestIds.STORAGE_TYPE}
          label={Messages.labels.StorageManagementSoftware()}
        >
          {dbSystem?.dbSystemOptions?.storageManagement
            ? getStorageTypeDisplayName(dbSystem?.dbSystemOptions?.storageManagement?.toString() || "")
            : <NoValue />}
        </LabelMetaItem>
      )}
      <LabelMetaItem
        testId={MetaItemSectionTestIds.DATA_STORAGE}
        label={Messages.labels.availableDataStorage()}
        action={{
          id: ActionIds.EditStorage,
          testId: MetaItemActionTestIds.EditStorage,
          label: Messages.actions.edit(),
          onClick: () => {
            trackActionClick(ActionIds.EditStorage, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.CONFIGURATION);
            onEditStorage();
          },
        }}
      >
        {dbSystem?.dataStorageSizeInGBs
          ? sanitizeAvailableStorage(dbSystem.dataStorageSizeInGBs?.toString())
          : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem
        testId={MetaItemSectionTestIds.LICENSE}
        label={Messages.labels.license()}
        action={{
          id: ActionIds.EditLicense,
          testId: MetaItemActionTestIds.EditLicense,
          label: Messages.actions.edit(),
          onClick: () => {
            trackActionClick(ActionIds.EditLicense, PageId.VMDB_SYSTEM_DETAILS, DetailsPanelId.CONFIGURATION);
            onEditLicense();
          },
        }}
      >
        {dbSystem?.licenseModel ? getLocalizedLicenseType(dbSystem.licenseModel) : <NoValue />}
      </LabelMetaItem>
    </Stack>,
    <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="vmdb-system-configuration-right-columm">
      <LabelMetaItem testId={MetaItemSectionTestIds.HOSTNAME} label={Messages.labels.hostNameOnly()}>
        {(dbSystem?.hostname && dbSystem?.domain) ? `${dbSystem.hostname}.${dbSystem.domain}` : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.LICENSE_PORT} label={Messages.labels.listenerPort()}>
        {dbSystem?.listenerPort?.toString() || <NoValue />}
      </LabelMetaItem>
    </Stack>,
  ];

  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
      <ActionBar actions={overviewActionItems} onActionClick={closePanels} />
      <MetaItemSection labelWidth={185}>
        {essentialsMetaItemGroup}
      </MetaItemSection>
    </Stack>
  );
};
