import { NotificationRequest, NotificationType, optimizedRetryOption, useNotificationRequest } from "o4a-react";
import * as React from "react";
import { NormalError, useQuery } from "savant-connector";
import apiClients from "../apiClients";
import * as Messages from "../codegen/Messages";
import { ttlFiveMinCaching } from "../constants/uiConstants";
import { AzureCloudLinkCollection, AzureCloudLinkSummary } from "../gen/clients/mchub-azure-api-client";
import { responseItemstoArray } from "../helpers/resourceHelper";
import { getRealmRegions } from "../utils";

export interface CloudLinksResult {
  loading: boolean;
  error?: NormalError;
  cloudLinks?: AzureCloudLinkSummary[];
  refresh: () => void;
}
export const useCloudLinks = (): CloudLinksResult => {
  const { submit: submitNotificationRequest } = useNotificationRequest();
  const [isRefreshLoading, setIsRefreshLoading] = React.useState<boolean>(true);

  const { response, loading, error, refresh } = useQuery({
    method: apiClients.withRegion(getRealmRegions().defaultRegion).cloudLinkApi.listAzureCloudLinks,
    options: {
      args: { },
      caching: ttlFiveMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
  });

  const cloudLinks = (response
    && responseItemstoArray<AzureCloudLinkCollection, AzureCloudLinkSummary>(response))?.filter(
    cloudLink => cloudLink.lifecycleState === "ACTIVE" || cloudLink.lifecycleState === "UPDATING",
  );

  React.useEffect(() => {
    if (error && isRefreshLoading) {
      refresh();
      setIsRefreshLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isRefreshLoading]);

  React.useEffect(() => {
    if (!isRefreshLoading && !loading && error) {
      submitNotificationRequest({
        type: NotificationType.FAILURE,
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadCloudLinks(),
        apiError: error.body.message,
      } as NotificationRequest);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshLoading, loading, error]);

  const result = React.useMemo(() => ({
    loading,
    error,
    refresh,
    cloudLinks,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [error, loading, refresh, JSON.stringify(cloudLinks)]);

  return result;
};
