import { FieldSet, InputWizardPanelComponent, SelectComponent, TextInput } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { RegionSelect } from "../../components/RegionSelect/RegionSelect";
import { ResourceGroupSelect } from "../../components/ResourceGroupSelect/ResourceGroupSelect";
import { SubscriptionSelect } from "../../components/SubscriptionSelect/SubscriptionSelect";
import { validateMcvcnName } from "../../helpers/validationHelper";

export const GROUP_BASICS = "basics";

export enum Fields {
  Subscription = "subscriptionId",
  ResourceGroup = "resourceGroupId",
  Name = "name",
  Region = "region",
}

export enum FieldTestIds {
  Name = "name",
  ResourceGroup = "resource-group",
  Subscription = "subscription",
  Region = "region"
}

export interface BasicsTabComponent {
  closeSidePanels: () => void;
}
export interface BasicsTabContentProps {
  inputWizardPanelRef: InputWizardPanelComponent;
}

export const BasicsTabContent = ({ inputWizardPanelRef }: BasicsTabContentProps): JSX.Element => {
  const [subscriptionId, setSubscriptionId] = React.useState<string>("");

  const resourceSelectRef = React.useRef<SelectComponent>();
  const setResourceSelectRef = (selectComponent: SelectComponent): void => {
    resourceSelectRef.current = selectComponent;
  };

  const onSubscriptionChange = (subId: string): void => setSubscriptionId(subId);

  const onResourceGroupError = (): void => {
    inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.resourceGroups());
  };

  const onResourceGroupMissingDependencies = (): void => inputWizardPanelRef.showErrorDialog(
    Messages.createCommon.dependencyWarnings.subscriptionNotSelected.message(),
    Messages.createCommon.dependencyWarnings.subscriptionNotSelected.title(),
  );

  return (
    <>
      <FieldSet header={Messages.createMcvcn.basicsTab.sectionTitles.projectDetails()}>
        <SubscriptionSelect
          required
          onChange={onSubscriptionChange}
          testId={FieldTestIds.Subscription}
          fieldName={Fields.Subscription}
          label={Messages.labels.subscription()}
          tooltip={Messages.hints.toolTipSubscription()}
          defaultValue={subscriptionId ? [subscriptionId] : undefined}
        />
        <ResourceGroupSelect
          required
          subField
          testId={FieldTestIds.ResourceGroup}
          subscriptionId={subscriptionId}
          componentRef={setResourceSelectRef}
          fieldName={Fields.ResourceGroup}
          label={Messages.labels.resourceGroup()}
          tooltip={Messages.hints.toolTipResourceGroup()}
          onError={onResourceGroupError}
          onMissingDependencies={onResourceGroupMissingDependencies}
        />
      </FieldSet>
      <FieldSet header={Messages.createMcvcn.basicsTab.sectionTitles.instanceDetails()}>
        <TextInput
          required
          testId={FieldTestIds.Name}
          fieldName={Fields.Name}
          label={Messages.labels.name()}
          validator={validateMcvcnName}
        />
        <RegionSelect
          required
          subscriptionId={subscriptionId}
          testId={FieldTestIds.Region}
          // showUnlinkedRegions
          fieldName={Fields.Region}
          label={Messages.labels.region()}
          tooltip={Messages.hints.toolTipRegion()}
        />
      </FieldSet>
    </>
  );
};
