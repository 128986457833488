import {
  FieldWrapper,
  FormErrors,
  FormInputGroup,
  FormInputGroupLayout,
  FormValues,
  getValue,
  InputFormGroup,
  InputFormInline,
  IntegerInput,
  Select,
  SelectOption,
  SimpleBuilder,
  SubmitButtonMode,
  uniqueGUID,
  useResizeObserver,
} from "o4a-react";
import * as React from "react";
import { mergeStyleSets, Stack, Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext, SettingType } from "../../console/SettingsContext";
import { DetailsPanelId, InlineFormId, PageId } from "../../constants/pluginConstants";
import { InputFormInlineTestIds } from "../../constants/uiConstants";
import {
  CustomMinuteId,
  CustomMinutes,
  getCustomMinuteStr,
  getInactivityThresholdStr,
  InactivityThreshold,
  InactivityThresholdId,
  InactivityThresholds,
} from "../../helpers/settingsHelper";
import { useAnalytics } from "../../hooks/useAnalytics";

export enum Fields {
  Inactivity = "inactivity",
  CustomDuration = "customDuration",
  Hours = "hours",
  Minutes = "minutes",
}

export enum FieldTestIds {
  Inactivity = "inactivity",
  CustomDuration = "custom-duration",
  Hours = "hours",
  Minutes = "minutes",
}

const classNames = mergeStyleSets({
  inactivityThresholdIdSelectContainer: { width: "100%", minWidth: "107px", maxWidth: "530px" },
  customHrs: { width: "205px", minWidth: "205px" },
  customMins: { width: "60px", minWidth: "60px" },
  stackSimpleBuilder: { "div > span:has(+ div)": { paddingLeft: "9rem" } },
});

const getCustomMinuteId = (mins: number): string => {
  switch (mins) {
    case 45: return CustomMinuteId.CUSTOM_MINS_45;
    case 30: return CustomMinuteId.CUSTOM_MINS_30;
    case 15: return CustomMinuteId.CUSTOM_MINS_15;
    case 0:
    default: return CustomMinuteId.CUSTOM_MINS_0;
  }
};

const idleTime2HrsMins = (idleInMins: number): [number, number] => {
  const hrs = Math.floor(idleInMins / 60);
  const mins = idleInMins % 60;
  return [hrs, mins];
};

export const SettingSignout = (): JSX.Element => {
  const { trackFormSave } = useAnalytics();
  const [formKey, setFormKey] = React.useState<string>(uniqueGUID());
  const { inactivityThreshold, setSettingValue } = React.useContext<Settings>(SettingsContext);
  const [inactivityThresholdId, setInactivityThresholdId] = React.useState<InactivityThresholdId>(
    inactivityThreshold.id,
  );
  const [customHours, setCustomHours] = React.useState<number | undefined>(
    inactivityThreshold.id === InactivityThresholdId.CUSTOM && inactivityThreshold.idleInMins !== undefined
      ? Math.floor(inactivityThreshold.idleInMins / 60)
      : 0,
  );
  const [customMins, setCustomMins] = React.useState<number>(
    inactivityThreshold.id === InactivityThresholdId.CUSTOM && inactivityThreshold.idleInMins !== undefined
      ? inactivityThreshold.idleInMins % 60
      : 0,
  );
  const [initialValues, setInitialValues] = React.useState<FormValues>(
    inactivityThreshold.id === InactivityThresholdId.CUSTOM && inactivityThreshold.idleInMins !== undefined
      ? {
        [Fields.Inactivity]: [inactivityThreshold.id],
        [Fields.CustomDuration]: [{
          hours: customHours?.toString(),
          minutes: [getCustomMinuteId(customMins)],
        }],
      }
      : { [Fields.Inactivity]: [inactivityThreshold.id] },
  );

  const onSubmit = (formValues: FormValues): void => {
    const thresholdId = getValue<SelectOption[]>(
      formValues,
      Fields.Inactivity,
      InputFormGroup,
    )?.[0].id as InactivityThresholdId;
    const threshold: InactivityThreshold = { id: thresholdId, idleInMins: undefined };
    if (thresholdId === InactivityThresholdId.CUSTOM) {
      const customSignout = getValue<FormValues[]>(formValues, Fields.CustomDuration, InputFormGroup)?.[0];
      const customHrs = customSignout ? parseInt(customSignout.hours, 10) : 0;
      const customMinId = customSignout
        ? customSignout.minutes?.[0].id as CustomMinuteId
        : CustomMinuteId.CUSTOM_MINS_0;
      const { duration: customMin } = CustomMinutes[customMinId];
      const idleInMins = customHrs * 60 + customMin;
      threshold.idleInMins = idleInMins;
      setInitialValues({
        [Fields.Inactivity]: [threshold.id],
        [Fields.CustomDuration]: [{
          hours: customHrs.toString(),
          minutes: [customMinId],
        }],
      });
    } else {
      const { idleInMins } = InactivityThresholds[thresholdId as InactivityThresholdId];
      threshold.idleInMins = idleInMins;
      setInitialValues({ [Fields.Inactivity]: [threshold.id] });
    }
    setSettingValue(SettingType.INACTIVITY_THRESHOLD, threshold);
    setInactivityThresholdId(threshold.id);
    setFormKey(uniqueGUID());
  };
  const onCancel = (): void => {
    setInactivityThresholdId(initialValues[Fields.Inactivity][0]);
    setCustomHours(0);
    setCustomMins(0);
    if (inactivityThreshold.id === InactivityThresholdId.CUSTOM && inactivityThreshold.idleInMins !== undefined) {
      const idleTime = idleTime2HrsMins(inactivityThreshold.idleInMins);
      setCustomHours(idleTime[0]);
      setCustomMins(idleTime[1]);
    }
    setFormKey(uniqueGUID());
  };

  const optionItems = Object.values(InactivityThresholds).map(threshold => {
    const { id } = threshold;
    return ({ id, text: getInactivityThresholdStr(id) });
  });

  const minutesItems = Object.values(CustomMinutes).map(customMin => {
    const { id } = customMin;
    return ({ id, text: getCustomMinuteStr(id) });
  });

  const ref = React.useRef(null);
  const { width: formWidth } = useResizeObserver(ref);
  const defaultCustomDuration = [{
    [Fields.Hours]: customHours,
    [Fields.Minutes]: customMins !== undefined
      ? [getCustomMinuteId(customMins)] : [CustomMinuteId.CUSTOM_MINS_0],
  }];

  return (
    <InputFormInline
      key={formKey}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      initialValues={initialValues}
      layout={FormInputGroupLayout.WIDE}
      onSubmit={(formValues: FormValues) => {
        trackFormSave(InlineFormId.SETTING_SIGNOUT, PageId.SETTINGS, DetailsPanelId.SIGNOUT);
        onSubmit(formValues);
      }}
      onDiscard={onCancel}
      submitButtonText={Messages.actions.apply()}
      discardButtonText={Messages.actions.discardChanges()}
      testId={InputFormInlineTestIds.SettingSignout}
    >
      <div ref={ref}>
        <Stack className={classNames.inactivityThresholdIdSelectContainer} tokens={{ childrenGap: 8 }}>
          <Text style={{ fontWeight: "600" }}>{Messages.labels.signingOut()}</Text>
          <Select
            testId={FieldTestIds.Inactivity}
            fieldName={Fields.Inactivity}
            label={Messages.labels.signOutInactive()}
            hideSearchBox
            options={optionItems}
            defaultValue={[inactivityThresholdId]}
            onChange={(thresholdId: string) => {
              setInactivityThresholdId(thresholdId as InactivityThresholdId);
              if (thresholdId !== InactivityThresholdId.CUSTOM) {
                setCustomHours(0);
                setCustomMins(0);
              }
            }}
          />
          {inactivityThresholdId === InactivityThresholdId.CUSTOM && (
          <FieldWrapper
            subField
            label={Messages.labels.customDuration()}
            minWidth={formWidth < 500 ? 123 : 250}
          >
            <FormInputGroup
              layout={FormInputGroupLayout.COMPACT}
              // For initialValues comparison to work, all groups within InputForm must use the InputFormGroup
              groupName={InputFormGroup}
            >
              <Stack className={classNames.stackSimpleBuilder}>
                <SimpleBuilder
                  fieldName={Fields.CustomDuration}
                  testId={FieldTestIds.CustomDuration}
                  minRows={1}
                  maxRows={1}
                  defaultValue={defaultCustomDuration}
                  rowValidator={(builderValues: FormValues[], rowNdx: number): FormErrors | undefined => {
                    const formErrors: FormErrors = { hours: [] };
                    const rowValue = builderValues[rowNdx];
                    const zeroHours = !parseInt(rowValue?.hours, 10);
                    const zeroMins = rowValue?.minutes?.[0].id === CustomMinuteId.CUSTOM_MINS_0;
                    if (zeroHours && zeroMins) {
                      formErrors.hours?.push(Messages.validation.invalidCustomDuration());
                    }
                    return formErrors;
                  }}
                >
                  <IntegerInput
                    testId={FieldTestIds.Hours}
                    fieldName={Fields.Hours}
                    label={Messages.common.hours()}
                    min={0}
                    onChange={setCustomHours}
                  />
                  <Select
                    testId={FieldTestIds.Minutes}
                    fieldName={Fields.Minutes}
                    width={60}
                    minWidth={60}
                    label={Messages.common.minutes()}
                    hideSearchBox
                    options={minutesItems}
                    onChange={(minutesId: string) => {
                      const { duration } = CustomMinutes[minutesId as CustomMinuteId];
                      setCustomMins(duration);
                    }}
                  />
                </SimpleBuilder>
              </Stack>
            </FormInputGroup>
          </FieldWrapper>
          )}
        </Stack>
      </div>
    </InputFormInline>
  );
};
