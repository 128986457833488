import { FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Fields as TagDetailsFields, TagsEditPanel } from "../../components/TagsEditPanel/TagsEditPanel";
import { TagsInfoType } from "../../helpers/resourceHelper";
import { useOperation } from "../../hooks/useOperation";
import { OperationProps } from "../OperationTypes";
import { newVmDbUpdateTagsCdb, VmDbUpdateTagsCdbProps } from "./VmDbUpdateTagsCdb";

export interface VmDbEditTagsCdbProps extends OperationProps {
  databaseId: string | undefined;
  defaultTags?: TagsInfoType;
}

const VmDbEditTagsCdb = (
  { location, databaseId, defaultTags, onExecute, onCancel }: VmDbEditTagsCdbProps,
): JSX.Element => {
  const { trigger: triggerUpdateTagsCdb } = useOperation<VmDbUpdateTagsCdbProps>(newVmDbUpdateTagsCdb);

  return (
    <TagsEditPanel
      title={Messages.labels.editTags()}
      resourceId={databaseId}
      tagsDefaultValue={defaultTags}
      onSubmit={(formValues: FormValues) => {
        const tags = getValue<TagsInfoType>(formValues, TagDetailsFields.Tags, InputFormGroup) ?? [];
        triggerUpdateTagsCdb({
          databaseId,
          tags,
          location,
          onExecute,
        });
      }}
      onClose={onCancel}
    />
  );
};

export const newVmDbEditTagsCdb = (
  props: VmDbEditTagsCdbProps,
): JSX.Element => (<VmDbEditTagsCdb {...props} />);
