import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  IntegerInput,
  optimizedRetryOption,
  Select,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { DbSystemShapeSummary, DbSystemShapeSummaryCollection } from "../../gen/clients/mchub-azure-api-client-exa";
import { IdResourceType } from "../../helpers/idHelper";
import {
  getShapeDisplayName,
  isFlexDatabaseShape,
  maxExaInfraStorageServerCount,
  minExaInfraStorageServerCount,
  responseItemstoArray,
} from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

type BasicProps = Omit<SelectProps, "options" | "onChange">
export interface Props extends BasicProps {
  subscriptionId: string;
  location: string;
  onShapeChange?: (value: string) => void;
  onDBServerCountChange?: (value: number | undefined) => void;
  onStorageServerCountChange?: (value: number | undefined) => void;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export enum ExadataDbSystemShapeSelectFields {
  DatabaseServers = "databaseServers",
  StorageServers = "storageServers",
}

export enum FieldTestIds {
  DatabaseServers = "databaseServers",
  StorageServers = "storageServers",
}

export const ExadataDbSystemShapeSelect = (
  {
    defaultValue,
    testId,
    fieldName,
    label,
    placeholder,
    required,
    subField,
    tooltip,
    validator,
    subscriptionId,
    location,
    onShapeChange,
    onDBServerCountChange,
    onStorageServerCountChange,
    onError,
    onMissingDependencies,
  }: Props,
): JSX.Element => {
  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const form: FormState = React.useContext(FormContext);

  const [key, setKey] = React.useState<string>(uniqueGUID());

  const { loading, response, error } = useQueryCall({
    wait: !(subscriptionId && location),
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDbSystemShapesBySubscription,
    options: {
      args: { subscriptionId },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDBSystemShape(),
      },
    },
  });
  const dbSystemShapes = (response
    && responseItemstoArray<DbSystemShapeSummaryCollection, DbSystemShapeSummary>(response)) || [];
  const options = dbSystemShapes?.filter(shape => shape.shapeFamily === "EXADATA")?.map(shape => ({
    id: shape.name || "",
    text: getShapeDisplayName(shape.name) || "",
  })).sort(({ text: a }, { text: b }) => (a === "Exadata Base" ? -1
    : b === "Exadata Base" ? 1 : a > b ? -1 : 1));

  const [currentShape, setCurrentShape] = React.useState<DbSystemShapeSummary>();

  React.useEffect(() => {
    if (defaultValue && defaultValue.length > 0 && subscriptionId) {
      const sName = defaultValue[0];
      const tempShape = dbSystemShapes?.find(shape => (shape.name === sName));
      setCurrentShape(tempShape);
      if (tempShape) {
        if (onShapeChange) onShapeChange(tempShape.name);
        if (isFlexDatabaseShape(tempShape)) {
          if (onDBServerCountChange) onDBServerCountChange(tempShape.minimumNodeCount || 0);
          if (onStorageServerCountChange) onStorageServerCountChange(minExaInfraStorageServerCount);
        }
      }
      form.setValue(undefined, fieldName, groupName);
      setKey(uniqueGUID());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location, JSON.stringify(dbSystemShapes)]);

  React.useEffect(() => {
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dbSystemShapes)]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    if (!location) missingDependencies.push(IdResourceType.LOCATION);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const derivedPlaceholder = !loading && !error && options.length === 0
    ? Messages.createCommon.loadingNone(label?.toLowerCase() || "")
    : placeholder;

  return (
    <>
      <Select
        key={key}
        testId={testId}
        loading={loading}
        required={required}
        subField={subField}
        fieldName={fieldName}
        label={label}
        options={options}
        placeholder={derivedPlaceholder}
        defaultValue={options?.length > 0 ? defaultValue : undefined}
        tooltip={tooltip}
        validator={validator}
        onClick={internalOnClick}
        onChange={
          (sName: string) => {
            if (onShapeChange) {
              onShapeChange(sName);
            }
            const tempShape = dbSystemShapes?.find(shape => (shape.name === sName));
            if (!tempShape || !isFlexDatabaseShape(tempShape)) {
              form.setValue(0, ExadataDbSystemShapeSelectFields.DatabaseServers, groupName);
              form.setValue(0, ExadataDbSystemShapeSelectFields.StorageServers, groupName);
            }
            setCurrentShape(tempShape);
            if (tempShape && isFlexDatabaseShape(tempShape)) {
              if (onDBServerCountChange) onDBServerCountChange(tempShape.minimumNodeCount || 0);
              if (onStorageServerCountChange) onStorageServerCountChange(minExaInfraStorageServerCount);
            }
          }
        }
      />
      {currentShape && isFlexDatabaseShape(currentShape) && (
        <>
          <IntegerInput
            testId={FieldTestIds.DatabaseServers}
            fieldName={ExadataDbSystemShapeSelectFields.DatabaseServers}
            label={Messages.labels.databaseServers()}
            min={currentShape.minimumNodeCount}
            max={currentShape.maximumNodeCount}
            defaultValue={currentShape.minimumNodeCount}
            required
            onChange={onDBServerCountChange}
          />
          <IntegerInput
            testId={FieldTestIds.StorageServers}
            fieldName={ExadataDbSystemShapeSelectFields.StorageServers}
            label={Messages.labels.storageServers()}
            min={currentShape.minStorageCount || minExaInfraStorageServerCount}
            max={currentShape.maxStorageCount || maxExaInfraStorageServerCount}
            defaultValue={currentShape.minStorageCount || minExaInfraStorageServerCount}
            required
            onChange={onStorageServerCountChange}
          />
        </>
      )}
    </>
  );
};
