import {
  ActionBar,
  ActionType,
  getResourceLifecycleStatus,
  LabelMetaItem,
  MetaItemSection,
  NoValue,
  optimizedRetryOption,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { MdsDbSystem } from "../../gen/clients/mchub-azure-api-client-mds";
import { parseId } from "../../helpers/idHelper";
import { HeatWaveClusterStatus } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { MysqlCreateHeatWaveProps, newMysqlCreateHeatWave } from "../../operations/Mysql/MysqlCreateHeatWave";
import { MysqlDeleteHeatWaveProps, newMysqlDeleteHeatWave } from "../../operations/Mysql/MysqlDeleteHeatWave";
import { MysqlEditNodeCountProps, newMysqlEditNodeCount } from "../../operations/Mysql/MysqlEditNodeCount";
import { MysqlRestartHeatWaveProps, newMysqlRestartHeatWave } from "../../operations/Mysql/MysqlRestartHeatWave";
import { MysqlStartHeatWaveProps, newMysqlStartHeatWave } from "../../operations/Mysql/MysqlStartHeatWave";
import { MysqlStopHeatWaveProps, newMysqlStopHeatWave } from "../../operations/Mysql/MysqlStopHeatWave";
import { getOciRegion } from "../../utils";

export enum ActionIds {
  Create = "create",
  Refresh = "refresh",
  Start = "start",
  Stop = "stop",
  Restart = "restart",
  Delete = "delete",
  EditNodeCount = "edit-node-count",
}

export enum ActionBarTestIds {
  Create = "create",
  Refresh = "refresh",
  Start = "start",
  Stop = "stop",
  Restart = "restart",
  Delete = "delete",
}

export enum MetaItemSectionTestIds {
  STATUS = "mis-status",
  NODE_COUNT = "mis-node-count",
  TOTAL_MEMORY = "mis-total-memory",
}

export enum MetaItemActionTestIds {
  EditNodeCount = "edit-node-count",
}

export interface MysqlHeatwaveDetailsProps {
  mdsDbSystemId: string,
  mdsDbSystem: MdsDbSystem | undefined,
  location: string;
  refreshMds: () => void;
}

export const MysqlHeatwaveDetails = (
  { mdsDbSystemId, mdsDbSystem, location, refreshMds }: MysqlHeatwaveDetailsProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup: resourceGroupName, resourceName: dbSystemName } = parseId(mdsDbSystemId);

  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { response, refresh } = useQueryCall({
    wait: !mdsDbSystem?.isHeatWaveClusterAttached,
    method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.getHeatWaveCluster,
    options: {
      args: {
        subscriptionId,
        resourceGroupName,
        dbSystemName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadHeatwaveCluster(),
      },
      excludeErrorStatus: [404],
    },
  });

  const refreshResources = (): void => {
    refreshMds();
    refresh();
  };

  const heatwaveCluster = response?.data;

  const getTotalMemorySize = (): string|undefined => (
    heatwaveCluster?.clusterSize ? Messages.common.memoryTB((heatwaveCluster.clusterSize * 0.5).toString()) : undefined
  );

  const { trigger: triggerCreateMysqlHeatWave } = useOperation<MysqlCreateHeatWaveProps>(newMysqlCreateHeatWave);
  const { trigger: triggerEditNodeCount } = useOperation<MysqlEditNodeCountProps>(newMysqlEditNodeCount);
  const { trigger: triggerStartMysqlHeatWave } = useOperation<MysqlStartHeatWaveProps>(newMysqlStartHeatWave);
  const { trigger: triggerStopMysqlHeatWave } = useOperation<MysqlStopHeatWaveProps>(newMysqlStopHeatWave);
  const { trigger: triggerRestartMysqlHeatWave } = useOperation<MysqlRestartHeatWaveProps>(newMysqlRestartHeatWave);
  const { trigger: triggerDeleteMysqlHeatWave } = useOperation<MysqlDeleteHeatWaveProps>(newMysqlDeleteHeatWave);

  const { closePanels } = useSidePanel();

  const HeatwaveActions: ActionType[] = [
    {
      key: ActionIds.Start,
      testId: ActionBarTestIds.Start,
      text: Messages.actions.start(),
      icon: MonochromeIconIds.Play,
      title: Messages.labels.startHeatWaveCluster(),
      description: Messages.hints.startHeatWaveClusterConfirmation(mdsDbSystem?.name || ""),
      disabled: heatwaveCluster?.status !== HeatWaveClusterStatus.STOPPED,
      onClick: () => {
        trackActionClick(ActionIds.Start, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE);
      },
      onConfirm: () => {
        triggerStartMysqlHeatWave({
          databaseId: mdsDbSystemId,
          location,
          onExecute: refresh,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Start, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE),
    },
    {
      key: ActionIds.Stop,
      testId: ActionBarTestIds.Stop,
      text: Messages.actions.stop(),
      icon: MonochromeIconIds.Stop,
      title: Messages.labels.stopHeatWaveCluster(),
      description: Messages.hints.stopHeatWaveClusterConfirmation(mdsDbSystem?.name || ""),
      disabled: heatwaveCluster?.status !== HeatWaveClusterStatus.ACTIVE,
      onClick: () => {
        trackActionClick(ActionIds.Stop, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE);
      },
      onConfirm: () => {
        triggerStopMysqlHeatWave({
          databaseId: mdsDbSystemId,
          location,
          onExecute: refresh,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Stop, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE),
    },
    {
      key: ActionIds.Restart,
      testId: ActionBarTestIds.Restart,
      text: Messages.actions.restart(),
      icon: MonochromeIconIds.Restart,
      title: Messages.labels.restartHeatWaveCluster(),
      description: Messages.hints.restartHeatWaveClusterConfirmation(mdsDbSystem?.name || ""),
      disabled: (heatwaveCluster?.status !== HeatWaveClusterStatus.STOPPED
        && heatwaveCluster?.status !== HeatWaveClusterStatus.ACTIVE),
      onClick: () => {
        trackActionClick(ActionIds.Restart, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE);
      },
      onConfirm: () => {
        triggerRestartMysqlHeatWave({
          databaseId: mdsDbSystemId,
          location,
          onExecute: refresh,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Restart, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE),
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE);
        refreshResources();
      },
    },
    {
      key: ActionIds.Delete,
      testId: ActionBarTestIds.Delete,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.labels.deleteHeatWave(),
      description: Messages.hints.deleteHeatWave(),
      onClick: () => {
        trackActionClick(ActionIds.Delete, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE);
      },
      onConfirm: () => {
        triggerDeleteMysqlHeatWave({
          databaseId: mdsDbSystemId,
          location,
          onExecute: refreshResources,
        });
      },
      onCancel: () => trackActionDiscard(ActionIds.Delete, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE),
      disabled: heatwaveCluster?.status !== HeatWaveClusterStatus.ACTIVE,
    },
  ];

  const CreateHeatWaveActions: ActionType[] = [
    {
      key: ActionIds.Create,
      testId: ActionBarTestIds.Create,
      text: Messages.actions.createHeatWave(),
      icon: MonochromeIconIds.Create,
      title: Messages.labels.createHeatWave(),
      description: Messages.hints.createHeatWave(),
      onClick: () => {
        trackActionClick(ActionIds.Create, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE);
        triggerCreateMysqlHeatWave({
          databaseId: mdsDbSystemId,
          clusterSize: heatwaveCluster?.clusterSize,
          location,
          onExecute: refreshResources,
          onCancel: () => trackActionDiscard(ActionIds.Create, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE),
        });
      },
    },
  ];

  const essentialsMetaItemGroup: JSX.Element[] = [
    <Stack tokens={{ childrenGap: metaItemLabelsGap }} key="heatwave-details-left-columm">
      <LabelMetaItem testId={MetaItemSectionTestIds.STATUS} label={Messages.labels.heatwaveClusterStatus()}>
        <Status
          iconPosition="right"
          label={stateT(heatwaveCluster?.status || "")}
          status={getResourceLifecycleStatus(heatwaveCluster?.status || "")}
        />
      </LabelMetaItem>
      <LabelMetaItem
        testId={MetaItemSectionTestIds.NODE_COUNT}
        label={Messages.labels.nodeCount()}
        action={{
          id: ActionIds.EditNodeCount,
          testId: MetaItemActionTestIds.EditNodeCount,
          label: Messages.actions.edit(),
          onClick: () => {
            trackActionClick(ActionIds.EditNodeCount, PageId.MYSQL_DETAILS, DetailsPanelId.HEATWAVE);
            triggerEditNodeCount({
              databaseId: mdsDbSystemId,
              nodeConfig: {
                shapeName: heatwaveCluster?.shapeName,
                clusterSize: heatwaveCluster?.clusterSize,
              },
              location,
              onExecute: refresh,
              onCancel: () => trackActionDiscard(
                ActionIds.EditNodeCount,
                PageId.MYSQL_DETAILS,
                DetailsPanelId.HEATWAVE,
              ),
            });
          },
        }}
      >
        {heatwaveCluster?.clusterSize.toString() || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.TOTAL_MEMORY} label={Messages.labels.totalMemorySize()}>
        {getTotalMemorySize()?.toString() || <NoValue />}
      </LabelMetaItem>
    </Stack>,
  ];

  return (
    <>
      {!mdsDbSystem?.isHeatWaveClusterAttached && (
        <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
          <ActionBar actions={CreateHeatWaveActions} onActionClick={closePanels} />
          <p style={{ color: "grey" }}>{Messages.hints.noHeatWave()}</p>
        </Stack>
      )}
      {mdsDbSystem?.isHeatWaveClusterAttached && (
        <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
          <ActionBar actions={HeatwaveActions} onActionClick={closePanels} />
          <MetaItemSection>
            {essentialsMetaItemGroup}
          </MetaItemSection>
        </Stack>
      )}
    </>
  );
};
