import { AnchoredPanel, AnchoredPanelType, FieldSet, FormattedString, useNavigation } from "o4a-react";
import * as React from "react";
import { DefaultButton, IButtonStyles, Icon, Link, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { OperationBaseProps } from "../../console/OperationsContext";
import { contactSupportCommunityHelpUrl, odsaDocumentationUrl } from "../../constants/docConstants";
import { PageId, SUPPORT_ROUTE } from "../../constants/pluginConstants";

const supportButtonStyles: IButtonStyles = { root: { minWidth: "80px", height: "24px" } };

export type ViewHelpProps = OperationBaseProps;

const ViewHelp = (
  { onCancel }: ViewHelpProps,
): JSX.Element => {
  const { navigateTo } = useNavigation(ConsoleContext);

  const onSupportClick = (): void => {
    navigateTo(SUPPORT_ROUTE, PageId.SUPPORT);
  };

  return (
    <AnchoredPanel
      type={AnchoredPanelType.MEDIUM}
      title={Messages.help.title()}
      isOpen
      onClose={onCancel}
    >
      <FieldSet
        header={Messages.help.documentation.title()}
      >
        <Stack style={{ display: "inline", marginBottom: "5px" }}>
          <FormattedString inputText={Messages.help.documentation.description(odsaDocumentationUrl)} />
          <Link href={odsaDocumentationUrl} target="_blank">
            <Icon style={{ paddingLeft: "5px" }} iconName="OpenInNewWindow" />
          </Link>
        </Stack>
        <Stack style={{ display: "inline" }}>
          <FormattedString inputText={Messages.help.documentation.contactSupportCommunityHelp()} />
          <Link href={contactSupportCommunityHelpUrl} target="_blank">
            <Icon style={{ paddingLeft: "5px" }} iconName="OpenInNewWindow" />
          </Link>
        </Stack>
      </FieldSet>
      <br />
      <FieldSet
        header={Messages.help.needMoreHelp.title()}
        description={Messages.help.needMoreHelp.description()}
      >
        <div />
      </FieldSet>
      <DefaultButton
        styles={supportButtonStyles}
        onClick={onSupportClick}
      >
        {Messages.labels.support()}
      </DefaultButton>
    </AnchoredPanel>
  );
};

export const newViewHelp = (
  props: ViewHelpProps,
): JSX.Element => (<ViewHelp {...props} />);
