import { CalloutComponent, FormValues, getValue, InputFormGroup, RadioGroupOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { BackupRestorePanel, Fields as RestoreFields } from "../../components/BackupRestorePanel/BackupRestorePanel";
import { RestoreVmdbDatabaseDetails } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { parseId } from "../../helpers/idHelper";
import { BackupRestoreType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_CDB_UPDATE_POLL_DELAY,
  VMDB_CDB_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface VmDbRestoreCdbProps extends OperationActionProps {
  databaseId: string | undefined;
  timeCreated?: Date | undefined;
}

const VmDbRestoreCdb = (
  { targetId, location, databaseId, onExecute, onCancel, timeCreated }: VmDbRestoreCdbProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName: databaseName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");

  const { invokeCall: invokeRestore } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.restoreVmdbDatabase },
  );
  const [calloutRef, setCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const onRestore = (formValues: FormValues): void => {
    const formRestoreType = getValue<RadioGroupOption>(formValues, RestoreFields.Type, InputFormGroup)?.id;
    const formRestoreTimestamp = getValue<number>(formValues, RestoreFields.Timestamp, InputFormGroup) as number;
    const formRestoreSCN = getValue<string>(formValues, RestoreFields.SCN, InputFormGroup);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: calloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.restore(),
          message: Messages.notifications.inProgress.messages.restore(databaseName),
        },
        success: {
          title: Messages.notifications.success.titles.restore(),
          message: Messages.notifications.success.messages.restore(databaseName),
        },
        failure: {
          title: Messages.notifications.failure.titles.restore(),
          message: Messages.notifications.failure.messages.restore(databaseName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_CDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_UPDATED_CHECK_CDB,
          delay: VMDB_CDB_UPDATE_POLL_DELAY,
          interval: VMDB_CDB_UPDATE_POLL_INTERVAL,
        },
      },
    };

    invokeRestore({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName,
      restoreVmdbDatabaseDetails: {
        latest: formRestoreType === BackupRestoreType.LATEST ? true : undefined,
        timestamp: formRestoreType === BackupRestoreType.TIMESTAMP ? new Date(formRestoreTimestamp) : undefined,
        databaseSCN: formRestoreType === BackupRestoreType.SCN ? formRestoreSCN : undefined,
      } as RestoreVmdbDatabaseDetails,
    }, invokeOptions);
  };

  return (
    <BackupRestorePanel
      componentRef={setCalloutRef}
      targetId={targetId}
      onSubmit={onRestore}
      onClose={onCancel}
      timeCreated={timeCreated}
    />
  );
};

export const newVmDbRestoreCdb = (
  props: VmDbRestoreCdbProps,
): JSX.Element => (<VmDbRestoreCdb {...props} />);
