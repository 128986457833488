import { optimizedRetryOption } from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { OciResourceType } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { AzureSubscriptionSummaryExt } from "../../hooks/useSubscriptions";
import { getOciRegion } from "../../utils";
import { OciLink, OciLinkProps } from "./OciLink";

export interface OciExaDbVmClusterLinkProps extends Pick<OciLinkProps, "location" | "analytics"> {
  id: string;
  subscriptions: AzureSubscriptionSummaryExt[];
}

export const OciExaDbVmClusterLink = (
  { id, location, subscriptions, analytics }: OciExaDbVmClusterLinkProps,
): JSX.Element => {
  const idComps = parseId(id);
  const resGroup = decodeURIComponent(idComps?.resourceGroup || "");
  const resourceName = decodeURIComponent(idComps?.resourceName || "");
  const subscription = subscriptions?.find(item => item.id === idComps?.subscriptionId);

  const { response, error } = useQueryCall({
    wait: !id,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.getCloudVmCluster,
    options: {
      args: {
        subscriptionId: idComps?.subscriptionId || "",
        resourceGroupName: resGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        cloudVmClusterName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
  });

  const vmCluster = response?.data;

  const ociResourcePath = `/dbaas/cloudVmClusters/${vmCluster?.ocid}`;

  return (
    <OciLink
      ociResourceType={OciResourceType.EXA_VM_CLUSTER}
      ociResourcePath={!error && vmCluster?.ocid ? ociResourcePath : undefined}
      ociResourceName={resourceName}
      location={location}
      subscription={subscription}
      analytics={analytics}
    />
  );
};
