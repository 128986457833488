import * as React from "react";
import { Link, mergeStyleSets } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { CategoryService, ResourceCategories, ServiceItem } from "./ResourceCategoryUtil";

export interface Props {
  categoryService: CategoryService | undefined;
  testId?: string;
}

const classNames = mergeStyleSets({
  headerContainer: {
    paddingTop: "2px",
    paddingBottom: "7px",
    lineHeight: "20px",
    fontSize: "16px",
    color: "#323130",
    borderBottom: "1px solid rgba(105, 130, 155, 0.25)",
  },
  bodyContainer: {
    marginTop: "4px",
    marginBottom: "13px",
  },
  contentContainer: { width: "50%" },
  itemIcon: {
    height: "48px",
    width: "53px",
    marginRight: "12px",
  },
  itemTitleLink: {
    fontSize: "14px",
    color: "rgb(37, 37, 37)",
    paddingBottom: "2px",
    fontWeight: 500,
    textDecoration: "none",
    ":hover": { textDecoration: "underline" },
    cursor: "pointer",
  },
  itemDisabledTitleLink: {
    fontSize: "14px",
    color: "rgb(37, 37, 37)",
    paddingBottom: "2px",
    fontWeight: 500,
    textDecoration: "none",
  },
  itemTitleNoLink: {
    fontSize: "14px",
    color: "rgb(37, 37, 37)",
    paddingBottom: "2px",
    fontWeight: 500,
    textDecoration: "none",
  },
  itemDisabledLinkUrl: { textDecoration: "none" },
  itemLinkUrl: {
    color: "#0078d4",
    textDecoration: "none",
    ":hover": { textDecoration: "underline" },
    cursor: "pointer",
  },
  itemLinkNoUrl: {
    color: "#0078d4",
    textDecoration: "none",
  },
  itemLinkDivider: { paddingLeft: "5px", paddingRight: "5px" },
  itemContainer: {
    display: "flex",
    boxSizing: "border-box",
    border: "1px solid transparent",
    marginLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  itemDisabledContainer: {
    display: "flex",
    boxSizing: "border-box",
    border: "1px solid transparent",
    marginLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "5px",
    filter: "grayscale(100%)",
    opacity: "0.4",
  },
  itemLinks: {
    display: "flex",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  itemLink: { display: "flex" },
  itemContentsContainer: {},
});

export const ResourceCategoryContent = ({ categoryService, testId }: Props): JSX.Element => {
  const getItemHtml = (item: ServiceItem, index: number): JSX.Element => {
    const returnVal = (
      <div
        key={`item-${index}`}
        id="content-item"
        className={item.disabled ? classNames.itemDisabledContainer : classNames.itemContainer}
      >
        <div className={classNames.itemIcon}>
          {item.image}
        </div>
        <div className={classNames.itemContentsContainer}>
          <div>
            {item.url && (
              <Link
                className={item.disabled ? classNames.itemDisabledTitleLink : classNames.itemTitleLink}
                target="_blank"
                href={item.url}
                disabled={item.disabled}
                data-test-id={item.testId}
              >
                {item.title}
              </Link>
            )}
            {!item.url && item.onClick && (
              <Link
                className={item.disabled ? classNames.itemDisabledTitleLink : classNames.itemTitleLink}
                onClick={item.onClick}
                disabled={item.disabled}
                data-test-id={item.testId}
              >
                {item.title}
              </Link>
            )}
            {!(item.url || item.onClick) && (
              <div className={classNames.itemTitleNoLink}>
                {item.title}
              </div>
            )}
          </div>
          <ul className={classNames.itemLinks}>
            {item.links?.map(
              (link, i) => (
                <li
                  className={classNames.itemLink}
                  key={link.text}
                >
                  {link.url && (
                    <Link
                      className={item.disabled ? classNames.itemDisabledLinkUrl : classNames.itemLinkUrl}
                      target="_blank"
                      href={link.url}
                      disabled={item.disabled}
                      data-test-id={link.testId}
                    >
                      {link.text}
                    </Link>
                  )}
                  {!link.url && link.onClick && (
                    <Link
                      className={item.disabled ? classNames.itemDisabledLinkUrl : classNames.itemLinkUrl}
                      onClick={link.onClick}
                      disabled={item.disabled}
                      data-test-id={link.testId}
                    >
                      {link.text}
                    </Link>
                  )}
                  {!(link.url || link.onClick) && (
                    <div className={classNames.itemLinkNoUrl}>
                      {link.text}
                    </div>
                  )}
                  {(item.links && i !== (item.links.length - 1)) && (
                    <span className={classNames.itemLinkDivider}>|</span>
                  )}
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    );
    return returnVal;
  };

  return (
    <div className={classNames.contentContainer}>
      <div className={classNames.headerContainer}>
        {categoryService?.category === ResourceCategories.RECENTLY
          ? Messages.labels.recentlyCreated()
          : Messages.labels.popularProducts()}
      </div>
      {(categoryService && categoryService.services && categoryService.services.length > 0) && (
        <section
          id="services-content-wrapper"
          className={classNames.bodyContainer}
          data-test-id={testId}
        >
          {categoryService.services.map(
            (item, i) => (getItemHtml(item, i)),
          )}
        </section>
      )}
    </div>
  );
};
