import { AnchoredPanel, AnchoredPanelType, ButtonType, CodeSnippet } from "o4a-react";
import * as React from "react";
import { mergeStyleSets } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { OperationProps } from "../OperationTypes";

export interface DeploymentShowOperationDetailValues {
  deploymentId: string | undefined;
  status: string | undefined;
  errorMsg: string | undefined;
  type: string | undefined;
  resourceName: string | undefined;
}

export enum MetaItemTestIds {
  DeploymentId = "resource-deployment-id",
  Status = "resource-status",
  ErrorMessage = "resource-error-message",
  Type = "resource-type",
  ResourceId = "resource-id",
  ResourceName = "resource-name",
}

export enum ButtonTestIds {
  ClosePanel = "deployment-show-operation-details-close-button",
}

export interface DeploymentShowOperationDetailsProps extends OperationProps {
  resourceId: string | undefined;
  deploymentShowOperationDetailValues: DeploymentShowOperationDetailValues;
}

const classNames = mergeStyleSets({
  operationDetailItems: {
    margin: 0,
    padding: 0,
  },
  operationDetailItem: { paddingBottom: "10px" },
  operationDetailItemLabel: { paddingBottom: "2px" },
  operationDetailItemCodeSnippetContainer: {
    border: "1px solid rgb(138, 136, 134)",
    borderRadius: "2px",
  },
});

const DeploymentShowOperationDetails = (
  { onCancel, resourceId, deploymentShowOperationDetailValues }: DeploymentShowOperationDetailsProps,
): JSX.Element => (
  <AnchoredPanel
    title={Messages.labels.operationDetails()}
    type={AnchoredPanelType.MEDIUM}
    onClose={onCancel}
    isOpen
    footer={[
      {
        type: ButtonType.DEFAULT,
        label: Messages.actions.close(),
        onClick: () => onCancel?.(),
        testId: ButtonTestIds.ClosePanel,
        style: { margin: "10px 5px 0px 0px", height: "24px" },
      },
    ]}
  >
    <div className={classNames.operationDetailItems}>
      <div className={classNames.operationDetailItem}>
        <label className={classNames.operationDetailItemLabel}>
          {Messages.labels.deploymentId()}
        </label>
        <div className={classNames.operationDetailItemCodeSnippetContainer}>
          <CodeSnippet
            text={deploymentShowOperationDetailValues?.deploymentId || ""}
            numberOfRows={1}
            testId={MetaItemTestIds.DeploymentId}
            ariaLabel={Messages.labels.deploymentId()}
          />
        </div>
      </div>
      <div className={classNames.operationDetailItem}>
        <label className={classNames.operationDetailItemLabel}>
          {Messages.labels.status()}
        </label>
        <div className={classNames.operationDetailItemCodeSnippetContainer}>
          <CodeSnippet
            text={deploymentShowOperationDetailValues?.status || ""}
            numberOfRows={1}
            testId={MetaItemTestIds.Status}
            ariaLabel={Messages.labels.status()}
          />
        </div>
      </div>
      {deploymentShowOperationDetailValues?.errorMsg && (
        <div className={classNames.operationDetailItem}>
          <label className={classNames.operationDetailItemLabel}>
            {Messages.labels.errorMessage()}
          </label>
          <div
            className={classNames.operationDetailItemCodeSnippetContainer}
          >
            <CodeSnippet
              text={deploymentShowOperationDetailValues?.errorMsg || ""}
              numberOfRows={1}
              testId={MetaItemTestIds.ErrorMessage}
              ariaLabel={Messages.labels.errorMessage()}
            />
          </div>
        </div>
      )}
      <div className={classNames.operationDetailItem}>
        <label className={classNames.operationDetailItemLabel}>
          {Messages.labels.type()}
        </label>
        <div className={classNames.operationDetailItemCodeSnippetContainer}>
          <CodeSnippet
            text={deploymentShowOperationDetailValues?.type || ""}
            numberOfRows={1}
            testId={MetaItemTestIds.Type}
            ariaLabel={Messages.labels.type()}
          />
        </div>
      </div>
      <div className={classNames.operationDetailItem}>
        <label className={classNames.operationDetailItemLabel}>
          {Messages.labels.resourceId()}
        </label>
        <div className={classNames.operationDetailItemCodeSnippetContainer}>
          <CodeSnippet
            text={resourceId || ""}
            numberOfRows={1}
            testId={MetaItemTestIds.ResourceId}
            ariaLabel={Messages.labels.resourceId()}
          />
        </div>
      </div>
      <div className={classNames.operationDetailItem}>
        <label className={classNames.operationDetailItemLabel}>
          {Messages.labels.resource()}
        </label>
        <div className={classNames.operationDetailItemCodeSnippetContainer}>
          <CodeSnippet
            text={deploymentShowOperationDetailValues?.resourceName || ""}
            numberOfRows={1}
            testId={MetaItemTestIds.ResourceName}
            ariaLabel={Messages.labels.resource()}
          />
        </div>
      </div>
    </div>
  </AnchoredPanel>
);

export const newDeploymentShowOperationDetails = (
  props: DeploymentShowOperationDetailsProps,
): JSX.Element => (<DeploymentShowOperationDetails {...props} />);
