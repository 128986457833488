import {
  ActionPermissionMap,
  ActionRoles,
  ActionRolesMap,
  Permission,
  Role,
  RoleBasedAction,
  RoleScope,
} from "../helpers/roleHelper";
import { useAppAuthContext } from "./useAppAuthContext";

export interface RolesQueries {
  isActionAllowed: (action: RoleBasedAction) => boolean;
  actionRequiredRoles: (action: RoleBasedAction) => Role[];
}

const getActionRolesMap = (): Map<RoleBasedAction, ActionRoles> => {
  const map = new Map<RoleBasedAction, ActionRoles>();
  Object.entries(ActionRolesMap).forEach(([key, value]) => {
    let roles = value;
    if (typeof value === "string") {
      roles = ActionRolesMap[value as RoleBasedAction];
    }
    map.set(key as RoleBasedAction, roles as ActionRoles);
  });
  return map;
};

const rolesSufficient = (
  actionRoles: ActionRoles | undefined,
  currentRoles: string[] | undefined,
): boolean => actionRoles?.reduce<boolean>((sufficient, allowedRoles) => (
  sufficient ? allowedRoles.some(allowedRole => currentRoles?.includes(allowedRole.name)) : false
), true) ?? true;

const getActionRequiredRoles = (
  actionRoles: ActionRoles | undefined,
  permission: Permission,
): Role[] => actionRoles?.reduce<Role[]>((requiredRoles, allowedRoles) => {
  if (allowedRoles) {
    const requiredRolesForPermission = allowedRoles.filter(role => {
      // Check first the exact permission
      if (role.permission === permission) {
        return true;
      }
      // Then check if required permission is READ and available permission is READ_WRITE (supports READ)
      if (permission === Permission.READ && role.permission === Permission.READ_WRITE) {
        return true;
      }
      // Otherwise none is found
      return false;
    });
    // Search first for single scope
    let requiredRole = requiredRolesForPermission.find(role => role.scope === RoleScope.SINGLE_RESOURCE_TYPE);
    if (!requiredRole) {
      // If no single scope found then search multiple scope
      requiredRole = requiredRolesForPermission.find(role => role.scope === RoleScope.MULTI_RESOURCE_TYPES);
    }
    if (requiredRole) {
      requiredRoles.push(requiredRole);
    }
  }
  return requiredRoles;
}, []) ?? [];

export const useRoles = (): RolesQueries => {
  const { roles } = useAppAuthContext();
  const actionRolesMap = getActionRolesMap();

  return {
    isActionAllowed: (action: RoleBasedAction): boolean => {
      const actionRoles = actionRolesMap.get(action);
      return rolesSufficient(actionRoles, roles);
    },
    actionRequiredRoles: (action: RoleBasedAction): Role[] => {
      const actionPermission = ActionPermissionMap[action];
      const actionRoles = actionRolesMap.get(action);
      return getActionRequiredRoles(actionRoles, actionPermission);
    },
  };
};
