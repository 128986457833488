import { FormContext, FormState, Select, TextInput, TextInputMultilineConfig } from "o4a-react";
import OpenCrypto from "opencrypto";
import React from "react";
import { parseKey } from "sshpk";
import * as Messages from "../../codegen/Messages";

export enum FieldsTestIds {
  Source = "Source",
  PublicKey = "publicKey",
}

export enum DerivedFields {
  Source = "Source",
  PublicKey = "publicKey",
  PrivateKey = "privateKey",
}

export enum SshPublicKeySource {
  Generate = "generate",
  Upload = "upload",
}

export interface Keys {
  publicKey: string;
  privateKey: string;
}

export const modulusLength = 2048;
export const hash = "SHA-512";
export const defaultName = (): string => (`ssh-key-${new Date().toISOString().replace(/T.*/, "")}`);

export const generateKeys = async (): Promise<Keys> => {
  const keyPair = await crypt.getRSAKeyPair(
    modulusLength,
    hash,
    "RSA-OAEP",
    ["encrypt", "decrypt", "wrapKey", "unwrapKey"],
    true,
  );
  const promises = [crypt.cryptoPrivateToPem(keyPair.privateKey), crypt.cryptoPublicToPem(keyPair.publicKey)];
  const results = await Promise.all(promises);
  const [privateKeyPem, publicKeyPem] = results;
  const privateKeySSH = privateKeyPem;
  const key = parseKey(publicKeyPem, "pem");
  key.comment = defaultName();
  const publicKeySSH = key.toString("ssh");
  return { publicKey: publicKeySSH, privateKey: privateKeySSH };
};

export interface SshKeyPairInputProps {
  fieldName: string;
  groupName?: string;
  defaultValue: SshPublicKeySource;
}

const crypt = new OpenCrypto();

export const SshKeyPairInput: React.FC<SshKeyPairInputProps> = ({
  fieldName,
  groupName,
  defaultValue,
}): JSX.Element => {
  const [defaultUploadedKey, setDefaultUploadedKey] = React.useState<string>();
  const form: FormState = React.useContext(FormContext);
  if (!Object.keys(form).length) {
    throw new Error("SshKeyPairInput should be used within form");
  }

  const multilineConfig : TextInputMultilineConfig = {
    rows: 10,
    resizable: true,
  };

  const [publicKeySourceType, setPublicKeySourceType] = React.useState<SshPublicKeySource>(defaultValue);

  const sshPublicKeySourceOptions = [
    {
      id: SshPublicKeySource.Generate,
      text: Messages.sshKeySources.generateNewKeyPair(),
    },
    {
      id: SshPublicKeySource.Upload,
      text: Messages.sshKeySources.uploadExistingPublicKey(),
    },
  ];

  const onSshPublicKeySourceChange = (type: string): void => {
    setPublicKeySourceType(type as SshPublicKeySource);
  };

  const unsetSshKeyPair = (): void => {
    form.setValue({}, fieldName, groupName);
  };

  const setUploadedPublicSshKey = (publicKey: string | undefined): void => {
    form.setValue({ [DerivedFields.PublicKey]: publicKey }, fieldName, groupName);
    setDefaultUploadedKey(publicKey);
  };

  const setGeneratedSshKeyPair = (): void => {
    generateKeys()
      .then(keyPair => form.setValue({
        [DerivedFields.PrivateKey]: keyPair.privateKey,
        [DerivedFields.PublicKey]: keyPair.publicKey,
      }, fieldName, groupName));
  };

  React.useEffect(() => {
    unsetSshKeyPair();

    if (publicKeySourceType === SshPublicKeySource.Generate) {
      setGeneratedSshKeyPair();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeySourceType]);

  return (
    <>
      <Select
        required
        hideSearchBox
        testId={FieldsTestIds.Source}
        fieldName={fieldName + DerivedFields.Source}
        label={Messages.labels.sshPublicKeySource()}
        options={sshPublicKeySourceOptions}
        onChange={onSshPublicKeySourceChange}
        defaultValue={[defaultValue]}
      />
      {publicKeySourceType === SshPublicKeySource.Upload && (
        <TextInput
          required
          defaultValue={defaultUploadedKey}
          testId={FieldsTestIds.PublicKey}
          fieldName={fieldName + DerivedFields.PublicKey}
          label={Messages.labels.uploadKey()}
          tooltip={Messages.hints.pasteToUpload()}
          placeholder={Messages.hints.pasteToUpload()}
          multiline={multilineConfig}
          onChange={setUploadedPublicSshKey}
        />
      )}
    </>
  );
};
