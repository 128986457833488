import { InputFormCallout, InputFormCalloutProps, Select, SelectOption, SubmitButtonMode } from "o4a-react";
import * as React from "react";
import { Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";
import { AzureCloudLinkSummary } from "../../gen/clients/mchub-azure-api-client";
import { regions } from "../../utils";

export enum Fields {
  CloudLink = "cloudLink",
  Region = "region",
}

export enum FieldTestIds {
  CloudLink = "cloudLink",
  Region = "region",
}

export interface AddRegionPanelProps extends
  Pick<InputFormCalloutProps, "targetId" | "onClose" | "onSubmit" | "componentRef">
{
  showTenantOptions: boolean;
  activeCloudLinks: AzureCloudLinkSummary[];
}

const getRegionOptions = (cloudLink: AzureCloudLinkSummary | undefined): SelectOption[] => (
  regions.filter(region => !cloudLink?.locations?.includes(region.regionName)).map(region => ({
    id: region.regionName,
    text: region.regionDisplayName || region.regionName,
  }))
);

export const AddRegionPanel = ({
  showTenantOptions,
  activeCloudLinks,
  targetId,
  componentRef,
  onClose,
  onSubmit,
}: AddRegionPanelProps): JSX.Element => {
  const cloudLinkOptions = activeCloudLinks.map(cloudLink => ({
    id: cloudLink.id,
    text: cloudLink.ociTenancyName || cloudLink.name,
  }));
  const [cloudLinkId, setCloudLinkId] = React.useState<string | undefined>(
    activeCloudLinks.length === 1 ? activeCloudLinks[0].id : undefined,
  );
  const [regionOptions, setRegionOptions] = React.useState<SelectOption[]>(
    getRegionOptions(activeCloudLinks?.find(cloudLink => cloudLink.id === cloudLinkId)),
  );

  React.useEffect(() => {
    setRegionOptions(getRegionOptions(activeCloudLinks?.find(cloudLink => cloudLink.id === cloudLinkId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudLinkId]);

  return (
    <InputFormCallout
      testId={CalloutTestIds.AddRegion}
      componentRef={componentRef}
      title={Messages.createNewPanels.addRegion.title()}
      targetId={targetId}
      onClose={onClose}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
    >
      <Text
        block
        styles={{
          root: {
            marginBottom: 18,
            fontStyle: "normal",
          },
        }}
        variant="medium"
      >
        {showTenantOptions
          ? Messages.createNewPanels.addRegion.descriptionMultipleCloudLinks()
          : Messages.createNewPanels.addRegion.descriptionSingleCloudLink()}
      </Text>
      {showTenantOptions && (
        <Select
          required
          testId={FieldTestIds.CloudLink}
          defaultValue={cloudLinkId ? [cloudLinkId] : undefined}
          fieldName={Fields.CloudLink}
          label={Messages.labels.ociTenant()}
          tooltip={Messages.hints.regionAddOciTenant()}
          options={cloudLinkOptions}
          onChange={(id: string) => setCloudLinkId(id)}
        />
      )}
      <Select
        required
        testId={FieldTestIds.Region}
        fieldName={Fields.Region}
        label={Messages.labels.location()}
        options={regionOptions}
      />
    </InputFormCallout>
  );
};
