import {
  ActionBar,
  ActionType,
  BookmarkablePage,
  buildLabelMetaItemTestIds,
  DateTimeDisplay,
  ErrorViewPanel,
  FullPagePanel,
  optimizedRetryOption,
  stateT,
  uniqueGUID,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FontIcon, IStackStyles, Link, mergeStyleSets, Spinner, Stack, Text } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ConsoleContext } from "../../console/ConsoleContext";
import { PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { MonochromeIconIds, SvgIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  ActivityItem,
  Incident,
  Item,
  ItemTypeValues,
  LimitItem,
  ProblemTypeValues,
} from "../../gen/clients/mchub-azure-api-client-platform";
import { buildSupportId } from "../../helpers/idHelper";
import { getIncidentSeverity, incidentStatusDetailsT } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { newSupportAddComment, SupportAddCommentProps } from "../../operations/Support/SupportAddComment";
import { newSupportClose, SupportCloseProps } from "../../operations/Support/SupportClose";
import { newSupportUploadFile, SupportUploadFileProps } from "../../operations/Support/SupportUploadFile";
import { newSupportViewAll, SupportViewAllProps } from "../../operations/Support/SupportViewAll";
import { getOciRegion } from "../../utils";

// The API definition does not include these fields even though they are returned in the response
interface ItemWithTimeCreated extends Item {
  /**
   * The serialized DateTime
   */
  timeCreated?: string;
}

const registrationIds = PageRegistrationConfig[PageId.SUPPORT_DETAILS].map(config => config.key);

const classNames = mergeStyleSets({
  contactTextContainer: {
    display: "flex",
    fontSize: "13px",
    fontWeight: 400,
  },
  messagesContainer: { maxWidth: "680px" },
  metaInfoContainer: {
    height: "100vh",
    borderRight: "1px solid rgb(214, 214, 214)",
    maxWidth: "342px",
  },
  metaItemRowContainer: { display: "flex" },
  metaHeaderTitle: {
    fontWeight: 600,
    paddingTop: "20px",
    paddingBottom: "6px",
    fontSize: "16px",
    paddingLeft: "5px",
  },
  toggleIconClass: {
    fontSize: "15px",
    width: "15px",
    cursor: "pointer",
    color: "rgb(0, 120, 212)",
    paddingRight: "8px",
  },
  messageItemTitleIcon: {
    fontSize: "15px",
    width: "18px",
    height: "18px",
    cursor: "pointer",
    color: "rgb(0, 120, 212)",
    paddingRight: "8px",
  },
  messageItemDesc: {
    paddingTop: "8px",
    paddingBottom: "12px",
    paddingRight: "15px",
    paddingLeft: "23px",
    fontWeight: 400,
    color: "rgb(50, 49, 48)",
  },
  messageHeaderTitle: {
    fontWeight: 600,
    paddingTop: "20px",
    paddingBottom: "12px",
    fontSize: "16px",
  },
  messageItemContainer: {
    boxShadow: "0px 1px 4px rgb(0 0 0 / 13%)",
    borderRadius: "2px",
    border: "1px solid rgba(204,204,204, 0.5)",
    boxSizing: "border-box",
    marginBottom: "10px",
  },
  messageItem: { margin: "12px" },
  metaLabel: {
    fontSize: "13px",
    fontWeight: 600,
  },
  metaViewAll: {
    fontSize: "13px",
    color: "rgb(0, 120, 212)",
  },
  messageItemTitle: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  messageItemTitleCreateBy: {
    fontSize: "13px",
    fontWeight: 600,
    color: "rgb(50, 49, 48)",
  },
  metaSeverityValue: {
    fontSize: "20px",
    fontWeight: 600,
  },
  metaValue: {
    fontSize: "13px",
    fontWeight: 400,
  },
  messageItemTitleDate: {
    fontSize: "10px",
    color: "#605E5C",
  },
});
const metaItemStackStyle: IStackStyles = {
  root: {
    display: "grid",
    gridTemplateColumns: "12fr 25fr",
    margin: "5px",
    marginTop: "10px",
    marginBottom: "10px",
    border: "1px",
    color: "rgb(50, 49, 48)",
  },
};

const metaItemStackSeverityStyle: IStackStyles = {
  root: {
    display: "grid",
    gridTemplateColumns: "12fr 25fr",
    margin: "5px",
    marginTop: "10px",
    marginBottom: "10px",
    border: "1px",
    alignItems: "center",
  },
};

export enum ActionIds {
  Reopen = "reopen",
  Close = "close",
  Update = "update",
  UploadFile = "upload-file",
}

export enum ActionBarTestIds {
  Reopen = "reopen",
  Close = "close",
  Update = "update",
  UploadFile = "upload-file",
}

export enum MetaItemSectionTestIds {
  Severity = "support-severity",
  Status = "support-status",
  SupportRequestId = "support-request-id",
  CreatedOn = "support-created-on",
  Summary = "support-summary",
  ProblemStartDate = "support-problem-start-date",
  Description = "support-description",
}

export enum MessageSectionTestIds {
  MessageItem = "message-item",
  MessageItemTitleContainer = "message-item-title-container",
  MessageItemTitle = "message-item-title",
  MessageItemCreatedTime = "message-item-created-time",
}

export const viewAllLinkTestId = "view-all-link";

export const getItemTitle = (item: Item): string => {
  switch (item.itemType) {
    case ItemTypeValues.ACTIVITY:
      switch ((item as ActivityItem).activityType) {
        case "NOTES":
          return ((item as ActivityItem).activityKind === "ATTACHMENTS")
            ? Messages.supportItemTypes.activityAttachmentTitle()
            : Messages.supportItemTypes.activityNotesTitle();
        case "PROBLEM_DESCRIPTION":
          return Messages.supportItemTypes.activityProblemDescriptionTitle();
        case "UPDATE":
          return Messages.supportItemTypes.activityUpdateTitle();
        case "CLOSE":
          return Messages.supportItemTypes.activityCloseTitle();
        case "REOPEN":
          return Messages.supportItemTypes.activityReopenTitle();
        default:
          return Messages.supportItemTypes.activityTitle((item as ActivityItem).activityType || "");
      }
    case ItemTypeValues.LIMIT:
      if ((item as LimitItem).limitStatus) {
        switch ((item as LimitItem).limitStatus) {
          case "APPROVED":
            return Messages.supportItemTypes.limitApprovedTitle();
          case "PARTIALLY_APPROVED":
            return Messages.supportItemTypes.limitPatiallyApprovedTitle();
          case "NOT_APPROVED":
            return Messages.supportItemTypes.limitNotApprovedTitle();
          default:
            return Messages.supportItemTypes.limitTitle((item as LimitItem).limitStatus || "");
        }
      }
      return Messages.supportItemTypes.limitPendingTitle();
    case ItemTypeValues.TECHNICAL:
      return Messages.supportItemTypes.technicalTitle();
    case ItemTypeValues.BILLING:
    default:
      return `${item.itemType}`;
  }
};

export const SupportDetailsPage = (): JSX.Element => {
  const { subscriptionId, provider, resourceType, resourceName, panelId } = useParams();
  const [searchParams] = useSearchParams();

  const resName = decodeURIComponent(resourceName || "");

  // No resource group for incidents
  const incidentId = buildSupportId({
    subscriptionId: subscriptionId || "",
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName: resName,
  });

  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { back, navigateToSelf, customData } = useNavigation(ConsoleContext);
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();
  const subscriptionLocation = subscriptions?.find(sub => sub.id === subscriptionId)?.primaryLocation;
  const [showMessageDetails, setShowMessageDetails] = React.useState<boolean[]>([]);
  const [, { toggle: toggleMessageDetails }] = useBoolean(false);

  React.useEffect(() => {
    // In case page was navigated to directly by entering its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.SUPPORT_DETAILS].find(
      config => config.panelPath === panelId,
    );
    const urlQueryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const path = `${RESOURCE_ROUTE}/${incidentId}/${panelId}${urlQueryParams}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = (): void => back();

  const { response, loading, error, refresh } = useQueryCall({
    wait: subscriptionLoading || !subscriptionLocation,
    method: apiClients.withRegion(getOciRegion(subscriptionLocation || "")).incidentsApi.getIncident,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        incidentKey: resName,
        problemType: customData?.problemType,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadSupport(),
      },
      excludeErrorStatus: [404],
    },
  });

  const incident: Incident | undefined = response?.data;

  // const getContactMeta = (): JSX.Element => {
  //   const contactElements: JSX.Element[] = [];
  //   incident?.contactList?.contactList?.forEach(contact => {
  //     if (contact.contactPhone) {
  //       contactElements.push(
  //         <Text className={classNames.contactTextContainer} key={contact.contactPhone}>
  //           {Messages.labels.personaPhone(contact.contactPhone || "")}
  //         </Text>,
  //       );
  //     }
  //     if (contact.contactEmail) {
  //       contactElements.push(
  //         <Text className={classNames.contactTextContainer} key={contact.contactEmail}>
  //           {Messages.labels.personaEmail(contact.contactEmail || "")}
  //         </Text>,
  //       );
  //     }
  //   });
  //   return (<div>{contactElements}</div>);
  // };

  const onViewAll = (): void => {
    triggerViewAll({
      incidentId,
      supportViewAllValues: {
        contactList: incident?.contactList,
        ticket: incident?.ticket,
        problemType: incident?.problemType,
        subscriptionInformation: incident?.subscriptionInformation,
      },
      location: subscriptionLocation || "",
    });
  };

  const metaItemGroup: JSX.Element[] = [
    (
      <div key="incident-overview">
        <div className={classNames.metaHeaderTitle}>{Messages.labels.overview()}</div>
        <div key="incident-overview-meta">
          <Stack
            styles={metaItemStackSeverityStyle}
            data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.Severity).component}
          >
            <Stack.Item>
              <Text className={classNames.metaLabel}>{Messages.labels.severity()}</Text>
            </Stack.Item>
            <Stack.Item
              data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.Severity).value}
            >
              <Text className={classNames.metaSeverityValue}>
                {incident?.ticket?.severity ? getIncidentSeverity(incident.ticket.severity) : ""}
              </Text>
            </Stack.Item>
          </Stack>
          <Stack
            styles={metaItemStackStyle}
            data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.Status).component}
          >
            <Stack.Item>
              <Text className={classNames.metaLabel}>{Messages.labels.status()}</Text>
            </Stack.Item>
            <Stack.Item
              data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.Status).value}
            >
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                <Text className={classNames.metaValue}>
                  {(incident?.ticket?.lifecycleState === "CLOSED" || !incident?.ticket?.lifecycleDetails) && (
                    stateT(incident?.ticket?.lifecycleState || "")
                  )}
                  {incident?.ticket?.lifecycleState !== "CLOSED" && incident?.ticket?.lifecycleDetails && (
                    incidentStatusDetailsT(incident?.ticket?.lifecycleDetails)
                  )}
                </Text>
              </Stack>
            </Stack.Item>
          </Stack>
          <Stack
            styles={metaItemStackStyle}
            data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.SupportRequestId).component}
          >
            <Stack.Item>
              <Text className={classNames.metaLabel}>{Messages.labels.supportRequestId()}</Text>
            </Stack.Item>
            <Stack.Item
              data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.SupportRequestId).value}
            >
              <Text className={classNames.metaValue}>{incident?.ticket?.ticketNumber}</Text>
            </Stack.Item>
          </Stack>
          <Stack
            styles={metaItemStackStyle}
            data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.CreatedOn).component}
          >
            <Stack.Item>
              <Text className={classNames.metaLabel}>{Messages.labels.createdOn()}</Text>
            </Stack.Item>
            <Stack.Item
              data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.CreatedOn).value}
            >
              <Text className={classNames.metaValue}>
                <DateTimeDisplay date={incident?.ticket?.timeCreated} hideClipboardCopy />
              </Text>
            </Stack.Item>
          </Stack>
          {/* <Stack styles={metaItemStackStyle}>
            <Stack.Item>
              <Text className={classNames.metaLabel}>{Messages.labels.contactMethod()}</Text>
            </Stack.Item>
            <Stack.Item>
              {getContactMeta()}
            </Stack.Item>
          </Stack> */}
          <Stack
            styles={metaItemStackStyle}
            data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.Summary).component}
          >
            <Stack.Item>
              <Text className={classNames.metaLabel}>{Messages.labels.summary()}</Text>
            </Stack.Item>
            <Stack.Item
              data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.Summary).value}
            >
              <Text className={classNames.metaValue}>{incident?.ticket?.title}</Text>
            </Stack.Item>
          </Stack>
          {incident?.timeEncountered && (
            <Stack
              styles={metaItemStackStyle}
              data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.ProblemStartDate).component}
            >
              <Stack.Item>
                <Text className={classNames.metaLabel}>{Messages.labels.problemStartDate()}</Text>
              </Stack.Item>
              <Stack.Item
                data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.ProblemStartDate).value}
              >
                <Text className={classNames.metaValue}>
                  <DateTimeDisplay date={incident.timeEncountered} hideClipboardCopy />
                </Text>
              </Stack.Item>
            </Stack>
          )}
          <Stack
            styles={metaItemStackStyle}
            data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.Description).component}
          >
            <Stack.Item>
              <Text className={classNames.metaLabel}>{Messages.labels.description()}</Text>
            </Stack.Item>
            <Stack.Item
              data-test-id={buildLabelMetaItemTestIds(MetaItemSectionTestIds.Description).value}
            >
              <Text className={classNames.metaValue}>{incident?.ticket?.description}</Text>
            </Stack.Item>
          </Stack>
          <Stack styles={metaItemStackStyle}>
            <Stack.Item>
              <Link
                className={classNames.metaViewAll}
                onClick={onViewAll}
                data-test-id={viewAllLinkTestId}
                underline
              >
                {Messages.labels.viewAll()}
              </Link>
            </Stack.Item>
          </Stack>
        </div>
      </div>
    ),
  ];

  const getMessages = (): JSX.Element => {
    const incidentItems = incident?.ticket?.itemsList ? [...incident.ticket.itemsList] : [];

    const sortedIncidentItems = incidentItems.sort((a: ItemWithTimeCreated, b: ItemWithTimeCreated): number => {
      const aTimeCreated = a.timeCreated ? Date.parse(a.timeCreated) : undefined;
      const bTimeCreated = b.timeCreated ? Date.parse(b.timeCreated) : undefined;

      // This will also handle if they are both undefined
      if (aTimeCreated === bTimeCreated) return 0;

      // Handles if either is undefined
      // Pushes the undefined item(s) to the bottom of the list
      if (!aTimeCreated) return -1;
      if (!bTimeCreated) return -1;

      if (aTimeCreated < bTimeCreated) return 1;
      if (aTimeCreated > bTimeCreated) return -1;
      return 0;
    });

    const messageItems: JSX.Element[] = [];

    sortedIncidentItems?.forEach((item, index) => {
      messageItems.push(
        <div
          className={classNames.messageItemContainer}
          key={uniqueGUID()}
          data-test-id={MessageSectionTestIds.MessageItem}
        >
          <div className={classNames.messageItem}>
            <Stack
              onClick={() => {
                showMessageDetails[index] = !showMessageDetails[index];
                setShowMessageDetails(showMessageDetails);
                toggleMessageDetails();
              }}
              horizontal
              tokens={{ childrenGap: 10 }}
              className={classNames.messageItemTitle}
              data-test-id={MessageSectionTestIds.MessageItemTitleContainer}
            >
              <Stack.Item>
                <FontIcon
                  iconName={showMessageDetails && showMessageDetails[index] ? "ChevronDownSmall" : "ChevronUpSmall"}
                  className={classNames.toggleIconClass}
                />
                <FontIcon
                  iconName={SvgIconIds.supportSmallSvg}
                  className={classNames.messageItemTitleIcon}
                />
                <Text
                  variant="medium"
                  className={classNames.messageItemTitleCreateBy}
                  data-test-id={MessageSectionTestIds.MessageItemTitle}
                >
                  {getItemTitle(item)}
                </Text>
              </Stack.Item>
              <Stack.Item>
                <Text
                  variant="medium"
                  className={classNames.messageItemTitleDate}
                  data-test-id={MessageSectionTestIds.MessageItemCreatedTime}
                >
                  <DateTimeDisplay date={(item as ActivityItem)?.timeCreated} hideClipboardCopy />
                </Text>
              </Stack.Item>
            </Stack>
            {showMessageDetails && showMessageDetails[index] && (
              <>
                {item?.name && (
                  <div className={classNames.messageItemDesc}>
                    {item.name}
                  </div>
                )}
                {item?.itemType === "ACTIVITY" && (item as ActivityItem)?.comments && (
                  <div className={classNames.messageItemDesc}>
                    {(item as ActivityItem).comments}
                  </div>
                )}
              </>
            )}
          </div>
        </div>,
      );
    });

    const messageElement: JSX.Element = (
      <div className={classNames.messagesContainer}>
        <div className={classNames.messageHeaderTitle}>
          {Messages.labels.messages()}
        </div>
        <div>
          <Stack key="incident-messages">
            {messageItems}
          </Stack>
        </div>
      </div>
    );
    return messageElement;
  };

  const { trigger: triggerClose } = useOperation<SupportCloseProps>(newSupportClose);
  // const { trigger: triggerReopen } = useOperation<SupportReopenProps>(newSupportReopen);
  const { trigger: triggerAddComment } = useOperation<SupportAddCommentProps>(newSupportAddComment);
  const { trigger: triggerViewAll } = useOperation<SupportViewAllProps>(newSupportViewAll);
  const { trigger: triggerUploadFile } = useOperation<SupportUploadFileProps>(newSupportUploadFile);

  const { closePanels } = useSidePanel();

  const actionItems: ActionType[] = [];
  if (incident?.ticket?.lifecycleState === "ACTIVE") {
    actionItems.push(
      {
        key: ActionIds.Close,
        testId: ActionBarTestIds.Close,
        text: Messages.actions.close(),
        icon: MonochromeIconIds.PageRemove,
        onClick: () => {
          trackActionClick(ActionIds.Close, PageId.SUPPORT_DETAILS);
          triggerClose({
            incidentId,
            supportCloseValues: {
              title: incident.ticket?.title,
              problemType: incident.problemType,
            },
            location: subscriptionLocation || "",
            onExecute: refresh,
            onCancel: () => trackActionDiscard(ActionIds.Close, PageId.SUPPORT_DETAILS),
          });
        },
      },
    );
  }
  /* TODO: Back end does not support reopening tickets yet (even if api spec supports it)
   else if (incident?.ticket?.lifecycleState === "CLOSED") {
     actionItems.push(
       {
         key: ActionIds.Reopen,
         text: Messages.actions.reopen(),
         icon: "PageAdd",
         onClick: () => {
           trackActionClick(ActionIds.Reopen, PageId.SUPPORT_DETAILS);
           triggerReopen({
             incidentId,
             supportReopenValues: {
               title: incident.ticket?.title,
               problemType: incident.problemType,
             },
             targetId: MainTargetId,
             location: subscriptionLocation || "",
             onExecute: refresh,
             onCancel: () => trackActionDiscard(ActionIds.Reopen, PageId.SUPPORT_DETAILS),
           });
         },
       },
     );
   }
   */
  actionItems.push(
    {
      key: ActionIds.Update,
      testId: ActionBarTestIds.Update,
      text: Messages.actions.addComment(),
      icon: MonochromeIconIds.Edit,
      disabled: incident?.ticket?.lifecycleState === "CLOSED",
      onClick: () => {
        trackActionClick(ActionIds.Update, PageId.SUPPORT_DETAILS);
        triggerAddComment({
          incidentId,
          supportAddCommentValues: {
            title: incident?.ticket?.title,
            problemType: incident?.problemType,
          },
          location: subscriptionLocation || "",
          onExecute: refresh,
          onCancel: () => trackActionDiscard(ActionIds.Update, PageId.SUPPORT_DETAILS),
        });
      },
    },
  );

  if (incident?.problemType === ProblemTypeValues.TECHNICAL) {
    actionItems.push(
      {
        key: ActionIds.UploadFile,
        testId: ActionBarTestIds.UploadFile,
        text: Messages.actions.uploadFile(),
        icon: MonochromeIconIds.Upload,
        disabled: incident?.ticket?.lifecycleState === "CLOSED",
        onClick: () => {
          trackActionClick(ActionIds.UploadFile, PageId.SUPPORT_DETAILS);
          triggerUploadFile({
            incidentId,
            supportUploadFileValues: {
              title: incident?.ticket?.title,
              problemType: incident?.problemType,
            },
            targetId: ActionIds.UploadFile,
            location: subscriptionLocation || "",
            onExecute: refresh,
            onCancel: () => trackActionDiscard(ActionIds.UploadFile, PageId.SUPPORT_DETAILS),
          });
        },
      },
    );
  }

  const renderedContent = (): JSX.Element => {
    if (loading) {
      return <Spinner label={Messages.common.loading()} />;
    } if (error) {
      const isNotFound = error?.status === 404;
      const errorTitle = isNotFound
        ? Messages.detailSupport.loadError.notFound.title()
        : Messages.detailSupport.loadError.general.title();
      const errorDetail = isNotFound
        ? Messages.detailSupport.loadError.notFound.details()
        : Messages.detailSupport.loadError.general.details();

      return (
        <ErrorViewPanel
          icon={SvgIconIds.supportSvg}
          title={resName}
          errorTitle={errorTitle}
          resourceId={incidentId}
          errorCode={error?.status}
          details={[
            errorDetail,
            Messages.notifications.apiErrorMsg(error?.body?.message),
          ]}
          isOpen
          onClose={onClose}
        />
      );
    }
    return (
      <FullPagePanel
        title={incident?.ticket?.title
          ? Messages.detailSupport.titles.supportRequest(incident?.ticket?.title)
          : Messages.detailSupport.titles.short()}
        isOpen
        onClose={onClose}
        icon={SvgIconIds.supportSvg}
      >
        <ActionBar actions={actionItems} onActionClick={closePanels} />
        <Stack horizontal tokens={{ childrenGap: 25 }}>
          <Stack.Item className={classNames.metaInfoContainer}>
            {metaItemGroup}
          </Stack.Item>
          <Stack.Item style={{ flex: 1, paddingRight: 25 }}>
            {getMessages()}
          </Stack.Item>
        </Stack>
      </FullPagePanel>
    );
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={incident?.ticket?.title
        ? Messages.detailSupport.titles.supportRequest(incident?.ticket?.title)
        : Messages.detailSupport.titles.short()}
    >
      {renderedContent()}
    </BookmarkablePage>
  );
};
