import { CustomLink, getResourceLifecycleStatus, NoValue, stateT, Status, useNavigation } from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import { Shimmer } from "@fluentui/react";
import { ConsoleContext } from "../../console/ConsoleContext";
import { RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { LinkAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";

export interface Resource {
  id: string | undefined;
  name: string | undefined;
  location: string | undefined;
  status?: string ;
}

export interface ResourceNavigation {
  navigationKey: string;
  panelPath: string | undefined;
}

export interface CustomNavigation {
  onClick: () => void;
}

export interface ResourceLinkProps<T extends Resource> {
  resource: T;
  navigation: ResourceNavigation | CustomNavigation;
  analytics: LinkAnalyticsData;
  error: NormalError | undefined;
  loading: boolean;
  testId?: string;
}

// eslint-disable-next-line react/function-component-definition
export function ResourceLink<T extends Resource>({
  resource,
  navigation,
  analytics,
  error,
  loading,
  testId,
}: ResourceLinkProps<T>): JSX.Element {
  const { trackLinkNavigate } = useAnalytics();

  const { navigateTo } = useNavigation(ConsoleContext);

  const isLoading = (loading && !resource) && !error;

  const renderLink = (): JSX.Element => (
    <CustomLink
      underline
      onClick={() => {
        trackLinkNavigate(analytics.target, analytics.source.pageId, analytics.source.panelId);
        if ("onClick" in navigation) {
          navigation.onClick();
        } else if ("navigationKey" in navigation) {
          navigateTo(
            `${RESOURCE_ROUTE}/${resource?.id}/${navigation.panelPath}?location=${resource?.location}`,
            navigation.navigationKey,
          );
        }
      }}
      linkText={resource?.name || ""}
      testId={testId}
    >
      {resource?.status
        ? (
          <div style={{ marginLeft: "6px" }}>
            <Status
              status={getResourceLifecycleStatus(resource.status)}
              tooltip={stateT(resource.status)}
              hideClipboardCopy
            />
          </div>
        )
        : undefined}
    </CustomLink>
  );

  return (
    <Shimmer
      isDataLoaded={!isLoading}
      width="50%"
    >
      {(error || !resource?.id) && <span data-test-id={testId}><NoValue /></span>}
      {!error && resource?.id && (renderLink())}
    </Shimmer>
  );
}
