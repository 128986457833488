import {
  ActionBar,
  ActionType,
  AnchoredPanelComponent,
  BookmarkablePage,
  DateTimeDisplay,
  DetailView,
  DetailViewsPanel,
  ErrorViewPanel,
  getResourceLifecycleStatus,
  GroupItem,
  InfoBlock,
  InfoBlockLayout,
  InfoBlockStatus,
  Listing,
  ListingColumn,
  ListingDisplayNameLink,
  optimizedRetryOption,
  SelectionMode,
  stateT,
  Status,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FontIcon, IStackStyles, Link, mergeStyleSets, PrimaryButton, Spinner, Stack, Text } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  DetailsPanelId,
  InfoPanelId,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import { MonochromeIconIds, SvgIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  AdbsDeploymentParametersDetails,
  CreateAdbsDatabaseDeploymentDetails,
  CreateCloudExadataInfrastructureDeploymentDetails,
  CreateCloudVmClusterDeploymentDetails,
  CreateDatabaseDeploymentDetails,
  CreateMdsSystemDeploymentDetails,
  CreateMultiCloudVirtualNetworkDeploymentDetails,
  CreateNetworkLinkDeploymentDetails,
  CreateVmdbDbSystemDeploymentDetails,
  Deployment,
  DeploymentLifecycleStateEnum,
  DeploymentResource,
  ExadataDeploymentParametersDetails,
  GetCloudExadataInfrastructureDeploymentDetails,
  GetCloudVmClusterDeploymentDetails,
  GetMdsSystemDeploymentDetails,
  GetMultiCloudVirtualNetworkDeploymentDetails,
  GetNetworkLinkDeploymentDetails,
  HeatwaveDeploymentParametersDetails,
  RegisterExistingOciNetworkDeploymentDetails,
  VmdbDeploymentParametersDetails,
} from "../../gen/clients/mchub-azure-api-client";
import {
  VmdbDatabaseSummary,
  VmdbDatabaseSummaryCollection,
  VmdbDbHomeSummary,
  VmdbDbHomeSummaryCollection,
} from "../../gen/clients/mchub-azure-api-client-vmdb";
import { buildId, parseId } from "../../helpers/idHelper";
import {
  DeploymentResourceType,
  getDeploymentStatusTitle,
  getResourceTypeMessage,
  getTitleSuffix,
  responseItemstoArray,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { DeploymentShowErrorsProps, newDeploymentShowErrors } from "../../operations/Deployment/DeploymentShowErrors";
import {
  DeploymentShowOperationDetailsProps,
  newDeploymentShowOperationDetails,
} from "../../operations/Deployment/DeploymentShowOperationDetails";
import { getOciRegion } from "../../utils";

type DeploymentParametersType =
  ExadataDeploymentParametersDetails &
  VmdbDeploymentParametersDetails &
  AdbsDeploymentParametersDetails &
  HeatwaveDeploymentParametersDetails;

interface DeploymentResourceWithOperation extends DeploymentResource {
  operationDetails: string;
}

const registrationIds = PageRegistrationConfig[PageId.DEPLOYMENT_DETAILS].map(config => config.key);
const exadbCdbDetailsPageRegistrationIds = PageRegistrationConfig[PageId.EXADB_CDB_DETAILS].map(config => config.key);
const exaclusterDetailsPageRegistrationIds = PageRegistrationConfig[PageId.VMCLUSTER_DETAILS].map(config => config.key);
const exainfraDetailsPageRegistrationIds = PageRegistrationConfig[PageId.EXAINFRA_DETAILS].map(config => config.key);
const vmdbCdbDetailsPageRegistrationIds = PageRegistrationConfig[PageId.VMDB_CDB_DETAILS].map(config => config.key);
// eslint-disable-next-line max-len
const vmdbSystemDetailsPageRegistrationIds = PageRegistrationConfig[PageId.VMDB_SYSTEM_DETAILS].map(config => config.key);
const mdsDetailsPageRegIds = PageRegistrationConfig[PageId.MYSQL_DETAILS].map(config => config.key);
const adbsDbDetailsPageRegistrationIds = PageRegistrationConfig[PageId.ADBS_DETAILS].map(config => config.key);
const mcvcnDetailsPageRegistrationIds = PageRegistrationConfig[PageId.MCVCN_DETAILS].map(config => config.key);

const getViewId = (panelId: string | undefined): string => {
  switch (panelId) {
    case DetailsPanelId.OVERVIEW:
    default:
      return registrationIds[0];
  }
};

const classNames = mergeStyleSets({
  statusContainer: {
    display: "flex",
    alignItem: "center",
  },
  statusIcon: { paddingRight: "10px" },
  toggleIconClass: {
    fontSize: "15px",
    width: "15",
    cursor: "pointer",
  },
  metaSectionTitleContainer: {
    display: "flex",
    fontSize: "23px",
    fontWeight: "normal",
    alignItems: "center",
  },
  metaSectionTitleIcon: { paddingRight: "20px" },
  metaItemContainer: {
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  metaItemsIcon: {
    paddingTop: "5px",
    paddingRight: "10px",
    width: "26px",
    height: "26px",
  },
  metaItemsContainer: {
    margin: "10px 0px",
    paddingBottom: "10px",
    paddingTop: "20px",
  },
  resourceTableContainer: { margin: "10px 0px" },
  goToSourceContainer: {
    margin: "10px 0px",
    paddingBottom: "10px",
  },
  boldText: { fontWeight: "bold", cursor: "pointer" },
  goToResourceButton: { height: "24px" },
  errorInfoBlockTitleLink: {
    color: "rgb(50, 49, 48) !important",
    fontSize: "13px",
    ":hover": { textDecoration: "none" },
    ":active": { textDecoration: "none !important" },
  },
});

const labelMetaItemStackStyle: IStackStyles = {
  root: {
    display: "flex",
    margin: "5px",
    border: "1px",
    alignItems: "center",
    justifyContent: "start",
    flexFlow: "row",
  },
};

const metaInfoStackStyle: IStackStyles = {
  root: {
    minWidth: "760px",
    gridGap: "10px",
  },
};

export enum ActionIds {
  Refresh = "refresh",
}

export enum ActionBarTestIds {
  Refresh = "refresh"
}

export enum MetaItemSectionTestIds {
  DeploymentNameValue = "deployment-name-value",
  SubscriptionValue = "deployment-subscription-value",
  ResourceGroupValue = "deployment-resource-group-value",
  TimeCreatedValue = "deployment-time-created-value",
}

export const resourceListingTestId = "deployment-resource-listing";
export const resourceListAccordionTestId = "deployment-resource-accordion-id";
export const goToNextAccordionTestId = "deployment-goto-next-accordion-id";

const VmDatabaseFakeId = "dummy";

export const DeploymentDetailsPage = (): JSX.Element => {
  const { subscriptionId, resourceGroup, provider, resourceType, resourceName, panelId } = useParams();
  const [searchParams] = useSearchParams();
  const { enableMcvcn } = useFeatures();

  const { trackActionClick, trackLinkNavigate } = useAnalytics();

  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");
  const location = searchParams.get("location") || "";

  const [mainDeploymentResource, setMainDeploymentResource] = React.useState<DeploymentResource>();
  const [lastErrorMsgs, setLastErrorMsgs] = React.useState<string[]>();
  const [
    currentDeploymentResourceWithOperation, setCurrentDeploymentResourceWithOperation,
  ] = React.useState<DeploymentResourceWithOperation[]>();

  const [hideDeploymentDetailSection, { toggle: toggleDeploymentDetailSection }] = useBoolean(true);
  const [hideNextStepSection, { toggle: toggleNextStepSection }] = useBoolean(false);

  const [vmDatabase, setVmDdatabase] = React.useState<VmdbDatabaseSummary>();

  const deploymentId = buildId({
    subscriptionId: subscriptionId || "",
    resourceGroup: resGroup,
    provider: provider || "",
    resourceType: resourceType || "",
    resourceName: resName,
  });

  const { back, navigateToPanel, navigateToSelf, navigateTo } = useNavigation(ConsoleContext);

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const registrationConfig = PageRegistrationConfig[PageId.DEPLOYMENT_DETAILS].find(
      config => config.panelPath === panelId,
    );
    const urlQueryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const path = `${RESOURCE_ROUTE}/${deploymentId}/${panelId}${urlQueryParams}`;
    navigateToSelf(path, registrationConfig?.key || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  const onClose = (): void => back();

  const onMenuItemSelect = (id: string): void => navigateToPanel(id);

  // Subscription
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();
  const subscription = !subscriptionLoading && subscriptions
    ? subscriptions.find(s => s.id === subscriptionId)
    : undefined;

  const { response, loading, refresh, error } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).deploymentApi.getDeployment,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        deploymentName: resName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDeployment(),
      },
      excludeErrorStatus: [404],
    },
  });

  const instance = response?.data;
  const isLoading = loading && !instance;

  React.useEffect(() => {
    if (instance) {
      let mainResourceType = DeploymentResourceType.ORACLE_DATABASE_DATABASES;
      const errorMsgArr: string[] = [];
      const tempResourceWithOperation: DeploymentResourceWithOperation[] = [];

      if (instance.parameters?.kind === "VMDB") {
        mainResourceType = DeploymentResourceType.ORACLE_VMDB_DBSYSTEMS;
      } else if (instance.parameters?.kind === "EXADATA") {
        const params: ExadataDeploymentParametersDetails = instance.parameters;
        if (params?.databaseDetails) {
          mainResourceType = DeploymentResourceType.ORACLE_DATABASE_DATABASES;
        } else if (params?.cloudVmClusterDetails) {
          mainResourceType = DeploymentResourceType.ORACLE_DATABASE_CLOUDVMCLUSTERS;
        } else if (params?.cloudExadataInfrastructureDetails) {
          mainResourceType = DeploymentResourceType.ORACLE_DATABASE_CLOUDEXADATAINFRASTRUCTURES;
        }
      } else if (instance.parameters?.kind === "HEATWAVE") {
        mainResourceType = DeploymentResourceType.ORACLE_MDS_DBSYSTEMS;
      } else if (instance.parameters?.kind === "ADBS") {
        mainResourceType = DeploymentResourceType.ORACLE_ADBS_DATABASES;
      }

      instance.resources?.forEach(resource => {
        if (resource.type === mainResourceType) {
          setMainDeploymentResource(resource);
        }
        if (resource.status === "FAILED" && resource.lastOperationStatusDetails) {
          errorMsgArr.push(`${getResourceTypeMessage(resource?.type || "")}: ${resource.lastOperationStatusDetails}`);
        }
        tempResourceWithOperation.push({ ...resource, operationDetails: Messages.labels.operationDetails() });
      });
      if (instance.parameters?.kind === "VMDB") {
        tempResourceWithOperation.push({
          id: vmDatabase?.id || VmDatabaseFakeId,
          type: DeploymentResourceType.ORACLE_VMDB_DATABASES,
          status: tempResourceWithOperation[tempResourceWithOperation.length - 1].status,
          operationDetails: "",
        } as DeploymentResourceWithOperation);
      }
      setLastErrorMsgs(errorMsgArr);
      setCurrentDeploymentResourceWithOperation(tempResourceWithOperation);
    }
  }, [instance, vmDatabase]);

  const overviewActionItems: ActionType[] = [
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.DEPLOYMENT_DETAILS, DetailsPanelId.OVERVIEW);
        refresh();
      },
    },
  ];

  const essentialsMetaItemGroup: JSX.Element[] = [
    (
      <Stack horizontal disableShrink key="deployment-details-meta-section">
        <Stack>
          <FontIcon
            iconName={SvgIconIds.deploymentMetaIconSvg}
            aria-label={Messages.topHeader.settings()}
            title={Messages.topHeader.settings()}
            className={classNames.metaItemsIcon}
          />
        </Stack>
        <Stack.Item align="auto">
          <Stack
            styles={labelMetaItemStackStyle}
          >
            <Text>{Messages.labels.deploymentName()}</Text>
            {": "}
            <div
              className={classNames.metaItemContainer}
              data-test-id={MetaItemSectionTestIds.DeploymentNameValue}
            >
              <Text>{instance?.name}</Text>
            </div>
          </Stack>
          <Stack
            styles={labelMetaItemStackStyle}
          >
            <Text>{Messages.labels.subscription()}</Text>
            {": "}
            <div
              className={classNames.metaItemContainer}
              data-test-id={MetaItemSectionTestIds.SubscriptionValue}
            >
              <AzureSubscriptionLink
                resourceId={instance?.id as string}
                subscriptionName={subscription?.name}
                hideClipboard
              />
            </div>
          </Stack>
          <Stack
            styles={labelMetaItemStackStyle}
          >
            <Text>{Messages.labels.resourceGroup()}</Text>
            {": "}
            <div
              className={classNames.metaItemContainer}
              data-test-id={MetaItemSectionTestIds.ResourceGroupValue}
            >
              <AzureResourceGroupLink resourceId={instance?.id as string} hideClipboard />
            </div>
          </Stack>
        </Stack.Item>
        <Stack.Item align="auto">
          <Stack
            styles={labelMetaItemStackStyle}
          >
            <Text>{Messages.labels.startTime()}</Text>
            {": "}
            <div
              className={classNames.metaItemContainer}
              data-test-id={MetaItemSectionTestIds.TimeCreatedValue}
            >
              <Text><DateTimeDisplay date={instance?.timeCreated} hideClipboardCopy /></Text>
            </div>
          </Stack>
        </Stack.Item>
      </Stack>
    ),
  ];

  const getResourceName = (
    deployment: Deployment,
    resource: DeploymentResource,
  ): string => {
    let name = deployment?.name;

    const params: DeploymentParametersType | undefined = deployment?.parameters;
    switch (resource?.type) {
      // Exadata
      case DeploymentResourceType.ORACLE_DATABASE_CLOUDEXADATAINFRASTRUCTURES:
        if (params?.cloudExadataInfrastructureDetails?.kind === "CREATE") {
          name = (params?.cloudExadataInfrastructureDetails as CreateCloudExadataInfrastructureDeploymentDetails).name;
        } else {
          const id = (params?.cloudExadataInfrastructureDetails as GetCloudExadataInfrastructureDeploymentDetails)?.id;
          const idComps = parseId(id);
          name = idComps?.resourceName;
        }
        break;
      case DeploymentResourceType.ORACLE_DATABASE_CLOUDVMCLUSTERS:
        if (params?.cloudVmClusterDetails?.kind === "CREATE") {
          name = (params?.cloudVmClusterDetails as CreateCloudVmClusterDeploymentDetails).name;
        } else {
          const id = (params?.cloudVmClusterDetails as GetCloudVmClusterDeploymentDetails)?.id;
          const idComps = parseId(id);
          name = idComps?.resourceName;
        }
        break;
      case DeploymentResourceType.ORACLE_DATABASE_DATABASES:
        name = (params?.databaseDetails as CreateDatabaseDeploymentDetails)?.name;
        break;
      // VMDB
      case DeploymentResourceType.ORACLE_VMDB_DBSYSTEMS:
        name = (params?.vmdbDbSystemDeploymentDetails as CreateVmdbDbSystemDeploymentDetails)?.name;
        break;
      case DeploymentResourceType.ORACLE_VMDB_DATABASES:
        name = (params?.vmdbDbSystemDeploymentDetails as CreateVmdbDbSystemDeploymentDetails)?.dbHome.database.dbName;
        break;
        // ADBS
      case DeploymentResourceType.ORACLE_ADBS_DATABASES:
        name = (params?.adbsDatabaseDetails as CreateAdbsDatabaseDeploymentDetails)?.name;
        break;
      // Heatwave
      case DeploymentResourceType.ORACLE_MDS_DBSYSTEMS:
      case DeploymentResourceType.ORACLE_MDS_HEATWAVE:
        // The heatwave name is simply the name of the mds db system
        if (params?.mdsDbSystemDeploymentDetails?.kind === "CREATE") {
          name = (params?.mdsDbSystemDeploymentDetails as CreateMdsSystemDeploymentDetails).name;
        } else if (params?.mdsDbSystemDeploymentDetails?.kind === "GET") {
          const id = (params?.mdsDbSystemDeploymentDetails as GetMdsSystemDeploymentDetails)?.id;
          const idComps = parseId(id);
          name = idComps?.resourceName;
        } else {
          name = deployment?.name;
        }
        break;
      // Network
      case DeploymentResourceType.ORACLE_NETWORK_NETWORKLINKS:
        if (params?.networkLinkDetails?.kind === "CREATE") {
          name = (params?.networkLinkDetails as CreateNetworkLinkDeploymentDetails).name;
        } else {
          const id = (params?.networkLinkDetails as GetNetworkLinkDeploymentDetails)?.id;
          const idComps = parseId(id);
          name = idComps?.resourceName;
        }
        break;
      case DeploymentResourceType.ORACLE_NETWORK_MCVCNS:
        if (params?.multiCloudVirtualNetworkDetails?.kind === "CREATE") {
          name = (params?.multiCloudVirtualNetworkDetails as CreateMultiCloudVirtualNetworkDeploymentDetails).name;
        } else if (params?.multiCloudVirtualNetworkDetails?.kind === "REGISTER") {
          name = (params?.multiCloudVirtualNetworkDetails as RegisterExistingOciNetworkDeploymentDetails).name;
        } else {
          const id = (params?.multiCloudVirtualNetworkDetails as GetMultiCloudVirtualNetworkDeploymentDetails)?.id;
          const idComps = parseId(id);
          name = idComps?.resourceName;
        }
        break;
      // Default
      default:
        name = deployment?.name;
        break;
    }
    return name;
  };

  // Need to get the vmdb database id to build links since we only get the dbsystem id back
  const vmDbsystemResource = instance?.resources?.find(
    resource => resource.type === DeploymentResourceType.ORACLE_VMDB_DBSYSTEMS,
  );
  const vmDbsystemResourceIdComps = parseId(vmDbsystemResource?.id);

  const { response: vmDbHomesResponse } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbDbHomes,
    wait: !vmDbsystemResource?.id,
    options: {
      args: {
        subscriptionId: vmDbsystemResourceIdComps?.subscriptionId,
        resourceGroupName: vmDbsystemResourceIdComps?.resourceGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        dbSystemId: vmDbsystemResource?.id,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
  });

  const vmDbHome = (vmDbHomesResponse
    && responseItemstoArray<VmdbDbHomeSummaryCollection, VmdbDbHomeSummary>(vmDbHomesResponse))?.[0];

  const { response: vmDatabasesResponse } = useQueryCall({
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbDatabases,
    wait: !vmDbHome,
    options: {
      args: {
        subscriptionId: vmDbsystemResourceIdComps?.subscriptionId,
        resourceGroupName: vmDbsystemResourceIdComps?.resourceGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        dbHomeId: vmDbHome?.id,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
  });

  const vmDatabaseSummary = (vmDatabasesResponse
    && responseItemstoArray<VmdbDatabaseSummaryCollection, VmdbDatabaseSummary>(vmDatabasesResponse))?.[0];

  React.useEffect(() => {
    if (vmDatabaseSummary) {
      setVmDdatabase(vmDatabaseSummary);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(vmDatabaseSummary)]);

  const getNameVal = (
    deployment: Deployment,
    resource: DeploymentResource,
  ): JSX.Element => {
    const resourceIdComps = parseId(resource?.id);
    let textElement = <div>{resourceIdComps?.resourceName || getResourceName(deployment, resource)}</div>;

    if (resource?.type === DeploymentResourceType.ORACLE_DATABASE_DATABASES && resource?.id) {
      textElement = (
        <ListingDisplayNameLink
          displayName={resourceIdComps?.resourceName || ""}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${resource?.id}/${PageRegistrationConfig[PageId.EXADB_CDB_DETAILS][0].panelPath}?location=${deployment.location}`,
            pageKey: exadbCdbDetailsPageRegistrationIds[0],
          }}
        />
      );
    } else if (resource?.type === DeploymentResourceType.ORACLE_DATABASE_CLOUDVMCLUSTERS && resource?.id) {
      textElement = (
        <ListingDisplayNameLink
          displayName={resourceIdComps?.resourceName || ""}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${resource?.id}/${PageRegistrationConfig[PageId.VMCLUSTER_DETAILS][0].panelPath}?location=${deployment.location}`,
            pageKey: exaclusterDetailsPageRegistrationIds[0],
          }}
        />
      );
    } else if (resource?.type === DeploymentResourceType.ORACLE_DATABASE_CLOUDEXADATAINFRASTRUCTURES && resource?.id) {
      textElement = (
        <ListingDisplayNameLink
          displayName={resourceIdComps?.resourceName || ""}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${resource?.id}/${PageRegistrationConfig[PageId.EXAINFRA_DETAILS][0].panelPath}?location=${deployment.location}`,
            pageKey: exainfraDetailsPageRegistrationIds[0],
          }}
        />
      );
    } else if (resource?.type === DeploymentResourceType.ORACLE_VMDB_DBSYSTEMS && resource?.id) {
      // Link to VMDB Database page with the db system panel in focus (3rd panel)
      // ---> more recent is to show DBSystem page in this case
      textElement = (
        <ListingDisplayNameLink
          displayName={resourceIdComps?.resourceName || ""}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${resource?.id}/${PageRegistrationConfig[PageId.VMDB_SYSTEM_DETAILS][0].panelPath}?location=${deployment.location}`,
            pageKey: vmdbSystemDetailsPageRegistrationIds[0],
          }}
        />
      );
    } else if (resource?.type === DeploymentResourceType.ORACLE_VMDB_DATABASES && resource?.id !== VmDatabaseFakeId) {
      textElement = (
        <ListingDisplayNameLink
          displayName={resourceIdComps?.resourceName || ""}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${resource?.id}/${PageRegistrationConfig[PageId.VMDB_CDB_DETAILS][0].panelPath}?location=${deployment.location}`,
            pageKey: vmdbCdbDetailsPageRegistrationIds[0],
          }}
        />
      );
    } else if (resource?.type === DeploymentResourceType.ORACLE_MDS_DBSYSTEMS && resource?.id) {
      textElement = (
        <ListingDisplayNameLink
          displayName={resourceIdComps?.resourceName || ""}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${resource?.id}/${PageRegistrationConfig[PageId.MYSQL_DETAILS][0].panelPath}?location=${deployment.location}`,
            pageKey: mdsDetailsPageRegIds[0],
          }}
        />
      );
    } else if (resource?.type === DeploymentResourceType.ORACLE_MDS_HEATWAVE && resource?.id) {
      textElement = (
        <ListingDisplayNameLink
          displayName={resourceIdComps?.resourceName || ""}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${resource?.id}/${PageRegistrationConfig[PageId.MYSQL_DETAILS][2].panelPath}?location=${deployment.location}`,
            pageKey: mdsDetailsPageRegIds[2],
          }}
        />
      );
    } else if (resource?.type === DeploymentResourceType.ORACLE_ADBS_DATABASES && resource?.id) {
      textElement = (
        <ListingDisplayNameLink
          displayName={resourceIdComps?.resourceName || ""}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${resource?.id}/${PageRegistrationConfig[PageId.ADBS_DETAILS][0].panelPath}?location=${deployment.location}`,
            pageKey: adbsDbDetailsPageRegistrationIds[0],
          }}
        />
      );
    } else if (enableMcvcn && resource?.type === DeploymentResourceType.ORACLE_NETWORK_MCVCNS && resource?.id) {
      textElement = (
        <ListingDisplayNameLink
          displayName={resourceIdComps?.resourceName || ""}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${resource?.id}/${PageRegistrationConfig[PageId.MCVCN_DETAILS][0].panelPath}?location=${deployment.location}`,
            pageKey: mcvcnDetailsPageRegistrationIds[0],
          }}
        />
      );
    }

    return (
      <div className={classNames.statusContainer}>
        {textElement}
      </div>
    );
  };
  const getNameStr = (
    deployment: Deployment,
    resource: DeploymentResource,
  ): string => {
    const resourceIdComps = parseId(resource?.id);
    const textStr = resourceIdComps?.resourceName || getResourceName(deployment, resource) || "";
    return textStr;
  };

  const getTypeVal = (
    resource: DeploymentResource,
  ): string => getResourceTypeMessage(resource?.type || "");

  const getStatusVal = (
    resource: DeploymentResource,
  ): JSX.Element => (
    <Status
      label={stateT(resource?.status || "")}
      status={getResourceLifecycleStatus(resource?.status || "")}
      hideClipboardCopy
    />
  );

  const getOperationDetails = (
    resource: DeploymentResource,
  ): JSX.Element => (
    <Link
      title={Messages.labels.operationDetails()}
      onClick={() => {
        trackLinkNavigate(
          InfoPanelId.DEPLOYMENT_OPERATION_DETAILS,
          PageId.DEPLOYMENT_DETAILS,
          DetailsPanelId.OVERVIEW,
        );
        triggerShowOperationDetails({
          resourceId: resource?.id,
          deploymentShowOperationDetailValues: {
            deploymentId,
            status: stateT(resource?.status || ""),
            errorMsg: resource?.status === "FAILED" && resource?.lastOperationStatusDetails
              ? resource?.lastOperationStatusDetails : "",
            type: getResourceTypeMessage(resource?.type || ""),
            resourceName: getNameStr(instance as Deployment, resource),
          },
          location,
        });
      }}
    >
      {Messages.labels.operationDetails()}
    </Link>
  );

  const columns: ListingColumn[] = [
    {
      itemProp: "id",
      name: Messages.labels.resource(),
      flexGrow: 1,
      onRenderItems: value => getNameVal(instance as Deployment, value),
    },
    {
      itemProp: "type",
      flexGrow: 1,
      name: Messages.common.type(),
      onRenderItems: value => getTypeVal(value),
    },
    {
      itemProp: "status",
      flexGrow: 1,
      name: Messages.labels.status(),
      onRenderItems: value => getStatusVal(value),
    },
    {
      itemProp: "operationDetails",
      flexGrow: 1,
      name: Messages.labels.operationDetails(),
      onRenderItems: value => getOperationDetails(value),
    },
  ];

  const { trigger: triggerShowErrors } = useOperation<DeploymentShowErrorsProps>(newDeploymentShowErrors);
  const { trigger: triggerShowOperationDetails } = useOperation<
    DeploymentShowOperationDetailsProps
  >(newDeploymentShowOperationDetails);

  const { closePanels } = useSidePanel();

  const detailViews: DetailView[] = [
    {
      id: registrationIds[0],
      content: (
        <>
          <Stack>
            <Stack>
              <ActionBar
                actions={overviewActionItems}
                onActionClick={closePanels}
              />
            </Stack>
            {lastErrorMsgs && lastErrorMsgs.length > 0 && (
              <InfoBlock
                message={(
                  <Link
                    className={classNames.errorInfoBlockTitleLink}
                    underline={false}
                    onClick={() => {
                      trackLinkNavigate(
                        InfoPanelId.DEPLOYMENT_ERRORS,
                        PageId.DEPLOYMENT_DETAILS,
                        DetailsPanelId.OVERVIEW,
                      );
                      triggerShowErrors({
                        errorMsgs: lastErrorMsgs,
                        location,
                      });
                    }}
                  >
                    {Messages.deployment.overview.sectionTitles.deploymentFailed()}
                  </Link>
                )}
                messageType={InfoBlockStatus.ERROR}
                infoLayout={InfoBlockLayout.Compact}
              />
            )}
            <Stack className={classNames.metaItemsContainer}>
              <Stack styles={metaInfoStackStyle}>
                <Stack horizontal horizontalAlign="space-between">
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}
                  >
                    <div className={classNames.metaSectionTitleContainer}>
                      {!lastErrorMsgs && (
                        <div className={classNames.metaSectionTitleIcon}>
                          <Status
                            status={getResourceLifecycleStatus(instance?.lifecycleState || "")}
                            tooltip={stateT(instance?.lifecycleState || "")}
                          />
                        </div>
                      )}
                      {getDeploymentStatusTitle(instance?.lifecycleState as DeploymentLifecycleStateEnum)}
                    </div>
                  </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  {essentialsMetaItemGroup}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              horizontal
              horizontalAlign="space-between"
              className={classNames.resourceTableContainer}
            >
              <Stack
                data-test-id={resourceListAccordionTestId}
                onClick={toggleDeploymentDetailSection}
                horizontal
                tokens={{ childrenGap: 10 }}
              >
                <FontIcon
                  iconName={hideDeploymentDetailSection ? "ChevronDownSmall" : "ChevronUpSmall"}
                  className={classNames.toggleIconClass}
                />
                <Text variant="medium" className={classNames.boldText}>
                  {Messages.deployment.overview.sectionTitles.deploymentDetails()}
                </Text>
              </Stack>
            </Stack>
            {!hideDeploymentDetailSection && (
              <Stack style={{ height: "180px" }}>
                <Listing
                  testId={resourceListingTestId}
                  items={currentDeploymentResourceWithOperation || []}
                  listColumns={columns}
                  selectionMode={SelectionMode.none}
                />
              </Stack>
            )}
          </Stack>
          <Stack>
            <Stack
              horizontal
              horizontalAlign="space-between"
              className={classNames.goToSourceContainer}
            >
              <Stack
                data-test-id={goToNextAccordionTestId}
                onClick={toggleNextStepSection}
                horizontal
                tokens={{ childrenGap: 10 }}
              >
                <FontIcon
                  iconName={hideNextStepSection ? "ChevronDownSmall" : "ChevronUpSmall"}
                  className={classNames.toggleIconClass}
                />
                <Text variant="medium" className={classNames.boldText}>
                  {Messages.deployment.overview.sectionTitles.nextSteps()}
                </Text>
              </Stack>
            </Stack>
            {!hideNextStepSection
              && mainDeploymentResource
              && mainDeploymentResource?.type === DeploymentResourceType.ORACLE_DATABASE_DATABASES
              && (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <PrimaryButton
                    className={classNames.goToResourceButton}
                    text={Messages.actions.goToResource()}
                    onClick={() => {
                      trackLinkNavigate(
                        {
                          pageId: PageId.EXADB_CDB_DETAILS,
                          panelId: DetailsPanelId.OVERVIEW,
                        },
                        PageId.DEPLOYMENT_DETAILS,
                        DetailsPanelId.OVERVIEW,
                      );
                      navigateTo(
                        // eslint-disable-next-line max-len
                        `${RESOURCE_ROUTE}/${mainDeploymentResource.id}/${PageRegistrationConfig[PageId.EXADB_CDB_DETAILS][0].panelPath}?location=${instance?.location}`,
                        exadbCdbDetailsPageRegistrationIds[0],
                      );
                    }}
                    disabled={!mainDeploymentResource || !mainDeploymentResource.id}
                  />
                </Stack>
              )}
            {!hideNextStepSection
              && mainDeploymentResource
              && mainDeploymentResource?.type === DeploymentResourceType.ORACLE_DATABASE_CLOUDVMCLUSTERS
              && (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <PrimaryButton
                    className={classNames.goToResourceButton}
                    text={Messages.actions.goToResource()}
                    onClick={() => {
                      trackLinkNavigate(
                        {
                          pageId: PageId.VMCLUSTER_DETAILS,
                          panelId: DetailsPanelId.OVERVIEW,
                        },
                        PageId.DEPLOYMENT_DETAILS,
                        DetailsPanelId.OVERVIEW,
                      );
                      navigateTo(
                        // eslint-disable-next-line max-len
                        `${RESOURCE_ROUTE}/${mainDeploymentResource.id}/${PageRegistrationConfig[PageId.VMCLUSTER_DETAILS][0].panelPath}?location=${instance?.location}`,
                        exaclusterDetailsPageRegistrationIds[0],
                      );
                    }}
                    disabled={!mainDeploymentResource || !mainDeploymentResource.id}
                  />
                </Stack>
              )}
            {!hideNextStepSection
              && mainDeploymentResource
              && mainDeploymentResource?.type === DeploymentResourceType.ORACLE_DATABASE_CLOUDEXADATAINFRASTRUCTURES
              && (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <PrimaryButton
                    className={classNames.goToResourceButton}
                    text={Messages.actions.goToResource()}
                    onClick={() => {
                      trackLinkNavigate(
                        {
                          pageId: PageId.EXAINFRA_DETAILS,
                          panelId: DetailsPanelId.OVERVIEW,
                        },
                        PageId.DEPLOYMENT_DETAILS,
                        DetailsPanelId.OVERVIEW,
                      );
                      navigateTo(
                        // eslint-disable-next-line max-len
                        `${RESOURCE_ROUTE}/${mainDeploymentResource.id}/${PageRegistrationConfig[PageId.EXAINFRA_DETAILS][0].panelPath}?location=${instance?.location}`,
                        exainfraDetailsPageRegistrationIds[0],
                      );
                    }}
                    disabled={!mainDeploymentResource || !mainDeploymentResource.id}
                  />
                </Stack>
              )}
            {!hideNextStepSection
              && mainDeploymentResource
              && mainDeploymentResource?.type === DeploymentResourceType.ORACLE_VMDB_DBSYSTEMS
              && (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <PrimaryButton
                    className={classNames.goToResourceButton}
                    text={Messages.actions.goToResource()}
                    onClick={() => {
                      trackLinkNavigate(
                        {
                          pageId: PageId.VMDB_CDB_DETAILS,
                          panelId: DetailsPanelId.OVERVIEW,
                        },
                        PageId.DEPLOYMENT_DETAILS,
                        DetailsPanelId.OVERVIEW,
                      );
                      navigateTo(
                        // eslint-disable-next-line max-len
                        `${RESOURCE_ROUTE}/${vmDatabase?.id}/${PageRegistrationConfig[PageId.VMDB_CDB_DETAILS][0].panelPath}?location=${instance?.location}`,
                        vmdbCdbDetailsPageRegistrationIds[0],
                      );
                    }}
                    disabled={!mainDeploymentResource || !mainDeploymentResource.id || !vmDatabase}
                  />
                </Stack>
              )}
            {!hideNextStepSection
              && mainDeploymentResource
              && mainDeploymentResource?.type === DeploymentResourceType.ORACLE_ADBS_DATABASES
              && (
                <Stack horizontal tokens={{ childrenGap: 20 }}>

                  <PrimaryButton
                    className={classNames.goToResourceButton}
                    text={Messages.actions.goToResource()}
                    onClick={() => {
                      trackLinkNavigate(
                        {
                          pageId: PageId.ADBS_DETAILS,
                          panelId: DetailsPanelId.OVERVIEW,
                        },
                        PageId.DEPLOYMENT_DETAILS,
                        DetailsPanelId.OVERVIEW,
                      );
                      navigateTo(
                        // eslint-disable-next-line max-len
                        `${RESOURCE_ROUTE}/${mainDeploymentResource.id}/${PageRegistrationConfig[PageId.ADBS_DETAILS][0].panelPath}?location=${instance?.location}`,
                        adbsDbDetailsPageRegistrationIds[0],
                      );
                    }}
                    disabled={!mainDeploymentResource || !mainDeploymentResource.id}
                  />
                </Stack>
              )}
            {!hideNextStepSection
              && mainDeploymentResource
              && mainDeploymentResource?.type === DeploymentResourceType.ORACLE_MDS_DBSYSTEMS
              && (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <PrimaryButton
                    className={classNames.goToResourceButton}
                    text={Messages.actions.goToResource()}
                    onClick={() => {
                      trackLinkNavigate(
                        {
                          pageId: PageId.MYSQL_DETAILS,
                          panelId: DetailsPanelId.OVERVIEW,
                        },
                        PageId.DEPLOYMENT_DETAILS,
                        DetailsPanelId.OVERVIEW,
                      );
                      navigateTo(
                        // eslint-disable-next-line max-len
                        `${RESOURCE_ROUTE}/${mainDeploymentResource.id}/${PageRegistrationConfig[PageId.MYSQL_DETAILS][0].panelPath}?location=${instance?.location}`,
                        mdsDetailsPageRegIds[0],
                      );
                    }}
                    disabled={!mainDeploymentResource || !mainDeploymentResource.id}
                  />
                </Stack>
              )}
          </Stack>
        </>
      ),
    },
  ];

  const groupItems: GroupItem[] = [
    {
      items: [
        {
          icon: SvgIconIds.deploymentSvg,
          id: detailViews[0].id,
          name: Messages.labels.overview(),
        },
      ],
    },
  ];

  const renderedContent = (): JSX.Element => {
    if (isLoading) {
      return <Spinner label={Messages.common.loading()} />;
    } if (error) {
      const isNotFound = error?.status === 404;
      const errorTitle = isNotFound
        ? Messages.deployment.loadError.notFound.title()
        : Messages.deployment.loadError.general.title();
      const errorDetail = isNotFound
        ? Messages.deployment.loadError.notFound.details()
        : Messages.deployment.loadError.general.details();

      return (
        <ErrorViewPanel
          icon={SvgIconIds.deploymentSvg}
          title={resName}
          errorTitle={errorTitle}
          resourceId={deploymentId}
          errorCode={error?.status}
          details={[
            errorDetail,
            Messages.notifications.apiErrorMsg(error?.body?.message),
          ]}
          isOpen
          onClose={onClose}
          favoriteAccessRegistration={{ id: deploymentId, data: { location } }}
        />
      );
    }
    return (
      <DetailViewsPanel
        componentRef={setPanelRef}
        title={instance?.name || ""}
        subTitle={Messages.deployment.titles.deployment()}
        icon={SvgIconIds.deploymentSvg}
        onClose={onClose}
        isOpen
        views={detailViews}
        activeViewId={getViewId(panelId)}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
        favoriteAccessRegistration={{ id: instance?.id || "", data: { location } }}
      />
    );
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={instance?.name || Messages.deployment.titles.deployment()}
      titleSuffix={instance?.name ? getTitleSuffix(panelId || "") : undefined}
      recentAccessRegistration={{ id: instance?.id || "", data: { location } }}
    >
      {renderedContent()}
    </BookmarkablePage>
  );
};
