import {
  AnchoredPanel,
  AnchoredPanelType,
  getResourceLifecycleStatus,
  LabelMetaItem,
  MetaItemGroupsLayout,
  MetaItemsGroupsDirection,
  NoValue,
  optimizedRetryOption,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import { Spinner, Stack } from "@fluentui/react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { metaItemLabelsGap, spinnerTestId, ttlOneMinCaching } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { getStatusInfo } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { AzureSubscriptionSummaryExt } from "../../hooks/useSubscriptions";
import { getAzureLocationName, getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export enum MetaItemSectionTestIds {
  ResourceGroup = "mis-resource-group",
  LifeCycleStatus = "mis-status",
  Location = "mis-loaction",
  Subscription = "mis-subscription",
  SubscriptionId = "mis-subscription-id",
  CoreCount = "mis-core-count",
  MemoryInGbs = "mis-memory-in-gbs",
}
export interface VmDbViewDbNodeProps extends OperationProps {
  dbNodeId: string | undefined;
  subscriptions: AzureSubscriptionSummaryExt[];
}

export const VmDbViewDbNode = ({
  dbNodeId,
  subscriptions,
  location,
  onCancel,
}: VmDbViewDbNodeProps): JSX.Element => {
  const idComps = parseId(dbNodeId);
  const resGroup = decodeURIComponent(idComps?.resourceGroup || "");
  const resourceName = decodeURIComponent(idComps?.resourceName || "");
  const subscription = subscriptions?.find(item => item.id === idComps?.subscriptionId);

  const { response, loading } = useQueryCall({
    wait: !dbNodeId,
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.getVmdbDbNode,
    options: {
      args: {
        subscriptionId: idComps?.subscriptionId || "",
        resourceGroupName: resGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        dbNodeName: resourceName,
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVMDbNode(),
      },
    },
  });

  const dbNode = response?.data;

  const essentialsMetaItemGroup: JSX.Element[] = [
    <Stack key="essentials-item-group-1" tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem testId={MetaItemSectionTestIds.ResourceGroup} label={Messages.labels.resourceGroup()}>
        <AzureResourceGroupLink resourceId={dbNodeId as string} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.LifeCycleStatus} label={Messages.labels.status()}>
        <Status
          iconPosition="right"
          label={stateT(dbNode?.status || "")}
          status={getResourceLifecycleStatus(dbNode?.status || "")}
          statusInfo={getStatusInfo(
            dbNode?.status || "",
            dbNode?.lastOperationStatus || "",
            dbNode?.lastOperationStatusDetails || "",
          )}
        />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Location} label={Messages.labels.location()}>
        {dbNode?.location ? getAzureLocationName(dbNode.location) : <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.Subscription} label={Messages.labels.subscription()}>
        <AzureSubscriptionLink resourceId={dbNodeId as string} subscriptionName={subscription?.name} />
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.SubscriptionId} label={Messages.labels.subscriptionId()}>
        {idComps?.subscriptionId || <NoValue />}
      </LabelMetaItem>
    </Stack>,
    <Stack key="essentials-item-group-2" tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem testId={MetaItemSectionTestIds.CoreCount} label={Messages.labels.coreCount()}>
        {dbNode?.cpuCoreCount?.toString() || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemSectionTestIds.MemoryInGbs} label={Messages.labels.memoryInGB()}>
        {dbNode?.memorySizeInGBs?.toString() || <NoValue />}
      </LabelMetaItem>
    </Stack>,
  ];

  return (
    <AnchoredPanel
      type={AnchoredPanelType.CUSTOM_WIDTH}
      customWidth={700}
      title={dbNode?.name || ""}
      isOpen
      onClose={onCancel}
    >
      {loading ? <Spinner data-test-id={spinnerTestId} label={Messages.common.loading()} />
        : (
          <MetaItemGroupsLayout direction={MetaItemsGroupsDirection.Vertical}>
            { essentialsMetaItemGroup }
          </MetaItemGroupsLayout>
        )}
    </AnchoredPanel>
  );
};

export const newVmDbViewDbNode = (
  props: VmDbViewDbNodeProps,
): JSX.Element => (<VmDbViewDbNode {...props} />);
