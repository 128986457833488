import {
  ActionBar,
  ActionType,
  InfoBlockProps,
  InfoBlockStatus,
  LabelMetaItem,
  MetaItemSection,
  NoValue,
} from "o4a-react";
import * as React from "react";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds } from "../../constants/uiConstants";
import { MdsDbSystem } from "../../gen/clients/mchub-azure-api-client-mds";
import {
  MdsAutoBackupRetentionPolicy,
  MdsCrashRecovery,
  MdsFinalBackupPolicy,
  ResourceStatus,
} from "../../helpers/resourceHelper";
import { utc } from "../../helpers/timeHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useSidePanel } from "../../hooks/useSidePanel";
import { MysqlEditBackupConfigProps, newMysqlEditBackupConfig } from "../../operations/Mysql/MysqlEditBackupConfig";
import { MysqlEditDeletionPlanProps, newMysqlEditDeletionPlan } from "../../operations/Mysql/MysqlEditDeletionPlan";
import {
  MysqlUpdateCrashRecoveryProps,
  newMysqlUpdateCrashRecovery,
} from "../../operations/Mysql/MysqlUpdateCrashRecovery";

export enum ActionIds {
  EditBackupPlan = "edit-backup-plan",
  Restore = "restore",
  Refresh = "refresh",
  EditDeletionPlan = "edit-deletion-plan",
  RefreshDeletionPlan = "refresh-deletion-plan",
  UpdateCrashRecovery = "update-crash-recovery"
}

export enum ActionBarTestIds {
  EditBackupPlan = "edit-backup-plan",
  Restore = "restore",
  Refresh = "refresh",
  EditDeletionPlan = "edit-deletion-plan",
  RefreshDeletionPlan = "refresh-deletion-plan",
  UpdateCrashRecovery = "update-crash-recovery"
}

export enum MetaItemSectionTestIds {
  AUTO_BACKUP = "mis-auto-backup",
  RETENTION_PERIOD = "mis-retention-period",
  AUTO_BACKUP_WINDOW = "mis-auto-backup-window",
  PITR_POLICY = "mis-pitr-policy",
  CRASH_RECOVERY = "mis-crash-recovery",
  DELETE_PROTECTED = "mis-delete-protected",
  RETAIN_ATUO_BACKUPS = "mis-retain-auto-backups",
  REQ_FINAL_BACKUP = "mis-require-final-backup",
}

export enum InfoBlockTestIds {
  CrashRecoveryDisabled = "disabled-crash-recovery-infoblock",
  CrashRecoveryEnabled = "enabled-crash-recovery-infoblock",
}

export enum PivotItemTestIds {
  EditBackupPlan = "edit-backup-plan-pivot-item",
  EditDeletionPlan = "edit-deletion-plan-pivot-item",
}

export interface MysqlManagementDetailsProps {
  mdsDbSystemId: string,
  database: MdsDbSystem | undefined;
  location: string;
  refresh: () => void;
}

export const MysqlManagementDetails = (
  { location, mdsDbSystemId, database, refresh }: MysqlManagementDetailsProps,
): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const autoBackupWindow = database?.backupPolicy?.windowStartTime;
  const backupSchedule = database?.backupPolicy?.retentionInDays;
  const autoBackup = database?.backupPolicy?.isEnabled;
  const crashRecoveryEnabled = database?.crashRecovery === MdsCrashRecovery.ENABLED;
  const deleteProtected = database?.deletionPolicy?.isDeleteProtected;
  const requireFinalBackup = database?.deletionPolicy?.finalBackup === MdsFinalBackupPolicy.REQUIRE_FINAL_BACKUP;
  const retainAutomaticBackups = database?.deletionPolicy?.automaticBackupRetention
    === MdsAutoBackupRetentionPolicy.RETAIN;
  const pitrPolicy = database?.backupPolicy?.pitrPolicy;

  const { trigger: triggerEditBackupConfig } = useOperation<MysqlEditBackupConfigProps>(newMysqlEditBackupConfig);
  const { trigger: triggerEditDeletionPlan } = useOperation<MysqlEditDeletionPlanProps>(newMysqlEditDeletionPlan);
  // eslint-disable-next-line max-len
  const { trigger: triggerUpdateCrashRecovery } = useOperation<MysqlUpdateCrashRecoveryProps>(newMysqlUpdateCrashRecovery);
  const { closePanels } = useSidePanel();

  const backupActions: ActionType[] = [
    {
      key: ActionIds.EditBackupPlan,
      testId: ActionBarTestIds.EditBackupPlan,
      text: Messages.actions.edit(),
      icon: MonochromeIconIds.Edit,
      disabled: (database?.status !== ResourceStatus.Active) || !crashRecoveryEnabled,
      onClick: () => {
        trackActionClick(ActionIds.EditBackupPlan, PageId.MYSQL_DETAILS, DetailsPanelId.MANAGEMENT);
        triggerEditBackupConfig({
          databaseId: mdsDbSystemId,
          backupPolicy: database?.backupPolicy,
          location,
          onExecute: refresh,
          onCancel: () => trackActionDiscard(
            ActionIds.EditBackupPlan,
            PageId.MYSQL_DETAILS,
            DetailsPanelId.MANAGEMENT,
          ),
        });
      },
    },
    {
      key: ActionIds.UpdateCrashRecovery,
      testId: ActionBarTestIds.UpdateCrashRecovery,
      text: crashRecoveryEnabled ? Messages.actions.disableCrashRecovery() : Messages.actions.enableCrashRecovery(),
      icon: crashRecoveryEnabled ? MonochromeIconIds.Disable : MonochromeIconIds.Enable,
      disabled: (database?.status !== ResourceStatus.Active) || autoBackup,
      onClick: () => {
        trackActionClick(ActionIds.UpdateCrashRecovery, PageId.MYSQL_DETAILS, DetailsPanelId.MANAGEMENT);
        triggerUpdateCrashRecovery({
          databaseId: mdsDbSystemId,
          isEnabled: crashRecoveryEnabled,
          location,
          onExecute: refresh,
          onCancel: () => trackActionDiscard(
            ActionIds.UpdateCrashRecovery,
            PageId.MYSQL_DETAILS,
            DetailsPanelId.MANAGEMENT,
          ),
        });
      },
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.MYSQL_DETAILS, DetailsPanelId.MANAGEMENT);
        refresh();
      },
    },
  ];

  const deletionPlanActions: ActionType[] = [
    {
      key: ActionIds.EditDeletionPlan,
      testId: ActionBarTestIds.EditDeletionPlan,
      text: Messages.actions.edit(),
      icon: MonochromeIconIds.Edit,
      disabled: database?.status !== ResourceStatus.Active,
      onClick: () => {
        trackActionClick(ActionIds.EditDeletionPlan, PageId.MYSQL_DETAILS, DetailsPanelId.MANAGEMENT);
        triggerEditDeletionPlan({
          databaseId: mdsDbSystemId,
          deletionPolicy: database?.deletionPolicy,
          targetId: ActionIds.EditDeletionPlan,
          location,
          onExecute: refresh,
          onCancel: () => trackActionDiscard(
            ActionIds.EditDeletionPlan,
            PageId.MYSQL_DETAILS,
            DetailsPanelId.MANAGEMENT,
          ),
        });
      },
    },
    {
      key: ActionIds.RefreshDeletionPlan,
      testId: ActionBarTestIds.RefreshDeletionPlan,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.RefreshDeletionPlan, PageId.MYSQL_DETAILS, DetailsPanelId.MANAGEMENT);
        refresh();
      },
    },
  ];

  const getInfoBlock = (): InfoBlockProps[] | undefined => {
    const info = { messageType: InfoBlockStatus.INFO };
    if (database?.status === ResourceStatus.Active) {
      if (!autoBackup && !crashRecoveryEnabled) {
        return [{
          testId: InfoBlockTestIds.CrashRecoveryDisabled,
          message: Messages.detailMysql.messages.backupDisabled(),
          ...info,
        }];
      }
      if (autoBackup && crashRecoveryEnabled) {
        return [{
          testId: InfoBlockTestIds.CrashRecoveryEnabled,
          message: Messages.detailMysql.messages.crashRecoverDisabled(),
          ...info,
        }];
      }
      return undefined;
    }
    return undefined;
  };

  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
      <Pivot
        style={{ height: "100%" }}
        styles={{ itemContainer: { height: "calc(100% - 44px)" } }}
        overflowBehavior="menu"
        overflowAriaLabel={Messages.ariaLabel.more()}
      >
        <PivotItem
          headerButtonProps={{ "data-test-id": PivotItemTestIds.EditBackupPlan }}
          headerText={Messages.labels.backupPlan()}
          style={{ height: "100%" }}
        >
          <ActionBar
            actions={backupActions}
            infoBlocks={getInfoBlock()}
            onActionClick={closePanels}
          />
          <MetaItemSection labelWidth={200}>
            <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
              <LabelMetaItem testId={MetaItemSectionTestIds.AUTO_BACKUP} label={Messages.labels.automaticBackups()}>
                {autoBackup ? Messages.common.enabled() : Messages.common.disabled()}
              </LabelMetaItem>
              {autoBackup && (
                <LabelMetaItem
                  testId={MetaItemSectionTestIds.RETENTION_PERIOD}
                  label={Messages.labels.retentionPeriod()}
                >
                  {autoBackup ? `${backupSchedule} ${Messages.common.days()}` : <NoValue />}
                </LabelMetaItem>
              )}
              {autoBackup && (
                <LabelMetaItem
                  testId={MetaItemSectionTestIds.AUTO_BACKUP_WINDOW}
                  label={Messages.labels.mySQLWindowStartTime()}
                >
                  {autoBackupWindow ? `${autoBackupWindow} ${utc}` : <NoValue />}
                </LabelMetaItem>
              )}
              {autoBackup && (
                <LabelMetaItem
                  testId={MetaItemSectionTestIds.PITR_POLICY}
                  label={Messages.labels.pointInTimeRestore()}
                >
                  {pitrPolicy?.isEnabled ? Messages.common.enabled() : Messages.common.disabled()}
                </LabelMetaItem>
              )}
              <LabelMetaItem
                testId={MetaItemSectionTestIds.CRASH_RECOVERY}
                label={Messages.labels.crashRecovery()}
              >
                {crashRecoveryEnabled ? Messages.common.enabled() : Messages.common.disabled()}
              </LabelMetaItem>
            </Stack>
          </MetaItemSection>
        </PivotItem>
        <PivotItem
          headerButtonProps={{ "data-test-id": PivotItemTestIds.EditDeletionPlan }}
          headerText={Messages.labels.deletionPlan()}
          style={{ height: "100%" }}
        >
          <ActionBar
            actions={deletionPlanActions}
            onActionClick={closePanels}
          />
          <MetaItemSection labelWidth={200}>
            <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
              <LabelMetaItem
                testId={MetaItemSectionTestIds.DELETE_PROTECTED}
                label={Messages.labels.mySQLDeleteProtected()}
              >
                {deleteProtected ? Messages.common.enabled() : Messages.common.disabled()}
              </LabelMetaItem>
              <LabelMetaItem
                testId={MetaItemSectionTestIds.RETAIN_ATUO_BACKUPS}
                label={Messages.labels.mySQLRetainAutomaticBackups()}
              >
                {retainAutomaticBackups ? Messages.common.enabled() : Messages.common.disabled()}
              </LabelMetaItem>
              <LabelMetaItem
                testId={MetaItemSectionTestIds.REQ_FINAL_BACKUP}
                label={Messages.labels.mySQLRequireFinalBackup()}
              >
                {requireFinalBackup ? Messages.common.enabled() : Messages.common.disabled()}
              </LabelMetaItem>
            </Stack>
          </MetaItemSection>
        </PivotItem>
      </Pivot>
    </Stack>
  );
};
