import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  optimizedRetryOption,
  Select,
  SelectProps,
  stateT,
  uniqueGUID,
} from "o4a-react";
import React from "react";
import { NormalError } from "savant-connector";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { DbHomeSummary, DbHomeSummaryCollection } from "../../gen/clients/mchub-azure-api-client";
import { IdResourceType } from "../../helpers/idHelper";
import { responseItemstoArray } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

type BaseSelectProps = Omit<SelectProps, "options" | "label"> & Required<Pick<SelectProps, "label">>;

export interface DbHomeSelectProps extends BaseSelectProps {
  location: string;
  subscriptionId: string;
  resourceGroupName: string
  vmClusterId: string;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const buildDbHomeLabel = (dbHome: DbHomeSummary): string => {
  if (dbHome.lifecycleState === "AVAILABLE" && !dbHome.dbVersion) return dbHome.name;
  return `${dbHome.name} (${dbHome.lifecycleState !== "AVAILABLE" ? stateT(dbHome.lifecycleState) : dbHome.dbVersion})`;
};

export const DbHomeSelect = ({
  location,
  subscriptionId,
  resourceGroupName,
  vmClusterId,
  onError,
  onMissingDependencies,
  fieldName,
  label,
  placeholder,
  testId,
  tooltip,
  ...selectProps
}: DbHomeSelectProps): JSX.Element => {
  const [renderKey, setRenderKey] = React.useState(uniqueGUID());

  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const form: FormState = React.useContext(FormContext);

  const { response, loading, error } = useQueryCall({
    wait: !(subscriptionId && location && resourceGroupName && vmClusterId),
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDbHomes,
    options: {
      args: {
        subscriptionId,
        resourceGroupName,
        apiVersion: MultiCloudDatabaseApiVersion,
        vmClusterId,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDatabaseHomes(),
      },
    },
  });
  const dbHomes = (response
    && responseItemstoArray<DbHomeSummaryCollection, DbHomeSummary>(response)) || [];

  const dbHomeOptions = dbHomes?.filter(
    dbHome => dbHome.lifecycleState !== "TERMINATED" && dbHome.lifecycleState !== "TERMINATING",
  )
    .map(dbHome => ({
      id: dbHome.id,
      text: buildDbHomeLabel(dbHome) || "",
      data: {
        databaseHomeName: dbHome.name,
        databaseHomeVersion: dbHome.dbVersion,
      },
      disabled: dbHome.lifecycleState !== "AVAILABLE",
    }));

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setRenderKey(uniqueGUID());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location, resourceGroupName]);

  React.useEffect(() => {
    setRenderKey(uniqueGUID());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dbHomeOptions)]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    if (!location) missingDependencies.push(IdResourceType.LOCATION);
    if (!resourceGroupName) missingDependencies.push(IdResourceType.RESOURCE_GROUPS);
    if (!vmClusterId) missingDependencies.push(IdResourceType.VM_CLUSTERS);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const derivedPlaceholder = !loading && !error && dbHomeOptions.length === 0
    ? Messages.createCommon.loadingNone(label?.toLowerCase() || "")
    : placeholder;

  return (
    <Select
      key={renderKey}
      testId={testId}
      fieldName={fieldName}
      groupName={groupName}
      loading={!!resourceGroupName && !!subscriptionId && !!location && !!vmClusterId && loading}
      label={label}
      tooltip={tooltip}
      placeholder={derivedPlaceholder}
      options={dbHomeOptions}
      onClick={internalOnClick}
      {...selectProps}
    />
  );
};
