import { BookmarkablePage, ErrorViewPanel, useNavigation } from "o4a-react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import * as Messages from "./codegen/Messages";
import { ConsoleContext } from "./console/ConsoleContext";
import { PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "./constants/pluginConstants";
import { buildId } from "./helpers/idHelper";

const registrationIds = PageRegistrationConfig[PageId.ERROR].map(config => config.key);

export interface ResourceErrorPageProps {
  title?: string;
  errorTitle?: string;
  errorDetails: string[];
}

export const ResourceErrorPage = ({
  title = Messages.resourceErrors.resourceNotFound(),
  errorTitle = Messages.resourceErrors.resourceNotFound(),
  errorDetails,
}: ResourceErrorPageProps): JSX.Element => {
  const
    {
      subscriptionId,
      resourceGroup,
      provider,
      resourceType,
      resourceName,
      panelId,
    } = useParams();

  const resourceId = resourceGroup
    ? buildId(
      {
        subscriptionId: subscriptionId || "",
        resourceGroup,
        provider: provider || "",
        resourceType: resourceType || "",
        resourceName: resourceName || "",
      },
    )
    : `/subscriptions/${subscriptionId}/providers/${provider}/${resourceType}/${resourceName}`;

  const [searchParams] = useSearchParams();

  const { back, navigateToSelf } = useNavigation(ConsoleContext);

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    const urlQueryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const path = `${RESOURCE_ROUTE}/${resourceId}/${panelId}${urlQueryParams}`;
    navigateToSelf(path, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={title}
    >
      <ErrorViewPanel
        title={title}
        errorTitle={errorTitle}
        resourceId={resourceId}
        errorCode={0}
        details={errorDetails}
        isOpen
        onClose={back}
      />
    </BookmarkablePage>
  );
};
