import { AnchoredPanel, AnchoredPanelType, Listing, ListingColumn } from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { OperationBaseProps } from "../../console/OperationsContext";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds } from "../../constants/uiConstants";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useSidePanel } from "../../hooks/useSidePanel";

export enum ActionIds {
  CopyConnectionString = "copy-connection-string",
}

export enum ListingActionTestIds {
  CopyConnectionString = "copy-connection-string",
}

export enum ColumnIds {
  ConnectionString = "connectionString",
  Type = "type",
  Id = "id",
}

export type ConnectionStringsType = { id: string, type?: string, connectionString: string }[];

export interface ConnectionStringsPanelProps extends OperationBaseProps {
  connectionStrings?: ConnectionStringsType;
  analytics: { pageId: PageId, panelId: DetailsPanelId };
  loading: boolean;
}

export const ConnectionStringsPanel = ({
  connectionStrings,
  analytics,
  loading,
  onCancel,
}: ConnectionStringsPanelProps): JSX.Element => {
  const { closePanels } = useSidePanel();
  const { trackActionClick } = useAnalytics();
  const connectionStringsHaveType = connectionStrings?.[0] && connectionStrings[0].type;

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Id,
      name: Messages.labels.tnsName(),
      flexGrow: 2,
    },
    ...(
      connectionStringsHaveType
        ? [
          {
            itemProp: ColumnIds.Type,
            name: Messages.labels.type(),
            flexGrow: 1,
          },
        ] : []
    ),
    {
      itemProp: ColumnIds.ConnectionString,
      name: Messages.labels.connectionString(),
      flexGrow: 7,
    },
  ];

  return (
    <AnchoredPanel
      type={AnchoredPanelType.CUSTOM_WIDTH}
      customWidth={700}
      title={Messages.labels.connectionStrings()}
      isOpen
      onClose={onCancel}
    >
      <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
        <Listing
          testId={ListingTestIds.ConnectionStrings}
          onActionClick={closePanels}
          items={connectionStrings || []}
          emptyList={{ title: Messages.common.noResults() }}
          listColumns={columns}
          isLoading={loading}
          actions={[
            {
              key: ActionIds.CopyConnectionString,
              testId: ListingActionTestIds.CopyConnectionString,
              text: Messages.actions.copyConnectionString(),
              icon: MonochromeIconIds.Copy,
              onClick: value => {
                trackActionClick(ActionIds.CopyConnectionString, analytics.pageId, analytics.panelId);
                navigator.clipboard.writeText(value?.connectionString || "");
              },
            },
          ]}
        />
      </Stack>
    </AnchoredPanel>
  );
};
