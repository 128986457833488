import {
  CheckBox,
  FormValues,
  InfoBlockStatus,
  InputFormCallout,
  InputFormCalloutProps,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import { Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";

export interface AdbsNetworkMtlsEditPanelProps extends
  Pick<InputFormCalloutProps, "targetId" | "onClose" | "onSubmit" | "componentRef"> {
  isMtlsConnectionRequired: boolean | undefined;
}

export enum Fields {
  MTLSAuthentication = "mTLSAuthentication",
}

export enum FieldTestIds {
  MTLSAuthentication = "mTLS-auth-field",
}

export enum InfoBlockTestIds {
  MTLSAuthentication = "mtls-auth-infoblock",
}

export const AdbsNetworkMutualAuthEditPanel = (
  { isMtlsConnectionRequired, targetId, onClose, onSubmit, componentRef }: AdbsNetworkMtlsEditPanelProps,
): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (isMtlsConnectionRequired !== undefined) {
      const initFieldValues: FormValues = { [Fields.MTLSAuthentication]: isMtlsConnectionRequired };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMtlsConnectionRequired]);

  return (
    <InputFormCallout
      testId={CalloutTestIds.AdbsEditMutualTlsAuth}
      componentRef={componentRef}
      title={Messages.createNewPanels.editNetworkingMTLS.title()}
      targetId={targetId}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={initialValues}
    >
      <Text variant="medium">
        {Messages.labels.requiretMTLSAuthentication()}
      </Text>
      <CheckBox
        testId={FieldTestIds.MTLSAuthentication}
        defaultValue={isMtlsConnectionRequired}
        fieldName={Fields.MTLSAuthentication}
        ariaLabel={Messages.labels.requiretMTLSAuthentication()}
        statusInfo={{
          testId: InfoBlockTestIds.MTLSAuthentication,
          messageType: InfoBlockStatus.INFO,
          message: Messages.hints.requiretMTLSAuthentication(),
        }}
      />
    </InputFormCallout>
  );
};
