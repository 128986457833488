import { ActionBar, ActionType, LabelMetaItem, MetaItemSection, NoValue } from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds } from "../../constants/uiConstants";
import { VmdbDatabase } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { getBackUpScheduleTimeRange } from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useSidePanel } from "../../hooks/useSidePanel";
import { newVmDbEditBackupConfig, VmDbEditBackupConfigProps } from "../../operations/VmDb/VmDbEditBackupConfig";

export enum ActionIds {
  Edit = "edit",
  Refresh = "refresh",
}

export enum ActionBarTestIds {
  Edit = "edit",
  Refresh = "refresh",
}

export enum MetaItemSectionTestIds {
  AutoBackupsEnabled = "mi-auto-backup-enabled",
  BackupSchedule = "mi-backup-schedule",
  RetentionPeriod = "mi-retention-period",
}

const pageId = PageId.VMDB_CDB_DETAILS;

export interface VmDbManagementDetailsProps {
  cdb: VmdbDatabase | undefined;
  location: string;
  refresh: () => void;
}

export const VmDbManagementDetails = (
  { location, cdb, refresh }: VmDbManagementDetailsProps,
): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { locale } = React.useContext<Settings>(SettingsContext);

  const autoBackupWindow = cdb?.dbBackupConfig?.autoBackupWindow;
  const backupSchedule = cdb?.dbBackupConfig?.recoveryWindowInDays;
  const autoBackup = cdb?.dbBackupConfig?.autoBackupEnabled;

  const { trigger: triggerEditBackupConfig } = useOperation<VmDbEditBackupConfigProps>(newVmDbEditBackupConfig);

  const { closePanels } = useSidePanel();

  const backupActions: ActionType[] = [
    {
      key: ActionIds.Edit,
      testId: ActionBarTestIds.Edit,
      text: Messages.actions.edit(),
      icon: MonochromeIconIds.Edit,
      onClick: () => {
        trackActionClick(ActionIds.Edit, pageId, DetailsPanelId.MANAGEMENT);
        triggerEditBackupConfig({
          databaseId: cdb?.id,
          backupConfig: cdb?.dbBackupConfig,
          location,
          onExecute: () => refresh(),
          onCancel: () => trackActionDiscard(ActionIds.Edit, pageId, DetailsPanelId.MANAGEMENT),
        });
      },
    },
    {
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, pageId, DetailsPanelId.MANAGEMENT);
        refresh();
      },
    },
  ];

  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
      <ActionBar actions={backupActions} onActionClick={closePanels} />
      <MetaItemSection>
        <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
          <LabelMetaItem testId={MetaItemSectionTestIds.AutoBackupsEnabled} label={Messages.labels.automaticBackups()}>
            {autoBackup ? Messages.common.enabled() : Messages.common.disabled()}
          </LabelMetaItem>
          {autoBackup && (
            <LabelMetaItem testId={MetaItemSectionTestIds.RetentionPeriod} label={Messages.labels.retentionPeriod()}>
              {autoBackup ? `${backupSchedule} ${Messages.common.days()}` : <NoValue />}
            </LabelMetaItem>
          )}
          {autoBackup && (
            <LabelMetaItem testId={MetaItemSectionTestIds.BackupSchedule} label={Messages.labels.scheduling()}>
              {autoBackup
                ? autoBackupWindow
                  ? getBackUpScheduleTimeRange(autoBackupWindow, locale) : <NoValue /> : <NoValue />}
            </LabelMetaItem>
          )}
        </Stack>
      </MetaItemSection>
    </Stack>
  );
};
