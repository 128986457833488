import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent, SelectOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ExaDbCdbBackupEditPanel, Fields } from "../../components/ExaDbCdbBackupEditPanel/ExaDbCdbBackupEditPanel";
import { DbBackupConfig, DbBackupConfigAutoBackupWindowEnum } from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXADB_CDB_UPDATE_POLL_DELAY,
  EXADB_CDB_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface ExaDbEditBackupConfigProps extends OperationProps {
  databaseId: string | undefined;
  backupConfig: DbBackupConfig | undefined;
}

const ExaDbEditBackupConfig = (
  { location, databaseId, backupConfig, onCancel, onExecute }: ExaDbEditBackupConfigProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const databaseName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.updateDatabase },
  );

  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const buildDbBackupConfig = (formValues: FormValues): DbBackupConfig => {
    const autoBackupEnabled = getValue<boolean>(formValues, Fields.Backups, InputFormGroup);
    const recoveryWindow = autoBackupEnabled
      ? getValue<SelectOption[]>(formValues, Fields.RetentionPeriod, InputFormGroup)?.[0]?.id
      : undefined;

    const autoBackupWindow = getValue<SelectOption[]>(
      formValues,
      Fields.Scheduling,
      InputFormGroup,
    )?.[0]?.id as DbBackupConfigAutoBackupWindowEnum | undefined;

    const backupDetails: DbBackupConfig = {
      autoBackupEnabled,
      autoBackupWindow: autoBackupEnabled
        ? autoBackupWindow : undefined,
      recoveryWindowInDays: recoveryWindow ? parseInt(recoveryWindow, 10) : undefined,
    };
    return backupDetails;
  };

  const onEditBackupConfig = (formValues: FormValues): void => {
    const autoBackupEnabled = getValue<boolean>(formValues, Fields.Backups, InputFormGroup);
    if (autoBackupEnabled) {
      // TODO: to replace with cross-field validation when it gets supported by the InputFormSidePanel
      const backupWindow = getValue<SelectOption[]>(formValues, Fields.Scheduling, InputFormGroup);
      const recoveryWindow = getValue<SelectOption[]>(formValues, Fields.RetentionPeriod, InputFormGroup);
      const errors: string[] = [];
      if (!backupWindow) {
        errors.push(Messages.validation.required());
      }
      if (!recoveryWindow) {
        errors.push(Messages.validation.required());
      }
      if (errors.length > 0) {
        return;
      }
    }
    const dbBackupConfig = buildDbBackupConfig(formValues);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateExaCdbBackup(),
          message: Messages.notifications.inProgress.messages.updateExaCdbBackup(databaseName),
        },
        success: {
          title: Messages.notifications.success.titles.updateExaCdbBackup(),
          message: Messages.notifications.success.messages.updateExaCdbBackup(databaseName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateExaCdbBackup(),
          message: Messages.notifications.failure.messages.updateExaCdbBackup(databaseName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXADB_CDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.EXADB_EDIT_CHECK_BACKUP,
          delay: EXADB_CDB_UPDATE_POLL_DELAY,
          interval: EXADB_CDB_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeUpdate({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName,
      updateDatabaseDetails: { dbBackupConfig },
    }, invokeOptions);
  };

  return (
    <ExaDbCdbBackupEditPanel
      componentRef={setSidePanelRef}
      cdbBackup={backupConfig}
      onSubmit={onEditBackupConfig}
      onClose={onCancel}
    />
  );
};

export const newExaDbEditBackupConfig = (
  props: ExaDbEditBackupConfigProps,
): JSX.Element => (<ExaDbEditBackupConfig {...props} />);
