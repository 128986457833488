export enum IdResourceType {
  BACKUPS = "backups",
  CLOUD_EXADATA_INFRAS = "cloudExadataInfrastructures",
  CLOUD_VM_CLUSTERS = "cloudVmClusters",
  CONTAINER_DATABASES = "containerDatabases",
  DATABASES = "databases",
  DB_HOMES = "dbHomes",
  DB_NODES = "dbNodes",
  DB_SYSTEMS = "dbSystems",
  DEPLOYMENTS = "deployments",
  INCIDENTS = "incidents",
  LOCATION = "location",
  MCVCNS = "multiCloudVirtualNetworks",
  NETWORK_LINKS = "networkLinks",
  PLUGGABLE_DATABASES = "pluggableDatabases",
  RESOURCE_GROUPS = "resourceGroups",
  SUBSCRIPTIONS = "subscriptions",
  SHAPE = "shape",
  VM_CLUSTERS = "vmClusters",
  VNETS = "virtualNetworks",
  WORK_LOAD = "workload",
}
export interface IdComponents {
  subscriptionId: string;
  resourceGroup: string;
  provider: string;
  resourceType: string;
  resourceName: string;
}

export type SupportIdComponents = Omit<IdComponents, "resourceGroup">;

const idRegEx = /\/subscriptions\/(.*)\/resourceGroups\/(.*)\/providers\/(.*)\/(.*)\/(.*)/;
export const resourceUrlRegEx = /\/subscriptions\/(.*)\/resourceGroups\/(.*)\/providers\/(.*)\/(.*)\/(.*)\/(.*)/;
const supportIdRegEx = /\/subscriptions\/(.*)\/providers\/(.*)\/(.*)\/(.*)/;
export const supportUrlRegEx = /\/subscriptions\/(.*)\/providers\/(.*)\/(.*)\/(.*)\/(.*)/;

export const parseId = (resourceId: string | undefined): IdComponents => {
  const match = resourceId?.match(idRegEx);
  return {
    subscriptionId: match?.[1],
    resourceGroup: match?.[2],
    provider: match?.[3],
    resourceType: match?.[4],
    resourceName: match?.[5],
  } as IdComponents;
};

export const parseSupportId = (resourceId: string | undefined): IdComponents => {
  const match = resourceId?.match(supportIdRegEx);

  return {
    subscriptionId: match?.[1],
    provider: match?.[2],
    resourceType: match?.[3],
    resourceName: match?.[4],
  } as IdComponents;
};

// eslint-disable-next-line max-len
export const buildId = (idComponents: IdComponents): string => `/subscriptions/${idComponents.subscriptionId}/resourceGroups/${idComponents.resourceGroup}/providers/${idComponents.provider}/${idComponents.resourceType}/${idComponents.resourceName}`;
// eslint-disable-next-line max-len
export const buildSupportId = (idComponents: SupportIdComponents): string => `/subscriptions/${idComponents.subscriptionId}/providers/${idComponents.provider}/${idComponents.resourceType}/${idComponents.resourceName}`;

export const getSubscriptionId = (id: string) : string | undefined => id?.match(/\/subscriptions\/(.*)/)?.[1];

export const buildResourceGroupId = (id: string): string | undefined => {
  const idComps = parseId(id);
  if (idComps.subscriptionId && idComps.resourceGroup) {
    return `/subscriptions/${idComps.subscriptionId}/resourceGroups/${idComps.resourceGroup}`;
  }
  return undefined;
};
