import React from "react";
import { OperationManager, OperationsContext } from "../console/OperationsContext";

export interface UseSidePanelHandles {
  closePanels: () => void;
}

export const useSidePanel = (): UseSidePanelHandles => {
  const { cancelAllOperations } = React.useContext<OperationManager>(OperationsContext);

  return { closePanels: () => cancelAllOperations() };
};
