import { CalloutComponent, ContentReaderDataType, FormValues, getValue, InputFormGroup, readFile } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  Fields as SupportFileUploadFields,
  SupportUploadFilePanel,
} from "../../components/SupportUploadFilePanel/SupportUploadFilePanel";
import { IncidentProblemTypeEnum } from "../../gen/clients/mchub-azure-api-client";
import { getBase64FromDataURL } from "../../helpers/fileHelper";
import { parseSupportId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface SupportUploadFileValues {
  title: string | undefined;
  problemType: IncidentProblemTypeEnum | undefined;
}

export interface SupportUploadFileProps extends OperationActionProps {
  incidentId: string | undefined;
  supportUploadFileValues: SupportUploadFileValues;
}

const SupportUploadFile = (
  { targetId, location, incidentId, supportUploadFileValues, onExecute, onCancel }: SupportUploadFileProps,
): JSX.Element => {
  const { subscriptionId, resourceName } = parseSupportId(incidentId);
  const resName = decodeURIComponent(resourceName || "");

  const [calloutRef, setCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const { invokeCall: invokeUpload } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).incidentsApi.putAttachment },
  );

  const onUploadSupportFile = async (formValues: FormValues): Promise<void> => {
    const file = getValue<File>(formValues, SupportFileUploadFields.File, InputFormGroup) as File;
    const isRestrictedFlag = getValue<boolean>(formValues, SupportFileUploadFields.HasPrivateInfo, InputFormGroup);

    // NOTE: OCI expects that the data type is always Base64 encoded, despite the content-type headers not being correct
    const { fileContent, fileName } = await readFile(file, ContentReaderDataType.EncodedData);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: calloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.uploadSupportRequestFile(),
          message: Messages.notifications.inProgress.messages.uploadSupportRequestFile(
            supportUploadFileValues.title || "",
          ),
        },
        success: {
          title: Messages.notifications.success.titles.uploadSupportRequestFile(),
          message: Messages.notifications.success.messages.uploadSupportRequestFile(
            supportUploadFileValues.title || "",
          ),
        },
        failure: {
          title: Messages.notifications.failure.titles.uploadSupportRequestFile(),
          message: Messages.notifications.failure.messages.uploadSupportRequestFile(
            supportUploadFileValues.title || "",
          ),
        },
      },
    };
    invokeUpload({
      subscriptionId: subscriptionId || "",
      incidentKey: resName,
      putAttachmentDetails: getBase64FromDataURL(fileContent),
      attachmentName: fileName,
      isRestrictedFlag,
      contentType: "multipart/form-data",
      problemType: supportUploadFileValues.problemType,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any, invokeOptions);
  };

  return (
    <SupportUploadFilePanel
      componentRef={setCalloutRef}
      targetId={targetId}
      onSubmit={onUploadSupportFile}
      onClose={onCancel}
      title={Messages.uploadSupportRequestFile.title()}
    />
  );
};

export const newSupportUploadFile = (
  props: SupportUploadFileProps,
): JSX.Element => (<SupportUploadFile {...props} />);
