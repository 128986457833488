import {
  CalloutComponent,
  FormValues,
  getValue,
  InputFormCallout,
  InputFormGroup,
  SelectOption,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { StorageScaleSelect } from "../../components/StorageSizeSelect/StorageScaleSelect";
import { CalloutTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_DB_SYSTEM_UPDATE_POLL_DELAY,
  VMDB_DB_SYSTEM_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export enum Fields {
  StorageSize = "storageSize",
}

export enum FieldTestIds {
  StorageSize = "storage-size-field",
}

export interface VmDbStorageConfig {
  dataStorageSizeInGBs: string | undefined;
}

export interface VmDbSystemEditStorageProps extends OperationActionProps {
  dbSystemId: string | undefined;
  storageConfig: VmDbStorageConfig;
}

const VmDbSystemEditStorage = (
  // eslint-disable-next-line max-len
  { targetId, location, dbSystemId, storageConfig, onCancel, onExecute }: VmDbSystemEditStorageProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(dbSystemId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (storageConfig && storageConfig.dataStorageSizeInGBs) {
      const initFieldValues: FormValues = { [Fields.StorageSize]: [storageConfig.dataStorageSizeInGBs] };
      setInitialValues(initFieldValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageConfig]);

  const { invokeCall: invokeUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.updateVmdbDbSystem },
  );

  const [storageCalloutRef, setStorageCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const onEditStorage = (formValues: FormValues): void => {
    const systemDataStorageSizeInGBsStr = getValue<SelectOption[]>(
      formValues,
      Fields.StorageSize,
      InputFormGroup,
    )?.[0].id;

    const systemDataStorageSizeInGBs = systemDataStorageSizeInGBsStr
      ? parseInt(systemDataStorageSizeInGBsStr, 10)
      : undefined;

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: storageCalloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateVmDbSystemStorage(),
          message: Messages.notifications.inProgress.messages.updateVmDbSystemStorage(resName),
        },
        success: {
          title: Messages.notifications.success.titles.updateVmDbSystemStorage(),
          message: Messages.notifications.success.messages.updateVmDbSystemStorage(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateVmDbSystemStorage(),
          message: Messages.notifications.failure.messages.updateVmDbSystemStorage(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_DB_SYSTEM_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            dbSystemName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_DB_SYSTEM_UPDATED_CHECK,
          delay: VMDB_DB_SYSTEM_UPDATE_POLL_DELAY,
          interval: VMDB_DB_SYSTEM_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeUpdate({
      dbSystemName: resName,
      resourceGroupName: resGroup,
      subscriptionId,
      updateDbSystemDetails: { dataStorageSizeInGBs: systemDataStorageSizeInGBs },
    }, invokeOptions);
  };

  return (
    <InputFormCallout
      testId={CalloutTestIds.VmDbSystemEditStorage}
      componentRef={setStorageCalloutRef}
      targetId={targetId}
      title={Messages.createNewPanels.editStorage.title()}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onEditStorage}
      onClose={onCancel}
      initialValues={initialValues}
    >
      <StorageScaleSelect
        required
        location={location}
        subscriptionId={subscriptionId}
        testId={FieldTestIds.StorageSize}
        label={Messages.labels.availableDataStorageGB()}
        fieldName={Fields.StorageSize}
        defaultValue={storageConfig.dataStorageSizeInGBs}
        dbSystemName={resName}
        resourceGroup={resGroup}
      />
    </InputFormCallout>
  );
};

export const newVmDbSystemEditStorage = (
  props: VmDbSystemEditStorageProps,
): JSX.Element => (<VmDbSystemEditStorage {...props} />);
