import {
  FieldSet,
  FormattedString,
  FormContext,
  FormState,
  InputWizardPanelComponent,
  IpInput,
  NetworkAddressInput,
  SelectComponent,
  TextInput,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { McvcnSelect } from "../../components/McvcnSelect/McvcnSelect";
import { VNETSelect } from "../../components/VNETSelect/VNETSelect";
import { networkCidrSizingUrl, vnetTroubleshooting } from "../../constants/docConstants";
import { AzureVirtualNetworkSummary } from "../../gen/clients/mchub-azure-api-client";
import { FutureMcvcnExisting, FutureMcvcnNew, maxNetworkLinkCidrPrefix } from "../../helpers/resourceHelper";
import {
  alphaNumericHyphenRegex,
  baseDbHostnamePrefixMaxLength,
  startWithLetterRegex,
  validateNetworkLinkAddressSpace,
} from "../../helpers/validationHelper";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import { McvcnCreateNewProps, newMcvcnCreateNew } from "../../operations/Mcvcn/McvcnCreateNew";

export const GROUP_NETWORK = "network";

export enum LinkTestIds {
  CreateVcnLink= "mcvcnCreateNewLink",
}

export enum FieldTestIds {
  Cidrs= "ociNetworkCidrs",
  Vnet= "azureAttachedNetworkIds",
  Hostname= "hostname",
  Mcvcn= "id",
  NetworkVirtualAppliance= "customerNvaIpAddress",
}

export enum Fields {
  Cidrs = "networkLinkDetails.ociNetworkCidrs",
  Vnet = "networkLinkDetails.azureAttachedNetworkIds",
  Hostname = "vmdbDbSystemDeploymentDetails.hostname",
  Mcvcn = "multiCloudVirtualNetworkDetails.id",
  NetworkVirtualAppliance = "networkLinkDetails.customerNvaIpAddress",
}

export interface NetworkTabContentProps {
  inputWizardPanelRef: InputWizardPanelComponent;
  subscriptionId: string;
  location: string;
}

export const NetworkTabContent = ({
  inputWizardPanelRef,
  subscriptionId,
  location,
}: NetworkTabContentProps): JSX.Element => {
  const { enableMcvcn, enableVmdbMcvcnCreate } = useFeatures();
  const [selectRef, setSelectRef] = React.useState<SelectComponent>();
  const [selectedVnet, setSelectedVnet] = React.useState<AzureVirtualNetworkSummary | undefined>(undefined);
  const [renderKey, setRenderKey] = React.useState<string>(uniqueGUID());
  const form: FormState = React.useContext(FormContext);

  const onError = (): void => inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.general());
  const onMissingDependencies = (): void => {
    inputWizardPanelRef.showErrorDialog(
      Messages.createCommon.dependencyWarnings.basicsIncomplete.message(),
      Messages.createCommon.dependencyWarnings.basicsIncomplete.title(),
    );
  };

  const onChangeVnet = (_: string, vnet: AzureVirtualNetworkSummary | undefined): void => {
    form.setValue(undefined, Fields.Cidrs, GROUP_NETWORK);
    setSelectedVnet(vnet);
    setRenderKey(uniqueGUID());
  };

  const { trigger: triggerMcvcnCreateNew } = useOperation<McvcnCreateNewProps>(newMcvcnCreateNew);

  const onLinkClick = (): void => {
    if (!subscriptionId || !location) {
      onMissingDependencies();
      return;
    }

    triggerMcvcnCreateNew({
      location,
      subscriptionId,
      onExecute: (futureMcvcn: FutureMcvcnExisting | FutureMcvcnNew): void => {
        if (selectRef) {
          // Here is where the consuming form can format the values returned by the panel
          // Just alter the data passed to the setNewOption
          selectRef.setOptionNew(futureMcvcn.name, futureMcvcn);
        }
      },
    });
  };

  const createVcnLink = {
    id: "create_new_vcn",
    text: Messages.actions.createNew(),
    testId: LinkTestIds.CreateVcnLink,
    onLinkClick,
  };

  return (
    <>
      <FieldSet header={Messages.createVmDb.networkingTab.sectionTitles.databaseSystemNetworking()}>
        <TextInput
          required
          fieldName={Fields.Hostname}
          testId={FieldTestIds.Hostname}
          label={Messages.labels.hostName()}
          validator={(value: string | undefined) => {
            const errors: string[] = [];
            if (value && !startWithLetterRegex.test(value)) {
              errors.push(Messages.validation.nameStartChar());
            }
            if (value && !alphaNumericHyphenRegex.test(value)) {
              errors.push(Messages.validation.valueAlphaNumericHyphen());
            }
            if (value && value.length > baseDbHostnamePrefixMaxLength) {
              errors.push(Messages.validation.valueMaxLen(baseDbHostnamePrefixMaxLength.toString()));
            }
            return errors.length ? errors : undefined;
          }}
        />
      </FieldSet>
      <FieldSet header={Messages.createVmDb.networkingTab.sectionTitles.networkPeering()}>
        {!(enableMcvcn && enableVmdbMcvcnCreate) && (
          <>
            <VNETSelect
              required
              fieldName={Fields.Vnet}
              testId={FieldTestIds.Vnet}
              label={Messages.labels.virtualNetwork()}
              tooltip={
                FormattedString(
                  { inputText: Messages.hints.tooltipVNET(vnetTroubleshooting) },
                ) as unknown as string
              }
              subscriptionId={subscriptionId}
              location={location}
              onChange={onChangeVnet}
              onError={onError}
              onMissingDependencies={onMissingDependencies}
            />
            <IpInput
              fieldName={Fields.NetworkVirtualAppliance}
              testId={FieldTestIds.NetworkVirtualAppliance}
              label={Messages.labels.networkVirtualAppliance()}
              tooltip={Messages.hints.tooltipNetworkVirtualAppliance()}
              validation={{
                containsIpAddress: true,
                containsCidrBlock: false,
              }}
            />
            <NetworkAddressInput
              required
              key={renderKey}
              testId={FieldTestIds.Cidrs}
              fieldName={Fields.Cidrs}
              groupName={GROUP_NETWORK}
              label={Messages.labels.ociCidr()}
              tooltip={FormattedString(
                { inputText: Messages.hints.toolTipCidr(networkCidrSizingUrl) },
              ) as unknown as string}
              validator={validateNetworkLinkAddressSpace(maxNetworkLinkCidrPrefix, selectedVnet?.addressSpaces)}
            />
          </>
        )}
        {enableMcvcn && enableVmdbMcvcnCreate && (
          <McvcnSelect
            required
            label={Messages.labels.virtualCloudNetwork()}
            componentRef={setSelectRef}
            location={location}
            testId={FieldTestIds.Mcvcn}
            fieldName={Fields.Mcvcn}
            groupName={GROUP_NETWORK}
            subscriptionId={subscriptionId}
            inputLink={createVcnLink}
            onError={onError}
            onMissingDependencies={onMissingDependencies}
          />
        )}
      </FieldSet>
    </>
  );
};
