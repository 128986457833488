import {
  FieldSet,
  FormattedString,
  FormContext,
  FormState,
  InputWizardPanelComponent,
  Select,
  SelectOption,
  TextInput,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { RegionSelect } from "../../components/RegionSelect/RegionSelect";
import { SubscriptionSelect } from "../../components/SubscriptionSelect/SubscriptionSelect";
import {
  SupportIssueTypeSelect,
  ValidateUserData,
} from "../../components/SupportIssueTypeSelect/SupportIssueTypeSelect";
import { ServiceProviders } from "../../constants/pluginConstants";
import {
  CreateIncidentDetailsProblemTypeEnum,
  ServiceCategoryValues,
  ValidationResponse,
} from "../../gen/clients/mchub-azure-api-client-platform";
import { SupportIssueType, ValidateUserErrorCode } from "../../helpers/resourceHelper";
import { validateSupportSummary } from "../../helpers/validationHelper";
import { useFeatures } from "../../hooks/useFeatures";

export const GROUP_PROBLEM = "problem";

export const enum Fields {
  Subscription = "subscription",
  IssueType = "issueType",
  BillingSummary = "billingSummary",
  TechnicalSummary = "technicalSummary",
  QuotaType = "quotaType",
  ServiceType = "serviceType",
  Region = "region"
}

export enum FieldTestIds {
  Subscription = "subscription",
  IssueType = "issue-type",
  BillingSummary = "billing-summary",
  TechnicalSummary = "billing-summary",
  QuotaType = "quota-type",
  ServiceType = "service-type",
  Region = "region",
}

export enum QuotaOptionTestIds {
  QuotaExaData = "quota-exa-data",
  QuotaVmdb = "quota-vmdb",
  QuotaAdbs = "quota-adbs",
  QuotaMds = "quota-mds",
}

export enum TechnicalOptionTestIds {
  ProviderOptionsTechnicalNetwork = "provider-options-technical-network",
  ProviderOptionsTechnicalExaDataDb = "provider-options-technical-exa-data-db",
  ProviderOptionsTechnicalAdbShared = "provider-options-technical-adb-shared",
  ProviderOptionsTechnicalVmdb = "provider-options-technical-vmdb",
  ProviderOptionsTechnicalMysqlHeatwave = "provider-options-technical-mysql-heatwave",
  ProviderOptionsTechnicalMultiCloudLink = "provider-options-technical-multi-cloud-link",
}

export interface ProblemTabContentProps {
  inputWizardPanelRef: InputWizardPanelComponent;
}

export const ProblemTabContent = ({ inputWizardPanelRef }: ProblemTabContentProps): JSX.Element => {
  const { enableMds } = useFeatures();
  const form = React.useContext<FormState>(FormContext);

  const [subscriptionId, setSubscriptionId] = React.useState<string>();
  const [issueType, setIssueType] = React.useState<CreateIncidentDetailsProblemTypeEnum>();
  const [isValidIssueType, setIsValidIssueType] = React.useState<boolean>(false);

  const noEmail = FormattedString({ inputText: Messages.createSupport.problemTab.validationMessages.noEmail() });
  const emailNotVerified = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.emailNotVerified() },
  );
  const noCsiAccount = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noCsiAccount() },
  );
  const noCsiRequest = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noCsiRequest() },
  );
  const noCsiRequestStep1 = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noCsiRequestStep1() },
  );
  const noCsiRequestStep2 = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noCsiRequestStep2() },
  );
  const csiRequestPending = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.csiRequestPending() },
  );
  const noMosAccount1 = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noMosAccount1() },
  );
  const noMosAccount1Step1 = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noMosAccount1Step1() },
  );
  const noMosAccount1Step2 = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noMosAccount1Step2() },
  );
  const noMosAccount1Step3 = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noMosAccount1Step3() },
  );
  const noMosAccount2 = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noMosAccount2() },
  );
  const noMosAccount2Step1 = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noMosAccount2Step1() },
  );
  const noMosAccount2Step2 = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noMosAccount2Step2() },
  );
  const noMosAccount2Step3 = FormattedString(
    { inputText: Messages.createSupport.problemTab.validationMessages.noMosAccount2Step3() },
  );

  const providerOptionsQuota = [
    {
      id: ServiceProviders.EXADATA,
      text: Messages.serviceProviders.exadataDedicated(),
      testId: QuotaOptionTestIds.QuotaExaData,
    },
    {
      id: ServiceProviders.VMDB,
      text: Messages.serviceProviders.vmDatabase(),
      testId: QuotaOptionTestIds.QuotaVmdb,
    },
    {
      id: ServiceProviders.ADBS,
      text: Messages.serviceProviders.adbShared(),
      testId: QuotaOptionTestIds.QuotaAdbs,
    },
    ...(enableMds ? [{
      id: ServiceProviders.MDS,
      text: Messages.serviceProviders.mySqlHeatwave(),
      testId: QuotaOptionTestIds.QuotaMds,
    }] : []),
  ];

  const providerOptionsTechnical: SelectOption[] = [
    {
      id: ServiceCategoryValues.NETWORK,
      text: Messages.serviceProviders.network(),
      testId: TechnicalOptionTestIds.ProviderOptionsTechnicalNetwork,
    },
    {
      id: ServiceCategoryValues.EXADATADB,
      text: Messages.serviceProviders.exadataDedicated(),
      testId: TechnicalOptionTestIds.ProviderOptionsTechnicalExaDataDb,
    },
    {
      id: ServiceCategoryValues.VMDB,
      text: Messages.serviceProviders.vmDatabase(),
      testId: TechnicalOptionTestIds.ProviderOptionsTechnicalVmdb,
    },
    {
      id: ServiceCategoryValues.AUTONOMOUSDB_SERVERLESS,
      text: Messages.serviceProviders.adbShared(),
      testId: TechnicalOptionTestIds.ProviderOptionsTechnicalAdbShared,
    },
    ...(enableMds ? [{
      id: ServiceCategoryValues.MDS,
      text: Messages.serviceProviders.mySqlHeatwave(),
      testId: TechnicalOptionTestIds.ProviderOptionsTechnicalMysqlHeatwave,
    }] : []),
    {
      id: ServiceCategoryValues.MULTICLOUDLINK,
      text: Messages.serviceProviders.cloudlink(),
      testId: TechnicalOptionTestIds.ProviderOptionsTechnicalMultiCloudLink,
    },
  ];

  const onSubscriptionIdChange = (id: string): void => {
    setSubscriptionId(id);
  };

  const onIssueTypeChange = (id: string, data: ValidateUserData): void => {
    setIssueType(id as CreateIncidentDetailsProblemTypeEnum);
    resetFields();

    if (validateUserFailed(data) && data?.response) {
      showValidateUserError(data.response);
      setIsValidIssueType(false);
    } else {
      inputWizardPanelRef.clearError();
      setIsValidIssueType(true);
    }
  };

  const resetFields = (): void => {
    form.setValue(undefined, Fields.BillingSummary, GROUP_PROBLEM);
    form.setValue(undefined, Fields.TechnicalSummary, GROUP_PROBLEM);
    form.setValue([], Fields.QuotaType, GROUP_PROBLEM);
    form.setValue([], Fields.ServiceType, GROUP_PROBLEM);
  };

  const validateUserFailed = (validateUserData: ValidateUserData): boolean => {
    // If validateUser api call failed then need to interpret as success to not block
    // Only consider failed if api call succeeded and error code returned is not ok
    if (validateUserData
      && !validateUserData.error
      && validateUserData.response
      && validateUserData.response.code !== ValidateUserErrorCode.Ok) {
      return true;
    }
    return false;
  };

  const showValidateUserError = (validateUserInfo: ValidationResponse): void => {
    switch (validateUserInfo?.code) {
      case ValidateUserErrorCode.EmailNotFound:
        inputWizardPanelRef.showError(
          Messages.createSupport.problemTab.validationMessages.title(),
          noEmail,
        );
        break;

      case ValidateUserErrorCode.EmailNotVerified:
        inputWizardPanelRef.showError(
          Messages.createSupport.problemTab.validationMessages.title(),
          emailNotVerified,
        );
        break;

      case ValidateUserErrorCode.CsiNotFound:
        inputWizardPanelRef.showError(
          Messages.createSupport.problemTab.validationMessages.title(),
          noCsiAccount,
        );
        break;

      case ValidateUserErrorCode.MosAccountNotFound:
        inputWizardPanelRef.showError(
          Messages.createSupport.problemTab.validationMessages.title(),
          (
            <>
              {noMosAccount1}
              <ol>
                <li>{noMosAccount1Step1}</li>
                <li>{noMosAccount1Step2}</li>
                <li>{noMosAccount1Step3}</li>
              </ol>
              <p />
              {noMosAccount2}
              <ol>
                <li>{noMosAccount2Step1}</li>
                <li>{noMosAccount2Step2}</li>
                <li>{noMosAccount2Step3}</li>
              </ol>
            </>
          ),
        );
        break;

      case ValidateUserErrorCode.CsiRequestNotFound:
        inputWizardPanelRef.showError(
          Messages.createSupport.problemTab.validationMessages.title(),
          (
            <>
              {noCsiRequest}
              <ol>
                <li>{noCsiRequestStep1}</li>
                <li>{noCsiRequestStep2}</li>
              </ol>
            </>
          ),
        );
        break;

      case ValidateUserErrorCode.CsiRequestPending:
        inputWizardPanelRef.showError(
          Messages.createSupport.problemTab.validationMessages.title(),
          csiRequestPending,
        );
        break;

      default:
        inputWizardPanelRef.showError(
          validateUserInfo?.message || Messages.createSupport.problemTab.validationMessages.title(),
          validateUserInfo?.action ? (
            <span>
              {validateUserInfo?.action}
            </span>
          ) : undefined,
        );
        break;
    }
  };

  return (
    <FieldSet
      header={Messages.createSupport.problemTab.sectionTitles.tellUs()}
      description={Messages.createSupport.problemTab.sectionDescriptions.tellUs()}
    >
      <SubscriptionSelect
        required
        fieldName={Fields.Subscription}
        testId={FieldTestIds.Subscription}
        label={Messages.labels.subscription()}
        onChange={onSubscriptionIdChange}
        tooltip={Messages.hints.toolTipSubscription()}
        defaultValue={subscriptionId ? [subscriptionId] : undefined}
      />
      <SupportIssueTypeSelect
        subscriptionId={subscriptionId || ""}
        fieldName={Fields.IssueType}
        testId={FieldTestIds.IssueType}
        label={Messages.labels.issueType()}
        required
        onChange={onIssueTypeChange}
        validator={(value: SelectOption[] | undefined) => {
          if (value && validateUserFailed(value[0]?.data)) {
            return [Messages.createSupport.problemTab.validationMessages.issueTypePrereqs()];
          }
          return undefined;
        }}
      />
      {issueType === SupportIssueType.QUOTAS && isValidIssueType && (
        <Select
          fieldName={Fields.QuotaType}
          testId={FieldTestIds.QuotaType}
          label={Messages.labels.quotaType()}
          required
          options={providerOptionsQuota}
          hideSearchBox
        />
      )}
      {issueType === SupportIssueType.TECHNICAL && isValidIssueType && (
        <>
          <Select
            fieldName={Fields.ServiceType}
            label={Messages.labels.serviceType()}
            testId={FieldTestIds.ServiceType}
            required
            options={providerOptionsTechnical}
            hideSearchBox
          />
          <RegionSelect
            required
            subscriptionId={subscriptionId || ""}
            fieldName={Fields.Region}
            testId={FieldTestIds.Region}
            label={Messages.labels.region()}
            tooltip={Messages.hints.toolTipRegion()}
          />
          <TextInput
            fieldName={Fields.TechnicalSummary}
            testId={FieldTestIds.TechnicalSummary}
            label={Messages.labels.summary()}
            required
            validator={validateSupportSummary}
          />
        </>
      )}
      {issueType === SupportIssueType.BILLING && isValidIssueType && (
        <TextInput
          fieldName={Fields.BillingSummary}
          testId={FieldTestIds.BillingSummary}
          label={Messages.labels.summary()}
          required
          validator={validateSupportSummary}
        />
      )}
    </FieldSet>
  );
};
