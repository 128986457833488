import { Select, SelectOption, SelectProps } from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ValidationResponse } from "../../gen/clients/mchub-azure-api-client-platform";
import { SupportIssueType } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { getOciRegion } from "../../utils";

export interface ValidateUserData {
  error: NormalError | undefined;
  response: ValidationResponse | undefined;
}

export enum IssueTypeOptionsTestIds {
  Billing = "billing",
  Quotas = "quotas",
  Technical = "technical",
}

export interface SupportIssueTypeProps extends Omit<SelectProps, "options" | "onChange"> {
  subscriptionId: string;
  onChange?: (id: string, data: ValidateUserData) => void;
}

export const SupportIssueTypeSelect = ({
  fieldName,
  label,
  testId,
  required,
  subscriptionId,
  validator,
  onChange,
  disabled,
}: SupportIssueTypeProps): JSX.Element => {
  const { loading: subscriptionsLoading, subscriptions } = useSubscriptions();
  const subscriptionLocation = subscriptions?.find(sub => sub.id === subscriptionId)?.primaryLocation;

  const {
    response: validateBillingUserResponse,
    loading: validateBillingUserLoading,
    error: validateBillingUserError,
  } = useQueryCall({
    wait: subscriptionsLoading || !(subscriptionId && subscriptionLocation),
    method: apiClients.withRegion(getOciRegion(subscriptionLocation || "")).incidentsApi.validateUser,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        problemType: SupportIssueType.BILLING,
      },
    },
  });
  const {
    response: validateQuotaUserResponse,
    loading: validateQuotaUserLoading,
    error: validateQuotaUserError,
  } = useQueryCall({
    wait: subscriptionsLoading || !(subscriptionId && subscriptionLocation),
    method: apiClients.withRegion(getOciRegion(subscriptionLocation || "")).incidentsApi.validateUser,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        problemType: SupportIssueType.QUOTAS,
      },
    },
  });
  const {
    response: validateTechnicalUserResponse,
    loading: validateTechnicalUserLoading,
    error: validateTechnicalUserError,
  } = useQueryCall({
    wait: subscriptionsLoading || !(subscriptionId && subscriptionLocation),
    method: apiClients.withRegion(getOciRegion(subscriptionLocation || "")).incidentsApi.validateUser,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        problemType: SupportIssueType.TECHNICAL,
      },
    },
  });

  const validateBillingUserInfo = validateBillingUserResponse && validateBillingUserResponse.data;
  const validateQuotaUserInfo = validateQuotaUserResponse && validateQuotaUserResponse.data;
  const validateTechnicalUserInfo = validateTechnicalUserResponse && validateTechnicalUserResponse.data;

  const issueOptions: SelectOption[] = React.useMemo(() => {
    if (!validateBillingUserLoading && !validateQuotaUserLoading && !validateTechnicalUserLoading) {
      return [
        {
          id: SupportIssueType.BILLING,
          text: Messages.supportIssueTypes.billing(),
          testId: IssueTypeOptionsTestIds.Billing,
          data: {
            error: validateBillingUserError,
            response: validateBillingUserInfo,
          } as ValidateUserData,
        },
        {
          id: SupportIssueType.QUOTAS,
          text: Messages.supportIssueTypes.quotas(),
          testId: IssueTypeOptionsTestIds.Quotas,
          data: {
            error: validateQuotaUserError,
            response: validateQuotaUserInfo,
          } as ValidateUserData,
        },
        {
          id: SupportIssueType.TECHNICAL,
          text: Messages.supportIssueTypes.technical(),
          testId: IssueTypeOptionsTestIds.Technical,
          data: {
            error: validateTechnicalUserError,
            response: validateTechnicalUserInfo,
          } as ValidateUserData,
        },
      ];
    }
    return [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateBillingUserLoading, validateQuotaUserLoading, validateTechnicalUserLoading]);

  const internalOnChange = (id: string): void => {
    onChange?.(id, issueOptions.find(issue => issue.id === id)?.data);
  };

  return (
    <Select
      fieldName={fieldName}
      label={label}
      testId={testId}
      required={required}
      options={issueOptions}
      onChange={internalOnChange}
      disabled={disabled}
      validator={validator}
      hideSearchBox
      loading={!!subscriptionId
        && (validateBillingUserLoading || validateQuotaUserLoading || validateTechnicalUserLoading)}
    />
  );
};
