import {
  ActionType,
  ConfirmAction,
  CustomAction,
  EmptyListingButtonTypes,
  ExtraFilter,
  FavoriteAccessItem,
  FilterAll,
  FilterComponent,
  getResourceLifecycleStatus,
  InfoBlockProps,
  InfoBlockStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingDisplayNameLink,
  ListingSelectOption,
  Operator,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
  useFavoriteAccess,
  useNavigation,
  ViewSelectOption,
} from "o4a-react";
import * as React from "react";
import { useParams } from "react-router-dom";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  DetailsPanelId,
  EXADB_CREATE_ROUTE,
  EXADB_FULL_CREATE_ROUTE,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
} from "../../constants/pluginConstants";
import {
  emptyChar,
  ListingTestIds,
  MonochromeIconIds,
  SvgIconIds,
  ttlOneMinCaching,
  ViewKeys,
} from "../../constants/uiConstants";
import { AzureSubscriptionSummary } from "../../gen/clients/mchub-azure-api-client";
import {
  DatabaseSummary,
  DatabaseSummaryCollection,
  DatabaseSummaryLifecycleStateEnum,
  DatabaseSummaryStatusEnum,
  PluggableDatabaseOpenModeEnum,
  PluggableDatabaseSummary,
  PluggableDatabaseSummaryCollection,
  PluggableDatabaseSummaryLifecycleStateEnum,
  PluggableDatabaseSummaryStatusEnum,
} from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import {
  getFieldCustomOptions,
  getStatusInfo,
  PdbOpenMode,
  ResourceStatus,
  responseItemstoArray,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFilterStatePills } from "../../hooks/useFilterStatePills";
import { useLocationFilters } from "../../hooks/useLocationFilters";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { ExaDbDeleteCdbProps, newExaDbDeleteCdb } from "../../operations/ExaDb/ExaDbDeleteCdb";
import { ExaDbDeletePdbProps, newExaDbDeletePdb } from "../../operations/ExaDb/ExaDbDeletePdb";
import { ExaDbStartPdbProps, newExaDbStartPdb } from "../../operations/ExaDb/ExaDbStartPdb";
import { ExaDbStopPdbProps, newExaDbStopPdb } from "../../operations/ExaDb/ExaDbStopPdb";
import {
  ExaDbViewConnectionStringsProps,
  newExaDbViewConnectionStrings,
} from "../../operations/ExaDb/ExaDbViewConnectionStrings";
import { getAzureLocationName, getOciRegion } from "../../utils";

const cdbDetailsPageRegistrationIds = PageRegistrationConfig[PageId.EXADB_CDB_DETAILS].map(config => config.key);
const pdbDetailsPageRegistrationIds = PageRegistrationConfig[PageId.EXADB_PDB_DETAILS].map(config => config.key);

const getCdbLink = (value: ExadataItem): JSX.Element => (
  <ListingDisplayNameLink
    displayName={value.containerDBName || ""}
    navigation={{
      // eslint-disable-next-line max-len
      to: `${RESOURCE_ROUTE}/${value.containerDBId}/${PageRegistrationConfig[PageId.EXADB_CDB_DETAILS][0].panelPath}?location=${value.location}`,
      pageKey: cdbDetailsPageRegistrationIds[0],
    }}
  />
);

export enum ColumnIds {
  DisplayName = "displayName",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  LifecycleStatus = "status",
  ContainerDB = "containerDBName",
  FreeformTags = "freeformTags",
}

export enum ColumnTestIds {
  DisplayName = "displayName",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  LifecycleStatus = "status",
  ContainerDB = "containerDBName",
  FreeformTags = "freeformTags",
}

export enum ActionBarIds {
  Create = "action-bar-create",
  CreateDB = "action-bar-create-db",
  CreateSystem = "action-bar-create-system",
  Start = "action-bar-start",
  Stop = "action-bar-stop",
  ConnectionStrings = "action-bar-connection-strings",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
}

export enum ActionMenuIds {
  Start = "action-menu-start",
  Stop = "action-menu-stop",
  ConnectionStrings = "action-menu-connection-strings",
  Delete = "action-menu-delete",
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum ActionBarTestIds {
  Create = "action-bar-create",
  CreateDB = "action-bar-create-db",
  CreateSystem = "action-bar-create-system",
  Start = "action-bar-start",
  Stop = "action-bar-stop",
  ConnectionStrings = "action-bar-connection-strings",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
}

export enum ActionMenuTestIds {
  Start = "action-menu-start",
  Stop = "action-menu-stop",
  ConnectionStrings = "action-menu-connection-strings",
  Delete = "action-menu-delete",
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum FilterTestIds {
  Subscription = "Subscription",
  Location = "Location"
}

export enum InfoBlockTestIds {
  DisabledActions = "disabled-actions-info-block"
}

interface AzureSubscriptionSummaryMap {
  [key: string]: AzureSubscriptionSummary;
}

export interface ExadataItem {
  id: string;
  displayName: string;
  subscriptionId: string;
  subscriptionName: string;
  resourceGroupName: string;
  location: string;
  containerDBName?: string;
  containerDBId?: string;
  isPdb: boolean;
  lifecycleState: PluggableDatabaseSummaryLifecycleStateEnum | DatabaseSummaryLifecycleStateEnum;
  status: PluggableDatabaseSummaryStatusEnum | DatabaseSummaryStatusEnum;
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
  lifecycleDetails?: string;
  freeformTags?: { [key: string]: string; };
  openMode?: PluggableDatabaseOpenModeEnum;
  isFavorite: boolean;
}

export interface ExaDbListProps {
  disabled?: boolean;
}

export const ExaDbList = ({ disabled }: ExaDbListProps): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { favoriteItems, isFavorite, setAsFavorite, removeFromFavorites } = useFavoriteAccess();

  const { navigateTo } = useNavigation(ConsoleContext);
  // eslint-disable-next-line max-len
  const { preferredSubscription, locale } = React.useContext<Settings>(SettingsContext);
  const filterState = React.useContext<FilterState>(FilterStateContext);
  const { listId } = useParams();

  const [selection, setSelection] = React.useState<ExadataItem[]>([]);
  const [selectedSubscription, setSelectedSubscription] = React.useState<string>("");
  const [subscriptionOptions, setSubscriptionOptions] = React.useState<{ key: string, text: string }[]>([]);

  const { locationOptions, selectedLocation, setSelectedLocation } = useLocationFilters(
    filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
  );

  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();

  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<ExadataItem>>();

  const [filterComponentRef, setFilterComponentRef] = React.useState<FilterComponent>();
  const pillFilterValues = filterComponentRef?.getFilters();
  const filterTextValue = filterComponentRef?.getFilterText();
  const [filterText, setFilterText] = React.useState(filterState.filterTextValue || filterTextValue);

  const getLocation = (value: ExadataItem): string => getAzureLocationName(value.location);

  const { perPageItemsCount } = useRowCount();

  const getStatus = (value: ExadataItem): JSX.Element => (
    <div style={{ fontSize: "14px !important" }}>
      <Status
        label={stateT(value?.status || "")}
        tooltip={stateT(value?.status || "")}
        status={getResourceLifecycleStatus(value?.status || "")}
        statusInfo={
          getStatusInfo(
            value.status,
            value.lastOperationStatus || "",
            value.lastOperationStatusDetails || "",
            value.lifecycleDetails,
          )
        }
        hideClipboardCopy
      />
    </div>
  );

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.DisplayName,
      testId: ColumnTestIds.DisplayName,
      name: Messages.common.name(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => {
        const isCdb = !value.isPdb;
        // eslint-disable-next-line max-len
        const cdbNavigation = `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.EXADB_CDB_DETAILS][0].panelPath}?location=${value.location}`;
        // eslint-disable-next-line max-len
        const pdbNavigation = `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.EXADB_PDB_DETAILS][0].panelPath}?location=${value.location}`;
        return (
          <ListingDisplayNameLink
            displayName={value.displayName}
            navigation={{
              to: isCdb ? cdbNavigation : pdbNavigation,
              pageKey: isCdb ? cdbDetailsPageRegistrationIds[0] : pdbDetailsPageRegistrationIds[0],
            }}
            iconName={SvgIconIds.exadataSvg}
          />
        );
      },
    },
    {
      itemProp: ColumnIds.SubscriptionName,
      testId: ColumnTestIds.SubscriptionName,
      name: Messages.labels.subscription(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, max-len
      onRenderItems: value => <AzureSubscriptionLink resourceId={value.id} subscriptionName={value.subscriptionName} hideClipboard />,
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.LifecycleStatus,
      testId: ColumnTestIds.LifecycleStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: ExadataItem,
        b: ExadataItem,
      ) => statusSortComparator<ExadataItem>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.ContainerDB,
      testId: ColumnTestIds.ContainerDB,
      name: Messages.labels.containerDb(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getCdbLink(value),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectedItems = (selectedItems: any[]): void => {
    setSelection(selectedItems);
  };

  const { refresh: cdbRefresh, loading: cdbLoading, response: cdbResponse } = useQueryCall({
    wait: (!selectedSubscription || !selectedLocation) || disabled,
    method: apiClients.withRegion(
      getOciRegion(selectedLocation),
    ).exaDatabaseApi.listDatabasesBySubscription,
    options: {
      args: {
        subscriptionId: selectedSubscription,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadExaDbCdbs(),
      },
    },
  });

  const cdbs = cdbResponse
    && responseItemstoArray<DatabaseSummaryCollection, DatabaseSummary>(cdbResponse);

  const { refresh: pdbRefresh, loading: pdbLoading, response: pdbResponse } = useQueryCall({
    wait: (!selectedSubscription || !selectedLocation) || disabled,
    method: apiClients.withRegion(
      getOciRegion(selectedLocation),
    ).exaDatabaseApi.listPluggableDatabasesBySubscription,
    options: {
      args: {
        subscriptionId: selectedSubscription,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadExaDbPdbs(),
      },
    },
  });

  const pdbs = pdbResponse
    && responseItemstoArray<PluggableDatabaseSummaryCollection, PluggableDatabaseSummary>(pdbResponse);

  const groupingOptions: ListingSelectOption<ExadataItem>[] = [];

  const viewOptions: ViewSelectOption<ExadataItem>[] = [
    {
      key: ViewKeys.ListView,
      text: Messages.listExaDb.filters.listView(),
      icon: MonochromeIconIds.List,
    },
  ];

  const exadataSummaryToExadataItem = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any[],
    subscriptionsMap: AzureSubscriptionSummaryMap,
  ): ExadataItem[] => {
    const exadataItem: ExadataItem[] = [];
    items.forEach(item => {
      const { subscriptionId, resourceGroup } = parseId(item.id);
      const itemRgName = resourceGroup;
      const itemSubscriptionName = subscriptionsMap && subscriptionsMap[subscriptionId]?.name;
      if (item.lifecycleState !== "TERMINATED") {
        if (item.containerDatabaseId) /* is pdb */ {
          const { resourceName: cdbName } = parseId(item.containerDatabaseId);
          exadataItem.push({
            id: item.id,
            displayName: item.name,
            subscriptionId,
            subscriptionName: itemSubscriptionName,
            resourceGroupName: itemRgName,
            location: item.location,
            containerDBName: cdbName,
            containerDBId: item.containerDatabaseId,
            isPdb: true,
            lifecycleState: item.lifecycleState,
            status: item.status,
            lastOperationStatus: item.lastOperationStatus,
            lastOperationStatusDetails: item.lastOperationStatusDetails,
            lifecycleDetails: item.lifecycleDetails,
            freeformTags: item.freeformTags,
            openMode: item.openMode,
            isFavorite: isFavorite(item.id),
          });
        } else {
          exadataItem.push({
            id: item.id,
            displayName: item.name,
            subscriptionId,
            subscriptionName: itemSubscriptionName,
            resourceGroupName: itemRgName,
            location: item.location,
            isPdb: false,
            lifecycleState: item.lifecycleState,
            status: item.status,
            lastOperationStatus: item.lastOperationStatus,
            lastOperationStatusDetails: item.lastOperationStatusDetails,
            lifecycleDetails: item.lifecycleDetails,
            freeformTags: item.freeformTags,
            isFavorite: isFavorite(item.id),
          });
        }
      }
    });
    return exadataItem;
  };

  const items = React.useMemo(() => {
    let newInstance: ExadataItem[] = [];

    if (subscriptions && subscriptions.length > 0) {
      const subscriptionsMap = subscriptions.reduce((
        map: AzureSubscriptionSummaryMap,
        subscription: AzureSubscriptionSummary,
      ) => {
        map[subscription.id] = subscription;
        return map;
      }, {});
      if (cdbs) newInstance = newInstance.concat(exadataSummaryToExadataItem(cdbs, subscriptionsMap));
      if (pdbs) newInstance = newInstance.concat(exadataSummaryToExadataItem(pdbs, subscriptionsMap));
    }
    return newInstance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pdbs), JSON.stringify(cdbs), JSON.stringify(subscriptions), JSON.stringify(favoriteItems)]);

  const onRefresh = (): void => {
    cdbRefresh();
    pdbRefresh();
  };

  const containerDb = (selectedItem?: ExadataItem):
  ExadataItem | undefined => (items.find(item => item.id === selectedItem?.containerDBId));
  const containerDbStatus = (selectedItem: ExadataItem) : string | undefined => {
    const cdb = containerDb(selectedItem);
    return cdb?.status || undefined;
  };

  // eslint-disable-next-line max-len
  const cdbFailedState = (selectedItem: ExadataItem): boolean => containerDbStatus(selectedItem) === ResourceStatus.Failed;
  const pdbFailedState = (selectedPdb: ExadataItem): boolean => (selectedPdb?.status === ResourceStatus.Failed);

  const openModesThatDisablePdbStop = (selectedItem: ExadataItem)
  : boolean => ((selectedItem?.openMode !== PdbOpenMode.READ_ONLY
    && selectedItem?.openMode !== PdbOpenMode.READ_WRITE));
  const openModeThatDisablesPdbStart = (selectedItem: ExadataItem)
  : boolean => (selectedItem?.openMode !== PdbOpenMode.MOUNTED);

  const disabledActionsInfoBlocks = (selectedItem: ExadataItem): InfoBlockProps[] => {
    const disabledActionInfo: InfoBlockProps[] = [];
    const disabledActions: string[] = [];
    if (pdbFailedState(selectedItem)) {
      disabledActions.push(Messages.actions.start());
      disabledActions.push(Messages.actions.stop());
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          ? Messages.hints.pdbDisabledActionPdbStatus(disabledActions.toString(), stateT(selectedItem?.status || ""))
          : Messages.hints.pdbDisabledActionsPdbStatus(disabledActions.join(", "), stateT(selectedItem?.status || ""));
        disabledActionInfo.push(
          {
            testId: InfoBlockTestIds.DisabledActions,
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    } else {
      if (cdbFailedState(selectedItem)) {
        disabledActions.push(Messages.actions.start());
        disabledActions.push(Messages.actions.stop());
      }
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          // eslint-disable-next-line max-len
          ? Messages.hints.pdbDisabledActionCdbStatus(disabledActions.toString(), stateT(containerDbStatus(selectedItem) || ""))
          // eslint-disable-next-line max-len
          : Messages.hints.pdbDisabledActionsCdbStatus(disabledActions.join(", "), stateT(containerDbStatus(selectedItem) || ""));
        disabledActionInfo.push(
          {
            testId: InfoBlockTestIds.DisabledActions,
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    }
    return disabledActionInfo;
  };

  const cdbDisabledActionsInfoBlock = (selectedItem: ExadataItem): InfoBlockProps[] => {
    const disabledActionInfo: InfoBlockProps[] = [];
    if (selectedItem && !selectedItem.isPdb) {
      disabledActionInfo.push(
        {
          testId: InfoBlockTestIds.DisabledActions,
          message: Messages.hints.cdbDisabledActions(`${Messages.actions.start()}, ${Messages.actions.stop()}`),
          messageType: InfoBlockStatus.INFO,
        },
      );
    }
    return disabledActionInfo;
  };

  const { trigger: triggerDeleteCdb } = useOperation<ExaDbDeleteCdbProps>(newExaDbDeleteCdb);
  const { trigger: triggerDeletePdb } = useOperation<ExaDbDeletePdbProps>(newExaDbDeletePdb);
  const { trigger: triggerStartPdb } = useOperation<ExaDbStartPdbProps>(newExaDbStartPdb);
  const { trigger: triggerStopPdb } = useOperation<ExaDbStopPdbProps>(newExaDbStopPdb);
  const { trigger: triggerViewConnectionStrings } = useOperation<ExaDbViewConnectionStringsProps>(
    newExaDbViewConnectionStrings,
  );

  const { closePanels } = useSidePanel();

  const confirmDeleteCdbAction = (selectedCdb: ExadataItem) : void => {
    triggerDeleteCdb({
      databaseId: selectedCdb.id,
      location: selectedLocation,
      onExecute: () => {
        cdbRefresh();
        listingComponentRef?.resetTableSelection();
      },
    });
  };

  const confirmDeletePdbAction = (selectedPdb: ExadataItem) : void => {
    triggerDeletePdb({
      databaseId: selectedPdb.id,
      location: selectedLocation,
      onExecute: () => {
        pdbRefresh();
        listingComponentRef?.resetTableSelection();
      },
    });
  };

  const confirmStartPdbAction = (selectedPdb: ExadataItem) : void => {
    triggerStartPdb({
      databaseId: selectedPdb.id,
      location: selectedLocation,
      onExecute: () => {
        pdbRefresh();
        listingComponentRef?.resetTableSelection();
      },
    });
  };

  const confirmStopPdbAction = (selectedPdb: ExadataItem): void => {
    triggerStopPdb({
      databaseId: selectedPdb.id,
      location: selectedLocation,
      onExecute: () => {
        pdbRefresh();
        listingComponentRef?.resetTableSelection();
      },
    });
  };

  const confirmViewConnectionStringsAction = (selectedItem: ExadataItem): void => {
    triggerViewConnectionStrings({
      databaseId: selectedItem.id,
      isPdb: selectedItem.isPdb,
      location: selectedLocation,
    });
  };

  const buildStartAction = (key: string, testId: string, selectedItem: ExadataItem)
  : ConfirmAction<ExadataItem | undefined> => {
    const startAction = {
      key,
      testId,
      text: Messages.actions.start(),
      icon: MonochromeIconIds.Play,
      title: Messages.labels.startExaCSDB(),
      description: Messages.hints.startExaCSDBConfirmation(selectedItem?.displayName),
      disabled: cdbFailedState(selectedItem) || pdbFailedState(selectedItem)
      || openModeThatDisablesPdbStart(selectedItem),
      onClick: () => {
        trackActionClick(key, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES);
      },
      onConfirm: () => confirmStartPdbAction(selectedItem),
      onCancel: () => trackActionDiscard(key, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES),
    };
    return startAction;
  };
  const buildStopAction = (key: string, testId: string, selectedItem: ExadataItem)
  : ConfirmAction<ExadataItem | undefined> => {
    const stopAction = {
      key,
      testId,
      text: Messages.actions.stop(),
      icon: MonochromeIconIds.Stop,
      title: Messages.labels.stopExaCSDB(),
      description: Messages.hints.stopExaCSDBConfirmation(selectedItem?.displayName || ""),
      disabled: cdbFailedState(selectedItem) || pdbFailedState(selectedItem)
      || openModesThatDisablePdbStop(selectedItem),
      onClick: () => {
        trackActionClick(key, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES);
      },
      onConfirm: () => confirmStopPdbAction(selectedItem),
      onCancel: () => trackActionDiscard(key, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES),
    };
    return stopAction;
  };
  const buildViewConnectionStringsAction = (key: string, testId: string, selectedItem: ExadataItem)
  : CustomAction<ExadataItem | undefined> => {
    const viewConnectionStringsAction = {
      key,
      testId,
      text: Messages.actions.connectionStrings(),
      icon: MonochromeIconIds.Connect,
      disabled: !selectedItem,
      onClick: () => {
        trackActionClick(key, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES);
        confirmViewConnectionStringsAction(selectedItem);
      },
    };
    return viewConnectionStringsAction;
  };
  const buildDeleteAction = (key: string, testId: string, selectedItem: ExadataItem)
  : ConfirmAction<ExadataItem | undefined> => {
    const deleteAction = {
      key,
      testId,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.labels.deleteExaCSDB(),
      description: Messages.hints.deleteExaCSDBConfirmation(selectedItem?.displayName),
      disabled: !selectedItem,
      onClick: () => trackActionClick(key, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES),
      onConfirm: () => {
        if (selectedItem?.isPdb) {
          confirmDeletePdbAction(selectedItem);
        } else {
          confirmDeleteCdbAction(selectedItem);
        }
      },
      onCancel: () => trackActionDiscard(key, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES),
    };
    return deleteAction;
  };

  const exaDbRowActionMenuItems = (item?: ExadataItem | undefined) : ActionType<ExadataItem>[] => {
    const exaMenuItems: ActionType<ExadataItem | undefined>[] = [];
    if (item) {
      if (item.isFavorite) {
        exaMenuItems.push({
          key: ActionMenuIds.RemoveFavorite,
          testId: ActionMenuTestIds.RemoveFavorite,
          text: Messages.actions.removeFromFavorites(),
          icon: MonochromeIconIds.FavoriteSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.RemoveFavorite, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES);
            removeFromFavorites(item.id);
          },
        } as CustomAction<ExadataItem | undefined>);
      } else {
        exaMenuItems.push({
          key: ActionMenuIds.AddFavorite,
          testId: ActionMenuTestIds.AddFavorite,
          text: Messages.actions.addToFavorites(),
          icon: MonochromeIconIds.FavoriteNotSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.AddFavorite, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES);
            setAsFavorite({ id: item.id, data: { location: item.location } } as FavoriteAccessItem);
          },
        } as CustomAction<ExadataItem | undefined>);
      }

      if (item?.isPdb) {
        exaMenuItems.push(
          buildStartAction(ActionMenuIds.Start, ActionMenuTestIds.Start, item),
          buildStopAction(ActionMenuIds.Stop, ActionMenuTestIds.Stop, item),
        );
      }
      exaMenuItems.push(
        buildViewConnectionStringsAction(ActionMenuIds.ConnectionStrings, ActionMenuTestIds.ConnectionStrings, item),
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return exaMenuItems;
  };

  const exaDbBarActions: ActionType<ExadataItem>[] = [
    {
      key: ActionBarIds.Create,
      testId: ActionBarTestIds.Create,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      subMenu: {
        items: [
          {
            key: ActionBarIds.CreateDB,
            testId: ActionBarTestIds.CreateDB,
            text: Messages.actions.createExaDb(),
            onClick: () => {
              trackActionClick(ActionBarIds.CreateDB, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES);
              navigateTo(EXADB_CREATE_ROUTE, PageId.EXADB_CREATE, {
                analytics: {
                  pageId: PageId.EXA_LIST_HUB,
                  panelId: DetailsPanelId.DATABASES,
                  actionName: ActionBarIds.CreateDB,
                } as NavigationAnalyticsData,
              });
            },
          },
          {
            key: ActionBarIds.CreateSystem,
            testId: ActionBarTestIds.CreateSystem,
            text: Messages.actions.createExaSystem(),
            onClick: () => {
              trackActionClick(ActionBarIds.CreateSystem, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES);
              navigateTo(EXADB_FULL_CREATE_ROUTE, PageId.EXADB_CREATE, {
                analytics: {
                  pageId: PageId.EXA_LIST_HUB,
                  panelId: DetailsPanelId.DATABASES,
                  actionName: ActionBarIds.CreateSystem,
                } as NavigationAnalyticsData,
              });
            },
          },
        ],
      },
    },
    buildStartAction(ActionBarIds.Start, ActionBarTestIds.Start, selection[0]),
    buildStopAction(ActionBarIds.Stop, ActionBarTestIds.Stop, selection[0]),
    buildViewConnectionStringsAction(ActionBarIds.ConnectionStrings, ActionBarTestIds.ConnectionStrings, selection[0]),
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      disabled,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES);
        onRefresh();
        listingComponentRef?.resetTableSelection();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  React.useEffect(() => {
    const newSubscriptionOptions: { key: string, text: string }[] = [];
    // eslint-disable-next-line no-unused-expressions
    subscriptions && subscriptions.forEach(subscription => {
      newSubscriptionOptions.push({ key: subscription.id, text: subscription.name });
    });

    setSubscriptionOptions(newSubscriptionOptions);
    if (filterState.defaultSubscription) {
      setSelectedSubscription(filterState.defaultSubscription);
      if (defaultExtraFilters) defaultExtraFilters[0].value = filterState.defaultSubscription;
    } else if (preferredSubscription) {
      setSelectedSubscription(preferredSubscription);
    } else {
      setSelectedSubscription(newSubscriptionOptions.length ? newSubscriptionOptions[0].key : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions)]);

  const subscriptionFilter: ExtraFilter = {
    testId: FilterTestIds.Subscription,
    name: columns[1].name,
    operator: Operator.Equal,
    value: filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
    fieldKey: columns[1].itemProp,
    customOptions: subscriptionOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedSubscription(value);
        filterState.setDefaultSubscription(value);
      }
    },
    hideDelete: true,
  };

  const resourceGroupFilter: ExtraFilter = {
    name: columns[2].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[2].itemProp)?.value || [FilterAll],
    fieldKey: columns[2].itemProp,
    customOptions: getFieldCustomOptions(items?.map(item => item.resourceGroupName), (fieldVal: string) => fieldVal),
  };

  React.useEffect(() => {
    if (filterState.defaultLocation) {
      setSelectedLocation(filterState.defaultLocation);
      if (defaultExtraFilters) defaultExtraFilters[1].value = filterState.defaultLocation;
    } else {
      setSelectedLocation(filterState.pillFilterStateValue.filterValues?.[1]?.value || selectedLocation);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locationFilter: ExtraFilter = {
    testId: FilterTestIds.Location,
    name: columns[3].name,
    operator: Operator.Equal,
    value: filterState.defaultLocation ? filterState.defaultLocation : selectedLocation,
    fieldKey: columns[3].itemProp,
    customOptions: locationOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedLocation(value);
        filterState.setDefaultLocation(value);
      }
    },
    hideDelete: true,
  };

  const lifecycleStateFilter: ExtraFilter = {
    name: columns[4].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[4].itemProp)?.value || [FilterAll],
    fieldKey: columns[4].itemProp,
    messageCallBack: stateT,
    customOptions: getFieldCustomOptions(
      items?.map(item => item.status || ""),
      (fieldVal: string) => {
        if (fieldVal) {
          return stateT(fieldVal);
        }
        return emptyChar;
      },
    ),
  };

  const containerDbFilter: ExtraFilter = {
    name: columns[5].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[5].itemProp)?.value || [FilterAll],
    fieldKey: columns[5].itemProp,
    customOptions: getFieldCustomOptions(
      items?.map(item => item.containerDBName || ""),
      (fieldVal: string) => {
        if (fieldVal) {
          return fieldVal;
        }
        return emptyChar;
      },
    ),
  };

  const allFilterPills = [
    subscriptionFilter,
    locationFilter,
    resourceGroupFilter,
    lifecycleStateFilter,
    containerDbFilter,
  ];

  const filterPills: ExtraFilter[] = [
    subscriptionFilter,
    locationFilter,
  ];

  const textFilterCallBack = (rowItem: ExadataItem): ExadataItem => {
    const displayRowItem = { ...rowItem };
    displayRowItem.lifecycleState = stateT(
      displayRowItem.lifecycleState,
    ) as PluggableDatabaseSummaryLifecycleStateEnum | DatabaseSummaryLifecycleStateEnum;
    displayRowItem.location = getAzureLocationName(displayRowItem.location);
    return displayRowItem;
  };

  React.useEffect(() => {
    setFilterText(filterTextValue);
    filterState.setFilterTextValue(filterTextValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTextValue]);

  const defaultExtraFilters = useFilterStatePills(pillFilterValues, allFilterPills, listId, filterPills);

  const resetFilterValues = (): void => {
    const defaultPillValues = filterPills.map(filter => ({ fieldKey: filter.fieldKey, value: filter.value as string }));
    filterState.setFilterStateValue({ filterValues: defaultPillValues, listId, defaultPills: filterPills });
    filterState.setFilterTextValue("");
    setFilterText("");
  };

  return (
    <Listing
      testId={ListingTestIds.ExaDb}
      listingComponentRef={setListingComponentRef}
      items={items}
      listColumns={columns}
      itemsPerPage={perPageItemsCount}
      actionBarItems={exaDbBarActions as ActionType<undefined>[]}
      infoBlocks={selection[0]?.isPdb ? disabledActionsInfoBlocks(selection[0])
        : cdbDisabledActionsInfoBlock(selection[0])}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      actions={exaDbRowActionMenuItems}
      emptyList={{
        title: Messages.listExaDb.emptyList.title(),
        emptyButtons: [
          {
            type: EmptyListingButtonTypes.PRIMARY,
            text: Messages.actions.create(),
            menu: {
              items: [
                {
                  key: ActionBarIds.CreateDB,
                  text: Messages.actions.createExaDb(),
                  testId: ActionBarIds.CreateDB,
                  onClick: () => {
                    trackActionClick(ActionBarIds.CreateDB, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES);
                    navigateTo(EXADB_CREATE_ROUTE, PageId.EXADB_CREATE, {
                      analytics: {
                        pageId: PageId.EXA_LIST_HUB,
                        panelId: DetailsPanelId.DATABASES,
                        actionName: ActionBarIds.CreateDB,
                      } as NavigationAnalyticsData,
                    });
                  },
                },
                {
                  key: ActionBarIds.CreateSystem,
                  text: Messages.actions.createExaSystem(),
                  testId: ActionBarIds.CreateSystem,
                  onClick: () => {
                    trackActionClick(ActionBarIds.CreateSystem, PageId.EXA_LIST_HUB, DetailsPanelId.DATABASES);
                    navigateTo(EXADB_FULL_CREATE_ROUTE, PageId.EXADB_CREATE, {
                      analytics: {
                        pageId: PageId.EXA_LIST_HUB,
                        panelId: DetailsPanelId.DATABASES,
                        actionName: ActionBarIds.CreateSystem,
                      } as NavigationAnalyticsData,
                    });
                  },
                },
              ],
            },
          },
        ],
        watermark: SvgIconIds.exaWaterMarkSvg,
      }}
      isLoading={(pdbLoading || cdbLoading || subscriptionLoading) && !disabled}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.DisplayName,
      }}
      groupingSelect={{ groupingOptions }}
      viewSelect={{
        viewOptions,
        defaultSelectedKey: ViewKeys.ListView,
      }}
      filtering={{
        allExtraFilters: allFilterPills,
        defaultExtraFilters,
        defaultFilterText: filterText,
        textFilterCallBack,
        componentRef: setFilterComponentRef,
        onClearFilters: resetFilterValues,
      }}
    />
  );
};
