import saveAs from "file-saver";

export const getBase64FromDataURL = (dataURL: string): string => {
  let base64Data = dataURL;
  if (base64Data) {
    const base64Location = base64Data.indexOf(";base64,");
    if (base64Location !== -1) {
      base64Data = base64Data.substring(base64Location + 8);
    }
  }
  return base64Data;
};

export const saveBlob = (fileContents: string, fileName: string): void => {
  const blob = new Blob([fileContents], { type: "text/plain;charset=utf-8" });
  saveAs(blob, fileName);
};
