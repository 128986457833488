import { AnchoredPanel, AnchoredPanelType, ButtonType, FocusableArea, FormattedString } from "o4a-react";
import * as React from "react";
import { Icon, Link } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { OperationBaseProps } from "../../console/OperationsContext";
import { SidePanelTestIds } from "../../constants/uiConstants";

export enum ButtonTestIds {
  Close = "close-button"
}

export type CloudLinkViewAdminInstructionsProps = Omit<OperationBaseProps, "onExecute">

const CloudLinkViewAdminInstructions = ({ onCancel }: CloudLinkViewAdminInstructionsProps): JSX.Element => (
  <AnchoredPanel
    testId={SidePanelTestIds.CloudLinkViewAdminInstructions}
    isOpen
    type={AnchoredPanelType.MEDIUM}
    title={Messages.instructions.linkSubscriptions.title(Messages.portalBrand.short())}
    onClose={onCancel}
    initialFocusableArea={FocusableArea.FOOTER}
    footer={[
      {
        type: ButtonType.DEFAULT,
        testId: ButtonTestIds.Close,
        label: Messages.actions.close(),
        onClick: () => onCancel?.(),
        style: { margin: "10px 5px 0px 0px", height: "24px" },
      },
    ]}
  >
    <div>
      <ol>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step1()} /></li>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step2()} /></li>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step3()} /></li>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step4()} /></li>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step5()} /></li>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step6()} /></li>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step7()} /></li>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step8()} /></li>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step9()} /></li>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step10()} /></li>
        <li><FormattedString inputText={Messages.instructions.linkSubscriptions.step11()} /></li>
        <li>
          <FormattedString inputText={Messages.instructions.linkSubscriptions.step12(Messages.portalBrand.short())} />
        </li>
      </ol>
    </div>
    <div>
      <Link
        // eslint-disable-next-line max-len
        href="https://portal.azure.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview/menuId~/null"
        target="_blank"
      >
        <span>{Messages.instructions.linkSubscriptions.azureLinkLabel()}</span>
        <span style={{ marginLeft: "8px" }}><Icon iconName="OpenInNewWindow" /></span>
      </Link>
    </div>
  </AnchoredPanel>
);

export const newCloudLinkViewAdminInstructions = (
  props: CloudLinkViewAdminInstructionsProps,
): JSX.Element => (<CloudLinkViewAdminInstructions {...props} />);
