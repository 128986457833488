import { CalloutComponent, FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import {
  AdbsNetworkMutualAuthEditPanel,
  Fields as authField,
} from "../../components/AdbsNetworkMutualAuthEditPanel/AdbsNetworkMutualAuthEditPanel";
import { UpdateAdbsDatabaseDetails } from "../../gen/clients/mchub-azure-api-client";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  ADBS_UPDATE_POLL_DELAY,
  ADBS_UPDATE_POLL_INTERVAL,
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface AdbsEditMutualTlsAuthProps extends OperationActionProps {
  databaseId: string | undefined;
  isMtlsConnectionRequired: boolean | undefined;
}

const AdbsEditMutualTlsAuth = (
  { targetId, location, databaseId, isMtlsConnectionRequired, onExecute, onCancel }: AdbsEditMutualTlsAuthProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const [calloutRef, setCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const { invokeCall } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.updateAdbsDatabase },
  );

  const onSubmitMTLsAuth = (formValues: FormValues): void => {
    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: calloutRef.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateAdbsNetworkMutualAuth(),
          message: Messages.notifications.inProgress.messages.updateAdbsNetworkMutualAuth(resName),
        },
        success: {
          title: Messages.notifications.success.titles.updateAdbsNetworkMutualAuth(),
          message: Messages.notifications.success.messages.updateAdbsNetworkMutualAuth(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateAdbsNetworkMutualAuth(),
          message: Messages.notifications.failure.messages.updateAdbsNetworkMutualAuth(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.ADBS_GET,
          methodArgs: { subscriptionId, resourceGroupName: resGroup, databaseName: resName },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.ADBS_UPDATED_CHECK,
          delay: ADBS_UPDATE_POLL_DELAY,
          interval: ADBS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeCall({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName: resName,
      updateAdbsDatabaseDetails: {
        isMtlsConnectionRequired:
          getValue<boolean>(formValues, authField.MTLSAuthentication, InputFormGroup),
      } as UpdateAdbsDatabaseDetails,
    }, invokeOptions);
  };

  return (
    <AdbsNetworkMutualAuthEditPanel
      isMtlsConnectionRequired={isMtlsConnectionRequired}
      targetId={targetId}
      onSubmit={onSubmitMTLsAuth}
      componentRef={setCalloutRef}
      onClose={onCancel}
    />
  );
};

export const newAdbsEditMutualTlsAuth = (
  props: AdbsEditMutualTlsAuthProps,
): JSX.Element => (<AdbsEditMutualTlsAuth {...props} />);
