import { BookmarkablePage, DetailView, DetailViewsPanel, GroupItem, useNavigation } from "o4a-react";
import * as React from "react";
import { Icon } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { PromoAlertModal } from "../../components/PromoAlertModal/PromoAlertModal";
import { ConsoleContext } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  gettingStartedADB,
  gettingStartedExadata,
  gettingStartedMySQL,
  gettingStartedVMDB,
} from "../../constants/docConstants";
import {
  ADBS_CREATE_ROUTE,
  APEX_CREATE_ROUTE,
  CREATE_HUB_ROUTE,
  EXADB_CREATE_ROUTE,
  EXADB_FULL_CREATE_ROUTE,
  EXAINFRA_CREATE_ROUTE,
  MCVCN_CREATE_ROUTE,
  MYSQL_CREATE_ROUTE,
  PageId,
  PageRegistrationConfig,
  VMCLUSTER_CREATE_ROUTE,
  VMDB_CREATE_ROUTE,
} from "../../constants/pluginConstants";
import { SvgIconIds } from "../../constants/uiConstants";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { getOCIConsoleUrl, getOciRegion } from "../../utils";
import { ResourceCategoryContent } from "./ResourceCategoryContent";
import { CategoryService, ResourceCategories, ServiceItem } from "./ResourceCategoryUtil";

export enum ActionIds {
  CreateAdbs = "create-adbs",
  CreateExaDb = "create-exa-db",
  CreateExaVmCluster = "create-exa-vmcluster",
  CreateExaInfra = "create-exa-infra",
  CreateExaSystem = "create-exa-system",
  CreateVmDb = "create-vmdb",
  CreateMysql = "create-mysql",
  CreateApex = "create-apex",
  CreateMcvcn = "create-mcvcn",
}

export enum ServiceItemTestIds {
  Adb = "adb-item",
  ExaDb = "exadb-item",
  ExaVmCluster = "exavm-item",
  ExaInfra = "exainfra-item",
  ExaSystem = "exasys-item",
  VmDb = "vmdb-item",
  Mysql = "mysql-item",
  Apex = "apex-item",
  Mcvcn = "mcvcn-item",
}

export enum ServiceLinkTestIds {
  Adb = "adb-item-create",
  ExaDb = "exadb-item-create",
  ExaVmCluster = "exavm-item-create",
  ExaInfra = "exainfra-item-create",
  ExaSystem = "exasys-item-create",
  VmDb = "vmdb-item-create",
  Mysql = "mysql-item-create",
  Apex = "apex-item-create",
  Mcvcn = "mcvcn-item-create",
}

export enum LearnMoreLinksTestIds {
  Adb = "adbs-item-learn-more",
  ExaDb = "exadb-item-learn-more",
  ExaVmCluster = "exavm-item-learn-more",
  ExaInfra = "exainfra-item-learn-more",
  ExaSystem = "exasys-item-learn-more",
  VmDb = "vmdb-item-learn-more",
  Mysql = "mysql-item-learn-more",
  Apex = "apex-item-learn-more",
}

export enum CategoryServiceDataTestIds {
  Recently = "recently",
  Database = "database",
  Network = "network",
}

const registrationIds = PageRegistrationConfig[PageId.CREATE_A_RESOURCE].map(config => config.key);

const iconStyles = {
  root: {
    width: "35px",
    height: "35px",
  },
};

export const CreateAResourcePage = (): JSX.Element => {
  const { enableMds, enableApex, enableMcvcnCreate } = useFeatures();
  const { trackActionClick } = useAnalytics();
  const { back, navigateTo, navigateToSelf } = useNavigation(ConsoleContext);
  const { preferredSubscription } = React.useContext<Settings>(SettingsContext);
  const { subscriptions } = useSubscriptions();
  const preferredSubscriptionInfo = subscriptions?.find(sub => sub.id === preferredSubscription);
  const [isPromoAlertModalOpen, { setTrue: showPromoAlertModal, setFalse: hidePromoAlertModal }] = useBoolean(false);
  const [panelId, setPanelId] = React.useState<string>();
  const onMenuItemSelect = (id: string): void => setPanelId(id);

  const isPromoAccount = React.useMemo(() => {
    let promoAccount = false;
    if (subscriptions) {
      for (let i = 0; i < subscriptions.length; i++) {
        if (subscriptions[i].ociTenancyPaymentModel === "FREE") {
          promoAccount = true;
        } else {
          promoAccount = false;
          break;
        }
      }
    }
    return promoAccount;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions)]);

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    navigateToSelf(CREATE_HUB_ROUTE, registrationIds[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const databaseItems: ServiceItem[] = [
    {
      image: (<Icon iconName={SvgIconIds.adbSvg} styles={iconStyles} />),
      title: Messages.topServices.autonomousSharedDatabase(),
      testId: ServiceItemTestIds.Adb,
      onClick: () => {
        trackActionClick(ActionIds.CreateAdbs, PageId.CREATE_A_RESOURCE);
        navigateTo(ADBS_CREATE_ROUTE, PageId.ADBS_CREATE, {
          analytics: {
            actionName: ActionIds.CreateAdbs,
            pageId: PageId.CREATE_A_RESOURCE,
          } as NavigationAnalyticsData,
        });
      },
      links: [
        {
          testId: ServiceLinkTestIds.Adb,
          text: Messages.actions.create(),
          onClick: () => {
            trackActionClick(ActionIds.CreateAdbs, PageId.CREATE_A_RESOURCE);
            navigateTo(ADBS_CREATE_ROUTE, PageId.ADBS_CREATE, {
              analytics: {
                actionName: ActionIds.CreateAdbs,
                pageId: PageId.CREATE_A_RESOURCE,
              } as NavigationAnalyticsData,
            });
          },
        },
        {
          testId: LearnMoreLinksTestIds.Adb,
          text: Messages.common.learnMore(),
          url: gettingStartedADB,
        },
      ],
    },
    {
      image: (<Icon iconName={SvgIconIds.exaSvg} styles={iconStyles} />),
      title: Messages.topServices.exadataDatabases(),
      onClick: () => {
        if (isPromoAccount) {
          showPromoAlertModal();
        } else {
          trackActionClick(ActionIds.CreateExaDb, PageId.CREATE_A_RESOURCE);
          navigateTo(EXADB_CREATE_ROUTE, PageId.EXADB_CREATE, {
            analytics: {
              actionName: ActionIds.CreateExaDb,
              pageId: PageId.CREATE_A_RESOURCE,
            } as NavigationAnalyticsData,
          });
        }
      },
      testId: ServiceItemTestIds.ExaDb,
      links: [
        {
          testId: ServiceLinkTestIds.ExaDb,
          text: Messages.actions.create(),
          onClick: () => {
            if (isPromoAccount) {
              showPromoAlertModal();
            } else {
              trackActionClick(ActionIds.CreateExaDb, PageId.CREATE_A_RESOURCE);
              navigateTo(EXADB_CREATE_ROUTE, PageId.EXADB_CREATE, {
                analytics: {
                  actionName: ActionIds.CreateExaDb,
                  pageId: PageId.CREATE_A_RESOURCE,
                } as NavigationAnalyticsData,
              });
            }
          },
        },
        {
          testId: LearnMoreLinksTestIds.ExaDb,
          text: Messages.common.learnMore(),
          url: gettingStartedExadata,
        },
      ],
    },
    {
      image: (<Icon iconName={SvgIconIds.vmClusterSvg} styles={iconStyles} />),
      title: Messages.topServices.exadataVmClusters(),
      onClick: () => {
        if (isPromoAccount) {
          showPromoAlertModal();
        } else {
          trackActionClick(ActionIds.CreateExaVmCluster, PageId.CREATE_A_RESOURCE);
          navigateTo(VMCLUSTER_CREATE_ROUTE, PageId.VMCLUSTER_CREATE, {
            analytics: {
              actionName: ActionIds.CreateExaVmCluster,
              pageId: PageId.CREATE_A_RESOURCE,
            } as NavigationAnalyticsData,
          });
        }
      },
      testId: ServiceItemTestIds.ExaVmCluster,
      links: [
        {
          testId: ServiceLinkTestIds.ExaVmCluster,
          text: Messages.actions.create(),
          onClick: () => {
            if (isPromoAccount) {
              showPromoAlertModal();
            } else {
              trackActionClick(ActionIds.CreateExaVmCluster, PageId.CREATE_A_RESOURCE);
              navigateTo(VMCLUSTER_CREATE_ROUTE, PageId.VMCLUSTER_CREATE, {
                analytics: {
                  actionName: ActionIds.CreateExaVmCluster,
                  pageId: PageId.CREATE_A_RESOURCE,
                } as NavigationAnalyticsData,
              });
            }
          },
        },
        {
          testId: LearnMoreLinksTestIds.ExaVmCluster,
          text: Messages.common.learnMore(),
          url: gettingStartedExadata,
        },
      ],
    },
    {
      image: (<Icon iconName={SvgIconIds.infraSvg} styles={iconStyles} />),
      title: Messages.topServices.exadataInfras(),
      onClick: () => {
        if (isPromoAccount) {
          showPromoAlertModal();
        } else {
          trackActionClick(ActionIds.CreateExaInfra, PageId.CREATE_A_RESOURCE);
          navigateTo(EXAINFRA_CREATE_ROUTE, PageId.EXAINFRA_CREATE, {
            analytics: {
              actionName: ActionIds.CreateExaInfra,
              pageId: PageId.CREATE_A_RESOURCE,
            } as NavigationAnalyticsData,
          });
        }
      },
      testId: ServiceItemTestIds.ExaInfra,
      links: [
        {
          testId: ServiceLinkTestIds.ExaInfra,
          text: Messages.actions.create(),
          onClick: () => {
            if (isPromoAccount) {
              showPromoAlertModal();
            } else {
              trackActionClick(ActionIds.CreateExaInfra, PageId.CREATE_A_RESOURCE);
              navigateTo(EXAINFRA_CREATE_ROUTE, PageId.EXAINFRA_CREATE, {
                analytics: {
                  actionName: ActionIds.CreateExaInfra,
                  pageId: PageId.CREATE_A_RESOURCE,
                } as NavigationAnalyticsData,
              });
            }
          },
        },
        {
          testId: LearnMoreLinksTestIds.ExaInfra,
          text: Messages.common.learnMore(),
          url: gettingStartedExadata,
        },
      ],
    },
    {
      image: (<Icon iconName={SvgIconIds.exaSvg} styles={iconStyles} />),
      title: Messages.topServices.exadataSystems(),
      onClick: () => {
        if (isPromoAccount) {
          showPromoAlertModal();
        } else {
          trackActionClick(ActionIds.CreateExaSystem, PageId.CREATE_A_RESOURCE);
          navigateTo(EXADB_FULL_CREATE_ROUTE, PageId.EXADB_CREATE, {
            analytics: {
              actionName: ActionIds.CreateExaSystem,
              pageId: PageId.CREATE_A_RESOURCE,
            } as NavigationAnalyticsData,
          });
        }
      },
      testId: ServiceItemTestIds.ExaSystem,
      links: [
        {
          testId: ServiceLinkTestIds.ExaSystem,
          text: Messages.actions.create(),
          onClick: () => {
            if (isPromoAccount) {
              showPromoAlertModal();
            } else {
              trackActionClick(ActionIds.CreateExaSystem, PageId.CREATE_A_RESOURCE);
              navigateTo(EXADB_FULL_CREATE_ROUTE, PageId.EXADB_CREATE, {
                analytics: {
                  actionName: ActionIds.CreateExaSystem,
                  pageId: PageId.CREATE_A_RESOURCE,
                } as NavigationAnalyticsData,
              });
            }
          },
        },
        {
          testId: LearnMoreLinksTestIds.ExaSystem,
          text: Messages.common.learnMore(),
          url: gettingStartedExadata,
        },
      ],
    },
    {
      image: (<Icon iconName={SvgIconIds.baseDbSvg} styles={iconStyles} />),
      title: Messages.topServices.vmDatabases(),
      testId: ServiceItemTestIds.VmDb,
      onClick: () => {
        trackActionClick(ActionIds.CreateVmDb, PageId.CREATE_A_RESOURCE);
        navigateTo(VMDB_CREATE_ROUTE, PageId.VMDB_CREATE, {
          analytics: {
            actionName: ActionIds.CreateVmDb,
            pageId: PageId.CREATE_A_RESOURCE,
          } as NavigationAnalyticsData,
        });
      },
      links: [
        {
          testId: ServiceLinkTestIds.VmDb,
          text: Messages.actions.create(),
          onClick: () => {
            trackActionClick(ActionIds.CreateVmDb, PageId.CREATE_A_RESOURCE);
            navigateTo(VMDB_CREATE_ROUTE, PageId.VMDB_CREATE, {
              analytics: {
                actionName: ActionIds.CreateVmDb,
                pageId: PageId.CREATE_A_RESOURCE,
              } as NavigationAnalyticsData,
            });
          },
        },
        {
          testId: LearnMoreLinksTestIds.VmDb,
          text: Messages.common.learnMore(),
          url: gettingStartedVMDB,
        },
      ],
    },
    ...(enableMds ? [{
      image: (<Icon iconName={SvgIconIds.mysqlSvg} styles={iconStyles} />),
      title: Messages.topServices.mysqlDatabase(),
      testId: ServiceItemTestIds.Mysql,
      onClick: () => {
        trackActionClick(ActionIds.CreateMysql, PageId.CREATE_A_RESOURCE);
        navigateTo(MYSQL_CREATE_ROUTE, PageId.MYSQL_CREATE, {
          analytics: {
            actionName: ActionIds.CreateMysql,
            pageId: PageId.CREATE_A_RESOURCE,
          } as NavigationAnalyticsData,
        });
      },
      links: [
        {
          testId: ServiceLinkTestIds.Mysql,
          text: Messages.actions.create(),
          onClick: () => {
            trackActionClick(ActionIds.CreateMysql, PageId.CREATE_A_RESOURCE);
            navigateTo(MYSQL_CREATE_ROUTE, PageId.MYSQL_CREATE, {
              analytics: {
                actionName: ActionIds.CreateMysql,
                pageId: PageId.CREATE_A_RESOURCE,
              } as NavigationAnalyticsData,
            });
          },
        },
        {
          testId: LearnMoreLinksTestIds.Mysql,
          text: Messages.common.learnMore(),
          url: gettingStartedMySQL,
        },
      ],
    }] : []),
    ...(enableApex ? [{
      image: (<Icon iconName={SvgIconIds.apexSvg} styles={iconStyles} />),
      title: Messages.topServices.apex(),
      onClick: () => {
        trackActionClick(ActionIds.CreateApex, PageId.CREATE_A_RESOURCE);
        navigateTo(APEX_CREATE_ROUTE, PageId.ADBS_CREATE, {
          analytics: {
            actionName: ActionIds.CreateApex,
            pageId: PageId.CREATE_A_RESOURCE,
          } as NavigationAnalyticsData,
        });
      },
      testId: ServiceItemTestIds.Apex,
      links: [
        {
          testId: ServiceLinkTestIds.Apex,
          text: Messages.actions.create(),
          onClick: () => {
            trackActionClick(ActionIds.CreateApex, PageId.CREATE_A_RESOURCE);
            navigateTo(APEX_CREATE_ROUTE, PageId.ADBS_CREATE, {
              analytics: {
                actionName: ActionIds.CreateApex,
                pageId: PageId.CREATE_A_RESOURCE,
              } as NavigationAnalyticsData,
            });
          },
        },
        {
          testId: LearnMoreLinksTestIds.Apex,
          text: Messages.common.learnMore(),
          url: gettingStartedADB,
        },
      ],
    }] : []),
  ];

  const networkItems: ServiceItem[] = [
    ...(enableMcvcnCreate ? [{
      image: (<Icon iconName={SvgIconIds.networkSvg} styles={iconStyles} />),
      title: Messages.topServices.mcvcn(),
      onClick: () => {
        trackActionClick(ActionIds.CreateMcvcn, PageId.CREATE_A_RESOURCE);
        navigateTo(MCVCN_CREATE_ROUTE, PageId.MCVCN_CREATE, {
          analytics: {
            actionName: ActionIds.CreateMcvcn,
            pageId: PageId.CREATE_A_RESOURCE,
          } as NavigationAnalyticsData,
        });
      },
      testId: ServiceItemTestIds.Mcvcn,
      links: [
        {
          testId: ServiceLinkTestIds.Mcvcn,
          text: Messages.actions.create(),
          onClick: () => {
            trackActionClick(ActionIds.CreateMcvcn, PageId.CREATE_A_RESOURCE);
            navigateTo(MCVCN_CREATE_ROUTE, PageId.MCVCN_CREATE, {
              analytics: {
                actionName: ActionIds.CreateMcvcn,
                pageId: PageId.CREATE_A_RESOURCE,
              } as NavigationAnalyticsData,
            });
          },
        },
        // {
        //   testId: LearnMoreLinksTestIds.Apex,
        //   text: Messages.common.learnMore(),
        //   url: gettingStartedADB,
        // },
      ],
    }] : []),
  ];

  const categoryServiceData: CategoryService[] = [
    // {
    //   testId: CategoryServiceDataTestIds.Recently,
    //   category: ResourceCategories.RECENTLY,
    // },
    {
      testId: CategoryServiceDataTestIds.Database,
      category: ResourceCategories.DATABASE,
      services: databaseItems,
    },
    {
      testId: CategoryServiceDataTestIds.Network,
      category: ResourceCategories.NETWORK,
      services: networkItems,
    },
  ];

  const getCategoryServiceData = (category: ResourceCategories): CategoryService | undefined => (
    categoryServiceData.find(data => data.category === category));

  const onUpgrade = (): void => {
    const region = getOciRegion(preferredSubscriptionInfo?.primaryLocation || "");
    const ociConsoleUrl = getOCIConsoleUrl();
    const ociTenancyParam = preferredSubscriptionInfo?.ociTenancyName
      ? `&tenant=${preferredSubscriptionInfo?.ociTenancyName}` : "";
    const ociDomainParam = preferredSubscriptionInfo?.ociDomainName
      ? `&domain=${preferredSubscriptionInfo?.ociDomainName}` : "";
    const upgradeUrl = `${ociConsoleUrl}/invoices-and-orders/upgrade-and-payment`
      + `?region=${region}${ociTenancyParam}${ociDomainParam}`;
    window.open(upgradeUrl, "_blank");
  };

  const detailViews: DetailView[] = [
    // {
    //   id: registrationIds[0],
    //   content: (<ResourceCategoryContent categoryService={getCategoryServiceData(ResourceCategories.RECENTLY)} />),
    // },
    {
      id: registrationIds[0],
      content: (<ResourceCategoryContent categoryService={getCategoryServiceData(ResourceCategories.DATABASE)} />),
    },
    ...(enableMcvcnCreate ? [({
      id: registrationIds[1],
      content: (<ResourceCategoryContent categoryService={getCategoryServiceData(ResourceCategories.NETWORK)} />),
    })] : []),
  ];

  const groupItems: GroupItem[] = [
    {
      heading: Messages.labels.categories(),
      items: [
        // {
        //   id: detailViews[0].id,
        //   name: Messages.labels.recentlyCreated(),
        // },
        {
          id: detailViews[0].id,
          name: Messages.labels.databases(),
        },
        ...(enableMcvcnCreate ? [{
          id: detailViews[1].id,
          name: Messages.labels.networking(),
        }] : []),
      ],
    },
  ];

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={Messages.labels.createAResource()}
    >
      <DetailViewsPanel
        title={Messages.labels.createAResource()}
        onClose={back}
        isOpen
        views={detailViews}
        activeViewId={panelId}
        onMenuItemSelect={onMenuItemSelect}
        menu={groupItems}
        menuFixed
        hideSearchBox
      />
      <PromoAlertModal
        isPromoAlertModalOpen={isPromoAlertModalOpen}
        hidePromoAlertModal={hidePromoAlertModal}
        onUpgrade={onUpgrade}
      />
    </BookmarkablePage>
  );
};
