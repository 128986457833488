import { SelectOption } from "o4a-react";
import * as React from "react";
import { Icon, Link, mergeStyleSets, Stack, Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { TagsCollection } from "../../components/Tags/TagsCollection";
import { tagsLearnMoreUrl } from "../../constants/docConstants";

export enum Fields {
  Tags = "tags"
}

export enum FieldTestIds {
  Tags = "tags"
}

const openInNewTabIcon = "OpenInNewWindow";

const classNames = mergeStyleSets({
  container: { padding: "10px", width: "710px", maxWidth: "100%" },
  separator: { padding: "2px 8px" },
  icon: { paddingLeft: "5px" },
});

interface Props {
  resourceOptions: SelectOption[];
}

export const TagsTabContent = ({ resourceOptions }: Props): JSX.Element => (
  <Stack className={classNames.container}>
    <div>
      <Text>{Messages.tags.description()}</Text>
      <Link href={tagsLearnMoreUrl} target="_blank">
        <>
          <span>
            {Messages.tags.info.learnMore()}
          </span>
          <Icon className={classNames.icon} iconName={openInNewTabIcon} />
        </>
      </Link>
    </div>
    <br />
    <div>
      <Text>{Messages.tags.note()}</Text>
    </div>
    <br />
    <TagsCollection fieldName={Fields.Tags} testId={FieldTestIds.Tags} resourceOptions={resourceOptions} />
  </Stack>
);
