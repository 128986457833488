/* eslint-disable object-curly-newline */
import {
  CheckBox,
  FieldSet,
  FormattedString,
  FormValues,
  InfoBlockLayout,
  InfoBlockStatus,
  InputWizardPanelComponent,
  RadioGroup,
  RadioGroupOption,
  SelectComponent,
  TextInput,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import {
  AdbsNetworkAddressCollection,
} from "../../components/AdbsNetworkAddressCollection/AdbsNetworkAddressCollection";
import { McvcnSelect } from "../../components/McvcnSelect/McvcnSelect";
import { SubnetSelect } from "../../components/SubnetSelect/SubnetSelect";
import { VNETSelect } from "../../components/VNETSelect/VNETSelect";
import { vnetTroubleshooting } from "../../constants/docConstants";
import { IdResourceType } from "../../helpers/idHelper";
import {
  ACCESS_TYPES,
  AdbWorkloadType,
  FutureMcvcnExisting,
  FutureMcvcnNew,
  getDatabaseAccessEndpoint,
  getToolsAccessEndpoint,
} from "../../helpers/resourceHelper";
import {
  adbsDbHostnamePrefixMaxLength,
  alphaNumericHyphenRegex,
  startWithLetterRegex,
} from "../../helpers/validationHelper";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import { McvcnCreateNewProps, newMcvcnCreateNew } from "../../operations/Mcvcn/McvcnCreateNew";

export const GROUP_NETWORK = "network";

export enum Fields {
  IPsCidrs = "adbsDatabaseDetails.whitelistedIps",
  NetworkAccessType = "adbsDatabaseDetails.networkAccessType",
  MTLSAuthentication = "adbsDatabaseDetails.isMtlsConnectionRequired",
  Mcvcn = "multiCloudVirtualNetworkDetails.id",
  Vnet = "adbsDatabaseDetails.vnetId",
  Subnet = "adbsDatabaseDetails.subnetId",
  Hostname = "adbsDatabaseDetails.hostname",
  DatabaseAccessEndpoint = "dbAccessEndpoint",
  ToolsAccessEndpoint = "toolsAccessEndpoint",
  RouteVNET = "routeVNET",
  NetworkPeering = "networkPeering"
}

export enum LinkTestIds {
  CreateVcnLink= "mcvcnCreateNewLink",
}

export enum FieldTestIds {
  IPsCidrs = "network-address-input",
  Mcvcn = "mcvcn-select",
  Vnet = "vnet-select",
  Subnet = "subnet-select",
  RouteVNET = "direct-connection-cb",
  NetworkPeering = "network-peering-cb",
  NetworkAccessType = "access-type",
  AccessTypeEveryWhere = "access-type-everywhere",
  AccessTypeIpAddress = "access-type-ip-address",
  AccessTypeAzurePE = "access-type-azure-pe",
  MTLSAuthentication = "mtls-required",
  Hostname = "hostname",
  DatabaseAccessEndpoint = "db-access-endpoint",
  ToolsAccessEndpoint = "tools-access-endpoint",
}

export enum InfoBlockTestIds {
  NetworkAccessType = "network-access-type-infoblock"
}

const accessTypes = (enableAdbsAzurePE: boolean): RadioGroupOption[] => {
  const accessTypeOptions = [
    {
      id: ACCESS_TYPES.EVERYWHERE,
      text: Messages.labels.accessFromEverywhere(),
      testId: FieldTestIds.AccessTypeEveryWhere,
    },
    {
      id: ACCESS_TYPES.ALLOWED_IPS,
      text: Messages.labels.accessFromAllowedIPs(),
      testId: FieldTestIds.AccessTypeIpAddress,
    },
  ] as RadioGroupOption[];

  if (enableAdbsAzurePE) {
    accessTypeOptions.push({
      id: ACCESS_TYPES.PRIVATE,
      text: Messages.labels.accessFromAzurePrivateEndpoint(),
      testId: FieldTestIds.AccessTypeAzurePE,
    });
  }
  return accessTypeOptions;
};

const accessTypeHints = (
  accessFromEverywhere: JSX.Element,
  accessFromAllowedIPs: JSX.Element,
  accessFromAzurePE?: JSX.Element,
): JSX.Element => (
  <>
    {accessFromEverywhere}
    <br />
    {accessFromAllowedIPs}
    {!!accessFromAzurePE && (
      <>
        <br />
        {accessFromAzurePE}
      </>
    )}
  </>
);

export interface NetworkTabContentProps {
  inputWizardPanelRef: InputWizardPanelComponent;
  subscription: string;
  location: string;
  workload?: AdbWorkloadType;
  isApex: boolean;
}

export const NetworkTabContent = (
  { inputWizardPanelRef, subscription, location, workload, isApex }: NetworkTabContentProps,
): JSX.Element => {
  const {
    enableAdbsOpenPL,
    enableAdbsRestrictedPL,
    enableAdbsAzurePE,
    enableMcvcn,
    enableAdbsAzurePEPeering,
  } = useFeatures();
  const [selectRef, setSelectRef] = React.useState<SelectComponent>();
  const [requireAuthKey, setRequireAuthKey] = React.useState<string>(uniqueGUID());
  const [defaultValueRequireAuth, setDefaultValueRequireAuth] = React.useState<boolean>(true);
  const [disableRequireAuth, setDisableRequireAuth] = React.useState<boolean>(true);
  const [showIPsCidrs, setShowIPsCidrs] = React.useState<boolean>(false);
  const [showRouteVNET, setShowRouteVNET] = React.useState<boolean>(true);
  const [showNetworkPeering, setShowNetworkPeering] = React.useState<boolean>(false);
  const [isRouteVNET, setIsRouteVNET] = React.useState<boolean>(true);
  const [isNetworkPeering, setIsNetworkPeering] = React.useState<boolean>(false);
  const [selectedVnet, setSelectedVnet] = React.useState<string>();
  const [selectedSubnet, setSelectedSubnet] = React.useState<string>();
  const [addresses, setAddresses] = React.useState<FormValues[]>();
  const [showVnetAndSubNet, setShowVnetAndSubNet] = React.useState<boolean>(true);
  const [showMcvcn, setShowMcvcn] = React.useState<boolean>(false);
  const accessOptions = accessTypes(enableAdbsAzurePE);
  const [selectedAccessOptions, setSelectedAccessOptions] = React.useState<string>(ACCESS_TYPES.EVERYWHERE);
  const accessFromEverywhere = FormattedString({ inputText: Messages.hints.accessFromEverywhere() });
  const accessFromAllowedIPs = FormattedString(
    { inputText: Messages.hints.accessFromAllowedIPs() },
  );
  const accessFromAzurePE = FormattedString({ inputText: Messages.hints.accessFromAzurePrivateEndpoint() });
  const [hostnameVal, setHostnameVal] = React.useState<string>();
  const onError = (): void => inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.general());
  const onMissingDependencies = (missingDependencies: IdResourceType[]): void => {
    const dependencyType = missingDependencies?.[0];
    if (dependencyType === IdResourceType.SUBSCRIPTIONS || dependencyType === IdResourceType.LOCATION
      || dependencyType === IdResourceType.RESOURCE_GROUPS) {
      inputWizardPanelRef.showErrorDialog(
        Messages.createCommon.dependencyWarnings.basicsIncomplete.message(),
        Messages.createCommon.dependencyWarnings.basicsIncomplete.title(),
      );
    } else if (dependencyType === IdResourceType.VNETS) {
      inputWizardPanelRef.showErrorDialog(
        Messages.createCommon.dependencyWarnings.vnetNotSelected.message(),
        Messages.createCommon.dependencyWarnings.vnetNotSelected.title(),
      );
    }
  };
  const onChangeVnet = (id: string): void => {
    setSelectedVnet(id);
  };
  const onChangeSubnet = (id: string): void => {
    setSelectedSubnet(id);
  };

  const vnetTooltip = FormattedString(
    { inputText: Messages.hints.tooltipVNET(vnetTroubleshooting) },
  ) as unknown as string;

  React.useMemo(() => {
    setSelectedVnet(undefined);
    setSelectedSubnet(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, location]);

  React.useEffect(() => {
    if (selectedAccessOptions === ACCESS_TYPES.EVERYWHERE) {
      setShowIPsCidrs(false);
      setRequireAuthKey(uniqueGUID());
      setDefaultValueRequireAuth(true);
      setDisableRequireAuth(true);
      if (enableAdbsOpenPL) {
        if (isRouteVNET) {
          setShowVnetAndSubNet(true);
        }
        setShowRouteVNET(true);
      } else {
        setShowRouteVNET(false);
        setShowVnetAndSubNet(false);
      }
      setShowNetworkPeering(false);
      setShowMcvcn(false);
    } else if (selectedAccessOptions === ACCESS_TYPES.ALLOWED_IPS) {
      setShowIPsCidrs(true);
      setDisableRequireAuth(false);
      if (enableAdbsRestrictedPL) {
        if (isRouteVNET) {
          setShowVnetAndSubNet(true);
        }
        setShowRouteVNET(true);
      } else {
        setShowRouteVNET(false);
        setShowVnetAndSubNet(false);
      }
      setShowNetworkPeering(false);
      setShowMcvcn(false);
    } else if (selectedAccessOptions === ACCESS_TYPES.PRIVATE) {
      setShowIPsCidrs(false);
      setDisableRequireAuth(false);
      setShowRouteVNET(false);
      if (enableMcvcn && enableAdbsAzurePEPeering) {
        setShowNetworkPeering(true);
        if (isNetworkPeering) {
          setShowVnetAndSubNet(false);
          setShowMcvcn(true);
        } else {
          setShowVnetAndSubNet(true);
          setShowMcvcn(false);
        }
      } else {
        setShowVnetAndSubNet(true);
        setShowNetworkPeering(false);
        setShowMcvcn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccessOptions]);

  const { trigger: triggerMcvcnCreateNew } = useOperation<McvcnCreateNewProps>(newMcvcnCreateNew);

  const onLinkClick = (): void => {
    if (!subscription || !location) {
      inputWizardPanelRef.showErrorDialog(
        Messages.createCommon.dependencyWarnings.basicsIncomplete.message(),
        Messages.createCommon.dependencyWarnings.basicsIncomplete.title(),
      );
      return;
    }

    triggerMcvcnCreateNew({
      location,
      subscriptionId: subscription,
      onExecute: (futureMcvcn: FutureMcvcnExisting | FutureMcvcnNew): void => {
        if (selectRef) {
          // Here is where the consuming form can format the values returned by the panel
          // Just alter the data passed to the setNewOption
          selectRef.setOptionNew(futureMcvcn.name, futureMcvcn);
        }
      },
    });
  };

  const createVcnLink = {
    id: "create_new_vcn",
    text: Messages.actions.createNew(),
    testId: LinkTestIds.CreateVcnLink,
    onLinkClick,
  };

  return (
    <>
      <FieldSet header="">
        <RadioGroup
          fieldName={Fields.NetworkAccessType}
          testId={FieldTestIds.NetworkAccessType}
          label={Messages.labels.accessType()}
          options={accessOptions}
          defaultValue={ACCESS_TYPES.EVERYWHERE}
          statusInfo={{
            messageType: InfoBlockStatus.INFO,
            infoLayout: InfoBlockLayout.Compact,
            message: accessTypeHints(
              accessFromEverywhere,
              accessFromAllowedIPs,
              enableAdbsAzurePE ? accessFromAzurePE : undefined,
            ),
            testId: InfoBlockTestIds.NetworkAccessType,
          }}
          onChange={(option: string) => setSelectedAccessOptions(option)}
        />
        {showIPsCidrs && (
          <AdbsNetworkAddressCollection
            label={Messages.labels.ipsAndOrCidrs()}
            tooltip={Messages.hints.ipsAndOrCidrs()}
            required
            defaultValue={addresses}
            onChange={setAddresses}
            fieldName={Fields.IPsCidrs}
            testId={FieldTestIds.IPsCidrs}
          />
        )}
        {showRouteVNET && (
          <CheckBox
            fieldName={Fields.RouteVNET}
            testId={FieldTestIds.RouteVNET}
            defaultValue={isRouteVNET}
            label={Messages.labels.routeVNET()}
            statusInfo={{
              messageType: InfoBlockStatus.INFO,
              infoLayout: InfoBlockLayout.Compact,
              message: Messages.hints.routeVNET(),
            }}
            onChange={(checked: boolean) => {
              setShowVnetAndSubNet(checked);
              setIsRouteVNET(checked);
            }}
          />
        )}
        {showNetworkPeering && (
          <CheckBox
            fieldName={Fields.NetworkPeering}
            testId={FieldTestIds.NetworkPeering}
            defaultValue={isNetworkPeering}
            label={Messages.labels.networkPeering()}
            statusInfo={{
              messageType: InfoBlockStatus.INFO,
              infoLayout: InfoBlockLayout.Compact,
              message: Messages.hints.networkPeering(),
            }}
            onChange={(checked: boolean) => {
              setShowVnetAndSubNet(!checked);
              setShowMcvcn(checked);
              setIsNetworkPeering(checked);
            }}
          />
        )}
        {showMcvcn && (
          <McvcnSelect
            required
            label={Messages.labels.virtualCloudNetwork()}
            componentRef={setSelectRef}
            location={location}
            testId={FieldTestIds.Mcvcn}
            fieldName={Fields.Mcvcn}
            groupName={GROUP_NETWORK}
            subscriptionId={subscription}
            inputLink={createVcnLink}
            onError={onError}
            onMissingDependencies={onMissingDependencies}
          />
        )}
        {showVnetAndSubNet && (
          <>
            <VNETSelect
              required
              defaultValue={selectedVnet ? [selectedVnet] : undefined}
              fieldName={Fields.Vnet}
              label={Messages.labels.virtualNetwork()}
              testId={FieldTestIds.Vnet}
              tooltip={vnetTooltip}
              subscriptionId={subscription}
              location={location}
              onChange={onChangeVnet}
              onError={onError}
              onMissingDependencies={onMissingDependencies}
            />
            <SubnetSelect
              required
              fieldName={Fields.Subnet}
              defaultValue={selectedSubnet ? [selectedSubnet] : undefined}
              label={Messages.labels.subnet()}
              testId={FieldTestIds.Subnet}
              vnetId={selectedVnet}
              subscriptionId={subscription}
              location={location}
              onError={onError}
              onMissingDependencies={onMissingDependencies}
              onChange={onChangeSubnet}
            />
          </>
        )}
        {!isApex && workload !== AdbWorkloadType.APEX && (
          <CheckBox
            key={requireAuthKey}
            defaultValue={defaultValueRequireAuth}
            disabled={disableRequireAuth}
            fieldName={Fields.MTLSAuthentication}
            testId={FieldTestIds.MTLSAuthentication}
            label={Messages.labels.requiretMTLSAuthentication()}
            statusInfo={{
              messageType: InfoBlockStatus.INFO,
              infoLayout: InfoBlockLayout.Compact,
              message: Messages.hints.requiretMTLSAuthentication(),
            }}
          />
        )}
      </FieldSet>
      {(selectedAccessOptions === ACCESS_TYPES.PRIVATE) && (
        <FieldSet header={Messages.createAdbs.networkingTab.sectionTitles.databaseAndToolsAccess()}>
          <TextInput
            fieldName={Fields.Hostname}
            testId={FieldTestIds.Hostname}
            required
            label={Messages.labels.hostName()}
            onChange={value => { setHostnameVal(value); }}
            validator={(value: string | undefined) => {
              const errors: string[] = [];
              if (!value) {
                errors.push(Messages.validation.required());
              }
              if (value && !startWithLetterRegex.test(value)) {
                errors.push(Messages.validation.nameStartChar());
              }
              if (value && !alphaNumericHyphenRegex.test(value)) {
                errors.push(Messages.validation.valueAlphaNumericHyphen());
              }
              if (value && value.length > adbsDbHostnamePrefixMaxLength) {
                errors.push(Messages.validation.valueMaxLen(adbsDbHostnamePrefixMaxLength.toString()));
              }
              return errors.length ? errors : undefined;
            }}
          />
          <TextInput
            fieldName={Fields.DatabaseAccessEndpoint}
            testId={FieldTestIds.DatabaseAccessEndpoint}
            label={Messages.labels.databaseAccessEndpoint()}
            placeholder={hostnameVal ? getDatabaseAccessEndpoint(hostnameVal, location) : ""}
            disabled
          />
          <TextInput
            fieldName={Fields.ToolsAccessEndpoint}
            testId={FieldTestIds.ToolsAccessEndpoint}
            label={Messages.labels.toolsAccessEndpoint()}
            placeholder={hostnameVal ? getToolsAccessEndpoint(hostnameVal, location) : ""}
            disabled
          />
        </FieldSet>
      )}
    </>
  );
};
