import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { SupportViewAllPanel, SupportViewAllValues } from "../../components/SupportViewAllPanel/SupportViewAllPanel";
import { OperationProps } from "../OperationTypes";

export interface SupportViewAllProps extends OperationProps {
  incidentId: string | undefined;
  supportViewAllValues: SupportViewAllValues;
}

const SupportViewAll = (
  { supportViewAllValues, onCancel }: SupportViewAllProps,
): JSX.Element => (
  <SupportViewAllPanel
    supportViewAllValues={supportViewAllValues}
    onClose={() => { onCancel?.(); }}
    title={Messages.labels.viewAll()}
  />
);

export const newSupportViewAll = (
  props: SupportViewAllProps,
): JSX.Element => (<SupportViewAll {...props} />);
