import {
  AsyncFieldError,
  AsyncValidationError,
  BookmarkablePage,
  ConfigInputValues,
  FormFieldErrors,
  FormValues,
  getValue,
  InputWizardItemMessage,
  InputWizardPanel,
  InputWizardPanelComponent,
  MessageType,
  OptionNewId,
  PanelMessage,
  ReviewItem,
  SelectOption,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import { useMatch } from "react-router-dom";
import { Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ExadataDbSystemShapeSelectFields } from "../../components/DbSystemShapeSelect/ExadataDbSystemShapeSelect";
import { DeploymentAsyncValidationPhase } from "../../components/DeploymentPhases/DeploymentAsyncValidationPhase";
import { DeployedService, DeploymentCreatePhase } from "../../components/DeploymentPhases/DeploymentCreationPhase";
import { DownloadSshKeyModal } from "../../components/SshKeyPair/DownloadSshKeyModal";
import {
  defaultName,
  DerivedFields as NestedSshInputFields,
  SshPublicKeySource,
} from "../../components/SshKeyPair/SshKeyPairInput";
import { GROUP_TAGS } from "../../components/Tags/TagsCollection";
import { ConsoleContext } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  DATABASE_RESOURCE_TYPE_PATH,
  EXADATA_SYSTEM_RESOURCE_TYPE_PATH,
  EXADB_CREATE_ROUTE,
  EXADB_FULL_CREATE_ROUTE,
  PageId,
  PageRegistrationConfig,
} from "../../constants/pluginConstants";
import { CreateWizardTestIds } from "../../constants/uiConstants";
import {
  AzureVirtualNetworkSummary,
  CreateCloudExadataInfrastructureDeploymentDetails,
  CreateCloudVmClusterDeploymentDetails,
  CreateDatabaseDeploymentDetails,
  CreateDeploymentArgs,
  CreateMultiCloudVirtualNetworkDeploymentDetails,
  CreateNetworkLinkDeploymentDetails,
  DbBackupConfig,
  ExadataDeploymentParametersDetails,
  GetCloudVmClusterDeploymentDetails,
  GetMultiCloudVirtualNetworkDeploymentDetails,
  MultiCloudVirtualNetworkDeploymentDetails,
  MultiCloudVirtualNetworkSummary,
  RegisterExistingOciNetworkDeploymentDetails,
} from "../../gen/clients/mchub-azure-api-client";
import {
  CreateCloudVmClusterDeploymentDetailsLicenseModelEnum,
  DbBackupConfigAutoBackupWindowEnum,
} from "../../gen/clients/mchub-azure-api-client-exa";
import { saveBlob } from "../../helpers/fileHelper";
import { buildResourceGroupId, parseId } from "../../helpers/idHelper";
import {
  DeploymentKinds,
  ExadataCreateFlowType,
  FutureMcvcn,
  FutureMcvcnExisting,
  FutureMcvcnNew,
  getAddressRange,
  getResourceTypeMessage,
  getTagsPerResourceTypeMap,
  ResourceType,
  TagsInfoType,
} from "../../helpers/resourceHelper";
import { RoleBasedAction } from "../../helpers/roleHelper";
import { getTimestamp, utc } from "../../helpers/timeHelper";
import { validateIpAddressWithinRanges } from "../../helpers/validationHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useRoles } from "../../hooks/useRoles";
import { useSidePanel } from "../../hooks/useSidePanel";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXADB_DEPLOYMENT_CREATE_POLL_DELAY,
  EXADB_DEPLOYMENT_CREATE_POLL_INTERVAL,
  EXASYS_DEPLOYMENT_CREATE_POLL_DELAY,
  EXASYS_DEPLOYMENT_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getDefaultDatabaseHomeName } from "../../operations/ExaDb/ExaDbCreateHome";
import { ReviewTabContent } from "../CreateCommon/ReviewTabContent";
import { Fields as TagsFields, TagsTabContent } from "../CreateCommon/TagsTabContent";
import { BasicsTabContent, Fields as BasicsFields, GROUP_BASICS } from "./BasicsTabContent";
import {
  BackupInfo,
  ConfigTabContent,
  Fields as ConfigFields,
  getDefaultExaInfraName,
  getDefaultVmClusterName,
  GROUP_CONFIG,
} from "./ConfigTabContent";
import { Fields as ManagementFields, GROUP_MANAGEMENT, ManagementTabContent } from "./ManagementTabContent";
import { Fields as NetworkFields, GROUP_NETWORK, NetworkTabContent } from "./NetworkTabContent";
import { Fields as SecurityFields, GROUP_SECURITY, SecurityTabContent } from "./SecurityTabContent";

const registrationIds = PageRegistrationConfig[PageId.EXADB_CREATE].map(config => config.key);

export enum PanelTestIds {
  Basics = "basic",
  Configuration = "configuration",
  Networking = "networking",
  Security = "security",
  Management = "management",
  Tags = "tags",
}

export const onProcessAsyncValidationErrors = (
  asyncErrors: AsyncValidationError[],
): AsyncValidationError[] => asyncErrors.map(asyncError => {
  if (typeof asyncError === "string") {
    return asyncError;
  }

  const asyncFieldError = asyncError as AsyncFieldError;

  if (asyncFieldError.field.startsWith("multiCloudVirtualNetworkDetails.")) {
    let fieldLabel: string;

    if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.name") {
      fieldLabel = Messages.labels.name();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.azureAttachedNetworkIds") {
      fieldLabel = Messages.labels.virtualNetwork();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociVcnCidrBlocks") {
      fieldLabel = Messages.labels.cidrBlocks();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociVcnOcid") {
      fieldLabel = Messages.labels.vcnOcid();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.ociSubnetOcids") {
      fieldLabel = Messages.labels.subnetOcids();
    } else if (asyncFieldError.field === "multiCloudVirtualNetworkDetails.customerNvaIpAddress") {
      fieldLabel = Messages.labels.networkVirtualAppliance();
    } else {
      return asyncFieldError;
    }

    asyncFieldError.field = NetworkFields.Mcvcn;
    asyncFieldError.error = `<strong>(${Messages.common.new()} | ${fieldLabel})</strong> ${asyncFieldError.error}`;
  } else if (asyncFieldError.field === "cloudVmClusterDetails.isLocalBackupEnabled") {
    const fieldLabel = Messages.labels.allocateStorageLocal();
    asyncFieldError.field = ConfigFields.StorageConfiguration;
    asyncFieldError.error = `<strong>(${fieldLabel})</strong> ${asyncFieldError.error}`;
  } else if (asyncFieldError.field === "cloudVmClusterDetails.isSparseDiskgroupEnabled") {
    const fieldLabel = Messages.labels.allocateStorageSparse();
    asyncFieldError.field = ConfigFields.StorageConfiguration;
    asyncFieldError.error = `<strong>(${fieldLabel})</strong> ${asyncFieldError.error}`;
  } else if (
    asyncFieldError.field === "cloudExadataInfrastructureDetails.computeCount"
    || asyncFieldError.field === "cloudExadataInfrastructureDetails.storageCount"
  ) {
    asyncFieldError.field = ConfigFields.ExadataSystemModel;
  // [BEGIN] *** backup mappings - may need to be adjusted when creating an exaxdb from backup gets enabled
  } else if (asyncFieldError.field === "database.dbName") {
    asyncFieldError.field = ConfigFields.DatabaseName;
  } else if (asyncFieldError.field === "database.adminPassword") {
    asyncFieldError.field = SecurityFields.Password;
  } else if (asyncFieldError.field === "database.tdeWalletPassword") {
    asyncFieldError.field = SecurityFields.TdePassword;
  } // [END] *** backup mappings

  return asyncFieldError;
});

export enum ActionIds {
  DownloadSshKey = "download-ssh-key",
}

enum CreationPhase {
  ASYNC_VALIDATE = "ASYNC_VALIDATE",
  CREATE_SUBMIT = "CREATE_SUBMIT",
}

export interface ExaDbCreatePageProps {
  createFlowType?: ExadataCreateFlowType;
}

export const ExaDbCreatePage = (
  { createFlowType: createType = ExadataCreateFlowType.EXADATA_DATABASE }: ExaDbCreatePageProps,
): JSX.Element => {
  const { enableMcvcn, enableExaMcvcnCreate, enableExaDbPreflight, enableExaDbHome } = useFeatures();
  const fullCreatePathMatch = useMatch(EXADB_FULL_CREATE_ROUTE);

  const [createFlowType, setCreateFlowType] = React.useState<ExadataCreateFlowType>(createType);

  const { isActionAllowed, actionRequiredRoles } = useRoles();
  const isCreateSystemRoleMissing = !isActionAllowed(RoleBasedAction.CREATE_EXA_SYSTEM);
  const systemRequiredRole = actionRequiredRoles(RoleBasedAction.CREATE_EXA_SYSTEM)[0]?.displayName;
  const isCreateDbRoleMissing = !isActionAllowed(RoleBasedAction.CREATE_EXA_CDB);
  const dbRequiredRole = actionRequiredRoles(RoleBasedAction.CREATE_EXA_CDB)[0]?.displayName;

  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { back, navigateToSelf, customData } = useNavigation(ConsoleContext);

  const { preferredLocationPerSubscription, preferredSubscription } = React.useContext<Settings>(SettingsContext);
  const preferredLocation = preferredLocationPerSubscription[preferredSubscription] || "";

  const [subscriptionId, setSubscriptionId] = React.useState<string>("");
  const [resourceGroupName, setResourceGroupName] = React.useState<string>("");
  const [vmClusterId, setVmClusterId] = React.useState<string>("");
  const [backup, setBackup] = React.useState<BackupInfo>();
  const [location, setLocation] = React.useState<string>("");

  const [basicsDisabledFields, setBasicsDisabledFields] = React.useState<BasicsFields[] | undefined>(undefined);
  const [configDisabledFields, setConfigDisabledFields] = React.useState<ConfigFields[] | undefined>(undefined);

  const [createExaDbPayload, setCreateExaDbPayload] = React.useState<CreateDeploymentArgs>();
  const [createExaSysPayload, setCreateExaSysPayload] = React.useState<CreateDeploymentArgs>();

  const [exaName, setExaName] = React.useState<string>("");

  const [creationPhase, setCreationPhase] = React.useState<CreationPhase>();

  const asyncValidationCallbacks = React.useRef<{
    resolve:(errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ resolve: () => {}, reject: () => {} });
  const submitCallbacks = React.useRef<{
    reject:(errorMessage: string, validationErrors?: AsyncValidationError[]) => void
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }>({ reject: () => {} });

  const deploymentNameRef = React.useRef<string>(""); // to hold the name to be used in failure message
  const resourceNameRef = React.useRef<string>("");

  const [
    displayDownloadSshKey,
    { setFalse: hideDownloadSshKey, setTrue: showDownloadSshKey },
  ] = useBoolean(false);

  React.useEffect(() => {
    if (customData?.location || customData?.vmClusterId || customData?.backup) {
      const basicsFieldsToDisable = [];
      const configFieldsToDisable = [];
      if (customData?.location) {
        basicsFieldsToDisable.push(BasicsFields.Region);

        setLocation(customData.location);
      }
      if (customData?.vmClusterId) {
        basicsFieldsToDisable.push(BasicsFields.Subscription, BasicsFields.ResourceGroup);
        configFieldsToDisable.push(ConfigFields.VMCluster);

        setVmClusterId(customData.vmClusterId);
        setSubscriptionId(parseId(customData.vmClusterId)?.subscriptionId);
      }
      if (customData?.backup) {
        configFieldsToDisable.push(ConfigFields.BackupName);

        setBackup(customData.backup);
        setCreateFlowType(ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP);
      }

      if (basicsFieldsToDisable.length > 0) {
        setBasicsDisabledFields(basicsFieldsToDisable);
      }
      if (configFieldsToDisable.length > 0) {
        setConfigDisabledFields(configFieldsToDisable);
      }
    } else {
      setLocation(preferredLocation);
      setSubscriptionId(preferredSubscription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredLocation, preferredSubscription]);

  React.useEffect(() => {
    // In case page was navigated to directly by entring its URL in the browser
    if (fullCreatePathMatch) {
      navigateToSelf(EXADB_FULL_CREATE_ROUTE, registrationIds[0]);
    } else {
      navigateToSelf(EXADB_CREATE_ROUTE, registrationIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [panelRef, setPanelRef] = React.useState<InputWizardPanelComponent>({} as InputWizardPanelComponent);
  const { closePanels } = useSidePanel();

  const onClose = (): void => back();
  const buildDbBackupConfig = (formValues: FormValues): DbBackupConfig => {
    const autoBackupEnabled = getValue<boolean>(formValues, ManagementFields.Backups, GROUP_MANAGEMENT);
    const backupWindow = getValue<SelectOption[]>(
      formValues,
      ManagementFields.Scheduling,
      GROUP_MANAGEMENT,
    )?.[0].id as DbBackupConfigAutoBackupWindowEnum | undefined;

    const recoveryWindow = getValue<SelectOption[]>(
      formValues,
      ManagementFields.RetentionPeriod,
      GROUP_MANAGEMENT,
    )?.[0].id;
    const recoveryWindowInDays = recoveryWindow ? parseInt(recoveryWindow, 10) : undefined;

    const backupDetails: DbBackupConfig = {
      autoBackupEnabled,
      autoBackupWindow: autoBackupEnabled ? backupWindow : undefined,
      recoveryWindowInDays: autoBackupEnabled ? recoveryWindowInDays : undefined,
    };
    return backupDetails;
  };
  const buildDbDeploymentDetails = (formValues: FormValues): CreateDatabaseDeploymentDetails => {
    const name = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS) ?? "";
    resourceNameRef.current = name;
    const tags = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS);
    const tagsMap = getTagsPerResourceTypeMap(tags);
    const pdbName = getValue<string>(formValues, ConfigFields.PdbName, GROUP_CONFIG);
    const dbHomeSelectValue = getValue<SelectOption[]>(formValues, ConfigFields.DatabaseHome, GROUP_CONFIG)?.[0];
    let dbHomeName;
    let dbHomeId;
    let dbVersion = getValue<SelectOption[]>(formValues, ConfigFields.DatabaseVersion, GROUP_CONFIG)?.[0].id;
    if (enableExaDbHome) {
      if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
        dbHomeName = getValue<string>(formValues, ConfigFields.DatabaseHomeName, GROUP_CONFIG)
          || getDefaultDatabaseHomeName();
      } else if (createFlowType === ExadataCreateFlowType.EXADATA_DATABASE) {
        dbVersion = dbHomeSelectValue?.data.databaseHomeVersion;
        if (dbHomeSelectValue?.id === OptionNewId) {
          dbHomeName = dbHomeSelectValue?.data.databaseHomeName;
        } else {
          dbHomeId = dbHomeSelectValue?.id;
        }
      }
    }
    const databaseDetails: CreateDatabaseDeploymentDetails = {
      kind: DeploymentKinds.Create,
      name,
      dbName: getValue<string>(formValues, ConfigFields.DatabaseName, GROUP_CONFIG) ?? "",
      pdbName,
      adminPassword: getValue<string>(formValues, SecurityFields.Password, GROUP_SECURITY) ?? "",
      dbBackupConfig: buildDbBackupConfig(formValues),
      tdeWalletPassword: getValue<boolean>(formValues, SecurityFields.UseDifferentTDEPassword, GROUP_SECURITY)
        ? getValue<string>(formValues, SecurityFields.TdePassword, GROUP_SECURITY)
        : undefined,
      freeformTags: tagsMap[ResourceType.DATABASE],
      dbVersion,
      dbHomeName,
      dbHomeId,
      characterSet: getValue<SelectOption[]>(formValues, ConfigFields.CharacterSet, GROUP_CONFIG)?.[0].id,
      ncharacterSet: getValue<SelectOption[]>(formValues, ConfigFields.NationalCharacterSet, GROUP_CONFIG)?.[0].id,
    };
    return databaseDetails;
  };

  const buildMcvcn = (formValues: FormValues): MultiCloudVirtualNetworkDeploymentDetails => {
    // eslint-disable-next-line max-len
    const data: FutureMcvcnNew | FutureMcvcnExisting | MultiCloudVirtualNetworkSummary | undefined = getValue<SelectOption[]>(
      formValues,
      NetworkFields.Mcvcn,
      GROUP_NETWORK,
    )?.[0]?.data;

    // Only exists if the user has selected an existing MCVCN
    const { id } = data as MultiCloudVirtualNetworkSummary;
    // Exists if the user is creating a new MCVCN
    const { name, azureAttachedNetworkId, customerNvaIpAddress } = data as FutureMcvcn;
    // Exists if the user is creating a new MCVCN using an existing OCI VCN
    const { ociSubnetOcids, ociVcnOcid } = data as FutureMcvcnExisting;
    // Exists if the user is creating a new MCVCN using a new OCI VCN
    const { ociVcnCidrBlocks } = data as FutureMcvcnNew;

    let mcvcnDetails: MultiCloudVirtualNetworkDeploymentDetails;
    const azureAttachedNetworkIds = [azureAttachedNetworkId];

    if (id) {
      mcvcnDetails = {
        kind: DeploymentKinds.Get,
        id,
      } as GetMultiCloudVirtualNetworkDeploymentDetails;
    } else if (ociVcnCidrBlocks) {
      mcvcnDetails = {
        kind: DeploymentKinds.Create,
        azureAttachedNetworkIds,
        name,
        ociVcnCidrBlocks,
        customerNvaIpAddress,
      } as CreateMultiCloudVirtualNetworkDeploymentDetails;
    } else if (ociVcnOcid && ociSubnetOcids) {
      mcvcnDetails = {
        kind: DeploymentKinds.Register,
        azureAttachedNetworkIds,
        name,
        ociVcnOcid,
        ociSubnetOcids,
        customerNvaIpAddress,
      } as RegisterExistingOciNetworkDeploymentDetails;
    } else throw new Error("Invalid parameters to create MCVCN");

    return mcvcnDetails;
  };

  const onAsyncValidate = (
    formValues: FormValues,
    resolve: (errors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
  ): void => {
    if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
      setCreateExaSysPayload(buildExaSysPayload(formValues));
    } else {
      setCreateExaDbPayload(buildExaDbPayload(formValues));
    }

    asyncValidationCallbacks.current = { resolve, reject };
    setCreationPhase(CreationPhase.ASYNC_VALIDATE);
  };

  const buildExaSysPayload = (formValues: FormValues): CreateDeploymentArgs => {
    const name = getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS) ?? "";
    resourceNameRef.current = name;
    const newComputeCount = getValue<string>(
      formValues,
      ExadataDbSystemShapeSelectFields.DatabaseServers,
      GROUP_CONFIG,
    );
    const computeCount = newComputeCount ? parseInt(newComputeCount, 10) : 0;
    const newStorageCount = getValue<string>(formValues, ExadataDbSystemShapeSelectFields.StorageServers, GROUP_CONFIG);
    const storageCount = newStorageCount ? parseInt(newStorageCount, 10) : 0;
    const cidrRange = getValue<string>(formValues, NetworkFields.Cidrs, GROUP_NETWORK);
    const cidr = cidrRange ? [cidrRange] : [];

    const tags = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS);
    const tagsMap = getTagsPerResourceTypeMap(tags);

    const shape = getValue<SelectOption[]>(formValues, ConfigFields.ExadataSystemModel, GROUP_CONFIG)?.[0].id ?? "";

    const cloudExadataInfrastructureDetails: CreateCloudExadataInfrastructureDeploymentDetails = {
      kind: DeploymentKinds.Create,
      name: getValue<string>(formValues, ConfigFields.ExaInfraName, GROUP_CONFIG)
        || getDefaultExaInfraName(exaName),
      computeCount: computeCount > 0 ? computeCount : undefined,
      storageCount: storageCount > 0 ? storageCount : undefined,
      shape,
      maintenanceWindow: { preference: "NO_PREFERENCE" },
      freeformTags: tagsMap[ResourceType.INFRA],
    };

    const sshPublicKey = getValue<Record<NestedSshInputFields, string>>(
      formValues,
      SecurityFields.SshKeyPair,
      GROUP_SECURITY,
    )?.[NestedSshInputFields.PublicKey];

    const licenseModel = getValue<SelectOption[]>(
      formValues,
      ConfigFields.LicenseType,
      GROUP_CONFIG,
    )?.[0].id as CreateCloudVmClusterDeploymentDetailsLicenseModelEnum | undefined;

    const cpuCoreCount = getValue<ConfigInputValues>(
      formValues,
      ConfigFields.VmClusterConfiguration,
      GROUP_CONFIG,
    )?.value.count;

    const giVersion = getValue<SelectOption[]>(
      formValues,
      ConfigFields.OracleGridInfrastructureVersion,
      GROUP_CONFIG,
    )?.[0].id ?? "";

    const hostname = getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK) ?? "";

    const storageConfig = getValue<ConfigInputValues>(
      formValues,
      ConfigFields.StorageConfiguration,
      GROUP_CONFIG,
    )?.value;

    const cloudVmClusterDetails: CreateCloudVmClusterDeploymentDetails = {
      kind: DeploymentKinds.Create,
      name: getValue<string>(formValues, ConfigFields.VMClusterName, GROUP_CONFIG) || getDefaultVmClusterName(exaName),
      licenseModel,
      sshPublicKeys: sshPublicKey ? [sshPublicKey] : [],
      cpuCoreCount,
      giVersion,
      hostname,
      freeformTags: tagsMap[ResourceType.VM],
      isLocalBackupEnabled: storageConfig.localEnabled,
      isSparseDiskgroupEnabled: storageConfig.sparseEnabled,
    };

    let networkLinkDetails: CreateNetworkLinkDeploymentDetails = {} as CreateNetworkLinkDeploymentDetails;
    if (!(enableMcvcn && enableExaMcvcnCreate)) {
      const vnet = getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].id;
      const azureAttachedNetworkIds = vnet ? [vnet] : [];

      networkLinkDetails = {
        kind: DeploymentKinds.Create,
        name: `${name}-networkLink`,
        ociNetworkCidrs: cidr,
        azureAttachedNetworkIds,
        customerNvaIpAddress: getValue<string>(formValues, NetworkFields.NetworkVirtualAppliance, GROUP_NETWORK),
      };
    }

    deploymentNameRef.current = `${EXADATA_SYSTEM_RESOURCE_TYPE_PATH}-${getTimestamp()}`;
    const createDeploymentDetails = {
      name: deploymentNameRef.current,
      parameters: {
        kind: DeploymentKinds.Exadata,
        databaseDetails: buildDbDeploymentDetails(formValues),
        networkLinkDetails: !(enableMcvcn && enableExaMcvcnCreate) ? networkLinkDetails : undefined,
        multiCloudVirtualNetworkDetails: enableMcvcn && enableExaMcvcnCreate ? buildMcvcn(formValues) : undefined,
        cloudVmClusterDetails,
        cloudExadataInfrastructureDetails,
      } as ExadataDeploymentParametersDetails,
    };

    const subscription = getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].id ?? "";
    const resourceGroup = getValue<SelectOption[]>(
      formValues,
      BasicsFields.ResourceGroup,
      GROUP_BASICS,
    )?.[0].text ?? "";

    return {
      subscriptionId: subscription,
      resourceGroupName: resourceGroup,
      apiVersion: MultiCloudDatabaseApiVersion,
      createDeploymentDetails,
    };
  };

  const buildExaDbPayload = (formValues: FormValues): CreateDeploymentArgs => {
    const id = getValue<SelectOption[]>(formValues, ConfigFields.VMCluster, GROUP_CONFIG)?.[0].id ?? "";
    const cloudVmClusterDetails: GetCloudVmClusterDeploymentDetails = {
      kind: DeploymentKinds.Get,
      id,
    };

    deploymentNameRef.current = `${DATABASE_RESOURCE_TYPE_PATH}-${getTimestamp()}`;

    const createDeploymentDetails = {
      name: deploymentNameRef.current,
      parameters: {
        kind: DeploymentKinds.Exadata,
        databaseDetails: buildDbDeploymentDetails(formValues),
        cloudVmClusterDetails,
      } as ExadataDeploymentParametersDetails,
    };

    const subscription = getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].id ?? "";
    const resourceGroup = getValue<SelectOption[]>(
      formValues,
      BasicsFields.ResourceGroup,
      GROUP_BASICS,
    )?.[0].text ?? "";

    return {
      subscriptionId: subscription,
      resourceGroupName: resourceGroup,
      apiVersion: MultiCloudDatabaseApiVersion,
      // createNewDatabaseDetails: createFlowType === ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP
      //   ? buildDbFromBackup(formValues)
      //   : buildDb(formValues),
      createDeploymentDetails,
    };
  };

  const onSubmit = (formValues: FormValues): void => {
    if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
      setCreateExaSysPayload(buildExaSysPayload(formValues));
    } else {
      setCreateExaDbPayload(buildExaDbPayload(formValues));
    }

    setCreationPhase(CreationPhase.CREATE_SUBMIT);
  };

  const onConfirmDownloadSshKey = (): void => {
    const privateKey = getValue<Record<NestedSshInputFields, string>>(
      panelRef.getFormValues?.() ?? {},
      SecurityFields.SshKeyPair,
      GROUP_SECURITY,
    )?.[NestedSshInputFields.PrivateKey];

    if (privateKey) {
      saveBlob(privateKey, `${defaultName()}.key`);
    }

    onSubmit(panelRef.getFormValues() ?? {});
  };

  const onFormSubmit = (formValues: FormValues): void => {
    const sshKeySourceFieldName = SecurityFields.SshKeyPair + NestedSshInputFields.Source;
    const sshKeySource = getValue<SelectOption[]>(formValues, sshKeySourceFieldName, GROUP_SECURITY)?.[0].id;

    // if the ssh key is set to generate
    if (SshPublicKeySource.Generate === sshKeySource) {
      /**
       * Normally we should not track action click in onSubmit but this is an exception
       * since the dialog is opened automatically by the wizard and not as a result of
       * a user action.
       */
      trackActionClick(ActionIds.DownloadSshKey, PageId.EXADB_CREATE);
      // then prompt for download of the SSH key
      showDownloadSshKey();
    } else {
      onSubmit(formValues);
    }
  };

  // *** [NOTE]:  Check 'processCreateNewAsyncErrors' function to ensure the
  //              fields that were mapped for the backup are stil correct

  // const buildDbFromBackup = (formValues: FormValues): CreateDatabaseFromBackup => {
  //   const DbBase: CreateDatabaseFromBackup = {
  //     vmClusterId: getValue<SelectOption[]>(formValues, ConfigFields.VMCluster, GROUP_CONFIG)?.[0].id,
  //     source: "DB_BACKUP",
  //     database: buildDbFromBackupDetails(formValues),
  //   };
  //   return DbBase;
  // };

  // const buildDbFromBackupDetails = (formValues: FormValues): CreateDatabaseFromBackupDetails => {
  //   const dbFromBackupDetails: CreateDatabaseFromBackupDetails = {
  //     backupId: backup?.id || "",
  //     dbName: getValue<string>(formValues, ConfigFields.DatabaseName, GROUP_CONFIG),
  //     adminPassword: getValue<string>(formValues, SecurityFields.password, GROUP_SECURITY),
  //     tdeWalletPassword: getValue<string>(formValues, SecurityFields.tdePassword, GROUP_SECURITY),
  //   };
  //   return dbFromBackupDetails;
  // };

  const buildBasicInfo = (formValues: FormValues): ReviewItem[] => {
    const reviewBasicInfo = [
      {
        label: Messages.labels.subscription(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Subscription, GROUP_BASICS)?.[0].text,
      },
      {
        label: Messages.labels.resourceGroup(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.ResourceGroup, GROUP_BASICS)?.[0].text,
      },
      {
        label: createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM
          ? Messages.labels.exadataSystemName()
          : Messages.labels.exadataDatabaseName(),
        value: getValue<string>(formValues, BasicsFields.Name, GROUP_BASICS),
      },
      {
        label: Messages.labels.region(),
        value: getValue<SelectOption[]>(formValues, BasicsFields.Region, GROUP_BASICS)?.[0].text,

      },
    ];
    return reviewBasicInfo;
  };

  const buildConfigInfoExaSys = (formValues: FormValues): ReviewItem[] => {
    const databaseServers = getValue<string>(
      formValues,
      ExadataDbSystemShapeSelectFields.DatabaseServers,
      GROUP_CONFIG,
    );
    const storageServers = getValue<string>(formValues, ExadataDbSystemShapeSelectFields.StorageServers, GROUP_CONFIG);
    const storageOptions = getValue<ConfigInputValues>(
      formValues,
      ConfigFields.StorageConfiguration,
      GROUP_CONFIG,
    )?.value;

    const reviewConfigInfo = [
      {
        label: Messages.labels.exadataInfraName(),
        value: getValue<string>(formValues, ConfigFields.ExaInfraName, GROUP_CONFIG) || getDefaultExaInfraName(exaName),
      },
      {
        label: Messages.labels.exadataSystemModel(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.ExadataSystemModel, GROUP_CONFIG)?.[0].text,
      },
      {
        label: Messages.labels.databaseServers(),
        value: databaseServers,
      },
      {
        label: Messages.labels.storageServers(),
        value: storageServers,
      },
    ];
    if (enableExaDbHome) {
      reviewConfigInfo.push(
        {
          label: Messages.labels.databaseHomeName(),
          value: getValue<string>(formValues, ConfigFields.DatabaseHomeName, GROUP_CONFIG)
            || getDefaultDatabaseHomeName(),
        },
        {
          label: Messages.labels.databaseVersion(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.DatabaseVersion, GROUP_CONFIG)?.[0].text,
        },
      );
    }

    const vmClusterConfig = getValue<ConfigInputValues>(formValues, ConfigFields.VmClusterConfiguration, GROUP_CONFIG);
    const giVersion = getValue<SelectOption[]>(
      formValues,
      ConfigFields.OracleGridInfrastructureVersion,
      GROUP_CONFIG,
    )?.[0].text;

    reviewConfigInfo.push(
      {
        label: Messages.labels.vmClusterName(),
        value: getValue<string>(formValues, ConfigFields.VMClusterName, GROUP_CONFIG)
          || getDefaultVmClusterName(exaName),
      },
      {
        label: Messages.labels.vmClusterConfiguration(),
        value: vmClusterConfig?.title as string || vmClusterConfig?.summary as string,
      },
      {
        label: Messages.labels.allocateStorageLocal(),
        value: storageOptions?.localEnabled ? Messages.common.yes() : Messages.common.no(),
      },
      {
        label: Messages.labels.allocateStorageSparse(),
        value: storageOptions?.sparseEnabled ? Messages.common.yes() : Messages.common.no(),
      },
      {
        label: Messages.labels.oracleGridInfrastructureVersion(),
        value: giVersion,
      },
      {
        label: Messages.labels.licenseType(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.LicenseType, GROUP_CONFIG)?.[0].text,
      },
    );
    if (!enableExaDbHome) {
      reviewConfigInfo.push(
        {
          label: Messages.labels.databaseVersion(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.DatabaseVersion, GROUP_CONFIG)?.[0].text,
        },
      );
    }
    reviewConfigInfo.push(
      {
        label: Messages.labels.databaseName(),
        value: getValue<string>(formValues, ConfigFields.DatabaseName, GROUP_CONFIG),
      },
      {
        label: Messages.labels.pdbName(),
        value: getValue<string>(formValues, ConfigFields.PdbName, GROUP_CONFIG),
      },
      {
        label: Messages.labels.characterSet(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.CharacterSet, GROUP_CONFIG)?.[0].text,
      },
      {
        label: Messages.labels.nationalCharacterSet(),
        value: getValue<SelectOption[]>(formValues, ConfigFields.NationalCharacterSet, GROUP_CONFIG)?.[0].text,
      },
    );
    return reviewConfigInfo;
  };

  const buildConfigInfo = (formValues: FormValues): ReviewItem[] => {
    if (createFlowType === ExadataCreateFlowType.EXADATA_DATABASE) {
      const dbHomeSelectValue = getValue<SelectOption[]>(formValues, ConfigFields.DatabaseHome, GROUP_CONFIG)?.[0];
      const reviewConfigInfo = [
        {
          label: Messages.labels.vmCluster(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.VMCluster, GROUP_CONFIG)?.[0].text,
        },
      ];
      if (enableExaDbHome) {
        reviewConfigInfo.push(
          {
            label: Messages.labels.databaseHomeName(),
            value: dbHomeSelectValue?.data.databaseHomeName,
          },
          {
            label: Messages.labels.databaseVersion(),
            value: dbHomeSelectValue?.data.databaseHomeVersion,
          },
        );
      } else {
        reviewConfigInfo.push(
          {
            label: Messages.labels.databaseVersion(),
            value: getValue<SelectOption[]>(formValues, ConfigFields.DatabaseVersion, GROUP_CONFIG)?.[0].text,
          },
        );
      }
      reviewConfigInfo.push(
        {
          label: Messages.labels.databaseName(),
          value: getValue<string>(formValues, ConfigFields.DatabaseName, GROUP_CONFIG),
        },
        {
          label: Messages.labels.pdbName(),
          value: getValue<string>(formValues, ConfigFields.PdbName, GROUP_CONFIG),
        },
        {
          label: Messages.labels.characterSet(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.CharacterSet, GROUP_CONFIG)?.[0].text,
        },
        {
          label: Messages.labels.nationalCharacterSet(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.NationalCharacterSet, GROUP_CONFIG)?.[0].text,
        },
      );
      return reviewConfigInfo;
    }
    if (createFlowType === ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP) {
      const reviewConfigInfo = [
        {
          label: Messages.labels.vmCluster(),
          value: getValue<SelectOption[]>(formValues, ConfigFields.VMCluster, GROUP_CONFIG)?.[0].text,
        },
        {
          label: Messages.labels.databaseBackupName(),
          value: getValue<string>(formValues, ConfigFields.BackupName, GROUP_CONFIG),
        },
        {
          label: Messages.labels.databaseName(),
          value: getValue<string>(formValues, ConfigFields.DatabaseName, GROUP_CONFIG),
        },
      ];
      return reviewConfigInfo;
    }
    return buildConfigInfoExaSys(formValues);
  };

  const buildSecurityInfo = (formValues: FormValues): ReviewItem[] => {
    const sshKeySourceFieldName = SecurityFields.SshKeyPair + NestedSshInputFields.Source;
    const sshKeySource = getValue<SelectOption[]>(formValues, sshKeySourceFieldName, GROUP_SECURITY)?.[0].id;
    const useDifferentTdePassword = getValue<boolean>(
      formValues,
      SecurityFields.UseDifferentTDEPassword,
      GROUP_SECURITY,
    ) as boolean;

    const sshKeyValue = sshKeySource !== undefined ? sshKeySource === SshPublicKeySource.Upload
      ? Messages.common.uploaded() : Messages.common.generated() : Messages.common.no();
    const reviewSecurityInfo = [];
    if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
      reviewSecurityInfo.push(
        {
          label: Messages.labels.sshPublicKey(),
          value: sshKeyValue,
        },
      );
    }
    reviewSecurityInfo.push(
      {
        label: Messages.labels.username(),
        value: getValue<string>(formValues, SecurityFields.Username, GROUP_SECURITY),
      },
    );
    if (createFlowType !== ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP) {
      reviewSecurityInfo.push(
        {
          label: Messages.labels.useDifferentTDEPassword(),
          value: useDifferentTdePassword ? Messages.common.yes() : Messages.common.no(),
        },
      );
    }
    return reviewSecurityInfo;
  };

  const buildManagementInfo = (formValues: FormValues): ReviewItem[] => {
    const autoBackupEnabled = getValue<boolean>(formValues, ManagementFields.Backups, GROUP_MANAGEMENT);
    const reviewManagementInfo: ReviewItem[] = [
      {
        label: Messages.labels.automaticBackups(),
        value: autoBackupEnabled ? Messages.common.enabled() : Messages.common.disabled(),
      },
    ];
    if (autoBackupEnabled) {
      reviewManagementInfo.push(
        {
          label: Messages.labels.backupRetentionPeriod(),
          value: getValue<SelectOption[]>(formValues, ManagementFields.RetentionPeriod, GROUP_MANAGEMENT)?.[0].text,
        },
        {
          label: `${Messages.labels.backupScheduling()} (${utc})`,
          value: getValue<SelectOption[]>(formValues, ManagementFields.Scheduling, GROUP_MANAGEMENT)?.[0].text,
        },
      );
    }
    return reviewManagementInfo;
  };

  const buildNetworkingInfo = (formValues: FormValues): ReviewItem[] => {
    const renderList = (list: string[]): JSX.Element => {
      const elements = list.map(item => (
        <Stack horizontal key={item}>
          <span style={{ paddingBottom: 1 }}>
            {item}
          </span>
        </Stack>
      ));
      return (
        <div>
          {elements}
        </div>
      );
    };
    const reviewNetworkingInfo: ReviewItem[] = [];

    if (!(enableMcvcn && enableExaMcvcnCreate)) {
      const cidrRange = getValue<string>(formValues, NetworkFields.Cidrs, GROUP_NETWORK);
      const cidrAddresses = getAddressRange(cidrRange);
      const cidr = [`${cidrRange} [${cidrAddresses}]`];
      const networkVirtualAppliance = getValue<string>(
        formValues,
        NetworkFields.NetworkVirtualAppliance,
        GROUP_NETWORK,
      );

      reviewNetworkingInfo.push(
        {
          label: Messages.labels.hostName(),
          value: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK),
        },
        {
          label: Messages.labels.virtualNetwork(),
          value: getValue<SelectOption[]>(formValues, NetworkFields.Vnet, GROUP_NETWORK)?.[0].text,
        },
        ...(networkVirtualAppliance
          ? [{ label: Messages.labels.networkVirtualAppliance(), value: networkVirtualAppliance }]
          : []),
        {
          label: Messages.labels.ociCidrs(),
          value: renderList(cidr),
        },
      );
    }

    if (enableMcvcn && enableExaMcvcnCreate) {
      // eslint-disable-next-line max-len
      const mcvcn: MultiCloudVirtualNetworkSummary | FutureMcvcnExisting | FutureMcvcnNew | undefined = getValue<SelectOption[]>(
        formValues,
        NetworkFields.Mcvcn,
        GROUP_NETWORK,
      )?.[0].data;

      if (!mcvcn) return [];

      // Only exists if the user has selected an existing MCVCN
      const createNewMcvcn = !(mcvcn as MultiCloudVirtualNetworkSummary).id;

      // Exists if the user is creating a new MCVCN
      const { name, customerNvaIpAddress, azureAttachedNetworkId } = mcvcn as FutureMcvcn;
      const virtualNetwork = decodeURIComponent(parseId(azureAttachedNetworkId)?.resourceName || "");

      // Exists if the user is creating a new MCVCN using a new OCI VCN
      const { ociVcnCidrBlocks } = mcvcn as FutureMcvcnNew;
      // Exists if the user is creating a new MCVCN using an existing OCI VCN
      const { ociSubnetOcids, ociVcnOcid } = mcvcn as FutureMcvcnExisting;

      reviewNetworkingInfo.push(
        {
          label: Messages.labels.hostName(),
          value: getValue<string>(formValues, NetworkFields.Hostname, GROUP_NETWORK),
        },
        {
          label: Messages.labels.virtualCloudNetwork(),
          value: name,
        },
      );

      if (createNewMcvcn) {
        reviewNetworkingInfo.push(
          ...(virtualNetwork
            ? [{ label: Messages.labels.virtualNetwork(), value: virtualNetwork }]
            : []),
          ...(customerNvaIpAddress
            ? [{ label: Messages.labels.networkVirtualAppliance(), value: customerNvaIpAddress }]
            : []),
          ...(ociVcnOcid
            ? [{ label: Messages.labels.vcnOcid(), value: ociVcnOcid }]
            : []),
          ...(ociSubnetOcids
            ? [{ label: Messages.labels.subnetOcids(), value: renderList(ociSubnetOcids) }]
            : []),
          ...(ociVcnCidrBlocks
            ? [{
              label: Messages.labels.cidrBlocks(),
              value: renderList(ociVcnCidrBlocks.map(cidr => `${cidr} [${getAddressRange(cidr)}]`)),
            }]
            : []),
        );
      }
    }

    return reviewNetworkingInfo;
  };

  const buildTagsInfo = (formValues: FormValues): ReviewItem[] => {
    const tagsInfo = getValue<TagsInfoType>(formValues, TagsFields.Tags, GROUP_TAGS) ?? [];
    const tags: { label: string; value: string; }[] = [];

    tagsInfo.forEach(element => (
      element.resources?.forEach(resource => (
        element.name !== undefined && tags.push({
          label: element.name,
          value: `${element.value !== undefined ? element.value : ""}(${resource.text})`,
        })
      ))));
    if (tags.length === 0) tags.push({ label: "None", value: "" });
    tags.sort((a, b) => ((a.value.split("(")[1].split(")")[0] > b.value.split("(")[1].split(")")[0]) ? 1 : -1));
    return tags;
  };

  const getTagsResourceTypeOptions = (): SelectOption[] => {
    const resourceOptions: SelectOption[] = [];

    if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
      resourceOptions.push({ id: ResourceType.INFRA, text: getResourceTypeMessage(ResourceType.INFRA) });
      resourceOptions.push({ id: ResourceType.VM, text: getResourceTypeMessage(ResourceType.VM) });
    }
    resourceOptions.push({ id: ResourceType.DATABASE, text: getResourceTypeMessage(ResourceType.DATABASE) });
    return resourceOptions;
  };

  const onRenderReview = (formValues: FormValues): JSX.Element => {
    const reviewSections = [];
    reviewSections.push(
      { title: Messages.createExaDb.basicsTab.title(), items: buildBasicInfo(formValues) },
      { title: Messages.createExaDb.configurationTab.title(), items: buildConfigInfo(formValues) },
    );
    if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
      reviewSections.push({
        title: Messages.createExaDb.networkingTab.title(),
        items: buildNetworkingInfo(formValues),
      });
    }
    reviewSections.push(
      { title: Messages.createExaDb.securityTab.title(), items: buildSecurityInfo(formValues) },
    );
    if (createFlowType !== ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP) {
      reviewSections.push(
        { title: Messages.createExaDb.managementTab.title(), items: buildManagementInfo(formValues) },
        { title: Messages.tags.title(), items: buildTagsInfo(formValues) },
      );
    }
    return <ReviewTabContent reviewSections={reviewSections} />;
  };

  const validator = (formValues: FormValues): FormFieldErrors[] | undefined => {
    const formFieldErrors: FormFieldErrors[] = [];
    const dbNameErrors:string[] = [];
    const configValues = formValues[GROUP_CONFIG];
    const networkValues = formValues[GROUP_NETWORK];
    const databaseName = configValues?.[ConfigFields.DatabaseName];
    const pdbName = configValues?.[ConfigFields.PdbName];
    const hostName = networkValues?.[NetworkFields.Hostname];

    const hasError = databaseName && pdbName && databaseName === pdbName;
    const hasSubstringError = databaseName && hostName && hostName.includes(databaseName);
    if (hasError) {
      dbNameErrors.push(Messages.validation.sameDbNames());
    }
    if (hasSubstringError) {
      dbNameErrors.push(Messages.validation.subStringDbName());
    }
    const errors = hasError ? [Messages.validation.sameDbNames()] : undefined;

    formFieldErrors.push(
      {
        errors:
        dbNameErrors.length > 0 ? dbNameErrors : undefined,
        field: ConfigFields.DatabaseName,
        group: GROUP_CONFIG,
      },
      {
        errors,
        field: ConfigFields.PdbName,
        group: GROUP_CONFIG,
      },
      {
        errors:
        hasSubstringError
          ? [Messages.validation.subStringDbName()]
          : undefined,
        field: NetworkFields.Hostname,
        group: GROUP_NETWORK,
      },

    );

    if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
      const virtualNetwork: AzureVirtualNetworkSummary | undefined = getValue<SelectOption[]>(
        formValues,
        NetworkFields.Vnet,
        GROUP_NETWORK,
      )?.[0]?.data;

      const networkVirtualAppliance = getValue<string>(
        formValues,
        NetworkFields.NetworkVirtualAppliance,
        GROUP_NETWORK,
      );

      formFieldErrors.push({
        errors:
          networkVirtualAppliance
            ? validateIpAddressWithinRanges(networkVirtualAppliance, virtualNetwork?.addressSpaces ?? [])
            : undefined,
        field: NetworkFields.NetworkVirtualAppliance,
        group: GROUP_NETWORK,
      });
    }
    return formFieldErrors;
  };

  const wizardItems = [];
  wizardItems.push({
    header: Messages.createExaDb.basicsTab.title(),
    groupName: GROUP_BASICS,
    testId: PanelTestIds.Basics,
    content: (
      <BasicsTabContent
        location={location}
        disableUIControls={basicsDisabledFields}
        subscription={subscriptionId}
        inputWizardPanelRef={panelRef}
        createFlowType={createFlowType}
        resourceGroupId={buildResourceGroupId(vmClusterId)}
      />
    ),
  });
  wizardItems.push({
    header: Messages.createExaDb.configurationTab.title(),
    groupName: GROUP_CONFIG,
    testId: PanelTestIds.Configuration,
    content: (
      <ConfigTabContent
        createFlowType={createFlowType}
        inputWizardPanelRef={panelRef}
        subscriptionId={subscriptionId}
        resourceGroupName={resourceGroupName}
        location={location}
        vmClusterId={vmClusterId}
        backup={backup}
        exaName={exaName}
        disableUIControls={configDisabledFields}
      />
    ),
  });
  if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
    wizardItems.push({
      header: Messages.createExaDb.networkingTab.title(),
      groupName: GROUP_NETWORK,
      testId: PanelTestIds.Networking,
      content: <NetworkTabContent
        inputWizardPanelRef={panelRef}
        subscriptionId={subscriptionId}
        location={location}
      />,
      onUnselecting: closePanels,
    });
  }
  wizardItems.push({
    header: Messages.createExaDb.securityTab.title(),
    groupName: GROUP_SECURITY,
    testId: PanelTestIds.Security,
    content: <SecurityTabContent createFlowType={createFlowType} />,
  });
  if (createFlowType !== ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP) {
    wizardItems.push({
      header: Messages.createExaDb.managementTab.title(),
      groupName: GROUP_MANAGEMENT,
      testId: PanelTestIds.Management,
      content: <ManagementTabContent />,
    });
    wizardItems.push({
      header: Messages.tags.title(),
      groupName: GROUP_TAGS,
      content: <TagsTabContent resourceOptions={getTagsResourceTypeOptions()} />,
    });
  }
  const resourceName = createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM
    ? Messages.labels.exadataSystem()
    : Messages.labels.exadataDatabase();

  const getTitle = (isShort: boolean): string => {
    switch (createFlowType) {
      case ExadataCreateFlowType.EXADATA_SYSTEM:
        return isShort ? Messages.createExaDb.titles.shortFullStack() : Messages.createExaDb.titles.longFullStack();
      case ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP:
        return isShort ? Messages.createExaDb.titles.shortFromBackup() : Messages.createExaDb.titles.longFromBackup();
      case ExadataCreateFlowType.EXADATA_DATABASE:
      default:
        return isShort ? Messages.createExaDb.titles.short() : Messages.createExaDb.titles.long();
    }
  };

  const getRoleMessage = (): PanelMessage | undefined => {
    switch (createFlowType) {
      case ExadataCreateFlowType.EXADATA_SYSTEM:
        return isCreateSystemRoleMissing
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(systemRequiredRole),
          } as PanelMessage
          : undefined;
      case ExadataCreateFlowType.EXADATA_DATABASE:
      case ExadataCreateFlowType.EXADATA_DATABASE_FROM_BACKUP:
        return isCreateDbRoleMissing
          ? {
            type: MessageType.WARNING,
            text: Messages.validation.roleMissingForCreate(dbRequiredRole),
          } as PanelMessage
          : undefined;
      default:
        return undefined;
    }
  };

  return (
    <BookmarkablePage
      appContext={ConsoleContext}
      registrationIds={registrationIds}
      title={getTitle(true)}
    >
      <InputWizardPanel
        componentRef={setPanelRef}
        title={getTitle(false)}
        message={getRoleMessage()}
        testId={CreateWizardTestIds.ExaDb}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFormChange={(_formValues: FormValues, fieldValue: any, field: string) => {
          if (field === BasicsFields.Subscription) {
            setSubscriptionId(fieldValue?.[0]?.id);
          } else if (field === BasicsFields.ResourceGroup) {
            setResourceGroupName(fieldValue?.[0]?.text);
          } else if (field === BasicsFields.Region) {
            setLocation(fieldValue?.[0]?.id);
          } else if (field === BasicsFields.Name) {
            setExaName(fieldValue);
          }
        }}
        items={wizardItems}
        itemsMessages={!basicsDisabledFields ? [{
          itemNdx: 0,
          type: MessageType.WARNING,
          message: Messages.createCommon.dependencyWarnings.basicsChangeWarning(resourceName),
          hideOnFirstSelect: true,
        }] as InputWizardItemMessage[] : undefined}
        onRenderReview={onRenderReview}
        onUnselectingReview={() => {
          // Reset to cover the case of the user navigating away from the review tab before the results come back
          if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
            setCreateExaSysPayload(undefined);
          } else {
            setCreateExaDbPayload(undefined);
          }

          setCreationPhase(undefined);
        }}
        onAsyncValidate={enableExaDbPreflight ? onAsyncValidate : undefined}
        onSubmit={(
          formValues: FormValues,
          reject: (errorMessage: string, validationErrors?: AsyncValidationError[]) => void,
        ) => {
          submitCallbacks.current = { reject };
          onFormSubmit(formValues);
        }}
        onClose={() => {
          const analytics = customData?.analytics as NavigationAnalyticsData;
          if (analytics) {
            trackActionDiscard(analytics.actionName, analytics.pageId, analytics.panelId);
          }
          onClose();
        }}
        validator={validator}
      />
      {creationPhase === CreationPhase.CREATE_SUBMIT && (
        <DeploymentCreatePhase
          panelRef={panelRef}
          deployedService={createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM
            ? DeployedService.EXADATA_SYSTEM
            : DeployedService.EXADATA_DATABASE}
          location={location}
          resourceName={resourceNameRef.current}
          subscriptionId={subscriptionId}
          resourceGroupName={resourceGroupName}
          deploymentName={deploymentNameRef.current}
          submitPayload={
            createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM
              ? createExaSysPayload
              : createExaDbPayload
          }
          submitReject={submitCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          asyncNotification={{
            methodKey: AsyncNotificationMethodKey.DEPLOYMENT_GET,
            delay: createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM
              ? EXASYS_DEPLOYMENT_CREATE_POLL_DELAY
              : EXADB_DEPLOYMENT_CREATE_POLL_DELAY,
            pollingInterval: createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM
              ? EXASYS_DEPLOYMENT_CREATE_POLL_INTERVAL
              : EXADB_DEPLOYMENT_CREATE_POLL_INTERVAL,
            polledResponseKey: AsyncNotificationPolledResponseKey.EXADB_DEPLOYMENT_CREATED_CHECK_CDB,
          }}
          onPostSubmit={() => {
            if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
              setCreateExaSysPayload(undefined);
            } else {
              setCreateExaDbPayload(undefined);
            }
            setCreationPhase(undefined);
          }}
        />
      )}
      {creationPhase === CreationPhase.ASYNC_VALIDATE && (
        <DeploymentAsyncValidationPhase
          location={location}
          asyncValidatePayload={
            createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM
              ? createExaSysPayload
              : createExaDbPayload
          }
          asyncValidateResolve={asyncValidationCallbacks.current.resolve}
          asyncValidateReject={asyncValidationCallbacks.current.reject}
          onProcessAsyncValidationErrors={onProcessAsyncValidationErrors}
          onPostAsyncValidate={() => {
            if (createFlowType === ExadataCreateFlowType.EXADATA_SYSTEM) {
              setCreateExaSysPayload(undefined);
            } else {
              setCreateExaDbPayload(undefined);
            }
            setCreationPhase(undefined);
          }}
        />
      )}
      <DownloadSshKeyModal
        closeModal={hideDownloadSshKey}
        submitBtnText={Messages.actions.downloadKeyCreateResource()}
        cancelBtnText={Messages.actions.returnToCreateResource()}
        isOpen={displayDownloadSshKey}
        onCancel={() => {
          trackActionDiscard(ActionIds.DownloadSshKey, PageId.EXADB_CREATE);
          panelRef.allowResubmit();
        }}
        onSubmit={() => {
          onConfirmDownloadSshKey();
        }}
      />
    </BookmarkablePage>
  );
};
