import { AnchoredPanel, AnchoredPanelType, ButtonType, CodeSnippet, Status, StatusType } from "o4a-react";
import * as React from "react";
import { DetailsList, DetailsRow, IColumn, IDetailsRowProps, mergeStyleSets, SelectionMode } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { OperationBaseProps } from "../../console/OperationsContext";
import { SidePanelTestIds } from "../../constants/uiConstants";
import { AzureLastOperationDetailsStatusEnum } from "../../gen/clients/mchub-azure-api-client";
import { AzureSubscriptionSummaryExt } from "../../hooks/useSubscriptions";
import { getAzureLocationName } from "../../utils";

export enum ButtonTestIds {
  Footer = "test-footer-btn",
}

export interface CloudLinkShowLocationsProps extends OperationBaseProps {
  subscription: AzureSubscriptionSummaryExt;
  newLocations?: string[];
  newLocationStatus?: AzureLastOperationDetailsStatusEnum;
  newLocationStatusMessage?: string;
}

export interface CloudLinkLocationItem {
  name: string;
  status: AzureLastOperationDetailsStatusEnum;
  statusMessage?: string;
}

export enum ColumnIds {
  Name = "name",
  LocationStatus = "status",
}

const classNames = mergeStyleSets({
  operationDetailItems: {
    margin: 0,
    padding: 0,
  },
  operationDetailItem: { paddingBottom: "10px" },
  operationDetailItemLabel: { paddingBottom: "2px" },
  operationDetailItemCodeSnippetContainer: {
    border: "1px solid rgb(138, 136, 134)",
    borderRadius: "2px",
  },
});

const tableRowStyles = { cell: { fontSize: "13px" } };

const CloudLinkShowLocations = (
  { onCancel, subscription, newLocations, newLocationStatus, newLocationStatusMessage }
  : CloudLinkShowLocationsProps,
): JSX.Element => {
  const getLocationStatusStr = (status: AzureLastOperationDetailsStatusEnum): string => {
    switch (status) {
      case "IN_PROGRESS":
        return Messages.subscriptionStatus.inProgress();
      case "SUCCEEDED":
        return Messages.subscriptionStatus.succeeded();
      case "FAILED":
        return Messages.subscriptionStatus.failed();
      default:
        return status;
    }
  };

  const getLocationStatusType = (status: AzureLastOperationDetailsStatusEnum): StatusType => {
    switch (status) {
      case "IN_PROGRESS":
        return StatusType.PENDING;
      case "SUCCEEDED":
        return StatusType.SUCCESS;
      case "FAILED":
        return StatusType.ERROR;
      default:
        return StatusType.UNKNOWN;
    }
  };

  const getLocationStatus = (value: CloudLinkLocationItem): JSX.Element => (
    <Status
      label={getLocationStatusStr(value.status)}
      tooltip={getLocationStatusStr(value.status)}
      status={getLocationStatusType(value.status)}
      statusInfo={value?.statusMessage ? {
        title: getLocationStatusStr(value.status),
        description: value?.statusMessage || "",
      } : undefined}
      hideClipboardCopy
    />
  );

  const columns: IColumn[] = [
    {
      key: ColumnIds.Name,
      fieldName: ColumnIds.Name,
      name: Messages.labels.location(),
      data: "string",
      isResizable: true,
      minWidth: 50,
      maxWidth: 300,
    },
    {
      key: ColumnIds.LocationStatus,
      fieldName: ColumnIds.LocationStatus,
      name: Messages.labels.status(),
      isResizable: true,
      minWidth: 50,
      maxWidth: 300,
      onRender: value => getLocationStatus(value),
    },
  ];

  const items: CloudLinkLocationItem[] = [];
  subscription?.locations?.forEach(location => {
    items.push({
      name: getAzureLocationName(location),
      status: "SUCCEEDED",
    });
  });
  if (newLocations && newLocations.length && newLocationStatus) {
    newLocations.forEach(newLocation => {
      items.push({
        name: getAzureLocationName(newLocation),
        status: newLocationStatus,
        statusMessage: newLocationStatusMessage,
      });
    });
  }

  const renderRow = (props: IDetailsRowProps | undefined): JSX.Element | null => {
    if (props) {
      return (
        <DetailsRow
          {...props}
          styles={tableRowStyles}
        />
      );
    }
    return null;
  };

  return (
    <AnchoredPanel
      testId={SidePanelTestIds.CloudLinkViewLocations}
      title={Messages.listCloudLink.titles.locations()}
      type={AnchoredPanelType.MEDIUM}
      onClose={onCancel}
      isOpen
      footer={[
        {
          type: ButtonType.DEFAULT,
          testId: ButtonTestIds.Footer,
          label: Messages.actions.close(),
          onClick: () => onCancel?.(),
          style: { margin: "10px 5px 0px 0px", height: "24px" },
        },
      ]}
    >
      <div className={classNames.operationDetailItems}>
        <div className={classNames.operationDetailItem}>
          <label className={classNames.operationDetailItemLabel}>
            {Messages.labels.subscription()}
          </label>
          <div className={classNames.operationDetailItemCodeSnippetContainer}>
            <CodeSnippet
              text={subscription?.name || ""}
              numberOfRows={1}
              ariaLabel={Messages.labels.subscription()}
            />
          </div>
        </div>
        <div className={classNames.operationDetailItem}>
          <label className={classNames.operationDetailItemLabel}>
            {Messages.labels.subscriptionId()}
          </label>
          <div className={classNames.operationDetailItemCodeSnippetContainer}>
            <CodeSnippet
              text={subscription?.id || ""}
              numberOfRows={1}
              ariaLabel={Messages.labels.subscriptionId()}
            />
          </div>
        </div>
      </div>
      <DetailsList
        setKey="location-items"
        items={items}
        columns={columns}
        onRenderRow={renderRow}
        selectionMode={SelectionMode.none}
        ariaLabelForGrid={Messages.ariaLabel.resourceList()}
      />
    </AnchoredPanel>
  );
};

export const newCloudLinkShowLocations = (
  props: CloudLinkShowLocationsProps,
): JSX.Element => (<CloudLinkShowLocations {...props} />);
