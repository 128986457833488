import { FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Fields as TagDetailsFields, TagsEditPanel } from "../../components/TagsEditPanel/TagsEditPanel";
import { TagsInfoType } from "../../helpers/resourceHelper";
import { useOperation } from "../../hooks/useOperation";
import { OperationProps } from "../OperationTypes";
import { MysqlUpdateTagsProps, newMysqlUpdateTags } from "./MysqlUpdateTags";

export interface MysqlEditTagsProps extends OperationProps {
  databaseId: string;
  defaultTags?: TagsInfoType;
}

const MysqlEditTags = (
  { location, databaseId, defaultTags, onExecute, onCancel }: MysqlEditTagsProps,
): JSX.Element => {
  const { trigger: triggerUpdateTags } = useOperation<MysqlUpdateTagsProps>(newMysqlUpdateTags);

  return (
    <TagsEditPanel
      title={Messages.labels.editTags()}
      resourceId={databaseId}
      tagsDefaultValue={defaultTags}
      onSubmit={(formValues: FormValues) => {
        const tags = getValue<TagsInfoType>(formValues, TagDetailsFields.Tags, InputFormGroup) ?? [];
        triggerUpdateTags({
          databaseId,
          tags,
          location,
          onExecute,
        });
      }}
      onClose={onCancel}
    />
  );
};

export const newMysqlEditTags = (
  props: MysqlEditTagsProps,
): JSX.Element => (<MysqlEditTags {...props} />);
