import * as React from "react";
import { McvcnCreateSidePanel } from "../../components/McvcnCreateSidePanel/McvcnCreateSidePanel";
import { FutureMcvcnExisting, FutureMcvcnNew } from "../../helpers/resourceHelper";
import { OperationProps } from "../OperationTypes";

export interface McvcnCreateNewProps extends OperationProps {
  subscriptionId: string;
}

const McvcnCreateNew = ({
  location,
  subscriptionId,
  onCancel,
  onExecute,
}: McvcnCreateNewProps): JSX.Element => {
  const onSubmit = (futureMcvcn: FutureMcvcnExisting | FutureMcvcnNew): void => {
    onExecute?.(futureMcvcn);
  };

  return (
    <McvcnCreateSidePanel
      location={location}
      onSubmit={onSubmit}
      subscriptionId={subscriptionId}
      onClose={onCancel}
    />
  );
};

export const newMcvcnCreateNew = (props: McvcnCreateNewProps): JSX.Element => (
  <McvcnCreateNew {...props} />
);
