import { LabelMetaItem, MetaItemSection, NoValue } from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { metaItemLabelsGap } from "../../constants/uiConstants";
import { VmdbDatabase } from "../../gen/clients/mchub-azure-api-client-vmdb";

export enum MetaItemTestIds {
  DatabaseName = "mi-database-name",
  CharacterSet = "mi-character-set",
  NationalCharacterSet = "mi-national-character-set",
  DbUniqueName = "mis-db-unique-name",
  SIDPrefix = "mis-sid-prefix",
}

export interface VmDbConfigurationDetailsProps {
    cdb: VmdbDatabase | undefined;

  }
export const VmDbConfigurationDetails = (
  { cdb }: VmDbConfigurationDetailsProps,
): JSX.Element => (
  <MetaItemSection labelWidth={150}>
    <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
      <LabelMetaItem testId={MetaItemTestIds.DatabaseName} label={Messages.labels.databaseName()}>
        {cdb?.dbName || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemTestIds.DbUniqueName} label={Messages.labels.databaseUniqueName()}>
        {cdb?.dbUniqueName || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemTestIds.SIDPrefix} label={Messages.labels.oracleSIDPrefix()}>
        {cdb?.sidPrefix || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemTestIds.CharacterSet} label={Messages.labels.characterSet()}>
        {cdb?.characterSet || <NoValue />}
      </LabelMetaItem>
      <LabelMetaItem testId={MetaItemTestIds.NationalCharacterSet} label={Messages.labels.nationalCharacterSet()}>
        {cdb?.ncharacterSet || <NoValue />}
      </LabelMetaItem>
    </Stack>
  </MetaItemSection>
);
