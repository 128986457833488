import {
  FormValues,
  InfoBlock,
  InfoBlockLayout,
  InfoBlockStatus,
  InputFormCallout,
  InputFormCalloutProps,
  IntegerInput,
  ReadOnlyText,
  SubmitButtonMode,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";
import { VmdbDbSystemShapeSummary } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { IdResourceType } from "../../helpers/idHelper";
import { isFlexVmdbShape } from "../../helpers/resourceHelper";
import { VmDbSystemShapeSelect } from "./VmDbSystemShapeSelect";

export interface VmDbScaleConfig {
  shapeId: string;
  cpuCoreCount: number;
  nodeCount?: number;
}

export interface VmDbShapeCalloutProps extends
  Pick<InputFormCalloutProps, "title" | "targetId" | "onClose" | "onSubmit" | "componentRef">
{
  isCreateMode: boolean;
  defaultValue?: VmDbScaleConfig;
  location: string;
  fieldName: string;
  subscriptionId: string;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export enum Fields {
  CpuCoreCount = "cpuCoreCount",
  NodeCount = "nodeCount",
  TotalCpuCoreCount = "totalCpuCoreCount"
}

export enum FieldsTestIds {
  Shape = "shape-field",
  CpuCoreCount = "cpu-core-count-field",
}

export enum InfoBlockTestIds {
  FlexShapeInfo = "flex-shape-info",
  ShapeUnavailable = "shape-unavailable",
}

export const VmDbShapeCallout = ({
  componentRef,
  isCreateMode,
  defaultValue,
  location,
  fieldName,
  subscriptionId,
  targetId,
  title,
  onClose,
  onError,
  onMissingDependencies,
  onSubmit,
}: VmDbShapeCalloutProps): JSX.Element => {
  // eslint-disable-next-line eqeqeq
  const defaultCpuCount = defaultValue?.cpuCoreCount != undefined
    ? defaultValue.cpuCoreCount / (defaultValue?.nodeCount || 1)
    : undefined;
  const [currentShape, setCurrentShape] = React.useState<VmdbDbSystemShapeSummary>();
  const [cpuCoreCount, setCpuCoreCount] = React.useState<number | undefined>(defaultCpuCount);
  const [cpuCoreCountKey, setCpuCoreCountKey] = React.useState<string>(uniqueGUID());
  const [totalCpuCoreCountKey, setTotalCpuCoreCountKey] = React.useState<string>(uniqueGUID());

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (defaultValue) {
      const initFieldValues: FormValues = {
        [fieldName]: [defaultValue.shapeId],
        // eslint-disable-next-line eqeqeq
        [Fields.CpuCoreCount]: Number(defaultCpuCount).toString(),
      };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  let maxCoreCount: number | undefined;

  if (
    currentShape
    && currentShape.maximumNodeCount
    && currentShape.minimumCoreCount === currentShape.availableCoreCount
  ) {
    maxCoreCount = currentShape.availableCoreCount * (defaultValue?.nodeCount || 1);
  // update flex shape
  } else {
    maxCoreCount = currentShape?.availableCoreCount;
  }

  const isChangingShape = (shape: VmdbDbSystemShapeSummary | undefined): boolean => (
    !isCreateMode && defaultValue?.shapeId !== shape?.name
  );

  const onShapeChange = (_: string, shape?: VmdbDbSystemShapeSummary): void => {
    setCurrentShape(shape);
    // If edit mode and changing to flex the cpu count will be the existing cpu count
    if (isChangingShape(shape) && isFlexVmdbShape(shape)) {
      setCpuCoreCount(defaultCpuCount);
    } else {
      setCpuCoreCount(shape?.minimumCoreCount);
    }
    setCpuCoreCountKey(uniqueGUID());
    setTotalCpuCoreCountKey(uniqueGUID());
  };

  const onDefaultSelect = (shape?: VmdbDbSystemShapeSummary): void => {
    setCurrentShape(shape);
    setCpuCoreCountKey(uniqueGUID());
    setTotalCpuCoreCountKey(uniqueGUID());
  };

  const validateIntegerStep = (value: number | undefined) : string[] | undefined => {
    const errors: string[] = [];
    const numValue = Number(value);
    const min = currentShape?.minimumCoreCount;
    const step = currentShape?.coreCountIncrement;
    if (min && step) {
      const noRemainder = (numValue - min) % step === 0;
      if (!noRemainder) errors.push(Messages.validation.integerStepMin(min.toString(), step.toString()));
    }
    return errors.length ? errors : undefined;
  };

  return (
    <InputFormCallout
      testId={CalloutTestIds.VmDbSystemEditScale}
      componentRef={componentRef}
      title={title}
      targetId={targetId}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={initialValues}
    >
      <VmDbSystemShapeSelect
        required
        testId={FieldsTestIds.Shape}
        fieldName={fieldName}
        location={location}
        subscriptionId={subscriptionId}
        nodeCount={defaultValue?.nodeCount || 1}
        defaultValue={defaultValue ? [defaultValue.shapeId] : undefined}
        tooltip={Messages.hints.selectVMShape()}
        onError={onError}
        onChange={onShapeChange}
        onDefaultSelect={onDefaultSelect}
        onMissingDependencies={onMissingDependencies}
        statusInfo={defaultValue?.nodeCount && defaultValue?.nodeCount !== 1 ? {
          testId: InfoBlockTestIds.ShapeUnavailable,
          message: Messages.hints.shapeUnavailable(),
          messageType: InfoBlockStatus.INFO,
          infoLayout: InfoBlockLayout.Compact,
        } : undefined}
      />
      <IntegerInput
        required
        testId={FieldsTestIds.CpuCoreCount}
        key={cpuCoreCountKey}
        fieldName={Fields.CpuCoreCount}
        label={Messages.labels.ocpuCountPerVM()}
        min={currentShape?.minimumCoreCount}
        max={maxCoreCount}
        defaultValue={cpuCoreCount ?? currentShape?.minimumCoreCount}
        disabled={!isFlexVmdbShape(currentShape) || isChangingShape(currentShape)}
        // eslint-disable-next-line max-len
        tooltip={currentShape?.minimumCoreCount && currentShape?.coreCountIncrement && maxCoreCount && !(currentShape?.minimumCoreCount === currentShape?.availableCoreCount)
          ? Messages.hints.toolTipSelectOcpuCount(
            currentShape?.minimumCoreCount.toString(),
            maxCoreCount?.toString(),
            currentShape?.coreCountIncrement.toString(),
          )
          : undefined}
        validator={validateIntegerStep}
        onChange={(val: number | undefined) => {
          setCpuCoreCount(val);
          setTotalCpuCoreCountKey(uniqueGUID());
        }}
      />
      {isChangingShape(currentShape) && isFlexVmdbShape(currentShape) && (
        <InfoBlock
          testId={InfoBlockTestIds.FlexShapeInfo}
          message={Messages.hints.selectOcpuCountChangeToFlex()}
          messageType={InfoBlockStatus.INFO}
          infoLayout={InfoBlockLayout.Compact}
          customInfoStyle={{ root: { marginBottom: "15px" } }}
        />
      )}
      {defaultValue?.nodeCount && (
      <ReadOnlyText
        defaultValue={(defaultValue?.nodeCount || 1).toString()}
        label={Messages.labels.nodeCount()}
        fieldName={Fields.NodeCount}
      />
      )}
      {defaultValue?.nodeCount && (
        <ReadOnlyText
          key={totalCpuCoreCountKey}
          defaultValue={((cpuCoreCount || 1) * (defaultValue?.nodeCount || 1)).toString()}
          label={Messages.labels.ocpuCount()}
          fieldName={Fields.TotalCpuCoreCount}
        />
      )}
    </InputFormCallout>
  );
};
