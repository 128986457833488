import {
  ActionType,
  ConfirmAction,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingDisplayNameLink,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
  useNavigation,
} from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { ConsoleContext } from "../../console/ConsoleContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import {
  DetailsPanelId,
  PageId,
  PageRegistrationConfig,
  RESOURCE_ROUTE,
  VMCLUSTER_CREATE_ROUTE,
} from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  CloudExadataInfrastructure,
  CloudVmClusterSummary,
  CloudVmClusterSummaryCollection,
} from "../../gen/clients/mchub-azure-api-client-exa";
import { getStatusInfo, responseItemstoArray } from "../../helpers/resourceHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import { ExaVmClusterDeleteProps, newExaVmClusterDelete } from "../../operations/ExaVmCluster/ExaVmClusterDelete";
import { getAzureLocationName, getOciRegion } from "../../utils";

export interface ExaInfraVmClustersListProps {
  infra: CloudExadataInfrastructure;
  resGroup: string;
  subscriptionId: string;
  location: string;
}

interface VmClusterSummary {
  id: string
  name: string;
  location: string;
  resourceType: VmClusterTypes;
  cpuCoreCount?: number;
}

enum VmClusterTypes {
  Exadata = "exadata",
}

export enum ActionBarIds {
  CreateVmCluster = "action-bar-create-vmcluster",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
}

export enum ActionBarTestIds {
  CreateVmCluster = "action-bar-create-vmcluster",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete"
}

export enum ActionMenuIds {
  Delete = "action-menu-delete",
}

export enum ActionMenuTestIds {
  Delete = "action-menu-delete",
}

export enum ColumnIds {
  Name = "name",
  ResourceGroupName = "id",
  Location = "location",
  ResourceStatus = "status",
  OCPUCount = "cpuCoreCount",
  ResourceType = "resourceType",
}

export enum ColumnTestIds {
  Name = "name",
  ResourceGroupName = "id",
  Location = "location",
  ResourceStatus = "status",
  OCPUCount = "cpuCoreCount",
  ResourceType = "resourceType",
}

const exaVmClusterDetailsPageRegistrationIds = PageRegistrationConfig[PageId.VMCLUSTER_DETAILS].map(
  config => config.key,
);

export const ExaInfraVmClustersList = (
  { infra, resGroup, subscriptionId, location }: ExaInfraVmClustersListProps,
): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { navigateTo } = useNavigation(ConsoleContext);
  const { locale } = React.useContext<Settings>(SettingsContext);
  const [selection, setSelection] = React.useState<VmClusterSummary[]>([]);
  const [listingComponentRef, setListingComponentRef] = React.useState
    <ListingComponent<VmClusterSummary>>();

  const {
    response: listVmClustersResponse,
    loading: listVmClustersLoading,
    refresh: listVmClustersRefresh,
  } = useQueryCall({
    wait: !(subscriptionId && resGroup && location),
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listCloudVmClusters,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName: resGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVMClusters(),
      },
    },
  });
  const { trigger: triggerDeleteExaVmCluster } = useOperation<ExaVmClusterDeleteProps>(newExaVmClusterDelete);
  const vmClusterItems = (listVmClustersResponse
    && responseItemstoArray<CloudVmClusterSummaryCollection, CloudVmClusterSummary>(listVmClustersResponse))
    ?.filter((vmClusterItem: CloudVmClusterSummary) => vmClusterItem.cloudExadataInfrastructureId === infra.id)
    .map((vmClusterItem: CloudVmClusterSummary) => ({
      id: vmClusterItem.id,
      name: vmClusterItem.name,
      location: vmClusterItem.location,
      status: vmClusterItem.status,
      cpuCoreCount: vmClusterItem.cpuCoreCount,
      resourceType: VmClusterTypes.Exadata,
    } as VmClusterSummary));
  
  const getLocation = (value: VmClusterSummary): string => getAzureLocationName(value?.location);
  const setSelectedItems = (selectedItems: VmClusterSummary[]): void => {
    setSelection(selectedItems);
  };

  const getResourceType = (value: VmClusterSummary): string => (value.resourceType === VmClusterTypes.Exadata
    ? Messages.labels.exaCS()
    : Messages.labels.autonomousDatabaseDedicated());

  const getStatus = (value: CloudVmClusterSummary): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
          value?.lifecycleDetails,
        )
      }
      hideClipboardCopy
    />
  );

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getName(value),
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
    {
      itemProp: ColumnIds.OCPUCount,
      testId: ColumnTestIds.OCPUCount,
      name: Messages.labels.ocpuCount(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
    },
    {
      itemProp: ColumnIds.ResourceType,
      testId: ColumnTestIds.ResourceType,
      name: Messages.labels.resourceType(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getResourceType(value),
    },
  ];

  const refreshVmClusters = (): void => {
    listVmClustersRefresh();
  };
  
  const { closePanels } = useSidePanel();

  const buildDeleteAction = (key: string, testId: string, selectedItem:VmClusterSummary)
  : ConfirmAction<VmClusterSummary | undefined> => {
    const deleteAction = {
      key,
      testId,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      title: Messages.actions.delete(),
      disabled: !selectedItem,
      description: Messages.hints.deleteVMClusterConfirmation(selectedItem?.name || ""),
      onClick: () => {
        trackActionClick(key, PageId.EXAINFRA_DETAILS, DetailsPanelId.VMCLUSTERS);
      },
      onConfirm: () => {
        triggerDeleteExaVmCluster({
          vmClusterId: selectedItem?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refreshVmClusters();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, PageId.EXAINFRA_DETAILS, DetailsPanelId.VMCLUSTERS),
    };
    return deleteAction;
  };

  const vmClusterRowActionMenu = (item?:VmClusterSummary | undefined)
  : ActionType<VmClusterSummary> [] => {
    const vmClusterActionItems : ActionType<VmClusterSummary | undefined>[] = [];
    if (item) {
      vmClusterActionItems.push(
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return vmClusterActionItems;
  };
  const vmBarActions: ActionType<VmClusterSummary>[] = [
    {
      key: ActionBarIds.CreateVmCluster,
      testId: ActionBarTestIds.CreateVmCluster,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        trackActionClick(ActionBarIds.CreateVmCluster, PageId.EXAINFRA_DETAILS, DetailsPanelId.VMCLUSTERS);
        navigateTo(
          VMCLUSTER_CREATE_ROUTE,
          PageId.VMCLUSTER_CREATE,
          {
            infraId: infra.id,
            location: infra.location,
            analytics: {
              pageId: PageId.EXAINFRA_DETAILS,
              panelId: DetailsPanelId.VMCLUSTERS,
              actionName: ActionBarIds.CreateVmCluster,
            } as NavigationAnalyticsData,
          },
        );
      },
    },
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, PageId.EXAINFRA_DETAILS, DetailsPanelId.VMCLUSTERS);
        refreshVmClusters();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  const getName = (value: VmClusterSummary): JSX.Element => (
    <ListingDisplayNameLink
      displayName={value.name}
      navigation={{
        // eslint-disable-next-line max-len
        to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.VMCLUSTER_DETAILS][0].panelPath}?location=${value.location}`,
        pageKey: exaVmClusterDetailsPageRegistrationIds[0],
      }}
    />
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = React.useMemo(() => vmClusterItems, [JSON.stringify(vmClusterItems)]);

  return (
    <Listing
      testId={ListingTestIds.ExaInfraVmClusters}
      listingComponentRef={setListingComponentRef}
      items={items || []}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      emptyList={{ title: Messages.common.noResults() }}
      listColumns={columns}
      actionBarItems={vmBarActions as ActionType<undefined>[]}
      actions={vmClusterRowActionMenu}
      isLoading={listVmClustersLoading}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Name,
      }}
    />
  );
};
