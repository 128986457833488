import {
  AnchoredPanelComponent,
  DetailView,
  DetailViewsMenuZoomLayout,
  DetailViewsPanel,
  GroupItem,
  TitleDetails,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { PageId, PageRegistrationConfig } from "../../constants/pluginConstants";
import { SettingAppearance } from "./SettingAppearance";
import { SettingLanguage } from "./SettingLanguage";
import { SettingSignout } from "./SettingSignout";
import { SettingSubscriptionsLocations } from "./SettingSubscriptionsLocations";

const registrationIds = PageRegistrationConfig[PageId.SETTINGS].map(config => config.key);

export enum SettingMenuItemTestIds {
  SubscriptionLocation = "setting-subscriptionlocation",
  Appearance = "setting-appearance",
  Language = "setting-language",
  Signout = "setting-signout"
}

const getViewTitle = (panelId: string | undefined): string | TitleDetails => {
  switch (panelId) {
    case registrationIds[3]:
      return { primary: Messages.labels.settings(), secondary: Messages.labels.signingOutSetting() };
    case registrationIds[2]:
      return { primary: Messages.labels.settings(), secondary: Messages.labels.language() };
    case registrationIds[1]:
      return { primary: Messages.labels.settings(), secondary: Messages.labels.appearance() };
    case registrationIds[0]:
    default:
      return { primary: Messages.labels.settings(), secondary: Messages.labels.subscriptionLocation() };
  }
};

export interface SettingsPanelProps {
  componentRef?: (ref: AnchoredPanelComponent) => void;
  onClose?: () => void;
}

export const SettingsPanel = ({ componentRef, onClose }: SettingsPanelProps): JSX.Element => {
  const [panelId, setPanelId] = React.useState<string>();

  const onMenuItemSelect = (id: string): void => setPanelId(id);

  const detailViews: DetailView[] = [
    {
      id: registrationIds[0],
      content: (
        <SettingSubscriptionsLocations />
      ),
    },
    {
      id: registrationIds[1],
      content: (
        <SettingAppearance />
      ),
    },
    {
      id: registrationIds[2],
      content: (
        <SettingLanguage />
      ),
    },
    {
      id: registrationIds[3],
      content: (
        <SettingSignout />
      ),
    },
  ];

  const groupItems: GroupItem[] = [
    {
      items: [
        {
          id: detailViews[0].id,
          name: Messages.labels.subscriptionLocation(),
          testId: SettingMenuItemTestIds.SubscriptionLocation,
        },
        {
          id: detailViews[1].id,
          name: Messages.labels.appearance(),
          testId: SettingMenuItemTestIds.Appearance,
        },
        {
          id: detailViews[2].id,
          name: Messages.labels.language(),
          testId: SettingMenuItemTestIds.Language,
        },
        {
          id: detailViews[3].id,
          name: Messages.labels.signingOutSetting(),
          testId: SettingMenuItemTestIds.Signout,
        },
      ],
    },
  ];

  return (
    <DetailViewsPanel
      componentRef={componentRef}
      title={getViewTitle(panelId)}
      onClose={onClose}
      isOpen
      views={detailViews}
      activeViewId={panelId}
      onMenuItemSelect={onMenuItemSelect}
      menu={groupItems}
      menuFixed
      menuZoomLayout={DetailViewsMenuZoomLayout.DROPDOWN}
    />
  );
};
