import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { RestoreVmdbDatabaseDetails, VmdbBackupSummary } from "../../gen/clients/mchub-azure-api-client-vmdb";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMDB_CDB_UPDATE_POLL_DELAY,
  VMDB_CDB_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface VmDbRestoreFromBackupProps extends OperationConfirmationProps {
  backup: VmdbBackupSummary | undefined;
  databaseId: string | undefined;
}

const VmDbRestoreFromBackup = (
  { location, backup, databaseId, onExecute }: VmDbRestoreFromBackupProps,
): JSX.Element => {
  const { invokeCall: invokeRestore } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.restoreVmdbDatabase },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName: databaseName } = parseId(databaseId);
    const resGroup = decodeURIComponent(resourceGroup || "");

    const { resourceName: backupName } = parseId(backup?.id);

    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.restore(),
          message: Messages.notifications.inProgress.messages.restoreFromBackup(databaseName, backupName),
        },
        success: {
          title: Messages.notifications.success.titles.restore(),
          message: Messages.notifications.success.messages.restoreFromBackup(databaseName, backupName),
        },
        failure: {
          title: Messages.notifications.failure.titles.restore(),
          message: Messages.notifications.failure.messages.restoreFromBackup(databaseName, backupName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMDB_CDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMDB_UPDATED_CHECK_CDB,
          delay: VMDB_CDB_UPDATE_POLL_DELAY,
          interval: VMDB_CDB_UPDATE_POLL_INTERVAL,

        },
      },
    };

    invokeRestore({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName,
      restoreVmdbDatabaseDetails:
        { timestamp: backup?.timeEnded ? new Date(backup?.timeEnded) : undefined } as RestoreVmdbDatabaseDetails,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newVmDbRestoreFromBackup = (
  props: VmDbRestoreFromBackupProps,
): JSX.Element => (<VmDbRestoreFromBackup {...props} />);
