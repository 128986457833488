import {
  ActionType,
  ConfirmAction,
  CustomAction,
  EmptyListingButtonTypes,
  ExtraFilter,
  FavoriteAccessItem,
  FilterAll,
  FilterComponent,
  getResourceLifecycleStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingDisplayNameLink,
  ListingSelectOption,
  Operator,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
  useFavoriteAccess,
  useNavigation,
  ViewSelectOption,
} from "o4a-react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../../components/AzureLinks/AzureSubscriptionLink";
import { ConsoleContext } from "../../console/ConsoleContext";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { MYSQL_CREATE_ROUTE, PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import {
  emptyChar,
  ListingTestIds,
  MonochromeIconIds,
  SvgIconIds,
  ttlOneMinCaching,
  ViewKeys,
} from "../../constants/uiConstants";
import { AzureSubscriptionSummary } from "../../gen/clients/mchub-azure-api-client";
import {
  MdsDbSystemCollection,
  MdsDbSystemStatusEnum,
  MdsDbSystemSummary,
} from "../../gen/clients/mchub-azure-api-client-mds";
import { parseId } from "../../helpers/idHelper";
import {
  getFieldCustomOptions,
  getStatusInfo,
  responseItemstoArray,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { NavigationAnalyticsData, useAnalytics } from "../../hooks/useAnalytics";
import { useFilterStatePills } from "../../hooks/useFilterStatePills";
import { useLocationFilters } from "../../hooks/useLocationFilters";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { MysqlDeleteProps, newMysqlDelete } from "../../operations/Mysql/MysqlDelete";
import { getAzureLocationName, getOciRegion } from "../../utils";

const detailsPageRegistrationIds = PageRegistrationConfig[PageId.MYSQL_DETAILS].map(config => config.key);

export enum ColumnIds {
  DisplayName = "displayName",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  ResourceStatus = "status",
  FreeformTags = "freeformTags",
}

export enum ColumnTestIds {
  DisplayName = "displayName",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
  ResourceStatus = "status",
  FreeformTags = "freeformTags",
}

export enum ActionBarIds {
  Create = "action-bar-create",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
}

export enum ActionMenuIds {
  Delete = "action-menu-delete",
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum ActionBarTestIds {
  Create = "action-bar-create",
  Refresh = "action-bar-refresh",
  Delete = "action-bar-delete",
}

export enum ActionMenuTestIds {
  Delete = "action-menu-delete",
  AddFavorite = "action-menu-add-favorite",
  RemoveFavorite = "action-menu-remove-favorite",
}

export enum FilterTestIds {
  SubscriptionFilter = "subscription-filter",
  LocationFilter = "location-filter",
}

export interface MysqlItem {
  id: string;
  displayName: string;
  subscriptionId: string;
  subscriptionName: string;
  resourceGroupName: string;
  location: string;
  status: MdsDbSystemStatusEnum;
  lastOperationStatus?: string;
  lastOperationStatusDetails?: string;
  freeformTags?: { [key: string]: string; };
  isFavorite: boolean;
}

interface AzureSubscriptionSummaryMap {
  [key: string]: AzureSubscriptionSummary;
}

export interface MysqlListProps {
  disabled?: boolean;
}

export const MysqlList = ({ disabled }: MysqlListProps): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { favoriteItems, isFavorite, setAsFavorite, removeFromFavorites } = useFavoriteAccess();

  const { navigateTo } = useNavigation(ConsoleContext);
  const { preferredSubscription, locale } = React.useContext<Settings>(SettingsContext);
  const filterState = React.useContext<FilterState>(FilterStateContext);
  const { pathname } = useLocation();

  const [selection, setSelection] = React.useState<MysqlItem[]>([]);
  const [selectedSubscription, setSelectedSubscription] = React.useState<string>("");
  const [subscriptionOptions, setSubscriptionOptions] = React.useState<{ key: string, text: string }[]>([]);
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();

  const { locationOptions, selectedLocation, setSelectedLocation } = useLocationFilters(
    filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
  );

  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<MysqlItem>>();

  const [filterComponentRef, setFilterComponentRef] = React.useState<FilterComponent>();
  const pillFilterValues = filterComponentRef?.getFilters();
  const filterTextValue = filterComponentRef?.getFilterText();
  const [filterText, setFilterText] = React.useState(filterState.filterTextValue || filterTextValue);

  const { perPageItemsCount } = useRowCount();

  const getLocation = (value: MysqlItem): string => getAzureLocationName(value.location);

  const getStatus = (value: MysqlItem): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status,
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
        )
      }
      hideClipboardCopy
    />
  );

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.DisplayName,
      testId: ColumnTestIds.DisplayName,
      name: Messages.common.name(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.displayName}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.MYSQL_DETAILS][0].panelPath}?location=${value.location}`,
            pageKey: detailsPageRegistrationIds[0],
          }}
          iconName={SvgIconIds.mysqlSvg}
        />
      ),
    },
    {
      itemProp: ColumnIds.SubscriptionName,
      testId: ColumnTestIds.SubscriptionName,
      name: Messages.labels.subscription(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, max-len
      onRenderItems: value => <AzureSubscriptionLink resourceId={value.id} subscriptionName={value.subscriptionName} hideClipboard />,
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: MysqlItem,
        b: MysqlItem,
      ) => statusSortComparator<MysqlItem>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
  ];

  const { refresh: mdsdbsRefresh, loading: mdsdbsLoading, response: mdsdbsResponse } = useQueryCall({
    wait: (!selectedSubscription || !selectedLocation) || disabled,
    method: apiClients.withRegion(
      getOciRegion(selectedLocation),
    ).mdsDatabaseApi.listMdsDbSystemsBySubscription,
    options: {
      args: { subscriptionId: selectedSubscription },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadMysql(),
      },
    },
  });

  const mdsdbs = mdsdbsResponse
    && responseItemstoArray<MdsDbSystemCollection, MdsDbSystemSummary>(mdsdbsResponse);

  const groupingOptions: ListingSelectOption<MysqlItem>[] = [];

  const viewOptions: ViewSelectOption<MysqlItem>[] = [
    {
      key: ViewKeys.ListView,
      text: Messages.listMysql.filters.listView(),
      icon: "List",
    },
  ];

  const mdsdbsSummaryToMdsdbsItem = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any[],
    subscriptionsMap: AzureSubscriptionSummaryMap,
  ): MysqlItem[] => {
    const mdsdbsItems: MysqlItem[] = [];
    items.forEach((item: MdsDbSystemSummary) => {
      const { subscriptionId, resourceGroup } = parseId(item.id);
      const itemRgName = resourceGroup;
      const itemSubscriptionName = subscriptionsMap && subscriptionsMap[subscriptionId]?.name;

      mdsdbsItems.push({
        id: item.id,
        displayName: item.name,
        subscriptionId,
        subscriptionName: itemSubscriptionName,
        resourceGroupName: itemRgName,
        location: item.location || "",
        status: item.status,
        freeformTags: item.freeformTags,
        isFavorite: isFavorite(item.id),
      });
    });
    return mdsdbsItems;
  };

  const items = React.useMemo(() => {
    let newInstance: MysqlItem[] = [];

    if (mdsdbs && subscriptions && subscriptions.length > 0) {
      const subscriptionsMap = subscriptions.reduce((
        map: AzureSubscriptionSummaryMap,
        subscription: AzureSubscriptionSummary,
      ) => {
        map[subscription.id] = subscription;
        return map;
      }, {});
      newInstance = mdsdbsSummaryToMdsdbsItem(mdsdbs, subscriptionsMap);
    }
    return newInstance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(mdsdbs), JSON.stringify(subscriptions), JSON.stringify(favoriteItems)]);

  React.useEffect(() => {
    const newSubscriptionOptions: { key: string, text: string }[] = [];
    // eslint-disable-next-line no-unused-expressions
    subscriptions && subscriptions.forEach(subscription => {
      newSubscriptionOptions.push({ key: subscription.id, text: subscription.name });
    });

    setSubscriptionOptions(newSubscriptionOptions);
    if (filterState.defaultSubscription) {
      setSelectedSubscription(filterState.defaultSubscription);
      if (defaultExtraFilters) defaultExtraFilters[0].value = filterState.defaultSubscription;
    } else if (preferredSubscription) {
      setSelectedSubscription(preferredSubscription);
    } else {
      setSelectedSubscription(newSubscriptionOptions.length ? newSubscriptionOptions[0].key : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions)]);

  const subscriptionFilter: ExtraFilter = {
    testId: FilterTestIds.SubscriptionFilter,
    name: columns[1].name,
    operator: Operator.Equal,
    value: filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
    fieldKey: columns[1].itemProp,
    customOptions: subscriptionOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedSubscription(value);
        filterState.setDefaultSubscription(value);
      }
    },
    hideDelete: true,
  };

  const resourceGroupFilter: ExtraFilter = {
    name: columns[2].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[2].itemProp)?.value || [FilterAll],
    fieldKey: columns[2].itemProp,
    customOptions: getFieldCustomOptions(items?.map(item => item.resourceGroupName), (fieldVal: string) => fieldVal),
  };

  React.useEffect(() => {
    if (filterState.defaultLocation) {
      setSelectedLocation(filterState.defaultLocation);
      if (defaultExtraFilters) defaultExtraFilters[1].value = filterState.defaultLocation;
    } else {
      setSelectedLocation(filterState.pillFilterStateValue.filterValues?.[1]?.value || selectedLocation);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locationFilter: ExtraFilter = {
    testId: FilterTestIds.LocationFilter,
    name: columns[3].name,
    operator: Operator.Equal,
    value: filterState.defaultLocation ? filterState.defaultLocation : selectedLocation,
    fieldKey: columns[3].itemProp,
    customOptions: locationOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedLocation(value);
        filterState.setDefaultLocation(value);
      }
    },
    hideDelete: true,
  };

  const lifecycleStateFilter: ExtraFilter = {
    name: columns[4].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(item => item.fieldKey === columns[4].itemProp)?.value || [FilterAll],
    fieldKey: columns[4].itemProp,
    messageCallBack: stateT,
    customOptions: getFieldCustomOptions(
      items?.map(item => item.status || ""),
      (fieldVal: string) => {
        if (fieldVal) {
          return stateT(fieldVal);
        }
        return emptyChar;
      },
    ),
  };

  const allFilterPills = [
    subscriptionFilter,
    locationFilter,
    resourceGroupFilter,
    lifecycleStateFilter,
  ];

  const filterPills: ExtraFilter[] = [
    subscriptionFilter,
    locationFilter,
  ];

  const { trigger: triggerDeleteMysql } = useOperation<MysqlDeleteProps>(newMysqlDelete);

  const { closePanels } = useSidePanel();

  const buildDeleteAction = (key:string, testId:string, selectedItem:MysqlItem)
  :ConfirmAction<MysqlItem | undefined> => {
    const deleteAction = {
      key,
      testId,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      disabled: !selectedItem || disabled,
      title: Messages.labels.deleteMysql(),
      description: Messages.hints.deleteMysqlConfirmation(selectedItem?.displayName),
      onClick: () => trackActionClick(key, PageId.MYSQL_LIST),
      onConfirm: () => {
        triggerDeleteMysql({
          databaseId: selectedItem?.id,
          location: selectedLocation,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            mdsdbsRefresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, PageId.MYSQL_LIST),
    };
    return deleteAction;
  };

  const mysqlRowActionMenus = (item?: MysqlItem | undefined): ActionType<MysqlItem>[] => {
    const mysqlActionItems: ActionType<MysqlItem | undefined>[] = [];
    if (item) {
      if (item.isFavorite) {
        mysqlActionItems.push({
          key: ActionMenuIds.RemoveFavorite,
          testId: ActionMenuTestIds.RemoveFavorite,
          text: Messages.actions.removeFromFavorites(),
          icon: MonochromeIconIds.FavoriteSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.RemoveFavorite, PageId.MYSQL_LIST);
            removeFromFavorites(item.id);
          },
        } as CustomAction<MysqlItem | undefined>);
      } else {
        mysqlActionItems.push({
          key: ActionMenuIds.AddFavorite,
          testId: ActionMenuTestIds.AddFavorite,
          text: Messages.actions.addToFavorites(),
          icon: MonochromeIconIds.FavoriteNotSet,
          onClick: () => {
            trackActionClick(ActionMenuIds.AddFavorite, PageId.MYSQL_LIST);
            setAsFavorite({ id: item.id, data: { location: item.location } } as FavoriteAccessItem);
          },
        } as CustomAction<MysqlItem | undefined>);
      }
      mysqlActionItems.push(
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return mysqlActionItems;
  };

  const mysqlBarActions: ActionType<MysqlItem>[] = [
    {
      key: ActionBarIds.Create,
      testId: ActionBarTestIds.Create,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        trackActionClick(ActionBarIds.Create, PageId.MYSQL_LIST);
        navigateTo(MYSQL_CREATE_ROUTE, PageId.MYSQL_CREATE, {
          analytics: {
            pageId: PageId.MYSQL_LIST,
            actionName: ActionBarIds.Create,
          } as NavigationAnalyticsData,
        });
      },
    },
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      disabled,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, PageId.MYSQL_LIST);
        mdsdbsRefresh(); listingComponentRef?.resetTableSelection();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  const setSelectedItems = (selectedItems: MysqlItem[]): void => {
    setSelection(selectedItems);
  };

  const textFilterCallBack = (rowItem: MysqlItem): MysqlItem => {
    const displayRowItem = { ...rowItem };
    displayRowItem.status = stateT(
      displayRowItem.status,
    ) as MdsDbSystemStatusEnum;
    displayRowItem.location = getAzureLocationName(displayRowItem.location);
    return displayRowItem;
  };

  React.useEffect(() => {
    setFilterText(filterTextValue);
    filterState.setFilterTextValue(filterTextValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTextValue]);

  const defaultExtraFilters = useFilterStatePills(pillFilterValues, allFilterPills, pathname, filterPills);

  const resetFilterValues = (): void => {
    const defaultPillValues = filterPills.map(filter => ({ fieldKey: filter.fieldKey, value: filter.value as string }));
    filterState.setFilterStateValue({ filterValues: defaultPillValues, listId: pathname, defaultPills: filterPills });
    filterState.setFilterTextValue("");
    setFilterText("");
  };

  return (
    <Listing
      testId={ListingTestIds.Mysql}
      items={items}
      listColumns={columns}
      listingComponentRef={setListingComponentRef}
      itemsPerPage={perPageItemsCount}
      actionBarItems={mysqlBarActions as ActionType<undefined>[]}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      actions={mysqlRowActionMenus}
      emptyList={{
        title: Messages.listMysql.emptyList.title(),
        emptyButtons: [
          {
            type: EmptyListingButtonTypes.PRIMARY,
            text: Messages.actions.createMysql(),
            action: () => navigateTo(MYSQL_CREATE_ROUTE, PageId.MYSQL_CREATE),
          },
        ],
        watermark: SvgIconIds.mySqlWaterMarkSvg,
      }}
      isLoading={(mdsdbsLoading || subscriptionLoading) && !disabled}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.DisplayName,
      }}
      groupingSelect={{ groupingOptions }}
      viewSelect={{
        viewOptions,
        defaultSelectedKey: ViewKeys.ListView,
      }}
      filtering={{
        allExtraFilters: allFilterPills,
        defaultExtraFilters,
        defaultFilterText: filterText,
        textFilterCallBack,
        componentRef: setFilterComponentRef,
        onClearFilters: resetFilterValues,
      }}
    />
  );
};
