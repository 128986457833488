import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  optimizedRetryOption,
  Select,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { VmdbDbSystemShapeSummaryShapeTypeEnum } from "../../gen/clients/mchub-azure-api-client";
import {
  DbSystemInitialStorageSizeSummary,
  DbSystemInitialStorageSizeSummaryCollection,
  VmdbDbSystemOptionsStorageManagementEnum,
} from "../../gen/clients/mchub-azure-api-client-vmdb";
import { IdResourceType } from "../../helpers/idHelper";
import { responseItemstoArray, STORAGE_TYPE } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { getOciRegion } from "../../utils";

export interface StorageSizeProps extends Omit<SelectProps, "options" | "defaultValue" | "loading"> {
  subscriptionId: string;
  location: string;
  defaultValue?: string;
  shapeType: VmdbDbSystemShapeSummaryShapeTypeEnum | undefined;
  storageType: VmdbDbSystemOptionsStorageManagementEnum | undefined;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const StorageSizeSelect = ({
  subscriptionId,
  location,
  shapeType,
  defaultValue,
  storageType = STORAGE_TYPE.ASM,
  disabled,
  fieldName,
  label,
  onChange,
  placeholder,
  required,
  tooltip,
  validator,
  testId,
  onError,
  onMissingDependencies,
}: StorageSizeProps): JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());

  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const form: FormState = React.useContext(FormContext);
  if (!Object.keys(form).length) {
    throw new Error("StorageSizeSelect must be used within the Form");
  }

  const { loading, response, error } = useQueryCall({
    wait: !(subscriptionId && location),
    method: apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listDbSystemInitialStorageSizes,
    options: {
      args: { subscriptionId },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadDbSystemInitialStorageSizes(),
      },
    },
  });

  const items = (response
    && responseItemstoArray<
      DbSystemInitialStorageSizeSummaryCollection, DbSystemInitialStorageSizeSummary
    >(response)) || [];

  const options = items.filter(
    sizeSummary => sizeSummary.storageManagement === storageType && sizeSummary.shapeType === shapeType,
  )?.[0]?.storageSizeDetails.map(item => ({
    id: item.dataStorageSizeInGBs.toString(),
    text: item.dataStorageSizeInGBs.toString(),
  })) || [];

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    if (!location) missingDependencies.push(IdResourceType.LOCATION);
    if (!shapeType) missingDependencies.push(IdResourceType.SHAPE);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, location, shapeType]);

  React.useEffect(() => {
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Select
      key={key}
      label={label}
      loading={loading}
      testId={testId}
      tooltip={tooltip}
      required={required}
      disabled={disabled}
      onChange={onChange}
      fieldName={fieldName}
      placeholder={placeholder}
      defaultValue={options.length && defaultValue ? [defaultValue] : undefined}
      options={options}
      validator={validator}
      onClick={internalOnClick}
      hideSearchBox
    />
  );
};
