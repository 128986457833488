import {
  FormContext,
  FormInputGroupLayoutContext,
  FormState,
  optimizedRetryOption,
  Select,
  SelectOption,
  SelectProps,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { NormalError } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ServiceProviders } from "../../constants/pluginConstants";
import { ttlOneMinCaching } from "../../constants/uiConstants";
import { LimitDefinitionCollection, LimitDefinitionSummary } from "../../gen/clients/mchub-azure-api-client-platform";
import { IdResourceType } from "../../helpers/idHelper";
import { getProviderName, responseItemstoArray } from "../../helpers/resourceHelper";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { getOciRegion } from "../../utils";

export interface QuotaSelectProps extends Omit<SelectProps, "options" | "onChange"> {
  subscriptionId: string;
  provider: ServiceProviders;
  onDefaultValueSet?: (limitDefinition: LimitDefinitionSummary) => void;
  onChange?: (id: string, limitDefinition: LimitDefinitionSummary) => void;
  onError?: (error: NormalError) => void;
  onMissingDependencies?: (missingDependencies: IdResourceType[]) => void;
}

export const QuotaSelect = ({
  defaultValue,
  fieldName,
  testId,
  label,
  placeholder,
  required,
  subField,
  tooltip,
  validator,
  subscriptionId,
  provider,
  onDefaultValueSet,
  onChange,
  onError,
  onMissingDependencies,
}: QuotaSelectProps): JSX.Element => {
  const [key, setKey] = React.useState<string>(uniqueGUID());
  const [options, setOptions] = React.useState<SelectOption []>([]);

  const { groupName } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const form: FormState = React.useContext(FormContext);

  const { loading: subscriptionsLoading, subscriptions } = useSubscriptions();
  const subscriptionLocation = subscriptions?.find(sub => sub.id === subscriptionId)?.primaryLocation;

  const { loading, response, error } = useQueryCall({
    wait: subscriptionsLoading || !(subscriptionId && subscriptionLocation && provider),
    method: apiClients.withRegion(getOciRegion(subscriptionLocation || "")).limitsApi.listLimitDefinitions,
    options: {
      args: { subscriptionId, providerName: getProviderName(provider) },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadQuotas(),
      },
    },
  });
  const items = (response
    && responseItemstoArray<LimitDefinitionCollection, LimitDefinitionSummary>(response)) || [];

  React.useEffect(() => {
    const newOptions = items?.filter(item => item.isEligibleForLimitIncrease).map(item => ({
      id: item.name || "",
      text: item.description || "",
      data: item,
    }));
    if (defaultValue && defaultValue.length > 0) {
      const defaultItem = items.find(item => item.name === defaultValue[0]);
      onDefaultValueSet?.(defaultItem || {} as LimitDefinitionSummary);
    }
    setOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)]);

  React.useEffect(() => {
    form.setValue(undefined, fieldName, groupName);
    setKey(uniqueGUID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const internalOnClick = (): void => {
    const missingDependencies: IdResourceType[] = [];
    if (!subscriptionId) missingDependencies.push(IdResourceType.SUBSCRIPTIONS);
    if (missingDependencies.length > 0) {
      onMissingDependencies?.(missingDependencies);
    }
  };

  const internalOnChange = (id: string): void => {
    const limitDefinition = items.find(item => item.name === id);
    onChange?.(id, limitDefinition || {} as LimitDefinitionSummary);
  };

  const derivedPlaceholder = !loading && !error && options.length === 0
    ? Messages.createCommon.loadingNone(label?.toLowerCase() || "")
    : placeholder;

  return (
    <Select
      key={key}
      loading={!!subscriptionId && loading}
      testId={testId}
      required={required}
      subField={subField}
      fieldName={fieldName}
      label={label}
      options={options}
      placeholder={derivedPlaceholder}
      defaultValue={defaultValue}
      tooltip={tooltip}
      onClick={internalOnClick}
      onChange={internalOnChange}
      validator={validator}
    />
  );
};
