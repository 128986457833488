import { FormValues, getValue, InputFormGroup, InputFormSidePanelComponent } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { ExaDbPdbCreatePanel, Fields as PdbFields } from "../../components/PdbCreatePanel/ExaDbPdbCreatePanel";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXADB_PDB_CREATE_POLL_DELAY,
  EXADB_PDB_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface ExaDbClonePdbProps extends OperationProps {
  databaseId: string;
}

const ExaDbClonePdb = (
  { location, databaseId, onExecute, onCancel }: ExaDbClonePdbProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const databaseName = decodeURIComponent(resourceName || "");

  const { invokeCall: invokeClone } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.localClonePluggableDatabase },
  );
  const [sidePanelRef, setSidePanelRef] = React.useState<InputFormSidePanelComponent>(
    {} as InputFormSidePanelComponent,
  );

  const onClonePdb = (formValues: FormValues): void => {
    const pdbName = getValue<string>(formValues, PdbFields.PdbName, InputFormGroup) || "";
    const pdbAdminPassword = getValue<string>(formValues, PdbFields.Password, InputFormGroup);
    const unlockAccount = getValue<boolean>(formValues, PdbFields.UnlockAdminAccount, InputFormGroup);
    const tdeWalletPassword = getValue<string>(formValues, PdbFields.TdeWalletPassword, InputFormGroup);

    const invokeOptions = {
      onSuccess: () => onExecute?.(pdbName),
      onFailure: sidePanelRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.clonePluggableDb(),
          message: Messages.notifications.inProgress.messages.clonePluggableDb(pdbName),
        },
        success: {
          title: Messages.notifications.success.titles.clonePluggableDb(),
          message: Messages.notifications.success.messages.clonePluggableDb(pdbName),
        },
        failure: {
          title: Messages.notifications.failure.titles.clone(),
          message: Messages.notifications.failure.messages.clonePluggableDb(pdbName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXADB_PDB_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            pluggableDatabaseName: pdbName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.EXADB_CREATED_CHECK_PDB,
          delay: EXADB_PDB_CREATE_POLL_DELAY,
          interval: EXADB_PDB_CREATE_POLL_INTERVAL,
        },
      },
    };
    invokeClone({
      subscriptionId,
      resourceGroupName: resGroup,
      pluggableDatabaseName: databaseName,
      localClonePluggableDatabaseDetails: {
        clonedPdbName: pdbName,
        ...pdbAdminPassword ? { pdbAdminPassword } : {},
        shouldPdbAdminAccountBeLocked: !unlockAccount,
        targetTdeWalletPassword: tdeWalletPassword,
      },
    }, invokeOptions);
  };

  return (
    <ExaDbPdbCreatePanel
      title={Messages.clonePluggableDatabase.title()}
      componentRef={setSidePanelRef}
      onSubmit={onClonePdb}
      onClose={onCancel}
    />
  );
};

export const newExaDbClonePdb = (
  props: ExaDbClonePdbProps,
): JSX.Element => (<ExaDbClonePdb {...props} />);
