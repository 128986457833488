import { Select, SelectOption, SelectProps, uniqueGUID } from "o4a-react";
import * as React from "react";
import { SettingsContext } from "../../console/SettingsContext";
import { useSubscriptions } from "../../hooks/useSubscriptions";

export type SubscriptionProps = Omit<SelectProps, "options">

export const SubscriptionSelect = ({
  defaultValue,
  fieldName,
  label,
  inputLink,
  required,
  subField,
  tooltip,
  validator,
  onChange,
  disabled,
  testId,
  ariaLabel,
}: SubscriptionProps): JSX.Element => {
  const { preferredSubscription } = React.useContext(SettingsContext);
  const [key, setKey] = React.useState<string>(uniqueGUID());
  const { loading, subscriptions = [] } = useSubscriptions();

  const options = subscriptions?.map(subscription => ({
    id: subscription.id,
    text: subscription.name,
  }) as SelectOption);

  let selectedOption: string[] | undefined;

  if (defaultValue) {
    selectedOption = defaultValue;
  } else if (preferredSubscription) {
    selectedOption = [preferredSubscription];
  }

  React.useEffect(() => {
    if (!loading) {
      setKey(uniqueGUID());
      if (selectedOption?.length && !defaultValue) {
        onChange?.(selectedOption[0]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, JSON.stringify(selectedOption)]);

  return (
    <Select
      key={key}
      testId={testId}
      loading={loading}
      fieldName={fieldName}
      options={options}
      label={label}
      ariaLabel={ariaLabel}
      required={required}
      subField={subField}
      inputLink={inputLink}
      defaultValue={options?.length > 0 ? selectedOption : undefined}
      tooltip={tooltip}
      onChange={onChange}
      disabled={disabled}
      validator={validator}
    />
  );
};
