import {
  CalloutComponent,
  FormValues,
  getValue,
  InputFormCallout,
  InputFormGroup,
  SelectOption,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { LicenseTypeSelect } from "../../components/LicenseTypeSelect/LicenseTypeSelect";
import { CalloutTestIds } from "../../constants/uiConstants";
import { UpdateCloudVmClusterDetailsLicenseModelEnum } from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  VMCLUSTER_EDIT_POLL_DELAY,
  VMCLUSTER_EDIT_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export enum Fields {
  LicenseType = "licenseType",
}

export enum FieldsTestIds {
  LicenseType = "licenseType",
}

export interface ExaVmClusterEditLicenseProps extends OperationActionProps {
  vmClusterId: string | undefined;
  systemLicenseModel: string | undefined;
}

const ExaVmClusterEditLicense = (
  { targetId, location, vmClusterId, systemLicenseModel, onCancel, onExecute }: ExaVmClusterEditLicenseProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(vmClusterId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (systemLicenseModel) {
      const initFieldValues: FormValues = { [Fields.LicenseType]: [systemLicenseModel] };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemLicenseModel]);

  const { invokeCall: invokeVmClusterUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.updateCloudVmCluster },
  );
  const [licenseCalloutRef, setLicenseCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);
  const onEditLicense = (formValues: FormValues): void => {
    const licenseModel = getValue<SelectOption[]>(
      formValues,
      Fields.LicenseType,
      InputFormGroup,
    )?.[0].id as UpdateCloudVmClusterDetailsLicenseModelEnum | undefined;

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: () => {
        licenseCalloutRef?.allowResubmit();
      },
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateExadataVmClusterLicense(),
          message: Messages.notifications.inProgress.messages.updateExadataVmClusterLicense(resName),
        },
        success: {
          title: Messages.notifications.success.titles.updateExadataVmClusterLicense(),
          message: Messages.notifications.success.messages.updateExadataVmClusterLicense(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateExadataVmClusterLicense(),
          message: Messages.notifications.failure.messages.updateExadataVmClusterLicense(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.VMCLUSTER_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            cloudVmClusterName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.VMCLUSTER_EDIT_CHECK,
          delay: VMCLUSTER_EDIT_POLL_DELAY,
          interval: VMCLUSTER_EDIT_POLL_INTERVAL,
        },
      },
    };

    invokeVmClusterUpdate({
      cloudVmClusterName: resName,
      resourceGroupName: resGroup,
      subscriptionId,
      updateCloudVmClusterDetails: { licenseModel },
    }, invokeOptions);
  };
  return (
    <InputFormCallout
      componentRef={setLicenseCalloutRef}
      targetId={targetId}
      title={Messages.createNewPanels.licenseType.title()}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={(formValues: FormValues) => {
        onEditLicense(formValues);
      }}
      onClose={onCancel}
      testId={CalloutTestIds.ExaVmClusterEditLicense}
      initialValues={initialValues}
    >
      <LicenseTypeSelect
        testId={FieldsTestIds.LicenseType}
        fieldName={Fields.LicenseType}
        defaultValue={systemLicenseModel}
        label={Messages.labels.licenseType()}
      />
    </InputFormCallout>
  );
};

export const newExaVmClusterEditLicense = (
  props: ExaVmClusterEditLicenseProps,
): JSX.Element => (<ExaVmClusterEditLicense {...props} />);
