import {
  FormInputGroupLayout,
  FormValues,
  getValue,
  InputFormGroup,
  InputFormInline,
  Select,
  SelectOption,
  SubmitButtonMode,
  uniqueGUID,
} from "o4a-react";
import * as React from "react";
import { mergeStyleSets, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext, SettingType } from "../../console/SettingsContext";
import { DetailsPanelId, InlineFormId, PageId } from "../../constants/pluginConstants";
import { InputFormInlineTestIds } from "../../constants/uiConstants";
import { getSupportedLocales } from "../../helpers/supportedLocales";
import { useAnalytics } from "../../hooks/useAnalytics";

export enum Fields {
  Language = "language",
}

export enum FieldTestIds {
  Language = "language"
}

const classNames = mergeStyleSets({ languageSelectContainer: { width: "100%", minWidth: "107px", maxWidth: "404px" } });

export const SettingLanguage = (): JSX.Element => {
  const { trackFormSave } = useAnalytics();
  const { locale, setSettingValue } = React.useContext<Settings>(SettingsContext);
  const [formKey, setFormKey] = React.useState<string>(uniqueGUID());
  const [initialValues] = React.useState<FormValues>({ [Fields.Language]: [locale] });

  const onSubmit = (formValues: FormValues): void => {
    const languageVal = getValue<SelectOption[]>(formValues, Fields.Language, InputFormGroup)?.[0].id;
    setSettingValue(SettingType.LOCALE, languageVal);

    window.location.reload();
  };
  const optionItems = getSupportedLocales().map(item => {
    const { languageTag, endonym } = item;
    return ({ id: languageTag, text: endonym });
  });

  return (
    <InputFormInline
      key={formKey}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      initialValues={initialValues}
      layout={FormInputGroupLayout.WIDE}
      onSubmit={(formValues: FormValues) => {
        trackFormSave(InlineFormId.SETTING_LANGUAGE, PageId.SETTINGS, DetailsPanelId.LANGUAGE);
        onSubmit(formValues);
      }}
      onDiscard={() => setFormKey(uniqueGUID())}
      submitButtonText={Messages.actions.apply()}
      discardButtonText={Messages.actions.discardChanges()}
      testId={InputFormInlineTestIds.SettingLanguage}
    >
      <Stack className={classNames.languageSelectContainer}>
        <Select
          testId={FieldTestIds.Language}
          fieldName={Fields.Language}
          defaultValue={[locale]}
          label={Messages.labels.language()}
          options={optionItems}
          hideSearchBox
        />
      </Stack>
    </InputFormInline>
  );
};
