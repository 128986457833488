import { FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Fields as TagDetailsFields, TagsEditPanel } from "../../components/TagsEditPanel/TagsEditPanel";
import { TagsInfoType } from "../../helpers/resourceHelper";
import { useOperation } from "../../hooks/useOperation";
import { OperationProps } from "../OperationTypes";
import { ExaDbUpdateTagsCdbProps, newExaDbUpdateTagsCdb } from "./ExaDbUpdateTagsCdb";

export interface ExaDbEditTagsCdbProps extends OperationProps {
  databaseId: string;
  defaultTags?: TagsInfoType;
}

const ExaDbEditTagsCdb = (
  { location, databaseId, defaultTags, onExecute, onCancel }: ExaDbEditTagsCdbProps,
): JSX.Element => {
  const { trigger: triggerUpdateTagsCdb } = useOperation<ExaDbUpdateTagsCdbProps>(newExaDbUpdateTagsCdb);

  return (
    <TagsEditPanel
      title={Messages.labels.editTags()}
      resourceId={databaseId}
      tagsDefaultValue={defaultTags}
      onSubmit={(formValues: FormValues) => {
        const tags = getValue<TagsInfoType>(formValues, TagDetailsFields.Tags, InputFormGroup) ?? [];
        triggerUpdateTagsCdb({
          databaseId,
          tags,
          location,
          onExecute,
        });
      }}
      onClose={onCancel}
    />
  );
};

export const newExaDbEditTagsCdb = (
  props: ExaDbEditTagsCdbProps,
): JSX.Element => (<ExaDbEditTagsCdb {...props} />);
