import { AnalyticsClient } from "console-telemetry-client";
import React from "react";
import { ConsoleContext, ConsoleState } from "../console/ConsoleContext";
import { DetailsPanelId, InfoPanelId, InlineFormId, PageId } from "../constants/pluginConstants";
import { OciResourceType } from "../helpers/resourceHelper";
import { ANALYTICS_APP_NAME, ANALYTICS_NAMESPACE, EventType } from "../helpers/telemetryHelper";
import { useAppAuthContext } from "./useAppAuthContext";

export interface AnalyticsDataPage {
  pageId: PageId;
  panelId?: DetailsPanelId;
}
export interface LinkAnalyticsData {
  target: AnalyticsDataPage | InfoPanelId;
  source: AnalyticsDataPage;
}
export interface NavigationAnalyticsData extends AnalyticsDataPage {
  actionName: string;
}

export interface useAnalyticsTracks {
  trackPageLoad: (analyticsPath: string) => void;
  trackActionClick: (actionName: string, pageId: PageId, panelId?: DetailsPanelId) => void;
  trackActionDiscard: (actionName: string, pageId: PageId, panelId?: DetailsPanelId) => void;
  trackFormSave: (formId: InlineFormId, pageId: PageId, panelId?: DetailsPanelId) => void;
  trackLinkNavigate: (target: AnalyticsDataPage | InfoPanelId, pageId: PageId, panelId?: DetailsPanelId) => void;
  trackOciLinkNavigate: (target: OciResourceType, pageId: PageId, panelId?: DetailsPanelId) => void;
}

const getPageName = (pageId: PageId, panelId?: DetailsPanelId): string => {
  if (panelId) {
    return `${pageId}-${panelId}`;
  }
  return pageId;
};

const getActionEventName = (actionName: string, isDiscard: boolean): string => {
  if (isDiscard) {
    return `discard-action-${actionName}`;
  }
  return `click-action-${actionName}`;
};

export const useAnalytics = (): useAnalyticsTracks => {
  const { isCanarySession } = React.useContext<ConsoleState>(ConsoleContext);
  const { tenant } = useAppAuthContext();

  const postAnalytics = (eventName: string, eventType: EventType): void => {
    if (!isCanarySession) {
      AnalyticsClient.track(ANALYTICS_NAMESPACE, {
        "wt.app": ANALYTICS_APP_NAME,
        "wt.dl": eventType,
        "wt.oaeve": eventName,
        "wt.azTenantId": tenant,
      });
    }
  };

  return {
    trackPageLoad: (analyticsPath: string): void => {
      const eventName = `${ANALYTICS_NAMESPACE}:app-router:${analyticsPath}`;
      postAnalytics(eventName, EventType.PageViewEvent);
    },
    trackActionClick: (actionName: string, pageId: PageId, panelId?: DetailsPanelId): void => {
      const pageName = getPageName(pageId, panelId);
      const eventName = `${ANALYTICS_NAMESPACE}:${pageName}:${getActionEventName(actionName, false)}`;
      postAnalytics(eventName, EventType.ClickEvent);
    },
    trackActionDiscard: (actionName: string, pageId: PageId, panelId?: DetailsPanelId): void => {
      const pageName = getPageName(pageId, panelId);
      const eventName = `${ANALYTICS_NAMESPACE}:${pageName}:${getActionEventName(actionName, true)}`;
      postAnalytics(eventName, EventType.ClickEvent);
    },
    trackFormSave: (formId: InlineFormId, pageId: PageId, panelId?: DetailsPanelId): void => {
      const pageName = getPageName(pageId, panelId);
      const eventName = `${ANALYTICS_NAMESPACE}:${pageName}:save-form-${formId}`;
      postAnalytics(eventName, EventType.ClickEvent);
    },
    trackLinkNavigate: (target: AnalyticsDataPage | InfoPanelId, pageId: PageId, panelId?: DetailsPanelId): void => {
      const srcPageName = getPageName(pageId, panelId);
      let trgtPageName;
      if (target instanceof Object && "pageId" in target) {
        const trgt = target as AnalyticsDataPage;
        trgtPageName = getPageName(trgt.pageId, trgt.panelId);
      } else {
        trgtPageName = `${target as InfoPanelId}-panel`;
      }
      const eventName = `${ANALYTICS_NAMESPACE}:${srcPageName}:navigate-link-${trgtPageName}`;
      postAnalytics(eventName, EventType.ClickEvent);
    },
    trackOciLinkNavigate: (target: OciResourceType, pageId: PageId, panelId?: DetailsPanelId): void => {
      const srcPageName = getPageName(pageId, panelId);
      const eventName = `${ANALYTICS_NAMESPACE}:${srcPageName}:navigate-oci-link-${target}`;
      postAnalytics(eventName, EventType.ClickEvent);
    },
  };
};
