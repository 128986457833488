import {
  DateTimePicker,
  InputFormCallout,
  InputFormCalloutProps,
  RadioGroup,
  RadioGroupOption,
  SubmitButtonMode,
  TextInput,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { CalloutTestIds } from "../../constants/uiConstants";
import { getTimezoneName } from "../../helpers/jsMiscHelper.js";
import { BackupRestoreType } from "../../helpers/resourceHelper";

export enum Fields {
  Type = "type",
  SCN = "scn",
  Timestamp = "timestamp",
}

export enum FieldTestIds {
  Type = "type-test",
  SCN = "scn-test",
  Timestamp = "timestamp-test",
}

export enum RestoreTypeOptionsTestIds {
  Latest = "latest-option",
  Timestamp = "timestamp-option",
  SCN = "scn-option",
}

export interface BackupRestorePanelProps extends
  Pick<InputFormCalloutProps, "targetId" | "onClose" | "onSubmit" | "componentRef"> {
  timeCreated?: Date;
}

const defaultRestoreType = BackupRestoreType.LATEST;
const restoreTypeOptions = (timezoneName: string): RadioGroupOption[] => ([
  {
    testId: RestoreTypeOptionsTestIds.Latest,
    id: BackupRestoreType.LATEST,
    text: Messages.labels.backupRestoreLatest(),
  },
  {
    testId: RestoreTypeOptionsTestIds.Timestamp,
    id: BackupRestoreType.TIMESTAMP,
    text: Messages.labels.backupRestoreTimestamp(timezoneName),
  },
  {
    testId: RestoreTypeOptionsTestIds.SCN,
    id: BackupRestoreType.SCN,
    text: Messages.labels.backupRestoreSCN(),
  },
] as RadioGroupOption[]);

export const BackupRestorePanel = ({
  targetId,
  componentRef,
  onClose,
  onSubmit,
  timeCreated,
}: BackupRestorePanelProps): JSX.Element => {
  const [restoreType, setRestoreType] = React.useState<string>(defaultRestoreType);
  const { locale } = React.useContext<Settings>(SettingsContext);
  const minDate = timeCreated ? new Date(timeCreated).getTime() : new Date(Date.now() - 60 * 24 * 3600 * 1000);
  const timezoneName = getTimezoneName(locale);
  return (
    <InputFormCallout
      testId={CalloutTestIds.BackupRestorePanel}
      componentRef={componentRef}
      title={Messages.createNewPanels.restore.title()}
      targetId={targetId}
      onClose={onClose}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      primaryButtonText={Messages.actions.restore()}
    >
      <RadioGroup
        testId={FieldTestIds.Type}
        fieldName={Fields.Type}
        options={restoreTypeOptions(timezoneName)}
        defaultValue={defaultRestoreType}
        onChange={setRestoreType}
      />
      {restoreType === BackupRestoreType.TIMESTAMP && (
        <DateTimePicker
          locale={locale}
          fieldName={Fields.Timestamp}
          testId={FieldTestIds.Timestamp}
          minDate={new Date(minDate)}
          maxDate={new Date(Date.now())}
        />
      )}
      {restoreType === BackupRestoreType.SCN && (
      <TextInput
        required
        testId={FieldTestIds.SCN}
        fieldName={Fields.SCN}
        ariaLabel={Messages.ariaLabel.scn()}
      />
      )}
    </InputFormCallout>
  );
};
