import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_HEATWAVE_RESTART_POLL_DELAY,
  MDS_HEATWAVE_RESTART_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface MysqlRestartHeatWaveProps extends OperationConfirmationProps {
  databaseId: string | undefined;
}

const MysqlRestartHeatWave = (
  { location, databaseId, onExecute }: MysqlRestartHeatWaveProps,
): JSX.Element => {
  const { invokeCall: invokeRestart } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.restartHeatWaveCluster },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
    const resourceGroupName = decodeURIComponent(resourceGroup || "");
    const dbSystemName = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.restartHeatWaveCluster(),
          message: Messages.notifications.inProgress.messages.restartHeatWaveCluster(dbSystemName),
        },
        success: {
          title: Messages.notifications.success.titles.restartHeatWaveCluster(),
          message: Messages.notifications.success.messages.restartHeatWaveCluster(dbSystemName),
        },
        failure: {
          title: Messages.notifications.failure.titles.restart(),
          message: Messages.notifications.failure.messages.restartHeatWaveCluster(dbSystemName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_HEATWAVE_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            dbSystemName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_HEATWAVE_RESTARTED_CHECK,
          delay: MDS_HEATWAVE_RESTART_POLL_DELAY,
          interval: MDS_HEATWAVE_RESTART_POLL_INTERVAL,
        },
      },
    };
    invokeRestart({
      subscriptionId,
      resourceGroupName,
      dbSystemName,
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export const newMysqlRestartHeatWave = (
  props: MysqlRestartHeatWaveProps,
): JSX.Element => (<MysqlRestartHeatWave {...props} />);
