import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { getTagsMap, TagsInfoType } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  MDS_BACKUP_UPDATE_POLL_DELAY,
  MDS_BACKUP_UPDATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationConfirmationProps } from "../OperationTypes";

export interface MysqlBackupUpdateTagsProps extends OperationConfirmationProps {
  backupId: string | undefined;
  tags: TagsInfoType;
}

const MysqlBackupUpdateTags = (
  { location, backupId, tags, onExecute }: MysqlBackupUpdateTagsProps,
): JSX.Element => {
  const { invokeCall: invokeMdsBackupTagUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.updateMdsBackup },
  );

  React.useEffect(() => {
    const { subscriptionId, resourceGroup, resourceName } = parseId(backupId);
    const resourceGroupName = decodeURIComponent(resourceGroup || "");
    const backupKey = decodeURIComponent(resourceName || "");

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: onExecute,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.applyTags(),
          message: Messages.notifications.inProgress.messages.applyTags(backupKey),
        },
        success: {
          title: Messages.notifications.success.titles.applyTags(),
          message: Messages.notifications.success.messages.applyTags(backupKey),
        },
        failure: {
          title: Messages.notifications.failure.titles.applyTags(),
          message: Messages.notifications.failure.messages.applyTags(backupKey),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.MDS_BACKUP_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName,
            backupKey,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.MDS_BACKUP_UPDATED_CHECK,
          delay: MDS_BACKUP_UPDATE_POLL_DELAY,
          interval: MDS_BACKUP_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeMdsBackupTagUpdate({
      subscriptionId,
      resourceGroupName,
      backupKey,
      updateMdsBackupDetails: { freeformTags: getTagsMap(tags) },
    }, invokeOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-test-id={emptyDivTestId} />;
};

export const newMysqlBackupUpdateTags = (
  props: MysqlBackupUpdateTagsProps,
): JSX.Element => (<MysqlBackupUpdateTags {...props} />);
