export interface ServiceLinkItem {
  testId?: string;
  text: string;
  url?: string;
  onClick?: () => void;
}
export interface ServiceItem {
  testId?: string;
  image?: React.ReactNode;
  title: React.ReactNode;
  url?: string;
  onClick?: () => void;
  links?: ServiceLinkItem[];
  disabled?: boolean,
}

export interface CategoryService {
  testId?: string;
  category: ResourceCategories,
  services?: ServiceItem[],
}

export enum ResourceCategories {
  DATABASE = "DATABASE",
  NETWORK = "NETWORK",
  RECENTLY = "RECENTLY",
}
