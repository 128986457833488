import {
  ActionBar,
  ActionType,
  InfoBlockStatus,
  LabelMetaItem,
  MetaItemSection,
  NoValue,
  optimizedRetryOption,
} from "o4a-react";
import * as React from "react";
import { Stack } from "@fluentui/react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  CloudVmCluster,
  DbSystemShapeSummary,
  DbSystemShapeSummaryCollection,
} from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import {
  getLocalizedLicenseType,
  getMaxCoreCountPerNode,
  getMinCoreCountPerNode,
  getShapeDisplayName,
  isZeroCoreCountPerNodeAllowed,
  responseItemstoArray,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSidePanel } from "../../hooks/useSidePanel";
import {
  ExaVmClusterAddCapacityProps,
  newExaVmClusterAddCapacity,
} from "../../operations/ExaVmCluster/ExaVmClusterAddCapacity";
import {
  ExaVmClusterEditLicenseProps,
  newExaVmClusterEditLicense,
} from "../../operations/ExaVmCluster/ExaVmClusterEditLicense";
import { ExaVmClusterScaleProps, newExaVmClusterScale } from "../../operations/ExaVmCluster/ExaVmClusterScale";
import { getOciRegion } from "../../utils";

export enum ActionIds {
  Scale = "scale",
  Refresh = "refresh",
  AddCapacity = "add-capacity",
  EditLicense = "edit-license",
}

export enum ActionBarTestIds {
  Scale = "scale",
  Refresh = "refresh",
  AddCapacity = "add-capacity",
}

export interface ExaVmClusterConfigurationDetailsProps {
  vmCluster: CloudVmCluster | undefined;
  location: string;
  resourceGroupName: string;
  subscriptionId: string;
  refresh: () => void;
}

export enum MetaItemSectionTestIds {
  GI_VERSION = "exa-vmCluster-gi-version",
  SYS_VERSION = "exa-vmCluster-sys-version",
  SHAPE = "exa-vmCluster-shape",
  NODE_COUNT = "exa-vmCluster-node-count",
  OCPU_COUNT = "exa-vmCluster-ocpu-count",
  DATA_STORAGE = "exa-vmCluster-data-storage",
  BACKUPS_STORAGE = "exa-vmCluster-backups-storage",
  SNAPSHOT_STORAGE = "exa-vmCluster-snapshot-storage",
  LICENSE = "exa-vmCluster-license",
  HOSTNAME = "exa-vmCluster-hostname",
  LISTENER_PORT = "exa-vmCluster-listener-port",
  SCAN_DNS_NAME = "exa-vmCluster-scan-dns-name",
  SCAN_LISTENER_PORT = "exa-vmCluster-scan-listener-port",
  SCAN_LISTENER_SSL_PORT = "exa-vmCluster-scan-license-ssl-port",
  SCAN_IP = "exa-vmCluster-scan-ip",
  VIP = "exa-vmcluster-vip"
}

export enum MetaItemActionTestIds {
  EditLicense = "edit-license",
}

export const ExaVmClusterConfigurationDetails = (
  { vmCluster, location, subscriptionId, resourceGroupName, refresh }: ExaVmClusterConfigurationDetailsProps,
): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { enableAddVMCapacity } = useFeatures();

  const { loading: shapeLoading, response: shapeResponse, error: shapeError } = useQueryCall({
    wait: !vmCluster,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDbSystemShapesBySubscription,
    options: {
      args: { subscriptionId: subscriptionId || "" },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
  });
  const shape = (shapeResponse
    && responseItemstoArray<DbSystemShapeSummaryCollection, DbSystemShapeSummary>(shapeResponse))
    ?.find(item => (item.name === vmCluster?.shape));

  const disableScaleAction: boolean = !!shapeLoading || !!shapeError;
  const defaultOcpuCount = (vmCluster?.cpuCoreCount && vmCluster?.nodeCount)
    ? vmCluster.cpuCoreCount / vmCluster.nodeCount : undefined;

  const { loading: VmCapacityLoading, response: VmCapacityResponse } = useQueryCall({
    wait: !(vmCluster && enableAddVMCapacity),
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.getCloudVmClusterAvailableCapacity,
    options: {
      args: {
        subscriptionId: subscriptionId || "",
        resourceGroupName,
        cloudVmClusterName: decodeURIComponent(parseId(vmCluster?.id)?.resourceName || ""),
      },
      caching: ttlOneMinCaching,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadVmClusterCapacity(),
      },
    },
  });
  const { closePanels } = useSidePanel();

  const vmClusterCapacity = VmCapacityResponse?.data;
  const nodeCountCapacity = vmClusterCapacity?.availableComputeCount;
  const storageCapacity = vmClusterCapacity?.availableStorageSizeInGBs;
  const disableAddCapacityAction: boolean = (nodeCountCapacity === 0 || nodeCountCapacity === undefined)
    && (storageCapacity === 0 || storageCapacity === undefined);

  const { trigger: triggerEditLicense } = useOperation<ExaVmClusterEditLicenseProps>(newExaVmClusterEditLicense);
  const { trigger: triggerScale } = useOperation<ExaVmClusterScaleProps>(newExaVmClusterScale);
  const { trigger: triggerAddCapacity } = useOperation<ExaVmClusterAddCapacityProps>(newExaVmClusterAddCapacity);

  const configurationActionItems = (): ActionType[] => {
    const actionItems = [];
    if (enableAddVMCapacity) {
      actionItems.push(
        {
          key: ActionIds.AddCapacity,
          testId: ActionBarTestIds.AddCapacity,
          text: Messages.actions.addCapacity(),
          icon: MonochromeIconIds.ScaleUp,
          onClick: () => {
            trackActionClick(ActionIds.AddCapacity, PageId.VMCLUSTER_DETAILS, DetailsPanelId.CONFIGURATION);
            triggerAddCapacity({
              targetId: ActionIds.AddCapacity,
              vmClusterId: vmCluster?.id,
              location,
              vmClusterCapacity,
              onExecute: refresh,
              onCancel: () => trackActionDiscard(
                ActionIds.AddCapacity,
                PageId.VMCLUSTER_DETAILS,
                DetailsPanelId.CONFIGURATION,
              ),
            });
          },
          disabled: disableAddCapacityAction,
        },
      );
    }
    actionItems.push(
      {
        key: ActionIds.Scale,
        testId: ActionBarTestIds.Scale,
        text: Messages.actions.scale(),
        icon: MonochromeIconIds.Scale,
        onClick: () => {
          trackActionClick(ActionIds.Scale, PageId.VMCLUSTER_DETAILS, DetailsPanelId.CONFIGURATION);
          triggerScale({
            targetId: ActionIds.Scale,
            vmClusterId: vmCluster?.id,
            scaleConfig: vmCluster
              ? {
                defaultValue: defaultOcpuCount,
                min: getMinCoreCountPerNode(shape),
                max: getMaxCoreCountPerNode(shape),
                isZeroAllowed: isZeroCoreCountPerNodeAllowed(shape),
                nodeCount: vmCluster.nodeCount,
              }
              : undefined,
            location,
            onExecute: refresh,
            onCancel: () => trackActionDiscard(
              ActionIds.Scale,
              PageId.VMCLUSTER_DETAILS,
              DetailsPanelId.CONFIGURATION,
            ),
          });
        },
        disabled: disableScaleAction,
      },
      {
        key: ActionIds.Refresh,
        testId: ActionBarTestIds.Refresh,
        text: Messages.actions.refresh(),
        icon: MonochromeIconIds.Refresh,
        onClick: () => {
          trackActionClick(ActionIds.Refresh, PageId.VMCLUSTER_DETAILS, DetailsPanelId.CONFIGURATION);
          refresh();
        },
      },
    );
    return actionItems;
  };

  const onEditLicense = (): void => {
    triggerEditLicense({
      targetId: ActionIds.EditLicense,
      vmClusterId: vmCluster?.id,
      systemLicenseModel: vmCluster?.licenseModel,
      location,
      onExecute: refresh,
      onCancel: () => {
        trackActionDiscard(ActionIds.EditLicense, PageId.VMCLUSTER_DETAILS, DetailsPanelId.CONFIGURATION);
      },
    });
  };

  const disabledActionsInfoBlocks = [{
    message: Messages.hints.disabledAddCapacityAction(),
    messageType: InfoBlockStatus.INFO,
  }];

  const currentStorageSize = vmCluster?.storageSizeInGBs;
  const storageSize = Messages.labels.storageValueInGBPlaceholder(currentStorageSize?.toString() || "");

  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
      <ActionBar
        actions={configurationActionItems()}
        onActionClick={closePanels}
        infoBlocks={
          (disableAddCapacityAction && enableAddVMCapacity && !VmCapacityLoading) ? disabledActionsInfoBlocks : []
        }
      />
      <MetaItemSection labelWidth={233}>
        <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.GI_VERSION}
            label={Messages.labels.giVersion()}
          >
            {vmCluster?.giVersion || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.SYS_VERSION}
            label={Messages.labels.systemVersion()}
          >
            {vmCluster?.systemVersion || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.SHAPE}
            label={Messages.labels.shape()}
          >
            {vmCluster?.shape ? getShapeDisplayName(vmCluster.shape) : <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.NODE_COUNT}
            label={Messages.labels.nodeCount()}
          >
            {vmCluster?.nodeCount?.toString() || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.OCPU_COUNT}
            label={Messages.labels.ocpuCount()}
          >
            {vmCluster?.cpuCoreCount?.toString() || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.DATA_STORAGE}
            label={Messages.labels.availableDataStorage()}
          >
            {currentStorageSize ? storageSize : <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.BACKUPS_STORAGE}
            label={Messages.labels.storageLocalBackup()}
          >
            {vmCluster?.isLocalBackupEnabled ? Messages.common.enabled() : Messages.common.disabled()}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.SNAPSHOT_STORAGE}
            label={Messages.labels.storageSparseSnapshots()}
          >
            {vmCluster?.isSparseDiskgroupEnabled ? Messages.common.enabled() : Messages.common.disabled()}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.LICENSE}
            label={Messages.labels.license()}
            action={{
              id: ActionIds.EditLicense,
              testId: MetaItemActionTestIds.EditLicense,
              label: Messages.actions.edit(),
              onClick: () => {
                trackActionClick(ActionIds.EditLicense, PageId.VMCLUSTER_DETAILS, DetailsPanelId.CONFIGURATION);
                onEditLicense();
              },
            }}
          >
            {vmCluster?.licenseModel ? getLocalizedLicenseType(vmCluster.licenseModel) : <NoValue />}
          </LabelMetaItem>
        </Stack>
        <Stack tokens={{ childrenGap: metaItemLabelsGap }}>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.HOSTNAME}
            label={Messages.labels.hostNameOnly()}
          >
            {(vmCluster?.hostname && vmCluster?.domain)
              ? `${vmCluster.hostname}.${vmCluster.domain}`
              : <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.LISTENER_PORT}
            label={Messages.labels.listenerPort()}
          >
            {vmCluster?.listenerPort?.toString() || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.SCAN_DNS_NAME}
            label={Messages.labels.scanDnsName()}
          >
            {vmCluster?.scanDnsName || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.SCAN_LISTENER_PORT}
            label={Messages.labels.scanListenerPort()}
          >
            {vmCluster?.scanListenerPortTcp?.toString() || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.SCAN_LISTENER_SSL_PORT}
            label={Messages.labels.scanListenerPortSsl()}
          >
            {vmCluster?.scanListenerPortTcpSsl?.toString() || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.SCAN_IP}
            label={Messages.labels.scanIps()}
          >
            {vmCluster?.scanIps?.join(", ") || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            testId={MetaItemSectionTestIds.VIP}
            label={Messages.labels.vips()}
          >
            {vmCluster?.vips?.join(", ") || <NoValue />}
          </LabelMetaItem>
        </Stack>
      </MetaItemSection>
    </Stack>
  );
};
