import { AsyncValidationError, NotificationRequest, NotificationType, useNotificationRequest } from "o4a-react";
import * as React from "react";
import { useMutation } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { emptyDivTestId } from "../../constants/uiConstants";
import { errorMap2AsyncValidationErrors, PreflightError } from "../../helpers/validationHelper";
import { useFeatures } from "../../hooks/useFeatures";
import { getOciRegion } from "../../utils";

export interface DeploymentAsyncValidationPhaseProps {
  location: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asyncValidatePayload: any;
  onProcessAsyncValidationErrors?: (asyncErrors: AsyncValidationError[]) => AsyncValidationError[];
  asyncValidateResolve: (errors?: AsyncValidationError[]) => void;
  asyncValidateReject: (errorMessage: string) => void;
  onPostAsyncValidate: () => void;
}

export const DeploymentAsyncValidationPhase = ({
  location,
  asyncValidatePayload,
  onProcessAsyncValidationErrors,
  asyncValidateResolve,
  asyncValidateReject,
  onPostAsyncValidate,
}: DeploymentAsyncValidationPhaseProps): JSX.Element => {
  const { submit: submitNotificationRequest } = useNotificationRequest();
  const { useOpcDryRunHeader } = useFeatures();

  const { invoke, result } = useMutation(
    { method: apiClients.withRegion(getOciRegion(location)).deploymentApi.createDeployment },
  );

  React.useEffect(() => {
    invoke({
      ...asyncValidatePayload,
      mcpDryRun: useOpcDryRunHeader ? undefined : "All",
      opcDryRun: useOpcDryRunHeader ? "true" : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (result && !result.loading) {
      if (result.error) {
        const { body: { messageArguments } } = result.error as PreflightError;
        if (messageArguments) {
          let asyncErrors = errorMap2AsyncValidationErrors(messageArguments);
          if (onProcessAsyncValidationErrors) {
            asyncErrors = onProcessAsyncValidationErrors(asyncErrors);
          }
          asyncValidateResolve(asyncErrors);
        } else {
          submitNotificationRequest({
            type: NotificationType.FAILURE,
            title: Messages.notifications.failure.titles.validationCheck(),
            message: Messages.notifications.failure.messages.validationCheck(),
            apiError: result.error.body.message,
          } as NotificationRequest);

          asyncValidateReject(Messages.validation.unableToValidate());
        }
      } else {
        asyncValidateResolve();
      }
      onPostAsyncValidate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return <div data-test-id={emptyDivTestId} />;
};
