import {
  ActionType,
  CustomAction,
  getResourceLifecycleStatus,
  InfoBlockProps,
  InfoBlockStatus,
  Listing,
  ListingColumn,
  ListingComponent,
  ListingDisplayNameLink,
  optimizedRetryOption,
  SelectionMode,
  SortDirections,
  stateT,
  Status,
} from "o4a-react";
import * as React from "react";
import apiClients, { MultiCloudDatabaseApiVersion } from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AzureResourceGroupLink } from "../../components/AzureLinks/AzureResourceGroupLink";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { DetailsPanelId, PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { ListingTestIds, MonochromeIconIds, ttlOneMinCaching } from "../../constants/uiConstants";
import {
  Database,
  PluggableDatabaseSummary,
  PluggableDatabaseSummaryCollection,
} from "../../gen/clients/mchub-azure-api-client-exa";
import { parseId } from "../../helpers/idHelper";
import {
  getStatusInfo,
  PdbOpenMode,
  ResourceStatus as PdbStatus,
  responseItemstoArray,
  statusSortComparator,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useOperation } from "../../hooks/useOperation";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useRowCount } from "../../hooks/useRowCount";
import { useSidePanel } from "../../hooks/useSidePanel";
import { ExaDbCreatePdbProps, newExaDbCreatePdb } from "../../operations/ExaDb/ExaDbCreatePdb";
import { ExaDbDeletePdbProps, newExaDbDeletePdb } from "../../operations/ExaDb/ExaDbDeletePdb";
import { ExaDbStartPdbProps, newExaDbStartPdb } from "../../operations/ExaDb/ExaDbStartPdb";
import { ExaDbStopPdbProps, newExaDbStopPdb } from "../../operations/ExaDb/ExaDbStopPdb";
import {
  ExaDbViewConnectionStringsProps,
  newExaDbViewConnectionStrings,
} from "../../operations/ExaDb/ExaDbViewConnectionStrings";
import { getAzureLocationName, getOciRegion } from "../../utils";

export interface ExaDbPdbsListProps {
  cdb: Database;
  location: string;
}

export enum ColumnIds {
  Name = "name",
  ResourceGroupName = "id",
  Location = "location",
  ResourceStatus = "status",
}

export enum ColumnTestIds {
  Name = "name",
  ResourceGroupName = "id",
  Location = "location",
  ResourceStatus = "status",
}

export enum ActionBarIds {
  Create = "action-bar-create",
  Refresh = "action-bar-refresh",
  Start = "action-bar-start",
  Stop = "action-bar-stop",
  ConnectionStrings = "action-bar-connection-strings",
  Delete = "action-bar-delete"
}

export enum ActionBarTestIds {
  Create = "action-bar-create",
  Refresh = "action-bar-refresh",
  Start = "action-bar-start",
  Stop = "action-bar-stop",
  ConnectionStrings = "action-bar-connection-strings",
  Delete = "action-bar-delete"
}

export enum ActionMenuIds {
  Start = "action-menu-start",
  Stop = "action-menu-stop",
  ConnectionStrings = "action-menu-connection-strings",
  Delete = "action-menu-delete"
}

export enum ActionMenuTestIds {
  Start = "action-menu-start",
  Stop = "action-menu-stop",
  ConnectionStrings = "action-menu-connection-strings",
  Delete= "action-menu-delete"
}

export enum InfoBlockTestIds {
  DisabledActions = "disabled-actions-info-block"
}

const detailsPageRegistrationIds = PageRegistrationConfig[PageId.EXADB_PDB_DETAILS].map(config => config.key);
const pageId = PageId.EXADB_CDB_DETAILS;

export const ExaDbPdbsList = (
  { cdb, location }: ExaDbPdbsListProps,
): JSX.Element => {
  const { locale } = React.useContext<Settings>(SettingsContext);

  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { resourceGroup, subscriptionId } = parseId(cdb.id);

  const [selection, setSelection] = React.useState<PluggableDatabaseSummary[]>([]);

  const cdbResGroup = decodeURIComponent(resourceGroup || "");

  const [listingComponentRef, setListingComponentRef] = React.useState<ListingComponent<PluggableDatabaseSummary>>();

  const { perPageItemsCount } = useRowCount();

  const { response, loading, refresh } = useQueryCall({
    wait: !cdb.id,
    method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listPluggableDatabases,
    options: {
      args: {
        subscriptionId,
        resourceGroupName: cdbResGroup,
        apiVersion: MultiCloudDatabaseApiVersion,
        databaseId: cdb.id,
      },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: Messages.notifications.failure.messages.loadPluggableDatabase(),
      },
    },
  });

  const pdbs = response
    && responseItemstoArray<PluggableDatabaseSummaryCollection, PluggableDatabaseSummary>(response);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = React.useMemo(() => pdbs, [JSON.stringify(pdbs)]);

  const getLocation = (value: PluggableDatabaseSummary): string => getAzureLocationName(value?.location || "");

  const getStatus = (value: PluggableDatabaseSummary): JSX.Element => (
    <Status
      label={stateT(value?.status || "")}
      tooltip={stateT(value?.status || "")}
      status={getResourceLifecycleStatus(value?.status || "")}
      statusInfo={
        getStatusInfo(
          value?.status || "",
          value?.lastOperationStatus || "",
          value?.lastOperationStatusDetails || "",
          value?.lifecycleDetails,
        )
      }
      hideClipboardCopy
    />
  );

  const disabledActionsInfoBlocks = (selectedItem: PluggableDatabaseSummary): InfoBlockProps[] => {
    const disabledActionInfo: InfoBlockProps[] = [];
    const disabledActions: string[] = [];
    if (pdbFailedState(selectedItem)) {
      disabledActions.push(Messages.actions.start());
      disabledActions.push(Messages.actions.stop());
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          ? Messages.hints.pdbDisabledActionPdbStatus(disabledActions.toString(), stateT(selectedItem?.status || ""))
          : Messages.hints.pdbDisabledActionsPdbStatus(disabledActions.join(", "), stateT(selectedItem?.status || ""));
        disabledActionInfo.push(
          {
            testId: InfoBlockTestIds.DisabledActions,
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    } else {
      if (cdbFailedState() && selectedItem) {
        disabledActions.push(Messages.actions.start());
        disabledActions.push(Messages.actions.stop());
      }
      if (disabledActions.length > 0) {
        const disabledMessage = disabledActions.length === 1
          // eslint-disable-next-line max-len
          ? Messages.hints.pdbDisabledActionCdbStatus(disabledActions.toString(), stateT(containerDbStatus || ""))
          // eslint-disable-next-line max-len
          : Messages.hints.pdbDisabledActionsCdbStatus(disabledActions.join(", "), stateT(containerDbStatus || ""));
        disabledActionInfo.push(
          {
            testId: InfoBlockTestIds.DisabledActions,
            message: disabledMessage,
            messageType: InfoBlockStatus.INFO,
          },
        );
      }
    }
    return disabledActionInfo;
  };

  const columns: ListingColumn[] = [
    {
      itemProp: ColumnIds.Name,
      testId: ColumnTestIds.Name,
      name: Messages.common.name(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.name}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[PageId.EXADB_PDB_DETAILS][0].panelPath}?location=${value.location}`,
            pageKey: detailsPageRegistrationIds[0],
          }}
        />
      ),
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.ResourceStatus,
      testId: ColumnTestIds.ResourceStatus,
      name: Messages.labels.status(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      comparator: (
        a: PluggableDatabaseSummary,
        b: PluggableDatabaseSummary,
      ) => statusSortComparator<PluggableDatabaseSummary>(a, b, locale),
      isResizable: true,
      onRenderItems: value => getStatus(value),
    },
  ];

  const setSelectedItems = (selectedItems: PluggableDatabaseSummary[]): void => {
    setSelection(selectedItems);
  };

  const { trigger: triggerCreatePdb } = useOperation<ExaDbCreatePdbProps>(newExaDbCreatePdb);
  const { trigger: triggerDeletePdb } = useOperation<ExaDbDeletePdbProps>(newExaDbDeletePdb);
  const { trigger: triggerStartPdb } = useOperation<ExaDbStartPdbProps>(newExaDbStartPdb);
  const { trigger: triggerStopPdb } = useOperation<ExaDbStopPdbProps>(newExaDbStopPdb);
  const { trigger: triggerViewConnectionStrings } = useOperation<ExaDbViewConnectionStringsProps>(
    newExaDbViewConnectionStrings,
  );

  const { closePanels } = useSidePanel();

  const containerDbStatus = cdb ? cdb?.status : undefined;
  const cdbFailedState = (): boolean => (containerDbStatus === PdbStatus.Failed);
  const pdbFailedState = (selectedItem:PluggableDatabaseSummary)
  : boolean => (selectedItem?.status === PdbStatus.Failed);

  const openModesThatDisablePdbStop = (selectedItem:PluggableDatabaseSummary)
  : boolean => ((selectedItem?.openMode !== PdbOpenMode.READ_ONLY
    && selectedItem?.openMode !== PdbOpenMode.READ_WRITE));
  const openModeThatDisablesPdbStart = (selectedItem:PluggableDatabaseSummary)
  : boolean => (selectedItem?.openMode !== PdbOpenMode.MOUNTED);

  const buildDeleteAction = (key:string, testId:string, selectedItem: PluggableDatabaseSummary)
  :CustomAction<PluggableDatabaseSummary | undefined> => {
    const deleteAction = {
      key,
      testId,
      text: Messages.actions.delete(),
      icon: MonochromeIconIds.Delete,
      disabled: !selectedItem,
      title: Messages.actions.delete(),
      description: Messages.hints.deletePluggableDBConfirmation(
        selectedItem ? selectedItem.name : "",
      ),
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.PLUGGABLEDATABASES);
      },
      onConfirm: () => {
        triggerDeletePdb({
          databaseId: selectedItem?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, pageId, DetailsPanelId.PLUGGABLEDATABASES),
    };
    return deleteAction;
  };

  const buildStartAction = (key:string, testId:string, selectedItem: PluggableDatabaseSummary)
  :CustomAction<PluggableDatabaseSummary | undefined> => {
    const startAction = {
      key,
      testId,
      text: Messages.actions.start(),
      icon: MonochromeIconIds.Play,
      title: Messages.labels.startExaCSDB(),
      description: Messages.hints.startExaCSDBConfirmation(selectedItem ? selectedItem.name : ""),
      disabled: cdbFailedState() || pdbFailedState(selectedItem)
          || openModeThatDisablesPdbStart(selectedItem),
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.PLUGGABLEDATABASES);
      },
      onConfirm: () => {
        triggerStartPdb({
          databaseId: selectedItem?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, pageId, DetailsPanelId.PLUGGABLEDATABASES),
    };
    return startAction;
  };

  const buildStopAction = (key:string, testId:string, selectedItem: PluggableDatabaseSummary)
  :CustomAction<PluggableDatabaseSummary | undefined> => {
    const stopAction = {
      key,
      testId,
      text: Messages.actions.stop(),
      icon: MonochromeIconIds.Stop,
      title: Messages.labels.stopExaCSDB(),
      description: Messages.hints.stopExaCSDBConfirmation(selectedItem ? selectedItem.name : ""),
      disabled: cdbFailedState() || pdbFailedState(selectedItem)
          || openModesThatDisablePdbStop(selectedItem),
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.PLUGGABLEDATABASES);
      },
      onConfirm: () => {
        triggerStopPdb({
          databaseId: selectedItem?.id,
          location,
          onExecute: () => {
            listingComponentRef?.resetTableSelection();
            refresh();
          },
        });
      },
      onCancel: () => trackActionDiscard(key, pageId, DetailsPanelId.PLUGGABLEDATABASES),
    };
    return stopAction;
  };
  const buildViewConnectionStringsAction = (key: string, testId: string, selectedItem: PluggableDatabaseSummary)
  : CustomAction<PluggableDatabaseSummary | undefined> => {
    const viewConnectionStringsAction = {
      key,
      testId,
      text: Messages.actions.connectionStrings(),
      icon: MonochromeIconIds.Connect,
      disabled: !selectedItem,
      onClick: () => {
        trackActionClick(key, pageId, DetailsPanelId.PLUGGABLEDATABASES);
        triggerViewConnectionStrings({
          databaseId: selectedItem.id,
          isPdb: true,
          location,
        });
      },
    };
    return viewConnectionStringsAction;
  };

  const dbRowActionMenu = (item?: PluggableDatabaseSummary | undefined): ActionType<PluggableDatabaseSummary>[] => {
    const dbActionItems : ActionType<PluggableDatabaseSummary | undefined>[] = [];
    if (item) {
      dbActionItems.push(
        buildStartAction(ActionMenuIds.Start, ActionMenuTestIds.Start, item),
        buildStopAction(ActionMenuIds.Stop, ActionMenuTestIds.Stop, item),
        buildViewConnectionStringsAction(ActionMenuIds.ConnectionStrings, ActionMenuTestIds.ConnectionStrings, item),
        buildDeleteAction(ActionMenuIds.Delete, ActionMenuTestIds.Delete, item),
      );
    }
    return dbActionItems;
  };

  const dbBarActions: ActionType<PluggableDatabaseSummary>[] = [
    {
      key: ActionBarIds.Create,
      testId: ActionBarTestIds.Create,
      text: Messages.actions.create(),
      icon: MonochromeIconIds.Create,
      onClick: () => {
        listingComponentRef?.resetTableSelection();
        trackActionClick(ActionBarIds.Create, pageId, DetailsPanelId.PLUGGABLEDATABASES);
        triggerCreatePdb({
          databaseId: cdb.id,
          location,
          onExecute: refresh,
          onCancel: () => trackActionDiscard(ActionBarIds.Create, pageId, DetailsPanelId.PLUGGABLEDATABASES),
        });
      },
    },
    
    buildStartAction(ActionBarIds.Start, ActionBarTestIds.Start, selection[0]),
    buildStopAction(ActionBarIds.Stop, ActionBarTestIds.Stop, selection[0]),
    buildViewConnectionStringsAction(ActionBarIds.ConnectionStrings, ActionBarTestIds.ConnectionStrings, selection[0]),
    {
      key: ActionBarIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionBarIds.Refresh, pageId, DetailsPanelId.PLUGGABLEDATABASES);
        refresh();
        listingComponentRef?.resetTableSelection();
      },
    },
    buildDeleteAction(ActionBarIds.Delete, ActionBarTestIds.Delete, selection[0]),
  ];

  return (
    <Listing
      testId={ListingTestIds.ExaDbPdbs}
      listingComponentRef={setListingComponentRef}
      items={items || []}
      infoBlocks={disabledActionsInfoBlocks(selection[0])}
      emptyList={{ title: Messages.common.noResults() }}
      listColumns={columns}
      itemsPerPage={perPageItemsCount}
      actionBarItems={dbBarActions as ActionType<undefined>[]}
      actions={dbRowActionMenu}
      onActionClick={closePanels}
      selectionMode={SelectionMode.single}
      selectedItems={setSelectedItems}
      isLoading={loading}
      sorting={{
        locale,
        initialSortedColumn: ColumnIds.Name,
      }}
    />
  );
};
