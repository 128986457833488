import {
  AnchoredPanelType,
  ExtraFilter,
  FilterAll,
  FilterComponent,
  FormInputGroup,
  FormInputGroupLayout,
  FormValues,
  getValue,
  InputFormGroup,
  InputFormSidePanel,
  ListingDisplayNameLink,
  Operator,
  optimizedRetryOption,
  SelectTable,
  SelectTableColumn,
  SelectTableGroupingOption,
  SortDirections,
  SubmitButtonMode,
} from "o4a-react";
import React from "react";
import { QueryMethod } from "savant-connector";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { FilterState, FilterStateContext } from "../../console/FilterContext";
import { Settings, SettingsContext } from "../../console/SettingsContext";
import { PageId, PageRegistrationConfig, RESOURCE_ROUTE } from "../../constants/pluginConstants";
import { SidePanelTestIds, ttlOneMinCaching } from "../../constants/uiConstants";
import { AzureSubscriptionSummary } from "../../gen/clients/mchub-azure-api-client";
import { IdResourceType, parseId } from "../../helpers/idHelper";
import {
  getFieldCustomOptions,
  getResourceDisplayTypeFromEnum,
  getResourceIconName,
  getResourcePageId,
  getTopLevelResourceType,
  responseItemstoArray,
  TopLevelResourceType,
} from "../../helpers/resourceHelper";
import { useFilterStatePills } from "../../hooks/useFilterStatePills";
import { useLocationFilters } from "../../hooks/useLocationFilters";
import { useQueryCall } from "../../hooks/useQueryCall";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { getAzureLocationName, getOciRegion } from "../../utils";
import { AzureResourceGroupLink } from "../AzureLinks/AzureResourceGroupLink";
import { AzureSubscriptionLink } from "../AzureLinks/AzureSubscriptionLink";

export interface FavoriteAddResourcesPanelProps {
  onSubmit: (selectedItems: FavoriteResourceItem[]) => void;
  onClose?: () => void;
}

export enum Fields {
  ResourceList = "resource-list",
}

export enum ColumnIds {
  DisplayName = "displayName",
  DisplayType = "displayType",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
}

export enum ColumnTestIds {
  DisplayName = "displayName",
  DisplayType = "displayType",
  SubscriptionName = "subscriptionName",
  ResourceGroupName = "resourceGroupName",
  Location = "location",
}

export enum FilterTestIds {
  SubscriptionFilter = "subscription-filter",
  LocationFilter = "location-filter",
  ResourceGroupFilter = "resource-group-filter",
  TypeFilter = "type-filter",
}

export interface FavoriteResourceItem {
  id: string;
  displayName: string;
  subscriptionId: string;
  subscriptionName: string;
  resourceGroupName: string;
  location: string;
  displayType: TopLevelResourceType | undefined;
  iconName: string;
  detailsPageId: PageId;
}

interface AzureSubscriptionSummaryMap {
  [key: string]: AzureSubscriptionSummary;
}

interface Resource {
  id: string;
}

interface ResourceCollection {
  items: Array<Resource>;
}

const getQueryMethods = (
  location: string,
  type: TopLevelResourceType,
): QueryMethod<{ subscriptionId: string }, ResourceCollection>[] => {
  const methods: QueryMethod<{ subscriptionId: string }, ResourceCollection>[] = [];
  switch (type) {
    case TopLevelResourceType.ADBS:
      methods.push(apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.listAdbsDatabasesBySubscription);
      break;
    case TopLevelResourceType.EXA_DATABASE:
      methods.push(apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listDatabasesBySubscription);
      methods.push(apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listPluggableDatabasesBySubscription);
      break;
    case TopLevelResourceType.EXA_VM_CLUSTER:
      methods.push(apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listCloudVmClustersBySubscription);
      break;
    case TopLevelResourceType.EXA_INFRA:
      // eslint-disable-next-line max-len
      methods.push(apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.listCloudExadataInfrastructuresBySubscription);
      break;
    case TopLevelResourceType.VMDB_DATABASE:
      methods.push(apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbDatabasesBySubscription);
      // eslint-disable-next-line max-len
      methods.push(apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbPluggableDatabasesBySubscription);
      break;
    case TopLevelResourceType.VMDB_DBSYSTEM:
      methods.push(apiClients.withRegion(getOciRegion(location)).vmDatabaseApi.listVmdbDbSystemsBySubscription);
      break;
    case TopLevelResourceType.MDS_DBSYSTEM:
      methods.push(apiClients.withRegion(getOciRegion(location)).mdsDatabaseApi.listMdsDbSystemsBySubscription);
      break;
    case TopLevelResourceType.MCVCN:
      // eslint-disable-next-line max-len
      methods.push(apiClients.withRegion(getOciRegion(location)).networkApi.listMultiCloudVirtualNetworksInSubscription);
      break;
    case TopLevelResourceType.DEPLOYMENT:
      methods.push(apiClients.withRegion(getOciRegion(location)).deploymentApi.listDeploymentsInSubscription);
      break;
    default:
      break;
  }
  return methods;
};

const getFailureMessages = (
  type: TopLevelResourceType,
): string[] => {
  const messages: string[] = [];
  switch (type) {
    case TopLevelResourceType.ADBS:
      messages.push(Messages.notifications.failure.messages.loadAdbs());
      break;
    case TopLevelResourceType.EXA_DATABASE:
      messages.push(Messages.notifications.failure.messages.loadExaDbCdbs());
      messages.push(Messages.notifications.failure.messages.loadExaDbPdbs());
      break;
    case TopLevelResourceType.EXA_VM_CLUSTER:
      messages.push(Messages.notifications.failure.messages.loadVMCluster());
      break;
    case TopLevelResourceType.EXA_INFRA:
      messages.push(Messages.notifications.failure.messages.loadExadbInfra());
      break;
    case TopLevelResourceType.VMDB_DATABASE:
      messages.push(Messages.notifications.failure.messages.loadVMDbCdbs());
      messages.push(Messages.notifications.failure.messages.loadVMDbPdbs());
      break;
    case TopLevelResourceType.VMDB_DBSYSTEM:
      messages.push(Messages.notifications.failure.messages.loadVmDbSystems());
      break;
    case TopLevelResourceType.MDS_DBSYSTEM:
      messages.push(Messages.notifications.failure.messages.loadMysql());
      break;
    case TopLevelResourceType.MCVCN:
      messages.push(Messages.notifications.failure.messages.loadMcvcns());
      break;
    case TopLevelResourceType.DEPLOYMENT:
      messages.push(Messages.notifications.failure.messages.loadDeployment());
      break;
    default:
      break;
  }
  return messages;
};

export const FavoriteAddResourcesPanel = (
  { onSubmit, onClose }: FavoriteAddResourcesPanelProps,
): JSX.Element => {
  const { preferredSubscription, locale } = React.useContext<Settings>(SettingsContext);
  const filterState = React.useContext<FilterState>(FilterStateContext);

  const [selectedSubscription, setSelectedSubscription] = React.useState<string>("");
  const [subscriptionOptions, setSubscriptionOptions] = React.useState<{ key: string, text: string }[]>([]);
  const { loading: subscriptionLoading, subscriptions } = useSubscriptions();

  const { locationOptions, selectedLocation, setSelectedLocation } = useLocationFilters(
    filterState.defaultSubscription ? filterState.defaultSubscription : selectedSubscription,
  );

  const [selectedType, setSelectedType] = React.useState<TopLevelResourceType>(TopLevelResourceType.ADBS);

  const [filterComponentRef, setFilterComponentRef] = React.useState<FilterComponent>();
  const pillFilterValues = filterComponentRef?.getFilters();
  const filterTextValue = filterComponentRef?.getFilterText();
  const [filterText, setFilterText] = React.useState(filterState.filterTextValue || filterTextValue);

  React.useEffect(() => {
    const newSubscriptionOptions: { key: string, text: string }[] = [];
    // eslint-disable-next-line no-unused-expressions
    subscriptions && subscriptions.forEach(subscription => {
      newSubscriptionOptions.push({ key: subscription.id, text: subscription.name });
    });

    setSubscriptionOptions(newSubscriptionOptions);
    if (filterState.defaultSubscription) {
      setSelectedSubscription(filterState.defaultSubscription);
      if (defaultExtraFilters) defaultExtraFilters[0].value = filterState.defaultSubscription;
    } else if (preferredSubscription) {
      setSelectedSubscription(preferredSubscription);
    } else {
      setSelectedSubscription(newSubscriptionOptions.length ? newSubscriptionOptions[0].key : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptions)]);

  // eslint-disable-next-line max-len
  const { loading: mainLoading, response: mainResponse } = useQueryCall<{ subscriptionId: string }, ResourceCollection>({
    wait: (!selectedSubscription || !selectedLocation || !selectedType),
    method: getQueryMethods(selectedLocation, selectedType)[0],
    options: {
      args: { subscriptionId: selectedSubscription },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: getFailureMessages(selectedType)[0],
      },
    },
  });

  // eslint-disable-next-line max-len
  const { loading: secondaryLoading, response: secondaryResponse } = useQueryCall<{ subscriptionId: string }, ResourceCollection>({
    wait: (!selectedSubscription || !selectedLocation
      || !(selectedType === TopLevelResourceType.EXA_DATABASE || selectedType === TopLevelResourceType.VMDB_DATABASE)),
    method: getQueryMethods(selectedLocation, selectedType)?.[1]
      // Use any dummy method to work around the Invalid weak key error by useQuery when no method is specified.
      // The dummy method is never going to be called since wait is blocking the call for types that do not support
      // secondary methods
      || apiClients.withRegion(getOciRegion(selectedLocation)).azResourceApi.listAzureSubscriptions,
    options: {
      args: { subscriptionId: selectedSubscription },
      caching: ttlOneMinCaching,
      fetchAllPages: true,
      retry: optimizedRetryOption,
    },
    notification: {
      failure: {
        title: Messages.notifications.failure.titles.load(),
        message: getFailureMessages(selectedType)?.[1],
      },
    },
  });

  // eslint-disable-next-line max-len
  const loading = subscriptionLoading || mainLoading || ((selectedType === TopLevelResourceType.EXA_DATABASE || selectedType === TopLevelResourceType.VMDB_DATABASE) && secondaryLoading);

  const mainResources = (mainResponse && responseItemstoArray<ResourceCollection, Resource>(mainResponse)) || [];
  // eslint-disable-next-line max-len
  const secondaryResources = (secondaryResponse && responseItemstoArray<ResourceCollection, Resource>(secondaryResponse)) || [];

  const resources = [...mainResources, ...secondaryResources];

  const resourceItemToFavoriteResourceItem = (
    items: Resource[],
    subscriptionsMap: AzureSubscriptionSummaryMap,
  ): FavoriteResourceItem[] => {
    const favoriteResourceItems: FavoriteResourceItem[] = [];
    items.forEach((item: Resource) => {
      const { subscriptionId, resourceGroup, resourceName, resourceType, provider: providerName } = parseId(item.id);
      const itemRgName = resourceGroup;
      const itemSubscriptionName = subscriptionsMap && subscriptionsMap[subscriptionId]?.name;

      favoriteResourceItems.push({
        id: item.id,
        displayName: resourceName,
        subscriptionId,
        subscriptionName: itemSubscriptionName,
        resourceGroupName: itemRgName,
        location: selectedLocation || "",
        displayType: getTopLevelResourceType(providerName, resourceType as IdResourceType),
        iconName: getResourceIconName(providerName, resourceType as IdResourceType),
        detailsPageId: getResourcePageId(providerName, resourceType as IdResourceType),
      });
    });
    return favoriteResourceItems;
  };

  const items = React.useMemo(() => {
    let convertedItems: FavoriteResourceItem[] = [];

    if (resources && subscriptions && subscriptions.length > 0) {
      const subscriptionsMap = subscriptions.reduce((
        map: AzureSubscriptionSummaryMap,
        subscription: AzureSubscriptionSummary,
      ) => {
        map[subscription.id] = subscription;
        return map;
      }, {});
      convertedItems = resourceItemToFavoriteResourceItem(resources, subscriptionsMap);
    }
    return convertedItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(resources), JSON.stringify(subscriptions)]);

  const getDisplayType = (value: FavoriteResourceItem): JSX.Element => (
    <span>{getResourceDisplayTypeFromEnum(value.displayType)}</span>
  );

  const getLocation = (value: FavoriteResourceItem): JSX.Element => (
    <span>{getAzureLocationName(value.location)}</span>
  );

  const columns: SelectTableColumn[] = [
    {
      itemProp: ColumnIds.DisplayName,
      testId: ColumnTestIds.DisplayName,
      name: Messages.common.name(),
      flexGrow: 2,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => (
        <ListingDisplayNameLink
          displayName={value.displayName}
          navigation={{
            // eslint-disable-next-line max-len
            to: `${RESOURCE_ROUTE}/${value.id}/${PageRegistrationConfig[value.detailsPageId][0].panelPath}?location=${value.location}`,
            pageKey: PageRegistrationConfig[value.detailsPageId][0].key,
          }}
          iconName={value.iconName}
        />
      ),
    },
    {
      itemProp: ColumnIds.DisplayType,
      testId: ColumnTestIds.DisplayType,
      name: Messages.labels.type(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getDisplayType(value),
    },
    {
      itemProp: ColumnIds.Location,
      testId: ColumnTestIds.Location,
      name: Messages.labels.location(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      onRenderItems: value => getLocation(value),
    },
    {
      itemProp: ColumnIds.ResourceGroupName,
      testId: ColumnTestIds.ResourceGroupName,
      name: Messages.labels.resourceGroup(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      onRenderItems: value => <AzureResourceGroupLink resourceId={value.id} hideClipboard />,
    },
    {
      itemProp: ColumnIds.SubscriptionName,
      testId: ColumnTestIds.SubscriptionName,
      name: Messages.labels.subscription(),
      flexGrow: 1,
      initialSortDirection: SortDirections.ASC,
      isResizable: true,
      // eslint-disable-next-line react/no-unstable-nested-components, max-len
      onRenderItems: value => <AzureSubscriptionLink resourceId={value.id} subscriptionName={value.subscriptionName} hideClipboard />,
    },
  ];

  const getColumnNdx = (columnId: ColumnIds): number => columns.findIndex(column => column.itemProp === columnId);

  const groupingOptions: SelectTableGroupingOption[] = [];

  React.useEffect(() => {
    if (filterState.defaultLocation) {
      setSelectedLocation(filterState.defaultLocation);
      if (defaultExtraFilters) defaultExtraFilters[1].value = filterState.defaultLocation;
    } else {
      setSelectedLocation(filterState.pillFilterStateValue.filterValues?.[1]?.value || selectedLocation);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeOptions = Object.keys(TopLevelResourceType).map(key => ({
    key,
    text: getResourceDisplayTypeFromEnum(key as TopLevelResourceType),
  }));

  const typeFilter: ExtraFilter = {
    testId: FilterTestIds.TypeFilter,
    name: columns[getColumnNdx(ColumnIds.DisplayType)].name,
    operator: Operator.Equal,
    value: selectedType as string,
    fieldKey: columns[getColumnNdx(ColumnIds.DisplayType)].itemProp,
    customOptions: typeOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedType(value as TopLevelResourceType);
      }
    },
    hideDelete: true,
  };

  const locationFilter: ExtraFilter = {
    testId: FilterTestIds.LocationFilter,
    name: columns[getColumnNdx(ColumnIds.Location)].name,
    operator: Operator.Equal,
    value: selectedLocation,
    fieldKey: columns[getColumnNdx(ColumnIds.Location)].itemProp,
    customOptions: locationOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedLocation(value);
      }
    },
    hideDelete: true,
  };

  const resourceGroupFilter: ExtraFilter = {
    testId: FilterTestIds.ResourceGroupFilter,
    name: columns[getColumnNdx(ColumnIds.ResourceGroupName)].name,
    operator: Operator.Equal,
    value: pillFilterValues?.find(
      item => item.fieldKey === columns[getColumnNdx(ColumnIds.ResourceGroupName)].itemProp,
    )?.value || [FilterAll],
    fieldKey: columns[getColumnNdx(ColumnIds.ResourceGroupName)].itemProp,
    customOptions: getFieldCustomOptions(items?.map(item => item.resourceGroupName), (fieldVal: string) => fieldVal),
    hideDelete: true,
  };

  const subscriptionFilter: ExtraFilter = {
    testId: FilterTestIds.SubscriptionFilter,
    name: columns[getColumnNdx(ColumnIds.SubscriptionName)].name,
    operator: Operator.Equal,
    value: selectedSubscription,
    fieldKey: columns[getColumnNdx(ColumnIds.SubscriptionName)].itemProp,
    customOptions: subscriptionOptions,
    hideAllOption: true,
    callBack: (value: string | string[]) => {
      if (!Array.isArray(value)) {
        setSelectedSubscription(value);
      }
    },
    hideDelete: true,
  };

  const textFilterCallBack = (rowItem: FavoriteResourceItem): FavoriteResourceItem => {
    const displayRowItem = { ...rowItem };
    displayRowItem.location = getAzureLocationName(displayRowItem.location);
    // eslint-disable-next-line max-len
    displayRowItem.displayType = getResourceDisplayTypeFromEnum(displayRowItem.displayType) as unknown as TopLevelResourceType;
    return displayRowItem;
  };

  React.useEffect(() => {
    setFilterText(filterTextValue);
    filterState.setFilterTextValue(filterTextValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTextValue]);

  const allFilterPills = [
    subscriptionFilter,
    locationFilter,
    typeFilter,
    resourceGroupFilter,
  ];

  const filterPills = [
    subscriptionFilter,
    locationFilter,
    typeFilter,
    resourceGroupFilter,
  ];

  const defaultExtraFilters = useFilterStatePills(pillFilterValues, allFilterPills, undefined, filterPills);

  const resetFilterValues = (): void => {
    const defaultPillValues = filterPills.map(filter => ({ fieldKey: filter.fieldKey, value: filter.value as string }));
    filterState.setFilterStateValue({ filterValues: defaultPillValues, listId: undefined, defaultPills: filterPills });
    filterState.setFilterTextValue("");
    setFilterText("");
  };

  const internalOnSubmit = (formValues: FormValues): void => {
    const selectedItems = getValue<FavoriteResourceItem[]>(formValues, Fields.ResourceList, InputFormGroup) || [];
    onSubmit(selectedItems);
  };

  return (
    <InputFormSidePanel
      title={Messages.labels.selectResources()}
      type={AnchoredPanelType.LARGE}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      submitText={Messages.actions.select()}
      onSubmit={internalOnSubmit}
      discardText={Messages.common.cancel()}
      onClose={onClose}
      testId={SidePanelTestIds.FavoriteAddResources}
    >
      <FormInputGroup layout={FormInputGroupLayout.COMPACT} groupName={InputFormGroup}>
        <SelectTable
          fieldName={Fields.ResourceList}
          columns={columns}
          multiSelect
          showSelectAll
          items={items}
          isLoading={loading}
          sortOptions={{
            locale,
            initialSortedColumn: ColumnIds.DisplayName,
          }}
          groupingSelect={{ groupingOptions }}
          filtering={{
            allExtraFilters: allFilterPills,
            defaultExtraFilters,
            defaultFilterText: filterText,
            textFilterCallBack,
            componentRef: setFilterComponentRef,
            onClearFilters: resetFilterValues,
            hideAddFilterPill: true,
          }}
          emptyList={{ title: Messages.hints.noResourcesMatch() }}
        />
      </FormInputGroup>
    </InputFormSidePanel>
  );
};
