import { CalloutComponent, FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { BackupCreatePanel, Fields as BackupFields } from "../../components/BackupCreatePanel/BackupCreatePanel";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
  EXABACKUP_CREATE_POLL_DELAY,
  EXABACKUP_CREATE_POLL_INTERVAL,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface ExaDbCreateBackupProps extends OperationActionProps {
  databaseId: string;
}

const ExaDbCreateBackup = (
  { targetId, location, databaseId, onExecute, onCancel }: ExaDbCreateBackupProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");

  const { invokeCall: invokeCreateBackup } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).exaDatabaseApi.createBackup },
  );
  const [calloutRef, setCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const onCreateBackup = (formValues: FormValues): void => {
    const backupName = getValue<string>(formValues, BackupFields.Name, InputFormGroup) || "";

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: calloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.createBackup(),
          message: Messages.notifications.inProgress.messages.createBackup(backupName),
        },
        success: {
          title: Messages.notifications.success.titles.createBackup(),
          message: Messages.notifications.success.messages.createBackup(backupName),
        },
        failure: {
          title: Messages.notifications.failure.titles.create(),
          message: Messages.notifications.failure.messages.createBackup(backupName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.EXABACKUP_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            backupKey: backupName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.EXABACKUP_CREATED_CHECK,
          delay: EXABACKUP_CREATE_POLL_DELAY,
          interval: EXABACKUP_CREATE_POLL_INTERVAL,
        },
      },
    };

    invokeCreateBackup({
      subscriptionId,
      resourceGroupName: resGroup,
      createBackupDetails: {
        displayName: backupName,
        databaseId,
      },
    }, invokeOptions);
  };

  return (
    <BackupCreatePanel
      componentRef={setCalloutRef}
      targetId={targetId}
      onSubmit={onCreateBackup}
      onClose={onCancel}
    />
  );
};

export const newExaDbCreateBackup = (
  props: ExaDbCreateBackupProps,
): JSX.Element => (<ExaDbCreateBackup {...props} />);
