import { CalloutComponent, FormValues, getValue, InputFormGroup } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AdbsBackupRestorePanel } from "../../components/BackupRestorePanel/AdbsBackupRestorePanel";
import { Fields as RestoreFields } from "../../components/BackupRestorePanel/BackupRestorePanel";
import { RestoreAdbsDatabaseDetails } from "../../gen/clients/mchub-azure-api-client";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  ADBS_RESTORE_POLL_DELAY,
  ADBS_RESTORE_POLL_INTERVAL,
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";

export interface AdbsRestoreProps extends OperationActionProps {
  databaseId: string | undefined;
  timeCreated?: Date | undefined;
}

const AdbsRestore = (
  { targetId, location, databaseId, timeCreated, onExecute, onCancel }: AdbsRestoreProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");

  const [restoreCalloutRef, setRestoreCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const { invokeCall: invokeRestore } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.restoreAdbsDatabase },
  );

  const onRestore = (formValues: FormValues): void => {
    const formRestoreTimestamp = getValue<number>(formValues, RestoreFields.Timestamp, InputFormGroup) as number;

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: restoreCalloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.restoreAdbs(),
          message: Messages.notifications.inProgress.messages.restoreAdbs(resName),
        },
        success: {
          title: Messages.notifications.success.titles.restoreAdbs(),
          message: Messages.notifications.success.messages.restoreAdbs(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.restoreAdbs(),
          message: Messages.notifications.failure.messages.restoreAdbs(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.ADBS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.ADBS_RESTORE_CHECK,
          delay: ADBS_RESTORE_POLL_DELAY,
          interval: ADBS_RESTORE_POLL_INTERVAL,
        },
      },
    };
    invokeRestore({
      subscriptionId,
      resourceGroupName: resGroup,
      databaseName: resName,
      restoreAdbsDatabaseDetails: { timestamp: new Date(formRestoreTimestamp) } as RestoreAdbsDatabaseDetails,
    }, invokeOptions);
  };

  return (
    <AdbsBackupRestorePanel
      targetId={targetId}
      componentRef={setRestoreCalloutRef}
      onSubmit={onRestore}
      onClose={onCancel}
      timeCreated={timeCreated}
    />
  );
};

export const newAdbsRestore = (
  props: AdbsRestoreProps,
): JSX.Element => (<AdbsRestore {...props} />);
