type KeySelector<T> = (item: T) => string;
export function groupBy<T>(array: Iterable<T>, keySelector: KeySelector<T>): Record<string, T[]> {
  return Array.from(array).reduce(
    (acc: Record<string, T[]>, item: T) => {
      const key = keySelector(item);
      if (key in acc) {
        acc[key].push(item);
      } else {
        acc[key] = [item];
      }
      return acc;
    },
    { },
  );
}

export const trimText = (value = ""): string | undefined => (value.trim().length ? value.trim() : undefined);
