/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnalyticsClient, LogLevel, TelemetryClient } from "console-telemetry-client";
import { InitStatus } from "../session/sessionConstants";

export const ANALYTICS_APP_NAME = "ODSA Portal";
export const ANALYTICS_NAMESPACE = "Odsa";

export enum EventType {
  PageViewEvent = 0,
  ClickEvent = 1
}

export function trackInitStatus(status: InitStatus, tenancyId?: string): void {
  AnalyticsClient.track(ANALYTICS_NAMESPACE, {
    "wt.app": ANALYTICS_APP_NAME,
    "wt.dl": EventType.PageViewEvent,
    "wt.oaeve": `${ANALYTICS_NAMESPACE}:app-login:${status}`,
    "wt.azTenantId": tenancyId,
  });
}

export enum MetricName {
  // #region Error category metric names
  PageSessionWithUnhandledExceptions = "PageSessionWithUnhandledExceptions",
  PluginNotAvailable = "PluginNotAvailable",
  UnhandledException = "UnhandledException",
  // #endregion

  // #region Resource category metric names
  ServiceCall = "ServiceCall",
  // #endregion

  // #region Navigation timing metric names
  Navigation = "Navigation",
  TimeToPluginInit = "Navigation.TTPI",
  TimeToRender = "Navigation.TTR",
  TimeToInteractive = "Navigation.TTI",
  TimeToFirstByte = "Navigation.TTFB",
  TimeToFirstIdle = "Navigation.TTFI",
  FirstContentfulPaint = "Navigation.FCP",
  // #endregion

  // #region
  UserDefined = "UserDefined",
  Interaction = "Interaction",
  LongTask = "LongTask",
  UsedJSHeapSize = "UsedJSHeapSize",
  ClickInteraction = "Interaction.Click",
  // #endregion
}

const OpcRequestId = "opc-request-id";

const nonReadOperations: Set<string> = new Set([
  "POST",
  "PUT",
  "DELETE",
  "PATCH",
]);
const supportedOperations: Set<string> = new Set([
  "GET",
  "POST",
  "PUT",
  "DELETE",
  "PATCH",
]);

function isSupportedHttpOperation(method: string): boolean {
  return supportedOperations.has(method);
}

function isNonReadOperation(method: string): boolean {
  return nonReadOperations.has(method);
}

const startTimes = (() => {
  const queueMap: { [url: string]: number[] } = {};

  const queue = (url: string, time: number): void => {
    if (!queueMap[url]) {
      queueMap[url] = [];
    }
    queueMap[url].push(time);
  };

  const dequeue = (url: string): number | undefined => {
    const queuedUrl = queueMap[url];
    const result = queuedUrl && queuedUrl.shift();

    if (queuedUrl && queuedUrl.length === 0) {
      delete queueMap[url];
    }

    return result;
  };

  return { queue, dequeue };
})();

/**
 * Analytics response interceptor
 */
export async function analyticsResponseInterceptor(
  request: Request,
  response: Response,
  operation: string,
): Promise<Response> {
  if (isNonReadOperation(request.method)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const analyticsName = `service-call:${operation}-${response.status}`;
    // Telemetry.trackAnalytics(analyticsName);
  }

  return response;
}

/**
 * Records service call availability and perf metrics
 */
export async function metricsResponseInterceptor(
  request: Request,
  response: Response,
  operation: string,
): Promise<Response> {
  const { url, headers } = request;
  const canonicalOperation = headers.has("opc-dry-run") || headers.has("mcp-dry-run")
    ? `${operation}-dryRun`
    : operation;
  const metricName = `${MetricName.ServiceCall}.${canonicalOperation}`;

  // === CASE metrics ===
  // ServiceCall.ResponseOut.Count
  // ServiceCall.ResponseOut.StatusFamily.2XX.Count
  // ServiceCall.ResponseOut.StatusFamily.4XX.Count
  // ServiceCall.ResponseOut.StatusFamily.5XX.Count
  // ServiceCall.<operation>.ResponseOut.Count
  // ServiceCall.<operation>.ResponseOut.StatusFamily.2XX.Count
  // ServiceCall.<operation>.ResponseOut.StatusFamily.4XX.Count
  // ServiceCall.<operation>.ResponseOut.StatusFamily.5XX.Count
  const statusFamily = response.status >= 500 ? "5XX" : response.status >= 400 ? "4XX" : "2XX";
  const allApisCount = `${MetricName.ServiceCall}.ResponseOut.Count`;
  const allApisStatusFamily = `${MetricName.ServiceCall}.ResponseOut.StatusFamily.${statusFamily}.Count`;
  const metricNameCount = `${metricName}.ResponseOut.Count`;
  const metricNameStatusFamily = `${metricName}.ResponseOut.StatusFamily.${statusFamily}.Count`;
  TelemetryClient.processMetric(allApisCount, 1);
  TelemetryClient.processMetric(allApisStatusFamily, 1);
  TelemetryClient.processMetric(metricNameCount, 1);
  TelemetryClient.processMetric(metricNameStatusFamily, 1);

  // === Other metrics ===
  // ServiceCall.<operation>
  // ServiceCall.<operation>.<status>
  // ServiceCall.<operation>.duration
  const metricNameWithStatus = `${metricName}.${response.status}`;
  const metricNameDuration = `${metricName}.duration`;

  const startTime = startTimes.dequeue(url);
  const endTime = performance.now();

  TelemetryClient.processMetric(metricName, response.status >= 400 ? 0 : 1);
  TelemetryClient.processMetric(metricNameWithStatus, 1);
  if (startTime) {
    const elapsedTime = endTime - startTime;
    TelemetryClient.processMetric(metricNameDuration, elapsedTime || 0);
  }

  return response;
}

export async function serviceCallRequestInterceptor(
  request: Request,
): Promise<Request> {
  startTimes.queue(request.url, performance.now());
  return request;
}

/**
 * Log failed response
 */
export async function loggerResponseInterceptor(
  request: Request,
  response: Response,
  operation: string,
): Promise<Response> {
  const requestFailed = response.status >= 400;
  const nonReadOperation = isNonReadOperation(request.method);

  if (requestFailed || nonReadOperation) {
    // if the plugin is used when the browser is offline, a response with no headers is possible
    const opcRequestId = response && response.headers
      ? response.headers.get(OpcRequestId)
      : "no-headers-available";

    // eslint-disable-next-line max-len
    let message = `Service called - operation:${operation} method:${request.method} status:${response.status} url:${request.url} ${OpcRequestId}:${opcRequestId}`;
    let responseText = "";

    // Attempt to get an error message from the response
    if (requestFailed) {
      try {
        responseText = await response.clone().text();
        message += ` body:${responseText}`;
      } catch (e) {
        // Could not get error message from response, skip it
      }

      const error = new Error();
      error.message = responseText;
      error.name = operation;

      const metadata = {
        opcRequestId,
        statusCode: response.status,
        status: response.statusText,
        requestUrl: request.url,
      };

      TelemetryClient.processLog(LogLevel.Error, JSON.stringify({ message, error }), metadata);
    } else if (nonReadOperation) {
      TelemetryClient.processLog(LogLevel.Info, message);
    }
  }

  return response;
}

export async function telemetryResponseInterceptor(
  request: Request,
  response: Response,
  operation: string | undefined,
): Promise<Response> {
  if (isSupportedHttpOperation(request.method)) {
    return [
      analyticsResponseInterceptor,
      metricsResponseInterceptor,
      loggerResponseInterceptor,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ].reduce(async (p, fn: any) => {
      const resp = await p;
      return fn(request, resp, operation);
    }, Promise.resolve(response));
  }

  return response;
}

export async function telemetryRequestInterceptor(
  request: Request,
  operation: string | undefined,
): Promise<Request> {
  if (isSupportedHttpOperation(request.method)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return [serviceCallRequestInterceptor].reduce(async (p, fn: any) => {
      const req = await p;
      return fn(req, operation);
    }, Promise.resolve(request));
  }

  return request;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function apiErrorHandler(error: any): Promise<void> {
  const log = `Service call failed. url:${window.location.href}`;
  let canonicalError = error;
  if (error instanceof TypeError) {
    canonicalError = error.toString();
  }
  TelemetryClient.processLog(LogLevel.Error, JSON.stringify({ log, error: canonicalError }));
}
