import { FieldSet, InputWizardPanelComponent, SelectComponent, TextInput, useNavigation } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { RegionSelect } from "../../components/RegionSelect/RegionSelect";
import { ResourceGroupSelect } from "../../components/ResourceGroupSelect/ResourceGroupSelect";
import { SubscriptionSelect } from "../../components/SubscriptionSelect/SubscriptionSelect";
import { ConsoleContext } from "../../console/ConsoleContext";
import {
  mdsDatabaseDescriptionMaxLength,
  mdsDbNameMaxLength,
  mdsDbNameMinLength,
  nameValidationRegex,
} from "../../helpers/validationHelper";

export const GROUP_BASICS = "basics";

export enum Fields {
  Description = "mdsDbSystemDeploymentDetails.description",
  Name = "mdsDbSystemDeploymentDetails.name",
  Region = "region",
  ResourceGroup = "resourceGroupId",
  Subscription = "subscriptionId",
}

export enum FieldTestIds {
  Name = "dbSystemName",
  Subscription = "subscriptionId",
  ResourceGroup = "resourceGroupId",
  Description = "description",
  Region = "region",
}

export interface BasicsTabContentProps {
  location?: string;
  subscription?: string;
  resourceGroupId?: string;
  disableUIControls?: Fields[] | undefined;
  inputWizardPanelRef: InputWizardPanelComponent;
}

export const BasicsTabContent = ({
  location, subscription, resourceGroupId,
  disableUIControls, inputWizardPanelRef,
}: BasicsTabContentProps): JSX.Element => {
  const { customData } = useNavigation(ConsoleContext);
  const [subscriptionId, setSubscriptionId] = React.useState<string>(subscription || "");

  const resourceSelectRef = React.useRef<SelectComponent>();
  const setResourceSelectRef = (selectComponent: SelectComponent): void => {
    resourceSelectRef.current = selectComponent;
  };

  const onSubscriptionChange = (subId: string): void => setSubscriptionId(subId);

  const onResourceGroupError = (): void => {
    inputWizardPanelRef.showError(Messages.createCommon.loadingErrors.resourceGroups());
  };

  const onResourceGroupMissingDependencies = (): void => inputWizardPanelRef.showErrorDialog(
    Messages.createCommon.dependencyWarnings.subscriptionNotSelected.message(),
    Messages.createCommon.dependencyWarnings.subscriptionNotSelected.title(),
  );

  return (
    <>
      <FieldSet header={Messages.createMysql.basicsTab.sectionTitles.projectDetails()}>
        <SubscriptionSelect
          required
          testId={FieldTestIds.Subscription}
          onChange={onSubscriptionChange}
          fieldName={Fields.Subscription}
          label={Messages.labels.subscription()}
          tooltip={Messages.hints.toolTipSubscription()}
          // eslint-disable-next-line max-len
          disabled={customData?.dbSystemName || disableUIControls?.find(ele => ele === Fields.Subscription) !== undefined}
          defaultValue={subscriptionId ? [subscriptionId] : undefined}
        />
        <ResourceGroupSelect
          required
          subField
          testId={FieldTestIds.ResourceGroup}
          subscriptionId={subscriptionId}
          componentRef={setResourceSelectRef}
          fieldName={Fields.ResourceGroup}
          label={Messages.labels.resourceGroup()}
          tooltip={Messages.hints.toolTipResourceGroup()}
          onError={onResourceGroupError}
          onMissingDependencies={onResourceGroupMissingDependencies}
          defaultValue={resourceGroupId ? [resourceGroupId] : undefined}
          disabled={disableUIControls?.find(ele => ele === Fields.ResourceGroup) !== undefined}
        />
      </FieldSet>
      <FieldSet header={Messages.createMysql.basicsTab.sectionTitles.instanceDetails()}>
        <TextInput
          required
          testId={FieldTestIds.Name}
          fieldName={Fields.Name}
          label={Messages.labels.mySQLDatabaseSystemName()}
          tooltip={Messages.hints.toolTipMySQLDatabaseSystemName()}
          validator={(value: string | undefined) => {
            const errors: string[] = [];
            if (!value || (value?.length < mdsDbNameMinLength || value?.length > mdsDbNameMaxLength)) {
              errors.push(Messages.validation.nameChars(mdsDbNameMinLength.toString(), mdsDbNameMaxLength.toString()));
            }

            if (!value || (value && !nameValidationRegex.test(value))) {
              errors.push(Messages.validation.nameValidation());
            }

            return errors.length > 0 ? errors : undefined;
          }}
        />
        <TextInput
          testId={FieldTestIds.Description}
          fieldName={Fields.Description}
          label={Messages.labels.mySQLDatabaseDescription()}
          tooltip={Messages.hints.toolTipMySQLDatabaseDescription()}
          validator={(value: string | undefined) => {
            const errors: string[] = [];
            if (value && value.length > mdsDatabaseDescriptionMaxLength) {
              errors.push(Messages.validation.valueMaxLen(mdsDatabaseDescriptionMaxLength.toString()));
            }
            return errors.length > 0 ? errors : undefined;
          }}
        />
        <RegionSelect
          required
          subscriptionId={subscriptionId}
          // showUnlinkedRegions
          fieldName={Fields.Region}
          testId={FieldTestIds.Region}
          label={Messages.labels.region()}
          tooltip={Messages.hints.toolTipRegion()}
          defaultValue={location ? [location] : undefined}
          disabled={disableUIControls?.find(ele => ele === Fields.Region) !== undefined}
        />
      </FieldSet>
    </>
  );
};
